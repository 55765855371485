export const s015 = {
  cs_name: "Anakapalle",
  cs_shortname: "anakapalle",
  cs_code: "s015",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "C.M.RAMESH",
      party: "Bharatiya Janata Party",
      evm_votes: 750027,
      postal_votes: 12042,
      total_votes: 762069,
      percentage_of_votes: 57.5,
    },
    {
      serial_no: 2,
      candidate: "BUDI MUTYALA NAIDU",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 459762,
      postal_votes: 5777,
      total_votes: 465539,
      percentage_of_votes: 35.13,
    },
    {
      serial_no: 3,
      candidate: "VEGI VENKATESH",
      party: "Indian National Congress",
      evm_votes: 24833,
      postal_votes: 818,
      total_votes: 25651,
      percentage_of_votes: 1.94,
    },
    {
      serial_no: 4,
      candidate: "PALAKA SREERAMA MURTHY",
      party: "Bahujan Samaj Party",
      evm_votes: 19082,
      postal_votes: 75,
      total_votes: 19157,
      percentage_of_votes: 1.45,
    },
    {
      serial_no: 5,
      candidate: "VANKAYALA RAMACHANDRA RAO",
      party: "Independent",
      evm_votes: 4001,
      postal_votes: 1,
      total_votes: 4002,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 6,
      candidate: "LOVARAJU SIDDHA",
      party: "Independent",
      evm_votes: 3845,
      postal_votes: 7,
      total_votes: 3852,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 7,
      candidate: "KRISHNA SWAROOP VADLAMURI",
      party: "Dalita Bahujana Party",
      evm_votes: 3549,
      postal_votes: 8,
      total_votes: 3557,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 8,
      candidate: "GARA SURYA RAO",
      party: "Independent",
      evm_votes: 3116,
      postal_votes: 8,
      total_votes: 3124,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 9,
      candidate: "APPALA RAJU NAMMI",
      party: "Bharatha Chaitanya Yuvajana Party",
      evm_votes: 2589,
      postal_votes: 30,
      total_votes: 2619,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 10,
      candidate: "NAGESWARA RAO PETLA",
      party: "Independent",
      evm_votes: 2179,
      postal_votes: 5,
      total_votes: 2184,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 11,
      candidate: "ADARI SARAT CHANDRA",
      party: "Jai Bharat National Party",
      evm_votes: 1886,
      postal_votes: 27,
      total_votes: 1913,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 12,
      candidate: "VIJAYA LAKSHMI KARRI",
      party: "Pyramid Party of India",
      evm_votes: 1578,
      postal_votes: 13,
      total_votes: 1591,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "DR. THUMMAPALA HARI SHANKAR",
      party: "Independent",
      evm_votes: 1567,
      postal_votes: 9,
      total_votes: 1576,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "JUNNURI J SRINIVAS",
      party: "Independent",
      evm_votes: 1195,
      postal_votes: 6,
      total_votes: 1201,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 15,
      candidate: "APPALANAIDU TUMMAGUNTA",
      party: "Independent",
      evm_votes: 1055,
      postal_votes: 7,
      total_votes: 1062,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 26084,
      postal_votes: 151,
      total_votes: 26235,
      percentage_of_votes: 1.98,
    },
  ],
  total: {
    evm_votes: 1306348,
    postal_votes: 18984,
    total_votes: 1325332,
  },
};
export const s018 = {
  cs_name: "Rajahmundry",
  cs_shortname: "rajahmundry",
  cs_code: "s018",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DAGGUBATI PURANDHESHWARI",
      party: "Bharatiya Janata Party",
      evm_votes: 718641,
      postal_votes: 7874,
      total_votes: 726515,
      percentage_of_votes: 54.82,
    },
    {
      serial_no: 2,
      candidate: "DR. GUDURI SRINIVAS",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 483172,
      postal_votes: 4204,
      total_votes: 487376,
      percentage_of_votes: 36.77,
    },
    {
      serial_no: 3,
      candidate: "GIDUGU RUDRARAJU",
      party: "Indian National Congress",
      evm_votes: 31441,
      postal_votes: 1067,
      total_votes: 32508,
      percentage_of_votes: 2.45,
    },
    {
      serial_no: 4,
      candidate: "GANESWARA RAO PARAMATA",
      party: "Bahujan Samaj Party",
      evm_votes: 20277,
      postal_votes: 132,
      total_votes: 20409,
      percentage_of_votes: 1.54,
    },
    {
      serial_no: 5,
      candidate: "BATTULA BALARAMAKRISHNA",
      party: "Navarang Congress Party",
      evm_votes: 16930,
      postal_votes: 78,
      total_votes: 17008,
      percentage_of_votes: 1.28,
    },
    {
      serial_no: 6,
      candidate: "SALOPANTULA RAGHAVENDRA RAO",
      party: "Independent",
      evm_votes: 3261,
      postal_votes: 17,
      total_votes: 3278,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 7,
      candidate: "DR. MEDISI RATNARAO ALIAS VINAY",
      party: "Independent",
      evm_votes: 3109,
      postal_votes: 6,
      total_votes: 3115,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 8,
      candidate: "MOHANARAO SINGULURI",
      party: "Jai Bharat National Party",
      evm_votes: 2818,
      postal_votes: 15,
      total_votes: 2833,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 9,
      candidate: "MEDA SRINIVASA RAO",
      party: "Rashtriya Praja Congress (Secular)",
      evm_votes: 2422,
      postal_votes: 7,
      total_votes: 2429,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 10,
      candidate: "BOMMANABOINA VSR MURTHY",
      party: "Independent",
      evm_votes: 2173,
      postal_votes: 6,
      total_votes: 2179,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 11,
      candidate: "BHANU CHANDAR KURUVELLA",
      party: "Independent",
      evm_votes: 1955,
      postal_votes: 3,
      total_votes: 1958,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 12,
      candidate: "JALLI BALA NAVEENA",
      party: "Independent",
      evm_votes: 1250,
      postal_votes: 4,
      total_votes: 1254,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 24302,
      postal_votes: 133,
      total_votes: 24435,
      percentage_of_votes: 1.84,
    },
  ],
  total: {
    evm_votes: 1311751,
    postal_votes: 13546,
    total_votes: 1325297,
  },
};
export const s019 = {
  cs_name: "Narsapuram",
  cs_shortname: "narsapuram",
  cs_code: "s019",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BHUPATHI RAJU SRINIVASA VARMA (B.J.P.VARMA)",
      party: "Bharatiya Janata Party",
      evm_votes: 699382,
      postal_votes: 7961,
      total_votes: 707343,
      percentage_of_votes: 57.46,
    },
    {
      serial_no: 2,
      candidate: "UMABALA GUDURI",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 426037,
      postal_votes: 4504,
      total_votes: 430541,
      percentage_of_votes: 34.98,
    },
    {
      serial_no: 3,
      candidate: "K.B.R.NAIDU",
      party: "Indian National Congress",
      evm_votes: 34298,
      postal_votes: 915,
      total_votes: 35213,
      percentage_of_votes: 2.86,
    },
    {
      serial_no: 4,
      candidate: "PRASANNA KUMAR UNDURTHI",
      party: "Independent",
      evm_votes: 6350,
      postal_votes: 14,
      total_votes: 6364,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 5,
      candidate: "MEDAPATI VENKATA VARAHALA REDDY",
      party: "Independent",
      evm_votes: 5966,
      postal_votes: 3,
      total_votes: 5969,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 6,
      candidate: "SIRRA RAJU",
      party: "Bahujan Samaj Party",
      evm_votes: 5945,
      postal_votes: 69,
      total_votes: 6014,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 7,
      candidate: "BALAGAM NAYAKAR",
      party: "Independent",
      evm_votes: 5232,
      postal_votes: 8,
      total_votes: 5240,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 8,
      candidate: "NALLI RAJESH",
      party: "Independent",
      evm_votes: 4684,
      postal_votes: 0,
      total_votes: 4684,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 9,
      candidate: "KETHA SREENU",
      party: "Independent",
      evm_votes: 3140,
      postal_votes: 5,
      total_votes: 3145,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 10,
      candidate: "OLETY NAGENDRA KRISHNA",
      party: "Jaibhim Rao Bharat Party",
      evm_votes: 3112,
      postal_votes: 4,
      total_votes: 3116,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 11,
      candidate: "ADDEPALLI VEERA VENKAT SUBBA RAO",
      party: "Independent",
      evm_votes: 2781,
      postal_votes: 2,
      total_votes: 2783,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 12,
      candidate: "ANAND CHANDULAL JASTI",
      party: "Independent",
      evm_votes: 2726,
      postal_votes: 0,
      total_votes: 2726,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 13,
      candidate: "GANJI PURNIMA",
      party: "Republican Party of India (A)",
      evm_votes: 1645,
      postal_votes: 4,
      total_votes: 1649,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 14,
      candidate: "GEDALA LAXMANA RAO",
      party: "Independent",
      evm_votes: 1525,
      postal_votes: 2,
      total_votes: 1527,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 15,
      candidate: "ADDANKI DORABABU",
      party: "Independent",
      evm_votes: 1388,
      postal_votes: 5,
      total_votes: 1393,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 16,
      candidate: "GOTTUMUKKALA SHIVAJI",
      party: "Independent",
      evm_votes: 1378,
      postal_votes: 6,
      total_votes: 1384,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 17,
      candidate: "ADINARAYANA DUPPANAPUDI",
      party: "Independent",
      evm_votes: 1002,
      postal_votes: 5,
      total_votes: 1007,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 18,
      candidate: "MANNE LEELA RAMA NARENDRA",
      party: "Pyramid Party of India",
      evm_votes: 922,
      postal_votes: 8,
      total_votes: 930,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 19,
      candidate: "RAMA DURGA PRASAD THOLETI",
      party: "Independent",
      evm_votes: 919,
      postal_votes: 1,
      total_votes: 920,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 20,
      candidate: "RUKHMINI",
      party: "Independent",
      evm_votes: 683,
      postal_votes: 3,
      total_votes: 686,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 21,
      candidate: "ADABALA SIVA",
      party: "Independent",
      evm_votes: 682,
      postal_votes: 1,
      total_votes: 683,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 22,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7554,
      postal_votes: 123,
      total_votes: 7677,
      percentage_of_votes: 0.62,
    },
  ],
  total: {
    evm_votes: 1217351,
    postal_votes: 13643,
    total_votes: 1230994,
  },
};
export const s021 = {
  cs_name: "Arunachal_West",
  cs_shortname: "arunachalwest",
  cs_code: "s021",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "KIREN RIJIJU",
      party: "Bharatiya Janata Party",
      evm_votes: 196415,
      postal_votes: 9002,
      total_votes: 205417,
      percentage_of_votes: 51.38,
    },
    {
      serial_no: 2,
      candidate: "NABAM TUKI",
      party: "Indian National Congress",
      evm_votes: 100100,
      postal_votes: 4579,
      total_votes: 104679,
      percentage_of_votes: 26.18,
    },
    {
      serial_no: 3,
      candidate: "TECHI RANA",
      party: "Independent",
      evm_votes: 31106,
      postal_votes: 2208,
      total_votes: 33314,
      percentage_of_votes: 8.33,
    },
    {
      serial_no: 4,
      candidate: "TOKO SHEETAL",
      party: "Gana Suraksha Party",
      evm_votes: 27670,
      postal_votes: 2860,
      total_votes: 30530,
      percentage_of_votes: 7.64,
    },
    {
      serial_no: 5,
      candidate: "BIMPAK SIGA",
      party: "Independent",
      evm_votes: 10569,
      postal_votes: 949,
      total_votes: 11518,
      percentage_of_votes: 2.88,
    },
    {
      serial_no: 6,
      candidate: "RUHI TAGUNG",
      party: "Independent",
      evm_votes: 7559,
      postal_votes: 262,
      total_votes: 7821,
      percentage_of_votes: 1.96,
    },
    {
      serial_no: 7,
      candidate: "LEKI NORBU",
      party: "Independent",
      evm_votes: 2139,
      postal_votes: 132,
      total_votes: 2271,
      percentage_of_votes: 0.57,
    },
    {
      serial_no: 8,
      candidate: "TANIA JUNE",
      party: "Independent",
      evm_votes: 1920,
      postal_votes: 38,
      total_votes: 1958,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 2133,
      postal_votes: 163,
      total_votes: 2296,
      percentage_of_votes: 0.57,
    },
  ],
  total: {
    evm_votes: 379611,
    postal_votes: 20193,
    total_votes: 399804,
  },
};
export const s022 = {
  cs_name: "Arunachal_East",
  cs_shortname: "arunachaleast",
  cs_code: "s022",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "TAPIR GAO",
      party: "Bharatiya Janata Party",
      evm_votes: 140715,
      postal_votes: 4866,
      total_votes: 145581,
      percentage_of_votes: 45.01,
    },
    {
      serial_no: 2,
      candidate: "BOSIRAM SIRAM",
      party: "Indian National Congress",
      evm_votes: 109433,
      postal_votes: 5727,
      total_votes: 115160,
      percentage_of_votes: 35.6,
    },
    {
      serial_no: 3,
      candidate: "TAMAT GAMOH",
      party: "Independent",
      evm_votes: 26691,
      postal_votes: 912,
      total_votes: 27603,
      percentage_of_votes: 8.53,
    },
    {
      serial_no: 4,
      candidate: "SOTAI KRI",
      party: "Independent",
      evm_votes: 13227,
      postal_votes: 986,
      total_votes: 14213,
      percentage_of_votes: 4.39,
    },
    {
      serial_no: 5,
      candidate: "OMAK NITIK",
      party: "Independent",
      evm_votes: 9074,
      postal_votes: 295,
      total_votes: 9369,
      percentage_of_votes: 2.9,
    },
    {
      serial_no: 6,
      candidate: "BANDEY MILI",
      party: "Arunachal Democratic Party",
      evm_votes: 6419,
      postal_votes: 203,
      total_votes: 6622,
      percentage_of_votes: 2.05,
    },
    {
      serial_no: 7,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4654,
      postal_votes: 241,
      total_votes: 4895,
      percentage_of_votes: 1.51,
    },
  ],
  total: {
    evm_votes: 310213,
    postal_votes: 13230,
    total_votes: 323443,
  },
};
export const s034 = {
  cs_name: "Darrang-Udalguri",
  cs_shortname: "darrangudalguri",
  cs_code: "s034",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DILIP SAIKIA",
      party: "Bharatiya Janata Party",
      evm_votes: 864583,
      postal_votes: 3804,
      total_votes: 868387,
      percentage_of_votes: 47.95,
    },
    {
      serial_no: 2,
      candidate: "MADHAB RAJBANGSHI",
      party: "Indian National Congress",
      evm_votes: 538032,
      postal_votes: 1343,
      total_votes: 539375,
      percentage_of_votes: 29.78,
    },
    {
      serial_no: 3,
      candidate: "DURGADAS BORO",
      party: "Bodoland Peoples Front",
      evm_votes: 309040,
      postal_votes: 1534,
      total_votes: 310574,
      percentage_of_votes: 17.15,
    },
    {
      serial_no: 4,
      candidate: "DANIEL MARDI",
      party: "Independent",
      evm_votes: 14277,
      postal_votes: 14,
      total_votes: 14291,
      percentage_of_votes: 0.79,
    },
    {
      serial_no: 5,
      candidate: "AROON BAROOA",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 12519,
      postal_votes: 44,
      total_votes: 12563,
      percentage_of_votes: 0.69,
    },
    {
      serial_no: 6,
      candidate: "SWARNA DEVI",
      party: "Gana Suraksha Party",
      evm_votes: 11067,
      postal_votes: 45,
      total_votes: 11112,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 7,
      candidate: "LALIT PEGU",
      party: "Voters Party International",
      evm_votes: 10887,
      postal_votes: 28,
      total_votes: 10915,
      percentage_of_votes: 0.6,
    },
    {
      serial_no: 8,
      candidate: "JITENDRA CHALIHA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 7421,
      postal_votes: 31,
      total_votes: 7452,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 9,
      candidate: "ABUL KASEM",
      party: "Republican Party of India (Athawale)",
      evm_votes: 4956,
      postal_votes: 17,
      total_votes: 4973,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 10,
      candidate: "BIREN BASAK",
      party: "Bharatiya Gana Parishad",
      evm_votes: 4605,
      postal_votes: 18,
      total_votes: 4623,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 11,
      candidate: "ABDUL HAMED",
      party: "Rashtriya Ulama Council",
      evm_votes: 3721,
      postal_votes: 10,
      total_votes: 3731,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 23086,
      postal_votes: 118,
      total_votes: 23204,
      percentage_of_votes: 1.28,
    },
  ],
  total: {
    evm_votes: 1804194,
    postal_votes: 7006,
    total_votes: 1811200,
  },
};
export const s035 = {
  cs_name: "Guwahati",
  cs_shortname: "guwahati",
  cs_code: "s035",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BIJULI KALITA MEDHI",
      party: "Bharatiya Janata Party",
      evm_votes: 891901,
      postal_votes: 2986,
      total_votes: 894887,
      percentage_of_votes: 55.95,
    },
    {
      serial_no: 2,
      candidate: "MIRA BORTHAKUR GOSWAMI",
      party: "Indian National Congress",
      evm_votes: 642353,
      postal_votes: 1444,
      total_votes: 643797,
      percentage_of_votes: 40.25,
    },
    {
      serial_no: 3,
      candidate: "DIPAK KR BORO",
      party: "Voters Party International",
      evm_votes: 8494,
      postal_votes: 54,
      total_votes: 8548,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 4,
      candidate: "COLONEL GOKUL CHANDRA SINGHA",
      party: "Independent",
      evm_votes: 7644,
      postal_votes: 34,
      total_votes: 7678,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 5,
      candidate: "AMITABH SARMA",
      party: "Ekam Sanatan Bharat Dal",
      evm_votes: 7508,
      postal_votes: 19,
      total_votes: 7527,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 6,
      candidate: "KAZI NEKIB AHMED",
      party: "Independent",
      evm_votes: 6753,
      postal_votes: 8,
      total_votes: 6761,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 7,
      candidate: "SAMAD CHOUDHURY",
      party: "Bharatiya Gana Parishad",
      evm_votes: 5081,
      postal_votes: 14,
      total_votes: 5095,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 8,
      candidate: "SHEJON GOYARY",
      party: "Bahujan Maha Party",
      evm_votes: 4826,
      postal_votes: 21,
      total_votes: 4847,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 20139,
      postal_votes: 110,
      total_votes: 20249,
      percentage_of_votes: 1.27,
    },
  ],
  total: {
    evm_votes: 1594699,
    postal_votes: 4690,
    total_votes: 1599389,
  },
};
export const s036 = {
  cs_name: "Diphu",
  cs_shortname: "diphu",
  cs_code: "s036",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "AMARSING TISSO",
      party: "Bharatiya Janata Party",
      evm_votes: 332440,
      postal_votes: 2180,
      total_votes: 334620,
      percentage_of_votes: 49.01,
    },
    {
      serial_no: 2,
      candidate: "J. I. KATHAR",
      party: "Independent",
      evm_votes: 185864,
      postal_votes: 1153,
      total_votes: 187017,
      percentage_of_votes: 27.39,
    },
    {
      serial_no: 3,
      candidate: "JOY RAM ENGLENG",
      party: "Indian National Congress",
      evm_votes: 123055,
      postal_votes: 964,
      total_votes: 124019,
      percentage_of_votes: 18.16,
    },
    {
      serial_no: 4,
      candidate: "JOHN BARNARD SANGMA",
      party: "Gana Suraksha Party",
      evm_votes: 11241,
      postal_votes: 38,
      total_votes: 11279,
      percentage_of_votes: 1.65,
    },
    {
      serial_no: 5,
      candidate: "JOTSON BEY",
      party: "Autonomous State Demand Committee",
      evm_votes: 9561,
      postal_votes: 72,
      total_votes: 9633,
      percentage_of_votes: 1.41,
    },
    {
      serial_no: 6,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 16127,
      postal_votes: 132,
      total_votes: 16259,
      percentage_of_votes: 2.38,
    },
  ],
  total: {
    evm_votes: 678288,
    postal_votes: 4539,
    total_votes: 682827,
  },
};
export const s037 = {
  cs_name: "Karimganj",
  cs_shortname: "karimganj",
  cs_code: "s037",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "KRIPANATH MALLAH",
      party: "Bharatiya Janata Party",
      evm_votes: 541220,
      postal_votes: 3873,
      total_votes: 545093,
      percentage_of_votes: 47.53,
    },
    {
      serial_no: 2,
      candidate: "HAFIZ RASHID AHMED CHOUDHURY",
      party: "Indian National Congress",
      evm_votes: 524390,
      postal_votes: 2343,
      total_votes: 526733,
      percentage_of_votes: 45.93,
    },
    {
      serial_no: 3,
      candidate: "SAHABUL ISLAM CHOUDHURY",
      party: "All India United Democratic Front",
      evm_votes: 29070,
      postal_votes: 135,
      total_votes: 29205,
      percentage_of_votes: 2.55,
    },
    {
      serial_no: 4,
      candidate: "JIBESH DEB",
      party: "Independent",
      evm_votes: 7105,
      postal_votes: 4,
      total_votes: 7109,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 5,
      candidate: "DEBOJYOTI NATH",
      party: "Independent",
      evm_votes: 5900,
      postal_votes: 7,
      total_votes: 5907,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 6,
      candidate: "AHAD UDDIN",
      party: "Rashtriya Ulama Council",
      evm_votes: 4186,
      postal_votes: 6,
      total_votes: 4192,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 7,
      candidate: "BINAY KRISHNA ROY",
      party: "Independent",
      evm_votes: 3621,
      postal_votes: 7,
      total_votes: 3628,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 8,
      candidate: "DILIP KUMAR",
      party: "Independent",
      evm_votes: 2623,
      postal_votes: 7,
      total_votes: 2630,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 9,
      candidate: "ROSID AHMED LASKAR",
      party: "Independent",
      evm_votes: 2600,
      postal_votes: 1,
      total_votes: 2601,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 10,
      candidate: "MUZAKKIR HASAN LASKAR",
      party: "Independent",
      evm_votes: 2287,
      postal_votes: 4,
      total_votes: 2291,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 11,
      candidate: "ABDUL BASIT TAPADAR",
      party: "Independent",
      evm_votes: 1912,
      postal_votes: 11,
      total_votes: 1923,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "GOPAL CHANDRA PAUL",
      party: "Independent",
      evm_votes: 1650,
      postal_votes: 7,
      total_votes: 1657,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 13,
      candidate: "MD. NAZRUL ISLAM LASKAR",
      party: "Independent",
      evm_votes: 1382,
      postal_votes: 0,
      total_votes: 1382,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "SAHEL AHMED",
      party: "Independent",
      evm_votes: 1110,
      postal_votes: 1,
      total_votes: 1111,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "ABDUL GOFFAR TALUKDAR",
      party: "Independent",
      evm_votes: 1094,
      postal_votes: 1,
      total_votes: 1095,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 16,
      candidate: "SAFIKUR RAHMAN HAZARI",
      party: "Independent",
      evm_votes: 1031,
      postal_votes: 0,
      total_votes: 1031,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 17,
      candidate: "PRAJJWAL SUDIP DEB",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 994,
      postal_votes: 7,
      total_votes: 1001,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 18,
      candidate: "ABDUL KALAM MAZUMDER",
      party: "Independent",
      evm_votes: 975,
      postal_votes: 0,
      total_votes: 975,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 19,
      candidate: "ALIM UDDIN MAZUMDER",
      party: "Independent",
      evm_votes: 881,
      postal_votes: 1,
      total_votes: 882,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 20,
      candidate: "ABDUL HAMEED",
      party: "Independent",
      evm_votes: 841,
      postal_votes: 1,
      total_votes: 842,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 21,
      candidate: "DEBASHISH GHOSH",
      party: "Bangali Nabanirman Sena",
      evm_votes: 800,
      postal_votes: 17,
      total_votes: 817,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 22,
      candidate: "RASHID AHMED CHOUDHURY",
      party: "Independent",
      evm_votes: 719,
      postal_votes: 2,
      total_votes: 721,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 23,
      candidate: "ABDUS SUBHAN TAPADAR",
      party: "Independent",
      evm_votes: 568,
      postal_votes: 3,
      total_votes: 571,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 24,
      candidate: "ROKIBUL HUSSAIN CHOUDHURY",
      party: "Independent",
      evm_votes: 539,
      postal_votes: 6,
      total_votes: 545,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 25,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 2851,
      postal_votes: 89,
      total_votes: 2940,
      percentage_of_votes: 0.26,
    },
  ],
  total: {
    evm_votes: 1140349,
    postal_votes: 6533,
    total_votes: 1146882,
  },
};
export const s038 = {
  cs_name: "Silchar",
  cs_shortname: "silchar",
  cs_code: "s038",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "PARIMAL SUKLABAIDYA",
      party: "Bharatiya Janata Party",
      evm_votes: 647572,
      postal_votes: 4833,
      total_votes: 652405,
      percentage_of_votes: 59.89,
    },
    {
      serial_no: 2,
      candidate: "SURYA KANTA SARKAR",
      party: "Indian National Congress",
      evm_votes: 386453,
      postal_votes: 1641,
      total_votes: 388094,
      percentage_of_votes: 35.62,
    },
    {
      serial_no: 3,
      candidate: "RADHESHYAM BISWAS",
      party: "All India Trinamool Congress",
      evm_votes: 20384,
      postal_votes: 109,
      total_votes: 20493,
      percentage_of_votes: 1.88,
    },
    {
      serial_no: 4,
      candidate: "RAJU DAS",
      party: "Independent",
      evm_votes: 4580,
      postal_votes: 17,
      total_votes: 4597,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 5,
      candidate: "PROBASH CHANDRA SARKAR",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 4494,
      postal_votes: 40,
      total_votes: 4534,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 6,
      candidate: "ANANTA MOHAN ROY",
      party: "Independent",
      evm_votes: 3113,
      postal_votes: 30,
      total_votes: 3143,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 7,
      candidate: "RAJIB DAS",
      party: "Bahujan Maha Party",
      evm_votes: 1843,
      postal_votes: 24,
      total_votes: 1867,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 8,
      candidate: "BARINDRA KUMAR DAS",
      party: "Bangali Nabanirman Sena",
      evm_votes: 1575,
      postal_votes: 18,
      total_votes: 1593,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12598,
      postal_votes: 102,
      total_votes: 12700,
      percentage_of_votes: 1.17,
    },
  ],
  total: {
    evm_votes: 1082612,
    postal_votes: 6814,
    total_votes: 1089426,
  },
};
export const s0310 = {
  cs_name: "Kaziranga",
  cs_shortname: "kaziranga",
  cs_code: "s0310",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "KAMAKHYA PRASAD TASA",
      party: "Bharatiya Janata Party",
      evm_votes: 892766,
      postal_votes: 4277,
      total_votes: 897043,
      percentage_of_votes: 55.04,
    },
    {
      serial_no: 2,
      candidate: "ROSELINA TIRKEY",
      party: "Indian National Congress",
      evm_votes: 646123,
      postal_votes: 1973,
      total_votes: 648096,
      percentage_of_votes: 39.76,
    },
    {
      serial_no: 3,
      candidate: "DILUWARA BEGUM CHOWDHURY",
      party: "Independent",
      evm_votes: 11719,
      postal_votes: 24,
      total_votes: 11743,
      percentage_of_votes: 0.72,
    },
    {
      serial_no: 4,
      candidate: "BINOD GOGOI",
      party: "Independent",
      evm_votes: 10625,
      postal_votes: 54,
      total_votes: 10679,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 5,
      candidate: "ANIMA DEKA GUPTA",
      party: "Voters Party International",
      evm_votes: 8641,
      postal_votes: 32,
      total_votes: 8673,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 6,
      candidate: "TRIDIV JYOTI BHUYAN",
      party: "Independent",
      evm_votes: 8091,
      postal_votes: 37,
      total_votes: 8128,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 7,
      candidate: "ABDUL HOQUE",
      party: "Independent",
      evm_votes: 6395,
      postal_votes: 46,
      total_votes: 6441,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 8,
      candidate: "JYOTISKA RANJAN GOSWAMI",
      party: "Independent",
      evm_votes: 4494,
      postal_votes: 44,
      total_votes: 4538,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 9,
      candidate: "SAILEN CH MALAKAR",
      party: "Bharatiya Gana Parishad",
      evm_votes: 3698,
      postal_votes: 22,
      total_votes: 3720,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 10,
      candidate: "SALIM AHMED",
      party: "Asom Jana Morcha",
      evm_votes: 3243,
      postal_votes: 23,
      total_votes: 3266,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 11,
      candidate: "SALEH AHMED MAZUMDAR",
      party: "Republican Party of India (Athawale)",
      evm_votes: 3160,
      postal_votes: 19,
      total_votes: 3179,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 24212,
      postal_votes: 219,
      total_votes: 24431,
      percentage_of_votes: 1.5,
    },
  ],
  total: {
    evm_votes: 1623167,
    postal_votes: 6770,
    total_votes: 1629937,
  },
};
export const s0311 = {
  cs_name: "Sonitpur",
  cs_shortname: "sonitpur",
  cs_code: "s0311",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "RANJIT DUTTA",
      party: "Bharatiya Janata Party",
      evm_votes: 771018,
      postal_votes: 4770,
      total_votes: 775788,
      percentage_of_votes: 60.21,
    },
    {
      serial_no: 2,
      candidate: "PREMLAL GANJU",
      party: "Indian National Congress",
      evm_votes: 412553,
      postal_votes: 1827,
      total_votes: 414380,
      percentage_of_votes: 32.16,
    },
    {
      serial_no: 3,
      candidate: "RISHIRAJ KAUNDINYA",
      party: "Aam Aadmi Party",
      evm_votes: 32771,
      postal_votes: 277,
      total_votes: 33048,
      percentage_of_votes: 2.56,
    },
    {
      serial_no: 4,
      candidate: "RAJU DEURI",
      party: "Bodoland Peoples Front",
      evm_votes: 19775,
      postal_votes: 117,
      total_votes: 19892,
      percentage_of_votes: 1.54,
    },
    {
      serial_no: 5,
      candidate: "PRADIP BHANDARI",
      party: "Independent",
      evm_votes: 8136,
      postal_votes: 69,
      total_votes: 8205,
      percentage_of_votes: 0.64,
    },
    {
      serial_no: 6,
      candidate: "KAMESWAR SWARGIARY",
      party: "Voters Party International",
      evm_votes: 7320,
      postal_votes: 62,
      total_votes: 7382,
      percentage_of_votes: 0.57,
    },
    {
      serial_no: 7,
      candidate: "RINKU ROY",
      party: "Gana Suraksha Party",
      evm_votes: 6780,
      postal_votes: 43,
      total_votes: 6823,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 8,
      candidate: "ALAM ALI",
      party: "Bahujan Maha Party",
      evm_votes: 4186,
      postal_votes: 16,
      total_votes: 4202,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 18535,
      postal_votes: 213,
      total_votes: 18748,
      percentage_of_votes: 1.46,
    },
  ],
  total: {
    evm_votes: 1281074,
    postal_votes: 7394,
    total_votes: 1288468,
  },
};
export const s0312 = {
  cs_name: "Lakhimpur",
  cs_shortname: "lakhimpur",
  cs_code: "s0312",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "PRADAN BARUAH",
      party: "Bharatiya Janata Party",
      evm_votes: 659307,
      postal_votes: 3815,
      total_votes: 663122,
      percentage_of_votes: 54.75,
    },
    {
      serial_no: 2,
      candidate: "UDAY SHANKAR HAZARIKA",
      party: "Indian National Congress",
      evm_votes: 458720,
      postal_votes: 3145,
      total_votes: 461865,
      percentage_of_votes: 38.13,
    },
    {
      serial_no: 3,
      candidate: "DHIREN KACHARI",
      party: "Communist Party of India",
      evm_votes: 19562,
      postal_votes: 69,
      total_votes: 19631,
      percentage_of_votes: 1.62,
    },
    {
      serial_no: 4,
      candidate: "GHANA KANTA CHUTIA",
      party: "All India Trinamool Congress",
      evm_votes: 14055,
      postal_votes: 142,
      total_votes: 14197,
      percentage_of_votes: 1.17,
    },
    {
      serial_no: 5,
      candidate: "BIKRAM RAMCHIARY",
      party: "Independent",
      evm_votes: 10218,
      postal_votes: 75,
      total_votes: 10293,
      percentage_of_votes: 0.85,
    },
    {
      serial_no: 6,
      candidate: "GOBIN BISWAKARMA",
      party: "Independent",
      evm_votes: 7461,
      postal_votes: 65,
      total_votes: 7526,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 7,
      candidate: "DEBA NATH PAIT",
      party: "Independent",
      evm_votes: 6143,
      postal_votes: 20,
      total_votes: 6163,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 8,
      candidate: "PALLAB PEGU",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 6051,
      postal_votes: 86,
      total_votes: 6137,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 9,
      candidate: "BIREN BAILUNG",
      party: "Voters Party International",
      evm_votes: 5329,
      postal_votes: 30,
      total_votes: 5359,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 16716,
      postal_votes: 205,
      total_votes: 16921,
      percentage_of_votes: 1.4,
    },
  ],
  total: {
    evm_votes: 1203562,
    postal_votes: 7652,
    total_votes: 1211214,
  },
};
export const s0313 = {
  cs_name: "Dibrugarh",
  cs_shortname: "dibrugarh",
  cs_code: "s0313",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SARBANANDA SONOWAL",
      party: "Bharatiya Janata Party",
      evm_votes: 691153,
      postal_votes: 2609,
      total_votes: 693762,
      percentage_of_votes: 54.27,
    },
    {
      serial_no: 2,
      candidate: "LURINJYOTI GOGOI",
      party: "Assam Jatiya Parishad",
      evm_votes: 412793,
      postal_votes: 1648,
      total_votes: 414441,
      percentage_of_votes: 32.42,
    },
    {
      serial_no: 3,
      candidate: "MANOJ DHANOWAR",
      party: "Aam Aadmi Party",
      evm_votes: 137618,
      postal_votes: 246,
      total_votes: 137864,
      percentage_of_votes: 10.78,
    },
    {
      serial_no: 4,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 32163,
      postal_votes: 92,
      total_votes: 32255,
      percentage_of_votes: 2.52,
    },
  ],
  total: {
    evm_votes: 1273727,
    postal_votes: 4595,
    total_votes: 1278322,
  },
};
export const s042 = {
  cs_name: "Paschim_Champaran",
  cs_shortname: "paschimchamparan",
  cs_code: "s042",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR.SANJAY JAISWAL",
      party: "Bharatiya Janata Party",
      evm_votes: 578959,
      postal_votes: 1462,
      total_votes: 580421,
      percentage_of_votes: 53.43,
    },
    {
      serial_no: 2,
      candidate: "MADAN MOHAN TIWARI",
      party: "Indian National Congress",
      evm_votes: 441363,
      postal_votes: 2490,
      total_votes: 443853,
      percentage_of_votes: 40.86,
    },
    {
      serial_no: 3,
      candidate: "SANJAY KUMAR",
      party: "Viro Ke Vir Indian Party",
      evm_votes: 13139,
      postal_votes: 6,
      total_votes: 13145,
      percentage_of_votes: 1.21,
    },
    {
      serial_no: 4,
      candidate: "UPENDRA RAM",
      party: "Bahujan Samaj Party",
      evm_votes: 10913,
      postal_votes: 49,
      total_votes: 10962,
      percentage_of_votes: 1.01,
    },
    {
      serial_no: 5,
      candidate: "MOHAMMAD SOAIB",
      party: "Independent",
      evm_votes: 8992,
      postal_votes: 2,
      total_votes: 8994,
      percentage_of_votes: 0.83,
    },
    {
      serial_no: 6,
      candidate: "RAUSHAN KUMAR SRIVASTAVA",
      party: "Independent",
      evm_votes: 8496,
      postal_votes: 9,
      total_votes: 8505,
      percentage_of_votes: 0.78,
    },
    {
      serial_no: 7,
      candidate: "MD. KALAM SAI",
      party: "Independent",
      evm_votes: 4922,
      postal_votes: 3,
      total_votes: 4925,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 8,
      candidate: "NAFISH AHMAD",
      party: "Independent",
      evm_votes: 4163,
      postal_votes: 0,
      total_votes: 4163,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11223,
      postal_votes: 65,
      total_votes: 11288,
      percentage_of_votes: 1.04,
    },
  ],
  total: {
    evm_votes: 1082170,
    postal_votes: 4086,
    total_votes: 1086256,
  },
};
export const s043 = {
  cs_name: "Purvi_Champaran",
  cs_shortname: "purvichamparan",
  cs_code: "s043",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "RADHA MOHAN SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 540598,
      postal_votes: 1595,
      total_votes: 542193,
      percentage_of_votes: 50.5,
    },
    {
      serial_no: 2,
      candidate: "DR RAJESH KUMAR",
      party: "Vikassheel Insaan Party",
      evm_votes: 451118,
      postal_votes: 2788,
      total_votes: 453906,
      percentage_of_votes: 42.28,
    },
    {
      serial_no: 3,
      candidate: "MOHAMMAD AJMER ALAM",
      party: "Independent",
      evm_votes: 13030,
      postal_votes: 17,
      total_votes: 13047,
      percentage_of_votes: 1.22,
    },
    {
      serial_no: 4,
      candidate: "VIJAY KUMAR SAHANI",
      party: "Rashtriya Jansambhavna Party",
      evm_votes: 8158,
      postal_votes: 27,
      total_votes: 8185,
      percentage_of_votes: 0.76,
    },
    {
      serial_no: 5,
      candidate: "RAJESH SINGH",
      party: "Independent",
      evm_votes: 7176,
      postal_votes: 6,
      total_votes: 7182,
      percentage_of_votes: 0.67,
    },
    {
      serial_no: 6,
      candidate: "GYANTI DEVI",
      party: "Prabuddha Republican Party",
      evm_votes: 6963,
      postal_votes: 45,
      total_votes: 7008,
      percentage_of_votes: 0.65,
    },
    {
      serial_no: 7,
      candidate: "PAWAN KUMAR",
      party: "Bhartiya Sarthak Party",
      evm_votes: 4895,
      postal_votes: 19,
      total_votes: 4914,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 8,
      candidate: "MUNESHWAR TIWARI",
      party: "Independent",
      evm_votes: 4017,
      postal_votes: 5,
      total_votes: 4022,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 9,
      candidate: "RAJESH KUMAR S/O GAGANLAL SAHNI",
      party: "Independent",
      evm_votes: 3930,
      postal_votes: 4,
      total_votes: 3934,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 10,
      candidate: "RAJESH KUMAR S/O VISHUN SAH",
      party: "Independent",
      evm_votes: 3489,
      postal_votes: 3,
      total_votes: 3492,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 11,
      candidate: "NAWAL KISHOR PRASAD",
      party: "Viro Ke Vir Indian Party",
      evm_votes: 3449,
      postal_votes: 15,
      total_votes: 3464,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 12,
      candidate: "NIKESH KUMAR",
      party: "Independent",
      evm_votes: 2539,
      postal_votes: 14,
      total_votes: 2553,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 19729,
      postal_votes: 59,
      total_votes: 19788,
      percentage_of_votes: 1.84,
    },
  ],
  total: {
    evm_votes: 1069091,
    postal_votes: 4597,
    total_votes: 1073688,
  },
};
export const s046 = {
  cs_name: "Madhubani",
  cs_shortname: "madhubani",
  cs_code: "s046",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ASHOK KUMAR YADAV",
      party: "Bharatiya Janata Party",
      evm_votes: 552705,
      postal_votes: 723,
      total_votes: 553428,
      percentage_of_votes: 53.85,
    },
    {
      serial_no: 2,
      candidate: "MD ALI ASHRAF FATMI",
      party: "Rashtriya Janata Dal",
      evm_votes: 400909,
      postal_votes: 574,
      total_votes: 401483,
      percentage_of_votes: 39.07,
    },
    {
      serial_no: 3,
      candidate: "SHIV BODHAN SAHU",
      party: "Independent",
      evm_votes: 13365,
      postal_votes: 5,
      total_votes: 13370,
      percentage_of_votes: 1.3,
    },
    {
      serial_no: 4,
      candidate: "BIKASH KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 7197,
      postal_votes: 26,
      total_votes: 7223,
      percentage_of_votes: 0.7,
    },
    {
      serial_no: 5,
      candidate: "PRIYE RANJAN",
      party: "Independent",
      evm_votes: 7046,
      postal_votes: 4,
      total_votes: 7050,
      percentage_of_votes: 0.69,
    },
    {
      serial_no: 6,
      candidate: "VAIDYANATH YADAV",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 4980,
      postal_votes: 3,
      total_votes: 4983,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 7,
      candidate: "SARFARAJ ALAM",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 4620,
      postal_votes: 3,
      total_votes: 4623,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 8,
      candidate: "MD WAQUAR SIDDIQUI",
      party: "All India Majlis-E-Ittehadul Muslimeen",
      evm_votes: 4492,
      postal_votes: 6,
      total_votes: 4498,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 9,
      candidate: "MOHAN SHARMA",
      party: "Rashtriya Jansambhavna Party",
      evm_votes: 4460,
      postal_votes: 4,
      total_votes: 4464,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 10,
      candidate: "ABU BAKAR RAHMANI",
      party: "Country Citizen Party",
      evm_votes: 2095,
      postal_votes: 2,
      total_votes: 2097,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 11,
      candidate: "UDAY MANDAL",
      party: "Samata Party",
      evm_votes: 1998,
      postal_votes: 9,
      total_votes: 2007,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 12,
      candidate: "KUL BHUSHAN PRASAD",
      party: "Loktantrik Lok Rajyam Party",
      evm_votes: 1780,
      postal_votes: 3,
      total_votes: 1783,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 20691,
      postal_votes: 28,
      total_votes: 20719,
      percentage_of_votes: 2.02,
    },
  ],
  total: {
    evm_votes: 1026338,
    postal_votes: 1390,
    total_votes: 1027728,
  },
};
export const s049 = {
  cs_name: "Araria",
  cs_shortname: "araria",
  cs_code: "s049",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "PRADEEP KUMAR SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 599118,
      postal_votes: 1028,
      total_votes: 600146,
      percentage_of_votes: 47.91,
    },
    {
      serial_no: 2,
      candidate: "SHAHNAWAZ",
      party: "Rashtriya Janata Dal",
      evm_votes: 578904,
      postal_votes: 1148,
      total_votes: 580052,
      percentage_of_votes: 46.31,
    },
    {
      serial_no: 3,
      candidate: "SHATRUGHAN PRASAD SUMAN",
      party: "Independent",
      evm_votes: 13697,
      postal_votes: 49,
      total_votes: 13746,
      percentage_of_votes: 1.1,
    },
    {
      serial_no: 4,
      candidate: "MD GHOUSUL AZAM",
      party: "Bahujan Samaj Party",
      evm_votes: 12672,
      postal_votes: 18,
      total_votes: 12690,
      percentage_of_votes: 1.01,
    },
    {
      serial_no: 5,
      candidate: "MD. MOBINUL HAQUE",
      party: "Independent",
      evm_votes: 12005,
      postal_votes: 3,
      total_votes: 12008,
      percentage_of_votes: 0.96,
    },
    {
      serial_no: 6,
      candidate: "MD. ISMAIL",
      party: "Bharatiya Momin Front",
      evm_votes: 7353,
      postal_votes: 1,
      total_votes: 7354,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 7,
      candidate: "AKHILESH KUMAR",
      party: "Independent",
      evm_votes: 5086,
      postal_votes: 34,
      total_votes: 5120,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 8,
      candidate: "MUSHTAK ALAM",
      party: "Independent",
      evm_votes: 4897,
      postal_votes: 2,
      total_votes: 4899,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 9,
      candidate: "ZAWED AKHTAR",
      party: "The National Road Map Party of India",
      evm_votes: 3037,
      postal_votes: 4,
      total_votes: 3041,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13438,
      postal_votes: 66,
      total_votes: 13504,
      percentage_of_votes: 1.08,
    },
  ],
  total: {
    evm_votes: 1250207,
    postal_votes: 2353,
    total_votes: 1252560,
  },
};
export const s0414 = {
  cs_name: "Darbhanga",
  cs_shortname: "darbhanga",
  cs_code: "s0414",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "GOPAL JEE THAKUR",
      party: "Bharatiya Janata Party",
      evm_votes: 565706,
      postal_votes: 924,
      total_votes: 566630,
      percentage_of_votes: 55.33,
    },
    {
      serial_no: 2,
      candidate: "LALIT KUMAR YADAV",
      party: "Rashtriya Janata Dal",
      evm_votes: 387408,
      postal_votes: 1066,
      total_votes: 388474,
      percentage_of_votes: 37.93,
    },
    {
      serial_no: 3,
      candidate: "SAROJ CHAUDHARY",
      party: "Mithilanchal Mukti Morcha",
      evm_votes: 18018,
      postal_votes: 7,
      total_votes: 18025,
      percentage_of_votes: 1.76,
    },
    {
      serial_no: 4,
      candidate: "DURGANANDA MAHAVIR NAYAK",
      party: "Bahujan Samaj Party",
      evm_votes: 8259,
      postal_votes: 9,
      total_votes: 8268,
      percentage_of_votes: 0.81,
    },
    {
      serial_no: 5,
      candidate: "MITHILESH MAHTO",
      party: "Independent",
      evm_votes: 6983,
      postal_votes: 9,
      total_votes: 6992,
      percentage_of_votes: 0.68,
    },
    {
      serial_no: 6,
      candidate: "KISHOR KUMAR DAS",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 5716,
      postal_votes: 5,
      total_votes: 5721,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 7,
      candidate: "RAJNISH KUMAR",
      party: "Jantantra Awaj Party",
      evm_votes: 3634,
      postal_votes: 4,
      total_votes: 3638,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 8,
      candidate: "RANJEET KUMAR RAM",
      party: "Wazib Adhikar Party",
      evm_votes: 2529,
      postal_votes: 3,
      total_votes: 2532,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 23881,
      postal_votes: 23,
      total_votes: 23904,
      percentage_of_votes: 2.33,
    },
  ],
  total: {
    evm_votes: 1022134,
    postal_votes: 2050,
    total_votes: 1024184,
  },
};
export const s0415 = {
  cs_name: "Muzaffarpur",
  cs_shortname: "muzaffarpur",
  cs_code: "s0415",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAJ BHUSHAN CHOUDHARY",
      party: "Bharatiya Janata Party",
      evm_votes: 618569,
      postal_votes: 1180,
      total_votes: 619749,
      percentage_of_votes: 55.71,
    },
    {
      serial_no: 2,
      candidate: "AJAY NISHAD",
      party: "Indian National Congress",
      evm_votes: 383759,
      postal_votes: 1063,
      total_votes: 384822,
      percentage_of_votes: 34.59,
    },
    {
      serial_no: 3,
      candidate: "ALOK KUMAR SINH",
      party: "Independent",
      evm_votes: 17225,
      postal_votes: 0,
      total_votes: 17225,
      percentage_of_votes: 1.55,
    },
    {
      serial_no: 4,
      candidate: "AWADHESH PRASAD SINGH",
      party: "Independent",
      evm_votes: 15617,
      postal_votes: 1,
      total_votes: 15618,
      percentage_of_votes: 1.4,
    },
    {
      serial_no: 5,
      candidate: "JITENDRA KUMAR",
      party: "Independent",
      evm_votes: 8692,
      postal_votes: 8,
      total_votes: 8700,
      percentage_of_votes: 0.78,
    },
    {
      serial_no: 6,
      candidate: "NILIMA KUMARI",
      party: "Independent",
      evm_votes: 7309,
      postal_votes: 1,
      total_votes: 7310,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 7,
      candidate: "VIJAYESH KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 6530,
      postal_votes: 17,
      total_votes: 6547,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 8,
      candidate: "AJAY SAHNI",
      party: "Independent",
      evm_votes: 5157,
      postal_votes: 1,
      total_votes: 5158,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 9,
      candidate: "SUNITA KUMARI",
      party: "Bajjikanchal Vikas Party",
      evm_votes: 4791,
      postal_votes: 1,
      total_votes: 4792,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 10,
      candidate: "MOHAMMAD ANZARUL HASAN",
      party: "All India Majlis-E-Ittehadul Muslimeen",
      evm_votes: 4121,
      postal_votes: 10,
      total_votes: 4131,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 11,
      candidate: "MD HAIDAR",
      party: "Independent",
      evm_votes: 3897,
      postal_votes: 0,
      total_votes: 3897,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 12,
      candidate: "MUKESH KUMAR",
      party: "Independent",
      evm_votes: 3225,
      postal_votes: 0,
      total_votes: 3225,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 13,
      candidate: "SUDHIR KUMAR OJHA",
      party: "Bhartiya Sarthak Party",
      evm_votes: 2890,
      postal_votes: 3,
      total_votes: 2893,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 14,
      candidate: "SABINA KHATOON",
      party: "Indian National League",
      evm_votes: 2445,
      postal_votes: 8,
      total_votes: 2453,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 15,
      candidate: "SANDIP KUMAR",
      party: "Jantantra Awaj Party",
      evm_votes: 1968,
      postal_votes: 3,
      total_votes: 1971,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 16,
      candidate: "ARVIND KUMAR CHAUDHARY",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1852,
      postal_votes: 5,
      total_votes: 1857,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 17,
      candidate: "AJITANSH GAUR",
      party: "The Agrani Party",
      evm_votes: 1840,
      postal_votes: 3,
      total_votes: 1843,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 18,
      candidate: "SANJAY KUMAR",
      party: "Independent",
      evm_votes: 1811,
      postal_votes: 1,
      total_votes: 1812,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 19,
      candidate: "BASKIT KUMAR SHARMA",
      party: "Independent",
      evm_votes: 1778,
      postal_votes: 1,
      total_votes: 1779,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 20,
      candidate: "SONELAL PASWAN",
      party: "Independent",
      evm_votes: 1730,
      postal_votes: 1,
      total_votes: 1731,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 21,
      candidate: "MANORANJAN KUMAR",
      party: "Independent",
      evm_votes: 1595,
      postal_votes: 3,
      total_votes: 1598,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 22,
      candidate: "NAND KISHORE SHARMA",
      party: "Apna Kisan Party",
      evm_votes: 1521,
      postal_votes: 1,
      total_votes: 1522,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 23,
      candidate: "ABHAY KUMAR",
      party: "Samata Party",
      evm_votes: 1170,
      postal_votes: 4,
      total_votes: 1174,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 24,
      candidate: "PRABHAKAR RANJAN",
      party: "Ekam Sanatan Bharat Dal",
      evm_votes: 1115,
      postal_votes: 1,
      total_votes: 1116,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 25,
      candidate: "RAJU SINGH",
      party: "Independent",
      evm_votes: 984,
      postal_votes: 0,
      total_votes: 984,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 26,
      candidate: "SAROJ KUMAR CHAUDHARY",
      party: "Independent",
      evm_votes: 965,
      postal_votes: 0,
      total_votes: 965,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 27,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7539,
      postal_votes: 49,
      total_votes: 7588,
      percentage_of_votes: 0.68,
    },
  ],
  total: {
    evm_votes: 1110095,
    postal_votes: 2365,
    total_votes: 1112460,
  },
};
export const s0419 = {
  cs_name: "Maharajganj_bihar",
  cs_shortname: "maharajganj_bihar",
  cs_code: "s0419",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "JANARDAN SINGH SIGRIWAL",
      party: "Bharatiya Janata Party",
      evm_votes: 527974,
      postal_votes: 1559,
      total_votes: 529533,
      percentage_of_votes: 52.22,
    },
    {
      serial_no: 2,
      candidate: "AAKASH KUMAR SINGH",
      party: "Indian National Congress",
      evm_votes: 425887,
      postal_votes: 995,
      total_votes: 426882,
      percentage_of_votes: 42.09,
    },
    {
      serial_no: 3,
      candidate: "TRIBHUWAN RAM",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 14830,
      postal_votes: 8,
      total_votes: 14838,
      percentage_of_votes: 1.46,
    },
    {
      serial_no: 4,
      candidate: "MADHUSUDAN SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 13359,
      postal_votes: 43,
      total_votes: 13402,
      percentage_of_votes: 1.32,
    },
    {
      serial_no: 5,
      candidate: "AKHILESHWAR PRASAD SINGH",
      party: "All India Majlis-E-Ittehadul Muslimeen",
      evm_votes: 7750,
      postal_votes: 17,
      total_votes: 7767,
      percentage_of_votes: 0.77,
    },
    {
      serial_no: 6,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 21662,
      postal_votes: 25,
      total_votes: 21687,
      percentage_of_votes: 2.14,
    },
  ],
  total: {
    evm_votes: 1011462,
    postal_votes: 2647,
    total_votes: 1014109,
  },
};
export const s0420 = {
  cs_name: "Saran",
  cs_shortname: "saran",
  cs_code: "s0420",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAJIV PRATAP RUDY",
      party: "Bharatiya Janata Party",
      evm_votes: 470234,
      postal_votes: 1518,
      total_votes: 471752,
      percentage_of_votes: 46.18,
    },
    {
      serial_no: 2,
      candidate: "ROHINI ACHARYA",
      party: "Rashtriya Janata Dal",
      evm_votes: 456763,
      postal_votes: 1328,
      total_votes: 458091,
      percentage_of_votes: 44.84,
    },
    {
      serial_no: 3,
      candidate: "LAXAMAN PRAO YADAV",
      party: "Independent",
      evm_votes: 22041,
      postal_votes: 2,
      total_votes: 22043,
      percentage_of_votes: 2.16,
    },
    {
      serial_no: 4,
      candidate: "SHAK NAUSHAD",
      party: "Independent",
      evm_votes: 16101,
      postal_votes: 2,
      total_votes: 16103,
      percentage_of_votes: 1.58,
    },
    {
      serial_no: 5,
      candidate: "AVINASH KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 14708,
      postal_votes: 62,
      total_votes: 14770,
      percentage_of_votes: 1.45,
    },
    {
      serial_no: 6,
      candidate: "RAGHWENDRA PRATAP SINGH",
      party: "Independent",
      evm_votes: 5165,
      postal_votes: 2,
      total_votes: 5167,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 7,
      candidate: "GAJENDRA PRASAD CHAURSIYA",
      party: "Janhit Kisan Party",
      evm_votes: 5025,
      postal_votes: 5,
      total_votes: 5030,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 8,
      candidate: "SHATRUDHAN TIWARY",
      party: "Bhartiya Sarthak Party",
      evm_votes: 3661,
      postal_votes: 26,
      total_votes: 3687,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 9,
      candidate: "MOHAMAD SALIM",
      party: "Independent",
      evm_votes: 3148,
      postal_votes: 3,
      total_votes: 3151,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 10,
      candidate: "ARTI KUMARI",
      party: "Independent",
      evm_votes: 3009,
      postal_votes: 14,
      total_votes: 3023,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 11,
      candidate: "PRABHAT KUMAR",
      party: "Independent",
      evm_votes: 2302,
      postal_votes: 4,
      total_votes: 2306,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 12,
      candidate: "BARUN KUMAR DAS",
      party: "Gana Suraksha Party",
      evm_votes: 1770,
      postal_votes: 5,
      total_votes: 1775,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 13,
      candidate: "GYANI KUMAR SHARMA",
      party: "Bharatiya Ekta Dal",
      evm_votes: 1699,
      postal_votes: 5,
      total_votes: 1704,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 14,
      candidate: "RAJESH KUSHWAHA",
      party: "Bhartiya Lok Chetna Party",
      evm_votes: 1486,
      postal_votes: 3,
      total_votes: 1489,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11382,
      postal_votes: 35,
      total_votes: 11417,
      percentage_of_votes: 1.12,
    },
  ],
  total: {
    evm_votes: 1018494,
    postal_votes: 3014,
    total_votes: 1021508,
  },
};
export const s0422 = {
  cs_name: "Ujiarpur",
  cs_shortname: "ujiarpur",
  cs_code: "s0422",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "NITYANAND RAI",
      party: "Bharatiya Janata Party",
      evm_votes: 515159,
      postal_votes: 806,
      total_votes: 515965,
      percentage_of_votes: 49.51,
    },
    {
      serial_no: 2,
      candidate: "ALOK KUMAR MEHTA",
      party: "Rashtriya Janata Dal",
      evm_votes: 454870,
      postal_votes: 993,
      total_votes: 455863,
      percentage_of_votes: 43.75,
    },
    {
      serial_no: 3,
      candidate: "SANJAY PASWAN",
      party: "Independent",
      evm_votes: 16961,
      postal_votes: 2,
      total_votes: 16963,
      percentage_of_votes: 1.63,
    },
    {
      serial_no: 4,
      candidate: "MOHAN KUMAR MAURYA",
      party: "Bahujan Samaj Party",
      evm_votes: 7081,
      postal_votes: 20,
      total_votes: 7101,
      percentage_of_votes: 0.68,
    },
    {
      serial_no: 5,
      candidate: "RAKESH KUMAR",
      party: "Independent",
      evm_votes: 5953,
      postal_votes: 4,
      total_votes: 5957,
      percentage_of_votes: 0.57,
    },
    {
      serial_no: 6,
      candidate: "SANTHOSH RAI",
      party: "Rashtriya Jansambhavna Party",
      evm_votes: 4423,
      postal_votes: 3,
      total_votes: 4426,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 7,
      candidate: "NARENDRA GIRI",
      party: "Independent",
      evm_votes: 3150,
      postal_votes: 1,
      total_votes: 3151,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 8,
      candidate: "KISHOR KUMAR",
      party: "Independent",
      evm_votes: 2209,
      postal_votes: 0,
      total_votes: 2209,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 9,
      candidate: "AMARESH RAY",
      party: "Independent",
      evm_votes: 2140,
      postal_votes: 3,
      total_votes: 2143,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 10,
      candidate: "ANSHU KUMAR",
      party: "Subhashwadi Bhartiya Samajwadi Party (Subhas Party)",
      evm_votes: 1749,
      postal_votes: 7,
      total_votes: 1756,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 11,
      candidate: "RAMPUKAR RAY",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1299,
      postal_votes: 7,
      total_votes: 1306,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 12,
      candidate: "NIKKI JHA",
      party: "Jagrook Janta Party",
      evm_votes: 1006,
      postal_votes: 14,
      total_votes: 1020,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 13,
      candidate: "MANOJ KUMAR",
      party: "Janta Raj Vikas Party",
      evm_votes: 937,
      postal_votes: 2,
      total_votes: 939,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 23246,
      postal_votes: 23,
      total_votes: 23269,
      percentage_of_votes: 2.23,
    },
  ],
  total: {
    evm_votes: 1040183,
    postal_votes: 1885,
    total_votes: 1042068,
  },
};
export const s0424 = {
  cs_name: "Begusarai",
  cs_shortname: "begusarai",
  cs_code: "s0424",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "GIRIRAJ SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 646912,
      postal_votes: 2419,
      total_votes: 649331,
      percentage_of_votes: 50.15,
    },
    {
      serial_no: 2,
      candidate: "ABDHESH KUMAR ROY",
      party: "Communist Party of India",
      evm_votes: 564310,
      postal_votes: 3541,
      total_votes: 567851,
      percentage_of_votes: 43.86,
    },
    {
      serial_no: 3,
      candidate: "INDRAJEET KUMAR ROY",
      party: "Independent",
      evm_votes: 12947,
      postal_votes: 6,
      total_votes: 12953,
      percentage_of_votes: 1,
    },
    {
      serial_no: 4,
      candidate: "RAJNISH KUMAR MUKHIYA",
      party: "Rashtriya Jansambhavna Party",
      evm_votes: 10846,
      postal_votes: 26,
      total_votes: 10872,
      percentage_of_votes: 0.84,
    },
    {
      serial_no: 5,
      candidate: "ADV CHANDAN KUMAR DAS",
      party: "Bahujan Samaj Party",
      evm_votes: 8362,
      postal_votes: 91,
      total_votes: 8453,
      percentage_of_votes: 0.65,
    },
    {
      serial_no: 6,
      candidate: "RAM BADAN RAI",
      party: "Karpoori Janta Dal",
      evm_votes: 6599,
      postal_votes: 45,
      total_votes: 6644,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 7,
      candidate: "MD SHAHNAWAZ HASSAN",
      party: "Independent",
      evm_votes: 6144,
      postal_votes: 8,
      total_votes: 6152,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 8,
      candidate: "ARUN KUMAR",
      party: "Independent",
      evm_votes: 4030,
      postal_votes: 15,
      total_votes: 4045,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 9,
      candidate: "RAJ KUMAR SAH",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 3329,
      postal_votes: 21,
      total_votes: 3350,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 10,
      candidate: "RAMUDGAR",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2633,
      postal_votes: 7,
      total_votes: 2640,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 22283,
      postal_votes: 99,
      total_votes: 22382,
      percentage_of_votes: 1.73,
    },
  ],
  total: {
    evm_votes: 1288395,
    postal_votes: 6278,
    total_votes: 1294673,
  },
};
export const s0430 = {
  cs_name: "Patna_Sahib",
  cs_shortname: "patnasahib",
  cs_code: "s0430",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAVI SHANKAR PRASAD",
      party: "Bharatiya Janata Party",
      evm_votes: 587212,
      postal_votes: 1058,
      total_votes: 588270,
      percentage_of_votes: 54.7,
    },
    {
      serial_no: 2,
      candidate: "ANSHUL AVIJIT",
      party: "Indian National Congress",
      evm_votes: 433818,
      postal_votes: 606,
      total_votes: 434424,
      percentage_of_votes: 40.39,
    },
    {
      serial_no: 3,
      candidate: "RAKESH SHARMA",
      party: "Independent",
      evm_votes: 9942,
      postal_votes: 1,
      total_votes: 9943,
      percentage_of_votes: 0.92,
    },
    {
      serial_no: 4,
      candidate: "NEERAJ KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 5912,
      postal_votes: 16,
      total_votes: 5928,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 5,
      candidate: "AWADHESH PRASAD",
      party: "Independent",
      evm_votes: 4973,
      postal_votes: 1,
      total_votes: 4974,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 6,
      candidate: "ENG UMESH RAJAK",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 4664,
      postal_votes: 8,
      total_votes: 4672,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 7,
      candidate: "SANJAY KUMAR URF SANJAY BALMIKI",
      party: "Independent",
      evm_votes: 4643,
      postal_votes: 0,
      total_votes: 4643,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 8,
      candidate: "DHARMVIR KUMAR BHASKAR",
      party: "Independent",
      evm_votes: 2415,
      postal_votes: 1,
      total_votes: 2416,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 9,
      candidate: "AMIT KUMAR ALBELA",
      party: "Independent",
      evm_votes: 2050,
      postal_votes: 3,
      total_votes: 2053,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 10,
      candidate: "MAHESH KUMAR",
      party: "Samata Party",
      evm_votes: 2003,
      postal_votes: 0,
      total_votes: 2003,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 11,
      candidate: "DHANJAY KUMAR",
      party: "Rashtriya Jansambhavna Party",
      evm_votes: 1969,
      postal_votes: 2,
      total_votes: 1971,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 12,
      candidate: "GULAB PRASAD",
      party: "Loktantrik Samajwadi Party",
      evm_votes: 1923,
      postal_votes: 2,
      total_votes: 1925,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 13,
      candidate: "SUMIT RANJAN SINHA",
      party: "Akhand Bharat Janpriya Party",
      evm_votes: 1715,
      postal_votes: 1,
      total_votes: 1716,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 14,
      candidate: "MAHBOOB ALAM ANSARI",
      party: "Bharatiya Momin Front",
      evm_votes: 1635,
      postal_votes: 3,
      total_votes: 1638,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 15,
      candidate: "DR RAKESH DUTTA MISHRA",
      party: "Bharatiya Jan Kranti Dal (Democratic)",
      evm_votes: 1238,
      postal_votes: 2,
      total_votes: 1240,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 16,
      candidate: "SAROJ KUMAR SUMAN",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1078,
      postal_votes: 0,
      total_votes: 1078,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 17,
      candidate: "SHAHID ALAM",
      party: "Jantantra Awaj Party",
      evm_votes: 1040,
      postal_votes: 3,
      total_votes: 1043,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 18,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5532,
      postal_votes: 27,
      total_votes: 5559,
      percentage_of_votes: 0.52,
    },
  ],
  total: {
    evm_votes: 1073762,
    postal_votes: 1734,
    total_votes: 1075496,
  },
};
export const s0439 = {
  cs_name: "Nawada",
  cs_shortname: "nawada",
  cs_code: "s0439",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "VIVEK THAKUR",
      party: "Bharatiya Janata Party",
      evm_votes: 408537,
      postal_votes: 2071,
      total_votes: 410608,
      percentage_of_votes: 47.2,
    },
    {
      serial_no: 2,
      candidate: "SHRAWAN KUMAR",
      party: "Rashtriya Janata Dal",
      evm_votes: 340562,
      postal_votes: 2376,
      total_votes: 342938,
      percentage_of_votes: 39.42,
    },
    {
      serial_no: 3,
      candidate: "BINOD YADAV",
      party: "Independent",
      evm_votes: 39391,
      postal_votes: 128,
      total_votes: 39519,
      percentage_of_votes: 4.54,
    },
    {
      serial_no: 4,
      candidate: "GUNJAN KUMAR",
      party: "Independent",
      evm_votes: 29444,
      postal_votes: 238,
      total_votes: 29682,
      percentage_of_votes: 3.41,
    },
    {
      serial_no: 5,
      candidate: "RANJIT KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 16240,
      postal_votes: 76,
      total_votes: 16316,
      percentage_of_votes: 1.88,
    },
    {
      serial_no: 6,
      candidate: "ANAND KUMAR VERMA",
      party: "Bharat Jan Jagran Dal",
      evm_votes: 7727,
      postal_votes: 9,
      total_votes: 7736,
      percentage_of_votes: 0.89,
    },
    {
      serial_no: 7,
      candidate: "GAUTAM KUMAR BABLOO",
      party: "Bhagidari Party(P)",
      evm_votes: 5886,
      postal_votes: 3,
      total_votes: 5889,
      percentage_of_votes: 0.68,
    },
    {
      serial_no: 8,
      candidate: "GANAURI PANDIT",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 4558,
      postal_votes: 32,
      total_votes: 4590,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12555,
      postal_votes: 37,
      total_votes: 12592,
      percentage_of_votes: 1.45,
    },
  ],
  total: {
    evm_votes: 864900,
    postal_votes: 4970,
    total_votes: 869870,
  },
};
export const s051 = {
  cs_name: "North_Goa",
  cs_shortname: "northgoa",
  cs_code: "s051",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SHRIPAD YESSO NAIK",
      party: "Bharatiya Janata Party",
      evm_votes: 253812,
      postal_votes: 3514,
      total_votes: 257326,
      percentage_of_votes: 56.43,
    },
    {
      serial_no: 2,
      candidate: "RAMAKANT KHALAP",
      party: "Indian National Congress",
      evm_votes: 140191,
      postal_votes: 1120,
      total_votes: 141311,
      percentage_of_votes: 30.99,
    },
    {
      serial_no: 3,
      candidate: "TUKARAM BHARAT PARAB",
      party: "Revolutionary Goans Party",
      evm_votes: 45460,
      postal_votes: 233,
      total_votes: 45693,
      percentage_of_votes: 10.02,
    },
    {
      serial_no: 4,
      candidate: "MILAN R VAINGANKAR",
      party: "Bahujan Samaj Party",
      evm_votes: 1600,
      postal_votes: 25,
      total_votes: 1625,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 5,
      candidate: "MR SAKHARAM NAIK",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 1404,
      postal_votes: 13,
      total_votes: 1417,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 6,
      candidate: "SHAKEEL JAMAL SHAIKH",
      party: "Independent",
      evm_votes: 800,
      postal_votes: 7,
      total_votes: 807,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 7,
      candidate: "THOMAS AUGUSTINE FERNANDES",
      party: "Independent",
      evm_votes: 746,
      postal_votes: 13,
      total_votes: 759,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 8,
      candidate: "ADV VISHAL NAIK",
      party: "Independent",
      evm_votes: 744,
      postal_votes: 8,
      total_votes: 752,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6297,
      postal_votes: 31,
      total_votes: 6328,
      percentage_of_votes: 1.39,
    },
  ],
  total: {
    evm_votes: 451054,
    postal_votes: 4964,
    total_votes: 456018,
  },
};
export const s061 = {
  cs_name: "Kachchh",
  cs_shortname: "kachchh",
  cs_code: "s061",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "CHAVDA VINOD LAKHAMSHI",
      party: "Bharatiya Janata Party",
      evm_votes: 656979,
      postal_votes: 2595,
      total_votes: 659574,
      percentage_of_votes: 60.23,
    },
    {
      serial_no: 2,
      candidate: "NITESH PARBATBHAI LALAN (MATANG)",
      party: "Indian National Congress",
      evm_votes: 389384,
      postal_votes: 1408,
      total_votes: 390792,
      percentage_of_votes: 35.68,
    },
    {
      serial_no: 3,
      candidate: "VIJAY BHACHRA",
      party: "Bahujan Samaj Party",
      evm_votes: 6075,
      postal_votes: 36,
      total_votes: 6111,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 4,
      candidate: "VANAZARA HIRABEN DALPATBHAI",
      party: "Independent",
      evm_votes: 5145,
      postal_votes: 6,
      total_votes: 5151,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 5,
      candidate: "BABULAL LADHA CHAVDA",
      party: "Independent",
      evm_votes: 3348,
      postal_votes: 6,
      total_votes: 3354,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 6,
      candidate: "KAVITABEN DINESHBHAI MACHCHHOYA",
      party: "Independent",
      evm_votes: 3342,
      postal_votes: 24,
      total_votes: 3366,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 7,
      candidate: "DEVABHAI MITHABHAI GOHIL",
      party: "Rashtriya Power Party",
      evm_votes: 2087,
      postal_votes: 14,
      total_votes: 2101,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 8,
      candidate: "SHAMLIA VIRJI CHAKU",
      party: "Hindvi Swarajyay Dal",
      evm_votes: 1560,
      postal_votes: 13,
      total_votes: 1573,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 9,
      candidate: "ARVIND ASHOK SANGHELA",
      party: "Gujarat Sarva Samaj Party",
      evm_votes: 1509,
      postal_votes: 17,
      total_votes: 1526,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "BOCHIYA BHIMJI BHIKHA",
      party: "Sarva Samaj Janata Party",
      evm_votes: 1491,
      postal_votes: 6,
      total_votes: 1497,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "RAMJIBHAI JAKHUBHAI DAFDA",
      party: "Right to Recall Party",
      evm_votes: 1357,
      postal_votes: 13,
      total_votes: 1370,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 18604,
      postal_votes: 138,
      total_votes: 18742,
      percentage_of_votes: 1.71,
    },
  ],
  total: {
    evm_votes: 1090881,
    postal_votes: 4276,
    total_votes: 1095157,
  },
};
export const s063 = {
  cs_name: "Patan",
  cs_shortname: "patan",
  cs_code: "s063",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DABHI BHARATSINHJI SHANKARJI",
      party: "Bharatiya Janata Party",
      evm_votes: 585591,
      postal_votes: 6356,
      total_votes: 591947,
      percentage_of_votes: 49.61,
    },
    {
      serial_no: 2,
      candidate: "CHANDANJI TALAJI THAKOR",
      party: "Indian National Congress",
      evm_votes: 555134,
      postal_votes: 4937,
      total_votes: 560071,
      percentage_of_votes: 46.94,
    },
    {
      serial_no: 3,
      candidate: "BALVANT CHHATRALIYA",
      party: "Bahujan Samaj Party",
      evm_votes: 5811,
      postal_votes: 54,
      total_votes: 5865,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 4,
      candidate: "SOYAB HASAM BHORANIYA",
      party: "Independent",
      evm_votes: 5473,
      postal_votes: 1,
      total_votes: 5474,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 5,
      candidate: "THAKOR KISHANBHAI KALUBHAI",
      party: "Independent",
      evm_votes: 2909,
      postal_votes: 7,
      total_votes: 2916,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 6,
      candidate: "SHARMA RAKESHBHAI",
      party: "Rashtriya Samaj Paksha",
      evm_votes: 2765,
      postal_votes: 9,
      total_votes: 2774,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 7,
      candidate: "GHAGHA MASIHULLAH ABDULHAMID",
      party: "Social Democratic Party Of India",
      evm_votes: 2305,
      postal_votes: 7,
      total_votes: 2312,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 8,
      candidate: "CHANDURA DHANJIBHAI LAXAMANBHAI",
      party: "Independent",
      evm_votes: 2135,
      postal_votes: 25,
      total_votes: 2160,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 9,
      candidate: "ABDULHAQ ISMAIL NEDARIYA",
      party: "Independent",
      evm_votes: 1510,
      postal_votes: 14,
      total_votes: 1524,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 10,
      candidate: "ABDULKUDDUS",
      party: "Independent",
      evm_votes: 1358,
      postal_votes: 2,
      total_votes: 1360,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 16382,
      postal_votes: 340,
      total_votes: 16722,
      percentage_of_votes: 1.4,
    },
  ],
  total: {
    evm_votes: 1181373,
    postal_votes: 11752,
    total_votes: 1193125,
  },
};
export const s064 = {
  cs_name: "Mahesana",
  cs_shortname: "mahesana",
  cs_code: "s064",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "HARIBHAI PATEL",
      party: "Bharatiya Janata Party",
      evm_votes: 675614,
      postal_votes: 10792,
      total_votes: 686406,
      percentage_of_votes: 63.74,
    },
    {
      serial_no: 2,
      candidate: "RAMJI THAKOR",
      party: "Indian National Congress",
      evm_votes: 352578,
      postal_votes: 5782,
      total_votes: 358360,
      percentage_of_votes: 33.28,
    },
    {
      serial_no: 3,
      candidate: "AMRUTLAL MAKWANA",
      party: "Bahujan Samaj Party",
      evm_votes: 9710,
      postal_votes: 164,
      total_votes: 9874,
      percentage_of_votes: 0.92,
    },
    {
      serial_no: 4,
      candidate: "CHAUHAN PRAKASHKUMAR TRIBHOVANDAS",
      party: "Akhila Vijaya Party",
      evm_votes: 3866,
      postal_votes: 16,
      total_votes: 3882,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 5,
      candidate: "PATEL MANUBHAI SHANKARLAL",
      party: "Independent",
      evm_votes: 3597,
      postal_votes: 75,
      total_votes: 3672,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 6,
      candidate: "ZALA VIKRAMSINH BANESINH",
      party: "Rashtra Nirman Party",
      evm_votes: 2949,
      postal_votes: 58,
      total_votes: 3007,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 7,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11002,
      postal_votes: 624,
      total_votes: 11626,
      percentage_of_votes: 1.08,
    },
  ],
  total: {
    evm_votes: 1059316,
    postal_votes: 17511,
    total_votes: 1076827,
  },
};
export const s065 = {
  cs_name: "Sabarkantha",
  cs_shortname: "sabarkantha",
  cs_code: "s065",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SHOBHANABEN MAHENDRASINH BARAIYA",
      party: "Bharatiya Janata Party",
      evm_votes: 670929,
      postal_votes: 6389,
      total_votes: 677318,
      percentage_of_votes: 53.36,
    },
    {
      serial_no: 2,
      candidate: "CHAUDHARI TUSHAR AMARSINH",
      party: "Indian National Congress",
      evm_votes: 515902,
      postal_votes: 5734,
      total_votes: 521636,
      percentage_of_votes: 41.09,
    },
    {
      serial_no: 3,
      candidate: "PARMAR RAMESHCHANDRA NANJIBHAI",
      party: "Bahujan Samaj Party",
      evm_votes: 9850,
      postal_votes: 117,
      total_votes: 9967,
      percentage_of_votes: 0.79,
    },
    {
      serial_no: 4,
      candidate: "SOLANKI CHHAGANBHAI KEVLABHAI",
      party: "Independent",
      evm_votes: 7539,
      postal_votes: 12,
      total_votes: 7551,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 5,
      candidate: "VIJAYSINH NAVALSINH CHAUHAN",
      party: "Independent",
      evm_votes: 6692,
      postal_votes: 29,
      total_votes: 6721,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 6,
      candidate: "BHAVNABA NARENDRASINH PARMAR",
      party: "Independent",
      evm_votes: 5365,
      postal_votes: 94,
      total_votes: 5459,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 7,
      candidate: "ANILKUMAR NIRANJANKUMAR MUNDADA",
      party: "Log Party",
      evm_votes: 5225,
      postal_votes: 22,
      total_votes: 5247,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 8,
      candidate: "MUSTAKBHAI JAMALBHAI SANGHANI",
      party: "Independent",
      evm_votes: 3196,
      postal_votes: 7,
      total_votes: 3203,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 9,
      candidate: "KATARA VARUNKUMAR KALYANSINH",
      party: "Gunj Satya Ni Janata Party",
      evm_votes: 2717,
      postal_votes: 56,
      total_votes: 2773,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 10,
      candidate: "RAKESHSINH MAHOBATSINH ZALA",
      party: "Bhartiya Jan Parishad",
      evm_votes: 1882,
      postal_votes: 69,
      total_votes: 1951,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 11,
      candidate: "PANDOR KAUSHIKKUMAR SHANKARBHAI",
      party: "Independent",
      evm_votes: 1782,
      postal_votes: 45,
      total_votes: 1827,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 12,
      candidate: "ASHOK L VAGHELA",
      party: "Independent",
      evm_votes: 1673,
      postal_votes: 15,
      total_votes: 1688,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 13,
      candidate: "KANUBHAI KHIMJIBHAI GADHAVI",
      party: "Independent",
      evm_votes: 1467,
      postal_votes: 38,
      total_votes: 1505,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "THAKOR INDIRABEN JITENDRASINH",
      party: "Insaniyat Party",
      evm_votes: 1402,
      postal_votes: 34,
      total_votes: 1436,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 20665,
      postal_votes: 411,
      total_votes: 21076,
      percentage_of_votes: 1.66,
    },
  ],
  total: {
    evm_votes: 1256286,
    postal_votes: 13072,
    total_votes: 1269358,
  },
};
export const s066 = {
  cs_name: "Gandhinagar",
  cs_shortname: "gandhinagar",
  cs_code: "s066",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "AMIT SHAH",
      party: "Bharatiya Janata Party",
      evm_votes: 999984,
      postal_votes: 10988,
      total_votes: 1010972,
      percentage_of_votes: 76.48,
    },
    {
      serial_no: 2,
      candidate: "SONAL RAMANBHAI PATEL",
      party: "Indian National Congress",
      evm_votes: 261411,
      postal_votes: 4845,
      total_votes: 266256,
      percentage_of_votes: 20.14,
    },
    {
      serial_no: 3,
      candidate: "MOHAMMEDANISH DESAI",
      party: "Bahujan Samaj Party",
      evm_votes: 7272,
      postal_votes: 122,
      total_votes: 7394,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 4,
      candidate: "SHAHNAWAZKHAN SULTANKHAN PATHAN",
      party: "Independent",
      evm_votes: 2325,
      postal_votes: 7,
      total_votes: 2332,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 5,
      candidate: "MALEK MAKBUL SHAKIB",
      party: "Independent",
      evm_votes: 2052,
      postal_votes: 6,
      total_votes: 2058,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 6,
      candidate: "THAKOR JITENDRASINH JASHAVANTSINH",
      party: "Insaniyat Party",
      evm_votes: 1949,
      postal_votes: 60,
      total_votes: 2009,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 7,
      candidate: "PARIKH RAJIVBHAI KALABHAI",
      party: "Independent",
      evm_votes: 1719,
      postal_votes: 32,
      total_votes: 1751,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 8,
      candidate: "MANSURI SUHANA",
      party: "Independent",
      evm_votes: 1437,
      postal_votes: 16,
      total_votes: 1453,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 9,
      candidate: "MAURYA SUMITRA DEVNARIAN",
      party: "Prajatantra Aadhar Party",
      evm_votes: 1205,
      postal_votes: 12,
      total_votes: 1217,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 10,
      candidate: "UMADIYA ALIBHAI RAJABHAI",
      party: "Independent",
      evm_votes: 1004,
      postal_votes: 5,
      total_votes: 1009,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 11,
      candidate: "RAHUL CHIMANBHAI MEHTA",
      party: "Right to Recall Party",
      evm_votes: 982,
      postal_votes: 19,
      total_votes: 1001,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "PATHAN IMTIYAJKHAN",
      party: "Independent",
      evm_votes: 786,
      postal_votes: 56,
      total_votes: 842,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "BAGVAN BAHADURSHAH GULMOHAMMAD",
      party: "Independent",
      evm_votes: 755,
      postal_votes: 16,
      total_votes: 771,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 14,
      candidate: "NAVSADALAM IBRAHIMBHAI MALEK",
      party: "Independent",
      evm_votes: 726,
      postal_votes: 6,
      total_votes: 732,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 21233,
      postal_votes: 772,
      total_votes: 22005,
      percentage_of_votes: 1.66,
    },
  ],
  total: {
    evm_votes: 1304840,
    postal_votes: 16962,
    total_votes: 1321802,
  },
};
export const s067 = {
  cs_name: "Ahmedabad_East",
  cs_shortname: "ahmedabadeast",
  cs_code: "s067",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "HASMUKHBHAI PATEL (H.S.PATEL)",
      party: "Bharatiya Janata Party",
      evm_votes: 763028,
      postal_votes: 7431,
      total_votes: 770459,
      percentage_of_votes: 68.28,
    },
    {
      serial_no: 2,
      candidate: "HIMMATSINH PRAHLADSINH PATEL",
      party: "Indian National Congress",
      evm_votes: 303713,
      postal_votes: 4991,
      total_votes: 308704,
      percentage_of_votes: 27.36,
    },
    {
      serial_no: 3,
      candidate: "MAKWANA JAYANTIBHAI KANJIBHAI",
      party: "Bahujan Samaj Party",
      evm_votes: 10235,
      postal_votes: 247,
      total_votes: 10482,
      percentage_of_votes: 0.93,
    },
    {
      serial_no: 4,
      candidate: "BRIJESH SHARMA",
      party: "Independent",
      evm_votes: 4514,
      postal_votes: 12,
      total_votes: 4526,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 5,
      candidate: "HARSHAD BABUBHAI NANDOLIYA",
      party: "Independent",
      evm_votes: 3554,
      postal_votes: 17,
      total_votes: 3571,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 6,
      candidate: "MAHESHKUMAR SOMABHAI THAKOR",
      party: "Independent",
      evm_votes: 3005,
      postal_votes: 28,
      total_votes: 3033,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 7,
      candidate: "KALPESHBHAI SHETE",
      party: "Gunj Satya Ni Janata Party",
      evm_votes: 2927,
      postal_votes: 10,
      total_votes: 2937,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 8,
      candidate: "PATANI VISHNUBHAI NATAVARBHAI",
      party: "Independent",
      evm_votes: 2377,
      postal_votes: 43,
      total_votes: 2420,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 9,
      candidate: "ZALA SANJAYKUMAR MAHOBATSINH",
      party: "Independent",
      evm_votes: 2310,
      postal_votes: 56,
      total_votes: 2366,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 10,
      candidate: "DASHARATHBHAI KANTILAL PANCHAL",
      party: "Independent",
      evm_votes: 1474,
      postal_votes: 6,
      total_votes: 1480,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "PRAMOD SAHDEVBHAI GUDADE",
      party: "Swatantrata Abhivyakti Party",
      evm_votes: 1275,
      postal_votes: 4,
      total_votes: 1279,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 12,
      candidate: "RAJESH HARIRAM MAURYA",
      party: "Prajatantra Aadhar Party",
      evm_votes: 1199,
      postal_votes: 13,
      total_votes: 1212,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 13,
      candidate: "INGOLE ROOPESHBHAI BABUBHAI",
      party: "Independent",
      evm_votes: 1153,
      postal_votes: 16,
      total_votes: 1169,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 14,
      candidate: "DHANANJAY GIRJASHANKAR RAJPUT",
      party: "Bhartiya Jan Parishad",
      evm_votes: 1083,
      postal_votes: 37,
      total_votes: 1120,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "MANISH KUMAR DUBEY",
      party: "Swaraj Kranti Party",
      evm_votes: 898,
      postal_votes: 11,
      total_votes: 909,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 16,
      candidate: "CHAUHAN MOHAMADFARUK AHEMADHASANBHAI",
      party: "Independent",
      evm_votes: 771,
      postal_votes: 7,
      total_votes: 778,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 17,
      candidate: "HITENDRABHAI PATEL",
      party: "Aadi Bharat Party",
      evm_votes: 722,
      postal_votes: 20,
      total_votes: 742,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "PIYUSHBHAI BHAVSAR",
      party: "Youth India Peace Party",
      evm_votes: 642,
      postal_votes: 7,
      total_votes: 649,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 19,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9911,
      postal_votes: 592,
      total_votes: 10503,
      percentage_of_votes: 0.93,
    },
  ],
  total: {
    evm_votes: 1114791,
    postal_votes: 13548,
    total_votes: 1128339,
  },
};
export const s068 = {
  cs_name: "Ahmedabad_West",
  cs_shortname: "ahmedabadwest",
  cs_code: "s068",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DINESHBHAI MAKWANA (ADVOCATE)",
      party: "Bharatiya Janata Party",
      evm_votes: 606545,
      postal_votes: 5159,
      total_votes: 611704,
      percentage_of_votes: 63.28,
    },
    {
      serial_no: 2,
      candidate: "BHARAT YOGENDRA MAKWANA",
      party: "Indian National Congress",
      evm_votes: 321972,
      postal_votes: 3295,
      total_votes: 325267,
      percentage_of_votes: 33.65,
    },
    {
      serial_no: 3,
      candidate: "ANILKUMAR VASANTBHAI VAGHELA",
      party: "Bahujan Samaj Party",
      evm_votes: 9448,
      postal_votes: 152,
      total_votes: 9600,
      percentage_of_votes: 0.99,
    },
    {
      serial_no: 4,
      candidate: "BHITORA BHAVESHKUMAR CHIMANLAL",
      party: "Gujarat Loktantra Party",
      evm_votes: 2443,
      postal_votes: 15,
      total_votes: 2458,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 5,
      candidate: "SHANKARBHAI KHUSHALBHAI RATHOD",
      party: "Democratic Bharatiya Samaj Party",
      evm_votes: 2345,
      postal_votes: 44,
      total_votes: 2389,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 6,
      candidate: "VEDUBHAI KAUTIKBHAI SIRASAT",
      party: "Jan Sewa Driver Party",
      evm_votes: 1214,
      postal_votes: 7,
      total_votes: 1221,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 7,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13604,
      postal_votes: 403,
      total_votes: 14007,
      percentage_of_votes: 1.45,
    },
  ],
  total: {
    evm_votes: 957571,
    postal_votes: 9075,
    total_votes: 966646,
  },
};
export const s069 = {
  cs_name: "Surendranagar",
  cs_shortname: "surendranagar",
  cs_code: "s069",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "CHANDUBHAI CHHAGANBHAI SHIHORA",
      party: "Bharatiya Janata Party",
      evm_votes: 664093,
      postal_votes: 5656,
      total_votes: 669749,
      percentage_of_votes: 59.2,
    },
    {
      serial_no: 2,
      candidate: "RUTVIKBHAI LAVJIBHAI MAKWANA",
      party: "Indian National Congress",
      evm_votes: 403461,
      postal_votes: 4671,
      total_votes: 408132,
      percentage_of_votes: 36.07,
    },
    {
      serial_no: 3,
      candidate: "DABHI ASHOKBHAI SUKHABHAI",
      party: "Bahujan Samaj Party",
      evm_votes: 11885,
      postal_votes: 151,
      total_votes: 12036,
      percentage_of_votes: 1.06,
    },
    {
      serial_no: 4,
      candidate: "VINODBHAI BABUBHAI SATROTIYA",
      party: "Independent",
      evm_votes: 5940,
      postal_votes: 6,
      total_votes: 5946,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 5,
      candidate: "KOLI RAMESHBHAI VIRSANGBHAI VAGHELA",
      party: "Independent",
      evm_votes: 5173,
      postal_votes: 16,
      total_votes: 5189,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 6,
      candidate: "CHAVDA NILESHBHAI MANSUKHBHAI",
      party: "Rashtra Nirman Party",
      evm_votes: 4103,
      postal_votes: 26,
      total_votes: 4129,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 7,
      candidate: "ZALA DEVRAJBHAI BABUBHAI",
      party: "Independent",
      evm_votes: 2442,
      postal_votes: 21,
      total_votes: 2463,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 8,
      candidate: "J.K.PATEL",
      party: "Independent",
      evm_votes: 2308,
      postal_votes: 59,
      total_votes: 2367,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 9,
      candidate: "DEVENDRA MOHANDAS MAHANT",
      party: "Gunj Satya Ni Janata Party",
      evm_votes: 2042,
      postal_votes: 9,
      total_votes: 2051,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 10,
      candidate: "GEDIYA KRUSHANAVADAN HARIPRASADBHAI",
      party: "Independent",
      evm_votes: 1435,
      postal_votes: 24,
      total_votes: 1459,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "ANANDBHAI PACHANBHAI RATHOD",
      party: "Independent",
      evm_votes: 1241,
      postal_votes: 8,
      total_votes: 1249,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 12,
      candidate: "DILIPBHAI PARSHOTTAMBHAI MAKWANA",
      party: "New India United Party",
      evm_votes: 1222,
      postal_votes: 35,
      total_votes: 1257,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 13,
      candidate: "ASHOK PALAJIBHAI RATHOD",
      party: "Independent",
      evm_votes: 1087,
      postal_votes: 4,
      total_votes: 1091,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 14,
      candidate: "PATEL MADHUSUDAN BALDEVBHAI",
      party: "Mission All India Independent Justice Party",
      evm_votes: 948,
      postal_votes: 12,
      total_votes: 960,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12799,
      postal_votes: 500,
      total_votes: 13299,
      percentage_of_votes: 1.18,
    },
  ],
  total: {
    evm_votes: 1120179,
    postal_votes: 11198,
    total_votes: 1131377,
  },
};
export const s0610 = {
  cs_name: "Rajkot",
  cs_shortname: "rajkot",
  cs_code: "s0610",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "PARSHOTTAMBHAI RUPALA",
      party: "Bharatiya Janata Party",
      evm_votes: 850846,
      postal_votes: 7138,
      total_votes: 857984,
      percentage_of_votes: 67.37,
    },
    {
      serial_no: 2,
      candidate: "DHANANI PARESH",
      party: "Indian National Congress",
      evm_votes: 368964,
      postal_votes: 4760,
      total_votes: 373724,
      percentage_of_votes: 29.35,
    },
    {
      serial_no: 3,
      candidate: "CHAMANBHAI NAGJIBHAI SAVSANI",
      party: "Bahujan Samaj Party",
      evm_votes: 10356,
      postal_votes: 109,
      total_votes: 10465,
      percentage_of_votes: 0.82,
    },
    {
      serial_no: 4,
      candidate: "AJAGIYA NIRALBHAI AMRUTLAL",
      party: "Independent",
      evm_votes: 3674,
      postal_votes: 6,
      total_votes: 3680,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 5,
      candidate: "BHAVESHBHAI KANTILAL PIPALIYA",
      party: "Independent",
      evm_votes: 2846,
      postal_votes: 10,
      total_votes: 2856,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 6,
      candidate: "BHAVESH UPENDRABHAI ACHARYA",
      party: "Independent",
      evm_votes: 2806,
      postal_votes: 34,
      total_votes: 2840,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 7,
      candidate: "PRAKASH GOVINDBHAI SINDHAV",
      party: "Independent",
      evm_votes: 2355,
      postal_votes: 34,
      total_votes: 2389,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 8,
      candidate: "ZALA NAYAN J.",
      party: "Independent",
      evm_votes: 2321,
      postal_votes: 29,
      total_votes: 2350,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 9,
      candidate: "JIGNESHBHAI MAHAJAN",
      party: "Independent",
      evm_votes: 1322,
      postal_votes: 10,
      total_votes: 1332,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 15288,
      postal_votes: 634,
      total_votes: 15922,
      percentage_of_votes: 1.25,
    },
  ],
  total: {
    evm_votes: 1260778,
    postal_votes: 12764,
    total_votes: 1273542,
  },
};
export const s0611 = {
  cs_name: "Porbandar",
  cs_shortname: "porbandar",
  cs_code: "s0611",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR. MANSUKH MANDAVIYA",
      party: "Bharatiya Janata Party",
      evm_votes: 625962,
      postal_votes: 7156,
      total_votes: 633118,
      percentage_of_votes: 68.15,
    },
    {
      serial_no: 2,
      candidate: "LALIT VASOYA",
      party: "Indian National Congress",
      evm_votes: 245677,
      postal_votes: 4081,
      total_votes: 249758,
      percentage_of_votes: 26.89,
    },
    {
      serial_no: 3,
      candidate: "N. P. RATHOD",
      party: "Bahujan Samaj Party",
      evm_votes: 10560,
      postal_votes: 362,
      total_votes: 10922,
      percentage_of_votes: 1.18,
    },
    {
      serial_no: 4,
      candidate: "NATHABHAI BHURABHAI ODEDRA",
      party: "Independent",
      evm_votes: 7164,
      postal_votes: 132,
      total_votes: 7296,
      percentage_of_votes: 0.79,
    },
    {
      serial_no: 5,
      candidate: "LAKHANSI ODEDARA",
      party: "Viro Ke Vir Indian Party",
      evm_votes: 3130,
      postal_votes: 33,
      total_votes: 3163,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 6,
      candidate: "SOLANKI JATIN DHIRUBHAI",
      party: "Independent",
      evm_votes: 2469,
      postal_votes: 17,
      total_votes: 2486,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 7,
      candidate: "SODHA HUSHENBHAI ALIBHAI",
      party: "Independent",
      evm_votes: 2432,
      postal_votes: 3,
      total_votes: 2435,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 8,
      candidate: "RATHOD CHANDUBHAI MOHANBHAI",
      party: "Independent",
      evm_votes: 1705,
      postal_votes: 24,
      total_votes: 1729,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 9,
      candidate: "SHEKHAVA NILESHKUMAR RAMJIBHAI",
      party: "Samajwadi Party",
      evm_votes: 1587,
      postal_votes: 22,
      total_votes: 1609,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 10,
      candidate: "SIDHHAPARA HARSUKHLAL JIVANBHAI",
      party: "Log Party",
      evm_votes: 1059,
      postal_votes: 13,
      total_votes: 1072,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 11,
      candidate: "MAHEMUDBHAI SAIYAD",
      party: "Independent",
      evm_votes: 1023,
      postal_votes: 6,
      total_votes: 1029,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 12,
      candidate: "JETHAVA BIPINKUMAR BHIKHALAL",
      party: "Independent",
      evm_votes: 786,
      postal_votes: 11,
      total_votes: 797,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13004,
      postal_votes: 559,
      total_votes: 13563,
      percentage_of_votes: 1.46,
    },
  ],
  total: {
    evm_votes: 916558,
    postal_votes: 12419,
    total_votes: 928977,
  },
};
export const s0612 = {
  cs_name: "Jamnagar",
  cs_shortname: "jamnagar",
  cs_code: "s0612",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "POONAMBEN HEMATBHAI MAADAM",
      party: "Bharatiya Janata Party",
      evm_votes: 618120,
      postal_votes: 1929,
      total_votes: 620049,
      percentage_of_votes: 58.98,
    },
    {
      serial_no: 2,
      candidate: "ADVOCATE J. P. MARAVIYA",
      party: "Indian National Congress",
      evm_votes: 380949,
      postal_votes: 1092,
      total_votes: 382041,
      percentage_of_votes: 36.34,
    },
    {
      serial_no: 3,
      candidate: "JAYSUKH NATHUBHAI PINGALSUR",
      party: "Bahujan Samaj Party",
      evm_votes: 11429,
      postal_votes: 33,
      total_votes: 11462,
      percentage_of_votes: 1.09,
    },
    {
      serial_no: 4,
      candidate: "KANZARIYA RANCHHODBHAI NARANBHAI",
      party: "Viro Ke Vir Indian Party",
      evm_votes: 6973,
      postal_votes: 29,
      total_votes: 7002,
      percentage_of_votes: 0.67,
    },
    {
      serial_no: 5,
      candidate: "VIJAYSINH PRAVINSINH JADEJA",
      party: "Independent",
      evm_votes: 4817,
      postal_votes: 17,
      total_votes: 4834,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 6,
      candidate: "RATHOD PUNJABHAI PALABHAI",
      party: "Independent",
      evm_votes: 3727,
      postal_votes: 5,
      total_votes: 3732,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 7,
      candidate: "BHURALAL MEGHJIBHAI PARMAR",
      party: "Independent",
      evm_votes: 1923,
      postal_votes: 5,
      total_votes: 1928,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "GHUGHA ALARAKHABHAI ISHAKBHAI",
      party: "Independent",
      evm_votes: 1582,
      postal_votes: 3,
      total_votes: 1585,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 9,
      candidate: "KHIRA YUSUF SIDIKBHAI",
      party: "Independent",
      evm_votes: 1368,
      postal_votes: 3,
      total_votes: 1371,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 10,
      candidate: "NANJI AMARSHI BATHWAR",
      party: "Independent",
      evm_votes: 1365,
      postal_votes: 5,
      total_votes: 1370,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "ANWAR NOORMAMAD SANGHAR",
      party: "Independent",
      evm_votes: 1299,
      postal_votes: 0,
      total_votes: 1299,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "PARESHBHAI PARSOTTAMBHAI MUNGARA",
      party: "Rashtriya Mahaswaraj Bhumi Party",
      evm_votes: 1266,
      postal_votes: 4,
      total_votes: 1270,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "POPATPUTRA RAFIK ABUBAKAR",
      party: "Independent",
      evm_votes: 1133,
      postal_votes: 5,
      total_votes: 1138,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 14,
      candidate: "NADEEM MAHAMMAD HALA",
      party: "Independent",
      evm_votes: 1069,
      postal_votes: 1,
      total_votes: 1070,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10972,
      postal_votes: 112,
      total_votes: 11084,
      percentage_of_votes: 1.05,
    },
  ],
  total: {
    evm_votes: 1047992,
    postal_votes: 3243,
    total_votes: 1051235,
  },
};
export const s0613 = {
  cs_name: "Junagadh",
  cs_shortname: "junagadh",
  cs_code: "s0613",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "CHUDASAMA RAJESHBHAI NARANBHAI",
      party: "Bharatiya Janata Party",
      evm_votes: 578516,
      postal_votes: 5533,
      total_votes: 584049,
      percentage_of_votes: 54.67,
    },
    {
      serial_no: 2,
      candidate: "JOTVA HIRABHAI ARJANBHAI",
      party: "Indian National Congress",
      evm_votes: 444156,
      postal_votes: 4399,
      total_votes: 448555,
      percentage_of_votes: 41.99,
    },
    {
      serial_no: 3,
      candidate: "MAKADIA JAYANTILAL MALDEBHAI",
      party: "Bahujan Samaj Party",
      evm_votes: 7132,
      postal_votes: 150,
      total_votes: 7282,
      percentage_of_votes: 0.68,
    },
    {
      serial_no: 4,
      candidate: "VADHER DANSING CHINABHAI",
      party: "Independent",
      evm_votes: 5161,
      postal_votes: 28,
      total_votes: 5189,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 5,
      candidate: "DEVENDRABHAI DHANJIBHAI MOTIVARAS",
      party: "Independent",
      evm_votes: 2302,
      postal_votes: 7,
      total_votes: 2309,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 6,
      candidate: "BORICHANGAR BHAVESH DALPATRAI",
      party: "Independent",
      evm_votes: 1884,
      postal_votes: 24,
      total_votes: 1908,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 7,
      candidate: "DAKI NATHABHAI MENSIBHAI",
      party: "Independent",
      evm_votes: 1329,
      postal_votes: 15,
      total_votes: 1344,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 8,
      candidate: "ISHWAR RAMBHAI SOLANKI",
      party: "Right to Recall Party",
      evm_votes: 1150,
      postal_votes: 21,
      total_votes: 1171,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 9,
      candidate: "ALPESHKUMAR CHANDULAL TRAMBADIYA",
      party: "Log Party",
      evm_votes: 943,
      postal_votes: 21,
      total_votes: 964,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 10,
      candidate: "GORDHANBHAI MANGABHAI GOHEL",
      party: "Independent",
      evm_votes: 758,
      postal_votes: 17,
      total_votes: 775,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "AARAB HASAM SUMRA",
      party: "Independent",
      evm_votes: 689,
      postal_votes: 5,
      total_votes: 694,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13434,
      postal_votes: 579,
      total_votes: 14013,
      percentage_of_votes: 1.31,
    },
  ],
  total: {
    evm_votes: 1057454,
    postal_votes: 10799,
    total_votes: 1068253,
  },
};
export const s0614 = {
  cs_name: "Amreli",
  cs_shortname: "amreli",
  cs_code: "s0614",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BHARATBHAI MANUBHAI SUTARIYA",
      party: "Bharatiya Janata Party",
      evm_votes: 577820,
      postal_votes: 3052,
      total_votes: 580872,
      percentage_of_votes: 66.28,
    },
    {
      serial_no: 2,
      candidate: "JENNY THUMMAR",
      party: "Indian National Congress",
      evm_votes: 258231,
      postal_votes: 1573,
      total_votes: 259804,
      percentage_of_votes: 29.64,
    },
    {
      serial_no: 3,
      candidate: "CHAUHAN RAVAJIBHAI MULABHAI",
      party: "Bahujan Samaj Party",
      evm_votes: 7486,
      postal_votes: 57,
      total_votes: 7543,
      percentage_of_votes: 0.86,
    },
    {
      serial_no: 4,
      candidate: "SANKHAT VIKRAMBHAI VISABHAI",
      party: "Global Republican Party",
      evm_votes: 4958,
      postal_votes: 51,
      total_votes: 5009,
      percentage_of_votes: 0.57,
    },
    {
      serial_no: 5,
      candidate: "BAVKUBHAI AMARUBHAI VALA",
      party: "Independent",
      evm_votes: 3733,
      postal_votes: 44,
      total_votes: 3777,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 6,
      candidate: "BHAVESHBHAI JENTIBHAI RANK",
      party: "Independent",
      evm_votes: 3663,
      postal_votes: 8,
      total_votes: 3671,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 7,
      candidate: "PUNJABHAI BAVBHAI DAFDA",
      party: "Independent",
      evm_votes: 2598,
      postal_votes: 23,
      total_votes: 2621,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 8,
      candidate: "PRITESH CHAUHAN (LALU)",
      party: "Independent",
      evm_votes: 1759,
      postal_votes: 21,
      total_votes: 1780,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11125,
      postal_votes: 224,
      total_votes: 11349,
      percentage_of_votes: 1.29,
    },
  ],
  total: {
    evm_votes: 871373,
    postal_votes: 5053,
    total_votes: 876426,
  },
};
export const s0615 = {
  cs_name: "Bhavnagar",
  cs_shortname: "bhavnagar",
  cs_code: "s0615",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "NIMUBEN JAYANTIBHAI BAMBHANIYA (NIMUBEN BAMBHANIYA)",
      party: "Bharatiya Janata Party",
      evm_votes: 707428,
      postal_votes: 9455,
      total_votes: 716883,
      percentage_of_votes: 68.46,
    },
    {
      serial_no: 2,
      candidate: "UMESHBHAI NARANBHAI MAKWANA",
      party: "Aam Aadmi Party",
      evm_votes: 256902,
      postal_votes: 4692,
      total_votes: 261594,
      percentage_of_votes: 24.98,
    },
    {
      serial_no: 3,
      candidate: "RATHOD DINESHBHAI LAKHABHAI",
      party: "Bahujan Samaj Party",
      evm_votes: 9989,
      postal_votes: 141,
      total_votes: 10130,
      percentage_of_votes: 0.97,
    },
    {
      serial_no: 4,
      candidate: "ANILBHAI NARENDRABHAI CHAVDA",
      party: "Swatantrata Abhivyakti Party",
      evm_votes: 7574,
      postal_votes: 45,
      total_votes: 7619,
      percentage_of_votes: 0.73,
    },
    {
      serial_no: 5,
      candidate: "PARMAR RAJESHKUMAR PREMJIBHAI",
      party: "New India United Party",
      evm_votes: 6456,
      postal_votes: 37,
      total_votes: 6493,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 6,
      candidate: "KALANIYA SAGARBHAI POPATBHAI",
      party: "Aapki Awaaz Party",
      evm_votes: 6039,
      postal_votes: 48,
      total_votes: 6087,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 7,
      candidate: "SANJAYBHAI MAGANBHAI MAKAWANA",
      party: "Independent",
      evm_votes: 4131,
      postal_votes: 22,
      total_votes: 4153,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 8,
      candidate: "HARSH GOKLANI",
      party: "Independent",
      evm_votes: 3593,
      postal_votes: 41,
      total_votes: 3634,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 9,
      candidate: "SHRI MULSHANKARBHAI RAGHURAMBHAI CHAUHAN",
      party: "Independent",
      evm_votes: 3386,
      postal_votes: 20,
      total_votes: 3406,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 10,
      candidate: "SHRI TIDABHAI DEVSHIBHAI",
      party: "Gujarat Sarva Samaj Party",
      evm_votes: 2641,
      postal_votes: 44,
      total_votes: 2685,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 11,
      candidate: "BHUPATBHAI MOHANBHAI VALA",
      party: "Right to Recall Party",
      evm_votes: 2370,
      postal_votes: 24,
      total_votes: 2394,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 12,
      candidate: "NARESHBHAI NARANBHAI RATHOD",
      party: "Independent",
      evm_votes: 1745,
      postal_votes: 20,
      total_votes: 1765,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 13,
      candidate: "BRAHMAKSHTRIYA BHAGVATIBEN KHETSINH",
      party: "Independent",
      evm_votes: 1562,
      postal_votes: 62,
      total_votes: 1624,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 17717,
      postal_votes: 1048,
      total_votes: 18765,
      percentage_of_votes: 1.79,
    },
  ],
  total: {
    evm_votes: 1031533,
    postal_votes: 15699,
    total_votes: 1047232,
  },
};
export const s0616 = {
  cs_name: "Anand",
  cs_shortname: "anand",
  cs_code: "s0616",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "MITESH PATEL (BAKABHAI)",
      party: "Bharatiya Janata Party",
      evm_votes: 607394,
      postal_votes: 5090,
      total_votes: 612484,
      percentage_of_votes: 52.44,
    },
    {
      serial_no: 2,
      candidate: "AMIT CHAVDA",
      party: "Indian National Congress",
      evm_votes: 516453,
      postal_votes: 6092,
      total_votes: 522545,
      percentage_of_votes: 44.74,
    },
    {
      serial_no: 3,
      candidate: "SURESHBHAI DHULABHAI PATEL",
      party: "Bahujan Samaj Party",
      evm_votes: 5778,
      postal_votes: 53,
      total_votes: 5831,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 4,
      candidate: "BHOI ASHISHKUMAR THAKORBHAI",
      party: "Independent",
      evm_votes: 5525,
      postal_votes: 17,
      total_votes: 5542,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 5,
      candidate: "KEYURBHAI PRAVINBHAI PATEL (BAKABHAI)",
      party: "Independent",
      evm_votes: 2290,
      postal_votes: 8,
      total_votes: 2298,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 6,
      candidate: "DHEERAJKUMAR KSHATRIYA",
      party: "Garib Kalyan Party",
      evm_votes: 1723,
      postal_votes: 24,
      total_votes: 1747,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 7,
      candidate: "BHATT SUNILKUMAR NARENDRABHAI",
      party: "Right to Recall Party",
      evm_votes: 1584,
      postal_votes: 8,
      total_votes: 1592,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 15679,
      postal_votes: 251,
      total_votes: 15930,
      percentage_of_votes: 1.36,
    },
  ],
  total: {
    evm_votes: 1156426,
    postal_votes: 11543,
    total_votes: 1167969,
  },
};
export const s0617 = {
  cs_name: "Kheda",
  cs_shortname: "kheda",
  cs_code: "s0617",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DEVUSINH CHAUHAN",
      party: "Bharatiya Janata Party",
      evm_votes: 738609,
      postal_votes: 5826,
      total_votes: 744435,
      percentage_of_votes: 63.31,
    },
    {
      serial_no: 2,
      candidate: "KALUSINH DABHI",
      party: "Indian National Congress",
      evm_votes: 381656,
      postal_votes: 5021,
      total_votes: 386677,
      percentage_of_votes: 32.88,
    },
    {
      serial_no: 3,
      candidate: "SODHA SANJAYKUMAR PARVATSINH",
      party: "Independent",
      evm_votes: 6123,
      postal_votes: 16,
      total_votes: 6139,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 4,
      candidate: "BHAILALBHAI KALUBHAI PANDAV",
      party: "Bahujan Samaj Party",
      evm_votes: 6055,
      postal_votes: 97,
      total_votes: 6152,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 5,
      candidate: "PARMAR HITESHKUMAR PARSOTTAMBHAI",
      party: "Independent",
      evm_votes: 2730,
      postal_votes: 11,
      total_votes: 2741,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 6,
      candidate: "UPENDRAKUMAR VALLAVBHAI PATEL",
      party: "Independent",
      evm_votes: 2703,
      postal_votes: 12,
      total_votes: 2715,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 7,
      candidate: "PATEL ANILKUMAR BHAILALBHAI",
      party: "Rashtriya Mahaswaraj Bhumi Party",
      evm_votes: 2493,
      postal_votes: 30,
      total_votes: 2523,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 8,
      candidate: "SAIYAD KADARI MOHAMMAD SABIR ANVAR HUSAIN",
      party: "Bhartiya Jan Nayak Party",
      evm_votes: 1812,
      postal_votes: 26,
      total_votes: 1838,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "INDIRADEVI HIRALAL VORA",
      party: "Garib Kalyan Party",
      evm_votes: 1126,
      postal_votes: 9,
      total_votes: 1135,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 10,
      candidate: "KAMLESHBHAI POPATBHAI PATEL",
      party: "Bhartiya Jan Parishad",
      evm_votes: 933,
      postal_votes: 16,
      total_votes: 949,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 11,
      candidate: "IMRANBHAI VANKAWALA",
      party: "Right to Recall Party",
      evm_votes: 924,
      postal_votes: 4,
      total_votes: 928,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "KANTIYA DASRATH HARJIVANBHAI",
      party: "New India United Party",
      evm_votes: 789,
      postal_votes: 3,
      total_votes: 792,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 18444,
      postal_votes: 380,
      total_votes: 18824,
      percentage_of_votes: 1.6,
    },
  ],
  total: {
    evm_votes: 1164397,
    postal_votes: 11451,
    total_votes: 1175848,
  },
};
export const s0618 = {
  cs_name: "Panchmahal",
  cs_shortname: "panchmahal",
  cs_code: "s0618",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAJPALSINH MAHENDRASINH JADAV",
      party: "Bharatiya Janata Party",
      evm_votes: 785830,
      postal_votes: 8749,
      total_votes: 794579,
      percentage_of_votes: 70.22,
    },
    {
      serial_no: 2,
      candidate: "GULABSINH SOMSINH CHAUHAN",
      party: "Indian National Congress",
      evm_votes: 279396,
      postal_votes: 5841,
      total_votes: 285237,
      percentage_of_votes: 25.21,
    },
    {
      serial_no: 3,
      candidate: "HASMUKHKUMAR GANPATSINH RATHOD",
      party: "Independent",
      evm_votes: 10376,
      postal_votes: 23,
      total_votes: 10399,
      percentage_of_votes: 0.92,
    },
    {
      serial_no: 4,
      candidate: "JITESHKUMAR GHANSHYAMBHAI SEVAK",
      party: "Dhanwan Bharat Party",
      evm_votes: 8350,
      postal_votes: 39,
      total_votes: 8389,
      percentage_of_votes: 0.74,
    },
    {
      serial_no: 5,
      candidate: "MANOJSINH RANAJITSINH RATHOD",
      party: "Independent",
      evm_votes: 3984,
      postal_votes: 29,
      total_votes: 4013,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 6,
      candidate: "LAXMANBHAI GALABHAI BARIYA",
      party: "Aam Janmat Party,",
      evm_votes: 3108,
      postal_votes: 97,
      total_votes: 3205,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 7,
      candidate: "TASLIM MOHAMMEDRAFIK DURVESH",
      party: "Independent",
      evm_votes: 3051,
      postal_votes: 14,
      total_votes: 3065,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 8,
      candidate: "PANDOR KAUSHIKKUMAR SHANKARBHAI",
      party: "Independent",
      evm_votes: 2486,
      postal_votes: 21,
      total_votes: 2507,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 19629,
      postal_votes: 474,
      total_votes: 20103,
      percentage_of_votes: 1.78,
    },
  ],
  total: {
    evm_votes: 1116210,
    postal_votes: 15287,
    total_votes: 1131497,
  },
};
export const s0619 = {
  cs_name: "Dahod",
  cs_shortname: "dahod",
  cs_code: "s0619",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "JASWANTSINH SUMANBHAI BHABHOR",
      party: "Bharatiya Janata Party",
      evm_votes: 685734,
      postal_votes: 2981,
      total_votes: 688715,
      percentage_of_votes: 61.59,
    },
    {
      serial_no: 2,
      candidate: "DR. PRABHABEN KISHORSINH TAVIYAD",
      party: "Indian National Congress",
      evm_votes: 352238,
      postal_votes: 2800,
      total_votes: 355038,
      percentage_of_votes: 31.75,
    },
    {
      serial_no: 3,
      candidate: "MEDA DEVENDRAKUMAR LAXMANBHAI",
      party: "Independent",
      evm_votes: 11068,
      postal_votes: 7,
      total_votes: 11075,
      percentage_of_votes: 0.99,
    },
    {
      serial_no: 4,
      candidate: "BHABHOR DHULABHAI DITABHAI",
      party: "Bahujan Samaj Party",
      evm_votes: 8621,
      postal_votes: 11,
      total_votes: 8632,
      percentage_of_votes: 0.77,
    },
    {
      serial_no: 5,
      candidate: "BARIYA MANILAL HIRABHAI",
      party: "Independent",
      evm_votes: 6576,
      postal_votes: 12,
      total_votes: 6588,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 6,
      candidate: "DAMOR VESTABHAI JOKHANABHAI",
      party: "Independent",
      evm_votes: 4374,
      postal_votes: 26,
      total_votes: 4400,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 7,
      candidate: "DAMOR MANABHAI BHAVSINGBHAI",
      party: "Independent",
      evm_votes: 3144,
      postal_votes: 29,
      total_votes: 3173,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 8,
      candidate: "JAGDISHBHAI MANILAL MEDA",
      party: "Bharatiya National Janata Dal",
      evm_votes: 3053,
      postal_votes: 9,
      total_votes: 3062,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 9,
      candidate: "PASAYA NAVALSINH MULABHAI",
      party: "Saath Sahakar Vikas Party",
      evm_votes: 2667,
      postal_votes: 6,
      total_votes: 2673,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 34774,
      postal_votes: 164,
      total_votes: 34938,
      percentage_of_votes: 3.12,
    },
  ],
  total: {
    evm_votes: 1112249,
    postal_votes: 6045,
    total_votes: 1118294,
  },
};
export const s0620 = {
  cs_name: "Vadodara",
  cs_shortname: "vadodara",
  cs_code: "s0620",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR. HEMANG JOSHI",
      party: "Bharatiya Janata Party",
      evm_votes: 865825,
      postal_votes: 7364,
      total_votes: 873189,
      percentage_of_votes: 72.04,
    },
    {
      serial_no: 2,
      candidate: "PADHIYAR JASHPALSINH MAHENDRASINH (BAPU)",
      party: "Indian National Congress",
      evm_votes: 287570,
      postal_votes: 3493,
      total_votes: 291063,
      percentage_of_votes: 24.01,
    },
    {
      serial_no: 3,
      candidate: "JADAV AMITKUMAR RAMJIBHAI",
      party: "Bahujan Samaj Party",
      evm_votes: 7473,
      postal_votes: 56,
      total_votes: 7529,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 4,
      candidate: "RAJESH RATHOD",
      party: "Independent",
      evm_votes: 5416,
      postal_votes: 53,
      total_votes: 5469,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 5,
      candidate: "ANILBHAI SHARMA",
      party: "Hindrashtra Sangh",
      evm_votes: 3436,
      postal_votes: 17,
      total_votes: 3453,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 6,
      candidate: "DR. RAHUL VASUDEVBHAI VYAS",
      party: "Independent",
      evm_votes: 2731,
      postal_votes: 21,
      total_votes: 2752,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 7,
      candidate: "PARMAR MAYURSINH ARVINDSINH",
      party: "Independent",
      evm_votes: 2122,
      postal_votes: 86,
      total_votes: 2208,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "NILKANTHKUMAR MANSUKHLAL MISTRI",
      party: "Independent",
      evm_votes: 1748,
      postal_votes: 8,
      total_votes: 1756,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 9,
      candidate: "PARMAR HEMANTKUMAR ARVINDBHAI",
      party: "Independent",
      evm_votes: 1388,
      postal_votes: 23,
      total_votes: 1411,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 10,
      candidate: "TAPAN DASGUPTA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1252,
      postal_votes: 23,
      total_votes: 1275,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 11,
      candidate: "ATUL GAMECHI",
      party: "Independent",
      evm_votes: 1061,
      postal_votes: 23,
      total_votes: 1084,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 12,
      candidate: "PARTHIV VIJAYKUMAR DAVE",
      party: "Right to Recall Party",
      evm_votes: 1052,
      postal_votes: 5,
      total_votes: 1057,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "NILESH JAGANNATH VASAIKAR",
      party: "Independent",
      evm_votes: 840,
      postal_votes: 18,
      total_votes: 858,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 14,
      candidate: "DOSHI HARDIK BIPINBHAI",
      party: "Satyawadi Rakshak Party",
      evm_votes: 643,
      postal_votes: 14,
      total_votes: 657,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 17823,
      postal_votes: 565,
      total_votes: 18388,
      percentage_of_votes: 1.52,
    },
  ],
  total: {
    evm_votes: 1200380,
    postal_votes: 11769,
    total_votes: 1212149,
  },
};
export const s0621 = {
  cs_name: "Chhota_Udaipur",
  cs_shortname: "chhotaudaipur",
  cs_code: "s0621",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "JASHUBHAI BHILUBHAI RATHVA",
      party: "Bharatiya Janata Party",
      evm_votes: 791727,
      postal_votes: 4862,
      total_votes: 796589,
      percentage_of_votes: 62.84,
    },
    {
      serial_no: 2,
      candidate: "SUKHRAMBHAI HARIYABHAI RATHWA",
      party: "Indian National Congress",
      evm_votes: 394433,
      postal_votes: 3379,
      total_votes: 397812,
      percentage_of_votes: 31.38,
    },
    {
      serial_no: 3,
      candidate: "BHIL SOMABHAI GOKALBHAI",
      party: "Bahujan Samaj Party",
      evm_votes: 16021,
      postal_votes: 72,
      total_votes: 16093,
      percentage_of_votes: 1.27,
    },
    {
      serial_no: 4,
      candidate: "RATHVA FURAKANBHAI BALJIBHAI",
      party: "Malwa Congress",
      evm_votes: 11091,
      postal_votes: 70,
      total_votes: 11161,
      percentage_of_votes: 0.88,
    },
    {
      serial_no: 5,
      candidate: "TADVI RANCHHODBHAI",
      party: "Bharatiya Bahujan Congress",
      evm_votes: 8292,
      postal_votes: 52,
      total_votes: 8344,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 6,
      candidate: "RATHVA MUKESHBHAI NURABHAI",
      party: "Independent",
      evm_votes: 8032,
      postal_votes: 33,
      total_votes: 8065,
      percentage_of_votes: 0.64,
    },
    {
      serial_no: 7,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 29394,
      postal_votes: 261,
      total_votes: 29655,
      percentage_of_votes: 2.34,
    },
  ],
  total: {
    evm_votes: 1258990,
    postal_votes: 8729,
    total_votes: 1267719,
  },
};
export const s0622 = {
  cs_name: "Bharuch",
  cs_shortname: "bharuch",
  cs_code: "s0622",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "MANSUKHBHAI DHANJIBHAI VASAVA",
      party: "Bharatiya Janata Party",
      evm_votes: 605489,
      postal_votes: 2668,
      total_votes: 608157,
      percentage_of_votes: 50.72,
    },
    {
      serial_no: 2,
      candidate: "CHAITARBHAI DAMJIBHAI VASAVA",
      party: "Aam Aadmi Party",
      evm_votes: 518419,
      postal_votes: 4042,
      total_votes: 522461,
      percentage_of_votes: 43.58,
    },
    {
      serial_no: 3,
      candidate: "DILIPBHAI CHHOTUBHAI VASAVA",
      party: "Bharat Adivasi Party",
      evm_votes: 9981,
      postal_votes: 33,
      total_votes: 10014,
      percentage_of_votes: 0.84,
    },
    {
      serial_no: 4,
      candidate: "SAJID YAKUB MUNSHI",
      party: "Independent",
      evm_votes: 9934,
      postal_votes: 3,
      total_votes: 9937,
      percentage_of_votes: 0.83,
    },
    {
      serial_no: 5,
      candidate: "VASAVA CHETANBHAI KANJIBHAI",
      party: "Bahujan Samaj Party",
      evm_votes: 6312,
      postal_votes: 12,
      total_votes: 6324,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 6,
      candidate: "NAVINBHAI BHIKHABHAI PATEL",
      party: "Independent",
      evm_votes: 3440,
      postal_votes: 13,
      total_votes: 3453,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 7,
      candidate: "YUSUF VALI HASANALI",
      party: "Independent",
      evm_votes: 3245,
      postal_votes: 2,
      total_votes: 3247,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 8,
      candidate: "GITABEN MANUBHAI MACHHI",
      party: "Malwa Congress",
      evm_votes: 2750,
      postal_votes: 14,
      total_votes: 2764,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 9,
      candidate: "MITESHBHAI THAKORBHAI PADHIYAR",
      party: "Independent",
      evm_votes: 2451,
      postal_votes: 8,
      total_votes: 2459,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 10,
      candidate: "MIRZA AABIDBEG YASINBEG",
      party: "Independent",
      evm_votes: 2043,
      postal_votes: 7,
      total_votes: 2050,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 11,
      candidate: "ISMAIL AHMAD PATEL",
      party: "Independent",
      evm_votes: 1887,
      postal_votes: 15,
      total_votes: 1902,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 12,
      candidate: "NARAYANBHAI LILADHARJI RAVAL",
      party: "Independent",
      evm_votes: 1574,
      postal_votes: 9,
      total_votes: 1583,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 13,
      candidate: "DHARMESHKUMAR VISHNUBHAI VASAVA",
      party: "Independent",
      evm_votes: 1324,
      postal_votes: 6,
      total_votes: 1330,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 23151,
      postal_votes: 132,
      total_votes: 23283,
      percentage_of_votes: 1.94,
    },
  ],
  total: {
    evm_votes: 1192000,
    postal_votes: 6964,
    total_votes: 1198964,
  },
};
export const s0623 = {
  cs_name: "Bardoli",
  cs_shortname: "bardoli",
  cs_code: "s0623",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "PARBHUBHAI NAGARBHAI VASAVA",
      party: "Bharatiya Janata Party",
      evm_votes: 757787,
      postal_votes: 6163,
      total_votes: 763950,
      percentage_of_votes: 57.04,
    },
    {
      serial_no: 2,
      candidate: "CHAUDHARY SIDDHARTH AMARSINH",
      party: "Indian National Congress",
      evm_votes: 526054,
      postal_votes: 7643,
      total_votes: 533697,
      percentage_of_votes: 39.85,
    },
    {
      serial_no: 3,
      candidate: "REKHABEN HARSINGBHAI CHAUDHARI",
      party: "Bahujan Samaj Party",
      evm_votes: 15872,
      postal_votes: 272,
      total_votes: 16144,
      percentage_of_votes: 1.21,
    },
    {
      serial_no: 4,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 24762,
      postal_votes: 780,
      total_votes: 25542,
      percentage_of_votes: 1.91,
    },
  ],
  total: {
    evm_votes: 1324475,
    postal_votes: 14858,
    total_votes: 1339333,
  },
};
export const s0624 = {
  cs_name: "Surat",
  cs_shortname: "surat",
  statename: "Gujarat",
  cs_code: "s0624",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "MUKESHKUMAR CHANDRAKAANT DALAL ( Uncontested )",
      party: "Bharatiya Janata Party",
      evm_votes: 0,
      postal_votes: 0,
      total_votes: 0,
      percentage_of_votes: 0,
    },
  ],
  total: {
    evm_votes: 0,
    postal_votes: 0,
    total_votes: 0,
  },
};
export const s0625 = {
  cs_name: "Navsari",
  cs_shortname: "navsari",
  cs_code: "s0625",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "C R PATIL",
      party: "Bharatiya Janata Party",
      evm_votes: 1023366,
      postal_votes: 7699,
      total_votes: 1031065,
      percentage_of_votes: 77.05,
    },
    {
      serial_no: 2,
      candidate: "NAISHADHBHAI BHUPATBHAI DESAI",
      party: "Indian National Congress",
      evm_votes: 254567,
      postal_votes: 2947,
      total_votes: 257514,
      percentage_of_votes: 19.24,
    },
    {
      serial_no: 3,
      candidate: "MALKHAN RAMKISHOR VARMA",
      party: "Bahujan Samaj Party",
      evm_votes: 8076,
      postal_votes: 57,
      total_votes: 8133,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 4,
      candidate: "KIRIT L. SURATI",
      party: "Independent",
      evm_votes: 4190,
      postal_votes: 21,
      total_votes: 4211,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 5,
      candidate: "RAJU VARDE",
      party: "Independent",
      evm_votes: 3139,
      postal_votes: 45,
      total_votes: 3184,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 6,
      candidate: "RAMZAN MANSURI (SENIOR JOURNALIST)",
      party: "Log Party",
      evm_votes: 2652,
      postal_votes: 1,
      total_votes: 2653,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 7,
      candidate: "KADIR MAHEBOOB SAIYED",
      party: "Social Democratic Party Of India",
      evm_votes: 2527,
      postal_votes: 11,
      total_votes: 2538,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 8,
      candidate: "SHEKH MOHMMAD NISHAR (MUNNABHAI GARAGE)",
      party: "Independent",
      evm_votes: 1481,
      postal_votes: 2,
      total_votes: 1483,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 9,
      candidate: "DR. KANUBHAI KHADADIYA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1470,
      postal_votes: 15,
      total_votes: 1485,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "SUMANBEN KHUSHWA (HANSIKA RAJPUT)",
      party: "Sardar Vallabhbhai Patel Party",
      evm_votes: 1397,
      postal_votes: 29,
      total_votes: 1426,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 11,
      candidate: "ADVOCATE NAVINKUMAR SHANKERBHAI PATEL",
      party: "Independent",
      evm_votes: 1091,
      postal_votes: 4,
      total_votes: 1095,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "MOHAMMAD HANIF SHAH",
      party: "Garib Kalyan Party",
      evm_votes: 1089,
      postal_votes: 8,
      total_votes: 1097,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "CHANDANSINGH SHIVBADANSINGH THAKUR",
      party: "Independent",
      evm_votes: 1070,
      postal_votes: 17,
      total_votes: 1087,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "KAZI AYAZ HUSNUDDIN",
      party: "Independent",
      evm_votes: 775,
      postal_votes: 8,
      total_votes: 783,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 19653,
      postal_votes: 809,
      total_votes: 20462,
      percentage_of_votes: 1.53,
    },
  ],
  total: {
    evm_votes: 1326543,
    postal_votes: 11673,
    total_votes: 1338216,
  },
};
export const s0626 = {
  cs_name: "Valsad",
  cs_shortname: "valsad",
  cs_code: "s0626",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DHAVAL LAXMANBHAI PATEL",
      party: "Bharatiya Janata Party",
      evm_votes: 760935,
      postal_votes: 3291,
      total_votes: 764226,
      percentage_of_votes: 56.13,
    },
    {
      serial_no: 2,
      candidate: "ANANTKUMAR HASMUKHBHAI PATEL",
      party: "Indian National Congress",
      evm_votes: 547307,
      postal_votes: 6215,
      total_votes: 553522,
      percentage_of_votes: 40.66,
    },
    {
      serial_no: 3,
      candidate: "MANAKBHAI JATRUBHAI SHANKAR",
      party: "Bahujan Samaj Party",
      evm_votes: 7487,
      postal_votes: 12,
      total_votes: 7499,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 4,
      candidate: "RAMANBHAI KARSHANBHAI PATEL",
      party: "Independent",
      evm_votes: 6732,
      postal_votes: 13,
      total_votes: 6745,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 5,
      candidate: "JAYANTIBHAI KHANDUBHAI SHALUA",
      party: "Viro Ke Vir Indian Party",
      evm_votes: 4763,
      postal_votes: 11,
      total_votes: 4774,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 6,
      candidate: "CHIRAGKUMAR BHARATBHAI PATEL",
      party: "Independent",
      evm_votes: 3431,
      postal_votes: 9,
      total_votes: 3440,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 7,
      candidate: "UMESHBHAI MAGANBHAI PATEL",
      party: "Bahujan Republican Socialist Party",
      evm_votes: 2813,
      postal_votes: 19,
      total_votes: 2832,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 18169,
      postal_votes: 204,
      total_votes: 18373,
      percentage_of_votes: 1.35,
    },
  ],
  total: {
    evm_votes: 1351637,
    postal_votes: 9774,
    total_votes: 1361411,
  },
};
export const s072 = {
  cs_name: "KURUKSHETRA",
  cs_shortname: "kurukshetra",
  cs_code: "s072",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "NAVEEN JINDAL",
      party: "Bharatiya Janata Party",
      evm_votes: 540677,
      postal_votes: 1498,
      total_votes: 542175,
      percentage_of_votes: 44.96,
    },
    {
      serial_no: 2,
      candidate: "DR SUSHIL GUPTA",
      party: "Aam Aadmi Party",
      evm_votes: 511770,
      postal_votes: 1384,
      total_votes: 513154,
      percentage_of_votes: 42.55,
    },
    {
      serial_no: 3,
      candidate: "ABHAY SINGH CHAUTALA",
      party: "Indian National Lok Dal",
      evm_votes: 78362,
      postal_votes: 346,
      total_votes: 78708,
      percentage_of_votes: 6.53,
    },
    {
      serial_no: 4,
      candidate: "DEEPAK MEHRA",
      party: "Bahujan Samaj Party",
      evm_votes: 20864,
      postal_votes: 80,
      total_votes: 20944,
      percentage_of_votes: 1.74,
    },
    {
      serial_no: 5,
      candidate: "JAI KUMAR SAINI HAMIDPUR",
      party: "Independent",
      evm_votes: 8090,
      postal_votes: 3,
      total_votes: 8093,
      percentage_of_votes: 0.67,
    },
    {
      serial_no: 6,
      candidate: "PALA RAM SAINI",
      party: "Jannayak Janta Party",
      evm_votes: 6159,
      postal_votes: 23,
      total_votes: 6182,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 7,
      candidate: "SATBIR KASHYAP",
      party: "Rashtriya Janshakti Party(Eklavya)",
      evm_votes: 3251,
      postal_votes: 12,
      total_votes: 3263,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 8,
      candidate: "KHAZAN SINGH SEHGAL",
      party: "Shiromani Akali Dal (Amritsar)(Simranjit Singh Mann)",
      evm_votes: 2824,
      postal_votes: 35,
      total_votes: 2859,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 9,
      candidate: "MAHAVIR SINGH",
      party: "Ekam Sanatan Bharat Dal",
      evm_votes: 2779,
      postal_votes: 4,
      total_votes: 2783,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 10,
      candidate: "INDERJEET KAMBOJ",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 2107,
      postal_votes: 6,
      total_votes: 2113,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 11,
      candidate: "PANDIT ANIL UPADHYAY",
      party: "Independent",
      evm_votes: 2053,
      postal_votes: 3,
      total_votes: 2056,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "ADV ASHWINI SHARMA HRITTWAL",
      party: "Independent",
      evm_votes: 2027,
      postal_votes: 2,
      total_votes: 2029,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 13,
      candidate: "COMRADE OM PARKASH SHASTRI",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1806,
      postal_votes: 6,
      total_votes: 1812,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 14,
      candidate: "SURENDER SINGH",
      party: "Independent",
      evm_votes: 1599,
      postal_votes: 0,
      total_votes: 1599,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 15,
      candidate: "AMIT MALIK",
      party: "Independent",
      evm_votes: 1503,
      postal_votes: 3,
      total_votes: 1506,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 16,
      candidate: "PATASHO DEVI EX-SARPANCH",
      party: "Independent",
      evm_votes: 1379,
      postal_votes: 0,
      total_votes: 1379,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 17,
      candidate: "DR MAHESH CHAND GAUR",
      party: "Independent",
      evm_votes: 1338,
      postal_votes: 2,
      total_votes: 1340,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 18,
      candidate: "JAI PARKASH SHARMA",
      party: "Independent",
      evm_votes: 1152,
      postal_votes: 2,
      total_votes: 1154,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 19,
      candidate: "VARUN GUPTA",
      party: "Independent",
      evm_votes: 1129,
      postal_votes: 5,
      total_votes: 1134,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 20,
      candidate: "SATNAM BHAZIGAR",
      party: "Loktantrik Lok Rajyam Party",
      evm_votes: 1063,
      postal_votes: 3,
      total_votes: 1066,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 21,
      candidate: "NARESH KUMAR",
      party: "Samata Party",
      evm_votes: 1028,
      postal_votes: 4,
      total_votes: 1032,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 22,
      candidate: "SATISH DHULL",
      party: "Bharatiya Asha Party",
      evm_votes: 991,
      postal_votes: 6,
      total_votes: 997,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 23,
      candidate: "ADVOCATE PARDEEP SAINI",
      party: "Rashtriya Garib Dal",
      evm_votes: 958,
      postal_votes: 2,
      total_votes: 960,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 24,
      candidate: "SEWA SINGH",
      party: "Independent",
      evm_votes: 717,
      postal_votes: 1,
      total_votes: 718,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 25,
      candidate: "ER VISHAL KUMAR",
      party: "Independent",
      evm_votes: 715,
      postal_votes: 0,
      total_votes: 715,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 26,
      candidate: "COMRADE RAMESH KHATKAR",
      party: "Independent",
      evm_votes: 714,
      postal_votes: 3,
      total_votes: 717,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 27,
      candidate: "OM PARKASH INDAL",
      party: "Global Republican Party",
      evm_votes: 675,
      postal_votes: 3,
      total_votes: 678,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 28,
      candidate: "PHOOL SINGH",
      party: "Independent",
      evm_votes: 625,
      postal_votes: 1,
      total_votes: 626,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 29,
      candidate: "SUNNY KASHYAP TEORA",
      party: "Independent",
      evm_votes: 576,
      postal_votes: 0,
      total_votes: 576,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 30,
      candidate: "RAMDIYA",
      party: "Independent",
      evm_votes: 553,
      postal_votes: 3,
      total_votes: 556,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 31,
      candidate: "MANGAT RAM",
      party: "Independent",
      evm_votes: 509,
      postal_votes: 0,
      total_votes: 509,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 32,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 2420,
      postal_votes: 19,
      total_votes: 2439,
      percentage_of_votes: 0.2,
    },
  ],
  total: {
    evm_votes: 1202413,
    postal_votes: 3459,
    total_votes: 1205872,
  },
};
export const s075 = {
  cs_name: "KARNAL",
  cs_shortname: "karnal",
  cs_code: "s075",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "MANOHAR LAL",
      party: "Bharatiya Janata Party",
      evm_votes: 737282,
      postal_votes: 2003,
      total_votes: 739285,
      percentage_of_votes: 54.93,
    },
    {
      serial_no: 2,
      candidate: "DIVYANSHU BUDHIRAJA",
      party: "Indian National Congress",
      evm_votes: 504437,
      postal_votes: 2271,
      total_votes: 506708,
      percentage_of_votes: 37.65,
    },
    {
      serial_no: 3,
      candidate: "INDER SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 32410,
      postal_votes: 98,
      total_votes: 32508,
      percentage_of_votes: 2.42,
    },
    {
      serial_no: 4,
      candidate: "MARATHA VIRENDER VERMA",
      party: "Nationalist Congress Party – Sharadchandra Pawar",
      evm_votes: 28984,
      postal_votes: 167,
      total_votes: 29151,
      percentage_of_votes: 2.17,
    },
    {
      serial_no: 5,
      candidate: "DEVENDER KADIAN",
      party: "Jannayak Janta Party",
      evm_votes: 11399,
      postal_votes: 68,
      total_votes: 11467,
      percentage_of_votes: 0.85,
    },
    {
      serial_no: 6,
      candidate: "MANOJ BANSAL",
      party: "Independent",
      evm_votes: 4000,
      postal_votes: 4,
      total_votes: 4004,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 7,
      candidate: "GULSHAN KASHYAP",
      party: "Independent",
      evm_votes: 2987,
      postal_votes: 7,
      total_votes: 2994,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 8,
      candidate: "ASHOK KATARIA",
      party: "Samst Samaj Party",
      evm_votes: 2898,
      postal_votes: 6,
      total_votes: 2904,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 9,
      candidate: "HARJEET SINGH VIRK",
      party: "Shiromani Akali Dal (Amritsar)(Simranjit Singh Mann)",
      evm_votes: 1857,
      postal_votes: 9,
      total_votes: 1866,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "SONIA",
      party: "Independent",
      evm_votes: 1846,
      postal_votes: 5,
      total_votes: 1851,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "MANISH",
      party: "Independent",
      evm_votes: 1587,
      postal_votes: 0,
      total_votes: 1587,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "LEENA KOHLI",
      party: "Independent",
      evm_votes: 1281,
      postal_votes: 11,
      total_votes: 1292,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 13,
      candidate: "NITIN GEHLOT",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 1252,
      postal_votes: 1,
      total_votes: 1253,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "BALWAN SINGH",
      party: "Independent",
      evm_votes: 1186,
      postal_votes: 2,
      total_votes: 1188,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 15,
      candidate: "VINEET CHOUDHARY",
      party: "Bharatiya Asha Party",
      evm_votes: 1018,
      postal_votes: 2,
      total_votes: 1020,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 16,
      candidate: "ROOP SINGH",
      party: "Rashtriya Garib Dal",
      evm_votes: 766,
      postal_votes: 3,
      total_votes: 769,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 17,
      candidate: "SAWAMI AGNIVESH",
      party: "Independent",
      evm_votes: 748,
      postal_votes: 4,
      total_votes: 752,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 18,
      candidate: "AZAD SINGH PUNIA",
      party: "Independent",
      evm_votes: 703,
      postal_votes: 2,
      total_votes: 705,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 19,
      candidate: "ASHOK KUMAR",
      party: "Independent",
      evm_votes: 631,
      postal_votes: 2,
      total_votes: 633,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 20,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3925,
      postal_votes: 30,
      total_votes: 3955,
      percentage_of_votes: 0.29,
    },
  ],
  total: {
    evm_votes: 1341197,
    postal_votes: 4695,
    total_votes: 1345892,
  },
};
export const s078 = {
  cs_name: "BHIWANI-MAHENDRAGARH",
  cs_shortname: "bhiwanimahendragarh",
  cs_code: "s078",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DHARAMBIR SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 582735,
      postal_votes: 5929,
      total_votes: 588664,
      percentage_of_votes: 49.74,
    },
    {
      serial_no: 2,
      candidate: "RAO DAN SINGH",
      party: "Indian National Congress",
      evm_votes: 541926,
      postal_votes: 5228,
      total_votes: 547154,
      percentage_of_votes: 46.24,
    },
    {
      serial_no: 3,
      candidate: "BAHADUR SINGH",
      party: "Jannayak Janta Party",
      evm_votes: 15076,
      postal_votes: 189,
      total_votes: 15265,
      percentage_of_votes: 1.29,
    },
    {
      serial_no: 4,
      candidate: "SUNIL KUMAR SHARMA",
      party: "Bahujan Samaj Party",
      evm_votes: 6239,
      postal_votes: 97,
      total_votes: 6336,
      percentage_of_votes: 0.54,
    },
    {
      serial_no: 5,
      candidate: "SUBHASH",
      party: "Rashtrawadi Janlok Party (Satya)",
      evm_votes: 4663,
      postal_votes: 32,
      total_votes: 4695,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 6,
      candidate: "HEMANT",
      party: "Independent",
      evm_votes: 3876,
      postal_votes: 5,
      total_votes: 3881,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 7,
      candidate: "SEEMA",
      party: "Independent",
      evm_votes: 2961,
      postal_votes: 18,
      total_votes: 2979,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 8,
      candidate: "VED PRAKASH",
      party: "Independent",
      evm_votes: 1897,
      postal_votes: 3,
      total_votes: 1900,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "ANAND KUMAR",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 1642,
      postal_votes: 26,
      total_votes: 1668,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "YOGBIR SINGH",
      party: "Independent",
      evm_votes: 1473,
      postal_votes: 5,
      total_votes: 1478,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 11,
      candidate: "VARSHA",
      party: "Independent",
      evm_votes: 993,
      postal_votes: 60,
      total_votes: 1053,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 12,
      candidate: "BALWAN",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 854,
      postal_votes: 3,
      total_votes: 857,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "ROHTASH",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 637,
      postal_votes: 3,
      total_votes: 640,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "JAI SINGH",
      party: "Independent",
      evm_votes: 442,
      postal_votes: 9,
      total_votes: 451,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 15,
      candidate: "BHARAT BHUSHAN",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 440,
      postal_votes: 3,
      total_votes: 443,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "ENGINEER MAHAVIR SINGH YADAV",
      party: "Independent",
      evm_votes: 406,
      postal_votes: 9,
      total_votes: 415,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 17,
      candidate: "JAGDISH",
      party: "Independent",
      evm_votes: 219,
      postal_votes: 10,
      total_votes: 229,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 18,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5188,
      postal_votes: 99,
      total_votes: 5287,
      percentage_of_votes: 0.45,
    },
  ],
  total: {
    evm_votes: 1171667,
    postal_votes: 11728,
    total_votes: 1183395,
  },
};
export const s079 = {
  cs_name: "GURGAON",
  cs_shortname: "gurgaon",
  cs_code: "s079",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAO INDERJIT SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 805161,
      postal_votes: 3175,
      total_votes: 808336,
      percentage_of_votes: 50.48,
    },
    {
      serial_no: 2,
      candidate: "RAJ BABBAR",
      party: "Indian National Congress",
      evm_votes: 731696,
      postal_votes: 1561,
      total_votes: 733257,
      percentage_of_votes: 45.79,
    },
    {
      serial_no: 3,
      candidate: "RAHUL YADAV FAZILPURIA",
      party: "Jannayak Janta Party",
      evm_votes: 13214,
      postal_votes: 64,
      total_votes: 13278,
      percentage_of_votes: 0.83,
    },
    {
      serial_no: 4,
      candidate: "VIJAY KHATANA",
      party: "Bahujan Samaj Party",
      evm_votes: 8900,
      postal_votes: 46,
      total_votes: 8946,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 5,
      candidate: "SORAB KHAN",
      party: "Indian National Lok Dal",
      evm_votes: 4897,
      postal_votes: 20,
      total_votes: 4917,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 6,
      candidate: "AJAY KUMAR",
      party: "Independent",
      evm_votes: 3527,
      postal_votes: 1,
      total_votes: 3528,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 7,
      candidate: "ASHOK JANGRA",
      party: "Independent",
      evm_votes: 3002,
      postal_votes: 3,
      total_votes: 3005,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 8,
      candidate: "AZAD SINGH",
      party: "Independent",
      evm_votes: 2744,
      postal_votes: 1,
      total_votes: 2745,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 9,
      candidate: "AKSHAT GAIT",
      party: "Independent",
      evm_votes: 2549,
      postal_votes: 1,
      total_votes: 2550,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 10,
      candidate: "LAL CHAND YADAV",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 2214,
      postal_votes: 8,
      total_votes: 2222,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "SAMAY SINGH",
      party: "Independent",
      evm_votes: 1885,
      postal_votes: 2,
      total_votes: 1887,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "DHARMENDER THAKRAN",
      party: "Swayam Shashan Party",
      evm_votes: 1402,
      postal_votes: 6,
      total_votes: 1408,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "VIJAY YADAV",
      party: "Jan Sewak Kranti Party",
      evm_votes: 1120,
      postal_votes: 0,
      total_votes: 1120,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 14,
      candidate: "ISHWAR SINGH SUTHANI",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 1110,
      postal_votes: 4,
      total_votes: 1114,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 15,
      candidate: "COMRADE SARWAN KUMAR GUPTA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1066,
      postal_votes: 8,
      total_votes: 1074,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 16,
      candidate: "VANDNA GULIYA",
      party: "Right to Recall Party",
      evm_votes: 980,
      postal_votes: 20,
      total_votes: 1000,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 17,
      candidate: "SAMPOORN ANAND",
      party: "Rashtriya Lokswaraj Party",
      evm_votes: 911,
      postal_votes: 4,
      total_votes: 915,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 18,
      candidate: "KUSHESHWAR BHAGAT",
      party: "Independent",
      evm_votes: 794,
      postal_votes: 0,
      total_votes: 794,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 19,
      candidate: "SINGH RAM",
      party: "Independent",
      evm_votes: 789,
      postal_votes: 2,
      total_votes: 791,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 20,
      candidate: "ANWAR",
      party: "Social Democratic Party Of India",
      evm_votes: 572,
      postal_votes: 6,
      total_votes: 578,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 21,
      candidate: "FAUJI JAI KAWAR TYAGI (DIXIT)",
      party: "Independent",
      evm_votes: 469,
      postal_votes: 5,
      total_votes: 474,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "AKASH VYAS",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 436,
      postal_votes: 3,
      total_votes: 439,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 23,
      candidate: "VISHNU",
      party: "Independent",
      evm_votes: 436,
      postal_votes: 1,
      total_votes: 437,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 24,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6377,
      postal_votes: 40,
      total_votes: 6417,
      percentage_of_votes: 0.4,
    },
  ],
  total: {
    evm_votes: 1596251,
    postal_votes: 4981,
    total_votes: 1601232,
  },
};
export const s0710 = {
  cs_name: "FARIDABAD",
  cs_shortname: "faridabad",
  cs_code: "s0710",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "KRISHAN PAL",
      party: "Bharatiya Janata Party",
      evm_votes: 787332,
      postal_votes: 1237,
      total_votes: 788569,
      percentage_of_votes: 53.6,
    },
    {
      serial_no: 2,
      candidate: "MAHENDER PRATAP SINGH",
      party: "Indian National Congress",
      evm_votes: 614908,
      postal_votes: 747,
      total_votes: 615655,
      percentage_of_votes: 41.84,
    },
    {
      serial_no: 3,
      candidate: "KISHAN THAKUR",
      party: "Bahujan Samaj Party",
      evm_votes: 25164,
      postal_votes: 42,
      total_votes: 25206,
      percentage_of_votes: 1.71,
    },
    {
      serial_no: 4,
      candidate: "SUNIL TEWATIA",
      party: "Indian National Lok Dal",
      evm_votes: 8057,
      postal_votes: 28,
      total_votes: 8085,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 5,
      candidate: "NALIN HOODA",
      party: "Jannayak Janta Party",
      evm_votes: 5335,
      postal_votes: 26,
      total_votes: 5361,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 6,
      candidate: "SAWATANTAR SINGH CHAUHAN",
      party: "Jan Shakti Dal",
      evm_votes: 2951,
      postal_votes: 4,
      total_votes: 2955,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 7,
      candidate: "NEERAJ JATAV",
      party: "Independent",
      evm_votes: 2104,
      postal_votes: 4,
      total_votes: 2108,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 8,
      candidate: "HARI SHANKER RAJVANS",
      party: "Adim Bhartiya Dal",
      evm_votes: 1584,
      postal_votes: 0,
      total_votes: 1584,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 9,
      candidate: "ATUL",
      party: "Independent",
      evm_votes: 1456,
      postal_votes: 2,
      total_votes: 1458,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 10,
      candidate: "PANDIT SUMIT KUMAR SHARMA",
      party: "AARAKSHAN VIRODHI PARTY",
      evm_votes: 1439,
      postal_votes: 5,
      total_votes: 1444,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "BRIJABALA",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 1370,
      postal_votes: 10,
      total_votes: 1380,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 12,
      candidate: "SHYAM SUNDER SINGH",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 1247,
      postal_votes: 0,
      total_votes: 1247,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "MAHESH PRATAP SHARMA",
      party: "Rashtriya Vikas Party",
      evm_votes: 1237,
      postal_votes: 3,
      total_votes: 1240,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "SATAY DEO YADAV",
      party: "Buland Bharat Party",
      evm_votes: 953,
      postal_votes: 2,
      total_votes: 955,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "RANDHEER SINGH ALIAS DHEERU KHATANA",
      party: "Akhil Bhartiya Kisan Majdoor Party",
      evm_votes: 919,
      postal_votes: 5,
      total_votes: 924,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 16,
      candidate: "SUNIL KUMAR",
      party: "Independent",
      evm_votes: 888,
      postal_votes: 1,
      total_votes: 889,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 17,
      candidate: "BHARAT BHUSHAN KOLI",
      party: "Rashtra Nirman Party",
      evm_votes: 807,
      postal_votes: 1,
      total_votes: 808,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 18,
      candidate: "SHIV NARAYAN BABA DUBEY",
      party: "Kisan Mazdoor Sangharsh Party",
      evm_votes: 769,
      postal_votes: 2,
      total_votes: 771,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 19,
      candidate: "GYAN CHAND BAINSLA",
      party: "Samrat Mihir Bhoj Samaj Party",
      evm_votes: 751,
      postal_votes: 5,
      total_votes: 756,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 20,
      candidate: "RAJESH GAUTAM",
      party: "Independent",
      evm_votes: 662,
      postal_votes: 1,
      total_votes: 663,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 21,
      candidate: "SWAMI RAJENDRA DEV JI",
      party: "Independent",
      evm_votes: 660,
      postal_votes: 3,
      total_votes: 663,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 22,
      candidate: "GIRRAJ",
      party: "Independent",
      evm_votes: 612,
      postal_votes: 2,
      total_votes: 614,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 23,
      candidate: "LEKHRAM DABANG",
      party: "Independent",
      evm_votes: 610,
      postal_votes: 5,
      total_votes: 615,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 24,
      candidate: "SHAKILA HUSSAIN",
      party: "Loktantrik Janshakti Party",
      evm_votes: 547,
      postal_votes: 4,
      total_votes: 551,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 25,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6808,
      postal_votes: 13,
      total_votes: 6821,
      percentage_of_votes: 0.46,
    },
  ],
  total: {
    evm_votes: 1469170,
    postal_votes: 2152,
    total_votes: 1471322,
  },
};
export const s081 = {
  cs_name: "KANGRA",
  cs_shortname: "kangra",
  cs_code: "s081",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR RAJEEV BHARDWAJ",
      party: "Bharatiya Janata Party",
      evm_votes: 622482,
      postal_votes: 10311,
      total_votes: 632793,
      percentage_of_votes: 61.03,
    },
    {
      serial_no: 2,
      candidate: "ANAND SHARMA",
      party: "Indian National Congress",
      evm_votes: 374346,
      postal_votes: 6552,
      total_votes: 380898,
      percentage_of_votes: 36.74,
    },
    {
      serial_no: 3,
      candidate: "REKHA CHAUDHARY",
      party: "Bahujan Samaj Party",
      evm_votes: 7478,
      postal_votes: 275,
      total_votes: 7753,
      percentage_of_votes: 0.75,
    },
    {
      serial_no: 4,
      candidate: "ADVOCATE SANJAY SHARMA",
      party: "Independent",
      evm_votes: 2304,
      postal_votes: 15,
      total_votes: 2319,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 5,
      candidate: "DR KEHAR SINGH",
      party: "Independent",
      evm_votes: 2129,
      postal_votes: 49,
      total_votes: 2178,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 6,
      candidate: "JEEVAN KUMAR",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 1441,
      postal_votes: 35,
      total_votes: 1476,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 7,
      candidate: "NARAIN SINGH DOGRA",
      party: "Himachal Janta Party",
      evm_votes: 1125,
      postal_votes: 46,
      total_votes: 1171,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 8,
      candidate: "BHUVNESH KUMAR",
      party: "Rashtriya Devbhumi Party",
      evm_votes: 701,
      postal_votes: 55,
      total_votes: 756,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 9,
      candidate: "DEV RAJ BHARDWAJ",
      party: "Rashtriya Samaj Dal (R)",
      evm_votes: 588,
      postal_votes: 19,
      total_votes: 607,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 10,
      candidate: "ACHAL SINGH",
      party: "Independent",
      evm_votes: 491,
      postal_votes: 54,
      total_votes: 545,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6260,
      postal_votes: 112,
      total_votes: 6372,
      percentage_of_votes: 0.61,
    },
  ],
  total: {
    evm_votes: 1019345,
    postal_votes: 17523,
    total_votes: 1036868,
  },
};
export const s082 = {
  cs_name: "MANDI",
  cs_shortname: "mandi",
  cs_code: "s082",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "KANGNA RANAUT",
      party: "Bharatiya Janata Party",
      evm_votes: 527463,
      postal_votes: 9559,
      total_votes: 537022,
      percentage_of_votes: 52.87,
    },
    {
      serial_no: 2,
      candidate: "VIKRAMADITYA SINGH",
      party: "Indian National Congress",
      evm_votes: 453760,
      postal_votes: 8507,
      total_votes: 462267,
      percentage_of_votes: 45.51,
    },
    {
      serial_no: 3,
      candidate: "DR. PRAKASH CHAND BHARDWAJ",
      party: "Bahujan Samaj Party",
      evm_votes: 4320,
      postal_votes: 73,
      total_votes: 4393,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 4,
      candidate: "NARENDER KUMAR",
      party: "Rashtriya Devbhumi Party",
      evm_votes: 2422,
      postal_votes: 25,
      total_votes: 2447,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 5,
      candidate: "RAKHEE GUPTA",
      party: "Independent",
      evm_votes: 1014,
      postal_votes: 37,
      total_votes: 1051,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 6,
      candidate: "SUBHASH MOHAN SNEHI",
      party: "Independent",
      evm_votes: 963,
      postal_votes: 11,
      total_votes: 974,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 7,
      candidate: "VINAY KUMAR",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 674,
      postal_votes: 16,
      total_votes: 690,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 8,
      candidate: "MAHESH SAINI",
      party: "Himachal Janta Party",
      evm_votes: 641,
      postal_votes: 13,
      total_votes: 654,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 9,
      candidate: "DINESH KUMAR BHATI",
      party: "Independent",
      evm_votes: 375,
      postal_votes: 6,
      total_votes: 381,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 10,
      candidate: "ASHUTOSH MAHANT",
      party: "Independent",
      evm_votes: 260,
      postal_votes: 26,
      total_votes: 286,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5586,
      postal_votes: 59,
      total_votes: 5645,
      percentage_of_votes: 0.56,
    },
  ],
  total: {
    evm_votes: 997478,
    postal_votes: 18332,
    total_votes: 1015810,
  },
};
export const s083 = {
  cs_name: "HAMIRPUR_hp",
  cs_shortname: "hamirpur_hp",
  cs_code: "s083",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ANURAG SINGH THAKUR",
      party: "Bharatiya Janata Party",
      evm_votes: 593055,
      postal_votes: 14013,
      total_votes: 607068,
      percentage_of_votes: 57.97,
    },
    {
      serial_no: 2,
      candidate: "SATPAL RAIZADA",
      party: "Indian National Congress",
      evm_votes: 414828,
      postal_votes: 9883,
      total_votes: 424711,
      percentage_of_votes: 40.55,
    },
    {
      serial_no: 3,
      candidate: "HEM RAJ",
      party: "Bahujan Samaj Party",
      evm_votes: 2694,
      postal_votes: 64,
      total_votes: 2758,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 4,
      candidate: "JAGDEEP KUMAR",
      party: "Rashtriya Devbhumi Party",
      evm_votes: 1738,
      postal_votes: 26,
      total_votes: 1764,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 5,
      candidate: "SURENDER KUMAR GAUTAM",
      party: "Independent",
      evm_votes: 1401,
      postal_votes: 9,
      total_votes: 1410,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 6,
      candidate: "SUBEDAR MAJOR KULWANT SINGH PATIAL",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 1176,
      postal_votes: 63,
      total_votes: 1239,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 7,
      candidate: "NAND LAL",
      party: "Independent",
      evm_votes: 978,
      postal_votes: 41,
      total_votes: 1019,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 8,
      candidate: "SUMIT RANA",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 666,
      postal_votes: 9,
      total_votes: 675,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 9,
      candidate: "RAMESH CHAND SARTHI",
      party: "Independent",
      evm_votes: 544,
      postal_votes: 16,
      total_votes: 560,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 10,
      candidate: "ARUN ANKESH SYAL",
      party: "Ekam Sanatan Bharat Dal",
      evm_votes: 412,
      postal_votes: 21,
      total_votes: 433,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 11,
      candidate: "GARIB DASS KATOCH",
      party: "Independent",
      evm_votes: 210,
      postal_votes: 36,
      total_votes: 246,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 12,
      candidate: "GOPI CHAND ATTARI",
      party: "Independent",
      evm_votes: 168,
      postal_votes: 34,
      total_votes: 202,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5100,
      postal_votes: 78,
      total_votes: 5178,
      percentage_of_votes: 0.49,
    },
  ],
  total: {
    evm_votes: 1022970,
    postal_votes: 24293,
    total_votes: 1047263,
  },
};
export const s084 = {
  cs_name: "SHIMLA",
  cs_shortname: "shimla",
  cs_code: "s084",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "Suresh Kumar Kashyap",
      party: "Bharatiya Janata Party",
      evm_votes: 513936,
      postal_votes: 5812,
      total_votes: 519748,
      percentage_of_votes: 53.58,
    },
    {
      serial_no: 2,
      candidate: "Vinod Sultanpuri",
      party: "Indian National Congress",
      evm_votes: 423388,
      postal_votes: 4909,
      total_votes: 428297,
      percentage_of_votes: 44.16,
    },
    {
      serial_no: 3,
      candidate: "Anil Kumar Manget",
      party: "Bahujan Samaj Party",
      evm_votes: 6379,
      postal_votes: 121,
      total_votes: 6500,
      percentage_of_votes: 0.67,
    },
    {
      serial_no: 4,
      candidate: "Suresh Kumar",
      party: "Rashtriya Devbhumi Party",
      evm_votes: 4766,
      postal_votes: 48,
      total_votes: 4814,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 5,
      candidate: "Madan Lal",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 4633,
      postal_votes: 43,
      total_votes: 4676,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 6,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5876,
      postal_votes: 54,
      total_votes: 5930,
      percentage_of_votes: 0.61,
    },
  ],
  total: {
    evm_votes: 958978,
    postal_votes: 10987,
    total_votes: 969965,
  },
};
export const s102 = {
  cs_name: "Belgaum",
  cs_shortname: "belgaum",
  cs_code: "s102",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "JAGADISH SHETTAR",
      party: "Bharatiya Janata Party",
      evm_votes: 756471,
      postal_votes: 5558,
      total_votes: 762029,
      percentage_of_votes: 55.06,
    },
    {
      serial_no: 2,
      candidate: "MRINAL R HEBBALKAR",
      party: "Indian National Congress",
      evm_votes: 580897,
      postal_votes: 2695,
      total_votes: 583592,
      percentage_of_votes: 42.17,
    },
    {
      serial_no: 3,
      candidate: "MAHADEV PATIL",
      party: "Independent",
      evm_votes: 9425,
      postal_votes: 78,
      total_votes: 9503,
      percentage_of_votes: 0.69,
    },
    {
      serial_no: 4,
      candidate: "BASAPPA GURUSIDDAPPA KUMBAR",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 5406,
      postal_votes: 35,
      total_votes: 5441,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 5,
      candidate: "ASHOK APPAYA APPUGOL",
      party: "Bahujan Samaj Party",
      evm_votes: 4350,
      postal_votes: 57,
      total_votes: 4407,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 6,
      candidate: "RAVI SHIVAPPA PADASALAGI",
      party: "Independent",
      evm_votes: 3911,
      postal_votes: 15,
      total_votes: 3926,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 7,
      candidate: "VIJAY S METHRANI",
      party: "Independent",
      evm_votes: 2804,
      postal_votes: 4,
      total_votes: 2808,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 8,
      candidate: "PRAJAAKEEYA MALLAPPA CHOGALA (PREM)",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 1413,
      postal_votes: 20,
      total_votes: 1433,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 9,
      candidate: "LAXMAN JADAGANNAVAR",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1287,
      postal_votes: 15,
      total_votes: 1302,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 10,
      candidate: "NITIN ASHOK MHADAGUT",
      party: "Independent",
      evm_votes: 1286,
      postal_votes: 24,
      total_votes: 1310,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 11,
      candidate: "PUNDALIK ITNAL",
      party: "Independent",
      evm_votes: 1062,
      postal_votes: 37,
      total_votes: 1099,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "ASHOK P HANAJI",
      party: "Independent",
      evm_votes: 668,
      postal_votes: 11,
      total_votes: 679,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "ASHPAKAHMAD USTAD",
      party: "Independent",
      evm_votes: 622,
      postal_votes: 12,
      total_votes: 634,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5683,
      postal_votes: 43,
      total_votes: 5726,
      percentage_of_votes: 0.41,
    },
  ],
  total: {
    evm_votes: 1375285,
    postal_votes: 8604,
    total_votes: 1383889,
  },
};
export const s103 = {
  cs_name: "Bagalkot",
  cs_shortname: "bagalkot",
  cs_code: "s103",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "GADDIGOUDAR. PARVATAGOUDA. CHANDANAGOUDA.",
      party: "Bharatiya Janata Party",
      evm_votes: 667441,
      postal_votes: 3598,
      total_votes: 671039,
      percentage_of_votes: 50.93,
    },
    {
      serial_no: 2,
      candidate: "SAMYUKTA SHIVANAND PATIL",
      party: "Indian National Congress",
      evm_votes: 600741,
      postal_votes: 1899,
      total_votes: 602640,
      percentage_of_votes: 45.74,
    },
    {
      serial_no: 3,
      candidate: "NAGARAJ. S. KALKUTAGAR",
      party: "Independent",
      evm_votes: 5628,
      postal_votes: 1,
      total_votes: 5629,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 4,
      candidate: "PARASHURAM. LAXMAN. NEELANAIK",
      party: "Independent",
      evm_votes: 5084,
      postal_votes: 6,
      total_votes: 5090,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 5,
      candidate: "ANTHOSH SAVVASE",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 4909,
      postal_votes: 14,
      total_votes: 4923,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 6,
      candidate: "JYOTI GULEDGUDDA",
      party: "Independent",
      evm_votes: 4636,
      postal_votes: 9,
      total_votes: 4645,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 7,
      candidate: "M. B. SIDAGONI",
      party: "Bahujan Samaj Party",
      evm_votes: 4453,
      postal_votes: 26,
      total_votes: 4479,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 8,
      candidate: "DR. MUTTU. S. SURAKOD. (MADAR)",
      party: "Independent",
      evm_votes: 3131,
      postal_votes: 4,
      total_votes: 3135,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 9,
      candidate: "PRASHANT RAO",
      party: "Independent",
      evm_votes: 1909,
      postal_votes: 5,
      total_votes: 1914,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "DATTATREYA DASHARATHA TAVARE",
      party: "Independent",
      evm_votes: 1607,
      postal_votes: 13,
      total_votes: 1620,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 11,
      candidate: "AMBROSE D' MELLO",
      party: "Independent",
      evm_votes: 1203,
      postal_votes: 2,
      total_votes: 1205,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 12,
      candidate: "KENGANAL. MALLIKARJUN. BHIMAPPA",
      party: "Independent",
      evm_votes: 1189,
      postal_votes: 18,
      total_votes: 1207,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "MALLIKARJUN H. T.",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1137,
      postal_votes: 7,
      total_votes: 1144,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "RAJESAB BURANSAB. MASALI",
      party: "Independent",
      evm_votes: 988,
      postal_votes: 4,
      total_votes: 992,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "SHARANAPPA MALLAPPA KOTLANNAVAR",
      party: "Independent",
      evm_votes: 830,
      postal_votes: 2,
      total_votes: 832,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 16,
      candidate: "RAVI SHIVAPPA PADASALAGI",
      party: "Independent",
      evm_votes: 654,
      postal_votes: 2,
      total_votes: 656,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 17,
      candidate: "SAIYYAD IMAMSAB JAKALI",
      party: "Tipu Sultan Party",
      evm_votes: 546,
      postal_votes: 4,
      total_votes: 550,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 18,
      candidate: "ABDULAZIZ KALADAGI PENDARI",
      party: "Independent",
      evm_votes: 524,
      postal_votes: 5,
      total_votes: 529,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 19,
      candidate: "MUTTAPPA MUDAKAPPA HIREKUMBI",
      party: "Raita Bharat Party",
      evm_votes: 509,
      postal_votes: 9,
      total_votes: 518,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 20,
      candidate: "SAGAR. BASAPPA. KUMBAR",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 494,
      postal_votes: 5,
      total_votes: 499,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 21,
      candidate: "S. M. MARIGOUDAR",
      party: "Desh Prem Party",
      evm_votes: 451,
      postal_votes: 3,
      total_votes: 454,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "SHANKAR. VENKAPPA. NAIKAR.",
      party: "Republican Party of India (A)",
      evm_votes: 431,
      postal_votes: 10,
      total_votes: 441,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 23,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3398,
      postal_votes: 22,
      total_votes: 3420,
      percentage_of_votes: 0.26,
    },
  ],
  total: {
    evm_votes: 1311893,
    postal_votes: 5668,
    total_votes: 1317561,
  },
};
export const s104 = {
  cs_name: "Bijapur",
  cs_shortname: "bijapur",
  cs_code: "s104",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAMESH JIGAJINAGI",
      party: "Bharatiya Janata Party",
      evm_votes: 669055,
      postal_votes: 3726,
      total_votes: 672781,
      percentage_of_votes: 51.91,
    },
    {
      serial_no: 2,
      candidate: "RAJU ALAGUR.",
      party: "Indian National Congress",
      evm_votes: 593277,
      postal_votes: 2275,
      total_votes: 595552,
      percentage_of_votes: 45.95,
    },
    {
      serial_no: 3,
      candidate: "GANAPATI RATHOD (HANJAGI)",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 7652,
      postal_votes: 39,
      total_votes: 7691,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 4,
      candidate: "BHOVI TARABAI",
      party: "Independent",
      evm_votes: 4342,
      postal_votes: 11,
      total_votes: 4353,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 5,
      candidate: "RAMJI YAMANAPPA BUDDHAPRIYA",
      party: "Naki Bharatiya Ekta Party",
      evm_votes: 2414,
      postal_votes: 12,
      total_votes: 2426,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 6,
      candidate: "NAGAJYOTI.B.N.",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2375,
      postal_votes: 17,
      total_votes: 2392,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 7,
      candidate: "RAJAKUMAR APPANNA HONNAKATTI",
      party: "Rashtriya Samaj Paksha",
      evm_votes: 1784,
      postal_votes: 10,
      total_votes: 1794,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 8,
      candidate: "JITENDRA.ASHOK. KAMBLE",
      party: "Republican Party of India (A)",
      evm_votes: 1605,
      postal_votes: 18,
      total_votes: 1623,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7482,
      postal_votes: 20,
      total_votes: 7502,
      percentage_of_votes: 0.58,
    },
  ],
  total: {
    evm_votes: 1289986,
    postal_votes: 6128,
    total_votes: 1296114,
  },
};
export const s1010 = {
  cs_name: "Haveri",
  cs_shortname: "haveri",
  cs_code: "s1010",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BASAVARAJ BOMMAI",
      party: "Bharatiya Janata Party",
      evm_votes: 702189,
      postal_votes: 3349,
      total_votes: 705538,
      percentage_of_votes: 50.55,
    },
    {
      serial_no: 2,
      candidate: "ANANDSWAMY GADDADEVARMATH",
      party: "Indian National Congress",
      evm_votes: 660191,
      postal_votes: 1834,
      total_votes: 662025,
      percentage_of_votes: 47.43,
    },
    {
      serial_no: 3,
      candidate: "SUNANDA KARIYAPPA SHIRHATTI",
      party: "Independent",
      evm_votes: 4705,
      postal_votes: 2,
      total_votes: 4707,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 4,
      candidate: "KHAJAMOHIDDIN GUDAGERI",
      party: "Socialist Party (India)",
      evm_votes: 3398,
      postal_votes: 3,
      total_votes: 3401,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 5,
      candidate: "GANGADHAR BADIGER",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2264,
      postal_votes: 6,
      total_votes: 2270,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 6,
      candidate: "RUDRAPPA BASAPPA KUMBAR",
      party: "Independent",
      evm_votes: 1352,
      postal_votes: 4,
      total_votes: 1356,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 7,
      candidate: "BASAVARAJ B HADI",
      party: "Independent",
      evm_votes: 1065,
      postal_votes: 6,
      total_votes: 1071,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 8,
      candidate: "PRAJAKEEYA SACHINKUMAR KARJEKANNAVAR",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 1058,
      postal_votes: 14,
      total_votes: 1072,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 9,
      candidate: "DR. G H IMRAPUR",
      party: "Independent",
      evm_votes: 930,
      postal_votes: 23,
      total_votes: 953,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 10,
      candidate: "JAGADISH YALLAPPA BANKAPURA",
      party: "Independent",
      evm_votes: 660,
      postal_votes: 14,
      total_votes: 674,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 11,
      candidate: "TANU CHIKKANNA YADAV",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 628,
      postal_votes: 8,
      total_votes: 636,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 12,
      candidate: "H K NARASIMHAPPA",
      party: "Samajwadi Janata Party(Karnataka)",
      evm_votes: 445,
      postal_votes: 1,
      total_votes: 446,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 13,
      candidate: "VISHWANATH SHEERI",
      party: "Ekam Sanatan Bharat Dal",
      evm_votes: 401,
      postal_votes: 0,
      total_votes: 401,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 14,
      candidate: "RASHEEDA BEGUM",
      party: "Indian Movement Party",
      evm_votes: 396,
      postal_votes: 1,
      total_votes: 397,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10831,
      postal_votes: 34,
      total_votes: 10865,
      percentage_of_votes: 0.78,
    },
  ],
  total: {
    evm_votes: 1390513,
    postal_votes: 5299,
    total_votes: 1395812,
  },
};
export const s1011 = {
  cs_name: "Dharwad",
  cs_shortname: "dharwad",
  cs_code: "s1011",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "PRALHAD JOSHI",
      party: "Bharatiya Janata Party",
      evm_votes: 713649,
      postal_votes: 2582,
      total_votes: 716231,
      percentage_of_votes: 52.41,
    },
    {
      serial_no: 2,
      candidate: "VINOD ASOOTI",
      party: "Indian National Congress",
      evm_votes: 617616,
      postal_votes: 1291,
      total_votes: 618907,
      percentage_of_votes: 45.29,
    },
    {
      serial_no: 3,
      candidate: "SHAKEELAHMAD.D.MULLA",
      party: "Independent",
      evm_votes: 5866,
      postal_votes: 6,
      total_votes: 5872,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 4,
      candidate: "S. S. PATIL",
      party: "Independent",
      evm_votes: 5153,
      postal_votes: 8,
      total_votes: 5161,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 5,
      candidate: "RAJU ANANTSA NAIKWADI",
      party: "Independent",
      evm_votes: 3601,
      postal_votes: 0,
      total_votes: 3601,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 6,
      candidate: "JAVEED AHAMED BELGAUMKAR.",
      party: "Naki Bharatiya Ekta Party",
      evm_votes: 3551,
      postal_votes: 7,
      total_votes: 3558,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 7,
      candidate: "SHARANABASAV GONAVAR",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1148,
      postal_votes: 19,
      total_votes: 1167,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 8,
      candidate: "BALANAGOUDRA MALLIKARJUNAGOUDA",
      party: "Independent",
      evm_votes: 986,
      postal_votes: 1,
      total_votes: 987,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 9,
      candidate: "TAKAPPA YALLAPPA KALAL",
      party: "Prahar Janshakti Party",
      evm_votes: 901,
      postal_votes: 4,
      total_votes: 905,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 10,
      candidate: "BUGADI BASAVALINGAPPA IRAPPA",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 796,
      postal_votes: 4,
      total_votes: 800,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 11,
      candidate: "NAGARAJ. KARENNAVAR.",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 553,
      postal_votes: 2,
      total_votes: 555,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 12,
      candidate: "PRAVEEN H. H.",
      party: "Independent",
      evm_votes: 545,
      postal_votes: 3,
      total_votes: 548,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 13,
      candidate: "DR. GURAPPA H IMRAPUR",
      party: "Independent",
      evm_votes: 510,
      postal_votes: 4,
      total_votes: 514,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 14,
      candidate: "BANKAPUR SHOUKATALI",
      party: "Tipu Sultan Party",
      evm_votes: 464,
      postal_votes: 9,
      total_votes: 473,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 15,
      candidate: "VINOD DASHARATH GHODKE",
      party: "Proutist Bloc, India",
      evm_votes: 409,
      postal_votes: 2,
      total_votes: 411,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 16,
      candidate: "VENKATESH PRASAD. H",
      party: "Indian Labour Party (Ambedkar Phule)",
      evm_votes: 334,
      postal_votes: 3,
      total_votes: 337,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 17,
      candidate: "MOHAMMED ISMAIL MUKTI",
      party: "Bharatiya Prajagala Kalyana Paksha",
      evm_votes: 313,
      postal_votes: 4,
      total_votes: 317,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 18,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6126,
      postal_votes: 21,
      total_votes: 6147,
      percentage_of_votes: 0.45,
    },
  ],
  total: {
    evm_votes: 1362521,
    postal_votes: 3970,
    total_votes: 1366491,
  },
};
export const s1012 = {
  cs_name: "Uttara_Kannada",
  cs_shortname: "uttarakannada",
  cs_code: "s1012",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "VISHWESHWAR HEGDE KAGERI",
      party: "Bharatiya Janata Party",
      evm_votes: 777887,
      postal_votes: 4608,
      total_votes: 782495,
      percentage_of_votes: 61.97,
    },
    {
      serial_no: 2,
      candidate: "DR. ANJALI NIMBALKAR",
      party: "Indian National Congress",
      evm_votes: 443296,
      postal_votes: 1771,
      total_votes: 445067,
      percentage_of_votes: 35.25,
    },
    {
      serial_no: 3,
      candidate: "RAJSHEKHAR. SHANKAR. HINDALAGI.",
      party: "Independent",
      evm_votes: 5384,
      postal_votes: 13,
      total_votes: 5397,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 4,
      candidate: "GANAPATI HEGDE",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 4427,
      postal_votes: 45,
      total_votes: 4472,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 5,
      candidate: "NIRANJAN UDAYSINH SIRDESAI",
      party: "Independent",
      evm_votes: 4116,
      postal_votes: 53,
      total_votes: 4169,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 6,
      candidate: "NAGARAJ. ANANT. SHIRALI.",
      party: "Independent",
      evm_votes: 2860,
      postal_votes: 22,
      total_votes: 2882,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 7,
      candidate: "CHIDANAND HANUMANTHAPPA HARIJAN",
      party: "Independent",
      evm_votes: 1707,
      postal_votes: 14,
      total_votes: 1721,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 8,
      candidate: "PRAJAAKEEYA SUNIL",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 1523,
      postal_votes: 43,
      total_votes: 1566,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 9,
      candidate: "VINAYAK NAIK",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 1230,
      postal_votes: 33,
      total_votes: 1263,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 10,
      candidate: "K. P. PATIL",
      party: "Independent",
      evm_votes: 901,
      postal_votes: 55,
      total_votes: 956,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 11,
      candidate: "KRISHNA BALEGAR",
      party: "Independent",
      evm_votes: 897,
      postal_votes: 23,
      total_votes: 920,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "ARAVIND GOWDA",
      party: "Independent",
      evm_votes: 872,
      postal_votes: 32,
      total_votes: 904,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "AVINASH. NARAYAN. PATIL.",
      party: "Independent",
      evm_votes: 761,
      postal_votes: 12,
      total_votes: 773,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10151,
      postal_votes: 25,
      total_votes: 10176,
      percentage_of_votes: 0.81,
    },
  ],
  total: {
    evm_votes: 1256012,
    postal_votes: 6749,
    total_votes: 1262761,
  },
};
export const s1014 = {
  cs_name: "Shimoga",
  cs_shortname: "shimoga",
  cs_code: "s1014",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "B.Y.RAGHAVENDRA",
      party: "Bharatiya Janata Party",
      evm_votes: 775731,
      postal_votes: 2990,
      total_votes: 778721,
      percentage_of_votes: 56.54,
    },
    {
      serial_no: 2,
      candidate: "GEETHA SHIVARAJKUMAR",
      party: "Indian National Congress",
      evm_votes: 533622,
      postal_votes: 1384,
      total_votes: 535006,
      percentage_of_votes: 38.85,
    },
    {
      serial_no: 3,
      candidate: "K. S. ESHWARAPPA",
      party: "Independent",
      evm_votes: 29959,
      postal_votes: 91,
      total_votes: 30050,
      percentage_of_votes: 2.18,
    },
    {
      serial_no: 4,
      candidate: "BANDI",
      party: "Independent",
      evm_votes: 7265,
      postal_votes: 1,
      total_votes: 7266,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 5,
      candidate: "RAVIKUMARA N.",
      party: "Independent",
      evm_votes: 4551,
      postal_votes: 1,
      total_votes: 4552,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 6,
      candidate: "POOJA N. ANNAIAH",
      party: "Independent",
      evm_votes: 3454,
      postal_votes: 3,
      total_votes: 3457,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 7,
      candidate: "A.D. SHIVAPPA",
      party: "Bahujan Samaj Party",
      evm_votes: 2772,
      postal_votes: 7,
      total_votes: 2779,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 8,
      candidate: "N. V. NAVEEN KUMAR",
      party: "Independent",
      evm_votes: 1992,
      postal_votes: 1,
      total_votes: 1993,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 9,
      candidate: "ARUNA KANAHALLI",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 1475,
      postal_votes: 3,
      total_votes: 1478,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "E.H. NAYAK",
      party: "Independent",
      evm_votes: 953,
      postal_votes: 1,
      total_votes: 954,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "JOHN BENNY",
      party: "Independent",
      evm_votes: 863,
      postal_votes: 4,
      total_votes: 867,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "GANESH. B (BELLI)",
      party: "Independent",
      evm_votes: 736,
      postal_votes: 11,
      total_votes: 747,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "D.S. ESHWARAPPA",
      party: "Independent",
      evm_votes: 687,
      postal_votes: 8,
      total_votes: 695,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "KUNAJE MANJUNATHA GOWDA",
      party: "Independent",
      evm_votes: 671,
      postal_votes: 12,
      total_votes: 683,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "S.K PRABHU",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 611,
      postal_votes: 6,
      total_votes: 617,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "SHIVARUDRAIAH SWAMY",
      party: "Independent",
      evm_votes: 599,
      postal_votes: 0,
      total_votes: 599,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 17,
      candidate: "IMTIYAZ A. ATTAR",
      party: "Independent",
      evm_votes: 440,
      postal_votes: 2,
      total_votes: 442,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 18,
      candidate: "MOHAMMED YUSUF KHAN",
      party: "Young Star Empowerment Party",
      evm_votes: 403,
      postal_votes: 1,
      total_votes: 404,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 19,
      candidate: "G. JAYADEVA",
      party: "Independent",
      evm_votes: 367,
      postal_votes: 1,
      total_votes: 368,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 20,
      candidate: "CHANDRASHEKAR. H C",
      party: "Independent",
      evm_votes: 352,
      postal_votes: 5,
      total_votes: 357,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 21,
      candidate: "P. SRIPATHI. BHAT",
      party: "Independent",
      evm_votes: 343,
      postal_votes: 1,
      total_votes: 344,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 22,
      candidate: "SANDESH SHETTY. A",
      party: "Independent",
      evm_votes: 292,
      postal_votes: 1,
      total_votes: 293,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 23,
      candidate: "H SURESH POOJARI",
      party: "Independent",
      evm_votes: 219,
      postal_votes: 1,
      total_votes: 220,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 24,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4311,
      postal_votes: 21,
      total_votes: 4332,
      percentage_of_votes: 0.31,
    },
  ],
  total: {
    evm_votes: 1372668,
    postal_votes: 4556,
    total_votes: 1377224,
  },
};
export const s1015 = {
  cs_name: "Udupi_Chikmagalur",
  cs_shortname: "udupichikmagalur",
  cs_code: "s1015",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "KOTA SRINIVAS POOJARY",
      party: "Bharatiya Janata Party",
      evm_votes: 728219,
      postal_votes: 4015,
      total_votes: 732234,
      percentage_of_votes: 59.56,
    },
    {
      serial_no: 2,
      candidate: "K. JAYAPRAKASH HEGDE",
      party: "Indian National Congress",
      evm_votes: 470769,
      postal_votes: 2290,
      total_votes: 473059,
      percentage_of_votes: 38.48,
    },
    {
      serial_no: 3,
      candidate: "K.T. RADHAKRISHNA",
      party: "Bahujan Samaj Party",
      evm_votes: 5378,
      postal_votes: 39,
      total_votes: 5417,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 4,
      candidate: "SUDHEER KANCHAN MARAKALA",
      party: "Independent",
      evm_votes: 2281,
      postal_votes: 5,
      total_votes: 2286,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 5,
      candidate: "SACHIN B.K",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 1299,
      postal_votes: 34,
      total_votes: 1333,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 6,
      candidate: "M. K. DAYANANDA",
      party: "Proutist Sarva Samaj",
      evm_votes: 1049,
      postal_votes: 20,
      total_votes: 1069,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 7,
      candidate: "VIJAY KUMAR M. G",
      party: "Independent",
      evm_votes: 943,
      postal_votes: 13,
      total_votes: 956,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 8,
      candidate: "SUPREETH KUMAR POOJARY KATEEL",
      party: "Janahitha Paksha",
      evm_votes: 670,
      postal_votes: 20,
      total_votes: 690,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 9,
      candidate: "SHABAREESH",
      party: "Karunada Sevakara Party",
      evm_votes: 560,
      postal_votes: 5,
      total_votes: 565,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 10,
      candidate: "L. RANGANATH GOWDA",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 536,
      postal_votes: 4,
      total_votes: 540,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11235,
      postal_votes: 34,
      total_votes: 11269,
      percentage_of_votes: 0.92,
    },
  ],
  total: {
    evm_votes: 1222939,
    postal_votes: 6479,
    total_votes: 1229418,
  },
};
export const s1017 = {
  cs_name: "Dakshina_Kannada",
  cs_shortname: "dakshinakannada",
  cs_code: "s1017",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "CAPTAIN BRIJESH CHOWTA",
      party: "Bharatiya Janata Party",
      evm_votes: 760130,
      postal_votes: 4002,
      total_votes: 764132,
      percentage_of_votes: 53.97,
    },
    {
      serial_no: 2,
      candidate: "PADMARAJ.R.POOJARY",
      party: "Indian National Congress",
      evm_votes: 612103,
      postal_votes: 2821,
      total_votes: 614924,
      percentage_of_votes: 43.43,
    },
    {
      serial_no: 3,
      candidate: "KANTHAPPA ALANGAR",
      party: "Bahujan Samaj Party",
      evm_votes: 4202,
      postal_votes: 30,
      total_votes: 4232,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 4,
      candidate: "DURGA PRASAD",
      party: "Karunada Sevakara Party",
      evm_votes: 2578,
      postal_votes: 14,
      total_votes: 2592,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 5,
      candidate: "SUPREETH KUMAR POOJARY KATEEL",
      party: "Independent",
      evm_votes: 1884,
      postal_votes: 17,
      total_votes: 1901,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 6,
      candidate: "MAXIM PINTO",
      party: "Independent",
      evm_votes: 1654,
      postal_votes: 36,
      total_votes: 1690,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 7,
      candidate: "PRAJAAKEEYA MANOHARA",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 964,
      postal_votes: 7,
      total_votes: 971,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 8,
      candidate: "DEEPAK RAJESH COELHO",
      party: "Independent",
      evm_votes: 950,
      postal_votes: 26,
      total_votes: 976,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 9,
      candidate: "RANJINI. M",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 758,
      postal_votes: 18,
      total_votes: 776,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 23515,
      postal_votes: 61,
      total_votes: 23576,
      percentage_of_votes: 1.67,
    },
  ],
  total: {
    evm_votes: 1408738,
    postal_votes: 7032,
    total_votes: 1415770,
  },
};
export const s1018 = {
  cs_name: "Chitradurga",
  cs_shortname: "chitradurga",
  cs_code: "s1018",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "GOVIND MAKTHAPPA KARJOL",
      party: "Bharatiya Janata Party",
      evm_votes: 681217,
      postal_votes: 3673,
      total_votes: 684890,
      percentage_of_votes: 50.11,
    },
    {
      serial_no: 2,
      candidate: "B.N.CHANDRAPPA",
      party: "Indian National Congress",
      evm_votes: 634152,
      postal_votes: 2617,
      total_votes: 636769,
      percentage_of_votes: 46.58,
    },
    {
      serial_no: 3,
      candidate: "ASHOKA CHAKRAVARTHI",
      party: "Bahujan Samaj Party",
      evm_votes: 7679,
      postal_votes: 26,
      total_votes: 7705,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 4,
      candidate: "NARASIMHARAJU. C.N.",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 4932,
      postal_votes: 18,
      total_votes: 4950,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 5,
      candidate: "SRINIVASAPURADA SRINIVASABABU PAVAGADA",
      party: "Independent",
      evm_votes: 4539,
      postal_votes: 9,
      total_votes: 4548,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 6,
      candidate: "B. VENKATESHA, SHILPI",
      party: "Independent",
      evm_votes: 4196,
      postal_votes: 5,
      total_votes: 4201,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 7,
      candidate: "MANJUNATHA SWAMY T",
      party: "Independent",
      evm_votes: 2652,
      postal_votes: 24,
      total_votes: 2676,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 8,
      candidate: "BHUTHARAJA. V.S.",
      party: "Independent",
      evm_votes: 2386,
      postal_votes: 11,
      total_votes: 2397,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 9,
      candidate: "RAGHU KUMAR .S",
      party: "Independent",
      evm_votes: 2318,
      postal_votes: 19,
      total_votes: 2337,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 10,
      candidate: "RAMESH NAIK .T.",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 2187,
      postal_votes: 19,
      total_votes: 2206,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 11,
      candidate: "NAGARAJAPPA",
      party: "Independent",
      evm_votes: 1674,
      postal_votes: 5,
      total_votes: 1679,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "K NARASHIMAMURTHY",
      party: "Independent",
      evm_votes: 1385,
      postal_votes: 12,
      total_votes: 1397,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 13,
      candidate: "SUDHAKARA .R",
      party: "Independent",
      evm_votes: 1201,
      postal_votes: 10,
      total_votes: 1211,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "TULASI.H",
      party: "Independent",
      evm_votes: 1065,
      postal_votes: 17,
      total_votes: 1082,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "GANESH.",
      party: "Independent",
      evm_votes: 1048,
      postal_votes: 45,
      total_votes: 1093,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 16,
      candidate: "SHABAREESH.",
      party: "Karunada Sevakara Party",
      evm_votes: 1014,
      postal_votes: 4,
      total_votes: 1018,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 17,
      candidate: "SUJATHA .D.",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 996,
      postal_votes: 18,
      total_votes: 1014,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "DR M.P. DARAKESWARAIAH",
      party: "Independent",
      evm_votes: 982,
      postal_votes: 10,
      total_votes: 992,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 19,
      candidate: "AMRUTH RAJA.",
      party: "Independent",
      evm_votes: 804,
      postal_votes: 30,
      total_votes: 834,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 20,
      candidate: "B.T. RAMASUBBAIAH",
      party: "Indian Movement Party",
      evm_votes: 718,
      postal_votes: 2,
      total_votes: 720,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 21,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3156,
      postal_votes: 34,
      total_votes: 3190,
      percentage_of_votes: 0.23,
    },
  ],
  total: {
    evm_votes: 1360301,
    postal_votes: 6608,
    total_votes: 1366909,
  },
};
export const s1019 = {
  cs_name: "Tumkur",
  cs_shortname: "tumkur",
  cs_code: "s1019",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "V. SOMANNA",
      party: "Bharatiya Janata Party",
      evm_votes: 716709,
      postal_votes: 4237,
      total_votes: 720946,
      percentage_of_votes: 55.31,
    },
    {
      serial_no: 2,
      candidate: "S.P. MUDDAHANUMEGOWDA",
      party: "Indian National Congress",
      evm_votes: 543064,
      postal_votes: 2288,
      total_votes: 545352,
      percentage_of_votes: 41.84,
    },
    {
      serial_no: 3,
      candidate: "J.K. SAMI",
      party: "Independent",
      evm_votes: 6761,
      postal_votes: 14,
      total_votes: 6775,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 4,
      candidate: "RAJASIMHA J. N.",
      party: "Bahujan Samaj Party",
      evm_votes: 5521,
      postal_votes: 33,
      total_votes: 5554,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 5,
      candidate: "S.N. SWAMY",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 4582,
      postal_votes: 9,
      total_votes: 4591,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 6,
      candidate: "RANGANATHA. R.S",
      party: "Independent",
      evm_votes: 3129,
      postal_votes: 3,
      total_votes: 3132,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 7,
      candidate: "MALLIKARJUNAIAH",
      party: "Independent",
      evm_votes: 1872,
      postal_votes: 8,
      total_votes: 1880,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 8,
      candidate: "SIDDARAMEGOWDA T. B",
      party: "Independent",
      evm_votes: 1644,
      postal_votes: 2,
      total_votes: 1646,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 9,
      candidate: "PRADEEP KUMAR DODDAMUDDEGOWDA",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 1606,
      postal_votes: 12,
      total_votes: 1618,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 10,
      candidate: "H.L. MOHAN KUMAR",
      party: "Independent",
      evm_votes: 890,
      postal_votes: 3,
      total_votes: 893,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "PRAKASH. R.A. JAIN",
      party: "Independent",
      evm_votes: 859,
      postal_votes: 4,
      total_votes: 863,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "NEELAKANTESHA. H S",
      party: "Independent",
      evm_votes: 710,
      postal_votes: 9,
      total_votes: 719,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "R. PUSHPA",
      party: "Independent",
      evm_votes: 622,
      postal_votes: 7,
      total_votes: 629,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "B. DEVARAJ",
      party: "Independent",
      evm_votes: 491,
      postal_votes: 8,
      total_votes: 499,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 15,
      candidate: "R. NARAYANAPPA",
      party: "Independent",
      evm_votes: 483,
      postal_votes: 24,
      total_votes: 507,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "DR. H.B.M. HIREMATH",
      party: "Kannada Paksha",
      evm_votes: 477,
      postal_votes: 0,
      total_votes: 477,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 17,
      candidate: "KAPANIGOWDA.",
      party: "Independent",
      evm_votes: 451,
      postal_votes: 20,
      total_votes: 471,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 18,
      candidate: "BASAVARAJU. H.R",
      party: "National Maha Sabha Party",
      evm_votes: 433,
      postal_votes: 6,
      total_votes: 439,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 19,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6426,
      postal_votes: 34,
      total_votes: 6460,
      percentage_of_votes: 0.5,
    },
  ],
  total: {
    evm_votes: 1296730,
    postal_votes: 6721,
    total_votes: 1303451,
  },
};
export const s1021 = {
  cs_name: "Mysore",
  cs_shortname: "mysore",
  cs_code: "s1021",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "YADUVEER KRISHNADATTA CHAMARAJA WADIYAR",
      party: "Bharatiya Janata Party",
      evm_votes: 790391,
      postal_votes: 5112,
      total_votes: 795503,
      percentage_of_votes: 53.59,
    },
    {
      serial_no: 2,
      candidate: "M. LAKSHMANA",
      party: "Indian National Congress",
      evm_votes: 653739,
      postal_votes: 2502,
      total_votes: 656241,
      percentage_of_votes: 44.21,
    },
    {
      serial_no: 3,
      candidate: "RANGASWAMY. M",
      party: "Independent",
      evm_votes: 4301,
      postal_votes: 5,
      total_votes: 4306,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 4,
      candidate: "KADAMBA NAA AMBARISH",
      party: "Karnataka Jantha Paksha",
      evm_votes: 4069,
      postal_votes: 11,
      total_votes: 4080,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 5,
      candidate: "A. S. SATHISH",
      party: "Independent",
      evm_votes: 3736,
      postal_votes: 10,
      total_votes: 3746,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 6,
      candidate: "RAMAMURTHY. M",
      party: "Independent",
      evm_votes: 3601,
      postal_votes: 4,
      total_votes: 3605,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 7,
      candidate: "RAJU",
      party: "Independent",
      evm_votes: 1940,
      postal_votes: 9,
      total_votes: 1949,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 8,
      candidate: "PRAJAKEEYA LEELA SHIVAKUMAR",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 1724,
      postal_votes: 30,
      total_votes: 1754,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 9,
      candidate: "COMRADE SUNIL T. R",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1538,
      postal_votes: 22,
      total_votes: 1560,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "H. K. KRISHNA",
      party: "Karunaadu Party",
      evm_votes: 1479,
      postal_votes: 10,
      total_votes: 1489,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "HARISH GOWDA",
      party: "Socialist Party (India)",
      evm_votes: 967,
      postal_votes: 9,
      total_votes: 976,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "P. S. YADURAPPA",
      party: "Independent",
      evm_votes: 874,
      postal_votes: 4,
      total_votes: 878,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "AMBEDKAR. C. J",
      party: "Independent",
      evm_votes: 821,
      postal_votes: 14,
      total_votes: 835,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 14,
      candidate: "DARSHAN K PONNETI",
      party: "Independent",
      evm_votes: 761,
      postal_votes: 3,
      total_votes: 764,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "M. S. PRAVEEN",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 735,
      postal_votes: 5,
      total_votes: 740,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "CHRISTOPHER RAJ KUMAR",
      party: "Independent",
      evm_votes: 533,
      postal_votes: 11,
      total_votes: 544,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 17,
      candidate: "DR. H. M. NANJUNDASWAMY",
      party: "Samajwadi Janata Party(Karnataka)",
      evm_votes: 519,
      postal_votes: 4,
      total_votes: 523,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 18,
      candidate: "A. G. RAMACHANDRA RAO",
      party: "Secular Democratic Congress",
      evm_votes: 487,
      postal_votes: 3,
      total_votes: 490,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 19,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4446,
      postal_votes: 44,
      total_votes: 4490,
      percentage_of_votes: 0.3,
    },
  ],
  total: {
    evm_votes: 1476661,
    postal_votes: 7812,
    total_votes: 1484473,
  },
};
export const s1023 = {
  cs_name: "Bangalore_Rural",
  cs_shortname: "bangalorerural",
  cs_code: "s1023",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR C N MANJUNATH",
      party: "Bharatiya Janata Party",
      evm_votes: 1075553,
      postal_votes: 3449,
      total_votes: 1079002,
      percentage_of_votes: 56.21,
    },
    {
      serial_no: 2,
      candidate: "D K SURESH",
      party: "Indian National Congress",
      evm_votes: 807459,
      postal_votes: 1896,
      total_votes: 809355,
      percentage_of_votes: 42.16,
    },
    {
      serial_no: 3,
      candidate: "ABHISHEK K",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 6191,
      postal_votes: 11,
      total_votes: 6202,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 4,
      candidate: "SURESH M N",
      party: "Independent",
      evm_votes: 3231,
      postal_votes: 5,
      total_votes: 3236,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 5,
      candidate: "HEMAVATHI K",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1768,
      postal_votes: 6,
      total_votes: 1774,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 6,
      candidate: "J T PRAKASH",
      party: "Independent",
      evm_votes: 1468,
      postal_votes: 4,
      total_votes: 1472,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 7,
      candidate: "N KRISHNAPPA",
      party: "Pyramid Party of India",
      evm_votes: 1396,
      postal_votes: 6,
      total_votes: 1402,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 8,
      candidate: "C N MANJUNATHA",
      party: "Bahujan Bharat Party",
      evm_votes: 1350,
      postal_votes: 50,
      total_votes: 1400,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 9,
      candidate: "KUMAR L",
      party: "Bharatiya Prajagala Kalyana Paksha",
      evm_votes: 1052,
      postal_votes: 14,
      total_votes: 1066,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 10,
      candidate: "MAHAMAD MUSADIK PASHA",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 817,
      postal_votes: 4,
      total_votes: 821,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 11,
      candidate: "NARASIMHAMURTHY J P",
      party: "Independent",
      evm_votes: 809,
      postal_votes: 4,
      total_votes: 813,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 12,
      candidate: "SURESH S",
      party: "Karunaadu Party",
      evm_votes: 792,
      postal_votes: 6,
      total_votes: 798,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 13,
      candidate: "VASIST J",
      party: "Country Citizen Party",
      evm_votes: 546,
      postal_votes: 38,
      total_votes: 584,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 14,
      candidate: "MOHAMMAD DASTAGEER",
      party: "Young Star Empowerment Party",
      evm_votes: 486,
      postal_votes: 0,
      total_votes: 486,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 15,
      candidate: "H V CHANDRASHEKAR",
      party: "Viduthalai Chiruthaigal Katchi",
      evm_votes: 473,
      postal_votes: 7,
      total_votes: 480,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10633,
      postal_votes: 16,
      total_votes: 10649,
      percentage_of_votes: 0.55,
    },
  ],
  total: {
    evm_votes: 1914024,
    postal_votes: 5516,
    total_votes: 1919540,
  },
};
export const s1024 = {
  cs_name: "Bangalore_North",
  cs_shortname: "bangalorenorth",
  cs_code: "s1024",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SHOBHA KARANDLAJE",
      party: "Bharatiya Janata Party",
      evm_votes: 982805,
      postal_votes: 3244,
      total_votes: 986049,
      percentage_of_votes: 56.27,
    },
    {
      serial_no: 2,
      candidate: "PROFESSOR M.V RAJEEV GOWDA",
      party: "Indian National Congress",
      evm_votes: 725190,
      postal_votes: 1383,
      total_votes: 726573,
      percentage_of_votes: 41.46,
    },
    {
      serial_no: 3,
      candidate: "GOVINDAIAH",
      party: "Bahujan Samaj Party",
      evm_votes: 4614,
      postal_votes: 17,
      total_votes: 4631,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 4,
      candidate: "SANTHOSH M",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 3450,
      postal_votes: 11,
      total_votes: 3461,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 5,
      candidate: "UDAYA SHANKAR.G",
      party: "Prahar Janshakti Party",
      evm_votes: 2676,
      postal_votes: 2,
      total_votes: 2678,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 6,
      candidate: "LAKSHMI SRIHARI.J.N.",
      party: "Independent",
      evm_votes: 2091,
      postal_votes: 3,
      total_votes: 2094,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 7,
      candidate: "DILIP KUMAR I.S.",
      party: "Independent",
      evm_votes: 1896,
      postal_votes: 7,
      total_votes: 1903,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 8,
      candidate: "K.M.MAHADEVA",
      party: "Independent",
      evm_votes: 1716,
      postal_votes: 4,
      total_votes: 1720,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 9,
      candidate: "K.V.NANJUNDASWAMY",
      party: "Independent",
      evm_votes: 1363,
      postal_votes: 2,
      total_votes: 1365,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 10,
      candidate: "SHOBHA",
      party: "Independent",
      evm_votes: 1272,
      postal_votes: 8,
      total_votes: 1280,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "Y.R.KALLESHWARI",
      party: "Samajwadi Janata Party(Karnataka)",
      evm_votes: 1072,
      postal_votes: 5,
      total_votes: 1077,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "NIRMALA H.L",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 972,
      postal_votes: 5,
      total_votes: 977,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "H.R.NATARAJ",
      party: "Independent",
      evm_votes: 857,
      postal_votes: 4,
      total_votes: 861,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "BHASKAR ANKALAMADUGU SHIVAREDDY",
      party: "Independent",
      evm_votes: 831,
      postal_votes: 0,
      total_votes: 831,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "SHIVAKUMAR. B",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 813,
      postal_votes: 4,
      total_votes: 817,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "SUDHA. V",
      party: "Independent",
      evm_votes: 650,
      postal_votes: 7,
      total_votes: 657,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 17,
      candidate: "KRISHNAIAH",
      party: "Rashtriya Samaj Dal (R)",
      evm_votes: 556,
      postal_votes: 3,
      total_votes: 559,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 18,
      candidate: "HEBBAL VENKATESH.J",
      party: "Republican Party of India (A)",
      evm_votes: 422,
      postal_votes: 3,
      total_votes: 425,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 19,
      candidate: "GLOBE STAR J. JAI SIMHA VARMA, B E.",
      party: "Gareeb Aadmi Party",
      evm_votes: 356,
      postal_votes: 3,
      total_votes: 359,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 20,
      candidate: "SHOBAN BABU V",
      party: "Indian Labour Party (Ambedkar Phule)",
      evm_votes: 315,
      postal_votes: 5,
      total_votes: 320,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 21,
      candidate: "PARASAPPA BHEEMAPPA GAJJERI",
      party: "Rani Chennamma Party",
      evm_votes: 313,
      postal_votes: 0,
      total_votes: 313,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 22,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13499,
      postal_votes: 55,
      total_votes: 13554,
      percentage_of_votes: 0.77,
    },
  ],
  total: {
    evm_votes: 1747729,
    postal_votes: 4775,
    total_votes: 1752504,
  },
};
export const s1025 = {
  cs_name: "Bangalore_central",
  cs_shortname: "bangalorecentral",
  cs_code: "s1025",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "P C MOHAN",
      party: "Bharatiya Janata Party",
      evm_votes: 657236,
      postal_votes: 1679,
      total_votes: 658915,
      percentage_of_votes: 50.05,
    },
    {
      serial_no: 2,
      candidate: "MANSOOR ALI KHAN",
      party: "Indian National Congress",
      evm_votes: 625368,
      postal_votes: 840,
      total_votes: 626208,
      percentage_of_votes: 47.57,
    },
    {
      serial_no: 3,
      candidate: "SATISH CHANDRA. M.",
      party: "Bahujan Samaj Party",
      evm_votes: 3071,
      postal_votes: 16,
      total_votes: 3087,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 4,
      candidate: "JAYALAKSHMI SUNDARA RAJAN",
      party: "Independent",
      evm_votes: 2755,
      postal_votes: 6,
      total_votes: 2761,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 5,
      candidate: "SURESH BABU. M. C",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 1735,
      postal_votes: 4,
      total_votes: 1739,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 6,
      candidate: "K. MANJUNATHA",
      party: "Independent",
      evm_votes: 1552,
      postal_votes: 4,
      total_votes: 1556,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 7,
      candidate: "S. M. KRISHNA",
      party: "Independent",
      evm_votes: 1466,
      postal_votes: 8,
      total_votes: 1474,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 8,
      candidate: "MURUGESH. R",
      party: "Independent",
      evm_votes: 1162,
      postal_votes: 0,
      total_votes: 1162,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 9,
      candidate: "UMA. V.",
      party: "Independent",
      evm_votes: 820,
      postal_votes: 7,
      total_votes: 827,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 10,
      candidate: "PRAKASH. K",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 766,
      postal_votes: 13,
      total_votes: 779,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 11,
      candidate: "H. P. SHIVAPRAKASH",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 700,
      postal_votes: 7,
      total_votes: 707,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 12,
      candidate: "MODI SAIFULLA",
      party: "Independent",
      evm_votes: 688,
      postal_votes: 7,
      total_votes: 695,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "ABHAYA SHEELA.",
      party: "Rashtriya Samaj Dal (R)",
      evm_votes: 625,
      postal_votes: 10,
      total_votes: 635,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "KARTHIK. K",
      party: "Voters Independent Party",
      evm_votes: 594,
      postal_votes: 7,
      total_votes: 601,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "T.R. JAGANNATHA RAO",
      party: "Bharatiya Prajagala Kalyana Paksha",
      evm_votes: 456,
      postal_votes: 4,
      total_votes: 460,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 16,
      candidate: "VASANTH KUMAR ERAPPA",
      party: "Independent",
      evm_votes: 439,
      postal_votes: 0,
      total_votes: 439,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 17,
      candidate: "C.M. SHABAZ KHAN",
      party: "Young Star Empowerment Party",
      evm_votes: 428,
      postal_votes: 1,
      total_votes: 429,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 18,
      candidate: "WHITEFIELD MURUGESH .C",
      party: "Republican Party of India (Karnataka)",
      evm_votes: 376,
      postal_votes: 2,
      total_votes: 378,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 19,
      candidate: "B. KRISHNA PRASAD",
      party: "Proutist Bloc, India",
      evm_votes: 324,
      postal_votes: 3,
      total_votes: 327,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 20,
      candidate: "MUKTHAR ALI KHAN",
      party: "Karnataka Karmikara Paksha",
      evm_votes: 314,
      postal_votes: 0,
      total_votes: 314,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 21,
      candidate: "SYED ASIF BUKHARI",
      party: "Independent",
      evm_votes: 286,
      postal_votes: 0,
      total_votes: 286,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 22,
      candidate: "RANJITH VISHWA",
      party: "Samajwadi Janata Party(Karnataka)",
      evm_votes: 270,
      postal_votes: 4,
      total_votes: 274,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 23,
      candidate: "RUMAN SHARIFFE",
      party: "Independent",
      evm_votes: 170,
      postal_votes: 1,
      total_votes: 171,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 24,
      candidate: "YALLAPPA.",
      party: "Independent",
      evm_votes: 158,
      postal_votes: 2,
      total_votes: 160,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 25,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12103,
      postal_votes: 23,
      total_votes: 12126,
      percentage_of_votes: 0.92,
    },
  ],
  total: {
    evm_votes: 1313862,
    postal_votes: 2648,
    total_votes: 1316510,
  },
};
export const s1026 = {
  cs_name: "Bangalore_South",
  cs_shortname: "bangaloresouth",
  cs_code: "s1026",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "TEJASVI SURYA",
      party: "Bharatiya Janata Party",
      evm_votes: 747780,
      postal_votes: 3050,
      total_votes: 750830,
      percentage_of_votes: 60.1,
    },
    {
      serial_no: 2,
      candidate: "SOWMYA REDDY",
      party: "Indian National Congress",
      evm_votes: 472754,
      postal_votes: 993,
      total_votes: 473747,
      percentage_of_votes: 37.92,
    },
    {
      serial_no: 3,
      candidate: "ARUN PRASAD.A",
      party: "Bahujan Samaj Party",
      evm_votes: 2416,
      postal_votes: 9,
      total_votes: 2425,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 4,
      candidate: "TINTISHA HEMACHANDRA SAGAR",
      party: "Independent",
      evm_votes: 1962,
      postal_votes: 6,
      total_votes: 1968,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 5,
      candidate: "VATAL NAGARAJ",
      party: "Kannada Chalavali Vatal Paksha",
      evm_votes: 1857,
      postal_votes: 10,
      total_votes: 1867,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 6,
      candidate: "KEMPEGOWDA",
      party: "Navabharath Sena",
      evm_votes: 1390,
      postal_votes: 3,
      total_votes: 1393,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 7,
      candidate: "TEJASWINI DHANANJAY",
      party: "Independent",
      evm_votes: 1147,
      postal_votes: 3,
      total_votes: 1150,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 8,
      candidate: "BALAKRISHNA.M.",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 1106,
      postal_votes: 4,
      total_votes: 1110,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 9,
      candidate: "JAYAPAL.D",
      party: "Independent",
      evm_votes: 940,
      postal_votes: 2,
      total_votes: 942,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 10,
      candidate: "RANJITH GOWDA",
      party: "Independent",
      evm_votes: 859,
      postal_votes: 1,
      total_votes: 860,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "V.RAJKUMAR VCK",
      party: "Independent",
      evm_votes: 738,
      postal_votes: 2,
      total_votes: 740,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "RAGHUPATHI BHAT",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 737,
      postal_votes: 1,
      total_votes: 738,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "DR HARISH GOWDA",
      party: "Independent",
      evm_votes: 555,
      postal_votes: 8,
      total_votes: 563,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "CHETAN CHAMAN",
      party: "Country Citizen Party",
      evm_votes: 490,
      postal_votes: 2,
      total_votes: 492,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 15,
      candidate: "A . RAJ.",
      party: "Independent",
      evm_votes: 473,
      postal_votes: 4,
      total_votes: 477,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "K. C. JANARDHAN",
      party: "Independent",
      evm_votes: 444,
      postal_votes: 8,
      total_votes: 452,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 17,
      candidate: "KANTHAKUMAR.R.",
      party: "Independent",
      evm_votes: 393,
      postal_votes: 10,
      total_votes: 403,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 18,
      candidate: "KARAVALI MANJUNATH. BE",
      party: "Rashtriya Samaj Dal (R)",
      evm_votes: 308,
      postal_votes: 2,
      total_votes: 310,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 19,
      candidate: "C M. SHABAZ KHAN",
      party: "Young Star Empowerment Party",
      evm_votes: 295,
      postal_votes: 2,
      total_votes: 297,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 20,
      candidate: "ASGAR A MOHIN.S.M.",
      party: "Independent",
      evm_votes: 266,
      postal_votes: 3,
      total_votes: 269,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 21,
      candidate: "SUBRAHMANYA KUMAR KUNTIKANAMATA",
      party: "Independent",
      evm_votes: 234,
      postal_votes: 3,
      total_votes: 237,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 22,
      candidate: "DR.R.SHEKAR THIRUMAVALVAN",
      party: "Independent",
      evm_votes: 214,
      postal_votes: 1,
      total_votes: 215,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 23,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7830,
      postal_votes: 27,
      total_votes: 7857,
      percentage_of_votes: 0.63,
    },
  ],
  total: {
    evm_votes: 1245188,
    postal_votes: 4154,
    total_votes: 1249342,
  },
};
export const s1027 = {
  cs_name: "Chikkballapur",
  cs_shortname: "chikkballapur",
  cs_code: "s1027",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR.K.SUDHAKAR",
      party: "Bharatiya Janata Party",
      evm_votes: 819588,
      postal_votes: 3031,
      total_votes: 822619,
      percentage_of_votes: 53.74,
    },
    {
      serial_no: 2,
      candidate: "M.S. RAKSHA RAMAIAH",
      party: "Indian National Congress",
      evm_votes: 657489,
      postal_votes: 1670,
      total_votes: 659159,
      percentage_of_votes: 43.06,
    },
    {
      serial_no: 3,
      candidate: "MUNIVENKATAPPA M.P",
      party: "Communist Party of India (Marxist)",
      evm_votes: 4546,
      postal_votes: 11,
      total_votes: 4557,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 4,
      candidate: "MAHADEV. P",
      party: "Bahujan Samaj Party",
      evm_votes: 4428,
      postal_votes: 12,
      total_votes: 4440,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 5,
      candidate: "RAJANNA",
      party: "Independent",
      evm_votes: 3759,
      postal_votes: 5,
      total_votes: 3764,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 6,
      candidate: "RAJA REDDY",
      party: "Independent",
      evm_votes: 3377,
      postal_votes: 4,
      total_votes: 3381,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 7,
      candidate: "KALAVATHI. N",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2736,
      postal_votes: 8,
      total_votes: 2744,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "VALASAPALLI UTAPPA",
      party: "Independent",
      evm_votes: 2152,
      postal_votes: 10,
      total_votes: 2162,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 9,
      candidate: "VENKATESHMURTHY.V",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 2015,
      postal_votes: 5,
      total_votes: 2020,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 10,
      candidate: "D. SUDHAKARA",
      party: "Independent",
      evm_votes: 1678,
      postal_votes: 11,
      total_votes: 1689,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 11,
      candidate: "SUDHAKAR. N",
      party: "Independent",
      evm_votes: 1581,
      postal_votes: 10,
      total_votes: 1591,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 12,
      candidate: "G N RAVI",
      party: "Independent",
      evm_votes: 1462,
      postal_votes: 3,
      total_votes: 1465,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 13,
      candidate: "BHASKAR ANKALAMADUGU SHIVAREDDY",
      party: "Independent",
      evm_votes: 1363,
      postal_votes: 4,
      total_votes: 1367,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "CHANDRASHEKAR. H.C",
      party: "Independent",
      evm_votes: 1270,
      postal_votes: 12,
      total_votes: 1282,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "MOHITH NARASIMHAMURTHY",
      party: "Independent",
      evm_votes: 1222,
      postal_votes: 7,
      total_votes: 1229,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 16,
      candidate: "V.N. NARASIMHAMURTHY VADIGERE",
      party: "Independent",
      evm_votes: 1212,
      postal_votes: 6,
      total_votes: 1218,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 17,
      candidate: "G.N. VENKATESH B.A.L.L.B",
      party: "Independent",
      evm_votes: 1199,
      postal_votes: 8,
      total_votes: 1207,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 18,
      candidate: "DEVARAJ CORONA WARRIOR",
      party: "Independent",
      evm_votes: 1168,
      postal_votes: 9,
      total_votes: 1177,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 19,
      candidate: "G SUBRAMANI SHETTY",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 869,
      postal_votes: 3,
      total_votes: 872,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 20,
      candidate: "DR. M.R. RANGANATHA",
      party: "Independent",
      evm_votes: 850,
      postal_votes: 7,
      total_votes: 857,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 21,
      candidate: "NAGESH. S",
      party: "Digvijaya Bharatha Party",
      evm_votes: 843,
      postal_votes: 7,
      total_votes: 850,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 22,
      candidate: "T.R. NARAYANA RAO",
      party: "Indian Labour Party (Ambedkar Phule)",
      evm_votes: 757,
      postal_votes: 5,
      total_votes: 762,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 23,
      candidate: "C.V. LOKESH GOWDA, B.E",
      party: "Independent",
      evm_votes: 691,
      postal_votes: 10,
      total_votes: 701,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 24,
      candidate: "D. CHINNAPPA",
      party: "Independent",
      evm_votes: 616,
      postal_votes: 3,
      total_votes: 619,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 25,
      candidate: "SANDESH.G",
      party: "Independent",
      evm_votes: 588,
      postal_votes: 6,
      total_votes: 594,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 26,
      candidate: "NASURULLA",
      party: "Independent",
      evm_votes: 579,
      postal_votes: 4,
      total_votes: 583,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 27,
      candidate: "K. VENKATESH",
      party: "Independent",
      evm_votes: 469,
      postal_votes: 5,
      total_votes: 474,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 28,
      candidate: "T VENKATA SIVUDU",
      party: "Independent",
      evm_votes: 434,
      postal_votes: 6,
      total_votes: 440,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 29,
      candidate: "G N. KODANDAREDDY",
      party: "Independent",
      evm_votes: 399,
      postal_votes: 2,
      total_votes: 401,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 30,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6545,
      postal_votes: 51,
      total_votes: 6596,
      percentage_of_votes: 0.43,
    },
  ],
  total: {
    evm_votes: 1525885,
    postal_votes: 4935,
    total_votes: 1530820,
  },
};
export const s1110 = {
  cs_name: "Thrissur",
  cs_shortname: "thrissur",
  cs_code: "s1110",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SURESH GOPI",
      party: "Bharatiya Janata Party",
      evm_votes: 409302,
      postal_votes: 3036,
      total_votes: 412338,
      percentage_of_votes: 37.8,
    },
    {
      serial_no: 2,
      candidate: "ADV V S SUNILKUMAR",
      party: "Communist Party of India",
      evm_votes: 334462,
      postal_votes: 3190,
      total_votes: 337652,
      percentage_of_votes: 30.95,
    },
    {
      serial_no: 3,
      candidate: "K MURALEEDHARAN",
      party: "Indian National Congress",
      evm_votes: 324810,
      postal_votes: 3314,
      total_votes: 328124,
      percentage_of_votes: 30.08,
    },
    {
      serial_no: 4,
      candidate: "ADV P K NARAYANAN",
      party: "Bahujan Samaj Party",
      evm_votes: 1985,
      postal_votes: 34,
      total_votes: 2019,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 5,
      candidate: "SUNILKUMAR SON OF PRABHAKARAN",
      party: "Independent",
      evm_votes: 1930,
      postal_votes: 22,
      total_votes: 1952,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 6,
      candidate: "DIVAKARAN PALLATH",
      party: "New Labour Party",
      evm_votes: 812,
      postal_votes: 7,
      total_votes: 819,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 7,
      candidate: "M S JAFARGHAN",
      party: "Independent",
      evm_votes: 689,
      postal_votes: 9,
      total_votes: 698,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 8,
      candidate: "PRATHAPAN",
      party: "Independent",
      evm_votes: 685,
      postal_votes: 24,
      total_votes: 709,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 9,
      candidate: "JOSHI VILLADOM",
      party: "Independent",
      evm_votes: 460,
      postal_votes: 33,
      total_votes: 493,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6046,
      postal_votes: 26,
      total_votes: 6072,
      percentage_of_votes: 0.56,
    },
  ],
  total: {
    evm_votes: 1081181,
    postal_votes: 9695,
    total_votes: 1090876,
  },
};
export const s121 = {
  cs_name: "MORENA",
  cs_shortname: "morena",
  cs_code: "s121",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SHIVMANGAL SINGH TOMAR",
      party: "Bharatiya Janata Party",
      evm_votes: 513136,
      postal_votes: 2341,
      total_votes: 515477,
      percentage_of_votes: 43.41,
    },
    {
      serial_no: 2,
      candidate: "NEETU SATYAPAL SINGH SIKARWAR",
      party: "Indian National Congress",
      evm_votes: 461808,
      postal_votes: 1139,
      total_votes: 462947,
      percentage_of_votes: 38.99,
    },
    {
      serial_no: 3,
      candidate: "RAMESH GARG",
      party: "Bahujan Samaj Party",
      evm_votes: 179492,
      postal_votes: 177,
      total_votes: 179669,
      percentage_of_votes: 15.13,
    },
    {
      serial_no: 4,
      candidate: "NEERAJ CHANDSORIYA",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 7534,
      postal_votes: 10,
      total_votes: 7544,
      percentage_of_votes: 0.64,
    },
    {
      serial_no: 5,
      candidate: "RAJENDRA PRASAD KUSHWAH",
      party: "Independent",
      evm_votes: 2392,
      postal_votes: 10,
      total_votes: 2402,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 6,
      candidate: "ER. SURAJ KUSHWAH",
      party: "Independent",
      evm_votes: 2279,
      postal_votes: 6,
      total_votes: 2285,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 7,
      candidate: "RAMNIWASH",
      party: "Independent",
      evm_votes: 1970,
      postal_votes: 3,
      total_votes: 1973,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 8,
      candidate: "RAM SUNDER SHARMA",
      party: "Independent",
      evm_votes: 1762,
      postal_votes: 5,
      total_votes: 1767,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 9,
      candidate: "RAMSEVAK SAKHWAR",
      party: "Independent",
      evm_votes: 1630,
      postal_votes: 2,
      total_votes: 1632,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "HARIKANTH",
      party: "Independent",
      evm_votes: 1623,
      postal_votes: 0,
      total_votes: 1623,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "RAJKUMARI",
      party: "Independent",
      evm_votes: 1435,
      postal_votes: 6,
      total_votes: 1441,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "ANOOP NAGAR",
      party: "Independent",
      evm_votes: 1308,
      postal_votes: 9,
      total_votes: 1317,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 13,
      candidate: "RAJENDRA SINGH GURJER",
      party: "Independent",
      evm_votes: 1199,
      postal_votes: 11,
      total_votes: 1210,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 14,
      candidate: "PIYUSH BRAJESH RAJORIYA",
      party: "Independent",
      evm_votes: 572,
      postal_votes: 3,
      total_votes: 575,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "PRABHU JATAV",
      party: "Independent",
      evm_votes: 547,
      postal_votes: 8,
      total_votes: 555,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4899,
      postal_votes: 15,
      total_votes: 4914,
      percentage_of_votes: 0.41,
    },
  ],
  total: {
    evm_votes: 1183586,
    postal_votes: 3745,
    total_votes: 1187331,
  },
};
export const s122 = {
  cs_name: "BHIND",
  cs_shortname: "bhind",
  cs_code: "s122",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SANDHYA RAY",
      party: "Bharatiya Janata Party",
      evm_votes: 533462,
      postal_votes: 3603,
      total_votes: 537065,
      percentage_of_votes: 51.2,
    },
    {
      serial_no: 2,
      candidate: "PHOOL SINGH BARAIYA",
      party: "Indian National Congress",
      evm_votes: 470169,
      postal_votes: 2056,
      total_votes: 472225,
      percentage_of_votes: 45.02,
    },
    {
      serial_no: 3,
      candidate: "DEVASHISH JARARIYA",
      party: "Bahujan Samaj Party",
      evm_votes: 20322,
      postal_votes: 143,
      total_votes: 20465,
      percentage_of_votes: 1.95,
    },
    {
      serial_no: 4,
      candidate: "UMESH GARG",
      party: "Independent",
      evm_votes: 5057,
      postal_votes: 15,
      total_votes: 5072,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 5,
      candidate: "REKHA SHAKYA",
      party: "Independent",
      evm_votes: 2645,
      postal_votes: 22,
      total_votes: 2667,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 6,
      candidate: "HARIMOHAN",
      party: "Independent",
      evm_votes: 2598,
      postal_votes: 12,
      total_votes: 2610,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 7,
      candidate: "RAKESH",
      party: "Independent",
      evm_votes: 2345,
      postal_votes: 24,
      total_votes: 2369,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6486,
      postal_votes: 48,
      total_votes: 6534,
      percentage_of_votes: 0.62,
    },
  ],
  total: {
    evm_votes: 1043084,
    postal_votes: 5923,
    total_votes: 1049007,
  },
};
export const s123 = {
  cs_name: "GWALIOR",
  cs_shortname: "gwalior",
  cs_code: "s123",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BHARAT SINGH KUSHWAH",
      party: "Bharatiya Janata Party",
      evm_votes: 668782,
      postal_votes: 2753,
      total_votes: 671535,
      percentage_of_votes: 49.99,
    },
    {
      serial_no: 2,
      candidate: "PRAVEEN PATHAK",
      party: "Indian National Congress",
      evm_votes: 599077,
      postal_votes: 2248,
      total_votes: 601325,
      percentage_of_votes: 44.77,
    },
    {
      serial_no: 3,
      candidate: "KALYAN SINGH KANSANA",
      party: "Bahujan Samaj Party",
      evm_votes: 33308,
      postal_votes: 157,
      total_votes: 33465,
      percentage_of_votes: 2.49,
    },
    {
      serial_no: 4,
      candidate: "ARCHANA SINGH RATHORE",
      party: "Rashtriya Samaj Paksha",
      evm_votes: 5873,
      postal_votes: 19,
      total_votes: 5892,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 5,
      candidate: "BHARAT PAL",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 4138,
      postal_votes: 25,
      total_votes: 4163,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 6,
      candidate: "ER. MAHENDRA PRATAP SINGH PAL",
      party: "Independent",
      evm_votes: 3777,
      postal_votes: 6,
      total_votes: 3783,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 7,
      candidate: "RAKESH DHAKAD",
      party: "Independent",
      evm_votes: 3218,
      postal_votes: 2,
      total_votes: 3220,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 8,
      candidate: "YASHDEV SHARMA",
      party: "Independent",
      evm_votes: 2568,
      postal_votes: 3,
      total_votes: 2571,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 9,
      candidate: "DEEPAK KUMAR BANSAL (RANG WALE)",
      party: "Independent",
      evm_votes: 2448,
      postal_votes: 0,
      total_votes: 2448,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 10,
      candidate: "SHREE NARESH CHANDRA SHARMA",
      party: "Independent",
      evm_votes: 2215,
      postal_votes: 1,
      total_votes: 2216,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 11,
      candidate: "GAJENDRA SINGH",
      party: "Independent",
      evm_votes: 1343,
      postal_votes: 5,
      total_votes: 1348,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 12,
      candidate: "ANJALI MONU RAWAT",
      party: "Parivartan Party of India",
      evm_votes: 1272,
      postal_votes: 7,
      total_votes: 1279,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 13,
      candidate: "ADVOCATE MUKESH KUMAR KORI",
      party: "Independent",
      evm_votes: 1130,
      postal_votes: 4,
      total_votes: 1134,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "RAM PRAKASH SINGH PAL",
      party: "Rashtra Uday Party",
      evm_votes: 1059,
      postal_votes: 12,
      total_votes: 1071,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "CHANDAN RATHORE",
      party: "Parivartan Samaj Party",
      evm_votes: 1053,
      postal_votes: 6,
      total_votes: 1059,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 16,
      candidate: "DR. P. D. AGARWAL SR. MEDICAL CONSULTANT",
      party: "Independent",
      evm_votes: 1016,
      postal_votes: 5,
      total_votes: 1021,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 17,
      candidate: "AMIT PARIHAR",
      party: "Independent",
      evm_votes: 877,
      postal_votes: 6,
      total_votes: 883,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "COMRADE RACHNA AGRAWAL",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 798,
      postal_votes: 5,
      total_votes: 803,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 19,
      candidate: "MUNESH NAGAR",
      party: "Vikas India Party",
      evm_votes: 667,
      postal_votes: 5,
      total_votes: 672,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 20,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3309,
      postal_votes: 32,
      total_votes: 3341,
      percentage_of_votes: 0.25,
    },
  ],
  total: {
    evm_votes: 1337928,
    postal_votes: 5301,
    total_votes: 1343229,
  },
};
export const s124 = {
  cs_name: "GUNA",
  cs_shortname: "guna",
  cs_code: "s124",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "JYOTIRADITYA M. SCINDIA",
      party: "Bharatiya Janata Party",
      evm_votes: 919744,
      postal_votes: 3558,
      total_votes: 923302,
      percentage_of_votes: 67.21,
    },
    {
      serial_no: 2,
      candidate: "YADVENDRA RAO DESHRAJ SINGH",
      party: "Indian National Congress",
      evm_votes: 380909,
      postal_votes: 1464,
      total_votes: 382373,
      percentage_of_votes: 27.83,
    },
    {
      serial_no: 3,
      candidate: "DHANIRAM CHAUDHARY",
      party: "Bahujan Samaj Party",
      evm_votes: 30105,
      postal_votes: 130,
      total_votes: 30235,
      percentage_of_votes: 2.2,
    },
    {
      serial_no: 4,
      candidate: "KANCHHEDI LAL KUSHWAHA",
      party: "Mahanwadi Party",
      evm_votes: 5850,
      postal_votes: 6,
      total_votes: 5856,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 5,
      candidate: "ADVOCATE KARANSINGH JATAV",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 5474,
      postal_votes: 11,
      total_votes: 5485,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 6,
      candidate: "HEMANT SINGH KUSHWAHA",
      party: "Independent",
      evm_votes: 3450,
      postal_votes: 3,
      total_votes: 3453,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 7,
      candidate: "RAKESH",
      party: "Independent",
      evm_votes: 3051,
      postal_votes: 1,
      total_votes: 3052,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 8,
      candidate: "MAHENDRA JAIN BHAIYAN",
      party: "Independent",
      evm_votes: 1990,
      postal_votes: 9,
      total_votes: 1999,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 9,
      candidate: "MOHAN",
      party: "Independent",
      evm_votes: 1883,
      postal_votes: 3,
      total_votes: 1886,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "GAJANAND KUSHWAH",
      party: "Independent",
      evm_votes: 1595,
      postal_votes: 2,
      total_votes: 1597,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 11,
      candidate: "MANISH SHRIVASTAVA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1342,
      postal_votes: 6,
      total_votes: 1348,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 12,
      candidate: "MANMOHAN",
      party: "Independent",
      evm_votes: 1143,
      postal_votes: 6,
      total_votes: 1149,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "KISAN LAL AHIRWAR",
      party: "Independent",
      evm_votes: 1033,
      postal_votes: 11,
      total_votes: 1044,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "MOHAMMAD JAVED ANSARI",
      party: "Rashtriya Jansena Party",
      evm_votes: 983,
      postal_votes: 0,
      total_votes: 983,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 15,
      candidate: "D.S. CHAUHAN (ADVOCATE)",
      party: "Rashtriya Samaj Paksha",
      evm_votes: 913,
      postal_votes: 2,
      total_votes: 915,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9055,
      postal_votes: 34,
      total_votes: 9089,
      percentage_of_votes: 0.66,
    },
  ],
  total: {
    evm_votes: 1368520,
    postal_votes: 5246,
    total_votes: 1373766,
  },
};
export const s125 = {
  cs_name: "SAGAR",
  cs_shortname: "sagar",
  cs_code: "s125",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR. LATA WANKHEDE",
      party: "Bharatiya Janata Party",
      evm_votes: 786160,
      postal_votes: 1819,
      total_votes: 787979,
      percentage_of_votes: 68.49,
    },
    {
      serial_no: 2,
      candidate: "CHANDRA BHUSAN SINGH BUNDELA 'GUDDU RAJA'",
      party: "Indian National Congress",
      evm_votes: 316059,
      postal_votes: 698,
      total_votes: 316757,
      percentage_of_votes: 27.53,
    },
    {
      serial_no: 3,
      candidate: "BHAGWATI PRASAD JATAV",
      party: "Bahujan Samaj Party",
      evm_votes: 16592,
      postal_votes: 44,
      total_votes: 16636,
      percentage_of_votes: 1.45,
    },
    {
      serial_no: 4,
      candidate: "BHEEKAM SINGH KUSHWAHA",
      party: "Mahanwadi Party",
      evm_votes: 5778,
      postal_votes: 6,
      total_votes: 5784,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 5,
      candidate: "RAM BHJAN BANSAL",
      party: "Samata Party",
      evm_votes: 3149,
      postal_votes: 4,
      total_votes: 3153,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 6,
      candidate: "MOHAMAD AARIF MAKRANI",
      party: "Independent",
      evm_votes: 2888,
      postal_votes: 0,
      total_votes: 2888,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 7,
      candidate: "SANGRAM SINGH YADAV",
      party: "Independent",
      evm_votes: 2819,
      postal_votes: 5,
      total_votes: 2824,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 8,
      candidate: "DHARMENDRA BANPURIYA",
      party: "Independent",
      evm_votes: 1621,
      postal_votes: 3,
      total_votes: 1624,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 9,
      candidate: "TOSHMANI PANTHI (KANNA)",
      party: "Independent",
      evm_votes: 1293,
      postal_votes: 6,
      total_votes: 1299,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "SURESH BANSAL",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 1147,
      postal_votes: 4,
      total_votes: 1151,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "LAXMI KUSHWAHA",
      party: "Pichhara Samaj Party United",
      evm_votes: 947,
      postal_votes: 5,
      total_votes: 952,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "DR. RAMAVATAR SHARMA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 924,
      postal_votes: 4,
      total_votes: 928,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "AHIRWAR RAJKUMAR THEKEDAAR",
      party: "Independent",
      evm_votes: 914,
      postal_votes: 3,
      total_votes: 917,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7641,
      postal_votes: 16,
      total_votes: 7657,
      percentage_of_votes: 0.67,
    },
  ],
  total: {
    evm_votes: 1147932,
    postal_votes: 2617,
    total_votes: 1150549,
  },
};
export const s126 = {
  cs_name: "TIKAMGARH",
  cs_shortname: "tikamgarh",
  cs_code: "s126",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR. VIRENDRA KUMAR",
      party: "Bharatiya Janata Party",
      evm_votes: 713781,
      postal_votes: 1269,
      total_votes: 715050,
      percentage_of_votes: 65.1,
    },
    {
      serial_no: 2,
      candidate: "KHUMAN URF PANKAJ AHIRWAR",
      party: "Indian National Congress",
      evm_votes: 311134,
      postal_votes: 604,
      total_votes: 311738,
      percentage_of_votes: 28.38,
    },
    {
      serial_no: 3,
      candidate: "AHIRWAR DALLURAM",
      party: "Bahujan Samaj Party",
      evm_votes: 32604,
      postal_votes: 69,
      total_votes: 32673,
      percentage_of_votes: 2.97,
    },
    {
      serial_no: 4,
      candidate: "AHIRWAR PANKAJ",
      party: "Rashtriya Samaj Paksha",
      evm_votes: 10147,
      postal_votes: 11,
      total_votes: 10158,
      percentage_of_votes: 0.92,
    },
    {
      serial_no: 5,
      candidate: "SARJU PRASAD",
      party: "Independent",
      evm_votes: 5993,
      postal_votes: 4,
      total_votes: 5997,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 6,
      candidate: "N.R. PRAJAPATI",
      party: "Adarsh Nyay Rakshak Party",
      evm_votes: 5475,
      postal_votes: 9,
      total_votes: 5484,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 7,
      candidate: "BABULAL KHANGAR",
      party: "Independent",
      evm_votes: 4452,
      postal_votes: 7,
      total_votes: 4459,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12816,
      postal_votes: 15,
      total_votes: 12831,
      percentage_of_votes: 1.17,
    },
  ],
  total: {
    evm_votes: 1096402,
    postal_votes: 1988,
    total_votes: 1098390,
  },
};
export const s127 = {
  cs_name: "DAMOH",
  cs_shortname: "damoh",
  cs_code: "s127",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAHUL SINGH LODHI",
      party: "Bharatiya Janata Party",
      evm_votes: 708589,
      postal_votes: 1179,
      total_votes: 709768,
      percentage_of_votes: 65.18,
    },
    {
      serial_no: 2,
      candidate: "TARBAR SINGH LODHI (BANTU BHAIYA)",
      party: "Indian National Congress",
      evm_votes: 302828,
      postal_votes: 514,
      total_votes: 303342,
      percentage_of_votes: 27.86,
    },
    {
      serial_no: 3,
      candidate: "ENGINEER GOVERDHAN RAJ",
      party: "Bahujan Samaj Party",
      evm_votes: 21379,
      postal_votes: 25,
      total_votes: 21404,
      percentage_of_votes: 1.97,
    },
    {
      serial_no: 4,
      candidate: "BHAIYA VIJAY PATEL (KURMI) TIRGARH BALE",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 12200,
      postal_votes: 17,
      total_votes: 12217,
      percentage_of_votes: 1.12,
    },
    {
      serial_no: 5,
      candidate: "MANU SINGH MARAVI",
      party: "Bharat Adivasi Party",
      evm_votes: 7871,
      postal_votes: 16,
      total_votes: 7887,
      percentage_of_votes: 0.72,
    },
    {
      serial_no: 6,
      candidate: "RAHUL BHAIYA S/O RAM PRATAP",
      party: "Independent",
      evm_votes: 6273,
      postal_votes: 12,
      total_votes: 6285,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 7,
      candidate: "RAHUL BHAIYA S/O JEEVAN LAL",
      party: "Independent",
      evm_votes: 5556,
      postal_votes: 5,
      total_votes: 5561,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 8,
      candidate: "VEDRAM KURMI",
      party: "Independent",
      evm_votes: 4054,
      postal_votes: 3,
      total_votes: 4057,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 9,
      candidate: "RAJESH SINGH SOYAM",
      party: "Gondvana Gantantra Party",
      evm_votes: 3172,
      postal_votes: 18,
      total_votes: 3190,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 10,
      candidate: "RAKESH KUMAR AHIRWAR",
      party: "Independent",
      evm_votes: 2044,
      postal_votes: 7,
      total_votes: 2051,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 11,
      candidate: "NANDAN KUMAR AHIRWAL",
      party: "Independent",
      evm_votes: 1848,
      postal_votes: 5,
      total_votes: 1853,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "NEELESH SONI",
      party: "Independent",
      evm_votes: 1277,
      postal_votes: 1,
      total_votes: 1278,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "DURGA MOUSI",
      party: "Independent",
      evm_votes: 1120,
      postal_votes: 4,
      total_votes: 1124,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 14,
      candidate: "TARVAR SINGH LODHI",
      party: "Independent",
      evm_votes: 1092,
      postal_votes: 2,
      total_votes: 1094,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7820,
      postal_votes: 13,
      total_votes: 7833,
      percentage_of_votes: 0.72,
    },
  ],
  total: {
    evm_votes: 1087123,
    postal_votes: 1821,
    total_votes: 1088944,
  },
};
export const s128 = {
  cs_name: "KHAJURAHO",
  cs_shortname: "khajuraho",
  cs_code: "s128",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "VISHNU DATT SHARMA (V.D.SHARMA)",
      party: "Bharatiya Janata Party",
      evm_votes: 771014,
      postal_votes: 1760,
      total_votes: 772774,
      percentage_of_votes: 67.75,
    },
    {
      serial_no: 2,
      candidate: "KAMLESH KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 231163,
      postal_votes: 382,
      total_votes: 231545,
      percentage_of_votes: 20.3,
    },
    {
      serial_no: 3,
      candidate: "R. B. PRAJAPATI (RAJA BHAIYA) EX.I.A.S.",
      party: "All India Forward Bloc",
      evm_votes: 50033,
      postal_votes: 182,
      total_votes: 50215,
      percentage_of_votes: 4.4,
    },
    {
      serial_no: 4,
      candidate: "MOHAMMAD IMRAN",
      party: "Rashtriya Jansambhavna Party",
      evm_votes: 17853,
      postal_votes: 20,
      total_votes: 17873,
      percentage_of_votes: 1.57,
    },
    {
      serial_no: 5,
      candidate: "PENTER SUNMAN SINGH LODHI",
      party: "Independent",
      evm_votes: 10005,
      postal_votes: 6,
      total_votes: 10011,
      percentage_of_votes: 0.88,
    },
    {
      serial_no: 6,
      candidate: "KAPIL GUPTA BHARTIYA",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 7540,
      postal_votes: 24,
      total_votes: 7564,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 7,
      candidate: "LAXMI PRASAD",
      party: "Independent",
      evm_votes: 6752,
      postal_votes: 12,
      total_votes: 6764,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 8,
      candidate: "NANDKISHOR",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 6019,
      postal_votes: 8,
      total_votes: 6027,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 9,
      candidate: "PANKAJ MAURYA (KUSHWAHA)",
      party: "Gondvana Gantantra Party",
      evm_votes: 4730,
      postal_votes: 10,
      total_votes: 4740,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 10,
      candidate: "BITIAYA AHIRWAR",
      party: "Independent",
      evm_votes: 4018,
      postal_votes: 8,
      total_votes: 4026,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 11,
      candidate: "FIROJKHAN",
      party: "Independent",
      evm_votes: 3708,
      postal_votes: 5,
      total_votes: 3713,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 12,
      candidate: "GIRAN SINGH",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 3485,
      postal_votes: 7,
      total_votes: 3492,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 13,
      candidate: "KESHKALI",
      party: "Rashtriya Jansanchar Dal",
      evm_votes: 2861,
      postal_votes: 11,
      total_votes: 2872,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 14,
      candidate: "PANNA LAL TRIPATHI AD.",
      party: "Indian Peoples Adhikar Party",
      evm_votes: 2794,
      postal_votes: 3,
      total_votes: 2797,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 16134,
      postal_votes: 23,
      total_votes: 16157,
      percentage_of_votes: 1.42,
    },
  ],
  total: {
    evm_votes: 1138109,
    postal_votes: 2461,
    total_votes: 1140570,
  },
};
export const s129 = {
  cs_name: "SATNA",
  cs_shortname: "satna",
  cs_code: "s129",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "GANESH SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 458373,
      postal_votes: 1355,
      total_votes: 459728,
      percentage_of_votes: 43.41,
    },
    {
      serial_no: 2,
      candidate: "DABBU SIDDHARTH SUKHLAL KUSHWAHA",
      party: "Indian National Congress",
      evm_votes: 373982,
      postal_votes: 797,
      total_votes: 374779,
      percentage_of_votes: 35.39,
    },
    {
      serial_no: 3,
      candidate: "NARAYAN TRIPATHI",
      party: "Bahujan Samaj Party",
      evm_votes: 185303,
      postal_votes: 315,
      total_votes: 185618,
      percentage_of_votes: 17.53,
    },
    {
      serial_no: 4,
      candidate: "DURGESH KUMAR VISHWAKARMA",
      party: "Independent",
      evm_votes: 5919,
      postal_votes: 2,
      total_votes: 5921,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 5,
      candidate: "RAHUL DAHIYA",
      party: "Independent",
      evm_votes: 4276,
      postal_votes: 1,
      total_votes: 4277,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 6,
      candidate: "CHHEDI LAL PRAJAPATI (PANDA BABA)",
      party: "Independent",
      evm_votes: 4060,
      postal_votes: 1,
      total_votes: 4061,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 7,
      candidate: "CHANDRABHAN KOL",
      party: "Independent",
      evm_votes: 3393,
      postal_votes: 5,
      total_votes: 3398,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 8,
      candidate: "SAFI ULLA DBBU BHAI",
      party: "Independent",
      evm_votes: 2612,
      postal_votes: 2,
      total_votes: 2614,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 9,
      candidate: "ADV. ASHOK SAKET",
      party: "Bahujan Dravida Party",
      evm_votes: 2396,
      postal_votes: 3,
      total_votes: 2399,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 10,
      candidate: "AZEEZ AHMED QURAISHY",
      party: "Manviya Bharat Party",
      evm_votes: 2078,
      postal_votes: 4,
      total_votes: 2082,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 11,
      candidate: "MASTER JI AJIT KUMAR JAYSWAL",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 1887,
      postal_votes: 4,
      total_votes: 1891,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 12,
      candidate: "KALIM AHMAD BACHCHA",
      party: "Independent",
      evm_votes: 1622,
      postal_votes: 8,
      total_votes: 1630,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 13,
      candidate: "SUKHLAL VERMA RATNESH",
      party: "Independent",
      evm_votes: 1616,
      postal_votes: 1,
      total_votes: 1617,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 14,
      candidate: "ASHOK KUMAR GUPTA",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 1259,
      postal_votes: 6,
      total_votes: 1265,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 15,
      candidate: "NANKU YADAV",
      party: "Akhil Bharatiya Hind Kranti Party",
      evm_votes: 1254,
      postal_votes: 5,
      total_votes: 1259,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 16,
      candidate: "RISHABH SINGH",
      party: "Independent",
      evm_votes: 1068,
      postal_votes: 5,
      total_votes: 1073,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 17,
      candidate: "ASHOK BAUDH",
      party: "Independent",
      evm_votes: 945,
      postal_votes: 2,
      total_votes: 947,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 18,
      candidate: "HARI SHANKAR TIWARI (LOHA WALA)",
      party: "Bharatiya Jan Morcha Party",
      evm_votes: 935,
      postal_votes: 10,
      total_votes: 945,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 19,
      candidate: "RANJANA MISHRA",
      party: "Nyaydharmsabha",
      evm_votes: 905,
      postal_votes: 10,
      total_votes: 915,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 20,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 2538,
      postal_votes: 15,
      total_votes: 2553,
      percentage_of_votes: 0.24,
    },
  ],
  total: {
    evm_votes: 1056421,
    postal_votes: 2551,
    total_votes: 1058972,
  },
};
export const s1210 = {
  cs_name: "REWA",
  cs_shortname: "rewa",
  cs_code: "s1210",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "JANARDAN MISHRA",
      party: "Bharatiya Janata Party",
      evm_votes: 475439,
      postal_votes: 2020,
      total_votes: 477459,
      percentage_of_votes: 52,
    },
    {
      serial_no: 2,
      candidate: "NEELAM ABHAY MISHRA",
      party: "Indian National Congress",
      evm_votes: 283187,
      postal_votes: 898,
      total_votes: 284085,
      percentage_of_votes: 30.94,
    },
    {
      serial_no: 3,
      candidate: "ADV. ABHISHEK MASTER BUDDHSEN PATEL",
      party: "Bahujan Samaj Party",
      evm_votes: 117089,
      postal_votes: 132,
      total_votes: 117221,
      percentage_of_votes: 12.77,
    },
    {
      serial_no: 4,
      candidate: "DAYASHANKAR PANDEY",
      party: "Independent",
      evm_votes: 4609,
      postal_votes: 1,
      total_votes: 4610,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 5,
      candidate: "ROSHAN LAL KOL",
      party: "Independent",
      evm_votes: 4110,
      postal_votes: 12,
      total_votes: 4122,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 6,
      candidate: "ER. DEVENDRA SINGH",
      party: "Sapaks Party",
      evm_votes: 4065,
      postal_votes: 11,
      total_votes: 4076,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 7,
      candidate: "ER. RAM KUMAR SONI",
      party: "Independent",
      evm_votes: 3868,
      postal_votes: 5,
      total_votes: 3873,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 8,
      candidate: "RAM GOPAL SINGH",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 3502,
      postal_votes: 7,
      total_votes: 3509,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 9,
      candidate: "PRASSAN JEET SINGH",
      party: "Independent",
      evm_votes: 3306,
      postal_votes: 7,
      total_votes: 3313,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 10,
      candidate: "JANARDAN MISHRA",
      party: "Independent",
      evm_votes: 2274,
      postal_votes: 21,
      total_votes: 2295,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 11,
      candidate: "RANJAN GUPTA",
      party: "Rashtravadi Bharat Party",
      evm_votes: 2151,
      postal_votes: 9,
      total_votes: 2160,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 12,
      candidate: "VIPIN SINGH PATEL",
      party: "Rashtriya Hind Ekta Dal",
      evm_votes: 1799,
      postal_votes: 7,
      total_votes: 1806,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 13,
      candidate: "ARUNENDRA NARAYAN PANDEY",
      party: "Independent",
      evm_votes: 1550,
      postal_votes: 1,
      total_votes: 1551,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 14,
      candidate: "ARUN TIWARI 'MINTU'",
      party: "Independent",
      evm_votes: 1113,
      postal_votes: 0,
      total_votes: 1113,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6916,
      postal_votes: 20,
      total_votes: 6936,
      percentage_of_votes: 0.76,
    },
  ],
  total: {
    evm_votes: 914978,
    postal_votes: 3151,
    total_votes: 918129,
  },
};
export const s1211 = {
  cs_name: "SIDHI",
  cs_shortname: "sidhi",
  cs_code: "s1211",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR. RAJESH MISHRA",
      party: "Bharatiya Janata Party",
      evm_votes: 582664,
      postal_votes: 895,
      total_votes: 583559,
      percentage_of_votes: 50.87,
    },
    {
      serial_no: 2,
      candidate: "KAMLESHWAR INDRAJIT KUMAR",
      party: "Indian National Congress",
      evm_votes: 376336,
      postal_votes: 807,
      total_votes: 377143,
      percentage_of_votes: 32.87,
    },
    {
      serial_no: 3,
      candidate: "AJAY PRATAP SINGH",
      party: "Gondvana Gantantra Party",
      evm_votes: 50897,
      postal_votes: 65,
      total_votes: 50962,
      percentage_of_votes: 4.44,
    },
    {
      serial_no: 4,
      candidate: "PUJAN RAM SAKET (BABBU)",
      party: "Bahujan Samaj Party",
      evm_votes: 33617,
      postal_votes: 39,
      total_votes: 33656,
      percentage_of_votes: 2.93,
    },
    {
      serial_no: 5,
      candidate: "LAXMAN SINGH BAIS",
      party: "Independent",
      evm_votes: 30634,
      postal_votes: 28,
      total_votes: 30662,
      percentage_of_votes: 2.67,
    },
    {
      serial_no: 6,
      candidate: "SANJAY NAMDEO",
      party: "Communist Party of India",
      evm_votes: 20284,
      postal_votes: 6,
      total_votes: 20290,
      percentage_of_votes: 1.77,
    },
    {
      serial_no: 7,
      candidate: "BHAGWAN PRASAD TIWARI",
      party: "Independent",
      evm_votes: 7330,
      postal_votes: 1,
      total_votes: 7331,
      percentage_of_votes: 0.64,
    },
    {
      serial_no: 8,
      candidate: "SUNIL TIWARI",
      party: "Independent",
      evm_votes: 5694,
      postal_votes: 5,
      total_votes: 5699,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 9,
      candidate: "SHYAMLAL VAISH",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 5340,
      postal_votes: 3,
      total_votes: 5343,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 10,
      candidate: "KAILASH PRASAD VERMA",
      party: "Independent",
      evm_votes: 5289,
      postal_votes: 2,
      total_votes: 5291,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 11,
      candidate: "MAHENDRA BHAIYA",
      party: "Independent",
      evm_votes: 4658,
      postal_votes: 0,
      total_votes: 4658,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 12,
      candidate: "DASHRATH PRASAD BAIS",
      party: "Independent",
      evm_votes: 4131,
      postal_votes: 0,
      total_votes: 4131,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 13,
      candidate: "NARAYAN DAS SHAH MULNIWASI",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 3791,
      postal_votes: 1,
      total_votes: 3792,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 14,
      candidate: "DADDI YADAV",
      party: "Independent",
      evm_votes: 3301,
      postal_votes: 4,
      total_votes: 3305,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 15,
      candidate: "SMT. TARA DEVI SINGH",
      party: "Rashtriya Jansanchar Dal",
      evm_votes: 2701,
      postal_votes: 6,
      total_votes: 2707,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 16,
      candidate: "RAMSAHAY SAHU",
      party: "Aapka Gantantra Party",
      evm_votes: 2430,
      postal_votes: 2,
      total_votes: 2432,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 17,
      candidate: "RAMVISHAL KOL",
      party: "Rashtriya Samaj Paksha",
      evm_votes: 2079,
      postal_votes: 8,
      total_votes: 2087,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 18,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4212,
      postal_votes: 4,
      total_votes: 4216,
      percentage_of_votes: 0.37,
    },
  ],
  total: {
    evm_votes: 1145388,
    postal_votes: 1876,
    total_votes: 1147264,
  },
};
export const s1212 = {
  cs_name: "SHAHDOL",
  cs_shortname: "shahdol",
  cs_code: "s1212",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SMT. HIMADRI SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 709477,
      postal_votes: 1666,
      total_votes: 711143,
      percentage_of_votes: 61.73,
    },
    {
      serial_no: 2,
      candidate: "PHUNDE LAL SINGH MARKO",
      party: "Indian National Congress",
      evm_votes: 312833,
      postal_votes: 970,
      total_votes: 313803,
      percentage_of_votes: 27.24,
    },
    {
      serial_no: 3,
      candidate: "ANIL SINGH DHURVEY",
      party: "Gondvana Gantantra Party",
      evm_votes: 35186,
      postal_votes: 92,
      total_votes: 35278,
      percentage_of_votes: 3.06,
    },
    {
      serial_no: 4,
      candidate: "DHANI RAM KOL",
      party: "Bahujan Samaj Party",
      evm_votes: 21792,
      postal_votes: 62,
      total_votes: 21854,
      percentage_of_votes: 1.9,
    },
    {
      serial_no: 5,
      candidate: "SAMAR SHAH SINGH GOND",
      party: "Communist Party of India",
      evm_votes: 19861,
      postal_votes: 22,
      total_votes: 19883,
      percentage_of_votes: 1.73,
    },
    {
      serial_no: 6,
      candidate: "KESHKALI BAIGA",
      party: "Independent",
      evm_votes: 10042,
      postal_votes: 29,
      total_votes: 10071,
      percentage_of_votes: 0.87,
    },
    {
      serial_no: 7,
      candidate: "GUNJAN SINGH",
      party: "Independent",
      evm_votes: 7460,
      postal_votes: 19,
      total_votes: 7479,
      percentage_of_votes: 0.65,
    },
    {
      serial_no: 8,
      candidate: "RAVIKARAN SINGH DHURVE",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 4964,
      postal_votes: 11,
      total_votes: 4975,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 9,
      candidate: "AMRIT LAL SINGH UIKEY",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 4701,
      postal_votes: 21,
      total_votes: 4722,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 10,
      candidate: "DR. DURGA WATI BHARIA",
      party: "Chhattisgarh Vikas Ganga Rashtriya Party",
      evm_votes: 3425,
      postal_votes: 19,
      total_votes: 3444,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 19341,
      postal_votes: 20,
      total_votes: 19361,
      percentage_of_votes: 1.68,
    },
  ],
  total: {
    evm_votes: 1149082,
    postal_votes: 2931,
    total_votes: 1152013,
  },
};
export const s1213 = {
  cs_name: "JABALPUR",
  cs_shortname: "jabalpur",
  cs_code: "s1213",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ASHISH DUBEY",
      party: "Bharatiya Janata Party",
      evm_votes: 788721,
      postal_votes: 1412,
      total_votes: 790133,
      percentage_of_votes: 68.2,
    },
    {
      serial_no: 2,
      candidate: "DINESH YADAV",
      party: "Indian National Congress",
      evm_votes: 302892,
      postal_votes: 567,
      total_votes: 303459,
      percentage_of_votes: 26.19,
    },
    {
      serial_no: 3,
      candidate: "RAKESH CHOUDHARY",
      party: "Bahujan Samaj Party",
      evm_votes: 21389,
      postal_votes: 27,
      total_votes: 21416,
      percentage_of_votes: 1.85,
    },
    {
      serial_no: 4,
      candidate: "FOJI VIJAY HALDKAR (EX-ARMY)",
      party: "Independent",
      evm_votes: 7028,
      postal_votes: 15,
      total_votes: 7043,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 5,
      candidate: "MAHAVEER JAIN",
      party: "Independent",
      evm_votes: 6463,
      postal_votes: 5,
      total_votes: 6468,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 6,
      candidate: "ADVOCATE UDAY KUMAR SAHU",
      party: "Gondvana Gantantra Party",
      evm_votes: 4832,
      postal_votes: 16,
      total_votes: 4848,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 7,
      candidate: "ER. PRAVIN GAJBHIYE DADA",
      party: "Independent",
      evm_votes: 3437,
      postal_votes: 3,
      total_votes: 3440,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 8,
      candidate: "POORNESH KUMAR JAIN",
      party: "Independent",
      evm_votes: 2822,
      postal_votes: 1,
      total_votes: 2823,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 9,
      candidate: "SWATANATRA SAMAJ SEVI VINAY CHAKRAVARTI",
      party: "Independent",
      evm_votes: 2488,
      postal_votes: 3,
      total_votes: 2491,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 10,
      candidate: "JAGDISH SINGH LODHI",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 1871,
      postal_votes: 4,
      total_votes: 1875,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 11,
      candidate: "DINESH YADAV",
      party: "Independent",
      evm_votes: 1480,
      postal_votes: 4,
      total_votes: 1484,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 12,
      candidate: "DR. DHAI AKSHAR",
      party: "Independent",
      evm_votes: 1399,
      postal_votes: 1,
      total_votes: 1400,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "ASHOK RANA",
      party: "Rashtra Nirman Party",
      evm_votes: 1226,
      postal_votes: 2,
      total_votes: 1228,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 14,
      candidate: "SANTOSH KUMAR KUSHWAHA",
      party: "Independent",
      evm_votes: 1202,
      postal_votes: 2,
      total_votes: 1204,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "DASAI RAM KOL",
      party: "Aadim Samaj Party",
      evm_votes: 1099,
      postal_votes: 1,
      total_votes: 1100,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 16,
      candidate: "COMRADE SACHIN JAIN",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 868,
      postal_votes: 8,
      total_votes: 876,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 17,
      candidate: "GULAB SINGH",
      party: "Independent",
      evm_votes: 821,
      postal_votes: 8,
      total_votes: 829,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "LAL SINGH",
      party: "Rashtriya Samaj Dal (R)",
      evm_votes: 748,
      postal_votes: 4,
      total_votes: 752,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 19,
      candidate: "RAMKUMAR PASI",
      party: "Bahujan Awam Party",
      evm_votes: 684,
      postal_votes: 6,
      total_votes: 690,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 20,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4964,
      postal_votes: 22,
      total_votes: 4986,
      percentage_of_votes: 0.43,
    },
  ],
  total: {
    evm_votes: 1156434,
    postal_votes: 2111,
    total_votes: 1158545,
  },
};
export const s1214 = {
  cs_name: "MANDLA",
  cs_shortname: "mandla",
  cs_code: "s1214",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "FAGGAN SINGH KULASTE",
      party: "Bharatiya Janata Party",
      evm_votes: 749761,
      postal_votes: 1614,
      total_votes: 751375,
      percentage_of_votes: 48.93,
    },
    {
      serial_no: 2,
      candidate: "OMKAR SINGH MARKAM",
      party: "Indian National Congress",
      evm_votes: 645759,
      postal_votes: 1770,
      total_votes: 647529,
      percentage_of_votes: 42.17,
    },
    {
      serial_no: 3,
      candidate: "MAHESH KUMAR WATTEE",
      party: "Gondvana Gantantra Party",
      evm_votes: 37686,
      postal_votes: 111,
      total_votes: 37797,
      percentage_of_votes: 2.46,
    },
    {
      serial_no: 4,
      candidate: "INDAR SINGH UIKEY",
      party: "Bahujan Samaj Party",
      evm_votes: 16587,
      postal_votes: 30,
      total_votes: 16617,
      percentage_of_votes: 1.08,
    },
    {
      serial_no: 5,
      candidate: "CHANDRA SINGH KUSHRAM",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 15533,
      postal_votes: 25,
      total_votes: 15558,
      percentage_of_votes: 1.01,
    },
    {
      serial_no: 6,
      candidate: "SITAR MARKAM",
      party: "Independent",
      evm_votes: 10365,
      postal_votes: 10,
      total_votes: 10375,
      percentage_of_votes: 0.68,
    },
    {
      serial_no: 7,
      candidate: "RAKESH SINGH THAKUR",
      party: "Independent",
      evm_votes: 10340,
      postal_votes: 6,
      total_votes: 10346,
      percentage_of_votes: 0.67,
    },
    {
      serial_no: 8,
      candidate: "DR. BHAW SINGH TEKAM",
      party: "Independent",
      evm_votes: 6393,
      postal_votes: 8,
      total_votes: 6401,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 9,
      candidate: "CHARAN SINGH DHURWEY",
      party: "Bharat Adivasi Party",
      evm_votes: 4269,
      postal_votes: 18,
      total_votes: 4287,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 10,
      candidate: "ADVOCATE-DEV SINGH KUMRE",
      party: "Independent",
      evm_votes: 4205,
      postal_votes: 12,
      total_votes: 4217,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 11,
      candidate: "GHUR SINGH SALLAM",
      party: "Independent",
      evm_votes: 3959,
      postal_votes: 12,
      total_votes: 3971,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 12,
      candidate: "KALIYA BAI KAKODIA",
      party: "Independent",
      evm_votes: 2990,
      postal_votes: 11,
      total_votes: 3001,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 13,
      candidate: "ASHOK SARAIYA",
      party: "Independent",
      evm_votes: 2717,
      postal_votes: 10,
      total_votes: 2727,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 14,
      candidate: "RAMKUMAR INWATI [ADVOCATE]",
      party: "Smart Indians Party",
      evm_votes: 2499,
      postal_votes: 11,
      total_votes: 2510,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 18887,
      postal_votes: 34,
      total_votes: 18921,
      percentage_of_votes: 1.23,
    },
  ],
  total: {
    evm_votes: 1531950,
    postal_votes: 3682,
    total_votes: 1535632,
  },
};
export const s1215 = {
  cs_name: "BALAGHAT",
  cs_shortname: "balaghat",
  cs_code: "s1215",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BHARTI PARDHI",
      party: "Bharatiya Janata Party",
      evm_votes: 709939,
      postal_votes: 2721,
      total_votes: 712660,
      percentage_of_votes: 51.56,
    },
    {
      serial_no: 2,
      candidate: "SAMRAT ASHOK SINGH SARASWAR",
      party: "Indian National Congress",
      evm_votes: 536333,
      postal_votes: 1815,
      total_votes: 538148,
      percentage_of_votes: 38.93,
    },
    {
      serial_no: 3,
      candidate: "KANKAR MUNJARE",
      party: "Bahujan Samaj Party",
      evm_votes: 52883,
      postal_votes: 251,
      total_votes: 53134,
      percentage_of_votes: 3.84,
    },
    {
      serial_no: 4,
      candidate: "BHUVAN SINGH KORAM",
      party: "Independent",
      evm_votes: 13028,
      postal_votes: 46,
      total_votes: 13074,
      percentage_of_votes: 0.95,
    },
    {
      serial_no: 5,
      candidate: "NANDLAL UIKEY",
      party: "Gondvana Gantantra Party",
      evm_votes: 12338,
      postal_votes: 84,
      total_votes: 12422,
      percentage_of_votes: 0.9,
    },
    {
      serial_no: 6,
      candidate: "D.L. MANESHWAR",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 9994,
      postal_votes: 23,
      total_votes: 10017,
      percentage_of_votes: 0.72,
    },
    {
      serial_no: 7,
      candidate: "DHANENDRA DEV PAWAR BANWARI SETH",
      party: "Independent",
      evm_votes: 7185,
      postal_votes: 60,
      total_votes: 7245,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 8,
      candidate: "MAHADEV NAGDEVE",
      party: "Independent",
      evm_votes: 7181,
      postal_votes: 11,
      total_votes: 7192,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 9,
      candidate: "RAJKUMAR NAGESHWER",
      party: "Independent",
      evm_votes: 6723,
      postal_votes: 5,
      total_votes: 6728,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 10,
      candidate: "MOHAN RAUT",
      party: "Rashtravadi Bharat Party",
      evm_votes: 3537,
      postal_votes: 5,
      total_votes: 3542,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 11,
      candidate: "PRIYANKA SANJAY BHANDARKAR",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 2906,
      postal_votes: 20,
      total_votes: 2926,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 12,
      candidate: "ADVOCATE SATYAPRAKASH SHULKE (LODHI)",
      party: "Madhya Pradesh Jan Vikas Party",
      evm_votes: 1929,
      postal_votes: 3,
      total_votes: 1932,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 13,
      candidate: "DILEEP CHHABDA",
      party: "Independent",
      evm_votes: 1733,
      postal_votes: 17,
      total_votes: 1750,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11479,
      postal_votes: 31,
      total_votes: 11510,
      percentage_of_votes: 0.83,
    },
  ],
  total: {
    evm_votes: 1377188,
    postal_votes: 5092,
    total_votes: 1382280,
  },
};
export const s1216 = {
  cs_name: "CHHINDWARA",
  cs_shortname: "chhindwara",
  cs_code: "s1216",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BUNTY VIVEK SAHU",
      party: "Bharatiya Janata Party",
      evm_votes: 643489,
      postal_votes: 1249,
      total_votes: 644738,
      percentage_of_votes: 49.41,
    },
    {
      serial_no: 2,
      candidate: "NAKUL KAMALNATH",
      party: "Indian National Congress",
      evm_votes: 529931,
      postal_votes: 1189,
      total_votes: 531120,
      percentage_of_votes: 40.7,
    },
    {
      serial_no: 3,
      candidate: "DEVIRAM URF DEV RAVEN BHALAVI",
      party: "Gondvana Gantantra Party",
      evm_votes: 55854,
      postal_votes: 134,
      total_votes: 55988,
      percentage_of_votes: 4.29,
    },
    {
      serial_no: 4,
      candidate: "UMAKANT BANDEWAR",
      party: "Bahujan Samaj Party",
      evm_votes: 11802,
      postal_votes: 21,
      total_votes: 11823,
      percentage_of_votes: 0.91,
    },
    {
      serial_no: 5,
      candidate: "KAPIL SONI",
      party: "Rashtriya Gondvana Party",
      evm_votes: 9634,
      postal_votes: 4,
      total_votes: 9638,
      percentage_of_votes: 0.74,
    },
    {
      serial_no: 6,
      candidate: "SIRAJ SHEKH",
      party: "Independent",
      evm_votes: 9567,
      postal_votes: 1,
      total_votes: 9568,
      percentage_of_votes: 0.73,
    },
    {
      serial_no: 7,
      candidate: "PAWANSHAH SARYAM",
      party: "Independent",
      evm_votes: 6612,
      postal_votes: 9,
      total_votes: 6621,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 8,
      candidate: "SUBHASH SHUKLA",
      party: "Independent",
      evm_votes: 5768,
      postal_votes: 1,
      total_votes: 5769,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 9,
      candidate: "RAJENDRA DONGRE",
      party: "Independent",
      evm_votes: 4628,
      postal_votes: 4,
      total_votes: 4632,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 10,
      candidate: "GOVIND BHALAVI",
      party: "Independent",
      evm_votes: 4022,
      postal_votes: 7,
      total_votes: 4029,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 11,
      candidate: "MOHD. PARVEZ QURESHI",
      party: "Independent",
      evm_votes: 3818,
      postal_votes: 10,
      total_votes: 3828,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 12,
      candidate: "AJAY BARKADE",
      party: "Independent",
      evm_votes: 2491,
      postal_votes: 4,
      total_votes: 2495,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 13,
      candidate: "KUSHWAHA RAJESH TANTRIK",
      party: "Ahinsa Samaj Party",
      evm_votes: 1695,
      postal_votes: 3,
      total_votes: 1698,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 14,
      candidate: " PRAKASH ALIAS BANTY  PARTETI",
      party: "Rashtra Samarpan Party",
      evm_votes: 1652,
      postal_votes: 2,
      total_votes: 1654,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 15,
      candidate: "FOGAL BANSOD",
      party: "Republican Party of India (A)",
      evm_votes: 1322,
      postal_votes: 1,
      total_votes: 1323,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9887,
      postal_votes: 16,
      total_votes: 9903,
      percentage_of_votes: 0.76,
    },
  ],
  total: {
    evm_votes: 1302172,
    postal_votes: 2655,
    total_votes: 1304827,
  },
};
export const s1217 = {
  cs_name: "HOSHANGABAD",
  cs_shortname: "hoshangabad",
  cs_code: "s1217",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DARSHAN SINGH CHOUDHARY",
      party: "Bharatiya Janata Party",
      evm_votes: 810529,
      postal_votes: 1618,
      total_votes: 812147,
      percentage_of_votes: 64.99,
    },
    {
      serial_no: 2,
      candidate: "SANJAY SHARMA SANJU BHAIYA",
      party: "Indian National Congress",
      evm_votes: 379868,
      postal_votes: 583,
      total_votes: 380451,
      percentage_of_votes: 30.44,
    },
    {
      serial_no: 3,
      candidate: "RAMGOVIND BARUAA",
      party: "Bahujan Samaj Party",
      evm_votes: 15344,
      postal_votes: 43,
      total_votes: 15387,
      percentage_of_votes: 1.23,
    },
    {
      serial_no: 4,
      candidate: "PANDIT DIVYENDRA BRIJMOHAN DUBEY ADVOCATE",
      party: "Bharatiya Yuva Jan Ekta Party",
      evm_votes: 6157,
      postal_votes: 11,
      total_votes: 6168,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 5,
      candidate: "RAKESH RAMPRASAD RICKY",
      party: "Independent",
      evm_votes: 5071,
      postal_votes: 8,
      total_votes: 5079,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 6,
      candidate: "SARJERAO SAHARE",
      party: "Independent",
      evm_votes: 4487,
      postal_votes: 6,
      total_votes: 4493,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 7,
      candidate: "PATRAKAR TILAK JATAV",
      party: "Independent",
      evm_votes: 2257,
      postal_votes: 3,
      total_votes: 2260,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "YUVRAJ GAVADE",
      party: "Independent",
      evm_votes: 2205,
      postal_votes: 10,
      total_votes: 2215,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 9,
      candidate: "JYOTI SURESH JHARIYA",
      party: "Independent",
      evm_votes: 2033,
      postal_votes: 13,
      total_votes: 2046,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 10,
      candidate: "MAKHAN SINGH LODHI",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 1651,
      postal_votes: 6,
      total_votes: 1657,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "MUKESH YADAV",
      party: "Independent",
      evm_votes: 1578,
      postal_votes: 10,
      total_votes: 1588,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 12,
      candidate: "PANDIT BRAJESH KHEMARIYA",
      party: "Independent",
      evm_votes: 1251,
      postal_votes: 9,
      total_votes: 1260,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 14927,
      postal_votes: 14,
      total_votes: 14941,
      percentage_of_votes: 1.2,
    },
  ],
  total: {
    evm_votes: 1247358,
    postal_votes: 2334,
    total_votes: 1249692,
  },
};
export const s1218 = {
  cs_name: "VIDISHA",
  cs_shortname: "vidisha",
  cs_code: "s1218",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SHIVRAJ SINGH CHOUHAN",
      party: "Bharatiya Janata Party",
      evm_votes: 1111556,
      postal_votes: 4904,
      total_votes: 1116460,
      percentage_of_votes: 76.7,
    },
    {
      serial_no: 2,
      candidate: "PRATAPBHANU SHARMA",
      party: "Indian National Congress",
      evm_votes: 293537,
      postal_votes: 1515,
      total_votes: 295052,
      percentage_of_votes: 20.27,
    },
    {
      serial_no: 3,
      candidate: "KISHAN LAL LADIYA",
      party: "Bahujan Samaj Party",
      evm_votes: 10734,
      postal_votes: 82,
      total_votes: 10816,
      percentage_of_votes: 0.74,
    },
    {
      serial_no: 4,
      candidate: "KAMLESH KUMAR GOUR",
      party: "Jai Prakash Janata Dal",
      evm_votes: 8167,
      postal_votes: 11,
      total_votes: 8178,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 5,
      candidate: "DHOOLSINGH DHAMMA",
      party: "Independent",
      evm_votes: 3417,
      postal_votes: 7,
      total_votes: 3424,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 6,
      candidate: "SATYENDRA SINGH SISODIYA",
      party: "Independent",
      evm_votes: 2921,
      postal_votes: 4,
      total_votes: 2925,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 7,
      candidate: "DHARMVIR BHARTI",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 2809,
      postal_votes: 10,
      total_votes: 2819,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 8,
      candidate: "ABDUL RASHID",
      party: "Independent",
      evm_votes: 1679,
      postal_votes: 3,
      total_votes: 1682,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 9,
      candidate: "BHAI MUNSHILAL SILAWAT",
      party: "Public Political Party",
      evm_votes: 1114,
      postal_votes: 3,
      total_votes: 1117,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 10,
      candidate: "ABDUL JABBAR",
      party: "Independent",
      evm_votes: 1076,
      postal_votes: 6,
      total_votes: 1082,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "SEEMA SHARMA",
      party: "Sapaks Party",
      evm_votes: 1065,
      postal_votes: 8,
      total_votes: 1073,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "DHARMENDRA SINGH PANWAR (GOLU BHAIYA)",
      party: "Right to Recall Party",
      evm_votes: 1025,
      postal_votes: 1,
      total_votes: 1026,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "BHEEKAM SINGH KUSHWAHA",
      party: "Mahanwadi Party",
      evm_votes: 720,
      postal_votes: 4,
      total_votes: 724,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9218,
      postal_votes: 62,
      total_votes: 9280,
      percentage_of_votes: 0.64,
    },
  ],
  total: {
    evm_votes: 1449038,
    postal_votes: 6620,
    total_votes: 1455658,
  },
};
export const s1219 = {
  cs_name: "BHOPAL",
  cs_shortname: "bhopal",
  cs_code: "s1219",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ALOK SHARMA",
      party: "Bharatiya Janata Party",
      evm_votes: 978285,
      postal_votes: 2824,
      total_votes: 981109,
      percentage_of_votes: 65.48,
    },
    {
      serial_no: 2,
      candidate: "ADVOCATE ARUN SHRIVASTAVA",
      party: "Indian National Congress",
      evm_votes: 478698,
      postal_votes: 912,
      total_votes: 479610,
      percentage_of_votes: 32.01,
    },
    {
      serial_no: 3,
      candidate: "BHANU PRATAP SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 13255,
      postal_votes: 50,
      total_votes: 13305,
      percentage_of_votes: 0.89,
    },
    {
      serial_no: 4,
      candidate: "DEENDAYAL AHIRWAR URF DEENU BHAIYA",
      party: "Independent",
      evm_votes: 3639,
      postal_votes: 2,
      total_votes: 3641,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 5,
      candidate: "JAY SINGH",
      party: "Independent",
      evm_votes: 2509,
      postal_votes: 1,
      total_votes: 2510,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 6,
      candidate: "BHARTI YADAV",
      party: "Independent",
      evm_votes: 1833,
      postal_votes: 6,
      total_votes: 1839,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 7,
      candidate: "ANKIT RAI",
      party: "Independent",
      evm_votes: 1100,
      postal_votes: 1,
      total_votes: 1101,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 8,
      candidate: "DHANRAJ SHENDE",
      party: "Republican Party of India (A)",
      evm_votes: 788,
      postal_votes: 2,
      total_votes: 790,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 9,
      candidate: "AJAY KUMAR PATHAK",
      party: "Chhatrapati Shivaji Bhartiya Garib Party",
      evm_votes: 787,
      postal_votes: 3,
      total_votes: 790,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 10,
      candidate: "MOHAMMAD ASHRAF",
      party: "Independent",
      evm_votes: 777,
      postal_votes: 1,
      total_votes: 778,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 11,
      candidate: "BABULAL SEN",
      party: "Moulik Adhikar Party",
      evm_votes: 718,
      postal_votes: 2,
      total_votes: 720,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 12,
      candidate: "SANJAY KUMAR SAROJ",
      party: "Manav Samadhan Party",
      evm_votes: 621,
      postal_votes: 1,
      total_votes: 622,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 13,
      candidate: "BALRAM SINGH TAUMAR",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 587,
      postal_votes: 1,
      total_votes: 588,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 14,
      candidate: "RAMPRASAD PATEL",
      party: "Kranti Janshakti Party",
      evm_votes: 572,
      postal_votes: 3,
      total_votes: 575,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 15,
      candidate: "MUDIT CHAURASIA",
      party: "Akhil Bhartiya Aarakshit Samaj Party",
      evm_votes: 541,
      postal_votes: 3,
      total_votes: 544,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "MUDIT BHATNAGAR",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 537,
      postal_votes: 3,
      total_votes: 540,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 17,
      candidate: "R.K. MAHAJAN",
      party: "Independent",
      evm_votes: 527,
      postal_votes: 2,
      total_votes: 529,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 18,
      candidate:
        "ABDUL TAHIR ADVOCATE (BABLU RAJNIKANT) S/O SAEED PAHELWAN ANSARI",
      party: "Bahujan Maha Party",
      evm_votes: 473,
      postal_votes: 0,
      total_votes: 473,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 19,
      candidate: "MAITHILI SHARAN GUPTA",
      party: "Independent",
      evm_votes: 447,
      postal_votes: 1,
      total_votes: 448,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 20,
      candidate: "RAJESH KEER",
      party: "Independent",
      evm_votes: 427,
      postal_votes: 0,
      total_votes: 427,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 21,
      candidate: "AKSHAY GOTHI",
      party: "Parivartan Party of India",
      evm_votes: 403,
      postal_votes: 4,
      total_votes: 407,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "HITENDRA SHAHARE (BABA)",
      party: "Independent",
      evm_votes: 318,
      postal_votes: 0,
      total_votes: 318,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 23,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6573,
      postal_votes: 48,
      total_votes: 6621,
      percentage_of_votes: 0.44,
    },
  ],
  total: {
    evm_votes: 1494415,
    postal_votes: 3870,
    total_votes: 1498285,
  },
};
export const s1220 = {
  cs_name: "RAJGARH",
  cs_shortname: "rajgarh",
  cs_code: "s1220",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "RODMAL NAGAR",
      party: "Bharatiya Janata Party",
      evm_votes: 756139,
      postal_votes: 2604,
      total_votes: 758743,
      percentage_of_votes: 53.1,
    },
    {
      serial_no: 2,
      candidate: "DIGVIJAYA SINGH",
      party: "Indian National Congress",
      evm_votes: 610602,
      postal_votes: 2052,
      total_votes: 612654,
      percentage_of_votes: 42.87,
    },
    {
      serial_no: 3,
      candidate: "DR. RAJENDRA SURYAVANSHI",
      party: "Bahujan Samaj Party",
      evm_votes: 13902,
      postal_votes: 40,
      total_votes: 13942,
      percentage_of_votes: 0.98,
    },
    {
      serial_no: 4,
      candidate: "ASHOK PAWAR",
      party: "Samata Samadhan Party",
      evm_votes: 6597,
      postal_votes: 17,
      total_votes: 6614,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 5,
      candidate: "JITENDRA SINGH",
      party: "National World Leader Party",
      evm_votes: 5051,
      postal_votes: 12,
      total_votes: 5063,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 6,
      candidate: "DINESH RAZAWAT",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 4170,
      postal_votes: 9,
      total_votes: 4179,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 7,
      candidate: "RODMAL",
      party: "Independent",
      evm_votes: 3733,
      postal_votes: 11,
      total_votes: 3744,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 8,
      candidate: "ANIL JAIN",
      party: "Independent",
      evm_votes: 3069,
      postal_votes: 20,
      total_votes: 3089,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 9,
      candidate: "RADHESHYAM MALVIYA",
      party: "Independent",
      evm_votes: 2796,
      postal_votes: 3,
      total_votes: 2799,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 10,
      candidate: "SUNEEL BHEEL",
      party: "Independent",
      evm_votes: 2703,
      postal_votes: 10,
      total_votes: 2713,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 11,
      candidate: "VISHAL SONI (ADVOCATE)",
      party: "Bharatiya Yuva Jan Ekta Party",
      evm_votes: 2544,
      postal_votes: 16,
      total_votes: 2560,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 12,
      candidate: "JAGDISH KARPENTER",
      party: "Independent",
      evm_votes: 1730,
      postal_votes: 4,
      total_votes: 1734,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "ANIL",
      party: "Independent",
      evm_votes: 1414,
      postal_votes: 4,
      total_votes: 1418,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 14,
      candidate: "RAMCHARAN PIRJAPATI",
      party: "Lok Samaj Party",
      evm_votes: 1292,
      postal_votes: 2,
      total_votes: 1294,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 15,
      candidate: "BABU SINGH",
      party: "Sajag Samaj Party",
      evm_votes: 1186,
      postal_votes: 5,
      total_votes: 1191,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7248,
      postal_votes: 12,
      total_votes: 7260,
      percentage_of_votes: 0.51,
    },
  ],
  total: {
    evm_votes: 1424176,
    postal_votes: 4821,
    total_votes: 1428997,
  },
};
export const s1221 = {
  cs_name: "DEWAS",
  cs_shortname: "dewas",
  cs_code: "s1221",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "MAHENDRA SINGH SOLANKY",
      party: "Bharatiya Janata Party",
      evm_votes: 925917,
      postal_votes: 3024,
      total_votes: 928941,
      percentage_of_votes: 63.23,
    },
    {
      serial_no: 2,
      candidate: "RAJENDRA RADHAKISHAN MALVIYA",
      party: "Indian National Congress",
      evm_votes: 502548,
      postal_votes: 1168,
      total_votes: 503716,
      percentage_of_votes: 34.29,
    },
    {
      serial_no: 3,
      candidate: "RAJENDRASINGH CHOKHUTIYA",
      party: "Bahujan Samaj Party",
      evm_votes: 11162,
      postal_votes: 41,
      total_votes: 11203,
      percentage_of_votes: 0.76,
    },
    {
      serial_no: 4,
      candidate: "RAMPRASAD",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 7201,
      postal_votes: 19,
      total_votes: 7220,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 5,
      candidate: "NITIN VERMA",
      party: "Independent",
      evm_votes: 2756,
      postal_votes: 5,
      total_votes: 2761,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 6,
      candidate: "ADVOCATE VIDAYARAJ MALAVIY",
      party: "Social Democratic Party Of India",
      evm_votes: 2026,
      postal_votes: 5,
      total_votes: 2031,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 7,
      candidate: "ENG. DEEPAK RAMESH CHANDRA VICHITRA",
      party: "Independent",
      evm_votes: 1585,
      postal_votes: 10,
      total_votes: 1595,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 8,
      candidate: "HEMRAJ POONAMCHANDRA BAMNIYA",
      party: "Public Political Party",
      evm_votes: 1236,
      postal_votes: 12,
      total_votes: 1248,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10365,
      postal_votes: 24,
      total_votes: 10389,
      percentage_of_votes: 0.71,
    },
  ],
  total: {
    evm_votes: 1464796,
    postal_votes: 4308,
    total_votes: 1469104,
  },
};
export const s1222 = {
  cs_name: "UJJAIN",
  cs_shortname: "ujjain",
  cs_code: "s1222",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ANIL FIROJIYA",
      party: "Bharatiya Janata Party",
      evm_votes: 834374,
      postal_votes: 1730,
      total_votes: 836104,
      percentage_of_votes: 62.93,
    },
    {
      serial_no: 2,
      candidate: "MAHESH PARMAR",
      party: "Indian National Congress",
      evm_votes: 459531,
      postal_votes: 713,
      total_votes: 460244,
      percentage_of_votes: 34.64,
    },
    {
      serial_no: 3,
      candidate: "ADVOCATE PRAKASH CHOUHAN",
      party: "Bahujan Samaj Party",
      evm_votes: 9502,
      postal_votes: 16,
      total_votes: 9518,
      percentage_of_votes: 0.72,
    },
    {
      serial_no: 4,
      candidate: "DR. HEMANT PARMAR",
      party: "Bhim Sena",
      evm_votes: 4973,
      postal_votes: 3,
      total_votes: 4976,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 5,
      candidate: "SURESH BAGARI",
      party: "Independent",
      evm_votes: 2786,
      postal_votes: 5,
      total_votes: 2791,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 6,
      candidate: "MAHESH PARMAR",
      party: "Independent",
      evm_votes: 2468,
      postal_votes: 8,
      total_votes: 2476,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 7,
      candidate: "GANGA MALVIYA",
      party: "Independent",
      evm_votes: 1288,
      postal_votes: 7,
      total_votes: 1295,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 8,
      candidate: "ANIL",
      party: "Independent",
      evm_votes: 979,
      postal_votes: 2,
      total_votes: 981,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 9,
      candidate: "ISHWARLAL VARSHI",
      party: "Independent",
      evm_votes: 860,
      postal_votes: 3,
      total_votes: 863,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9310,
      postal_votes: 22,
      total_votes: 9332,
      percentage_of_votes: 0.7,
    },
  ],
  total: {
    evm_votes: 1326071,
    postal_votes: 2509,
    total_votes: 1328580,
  },
};
export const s1223 = {
  cs_name: "MANDSOUR",
  cs_shortname: "mandsour",
  cs_code: "s1223",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SUDHEER GUPTA",
      party: "Bharatiya Janata Party",
      evm_votes: 942249,
      postal_votes: 3512,
      total_votes: 945761,
      percentage_of_votes: 65.98,
    },
    {
      serial_no: 2,
      candidate: "DILIP SINGH GURJAR",
      party: "Indian National Congress",
      evm_votes: 443622,
      postal_votes: 1484,
      total_votes: 445106,
      percentage_of_votes: 31.05,
    },
    {
      serial_no: 3,
      candidate: "KANHAIYALAL MALVIYA",
      party: "Bahujan Samaj Party",
      evm_votes: 10456,
      postal_votes: 56,
      total_votes: 10512,
      percentage_of_votes: 0.73,
    },
    {
      serial_no: 4,
      candidate: "ISMAIL KHAN MAIV",
      party: "Independent",
      evm_votes: 8810,
      postal_votes: 16,
      total_votes: 8826,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 5,
      candidate: "SUSHIL",
      party: "Independent",
      evm_votes: 3432,
      postal_votes: 7,
      total_votes: 3439,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 6,
      candidate: "SAEED AHAMAD",
      party: "Independent",
      evm_votes: 3165,
      postal_votes: 2,
      total_votes: 3167,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 7,
      candidate: "MURALIDHAR",
      party: "Independent",
      evm_votes: 2682,
      postal_votes: 14,
      total_votes: 2696,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 8,
      candidate: "VIJAY RANN",
      party: "Independent",
      evm_votes: 2314,
      postal_votes: 9,
      total_votes: 2323,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11615,
      postal_votes: 47,
      total_votes: 11662,
      percentage_of_votes: 0.81,
    },
  ],
  total: {
    evm_votes: 1428345,
    postal_votes: 5147,
    total_votes: 1433492,
  },
};
export const s1224 = {
  cs_name: "RATLAM",
  cs_shortname: "ratlam",
  cs_code: "s1224",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ANITA NAGARSINGH CHOUHAN",
      party: "Bharatiya Janata Party",
      evm_votes: 793432,
      postal_votes: 2431,
      total_votes: 795863,
      percentage_of_votes: 51.93,
    },
    {
      serial_no: 2,
      candidate: "KANTILAL BHURIA",
      party: "Indian National Congress",
      evm_votes: 586904,
      postal_votes: 1727,
      total_votes: 588631,
      percentage_of_votes: 38.41,
    },
    {
      serial_no: 3,
      candidate: "ENGINEER BALUSINGH GAMAD",
      party: "Bharat Adivasi Party",
      evm_votes: 52525,
      postal_votes: 234,
      total_votes: 52759,
      percentage_of_votes: 3.44,
    },
    {
      serial_no: 4,
      candidate: "RAMCHANDRA SOLANKI",
      party: "Bahujan Samaj Party",
      evm_votes: 15159,
      postal_votes: 13,
      total_votes: 15172,
      percentage_of_votes: 0.99,
    },
    {
      serial_no: 5,
      candidate: "SURAJ BHABHAR",
      party: "Independent",
      evm_votes: 10060,
      postal_votes: 2,
      total_votes: 10062,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 6,
      candidate: "RANGLA KALESH",
      party: "Independent",
      evm_votes: 7331,
      postal_votes: 4,
      total_votes: 7335,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 7,
      candidate: "KASNA RANA PARGI",
      party: "Independent",
      evm_votes: 7311,
      postal_votes: 5,
      total_votes: 7316,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 8,
      candidate: "SUMITRA MEDA",
      party: "Independent",
      evm_votes: 6927,
      postal_votes: 3,
      total_votes: 6930,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 9,
      candidate: "RAMESHWAR SINGAR",
      party: "Independent",
      evm_votes: 4603,
      postal_votes: 4,
      total_votes: 4607,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 10,
      candidate: "MOHANSINGH NINGWAL",
      party: "Bhartiya Samajik Party",
      evm_votes: 4503,
      postal_votes: 4,
      total_votes: 4507,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 11,
      candidate: "SHEETAL BARELA",
      party: "Akhand Bharat Samrajya Party",
      evm_votes: 3929,
      postal_votes: 1,
      total_votes: 3930,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 12,
      candidate: "UDESINGH MACHAR",
      party: "Republican Party of India (A)",
      evm_votes: 3795,
      postal_votes: 1,
      total_votes: 3796,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 31697,
      postal_votes: 38,
      total_votes: 31735,
      percentage_of_votes: 2.07,
    },
  ],
  total: {
    evm_votes: 1528176,
    postal_votes: 4467,
    total_votes: 1532643,
  },
};
export const s1225 = {
  cs_name: "DHAR",
  cs_shortname: "dhar",
  cs_code: "s1225",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SAVITRI THAKUR",
      party: "Bharatiya Janata Party",
      evm_votes: 792591,
      postal_votes: 1858,
      total_votes: 794449,
      percentage_of_votes: 55.75,
    },
    {
      serial_no: 2,
      candidate: "RADHESHYAM MUVEL",
      party: "Indian National Congress",
      evm_votes: 574756,
      postal_votes: 1028,
      total_votes: 575784,
      percentage_of_votes: 40.4,
    },
    {
      serial_no: 3,
      candidate: "DHUMSINGH MANDLOI MACHHAR",
      party: "Bahujan Samaj Party",
      evm_votes: 14926,
      postal_votes: 30,
      total_votes: 14956,
      percentage_of_votes: 1.05,
    },
    {
      serial_no: 4,
      candidate: "JITENDRA MUNIYA",
      party: "Bharat Adivasi Party",
      evm_votes: 10586,
      postal_votes: 35,
      total_votes: 10621,
      percentage_of_votes: 0.75,
    },
    {
      serial_no: 5,
      candidate: "SUNIL AJRAWAT",
      party: "Independent",
      evm_votes: 5400,
      postal_votes: 8,
      total_votes: 5408,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 6,
      candidate: "SHIVAJI PATEL KALMI",
      party: "Independent",
      evm_votes: 4694,
      postal_votes: 6,
      total_votes: 4700,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 7,
      candidate: "VIKRAM VASUNIYA",
      party: "Janata Congress",
      evm_votes: 3464,
      postal_votes: 11,
      total_votes: 3475,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 15612,
      postal_votes: 39,
      total_votes: 15651,
      percentage_of_votes: 1.1,
    },
  ],
  total: {
    evm_votes: 1422029,
    postal_votes: 3015,
    total_votes: 1425044,
  },
};
export const s1226 = {
  cs_name: "INDORE",
  cs_shortname: "indore",
  cs_code: "s1226",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SHANKAR LALWANI",
      party: "Bharatiya Janata Party",
      evm_votes: 1223746,
      postal_votes: 3005,
      total_votes: 1226751,
      percentage_of_votes: 78.54,
    },
    {
      serial_no: 2,
      candidate: "SANJAY S/O LAKSHMAN SOLANKI",
      party: "Bahujan Samaj Party",
      evm_votes: 51486,
      postal_votes: 173,
      total_votes: 51659,
      percentage_of_votes: 3.31,
    },
    {
      serial_no: 3,
      candidate: "PAWAN KUMAR",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 15182,
      postal_votes: 28,
      total_votes: 15210,
      percentage_of_votes: 0.97,
    },
    {
      serial_no: 4,
      candidate: "ABHAY JAIN",
      party: "Independent",
      evm_votes: 8379,
      postal_votes: 13,
      total_votes: 8392,
      percentage_of_votes: 0.54,
    },
    {
      serial_no: 5,
      candidate: "LAVISH DILIP KHANDELWAL",
      party: "Independent",
      evm_votes: 8231,
      postal_votes: 7,
      total_votes: 8238,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 6,
      candidate: "COMRADE AJEET SINGH",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 7148,
      postal_votes: 31,
      total_votes: 7179,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 7,
      candidate: "AYAZ ALI",
      party: "Independent",
      evm_votes: 7074,
      postal_votes: 22,
      total_votes: 7096,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 8,
      candidate: "ENG. ARJUN PARIHAR",
      party: "Independent",
      evm_votes: 3823,
      postal_votes: 8,
      total_votes: 3831,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 9,
      candidate: "BASANT GEHLOT",
      party: "Jan Sangh Party",
      evm_votes: 3663,
      postal_votes: 13,
      total_votes: 3676,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 10,
      candidate: "RAVI SIRWAIYA",
      party: "Independent",
      evm_votes: 2615,
      postal_votes: 6,
      total_votes: 2621,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 11,
      candidate: "MUDIT CHAURASIA",
      party: "Independent",
      evm_votes: 2601,
      postal_votes: 8,
      total_votes: 2609,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "PARMANAND TOLANI",
      party: "Independent",
      evm_votes: 2399,
      postal_votes: 4,
      total_votes: 2403,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 13,
      candidate: "DESH BHAKT ANKIT GUPTA",
      party: "Independent",
      evm_votes: 1957,
      postal_votes: 12,
      total_votes: 1969,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 14,
      candidate: "ADVOCATE PANKAJ GUPTE",
      party: "Independent",
      evm_votes: 1652,
      postal_votes: 8,
      total_votes: 1660,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 218355,
      postal_votes: 319,
      total_votes: 218674,
      percentage_of_votes: 14,
    },
  ],
  total: {
    evm_votes: 1558311,
    postal_votes: 3657,
    total_votes: 1561968,
  },
};
export const s1227 = {
  cs_name: "KHARGONE",
  cs_shortname: "khargone",
  cs_code: "s1227",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "GAJENDRA SINGH PATEL",
      party: "Bharatiya Janata Party",
      evm_votes: 818291,
      postal_votes: 1572,
      total_votes: 819863,
      percentage_of_votes: 52.6,
    },
    {
      serial_no: 2,
      candidate: "PORLAL BATHA KHARTE",
      party: "Indian National Congress",
      evm_votes: 683651,
      postal_votes: 1194,
      total_votes: 684845,
      percentage_of_votes: 43.93,
    },
    {
      serial_no: 3,
      candidate: "DEVISINGH NARGAVE",
      party: "Communist Party of India",
      evm_votes: 14680,
      postal_votes: 6,
      total_votes: 14686,
      percentage_of_votes: 0.94,
    },
    {
      serial_no: 4,
      candidate: "SHOBHARAM DAWAR",
      party: "Bahujan Samaj Party",
      evm_votes: 12047,
      postal_votes: 22,
      total_votes: 12069,
      percentage_of_votes: 0.77,
    },
    {
      serial_no: 5,
      candidate: "NARSINGH SOLANKI",
      party: "Independent",
      evm_votes: 9052,
      postal_votes: 6,
      total_votes: 9058,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 6,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 18237,
      postal_votes: 20,
      total_votes: 18257,
      percentage_of_votes: 1.17,
    },
  ],
  total: {
    evm_votes: 1555958,
    postal_votes: 2820,
    total_votes: 1558778,
  },
};
export const s1228 = {
  cs_name: "KHANDWA",
  cs_shortname: "khandwa",
  cs_code: "s1228",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "GYANESHWAR PATIL",
      party: "Bharatiya Janata Party",
      evm_votes: 861188,
      postal_votes: 1491,
      total_votes: 862679,
      percentage_of_votes: 57.04,
    },
    {
      serial_no: 2,
      candidate: "NARENDRA PATEL",
      party: "Indian National Congress",
      evm_votes: 591540,
      postal_votes: 1168,
      total_votes: 592708,
      percentage_of_votes: 39.19,
    },
    {
      serial_no: 3,
      candidate: "MUNNALAL S/O DHANNALAL TEJI",
      party: "Bahujan Samaj Party",
      evm_votes: 11796,
      postal_votes: 22,
      total_votes: 11818,
      percentage_of_votes: 0.78,
    },
    {
      serial_no: 4,
      candidate: "ABHINESH SINGH",
      party: "Rashtriya Jan Awaz Party",
      evm_votes: 9295,
      postal_votes: 4,
      total_votes: 9299,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 5,
      candidate: "LAXMI NARAYAN KATARE",
      party: "Independent",
      evm_votes: 4902,
      postal_votes: 3,
      total_votes: 4905,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 6,
      candidate: "MANOJ KUMAR VISHWAMBHARNATH AGRAWAL",
      party: "Independent",
      evm_votes: 4346,
      postal_votes: 1,
      total_votes: 4347,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 7,
      candidate: "MAHESH PAWAR",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 3701,
      postal_votes: 8,
      total_votes: 3709,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 8,
      candidate: "SHIVAM SEN",
      party: "Independent",
      evm_votes: 3479,
      postal_votes: 2,
      total_votes: 3481,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 9,
      candidate: "RAVINDRA SONVANE",
      party: "Independent",
      evm_votes: 2679,
      postal_votes: 6,
      total_votes: 2685,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 10,
      candidate: "PRAKASH RATHOR BANJARA",
      party: "Aam Janta Party (India)",
      evm_votes: 2368,
      postal_votes: 2,
      total_votes: 2370,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 11,
      candidate: "NARAYAN SUKHADEV PAL",
      party: "Bharatiya Yuva Jan Ekta Party",
      evm_votes: 1699,
      postal_votes: 3,
      total_votes: 1702,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12817,
      postal_votes: 22,
      total_votes: 12839,
      percentage_of_votes: 0.85,
    },
  ],
  total: {
    evm_votes: 1509810,
    postal_votes: 2732,
    total_votes: 1512542,
  },
};
export const s1229 = {
  cs_name: "BETUL",
  cs_shortname: "betul",
  cs_code: "s1229",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DURGADAS (D. D.) UIKEY",
      party: "Bharatiya Janata Party",
      evm_votes: 846696,
      postal_votes: 1540,
      total_votes: 848236,
      percentage_of_votes: 60.76,
    },
    {
      serial_no: 2,
      candidate: "RAMU TEKAM",
      party: "Indian National Congress",
      evm_votes: 467810,
      postal_votes: 665,
      total_votes: 468475,
      percentage_of_votes: 33.56,
    },
    {
      serial_no: 3,
      candidate: "ARJUN ASHOK BHALAVI",
      party: "Bahujan Samaj Party",
      evm_votes: 26559,
      postal_votes: 38,
      total_votes: 26597,
      percentage_of_votes: 1.91,
    },
    {
      serial_no: 4,
      candidate: "ANIL UIKEY",
      party: "Bharat Adivasi Party",
      evm_votes: 10552,
      postal_votes: 24,
      total_votes: 10576,
      percentage_of_votes: 0.76,
    },
    {
      serial_no: 5,
      candidate: "BHURELAL CHOTELAL BETHEKAR",
      party: "Independent",
      evm_votes: 7800,
      postal_votes: 11,
      total_votes: 7811,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 6,
      candidate: "BHAGCHARAN WARKADE",
      party: "Independent",
      evm_votes: 5957,
      postal_votes: 11,
      total_votes: 5968,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 7,
      candidate: "SUNER UIKEY",
      party: "Gondvana Gantantra Party",
      evm_votes: 4172,
      postal_votes: 14,
      total_votes: 4186,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 8,
      candidate: "BARSKAR SUBHASH KORAKU",
      party: "Swatantra Kisan Party",
      evm_votes: 3831,
      postal_votes: 18,
      total_votes: 3849,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 20309,
      postal_votes: 13,
      total_votes: 20322,
      percentage_of_votes: 1.46,
    },
  ],
  total: {
    evm_votes: 1393686,
    postal_votes: 2334,
    total_votes: 1396020,
  },
};
export const s133 = {
  cs_name: "Jalgaon",
  cs_shortname: "jalgaon",
  cs_code: "s133",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SMITA UDAY WAGH",
      party: "Bharatiya Janata Party",
      evm_votes: 672119,
      postal_votes: 2309,
      total_votes: 674428,
      percentage_of_votes: 57.67,
    },
    {
      serial_no: 2,
      candidate: "KARAN BALASAHEB PATIL - PAWAR",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 421213,
      postal_votes: 1621,
      total_votes: 422834,
      percentage_of_votes: 36.15,
    },
    {
      serial_no: 3,
      candidate: "YUVRAJ JADHAV (SAMBHA AAPPA)",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 21062,
      postal_votes: 115,
      total_votes: 21177,
      percentage_of_votes: 1.81,
    },
    {
      serial_no: 4,
      candidate: "EX-HAVILDAR ISHWAR DAYARAM MORE",
      party: "Sainik Samaj Party",
      evm_votes: 7479,
      postal_votes: 69,
      total_votes: 7548,
      percentage_of_votes: 0.65,
    },
    {
      serial_no: 5,
      candidate: "VILAS SHANKAR TAYADE",
      party: "Bahujan Samaj Party",
      evm_votes: 6310,
      postal_votes: 71,
      total_votes: 6381,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 6,
      candidate: "LALIT (BANTI) SHARMA",
      party: "Independent",
      evm_votes: 4189,
      postal_votes: 3,
      total_votes: 4192,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 7,
      candidate: "KARAN PAWAR",
      party: "Independent",
      evm_votes: 3954,
      postal_votes: 29,
      total_votes: 3983,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 8,
      candidate: "ADVOCATE VIJAY BABULAL DANEJ",
      party: "Independent",
      evm_votes: 3400,
      postal_votes: 4,
      total_votes: 3404,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 9,
      candidate: "MUKESH MULCHAND KOLI",
      party: "Independent",
      evm_votes: 3358,
      postal_votes: 12,
      total_votes: 3370,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 10,
      candidate: "MAHENDRA DEVRAM KOLI",
      party: "Independent",
      evm_votes: 2462,
      postal_votes: 14,
      total_votes: 2476,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 11,
      candidate: "AHMAD KHAN",
      party: "Independent",
      evm_votes: 1889,
      postal_votes: 12,
      total_votes: 1901,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 12,
      candidate: "ABDUL SHAKUR DESHPANDE",
      party: "Independent",
      evm_votes: 1593,
      postal_votes: 13,
      total_votes: 1606,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 13,
      candidate: "ADV. NAMDEO PANDURANG KOLI",
      party: "Independent",
      evm_votes: 1212,
      postal_votes: 12,
      total_votes: 1224,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 14,
      candidate: "PATIL SANDIP YUVRAJ",
      party: "Independent",
      evm_votes: 1098,
      postal_votes: 12,
      total_votes: 1110,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13878,
      postal_votes: 41,
      total_votes: 13919,
      percentage_of_votes: 1.19,
    },
  ],
  total: {
    evm_votes: 1165216,
    postal_votes: 4337,
    total_votes: 1169553,
  },
};
export const s134 = {
  cs_name: "Raver",
  cs_shortname: "raver",
  cs_code: "s134",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "KHADSE RAKSHA NIKHIL",
      party: "Bharatiya Janata Party",
      evm_votes: 629558,
      postal_votes: 1321,
      total_votes: 630879,
      percentage_of_votes: 53.84,
    },
    {
      serial_no: 2,
      candidate: "SHRIRAM DAYARAM PATIL",
      party: "Nationalist Congress Party – Sharadchandra Pawar",
      evm_votes: 358030,
      postal_votes: 666,
      total_votes: 358696,
      percentage_of_votes: 30.61,
    },
    {
      serial_no: 3,
      candidate: "ER. SANJAY PANDIT BRAMHANE",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 59001,
      postal_votes: 119,
      total_votes: 59120,
      percentage_of_votes: 5.05,
    },
    {
      serial_no: 4,
      candidate: "EKNATH NAGO SALUNKE",
      party: "Independent",
      evm_votes: 43957,
      postal_votes: 25,
      total_votes: 43982,
      percentage_of_votes: 3.75,
    },
    {
      serial_no: 5,
      candidate: "GULAB DAYARAM BHIL",
      party: "Bharat Adivasi Party",
      evm_votes: 9622,
      postal_votes: 22,
      total_votes: 9644,
      percentage_of_votes: 0.82,
    },
    {
      serial_no: 6,
      candidate: "MAMTA ALIAS MUMTAJ BHIKARI TADVI",
      party: "Independent",
      evm_votes: 7874,
      postal_votes: 10,
      total_votes: 7884,
      percentage_of_votes: 0.67,
    },
    {
      serial_no: 7,
      candidate: "VIJAY RAMKRUSHNA KALE",
      party: "Bahujan Samaj Party",
      evm_votes: 7551,
      postal_votes: 26,
      total_votes: 7577,
      percentage_of_votes: 0.65,
    },
    {
      serial_no: 8,
      candidate: "VASANT SHANKAR KOLTE",
      party: "Independent",
      evm_votes: 6713,
      postal_votes: 5,
      total_votes: 6718,
      percentage_of_votes: 0.57,
    },
    {
      serial_no: 9,
      candidate: "PRAVIN LAXMAN PATIL",
      party: "Independent",
      evm_votes: 6087,
      postal_votes: 5,
      total_votes: 6092,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 10,
      candidate: "YUVRAJ DEVSING BARELA",
      party: "Independent",
      evm_votes: 5131,
      postal_votes: 4,
      total_votes: 5135,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 11,
      candidate: "ASHOK BABURAO JADHAV",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 4686,
      postal_votes: 4,
      total_votes: 4690,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 12,
      candidate: "SHRIRAM SITARAM PATIL",
      party: "Independent",
      evm_votes: 4264,
      postal_votes: 5,
      total_votes: 4269,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 13,
      candidate: "JITENDRA PANDURANG PATIL",
      party: "Independent",
      evm_votes: 3582,
      postal_votes: 5,
      total_votes: 3587,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 14,
      candidate: "SANJAY PRALHAD KANDELKAR",
      party: "Independent",
      evm_votes: 2954,
      postal_votes: 7,
      total_votes: 2961,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 15,
      candidate: "SAGAR PRABHAKAR PATIL",
      party: "Independent",
      evm_votes: 2932,
      postal_votes: 3,
      total_votes: 2935,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 16,
      candidate: "SANJAYKUMAR LAXMAN WANKHEDE",
      party: "Republican Party of India (Social)",
      evm_votes: 2277,
      postal_votes: 5,
      total_votes: 2282,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 17,
      candidate: "ANIL PITAMBAR WAGH (SIR)",
      party: "Independent",
      evm_votes: 2265,
      postal_votes: 13,
      total_votes: 2278,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 18,
      candidate: "PROF. DR. ASHISH SUBHASH JADHAV",
      party: "Independent",
      evm_votes: 1919,
      postal_votes: 8,
      total_votes: 1927,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 19,
      candidate: "BHIVRAJ RAMDAS RAYSINGE",
      party: "Independent",
      evm_votes: 1807,
      postal_votes: 2,
      total_votes: 1809,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 20,
      candidate: "KOMALBAI BAPURAO PATIL",
      party: "Independent",
      evm_votes: 1424,
      postal_votes: 5,
      total_votes: 1429,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 21,
      candidate: "SHAIKH AABID SHAIKH BASHIR",
      party: "Independent",
      evm_votes: 1035,
      postal_votes: 5,
      total_votes: 1040,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 22,
      candidate: "AMIT HARIBHAU KOLTE",
      party: "Independent",
      evm_votes: 980,
      postal_votes: 1,
      total_votes: 981,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 23,
      candidate: "DR. YOGENDRA VITTHAL KOLTE",
      party: "Independent",
      evm_votes: 900,
      postal_votes: 2,
      total_votes: 902,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 24,
      candidate: "SHRIRAM OMKAR PATIL",
      party: "Independent",
      evm_votes: 743,
      postal_votes: 6,
      total_votes: 749,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 25,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4073,
      postal_votes: 27,
      total_votes: 4100,
      percentage_of_votes: 0.35,
    },
  ],
  total: {
    evm_votes: 1169365,
    postal_votes: 2301,
    total_votes: 1171666,
  },
};
export const s136 = {
  cs_name: "Akola",
  cs_shortname: "akola",
  cs_code: "s136",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ANUP SANJAY DHOTRE",
      party: "Bharatiya Janata Party",
      evm_votes: 454972,
      postal_votes: 2058,
      total_votes: 457030,
      percentage_of_votes: 38.96,
    },
    {
      serial_no: 2,
      candidate: "ABHAY KASHINATH PATIL",
      party: "Indian National Congress",
      evm_votes: 414557,
      postal_votes: 1847,
      total_votes: 416404,
      percentage_of_votes: 35.5,
    },
    {
      serial_no: 3,
      candidate: "AMBEDKAR PRAKASH YASHWANT",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 275490,
      postal_votes: 1257,
      total_votes: 276747,
      percentage_of_votes: 23.59,
    },
    {
      serial_no: 4,
      candidate: "ADV NAJIB SHAIKH",
      party: "Indian National League",
      evm_votes: 3288,
      postal_votes: 12,
      total_votes: 3300,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 5,
      candidate: "KASHINATH VISHWANATH DHAMODE",
      party: "Bahujan Samaj Party",
      evm_votes: 2737,
      postal_votes: 23,
      total_votes: 2760,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 6,
      candidate: "MURLIDHAR LALSING PAWAR",
      party: "Independent",
      evm_votes: 2058,
      postal_votes: 8,
      total_votes: 2066,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 7,
      candidate: "ADHAU RAVIKANT RAMKRUSHNA",
      party: "Jay Vidarbha Party",
      evm_votes: 1710,
      postal_votes: 24,
      total_votes: 1734,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 8,
      candidate: "AACHARYADIP SUBHASHCHANDRA GANOJE",
      party: "Independent",
      evm_votes: 1610,
      postal_votes: 8,
      total_votes: 1618,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 9,
      candidate: "UJWALA VINAYAK RAUT",
      party: "Independent",
      evm_votes: 1298,
      postal_votes: 8,
      total_votes: 1306,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "DHARMENDRA CHANDRAPRAKASH KOTHARI",
      party: "Independent",
      evm_votes: 1236,
      postal_votes: 4,
      total_votes: 1240,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 11,
      candidate: "DILIP SHATRUGHAN MHAISANE",
      party: "Independent",
      evm_votes: 860,
      postal_votes: 2,
      total_votes: 862,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "ASHOK KISANRAO THORAT",
      party: "Independent",
      evm_votes: 573,
      postal_votes: 8,
      total_votes: 581,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "MOHMMAD AJAZ MOHMMAD TAHER",
      party: "Azad Adhikar Sena",
      evm_votes: 564,
      postal_votes: 4,
      total_votes: 568,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "BABAN MAHADEV SAYAM",
      party: "Janseva Gondwana Party",
      evm_votes: 535,
      postal_votes: 2,
      total_votes: 537,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "PRITI PRAMOD SADANSHIV",
      party: "Republican Party of India (Social)",
      evm_votes: 528,
      postal_votes: 8,
      total_votes: 536,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5752,
      postal_votes: 31,
      total_votes: 5783,
      percentage_of_votes: 0.49,
    },
  ],
  total: {
    evm_votes: 1167768,
    postal_votes: 5304,
    total_votes: 1173072,
  },
};
export const s1310 = {
  cs_name: "Nagpur",
  cs_shortname: "nagpur",
  cs_code: "s1310",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "NITIN JAIRAM GADKARI",
      party: "Bharatiya Janata Party",
      evm_votes: 652809,
      postal_votes: 2218,
      total_votes: 655027,
      percentage_of_votes: 54.08,
    },
    {
      serial_no: 2,
      candidate: "VIKAS THAKRE",
      party: "Indian National Congress",
      evm_votes: 515941,
      postal_votes: 1483,
      total_votes: 517424,
      percentage_of_votes: 42.72,
    },
    {
      serial_no: 3,
      candidate: "YOGIRAJ ALIAS YOGESH PATIRAM LANJEWAR",
      party: "Bahujan Samaj Party",
      evm_votes: 19164,
      postal_votes: 78,
      total_votes: 19242,
      percentage_of_votes: 1.59,
    },
    {
      serial_no: 4,
      candidate: "KIVINSUKA VIRENDRA SURYAVANSHI",
      party: "Desh Janhit Party",
      evm_votes: 2055,
      postal_votes: 4,
      total_votes: 2059,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 5,
      candidate: "ADARSH RAVISHANKAR THAKUR",
      party: "Independent",
      evm_votes: 1749,
      postal_votes: 1,
      total_votes: 1750,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 6,
      candidate: "ADV ULHAS SHALIKRAM DUPARE",
      party: "Independent",
      evm_votes: 1346,
      postal_votes: 2,
      total_votes: 1348,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 7,
      candidate: "FAHIM SHAMIM KHAN",
      party: "Minorities Democratic Party",
      evm_votes: 1072,
      postal_votes: 1,
      total_votes: 1073,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 8,
      candidate: "SURAJ BALRAM MISHRA",
      party: "Kunbi Bahujan Sawrajya Party",
      evm_votes: 814,
      postal_votes: 3,
      total_votes: 817,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 9,
      candidate: "TEKRAJ BELKHODE",
      party: "Independent",
      evm_votes: 803,
      postal_votes: 5,
      total_votes: 808,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 10,
      candidate: "GARUDADRI ANAND KUMAR",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 719,
      postal_votes: 1,
      total_votes: 720,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 11,
      candidate: "DHANU WALTHARE",
      party: "Independent",
      evm_votes: 690,
      postal_votes: 2,
      total_votes: 692,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "VIJAY MANKAR",
      party: "Ambedkarite Party of India",
      evm_votes: 606,
      postal_votes: 4,
      total_votes: 610,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "ADV. SANTOSH RAMKRISHNA LANJEWAR",
      party: "All India Forward Bloc",
      evm_votes: 565,
      postal_votes: 2,
      total_votes: 567,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "VISHESH VASANTA FUTANE",
      party: "Bahujan Republican Socialist Party",
      evm_votes: 559,
      postal_votes: 3,
      total_votes: 562,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "GUNWANT HARICHANDRA SOMKUWAR",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 465,
      postal_votes: 6,
      total_votes: 471,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "SHRIDHAR NARAYAN SALVE",
      party: "Bhim Sena",
      evm_votes: 447,
      postal_votes: 4,
      total_votes: 451,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 17,
      candidate: "SUNIL WANKHEDE",
      party: "Rashtra Samarpan Party",
      evm_votes: 320,
      postal_votes: 2,
      total_votes: 322,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 18,
      candidate: "DIPAK LAXMANRAO MASKE",
      party: "Bahujan Maha Party",
      evm_votes: 267,
      postal_votes: 3,
      total_votes: 270,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 19,
      candidate: "NARAYAN TUKARAM CHOUDHARI",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 251,
      postal_votes: 0,
      total_votes: 251,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 20,
      candidate: "SANTOSH TULSHIRAM CHAVHAN",
      party: "Independent",
      evm_votes: 242,
      postal_votes: 0,
      total_votes: 242,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 21,
      candidate: "SUSHIL PATIL",
      party: "Independent",
      evm_votes: 236,
      postal_votes: 1,
      total_votes: 237,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 22,
      candidate: "SACHIN RAMKRUSHNARAO WAGHADE",
      party: "Independent",
      evm_votes: 202,
      postal_votes: 0,
      total_votes: 202,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 23,
      candidate: "PRAFUL MANIKCHAND BHANGE",
      party: "Independent",
      evm_votes: 201,
      postal_votes: 0,
      total_votes: 201,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 24,
      candidate: "VINAYAK BHAURAO AWCHAT",
      party: "Independent",
      evm_votes: 190,
      postal_votes: 0,
      total_votes: 190,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 25,
      candidate: "BABITA RAJU AWASTHI",
      party: "Independent",
      evm_votes: 179,
      postal_votes: 3,
      total_votes: 182,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 26,
      candidate: "SAHIL BALCHAND TURKAR",
      party: "Independent",
      evm_votes: 129,
      postal_votes: 0,
      total_votes: 129,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 27,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5434,
      postal_votes: 40,
      total_votes: 5474,
      percentage_of_votes: 0.45,
    },
  ],
  total: {
    evm_votes: 1207455,
    postal_votes: 3866,
    total_votes: 1211321,
  },
};
export const s1322 = {
  cs_name: "Palghar",
  cs_shortname: "palghar",
  cs_code: "s1322",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR. HEMANT VISHNU SAVARA",
      party: "Bharatiya Janata Party",
      evm_votes: 600208,
      postal_votes: 1036,
      total_votes: 601244,
      percentage_of_votes: 43.69,
    },
    {
      serial_no: 2,
      candidate: "BHARTI BHARAT KAMDI",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 416822,
      postal_votes: 1116,
      total_votes: 417938,
      percentage_of_votes: 30.37,
    },
    {
      serial_no: 3,
      candidate: "RAJESH RAGHUNATH PATIL",
      party: "Bahujan Vikas Aaghadi",
      evm_votes: 254011,
      postal_votes: 506,
      total_votes: 254517,
      percentage_of_votes: 18.5,
    },
    {
      serial_no: 4,
      candidate: "BHARAT SAMJI VANGA",
      party: "Bahujan Samaj Party",
      evm_votes: 15425,
      postal_votes: 40,
      total_votes: 15465,
      percentage_of_votes: 1.12,
    },
    {
      serial_no: 5,
      candidate: "MEENA KISHOR BHAD",
      party: "Independent",
      evm_votes: 14223,
      postal_votes: 12,
      total_votes: 14235,
      percentage_of_votes: 1.03,
    },
    {
      serial_no: 6,
      candidate: "VIJAYA RAJKUMAR MHATRE",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 10882,
      postal_votes: 54,
      total_votes: 10936,
      percentage_of_votes: 0.79,
    },
    {
      serial_no: 7,
      candidate: "MOHAN BARAKU GUHE",
      party: "Bharat Adivasi Party",
      evm_votes: 10737,
      postal_votes: 24,
      total_votes: 10761,
      percentage_of_votes: 0.78,
    },
    {
      serial_no: 8,
      candidate: "DINKAR DATTATRAYA WADHAN",
      party: "Independent",
      evm_votes: 10672,
      postal_votes: 15,
      total_votes: 10687,
      percentage_of_votes: 0.78,
    },
    {
      serial_no: 9,
      candidate: "AMAR KISAN KAVALE",
      party: "Independent",
      evm_votes: 9806,
      postal_votes: 15,
      total_votes: 9821,
      percentage_of_votes: 0.71,
    },
    {
      serial_no: 10,
      candidate: "COMRADE RAHUL MEDHA",
      party: "Marxist Leninist Party of India (Red Flag)",
      evm_votes: 7061,
      postal_votes: 24,
      total_votes: 7085,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 23312,
      postal_votes: 73,
      total_votes: 23385,
      percentage_of_votes: 1.7,
    },
  ],
  total: {
    evm_votes: 1373159,
    postal_votes: 2915,
    total_votes: 1376074,
  },
};
export const s1326 = {
  cs_name: "Mumbai_North",
  cs_shortname: "mumbainorth",
  cs_code: "s1326",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "PIYUSH GOYAL",
      party: "Bharatiya Janata Party",
      evm_votes: 678451,
      postal_votes: 1695,
      total_votes: 680146,
      percentage_of_votes: 65.68,
    },
    {
      serial_no: 2,
      candidate: "BHUSHAN PATIL",
      party: "Indian National Congress",
      evm_votes: 321455,
      postal_votes: 1083,
      total_votes: 322538,
      percentage_of_votes: 31.15,
    },
    {
      serial_no: 3,
      candidate: "ADV. SONAL DIWAKAR GONDANE",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 6007,
      postal_votes: 45,
      total_votes: 6052,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 4,
      candidate: "RAIS DOCTOR",
      party: "Bahujan Samaj Party",
      evm_votes: 2907,
      postal_votes: 16,
      total_votes: 2923,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 5,
      candidate: "PANDEY DHARMENDRA RAMMURAT",
      party: "Independent",
      evm_votes: 1481,
      postal_votes: 0,
      total_votes: 1481,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 6,
      candidate: "DIPTI ASHOK WALAWALKAR",
      party: "Independent",
      evm_votes: 1461,
      postal_votes: 11,
      total_votes: 1472,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 7,
      candidate: "COMRADE JAYRAM VISHWAKARMA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1194,
      postal_votes: 1,
      total_votes: 1195,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 8,
      candidate: "MUNNALAL GAJRAJ PRAJAPATI",
      party: "Independent",
      evm_votes: 968,
      postal_votes: 3,
      total_votes: 971,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 9,
      candidate: "LAXMAN YELLPPA KURADE",
      party: "Independent",
      evm_votes: 680,
      postal_votes: 6,
      total_votes: 686,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 10,
      candidate: "ADVOCATE KAPIL K. SONI",
      party: "Independent",
      evm_votes: 630,
      postal_votes: 3,
      total_votes: 633,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 11,
      candidate: "DEEPALI BHAWARSING SHEKHAWAT",
      party: "Maharashtra Vikas Aghadi",
      evm_votes: 621,
      postal_votes: 4,
      total_votes: 625,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "KAMLESH DAYABHAI VYAS",
      party: "Sardar Vallabhbhai Patel Party",
      evm_votes: 619,
      postal_votes: 3,
      total_votes: 622,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "BIPIN BACHUBHAI SHAH",
      party: "Hindu Samaj Party",
      evm_votes: 505,
      postal_votes: 3,
      total_votes: 508,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "GURUDAS RAMDAS KHAIRNAR",
      party: "Independent",
      evm_votes: 484,
      postal_votes: 2,
      total_votes: 486,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "RAVI BABU GAVLI",
      party: "Samata Party",
      evm_votes: 447,
      postal_votes: 3,
      total_votes: 450,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "JAEENDRA VASANT SURVE",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 398,
      postal_votes: 7,
      total_votes: 405,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 17,
      candidate: "ALIEK SUNDER MESHRAM",
      party: "Bharatiya Mulnivasi Aazad Party",
      evm_votes: 393,
      postal_votes: 3,
      total_votes: 396,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 18,
      candidate: "SAYED ZULFIQAR ALAM",
      party: "Bahujan Maha Party",
      evm_votes: 344,
      postal_votes: 4,
      total_votes: 348,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 19,
      candidate: "SANJAY MAFATLAL MORAKHIA",
      party: "Independent",
      evm_votes: 210,
      postal_votes: 0,
      total_votes: 210,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 20,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13248,
      postal_votes: 98,
      total_votes: 13346,
      percentage_of_votes: 1.29,
    },
  ],
  total: {
    evm_votes: 1032503,
    postal_votes: 2990,
    total_votes: 1035493,
  },
};
export const s1334 = {
  cs_name: "Pune",
  cs_shortname: "pune",
  cs_code: "s1334",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "MURLIDHAR MOHOL",
      party: "Bharatiya Janata Party",
      evm_votes: 584171,
      postal_votes: 557,
      total_votes: 584728,
      percentage_of_votes: 52.94,
    },
    {
      serial_no: 2,
      candidate: "DHANGEKAR RAVINDRA HEMRAJ",
      party: "Indian National Congress",
      evm_votes: 461409,
      postal_votes: 281,
      total_votes: 461690,
      percentage_of_votes: 41.8,
    },
    {
      serial_no: 3,
      candidate: "VASANT (TATYA) MORE",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 31991,
      postal_votes: 21,
      total_votes: 32012,
      percentage_of_votes: 2.9,
    },
    {
      serial_no: 4,
      candidate: "RANPISE PRASHANT DADARAM",
      party: "Bahujan Samaj Party",
      evm_votes: 3969,
      postal_votes: 5,
      total_votes: 3974,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 5,
      candidate: "CHORMALE SANDIP SOPAN",
      party: "Independent",
      evm_votes: 1644,
      postal_votes: 1,
      total_votes: 1645,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 6,
      candidate: "GORAKH NARAYAN GHODKE",
      party: "Independent",
      evm_votes: 1451,
      postal_votes: 0,
      total_votes: 1451,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 7,
      candidate: "KIRAN LAXMAN RAYKAR",
      party: "Independent",
      evm_votes: 1052,
      postal_votes: 0,
      total_votes: 1052,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 8,
      candidate: "ANIS ARASHID SUNDAKE",
      party: "All India Majlis-E-Ittehadul Muslimeen",
      evm_votes: 859,
      postal_votes: 1,
      total_votes: 860,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 9,
      candidate: "SACHIN DATTATRAY DHANKUDE",
      party: "Independent",
      evm_votes: 788,
      postal_votes: 1,
      total_votes: 789,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 10,
      candidate: "YUVRAJ CHANDRAKANT LIMBOLE",
      party: "Right to Recall Party",
      evm_votes: 698,
      postal_votes: 0,
      total_votes: 698,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 11,
      candidate: "DR. DEVYANI PANDIT",
      party: "Independent",
      evm_votes: 645,
      postal_votes: 5,
      total_votes: 650,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "NARENDRA DNYANESHWAR PAWTEKAR",
      party: "Independent",
      evm_votes: 533,
      postal_votes: 1,
      total_votes: 534,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "SAGAR SAMBHAJI PORE",
      party: "Independent",
      evm_votes: 515,
      postal_votes: 0,
      total_votes: 515,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "SURESH DNYANOBA PATIL",
      party: "Sainik Samaj Party",
      evm_votes: 437,
      postal_votes: 0,
      total_votes: 437,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 15,
      candidate: "AMBORE MANJUNATH MARUTI",
      party: "Independent",
      evm_votes: 433,
      postal_votes: 0,
      total_votes: 433,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "ASHWINI PRATIBHA KHAIRNAR",
      party: "Independent",
      evm_votes: 412,
      postal_votes: 3,
      total_votes: 415,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 17,
      candidate: "VIJAY VINAYAK JAGTAP",
      party: "Independent",
      evm_votes: 382,
      postal_votes: 0,
      total_votes: 382,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 18,
      candidate: "HEMANT BABURAV KOLEKAR ALIAS HEMANT PATIL",
      party: "Apni Prajahit Party",
      evm_votes: 374,
      postal_votes: 1,
      total_votes: 375,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 19,
      candidate: "NEERAJ SUNIL KAMTHAN",
      party: "Hindu Samaj Party",
      evm_votes: 351,
      postal_votes: 0,
      total_votes: 351,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 20,
      candidate: "MANOJ LAXMAN VETAL",
      party: "Bhim Sena",
      evm_votes: 351,
      postal_votes: 1,
      total_votes: 352,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 21,
      candidate: "KAMBLE MILIND",
      party: "Bahujan Samaj Party (Ambedkar)",
      evm_votes: 344,
      postal_votes: 3,
      total_votes: 347,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "CHANDRAKANT PARMESHWAR SAWANT",
      party: "Independent",
      evm_votes: 314,
      postal_votes: 0,
      total_votes: 314,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 23,
      candidate: "SHABBIR SAJJANBHAI TAMBOLI",
      party: "Independent",
      evm_votes: 313,
      postal_votes: 0,
      total_votes: 313,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 24,
      candidate: "SANJAY LAXMAN KENDALE",
      party: "Independent",
      evm_votes: 311,
      postal_votes: 0,
      total_votes: 311,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 25,
      candidate: "JOHNSON VASANT KOLHAPURE",
      party: "Independent",
      evm_votes: 304,
      postal_votes: 1,
      total_votes: 305,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 26,
      candidate: "ADV. YOGESH MAKANE",
      party: "Independent",
      evm_votes: 298,
      postal_votes: 1,
      total_votes: 299,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 27,
      candidate: "SUBROTO ROY",
      party: "Ekam Sanatan Bharat Dal",
      evm_votes: 293,
      postal_votes: 1,
      total_votes: 294,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 28,
      candidate: "DR. BALASAHEB ARJUN POL",
      party: "Independent",
      evm_votes: 259,
      postal_votes: 0,
      total_votes: 259,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 29,
      candidate: "VIJAYALAXMI SHRIKANT SINDAGI",
      party: "Rashtriya Maratha Party",
      evm_votes: 239,
      postal_votes: 1,
      total_votes: 240,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 30,
      candidate: "MAHESH DASHRATH MHASKE",
      party: "Independent",
      evm_votes: 229,
      postal_votes: 2,
      total_votes: 231,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 31,
      candidate: "YABES ALIAS AMOL SHAMUVEL TUJARE",
      party: "Independent",
      evm_votes: 223,
      postal_votes: 0,
      total_votes: 223,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 32,
      candidate: "VIJAY PANDURANGRAO DESHMUKH (DABADGAONKAR)",
      party: "Independent",
      evm_votes: 183,
      postal_votes: 2,
      total_votes: 185,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 33,
      candidate: "BABA SALIM SAYYED",
      party: "Independent",
      evm_votes: 163,
      postal_votes: 1,
      total_votes: 164,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 34,
      candidate: "BAGBAN SALEEM ABDUL RAZZAQUE",
      party: "Independent",
      evm_votes: 146,
      postal_votes: 0,
      total_votes: 146,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 35,
      candidate: "ZNYOSHO VIJAYPRAKASH",
      party: "Independent",
      evm_votes: 138,
      postal_votes: 0,
      total_votes: 138,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 36,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7449,
      postal_votes: 11,
      total_votes: 7460,
      percentage_of_votes: 0.68,
    },
  ],
  total: {
    evm_votes: 1103671,
    postal_votes: 901,
    total_votes: 1104572,
  },
};
export const s1345 = {
  cs_name: "Satara",
  cs_shortname: "satara",
  cs_code: "s1345",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SHRIMANT CHH UDAYANRAJE PRATAPSINHAMAHARAJ BHONSLE",
      party: "Bharatiya Janata Party",
      evm_votes: 568749,
      postal_votes: 2385,
      total_votes: 571134,
      percentage_of_votes: 47.67,
    },
    {
      serial_no: 2,
      candidate: "SHASHIKANT JAYVANTRAO SHINDE",
      party: "Nationalist Congress Party – Sharadchandra Pawar",
      evm_votes: 536475,
      postal_votes: 1888,
      total_votes: 538363,
      percentage_of_votes: 44.94,
    },
    {
      serial_no: 3,
      candidate: "GADE SANJAY KONDIBA",
      party: "Independent",
      evm_votes: 37035,
      postal_votes: 27,
      total_votes: 37062,
      percentage_of_votes: 3.09,
    },
    {
      serial_no: 4,
      candidate: "KADAM PRASHANT RAGHUNATH",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 11878,
      postal_votes: 34,
      total_votes: 11912,
      percentage_of_votes: 0.99,
    },
    {
      serial_no: 5,
      candidate: "ANAND RAMESH THORWADE",
      party: "Bahujan Samaj Party",
      evm_votes: 6432,
      postal_votes: 53,
      total_votes: 6485,
      percentage_of_votes: 0.54,
    },
    {
      serial_no: 6,
      candidate: "KORDE SURESHRAO DINKAR",
      party: "Independent",
      evm_votes: 4699,
      postal_votes: 13,
      total_votes: 4712,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 7,
      candidate: "MARUTI DHONDIRAM JANKAR",
      party: "Independent",
      evm_votes: 3944,
      postal_votes: 7,
      total_votes: 3951,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 8,
      candidate: "SEEMA SUNIL POTDAR",
      party: "Independent",
      evm_votes: 3458,
      postal_votes: 0,
      total_votes: 3458,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 9,
      candidate: "VISHWAJIT PATIL UNDALKAR",
      party: "Independent",
      evm_votes: 3432,
      postal_votes: 6,
      total_votes: 3438,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 10,
      candidate: "NIVRUTTI KERU SHINDE",
      party: "Independent",
      evm_votes: 2666,
      postal_votes: 8,
      total_votes: 2674,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 11,
      candidate: "SAYAJI GANPAT WAGHMARE",
      party: "Bahujan Republican Socialist Party",
      evm_votes: 2490,
      postal_votes: 11,
      total_votes: 2501,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 12,
      candidate: "SACHIN SUBHASH MAHAJAN",
      party: "Independent",
      evm_votes: 2010,
      postal_votes: 5,
      total_votes: 2015,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 13,
      candidate: "DR ABHIJEET WAMANRAO AWADE BICHUKALE",
      party: "Independent",
      evm_votes: 1388,
      postal_votes: 7,
      total_votes: 1395,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "TUSHAR VIJAY MOTLING",
      party: "Bahujan Mukti Party",
      evm_votes: 1296,
      postal_votes: 5,
      total_votes: 1301,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 15,
      candidate: "PRATIBHA SHELAR",
      party: "Independent",
      evm_votes: 1104,
      postal_votes: 19,
      total_votes: 1123,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 16,
      candidate: "BAGAL SADASHIV SAHEBRAO",
      party: "Independent",
      evm_votes: 955,
      postal_votes: 3,
      total_votes: 958,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 17,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5476,
      postal_votes: 46,
      total_votes: 5522,
      percentage_of_votes: 0.46,
    },
  ],
  total: {
    evm_votes: 1193487,
    postal_votes: 4517,
    total_votes: 1198004,
  },
};
export const s1346 = {
  cs_name: "Ratnagiri-_Sindhudurg",
  cs_shortname: "ratnagirisindhudurg",
  cs_code: "s1346",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "NARAYAN TATU RANE",
      party: "Bharatiya Janata Party",
      evm_votes: 445339,
      postal_votes: 3175,
      total_votes: 448514,
      percentage_of_votes: 49.07,
    },
    {
      serial_no: 2,
      candidate: "VINAYAK BHAURAO RAUT",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 396524,
      postal_votes: 4132,
      total_votes: 400656,
      percentage_of_votes: 43.83,
    },
    {
      serial_no: 3,
      candidate: "VINAYAK LAVU RAUT",
      party: "Independent",
      evm_votes: 15769,
      postal_votes: 57,
      total_votes: 15826,
      percentage_of_votes: 1.73,
    },
    {
      serial_no: 4,
      candidate: "MARUTI RAMACHANDRA JOSHI",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 9873,
      postal_votes: 166,
      total_votes: 10039,
      percentage_of_votes: 1.1,
    },
    {
      serial_no: 5,
      candidate: "AAYARE RAJENDRA LAHU",
      party: "Bahujan Samaj Party",
      evm_votes: 7796,
      postal_votes: 60,
      total_votes: 7856,
      percentage_of_votes: 0.86,
    },
    {
      serial_no: 6,
      candidate: "SHAKIL SAWANT",
      party: "Independent",
      evm_votes: 6373,
      postal_votes: 22,
      total_votes: 6395,
      percentage_of_votes: 0.7,
    },
    {
      serial_no: 7,
      candidate: "TAMBADE AMRUT ANANT (RAJAPURKAR)",
      party: "Independent",
      evm_votes: 5563,
      postal_votes: 19,
      total_votes: 5582,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 8,
      candidate: "ASHOK GANGARAM PAWAR",
      party: "Bahujan Mukti Party",
      evm_votes: 5244,
      postal_votes: 36,
      total_votes: 5280,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 9,
      candidate: "SURESH GOVINDRAO SHINDE",
      party: "Sainik Samaj Party",
      evm_votes: 2209,
      postal_votes: 38,
      total_votes: 2247,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11516,
      postal_votes: 127,
      total_votes: 11643,
      percentage_of_votes: 1.27,
    },
  ],
  total: {
    evm_votes: 906206,
    postal_votes: 7832,
    total_votes: 914038,
  },
};
export const s181 = {
  cs_name: "Bargarh",
  cs_shortname: "bargarh",
  cs_code: "s181",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "PRADEEP PUROHIT",
      party: "Bharatiya Janata Party",
      evm_votes: 709606,
      postal_votes: 6753,
      total_votes: 716359,
      percentage_of_votes: 54.69,
    },
    {
      serial_no: 2,
      candidate: "PARINITA MISHRA",
      party: "Biju Janata Dal",
      evm_votes: 463008,
      postal_votes: 1684,
      total_votes: 464692,
      percentage_of_votes: 35.48,
    },
    {
      serial_no: 3,
      candidate: "SANJAY BHOI",
      party: "Indian National Congress",
      evm_votes: 92666,
      postal_votes: 885,
      total_votes: 93551,
      percentage_of_votes: 7.14,
    },
    {
      serial_no: 4,
      candidate: "JAYARAM BAGH",
      party: "Bahujan Samaj Party",
      evm_votes: 9215,
      postal_votes: 107,
      total_votes: 9322,
      percentage_of_votes: 0.71,
    },
    {
      serial_no: 5,
      candidate: "GNYANENDRA BEHERA",
      party: "Independent",
      evm_votes: 4539,
      postal_votes: 24,
      total_votes: 4563,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 6,
      candidate: "DEVIKA SIKA",
      party: "National Apni Party",
      evm_votes: 4524,
      postal_votes: 14,
      total_votes: 4538,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 7,
      candidate: "PRABHAT KUMAR DHARUA",
      party: "Independent",
      evm_votes: 4294,
      postal_votes: 4,
      total_votes: 4298,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12414,
      postal_votes: 127,
      total_votes: 12541,
      percentage_of_votes: 0.96,
    },
  ],
  total: {
    evm_votes: 1300266,
    postal_votes: 9598,
    total_votes: 1309864,
  },
};
export const s182 = {
  cs_name: "Sundargarh",
  cs_shortname: "sundargarh",
  cs_code: "s182",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "JUAL ORAM",
      party: "Bharatiya Janata Party",
      evm_votes: 490655,
      postal_votes: 3627,
      total_votes: 494282,
      percentage_of_votes: 42.77,
    },
    {
      serial_no: 2,
      candidate: "DILIP KUMAR TIRKEY",
      party: "Biju Janata Dal",
      evm_votes: 353708,
      postal_votes: 1766,
      total_votes: 355474,
      percentage_of_votes: 30.76,
    },
    {
      serial_no: 3,
      candidate: "JANARDAN DEHURY",
      party: "Indian National Congress",
      evm_votes: 260681,
      postal_votes: 1305,
      total_votes: 261986,
      percentage_of_votes: 22.67,
    },
    {
      serial_no: 4,
      candidate: "JUSTIN LUGUN",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 9120,
      postal_votes: 21,
      total_votes: 9141,
      percentage_of_votes: 0.79,
    },
    {
      serial_no: 5,
      candidate: "JAGABANDHU ORAM",
      party: "Independent",
      evm_votes: 6023,
      postal_votes: 4,
      total_votes: 6027,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 6,
      candidate: "SUMANTA KUMAR SORENG",
      party: "National Apni Party",
      evm_votes: 4035,
      postal_votes: 17,
      total_votes: 4052,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 7,
      candidate: "SANTOSH JURA",
      party: "Naba Bharata Nirmana Seva Party",
      evm_votes: 3976,
      postal_votes: 5,
      total_votes: 3981,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 8,
      candidate: "PARUA EKKA",
      party: "Jharkhand Party",
      evm_votes: 3018,
      postal_votes: 15,
      total_votes: 3033,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 17752,
      postal_votes: 49,
      total_votes: 17801,
      percentage_of_votes: 1.54,
    },
  ],
  total: {
    evm_votes: 1148968,
    postal_votes: 6809,
    total_votes: 1155777,
  },
};
export const s183 = {
  cs_name: "Sambalpur",
  cs_shortname: "sambalpur",
  cs_code: "s183",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DHARMENDRA PRADHAN",
      party: "Bharatiya Janata Party",
      evm_votes: 587827,
      postal_votes: 4335,
      total_votes: 592162,
      percentage_of_votes: 49.48,
    },
    {
      serial_no: 2,
      candidate: "PRANAB PRAKASH DAS",
      party: "Biju Janata Dal",
      evm_votes: 471198,
      postal_votes: 1128,
      total_votes: 472326,
      percentage_of_votes: 39.47,
    },
    {
      serial_no: 3,
      candidate: "NAGENDRA KUMAR PRADHAN",
      party: "Indian National Congress",
      evm_votes: 88573,
      postal_votes: 540,
      total_votes: 89113,
      percentage_of_votes: 7.45,
    },
    {
      serial_no: 4,
      candidate: "SADANAND RANBIDA",
      party: "Bahujan Samaj Party",
      evm_votes: 4776,
      postal_votes: 19,
      total_votes: 4795,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 5,
      candidate: "SRIRAM PANDEY",
      party: "Independent",
      evm_votes: 4700,
      postal_votes: 2,
      total_votes: 4702,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 6,
      candidate: "SHUSHIL KUMAR BABU",
      party: "Independent",
      evm_votes: 4298,
      postal_votes: 5,
      total_votes: 4303,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 7,
      candidate: "GNYANENDRA BEHERA",
      party: "Independent",
      evm_votes: 3331,
      postal_votes: 3,
      total_votes: 3334,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 8,
      candidate: "NABAKISHORE PRADHAN",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 3151,
      postal_votes: 6,
      total_votes: 3157,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 9,
      candidate: "ATMA RAM SUPKAR",
      party: "Independent",
      evm_votes: 2053,
      postal_votes: 6,
      total_votes: 2059,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 10,
      candidate: "PURUSHOTTAM PATRA",
      party: "Kosal Janata Dal",
      evm_votes: 1731,
      postal_votes: 4,
      total_votes: 1735,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "RAKESH BAG",
      party: "Bharatiya Nyay-Adhikar Raksha Party",
      evm_votes: 1704,
      postal_votes: 4,
      total_votes: 1708,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 12,
      candidate: "SUBASH MOHAPATRA",
      party: "National Jan Dal",
      evm_votes: 1669,
      postal_votes: 24,
      total_votes: 1693,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 13,
      candidate: "LAXMAN KUMAR BHOI",
      party: "Paschimanchal Vikas Party",
      evm_votes: 1662,
      postal_votes: 20,
      total_votes: 1682,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 14,
      candidate: "KHAGESWAR BEHERA",
      party: "Rashtriya Manav Party",
      evm_votes: 1492,
      postal_votes: 3,
      total_votes: 1495,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12451,
      postal_votes: 32,
      total_votes: 12483,
      percentage_of_votes: 1.04,
    },
  ],
  total: {
    evm_votes: 1190616,
    postal_votes: 6131,
    total_votes: 1196747,
  },
};
export const s184 = {
  cs_name: "Keonjhar",
  cs_shortname: "keonjhar",
  cs_code: "s184",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ANANTA NAYAK",
      party: "Bharatiya Janata Party",
      evm_votes: 571133,
      postal_votes: 2790,
      total_votes: 573923,
      percentage_of_votes: 45.67,
    },
    {
      serial_no: 2,
      candidate: "DHANURJAYA SIDU",
      party: "Biju Janata Dal",
      evm_votes: 476206,
      postal_votes: 675,
      total_votes: 476881,
      percentage_of_votes: 37.95,
    },
    {
      serial_no: 3,
      candidate: "BINOD BIHARI NAIK",
      party: "Indian National Congress",
      evm_votes: 104944,
      postal_votes: 334,
      total_votes: 105278,
      percentage_of_votes: 8.38,
    },
    {
      serial_no: 4,
      candidate: "JOHN MIRAN MUNDA",
      party: "Independent",
      evm_votes: 31729,
      postal_votes: 7,
      total_votes: 31736,
      percentage_of_votes: 2.53,
    },
    {
      serial_no: 5,
      candidate: "SAHEBA JUANGA",
      party: "Manas Lokshakti Dal",
      evm_votes: 11040,
      postal_votes: 3,
      total_votes: 11043,
      percentage_of_votes: 0.88,
    },
    {
      serial_no: 6,
      candidate: "SUSHANT KUMAR NAIK",
      party: "Independent",
      evm_votes: 9041,
      postal_votes: 3,
      total_votes: 9044,
      percentage_of_votes: 0.72,
    },
    {
      serial_no: 7,
      candidate: "LAMBODAR NAIK",
      party: "Independent",
      evm_votes: 6778,
      postal_votes: 0,
      total_votes: 6778,
      percentage_of_votes: 0.54,
    },
    {
      serial_no: 8,
      candidate: "HARIHAR MUNDA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 6569,
      postal_votes: 5,
      total_votes: 6574,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 9,
      candidate: "RAGHUMANI NAIK",
      party: "Independent",
      evm_votes: 4550,
      postal_votes: 6,
      total_votes: 4556,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 10,
      candidate: "RAM PRASAD HEMBRAM",
      party: "Bahujan Samaj Party",
      evm_votes: 3884,
      postal_votes: 11,
      total_votes: 3895,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 11,
      candidate: "PITABASA NAIK",
      party: "AJSU Party",
      evm_votes: 2141,
      postal_votes: 3,
      total_votes: 2144,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 24746,
      postal_votes: 17,
      total_votes: 24763,
      percentage_of_votes: 1.97,
    },
  ],
  total: {
    evm_votes: 1252761,
    postal_votes: 3854,
    total_votes: 1256615,
  },
};
export const s185 = {
  cs_name: "Mayurbhanj",
  cs_shortname: "mayurbhanj",
  cs_code: "s185",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "NABA CHARAN MAJHI",
      party: "Bharatiya Janata Party",
      evm_votes: 580579,
      postal_votes: 5392,
      total_votes: 585971,
      percentage_of_votes: 49.91,
    },
    {
      serial_no: 2,
      candidate: "SUDAM MARNDI",
      party: "Biju Janata Dal",
      evm_votes: 364745,
      postal_votes: 1892,
      total_votes: 366637,
      percentage_of_votes: 31.23,
    },
    {
      serial_no: 3,
      candidate: "ANJANI SOREN",
      party: "Jharkhand Mukti Morcha",
      evm_votes: 134713,
      postal_votes: 686,
      total_votes: 135399,
      percentage_of_votes: 11.53,
    },
    {
      serial_no: 4,
      candidate: "RABINDRA NATH SINGH",
      party: "Independent",
      evm_votes: 9282,
      postal_votes: 42,
      total_votes: 9324,
      percentage_of_votes: 0.79,
    },
    {
      serial_no: 5,
      candidate: "ASHOK KUMAR SING",
      party: "Independent",
      evm_votes: 8918,
      postal_votes: 35,
      total_votes: 8953,
      percentage_of_votes: 0.76,
    },
    {
      serial_no: 6,
      candidate: "JATIA SAWAYAN",
      party: "Bahujan Samaj Party",
      evm_votes: 8457,
      postal_votes: 45,
      total_votes: 8502,
      percentage_of_votes: 0.72,
    },
    {
      serial_no: 7,
      candidate: "SANTILATA CHATTAR",
      party: "Independent",
      evm_votes: 8448,
      postal_votes: 29,
      total_votes: 8477,
      percentage_of_votes: 0.72,
    },
    {
      serial_no: 8,
      candidate: "PITAMBAR NAIK",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 8070,
      postal_votes: 44,
      total_votes: 8114,
      percentage_of_votes: 0.69,
    },
    {
      serial_no: 9,
      candidate: "ANTA ALOK BASKEY",
      party: "AJSU Party",
      evm_votes: 5386,
      postal_votes: 8,
      total_votes: 5394,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 10,
      candidate: "BAJUN HANSDA",
      party: "Independent",
      evm_votes: 5304,
      postal_votes: 18,
      total_votes: 5322,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 11,
      candidate: "KANHU CHARAN TUDU",
      party: "Independent",
      evm_votes: 5103,
      postal_votes: 35,
      total_votes: 5138,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 12,
      candidate: "SUKANTA HEMBRAM",
      party: "Bharatiya Nyay-Adhikar Raksha Party",
      evm_votes: 3636,
      postal_votes: 20,
      total_votes: 3656,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 13,
      candidate: "GURU CHARAN HEMBRAM",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 3175,
      postal_votes: 35,
      total_votes: 3210,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 19853,
      postal_votes: 100,
      total_votes: 19953,
      percentage_of_votes: 1.7,
    },
  ],
  total: {
    evm_votes: 1165669,
    postal_votes: 8381,
    total_votes: 1174050,
  },
};
export const s186 = {
  cs_name: "Balasore",
  cs_shortname: "balasore",
  cs_code: "s186",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "PRATAP CHANDRA SARANGI",
      party: "Bharatiya Janata Party",
      evm_votes: 561590,
      postal_votes: 2275,
      total_votes: 563865,
      percentage_of_votes: 45.49,
    },
    {
      serial_no: 2,
      candidate: "LEKHASRI SAMANTSINGHAR",
      party: "Biju Janata Dal",
      evm_votes: 415917,
      postal_votes: 792,
      total_votes: 416709,
      percentage_of_votes: 33.62,
    },
    {
      serial_no: 3,
      candidate: "SHRIKANTA JENA",
      party: "Indian National Congress",
      evm_votes: 236081,
      postal_votes: 926,
      total_votes: 237007,
      percentage_of_votes: 19.12,
    },
    {
      serial_no: 4,
      candidate: "RAJAT KUMAR BEHERA",
      party: "Bahujan Samaj Party",
      evm_votes: 2913,
      postal_votes: 7,
      total_votes: 2920,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 5,
      candidate: "GOBINDA CHANDRA BHOI",
      party: "National Apni Party",
      evm_votes: 2640,
      postal_votes: 0,
      total_votes: 2640,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 6,
      candidate: "SURYAKANTA DASMOHAPATRA",
      party: "Independent",
      evm_votes: 2156,
      postal_votes: 5,
      total_votes: 2161,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 7,
      candidate: "MAHAMEGHABAHAN AIRA KHARABELA SWAIN",
      party: "Independent",
      evm_votes: 1863,
      postal_votes: 5,
      total_votes: 1868,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 8,
      candidate: "PRAMOD KUMAR PATTAYAT",
      party: "Independent",
      evm_votes: 1637,
      postal_votes: 2,
      total_votes: 1639,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 9,
      candidate: "BIREN PUHAN",
      party: "Krupaa Party",
      evm_votes: 1450,
      postal_votes: 2,
      total_votes: 1452,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 10,
      candidate: "DEEPAK SANKAR THAKUR GIRI",
      party: "Independent",
      evm_votes: 1191,
      postal_votes: 3,
      total_votes: 1194,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "AKHIL KUMAR MOHANTA",
      party: "Independent",
      evm_votes: 829,
      postal_votes: 1,
      total_votes: 830,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7333,
      postal_votes: 17,
      total_votes: 7350,
      percentage_of_votes: 0.59,
    },
  ],
  total: {
    evm_votes: 1235600,
    postal_votes: 4035,
    total_votes: 1239635,
  },
};
export const s187 = {
  cs_name: "Bhadrak",
  cs_shortname: "bhadrak",
  cs_code: "s187",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "AVIMANYU SETHI",
      party: "Bharatiya Janata Party",
      evm_votes: 569705,
      postal_votes: 3614,
      total_votes: 573319,
      percentage_of_votes: 44.19,
    },
    {
      serial_no: 2,
      candidate: "MANJULATA MANDAL",
      party: "Biju Janata Dal",
      evm_votes: 480105,
      postal_votes: 1670,
      total_votes: 481775,
      percentage_of_votes: 37.13,
    },
    {
      serial_no: 3,
      candidate: "ANANTA PRASAD SETHI",
      party: "Indian National Congress",
      evm_votes: 221020,
      postal_votes: 854,
      total_votes: 221874,
      percentage_of_votes: 17.1,
    },
    {
      serial_no: 4,
      candidate: "KIRTAN BIHARI MALIK",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 4879,
      postal_votes: 1,
      total_votes: 4880,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 5,
      candidate: "ADV. ARJUN CHARAN MALLIK",
      party: "Bahujan Samaj Party",
      evm_votes: 3581,
      postal_votes: 23,
      total_votes: 3604,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 6,
      candidate: "ANJAN DAS",
      party: "Ambedkarite Party of India",
      evm_votes: 3512,
      postal_votes: 5,
      total_votes: 3517,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 7,
      candidate: "BASANTA KUMAR MAHALIK",
      party: "Independent",
      evm_votes: 1527,
      postal_votes: 3,
      total_votes: 1530,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 8,
      candidate: "SARBESWAR BEHERA",
      party: "Independent",
      evm_votes: 1397,
      postal_votes: 3,
      total_votes: 1400,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5457,
      postal_votes: 21,
      total_votes: 5478,
      percentage_of_votes: 0.42,
    },
  ],
  total: {
    evm_votes: 1291183,
    postal_votes: 6194,
    total_votes: 1297377,
  },
};
export const s188 = {
  cs_name: "Jajpur",
  cs_shortname: "jajpur",
  cs_code: "s188",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SARMISTHA SETHI",
      party: "Biju Janata Dal",
      evm_votes: 529428,
      postal_votes: 3224,
      total_votes: 532652,
      percentage_of_votes: 45.87,
    },
    {
      serial_no: 2,
      candidate: "RABINDRA NARAYAN BEHERA",
      party: "Bharatiya Janata Party",
      evm_votes: 528959,
      postal_votes: 5280,
      total_votes: 534239,
      percentage_of_votes: 46.01,
    },
    {
      serial_no: 3,
      candidate: "ANCHAL DAS",
      party: "Indian National Congress",
      evm_votes: 52455,
      postal_votes: 690,
      total_votes: 53145,
      percentage_of_votes: 4.58,
    },
    {
      serial_no: 4,
      candidate: "SUBASH CHANDRA MALLIK",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 17955,
      postal_votes: 52,
      total_votes: 18007,
      percentage_of_votes: 1.55,
    },
    {
      serial_no: 5,
      candidate: "ER. SANDHYA RANI MALLICK",
      party: "Bahujan Samaj Party",
      evm_votes: 3189,
      postal_votes: 29,
      total_votes: 3218,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 6,
      candidate: "BHAIRABA PRASAD BEHERA",
      party: "Independent",
      evm_votes: 2398,
      postal_votes: 5,
      total_votes: 2403,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 7,
      candidate: "SUMANTA MALIK",
      party: "Independent",
      evm_votes: 2295,
      postal_votes: 6,
      total_votes: 2301,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 8,
      candidate: "BIDYADHAR DALEI",
      party: "Ambedkarite Party of India",
      evm_votes: 2172,
      postal_votes: 0,
      total_votes: 2172,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 9,
      candidate: "JHUNA MALIK",
      party: "Manas Lokshakti Dal",
      evm_votes: 2075,
      postal_votes: 2,
      total_votes: 2077,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 10,
      candidate: "ARTTATRANA MALIK",
      party: "Independent",
      evm_votes: 1234,
      postal_votes: 18,
      total_votes: 1252,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 11,
      candidate: "NARENDRA MALIKA",
      party: "Independent",
      evm_votes: 1119,
      postal_votes: 0,
      total_votes: 1119,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 12,
      candidate: "MANTU SETHI",
      party: "Janta Darbar Party",
      evm_votes: 954,
      postal_votes: 2,
      total_votes: 956,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "BINOD KUMAR JENA",
      party: "Gana Suraksha Party",
      evm_votes: 876,
      postal_votes: 3,
      total_votes: 879,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6738,
      postal_votes: 50,
      total_votes: 6788,
      percentage_of_votes: 0.58,
    },
  ],
  total: {
    evm_votes: 1151847,
    postal_votes: 9361,
    total_votes: 1161208,
  },
};
export const s189 = {
  cs_name: "Dhenkanal",
  cs_shortname: "dhenkanal",
  cs_code: "s189",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "RUDRA NARAYAN PANY",
      party: "Bharatiya Janata Party",
      evm_votes: 593907,
      postal_votes: 4814,
      total_votes: 598721,
      percentage_of_votes: 50.24,
    },
    {
      serial_no: 2,
      candidate: "ABINASH SAMAL",
      party: "Biju Janata Dal",
      evm_votes: 520144,
      postal_votes: 2010,
      total_votes: 522154,
      percentage_of_votes: 43.82,
    },
    {
      serial_no: 3,
      candidate: "SASHMITA BEHERA",
      party: "Indian National Congress",
      evm_votes: 34052,
      postal_votes: 678,
      total_votes: 34730,
      percentage_of_votes: 2.91,
    },
    {
      serial_no: 4,
      candidate: "PRAFULLA KUMAR NAIK",
      party: "Bahujan Samaj Party",
      evm_votes: 4850,
      postal_votes: 45,
      total_votes: 4895,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 5,
      candidate: "MAHESWAR SAHOO",
      party: "Independent",
      evm_votes: 4614,
      postal_votes: 15,
      total_votes: 4629,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 6,
      candidate: "MANASI SWAIN",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 3376,
      postal_votes: 8,
      total_votes: 3384,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 7,
      candidate: "PRIYABRATA GARNAIK",
      party: "Independent",
      evm_votes: 2904,
      postal_votes: 5,
      total_votes: 2909,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 8,
      candidate: "JANARDAN NAIK",
      party: "Independent",
      evm_votes: 2647,
      postal_votes: 6,
      total_votes: 2653,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 9,
      candidate: "KAILAS CHANDRA PRADHAN",
      party: "Independent",
      evm_votes: 2205,
      postal_votes: 8,
      total_votes: 2213,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 10,
      candidate: "SWAPNA RANI JAMES",
      party: "Utkal Samaj",
      evm_votes: 1603,
      postal_votes: 5,
      total_votes: 1608,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13731,
      postal_votes: 46,
      total_votes: 13777,
      percentage_of_votes: 1.16,
    },
  ],
  total: {
    evm_votes: 1184033,
    postal_votes: 7640,
    total_votes: 1191673,
  },
};
export const s1810 = {
  cs_name: "Bolangir",
  cs_shortname: "bolangir",
  cs_code: "s1810",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SANGEETA KUMARI SINGH DEO",
      party: "Bharatiya Janata Party",
      evm_votes: 614196,
      postal_votes: 3548,
      total_votes: 617744,
      percentage_of_votes: 44.12,
    },
    {
      serial_no: 2,
      candidate: "SURENDRA SINGH BHOI",
      party: "Biju Janata Dal",
      evm_votes: 483964,
      postal_votes: 1116,
      total_votes: 485080,
      percentage_of_votes: 34.64,
    },
    {
      serial_no: 3,
      candidate: "MANOJ KUMAR MISHRA",
      party: "Indian National Congress",
      evm_votes: 229577,
      postal_votes: 1297,
      total_votes: 230874,
      percentage_of_votes: 16.49,
    },
    {
      serial_no: 4,
      candidate: "SUNIL KUMAR BHOI",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 10334,
      postal_votes: 21,
      total_votes: 10355,
      percentage_of_votes: 0.74,
    },
    {
      serial_no: 5,
      candidate: "JAGADISH CHANDRA BIBHAR",
      party: "Bahujan Samaj Party",
      evm_votes: 9404,
      postal_votes: 65,
      total_votes: 9469,
      percentage_of_votes: 0.68,
    },
    {
      serial_no: 6,
      candidate: "BALRAM SINGH YADAV",
      party: "Independent",
      evm_votes: 8637,
      postal_votes: 37,
      total_votes: 8674,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 7,
      candidate: "MADHUSUDAN BARIK",
      party: "Ambedkarite Party of India",
      evm_votes: 7239,
      postal_votes: 13,
      total_votes: 7252,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 8,
      candidate: "BALARAM SA",
      party: "Independent",
      evm_votes: 6092,
      postal_votes: 5,
      total_votes: 6097,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 9,
      candidate: "AKSHAYA DISHRI",
      party: "National Apni Party",
      evm_votes: 5465,
      postal_votes: 4,
      total_votes: 5469,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 10,
      candidate: "RAJESH KUMAR PANDA",
      party: "Ekam Sanatan Bharat Dal",
      evm_votes: 3163,
      postal_votes: 7,
      total_votes: 3170,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 16010,
      postal_votes: 54,
      total_votes: 16064,
      percentage_of_votes: 1.15,
    },
  ],
  total: {
    evm_votes: 1394081,
    postal_votes: 6167,
    total_votes: 1400248,
  },
};
export const s1811 = {
  cs_name: "Kalahandi",
  cs_shortname: "kalahandi",
  cs_code: "s1811",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "MALVIKA DEVI",
      party: "Bharatiya Janata Party",
      evm_votes: 538535,
      postal_votes: 5768,
      total_votes: 544303,
      percentage_of_votes: 40.79,
    },
    {
      serial_no: 2,
      candidate: "LAMBODAR NIAL",
      party: "Biju Janata Dal",
      evm_votes: 408267,
      postal_votes: 2223,
      total_votes: 410490,
      percentage_of_votes: 30.77,
    },
    {
      serial_no: 3,
      candidate: "DROUPADI MAJHI",
      party: "Indian National Congress",
      evm_votes: 300060,
      postal_votes: 3139,
      total_votes: 303199,
      percentage_of_votes: 22.72,
    },
    {
      serial_no: 4,
      candidate: "UMAKANTA TANDI",
      party: "Independent",
      evm_votes: 9180,
      postal_votes: 14,
      total_votes: 9194,
      percentage_of_votes: 0.69,
    },
    {
      serial_no: 5,
      candidate: "BIRANCHI NARAYAN DURGA",
      party: "Bahujan Samaj Party",
      evm_votes: 8440,
      postal_votes: 57,
      total_votes: 8497,
      percentage_of_votes: 0.64,
    },
    {
      serial_no: 6,
      candidate: "DR. DHARMANANDA GAHIR",
      party: "Independent",
      evm_votes: 7775,
      postal_votes: 36,
      total_votes: 7811,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 7,
      candidate: "SIBA HATI",
      party: "Samajwadi Party",
      evm_votes: 7138,
      postal_votes: 30,
      total_votes: 7168,
      percentage_of_votes: 0.54,
    },
    {
      serial_no: 8,
      candidate: "RAJARAM SAHU",
      party: "Independent",
      evm_votes: 6712,
      postal_votes: 18,
      total_votes: 6730,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 9,
      candidate: "NARESH HIANLA",
      party: "Independent",
      evm_votes: 6627,
      postal_votes: 14,
      total_votes: 6641,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 10,
      candidate: "GANGADHAR JAL",
      party: "Independent",
      evm_votes: 5547,
      postal_votes: 43,
      total_votes: 5590,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 11,
      candidate: "HIMANSHU TRIPATHY",
      party: "Samruddha Odisha",
      evm_votes: 4898,
      postal_votes: 8,
      total_votes: 4906,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 19620,
      postal_votes: 95,
      total_votes: 19715,
      percentage_of_votes: 1.48,
    },
  ],
  total: {
    evm_votes: 1322799,
    postal_votes: 11445,
    total_votes: 1334244,
  },
};
export const s1812 = {
  cs_name: "Nabarangpur",
  cs_shortname: "nabarangpur",
  cs_code: "s1812",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BALABHADRA MAJHI",
      party: "Bharatiya Janata Party",
      evm_votes: 478330,
      postal_votes: 3066,
      total_votes: 481396,
      percentage_of_votes: 38.74,
    },
    {
      serial_no: 2,
      candidate: "PRADEEP KUMAR MAJHI",
      party: "Biju Janata Dal",
      evm_votes: 392448,
      postal_votes: 1412,
      total_votes: 393860,
      percentage_of_votes: 31.7,
    },
    {
      serial_no: 3,
      candidate: "BHUJABAL MAJHI",
      party: "Indian National Congress",
      evm_votes: 302095,
      postal_votes: 1297,
      total_votes: 303392,
      percentage_of_votes: 24.42,
    },
    {
      serial_no: 4,
      candidate: "TRINATH MUNDAGUDIA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 20548,
      postal_votes: 11,
      total_votes: 20559,
      percentage_of_votes: 1.65,
    },
    {
      serial_no: 5,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 43240,
      postal_votes: 28,
      total_votes: 43268,
      percentage_of_votes: 3.48,
    },
  ],
  total: {
    evm_votes: 1236661,
    postal_votes: 5814,
    total_votes: 1242475,
  },
};
export const s1813 = {
  cs_name: "Kandhamal",
  cs_shortname: "kandhamal",
  cs_code: "s1813",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SUKANTA KUMAR PANIGRAHI",
      party: "Bharatiya Janata Party",
      evm_votes: 413320,
      postal_votes: 3095,
      total_votes: 416415,
      percentage_of_votes: 41.8,
    },
    {
      serial_no: 2,
      candidate: "ACHYUTANANDA SAMANTA",
      party: "Biju Janata Dal",
      evm_votes: 393644,
      postal_votes: 1400,
      total_votes: 395044,
      percentage_of_votes: 39.66,
    },
    {
      serial_no: 3,
      candidate: "AMIR CHAND NAYAK",
      party: "Indian National Congress",
      evm_votes: 143311,
      postal_votes: 1011,
      total_votes: 144322,
      percentage_of_votes: 14.49,
    },
    {
      serial_no: 4,
      candidate: "BHAGIRATHI BALIARSINGH",
      party: "Manas Lokshakti Dal",
      evm_votes: 7329,
      postal_votes: 1,
      total_votes: 7330,
      percentage_of_votes: 0.74,
    },
    {
      serial_no: 5,
      candidate: "KANAK MANJARI RATH",
      party: "Independent",
      evm_votes: 5201,
      postal_votes: 4,
      total_votes: 5205,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 6,
      candidate: "HARIBANDHU BEHERA",
      party: "Bahujan Samaj Party",
      evm_votes: 5121,
      postal_votes: 16,
      total_votes: 5137,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 7,
      candidate: "SUBASH MOHAPATRA",
      party: "National Jan Dal",
      evm_votes: 4133,
      postal_votes: 4,
      total_votes: 4137,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 8,
      candidate: "PARSURAM PRADHAN",
      party: "Kalinga Sena",
      evm_votes: 2770,
      postal_votes: 11,
      total_votes: 2781,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 15691,
      postal_votes: 29,
      total_votes: 15720,
      percentage_of_votes: 1.58,
    },
  ],
  total: {
    evm_votes: 990520,
    postal_votes: 5571,
    total_votes: 996091,
  },
};
export const s1814 = {
  cs_name: "Cuttack",
  cs_shortname: "cuttack",
  cs_code: "s1814",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BHARTRUHARI MAHTAB",
      party: "Bharatiya Janata Party",
      evm_votes: 529271,
      postal_votes: 2330,
      total_votes: 531601,
      percentage_of_votes: 47.43,
    },
    {
      serial_no: 2,
      candidate: "SANTRUPT MISRA",
      party: "Biju Janata Dal",
      evm_votes: 473443,
      postal_votes: 1081,
      total_votes: 474524,
      percentage_of_votes: 42.34,
    },
    {
      serial_no: 3,
      candidate: "SURESH MOHAPATRA",
      party: "Indian National Congress",
      evm_votes: 83461,
      postal_votes: 354,
      total_votes: 83815,
      percentage_of_votes: 7.48,
    },
    {
      serial_no: 4,
      candidate: "PRAMOD KUMAR MALLICK",
      party: "Bahujan Samaj Party",
      evm_votes: 4461,
      postal_votes: 23,
      total_votes: 4484,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 5,
      candidate: "NIRANJAN PATI",
      party: "Independent",
      evm_votes: 4390,
      postal_votes: 6,
      total_votes: 4396,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 6,
      candidate: "SOBHA PANDEY",
      party: "Independent",
      evm_votes: 3878,
      postal_votes: 10,
      total_votes: 3888,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 7,
      candidate: "PRAKASH CHANDRA SAHOO",
      party: "Krupaa Party",
      evm_votes: 3790,
      postal_votes: 4,
      total_votes: 3794,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 8,
      candidate: "RAJAKISHORE MALLIK",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 3072,
      postal_votes: 3,
      total_votes: 3075,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 9,
      candidate: "GOKULANANDA MISHRA",
      party: "Independent",
      evm_votes: 1804,
      postal_votes: 4,
      total_votes: 1808,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 10,
      candidate: "MINATI PATNAIK",
      party: "Samruddha Odisha",
      evm_votes: 1241,
      postal_votes: 10,
      total_votes: 1251,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8130,
      postal_votes: 29,
      total_votes: 8159,
      percentage_of_votes: 0.73,
    },
  ],
  total: {
    evm_votes: 1116941,
    postal_votes: 3854,
    total_votes: 1120795,
  },
};
export const s1815 = {
  cs_name: "Kendrapara",
  cs_shortname: "kendrapara",
  cs_code: "s1815",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BAIJAYANT PANDA",
      party: "Bharatiya Janata Party",
      evm_votes: 612916,
      postal_votes: 2789,
      total_votes: 615705,
      percentage_of_votes: 48.21,
    },
    {
      serial_no: 2,
      candidate: "ANSHUMAN MOHANTY",
      party: "Biju Janata Dal",
      evm_votes: 547681,
      postal_votes: 1488,
      total_votes: 549169,
      percentage_of_votes: 43,
    },
    {
      serial_no: 3,
      candidate: "SIDHARTH SWARUP DASH",
      party: "Indian National Congress",
      evm_votes: 94595,
      postal_votes: 237,
      total_votes: 94832,
      percentage_of_votes: 7.43,
    },
    {
      serial_no: 4,
      candidate: "SOUBHAGYA KETAN SAMAL",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 3393,
      postal_votes: 2,
      total_votes: 3395,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 5,
      candidate: "SEKHA SADIKA HOSEN",
      party: "Bahujan Samaj Party",
      evm_votes: 1845,
      postal_votes: 7,
      total_votes: 1852,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 6,
      candidate: "SUPRAVA DAS",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 1559,
      postal_votes: 2,
      total_votes: 1561,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 7,
      candidate: "SRIRAM PANDEY",
      party: "Independent",
      evm_votes: 1397,
      postal_votes: 2,
      total_votes: 1399,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 8,
      candidate: "SANTOSH KUMAR PATRA",
      party: "Independent",
      evm_votes: 1370,
      postal_votes: 3,
      total_votes: 1373,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 9,
      candidate: "PRATAP CHANDRA MOHANTY",
      party: "Independent",
      evm_votes: 1088,
      postal_votes: 7,
      total_votes: 1095,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 10,
      candidate: "PRANGYAN PRAKASH MOHAPATRA",
      party: "Odisha Janata Party",
      evm_votes: 910,
      postal_votes: 2,
      total_votes: 912,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5807,
      postal_votes: 8,
      total_votes: 5815,
      percentage_of_votes: 0.46,
    },
  ],
  total: {
    evm_votes: 1272561,
    postal_votes: 4547,
    total_votes: 1277108,
  },
};
export const s1816 = {
  cs_name: "Jagatsinghpur",
  cs_shortname: "jagatsinghpur",
  cs_code: "s1816",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BIBHU PRASAD TARAI",
      party: "Bharatiya Janata Party",
      evm_votes: 585672,
      postal_votes: 3421,
      total_votes: 589093,
      percentage_of_votes: 45.8,
    },
    {
      serial_no: 2,
      candidate: "DR. RAJASHREE MALLICK",
      party: "Biju Janata Dal",
      evm_votes: 546443,
      postal_votes: 1954,
      total_votes: 548397,
      percentage_of_votes: 42.63,
    },
    {
      serial_no: 3,
      candidate: "RABINDRA KUMAR SETHY",
      party: "Indian National Congress",
      evm_votes: 122875,
      postal_votes: 695,
      total_votes: 123570,
      percentage_of_votes: 9.61,
    },
    {
      serial_no: 4,
      candidate: "RAMESH CHANDRA SETHY",
      party: "Communist Party of India",
      evm_votes: 8112,
      postal_votes: 18,
      total_votes: 8130,
      percentage_of_votes: 0.63,
    },
    {
      serial_no: 5,
      candidate: "PEEYUUSH DAS",
      party: "Ambedkarite Party of India",
      evm_votes: 3225,
      postal_votes: 5,
      total_votes: 3230,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 6,
      candidate: "BIBHUTI BHUSAN MAJHI",
      party: "Bahujan Samaj Party",
      evm_votes: 2718,
      postal_votes: 22,
      total_votes: 2740,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 7,
      candidate: "MAHESWAR DAS",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2374,
      postal_votes: 10,
      total_votes: 2384,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 8,
      candidate: "SANKAR DAS",
      party: "Independent",
      evm_votes: 1476,
      postal_votes: 12,
      total_votes: 1488,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 9,
      candidate: "SILI MALLICK",
      party: "Samruddha Odisha",
      evm_votes: 924,
      postal_votes: 6,
      total_votes: 930,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 10,
      candidate: "ARJUNA CHARAN BEHERA",
      party: "Independent",
      evm_votes: 758,
      postal_votes: 8,
      total_votes: 766,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 11,
      candidate: "BHANUMATI DAS",
      party: "Utkal Samaj",
      evm_votes: 674,
      postal_votes: 3,
      total_votes: 677,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4852,
      postal_votes: 30,
      total_votes: 4882,
      percentage_of_votes: 0.38,
    },
  ],
  total: {
    evm_votes: 1280103,
    postal_votes: 6184,
    total_votes: 1286287,
  },
};
export const s1817 = {
  cs_name: "Puri",
  cs_shortname: "puri",
  cs_code: "s1817",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SAMBIT PATRA",
      party: "Bharatiya Janata Party",
      evm_votes: 625372,
      postal_votes: 3958,
      total_votes: 629330,
      percentage_of_votes: 52.58,
    },
    {
      serial_no: 2,
      candidate: "ARUP MOHAN PATNAIK",
      party: "Biju Janata Dal",
      evm_votes: 523036,
      postal_votes: 1585,
      total_votes: 524621,
      percentage_of_votes: 43.83,
    },
    {
      serial_no: 3,
      candidate: "JAYANARAYAN PATTANAYAK",
      party: "Indian National Congress",
      evm_votes: 24066,
      postal_votes: 276,
      total_votes: 24342,
      percentage_of_votes: 2.03,
    },
    {
      serial_no: 4,
      candidate: "SANTANU KUMAR DAS",
      party: "Bahujan Samaj Party",
      evm_votes: 3918,
      postal_votes: 11,
      total_votes: 3929,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 5,
      candidate: "SUBASH CHANDRA BHOI",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 3257,
      postal_votes: 9,
      total_votes: 3266,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 6,
      candidate: "SOBHA PANDEY",
      party: "Independent",
      evm_votes: 2212,
      postal_votes: 17,
      total_votes: 2229,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 7,
      candidate: "DILLIP KUMAR BARAL",
      party: "Independent",
      evm_votes: 1605,
      postal_votes: 6,
      total_votes: 1611,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7568,
      postal_votes: 42,
      total_votes: 7610,
      percentage_of_votes: 0.64,
    },
  ],
  total: {
    evm_votes: 1191034,
    postal_votes: 5904,
    total_votes: 1196938,
  },
};
export const s1818 = {
  cs_name: "Bhubaneswar",
  cs_shortname: "bhubaneswar",
  cs_code: "s1818",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "APARAJITA SARANGI",
      party: "Bharatiya Janata Party",
      evm_votes: 509567,
      postal_votes: 2952,
      total_votes: 512519,
      percentage_of_votes: 47.36,
    },
    {
      serial_no: 2,
      candidate: "MANMATH KUMAR ROUTRAY",
      party: "Biju Janata Dal",
      evm_votes: 475990,
      postal_votes: 1377,
      total_votes: 477367,
      percentage_of_votes: 44.11,
    },
    {
      serial_no: 3,
      candidate: "SYED YASHIR NAWAZ",
      party: "Indian National Congress",
      evm_votes: 66045,
      postal_votes: 317,
      total_votes: 66362,
      percentage_of_votes: 6.13,
    },
    {
      serial_no: 4,
      candidate: "SURESH CHANDRA PANIGRAHY",
      party: "Communist Party of India (Marxist)",
      evm_votes: 4134,
      postal_votes: 14,
      total_votes: 4148,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 5,
      candidate: "HULAS SENAPATI",
      party: "Independent",
      evm_votes: 2248,
      postal_votes: 3,
      total_votes: 2251,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 6,
      candidate: "KISHOR KUMAR SUTAR",
      party: "Independent",
      evm_votes: 2202,
      postal_votes: 1,
      total_votes: 2203,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 7,
      candidate: "SUDHANSU SEKHAR DAS",
      party: "Independent",
      evm_votes: 1751,
      postal_votes: 7,
      total_votes: 1758,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 8,
      candidate: "SANTOSH KUMAR MISHRA",
      party: "Independent",
      evm_votes: 1738,
      postal_votes: 6,
      total_votes: 1744,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "SANGEETA PATTANAIK",
      party: "Odisha Janata Party",
      evm_votes: 1528,
      postal_votes: 2,
      total_votes: 1530,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "PRADEEP KUMAR SAHOO",
      party: "Kalinga Sena",
      evm_votes: 1495,
      postal_votes: 8,
      total_votes: 1503,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "SANTILATA ROUT",
      party: "Supreme Zero Party of Bharat",
      evm_votes: 1437,
      postal_votes: 4,
      total_votes: 1441,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 12,
      candidate: "NATABAR MAHARANA",
      party: "Bharatiya Bikash Parishad",
      evm_votes: 1080,
      postal_votes: 7,
      total_votes: 1087,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8261,
      postal_votes: 41,
      total_votes: 8302,
      percentage_of_votes: 0.77,
    },
  ],
  total: {
    evm_votes: 1077476,
    postal_votes: 4739,
    total_votes: 1082215,
  },
};
export const s1819 = {
  cs_name: "Aska",
  cs_shortname: "aska",
  cs_code: "s1819",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ANITA SUBHADARSHINI",
      party: "Bharatiya Janata Party",
      evm_votes: 493032,
      postal_votes: 1194,
      total_votes: 494226,
      percentage_of_votes: 48.55,
    },
    {
      serial_no: 2,
      candidate: "RANJITA SAHU",
      party: "Biju Janata Dal",
      evm_votes: 393759,
      postal_votes: 493,
      total_votes: 394252,
      percentage_of_votes: 38.73,
    },
    {
      serial_no: 3,
      candidate: "DEBAKANTA SARMA",
      party: "Indian National Congress",
      evm_votes: 75108,
      postal_votes: 147,
      total_votes: 75255,
      percentage_of_votes: 7.39,
    },
    {
      serial_no: 4,
      candidate: "SANJAYA KUMAR BISHOYI",
      party: "Independent",
      evm_votes: 11234,
      postal_votes: 6,
      total_votes: 11240,
      percentage_of_votes: 1.1,
    },
    {
      serial_no: 5,
      candidate: "PAPUNU SAHU",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 8671,
      postal_votes: 2,
      total_votes: 8673,
      percentage_of_votes: 0.85,
    },
    {
      serial_no: 6,
      candidate: "PITABAS SAHU",
      party: "Sanatan Sanskriti Raksha Dal",
      evm_votes: 6888,
      postal_votes: 0,
      total_votes: 6888,
      percentage_of_votes: 0.68,
    },
    {
      serial_no: 7,
      candidate: "BRUNDABANA NAHAK",
      party: "Bahujan Samaj Party",
      evm_votes: 5430,
      postal_votes: 9,
      total_votes: 5439,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 21925,
      postal_votes: 16,
      total_votes: 21941,
      percentage_of_votes: 2.16,
    },
  ],
  total: {
    evm_votes: 1016047,
    postal_votes: 1867,
    total_votes: 1017914,
  },
};
export const s1820 = {
  cs_name: "Berhampur",
  cs_shortname: "berhampur",
  cs_code: "s1820",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR. PRADEEP KUMAR PANIGRAHY",
      party: "Bharatiya Janata Party",
      evm_votes: 510585,
      postal_votes: 2517,
      total_votes: 513102,
      percentage_of_votes: 49.2,
    },
    {
      serial_no: 2,
      candidate: "BHRUGU BAXIPATRA",
      party: "Biju Janata Dal",
      evm_votes: 346780,
      postal_votes: 846,
      total_votes: 347626,
      percentage_of_votes: 33.33,
    },
    {
      serial_no: 3,
      candidate: "RASMI RANJAN PATNAIK",
      party: "Indian National Congress",
      evm_votes: 129096,
      postal_votes: 648,
      total_votes: 129744,
      percentage_of_votes: 12.44,
    },
    {
      serial_no: 4,
      candidate: "V CHANDRA SHEKHAR",
      party: "Independent",
      evm_votes: 8376,
      postal_votes: 3,
      total_votes: 8379,
      percentage_of_votes: 0.8,
    },
    {
      serial_no: 5,
      candidate: "BHASKAR CHAUDHORY",
      party: "Bahujan Samaj Party",
      evm_votes: 5198,
      postal_votes: 13,
      total_votes: 5211,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 6,
      candidate: "SOMANATH BEHERA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 5121,
      postal_votes: 14,
      total_votes: 5135,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 7,
      candidate: "SIRIN TAMMANA",
      party: "Independent",
      evm_votes: 5069,
      postal_votes: 9,
      total_votes: 5078,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 8,
      candidate: "BOMMALI KANTA RAO",
      party: "Naba Bharata Nirmana Seva Party",
      evm_votes: 4190,
      postal_votes: 11,
      total_votes: 4201,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 9,
      candidate: "RAM SANKAR SATAPATHY",
      party: "Independent",
      evm_votes: 3869,
      postal_votes: 2,
      total_votes: 3871,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 10,
      candidate: "RAJENDRA DALABEHERA",
      party: "Bharatiya Bikash Parishad",
      evm_votes: 2335,
      postal_votes: 8,
      total_votes: 2343,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 11,
      candidate: "SANTOSH KUMAR SAHU",
      party: "All India Forward Bloc",
      evm_votes: 2284,
      postal_votes: 7,
      total_votes: 2291,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 15903,
      postal_votes: 39,
      total_votes: 15942,
      percentage_of_votes: 1.53,
    },
  ],
  total: {
    evm_votes: 1038806,
    postal_votes: 4117,
    total_votes: 1042923,
  },
};
export const s202 = {
  cs_name: "BIKANER",
  cs_shortname: "bikaner",
  cs_code: "s202",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ARJUN RAM MEGHWAL",
      party: "Bharatiya Janata Party",
      evm_votes: 561240,
      postal_votes: 5497,
      total_votes: 566737,
      percentage_of_votes: 50.68,
    },
    {
      serial_no: 2,
      candidate: "GOVINDRAM MEGHWAL",
      party: "Indian National Congress",
      evm_votes: 506966,
      postal_votes: 4060,
      total_votes: 511026,
      percentage_of_votes: 45.7,
    },
    {
      serial_no: 3,
      candidate: "KHETA RAM",
      party: "Bahujan Samaj Party",
      evm_votes: 8249,
      postal_votes: 50,
      total_votes: 8299,
      percentage_of_votes: 0.74,
    },
    {
      serial_no: 4,
      candidate: "ATMA RAM GUJARATI",
      party: "Independent",
      evm_votes: 6267,
      postal_votes: 14,
      total_votes: 6281,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 5,
      candidate: "RATANI DEVI",
      party: "Independent",
      evm_votes: 3894,
      postal_votes: 32,
      total_votes: 3926,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 6,
      candidate: "SATYANARAYAN DEVRA",
      party: "Independent",
      evm_votes: 2958,
      postal_votes: 8,
      total_votes: 2966,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 7,
      candidate: "PUKHRAJ NAYAK",
      party: "Independent",
      evm_votes: 2621,
      postal_votes: 54,
      total_votes: 2675,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 8,
      candidate: "BABU LAL",
      party: "Independent",
      evm_votes: 2010,
      postal_votes: 9,
      total_votes: 2019,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 9,
      candidate: "GOPI CHAND MEGHWAL",
      party: "Independent",
      evm_votes: 1787,
      postal_votes: 8,
      total_votes: 1795,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12431,
      postal_votes: 127,
      total_votes: 12558,
      percentage_of_votes: 1.12,
    },
  ],
  total: {
    evm_votes: 1108423,
    postal_votes: 9859,
    total_votes: 1118282,
  },
};
export const s206 = {
  cs_name: "JAIPUR_RURAL",
  cs_shortname: "jaipurrural",
  cs_code: "s206",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAO RAJENDRA SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 608189,
      postal_votes: 9688,
      total_votes: 617877,
      percentage_of_votes: 48.96,
    },
    {
      serial_no: 2,
      candidate: "ANIL CHOPRA",
      party: "Indian National Congress",
      evm_votes: 602293,
      postal_votes: 13969,
      total_votes: 616262,
      percentage_of_votes: 48.83,
    },
    {
      serial_no: 3,
      candidate: "HANUMAN SAHAY",
      party: "Bahujan Samaj Party",
      evm_votes: 3773,
      postal_votes: 77,
      total_votes: 3850,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 4,
      candidate: "RAMSINGH KASANA",
      party: "Independent",
      evm_votes: 3701,
      postal_votes: 6,
      total_votes: 3707,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 5,
      candidate: "DR DASHRATH HINUNIA",
      party: "Ambedkarite Party of India",
      evm_votes: 2669,
      postal_votes: 12,
      total_votes: 2681,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 6,
      candidate: "KAHANVI BOHRA",
      party: "Independent",
      evm_votes: 1658,
      postal_votes: 53,
      total_votes: 1711,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 7,
      candidate: "DR RAM ROOP MEENA ADVOCATE",
      party: "Independent",
      evm_votes: 1589,
      postal_votes: 15,
      total_votes: 1604,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 8,
      candidate: "NEHA SINGH GURJAR",
      party: "Independent",
      evm_votes: 1378,
      postal_votes: 29,
      total_votes: 1407,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 9,
      candidate: "PRAKASH KUMAR SHARMA",
      party: "Independent",
      evm_votes: 1033,
      postal_votes: 7,
      total_votes: 1040,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 10,
      candidate: "AJAY BHAT",
      party: "Republican Party of India (A)",
      evm_votes: 931,
      postal_votes: 17,
      total_votes: 948,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 11,
      candidate: "DEVHANS",
      party: "Independent",
      evm_votes: 924,
      postal_votes: 17,
      total_votes: 941,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "DR OM SINGH MEENA SEHARA",
      party: "Independent",
      evm_votes: 676,
      postal_votes: 58,
      total_votes: 734,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "ADITYA PRAKASH SHARMA",
      party: "Right to Recall Party",
      evm_votes: 650,
      postal_votes: 16,
      total_votes: 666,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "YOGI JEETENDRA NATH ADVOCATE",
      party: "Rashtriya Sawarn Dal",
      evm_votes: 556,
      postal_votes: 6,
      total_votes: 562,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 15,
      candidate: "ADVOCATE HARI KISHAN TIWARI",
      party: "Bheem Tribal Congress",
      evm_votes: 541,
      postal_votes: 4,
      total_votes: 545,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7405,
      postal_votes: 114,
      total_votes: 7519,
      percentage_of_votes: 0.6,
    },
  ],
  total: {
    evm_votes: 1237966,
    postal_votes: 24088,
    total_votes: 1262054,
  },
};
export const s207 = {
  cs_name: "JAIPUR",
  cs_shortname: "jaipur",
  cs_code: "s207",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "MANJU SHARMA",
      party: "Bharatiya Janata Party",
      evm_votes: 878643,
      postal_votes: 8207,
      total_votes: 886850,
      percentage_of_votes: 60.61,
    },
    {
      serial_no: 2,
      candidate: "PRATAP SINGH KHACHARIYAWAS",
      party: "Indian National Congress",
      evm_votes: 549424,
      postal_votes: 5659,
      total_votes: 555083,
      percentage_of_votes: 37.93,
    },
    {
      serial_no: 3,
      candidate: "RAJESH TANWAR",
      party: "Bahujan Samaj Party",
      evm_votes: 3420,
      postal_votes: 41,
      total_votes: 3461,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 4,
      candidate: "HARI NARAYAN MEENA",
      party: "Independent",
      evm_votes: 2193,
      postal_votes: 9,
      total_votes: 2202,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 5,
      candidate: "RAJEEV ROLIWAL",
      party: "Independent",
      evm_votes: 962,
      postal_votes: 8,
      total_votes: 970,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 6,
      candidate: "YOGESH SHARMA",
      party: "Independent",
      evm_votes: 811,
      postal_votes: 5,
      total_votes: 816,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 7,
      candidate: "NARENDER SHARMA",
      party: "Rashtriya Sanatan Party",
      evm_votes: 665,
      postal_votes: 4,
      total_votes: 669,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 8,
      candidate: "KULDEEP SINGH",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 605,
      postal_votes: 10,
      total_votes: 615,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 9,
      candidate: "DR. ASEEM VERMA",
      party: "Independent",
      evm_votes: 562,
      postal_votes: 10,
      total_votes: 572,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 10,
      candidate: "PRADEEP VERMA",
      party: "Indian Peoples Green Party",
      evm_votes: 445,
      postal_votes: 3,
      total_votes: 448,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 11,
      candidate: "ADVOCATE HARI KISHAN TIWARI",
      party: "Bheem Tribal Congress",
      evm_votes: 384,
      postal_votes: 16,
      total_votes: 400,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 12,
      candidate: "SHASHANK SINGH ARYA",
      party: "Right to Recall Party",
      evm_votes: 372,
      postal_votes: 21,
      total_votes: 393,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 13,
      candidate: "TRILOK TIWARI",
      party: "Rashtriya Samta Vikas Party",
      evm_votes: 344,
      postal_votes: 7,
      total_votes: 351,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10280,
      postal_votes: 148,
      total_votes: 10428,
      percentage_of_votes: 0.71,
    },
  ],
  total: {
    evm_votes: 1449110,
    postal_votes: 14148,
    total_votes: 1463258,
  },
};
export const s208 = {
  cs_name: "ALWAR",
  cs_shortname: "alwar",
  cs_code: "s208",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BHUPENDER YADAV",
      party: "Bharatiya Janata Party",
      evm_votes: 624110,
      postal_votes: 7882,
      total_votes: 631992,
      percentage_of_votes: 50.42,
    },
    {
      serial_no: 2,
      candidate: "LALIT YADAV",
      party: "Indian National Congress",
      evm_votes: 575964,
      postal_votes: 7746,
      total_votes: 583710,
      percentage_of_votes: 46.57,
    },
    {
      serial_no: 3,
      candidate: "FAZAL HUSSAIN",
      party: "Bahujan Samaj Party",
      evm_votes: 19178,
      postal_votes: 109,
      total_votes: 19287,
      percentage_of_votes: 1.54,
    },
    {
      serial_no: 4,
      candidate: "PARDEEP KUMAR",
      party: "Sarv Samaj Party",
      evm_votes: 4339,
      postal_votes: 23,
      total_votes: 4362,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 5,
      candidate: "VIVEK JAIN",
      party: "Independent",
      evm_votes: 2429,
      postal_votes: 9,
      total_votes: 2438,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 6,
      candidate: "RAMBABU SHARMA",
      party: "Independent",
      evm_votes: 2207,
      postal_votes: 25,
      total_votes: 2232,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 7,
      candidate: "AMIT GUPTA",
      party: "Independent",
      evm_votes: 1366,
      postal_votes: 23,
      total_votes: 1389,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 8,
      candidate: "CHHAGAN LAL",
      party: "Independent",
      evm_votes: 1291,
      postal_votes: 36,
      total_votes: 1327,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 9,
      candidate: "VISHVNATH KHINCHI",
      party: "Hindustan Janta Party",
      evm_votes: 862,
      postal_votes: 17,
      total_votes: 879,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5727,
      postal_votes: 95,
      total_votes: 5822,
      percentage_of_votes: 0.46,
    },
  ],
  total: {
    evm_votes: 1237473,
    postal_votes: 15965,
    total_votes: 1253438,
  },
};
export const s2013 = {
  cs_name: "AJMER",
  cs_shortname: "ajmer",
  cs_code: "s2013",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BHAGIRATH CHOUDHARY",
      party: "Bharatiya Janata Party",
      evm_votes: 741151,
      postal_votes: 6311,
      total_votes: 747462,
      percentage_of_votes: 62.23,
    },
    {
      serial_no: 2,
      candidate: "RAMCHANDRA CHOUDHARY",
      party: "Indian National Congress",
      evm_votes: 413685,
      postal_votes: 3786,
      total_votes: 417471,
      percentage_of_votes: 34.76,
    },
    {
      serial_no: 3,
      candidate: "RAMDEV",
      party: "Bahujan Samaj Party",
      evm_votes: 4896,
      postal_votes: 48,
      total_votes: 4944,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 4,
      candidate: "SURENDRA SINGH RANAVAT",
      party: "Independent",
      evm_votes: 4348,
      postal_votes: 25,
      total_votes: 4373,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 5,
      candidate: "MUKESH GENA",
      party: "Bharatiya Yuva Jan Ekta Party",
      evm_votes: 3187,
      postal_votes: 9,
      total_votes: 3196,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 6,
      candidate: "JITENDRA BOYAT",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 2608,
      postal_votes: 25,
      total_votes: 2633,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 7,
      candidate: "SATYANARAYAN MALI",
      party: "Independent",
      evm_votes: 2409,
      postal_votes: 11,
      total_votes: 2420,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 8,
      candidate: "VISHRAM BABU",
      party: "Independent",
      evm_votes: 1517,
      postal_votes: 7,
      total_votes: 1524,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 9,
      candidate: "SHABUDADIN",
      party: "National Future Party",
      evm_votes: 1190,
      postal_votes: 7,
      total_votes: 1197,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 10,
      candidate: "YUSUF",
      party: "Independent",
      evm_votes: 988,
      postal_votes: 12,
      total_votes: 1000,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 11,
      candidate: "PREM LATA",
      party: "Independent",
      evm_votes: 988,
      postal_votes: 167,
      total_votes: 1155,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 12,
      candidate: "BHAWARLAL SONI",
      party: "Independent",
      evm_votes: 868,
      postal_votes: 70,
      total_votes: 938,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "RAMLAL",
      party: "Akhil Bhartiya Aamjan Party",
      evm_votes: 807,
      postal_votes: 11,
      total_votes: 818,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 14,
      candidate: "DAYA MOHAN GARG",
      party: "Independent",
      evm_votes: 644,
      postal_votes: 2,
      total_votes: 646,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11290,
      postal_votes: 112,
      total_votes: 11402,
      percentage_of_votes: 0.95,
    },
  ],
  total: {
    evm_votes: 1190576,
    postal_votes: 10603,
    total_votes: 1201179,
  },
};
export const s2015 = {
  cs_name: "PALI",
  cs_shortname: "pali",
  cs_code: "s2015",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "P. P. CHAUDHARY",
      party: "Bharatiya Janata Party",
      evm_votes: 750568,
      postal_votes: 6821,
      total_votes: 757389,
      percentage_of_votes: 55.94,
    },
    {
      serial_no: 2,
      candidate: "SANGEETA BENIWAL",
      party: "Indian National Congress",
      evm_votes: 505754,
      postal_votes: 6284,
      total_votes: 512038,
      percentage_of_votes: 37.82,
    },
    {
      serial_no: 3,
      candidate: "LAL SINGH DEWASI",
      party: "Independent",
      evm_votes: 18848,
      postal_votes: 154,
      total_votes: 19002,
      percentage_of_votes: 1.4,
    },
    {
      serial_no: 4,
      candidate: "HUKAM SINGH",
      party: "Independent",
      evm_votes: 16835,
      postal_votes: 262,
      total_votes: 17097,
      percentage_of_votes: 1.26,
    },
    {
      serial_no: 5,
      candidate: "MAHENDRA REGAR",
      party: "Bahujan Samaj Party",
      evm_votes: 11054,
      postal_votes: 47,
      total_votes: 11101,
      percentage_of_votes: 0.82,
    },
    {
      serial_no: 6,
      candidate: "JEEVARAM RANA",
      party: "Bharat Adivasi Party",
      evm_votes: 8325,
      postal_votes: 22,
      total_votes: 8347,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 7,
      candidate: "SHRAWAN RAM DEWASI",
      party: "Independent",
      evm_votes: 5585,
      postal_votes: 8,
      total_votes: 5593,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 8,
      candidate: "MUKESH SAINI",
      party: "Independent",
      evm_votes: 2471,
      postal_votes: 52,
      total_votes: 2523,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 9,
      candidate: "BASTIRAM",
      party: "Indian Peoples Green Party",
      evm_votes: 1570,
      postal_votes: 16,
      total_votes: 1586,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 10,
      candidate: "BHIYARAM",
      party: "Independent",
      evm_votes: 1335,
      postal_votes: 10,
      total_votes: 1345,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "ANAND KUMAR PARIHAR",
      party: "Independent",
      evm_votes: 1327,
      postal_votes: 8,
      total_votes: 1335,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 12,
      candidate: "KESARAM",
      party: "Independent",
      evm_votes: 1327,
      postal_votes: 18,
      total_votes: 1345,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 13,
      candidate: "DEEPAK BAMNIYA",
      party: "Independent",
      evm_votes: 1256,
      postal_votes: 32,
      total_votes: 1288,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13763,
      postal_votes: 90,
      total_votes: 13853,
      percentage_of_votes: 1.02,
    },
  ],
  total: {
    evm_votes: 1340018,
    postal_votes: 13824,
    total_votes: 1353842,
  },
};
export const s2016 = {
  cs_name: "JODHPUR",
  cs_shortname: "jodhpur",
  cs_code: "s2016",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "GAJENDRA SINGH SHEKHAWAT",
      party: "Bharatiya Janata Party",
      evm_votes: 722978,
      postal_votes: 7078,
      total_votes: 730056,
      percentage_of_votes: 52.76,
    },
    {
      serial_no: 2,
      candidate: "KARAN SINGH UCHIYARDA",
      party: "Indian National Congress",
      evm_votes: 608228,
      postal_votes: 6151,
      total_votes: 614379,
      percentage_of_votes: 44.4,
    },
    {
      serial_no: 3,
      candidate: "SUNIL KUMAR PAREEK",
      party: "Independent",
      evm_votes: 7424,
      postal_votes: 5,
      total_votes: 7429,
      percentage_of_votes: 0.54,
    },
    {
      serial_no: 4,
      candidate: "MANJU MEGHWAL",
      party: "Bahujan Samaj Party",
      evm_votes: 6347,
      postal_votes: 48,
      total_votes: 6395,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 5,
      candidate: "ANANDARAM URF ANAND CHAUHAN",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 3662,
      postal_votes: 18,
      total_votes: 3680,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 6,
      candidate: "SUNIL BHANDARI",
      party: "Independent",
      evm_votes: 3236,
      postal_votes: 2,
      total_votes: 3238,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 7,
      candidate: "VISHEK VISHNOI",
      party: "Independent",
      evm_votes: 1718,
      postal_votes: 32,
      total_votes: 1750,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 8,
      candidate: "SHIVA RAM",
      party: "Independent",
      evm_votes: 1191,
      postal_votes: 4,
      total_votes: 1195,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 9,
      candidate: "LIKHAMA RAM",
      party: "Independent",
      evm_votes: 1123,
      postal_votes: 2,
      total_votes: 1125,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 10,
      candidate: "NARESH KANDARA",
      party: "Independent",
      evm_votes: 1007,
      postal_votes: 20,
      total_votes: 1027,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "SATYANARAYAN",
      party: "Bharatiya Sampuran Krantikari Party",
      evm_votes: 776,
      postal_votes: 6,
      total_votes: 782,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "PAPPU DAN",
      party: "Right to Recall Party",
      evm_votes: 650,
      postal_votes: 4,
      total_votes: 654,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "SHAHNAZ BANO",
      party: "Dalit Kranti Dal",
      evm_votes: 546,
      postal_votes: 1,
      total_votes: 547,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 14,
      candidate: "RAM DAYAL BISHNOI",
      party: "Rashtriya Janshakti Party (Secular)",
      evm_votes: 491,
      postal_votes: 12,
      total_votes: 503,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 15,
      candidate: "BHOM SINGH",
      party: "Indian Peoples Green Party",
      evm_votes: 483,
      postal_votes: 4,
      total_votes: 487,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10511,
      postal_votes: 80,
      total_votes: 10591,
      percentage_of_votes: 0.77,
    },
  ],
  total: {
    evm_votes: 1370371,
    postal_votes: 13467,
    total_votes: 1383838,
  },
};
export const s2018 = {
  cs_name: "JALORE",
  cs_shortname: "jalore",
  cs_code: "s2018",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "LUMBARAM",
      party: "Bharatiya Janata Party",
      evm_votes: 793705,
      postal_votes: 3078,
      total_votes: 796783,
      percentage_of_votes: 54.91,
    },
    {
      serial_no: 2,
      candidate: "VAIBHAV GEHLOT",
      party: "Indian National Congress",
      evm_votes: 592204,
      postal_votes: 3036,
      total_votes: 595240,
      percentage_of_votes: 41.02,
    },
    {
      serial_no: 3,
      candidate: "OTARAM",
      party: "Bharat Adivasi Party",
      evm_votes: 10077,
      postal_votes: 32,
      total_votes: 10109,
      percentage_of_votes: 0.7,
    },
    {
      serial_no: 4,
      candidate: "SHAKUR",
      party: "Independent",
      evm_votes: 9866,
      postal_votes: 6,
      total_votes: 9872,
      percentage_of_votes: 0.68,
    },
    {
      serial_no: 5,
      candidate: "MOTI LAL",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 4579,
      postal_votes: 7,
      total_votes: 4586,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 6,
      candidate: "RAMESH KUMAR BHANDARI",
      party: "Independent",
      evm_votes: 3909,
      postal_votes: 1,
      total_votes: 3910,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 7,
      candidate: "DEVARAM",
      party: "Independent",
      evm_votes: 3126,
      postal_votes: 8,
      total_votes: 3134,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 8,
      candidate: "KALURAM MEGHWAL",
      party: "Independent",
      evm_votes: 2258,
      postal_votes: 5,
      total_votes: 2263,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "DINESH SINGH",
      party: "Independent",
      evm_votes: 2094,
      postal_votes: 11,
      total_votes: 2105,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "TIKMA RAM BHATI",
      party: "Bheem Tribal Congress",
      evm_votes: 1653,
      postal_votes: 7,
      total_votes: 1660,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 11,
      candidate: "DALARAM",
      party: "Independent",
      evm_votes: 1493,
      postal_votes: 15,
      total_votes: 1508,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 12,
      candidate: "FOJARAM",
      party: "Indian Peoples Green Party",
      evm_votes: 1493,
      postal_votes: 4,
      total_votes: 1497,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 18439,
      postal_votes: 20,
      total_votes: 18459,
      percentage_of_votes: 1.27,
    },
  ],
  total: {
    evm_votes: 1444896,
    postal_votes: 6230,
    total_votes: 1451126,
  },
};
export const s2019 = {
  cs_name: "UDAIPUR",
  cs_shortname: "udaipur",
  cs_code: "s2019",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "MANNA LAL RAWAT",
      party: "Bharatiya Janata Party",
      evm_votes: 732757,
      postal_votes: 5529,
      total_votes: 738286,
      percentage_of_votes: 49.27,
    },
    {
      serial_no: 2,
      candidate: "TARACHAND MEENA",
      party: "Indian National Congress",
      evm_votes: 473167,
      postal_votes: 3511,
      total_votes: 476678,
      percentage_of_votes: 31.81,
    },
    {
      serial_no: 3,
      candidate: "PRAKASH CHAND",
      party: "Bharat Adivasi Party",
      evm_votes: 215390,
      postal_votes: 1748,
      total_votes: 217138,
      percentage_of_votes: 14.49,
    },
    {
      serial_no: 4,
      candidate: "DALPAT RAM GARASIA",
      party: "Bahujan Samaj Party",
      evm_votes: 14427,
      postal_votes: 33,
      total_votes: 14460,
      percentage_of_votes: 0.97,
    },
    {
      serial_no: 5,
      candidate: "DR. SAVITA KUMARI AHARI",
      party: "Independent",
      evm_votes: 8130,
      postal_votes: 32,
      total_votes: 8162,
      percentage_of_votes: 0.54,
    },
    {
      serial_no: 6,
      candidate: "PRABHULAL MEENA",
      party: "Independent",
      evm_votes: 7972,
      postal_votes: 24,
      total_votes: 7996,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 7,
      candidate: "RAJENDRA KUMAR MEENA",
      party: "Indian Peoples Green Party",
      evm_votes: 6752,
      postal_votes: 25,
      total_votes: 6777,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 8,
      candidate: "KANJI LAL DAMOR",
      party: "Independent",
      evm_votes: 5912,
      postal_votes: 19,
      total_votes: 5931,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 22848,
      postal_votes: 100,
      total_votes: 22948,
      percentage_of_votes: 1.53,
    },
  ],
  total: {
    evm_votes: 1487355,
    postal_votes: 11021,
    total_votes: 1498376,
  },
};
export const s2021 = {
  cs_name: "CHITTORGARH",
  cs_shortname: "chittorgarh",
  cs_code: "s2021",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "CHANDRA PRAKASH JOSHI",
      party: "Bharatiya Janata Party",
      evm_votes: 882334,
      postal_votes: 5868,
      total_votes: 888202,
      percentage_of_votes: 59.26,
    },
    {
      serial_no: 2,
      candidate: "ANJANA UDAILAL",
      party: "Indian National Congress",
      evm_votes: 495177,
      postal_votes: 3148,
      total_votes: 498325,
      percentage_of_votes: 33.25,
    },
    {
      serial_no: 3,
      candidate: "BHIL ADIVASI MANGILAL NANAMA",
      party: "Bharat Adivasi Party",
      evm_votes: 42243,
      postal_votes: 568,
      total_votes: 42811,
      percentage_of_votes: 2.86,
    },
    {
      serial_no: 4,
      candidate: "RAMESHWAR LAL BAIRWA (INDORA)",
      party: "Independent",
      evm_votes: 9536,
      postal_votes: 3,
      total_votes: 9539,
      percentage_of_votes: 0.64,
    },
    {
      serial_no: 5,
      candidate: "KUMAWAT RAMESH CHANDRA",
      party: "Independent",
      evm_votes: 8834,
      postal_votes: 8,
      total_votes: 8842,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 6,
      candidate: "MEGHWAL RADHESHYAM",
      party: "Bahujan Samaj Party",
      evm_votes: 8578,
      postal_votes: 35,
      total_votes: 8613,
      percentage_of_votes: 0.57,
    },
    {
      serial_no: 7,
      candidate: "SHYAM LAL MEGHWAL",
      party: "Independent",
      evm_votes: 7901,
      postal_votes: 6,
      total_votes: 7907,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 8,
      candidate: "BAPU LAL ANJANA",
      party: "Independent",
      evm_votes: 7837,
      postal_votes: 9,
      total_votes: 7846,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 9,
      candidate: "PRAKASH DHAKAR",
      party: "Ekam Sanatan Bharat Dal",
      evm_votes: 3915,
      postal_votes: 10,
      total_votes: 3925,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 10,
      candidate: "PRATAP SINGH",
      party: "Independent",
      evm_votes: 3342,
      postal_votes: 5,
      total_votes: 3347,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 11,
      candidate: "AZAD PRAKASH CHANDRA MEGHWAL",
      party: "Akhil Bharatiya Congress Dal (Ambedkar)",
      evm_votes: 2764,
      postal_votes: 13,
      total_votes: 2777,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 12,
      candidate: "JOGENDRA SINGH HODA",
      party: "Independent",
      evm_votes: 2294,
      postal_votes: 8,
      total_votes: 2302,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 13,
      candidate: "GAJENDRA",
      party: "Independent",
      evm_votes: 1637,
      postal_votes: 7,
      total_votes: 1644,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 14,
      candidate: "KALYAN SINGH BHATI",
      party: "Independent",
      evm_votes: 1544,
      postal_votes: 18,
      total_votes: 1562,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "MAHAVIR PRASAD KUMAWAT",
      party: "Right to Recall Party",
      evm_votes: 1539,
      postal_votes: 10,
      total_votes: 1549,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 16,
      candidate: "KAMAL",
      party: "Independent",
      evm_votes: 1490,
      postal_votes: 8,
      total_votes: 1498,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 17,
      candidate: "MOHAMMAD WAHID KHAN",
      party: "Pehchan People’s Party",
      evm_votes: 1216,
      postal_votes: 12,
      total_votes: 1228,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 18,
      candidate: "SITA RAM AHIR",
      party: "Vishva Shakti Party",
      evm_votes: 1203,
      postal_votes: 23,
      total_votes: 1226,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 19,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5518,
      postal_votes: 72,
      total_votes: 5590,
      percentage_of_votes: 0.37,
    },
  ],
  total: {
    evm_votes: 1488902,
    postal_votes: 9831,
    total_votes: 1498733,
  },
};
export const s2022 = {
  cs_name: "RAJSAMAND",
  cs_shortname: "rajsamand",
  cs_code: "s2022",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "MAHIMA KUMARI MEWAR",
      party: "Bharatiya Janata Party",
      evm_votes: 775243,
      postal_votes: 5960,
      total_votes: 781203,
      percentage_of_votes: 64.4,
    },
    {
      serial_no: 2,
      candidate: "DR. DAMODAR GURJAR",
      party: "Indian National Congress",
      evm_votes: 385359,
      postal_votes: 3621,
      total_votes: 388980,
      percentage_of_votes: 32.06,
    },
    {
      serial_no: 3,
      candidate: "RAMAKISHAN BHADU",
      party: "Bahujan Samaj Party",
      evm_votes: 9097,
      postal_votes: 38,
      total_votes: 9135,
      percentage_of_votes: 0.75,
    },
    {
      serial_no: 4,
      candidate: "NEERU RAM KAPRI",
      party: "Independent",
      evm_votes: 7820,
      postal_votes: 11,
      total_votes: 7831,
      percentage_of_votes: 0.65,
    },
    {
      serial_no: 5,
      candidate: "DHARAM SINGH RAWAT",
      party: "Independent",
      evm_votes: 3993,
      postal_votes: 60,
      total_votes: 4053,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 6,
      candidate: "NARAYAN SONI",
      party: "Independent",
      evm_votes: 3367,
      postal_votes: 7,
      total_votes: 3374,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 7,
      candidate: "GHANSHYAM SINGH",
      party: "Bhartiya Jan Adhikar Party",
      evm_votes: 1917,
      postal_votes: 23,
      total_votes: 1940,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 8,
      candidate: "JITENDRA KUMAR KHATIK",
      party: "Independent",
      evm_votes: 1802,
      postal_votes: 13,
      total_votes: 1815,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 9,
      candidate: "DR. ARPIT CHHAJER",
      party: "Independent",
      evm_votes: 1235,
      postal_votes: 9,
      total_votes: 1244,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 10,
      candidate: "PRAMOD KUMAR VERMA",
      party: "Bheem Tribal Congress",
      evm_votes: 1134,
      postal_votes: 10,
      total_votes: 1144,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12339,
      postal_votes: 72,
      total_votes: 12411,
      percentage_of_votes: 1.02,
    },
  ],
  total: {
    evm_votes: 1203306,
    postal_votes: 9824,
    total_votes: 1213130,
  },
};
export const s2023 = {
  cs_name: "BHILWARA",
  cs_shortname: "bhilwara",
  cs_code: "s2023",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DAMODAR AGARWAL",
      party: "Bharatiya Janata Party",
      evm_votes: 803193,
      postal_votes: 4447,
      total_votes: 807640,
      percentage_of_votes: 61.92,
    },
    {
      serial_no: 2,
      candidate: "C P JOSHI",
      party: "Indian National Congress",
      evm_votes: 449528,
      postal_votes: 3506,
      total_votes: 453034,
      percentage_of_votes: 34.73,
    },
    {
      serial_no: 3,
      candidate: "RAMESHWAR LAL BAIRWA",
      party: "Bahujan Samaj Party",
      evm_votes: 8243,
      postal_votes: 40,
      total_votes: 8283,
      percentage_of_votes: 0.64,
    },
    {
      serial_no: 4,
      candidate: "RAJESH PATNI",
      party: "Independent",
      evm_votes: 8013,
      postal_votes: 5,
      total_votes: 8018,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 5,
      candidate: "JAYKISHAN",
      party: "Viro Ke Vir Indian Party",
      evm_votes: 4061,
      postal_votes: 23,
      total_votes: 4084,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 6,
      candidate: "MOTILAL SINGHANIA",
      party: "Independent",
      evm_votes: 3871,
      postal_votes: 16,
      total_votes: 3887,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 7,
      candidate: "NARAYAN LAL JAT",
      party: "Independent",
      evm_votes: 1643,
      postal_votes: 41,
      total_votes: 1684,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 8,
      candidate: "PAWAN KUMAR SHARMA",
      party: "Right to Recall Party",
      evm_votes: 1609,
      postal_votes: 8,
      total_votes: 1617,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 9,
      candidate: "ANURAG AROT",
      party: "Independent",
      evm_votes: 1406,
      postal_votes: 19,
      total_votes: 1425,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "VIJAY KUMAR SONI",
      party: "Bhartiya Garib Vikas Kalyan Party",
      evm_votes: 1340,
      postal_votes: 7,
      total_votes: 1347,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13337,
      postal_votes: 39,
      total_votes: 13376,
      percentage_of_votes: 1.03,
    },
  ],
  total: {
    evm_votes: 1296244,
    postal_votes: 8151,
    total_votes: 1304395,
  },
};
export const s2024 = {
  cs_name: "KOTA",
  cs_shortname: "kota",
  cs_code: "s2024",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "OM BIRLA",
      party: "Bharatiya Janata Party",
      evm_votes: 744396,
      postal_votes: 6100,
      total_votes: 750496,
      percentage_of_votes: 50.03,
    },
    {
      serial_no: 2,
      candidate: "PRAHLAD GUNJAL",
      party: "Indian National Congress",
      evm_votes: 702623,
      postal_votes: 5899,
      total_votes: 708522,
      percentage_of_votes: 47.23,
    },
    {
      serial_no: 3,
      candidate: "DHANRAJ YADAV",
      party: "Bahujan Samaj Party",
      evm_votes: 7547,
      postal_votes: 28,
      total_votes: 7575,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 4,
      candidate: "ASHISH YOGI",
      party: "Ekam Sanatan Bharat Dal",
      evm_votes: 5756,
      postal_votes: 19,
      total_votes: 5775,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 5,
      candidate: "LAKSHMICHAND",
      party: "Independent",
      evm_votes: 3890,
      postal_votes: 2,
      total_votes: 3892,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 6,
      candidate: "SATYENDRA KUMAR JAIN",
      party: "Independent",
      evm_votes: 2520,
      postal_votes: 1,
      total_votes: 2521,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 7,
      candidate: "RAMNATH MEHRA",
      party: "Independent",
      evm_votes: 2407,
      postal_votes: 15,
      total_votes: 2422,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 8,
      candidate: "MOINUDDIN",
      party: "Independent",
      evm_votes: 1854,
      postal_votes: 6,
      total_votes: 1860,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 9,
      candidate: "BHANWAR KUMAR RAWAL",
      party: "Independent",
      evm_votes: 1102,
      postal_votes: 29,
      total_votes: 1131,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 10,
      candidate: "BALDEV SINGH FOJI",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 1066,
      postal_votes: 3,
      total_votes: 1069,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "TARUN GOCHAR",
      party: "Right to Recall Party",
      evm_votes: 992,
      postal_votes: 8,
      total_votes: 1000,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "KAILASHI ANIL JAIN",
      party: "Independent",
      evm_votes: 990,
      postal_votes: 4,
      total_votes: 994,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "KAMAL KUMAR BAIRWA",
      party: "Independent",
      evm_votes: 965,
      postal_votes: 49,
      total_votes: 1014,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 14,
      candidate: "ABDUL ASIF",
      party: "Independent",
      evm_votes: 860,
      postal_votes: 5,
      total_votes: 865,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "OM PRAKASH SHAKYAWAL",
      party: "Independent",
      evm_votes: 757,
      postal_votes: 3,
      total_votes: 760,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10176,
      postal_votes: 85,
      total_votes: 10261,
      percentage_of_votes: 0.68,
    },
  ],
  total: {
    evm_votes: 1487901,
    postal_votes: 12256,
    total_votes: 1500157,
  },
};
export const s2025 = {
  cs_name: "JHALAWAR-BARAN",
  cs_shortname: "jhalawarbaran",
  cs_code: "s2025",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DUSHYANT SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 861850,
      postal_votes: 3526,
      total_votes: 865376,
      percentage_of_votes: 60.88,
    },
    {
      serial_no: 2,
      candidate: "URMILA JAIN “BHAYA”",
      party: "Indian National Congress",
      evm_votes: 492342,
      postal_votes: 2045,
      total_votes: 494387,
      percentage_of_votes: 34.78,
    },
    {
      serial_no: 3,
      candidate: "CHANDRA SINGH KIRAD",
      party: "Bahujan Samaj Party",
      evm_votes: 12252,
      postal_votes: 33,
      total_votes: 12285,
      percentage_of_votes: 0.86,
    },
    {
      serial_no: 4,
      candidate: "RAVI RAJ SINGH",
      party: "Independent",
      evm_votes: 10231,
      postal_votes: 43,
      total_votes: 10274,
      percentage_of_votes: 0.72,
    },
    {
      serial_no: 5,
      candidate: "BHUVANESH KUMAR",
      party: "Right to Recall Party",
      evm_votes: 9324,
      postal_votes: 21,
      total_votes: 9345,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 6,
      candidate: "BABULAL",
      party: "Independent",
      evm_votes: 7668,
      postal_votes: 24,
      total_votes: 7692,
      percentage_of_votes: 0.54,
    },
    {
      serial_no: 7,
      candidate: "PANKAJ PAJANTORIVALA",
      party: "Independent",
      evm_votes: 5927,
      postal_votes: 18,
      total_votes: 5945,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 15958,
      postal_votes: 69,
      total_votes: 16027,
      percentage_of_votes: 1.13,
    },
  ],
  total: {
    evm_votes: 1415552,
    postal_votes: 5779,
    total_votes: 1421331,
  },
};
export const s231 = {
  cs_name: "Tripura_West",
  cs_shortname: "tripurawest",
  cs_code: "s231",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BIPLAB KUMAR DEB",
      party: "Bharatiya Janata Party",
      evm_votes: 868484,
      postal_votes: 12857,
      total_votes: 881341,
      percentage_of_votes: 72.85,
    },
    {
      serial_no: 2,
      candidate: "ASISH KUMAR SAHA",
      party: "Indian National Congress",
      evm_votes: 265912,
      postal_votes: 3851,
      total_votes: 269763,
      percentage_of_votes: 22.3,
    },
    {
      serial_no: 3,
      candidate: "RAMENDRA REANG",
      party: "Independent",
      evm_votes: 8899,
      postal_votes: 33,
      total_votes: 8932,
      percentage_of_votes: 0.74,
    },
    {
      serial_no: 4,
      candidate: "ARUN KUMAR BHAUMIK",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 8617,
      postal_votes: 167,
      total_votes: 8784,
      percentage_of_votes: 0.73,
    },
    {
      serial_no: 5,
      candidate: "GOURI SANKAR NANDI",
      party: "Independent",
      evm_votes: 8074,
      postal_votes: 40,
      total_votes: 8114,
      percentage_of_votes: 0.67,
    },
    {
      serial_no: 6,
      candidate: "MILAN PADA MURASING",
      party: "Independent",
      evm_votes: 6625,
      postal_votes: 27,
      total_votes: 6652,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 7,
      candidate: "BALARAM DEBBARMA",
      party: "Independent",
      evm_votes: 4356,
      postal_votes: 123,
      total_votes: 4479,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 8,
      candidate: "BRAJALAL DEBNATH",
      party: "Independent",
      evm_votes: 3656,
      postal_votes: 27,
      total_votes: 3683,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 9,
      candidate: "ARNAB ROY",
      party: "Republican Party of India (A)",
      evm_votes: 3420,
      postal_votes: 29,
      total_votes: 3449,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 14444,
      postal_votes: 168,
      total_votes: 14612,
      percentage_of_votes: 1.21,
    },
  ],
  total: {
    evm_votes: 1192487,
    postal_votes: 17322,
    total_votes: 1209809,
  },
};
export const s232 = {
  cs_name: "Tripura_East",
  cs_shortname: "tripuraeast",
  cs_code: "s232",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "KRITI DEVI DEBBARMAN",
      party: "Bharatiya Janata Party",
      evm_votes: 768129,
      postal_votes: 9318,
      total_votes: 777447,
      percentage_of_votes: 68.54,
    },
    {
      serial_no: 2,
      candidate: "RAJENDRA REANG",
      party: "Communist Party of India (Marxist)",
      evm_votes: 287772,
      postal_votes: 2856,
      total_votes: 290628,
      percentage_of_votes: 25.62,
    },
    {
      serial_no: 3,
      candidate: "ARCHANA URANG",
      party: "Independent",
      evm_votes: 10814,
      postal_votes: 108,
      total_votes: 10922,
      percentage_of_votes: 0.96,
    },
    {
      serial_no: 4,
      candidate: "DARSHAN KUMAR REANG",
      party: "Independent",
      evm_votes: 9200,
      postal_votes: 69,
      total_votes: 9269,
      percentage_of_votes: 0.82,
    },
    {
      serial_no: 5,
      candidate: "SANTOJOY TRIPURA",
      party: "Independent",
      evm_votes: 6825,
      postal_votes: 37,
      total_votes: 6862,
      percentage_of_votes: 0.6,
    },
    {
      serial_no: 6,
      candidate: "SHIB CHANDRA DEBBARMA",
      party: "Independent",
      evm_votes: 6685,
      postal_votes: 34,
      total_votes: 6719,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 7,
      candidate: "SATRUGHNA JAMATIA",
      party: "Independent",
      evm_votes: 5939,
      postal_votes: 28,
      total_votes: 5967,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 8,
      candidate: "KRISHNA MOHAN JAMATIA",
      party: "Independent",
      evm_votes: 4166,
      postal_votes: 51,
      total_votes: 4217,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 9,
      candidate: "KALPA MOHAN TRIPURA",
      party: "Independent",
      evm_votes: 3958,
      postal_votes: 53,
      total_votes: 4011,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 18176,
      postal_votes: 127,
      total_votes: 18303,
      percentage_of_votes: 1.61,
    },
  ],
  total: {
    evm_votes: 1121664,
    postal_votes: 12681,
    total_votes: 1134345,
  },
};
export const s249 = {
  cs_name: "Amroha",
  cs_shortname: "amroha",
  cs_code: "s249",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "KANWAR SINGH TANWAR",
      party: "Bharatiya Janata Party",
      evm_votes: 475210,
      postal_votes: 1296,
      total_votes: 476506,
      percentage_of_votes: 42.9,
    },
    {
      serial_no: 2,
      candidate: "KUNWAR DANISH ALI",
      party: "Indian National Congress",
      evm_votes: 447343,
      postal_votes: 493,
      total_votes: 447836,
      percentage_of_votes: 40.32,
    },
    {
      serial_no: 3,
      candidate: "MUJAHID HUSAIN",
      party: "Bahujan Samaj Party",
      evm_votes: 163885,
      postal_votes: 214,
      total_votes: 164099,
      percentage_of_votes: 14.77,
    },
    {
      serial_no: 4,
      candidate: "DHANISH",
      party: "Independent",
      evm_votes: 3615,
      postal_votes: 2,
      total_votes: 3617,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 5,
      candidate: "SURESH",
      party: "Independent",
      evm_votes: 2716,
      postal_votes: 1,
      total_votes: 2717,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 6,
      candidate: "JEETPAL RANA",
      party: "Independent",
      evm_votes: 2550,
      postal_votes: 6,
      total_votes: 2556,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 7,
      candidate: "SUHEL HAIDER",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 2492,
      postal_votes: 12,
      total_votes: 2504,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 8,
      candidate: "NARENDRA SINGH",
      party: "Independent",
      evm_votes: 1776,
      postal_votes: 3,
      total_votes: 1779,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "NAEEMUDDIN",
      party: "Independent",
      evm_votes: 1002,
      postal_votes: 2,
      total_votes: 1004,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 10,
      candidate: "KUSHAGRA",
      party: "Independent",
      evm_votes: 856,
      postal_votes: 2,
      total_votes: 858,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 11,
      candidate: "KASHIF HUSAIN",
      party: "Independent",
      evm_votes: 677,
      postal_votes: 2,
      total_votes: 679,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "KUMDESH KUMAR",
      party: "Independent",
      evm_votes: 627,
      postal_votes: 8,
      total_votes: 635,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5886,
      postal_votes: 14,
      total_votes: 5900,
      percentage_of_votes: 0.53,
    },
  ],
  total: {
    evm_votes: 1108635,
    postal_votes: 2055,
    total_votes: 1110690,
  },
};
export const s2410 = {
  cs_name: "Meerut",
  cs_shortname: "meerut",
  cs_code: "s2410",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ARUN GOVIL",
      party: "Bharatiya Janata Party",
      evm_votes: 544375,
      postal_votes: 2094,
      total_votes: 546469,
      percentage_of_votes: 46.21,
    },
    {
      serial_no: 2,
      candidate: "SUNITA VERMA",
      party: "Samajwadi Party",
      evm_votes: 535009,
      postal_votes: 875,
      total_votes: 535884,
      percentage_of_votes: 45.32,
    },
    {
      serial_no: 3,
      candidate: "DEVVRAT KUMAR TYAGI",
      party: "Bahujan Samaj Party",
      evm_votes: 86693,
      postal_votes: 332,
      total_votes: 87025,
      percentage_of_votes: 7.36,
    },
    {
      serial_no: 4,
      candidate: "BHUPENDRA PAL URF BHUPPI BHAI",
      party: "Rashtriya Shoshit Samaj Party",
      evm_votes: 2615,
      postal_votes: 11,
      total_votes: 2626,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 5,
      candidate: "HAJI AFZAL",
      party: "Sabse Achchhi Party",
      evm_votes: 2026,
      postal_votes: 3,
      total_votes: 2029,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 6,
      candidate: "ABID HUSAIN",
      party: "Social Democratic Party Of India",
      evm_votes: 1917,
      postal_votes: 5,
      total_votes: 1922,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 7,
      candidate: "LIYAKAT",
      party: "Majloom Samaj Party",
      evm_votes: 891,
      postal_votes: 3,
      total_votes: 894,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 8,
      candidate: "DR HIMANSHU BHATNAGAR",
      party: "Jai Hind National Party",
      evm_votes: 848,
      postal_votes: 6,
      total_votes: 854,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4738,
      postal_votes: 38,
      total_votes: 4776,
      percentage_of_votes: 0.4,
    },
  ],
  total: {
    evm_votes: 1179112,
    postal_votes: 3367,
    total_votes: 1182479,
  },
};
export const s2412 = {
  cs_name: "Ghaziabad",
  cs_shortname: "ghaziabad",
  cs_code: "s2412",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ATUL GARG",
      party: "Bharatiya Janata Party",
      evm_votes: 853036,
      postal_votes: 1134,
      total_votes: 854170,
      percentage_of_votes: 58.09,
    },
    {
      serial_no: 2,
      candidate: "DOLLY SHARMA",
      party: "Indian National Congress",
      evm_votes: 516699,
      postal_votes: 506,
      total_votes: 517205,
      percentage_of_votes: 35.17,
    },
    {
      serial_no: 3,
      candidate: "NAND KISHOR PUNDIR",
      party: "Bahujan Samaj Party",
      evm_votes: 79387,
      postal_votes: 138,
      total_votes: 79525,
      percentage_of_votes: 5.41,
    },
    {
      serial_no: 4,
      candidate: "RAVI KUMAR PANCHAL",
      party: "Independent",
      evm_votes: 1965,
      postal_votes: 1,
      total_votes: 1966,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 5,
      candidate: "ANAND KUMAR",
      party: "Rashtra Nirman Party",
      evm_votes: 1174,
      postal_votes: 4,
      total_votes: 1178,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 6,
      candidate: "NATTHUSINGH CHAUDHARY",
      party: "Independent",
      evm_votes: 1159,
      postal_votes: 3,
      total_votes: 1162,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 7,
      candidate: "AURANGJEB",
      party: "Independent",
      evm_votes: 1120,
      postal_votes: 0,
      total_votes: 1120,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 8,
      candidate: "NAMAHA",
      party: "Samaj Vikas Kranti Party",
      evm_votes: 1079,
      postal_votes: 1,
      total_votes: 1080,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 9,
      candidate: "AVDESH KUMAR",
      party: "Independent",
      evm_votes: 1037,
      postal_votes: 6,
      total_votes: 1043,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 10,
      candidate: "POOJA SAXENA",
      party: "Right to Recall Party",
      evm_votes: 874,
      postal_votes: 4,
      total_votes: 878,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 11,
      candidate: "KAVITA",
      party: "Independent",
      evm_votes: 772,
      postal_votes: 3,
      total_votes: 775,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 12,
      candidate: "ANSUL GUPTA",
      party: "Rashtriya Jan Karmath Party",
      evm_votes: 763,
      postal_votes: 1,
      total_votes: 764,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "DHIRENDRA SINGH BHADAURIYA",
      party: "Subhashwadi Bhartiya Samajwadi Party (Subhas Party)",
      evm_votes: 696,
      postal_votes: 4,
      total_votes: 700,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "ABHISHEK PUNDIR",
      party: "Independent",
      evm_votes: 652,
      postal_votes: 1,
      total_votes: 653,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8195,
      postal_votes: 16,
      total_votes: 8211,
      percentage_of_votes: 0.56,
    },
  ],
  total: {
    evm_votes: 1468608,
    postal_votes: 1822,
    total_votes: 1470430,
  },
};
export const s2413 = {
  cs_name: "Gautam_Buddha_Nagar",
  cs_shortname: "gautambuddhanagar",
  cs_code: "s2413",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR. MAHESH SHARMA",
      party: "Bharatiya Janata Party",
      evm_votes: 855413,
      postal_votes: 2416,
      total_votes: 857829,
      percentage_of_votes: 59.69,
    },
    {
      serial_no: 2,
      candidate: "DR. MAHENDRA SINGH NAGAR",
      party: "Samajwadi Party",
      evm_votes: 297697,
      postal_votes: 660,
      total_votes: 298357,
      percentage_of_votes: 20.76,
    },
    {
      serial_no: 3,
      candidate: "RAJENDRA SINGH SOLANKI",
      party: "Bahujan Samaj Party",
      evm_votes: 250962,
      postal_votes: 653,
      total_votes: 251615,
      percentage_of_votes: 17.51,
    },
    {
      serial_no: 4,
      candidate: "BHIM PRAKASH JIGYASU",
      party: "Viro Ke Vir Indian Party",
      evm_votes: 3873,
      postal_votes: 2,
      total_votes: 3875,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 5,
      candidate: "MOHD. MUMTAJ AALAM",
      party: "Independent",
      evm_votes: 2818,
      postal_votes: 3,
      total_votes: 2821,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 6,
      candidate: "SHIVAM ASHUTOSH",
      party: "Independent",
      evm_votes: 2637,
      postal_votes: 4,
      total_votes: 2641,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 7,
      candidate: "MANISH KUMAR DWIVEDI",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 1769,
      postal_votes: 3,
      total_votes: 1772,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 8,
      candidate: "KISHOR SINGH",
      party: "National Party",
      evm_votes: 1739,
      postal_votes: 28,
      total_votes: 1767,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 9,
      candidate: "MAHKAR SINGH",
      party: "Independent",
      evm_votes: 1118,
      postal_votes: 6,
      total_votes: 1124,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 10,
      candidate: "KM. SHALU",
      party: "Loktantrik Janshakti Party",
      evm_votes: 1020,
      postal_votes: 30,
      total_votes: 1050,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "PARAG KAUSHIK",
      party: "Independent",
      evm_votes: 945,
      postal_votes: 1,
      total_votes: 946,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "NARESH NAUTIYAL",
      party: "Bhartiya Rashtriya Jansatta",
      evm_votes: 931,
      postal_votes: 10,
      total_votes: 941,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "RAN SINGH DUDEE",
      party: "Super Power India Party",
      evm_votes: 905,
      postal_votes: 3,
      total_votes: 908,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 14,
      candidate: "RAJEEV MISHRA",
      party: "Jai Hind National Party",
      evm_votes: 642,
      postal_votes: 15,
      total_votes: 657,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "NARAVEDASHVAR",
      party: "Subhashwadi Bhartiya Samajwadi Party (Subhas Party)",
      evm_votes: 615,
      postal_votes: 2,
      total_votes: 617,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10286,
      postal_votes: 38,
      total_votes: 10324,
      percentage_of_votes: 0.72,
    },
  ],
  total: {
    evm_votes: 1433370,
    postal_votes: 3874,
    total_votes: 1437244,
  },
};
export const s2414 = {
  cs_name: "Bulandshahr",
  cs_shortname: "bulandshahr",
  cs_code: "s2414",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR BHOLA SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 594040,
      postal_votes: 3270,
      total_votes: 597310,
      percentage_of_votes: 56.65,
    },
    {
      serial_no: 2,
      candidate: "SHIVRAM",
      party: "Indian National Congress",
      evm_votes: 321043,
      postal_votes: 1133,
      total_votes: 322176,
      percentage_of_votes: 30.56,
    },
    {
      serial_no: 3,
      candidate: "GIRISH CHANDRA",
      party: "Bahujan Samaj Party",
      evm_votes: 116876,
      postal_votes: 548,
      total_votes: 117424,
      percentage_of_votes: 11.14,
    },
    {
      serial_no: 4,
      candidate: "RAJESH TUREHA",
      party: "Rishivadi Karm Sheel Young Parmarthi Party",
      evm_votes: 4391,
      postal_votes: 10,
      total_votes: 4401,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 5,
      candidate: "SONAM BHARTI",
      party: "Independent",
      evm_votes: 3558,
      postal_votes: 42,
      total_votes: 3600,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 6,
      candidate: "PRADEEP KUMAR",
      party: "Independent",
      evm_votes: 2547,
      postal_votes: 16,
      total_votes: 2563,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 7,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6870,
      postal_votes: 55,
      total_votes: 6925,
      percentage_of_votes: 0.66,
    },
  ],
  total: {
    evm_votes: 1049325,
    postal_votes: 5074,
    total_votes: 1054399,
  },
};
export const s2415 = {
  cs_name: "Aligarh",
  cs_shortname: "aligarh",
  cs_code: "s2415",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SATISH KUMAR GAUTAM",
      party: "Bharatiya Janata Party",
      evm_votes: 500453,
      postal_votes: 1381,
      total_votes: 501834,
      percentage_of_votes: 44.28,
    },
    {
      serial_no: 2,
      candidate: "BIJENDRA SINGH",
      party: "Samajwadi Party",
      evm_votes: 485588,
      postal_votes: 599,
      total_votes: 486187,
      percentage_of_votes: 42.9,
    },
    {
      serial_no: 3,
      candidate: "HITENDRA KUMAR ALIAS BUNTY UPADHYAY",
      party: "Bahujan Samaj Party",
      evm_votes: 123770,
      postal_votes: 159,
      total_votes: 123929,
      percentage_of_votes: 10.93,
    },
    {
      serial_no: 4,
      candidate: "GYANI RAM",
      party: "Independent",
      evm_votes: 3795,
      postal_votes: 4,
      total_votes: 3799,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 5,
      candidate: "SATISH KUMAR",
      party: "Independent",
      evm_votes: 3553,
      postal_votes: 8,
      total_votes: 3561,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 6,
      candidate: "SATISH KUMAR",
      party: "Swatantra Jantaraj Party",
      evm_votes: 1647,
      postal_votes: 1,
      total_votes: 1648,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 7,
      candidate: "MANOJ KUMAR ALIAS MANOJ LODHI",
      party: "Saman Adhikar Party",
      evm_votes: 1434,
      postal_votes: 6,
      total_votes: 1440,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 8,
      candidate: "KESHAV DEV ALIAS PANDIT KESHAV DEV GAUTAM",
      party: "Independent",
      evm_votes: 1308,
      postal_votes: 11,
      total_votes: 1319,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 9,
      candidate: "SURENDRA",
      party: "Moulik Adhikar Party",
      evm_votes: 1175,
      postal_votes: 3,
      total_votes: 1178,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 10,
      candidate: "RAJESH KUMAR",
      party: "Independent",
      evm_votes: 1053,
      postal_votes: 3,
      total_votes: 1056,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 11,
      candidate: "DILEEP SHARMA AD.",
      party: "Independent",
      evm_votes: 952,
      postal_votes: 3,
      total_votes: 955,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "MAHESH CHANDRA SHARMA",
      party: "Independent",
      evm_votes: 733,
      postal_votes: 3,
      total_votes: 736,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "RAJ KUMAR",
      party: "Rashtriya Shoshit Samaj Party",
      evm_votes: 407,
      postal_votes: 5,
      total_votes: 412,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 14,
      candidate: "MANOJ KUMAR SHARMA ADVOCATE",
      party: "Loktantrik Janshakti Party",
      evm_votes: 371,
      postal_votes: 7,
      total_votes: 378,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4916,
      postal_votes: 18,
      total_votes: 4934,
      percentage_of_votes: 0.44,
    },
  ],
  total: {
    evm_votes: 1131155,
    postal_votes: 2211,
    total_votes: 1133366,
  },
};
export const s2416 = {
  cs_name: "Hathras",
  cs_shortname: "hathras",
  cs_code: "s2416",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ANOOP PRADHAN BALMIKI",
      party: "Bharatiya Janata Party",
      evm_votes: 552334,
      postal_votes: 2412,
      total_votes: 554746,
      percentage_of_votes: 51.24,
    },
    {
      serial_no: 2,
      candidate: "JASVEER VALMIKI",
      party: "Samajwadi Party",
      evm_votes: 306637,
      postal_votes: 791,
      total_votes: 307428,
      percentage_of_votes: 28.39,
    },
    {
      serial_no: 3,
      candidate: "HEMBABOO DHANGAR",
      party: "Bahujan Samaj Party",
      evm_votes: 200876,
      postal_votes: 387,
      total_votes: 201263,
      percentage_of_votes: 18.59,
    },
    {
      serial_no: 4,
      candidate: "JAYPAL MAHOR",
      party: "Independent",
      evm_votes: 3044,
      postal_votes: 17,
      total_votes: 3061,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 5,
      candidate: "RAJPAL SINGH",
      party: "Independent",
      evm_votes: 2361,
      postal_votes: 11,
      total_votes: 2372,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 6,
      candidate: "GHANSHYAM SINGH",
      party: "Swaraj Bhartiya Nyay Party",
      evm_votes: 2329,
      postal_votes: 16,
      total_votes: 2345,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 7,
      candidate: "RAVI KUMAR",
      party: "Independent",
      evm_votes: 1638,
      postal_votes: 7,
      total_votes: 1645,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 8,
      candidate: "DR. JAYVEER SINGH DHANGAR",
      party: "Rashtra Uday Party",
      evm_votes: 1591,
      postal_votes: 16,
      total_votes: 1607,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 9,
      candidate: "DINESH SAI",
      party: "Independent",
      evm_votes: 1140,
      postal_votes: 4,
      total_votes: 1144,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "MUNNALAL JATAV",
      party: "Independent",
      evm_votes: 803,
      postal_votes: 7,
      total_votes: 810,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6257,
      postal_votes: 42,
      total_votes: 6299,
      percentage_of_votes: 0.58,
    },
  ],
  total: {
    evm_votes: 1079010,
    postal_votes: 3710,
    total_votes: 1082720,
  },
};
export const s2417 = {
  cs_name: "Mathura",
  cs_shortname: "mathura",
  cs_code: "s2417",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "HEMAMALINI DHARMENDRA DEOL",
      party: "Bharatiya Janata Party",
      evm_votes: 507535,
      postal_votes: 2529,
      total_votes: 510064,
      percentage_of_votes: 53.3,
    },
    {
      serial_no: 2,
      candidate: "MUKESH DHANGAR",
      party: "Indian National Congress",
      evm_votes: 216043,
      postal_votes: 614,
      total_votes: 216657,
      percentage_of_votes: 22.64,
    },
    {
      serial_no: 3,
      candidate: "SURESH SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 188152,
      postal_votes: 265,
      total_votes: 188417,
      percentage_of_votes: 19.69,
    },
    {
      serial_no: 4,
      candidate: "BHANU PRATAP SINGH",
      party: "Independent",
      evm_votes: 15640,
      postal_votes: 25,
      total_votes: 15665,
      percentage_of_votes: 1.64,
    },
    {
      serial_no: 5,
      candidate: "RAKESH KUMAR",
      party: "Independent",
      evm_votes: 4492,
      postal_votes: 2,
      total_votes: 4494,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 6,
      candidate: "JAGDISH PRASAD KAUSHIK ADVOCATE",
      party: "Rashtriya Samta Vikas Party",
      evm_votes: 3567,
      postal_votes: 8,
      total_votes: 3575,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 7,
      candidate: "RAVI VERMA",
      party: "Independent",
      evm_votes: 2724,
      postal_votes: 0,
      total_votes: 2724,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 8,
      candidate: "SHIKHA SHARMA",
      party: "Independent",
      evm_votes: 2246,
      postal_votes: 5,
      total_votes: 2251,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 9,
      candidate: "KAMAL KANT SHARMA",
      party: "Independent",
      evm_votes: 1491,
      postal_votes: 12,
      total_votes: 1503,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 10,
      candidate: "YOGESH KUMAR TALAN",
      party: "Independent",
      evm_votes: 1491,
      postal_votes: 2,
      total_votes: 1493,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 11,
      candidate: "MONI FALHARI BAPU",
      party: "Independent",
      evm_votes: 1322,
      postal_votes: 4,
      total_votes: 1326,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 12,
      candidate: "KSHETRA PAL SINGH",
      party: "Independent",
      evm_votes: 1206,
      postal_votes: 5,
      total_votes: 1211,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 13,
      candidate: "DR. RASHMI YADAV",
      party: "Independent",
      evm_votes: 1183,
      postal_votes: 6,
      total_votes: 1189,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "PRAVESHANAND PURI",
      party: "Independent",
      evm_votes: 1081,
      postal_votes: 4,
      total_votes: 1085,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 15,
      candidate: "SURESH CHANDRA VAGHEL",
      party: "Rashtriya Shoshit Samaj Party",
      evm_votes: 822,
      postal_votes: 7,
      total_votes: 829,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4527,
      postal_votes: 36,
      total_votes: 4563,
      percentage_of_votes: 0.48,
    },
  ],
  total: {
    evm_votes: 953522,
    postal_votes: 3524,
    total_votes: 957046,
  },
};
export const s2418 = {
  cs_name: "Agra",
  cs_shortname: "agra",
  cs_code: "s2418",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "PROF S P SINGH BAGHEL",
      party: "Bharatiya Janata Party",
      evm_votes: 597061,
      postal_votes: 2336,
      total_votes: 599397,
      percentage_of_votes: 53.34,
    },
    {
      serial_no: 2,
      candidate: "SURESH CHAND KARDAM",
      party: "Samajwadi Party",
      evm_votes: 326644,
      postal_votes: 1459,
      total_votes: 328103,
      percentage_of_votes: 29.2,
    },
    {
      serial_no: 3,
      candidate: "POOJA AMROHI",
      party: "Bahujan Samaj Party",
      evm_votes: 175810,
      postal_votes: 664,
      total_votes: 176474,
      percentage_of_votes: 15.7,
    },
    {
      serial_no: 4,
      candidate: "ARAM SINGH",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 4130,
      postal_votes: 13,
      total_votes: 4143,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 5,
      candidate: "KULDEEP KUMAR",
      party: "Bharatiya Kisan Parivartan Party",
      evm_votes: 1997,
      postal_votes: 5,
      total_votes: 2002,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 6,
      candidate: "MAHENDRA SINGH",
      party: "Independent",
      evm_votes: 1779,
      postal_votes: 6,
      total_votes: 1785,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 7,
      candidate: "POOJA",
      party: "Independent",
      evm_votes: 1363,
      postal_votes: 7,
      total_votes: 1370,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 8,
      candidate: "SARVESH KUMAR",
      party: "Bharatiya Majdoor Janta Party",
      evm_votes: 1123,
      postal_votes: 10,
      total_votes: 1133,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 9,
      candidate: "AMBEDAKARI HASANURAM AMBEDAKARI",
      party: "Independent",
      evm_votes: 927,
      postal_votes: 6,
      total_votes: 933,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 10,
      candidate: "JITENDRA GAUTAM",
      party: "Lokpriya Rashtrawadi Party",
      evm_votes: 780,
      postal_votes: 2,
      total_votes: 782,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "CHANDRA PAL",
      party: "Adarsh Samaj Party",
      evm_votes: 640,
      postal_votes: 3,
      total_votes: 643,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6929,
      postal_votes: 85,
      total_votes: 7014,
      percentage_of_votes: 0.62,
    },
  ],
  total: {
    evm_votes: 1119183,
    postal_votes: 4596,
    total_votes: 1123779,
  },
};
export const s2419 = {
  cs_name: "Fatehpur_Sikri",
  cs_shortname: "fatehpursikri",
  cs_code: "s2419",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAJKUMAR CHAHAR",
      party: "Bharatiya Janata Party",
      evm_votes: 442862,
      postal_votes: 2795,
      total_votes: 445657,
      percentage_of_votes: 43.09,
    },
    {
      serial_no: 2,
      candidate: "RAMNATH SINGH SIKARWAR",
      party: "Indian National Congress",
      evm_votes: 400572,
      postal_votes: 1680,
      total_votes: 402252,
      percentage_of_votes: 38.9,
    },
    {
      serial_no: 3,
      candidate: "PT. RAMNIWAS SHARMA",
      party: "Bahujan Samaj Party",
      evm_votes: 120143,
      postal_votes: 396,
      total_votes: 120539,
      percentage_of_votes: 11.66,
    },
    {
      serial_no: 4,
      candidate: "DR RAMESHWAR SINGH",
      party: "Independent",
      evm_votes: 48301,
      postal_votes: 305,
      total_votes: 48606,
      percentage_of_votes: 4.7,
    },
    {
      serial_no: 5,
      candidate: "KALLAN KUMBHKAR",
      party: "Independent",
      evm_votes: 3458,
      postal_votes: 5,
      total_votes: 3463,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 6,
      candidate: "HOTAM SINGH NISHAD",
      party: "Rashtriya Shoshit Samaj Party",
      evm_votes: 1805,
      postal_votes: 5,
      total_votes: 1810,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 7,
      candidate: "GIRRAJ SINGH DHAKREY",
      party: "Proutist Bloc, India",
      evm_votes: 1556,
      postal_votes: 8,
      total_votes: 1564,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 8,
      candidate: "SANGEETA TOMAR",
      party: "Bharatiya Majdoor Janta Party",
      evm_votes: 1417,
      postal_votes: 16,
      total_votes: 1433,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 9,
      candidate: "VED PRAKASH",
      party: "Rashtriya Jansanchar Dal",
      evm_votes: 1031,
      postal_votes: 7,
      total_votes: 1038,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7764,
      postal_votes: 29,
      total_votes: 7793,
      percentage_of_votes: 0.75,
    },
  ],
  total: {
    evm_votes: 1028909,
    postal_votes: 5246,
    total_votes: 1034155,
  },
};
export const s2425 = {
  cs_name: "Bareilly",
  cs_shortname: "bareilly",
  cs_code: "s2425",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "CHHATRA PAL SINGH GANGWAR",
      party: "Bharatiya Janata Party",
      evm_votes: 565600,
      postal_votes: 1527,
      total_votes: 567127,
      percentage_of_votes: 50.66,
    },
    {
      serial_no: 2,
      candidate: "PRAVEEN SINGH ARON",
      party: "Samajwadi Party",
      evm_votes: 530825,
      postal_votes: 1498,
      total_votes: 532323,
      percentage_of_votes: 47.55,
    },
    {
      serial_no: 3,
      candidate: "IRSHAD ANSARI ADVOCATE",
      party: "Peace Party",
      evm_votes: 3548,
      postal_votes: 5,
      total_votes: 3553,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 4,
      candidate: "WASEEM MIAN",
      party: "Independent",
      evm_votes: 2839,
      postal_votes: 0,
      total_votes: 2839,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 5,
      candidate: "BHUPENDRA KUMAR MAURYA",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 1702,
      postal_votes: 2,
      total_votes: 1704,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 6,
      candidate: "BUDDH PRIYA KARMRAJ RAHUL",
      party: "Independent",
      evm_votes: 1192,
      postal_votes: 2,
      total_votes: 1194,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 7,
      candidate: "ROHTASH KASHYAP",
      party: "Jan Shakti Ekta Party",
      evm_votes: 844,
      postal_votes: 3,
      total_votes: 847,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 8,
      candidate: "JAGPAL SINGH YADAV",
      party: "Samrat Mihir Bhoj Samaj Party",
      evm_votes: 654,
      postal_votes: 2,
      total_votes: 656,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 9,
      candidate: "RAVI KUMAR",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 626,
      postal_votes: 1,
      total_votes: 627,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 10,
      candidate: "MOHD NAJIM ALI",
      party: "Bharat Jodo Party",
      evm_votes: 624,
      postal_votes: 9,
      total_votes: 633,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 11,
      candidate: "NITIN MOHAN",
      party: "Independent",
      evm_votes: 619,
      postal_votes: 1,
      total_votes: 620,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "ASHISH GANGWAR",
      party: "Independent",
      evm_votes: 616,
      postal_votes: 0,
      total_votes: 616,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "AYSHA BI",
      party: "Independent",
      evm_votes: 550,
      postal_votes: 9,
      total_votes: 559,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6226,
      postal_votes: 34,
      total_votes: 6260,
      percentage_of_votes: 0.56,
    },
  ],
  total: {
    evm_votes: 1116465,
    postal_votes: 3093,
    total_votes: 1119558,
  },
};
export const s2426 = {
  cs_name: "Pilibhit",
  cs_shortname: "pilibhit",
  cs_code: "s2426",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "JITIN PRASADA",
      party: "Bharatiya Janata Party",
      evm_votes: 605199,
      postal_votes: 1959,
      total_votes: 607158,
      percentage_of_votes: 52.3,
    },
    {
      serial_no: 2,
      candidate: "BHAGWAT SARAN GANGWAR",
      party: "Samajwadi Party",
      evm_votes: 439735,
      postal_votes: 2488,
      total_votes: 442223,
      percentage_of_votes: 38.09,
    },
    {
      serial_no: 3,
      candidate: "ANIS AHMED KHAN (PHOOL BABU)",
      party: "Bahujan Samaj Party",
      evm_votes: 89252,
      postal_votes: 445,
      total_votes: 89697,
      percentage_of_votes: 7.73,
    },
    {
      serial_no: 4,
      candidate: "RAJEEV KUMAR SAXENA",
      party: "Bhartiya Krishak Dal",
      evm_votes: 3800,
      postal_votes: 9,
      total_votes: 3809,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 5,
      candidate: "SUSHIL KUMAR SHUKLA",
      party: "Independent",
      evm_votes: 2723,
      postal_votes: 4,
      total_votes: 2727,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 6,
      candidate: "MOHMMAD SHAHID HUSAIN",
      party: "Independent",
      evm_votes: 1842,
      postal_votes: 4,
      total_votes: 1846,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 7,
      candidate: "ASHISH KUMAR",
      party: "Independent",
      evm_votes: 1842,
      postal_votes: 8,
      total_votes: 1850,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 8,
      candidate: "ADARSH PANDEY",
      party: "Independent",
      evm_votes: 1714,
      postal_votes: 12,
      total_votes: 1726,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 9,
      candidate: "SANJAY KUMAR BHARTI",
      party: "Rashtriya Samaj Dal (R)",
      evm_votes: 1621,
      postal_votes: 10,
      total_votes: 1631,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "PRAMOD BHAI PATEL ALIAS P.K.",
      party: "Independent",
      evm_votes: 1525,
      postal_votes: 14,
      total_votes: 1539,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6714,
      postal_votes: 27,
      total_votes: 6741,
      percentage_of_votes: 0.58,
    },
  ],
  total: {
    evm_votes: 1155967,
    postal_votes: 4980,
    total_votes: 1160947,
  },
};
export const s2427 = {
  cs_name: "Shahjahanpur",
  cs_shortname: "shahjahanpur",
  cs_code: "s2427",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ARUN KUMAR SAGAR",
      party: "Bharatiya Janata Party",
      evm_votes: 591500,
      postal_votes: 1218,
      total_votes: 592718,
      percentage_of_votes: 47.5,
    },
    {
      serial_no: 2,
      candidate: "JYOTSNA GOND",
      party: "Samajwadi Party",
      evm_votes: 534720,
      postal_votes: 2619,
      total_votes: 537339,
      percentage_of_votes: 43.06,
    },
    {
      serial_no: 3,
      candidate: "DOD RAM VERMA",
      party: "Bahujan Samaj Party",
      evm_votes: 91302,
      postal_votes: 408,
      total_votes: 91710,
      percentage_of_votes: 7.35,
    },
    {
      serial_no: 4,
      candidate: "KIRAN",
      party: "Rashtriya Sanatan Party",
      evm_votes: 6587,
      postal_votes: 1,
      total_votes: 6588,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 5,
      candidate: "SHIV KUMAR",
      party: "Independent",
      evm_votes: 2805,
      postal_votes: 0,
      total_votes: 2805,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 6,
      candidate: "MEENA KASYAP",
      party: "Independent",
      evm_votes: 2051,
      postal_votes: 10,
      total_votes: 2061,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 7,
      candidate: "PRADEEP KUMAR",
      party: "Independent",
      evm_votes: 1996,
      postal_votes: 9,
      total_votes: 2005,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 8,
      candidate: "PREMCHANDRA HARIJAN",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 1686,
      postal_votes: 1,
      total_votes: 1687,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 9,
      candidate: "DHARM PAL",
      party: "Independent",
      evm_votes: 1413,
      postal_votes: 10,
      total_votes: 1423,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "RAMESH CHANDRA VERMA",
      party: "Manav Kranti Party",
      evm_votes: 1076,
      postal_votes: 0,
      total_votes: 1076,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8449,
      postal_votes: 41,
      total_votes: 8490,
      percentage_of_votes: 0.68,
    },
  ],
  total: {
    evm_votes: 1243585,
    postal_votes: 4317,
    total_votes: 1247902,
  },
};
export const s2431 = {
  cs_name: "Hardoi",
  cs_shortname: "hardoi",
  cs_code: "s2431",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "JAI PRAKASH",
      party: "Bharatiya Janata Party",
      evm_votes: 485745,
      postal_votes: 1053,
      total_votes: 486798,
      percentage_of_votes: 44.25,
    },
    {
      serial_no: 2,
      candidate: "USHA VERMA",
      party: "Samajwadi Party",
      evm_votes: 457553,
      postal_votes: 1389,
      total_votes: 458942,
      percentage_of_votes: 41.72,
    },
    {
      serial_no: 3,
      candidate: "BHIMRAO AMBEDKAR",
      party: "Bahujan Samaj Party",
      evm_votes: 122334,
      postal_votes: 295,
      total_votes: 122629,
      percentage_of_votes: 11.15,
    },
    {
      serial_no: 4,
      candidate: "SHIVE KUMAR",
      party: "Independent",
      evm_votes: 4897,
      postal_votes: 1,
      total_votes: 4898,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 5,
      candidate: "SHYAM KUMAR",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 4196,
      postal_votes: 4,
      total_votes: 4200,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 6,
      candidate: "SUNIL KUMAR VERMA",
      party: "Independent",
      evm_votes: 3819,
      postal_votes: 2,
      total_votes: 3821,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 7,
      candidate: "INDRAPAL PASI",
      party: "Justice Party",
      evm_votes: 2945,
      postal_votes: 1,
      total_votes: 2946,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 8,
      candidate: "MUNNA LAL",
      party: "Naari Nar Rakshak Party",
      evm_votes: 1588,
      postal_votes: 1,
      total_votes: 1589,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 9,
      candidate: "OM PRAKASH",
      party: "Independent",
      evm_votes: 1559,
      postal_votes: 2,
      total_votes: 1561,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "AWADESH KUMAR",
      party: "Independent",
      evm_votes: 1420,
      postal_votes: 4,
      total_votes: 1424,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "PRADEEP KUMAR",
      party: "Bhartiya Krishak Dal",
      evm_votes: 1349,
      postal_votes: 5,
      total_votes: 1354,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "BRAHMA PRASAD",
      party: "Lok Jan Sangharsh Party",
      evm_votes: 1139,
      postal_votes: 1,
      total_votes: 1140,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8778,
      postal_votes: 36,
      total_votes: 8814,
      percentage_of_votes: 0.8,
    },
  ],
  total: {
    evm_votes: 1097322,
    postal_votes: 2794,
    total_votes: 1100116,
  },
};
export const s2432 = {
  cs_name: "Misrikh",
  cs_shortname: "misrikh",
  cs_code: "s2432",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ASHOK KUMAR RAWAT",
      party: "Bharatiya Janata Party",
      evm_votes: 473971,
      postal_votes: 1045,
      total_votes: 475016,
      percentage_of_votes: 45.15,
    },
    {
      serial_no: 2,
      candidate: "SANGITA RAJVANSHI",
      party: "Samajwadi Party",
      evm_votes: 440157,
      postal_votes: 1453,
      total_votes: 441610,
      percentage_of_votes: 41.98,
    },
    {
      serial_no: 3,
      candidate: "B R AHIRWAR",
      party: "Bahujan Samaj Party",
      evm_votes: 111670,
      postal_votes: 275,
      total_votes: 111945,
      percentage_of_votes: 10.64,
    },
    {
      serial_no: 4,
      candidate: "RAMPAL",
      party: "Independent",
      evm_votes: 3973,
      postal_votes: 5,
      total_votes: 3978,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 5,
      candidate: "LALIT KUMAR",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 3811,
      postal_votes: 4,
      total_votes: 3815,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 6,
      candidate: "Neelam Singh",
      party: "Independent",
      evm_votes: 2899,
      postal_votes: 6,
      total_votes: 2905,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 7,
      candidate: "VANDNA VERMA",
      party: "Naari Nar Rakshak Party",
      evm_votes: 1907,
      postal_votes: 5,
      total_votes: 1912,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "AVDHESH",
      party: "Independent",
      evm_votes: 1419,
      postal_votes: 4,
      total_votes: 1423,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 9,
      candidate: "SAVITRI DEVI",
      party: "Rashtriya Janshakti Samaj Party",
      evm_votes: 1347,
      postal_votes: 3,
      total_votes: 1350,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7997,
      postal_votes: 32,
      total_votes: 8029,
      percentage_of_votes: 0.76,
    },
  ],
  total: {
    evm_votes: 1049151,
    postal_votes: 2832,
    total_votes: 1051983,
  },
};
export const s2433 = {
  cs_name: "Unnao",
  cs_shortname: "unnao",
  cs_code: "s2433",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SWAMI SACHCHIDANAND HARI SAKSHI",
      party: "Bharatiya Janata Party",
      evm_votes: 614832,
      postal_votes: 1301,
      total_votes: 616133,
      percentage_of_votes: 47.31,
    },
    {
      serial_no: 2,
      candidate: "ANNU TANDON",
      party: "Samajwadi Party",
      evm_votes: 577699,
      postal_votes: 2616,
      total_votes: 580315,
      percentage_of_votes: 44.56,
    },
    {
      serial_no: 3,
      candidate: "ASHOK KUMAR PANDEY",
      party: "Bahujan Samaj Party",
      evm_votes: 72245,
      postal_votes: 282,
      total_votes: 72527,
      percentage_of_votes: 5.57,
    },
    {
      serial_no: 4,
      candidate: "SAIF KHAN",
      party: "Parcham Party of India",
      evm_votes: 8390,
      postal_votes: 14,
      total_votes: 8404,
      percentage_of_votes: 0.65,
    },
    {
      serial_no: 5,
      candidate: "UMESH",
      party: "Independent",
      evm_votes: 5625,
      postal_votes: 10,
      total_votes: 5635,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 6,
      candidate: "SHIV PRAKASH SINGH",
      party: "Independent",
      evm_votes: 4107,
      postal_votes: 11,
      total_votes: 4118,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 7,
      candidate: "SAIYYAD SARFARAZ GANDHI",
      party: "Independent",
      evm_votes: 3018,
      postal_votes: 5,
      total_votes: 3023,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 8,
      candidate: "HIMANSHU SHARMA",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 2656,
      postal_votes: 7,
      total_votes: 2663,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9430,
      postal_votes: 23,
      total_votes: 9453,
      percentage_of_votes: 0.73,
    },
  ],
  total: {
    evm_votes: 1298002,
    postal_votes: 4269,
    total_votes: 1302271,
  },
};
export const s2435 = {
  cs_name: "Lucknow",
  cs_shortname: "lucknow",
  cs_code: "s2435",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAJ NATH SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 610597,
      postal_votes: 2112,
      total_votes: 612709,
      percentage_of_votes: 53.89,
    },
    {
      serial_no: 2,
      candidate: "RAVIDAS MEHROTRA",
      party: "Samajwadi Party",
      evm_votes: 476303,
      postal_votes: 1247,
      total_votes: 477550,
      percentage_of_votes: 42,
    },
    {
      serial_no: 3,
      candidate: "MOHAMMAD SARWAR MALIK",
      party: "Bahujan Samaj Party",
      evm_votes: 29982,
      postal_votes: 210,
      total_votes: 30192,
      percentage_of_votes: 2.66,
    },
    {
      serial_no: 4,
      candidate: "MO AHMAD URF RINKU",
      party: "Social Democratic Party Of India",
      evm_votes: 3251,
      postal_votes: 4,
      total_votes: 3255,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 5,
      candidate: "AKHAND PRATAP SINGH",
      party: "Independent",
      evm_votes: 1465,
      postal_votes: 8,
      total_votes: 1473,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 6,
      candidate: "SARVAR ALI",
      party: "Sarvar Party",
      evm_votes: 1199,
      postal_votes: 3,
      total_votes: 1202,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 7,
      candidate: "ISHTIYAQ ALI",
      party: "Independent",
      evm_votes: 929,
      postal_votes: 5,
      total_votes: 934,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 8,
      candidate: "KAPIL MOHAN CHOWDHARY",
      party: "Mera Adhikaar Rashtriya Dal",
      evm_votes: 815,
      postal_votes: 2,
      total_votes: 817,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 9,
      candidate: "GAURAV VERMA",
      party: "Hindu Samaj Party",
      evm_votes: 801,
      postal_votes: 6,
      total_votes: 807,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 10,
      candidate: "BRIJESH KUMAR YADAV",
      party: "Kisan Vishwa Party",
      evm_votes: 601,
      postal_votes: 2,
      total_votes: 603,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7316,
      postal_votes: 34,
      total_votes: 7350,
      percentage_of_votes: 0.65,
    },
  ],
  total: {
    evm_votes: 1133259,
    postal_votes: 3633,
    total_votes: 1136892,
  },
};
export const s2440 = {
  cs_name: "Farrukhabad",
  cs_shortname: "farrukhabad",
  cs_code: "s2440",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "MUKESH RAJPUT",
      party: "Bharatiya Janata Party",
      evm_votes: 486925,
      postal_votes: 1038,
      total_votes: 487963,
      percentage_of_votes: 47.2,
    },
    {
      serial_no: 2,
      candidate: "DR. NAVAL KISHOR SHAKYA",
      party: "Samajwadi Party",
      evm_votes: 484485,
      postal_votes: 800,
      total_votes: 485285,
      percentage_of_votes: 46.94,
    },
    {
      serial_no: 3,
      candidate: "KRANTI PANDEY",
      party: "Bahujan Samaj Party",
      evm_votes: 45274,
      postal_votes: 116,
      total_votes: 45390,
      percentage_of_votes: 4.39,
    },
    {
      serial_no: 4,
      candidate: "AMAR SINGH",
      party: "Bharatiya Rashtriya Morcha",
      evm_votes: 3345,
      postal_votes: 13,
      total_votes: 3358,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 5,
      candidate: "HARNANDAN SINGH",
      party: "Independent",
      evm_votes: 2457,
      postal_votes: 12,
      total_votes: 2469,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 6,
      candidate: "SHYAMVEER SINGH",
      party: "Bharatiya Nagrik Party",
      evm_votes: 2431,
      postal_votes: 3,
      total_votes: 2434,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 7,
      candidate: "VIDYAPRAKASH",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 1456,
      postal_votes: 11,
      total_votes: 1467,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 8,
      candidate: "DINESH",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 1061,
      postal_votes: 2,
      total_votes: 1063,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4350,
      postal_votes: 15,
      total_votes: 4365,
      percentage_of_votes: 0.42,
    },
  ],
  total: {
    evm_votes: 1031784,
    postal_votes: 2010,
    total_votes: 1033794,
  },
};
export const s2443 = {
  cs_name: "Kanpur",
  cs_shortname: "kanpur",
  cs_code: "s2443",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAMESH AWASTHI",
      party: "Bharatiya Janata Party",
      evm_votes: 440609,
      postal_votes: 2446,
      total_votes: 443055,
      percentage_of_votes: 49.93,
    },
    {
      serial_no: 2,
      candidate: "ALOK MISRA",
      party: "Indian National Congress",
      evm_votes: 419469,
      postal_votes: 2618,
      total_votes: 422087,
      percentage_of_votes: 47.56,
    },
    {
      serial_no: 3,
      candidate: "KULDEEP BHADAURIYA",
      party: "Bahujan Samaj Party",
      evm_votes: 11843,
      postal_votes: 189,
      total_votes: 12032,
      percentage_of_votes: 1.36,
    },
    {
      serial_no: 4,
      candidate: "ASHOK PASWAN ADVOCATE",
      party: "Sabhi Jan Party",
      evm_votes: 1969,
      postal_votes: 5,
      total_votes: 1974,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 5,
      candidate: "ALOK MISHRA",
      party: "Independent",
      evm_votes: 1186,
      postal_votes: 12,
      total_votes: 1198,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 6,
      candidate: "MANOJ KUMAR",
      party: "Independent",
      evm_votes: 776,
      postal_votes: 2,
      total_votes: 778,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 7,
      candidate: "AJAY KUMAR MISHRA",
      party: "Independent",
      evm_votes: 671,
      postal_votes: 1,
      total_votes: 672,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 8,
      candidate: "PRASHAST DHEER",
      party: "All India Forward Bloc",
      evm_votes: 462,
      postal_votes: 3,
      total_votes: 465,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 9,
      candidate: "ARVIND KUMAR SRIVASTAVA",
      party: "Independent",
      evm_votes: 394,
      postal_votes: 4,
      total_votes: 398,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 10,
      candidate: "SANJAY SINGH",
      party: "Proutist Bloc, India",
      evm_votes: 348,
      postal_votes: 2,
      total_votes: 350,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 11,
      candidate: "VALENDRA KATIYAR",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 267,
      postal_votes: 3,
      total_votes: 270,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4048,
      postal_votes: 69,
      total_votes: 4117,
      percentage_of_votes: 0.46,
    },
  ],
  total: {
    evm_votes: 882042,
    postal_votes: 5354,
    total_votes: 887396,
  },
};
export const s2444 = {
  cs_name: "Akbarpur",
  cs_shortname: "akbarpur",
  cs_code: "s2444",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DEVENDRA SINGH ALIAS BHOLE SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 514256,
      postal_votes: 3167,
      total_votes: 517423,
      percentage_of_votes: 47.6,
    },
    {
      serial_no: 2,
      candidate: "RAJARAM PAL",
      party: "Samajwadi Party",
      evm_votes: 469766,
      postal_votes: 3312,
      total_votes: 473078,
      percentage_of_votes: 43.52,
    },
    {
      serial_no: 3,
      candidate: "RAJESH KUMAR DWIVEDI",
      party: "Bahujan Samaj Party",
      evm_votes: 72679,
      postal_votes: 461,
      total_votes: 73140,
      percentage_of_votes: 6.73,
    },
    {
      serial_no: 4,
      candidate: "RAJARAM",
      party: "Independent",
      evm_votes: 4629,
      postal_votes: 13,
      total_votes: 4642,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 5,
      candidate: "ASHOK PASWAN ADVOCATE",
      party: "Sabhi Jan Party",
      evm_votes: 3160,
      postal_votes: 13,
      total_votes: 3173,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 6,
      candidate: "CHANDRESH SINGH",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 2641,
      postal_votes: 13,
      total_votes: 2654,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 7,
      candidate: "YOGESH JAISWAL",
      party: "Independent",
      evm_votes: 2529,
      postal_votes: 14,
      total_votes: 2543,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 8,
      candidate: "VIPIN KUMAR",
      party: "Rashtriya Sanskriti Party",
      evm_votes: 1489,
      postal_votes: 5,
      total_votes: 1494,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 9,
      candidate: "RAMGOPAL",
      party: "Rashtriya Janutthan Party",
      evm_votes: 1146,
      postal_votes: 11,
      total_votes: 1157,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7523,
      postal_votes: 126,
      total_votes: 7649,
      percentage_of_votes: 0.7,
    },
  ],
  total: {
    evm_votes: 1079818,
    postal_votes: 7135,
    total_votes: 1086953,
  },
};
export const s2446 = {
  cs_name: "Jhansi",
  cs_shortname: "jhansi",
  cs_code: "s2446",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ANURAG SHARMA",
      party: "Bharatiya Janata Party",
      evm_votes: 689438,
      postal_votes: 878,
      total_votes: 690316,
      percentage_of_votes: 50,
    },
    {
      serial_no: 2,
      candidate: "PRADEEP JAIN ADITYA",
      party: "Indian National Congress",
      evm_votes: 587000,
      postal_votes: 702,
      total_votes: 587702,
      percentage_of_votes: 42.57,
    },
    {
      serial_no: 3,
      candidate: "RAVI PRAKASH",
      party: "Bahujan Samaj Party",
      evm_votes: 63106,
      postal_votes: 86,
      total_votes: 63192,
      percentage_of_votes: 4.58,
    },
    {
      serial_no: 4,
      candidate: "LAKHAN LAL",
      party: "Independent",
      evm_votes: 6832,
      postal_votes: 0,
      total_votes: 6832,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 5,
      candidate: "RAMESH",
      party: "Independent",
      evm_votes: 6330,
      postal_votes: 8,
      total_votes: 6338,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 6,
      candidate: "GANESH RAM",
      party: "Independent",
      evm_votes: 2574,
      postal_votes: 1,
      total_votes: 2575,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 7,
      candidate: "CHANDAN SINGH",
      party: "Apna Dal (Kamerawadi)",
      evm_votes: 2487,
      postal_votes: 4,
      total_votes: 2491,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "DHARMENDRA PRATAP",
      party: "Independent",
      evm_votes: 2299,
      postal_votes: 4,
      total_votes: 2303,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 9,
      candidate: "INDRA SINGH",
      party: "Independent",
      evm_votes: 2076,
      postal_votes: 2,
      total_votes: 2078,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "ER. DEEPAK KUMAR VERMA",
      party: "Al-Hind Party",
      evm_votes: 1375,
      postal_votes: 2,
      total_votes: 1377,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 15290,
      postal_votes: 12,
      total_votes: 15302,
      percentage_of_votes: 1.11,
    },
  ],
  total: {
    evm_votes: 1378807,
    postal_votes: 1699,
    total_votes: 1380506,
  },
};
export const s2451 = {
  cs_name: "Phulpur",
  cs_shortname: "phulpur",
  cs_code: "s2451",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "PRAVEEN PATEL",
      party: "Bharatiya Janata Party",
      evm_votes: 451380,
      postal_votes: 1220,
      total_votes: 452600,
      percentage_of_votes: 44.6,
    },
    {
      serial_no: 2,
      candidate: "AMAR NATH SINGH MAURYA",
      party: "Samajwadi Party",
      evm_votes: 446596,
      postal_votes: 1672,
      total_votes: 448268,
      percentage_of_votes: 44.17,
    },
    {
      serial_no: 3,
      candidate: "JAGANNATH PAL",
      party: "Bahujan Samaj Party",
      evm_votes: 82375,
      postal_votes: 211,
      total_votes: 82586,
      percentage_of_votes: 8.14,
    },
    {
      serial_no: 4,
      candidate: "MAHIMA PATEL",
      party: "Apna Dal (Kamerawadi)",
      evm_votes: 4134,
      postal_votes: 28,
      total_votes: 4162,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 5,
      candidate: "JILA JEET BHARTIY",
      party: "Bahujan Awam Party",
      evm_votes: 3294,
      postal_votes: 5,
      total_votes: 3299,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 6,
      candidate: "SUNIL KUMAR PRAJAPATI",
      party: "Bhagidari Party(P)",
      evm_votes: 3040,
      postal_votes: 7,
      total_votes: 3047,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 7,
      candidate: "ENGG YOGESH KUSHWAHA",
      party: "Pragatisheel Samaj Party",
      evm_votes: 2768,
      postal_votes: 3,
      total_votes: 2771,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 8,
      candidate: "AKHILESH TRIPATHI",
      party: "Independent",
      evm_votes: 2295,
      postal_votes: 3,
      total_votes: 2298,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 9,
      candidate: "NAFIS AHAMAD",
      party: "Independent",
      evm_votes: 2145,
      postal_votes: 2,
      total_votes: 2147,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 10,
      candidate: "DR NEERAJ",
      party: "Independent",
      evm_votes: 1702,
      postal_votes: 8,
      total_votes: 1710,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 11,
      candidate: "SANJEEV KUMAR MISHRA",
      party: "Yuva Vikas Party",
      evm_votes: 1675,
      postal_votes: 4,
      total_votes: 1679,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "MUSHIR AHMAD SIDDIQUI",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 1505,
      postal_votes: 1,
      total_votes: 1506,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 13,
      candidate: "SANGEETA YADAV",
      party: "Subhashwadi Bhartiya Samajwadi Party (Subhas Party)",
      evm_votes: 1283,
      postal_votes: 8,
      total_votes: 1291,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 14,
      candidate: "LALA RAM SAROJ",
      party: "Prabuddhwadi Bahujan Morcha",
      evm_votes: 1043,
      postal_votes: 9,
      total_votes: 1052,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "PRAMOD BHAI PATEL URF P K",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 947,
      postal_votes: 1,
      total_votes: 948,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5445,
      postal_votes: 15,
      total_votes: 5460,
      percentage_of_votes: 0.54,
    },
  ],
  total: {
    evm_votes: 1011627,
    postal_votes: 3197,
    total_votes: 1014824,
  },
};
export const s2456 = {
  cs_name: "Baharaich",
  cs_shortname: "baharaich",
  cs_code: "s2456",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ANAND KUMAR",
      party: "Bharatiya Janata Party",
      evm_votes: 518575,
      postal_votes: 227,
      total_votes: 518802,
      percentage_of_votes: 49.1,
    },
    {
      serial_no: 2,
      candidate: "RAMESH CHANDRA",
      party: "Samajwadi Party",
      evm_votes: 454454,
      postal_votes: 121,
      total_votes: 454575,
      percentage_of_votes: 43.02,
    },
    {
      serial_no: 3,
      candidate: "DR. BRIJESH KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 50422,
      postal_votes: 26,
      total_votes: 50448,
      percentage_of_votes: 4.77,
    },
    {
      serial_no: 4,
      candidate: "ARVIND KUMAR",
      party: "Bhartiya Awam Party (Rastriya)",
      evm_votes: 6158,
      postal_votes: 2,
      total_votes: 6160,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 5,
      candidate: "RAMESH BALMIKI",
      party: "Independent",
      evm_votes: 3223,
      postal_votes: 4,
      total_votes: 3227,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 6,
      candidate: "BECHULAL",
      party: "Independent",
      evm_votes: 2919,
      postal_votes: 1,
      total_votes: 2920,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 7,
      candidate: "JANARDAN GOND",
      party: "Independent",
      evm_votes: 2672,
      postal_votes: 1,
      total_votes: 2673,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 8,
      candidate: "RAM MILAN",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 1699,
      postal_votes: 0,
      total_votes: 1699,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "RINKU SAHANI",
      party: "Rashtra Dharak Dal",
      evm_votes: 1681,
      postal_votes: 2,
      total_votes: 1683,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 10,
      candidate: "JAGRAM",
      party: "Independent",
      evm_votes: 1514,
      postal_votes: 1,
      total_votes: 1515,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12862,
      postal_votes: 2,
      total_votes: 12864,
      percentage_of_votes: 1.22,
    },
  ],
  total: {
    evm_votes: 1056179,
    postal_votes: 387,
    total_votes: 1056566,
  },
};
export const s2457 = {
  cs_name: "Kaiserganj",
  cs_shortname: "kaiserganj",
  cs_code: "s2457",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "KARAN BHUSHAN SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 570436,
      postal_votes: 827,
      total_votes: 571263,
      percentage_of_votes: 53.79,
    },
    {
      serial_no: 2,
      candidate: "BHAGAT RAM",
      party: "Samajwadi Party",
      evm_votes: 422012,
      postal_votes: 408,
      total_votes: 422420,
      percentage_of_votes: 39.77,
    },
    {
      serial_no: 3,
      candidate: "NARENDRA PANDEY",
      party: "Bahujan Samaj Party",
      evm_votes: 44217,
      postal_votes: 62,
      total_votes: 44279,
      percentage_of_votes: 4.17,
    },
    {
      serial_no: 4,
      candidate: "ARUNIMA PANDEY",
      party: "Independent",
      evm_votes: 9230,
      postal_votes: 20,
      total_votes: 9250,
      percentage_of_votes: 0.87,
    },
    {
      serial_no: 5,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 14872,
      postal_votes: 15,
      total_votes: 14887,
      percentage_of_votes: 1.4,
    },
  ],
  total: {
    evm_votes: 1060767,
    postal_votes: 1332,
    total_votes: 1062099,
  },
};
export const s2459 = {
  cs_name: "Gonda",
  cs_shortname: "gonda",
  cs_code: "s2459",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "KIRTIVARDHAN SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 473691,
      postal_votes: 567,
      total_votes: 474258,
      percentage_of_votes: 49.77,
    },
    {
      serial_no: 2,
      candidate: "SHREYA VERMA",
      party: "Samajwadi Party",
      evm_votes: 427363,
      postal_votes: 671,
      total_votes: 428034,
      percentage_of_votes: 44.92,
    },
    {
      serial_no: 3,
      candidate: "SAURABH",
      party: "Bahujan Samaj Party",
      evm_votes: 29388,
      postal_votes: 41,
      total_votes: 29429,
      percentage_of_votes: 3.09,
    },
    {
      serial_no: 4,
      candidate: "RAM UJAGAR",
      party: "Independent",
      evm_votes: 3535,
      postal_votes: 0,
      total_votes: 3535,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 5,
      candidate: "ARUNIMA PANDEY",
      party: "Independent",
      evm_votes: 3070,
      postal_votes: 3,
      total_votes: 3073,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 6,
      candidate: "RAGHAVENDRA",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 2027,
      postal_votes: 1,
      total_votes: 2028,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 7,
      candidate: "RAJKUMAR",
      party: "Independent",
      evm_votes: 1988,
      postal_votes: 1,
      total_votes: 1989,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 8,
      candidate: "VINOD KUMAR SINGH",
      party: "Bhartiya Uday Nirman Party",
      evm_votes: 1482,
      postal_votes: 1,
      total_votes: 1483,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9048,
      postal_votes: 7,
      total_votes: 9055,
      percentage_of_votes: 0.95,
    },
  ],
  total: {
    evm_votes: 951592,
    postal_votes: 1292,
    total_votes: 952884,
  },
};
export const s2460 = {
  cs_name: "Domariyaganj",
  cs_shortname: "domariyaganj",
  cs_code: "s2460",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "JAGDAMBIKA PAL",
      party: "Bharatiya Janata Party",
      evm_votes: 462614,
      postal_votes: 689,
      total_votes: 463303,
      percentage_of_votes: 45.47,
    },
    {
      serial_no: 2,
      candidate: "BHISHMA SHANKAR ALIAS KUSHAL TIWARI",
      party: "Samajwadi Party",
      evm_votes: 418910,
      postal_votes: 1665,
      total_votes: 420575,
      percentage_of_votes: 41.27,
    },
    {
      serial_no: 3,
      candidate: "AMAR SINGH CHAUDHARY",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 81182,
      postal_votes: 123,
      total_votes: 81305,
      percentage_of_votes: 7.98,
    },
    {
      serial_no: 4,
      candidate: "MD. NADEEM",
      party: "Bahujan Samaj Party",
      evm_votes: 35654,
      postal_votes: 282,
      total_votes: 35936,
      percentage_of_votes: 3.53,
    },
    {
      serial_no: 5,
      candidate: "NAUSHAD AZAM",
      party: "Peace Party",
      evm_votes: 5134,
      postal_votes: 4,
      total_votes: 5138,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 6,
      candidate: "KIRAN DEVI",
      party: "Independent",
      evm_votes: 3258,
      postal_votes: 3,
      total_votes: 3261,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 7,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9431,
      postal_votes: 16,
      total_votes: 9447,
      percentage_of_votes: 0.93,
    },
  ],
  total: {
    evm_votes: 1016183,
    postal_votes: 2782,
    total_votes: 1018965,
  },
};
export const s2463 = {
  cs_name: "Maharajganj",
  cs_shortname: "maharajganj",
  cs_code: "s2463",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "PANKAJ CHAUDHARY",
      party: "Bharatiya Janata Party",
      evm_votes: 590487,
      postal_votes: 823,
      total_votes: 591310,
      percentage_of_votes: 48.85,
    },
    {
      serial_no: 2,
      candidate: "VIRENDRA CHAUDHARY",
      party: "Indian National Congress",
      evm_votes: 555045,
      postal_votes: 814,
      total_votes: 555859,
      percentage_of_votes: 45.92,
    },
    {
      serial_no: 3,
      candidate: "MO MAUSME ALAM",
      party: "Bahujan Samaj Party",
      evm_votes: 32893,
      postal_votes: 62,
      total_votes: 32955,
      percentage_of_votes: 2.72,
    },
    {
      serial_no: 4,
      candidate: "BRIJESH",
      party: "Abhay Samaj Party",
      evm_votes: 8575,
      postal_votes: 8,
      total_votes: 8583,
      percentage_of_votes: 0.71,
    },
    {
      serial_no: 5,
      candidate: "CHHEDI MAJDOOR",
      party: "Independent",
      evm_votes: 3833,
      postal_votes: 1,
      total_votes: 3834,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 6,
      candidate: "RAMPRIT",
      party: "Independent",
      evm_votes: 3028,
      postal_votes: 1,
      total_votes: 3029,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 7,
      candidate: "SUNIL",
      party: "Independent",
      evm_votes: 2716,
      postal_votes: 2,
      total_votes: 2718,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 8,
      candidate: "VINOD KUMAR PATEL",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 2415,
      postal_votes: 3,
      total_votes: 2418,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9730,
      postal_votes: 15,
      total_votes: 9745,
      percentage_of_votes: 0.81,
    },
  ],
  total: {
    evm_votes: 1208722,
    postal_votes: 1729,
    total_votes: 1210451,
  },
};
export const s2464 = {
  cs_name: "Gorakhpur",
  cs_shortname: "gorakhpur",
  cs_code: "s2464",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAVINDRA SHUKLA ALIAS RAVI KISHAN",
      party: "Bharatiya Janata Party",
      evm_votes: 584512,
      postal_votes: 1322,
      total_votes: 585834,
      percentage_of_votes: 50.75,
    },
    {
      serial_no: 2,
      candidate: "KAJAL NISHAD",
      party: "Samajwadi Party",
      evm_votes: 480788,
      postal_votes: 1520,
      total_votes: 482308,
      percentage_of_votes: 41.78,
    },
    {
      serial_no: 3,
      candidate: "JAVED ASHRAF ALIAS JAVED SIMNANI",
      party: "Bahujan Samaj Party",
      evm_votes: 55660,
      postal_votes: 121,
      total_votes: 55781,
      percentage_of_votes: 4.83,
    },
    {
      serial_no: 4,
      candidate: "ANAND KUMAR YADAV ALIAS ANAND KUMAR FAUJI",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 4946,
      postal_votes: 9,
      total_votes: 4955,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 5,
      candidate: "PINTU SAHANI",
      party: "Independent",
      evm_votes: 4031,
      postal_votes: 1,
      total_votes: 4032,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 6,
      candidate: "SHIVSHANKAR PRAJAPATI",
      party: "Bhagidari Party(P)",
      evm_votes: 2631,
      postal_votes: 4,
      total_votes: 2635,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 7,
      candidate: "SUDHANSHU",
      party: "Independent",
      evm_votes: 2393,
      postal_votes: 2,
      total_votes: 2395,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 8,
      candidate: "SANJAY SINGH RANA",
      party: "Bhartiya Sarvdharm Party",
      evm_votes: 2011,
      postal_votes: 1,
      total_votes: 2012,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 9,
      candidate: "NAFEES AKHTAR",
      party: "Independent",
      evm_votes: 1880,
      postal_votes: 0,
      total_votes: 1880,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 10,
      candidate: "ANKIT SHAH",
      party: "Bharatiya Yuva Jan Ekta Party",
      evm_votes: 1871,
      postal_votes: 3,
      total_votes: 1874,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 11,
      candidate: "AMITA BHARATI",
      party: "Independent",
      evm_votes: 1356,
      postal_votes: 8,
      total_votes: 1364,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "SONU RAI",
      party: "Mera Adhikaar Rashtriya Dal",
      evm_votes: 891,
      postal_votes: 7,
      total_votes: 898,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "SHRIRAM PRASAD",
      party: "Al-Hind Party",
      evm_votes: 762,
      postal_votes: 2,
      total_votes: 764,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7652,
      postal_votes: 29,
      total_votes: 7681,
      percentage_of_votes: 0.67,
    },
  ],
  total: {
    evm_votes: 1151384,
    postal_votes: 3029,
    total_votes: 1154413,
  },
};
export const s2465 = {
  cs_name: "Kushi_Nagar",
  cs_shortname: "kushinagar",
  cs_code: "s2465",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "VIJAY KUMAR DUBAY",
      party: "Bharatiya Janata Party",
      evm_votes: 516006,
      postal_votes: 339,
      total_votes: 516345,
      percentage_of_votes: 47.79,
    },
    {
      serial_no: 2,
      candidate: "AJAY PRATAP SINGH URF PINTU SAITHWAR",
      party: "Samajwadi Party",
      evm_votes: 434210,
      postal_votes: 345,
      total_votes: 434555,
      percentage_of_votes: 40.22,
    },
    {
      serial_no: 3,
      candidate: "SUBH NARAYAN CHAUHAN",
      party: "Bahujan Samaj Party",
      evm_votes: 67155,
      postal_votes: 53,
      total_votes: 67208,
      percentage_of_votes: 6.22,
    },
    {
      serial_no: 4,
      candidate: "SWAMI PRASAD MAURYA",
      party: "Rashtriya Shoshit Samaj Party",
      evm_votes: 36559,
      postal_votes: 16,
      total_votes: 36575,
      percentage_of_votes: 3.39,
    },
    {
      serial_no: 5,
      candidate: "HARIKESH",
      party: "Azad Adhikar Sena",
      evm_votes: 4094,
      postal_votes: 3,
      total_votes: 4097,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 6,
      candidate: "RAMCHANDRA SINGH",
      party: "Independent",
      evm_votes: 3619,
      postal_votes: 2,
      total_votes: 3621,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 7,
      candidate: "SUNIL KUMAR SHUKLA",
      party: "Bhartiya Lok Nayak Party",
      evm_votes: 3529,
      postal_votes: 3,
      total_votes: 3532,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 8,
      candidate: "AMIY UPADHYAY",
      party: "Independent",
      evm_votes: 2432,
      postal_votes: 1,
      total_votes: 2433,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 9,
      candidate: "VEDPRAKASH MISHRA",
      party: "Subhashwadi Bhartiya Samajwadi Party (Subhas Party)",
      evm_votes: 2332,
      postal_votes: 3,
      total_votes: 2335,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9774,
      postal_votes: 8,
      total_votes: 9782,
      percentage_of_votes: 0.91,
    },
  ],
  total: {
    evm_votes: 1079710,
    postal_votes: 773,
    total_votes: 1080483,
  },
};
export const s2466 = {
  cs_name: "Deoria",
  cs_shortname: "deoria",
  cs_code: "s2466",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SHASHANK MANI",
      party: "Bharatiya Janata Party",
      evm_votes: 503509,
      postal_votes: 1032,
      total_votes: 504541,
      percentage_of_votes: 48.36,
    },
    {
      serial_no: 2,
      candidate: "AKHILESH PRATAP SINGH",
      party: "Indian National Congress",
      evm_votes: 467946,
      postal_votes: 1753,
      total_votes: 469699,
      percentage_of_votes: 45.02,
    },
    {
      serial_no: 3,
      candidate: "SANDESH",
      party: "Bahujan Samaj Party",
      evm_votes: 45446,
      postal_votes: 118,
      total_votes: 45564,
      percentage_of_votes: 4.37,
    },
    {
      serial_no: 4,
      candidate: "SATENDER KUMAR MALL",
      party: "Independent",
      evm_votes: 4149,
      postal_votes: 9,
      total_votes: 4158,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 5,
      candidate: "MUKTINATH SINGH",
      party: "Janta Samta Party",
      evm_votes: 3261,
      postal_votes: 3,
      total_votes: 3264,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 6,
      candidate: "AGAMSWAROOP",
      party: "Rashtriya Samanta Dal",
      evm_votes: 3180,
      postal_votes: 2,
      total_votes: 3182,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 7,
      candidate: "RAFIK ANSARI",
      party: "Independent",
      evm_votes: 2687,
      postal_votes: 1,
      total_votes: 2688,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10203,
      postal_votes: 9,
      total_votes: 10212,
      percentage_of_votes: 0.98,
    },
  ],
  total: {
    evm_votes: 1040381,
    postal_votes: 2927,
    total_votes: 1043308,
  },
};
export const s2467 = {
  cs_name: "Bansgaon",
  cs_shortname: "bansgaon",
  cs_code: "s2467",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "KAMLESH PASWAN",
      party: "Bharatiya Janata Party",
      evm_votes: 427528,
      postal_votes: 1165,
      total_votes: 428693,
      percentage_of_votes: 45.38,
    },
    {
      serial_no: 2,
      candidate: "SADAL PRASAD",
      party: "Indian National Congress",
      evm_votes: 423872,
      postal_votes: 1671,
      total_votes: 425543,
      percentage_of_votes: 45.04,
    },
    {
      serial_no: 3,
      candidate: "RAMSAMUJH",
      party: "Bahujan Samaj Party",
      evm_votes: 64567,
      postal_votes: 183,
      total_votes: 64750,
      percentage_of_votes: 6.85,
    },
    {
      serial_no: 4,
      candidate: "GAYA PRASAD",
      party: "Independent",
      evm_votes: 6629,
      postal_votes: 9,
      total_votes: 6638,
      percentage_of_votes: 0.7,
    },
    {
      serial_no: 5,
      candidate: "SHRVAN KUMAR NIRALA",
      party: "Independent",
      evm_votes: 4139,
      postal_votes: 3,
      total_votes: 4142,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 6,
      candidate: "HEERALAL",
      party: "Independent",
      evm_votes: 2597,
      postal_votes: 0,
      total_votes: 2597,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 7,
      candidate: "MURLI DHAR",
      party: "Independent",
      evm_votes: 1741,
      postal_votes: 4,
      total_votes: 1745,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "RAJENDRA CHAUDHARI",
      party: "Independent",
      evm_votes: 1629,
      postal_votes: 5,
      total_votes: 1634,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9006,
      postal_votes: 15,
      total_votes: 9021,
      percentage_of_votes: 0.95,
    },
  ],
  total: {
    evm_votes: 941708,
    postal_votes: 3055,
    total_votes: 944763,
  },
};
export const s2477 = {
  cs_name: "Varanasi",
  cs_shortname: "varanasi",
  cs_code: "s2477",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "NARENDRA MODI",
      party: "Bharatiya Janata Party",
      evm_votes: 611439,
      postal_votes: 1531,
      total_votes: 612970,
      percentage_of_votes: 54.24,
    },
    {
      serial_no: 2,
      candidate: "AJAY RAI",
      party: "Indian National Congress",
      evm_votes: 459084,
      postal_votes: 1373,
      total_votes: 460457,
      percentage_of_votes: 40.74,
    },
    {
      serial_no: 3,
      candidate: "ATHER JAMAL LARI",
      party: "Bahujan Samaj Party",
      evm_votes: 33646,
      postal_votes: 120,
      total_votes: 33766,
      percentage_of_votes: 2.99,
    },
    {
      serial_no: 4,
      candidate: "KOLISETTY SHIVA KUMAR",
      party: "Yuga Thulasi Party",
      evm_votes: 5748,
      postal_votes: 2,
      total_votes: 5750,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 5,
      candidate: "GAGAN PRAKASH YADAV",
      party: "Apna Dal (Kamerawadi)",
      evm_votes: 3619,
      postal_votes: 15,
      total_votes: 3634,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 6,
      candidate: "DINESH KUMAR YADAV",
      party: "Independent",
      evm_votes: 2912,
      postal_votes: 5,
      total_votes: 2917,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 7,
      candidate: "SANJAY KUMAR TIWARI",
      party: "Independent",
      evm_votes: 2170,
      postal_votes: 1,
      total_votes: 2171,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8463,
      postal_votes: 15,
      total_votes: 8478,
      percentage_of_votes: 0.75,
    },
  ],
  total: {
    evm_votes: 1127081,
    postal_votes: 3062,
    total_votes: 1130143,
  },
};
export const s2478 = {
  cs_name: "Bhadohi",
  cs_shortname: "bhadohi",
  cs_code: "s2478",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR. VINOD KUMAR BIND",
      party: "Bharatiya Janata Party",
      evm_votes: 458877,
      postal_votes: 1105,
      total_votes: 459982,
      percentage_of_votes: 42.39,
    },
    {
      serial_no: 2,
      candidate: "LALITESHPATI TRIPATHI",
      party: "All India Trinamool Congress",
      evm_votes: 414131,
      postal_votes: 1779,
      total_votes: 415910,
      percentage_of_votes: 38.33,
    },
    {
      serial_no: 3,
      candidate: "HARISHANKAR",
      party: "Bahujan Samaj Party",
      evm_votes: 154718,
      postal_votes: 335,
      total_votes: 155053,
      percentage_of_votes: 14.29,
    },
    {
      serial_no: 4,
      candidate: "PREMCHAND BIND",
      party: "Pragatisheel Manav Samaj Party",
      evm_votes: 13655,
      postal_votes: 34,
      total_votes: 13689,
      percentage_of_votes: 1.26,
    },
    {
      serial_no: 5,
      candidate: "RAJ NARAYAN PATEL",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 6198,
      postal_votes: 8,
      total_votes: 6206,
      percentage_of_votes: 0.57,
    },
    {
      serial_no: 6,
      candidate: "RAM PRAKASH MISHRA",
      party: "Rashtriya Samaj Paksha",
      evm_votes: 5266,
      postal_votes: 4,
      total_votes: 5270,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 7,
      candidate: "SUSHIL",
      party: "All India Forward Bloc",
      evm_votes: 5254,
      postal_votes: 7,
      total_votes: 5261,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 8,
      candidate: "DR. SHYAMDHAR TIWARI",
      party: "Independent",
      evm_votes: 4842,
      postal_votes: 6,
      total_votes: 4848,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 9,
      candidate: "KRISHNA DEV",
      party: "Rashtra Uday Party",
      evm_votes: 4590,
      postal_votes: 7,
      total_votes: 4597,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 10,
      candidate: "DHIRENDRA KUMAR TIWARI",
      party: "Independent",
      evm_votes: 2944,
      postal_votes: 4,
      total_votes: 2948,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11222,
      postal_votes: 7,
      total_votes: 11229,
      percentage_of_votes: 1.03,
    },
  ],
  total: {
    evm_votes: 1081697,
    postal_votes: 3296,
    total_votes: 1084993,
  },
};
export const s252 = {
  cs_name: "Alipurduars",
  cs_shortname: "alipurduars",
  cs_code: "s252",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "MANOJ TIGGA",
      party: "Bharatiya Janata Party",
      evm_votes: 691533,
      postal_votes: 3781,
      total_votes: 695314,
      percentage_of_votes: 48.92,
    },
    {
      serial_no: 2,
      candidate: "PRAKASH CHIK BARAIK",
      party: "All India Trinamool Congress",
      evm_votes: 617486,
      postal_votes: 2381,
      total_votes: 619867,
      percentage_of_votes: 43.61,
    },
    {
      serial_no: 3,
      candidate: "MILI ORAON",
      party: "Revolutionary Socialist Party",
      evm_votes: 39424,
      postal_votes: 285,
      total_votes: 39709,
      percentage_of_votes: 2.79,
    },
    {
      serial_no: 4,
      candidate: "PARIMAL ORAON",
      party: "Independent",
      evm_votes: 12560,
      postal_votes: 24,
      total_votes: 12584,
      percentage_of_votes: 0.89,
    },
    {
      serial_no: 5,
      candidate: "ARJUN INDWAR",
      party: "Independent",
      evm_votes: 11090,
      postal_votes: 32,
      total_votes: 11122,
      percentage_of_votes: 0.78,
    },
    {
      serial_no: 6,
      candidate: "MUNIB NARJINARY",
      party: "Bahujan Samaj Party",
      evm_votes: 6600,
      postal_votes: 48,
      total_votes: 6648,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 7,
      candidate: "RAHUL MARAK",
      party: "Kisan Mazdoor Sangharsh Party",
      evm_votes: 5679,
      postal_votes: 16,
      total_votes: 5695,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 8,
      candidate: "CHANDAN ORAON",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2751,
      postal_votes: 36,
      total_votes: 2787,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 9,
      candidate: "BINAY MURMU",
      party: "North Bengal People’s Party",
      evm_votes: 2700,
      postal_votes: 58,
      total_votes: 2758,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 10,
      candidate: "NRIPENDRA NARAYAN DEBKARJEE",
      party: "Kamatapur People’s Party (United)",
      evm_votes: 2017,
      postal_votes: 38,
      total_votes: 2055,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "BABITA BARA",
      party: "Gana Suraksha Party",
      evm_votes: 1522,
      postal_votes: 30,
      total_votes: 1552,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 21235,
      postal_votes: 63,
      total_votes: 21298,
      percentage_of_votes: 1.5,
    },
  ],
  total: {
    evm_votes: 1414597,
    postal_votes: 6792,
    total_votes: 1421389,
  },
};
export const s253 = {
  cs_name: "Jalpaiguri",
  cs_shortname: "jalpaiguri",
  cs_code: "s253",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR JAYANTA KUMAR ROY",
      party: "Bharatiya Janata Party",
      evm_votes: 763236,
      postal_votes: 3332,
      total_votes: 766568,
      percentage_of_votes: 48.57,
    },
    {
      serial_no: 2,
      candidate: "NIRMAL CHANDRA ROY",
      party: "All India Trinamool Congress",
      evm_votes: 677496,
      postal_votes: 2379,
      total_votes: 679875,
      percentage_of_votes: 43.07,
    },
    {
      serial_no: 3,
      candidate: "DEBRAJ BARMAN",
      party: "Communist Party of India (Marxist)",
      evm_votes: 73629,
      postal_votes: 463,
      total_votes: 74092,
      percentage_of_votes: 4.69,
    },
    {
      serial_no: 4,
      candidate: "SHIPRA RAY HAKIM",
      party: "Independent",
      evm_votes: 9890,
      postal_votes: 17,
      total_votes: 9907,
      percentage_of_votes: 0.63,
    },
    {
      serial_no: 5,
      candidate: "BINOD MALLICK",
      party: "Bahujan Samaj Party",
      evm_votes: 8537,
      postal_votes: 29,
      total_votes: 8566,
      percentage_of_votes: 0.54,
    },
    {
      serial_no: 6,
      candidate: "HAREKRISHNA SARKAR",
      party: "Independent",
      evm_votes: 5837,
      postal_votes: 11,
      total_votes: 5848,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 7,
      candidate: "MAHESWAR BARMAN",
      party: "Independent",
      evm_votes: 4254,
      postal_votes: 13,
      total_votes: 4267,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 8,
      candidate: "RAM PRASAD MANDAL",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 3815,
      postal_votes: 20,
      total_votes: 3835,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 9,
      candidate: "MANABENDRA ROY",
      party: "Kamatapur People’s Party (United)",
      evm_votes: 2899,
      postal_votes: 51,
      total_votes: 2950,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 10,
      candidate: "NIRODE CHANDRA ADHIKARY",
      party: "Independent",
      evm_votes: 2504,
      postal_votes: 13,
      total_votes: 2517,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 11,
      candidate: "ADHIR CHANDRA BARMAN",
      party: "Independent",
      evm_votes: 1911,
      postal_votes: 16,
      total_votes: 1927,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "RANJIT BARMAN",
      party: "Mulnibasi Party of India",
      evm_votes: 1180,
      postal_votes: 9,
      total_votes: 1189,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 16807,
      postal_votes: 41,
      total_votes: 16848,
      percentage_of_votes: 1.07,
    },
  ],
  total: {
    evm_votes: 1571995,
    postal_votes: 6394,
    total_votes: 1578389,
  },
};
export const s254 = {
  cs_name: "Darjeeling",
  cs_shortname: "darjeeling",
  cs_code: "s254",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAJU BISTA",
      party: "Bharatiya Janata Party",
      evm_votes: 675324,
      postal_votes: 4007,
      total_votes: 679331,
      percentage_of_votes: 51.18,
    },
    {
      serial_no: 2,
      candidate: "GOPAL LAMA",
      party: "All India Trinamool Congress",
      evm_votes: 498789,
      postal_votes: 2017,
      total_votes: 500806,
      percentage_of_votes: 37.73,
    },
    {
      serial_no: 3,
      candidate: "MUNISH TAMANG",
      party: "Indian National Congress",
      evm_votes: 82597,
      postal_votes: 777,
      total_votes: 83374,
      percentage_of_votes: 6.28,
    },
    {
      serial_no: 4,
      candidate: "BANDANA RAI",
      party: "Independent",
      evm_votes: 13155,
      postal_votes: 519,
      total_votes: 13674,
      percentage_of_votes: 1.03,
    },
    {
      serial_no: 5,
      candidate: "BISHNU PRASAD SHARMA",
      party: "Independent",
      evm_votes: 7431,
      postal_votes: 16,
      total_votes: 7447,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 6,
      candidate: "BHUPENDRA LEPCHA",
      party: "Independent",
      evm_votes: 6369,
      postal_votes: 26,
      total_votes: 6395,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 7,
      candidate: "ATASI BISWAS",
      party: "Kisan Mazdoor Sangharsh Party",
      evm_votes: 4586,
      postal_votes: 55,
      total_votes: 4641,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 8,
      candidate: "PRADHAN MARDI",
      party: "Ambedkarite Party of India",
      evm_votes: 2714,
      postal_votes: 33,
      total_votes: 2747,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 9,
      candidate: "KHUSHI RANJAN MANDAL",
      party: "Independent",
      evm_votes: 2313,
      postal_votes: 24,
      total_votes: 2337,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 10,
      candidate: "KALICHARAN BARMAN",
      party: "Independent",
      evm_votes: 2055,
      postal_votes: 24,
      total_votes: 2079,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 11,
      candidate: "QAMRUL HAQUE",
      party: "North Bengal People’s Party",
      evm_votes: 1829,
      postal_votes: 24,
      total_votes: 1853,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 12,
      candidate: "BUDHARU ROY",
      party: "Kamatapur People’s Party (United)",
      evm_votes: 1734,
      postal_votes: 28,
      total_votes: 1762,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 13,
      candidate: "SHAHRIAR ALAM",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1605,
      postal_votes: 9,
      total_votes: 1614,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "SUBODH PAKHRIN",
      party: "Gorkha Rashtriya Congress",
      evm_votes: 1270,
      postal_votes: 61,
      total_votes: 1331,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 17960,
      postal_votes: 61,
      total_votes: 18021,
      percentage_of_votes: 1.36,
    },
  ],
  total: {
    evm_votes: 1319731,
    postal_votes: 7681,
    total_votes: 1327412,
  },
};
export const s255 = {
  cs_name: "Raiganj",
  cs_shortname: "raiganj",
  cs_code: "s255",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "KARTICK CHANDRA PAUL",
      party: "Bharatiya Janata Party",
      evm_votes: 558644,
      postal_votes: 2253,
      total_votes: 560897,
      percentage_of_votes: 40.99,
    },
    {
      serial_no: 2,
      candidate: "KALYANI KRISHNA",
      party: "All India Trinamool Congress",
      evm_votes: 491150,
      postal_votes: 1550,
      total_votes: 492700,
      percentage_of_votes: 36,
    },
    {
      serial_no: 3,
      candidate: "ALI IMRAN RAMZ",
      party: "Indian National Congress",
      evm_votes: 262694,
      postal_votes: 579,
      total_votes: 263273,
      percentage_of_votes: 19.24,
    },
    {
      serial_no: 4,
      candidate: "DWARIK NATH BARMAN",
      party: "Independent",
      evm_votes: 8258,
      postal_votes: 6,
      total_votes: 8264,
      percentage_of_votes: 0.6,
    },
    {
      serial_no: 5,
      candidate: "MD NAZIR AKHTAR",
      party: "Independent",
      evm_votes: 6004,
      postal_votes: 1,
      total_votes: 6005,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 6,
      candidate: "KALIDASH MURMU",
      party: "Independent",
      evm_votes: 5865,
      postal_votes: 3,
      total_votes: 5868,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 7,
      candidate: "SWAPAN KUMAR DAS",
      party: "Bahujan Samaj Party",
      evm_votes: 4106,
      postal_votes: 11,
      total_votes: 4117,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 8,
      candidate: "RUPAK ROY",
      party: "Independent",
      evm_votes: 3355,
      postal_votes: 3,
      total_votes: 3358,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 9,
      candidate: "FAKIRA MD",
      party: "Independent",
      evm_votes: 3353,
      postal_votes: 2,
      total_votes: 3355,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 10,
      candidate: "DURGA MURMU",
      party: "Ambedkarite Party of India",
      evm_votes: 3330,
      postal_votes: 4,
      total_votes: 3334,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 11,
      candidate: "PALASH CHANDRA MAHATO",
      party: "Independent",
      evm_votes: 3112,
      postal_votes: 2,
      total_votes: 3114,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 12,
      candidate: "ALI IMRAN",
      party: "Independent",
      evm_votes: 1939,
      postal_votes: 7,
      total_votes: 1946,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 13,
      candidate: "MD ABRAHIM HOQUE",
      party: "Independent",
      evm_votes: 1761,
      postal_votes: 2,
      total_votes: 1763,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 14,
      candidate: "SHAHIDUR RAHMAN",
      party: "North Bengal People’s Party",
      evm_votes: 1458,
      postal_votes: 4,
      total_votes: 1462,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 15,
      candidate: "SHARANYA DUTTA",
      party: "Independent",
      evm_votes: 1328,
      postal_votes: 2,
      total_votes: 1330,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 16,
      candidate: "SANATAN DUTTA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1256,
      postal_votes: 15,
      total_votes: 1271,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 17,
      candidate: "TAPAN BARMAN",
      party: "Jai Prakash Janata Dal",
      evm_votes: 1249,
      postal_votes: 2,
      total_votes: 1251,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 18,
      candidate: "ANWARUL HOQUE",
      party: "Independent",
      evm_votes: 1187,
      postal_votes: 3,
      total_votes: 1190,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 19,
      candidate: "RAMESH CHANDRA SINHA",
      party: "Bhumiputra United Party",
      evm_votes: 1046,
      postal_votes: 5,
      total_votes: 1051,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 20,
      candidate: "AL MONOWARA BEGAM",
      party: "India Manus Party",
      evm_votes: 977,
      postal_votes: 5,
      total_votes: 982,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 21,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 1924,
      postal_votes: 16,
      total_votes: 1940,
      percentage_of_votes: 0.14,
    },
  ],
  total: {
    evm_votes: 1363996,
    postal_votes: 4475,
    total_votes: 1368471,
  },
};
export const s256 = {
  cs_name: "Balurghat",
  cs_shortname: "balurghat",
  cs_code: "s256",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SUKANTA MAJUMDAR",
      party: "Bharatiya Janata Party",
      evm_votes: 572925,
      postal_votes: 2071,
      total_votes: 574996,
      percentage_of_votes: 46.47,
    },
    {
      serial_no: 2,
      candidate: "BIPLAB MITRA",
      party: "All India Trinamool Congress",
      evm_votes: 563252,
      postal_votes: 1358,
      total_votes: 564610,
      percentage_of_votes: 45.63,
    },
    {
      serial_no: 3,
      candidate: "JOYDEV KUMAR SIDDHANTA",
      party: "Revolutionary Socialist Party",
      evm_votes: 54081,
      postal_votes: 136,
      total_votes: 54217,
      percentage_of_votes: 4.38,
    },
    {
      serial_no: 4,
      candidate: "HAKIM MURMU",
      party: "Independent",
      evm_votes: 6820,
      postal_votes: 2,
      total_votes: 6822,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 5,
      candidate: "BAKHTIAR MUJAHIT SAHANI",
      party: "Independent",
      evm_votes: 6391,
      postal_votes: 2,
      total_votes: 6393,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 6,
      candidate: "RANENDRA NATH MALI",
      party: "Independent",
      evm_votes: 5590,
      postal_votes: 3,
      total_votes: 5593,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 7,
      candidate: "ANTA HANSDA",
      party: "Bahujan Samaj Party",
      evm_votes: 5130,
      postal_votes: 15,
      total_votes: 5145,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 8,
      candidate: "BISWANATH MURMU",
      party: "Ambedkarite Party of India",
      evm_votes: 3454,
      postal_votes: 11,
      total_votes: 3465,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 9,
      candidate: "MOZAMMEL HAQUE",
      party: "All India Secular Front",
      evm_votes: 2261,
      postal_votes: 7,
      total_votes: 2268,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 10,
      candidate: "NAROTTAM SAHA",
      party: "Independent",
      evm_votes: 2095,
      postal_votes: 11,
      total_votes: 2106,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 11,
      candidate: "MD. SARWARDI",
      party: "Bhumiputra United Party",
      evm_votes: 1957,
      postal_votes: 10,
      total_votes: 1967,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 12,
      candidate: "BIRENDRA NATH MAHANTA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1380,
      postal_votes: 7,
      total_votes: 1387,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 13,
      candidate: "RUBEL SARKAR",
      party: "Kamatapur People’s Party (United)",
      evm_votes: 898,
      postal_votes: 4,
      total_votes: 902,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7430,
      postal_votes: 7,
      total_votes: 7437,
      percentage_of_votes: 0.6,
    },
  ],
  total: {
    evm_votes: 1233664,
    postal_votes: 3644,
    total_votes: 1237308,
  },
};
export const s257 = {
  cs_name: "Maldaha_Uttar",
  cs_shortname: "maldahauttar",
  cs_code: "s257",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "KHAGEN MURMU",
      party: "Bharatiya Janata Party",
      evm_votes: 525109,
      postal_votes: 1914,
      total_votes: 527023,
      percentage_of_votes: 37.18,
    },
    {
      serial_no: 2,
      candidate: "PRASUN BANERJEE",
      party: "All India Trinamool Congress",
      evm_votes: 448234,
      postal_votes: 1081,
      total_votes: 449315,
      percentage_of_votes: 31.7,
    },
    {
      serial_no: 3,
      candidate: "ALAM MOSTAQUE",
      party: "Indian National Congress",
      evm_votes: 384063,
      postal_votes: 701,
      total_votes: 384764,
      percentage_of_votes: 27.14,
    },
    {
      serial_no: 4,
      candidate: "JATISH KISKU",
      party: "Independent",
      evm_votes: 8680,
      postal_votes: 3,
      total_votes: 8683,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 5,
      candidate: "MONATAN HEMBRAM",
      party: "Independent",
      evm_votes: 7927,
      postal_votes: 6,
      total_votes: 7933,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 6,
      candidate: "SWAPAN MAHATO",
      party: "Independent",
      evm_votes: 7235,
      postal_votes: 2,
      total_votes: 7237,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 7,
      candidate: "SANATAN RISHI",
      party: "Bahujan Samaj Party",
      evm_votes: 5624,
      postal_votes: 8,
      total_votes: 5632,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 8,
      candidate: "JOSEPH KISKU",
      party: "Ambedkarite Party of India",
      evm_votes: 4637,
      postal_votes: 0,
      total_votes: 4637,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 9,
      candidate: "MONJU HEMBROM",
      party: "Independent",
      evm_votes: 3535,
      postal_votes: 3,
      total_votes: 3538,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 10,
      candidate: "MD FARUQUE HOSSAIN",
      party: "Independent",
      evm_votes: 3374,
      postal_votes: 1,
      total_votes: 3375,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 11,
      candidate: "SOMNATH DAS",
      party: "Bharatiya Nyay-Adhikar Raksha Party",
      evm_votes: 3157,
      postal_votes: 7,
      total_votes: 3164,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 12,
      candidate: "SUBHASH BARMAN",
      party: "Kamatapur People’s Party (United)",
      evm_votes: 2019,
      postal_votes: 0,
      total_votes: 2019,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 13,
      candidate: "KALICHARAN ROY",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1286,
      postal_votes: 7,
      total_votes: 1293,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "MD SOHEL",
      party: "All India Secular Front",
      evm_votes: 1090,
      postal_votes: 2,
      total_votes: 1092,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "NARESH PAL",
      party: "Jan Sangh Party",
      evm_votes: 593,
      postal_votes: 3,
      total_votes: 596,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7257,
      postal_votes: 31,
      total_votes: 7288,
      percentage_of_votes: 0.51,
    },
  ],
  total: {
    evm_votes: 1413820,
    postal_votes: 3769,
    total_votes: 1417589,
  },
};
export const s2513 = {
  cs_name: "Ranaghat",
  cs_shortname: "ranaghat",
  cs_code: "s2513",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "JAGANNATH SARKAR",
      party: "Bharatiya Janata Party",
      evm_votes: 778046,
      postal_votes: 4350,
      total_votes: 782396,
      percentage_of_votes: 50.78,
    },
    {
      serial_no: 2,
      candidate: "MUKUT MANI ADHIKARI",
      party: "All India Trinamool Congress",
      evm_votes: 592276,
      postal_votes: 3221,
      total_votes: 595497,
      percentage_of_votes: 38.65,
    },
    {
      serial_no: 3,
      candidate: "ALAKESH DAS",
      party: "Communist Party of India (Marxist)",
      evm_votes: 122970,
      postal_votes: 840,
      total_votes: 123810,
      percentage_of_votes: 8.04,
    },
    {
      serial_no: 4,
      candidate: "BIPLAB BISWAS",
      party: "Bahujan Samaj Party",
      evm_votes: 9319,
      postal_votes: 73,
      total_votes: 9392,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 5,
      candidate: "PARESH HALDAR",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 8223,
      postal_votes: 16,
      total_votes: 8239,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 6,
      candidate: "JAGANNATH SARKAR",
      party: "Independent",
      evm_votes: 5831,
      postal_votes: 30,
      total_votes: 5861,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 7,
      candidate: "SHYAMAL KUMAR MALLICK",
      party: "Independent",
      evm_votes: 5533,
      postal_votes: 11,
      total_votes: 5544,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9798,
      postal_votes: 95,
      total_votes: 9893,
      percentage_of_votes: 0.64,
    },
  ],
  total: {
    evm_votes: 1531996,
    postal_votes: 8636,
    total_votes: 1540632,
  },
};
export const s2514 = {
  cs_name: "Bangaon",
  cs_shortname: "bangaon",
  cs_code: "s2514",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SHANTANU THAKUR",
      party: "Bharatiya Janata Party",
      evm_votes: 716952,
      postal_votes: 2553,
      total_votes: 719505,
      percentage_of_votes: 48.19,
    },
    {
      serial_no: 2,
      candidate: "BISWAJIT DAS S/O LATE BIJAY KRISHNA DAS",
      party: "All India Trinamool Congress",
      evm_votes: 643653,
      postal_votes: 2159,
      total_votes: 645812,
      percentage_of_votes: 43.25,
    },
    {
      serial_no: 3,
      candidate: "PRADIPKUMAR BISWAS",
      party: "Indian National Congress",
      evm_votes: 64859,
      postal_votes: 317,
      total_votes: 65176,
      percentage_of_votes: 4.37,
    },
    {
      serial_no: 4,
      candidate: "DIPAK MAJUMDAR",
      party: "All India Secular Front",
      evm_votes: 19199,
      postal_votes: 17,
      total_votes: 19216,
      percentage_of_votes: 1.29,
    },
    {
      serial_no: 5,
      candidate: "SUJATA BAIRAGI",
      party: "Independent",
      evm_votes: 9649,
      postal_votes: 4,
      total_votes: 9653,
      percentage_of_votes: 0.65,
    },
    {
      serial_no: 6,
      candidate: "JASHAJIT SARKHEL",
      party: "Independent",
      evm_votes: 7829,
      postal_votes: 4,
      total_votes: 7833,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 7,
      candidate: "CHANDAN MALLICK",
      party: "Bahujan Samaj Party",
      evm_votes: 5905,
      postal_votes: 30,
      total_votes: 5935,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 8,
      candidate: "PRANITA MANDAL",
      party: "Independent",
      evm_votes: 2407,
      postal_votes: 4,
      total_votes: 2411,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "SUMITA PODDAR",
      party: "Independent",
      evm_votes: 2347,
      postal_votes: 7,
      total_votes: 2354,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 10,
      candidate: "BISWAJIT DAS S/O LATE BALARAM DAS",
      party: "Independent",
      evm_votes: 2241,
      postal_votes: 7,
      total_votes: 2248,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 11,
      candidate: "PATIT PABAN MANDAL",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2140,
      postal_votes: 29,
      total_votes: 2169,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 12,
      candidate: "SAMARESH BISWAS",
      party: "Party for Democratic Socialism",
      evm_votes: 1562,
      postal_votes: 3,
      total_votes: 1565,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 13,
      candidate: "BISWAJIT DAS",
      party: "Independent",
      evm_votes: 1257,
      postal_votes: 15,
      total_votes: 1272,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "KALYAN SARKAR",
      party: "Independent",
      evm_votes: 676,
      postal_votes: 15,
      total_votes: 691,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "SUBRATA BISWAS",
      party: "Guruchand Sena Dal",
      evm_votes: 581,
      postal_votes: 2,
      total_votes: 583,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6635,
      postal_votes: 50,
      total_votes: 6685,
      percentage_of_votes: 0.45,
    },
  ],
  total: {
    evm_votes: 1487892,
    postal_votes: 5216,
    total_votes: 1493108,
  },
};
export const s2530 = {
  cs_name: "Tamluk",
  cs_shortname: "tamluk",
  cs_code: "s2530",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ABHIJIT GANGOPADHYAY",
      party: "Bharatiya Janata Party",
      evm_votes: 761183,
      postal_votes: 4401,
      total_votes: 765584,
      percentage_of_votes: 48.54,
    },
    {
      serial_no: 2,
      candidate: "DEBANGSHU BHATTACHARYA",
      party: "All India Trinamool Congress",
      evm_votes: 683955,
      postal_votes: 3896,
      total_votes: 687851,
      percentage_of_votes: 43.61,
    },
    {
      serial_no: 3,
      candidate: "SAYAN BANERJEE",
      party: "Communist Party of India (Marxist)",
      evm_votes: 84774,
      postal_votes: 615,
      total_votes: 85389,
      percentage_of_votes: 5.41,
    },
    {
      serial_no: 4,
      candidate: "MOHIUDDIN AHMED MAHI",
      party: "All India Secular Front",
      evm_votes: 12303,
      postal_votes: 36,
      total_votes: 12339,
      percentage_of_votes: 0.78,
    },
    {
      serial_no: 5,
      candidate: "SURYANEEL DAS",
      party: "Independent",
      evm_votes: 6012,
      postal_votes: 2,
      total_votes: 6014,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 6,
      candidate: "NARAYAN CHANDRA NAYAK",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 5633,
      postal_votes: 49,
      total_votes: 5682,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 7,
      candidate: "SABITRI BISHAI",
      party: "Bahujan Samaj Party",
      evm_votes: 3724,
      postal_votes: 30,
      total_votes: 3754,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 8,
      candidate: "DEBAPRASAD JANA",
      party: "Independent",
      evm_votes: 1460,
      postal_votes: 7,
      total_votes: 1467,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 9,
      candidate: "KALIPADA DAS",
      party: "Independent",
      evm_votes: 947,
      postal_votes: 27,
      total_votes: 974,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8223,
      postal_votes: 42,
      total_votes: 8265,
      percentage_of_votes: 0.52,
    },
  ],
  total: {
    evm_votes: 1568214,
    postal_votes: 9105,
    total_votes: 1577319,
  },
};
export const s2531 = {
  cs_name: "Kanthi",
  cs_shortname: "kanthi",
  cs_code: "s2531",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ADHIKARI SOUMENDU",
      party: "Bharatiya Janata Party",
      evm_votes: 757906,
      postal_votes: 5289,
      total_votes: 763195,
      percentage_of_votes: 49.85,
    },
    {
      serial_no: 2,
      candidate: "UTTAM BARIK SON OF LATE BIRENDRA BARIK",
      party: "All India Trinamool Congress",
      evm_votes: 709763,
      postal_votes: 5668,
      total_votes: 715431,
      percentage_of_votes: 46.73,
    },
    {
      serial_no: 3,
      candidate: "URBASI BANERJEE",
      party: "Indian National Congress",
      evm_votes: 30737,
      postal_votes: 385,
      total_votes: 31122,
      percentage_of_votes: 2.03,
    },
    {
      serial_no: 4,
      candidate: "BIDESH BASU MAITY",
      party: "Independent",
      evm_votes: 5633,
      postal_votes: 21,
      total_votes: 5654,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 5,
      candidate: "MANAS PRADHAN",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2489,
      postal_votes: 40,
      total_votes: 2529,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 6,
      candidate: "UTTAM BARIK SON OF LATE NARAYAN BARIK",
      party: "Independent",
      evm_votes: 1963,
      postal_votes: 35,
      total_votes: 1998,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 7,
      candidate: "MAKHAN LAL MAHAPATRA",
      party: "Bahujan Samaj Party",
      evm_votes: 1792,
      postal_votes: 12,
      total_votes: 1804,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 8,
      candidate: "MD AHAMADULLAH KHAN",
      party: "Independent",
      evm_votes: 530,
      postal_votes: 56,
      total_votes: 586,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 9,
      candidate: "ARJUN KUMAR MAITY",
      party: "Independent",
      evm_votes: 504,
      postal_votes: 40,
      total_votes: 544,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8075,
      postal_votes: 59,
      total_votes: 8134,
      percentage_of_votes: 0.53,
    },
  ],
  total: {
    evm_votes: 1519392,
    postal_votes: 11605,
    total_votes: 1530997,
  },
};
export const s2535 = {
  cs_name: "Purulia",
  cs_shortname: "purulia",
  cs_code: "s2535",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "JYOTIRMAY SINGH MAHATO",
      party: "Bharatiya Janata Party",
      evm_votes: 576246,
      postal_votes: 2243,
      total_votes: 578489,
      percentage_of_votes: 40.34,
    },
    {
      serial_no: 2,
      candidate: "SHANTIRAM MAHATO",
      party: "All India Trinamool Congress",
      evm_votes: 559325,
      postal_votes: 2085,
      total_votes: 561410,
      percentage_of_votes: 39.15,
    },
    {
      serial_no: 3,
      candidate: "NEPAL CHANDRA MAHATO",
      party: "Indian National Congress",
      evm_votes: 128565,
      postal_votes: 592,
      total_votes: 129157,
      percentage_of_votes: 9.01,
    },
    {
      serial_no: 4,
      candidate: "AJIT PRASAD MAHATA",
      party: "Independent",
      evm_votes: 98381,
      postal_votes: 277,
      total_votes: 98658,
      percentage_of_votes: 6.88,
    },
    {
      serial_no: 5,
      candidate: "DHIRENDRANATH MAHATO",
      party: "All India Forward Bloc",
      evm_votes: 14498,
      postal_votes: 74,
      total_votes: 14572,
      percentage_of_votes: 1.02,
    },
    {
      serial_no: 6,
      candidate: "SONTOSH RAJOWARD",
      party: "Bahujan Samaj Party",
      evm_votes: 7739,
      postal_votes: 21,
      total_votes: 7760,
      percentage_of_votes: 0.54,
    },
    {
      serial_no: 7,
      candidate: "SUSMITA MAHATO",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 6078,
      postal_votes: 31,
      total_votes: 6109,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 8,
      candidate: "GORISH ROY",
      party: "Independent",
      evm_votes: 4800,
      postal_votes: 9,
      total_votes: 4809,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 9,
      candidate: "BHAGWAT DASS SHASTRI",
      party: "Independent",
      evm_votes: 4508,
      postal_votes: 4,
      total_votes: 4512,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 10,
      candidate: "ASIT BARAN MAHATO",
      party: "Party for Democratic Socialism",
      evm_votes: 3868,
      postal_votes: 9,
      total_votes: 3877,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 11,
      candidate: "PIJUSH KANTI RAJAK",
      party: "Independent",
      evm_votes: 3408,
      postal_votes: 10,
      total_votes: 3418,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 12,
      candidate: "AJIT MAHATO",
      party: "Independent",
      evm_votes: 3309,
      postal_votes: 9,
      total_votes: 3318,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 17871,
      postal_votes: 58,
      total_votes: 17929,
      percentage_of_votes: 1.25,
    },
  ],
  total: {
    evm_votes: 1428596,
    postal_votes: 5422,
    total_votes: 1434018,
  },
};
export const s2537 = {
  cs_name: "Bishnupur",
  cs_shortname: "bishnupur",
  cs_code: "s2537",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "KHAN SAUMITRA",
      party: "Bharatiya Janata Party",
      evm_votes: 677320,
      postal_votes: 2810,
      total_votes: 680130,
      percentage_of_votes: 44.93,
    },
    {
      serial_no: 2,
      candidate: "SUJATA MONDAL",
      party: "All India Trinamool Congress",
      evm_votes: 671148,
      postal_votes: 3415,
      total_votes: 674563,
      percentage_of_votes: 44.56,
    },
    {
      serial_no: 3,
      candidate: "SITAL CHANDRA KAIBARTYA",
      party: "Communist Party of India (Marxist)",
      evm_votes: 104895,
      postal_votes: 516,
      total_votes: 105411,
      percentage_of_votes: 6.96,
    },
    {
      serial_no: 4,
      candidate: "SADANANDA MANDAL",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 16046,
      postal_votes: 28,
      total_votes: 16074,
      percentage_of_votes: 1.06,
    },
    {
      serial_no: 5,
      candidate: "JOYDEV DHANK",
      party: "Bahujan Samaj Party",
      evm_votes: 9027,
      postal_votes: 52,
      total_votes: 9079,
      percentage_of_votes: 0.6,
    },
    {
      serial_no: 6,
      candidate: "BASUDEB SIKARI",
      party: "Independent",
      evm_votes: 5093,
      postal_votes: 13,
      total_votes: 5106,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 7,
      candidate: "NARENDRANATH RAY",
      party: "Independent",
      evm_votes: 4360,
      postal_votes: 17,
      total_votes: 4377,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 19062,
      postal_votes: 70,
      total_votes: 19132,
      percentage_of_votes: 1.26,
    },
  ],
  total: {
    evm_votes: 1506951,
    postal_votes: 6921,
    total_votes: 1513872,
  },
};
export const s261 = {
  cs_name: "SURGUJA",
  cs_shortname: "surguja",
  cs_code: "s261",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "CHINTAMANI MAHARAJ",
      party: "Bharatiya Janata Party",
      evm_votes: 712172,
      postal_votes: 1028,
      total_votes: 713200,
      percentage_of_votes: 49.01,
    },
    {
      serial_no: 2,
      candidate: "SHASHI SINGH KORAM",
      party: "Indian National Congress",
      evm_votes: 647329,
      postal_votes: 1049,
      total_votes: 648378,
      percentage_of_votes: 44.55,
    },
    {
      serial_no: 3,
      candidate: "DR. L. S. UDAY SINGH",
      party: "Gondvana Gantantra Party",
      evm_votes: 15626,
      postal_votes: 25,
      total_votes: 15651,
      percentage_of_votes: 1.08,
    },
    {
      serial_no: 4,
      candidate: "SANJAY KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 15182,
      postal_votes: 17,
      total_votes: 15199,
      percentage_of_votes: 1.04,
    },
    {
      serial_no: 5,
      candidate: "RAMADHAR SINGH",
      party: "Independent",
      evm_votes: 10857,
      postal_votes: 4,
      total_votes: 10861,
      percentage_of_votes: 0.75,
    },
    {
      serial_no: 6,
      candidate: "PRINCE ABHISHEK KUJUR",
      party: "Independent",
      evm_votes: 6803,
      postal_votes: 12,
      total_votes: 6815,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 7,
      candidate: "URMILA SINGH",
      party: "Independent",
      evm_votes: 5779,
      postal_votes: 5,
      total_votes: 5784,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 8,
      candidate: "ARVIND KACHHAP",
      party: "Independent",
      evm_votes: 4166,
      postal_votes: 18,
      total_votes: 4184,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 9,
      candidate: "KANTA MINJ",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 4008,
      postal_votes: 8,
      total_votes: 4016,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 10,
      candidate: "JEROME MINJ",
      party: "Bharat Adivasi Party",
      evm_votes: 3035,
      postal_votes: 25,
      total_votes: 3060,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 28107,
      postal_votes: 14,
      total_votes: 28121,
      percentage_of_votes: 1.93,
    },
  ],
  total: {
    evm_votes: 1453064,
    postal_votes: 2205,
    total_votes: 1455269,
  },
};
export const s262 = {
  cs_name: "RAIGARH",
  cs_shortname: "raigarh",
  cs_code: "s262",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "RADHESHYAM RATHIYA",
      party: "Bharatiya Janata Party",
      evm_votes: 806667,
      postal_votes: 1608,
      total_votes: 808275,
      percentage_of_votes: 55.63,
    },
    {
      serial_no: 2,
      candidate: "DR. MENKA DEVI SINGH",
      party: "Indian National Congress",
      evm_votes: 566487,
      postal_votes: 1397,
      total_votes: 567884,
      percentage_of_votes: 39.08,
    },
    {
      serial_no: 3,
      candidate: "INNOCENT KUJUR - BIDANA ORAON",
      party: "Bahujan Samaj Party",
      evm_votes: 15493,
      postal_votes: 107,
      total_votes: 15600,
      percentage_of_votes: 1.07,
    },
    {
      serial_no: 4,
      candidate: "ALBERT MING",
      party: "Hamar Raj Party",
      evm_votes: 10915,
      postal_votes: 44,
      total_votes: 10959,
      percentage_of_votes: 0.75,
    },
    {
      serial_no: 5,
      candidate: "PRAKASH KUMAR URANW",
      party: "Independent",
      evm_votes: 7719,
      postal_votes: 3,
      total_votes: 7722,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 6,
      candidate: "RUPNARAYAN EKKA",
      party: "Independent",
      evm_votes: 7410,
      postal_votes: 10,
      total_votes: 7420,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 7,
      candidate: "GOWARDHAN RATHIA",
      party: "Independent",
      evm_votes: 4591,
      postal_votes: 2,
      total_votes: 4593,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 8,
      candidate: "POOJA SIDAR",
      party: "Independent",
      evm_votes: 4159,
      postal_votes: 8,
      total_votes: 4167,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 9,
      candidate: "MADAN PRASAD GOND",
      party: "Gondvana Gantantra Party",
      evm_votes: 3007,
      postal_votes: 8,
      total_votes: 3015,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 10,
      candidate: "GULESHWAR PAINKRA",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 2618,
      postal_votes: 8,
      total_votes: 2626,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 11,
      candidate: "UDAY KUMAR RATHIA",
      party: "Independent",
      evm_votes: 2127,
      postal_votes: 2,
      total_votes: 2129,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 12,
      candidate: "ABHAY KUMAR EKKA",
      party: "Independent",
      evm_votes: 1808,
      postal_votes: 17,
      total_votes: 1825,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 13,
      candidate: "BADAL EKKA",
      party: "Sarv Adi Dal",
      evm_votes: 1714,
      postal_votes: 14,
      total_votes: 1728,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 14993,
      postal_votes: 29,
      total_votes: 15022,
      percentage_of_votes: 1.03,
    },
  ],
  total: {
    evm_votes: 1449708,
    postal_votes: 3257,
    total_votes: 1452965,
  },
};
export const s263 = {
  cs_name: "JANJGIR-CHAMPA",
  cs_shortname: "janjgirchampa",
  cs_code: "s263",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "KAMLESH JANGDE",
      party: "Bharatiya Janata Party",
      evm_votes: 675740,
      postal_votes: 2459,
      total_votes: 678199,
      percentage_of_votes: 48.71,
    },
    {
      serial_no: 2,
      candidate: "DR. SHIVKUMAR DAHARIYA",
      party: "Indian National Congress",
      evm_votes: 616131,
      postal_votes: 2068,
      total_votes: 618199,
      percentage_of_votes: 44.4,
    },
    {
      serial_no: 3,
      candidate: "DR. ROHIT DAHARIA",
      party: "Bahujan Samaj Party",
      evm_votes: 48089,
      postal_votes: 412,
      total_votes: 48501,
      percentage_of_votes: 3.48,
    },
    {
      serial_no: 4,
      candidate: "ENGINEER REVA KURREY",
      party: "Independent",
      evm_votes: 8158,
      postal_votes: 1,
      total_votes: 8159,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 5,
      candidate: "AD. T.R. NIRALA",
      party: "Independent",
      evm_votes: 5792,
      postal_votes: 2,
      total_votes: 5794,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 6,
      candidate: "ANIL MANHAR",
      party: "Hamar Raj Party",
      evm_votes: 5770,
      postal_votes: 10,
      total_votes: 5780,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 7,
      candidate: "VIDYA DEVI SONI",
      party: "Independent",
      evm_votes: 4877,
      postal_votes: 4,
      total_votes: 4881,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 8,
      candidate: "MINA CHAUHAN",
      party: "Independent",
      evm_votes: 2929,
      postal_votes: 2,
      total_votes: 2931,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 9,
      candidate: "ANAND GILHARE",
      party: "Independent",
      evm_votes: 2876,
      postal_votes: 1,
      total_votes: 2877,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 10,
      candidate: "DEEPAK KUMAR KHUNTE",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 1822,
      postal_votes: 7,
      total_votes: 1829,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "ARVIND KUMAR",
      party: "Independent",
      evm_votes: 1710,
      postal_votes: 5,
      total_votes: 1715,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "GOPAL PRASAD KHUNTE",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 1471,
      postal_votes: 12,
      total_votes: 1483,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 13,
      candidate: "VIJAYLAXMI SURYAWANSHI NAT",
      party: "Asankhya Samaj Party",
      evm_votes: 1417,
      postal_votes: 5,
      total_votes: 1422,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 14,
      candidate: "SIMA MAHILANGE",
      party: "Independent",
      evm_votes: 1333,
      postal_votes: 3,
      total_votes: 1336,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "ADVOCATE SHAILENDRA BANJARE (SHAKTIPUTRA)",
      party: "Shakti Sena (Bharat Desh)",
      evm_votes: 1248,
      postal_votes: 5,
      total_votes: 1253,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 16,
      candidate: "VIJAY KUMAR KURRE",
      party: "Rashtriya Jansabha Party",
      evm_votes: 981,
      postal_votes: 2,
      total_votes: 983,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 17,
      candidate: "BRINDA CHAUHAN",
      party: "Chhattisgarh Vikas Ganga Rashtriya Party",
      evm_votes: 921,
      postal_votes: 3,
      total_votes: 924,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "JAGJIVAN RAM SATNAMI",
      party: "Azad Janata Party",
      evm_votes: 833,
      postal_votes: 4,
      total_votes: 837,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 19,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5098,
      postal_votes: 39,
      total_votes: 5137,
      percentage_of_votes: 0.37,
    },
  ],
  total: {
    evm_votes: 1387196,
    postal_votes: 5044,
    total_votes: 1392240,
  },
};
export const s265 = {
  cs_name: "BILASPUR",
  cs_shortname: "bilaspur",
  cs_code: "s265",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "TOKHAN SAHU",
      party: "Bharatiya Janata Party",
      evm_votes: 723194,
      postal_votes: 1743,
      total_votes: 724937,
      percentage_of_votes: 53.25,
    },
    {
      serial_no: 2,
      candidate: "DEVENDRA YADAV",
      party: "Indian National Congress",
      evm_votes: 558942,
      postal_votes: 1437,
      total_votes: 560379,
      percentage_of_votes: 41.16,
    },
    {
      serial_no: 3,
      candidate: "ASHWANI RAJAK",
      party: "Bahujan Samaj Party",
      evm_votes: 13175,
      postal_votes: 47,
      total_votes: 13222,
      percentage_of_votes: 0.97,
    },
    {
      serial_no: 4,
      candidate: "ANAND URAWAN",
      party: "Independent",
      evm_votes: 5894,
      postal_votes: 4,
      total_votes: 5898,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 5,
      candidate: "ANILESH MISHRA (MAHARAJ)",
      party: "Independent",
      evm_votes: 5374,
      postal_votes: 2,
      total_votes: 5376,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 6,
      candidate: "KULESH KUMAR SAHU",
      party: "Independent",
      evm_votes: 4439,
      postal_votes: 4,
      total_votes: 4443,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 7,
      candidate: "ASHAVANT SAHOO",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 4345,
      postal_votes: 9,
      total_votes: 4354,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 8,
      candidate: "SHRIKANT MALHWAYR (KASER)",
      party: "Loktantra Congress Party",
      evm_votes: 4197,
      postal_votes: 4,
      total_votes: 4201,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 9,
      candidate: "VIKRAMPRASAD JOGI",
      party: "Independent",
      evm_votes: 2862,
      postal_votes: 0,
      total_votes: 2862,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 10,
      candidate: "KESHAV PRASAD",
      party: "Independent",
      evm_votes: 2765,
      postal_votes: 2,
      total_votes: 2767,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 11,
      candidate: "SUDIEP SHRIVASTAVA",
      party: "Hamar Raj Party",
      evm_votes: 2310,
      postal_votes: 2,
      total_votes: 2312,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "RUPRAM SAHU",
      party: "Independent",
      evm_votes: 2261,
      postal_votes: 0,
      total_votes: 2261,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 13,
      candidate: "KRISHNA NANDAN SINGH",
      party: "Independent",
      evm_votes: 2020,
      postal_votes: 0,
      total_votes: 2020,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 14,
      candidate: "ROSHAN KUMAR",
      party: "Independent",
      evm_votes: 1920,
      postal_votes: 0,
      total_votes: 1920,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 15,
      candidate: "DINESH PRATAP SINGH KHUSRO NIKHIL",
      party: "Rashtriya Gondvana Party",
      evm_votes: 1792,
      postal_votes: 16,
      total_votes: 1808,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 16,
      candidate: "RAMESHWAR SAHU",
      party: "Independent",
      evm_votes: 1761,
      postal_votes: 0,
      total_votes: 1761,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 17,
      candidate: "VIJAY KUMAR",
      party: "Independent",
      evm_votes: 1625,
      postal_votes: 1,
      total_votes: 1626,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 18,
      candidate: "YASHUTOSH LAHARE",
      party: "Ambedkarite Party of India",
      evm_votes: 1271,
      postal_votes: 3,
      total_votes: 1274,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 19,
      candidate: "GHANSHYAM URF SADHELAL BHARDWAJ",
      party: "Independent",
      evm_votes: 1251,
      postal_votes: 0,
      total_votes: 1251,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 20,
      candidate: "VEDMANI SINGH KSHATRIYA (THAKUR)",
      party: "Independent",
      evm_votes: 1163,
      postal_votes: 0,
      total_votes: 1163,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 21,
      candidate: "SHEKHAR BANJARE",
      party: "Independent",
      evm_votes: 1160,
      postal_votes: 0,
      total_votes: 1160,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 22,
      candidate: "SIDDHRAM LAHARE",
      party: "Rashtriya Jansabha Party",
      evm_votes: 1149,
      postal_votes: 1,
      total_votes: 1150,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 23,
      candidate: "RAMPYARE RAI",
      party: "Independent",
      evm_votes: 1112,
      postal_votes: 0,
      total_votes: 1112,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 24,
      candidate: "NAND KISHORE RAJ",
      party: "Gondvana Gantantra Party",
      evm_votes: 1049,
      postal_votes: 12,
      total_votes: 1061,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 25,
      candidate: "BHUNESHWAR MARKO",
      party: "Independent",
      evm_votes: 967,
      postal_votes: 3,
      total_votes: 970,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 26,
      candidate: "RAJKUMAR SAHU",
      party: "Independent",
      evm_votes: 828,
      postal_votes: 1,
      total_votes: 829,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 27,
      candidate: "BHAGWAT PATRE",
      party: "Prabuddha Republican Party",
      evm_votes: 710,
      postal_votes: 2,
      total_votes: 712,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 28,
      candidate: "LAKSHMAN PATHAK",
      party: "Ekam Sanatan Bharat Dal",
      evm_votes: 698,
      postal_votes: 1,
      total_votes: 699,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 29,
      candidate: "ADVOCATE SHAILENDRA BANJARE (SHAKTIPUTRA)",
      party: "Shakti Sena (Bharat Desh)",
      evm_votes: 690,
      postal_votes: 0,
      total_votes: 690,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 30,
      candidate: "RAJENDRA KUMAR TANDAN",
      party: "Independent",
      evm_votes: 654,
      postal_votes: 2,
      total_votes: 656,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 31,
      candidate: "SALIK RAM JOGI",
      party: "Independent",
      evm_votes: 575,
      postal_votes: 1,
      total_votes: 576,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 32,
      candidate: "BASANT BANSL",
      party: "Independent",
      evm_votes: 564,
      postal_votes: 0,
      total_votes: 564,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 33,
      candidate: "DEEPMALA CHOUHAN",
      party: "Chhattisgarh Vikas Ganga Rashtriya Party",
      evm_votes: 559,
      postal_votes: 6,
      total_votes: 565,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 34,
      candidate: "MAHESH KUMAR SONWANI",
      party: "Independent",
      evm_votes: 559,
      postal_votes: 1,
      total_votes: 560,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 35,
      candidate: "DEV PRASAD BRAIHA",
      party: "Independent",
      evm_votes: 511,
      postal_votes: 0,
      total_votes: 511,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 36,
      candidate: "SANJAY PRAKASH SAHU",
      party: "Independent",
      evm_votes: 435,
      postal_votes: 1,
      total_votes: 436,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 37,
      candidate: "PARAS RAM YADAV",
      party: "Independent",
      evm_votes: 414,
      postal_votes: 1,
      total_votes: 415,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 38,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 2842,
      postal_votes: 7,
      total_votes: 2849,
      percentage_of_votes: 0.21,
    },
  ],
  total: {
    evm_votes: 1358037,
    postal_votes: 3313,
    total_votes: 1361350,
  },
};
export const s266 = {
  cs_name: "RAJNANDGAON",
  cs_shortname: "rajnandgaon",
  cs_code: "s266",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SANTOSH PANDEY",
      party: "Bharatiya Janata Party",
      evm_votes: 711439,
      postal_votes: 618,
      total_votes: 712057,
      percentage_of_votes: 49.25,
    },
    {
      serial_no: 2,
      candidate: "BHUPESH BAGHEL",
      party: "Indian National Congress",
      evm_votes: 667078,
      postal_votes: 568,
      total_votes: 667646,
      percentage_of_votes: 46.18,
    },
    {
      serial_no: 3,
      candidate: "A.H. SIDDIQUI",
      party: "Independent",
      evm_votes: 10737,
      postal_votes: 0,
      total_votes: 10737,
      percentage_of_votes: 0.74,
    },
    {
      serial_no: 4,
      candidate: "DEVLAL SINHA (SONVANSHI)",
      party: "Bahujan Samaj Party",
      evm_votes: 9659,
      postal_votes: 9,
      total_votes: 9668,
      percentage_of_votes: 0.67,
    },
    {
      serial_no: 5,
      candidate: "NARAD PRASAD NISHAD",
      party: "Shakti Sena (Bharat Desh)",
      evm_votes: 8029,
      postal_votes: 1,
      total_votes: 8030,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 6,
      candidate: "SUKHDEV SINHA",
      party: "Independent",
      evm_votes: 7998,
      postal_votes: 3,
      total_votes: 8001,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 7,
      candidate: "VISHESH DHAMGAYE",
      party: "Independent",
      evm_votes: 4872,
      postal_votes: 1,
      total_votes: 4873,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 8,
      candidate: "BHUVAN SAHU",
      party: "Independent",
      evm_votes: 4171,
      postal_votes: 3,
      total_votes: 4174,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 9,
      candidate: "LALITA KANWAR",
      party: "Hamar Raj Party",
      evm_votes: 2139,
      postal_votes: 10,
      total_votes: 2149,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "ER. BASANT KUMAR MESHRAM",
      party: "Independent",
      evm_votes: 1818,
      postal_votes: 2,
      total_votes: 1820,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "RAMESH RAJPUT",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 1735,
      postal_votes: 2,
      total_votes: 1737,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "TRIVENI PADOTI",
      party: "Independent",
      evm_votes: 1607,
      postal_votes: 6,
      total_votes: 1613,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 13,
      candidate: "RAMFAL PATIL",
      party: "Nyaydharmsabha",
      evm_votes: 1535,
      postal_votes: 2,
      total_votes: 1537,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 14,
      candidate: "AJAY PALI",
      party: "Independent",
      evm_votes: 1114,
      postal_votes: 0,
      total_votes: 1114,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "LAKHAN SING TANDAN",
      party: "Rashtriya Jansabha Party",
      evm_votes: 1056,
      postal_votes: 0,
      total_votes: 1056,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9152,
      postal_votes: 401,
      total_votes: 9553,
      percentage_of_votes: 0.66,
    },
  ],
  total: {
    evm_votes: 1444139,
    postal_votes: 1626,
    total_votes: 1445765,
  },
};
export const s267 = {
  cs_name: "DURG",
  cs_shortname: "durg",
  cs_code: "s267",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "VIJAY BAGHEL",
      party: "Bharatiya Janata Party",
      evm_votes: 954356,
      postal_votes: 2141,
      total_votes: 956497,
      percentage_of_votes: 62,
    },
    {
      serial_no: 2,
      candidate: "RAJENDRA SAHU",
      party: "Indian National Congress",
      evm_votes: 517277,
      postal_votes: 994,
      total_votes: 518271,
      percentage_of_votes: 33.59,
    },
    {
      serial_no: 3,
      candidate: "DILIP RAMTEKE",
      party: "Bahujan Samaj Party",
      evm_votes: 13290,
      postal_votes: 43,
      total_votes: 13333,
      percentage_of_votes: 0.86,
    },
    {
      serial_no: 4,
      candidate: "ANOOP KUMAR PANDEY",
      party: "Independent",
      evm_votes: 7765,
      postal_votes: 1,
      total_votes: 7766,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 5,
      candidate: "DR. ANJU KEME",
      party: "Ekam Sanatan Bharat Dal",
      evm_votes: 5237,
      postal_votes: 4,
      total_votes: 5241,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 6,
      candidate: "ALI HUSSAIN SIDDIQUI",
      party: "Independent",
      evm_votes: 5046,
      postal_votes: 4,
      total_votes: 5050,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 7,
      candidate: "ARUN JOSHI",
      party: "Independent",
      evm_votes: 4751,
      postal_votes: 2,
      total_votes: 4753,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 8,
      candidate: "DHRUV KUMAR SONI URF LANGUR SONI",
      party: "Independent",
      evm_votes: 3813,
      postal_votes: 2,
      total_votes: 3815,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 9,
      candidate: "KHILANAND JASPAL",
      party: "Independent",
      evm_votes: 3280,
      postal_votes: 1,
      total_votes: 3281,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 10,
      candidate: "SUKHADEV TANDON",
      party: "Rashtriya Jansabha Party",
      evm_votes: 2615,
      postal_votes: 2,
      total_votes: 2617,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 11,
      candidate: "ASHOK JAIN",
      party: "Independent",
      evm_votes: 1902,
      postal_votes: 0,
      total_votes: 1902,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "BALDEV SAHU",
      party: "Independent",
      evm_votes: 1610,
      postal_votes: 3,
      total_votes: 1613,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 13,
      candidate: "PUSHPA MAIRISHA",
      party: "Ambedkarite Party of India",
      evm_votes: 1556,
      postal_votes: 5,
      total_votes: 1561,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 14,
      candidate: "HARICHAND THAKUR",
      party: "Independent",
      evm_votes: 1451,
      postal_votes: 1,
      total_votes: 1452,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 15,
      candidate: "SAVITA SHAILENDRA BANJARE",
      party: "Shakti Sena (Bharat Desh)",
      evm_votes: 1393,
      postal_votes: 3,
      total_votes: 1396,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 16,
      candidate: "RAKESH SAHU",
      party: "Nyaydharmsabha",
      evm_votes: 1261,
      postal_votes: 1,
      total_votes: 1262,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 17,
      candidate: "SHITKARAN MAHILWAR",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 1181,
      postal_votes: 2,
      total_votes: 1183,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 18,
      candidate: "DR. HARISHCHANDRA SAHU",
      party: "Independent",
      evm_votes: 1116,
      postal_votes: 0,
      total_votes: 1116,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 19,
      candidate: "SHANKAR THAKUR",
      party: "Gondvana Gantantra Party",
      evm_votes: 1064,
      postal_votes: 9,
      total_votes: 1073,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 20,
      candidate: "YASHWANT SITARAM SAHU",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 899,
      postal_votes: 2,
      total_votes: 901,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 21,
      candidate: "SHYAM SUNDER SAHU",
      party: "Loktantra Congress Party",
      evm_votes: 897,
      postal_votes: 2,
      total_votes: 899,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 22,
      candidate: "VIKAS SHARMA",
      party: "Lokshahi Ekta Party",
      evm_votes: 798,
      postal_votes: 0,
      total_votes: 798,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 23,
      candidate: "SANTOSH KUMAR MARKANDE",
      party: "Independent",
      evm_votes: 646,
      postal_votes: 4,
      total_votes: 650,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 24,
      candidate: "BHAGBALI SIVARE",
      party: "Independent",
      evm_votes: 388,
      postal_votes: 1,
      total_votes: 389,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 25,
      candidate: "BHANUPRATAP CHATURVEDI",
      party: "Independent",
      evm_votes: 351,
      postal_votes: 0,
      total_votes: 351,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 26,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5596,
      postal_votes: 21,
      total_votes: 5617,
      percentage_of_votes: 0.36,
    },
  ],
  total: {
    evm_votes: 1539539,
    postal_votes: 3248,
    total_votes: 1542787,
  },
};
export const s268 = {
  cs_name: "RAIPUR",
  cs_shortname: "raipur",
  cs_code: "s268",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BRIJMOHAN AGRAWAL",
      party: "Bharatiya Janata Party",
      evm_votes: 1047447,
      postal_votes: 2904,
      total_votes: 1050351,
      percentage_of_votes: 66.19,
    },
    {
      serial_no: 2,
      candidate: "VIKAS UPADHYAY",
      party: "Indian National Congress",
      evm_votes: 473656,
      postal_votes: 1410,
      total_votes: 475066,
      percentage_of_votes: 29.94,
    },
    {
      serial_no: 3,
      candidate: "MAMTA RANI SAHU",
      party: "Bahujan Samaj Party",
      evm_votes: 9201,
      postal_votes: 98,
      total_votes: 9299,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 4,
      candidate: "SAVITA SHAILENDRA BANJARE",
      party: "Shakti Sena (Bharat Desh)",
      evm_votes: 8765,
      postal_votes: 3,
      total_votes: 8768,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 5,
      candidate: "RADHESHWARI GAIKWAD",
      party: "Independent",
      evm_votes: 4629,
      postal_votes: 1,
      total_votes: 4630,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 6,
      candidate: "SURESH KUMAR NETAM",
      party: "Hamar Raj Party",
      evm_votes: 3492,
      postal_votes: 7,
      total_votes: 3499,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 7,
      candidate: "ANIL MAHOBIA",
      party: "Right to Recall Party",
      evm_votes: 2914,
      postal_votes: 2,
      total_votes: 2916,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "ENGI. LAL BAHADUR YADAV",
      party: "Gondvana Gantantra Party",
      evm_votes: 2859,
      postal_votes: 6,
      total_votes: 2865,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 9,
      candidate: "RAMKRISHNA VERMA",
      party: "Independent",
      evm_votes: 2182,
      postal_votes: 3,
      total_votes: 2185,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "YAKUB KHAN",
      party: "Independent",
      evm_votes: 2054,
      postal_votes: 0,
      total_votes: 2054,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "VISHWAJIT HARODE",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1920,
      postal_votes: 0,
      total_votes: 1920,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "AMARNATH CHANDRAKAR",
      party: "Independent",
      evm_votes: 1608,
      postal_votes: 0,
      total_votes: 1608,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 13,
      candidate: "LAKHAMOO RAM TANDON",
      party: "Rashtriya Jansabha Party",
      evm_votes: 1347,
      postal_votes: 4,
      total_votes: 1351,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "YASHUTOSH LAHARE",
      party: "Ambedkarite Party of India",
      evm_votes: 1312,
      postal_votes: 0,
      total_votes: 1312,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "RAJESH DHRUV",
      party: "Independent",
      evm_votes: 1187,
      postal_votes: 6,
      total_votes: 1193,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 16,
      candidate: "BODHAN LAL FARIKAR",
      party: "Independent",
      evm_votes: 1059,
      postal_votes: 0,
      total_votes: 1059,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 17,
      candidate: "DAYA SHANKER NISHAD",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 1051,
      postal_votes: 11,
      total_votes: 1062,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "PILARAM ANANT",
      party: "Sunder Samaj Party",
      evm_votes: 1033,
      postal_votes: 3,
      total_votes: 1036,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 19,
      candidate: "ROHIT KUMAR PATIL",
      party: "Independent",
      evm_votes: 960,
      postal_votes: 0,
      total_votes: 960,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 20,
      candidate: "HIRA NAND NAGWANI (ASHOK BHAIYA)",
      party: "Republican Party of India (A)",
      evm_votes: 958,
      postal_votes: 2,
      total_votes: 960,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 21,
      candidate: "BHANJAN JANGADE (ADHIWAKTA)",
      party: "Bharatiya Bahujan Congress",
      evm_votes: 818,
      postal_votes: 3,
      total_votes: 821,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 22,
      candidate: "MO. AMIN",
      party: "Bhrashtachar Mukti Morcha",
      evm_votes: 765,
      postal_votes: 1,
      total_votes: 766,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 23,
      candidate: "MANOJ VERMA",
      party: "Independent",
      evm_votes: 723,
      postal_votes: 1,
      total_votes: 724,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 24,
      candidate: "PITAMBAR JANGDE",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 701,
      postal_votes: 4,
      total_votes: 705,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 25,
      candidate: "ASHISH KUMAR TIWARI",
      party: "Aap Sabki Apni Party",
      evm_votes: 525,
      postal_votes: 3,
      total_votes: 528,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 26,
      candidate: "ENGI. BHANU PRATAP TANDE",
      party: "Independent",
      evm_votes: 500,
      postal_votes: 3,
      total_votes: 503,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 27,
      candidate: "MO. IMRAN KHAN",
      party: "Independent",
      evm_votes: 466,
      postal_votes: 1,
      total_votes: 467,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 28,
      candidate: "NEERAJ SAINI PUJARI",
      party: "Dhoom Sena",
      evm_votes: 434,
      postal_votes: 0,
      total_votes: 434,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 29,
      candidate: "SUDHANSHU BHUSHAN",
      party: "Independent",
      evm_votes: 413,
      postal_votes: 1,
      total_votes: 414,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 30,
      candidate: "RAMPRASAD PRAJAPATI",
      party: "Independent",
      evm_votes: 391,
      postal_votes: 0,
      total_votes: 391,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 31,
      candidate: "PRAVIN JAIN",
      party: "Independent",
      evm_votes: 369,
      postal_votes: 0,
      total_votes: 369,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 32,
      candidate: "VINAYAK DHAMGAYE",
      party: "Independent",
      evm_votes: 349,
      postal_votes: 0,
      total_votes: 349,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 33,
      candidate: "NANDNI NAYAK",
      party: "Independent",
      evm_votes: 336,
      postal_votes: 1,
      total_votes: 337,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 34,
      candidate: "PRAVISHANT SALOMAN",
      party: "Independent",
      evm_votes: 310,
      postal_votes: 0,
      total_votes: 310,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 35,
      candidate: "SAYED IRSHAD",
      party: "Independent",
      evm_votes: 295,
      postal_votes: 0,
      total_votes: 295,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 36,
      candidate: "DR. OMPRAKASH SAHU",
      party: "Independent",
      evm_votes: 291,
      postal_votes: 3,
      total_votes: 294,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 37,
      candidate: "MO. NASIR",
      party: "Independent",
      evm_votes: 254,
      postal_votes: 0,
      total_votes: 254,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 38,
      candidate: "NURI KHAN",
      party: "Independent",
      evm_votes: 252,
      postal_votes: 0,
      total_votes: 252,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 39,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4414,
      postal_votes: 34,
      total_votes: 4448,
      percentage_of_votes: 0.28,
    },
  ],
  total: {
    evm_votes: 1582240,
    postal_votes: 4515,
    total_votes: 1586755,
  },
};
export const s269 = {
  cs_name: "MAHASAMUND",
  cs_shortname: "mahasamund",
  cs_code: "s269",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ROOP KUMARI CHOUDHARY",
      party: "Bharatiya Janata Party",
      evm_votes: 701291,
      postal_votes: 2368,
      total_votes: 703659,
      percentage_of_votes: 53.06,
    },
    {
      serial_no: 2,
      candidate: "TAMRADHWAJ SAHU",
      party: "Indian National Congress",
      evm_votes: 556764,
      postal_votes: 1439,
      total_votes: 558203,
      percentage_of_votes: 42.09,
    },
    {
      serial_no: 3,
      candidate: "PROFESSOR SURESH SAHU",
      party: "Independent",
      evm_votes: 11885,
      postal_votes: 3,
      total_votes: 11888,
      percentage_of_votes: 0.9,
    },
    {
      serial_no: 4,
      candidate: "BASANT SINHA",
      party: "Bahujan Samaj Party",
      evm_votes: 8823,
      postal_votes: 37,
      total_votes: 8860,
      percentage_of_votes: 0.67,
    },
    {
      serial_no: 5,
      candidate: "REKHRAM BAGH",
      party: "Independent",
      evm_votes: 7877,
      postal_votes: 1,
      total_votes: 7878,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 6,
      candidate: "GANESH RAM DHRUW",
      party: "Hamar Raj Party",
      evm_votes: 6626,
      postal_votes: 25,
      total_votes: 6651,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 7,
      candidate: "SANTOSH DARCHAND BANJARE",
      party: "Independent",
      evm_votes: 6049,
      postal_votes: 5,
      total_votes: 6054,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 8,
      candidate: "MUKESH KUMAR AGRAWAL",
      party: "Independent",
      evm_votes: 3689,
      postal_votes: 2,
      total_votes: 3691,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 9,
      candidate: "SUKHNANDAN DESHKAR",
      party: "Independent",
      evm_votes: 3554,
      postal_votes: 1,
      total_votes: 3555,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 10,
      candidate: "NITESH KUMAR RATRE",
      party: "Sunder Samaj Party",
      evm_votes: 2366,
      postal_votes: 7,
      total_votes: 2373,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 11,
      candidate: "DR. VIRENDRA CHAUDHRI",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 2093,
      postal_votes: 6,
      total_votes: 2099,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 12,
      candidate: "KALIYA PRASAD SETH",
      party: "Independent",
      evm_votes: 1528,
      postal_votes: 3,
      total_votes: 1531,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "MAHESH SWARN",
      party: "Independent",
      evm_votes: 1484,
      postal_votes: 2,
      total_votes: 1486,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 14,
      candidate: "CHAMPA LAL PATEL GURUJI DHARTI PAKD",
      party: "Right to Recall Party",
      evm_votes: 1376,
      postal_votes: 3,
      total_votes: 1379,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "FARID QURAISHI",
      party: "Gondvana Gantantra Party",
      evm_votes: 1074,
      postal_votes: 3,
      total_votes: 1077,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 16,
      candidate: "ISHWAR MARKANDE",
      party: "Independent",
      evm_votes: 957,
      postal_votes: 0,
      total_votes: 957,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 17,
      candidate: "NARAD PRASAD NISHAD",
      party: "Shakti Sena (Bharat Desh)",
      evm_votes: 897,
      postal_votes: 2,
      total_votes: 899,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3801,
      postal_votes: 39,
      total_votes: 3840,
      percentage_of_votes: 0.29,
    },
  ],
  total: {
    evm_votes: 1322134,
    postal_votes: 3946,
    total_votes: 1326080,
  },
};
export const s2610 = {
  cs_name: "BASTAR",
  cs_shortname: "bastar",
  cs_code: "s2610",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "MAHESH KASHYAP",
      party: "Bharatiya Janata Party",
      evm_votes: 456573,
      postal_votes: 1825,
      total_votes: 458398,
      percentage_of_votes: 45.5,
    },
    {
      serial_no: 2,
      candidate: "KAWASI LAKHMA",
      party: "Indian National Congress",
      evm_votes: 402378,
      postal_votes: 775,
      total_votes: 403153,
      percentage_of_votes: 40.02,
    },
    {
      serial_no: 3,
      candidate: "PHULSINGH KACHLAM",
      party: "Communist Party of India",
      evm_votes: 35850,
      postal_votes: 37,
      total_votes: 35887,
      percentage_of_votes: 3.56,
    },
    {
      serial_no: 4,
      candidate: "AYTU RAM MANDAVI",
      party: "Bahujan Samaj Party",
      evm_votes: 19615,
      postal_votes: 32,
      total_votes: 19647,
      percentage_of_votes: 1.95,
    },
    {
      serial_no: 5,
      candidate: "SUNDAR BAGHEL",
      party: "Independent",
      evm_votes: 14203,
      postal_votes: 4,
      total_votes: 14207,
      percentage_of_votes: 1.41,
    },
    {
      serial_no: 6,
      candidate: "PRAKASH KUMAR GOTA",
      party: "Independent",
      evm_votes: 11209,
      postal_votes: 36,
      total_votes: 11245,
      percentage_of_votes: 1.12,
    },
    {
      serial_no: 7,
      candidate: "KANWAL SINGH BAGHEL",
      party: "Rashtriya Jansabha Party",
      evm_votes: 8785,
      postal_votes: 15,
      total_votes: 8800,
      percentage_of_votes: 0.87,
    },
    {
      serial_no: 8,
      candidate: "SHIVRAM NAG",
      party: "Sarv Adi Dal",
      evm_votes: 8269,
      postal_votes: 10,
      total_votes: 8279,
      percentage_of_votes: 0.82,
    },
    {
      serial_no: 9,
      candidate: "NARENDRA BURKA",
      party: "Hamar Raj Party",
      evm_votes: 4382,
      postal_votes: 5,
      total_votes: 4387,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 10,
      candidate: "JAGDISH NAG",
      party: "Azad Janata Party",
      evm_votes: 3569,
      postal_votes: 23,
      total_votes: 3592,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 11,
      candidate: "TIKAM NAGWANSHI",
      party: "Gondvana Gantantra Party",
      evm_votes: 3030,
      postal_votes: 12,
      total_votes: 3042,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 36733,
      postal_votes: 25,
      total_votes: 36758,
      percentage_of_votes: 3.65,
    },
  ],
  total: {
    evm_votes: 1004596,
    postal_votes: 2799,
    total_votes: 1007395,
  },
};
export const s2611 = {
  cs_name: "KANKER",
  cs_shortname: "kanker",
  cs_code: "s2611",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BHOJRAJ NAG",
      party: "Bharatiya Janata Party",
      evm_votes: 595185,
      postal_votes: 2439,
      total_votes: 597624,
      percentage_of_votes: 47.23,
    },
    {
      serial_no: 2,
      candidate: "BIRESH THAKUR",
      party: "Indian National Congress",
      evm_votes: 593268,
      postal_votes: 2472,
      total_votes: 595740,
      percentage_of_votes: 47.08,
    },
    {
      serial_no: 3,
      candidate: "TILAK RAM MARKAM",
      party: "Bahujan Samaj Party",
      evm_votes: 11694,
      postal_votes: 76,
      total_votes: 11770,
      percentage_of_votes: 0.93,
    },
    {
      serial_no: 4,
      candidate: "SONSINGH",
      party: "Ambedkarite Party of India",
      evm_votes: 11351,
      postal_votes: 11,
      total_votes: 11362,
      percentage_of_votes: 0.9,
    },
    {
      serial_no: 5,
      candidate: "JIVAN LAL MATLAM",
      party: "Sarv Adi Dal",
      evm_votes: 8935,
      postal_votes: 14,
      total_votes: 8949,
      percentage_of_votes: 0.71,
    },
    {
      serial_no: 6,
      candidate: "SUKCHAND NETAM",
      party: "Gondvana Gantantra Party",
      evm_votes: 8617,
      postal_votes: 106,
      total_votes: 8723,
      percentage_of_votes: 0.69,
    },
    {
      serial_no: 7,
      candidate: "VINOD NAGWANSHI",
      party: "Hamar Raj Party",
      evm_votes: 4958,
      postal_votes: 51,
      total_votes: 5009,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 8,
      candidate: "THAKESH MAHLA",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 4201,
      postal_votes: 21,
      total_votes: 4222,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 9,
      candidate: "BHOJRAM MANDAVI",
      party: "Rashtriya Jansabha Party",
      evm_votes: 3326,
      postal_votes: 35,
      total_votes: 3361,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 18618,
      postal_votes: 51,
      total_votes: 18669,
      percentage_of_votes: 1.48,
    },
  ],
  total: {
    evm_votes: 1260153,
    postal_votes: 5276,
    total_votes: 1265429,
  },
};
export const s273 = {
  cs_name: "Godda",
  cs_shortname: "godda",
  cs_code: "s273",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "NISHIKANT DUBEY",
      party: "Bharatiya Janata Party",
      evm_votes: 688879,
      postal_votes: 4261,
      total_votes: 693140,
      percentage_of_votes: 49.57,
    },
    {
      serial_no: 2,
      candidate: "PRADEEP YADAV",
      party: "Indian National Congress",
      evm_votes: 586104,
      postal_votes: 5223,
      total_votes: 591327,
      percentage_of_votes: 42.29,
    },
    {
      serial_no: 3,
      candidate: "UDAY SHANKAR KHAWARE",
      party: "Independent",
      evm_votes: 26831,
      postal_votes: 6,
      total_votes: 26837,
      percentage_of_votes: 1.92,
    },
    {
      serial_no: 4,
      candidate: "ARUN MARIK",
      party: "Independent",
      evm_votes: 17436,
      postal_votes: 1,
      total_votes: 17437,
      percentage_of_votes: 1.25,
    },
    {
      serial_no: 5,
      candidate: "NILESH KUMAR GUPTA",
      party: "Independent",
      evm_votes: 10485,
      postal_votes: 3,
      total_votes: 10488,
      percentage_of_votes: 0.75,
    },
    {
      serial_no: 6,
      candidate: "AVISEK ANAND JHA",
      party: "Independent",
      evm_votes: 9601,
      postal_votes: 35,
      total_votes: 9636,
      percentage_of_votes: 0.69,
    },
    {
      serial_no: 7,
      candidate: "BAJRANGI MAHTHA",
      party: "Bahujan Samaj Party",
      evm_votes: 8151,
      postal_votes: 24,
      total_votes: 8175,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 8,
      candidate: "MUKESH KUMAR JHA",
      party: "Independent",
      evm_votes: 7366,
      postal_votes: 0,
      total_votes: 7366,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 9,
      candidate: "KUMARI DILESHWARI",
      party: "Ambedkarite Party of India",
      evm_votes: 6578,
      postal_votes: 3,
      total_votes: 6581,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 10,
      candidate: "RAMESHWAR MANDAL",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 3315,
      postal_votes: 1,
      total_votes: 3316,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 11,
      candidate: "SURAJ KUMAR AMAN",
      party: "Bhagidari Party(P)",
      evm_votes: 3070,
      postal_votes: 3,
      total_votes: 3073,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 12,
      candidate: "ARUN KUMAR",
      party: "Nyaydharmsabha",
      evm_votes: 2891,
      postal_votes: 2,
      total_votes: 2893,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 13,
      candidate: "DR. K. RANGAIAH",
      party: "Independent",
      evm_votes: 2205,
      postal_votes: 5,
      total_votes: 2210,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 14,
      candidate: "BRAJKISHORE PANDIT",
      party: "Akhil Bhartiya Manavadhikar Vichar Manch Party",
      evm_votes: 2102,
      postal_votes: 6,
      total_votes: 2108,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 15,
      candidate: "ANUP KUMAR",
      party: "Proutist Bloc, India",
      evm_votes: 2003,
      postal_votes: 4,
      total_votes: 2007,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 16,
      candidate: "KALIPADA MURMU",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1935,
      postal_votes: 6,
      total_votes: 1941,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 17,
      candidate: "KETAN KUMAR",
      party: "Right to Recall Party",
      evm_votes: 1896,
      postal_votes: 2,
      total_votes: 1898,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 18,
      candidate: "TIPLAL SAH",
      party: "Lokhit Adhikar Party",
      evm_votes: 1765,
      postal_votes: 5,
      total_votes: 1770,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 19,
      candidate: "GYANESHWAR JHA",
      party: "Jagrook Janta Party",
      evm_votes: 1637,
      postal_votes: 3,
      total_votes: 1640,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 20,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4309,
      postal_votes: 52,
      total_votes: 4361,
      percentage_of_votes: 0.31,
    },
  ],
  total: {
    evm_votes: 1388559,
    postal_votes: 9645,
    total_votes: 1398204,
  },
};
export const s274 = {
  cs_name: "Chatra",
  cs_shortname: "chatra",
  cs_code: "s274",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "KALI CHARAN SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 568912,
      postal_votes: 5644,
      total_votes: 574556,
      percentage_of_votes: 52.89,
    },
    {
      serial_no: 2,
      candidate: "KRISHNA NAND TRIPATHI",
      party: "Indian National Congress",
      evm_votes: 348110,
      postal_votes: 5487,
      total_votes: 353597,
      percentage_of_votes: 32.55,
    },
    {
      serial_no: 3,
      candidate: "AMIT KUMAR SINGH",
      party: "Independent",
      evm_votes: 31976,
      postal_votes: 15,
      total_votes: 31991,
      percentage_of_votes: 2.94,
    },
    {
      serial_no: 4,
      candidate: "ARJUN KUMAR",
      party: "Communist Party of India",
      evm_votes: 14062,
      postal_votes: 60,
      total_votes: 14122,
      percentage_of_votes: 1.3,
    },
    {
      serial_no: 5,
      candidate: "DR. ABHISHEK KUMAR SINGH",
      party: "Independent",
      evm_votes: 14038,
      postal_votes: 68,
      total_votes: 14106,
      percentage_of_votes: 1.3,
    },
    {
      serial_no: 6,
      candidate: "ARJUN PRAJAPATI",
      party: "Independent",
      evm_votes: 13320,
      postal_votes: 8,
      total_votes: 13328,
      percentage_of_votes: 1.23,
    },
    {
      serial_no: 7,
      candidate: "DEEPAK KUMAR GUPTA",
      party: "Independent",
      evm_votes: 12384,
      postal_votes: 182,
      total_votes: 12566,
      percentage_of_votes: 1.16,
    },
    {
      serial_no: 8,
      candidate: "NAGMANI",
      party: "Bahujan Samaj Party",
      evm_votes: 11798,
      postal_votes: 152,
      total_votes: 11950,
      percentage_of_votes: 1.1,
    },
    {
      serial_no: 9,
      candidate: "CHANDAN KUMAR",
      party: "Independent",
      evm_votes: 6569,
      postal_votes: 13,
      total_votes: 6582,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 10,
      candidate: "MD ABUZAR KHAN",
      party: "Independent",
      evm_votes: 5820,
      postal_votes: 3,
      total_votes: 5823,
      percentage_of_votes: 0.54,
    },
    {
      serial_no: 11,
      candidate: "YOGESH KUMAR SINGH",
      party: "Independent",
      evm_votes: 5110,
      postal_votes: 2,
      total_votes: 5112,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 12,
      candidate: "BIMAL LAKRA",
      party: "Ambedkarite Party of India",
      evm_votes: 4983,
      postal_votes: 31,
      total_votes: 5014,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 13,
      candidate: "KARMLAL ORAON",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 3968,
      postal_votes: 42,
      total_votes: 4010,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 14,
      candidate: "SUMIT KUMAR YADAV",
      party: "Samata Party",
      evm_votes: 3499,
      postal_votes: 17,
      total_votes: 3516,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 15,
      candidate: "SANJAY KUMAR SNEHI",
      party: "Lokhit Adhikar Party",
      evm_votes: 3202,
      postal_votes: 14,
      total_votes: 3216,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 16,
      candidate: "MAHESH BANDO",
      party: "Bahujan Mukti Party",
      evm_votes: 3159,
      postal_votes: 42,
      total_votes: 3201,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 17,
      candidate: "SRIRAM SINGH",
      party: "Independent",
      evm_votes: 2991,
      postal_votes: 2,
      total_votes: 2993,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 18,
      candidate: "DARSHAN GANJHU",
      party: "Jharkhand Party",
      evm_votes: 2780,
      postal_votes: 37,
      total_votes: 2817,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 19,
      candidate: "DULESHWAR SAW",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 2376,
      postal_votes: 9,
      total_votes: 2385,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 20,
      candidate: "LAW KUMAR PANDIT",
      party: "Bhagidari Party(P)",
      evm_votes: 2372,
      postal_votes: 8,
      total_votes: 2380,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 21,
      candidate: "KAMDEO DIHO RANA",
      party: "Rashtriya Jan Utkarsh Party",
      evm_votes: 2297,
      postal_votes: 12,
      total_votes: 2309,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 22,
      candidate: "VIKRANT KUMAR SINGH",
      party: "Independent",
      evm_votes: 2288,
      postal_votes: 1,
      total_votes: 2289,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 23,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8435,
      postal_votes: 76,
      total_votes: 8511,
      percentage_of_votes: 0.78,
    },
  ],
  total: {
    evm_votes: 1074449,
    postal_votes: 11925,
    total_votes: 1086374,
  },
};
export const s275 = {
  cs_name: "Kodarma",
  cs_shortname: "kodarma",
  cs_code: "s275",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ANNPURNA DEVI",
      party: "Bharatiya Janata Party",
      evm_votes: 787185,
      postal_votes: 4472,
      total_votes: 791657,
      percentage_of_votes: 57.79,
    },
    {
      serial_no: 2,
      candidate: "VINOD KUMAR SINGH",
      party: "Communist Party of India (Marxist-Leninist) (Liberation)",
      evm_votes: 410945,
      postal_votes: 3698,
      total_votes: 414643,
      percentage_of_votes: 30.27,
    },
    {
      serial_no: 3,
      candidate: "MANOJ KUMAR",
      party: "Independent",
      evm_votes: 28310,
      postal_votes: 302,
      total_votes: 28612,
      percentage_of_votes: 2.09,
    },
    {
      serial_no: 4,
      candidate: "JAI PRAKASH VERMA",
      party: "Independent",
      evm_votes: 22843,
      postal_votes: 380,
      total_votes: 23223,
      percentage_of_votes: 1.7,
    },
    {
      serial_no: 5,
      candidate: "SITAN RABIDAS",
      party: "Bahujan Samaj Party",
      evm_votes: 13067,
      postal_votes: 107,
      total_votes: 13174,
      percentage_of_votes: 0.96,
    },
    {
      serial_no: 6,
      candidate: "SURENDRA KUMAR AGRAWAL",
      party: "Independent",
      evm_votes: 9646,
      postal_votes: 10,
      total_votes: 9656,
      percentage_of_votes: 0.7,
    },
    {
      serial_no: 7,
      candidate: "SAHADAT ANSARI",
      party: "Independent",
      evm_votes: 8217,
      postal_votes: 15,
      total_votes: 8232,
      percentage_of_votes: 0.6,
    },
    {
      serial_no: 8,
      candidate: "JAYNARAYAN DAS",
      party: "Bahujan Mukti Party",
      evm_votes: 6701,
      postal_votes: 37,
      total_votes: 6738,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 9,
      candidate: "AKLESHWAR SAW",
      party: "Lokhit Adhikar Party",
      evm_votes: 5574,
      postal_votes: 27,
      total_votes: 5601,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 10,
      candidate: "JITLAL KISKU",
      party: "Independent",
      evm_votes: 5299,
      postal_votes: 34,
      total_votes: 5333,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 11,
      candidate: "MD. SAGIR",
      party: "Independent",
      evm_votes: 5041,
      postal_votes: 3,
      total_votes: 5044,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 12,
      candidate: "AJAY KRISHNA",
      party: "Moolniwasi Samaj Party",
      evm_votes: 4526,
      postal_votes: 15,
      total_votes: 4541,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 13,
      candidate: "RAJESH",
      party: "Independent",
      evm_votes: 4230,
      postal_votes: 3,
      total_votes: 4233,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 14,
      candidate: "RAMESHWAR PRASAD YADAV",
      party: "Independent",
      evm_votes: 4227,
      postal_votes: 2,
      total_votes: 4229,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 15,
      candidate: "ASHISH KUMAR",
      party: "Right to Recall Party",
      evm_votes: 2892,
      postal_votes: 4,
      total_votes: 2896,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 42089,
      postal_votes: 63,
      total_votes: 42152,
      percentage_of_votes: 3.08,
    },
  ],
  total: {
    evm_votes: 1360792,
    postal_votes: 9172,
    total_votes: 1369964,
  },
};
export const s277 = {
  cs_name: "Dhanbad",
  cs_shortname: "dhanbad",
  cs_code: "s277",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DULU MAHATO",
      party: "Bharatiya Janata Party",
      evm_votes: 782423,
      postal_votes: 6749,
      total_votes: 789172,
      percentage_of_votes: 55.26,
    },
    {
      serial_no: 2,
      candidate: "ANUPAMA SINGH",
      party: "Indian National Congress",
      evm_votes: 452358,
      postal_votes: 5231,
      total_votes: 457589,
      percentage_of_votes: 32.04,
    },
    {
      serial_no: 3,
      candidate: "MD. EKLAK ANSARI",
      party: "Independent",
      evm_votes: 78885,
      postal_votes: 768,
      total_votes: 79653,
      percentage_of_votes: 5.58,
    },
    {
      serial_no: 4,
      candidate: "JAGDISH RAWANI",
      party: "Independent",
      evm_votes: 27499,
      postal_votes: 136,
      total_votes: 27635,
      percentage_of_votes: 1.94,
    },
    {
      serial_no: 5,
      candidate: "JANAK SAH GOND",
      party: "Independent",
      evm_votes: 12761,
      postal_votes: 3,
      total_votes: 12764,
      percentage_of_votes: 0.89,
    },
    {
      serial_no: 6,
      candidate: "MD. ZAHIRUDDIN KHAN",
      party: "Independent",
      evm_votes: 6499,
      postal_votes: 1,
      total_votes: 6500,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 7,
      candidate: "MOHAN SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 5594,
      postal_votes: 45,
      total_votes: 5639,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 8,
      candidate: "TULSI MAHTO",
      party: "Independent",
      evm_votes: 5371,
      postal_votes: 4,
      total_votes: 5375,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 9,
      candidate: "KAMASHEVAR PRA. VERMA",
      party: "Independent",
      evm_votes: 4002,
      postal_votes: 2,
      total_votes: 4004,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 10,
      candidate: "SUNAINA KINNAR",
      party: "Independent",
      evm_votes: 3430,
      postal_votes: 32,
      total_votes: 3462,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 11,
      candidate: "AKBAR ALI",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 3031,
      postal_votes: 10,
      total_votes: 3041,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 12,
      candidate: "KRISHNA CHANDRA SINGH RAJ",
      party: "Samata Party",
      evm_votes: 2818,
      postal_votes: 3,
      total_votes: 2821,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 13,
      candidate: "ANINDITA DAS",
      party: "Independent",
      evm_votes: 2655,
      postal_votes: 19,
      total_votes: 2674,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 14,
      candidate: "REZAUL HAQUE",
      party: "Lokhit Adhikar Party",
      evm_votes: 2642,
      postal_votes: 7,
      total_votes: 2649,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 15,
      candidate: "LAKSHMI DEVI",
      party: "Independent",
      evm_votes: 2600,
      postal_votes: 5,
      total_votes: 2605,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 16,
      candidate: "UMESH PASWAN",
      party: "Independent",
      evm_votes: 2554,
      postal_votes: 6,
      total_votes: 2560,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 17,
      candidate: "MOHAMMAD FAISHAL KHAN",
      party: "Independent",
      evm_votes: 1994,
      postal_votes: 2,
      total_votes: 1996,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 18,
      candidate: "RAJIB TIWARI",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1880,
      postal_votes: 10,
      total_votes: 1890,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 19,
      candidate: "DEEPAK KUMAR DAS",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 1720,
      postal_votes: 15,
      total_votes: 1735,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 20,
      candidate: "NITAI DUTTA",
      party: "Independent",
      evm_votes: 1695,
      postal_votes: 48,
      total_votes: 1743,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 21,
      candidate: "SANJAY KUMAR GIRI",
      party: "Akhil Bharat Hindu Mahasabha",
      evm_votes: 1617,
      postal_votes: 10,
      total_votes: 1627,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 22,
      candidate: "PERWEZ NEYYAR",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 1157,
      postal_votes: 4,
      total_votes: 1161,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 23,
      candidate: "TRIDEV KR MAHTO",
      party: "Independent",
      evm_votes: 1126,
      postal_votes: 18,
      total_votes: 1144,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 24,
      candidate: "MOHAMMAD TAFAZUL HUSSAIN",
      party: "Independent",
      evm_votes: 681,
      postal_votes: 0,
      total_votes: 681,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 25,
      candidate: "PREM PRAKASH PASWAN",
      party: "Independent",
      evm_votes: 516,
      postal_votes: 17,
      total_votes: 533,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 26,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7202,
      postal_votes: 152,
      total_votes: 7354,
      percentage_of_votes: 0.51,
    },
  ],
  total: {
    evm_votes: 1414710,
    postal_votes: 13297,
    total_votes: 1428007,
  },
};
export const s278 = {
  cs_name: "Ranchi",
  cs_shortname: "ranchi",
  cs_code: "s278",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "SANJAY SETH",
      party: "Bharatiya Janata Party",
      evm_votes: 658975,
      postal_votes: 5757,
      total_votes: 664732,
      percentage_of_votes: 45.91,
    },
    {
      serial_no: 2,
      candidate: "YASHASWINI SAHAY",
      party: "Indian National Congress",
      evm_votes: 537646,
      postal_votes: 6574,
      total_votes: 544220,
      percentage_of_votes: 37.59,
    },
    {
      serial_no: 3,
      candidate: "DEVENDRA NATH MAHTO",
      party: "Independent",
      evm_votes: 130526,
      postal_votes: 2121,
      total_votes: 132647,
      percentage_of_votes: 9.16,
    },
    {
      serial_no: 4,
      candidate: "SOMA SINGH",
      party: "Jai Maha Bharath Party",
      evm_votes: 10340,
      postal_votes: 1,
      total_votes: 10341,
      percentage_of_votes: 0.71,
    },
    {
      serial_no: 5,
      candidate: "NIPU SINGH",
      party: "Rashtriya Jansangharsh Swaraj Party",
      evm_votes: 7793,
      postal_votes: 10,
      total_votes: 7803,
      percentage_of_votes: 0.54,
    },
    {
      serial_no: 6,
      candidate: "DHANANJAY BHAGAT “GANDHI“",
      party: "Mera Adhikaar Rashtriya Dal",
      evm_votes: 6784,
      postal_votes: 15,
      total_votes: 6799,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 7,
      candidate: "SHYAM BIHARI PRAJAPATI",
      party: "Bhagidari Party(P)",
      evm_votes: 6524,
      postal_votes: 6,
      total_votes: 6530,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 8,
      candidate: "MANOJ KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 6383,
      postal_votes: 44,
      total_votes: 6427,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 9,
      candidate: "RAMHARI GOPE",
      party: "Ambedkarite Party of India",
      evm_votes: 6304,
      postal_votes: 5,
      total_votes: 6309,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 10,
      candidate: "ANJANI PANDEY",
      party: "Independent",
      evm_votes: 5386,
      postal_votes: 11,
      total_votes: 5397,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 11,
      candidate: "SARWESHWARI SAHU",
      party: "Ekam Sanatan Bharat Dal",
      evm_votes: 4794,
      postal_votes: 8,
      total_votes: 4802,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 12,
      candidate: "HARINATH SAHU",
      party: "Lokhit Adhikar Party",
      evm_votes: 4120,
      postal_votes: 16,
      total_votes: 4136,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 13,
      candidate: "HEMNTI DEVI",
      party: "Samata Party",
      evm_votes: 4069,
      postal_votes: 11,
      total_votes: 4080,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 14,
      candidate: "RANJANA GIRI",
      party: "Republican Party of India (A)",
      evm_votes: 3206,
      postal_votes: 2,
      total_votes: 3208,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 15,
      candidate: "BIRENDRA NATH MANJHI",
      party: "Abua Jharkhand Party",
      evm_votes: 3130,
      postal_votes: 25,
      total_votes: 3155,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 16,
      candidate: "DHARMENDRA TIWARY",
      party: "Bhartiya Jantantra Morcha",
      evm_votes: 3072,
      postal_votes: 22,
      total_votes: 3094,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 17,
      candidate: "PRAWEEN KACHHAP",
      party: "Sampoorna Bharat Kranti Party",
      evm_votes: 2870,
      postal_votes: 74,
      total_votes: 2944,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 18,
      candidate: "PRABEEN CHANDRA MAHATO",
      party: "Independent",
      evm_votes: 2852,
      postal_votes: 7,
      total_votes: 2859,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 19,
      candidate: "SANTOSH KUMAR JAISWAL",
      party: "Independent",
      evm_votes: 2782,
      postal_votes: 5,
      total_votes: 2787,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 20,
      candidate: "PANKAJ KUMAR RAVI",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 2756,
      postal_votes: 7,
      total_votes: 2763,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 21,
      candidate: "MANORANJAN BHATTACHARYA",
      party: "Independent",
      evm_votes: 2537,
      postal_votes: 2,
      total_votes: 2539,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 22,
      candidate: "AINUL ANSARI",
      party: "Independent",
      evm_votes: 2533,
      postal_votes: 4,
      total_votes: 2537,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 23,
      candidate: "BINOD ORAON",
      party: "Bahujan Mukti Party",
      evm_votes: 2523,
      postal_votes: 66,
      total_votes: 2589,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 24,
      candidate: "MINTU PASWAN",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2151,
      postal_votes: 13,
      total_votes: 2164,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 25,
      candidate: "KOLESHWAR MAHTO",
      party: "Independent",
      evm_votes: 1702,
      postal_votes: 6,
      total_votes: 1708,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 26,
      candidate: "KAMESHWAR PRASAD SAW",
      party: "Independent",
      evm_votes: 1644,
      postal_votes: 2,
      total_votes: 1646,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 27,
      candidate: "ARSHAD AYUB",
      party: "Independent",
      evm_votes: 1411,
      postal_votes: 1,
      total_votes: 1412,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 28,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8046,
      postal_votes: 107,
      total_votes: 8153,
      percentage_of_votes: 0.56,
    },
  ],
  total: {
    evm_votes: 1432859,
    postal_votes: 14922,
    total_votes: 1447781,
  },
};
export const s279 = {
  cs_name: "Jamshedpur",
  cs_shortname: "jamshedpur",
  cs_code: "s279",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BIDYUT BARAN MAHATO",
      party: "Bharatiya Janata Party",
      evm_votes: 719891,
      postal_votes: 6283,
      total_votes: 726174,
      percentage_of_votes: 56.84,
    },
    {
      serial_no: 2,
      candidate: "SAMIR KUMAR MOHANTY",
      party: "Jharkhand Mukti Morcha",
      evm_votes: 459997,
      postal_votes: 6395,
      total_votes: 466392,
      percentage_of_votes: 36.5,
    },
    {
      serial_no: 3,
      candidate: "INDRA DEO PRASAD",
      party: "Independent",
      evm_votes: 8463,
      postal_votes: 6,
      total_votes: 8469,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 4,
      candidate: "ARUN MAHATO",
      party: "Independent",
      evm_votes: 7337,
      postal_votes: 7,
      total_votes: 7344,
      percentage_of_votes: 0.57,
    },
    {
      serial_no: 5,
      candidate: "ANAND MUKHI",
      party: "Independent",
      evm_votes: 6346,
      postal_votes: 6,
      total_votes: 6352,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 6,
      candidate: "PRANAV KUMAR MAHTO",
      party: "Bahujan Samaj Party",
      evm_votes: 6090,
      postal_votes: 61,
      total_votes: 6151,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 7,
      candidate: "BISHWANATH MAHATO",
      party: "Independent",
      evm_votes: 5226,
      postal_votes: 105,
      total_votes: 5331,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 8,
      candidate: "ANGAD MAHATO",
      party: "Amra Bangalee",
      evm_votes: 4898,
      postal_votes: 27,
      total_votes: 4925,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 9,
      candidate: "JUJHAR SOREN",
      party: "Independent",
      evm_votes: 4262,
      postal_votes: 53,
      total_votes: 4315,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 10,
      candidate: "SUKUMAR SOREN",
      party: "Bharat Adivasi Party",
      evm_votes: 3860,
      postal_votes: 34,
      total_votes: 3894,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 11,
      candidate: "DHARMU TUDU",
      party: "Ambedkarite Party of India",
      evm_votes: 3274,
      postal_votes: 17,
      total_votes: 3291,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 12,
      candidate: "JITENDRA SINGH",
      party: "Independent",
      evm_votes: 3159,
      postal_votes: 8,
      total_votes: 3167,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 13,
      candidate: "SANAKA MAHATO",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 3137,
      postal_votes: 38,
      total_votes: 3175,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 14,
      candidate: "MANOJ GUPTA",
      party: "Lokhit Adhikar Party",
      evm_votes: 2355,
      postal_votes: 7,
      total_votes: 2362,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 15,
      candidate: "SADHU CHARAN PAUL",
      party: "Independent",
      evm_votes: 2291,
      postal_votes: 26,
      total_votes: 2317,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 16,
      candidate: "SAURAV VISHNU",
      party: "Independent",
      evm_votes: 2234,
      postal_votes: 30,
      total_votes: 2264,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 17,
      candidate: "SHAIKH AKHIRUDDIN",
      party: "Bahujan Maha Party",
      evm_votes: 2099,
      postal_votes: 9,
      total_votes: 2108,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 18,
      candidate: "ASHOK KUMAR",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 2038,
      postal_votes: 10,
      total_votes: 2048,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 19,
      candidate: "MAHESH KUMAR",
      party: "Right to Recall Party",
      evm_votes: 1952,
      postal_votes: 8,
      total_votes: 1960,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 20,
      candidate: "G JAYRAM DAS",
      party: "Independent",
      evm_votes: 1927,
      postal_votes: 4,
      total_votes: 1931,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 21,
      candidate: "ARUN KUMAR SHARMA",
      party: "Bhartiya Azad Sena",
      evm_votes: 1797,
      postal_votes: 4,
      total_votes: 1801,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 22,
      candidate: "DOMAN CHANDRA BHAKAT",
      party: "Bhagidari Party(P)",
      evm_votes: 1343,
      postal_votes: 5,
      total_votes: 1348,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 23,
      candidate: "BABLU PRASAD DANGI",
      party: "Independent",
      evm_votes: 1144,
      postal_votes: 8,
      total_votes: 1152,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 24,
      candidate: "GYAN SAGAR PRASAD",
      party: "Independent",
      evm_votes: 1043,
      postal_votes: 1,
      total_votes: 1044,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 25,
      candidate: "PARWATI KISKU",
      party: "Independent",
      evm_votes: 972,
      postal_votes: 16,
      total_votes: 988,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 26,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7105,
      postal_votes: 221,
      total_votes: 7326,
      percentage_of_votes: 0.57,
    },
  ],
  total: {
    evm_votes: 1264240,
    postal_votes: 13389,
    total_votes: 1277629,
  },
};
export const s2713 = {
  cs_name: "Palamau",
  cs_shortname: "palamau",
  cs_code: "s2713",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "VISHNU DAYAL RAM",
      party: "Bharatiya Janata Party",
      evm_votes: 762290,
      postal_votes: 8072,
      total_votes: 770362,
      percentage_of_votes: 55.39,
    },
    {
      serial_no: 2,
      candidate: "MAMTA BHUIYAN",
      party: "Rashtriya Janata Dal",
      evm_votes: 473668,
      postal_votes: 7887,
      total_votes: 481555,
      percentage_of_votes: 34.63,
    },
    {
      serial_no: 3,
      candidate: "KAMESHWAR BAITHA",
      party: "Bahujan Samaj Party",
      evm_votes: 39267,
      postal_votes: 733,
      total_votes: 40000,
      percentage_of_votes: 2.88,
    },
    {
      serial_no: 4,
      candidate: "SANAN RAM",
      party: "Lokhit Adhikar Party",
      evm_votes: 17849,
      postal_votes: 26,
      total_votes: 17875,
      percentage_of_votes: 1.29,
    },
    {
      serial_no: 5,
      candidate: "GANESH RAVI",
      party: "Independent",
      evm_votes: 17669,
      postal_votes: 30,
      total_votes: 17699,
      percentage_of_votes: 1.27,
    },
    {
      serial_no: 6,
      candidate: "BRAJESH KUMAR TURI",
      party: "Rashtriya Samanta Dal",
      evm_votes: 17542,
      postal_votes: 24,
      total_votes: 17566,
      percentage_of_votes: 1.26,
    },
    {
      serial_no: 7,
      candidate: "VRINDA RAM",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 11205,
      postal_votes: 65,
      total_votes: 11270,
      percentage_of_votes: 0.81,
    },
    {
      serial_no: 8,
      candidate: "MAHENDRA BAITHA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 5097,
      postal_votes: 18,
      total_votes: 5115,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 9,
      candidate: "RAM VACHAN RAM",
      party: "Bahujan Mukti Party",
      evm_votes: 4924,
      postal_votes: 29,
      total_votes: 4953,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 24226,
      postal_votes: 117,
      total_votes: 24343,
      percentage_of_votes: 1.75,
    },
  ],
  total: {
    evm_votes: 1373737,
    postal_votes: 17001,
    total_votes: 1390738,
  },
};
export const s2714 = {
  cs_name: "Hazaribagh",
  cs_shortname: "hazaribagh",
  cs_code: "s2714",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "MANISH JAISWAL",
      party: "Bharatiya Janata Party",
      evm_votes: 647416,
      postal_votes: 7197,
      total_votes: 654613,
      percentage_of_votes: 51.76,
    },
    {
      serial_no: 2,
      candidate: "JAI PRAKASH BHAI PATEL",
      party: "Indian National Congress",
      evm_votes: 371641,
      postal_votes: 6286,
      total_votes: 377927,
      percentage_of_votes: 29.88,
    },
    {
      serial_no: 3,
      candidate: "SANJAY KUMAR MEHTA",
      party: "Independent",
      evm_votes: 156298,
      postal_votes: 1679,
      total_votes: 157977,
      percentage_of_votes: 12.49,
    },
    {
      serial_no: 4,
      candidate: "SHASHI BHUSHAN KESHRI",
      party: "Independent",
      evm_votes: 15373,
      postal_votes: 13,
      total_votes: 15386,
      percentage_of_votes: 1.22,
    },
    {
      serial_no: 5,
      candidate: "ANIRUDDH KUMAR",
      party: "Communist Party of India",
      evm_votes: 10428,
      postal_votes: 40,
      total_votes: 10468,
      percentage_of_votes: 0.83,
    },
    {
      serial_no: 6,
      candidate: "MD. MOIN UDDIN AHMAD",
      party: "Bahujan Samaj Party",
      evm_votes: 7395,
      postal_votes: 43,
      total_votes: 7438,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 7,
      candidate: "MD. SERAJ",
      party: "Independent",
      evm_votes: 5503,
      postal_votes: 3,
      total_votes: 5506,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 8,
      candidate: "VINOD KUMAR RANA",
      party: "Independent",
      evm_votes: 4831,
      postal_votes: 10,
      total_votes: 4841,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 9,
      candidate: "KUNJ BIHARI KUMAR",
      party: "Lokhit Adhikar Party",
      evm_votes: 4220,
      postal_votes: 14,
      total_votes: 4234,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 10,
      candidate: "CHHATHI DEVI",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 3296,
      postal_votes: 20,
      total_votes: 3316,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 11,
      candidate: "ABHISHEK KUMAR",
      party: "Independent",
      evm_votes: 2963,
      postal_votes: 9,
      total_votes: 2972,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 12,
      candidate: "NISHANT KUMAR SINHA",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 2932,
      postal_votes: 24,
      total_votes: 2956,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 13,
      candidate: "MANOJ KUMAR BEDIA",
      party: "Independent",
      evm_votes: 2559,
      postal_votes: 19,
      total_votes: 2578,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 14,
      candidate: "BHUNESHWAR BEDIYA",
      party: "Samata Party",
      evm_votes: 2236,
      postal_votes: 28,
      total_votes: 2264,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 15,
      candidate: "RAJ KUMAR",
      party: "Jharkhand Party",
      evm_votes: 1885,
      postal_votes: 50,
      total_votes: 1935,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 16,
      candidate: "SHYAM BIHARI PRAJAPATI",
      party: "Bhagidari Party(P)",
      evm_votes: 1818,
      postal_votes: 21,
      total_votes: 1839,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 17,
      candidate: "PRAKASH SONI",
      party: "Bhartiya Azad Sena",
      evm_votes: 1209,
      postal_votes: 8,
      total_votes: 1217,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 18,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7090,
      postal_votes: 110,
      total_votes: 7200,
      percentage_of_votes: 0.57,
    },
  ],
  total: {
    evm_votes: 1249093,
    postal_votes: 15574,
    total_votes: 1264667,
  },
};
export const s281 = {
  cs_name: "Tehri_Garhwal",
  cs_shortname: "tehrigarhwal",
  cs_code: "s281",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "MALA RAJYA LAKSHMI SHAH",
      party: "Bharatiya Janata Party",
      evm_votes: 455949,
      postal_votes: 6654,
      total_votes: 462603,
      percentage_of_votes: 53.66,
    },
    {
      serial_no: 2,
      candidate: "JOT SINGH GUNSOLA",
      party: "Indian National Congress",
      evm_votes: 187602,
      postal_votes: 2508,
      total_votes: 190110,
      percentage_of_votes: 22.05,
    },
    {
      serial_no: 3,
      candidate: "BOBY PANWAR",
      party: "Independent",
      evm_votes: 162469,
      postal_votes: 5612,
      total_votes: 168081,
      percentage_of_votes: 19.5,
    },
    {
      serial_no: 4,
      candidate: "NAVNEET SINGH GOSAIN",
      party: "Rashtriya Uttarakhand Party",
      evm_votes: 9969,
      postal_votes: 57,
      total_votes: 10026,
      percentage_of_votes: 1.16,
    },
    {
      serial_no: 5,
      candidate: "NEMCHAND",
      party: "Bahujan Samaj Party",
      evm_votes: 6786,
      postal_votes: 122,
      total_votes: 6908,
      percentage_of_votes: 0.8,
    },
    {
      serial_no: 6,
      candidate: "BRIJ BHUSHAN KARANWAL",
      party: "Bhartiya Rashtriya Ekta Dal",
      evm_votes: 3545,
      postal_votes: 25,
      total_votes: 3570,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 7,
      candidate: "SARDAR KHAN PAPPU",
      party: "Independent",
      evm_votes: 3268,
      postal_votes: 7,
      total_votes: 3275,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 8,
      candidate: "VEPIN KUMAR AGGARWAL",
      party: "Independent",
      evm_votes: 3173,
      postal_votes: 43,
      total_votes: 3216,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 9,
      candidate: "RAMPAL SINGH",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 2343,
      postal_votes: 19,
      total_votes: 2362,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 10,
      candidate: "SUDESH TOMAR",
      party: "Independent",
      evm_votes: 2261,
      postal_votes: 7,
      total_votes: 2268,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 11,
      candidate: "PREM DUTT SEMWAL",
      party: "Independent",
      evm_votes: 2134,
      postal_votes: 30,
      total_votes: 2164,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7304,
      postal_votes: 154,
      total_votes: 7458,
      percentage_of_votes: 0.87,
    },
  ],
  total: {
    evm_votes: 846803,
    postal_votes: 15238,
    total_votes: 862041,
  },
};
export const s282 = {
  cs_name: "Garhwal",
  cs_shortname: "garhwal",
  cs_code: "s282",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ANIL BALUNI",
      party: "Bharatiya Janata Party",
      evm_votes: 418531,
      postal_votes: 13628,
      total_votes: 432159,
      percentage_of_votes: 58.6,
    },
    {
      serial_no: 2,
      candidate: "GANESH GODIYAL",
      party: "Indian National Congress",
      evm_votes: 262692,
      postal_votes: 5964,
      total_votes: 268656,
      percentage_of_votes: 36.43,
    },
    {
      serial_no: 3,
      candidate: "SONU KUMAR",
      party: "Independent",
      evm_votes: 4803,
      postal_votes: 19,
      total_votes: 4822,
      percentage_of_votes: 0.65,
    },
    {
      serial_no: 4,
      candidate: "ASHUTOSH SINGH",
      party: "Uttarakhand Kranti Dal",
      evm_votes: 4182,
      postal_votes: 379,
      total_votes: 4561,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 5,
      candidate: "DHEER SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 4007,
      postal_votes: 115,
      total_votes: 4122,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 6,
      candidate: "MUKESH PRAKASH",
      party: "Independent",
      evm_votes: 3064,
      postal_votes: 15,
      total_votes: 3079,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 7,
      candidate: "SURESHI DEVI",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 1472,
      postal_votes: 40,
      total_votes: 1512,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 8,
      candidate: "DEEPENDRA SINGH NEGI",
      party: "Independent",
      evm_votes: 1408,
      postal_votes: 232,
      total_votes: 1640,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 9,
      candidate: "ARJUN SINGH",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 1308,
      postal_votes: 70,
      total_votes: 1378,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 10,
      candidate: "RESHMA PANWAR",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1168,
      postal_votes: 125,
      total_votes: 1293,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 11,
      candidate: "DR MUKESH PANT",
      party: "Sainik Samaj Party",
      evm_votes: 1121,
      postal_votes: 58,
      total_votes: 1179,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 12,
      candidate: "VINOD KUMAR SHARMA",
      party: "Uttarakhand Samanta Party",
      evm_votes: 838,
      postal_votes: 46,
      total_votes: 884,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "SHYAMLAL",
      party: "Bahujan Mukti Party",
      evm_votes: 743,
      postal_votes: 28,
      total_votes: 771,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11224,
      postal_votes: 151,
      total_votes: 11375,
      percentage_of_votes: 1.54,
    },
  ],
  total: {
    evm_votes: 716561,
    postal_votes: 20870,
    total_votes: 737431,
  },
};
export const s283 = {
  cs_name: "Almora",
  cs_shortname: "almora",
  cs_code: "s283",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "AJAY TAMTA",
      party: "Bharatiya Janata Party",
      evm_votes: 417535,
      postal_votes: 11632,
      total_votes: 429167,
      percentage_of_votes: 64.2,
    },
    {
      serial_no: 2,
      candidate: "PRADEEP TAMTA",
      party: "Indian National Congress",
      evm_votes: 191642,
      postal_votes: 3428,
      total_votes: 195070,
      percentage_of_votes: 29.18,
    },
    {
      serial_no: 3,
      candidate: "NARAYAN RAM",
      party: "Bahujan Samaj Party",
      evm_votes: 9840,
      postal_votes: 235,
      total_votes: 10075,
      percentage_of_votes: 1.51,
    },
    {
      serial_no: 4,
      candidate: "KIRAN ARYA",
      party: "Uttarakhand Parivartan Party",
      evm_votes: 5528,
      postal_votes: 250,
      total_votes: 5778,
      percentage_of_votes: 0.86,
    },
    {
      serial_no: 5,
      candidate: "ARJUN PRASAD",
      party: "Independent",
      evm_votes: 4591,
      postal_votes: 116,
      total_votes: 4707,
      percentage_of_votes: 0.7,
    },
    {
      serial_no: 6,
      candidate: "DR PRAMOD KUMAR",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 4401,
      postal_votes: 56,
      total_votes: 4457,
      percentage_of_votes: 0.67,
    },
    {
      serial_no: 7,
      candidate: "ER JYOTI PRAKASH TAMTA",
      party: "Bahujan Mukti Party",
      evm_votes: 2178,
      postal_votes: 46,
      total_votes: 2224,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 16697,
      postal_votes: 322,
      total_votes: 17019,
      percentage_of_votes: 2.55,
    },
  ],
  total: {
    evm_votes: 652412,
    postal_votes: 16085,
    total_votes: 668497,
  },
};
export const s284 = {
  cs_name: "Nainital-Udhamsingh_Nagar",
  cs_shortname: "nainitaludhamsinghnagar",
  cs_code: "s284",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "AJAY BHATT",
      party: "Bharatiya Janata Party",
      evm_votes: 766397,
      postal_votes: 6274,
      total_votes: 772671,
      percentage_of_votes: 61.03,
    },
    {
      serial_no: 2,
      candidate: "PRAKASH JOSHI",
      party: "Indian National Congress",
      evm_votes: 434916,
      postal_votes: 3207,
      total_votes: 438123,
      percentage_of_votes: 34.61,
    },
    {
      serial_no: 3,
      candidate: "AKHTAR ALI",
      party: "Bahujan Samaj Party",
      evm_votes: 23276,
      postal_votes: 179,
      total_votes: 23455,
      percentage_of_votes: 1.85,
    },
    {
      serial_no: 4,
      candidate: "AKHALESH KUMAR",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 4871,
      postal_votes: 31,
      total_votes: 4902,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 5,
      candidate: "HITESH PATHAK",
      party: "Independent",
      evm_votes: 4287,
      postal_votes: 28,
      total_votes: 4315,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 6,
      candidate: "RAMESH KUMAR MAX",
      party: "Independent",
      evm_votes: 3271,
      postal_votes: 29,
      total_votes: 3300,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 7,
      candidate: "AMAR SINGH SAINI",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 2963,
      postal_votes: 24,
      total_votes: 2987,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 8,
      candidate: "JEEVAN CHANDRA UPRETI",
      party: "Bharat Ki Lok Jimmedar Party",
      evm_votes: 2292,
      postal_votes: 18,
      total_votes: 2310,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 9,
      candidate: "SHIB SINGH",
      party: "Uttarakhand Kranti Dal",
      evm_votes: 1809,
      postal_votes: 46,
      total_votes: 1855,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "SURENDRA SINGH",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 1655,
      postal_votes: 18,
      total_votes: 1673,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10227,
      postal_votes: 198,
      total_votes: 10425,
      percentage_of_votes: 0.82,
    },
  ],
  total: {
    evm_votes: 1255964,
    postal_votes: 10052,
    total_votes: 1266016,
  },
};
export const s285 = {
  cs_name: "Haridwar",
  cs_shortname: "haridwar",
  cs_code: "s285",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "TRIVENDRA SINGH RAWAT",
      party: "Bharatiya Janata Party",
      evm_votes: 648498,
      postal_votes: 5310,
      total_votes: 653808,
      percentage_of_votes: 50.19,
    },
    {
      serial_no: 2,
      candidate: "VIRENDRA RAWAT",
      party: "Indian National Congress",
      evm_votes: 487406,
      postal_votes: 2346,
      total_votes: 489752,
      percentage_of_votes: 37.6,
    },
    {
      serial_no: 3,
      candidate: "UMESH KUMAR PATRAKAR",
      party: "Independent",
      evm_votes: 89958,
      postal_votes: 1230,
      total_votes: 91188,
      percentage_of_votes: 7,
    },
    {
      serial_no: 4,
      candidate: "JAMIL AHMAD",
      party: "Bahujan Samaj Party",
      evm_votes: 42116,
      postal_votes: 207,
      total_votes: 42323,
      percentage_of_votes: 3.25,
    },
    {
      serial_no: 5,
      candidate: "BALBIR SINGH BHANDARI",
      party: "Uttarakhand Samanta Party",
      evm_votes: 2943,
      postal_votes: 18,
      total_votes: 2961,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 6,
      candidate: "MOHAN SINGH ASWAL",
      party: "Uttarakhand Kranti Dal",
      evm_votes: 2809,
      postal_votes: 45,
      total_votes: 2854,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 7,
      candidate: "VIJAY KUMAR",
      party: "Independent",
      evm_votes: 2409,
      postal_votes: 1,
      total_votes: 2410,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 8,
      candidate: "PAWAN KASHYAP",
      party: "Independent",
      evm_votes: 2170,
      postal_votes: 8,
      total_votes: 2178,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 9,
      candidate: "KARAN SINGH SAINI ENGINEER",
      party: "Independent",
      evm_votes: 1963,
      postal_votes: 11,
      total_votes: 1974,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "AKRAM HUSSAIN",
      party: "Independent",
      evm_votes: 1678,
      postal_votes: 7,
      total_votes: 1685,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "LALIT KUMAR",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 1396,
      postal_votes: 14,
      total_votes: 1410,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 12,
      candidate: "SURESH PAL",
      party: "Bhartiya Rashtriya Ekta Dal",
      evm_votes: 1150,
      postal_votes: 12,
      total_votes: 1162,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "ASHISH DHYANI",
      party: "Independent",
      evm_votes: 1107,
      postal_votes: 10,
      total_votes: 1117,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "AVNISH KUMAR",
      party: "Independent",
      evm_votes: 967,
      postal_votes: 8,
      total_votes: 975,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6663,
      postal_votes: 163,
      total_votes: 6826,
      percentage_of_votes: 0.52,
    },
  ],
  total: {
    evm_votes: 1293233,
    postal_votes: 9390,
    total_votes: 1302623,
  },
};
export const s291 = {
  cs_name: "Adilabad",
  cs_shortname: "adilabad",
  cs_code: "s291",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "GODAM NAGESH",
      party: "Bharatiya Janata Party",
      evm_votes: 558936,
      postal_votes: 9232,
      total_votes: 568168,
      percentage_of_votes: 45.98,
    },
    {
      serial_no: 2,
      candidate: "ATHRAM SUGUNA",
      party: "Indian National Congress",
      evm_votes: 472333,
      postal_votes: 5183,
      total_votes: 477516,
      percentage_of_votes: 38.65,
    },
    {
      serial_no: 3,
      candidate: "ATHRAM SAKKU",
      party: "Bharat Rashtra Samithi",
      evm_votes: 136463,
      postal_votes: 837,
      total_votes: 137300,
      percentage_of_votes: 11.11,
    },
    {
      serial_no: 4,
      candidate: "MALOTHU SYAMLAL NAYAK",
      party: "Alliance of Democratic Reforms Party",
      evm_votes: 7489,
      postal_votes: 10,
      total_votes: 7499,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 5,
      candidate: "MESRAM JANGUBAPU",
      party: "Bahujan Samaj Party",
      evm_votes: 6689,
      postal_votes: 55,
      total_votes: 6744,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 6,
      candidate: "RATHOD SUBASH",
      party: "Independent",
      evm_votes: 6434,
      postal_votes: 94,
      total_votes: 6528,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 7,
      candidate: "BUKYA JAIVANTH RAO",
      party: "Independent",
      evm_votes: 6427,
      postal_votes: 19,
      total_votes: 6446,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 8,
      candidate: "CHAVAN SUDHARSHAN",
      party: "Anna YSR Congress Party",
      evm_votes: 4618,
      postal_votes: 26,
      total_votes: 4644,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 9,
      candidate: "GANGADEVI MESRAM",
      party: "Dharma Samaj Party",
      evm_votes: 3445,
      postal_votes: 23,
      total_votes: 3468,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 10,
      candidate: "N. TIRUPATI",
      party: "Vidhyarthula Rajakiya Party",
      evm_votes: 2284,
      postal_votes: 8,
      total_votes: 2292,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 11,
      candidate: "KODAPA VAMAN RAO",
      party: "Gondwana Dandkaranya Party",
      evm_votes: 1677,
      postal_votes: 8,
      total_votes: 1685,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 12,
      candidate: "GEDAM. SAGAR",
      party: "India Praja Bandhu Party",
      evm_votes: 1537,
      postal_votes: 8,
      total_votes: 1545,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11650,
      postal_votes: 112,
      total_votes: 11762,
      percentage_of_votes: 0.95,
    },
  ],
  total: {
    evm_votes: 1219982,
    postal_votes: 15615,
    total_votes: 1235597,
  },
};
export const s293 = {
  cs_name: "Karimnagar",
  cs_shortname: "karimnagar",
  cs_code: "s293",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BANDI SANJAY KUMAR",
      party: "Bharatiya Janata Party",
      evm_votes: 578827,
      postal_votes: 6289,
      total_votes: 585116,
      percentage_of_votes: 44.57,
    },
    {
      serial_no: 2,
      candidate: "VELCHALA RAJENDER RAO",
      party: "Indian National Congress",
      evm_votes: 357446,
      postal_votes: 2461,
      total_votes: 359907,
      percentage_of_votes: 27.41,
    },
    {
      serial_no: 3,
      candidate: "VINOD KUMAR BOIANAPALLI",
      party: "Bharat Rashtra Samithi",
      evm_votes: 280867,
      postal_votes: 1296,
      total_votes: 282163,
      percentage_of_votes: 21.49,
    },
    {
      serial_no: 4,
      candidate: "ABBADI BUCHI REDDY",
      party: "Independent",
      evm_votes: 11602,
      postal_votes: 10,
      total_votes: 11612,
      percentage_of_votes: 0.88,
    },
    {
      serial_no: 5,
      candidate: "ARUNA THALLAPALLY",
      party: "Alliance of Democratic Reforms Party",
      evm_votes: 9043,
      postal_votes: 21,
      total_votes: 9064,
      percentage_of_votes: 0.69,
    },
    {
      serial_no: 6,
      candidate: "KOTA SHYAM KUMAR",
      party: "Independent",
      evm_votes: 8744,
      postal_votes: 12,
      total_votes: 8756,
      percentage_of_votes: 0.67,
    },
    {
      serial_no: 7,
      candidate: "ANOSE KATKOORI",
      party: "Independent",
      evm_votes: 8699,
      postal_votes: 7,
      total_votes: 8706,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 8,
      candidate: "MAREPALLI MOGILAIAH",
      party: "Bahujan Samaj Party",
      evm_votes: 8010,
      postal_votes: 99,
      total_votes: 8109,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 9,
      candidate: "RAJENDER POTHURI",
      party: "Independent",
      evm_votes: 3250,
      postal_votes: 7,
      total_votes: 3257,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 10,
      candidate: "SRAVAN PEDDAPELLY",
      party: "Bharathiya YuvaKula Dalam",
      evm_votes: 3068,
      postal_votes: 6,
      total_votes: 3074,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 11,
      candidate: "GADDA SATHISH",
      party: "Independent",
      evm_votes: 2936,
      postal_votes: 9,
      total_votes: 2945,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 12,
      candidate: "ANILREDDY KADTHALA",
      party: "National Nava Kranthi Party",
      evm_votes: 2747,
      postal_votes: 7,
      total_votes: 2754,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 13,
      candidate: "CHILUVERU SRIKANTH",
      party: "Dharma Samaj Party",
      evm_votes: 2553,
      postal_votes: 33,
      total_votes: 2586,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 14,
      candidate: "AKSHAY KUMAR MEKALA",
      party: "Independent",
      evm_votes: 2219,
      postal_votes: 5,
      total_votes: 2224,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 15,
      candidate: "JOURNALIST VIKRAM REDDY VEMULAA",
      party: "Independent",
      evm_votes: 2090,
      postal_votes: 6,
      total_votes: 2096,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 16,
      candidate: "ASHOK PANCHIKA",
      party: "Social Justice Party of India",
      evm_votes: 1914,
      postal_votes: 10,
      total_votes: 1924,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 17,
      candidate: "GAVVALA LAXMI",
      party: "Independent",
      evm_votes: 1738,
      postal_votes: 6,
      total_votes: 1744,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 18,
      candidate: "GATTAIAH YADAV BARIGE",
      party: "Independent",
      evm_votes: 1688,
      postal_votes: 6,
      total_votes: 1694,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 19,
      candidate: "RANAPRATAP GATTU",
      party: "Socialist Party (India)",
      evm_votes: 1606,
      postal_votes: 7,
      total_votes: 1613,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 20,
      candidate: "GUDISHE MOHAN",
      party: "Independent",
      evm_votes: 1471,
      postal_votes: 3,
      total_votes: 1474,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 21,
      candidate: "MANASA PERALA",
      party: "Independent",
      evm_votes: 1106,
      postal_votes: 19,
      total_votes: 1125,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 22,
      candidate: "PODISHETTI SAMMAIAH",
      party: "Bahujan Mukti Party",
      evm_votes: 994,
      postal_votes: 0,
      total_votes: 994,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 23,
      candidate: "VENKATANARSAIAH DYAGALA @ DEGALA",
      party: "Independent",
      evm_votes: 926,
      postal_votes: 2,
      total_votes: 928,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 24,
      candidate: "SHIVARATRI SRINIVAS",
      party: "Independent",
      evm_votes: 887,
      postal_votes: 2,
      total_votes: 889,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 25,
      candidate: "CHINTHA ANILKUMAR",
      party: "Pyramid Party of India",
      evm_votes: 823,
      postal_votes: 7,
      total_votes: 830,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 26,
      candidate: "CHEEKOTI VARUN KUMAR GUPTA",
      party: "Telugu Congress Party",
      evm_votes: 783,
      postal_votes: 7,
      total_votes: 790,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 27,
      candidate: "RAPOLE RAMKUMAR BHARADWAJ",
      party: "Independent",
      evm_votes: 572,
      postal_votes: 3,
      total_votes: 575,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 28,
      candidate: "DEVUNOORI SRINIVASU",
      party: "Independent",
      evm_votes: 465,
      postal_votes: 0,
      total_votes: 465,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 29,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5373,
      postal_votes: 65,
      total_votes: 5438,
      percentage_of_votes: 0.41,
    },
  ],
  total: {
    evm_votes: 1302447,
    postal_votes: 10405,
    total_votes: 1312852,
  },
};
export const s294 = {
  cs_name: "Nizamabad",
  cs_shortname: "nizamabad",
  cs_code: "s294",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ARVIND DHARMAPURI",
      party: "Bharatiya Janata Party",
      evm_votes: 588244,
      postal_votes: 4074,
      total_votes: 592318,
      percentage_of_votes: 48.02,
    },
    {
      serial_no: 2,
      candidate: "JEEVANREDDY THATIPARTHI",
      party: "Indian National Congress",
      evm_votes: 480501,
      postal_votes: 2576,
      total_votes: 483077,
      percentage_of_votes: 39.16,
    },
    {
      serial_no: 3,
      candidate: "GOVERDHAN BAJIREDDY",
      party: "Bharat Rashtra Samithi",
      evm_votes: 101929,
      postal_votes: 477,
      total_votes: 102406,
      percentage_of_votes: 8.3,
    },
    {
      serial_no: 4,
      candidate: "GANTA CHARITHA RAO",
      party: "Independent",
      evm_votes: 5707,
      postal_votes: 3,
      total_votes: 5710,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 5,
      candidate: "YOUGENDAR GATLA",
      party: "Alliance of Democratic Reforms Party",
      evm_votes: 5439,
      postal_votes: 14,
      total_votes: 5453,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 6,
      candidate: "KOTAGIRI SRINIVAS",
      party: "Independent",
      evm_votes: 5308,
      postal_votes: 4,
      total_votes: 5312,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 7,
      candidate: "PUPPALA LIMBADRI",
      party: "Bahujan Samaj Party",
      evm_votes: 4220,
      postal_votes: 43,
      total_votes: 4263,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 8,
      candidate: "TUTUKURU JEEVAN REDDY",
      party: "Independent",
      evm_votes: 4085,
      postal_votes: 6,
      total_votes: 4091,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 9,
      candidate: "KANDELA SUMAN",
      party: "Dharma Samaj Party",
      evm_votes: 3842,
      postal_votes: 44,
      total_votes: 3886,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 10,
      candidate: "GOLI. NARESH",
      party: "Dalita Bahujana Party",
      evm_votes: 3017,
      postal_votes: 7,
      total_votes: 3024,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 11,
      candidate: "ARE RAJENDHAR",
      party: "Independent",
      evm_votes: 2906,
      postal_votes: 1,
      total_votes: 2907,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 12,
      candidate: "KOTHAKONDA SHAKTI PRASAD",
      party: "Independent",
      evm_votes: 2103,
      postal_votes: 3,
      total_votes: 2106,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 13,
      candidate: "VEMULAA VIKRAM REDDY",
      party: "Independent",
      evm_votes: 2073,
      postal_votes: 1,
      total_votes: 2074,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 14,
      candidate: "GOPI CHENDRIAH",
      party: "Independent",
      evm_votes: 1721,
      postal_votes: 2,
      total_votes: 1723,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 15,
      candidate: "ALI MANSOOR",
      party: "Anna YSR Congress Party",
      evm_votes: 1454,
      postal_votes: 14,
      total_votes: 1468,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 16,
      candidate: "GUYYA SAIKRISHNA MURTHY",
      party: "Yuga Thulasi Party",
      evm_votes: 1123,
      postal_votes: 6,
      total_votes: 1129,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 17,
      candidate: "SYED ASGAR",
      party: "Independent",
      evm_votes: 1046,
      postal_votes: 2,
      total_votes: 1048,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 18,
      candidate: "MALAVAT VITHAL",
      party: "Independent",
      evm_votes: 919,
      postal_votes: 0,
      total_votes: 919,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 19,
      candidate: "MOGILI RAJKUMAR",
      party: "India Praja Bandhu Party",
      evm_votes: 825,
      postal_votes: 5,
      total_votes: 830,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 20,
      candidate: "RAPELLY SRINIVAS",
      party: "Independent",
      evm_votes: 791,
      postal_votes: 2,
      total_votes: 793,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 21,
      candidate: "DESHABOINA LAXMINARAYANA",
      party: "Independent",
      evm_votes: 723,
      postal_votes: 0,
      total_votes: 723,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 22,
      candidate: "ABBAGONI ASHOK GOUD",
      party: "Bahujana Left Party",
      evm_votes: 641,
      postal_votes: 8,
      total_votes: 649,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 23,
      candidate: "DEVATHI SRINIVAS",
      party: "Bahujan Mukti Party",
      evm_votes: 624,
      postal_votes: 12,
      total_votes: 636,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 24,
      candidate: "B B NAIK",
      party: "Independent",
      evm_votes: 581,
      postal_votes: 0,
      total_votes: 581,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 25,
      candidate: "BHUKYA. NANDU",
      party: "Vidhyarthula Rajakiya Party",
      evm_votes: 574,
      postal_votes: 6,
      total_votes: 580,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 26,
      candidate: "RAPALLY SATYANARAYANA",
      party: "Independent",
      evm_votes: 425,
      postal_votes: 1,
      total_votes: 426,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 27,
      candidate: "RAGI ANIL",
      party: "Independent",
      evm_votes: 367,
      postal_votes: 8,
      total_votes: 375,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 28,
      candidate: "PALAMURI SAI NIKHIL",
      party: "Independent",
      evm_votes: 303,
      postal_votes: 4,
      total_votes: 307,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 29,
      candidate: "PRASHANTH KATRAJI",
      party: "Independent",
      evm_votes: 284,
      postal_votes: 0,
      total_votes: 284,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 30,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4440,
      postal_votes: 43,
      total_votes: 4483,
      percentage_of_votes: 0.36,
    },
  ],
  total: {
    evm_votes: 1226215,
    postal_votes: 7366,
    total_votes: 1233581,
  },
};
export const s296 = {
  cs_name: "Medak",
  cs_shortname: "medak",
  cs_code: "s296",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "MADHAVANENI RAGHUNANDAN RAO",
      party: "Bharatiya Janata Party",
      evm_votes: 463777,
      postal_votes: 7440,
      total_votes: 471217,
      percentage_of_votes: 33.99,
    },
    {
      serial_no: 2,
      candidate: "NEELAM MADHU",
      party: "Indian National Congress",
      evm_votes: 427899,
      postal_votes: 4179,
      total_votes: 432078,
      percentage_of_votes: 31.17,
    },
    {
      serial_no: 3,
      candidate: "P. VENKATRAMA REDDY",
      party: "Bharat Rashtra Samithi",
      evm_votes: 395250,
      postal_votes: 1540,
      total_votes: 396790,
      percentage_of_votes: 28.62,
    },
    {
      serial_no: 4,
      candidate: "NANDA KISHORE TALLADA",
      party: "Alliance of Democratic Reforms Party",
      evm_votes: 17191,
      postal_votes: 25,
      total_votes: 17216,
      percentage_of_votes: 1.24,
    },
    {
      serial_no: 5,
      candidate: "SARA YADAGIRI GOUD",
      party: "Praja Velugu Party",
      evm_votes: 11056,
      postal_votes: 4,
      total_votes: 11060,
      percentage_of_votes: 0.8,
    },
    {
      serial_no: 6,
      candidate: "BODAPATLA ESHWAR",
      party: "Bahujan Samaj Party",
      evm_votes: 7423,
      postal_votes: 40,
      total_votes: 7463,
      percentage_of_votes: 0.54,
    },
    {
      serial_no: 7,
      candidate: "DUBALA SRI SAILAM",
      party: "Independent",
      evm_votes: 5161,
      postal_votes: 5,
      total_votes: 5166,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 8,
      candidate: "ADLA KUMAR",
      party: "Independent",
      evm_votes: 5113,
      postal_votes: 3,
      total_votes: 5116,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 9,
      candidate: "BANDAPALLY SRINIVAS",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2366,
      postal_votes: 0,
      total_votes: 2366,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 10,
      candidate: "ANIL GOUD",
      party: "Bharat Jodo Party",
      evm_votes: 2128,
      postal_votes: 4,
      total_votes: 2132,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 11,
      candidate: "ABHILASH CHELIMELA",
      party: "Independent",
      evm_votes: 2031,
      postal_votes: 0,
      total_votes: 2031,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 12,
      candidate: "ATI ANJANEYULU",
      party: "Independent",
      evm_votes: 2029,
      postal_votes: 9,
      total_votes: 2038,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 13,
      candidate: "MAISANGARI SUNIL",
      party: "India Praja Bandhu Party",
      evm_votes: 2009,
      postal_votes: 2,
      total_votes: 2011,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 14,
      candidate: "PRADEEP",
      party: "Independent",
      evm_votes: 2006,
      postal_votes: 4,
      total_votes: 2010,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 15,
      candidate: "INDRAGOUD SILIVERI",
      party: "National Nava Kranthi Party",
      evm_votes: 1773,
      postal_votes: 1,
      total_votes: 1774,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 16,
      candidate: "NAGAMANI ACHA",
      party: "Independent",
      evm_votes: 1538,
      postal_votes: 2,
      total_votes: 1540,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 17,
      candidate: "A. KRANTHI KUMAR",
      party: "Independent",
      evm_votes: 1465,
      postal_votes: 2,
      total_votes: 1467,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 18,
      candidate: "MAREPALLY LAXMINARAYANA",
      party: "Independent",
      evm_votes: 1411,
      postal_votes: 3,
      total_votes: 1414,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 19,
      candidate: "BALESH UPPARI",
      party: "Independent",
      evm_votes: 1390,
      postal_votes: 3,
      total_votes: 1393,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 20,
      candidate: "VENKATESH BEJUGAM",
      party: "Independent",
      evm_votes: 1263,
      postal_votes: 1,
      total_votes: 1264,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 21,
      candidate: "BOMMALA VIJAY KUMAR",
      party: "Independent",
      evm_votes: 1194,
      postal_votes: 2,
      total_votes: 1196,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 22,
      candidate: "ANNELA LAXMAN",
      party: "Dharma Samaj Party",
      evm_votes: 1143,
      postal_votes: 9,
      total_votes: 1152,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 23,
      candidate: "ABHILASH SHIRNA",
      party: "Independent",
      evm_votes: 923,
      postal_votes: 1,
      total_votes: 924,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 24,
      candidate: "DODLA VENKAT",
      party: "Telangana Praja Shakthi Party",
      evm_votes: 915,
      postal_votes: 2,
      total_votes: 917,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 25,
      candidate: "ABISHEK GOUD THABETI",
      party: "Jana Shankhaaraavam Party",
      evm_votes: 792,
      postal_votes: 5,
      total_votes: 797,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 26,
      candidate: "MEDI SRINIVAS REDDI",
      party: "Independent",
      evm_votes: 791,
      postal_votes: 2,
      total_votes: 793,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 27,
      candidate: "VUTLA RAMESH",
      party: "Independent",
      evm_votes: 778,
      postal_votes: 0,
      total_votes: 778,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 28,
      candidate: "D. NARAHARI",
      party: "Independent",
      evm_votes: 674,
      postal_votes: 1,
      total_votes: 675,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 29,
      candidate: "GOLLAPALLY SAYA GOUD",
      party: "Pyramid Party of India",
      evm_votes: 656,
      postal_votes: 2,
      total_votes: 658,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 30,
      candidate: "SATYANARAYANA GOUD KOVURI",
      party: "Independent",
      evm_votes: 607,
      postal_votes: 0,
      total_votes: 607,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 31,
      candidate: "DASARI BHANUCHANDER",
      party: "Independent",
      evm_votes: 573,
      postal_votes: 2,
      total_votes: 575,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 32,
      candidate: "DADIGE RAJENDAR",
      party: "Independent",
      evm_votes: 556,
      postal_votes: 3,
      total_votes: 559,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 33,
      candidate: "C.H. NAVEEN KUMAR",
      party: "Independent",
      evm_votes: 529,
      postal_votes: 2,
      total_votes: 531,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 34,
      candidate: "KONDI ASHOK",
      party: "Independent",
      evm_votes: 509,
      postal_votes: 0,
      total_votes: 509,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 35,
      candidate: "URELLI YELLAIAH",
      party: "Viduthalai Chiruthaigal Katchi",
      evm_votes: 492,
      postal_votes: 2,
      total_votes: 494,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 36,
      candidate: "GONDHI BHUJANGAMU",
      party: "Independent",
      evm_votes: 430,
      postal_votes: 1,
      total_votes: 431,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 37,
      candidate: "MOHD AZHER",
      party: "Independent",
      evm_votes: 414,
      postal_votes: 2,
      total_votes: 416,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 38,
      candidate: "S RAJESH SAGAR",
      party: "Independent",
      evm_votes: 348,
      postal_votes: 0,
      total_votes: 348,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 39,
      candidate: "KASOJU SRIKANTH",
      party: "Independent",
      evm_votes: 344,
      postal_votes: 0,
      total_votes: 344,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 40,
      candidate: "K. LAXMINARAYANA",
      party: "Independent",
      evm_votes: 317,
      postal_votes: 0,
      total_votes: 317,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 41,
      candidate: "KOLKUR PRATAP",
      party: "Independent",
      evm_votes: 292,
      postal_votes: 1,
      total_votes: 293,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 42,
      candidate: "GADILA ANJANEYULU",
      party: "Independent",
      evm_votes: 289,
      postal_votes: 1,
      total_votes: 290,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 43,
      candidate: "GOUTI MALLESH",
      party: "Independent",
      evm_votes: 247,
      postal_votes: 1,
      total_votes: 248,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 44,
      candidate: "K.RAGHU",
      party: "Independent",
      evm_votes: 222,
      postal_votes: 2,
      total_votes: 224,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 45,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4582,
      postal_votes: 35,
      total_votes: 4617,
      percentage_of_votes: 0.33,
    },
  ],
  total: {
    evm_votes: 1372896,
    postal_votes: 13343,
    total_votes: 1386239,
  },
};
export const s297 = {
  cs_name: "Malkajgiri",
  cs_shortname: "malkajgiri",
  cs_code: "s297",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "EATALA RAJENDER",
      party: "Bharatiya Janata Party",
      evm_votes: 980712,
      postal_votes: 10330,
      total_votes: 991042,
      percentage_of_votes: 51.25,
    },
    {
      serial_no: 2,
      candidate: "PATNAM SUNEETHA MAHENDER REDDY",
      party: "Indian National Congress",
      evm_votes: 593337,
      postal_votes: 6230,
      total_votes: 599567,
      percentage_of_votes: 31,
    },
    {
      serial_no: 3,
      candidate: "RAGIDI LAXMA REDDY",
      party: "Bharat Rashtra Samithi",
      evm_votes: 298697,
      postal_votes: 1789,
      total_votes: 300486,
      percentage_of_votes: 15.54,
    },
    {
      serial_no: 4,
      candidate: "THALLADA VENKATESWARLU",
      party: "Independent",
      evm_votes: 6784,
      postal_votes: 16,
      total_votes: 6800,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 5,
      candidate: "ANITHA REDDY SHEELAM",
      party: "Bahujan Samaj Party",
      evm_votes: 4718,
      postal_votes: 61,
      total_votes: 4779,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 6,
      candidate: "VAISHNAVI PRASAD",
      party: "Independent",
      evm_votes: 1894,
      postal_votes: 18,
      total_votes: 1912,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 7,
      candidate: "PAREE (PESARIKAYALA PAREEKSHITH REDDY)",
      party: "Independent",
      evm_votes: 1831,
      postal_votes: 4,
      total_votes: 1835,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 8,
      candidate: "DONTHULA BIKSHAPATHI",
      party: "Independent",
      evm_votes: 1813,
      postal_votes: 8,
      total_votes: 1821,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 9,
      candidate: "BHARATHA SUDARSHAN",
      party: "Independent",
      evm_votes: 1639,
      postal_votes: 4,
      total_votes: 1643,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 10,
      candidate: "APPARAO KONDA",
      party: "Rashtra Samanya Praja Party",
      evm_votes: 1157,
      postal_votes: 5,
      total_votes: 1162,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 11,
      candidate: "MOUTAM SAMPATH",
      party: "Independent",
      evm_votes: 1135,
      postal_votes: 2,
      total_votes: 1137,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "RAJESH MISHRA",
      party: "Independent",
      evm_votes: 1110,
      postal_votes: 0,
      total_votes: 1110,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "BANJAPALLY SUDHA RANI",
      party: "Independent",
      evm_votes: 1064,
      postal_votes: 5,
      total_votes: 1069,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 14,
      candidate: "CHIRIPI REDDY RAMESH",
      party: "Independent",
      evm_votes: 986,
      postal_votes: 1,
      total_votes: 987,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "PEDDAPALLI SANDEEP",
      party: "Bharathiya YuvaKula Dalam",
      evm_votes: 930,
      postal_votes: 3,
      total_votes: 933,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "BOIN DURGA PRASAD YADAV",
      party: "Dharma Samaj Party",
      evm_votes: 778,
      postal_votes: 11,
      total_votes: 789,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 17,
      candidate: "CHAMAKURA RAJAIAH ALIAS PIDIKILI RAJU",
      party: "Social Justice Party of India",
      evm_votes: 722,
      postal_votes: 1,
      total_votes: 723,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 18,
      candidate: "NARRA SUKHENDER REDDY",
      party: "Vidhyarthula Rajakiya Party",
      evm_votes: 690,
      postal_votes: 4,
      total_votes: 694,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 19,
      candidate: "KANTE SAYANNA",
      party: "Independent",
      evm_votes: 539,
      postal_votes: 0,
      total_votes: 539,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 20,
      candidate: "JUVVA PHANI KUMAR CHOWDARY",
      party: "Jai Bharat National Party",
      evm_votes: 505,
      postal_votes: 3,
      total_votes: 508,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 21,
      candidate: "YADISHWAR NAKKA",
      party: "Telangana Republican Party",
      evm_votes: 492,
      postal_votes: 2,
      total_votes: 494,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "R. SUBENDER SINGH",
      party: "Independent",
      evm_votes: 446,
      postal_votes: 1,
      total_votes: 447,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 23,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13206,
      postal_votes: 160,
      total_votes: 13366,
      percentage_of_votes: 0.69,
    },
  ],
  total: {
    evm_votes: 1915185,
    postal_votes: 18658,
    total_votes: 1933843,
  },
};
export const s298 = {
  cs_name: "Secunderabad",
  cs_shortname: "secunderabad",
  cs_code: "s298",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "G. KISHAN REDDY",
      party: "Bharatiya Janata Party",
      evm_votes: 468311,
      postal_votes: 4701,
      total_votes: 473012,
      percentage_of_votes: 45.15,
    },
    {
      serial_no: 2,
      candidate: "DANAM NAGENDER",
      party: "Indian National Congress",
      evm_votes: 420220,
      postal_votes: 2848,
      total_votes: 423068,
      percentage_of_votes: 40.38,
    },
    {
      serial_no: 3,
      candidate: "PADMA RAO . T",
      party: "Bharat Rashtra Samithi",
      evm_votes: 128680,
      postal_votes: 906,
      total_votes: 129586,
      percentage_of_votes: 12.37,
    },
    {
      serial_no: 4,
      candidate: "DR. BASWANANDAM DANDEPU",
      party: "Bahujan Samaj Party",
      evm_votes: 2248,
      postal_votes: 20,
      total_votes: 2268,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 5,
      candidate: "SARARAPU SRISAILAM",
      party: "Telangana Rajya Samithi",
      evm_votes: 1726,
      postal_votes: 1,
      total_votes: 1727,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 6,
      candidate: "SAPAVAT SUMAN",
      party: "Independent",
      evm_votes: 996,
      postal_votes: 0,
      total_votes: 996,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 7,
      candidate: "R.S.J. THOMAS",
      party: "Jai Swaraj Party",
      evm_votes: 971,
      postal_votes: 3,
      total_votes: 974,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 8,
      candidate: "RAASALA VINOD KUMAR",
      party: "Dharma Samaj Party",
      evm_votes: 820,
      postal_votes: 6,
      total_votes: 826,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 9,
      candidate: "KOLISETTY SHIVA KUMAR",
      party: "Yuga Thulasi Party",
      evm_votes: 678,
      postal_votes: 1,
      total_votes: 679,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 10,
      candidate: "MACHCHERLA VENKATA REDDY",
      party: "Independent",
      evm_votes: 672,
      postal_votes: 1,
      total_votes: 673,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 11,
      candidate: "MOHAMMED AKRAM ALI KHAN",
      party: "Independent",
      evm_votes: 596,
      postal_votes: 1,
      total_votes: 597,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "MOHAMMED RAFIUDDIN KALEEM",
      party: "All India Majlis-E-Inquilab-E-Millat",
      evm_votes: 478,
      postal_votes: 6,
      total_votes: 484,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "LUNAVATH HARI RAM",
      party: "Independent",
      evm_votes: 474,
      postal_votes: 0,
      total_votes: 474,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "BOGGULA SUNIITHHA",
      party: "Independent",
      evm_votes: 417,
      postal_votes: 1,
      total_votes: 418,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 15,
      candidate: "MOHAMMED AYUB ALI",
      party: "Anna YSR Congress Party",
      evm_votes: 406,
      postal_votes: 4,
      total_votes: 410,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "MOHAMMED FASI UDDIN",
      party: "Independent",
      evm_votes: 341,
      postal_votes: 1,
      total_votes: 342,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 17,
      candidate: "PULIMAMIDI VENKATESH GUPTA",
      party: "Independent",
      evm_votes: 340,
      postal_votes: 0,
      total_votes: 340,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 18,
      candidate: "IBRAHIM KHAN",
      party: "Independent",
      evm_votes: 315,
      postal_votes: 5,
      total_votes: 320,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 19,
      candidate: "ANDRAPU SUDARSHAN",
      party: "Independent",
      evm_votes: 312,
      postal_votes: 3,
      total_votes: 315,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 20,
      candidate: "JUNAID ANAM SIDDIQUI",
      party: "Independent",
      evm_votes: 294,
      postal_votes: 1,
      total_votes: 295,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 21,
      candidate: "KRANTHI KUMAR BANDELA",
      party: "Indian National Yuva Jana Party",
      evm_votes: 283,
      postal_votes: 2,
      total_votes: 285,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "DR. R. GANGADHAR",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 280,
      postal_votes: 1,
      total_votes: 281,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 23,
      candidate: "UTTAM PRAVEEN KUMAR",
      party: "Independent",
      evm_votes: 273,
      postal_votes: 1,
      total_votes: 274,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 24,
      candidate: "GOWLIKAR SONY",
      party: "Social Justice Party of India",
      evm_votes: 269,
      postal_votes: 3,
      total_votes: 272,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 25,
      candidate: "BATHULA RAVI",
      party: "Independent",
      evm_votes: 252,
      postal_votes: 0,
      total_votes: 252,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 26,
      candidate: "P C LINGANNA",
      party: "Independent",
      evm_votes: 243,
      postal_votes: 0,
      total_votes: 243,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 27,
      candidate: "MOHAMMED IBRAHIM AHMED",
      party: "Independent",
      evm_votes: 240,
      postal_votes: 2,
      total_votes: 242,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 28,
      candidate: "SANGALA FLORY",
      party: "Independent",
      evm_votes: 223,
      postal_votes: 0,
      total_votes: 223,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 29,
      candidate: "MOHAMMED ABDUL AZEEM",
      party: "Independent",
      evm_votes: 220,
      postal_votes: 1,
      total_votes: 221,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 30,
      candidate: "GANESH BHARGAVI GUNDE",
      party: "Jai Bharat National Party",
      evm_votes: 220,
      postal_votes: 3,
      total_votes: 223,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 31,
      candidate: "V. K. VIJAYA LAKSHMI",
      party: "Independent",
      evm_votes: 213,
      postal_votes: 3,
      total_votes: 216,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 32,
      candidate: "CHEEKATI BHUPAL GOUD",
      party: "All India Backward People Sunami Party",
      evm_votes: 209,
      postal_votes: 1,
      total_votes: 210,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 33,
      candidate: "BOMMAKANTI SOWMYA",
      party: "Pyramid Party of India",
      evm_votes: 201,
      postal_votes: 4,
      total_votes: 205,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 34,
      candidate: "KRUPAVARAM GANDU",
      party: "Independent",
      evm_votes: 159,
      postal_votes: 2,
      total_votes: 161,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 35,
      candidate: "PAMPARI NARENDER",
      party: "Independent",
      evm_votes: 143,
      postal_votes: 0,
      total_votes: 143,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 36,
      candidate: "SYED KHADER",
      party: "Independent",
      evm_votes: 132,
      postal_votes: 1,
      total_votes: 133,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 37,
      candidate: "B . SUNITHA RANI",
      party: "Socialist Party (India)",
      evm_votes: 130,
      postal_votes: 3,
      total_votes: 133,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 38,
      candidate: "CHALIKA CHANDRA SEKHAR",
      party: "Independent",
      evm_votes: 129,
      postal_votes: 0,
      total_votes: 129,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 39,
      candidate: "PAGIDIPALLI SHYAMSON",
      party: "Viduthalai Chiruthaigal Katchi",
      evm_votes: 126,
      postal_votes: 4,
      total_votes: 130,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 40,
      candidate: "REDDYMALLA PARVATHI",
      party: "Independent",
      evm_votes: 126,
      postal_votes: 1,
      total_votes: 127,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 41,
      candidate: "VELAMARTHI. RAVI KIRAN",
      party: "Independent",
      evm_votes: 123,
      postal_votes: 1,
      total_votes: 124,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 42,
      candidate: "K. MURALI KRISHNA",
      party: "Independent",
      evm_votes: 120,
      postal_votes: 0,
      total_votes: 120,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 43,
      candidate: "DEVENDAR KONNE",
      party: "Independent",
      evm_votes: 117,
      postal_votes: 0,
      total_votes: 117,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 44,
      candidate: "KONDOJU MOHAN",
      party: "Independent",
      evm_votes: 113,
      postal_votes: 1,
      total_votes: 114,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 45,
      candidate: "MD KHALEEL UZ ZAMA",
      party: "Independent",
      evm_votes: 110,
      postal_votes: 1,
      total_votes: 111,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 46,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5092,
      postal_votes: 74,
      total_votes: 5166,
      percentage_of_votes: 0.49,
    },
  ],
  total: {
    evm_votes: 1039041,
    postal_votes: 8618,
    total_votes: 1047659,
  },
};
export const s2910 = {
  cs_name: "Chevella",
  cs_shortname: "chevella",
  cs_code: "s2910",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "KONDA VISHWESHWAR REDDY",
      party: "Bharatiya Janata Party",
      evm_votes: 798517,
      postal_votes: 11365,
      total_votes: 809882,
      percentage_of_votes: 48.34,
    },
    {
      serial_no: 2,
      candidate: "DR GADDAM RANJITH REDDY",
      party: "Indian National Congress",
      evm_votes: 630861,
      postal_votes: 6124,
      total_votes: 636985,
      percentage_of_votes: 38.02,
    },
    {
      serial_no: 3,
      candidate: "KASANI GYANESHWAR KASANI",
      party: "Bharat Rashtra Samithi",
      evm_votes: 177540,
      postal_votes: 1428,
      total_votes: 178968,
      percentage_of_votes: 10.68,
    },
    {
      serial_no: 4,
      candidate: "RAMULU BINGI",
      party: "Yuga Thulasi Party",
      evm_votes: 4281,
      postal_votes: 3,
      total_votes: 4284,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 5,
      candidate: "KONDA VISHVESHWAR REDDY",
      party: "All India Forward Bloc",
      evm_votes: 3736,
      postal_votes: 12,
      total_votes: 3748,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 6,
      candidate: "SRIDEVI M",
      party: "Alliance of Democratic Reforms Party",
      evm_votes: 2684,
      postal_votes: 4,
      total_votes: 2688,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 7,
      candidate: "MOHAMMED SALEEM",
      party: "Rashtriya Praja Congress (Secular)",
      evm_votes: 2372,
      postal_votes: 1,
      total_votes: 2373,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 8,
      candidate: "SAHITHI DASARI",
      party: "Independent",
      evm_votes: 1924,
      postal_votes: 14,
      total_votes: 1938,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 9,
      candidate: "RANJIT REDDY GADE",
      party: "Revolutionary Socialist Party",
      evm_votes: 1693,
      postal_votes: 5,
      total_votes: 1698,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 10,
      candidate: "MOHD SHAKEEL",
      party: "Desh Janhit Party",
      evm_votes: 1646,
      postal_votes: 1,
      total_votes: 1647,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "THOTLA RAGHAVENDER MUDIRAJ",
      party: "Dharma Samaj Party",
      evm_votes: 1599,
      postal_votes: 49,
      total_votes: 1648,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 12,
      candidate: "JANAPALA DURGA PRASAD",
      party: "Independent",
      evm_votes: 1516,
      postal_votes: 1,
      total_votes: 1517,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "JALEEL AHMED",
      party: "Independent",
      evm_votes: 1508,
      postal_votes: 2,
      total_votes: 1510,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "B V RAMESH NAIDU",
      party: "Socialist Party (India)",
      evm_votes: 1494,
      postal_votes: 1,
      total_votes: 1495,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 15,
      candidate: "PHANI PRASAD KATAKAM",
      party: "Social Justice Party of India",
      evm_votes: 1425,
      postal_votes: 1,
      total_votes: 1426,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 16,
      candidate: "SUGURU SRINIVAS",
      party: "Jai Swaraj Party",
      evm_votes: 1385,
      postal_votes: 1,
      total_votes: 1386,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 17,
      candidate: "G MALLESHAM GOUD",
      party: "Independent",
      evm_votes: 1239,
      postal_votes: 1,
      total_votes: 1240,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "KATHULA YADAIAH",
      party: "Independent",
      evm_votes: 1155,
      postal_votes: 0,
      total_votes: 1155,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 19,
      candidate: "DURGA PRASAD T",
      party: "Praja Velugu Party",
      evm_votes: 1134,
      postal_votes: 5,
      total_votes: 1139,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 20,
      candidate: "V PANDU",
      party: "Independent",
      evm_votes: 1070,
      postal_votes: 5,
      total_votes: 1075,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 21,
      candidate: "SANEM RAJU GOUD",
      party: "Praja Ektha Party",
      evm_votes: 970,
      postal_votes: 1,
      total_votes: 971,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 22,
      candidate: "GONE SRINIVAS REDDY",
      party: "Independent",
      evm_votes: 752,
      postal_votes: 0,
      total_votes: 752,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 23,
      candidate: "GOVIND LAL",
      party: "Independent",
      evm_votes: 661,
      postal_votes: 0,
      total_votes: 661,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 24,
      candidate: "MOHAMMED ABDUL QAVI ABBASI",
      party: "All India Majlis-E-Inquilab-E-Millat",
      evm_votes: 635,
      postal_votes: 0,
      total_votes: 635,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 25,
      candidate: "MOHAMMAD PASHA",
      party: "Independent",
      evm_votes: 628,
      postal_votes: 0,
      total_votes: 628,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 26,
      candidate: "P GOVIND",
      party: "Bahujan Mukti Party",
      evm_votes: 600,
      postal_votes: 11,
      total_votes: 611,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 27,
      candidate: "EESARI SURYAPRAKASH REDDY",
      party: "Independent",
      evm_votes: 581,
      postal_votes: 2,
      total_votes: 583,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 28,
      candidate: "SUDHAKAR VANAM",
      party: "Marxist Communist Party of India (United)",
      evm_votes: 578,
      postal_votes: 3,
      total_votes: 581,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 29,
      candidate: "NARASIMHA REDDY DYAPA",
      party: "Telangana Republican Party",
      evm_votes: 524,
      postal_votes: 1,
      total_votes: 525,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 30,
      candidate: "MOHAMMED IQBAL",
      party: "Independent",
      evm_votes: 493,
      postal_votes: 1,
      total_votes: 494,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 31,
      candidate: "VISHWAKARMA SAPTHAGIRI",
      party: "Independent",
      evm_votes: 489,
      postal_votes: 0,
      total_votes: 489,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 32,
      candidate: "ANKAGALA PRAVEEN KUMAR",
      party: "Independent",
      evm_votes: 487,
      postal_votes: 1,
      total_votes: 488,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 33,
      candidate: "ANJANEYULU NEERATI",
      party: "Independent",
      evm_votes: 446,
      postal_votes: 3,
      total_votes: 449,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 34,
      candidate: "KANDIKEKAR SRINIVAS",
      party: "Independent",
      evm_votes: 445,
      postal_votes: 1,
      total_votes: 446,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 35,
      candidate: "CHINTALAGARI VENKAT SWAMY",
      party: "Blue India Party",
      evm_votes: 431,
      postal_votes: 5,
      total_votes: 436,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 36,
      candidate: "PALAMAKULA MADHU",
      party: "India Praja Bandhu Party",
      evm_votes: 428,
      postal_votes: 2,
      total_votes: 430,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 37,
      candidate: "KAVALI SUKUMAR",
      party: "Independent",
      evm_votes: 392,
      postal_votes: 5,
      total_votes: 397,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 38,
      candidate: "SERUBAI VASANTH KUMAR",
      party: "Rashtra Samanya Praja Party",
      evm_votes: 311,
      postal_votes: 1,
      total_votes: 312,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 39,
      candidate: "MOHAMMED MUSTAFA RIZWAN",
      party: "Independent",
      evm_votes: 300,
      postal_votes: 1,
      total_votes: 301,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 40,
      candidate: "MOHAMMAD RIAZUR REHMAN SHAIK",
      party: "Independent",
      evm_votes: 283,
      postal_votes: 2,
      total_votes: 285,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 41,
      candidate: "MOHAMMED ALI",
      party: "Independent",
      evm_votes: 247,
      postal_votes: 1,
      total_votes: 248,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 42,
      candidate: "AMIR SAJID",
      party: "Independent",
      evm_votes: 226,
      postal_votes: 2,
      total_votes: 228,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 43,
      candidate: "MOHAMMED AFSAR QUDOOS",
      party: "Independent",
      evm_votes: 179,
      postal_votes: 0,
      total_votes: 179,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 44,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6313,
      postal_votes: 110,
      total_votes: 6423,
      percentage_of_votes: 0.38,
    },
  ],
  total: {
    evm_votes: 1656178,
    postal_votes: 19176,
    total_votes: 1675354,
  },
};
export const s2911 = {
  cs_name: "Mahbubnagar",
  cs_shortname: "mahbubnagar",
  cs_code: "s2911",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "ARUNA. D. K",
      party: "Bharatiya Janata Party",
      evm_votes: 506747,
      postal_votes: 4000,
      total_votes: 510747,
      percentage_of_votes: 41.66,
    },
    {
      serial_no: 2,
      candidate: "CHALLA VAMSHI CHAND REDDY",
      party: "Indian National Congress",
      evm_votes: 503111,
      postal_votes: 3136,
      total_votes: 506247,
      percentage_of_votes: 41.29,
    },
    {
      serial_no: 3,
      candidate: "MANNE SRINIVAS REDDY",
      party: "Bharat Rashtra Samithi",
      evm_votes: 154296,
      postal_votes: 496,
      total_votes: 154792,
      percentage_of_votes: 12.62,
    },
    {
      serial_no: 4,
      candidate: "VENKATESWARLU TALLADA",
      party: "Alliance of Democratic Reforms Party",
      evm_votes: 6375,
      postal_votes: 4,
      total_votes: 6379,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 5,
      candidate: "KINNERA YADAGIRI",
      party: "Independent",
      evm_votes: 4470,
      postal_votes: 0,
      total_votes: 4470,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 6,
      candidate: "KURA VENKATAIAH",
      party: "Independent",
      evm_votes: 4248,
      postal_votes: 1,
      total_votes: 4249,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 7,
      candidate: "HARINDER REDDY MALLELA",
      party: "Independent",
      evm_votes: 3814,
      postal_votes: 1,
      total_votes: 3815,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 8,
      candidate: "KARUKONDA SRINIVAS YADAV",
      party: "Independent",
      evm_votes: 3450,
      postal_votes: 1,
      total_votes: 3451,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 9,
      candidate: "HANUMESH",
      party: "Independent",
      evm_votes: 3225,
      postal_votes: 0,
      total_votes: 3225,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 10,
      candidate: "MOHD ALAUDDIN",
      party: "Bahujan Samaj Party",
      evm_votes: 3056,
      postal_votes: 21,
      total_votes: 3077,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 11,
      candidate: "GOVINDAMMA",
      party: "Independent",
      evm_votes: 2748,
      postal_votes: 0,
      total_votes: 2748,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 12,
      candidate: "GANTLAVELLI RAKESH",
      party: "Dharma Samaj Party",
      evm_votes: 2600,
      postal_votes: 18,
      total_votes: 2618,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 13,
      candidate: "UMASHANKAR NEMMIKANTI",
      party: "Independent",
      evm_votes: 2214,
      postal_votes: 0,
      total_votes: 2214,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 14,
      candidate: "DINESH",
      party: "Independent",
      evm_votes: 1726,
      postal_votes: 1,
      total_votes: 1727,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 15,
      candidate: "ANDHARI ANJAIAH",
      party: "Independent",
      evm_votes: 1669,
      postal_votes: 6,
      total_votes: 1675,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 16,
      candidate: "SANGEPAGA SAROJANAMMA",
      party: "Independent",
      evm_votes: 1563,
      postal_votes: 2,
      total_votes: 1565,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 17,
      candidate: "B. RAVINDER",
      party: "Social Justice Party of India",
      evm_votes: 1082,
      postal_votes: 3,
      total_votes: 1085,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 18,
      candidate: "K. UDAY TEJ NAIK",
      party: "Independent",
      evm_votes: 1027,
      postal_votes: 0,
      total_votes: 1027,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 19,
      candidate: "MARLA ANJANEYULU",
      party: "Jai Swaraj Party",
      evm_votes: 1019,
      postal_votes: 1,
      total_votes: 1020,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 20,
      candidate: "NADIMINTI SRINIVASULU",
      party: "Independent",
      evm_votes: 983,
      postal_votes: 2,
      total_votes: 985,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 21,
      candidate: "M. NAVEEN REDDY",
      party: "Independent",
      evm_votes: 951,
      postal_votes: 1,
      total_votes: 952,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 22,
      candidate: "VIJAYA",
      party: "Independent",
      evm_votes: 592,
      postal_votes: 4,
      total_votes: 596,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 23,
      candidate: "ANEESUR RAHMAN",
      party: "Bahujan Mukti Party",
      evm_votes: 503,
      postal_votes: 0,
      total_votes: 503,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 24,
      candidate: "A. SRINIVASULU",
      party: "Independent",
      evm_votes: 437,
      postal_votes: 1,
      total_votes: 438,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 25,
      candidate: "KADINTI SHANKAR REDDY",
      party: "Viduthalai Chiruthaigal Katchi",
      evm_votes: 427,
      postal_votes: 5,
      total_votes: 432,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 26,
      candidate: "NARESH REDDY",
      party: "Telangana Jaghir Party",
      evm_votes: 420,
      postal_votes: 0,
      total_votes: 420,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 27,
      candidate: "VENKATA RAMANA",
      party: "Independent",
      evm_votes: 352,
      postal_votes: 1,
      total_votes: 353,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 28,
      candidate: "BANDA SATYANARAYANA",
      party: "Independent",
      evm_votes: 263,
      postal_votes: 1,
      total_votes: 264,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 29,
      candidate: "PULLANGARI SANDEEP KUMAR REDDY",
      party: "Independent",
      evm_votes: 261,
      postal_votes: 1,
      total_votes: 262,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 30,
      candidate: "VISHNU VARDHAN REDDY THAPALAKINDHI",
      party: "Independent",
      evm_votes: 227,
      postal_votes: 2,
      total_votes: 229,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 31,
      candidate: "MUDAVATH BALARAJU NAIK",
      party: "Independent",
      evm_votes: 181,
      postal_votes: 2,
      total_votes: 183,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 32,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4286,
      postal_votes: 44,
      total_votes: 4330,
      percentage_of_votes: 0.35,
    },
  ],
  total: {
    evm_votes: 1218323,
    postal_votes: 7755,
    total_votes: 1226078,
  },
};
export const u011 = {
  cs_name: "Andaman_&_Nicobar_Islands",
  cs_shortname: "andaman&nicobarislands",
  cs_code: "u011",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BISHNU PADA RAY",
      party: "Bharatiya Janata Party",
      evm_votes: 102182,
      postal_votes: 254,
      total_votes: 102436,
      percentage_of_votes: 50.58,
    },
    {
      serial_no: 2,
      candidate: "KULDEEP RAI SHARMA",
      party: "Indian National Congress",
      evm_votes: 77829,
      postal_votes: 211,
      total_votes: 78040,
      percentage_of_votes: 38.54,
    },
    {
      serial_no: 3,
      candidate: "MANOJ PAUL",
      party: "Andaman Nicobar Democratic Congress",
      evm_votes: 8236,
      postal_votes: 18,
      total_votes: 8254,
      percentage_of_votes: 4.08,
    },
    {
      serial_no: 4,
      candidate: "D AYYAPPAN",
      party: "Communist Party of India (Marxist)",
      evm_votes: 6009,
      postal_votes: 8,
      total_votes: 6017,
      percentage_of_votes: 2.97,
    },
    {
      serial_no: 5,
      candidate: "V.K. ABDUL AZIZ",
      party: "Independent",
      evm_votes: 2195,
      postal_votes: 8,
      total_votes: 2203,
      percentage_of_votes: 1.09,
    },
    {
      serial_no: 6,
      candidate: "K J B SELVARAJ",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 911,
      postal_votes: 3,
      total_votes: 914,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 7,
      candidate: "DR ARUN KUMAR MALLIK",
      party: "Bahujan Samaj Party",
      evm_votes: 714,
      postal_votes: 5,
      total_votes: 719,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 8,
      candidate: "RINKU MALA MONDAL",
      party: "Independent",
      evm_votes: 539,
      postal_votes: 3,
      total_votes: 542,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 9,
      candidate: "K VENKAT RAM BABU",
      party: "Independent",
      evm_votes: 506,
      postal_votes: 1,
      total_votes: 507,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 10,
      candidate: "USHA KUMARI",
      party: "Independent",
      evm_votes: 378,
      postal_votes: 2,
      total_votes: 380,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 11,
      candidate: "SALAMAT MONDAL",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 349,
      postal_votes: 2,
      total_votes: 351,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "ANAND RAMNATH ARLEKAR",
      party: "Independent",
      evm_votes: 342,
      postal_votes: 0,
      total_votes: 342,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 1802,
      postal_votes: 7,
      total_votes: 1809,
      percentage_of_votes: 0.89,
    },
  ],
  total: {
    evm_votes: 201992,
    postal_votes: 522,
    total_votes: 202514,
  },
};
export const u032 = {
  cs_name: "Dadar_&_Nagar_Haveli",
  cs_shortname: "dadar&nagarhaveli",
  cs_code: "u032",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DELKAR KALABEN MOHANBHAI",
      party: "Bharatiya Janata Party",
      evm_votes: 120833,
      postal_votes: 241,
      total_votes: 121074,
      percentage_of_votes: 58.89,
    },
    {
      serial_no: 2,
      candidate: "AJIT RAMJIBHAI MAHALA",
      party: "Indian National Congress",
      evm_votes: 63423,
      postal_votes: 67,
      total_votes: 63490,
      percentage_of_votes: 30.88,
    },
    {
      serial_no: 3,
      candidate: "KURADA DEEPAKBHAI",
      party: "Bharat Adivasi Party",
      evm_votes: 10188,
      postal_votes: 9,
      total_votes: 10197,
      percentage_of_votes: 4.96,
    },
    {
      serial_no: 4,
      candidate: "BORSA SANDIPBHAI S",
      party: "Bahujan Samaj Party",
      evm_votes: 3147,
      postal_votes: 5,
      total_votes: 3152,
      percentage_of_votes: 1.53,
    },
    {
      serial_no: 5,
      candidate: "SHAILESHBHAI VARTHA",
      party: "Independent",
      evm_votes: 2522,
      postal_votes: 2,
      total_votes: 2524,
      percentage_of_votes: 1.23,
    },
    {
      serial_no: 6,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5144,
      postal_votes: 7,
      total_votes: 5151,
      percentage_of_votes: 2.51,
    },
  ],
  total: {
    evm_votes: 205257,
    postal_votes: 331,
    total_votes: 205588,
  },
};
export const u051 = {
  cs_name: "Chandni_Chowk",
  cs_shortname: "chandnichowk",
  cs_code: "u051",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "PRAVEEN KHANDELWAL",
      party: "Bharatiya Janata Party",
      evm_votes: 514958,
      postal_votes: 1538,
      total_votes: 516496,
      percentage_of_votes: 53.46,
    },
    {
      serial_no: 2,
      candidate: "JAI PRAKASH AGARWAL",
      party: "Indian National Congress",
      evm_votes: 426542,
      postal_votes: 629,
      total_votes: 427171,
      percentage_of_votes: 44.22,
    },
    {
      serial_no: 3,
      candidate: "ABUL KALAM AZAD",
      party: "Bahujan Samaj Party",
      evm_votes: 5820,
      postal_votes: 9,
      total_votes: 5829,
      percentage_of_votes: 0.6,
    },
    {
      serial_no: 4,
      candidate: "UMESH RATHOR",
      party: "Atulya Bharat Party",
      evm_votes: 1614,
      postal_votes: 3,
      total_votes: 1617,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 5,
      candidate: "SEEMA RIZVI",
      party: "Azad Samaj Party",
      evm_votes: 1089,
      postal_votes: 0,
      total_votes: 1089,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 6,
      candidate: "SHAIK JALEEL",
      party: "Navarang Congress Party",
      evm_votes: 947,
      postal_votes: 2,
      total_votes: 949,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 7,
      candidate: "MD NAZIR",
      party: "Aman Samaj Party",
      evm_votes: 945,
      postal_votes: 0,
      total_votes: 945,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 8,
      candidate: "SATYA DEV CHAUDHARY",
      party: "Satya Bahumat Party",
      evm_votes: 842,
      postal_votes: 0,
      total_votes: 842,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 9,
      candidate: "RITU KAUSHIK",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 642,
      postal_votes: 1,
      total_votes: 643,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 10,
      candidate: "SAMEER MIRZA",
      party: "Rashtriya Republican Party",
      evm_votes: 513,
      postal_votes: 0,
      total_votes: 513,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 11,
      candidate: "SOHARSH GULGULIA JAIN",
      party: "Swaraj Bhartiya Nyay Party",
      evm_votes: 503,
      postal_votes: 0,
      total_votes: 503,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 12,
      candidate: "SHYAM BHARTEEY",
      party: "Gareeb Aadmi Party",
      evm_votes: 462,
      postal_votes: 1,
      total_votes: 463,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "GULJAR SINGH",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 424,
      postal_votes: 0,
      total_votes: 424,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 14,
      candidate: "YOGENDER SINGH",
      party: "Bharatiya Liberal Party",
      evm_votes: 389,
      postal_votes: 0,
      total_votes: 389,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 15,
      candidate: "VINOD KUMAR GUPTA",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 357,
      postal_votes: 2,
      total_votes: 359,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "SHIVAM SAINI",
      party: "Right to Recall Party",
      evm_votes: 330,
      postal_votes: 0,
      total_votes: 330,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 17,
      candidate: "MANOJ KUMAR NIGAM",
      party: "Akhil Bhartiya Sudhar Party",
      evm_votes: 321,
      postal_votes: 0,
      total_votes: 321,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 18,
      candidate: "RAJESH GUPTA",
      party: "Bharat Lok Sewak Party",
      evm_votes: 261,
      postal_votes: 1,
      total_votes: 262,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 19,
      candidate: "RAHUL SHARMA",
      party: "Rashtriya Mangalam Party",
      evm_votes: 256,
      postal_votes: 1,
      total_votes: 257,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 20,
      candidate: "SUNIL KUMAR",
      party: "Independent",
      evm_votes: 247,
      postal_votes: 0,
      total_votes: 247,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 21,
      candidate: "SUBHASH CHANDER",
      party: "Independent",
      evm_votes: 220,
      postal_votes: 0,
      total_votes: 220,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 22,
      candidate: "MOHD HAMID",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 189,
      postal_votes: 0,
      total_votes: 189,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 23,
      candidate: "PYUSH NARULA",
      party: "Independent",
      evm_votes: 172,
      postal_votes: 0,
      total_votes: 172,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 24,
      candidate: "HEENA",
      party: "Public Political Party",
      evm_votes: 169,
      postal_votes: 0,
      total_votes: 169,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 25,
      candidate: "ASHOK KUMAR GUPTA",
      party: "Independent",
      evm_votes: 139,
      postal_votes: 1,
      total_votes: 140,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 26,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5544,
      postal_votes: 19,
      total_votes: 5563,
      percentage_of_votes: 0.58,
    },
  ],
  total: {
    evm_votes: 963895,
    postal_votes: 2207,
    total_votes: 966102,
  },
};
export const u052 = {
  cs_name: "North-East_Delhi",
  cs_shortname: "northeastdelhi",
  cs_code: "u052",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "MANOJ TIWARI",
      party: "Bharatiya Janata Party",
      evm_votes: 821567,
      postal_votes: 2884,
      total_votes: 824451,
      percentage_of_votes: 53.1,
    },
    {
      serial_no: 2,
      candidate: "KANHAIYA KUMAR",
      party: "Indian National Congress",
      evm_votes: 684501,
      postal_votes: 1172,
      total_votes: 685673,
      percentage_of_votes: 44.16,
    },
    {
      serial_no: 3,
      candidate: "ASHOK KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 12053,
      postal_votes: 85,
      total_votes: 12138,
      percentage_of_votes: 0.78,
    },
    {
      serial_no: 4,
      candidate: "SUNITA",
      party: "Navarang Congress Party",
      evm_votes: 3518,
      postal_votes: 0,
      total_votes: 3518,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 5,
      candidate: "AKESHAN DUT",
      party: "Independent",
      evm_votes: 3325,
      postal_votes: 0,
      total_votes: 3325,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 6,
      candidate: "SUSHIL CHOUDHARY",
      party: "Aapki Apni Party (Peoples)",
      evm_votes: 2066,
      postal_votes: 4,
      total_votes: 2070,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 7,
      candidate: "AJAY TIWARI",
      party: "Right to Recall Party",
      evm_votes: 2040,
      postal_votes: 2,
      total_votes: 2042,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 8,
      candidate: "SUBHASH",
      party: "Rashtriya Janmorcha",
      evm_votes: 1639,
      postal_votes: 0,
      total_votes: 1639,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 9,
      candidate: "AKASH SRIVASTAV",
      party: "Jai Hind National Party",
      evm_votes: 1317,
      postal_votes: 0,
      total_votes: 1317,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 10,
      candidate: "SANJEEV KUMAR PANDEY",
      party: "Bharatiya Liberal Party",
      evm_votes: 1163,
      postal_votes: 0,
      total_votes: 1163,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "SAROJ KUMAR RAY",
      party: "Independent",
      evm_votes: 931,
      postal_votes: 66,
      total_votes: 997,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "ADV. BHIM KISHOR",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 826,
      postal_votes: 1,
      total_votes: 827,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "SANJEEV KUMAR",
      party: "Public Political Party",
      evm_votes: 799,
      postal_votes: 0,
      total_votes: 799,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "PRAKASH DEVI",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 676,
      postal_votes: 0,
      total_votes: 676,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 15,
      candidate: "LAKSHYA VASHIST (SHYAM)",
      party: "Jindabad Kranti Party",
      evm_votes: 653,
      postal_votes: 0,
      total_votes: 653,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "BABITA YADAV",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 646,
      postal_votes: 0,
      total_votes: 646,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 17,
      candidate: "MANOJ KUMAR",
      party: "Independent",
      evm_votes: 559,
      postal_votes: 2,
      total_votes: 561,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 18,
      candidate: "NIVEDITA SHARMA “PREM”",
      party: "Rashtrawadi Janlok Party (Satya)",
      evm_votes: 541,
      postal_votes: 2,
      total_votes: 543,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 19,
      candidate: "DR. RAJESH KUMAR SINGH",
      party: "Independent",
      evm_votes: 539,
      postal_votes: 1,
      total_votes: 540,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 20,
      candidate: "KANHI LAL",
      party: "Swatantra Jantaraj Party",
      evm_votes: 496,
      postal_votes: 2,
      total_votes: 498,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 21,
      candidate: "VIJAY KUMAR JHA",
      party: "Independent",
      evm_votes: 486,
      postal_votes: 5,
      total_votes: 491,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "YOGESH SWAMY",
      party: "Independent",
      evm_votes: 410,
      postal_votes: 0,
      total_votes: 410,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 23,
      candidate: "ASHOK MISHRA",
      party: "Independent",
      evm_votes: 352,
      postal_votes: 0,
      total_votes: 352,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 24,
      candidate: "ADV. K.T. PALUSKAR",
      party: "Prabuddha Republican Party",
      evm_votes: 349,
      postal_votes: 0,
      total_votes: 349,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 25,
      candidate: "RAVI KIRAN THILAK THATIKONDA",
      party: "Independent",
      evm_votes: 324,
      postal_votes: 0,
      total_votes: 324,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 26,
      candidate: "ISHWAR CHAND",
      party: "Bharatrashtra Democratic Party",
      evm_votes: 310,
      postal_votes: 0,
      total_votes: 310,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 27,
      candidate: "JAGDISH",
      party: "Rashtriya Samaj Paksha",
      evm_votes: 276,
      postal_votes: 0,
      total_votes: 276,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 28,
      candidate: "BIRPAL SINGH KOLI",
      party: "Independent",
      evm_votes: 269,
      postal_votes: 1,
      total_votes: 270,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 29,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5845,
      postal_votes: 28,
      total_votes: 5873,
      percentage_of_votes: 0.38,
    },
  ],
  total: {
    evm_votes: 1548476,
    postal_votes: 4255,
    total_votes: 1552731,
  },
};
export const u053 = {
  cs_name: "East_Delhi",
  cs_shortname: "eastdelhi",
  cs_code: "u053",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "HARSH MALHOTRA",
      party: "Bharatiya Janata Party",
      evm_votes: 663420,
      postal_votes: 1399,
      total_votes: 664819,
      percentage_of_votes: 52.59,
    },
    {
      serial_no: 2,
      candidate: "KULDEEP KUMAR (MONU)",
      party: "Aam Aadmi Party",
      evm_votes: 570494,
      postal_votes: 662,
      total_votes: 571156,
      percentage_of_votes: 45.18,
    },
    {
      serial_no: 3,
      candidate: "MOHD. WAQAR CHOUDHARY",
      party: "Bahujan Samaj Party",
      evm_votes: 9158,
      postal_votes: 39,
      total_votes: 9197,
      percentage_of_votes: 0.73,
    },
    {
      serial_no: 4,
      candidate: "A. GOPALA KRISHNA",
      party: "Navarang Congress Party",
      evm_votes: 2807,
      postal_votes: 10,
      total_votes: 2817,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 5,
      candidate: "KULDEEP KUMAR",
      party: "Independent",
      evm_votes: 1579,
      postal_votes: 3,
      total_votes: 1582,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 6,
      candidate: "MANOJ KUMAR GUPTA (SWARAJI)",
      party: "Independent",
      evm_votes: 1191,
      postal_votes: 1,
      total_votes: 1192,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 7,
      candidate: "RODASH GUPTA",
      party: "Independent",
      evm_votes: 1097,
      postal_votes: 2,
      total_votes: 1099,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 8,
      candidate: "JYOTI PRAKASH (MONU)",
      party: "Ekam Sanatan Bharat Dal",
      evm_votes: 824,
      postal_votes: 0,
      total_votes: 824,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 9,
      candidate: "ARUNIMA GAUTAM",
      party: "Independent",
      evm_votes: 749,
      postal_votes: 2,
      total_votes: 751,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 10,
      candidate: "JAI RAM LAL",
      party: "Rashtriya Republican Party",
      evm_votes: 657,
      postal_votes: 0,
      total_votes: 657,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 11,
      candidate: "ADVOCATE MANJEET SINGH",
      party: "Rashtra Nirman Party",
      evm_votes: 614,
      postal_votes: 0,
      total_votes: 614,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 12,
      candidate: "MOHAMMAD IRFAN",
      party: "Independent",
      evm_votes: 582,
      postal_votes: 1,
      total_votes: 583,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "OM SHANKAR",
      party: "Independent",
      evm_votes: 556,
      postal_votes: 0,
      total_votes: 556,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 14,
      candidate: "LAL SINGH",
      party: "Bahujan Dravida Party",
      evm_votes: 476,
      postal_votes: 1,
      total_votes: 477,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 15,
      candidate: "YOGESH CHAUDHARY",
      party: "Bhartiya Veer Dal",
      evm_votes: 468,
      postal_votes: 2,
      total_votes: 470,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "VIMAL KUMAR SAXENA",
      party: "Independent",
      evm_votes: 427,
      postal_votes: 6,
      total_votes: 433,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 17,
      candidate: "SURENDRA SINGH BASOYA",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 392,
      postal_votes: 7,
      total_votes: 399,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 18,
      candidate: "SANJAY",
      party: "Public Political Party",
      evm_votes: 378,
      postal_votes: 1,
      total_votes: 379,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 19,
      candidate: "AMIT GUPTA",
      party: "Independent",
      evm_votes: 358,
      postal_votes: 2,
      total_votes: 360,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 20,
      candidate: "SANJAYA KUMAR YADAV",
      party: "Right to Recall Party",
      evm_votes: 289,
      postal_votes: 1,
      total_votes: 290,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 21,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5371,
      postal_votes: 23,
      total_votes: 5394,
      percentage_of_votes: 0.43,
    },
  ],
  total: {
    evm_votes: 1261887,
    postal_votes: 2162,
    total_votes: 1264049,
  },
};
export const u054 = {
  cs_name: "New_Delhi",
  cs_shortname: "newdelhi",
  cs_code: "u054",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "BANSURI SWARAJ",
      party: "Bharatiya Janata Party",
      evm_votes: 451464,
      postal_votes: 1721,
      total_votes: 453185,
      percentage_of_votes: 53.48,
    },
    {
      serial_no: 2,
      candidate: "SOMNATH BHARTI",
      party: "Aam Aadmi Party",
      evm_votes: 374032,
      postal_votes: 783,
      total_votes: 374815,
      percentage_of_votes: 44.23,
    },
    {
      serial_no: 3,
      candidate: "RAAJ KUMAR ANAND",
      party: "Bahujan Samaj Party",
      evm_votes: 5601,
      postal_votes: 28,
      total_votes: 5629,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 4,
      candidate: "SANJAY RAWAT",
      party: "Independent",
      evm_votes: 1271,
      postal_votes: 5,
      total_votes: 1276,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 5,
      candidate: "ACHLA JETHMALANI",
      party: "Jindabad Kranti Party",
      evm_votes: 1036,
      postal_votes: 1,
      total_votes: 1037,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 6,
      candidate: "HIRA LAL",
      party: "Independent",
      evm_votes: 1005,
      postal_votes: 0,
      total_votes: 1005,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 7,
      candidate: "PREM SINGH",
      party: "Navarang Congress Party",
      evm_votes: 800,
      postal_votes: 6,
      total_votes: 806,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 8,
      candidate: "UPEN‍DRA",
      party: "Anjaan Aadmi Party",
      evm_votes: 781,
      postal_votes: 0,
      total_votes: 781,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 9,
      candidate: "DILIP SINGH",
      party: "Independent",
      evm_votes: 725,
      postal_votes: 1,
      total_votes: 726,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 10,
      candidate: "LUKMAN KHAN",
      party: "Log Party",
      evm_votes: 654,
      postal_votes: 2,
      total_votes: 656,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 11,
      candidate: "PUNAM PANDEY",
      party: "Rashtravadi Loktantrik Party (India)",
      evm_votes: 640,
      postal_votes: 1,
      total_votes: 641,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "SANDHYA THAKKAR",
      party: "Rashtriya Republican Party",
      evm_votes: 485,
      postal_votes: 1,
      total_votes: 486,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "RAVINDER SINGH",
      party: "Independent",
      evm_votes: 414,
      postal_votes: 2,
      total_votes: 416,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "DINESH KUMAR",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 360,
      postal_votes: 1,
      total_votes: 361,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 15,
      candidate: "PRITHVI NATH SINGH",
      party: "Bharatiya Sampuran Krantikari Party",
      evm_votes: 335,
      postal_votes: 0,
      total_votes: 335,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "MAHESH KUMAR DENWAL",
      party: "Ambedkarite Party of India",
      evm_votes: 287,
      postal_votes: 1,
      total_votes: 288,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 17,
      candidate: "MANOJ KUMAR SHARMA",
      party: "Loktantrik Janshakti Party",
      evm_votes: 208,
      postal_votes: 4,
      total_votes: 212,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 18,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4791,
      postal_votes: 22,
      total_votes: 4813,
      percentage_of_votes: 0.57,
    },
  ],
  total: {
    evm_votes: 844889,
    postal_votes: 2579,
    total_votes: 847468,
  },
};
export const u055 = {
  cs_name: "North-West_Delhi",
  cs_shortname: "northwestdelhi",
  cs_code: "u055",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "YOGENDER CHANDOLIYA",
      party: "Bharatiya Janata Party",
      evm_votes: 863255,
      postal_votes: 3228,
      total_votes: 866483,
      percentage_of_votes: 58.26,
    },
    {
      serial_no: 2,
      candidate: "UDIT RAJ",
      party: "Indian National Congress",
      evm_votes: 574451,
      postal_votes: 1183,
      total_votes: 575634,
      percentage_of_votes: 38.71,
    },
    {
      serial_no: 3,
      candidate: "VIJAY BAUDDH",
      party: "Bahujan Samaj Party",
      evm_votes: 11955,
      postal_votes: 42,
      total_votes: 11997,
      percentage_of_votes: 0.81,
    },
    {
      serial_no: 4,
      candidate: "SURESH KUMAR",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 2869,
      postal_votes: 1,
      total_votes: 2870,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 5,
      candidate: "SANJAY KUMAR SIWAL",
      party: "Rashtriya Janshakti Party (Secular)",
      evm_votes: 2866,
      postal_votes: 0,
      total_votes: 2866,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 6,
      candidate: "SUBHASH",
      party: "Asankhya Samaj Party",
      evm_votes: 2250,
      postal_votes: 0,
      total_votes: 2250,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 7,
      candidate: "RAJENDER SOOD",
      party: "Aam Janshakti Party",
      evm_votes: 1468,
      postal_votes: 10,
      total_votes: 1478,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 8,
      candidate: "PRAVEEN GAUTAM",
      party: "Navarang Congress Party",
      evm_votes: 1455,
      postal_votes: 1,
      total_votes: 1456,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 9,
      candidate: "K M KAJAL",
      party: "Public Political Party",
      evm_votes: 1334,
      postal_votes: 8,
      total_votes: 1342,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 10,
      candidate: "NARESH KUMAR",
      party: "Independent",
      evm_votes: 1270,
      postal_votes: 5,
      total_votes: 1275,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 11,
      candidate: "ADVOCATE SATISH CHANDRA",
      party: "Ambedkarite Party of India",
      evm_votes: 1236,
      postal_votes: 1,
      total_votes: 1237,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "PARMENDRA MANJHI",
      party: "Independent",
      evm_votes: 1102,
      postal_votes: 0,
      total_votes: 1102,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "ADVOCATE DR. MAHENDER SINGH CHURIYANA",
      party: "Bahujan Dravida Party",
      evm_votes: 1015,
      postal_votes: 1,
      total_votes: 1016,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 14,
      candidate: "CHANDER PAL SONI",
      party: "Independent",
      evm_votes: 891,
      postal_votes: 0,
      total_votes: 891,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "ADITI",
      party: "Independent",
      evm_votes: 790,
      postal_votes: 0,
      total_votes: 790,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "ADV. KRUPAL",
      party: "Prabuddha Republican Party",
      evm_votes: 673,
      postal_votes: 2,
      total_votes: 675,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 17,
      candidate: "NAND RAM BAGRI",
      party: "Voters Party International",
      evm_votes: 638,
      postal_votes: 0,
      total_votes: 638,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 18,
      candidate: "SHYAM BHARTEEY",
      party: "Gareeb Aadmi Party",
      evm_votes: 629,
      postal_votes: 3,
      total_votes: 632,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 19,
      candidate: "HARI KISHAN (MECHANIC)",
      party: "Independent",
      evm_votes: 547,
      postal_votes: 1,
      total_votes: 548,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 20,
      candidate: "POOJA (BHAGTANI)",
      party: "Bharatiya Jan Jagriti Party",
      evm_votes: 504,
      postal_votes: 5,
      total_votes: 509,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 21,
      candidate: "NARESH KUMAR",
      party: "Rashtriya Samaj Paksha",
      evm_votes: 499,
      postal_votes: 1,
      total_votes: 500,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "PREETI",
      party: "Saman Adhikar Party",
      evm_votes: 495,
      postal_votes: 8,
      total_votes: 503,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 23,
      candidate: "SATYA PRAKASH UTTRAKHANDI",
      party: "Independent",
      evm_votes: 433,
      postal_votes: 1,
      total_votes: 434,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 24,
      candidate: "KHILKHILAKAR",
      party: "Bahujan Shoshit Samaj Sangharsh Samta Party",
      evm_votes: 372,
      postal_votes: 1,
      total_votes: 373,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 25,
      candidate: "PRADEEP KUMAR",
      party: "Independent",
      evm_votes: 371,
      postal_votes: 0,
      total_votes: 371,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 26,
      candidate: "PUSHPA NAGRA",
      party: "Independent",
      evm_votes: 353,
      postal_votes: 0,
      total_votes: 353,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 27,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8917,
      postal_votes: 67,
      total_votes: 8984,
      percentage_of_votes: 0.6,
    },
  ],
  total: {
    evm_votes: 1482638,
    postal_votes: 4569,
    total_votes: 1487207,
  },
};
export const u056 = {
  cs_name: "West_Delhi",
  cs_shortname: "westdelhi",
  cs_code: "u056",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "KAMALJEET SEHRAWAT",
      party: "Bharatiya Janata Party",
      evm_votes: 838332,
      postal_votes: 4326,
      total_votes: 842658,
      percentage_of_votes: 55.27,
    },
    {
      serial_no: 2,
      candidate: "MAHABAL MISHRA",
      party: "Aam Aadmi Party",
      evm_votes: 641759,
      postal_votes: 1886,
      total_votes: 643645,
      percentage_of_votes: 42.22,
    },
    {
      serial_no: 3,
      candidate: "VISHAKHA",
      party: "Bahujan Samaj Party",
      evm_votes: 7899,
      postal_votes: 65,
      total_votes: 7964,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 4,
      candidate: "SABIR KHAN",
      party: "Independent",
      evm_votes: 3096,
      postal_votes: 1,
      total_votes: 3097,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 5,
      candidate: "AJ RAJAN",
      party: "Navarang Congress Party",
      evm_votes: 2381,
      postal_votes: 6,
      total_votes: 2387,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 6,
      candidate: "SUNIL MEGHWAL",
      party: "Ambedkarite Party of India",
      evm_votes: 1874,
      postal_votes: 2,
      total_votes: 1876,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 7,
      candidate: "OM PRAKASH NARAYAN",
      party: "Aapki Apni Party (Peoples)",
      evm_votes: 1770,
      postal_votes: 3,
      total_votes: 1773,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 8,
      candidate: "ANJU SHARMA",
      party: "Independent",
      evm_votes: 1263,
      postal_votes: 4,
      total_votes: 1267,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 9,
      candidate: "KAPIL MARWAH",
      party: "Independent",
      evm_votes: 1154,
      postal_votes: 1,
      total_votes: 1155,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 10,
      candidate: "JARNAIL SINGH",
      party: "Independent",
      evm_votes: 1083,
      postal_votes: 6,
      total_votes: 1089,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "MADHU",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 1056,
      postal_votes: 3,
      total_votes: 1059,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "RAJIV KANT MISHRA",
      party: "Independent",
      evm_votes: 995,
      postal_votes: 0,
      total_votes: 995,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "RAMESH KUMAR JAIN @ NIRBHIK",
      party: "Gareeb Aadmi Party",
      evm_votes: 971,
      postal_votes: 0,
      total_votes: 971,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 14,
      candidate: "UMA SHANKAR TIWARI",
      party: "Bhartiya Mahasangh Party",
      evm_votes: 753,
      postal_votes: 5,
      total_votes: 758,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "MEENA UJJAINWAL",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 752,
      postal_votes: 4,
      total_votes: 756,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "CHARANJEET SINGH",
      party: "All India Forward Bloc",
      evm_votes: 614,
      postal_votes: 5,
      total_votes: 619,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 17,
      candidate: "UMESH CHAUHAN",
      party: "Rashtriya Rashtrawadi Party",
      evm_votes: 611,
      postal_votes: 3,
      total_votes: 614,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 18,
      candidate: "PRAMOD KUREEL",
      party: "Bahujan National Party (Ambedkar)",
      evm_votes: 585,
      postal_votes: 1,
      total_votes: 586,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 19,
      candidate: "AMIT KUMAR SINGH",
      party: "Rashtriya Suraksha Party",
      evm_votes: 571,
      postal_votes: 3,
      total_votes: 574,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 20,
      candidate: "JAYA RAMAN",
      party: "Sarvlokhit Samaj Party",
      evm_votes: 439,
      postal_votes: 7,
      total_votes: 446,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 21,
      candidate: "RAM NARAYAN MOHANTY",
      party: "Independent",
      evm_votes: 433,
      postal_votes: 1,
      total_votes: 434,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "CHAMAN LAL VERMA",
      party: "Samaj Vikas Kranti Party",
      evm_votes: 418,
      postal_votes: 2,
      total_votes: 420,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 23,
      candidate: "DEEPAK SHARMA",
      party: "Bharatiya Jan Jagriti Party",
      evm_votes: 362,
      postal_votes: 8,
      total_votes: 370,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 24,
      candidate: "RAVI",
      party: "Independent",
      evm_votes: 280,
      postal_votes: 2,
      total_votes: 282,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 25,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8624,
      postal_votes: 75,
      total_votes: 8699,
      percentage_of_votes: 0.57,
    },
  ],
  total: {
    evm_votes: 1518075,
    postal_votes: 6419,
    total_votes: 1524494,
  },
};
export const u057 = {
  cs_name: "South_Delhi",
  cs_shortname: "southdelhi",
  cs_code: "u057",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAMVIR SINGH BIDHURI",
      party: "Bharatiya Janata Party",
      evm_votes: 691184,
      postal_votes: 1648,
      total_votes: 692832,
      percentage_of_votes: 53.46,
    },
    {
      serial_no: 2,
      candidate: "SAHI RAM",
      party: "Aam Aadmi Party",
      evm_votes: 567716,
      postal_votes: 783,
      total_votes: 568499,
      percentage_of_votes: 43.87,
    },
    {
      serial_no: 3,
      candidate: "ABDUL BASIT",
      party: "Bahujan Samaj Party",
      evm_votes: 9829,
      postal_votes: 32,
      total_votes: 9861,
      percentage_of_votes: 0.76,
    },
    {
      serial_no: 4,
      candidate: "RAM GOPAL",
      party: "Haryana Jansena Party",
      evm_votes: 3956,
      postal_votes: 2,
      total_votes: 3958,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 5,
      candidate: "CHANDRASHEKHAR SINGH KUSHWAHA",
      party: "Independent",
      evm_votes: 1888,
      postal_votes: 1,
      total_votes: 1889,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 6,
      candidate: "DEEPAK KAPILA",
      party: "Independent",
      evm_votes: 1500,
      postal_votes: 0,
      total_votes: 1500,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 7,
      candidate: "OMAPRAKASH",
      party: "Samaj Vikas Kranti Party",
      evm_votes: 1364,
      postal_votes: 1,
      total_votes: 1365,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 8,
      candidate: "JAGDISH KUMAR VERMA",
      party: "Independent",
      evm_votes: 1295,
      postal_votes: 1,
      total_votes: 1296,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 9,
      candidate: "LAKHAN SINGH",
      party: "Navarang Congress Party",
      evm_votes: 967,
      postal_votes: 3,
      total_votes: 970,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 10,
      candidate: "POONAM SRIVASTAVA",
      party: "Independent",
      evm_votes: 951,
      postal_votes: 7,
      total_votes: 958,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "ARUN KUMAR",
      party: "Independent",
      evm_votes: 842,
      postal_votes: 0,
      total_votes: 842,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "RINKI SANYAL",
      party: "Bharatiya Sampuran Krantikari Party",
      evm_votes: 762,
      postal_votes: 1,
      total_votes: 763,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "KAMLESH KUMAR JHA",
      party: "Independent",
      evm_votes: 714,
      postal_votes: 0,
      total_votes: 714,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 14,
      candidate: "SURJEET SINGH",
      party: "Delhi Janta Party",
      evm_votes: 637,
      postal_votes: 1,
      total_votes: 638,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "BHIM SINGH",
      party: "Ambedkarite Party of India",
      evm_votes: 601,
      postal_votes: 1,
      total_votes: 602,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "SURINDER KUMAR",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 592,
      postal_votes: 0,
      total_votes: 592,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 17,
      candidate: "GAUTAM ANAND",
      party: "All India Forward Bloc",
      evm_votes: 539,
      postal_votes: 1,
      total_votes: 540,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 18,
      candidate: "SHANKAR DEY",
      party: "Independent",
      evm_votes: 522,
      postal_votes: 0,
      total_votes: 522,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 19,
      candidate: "BIHARI LAL JALANDHARI",
      party: "India Greens Party",
      evm_votes: 511,
      postal_votes: 3,
      total_votes: 514,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 20,
      candidate: "NAVEEN KUMAR",
      party: "Hamara Sahi Vikalp Party",
      evm_votes: 475,
      postal_votes: 1,
      total_votes: 476,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 21,
      candidate: "VIRENDER",
      party: "Independent",
      evm_votes: 381,
      postal_votes: 0,
      total_votes: 381,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "RAJAN SINGH",
      party: "Independent",
      evm_votes: 323,
      postal_votes: 2,
      total_votes: 325,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 23,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5927,
      postal_votes: 34,
      total_votes: 5961,
      percentage_of_votes: 0.46,
    },
  ],
  total: {
    evm_votes: 1293476,
    postal_votes: 2522,
    total_votes: 1295998,
  },
};
export const u084 = {
  cs_name: "UDHAMPUR",
  cs_shortname: "udhampur",
  cs_code: "u084",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR JITENDRA SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 566833,
      postal_votes: 4243,
      total_votes: 571076,
      percentage_of_votes: 51.28,
    },
    {
      serial_no: 2,
      candidate: "CH LAL SINGH",
      party: "Indian National Congress",
      evm_votes: 444151,
      postal_votes: 2552,
      total_votes: 446703,
      percentage_of_votes: 40.11,
    },
    {
      serial_no: 3,
      candidate: "GHULAM MOHD SAROORI",
      party: "Independent",
      evm_votes: 39519,
      postal_votes: 80,
      total_votes: 39599,
      percentage_of_votes: 3.56,
    },
    {
      serial_no: 4,
      candidate: "MEHRAJ DIN",
      party: "Independent",
      evm_votes: 9055,
      postal_votes: 27,
      total_votes: 9082,
      percentage_of_votes: 0.82,
    },
    {
      serial_no: 5,
      candidate: "AMIT KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 8559,
      postal_votes: 83,
      total_votes: 8642,
      percentage_of_votes: 0.78,
    },
    {
      serial_no: 6,
      candidate: "MOHD ALI GUJJAR",
      party: "Independent",
      evm_votes: 7139,
      postal_votes: 19,
      total_votes: 7158,
      percentage_of_votes: 0.64,
    },
    {
      serial_no: 7,
      candidate: "MANOJ KUMAR",
      party: "Ekam Sanatan Bharat Dal",
      evm_votes: 6778,
      postal_votes: 56,
      total_votes: 6834,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 8,
      candidate: "BALWAN SINGH",
      party: "Jammu & Kashmir National Panthers Party (Bhim)",
      evm_votes: 3243,
      postal_votes: 49,
      total_votes: 3292,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 9,
      candidate: "SWARN VEER SINGH JARAL",
      party: "Independent",
      evm_votes: 3071,
      postal_votes: 17,
      total_votes: 3088,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 10,
      candidate: "DR PANKAJ SHARMA",
      party: "Independent",
      evm_votes: 2201,
      postal_votes: 46,
      total_votes: 2247,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 11,
      candidate: "RAJESH MANCHANDA",
      party: "Independent",
      evm_votes: 1575,
      postal_votes: 30,
      total_votes: 1605,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 12,
      candidate: "SACHIN GUPTA",
      party: "Independent",
      evm_votes: 1457,
      postal_votes: 6,
      total_votes: 1463,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12891,
      postal_votes: 47,
      total_votes: 12938,
      percentage_of_votes: 1.16,
    },
  ],
  total: {
    evm_votes: 1106472,
    postal_votes: 7255,
    total_votes: 1113727,
  },
};
export const u085 = {
  cs_name: "JAMMU",
  cs_shortname: "jammu",
  cs_code: "u085",
  winner: "BJP",
  stats: [
    {
      serial_no: 1,
      candidate: "JUGAL KISHORE",
      party: "Bharatiya Janata Party",
      evm_votes: 677571,
      postal_votes: 10017,
      total_votes: 687588,
      percentage_of_votes: 52.8,
    },
    {
      serial_no: 2,
      candidate: "RAMAN BHALLA",
      party: "Indian National Congress",
      evm_votes: 546031,
      postal_votes: 6059,
      total_votes: 552090,
      percentage_of_votes: 42.4,
    },
    {
      serial_no: 3,
      candidate: "JAGDISH RAJ",
      party: "Bahujan Samaj Party",
      evm_votes: 10253,
      postal_votes: 47,
      total_votes: 10300,
      percentage_of_votes: 0.79,
    },
    {
      serial_no: 4,
      candidate: "SATISH POONCHI",
      party: "Independent",
      evm_votes: 5948,
      postal_votes: 11,
      total_votes: 5959,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 5,
      candidate: "ANKUR SHARMA",
      party: "Ekam Sanatan Bharat Dal",
      evm_votes: 4268,
      postal_votes: 10,
      total_votes: 4278,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 6,
      candidate: "NARESH KUMAR CHIB",
      party: "Jammu & Kashmir National Panthers Party (Bhim)",
      evm_votes: 4231,
      postal_votes: 12,
      total_votes: 4243,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 7,
      candidate: "PRINCIPAL C D SHARMA",
      party: "Independent",
      evm_votes: 3256,
      postal_votes: 1,
      total_votes: 3257,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 8,
      candidate: "KARANJIT",
      party: "Independent",
      evm_votes: 3199,
      postal_votes: 8,
      total_votes: 3207,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 9,
      candidate: "SHABER AHMED",
      party: "Independent",
      evm_votes: 3174,
      postal_votes: 3,
      total_votes: 3177,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 10,
      candidate: "SHIKHA BANDRAL",
      party: "National Awami United Party",
      evm_votes: 2932,
      postal_votes: 5,
      total_votes: 2937,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 11,
      candidate: "DR PRINCE RAINA",
      party: "Independent",
      evm_votes: 2863,
      postal_votes: 3,
      total_votes: 2866,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 12,
      candidate: "RAJ KUMAR",
      party: "Independent",
      evm_votes: 2611,
      postal_votes: 7,
      total_votes: 2618,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 13,
      candidate: "SURINDER SINGH",
      party: "Independent",
      evm_votes: 2216,
      postal_votes: 10,
      total_votes: 2226,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 14,
      candidate: "ATUL RAINA",
      party: "Independent",
      evm_votes: 2063,
      postal_votes: 5,
      total_votes: 2068,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 15,
      candidate: "RATTAN LAL",
      party: "Jammu & Kashmir People Conference",
      evm_votes: 1650,
      postal_votes: 1,
      total_votes: 1651,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 16,
      candidate: "SWAMI DIVYA NAND",
      party: "Jammu and Kashmir Nationalist People's Front",
      evm_votes: 1618,
      postal_votes: 2,
      total_votes: 1620,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 17,
      candidate: "PERSEEN SINGH",
      party: "Independent",
      evm_votes: 1587,
      postal_votes: 2,
      total_votes: 1589,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 18,
      candidate: "NARESH KUMAR TALLA",
      party: "Independent",
      evm_votes: 1458,
      postal_votes: 1,
      total_votes: 1459,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 19,
      candidate: "GANESH CHOUDHARY",
      party: "Hindustan Shakti Sena",
      evm_votes: 1187,
      postal_votes: 3,
      total_votes: 1190,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 20,
      candidate: "VICKY KUMAR DOGRA",
      party: "Independent",
      evm_votes: 1163,
      postal_votes: 2,
      total_votes: 1165,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 21,
      candidate: "BANSI LAL",
      party: "Independent",
      evm_votes: 1051,
      postal_votes: 8,
      total_votes: 1059,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 22,
      candidate: "QARI ZAHEER ABBAS BHATTI",
      party: "All India Forward Bloc",
      evm_votes: 983,
      postal_votes: 1,
      total_votes: 984,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 23,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4631,
      postal_votes: 14,
      total_votes: 4645,
      percentage_of_votes: 0.36,
    },
  ],
  total: {
    evm_votes: 1285944,
    postal_votes: 16232,
    total_votes: 1302176,
  },
};
export const s032 = {
  cs_name: "Dhubri",
  cs_shortname: "dhubri",
  cs_code: "s032",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "RAKIBUL HUSSAIN",
      party: "Indian National Congress",
      evm_votes: 1468549,
      postal_votes: 3336,
      total_votes: 1471885,
      percentage_of_votes: 59.99,
    },
    {
      serial_no: 2,
      candidate: "MOHAMMED BADRUDDIN AJMAL",
      party: "All India United Democratic Front",
      evm_votes: 458202,
      postal_votes: 1207,
      total_votes: 459409,
      percentage_of_votes: 18.72,
    },
    {
      serial_no: 3,
      candidate: "ZABED ISLAM",
      party: "Asom Gana Parishad",
      evm_votes: 435822,
      postal_votes: 2772,
      total_votes: 438594,
      percentage_of_votes: 17.88,
    },
    {
      serial_no: 4,
      candidate: "S. M. JIAUL HAQUE",
      party: "Bharatiya Gana Parishad",
      evm_votes: 11978,
      postal_votes: 128,
      total_votes: 12106,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 5,
      candidate: "FARUK KHAN",
      party: "Independent",
      evm_votes: 10986,
      postal_votes: 8,
      total_votes: 10994,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 6,
      candidate: "BISWAJIT RAY",
      party: "Independent",
      evm_votes: 7615,
      postal_votes: 88,
      total_votes: 7703,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 7,
      candidate: "ALAKESH ROY",
      party: "Independent",
      evm_votes: 6950,
      postal_votes: 40,
      total_votes: 6990,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 8,
      candidate: "HAFIZ BURHANUDDIN",
      party: "The National Road Map Party of India",
      evm_votes: 6934,
      postal_votes: 6,
      total_votes: 6940,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 9,
      candidate: "RADHARANI BISWAS",
      party: "Hindu Samaj Party",
      evm_votes: 6137,
      postal_votes: 150,
      total_votes: 6287,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 10,
      candidate: "TAHIDUR RAHMAN",
      party: "Voters Party International",
      evm_votes: 5384,
      postal_votes: 8,
      total_votes: 5392,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 11,
      candidate: "SHUKUR ALI",
      party: "Asom Jana Morcha",
      evm_votes: 4749,
      postal_votes: 43,
      total_votes: 4792,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 12,
      candidate: "SURAT ZAMAN MONDAL",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 3984,
      postal_votes: 31,
      total_votes: 4015,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 13,
      candidate: "REJAUL KARIM",
      party: "Republican Party of India (Athawale)",
      evm_votes: 3481,
      postal_votes: 5,
      total_votes: 3486,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 14912,
      postal_votes: 103,
      total_votes: 15015,
      percentage_of_votes: 0.61,
    },
  ],
  total: {
    evm_votes: 2445683,
    postal_votes: 7925,
    total_votes: 2453608,
  },
};
export const s039 = {
  cs_name: "Nagaon",
  cs_shortname: "nagaon",
  cs_code: "s039",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "PRADYUT BORDOLOI",
      party: "Indian National Congress",
      evm_votes: 786620,
      postal_votes: 2230,
      total_votes: 788850,
      percentage_of_votes: 50.89,
    },
    {
      serial_no: 2,
      candidate: "SURESH BORAH",
      party: "Bharatiya Janata Party",
      evm_votes: 573579,
      postal_votes: 3040,
      total_votes: 576619,
      percentage_of_votes: 37.2,
    },
    {
      serial_no: 3,
      candidate: "AMINUL ISLAM",
      party: "All India United Democratic Front",
      evm_votes: 137033,
      postal_votes: 307,
      total_votes: 137340,
      percentage_of_votes: 8.86,
    },
    {
      serial_no: 4,
      candidate: "SIKHA SARMA",
      party: "Independent",
      evm_votes: 6569,
      postal_votes: 51,
      total_votes: 6620,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 5,
      candidate: "SAMSUL ALAM",
      party: "Independent",
      evm_votes: 5048,
      postal_votes: 21,
      total_votes: 5069,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 6,
      candidate: "BIPAD BHANJAN SARKAR",
      party: "Independent",
      evm_votes: 4878,
      postal_votes: 7,
      total_votes: 4885,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 7,
      candidate: "ANUPAM BARUAH",
      party: "Jai Prakash Janata Dal",
      evm_votes: 4290,
      postal_votes: 29,
      total_votes: 4319,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 8,
      candidate: "NIKHIL DEKARAJA",
      party: "Independent",
      evm_votes: 3601,
      postal_votes: 26,
      total_votes: 3627,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 9,
      candidate: "RABBUL HAQUE",
      party: "Rashtriya Ulama Council",
      evm_votes: 2547,
      postal_votes: 12,
      total_votes: 2559,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 10,
      candidate: "ABU SHAMA",
      party: "Independent",
      evm_votes: 2216,
      postal_votes: 22,
      total_votes: 2238,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "ABU EUSUF MD RAIHAN UDDIN",
      party: "Independent",
      evm_votes: 2019,
      postal_votes: 11,
      total_votes: 2030,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 12,
      candidate: "SANTANU MUKHERJEE",
      party: "Bharatiya Gana Parishad",
      evm_votes: 1993,
      postal_votes: 17,
      total_votes: 2010,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 13,
      candidate: "SAIFUL ISLAM CHOUDHURY",
      party: "Asom Jana Morcha",
      evm_votes: 1823,
      postal_votes: 14,
      total_votes: 1837,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11910,
      postal_votes: 85,
      total_votes: 11995,
      percentage_of_votes: 0.77,
    },
  ],
  total: {
    evm_votes: 1544126,
    postal_votes: 5872,
    total_votes: 1549998,
  },
};
export const s0314 = {
  cs_name: "Jorhat",
  cs_shortname: "jorhat",
  cs_code: "s0314",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "GAURAV GOGOI",
      party: "Indian National Congress",
      evm_votes: 743194,
      postal_votes: 8577,
      total_votes: 751771,
      percentage_of_votes: 54.04,
    },
    {
      serial_no: 2,
      candidate: "TOPON KUMAR GOGOI",
      party: "Bharatiya Janata Party",
      evm_votes: 602019,
      postal_votes: 5359,
      total_votes: 607378,
      percentage_of_votes: 43.66,
    },
    {
      serial_no: 3,
      candidate: "ARUN CHANDRA HANDIQUE",
      party: "Ekam Sanatan Bharat Dal",
      evm_votes: 8998,
      postal_votes: 88,
      total_votes: 9086,
      percentage_of_votes: 0.65,
    },
    {
      serial_no: 4,
      candidate: "RAJ KUMAR DUWARA",
      party: "Independent",
      evm_votes: 8315,
      postal_votes: 55,
      total_votes: 8370,
      percentage_of_votes: 0.6,
    },
    {
      serial_no: 5,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 14477,
      postal_votes: 78,
      total_votes: 14555,
      percentage_of_votes: 1.05,
    },
  ],
  total: {
    evm_votes: 1377003,
    postal_votes: 14157,
    total_votes: 1391160,
  },
};
export const s0410 = {
  cs_name: "Kishanganj",
  cs_shortname: "kishanganj",
  cs_code: "s0410",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "MOHAMMAD JAWED",
      party: "Indian National Congress",
      evm_votes: 402470,
      postal_votes: 380,
      total_votes: 402850,
      percentage_of_votes: 35,
    },
    {
      serial_no: 2,
      candidate: "MUJAHID ALAM",
      party: "Janata Dal (United)",
      evm_votes: 342795,
      postal_votes: 363,
      total_votes: 343158,
      percentage_of_votes: 29.81,
    },
    {
      serial_no: 3,
      candidate: "AKHTARUL IMAN",
      party: "All India Majlis-E-Ittehadul Muslimeen",
      evm_votes: 309011,
      postal_votes: 253,
      total_votes: 309264,
      percentage_of_votes: 26.87,
    },
    {
      serial_no: 4,
      candidate: "BIDESHI RISHIDEV",
      party: "Independent",
      evm_votes: 11815,
      postal_votes: 3,
      total_votes: 11818,
      percentage_of_votes: 1.03,
    },
    {
      serial_no: 5,
      candidate: "VISHWANATH TUDU",
      party: "Independent",
      evm_votes: 11300,
      postal_votes: 2,
      total_votes: 11302,
      percentage_of_votes: 0.98,
    },
    {
      serial_no: 6,
      candidate: "MD GUFRAN JAMALI",
      party: "Independent",
      evm_votes: 9992,
      postal_votes: 1,
      total_votes: 9993,
      percentage_of_votes: 0.87,
    },
    {
      serial_no: 7,
      candidate: "HASIRUL",
      party: "Independent",
      evm_votes: 9522,
      postal_votes: 2,
      total_votes: 9524,
      percentage_of_votes: 0.83,
    },
    {
      serial_no: 8,
      candidate: "BABUL ALAM",
      party: "Bahujan Samaj Party",
      evm_votes: 7575,
      postal_votes: 4,
      total_votes: 7579,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 9,
      candidate: "RAVI KUMAR ROY",
      party: "Independent",
      evm_votes: 7299,
      postal_votes: 3,
      total_votes: 7302,
      percentage_of_votes: 0.63,
    },
    {
      serial_no: 10,
      candidate: "SHAHABUZZAMA BHARTIYA",
      party: "Rashtriya Samaj Paksha",
      evm_votes: 6055,
      postal_votes: 0,
      total_votes: 6055,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 11,
      candidate: "CHHOTE LAL MAHTO",
      party: "Independent",
      evm_votes: 3758,
      postal_votes: 5,
      total_votes: 3763,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 12,
      candidate: "MOHAMMAD KAUSAR PERWEZ",
      party: "Independent",
      evm_votes: 3525,
      postal_votes: 0,
      total_votes: 3525,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 24953,
      postal_votes: 13,
      total_votes: 24966,
      percentage_of_votes: 2.17,
    },
  ],
  total: {
    evm_votes: 1150070,
    postal_votes: 1029,
    total_votes: 1151099,
  },
};
export const s0411 = {
  cs_name: "Katihar",
  cs_shortname: "katihar",
  cs_code: "s0411",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "TARIQ ANWAR",
      party: "Indian National Congress",
      evm_votes: 565025,
      postal_votes: 2067,
      total_votes: 567092,
      percentage_of_votes: 48.41,
    },
    {
      serial_no: 2,
      candidate: "DULAL CHANDRA GOSWAMI",
      party: "Janata Dal (United)",
      evm_votes: 516199,
      postal_votes: 1030,
      total_votes: 517229,
      percentage_of_votes: 44.15,
    },
    {
      serial_no: 3,
      candidate: "GOPAL KUMAR MAHTO",
      party: "Bahujan Samaj Party",
      evm_votes: 14452,
      postal_votes: 46,
      total_votes: 14498,
      percentage_of_votes: 1.24,
    },
    {
      serial_no: 4,
      candidate: "GYANESHWAR SOREN",
      party: "Independent",
      evm_votes: 12307,
      postal_votes: 10,
      total_votes: 12317,
      percentage_of_votes: 1.05,
    },
    {
      serial_no: 5,
      candidate: "KHALID MOBARAK",
      party: "Independent",
      evm_votes: 10828,
      postal_votes: 43,
      total_votes: 10871,
      percentage_of_votes: 0.93,
    },
    {
      serial_no: 6,
      candidate: "RAJ KUMAR MANDAL",
      party: "Rashtriya Jansambhavna Party",
      evm_votes: 8473,
      postal_votes: 14,
      total_votes: 8487,
      percentage_of_votes: 0.72,
    },
    {
      serial_no: 7,
      candidate: "VISHNU SINGH",
      party: "Bharat Jago Janta Party",
      evm_votes: 6303,
      postal_votes: 37,
      total_votes: 6340,
      percentage_of_votes: 0.54,
    },
    {
      serial_no: 8,
      candidate: "MARANG HANSADA",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 5875,
      postal_votes: 14,
      total_votes: 5889,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 9,
      candidate: "BINDU KUMARI",
      party: "Samaj Shakti Party",
      evm_votes: 5866,
      postal_votes: 18,
      total_votes: 5884,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 22866,
      postal_votes: 73,
      total_votes: 22939,
      percentage_of_votes: 1.96,
    },
  ],
  total: {
    evm_votes: 1168194,
    postal_votes: 3352,
    total_votes: 1171546,
  },
};
export const s0434 = {
  cs_name: "Sasaram",
  cs_shortname: "sasaram",
  cs_code: "s0434",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "MANOJ KUMAR",
      party: "Indian National Congress",
      evm_votes: 509977,
      postal_votes: 3027,
      total_votes: 513004,
      percentage_of_votes: 46.76,
    },
    {
      serial_no: 2,
      candidate: "SHIVESH KUMAR",
      party: "Bharatiya Janata Party",
      evm_votes: 492177,
      postal_votes: 1670,
      total_votes: 493847,
      percentage_of_votes: 45.01,
    },
    {
      serial_no: 3,
      candidate: "SANTOSH KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 45491,
      postal_votes: 107,
      total_votes: 45598,
      percentage_of_votes: 4.16,
    },
    {
      serial_no: 4,
      candidate: "SHIV SHANKAR RAM",
      party: "Independent",
      evm_votes: 8009,
      postal_votes: 10,
      total_votes: 8019,
      percentage_of_votes: 0.73,
    },
    {
      serial_no: 5,
      candidate: "SANTOSH KUMAR KHARWAR",
      party: "Bhartiya Gandhiwadi Party",
      evm_votes: 4817,
      postal_votes: 5,
      total_votes: 4822,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 6,
      candidate: "BANARSI DAS",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 4498,
      postal_votes: 0,
      total_votes: 4498,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 7,
      candidate: "AMIT KUMAR AMBEDKAR",
      party: "Bahujan Mukti Party",
      evm_votes: 2823,
      postal_votes: 11,
      total_votes: 2834,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 8,
      candidate: "PUNAM DEVI",
      party: "Jantantra Awaj Party",
      evm_votes: 2634,
      postal_votes: 20,
      total_votes: 2654,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 9,
      candidate: "UJARAN MUSAHAR",
      party: "Jan Janwadi Party",
      evm_votes: 2616,
      postal_votes: 4,
      total_votes: 2620,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 10,
      candidate: "NANDLAL RAM",
      party: "Rashtriya Samaj Paksha",
      evm_votes: 1956,
      postal_votes: 4,
      total_votes: 1960,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 17160,
      postal_votes: 59,
      total_votes: 17219,
      percentage_of_votes: 1.57,
    },
  ],
  total: {
    evm_votes: 1092158,
    postal_votes: 4917,
    total_votes: 1097075,
  },
};
export const s052 = {
  cs_name: "South_Goa",
  cs_shortname: "southgoa",
  cs_code: "s052",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "CAPTAIN VIRIATO FERNANDES",
      party: "Indian National Congress",
      evm_votes: 216022,
      postal_votes: 1814,
      total_votes: 217836,
      percentage_of_votes: 48.35,
    },
    {
      serial_no: 2,
      candidate: "PALLAVI SHRINIVAS DEMPO",
      party: "Bharatiya Janata Party",
      evm_votes: 201806,
      postal_votes: 2495,
      total_votes: 204301,
      percentage_of_votes: 45.35,
    },
    {
      serial_no: 3,
      candidate: "RUBERT PEREIRA",
      party: "Revolutionary Goans Party",
      evm_votes: 18718,
      postal_votes: 167,
      total_votes: 18885,
      percentage_of_votes: 4.19,
    },
    {
      serial_no: 4,
      candidate: "DR SWETA GAONKAR",
      party: "Bahujan Samaj Party",
      evm_votes: 1545,
      postal_votes: 36,
      total_votes: 1581,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 5,
      candidate: "DEEPKUMAR D MAPARI",
      party: "Independent",
      evm_votes: 1309,
      postal_votes: 8,
      total_votes: 1317,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 6,
      candidate: "DR KALIDAS VAINGANKAR",
      party: "Independent",
      evm_votes: 707,
      postal_votes: 13,
      total_votes: 720,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 7,
      candidate: "ALEXY FERNANDES",
      party: "Independent",
      evm_votes: 526,
      postal_votes: 16,
      total_votes: 542,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 8,
      candidate: "HARISHCHANDRA SUDHAKAR NAIK",
      party: "Corruption Abolition Party",
      evm_votes: 487,
      postal_votes: 14,
      total_votes: 501,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4804,
      postal_votes: 33,
      total_votes: 4837,
      percentage_of_votes: 1.07,
    },
  ],
  total: {
    evm_votes: 445924,
    postal_votes: 4596,
    total_votes: 450520,
  },
};
export const s062 = {
  cs_name: "Banaskantha",
  cs_shortname: "banaskantha",
  cs_code: "s062",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "GENIBEN NAGAJI THAKOR",
      party: "Indian National Congress",
      evm_votes: 667072,
      postal_votes: 4811,
      total_votes: 671883,
      percentage_of_votes: 48.83,
    },
    {
      serial_no: 2,
      candidate: "DR. REKHABEN HITESHBHAI CHAUDHARI",
      party: "Bharatiya Janata Party",
      evm_votes: 635760,
      postal_votes: 5717,
      total_votes: 641477,
      percentage_of_votes: 46.62,
    },
    {
      serial_no: 3,
      candidate: "MANSUNGBHAI MASHRUBHAI PARMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 9900,
      postal_votes: 29,
      total_votes: 9929,
      percentage_of_votes: 0.72,
    },
    {
      serial_no: 4,
      candidate: "RATHOD MAVJI BHEMAJI",
      party: "Independent",
      evm_votes: 7690,
      postal_votes: 6,
      total_votes: 7696,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 5,
      candidate: "SHRIMALI ASHOKBHAI BALCHANDBHAI",
      party: "Independent",
      evm_votes: 5473,
      postal_votes: 8,
      total_votes: 5481,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 6,
      candidate: "JASUBHAI GAMAR",
      party: "Bharat Adivasi Party",
      evm_votes: 4615,
      postal_votes: 11,
      total_votes: 4626,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 7,
      candidate: "PATEL PIYUSHBHAI KARSHANBHAI",
      party: "Bharatiya Yuva Jan Ekta Party",
      evm_votes: 3503,
      postal_votes: 10,
      total_votes: 3513,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 8,
      candidate: "BAJAG LASHABHAI LAVAJIBHAI",
      party: "Independent",
      evm_votes: 2994,
      postal_votes: 15,
      total_votes: 3009,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 9,
      candidate: "PRAVINBHAI ISHVARBHAI CHAUHAN",
      party: "Swatantrata Abhivyakti Party",
      evm_votes: 1822,
      postal_votes: 1,
      total_votes: 1823,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 10,
      candidate: "IBRAHIMBHAI PARASANI",
      party: "Independent",
      evm_votes: 1588,
      postal_votes: 3,
      total_votes: 1591,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 11,
      candidate: "PARMAR CHHAGANCHANDRARAJ",
      party: "Independent",
      evm_votes: 1369,
      postal_votes: 5,
      total_votes: 1374,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 12,
      candidate: "CHETANKUMAR KESHAVLAL OZA",
      party: "Independent",
      evm_votes: 1321,
      postal_votes: 13,
      total_votes: 1334,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 22034,
      postal_votes: 133,
      total_votes: 22167,
      percentage_of_votes: 1.61,
    },
  ],
  total: {
    evm_votes: 1365141,
    postal_votes: 10762,
    total_votes: 1375903,
  },
};
export const s071 = {
  cs_name: "AMBALA",
  cs_shortname: "ambala",
  cs_code: "s071",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "VARUN CHAUDHRY",
      party: "Indian National Congress",
      evm_votes: 662592,
      postal_votes: 1065,
      total_votes: 663657,
      percentage_of_votes: 49.28,
    },
    {
      serial_no: 2,
      candidate: "BANTO KATARIA",
      party: "Bharatiya Janata Party",
      evm_votes: 613786,
      postal_votes: 835,
      total_votes: 614621,
      percentage_of_votes: 45.64,
    },
    {
      serial_no: 3,
      candidate: "PAWAN RANDHAWA",
      party: "Bahujan Samaj Party",
      evm_votes: 23535,
      postal_votes: 48,
      total_votes: 23583,
      percentage_of_votes: 1.75,
    },
    {
      serial_no: 4,
      candidate: "GURPREET SINGH",
      party: "Indian National Lok Dal",
      evm_votes: 8902,
      postal_votes: 84,
      total_votes: 8986,
      percentage_of_votes: 0.67,
    },
    {
      serial_no: 5,
      candidate: "KAMAL KUMAR BARARA",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 8741,
      postal_votes: 13,
      total_votes: 8754,
      percentage_of_votes: 0.65,
    },
    {
      serial_no: 6,
      candidate: "KIRAN PUNIA",
      party: "Jannayak Janta Party",
      evm_votes: 6055,
      postal_votes: 37,
      total_votes: 6092,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 7,
      candidate: "RAKESH KUMAR",
      party: "Haryana Jansena Party",
      evm_votes: 3110,
      postal_votes: 1,
      total_votes: 3111,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 8,
      candidate: "BALJEET SINGH",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 2459,
      postal_votes: 43,
      total_votes: 2502,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 9,
      candidate: "MEHAR SINGH CHALIA",
      party: "Ekam Sanatan Bharat Dal",
      evm_votes: 2383,
      postal_votes: 14,
      total_votes: 2397,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 10,
      candidate: "GULAB SINGH NARWAL",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 1683,
      postal_votes: 7,
      total_votes: 1690,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "SURAJ KUMAR",
      party: "Bahujan Mukti Party",
      evm_votes: 1501,
      postal_votes: 5,
      total_votes: 1506,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 12,
      candidate: "MOHAR SINGH STATION MASTER (RETD.)",
      party: "Independent",
      evm_votes: 1257,
      postal_votes: 6,
      total_votes: 1263,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "BETA MAM CHAND RATTUWALA",
      party: "Bharatiya Yuva Jan Ekta Party",
      evm_votes: 1248,
      postal_votes: 3,
      total_votes: 1251,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "DR. NITESH CHOPRA (HONEY)",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 854,
      postal_votes: 3,
      total_votes: 857,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6427,
      postal_votes: 25,
      total_votes: 6452,
      percentage_of_votes: 0.48,
    },
  ],
  total: {
    evm_votes: 1344533,
    postal_votes: 2189,
    total_votes: 1346722,
  },
};
export const s073 = {
  cs_name: "SIRSA",
  cs_shortname: "sirsa",
  cs_code: "s073",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "SELJA",
      party: "Indian National Congress",
      evm_votes: 732298,
      postal_votes: 1525,
      total_votes: 733823,
      percentage_of_votes: 54.17,
    },
    {
      serial_no: 2,
      candidate: "ASHOK TANWAR",
      party: "Bharatiya Janata Party",
      evm_votes: 464472,
      postal_votes: 854,
      total_votes: 465326,
      percentage_of_votes: 34.35,
    },
    {
      serial_no: 3,
      candidate: "SANDEEP LOT VALMIKI",
      party: "Indian National Lok Dal",
      evm_votes: 92279,
      postal_votes: 174,
      total_votes: 92453,
      percentage_of_votes: 6.82,
    },
    {
      serial_no: 4,
      candidate: "RAMESH KHATAK",
      party: "Jannayak Janta Party",
      evm_votes: 20049,
      postal_votes: 31,
      total_votes: 20080,
      percentage_of_votes: 1.48,
    },
    {
      serial_no: 5,
      candidate: "LILU RAM ASAKHERA",
      party: "Bahujan Samaj Party",
      evm_votes: 10120,
      postal_votes: 31,
      total_votes: 10151,
      percentage_of_votes: 0.75,
    },
    {
      serial_no: 6,
      candidate: "RAHUL CHOUHAN",
      party: "Independent",
      evm_votes: 6159,
      postal_votes: 1,
      total_votes: 6160,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 7,
      candidate: "KARNAIL SINGH ODHAN",
      party: "Independent",
      evm_votes: 4152,
      postal_votes: 14,
      total_votes: 4166,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 8,
      candidate: "SATPAL LADWAL",
      party: "Independent",
      evm_votes: 3413,
      postal_votes: 0,
      total_votes: 3413,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 9,
      candidate: "MISTRI DAULAT RAM ROLAN",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 3058,
      postal_votes: 3,
      total_votes: 3061,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 10,
      candidate: "JOGINDER RAM",
      party: "Independent",
      evm_votes: 2140,
      postal_votes: 2,
      total_votes: 2142,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 11,
      candidate: "RAN SINGH PANWAR",
      party: "Independent",
      evm_votes: 1548,
      postal_votes: 2,
      total_votes: 1550,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 12,
      candidate: "RAJINDER KUMAR",
      party: "Bharatiya Asha Party",
      evm_votes: 1239,
      postal_votes: 3,
      total_votes: 1242,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "BAGDAWAT RAM",
      party: "Independent",
      evm_votes: 1221,
      postal_votes: 3,
      total_votes: 1224,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "JASVIR SINGH",
      party: "Independent",
      evm_votes: 1128,
      postal_votes: 8,
      total_votes: 1136,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "NAVEEN KUMAR COMMANDO",
      party: "Independent",
      evm_votes: 1113,
      postal_votes: 3,
      total_votes: 1116,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 16,
      candidate: "SUKHDEV SINGH SANDHU",
      party: "Independent",
      evm_votes: 993,
      postal_votes: 6,
      total_votes: 999,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 17,
      candidate: "DR. RAJESH MEHANDIA",
      party: "Bahujan Republican Socialist Party",
      evm_votes: 931,
      postal_votes: 0,
      total_votes: 931,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "DHARAMPAL VARTIA",
      party: "Loktantrik Lok Rajyam Party",
      evm_votes: 916,
      postal_votes: 3,
      total_votes: 919,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 19,
      candidate: "SURENDER KUMAR PHULAN",
      party: "Independent",
      evm_votes: 614,
      postal_votes: 3,
      total_votes: 617,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 20,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4106,
      postal_votes: 17,
      total_votes: 4123,
      percentage_of_votes: 0.3,
    },
  ],
  total: {
    evm_votes: 1351949,
    postal_votes: 2683,
    total_votes: 1354632,
  },
};
export const s074 = {
  cs_name: "HISAR",
  cs_shortname: "hisar",
  cs_code: "s074",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "JAI PARKASH (J P) S/O HARIKESH",
      party: "Indian National Congress",
      evm_votes: 567194,
      postal_votes: 3230,
      total_votes: 570424,
      percentage_of_votes: 48.58,
    },
    {
      serial_no: 2,
      candidate: "RANJIT SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 504944,
      postal_votes: 2099,
      total_votes: 507043,
      percentage_of_votes: 43.19,
    },
    {
      serial_no: 3,
      candidate: "DESH RAJ",
      party: "Bahujan Samaj Party",
      evm_votes: 25892,
      postal_votes: 123,
      total_votes: 26015,
      percentage_of_votes: 2.22,
    },
    {
      serial_no: 4,
      candidate: "SUNAINA CHAUTALA",
      party: "Indian National Lok Dal",
      evm_votes: 22171,
      postal_votes: 132,
      total_votes: 22303,
      percentage_of_votes: 1.9,
    },
    {
      serial_no: 5,
      candidate: "NAINA SINGH CHAUTALA",
      party: "Jannayak Janta Party",
      evm_votes: 21910,
      postal_votes: 122,
      total_votes: 22032,
      percentage_of_votes: 1.88,
    },
    {
      serial_no: 6,
      candidate: "PARDEEP DAHIYA",
      party: "Independent",
      evm_votes: 3607,
      postal_votes: 1,
      total_votes: 3608,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 7,
      candidate: "JAI PRAKASH (J.P.) S/O UMA DUTT",
      party: "Independent",
      evm_votes: 3267,
      postal_votes: 6,
      total_votes: 3273,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 8,
      candidate: "JILE SINGH",
      party: "Independent",
      evm_votes: 3196,
      postal_votes: 0,
      total_votes: 3196,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 9,
      candidate: "POONAM MOR",
      party: "Independent",
      evm_votes: 1237,
      postal_votes: 4,
      total_votes: 1241,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "ISHWAR JHAJHRIA",
      party: "Independent",
      evm_votes: 1121,
      postal_votes: 1,
      total_votes: 1122,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "ATMA RAM BISHNOI",
      party: "Independent",
      evm_votes: 1091,
      postal_votes: 6,
      total_votes: 1097,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 12,
      candidate: "KULBIR",
      party: "Independent",
      evm_votes: 1041,
      postal_votes: 0,
      total_votes: 1041,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "CHANDER MOHAN",
      party: "Independent",
      evm_votes: 995,
      postal_votes: 3,
      total_votes: 998,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "ADVOCATE PARDEEP SIHMAR",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 871,
      postal_votes: 5,
      total_votes: 876,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 15,
      candidate: "AJIT SINGH",
      party: "Independent",
      evm_votes: 794,
      postal_votes: 2,
      total_votes: 796,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 16,
      candidate: "DEV GIRI",
      party: "Rashtriya Lokswaraj Party",
      evm_votes: 719,
      postal_votes: 7,
      total_votes: 726,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 17,
      candidate: "DINESH KUMAR",
      party: "Independent",
      evm_votes: 699,
      postal_votes: 4,
      total_votes: 703,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 18,
      candidate: "MASTER VIJENDER JITPURA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 654,
      postal_votes: 3,
      total_votes: 657,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 19,
      candidate: "RAJENDER KUMAR",
      party: "Independent",
      evm_votes: 485,
      postal_votes: 1,
      total_votes: 486,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 20,
      candidate: "NEERAJ KUMAR “CHHATTAR”",
      party: "Bharatiya Asha Party",
      evm_votes: 419,
      postal_votes: 4,
      total_votes: 423,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 21,
      candidate: "MANDEEP",
      party: "Independent",
      evm_votes: 404,
      postal_votes: 1,
      total_votes: 405,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "JAGAT SINGH",
      party: "Vikas India Party",
      evm_votes: 374,
      postal_votes: 8,
      total_votes: 382,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 23,
      candidate: "PARJAPATI RAKESH DHUWARIA",
      party: "Independent",
      evm_votes: 372,
      postal_votes: 2,
      total_votes: 374,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 24,
      candidate: "RANDHIR SINGH",
      party: "Independent",
      evm_votes: 352,
      postal_votes: 1,
      total_votes: 353,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 25,
      candidate: "SURENDER KUMAR",
      party: "Independent",
      evm_votes: 326,
      postal_votes: 0,
      total_votes: 326,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 26,
      candidate: "SURENDER",
      party: "Independent",
      evm_votes: 308,
      postal_votes: 0,
      total_votes: 308,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 27,
      candidate: "VIJAY SINGH",
      party: "Independent",
      evm_votes: 270,
      postal_votes: 2,
      total_votes: 272,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 28,
      candidate: "YOGESH BOORA",
      party: "Independent",
      evm_votes: 267,
      postal_votes: 2,
      total_votes: 269,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 29,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3333,
      postal_votes: 33,
      total_votes: 3366,
      percentage_of_votes: 0.29,
    },
  ],
  total: {
    evm_votes: 1168313,
    postal_votes: 5802,
    total_votes: 1174115,
  },
};
export const s076 = {
  cs_name: "SONIPAT",
  cs_shortname: "sonipat",
  cs_code: "s076",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "SATPAL BRAHAMCHARI",
      party: "Indian National Congress",
      evm_votes: 546163,
      postal_votes: 2519,
      total_votes: 548682,
      percentage_of_votes: 48.82,
    },
    {
      serial_no: 2,
      candidate: "MOHAN LAL BADOLI",
      party: "Bharatiya Janata Party",
      evm_votes: 525069,
      postal_votes: 1797,
      total_votes: 526866,
      percentage_of_votes: 46.88,
    },
    {
      serial_no: 3,
      candidate: "UMESH KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 12742,
      postal_votes: 80,
      total_votes: 12822,
      percentage_of_votes: 1.14,
    },
    {
      serial_no: 4,
      candidate: "ANOOP SINGH",
      party: "Indian National Lok Dal",
      evm_votes: 11384,
      postal_votes: 139,
      total_votes: 11523,
      percentage_of_votes: 1.03,
    },
    {
      serial_no: 5,
      candidate: "BHUPENDER SINGH MALIK",
      party: "Jannayak Janta Party",
      evm_votes: 7716,
      postal_votes: 104,
      total_votes: 7820,
      percentage_of_votes: 0.7,
    },
    {
      serial_no: 6,
      candidate: "SANT DHARAMVEER CHOTIVALA",
      party: "Independent",
      evm_votes: 2150,
      postal_votes: 4,
      total_votes: 2154,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 7,
      candidate: "RAMESH",
      party: "Independent",
      evm_votes: 1651,
      postal_votes: 0,
      total_votes: 1651,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 8,
      candidate: "NARESH KASHYAP",
      party: "Aam Aadmi Parivartan Party",
      evm_votes: 1622,
      postal_votes: 36,
      total_votes: 1658,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 9,
      candidate: "SATPAL",
      party: "Independent",
      evm_votes: 1215,
      postal_votes: 2,
      total_votes: 1217,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "GAUBHAGAT SUMIT LATHER",
      party: "Independent",
      evm_votes: 877,
      postal_votes: 5,
      total_votes: 882,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 11,
      candidate: "DR. KAMLESH KUMAR SAINI",
      party: "Independent",
      evm_votes: 863,
      postal_votes: 3,
      total_votes: 866,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "ROHTASH",
      party: "Independent",
      evm_votes: 775,
      postal_votes: 2,
      total_votes: 777,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "BALBIR SINGH",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 765,
      postal_votes: 3,
      total_votes: 768,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 14,
      candidate: "ASHWANI",
      party: "Independent",
      evm_votes: 694,
      postal_votes: 1,
      total_votes: 695,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "SANJAY DASS",
      party: "Independent",
      evm_votes: 569,
      postal_votes: 3,
      total_votes: 572,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "JAGBEER",
      party: "Independent",
      evm_votes: 521,
      postal_votes: 3,
      total_votes: 524,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 17,
      candidate: "SUNIL KUMAR",
      party: "Rashtriya Garib Dal",
      evm_votes: 456,
      postal_votes: 11,
      total_votes: 467,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 18,
      candidate: "RADHEY SHYAM",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 438,
      postal_votes: 3,
      total_votes: 441,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 19,
      candidate: "RAKESH",
      party: "Samata Party",
      evm_votes: 407,
      postal_votes: 3,
      total_votes: 410,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 20,
      candidate: "NIRMAL SINGH",
      party: "Independent",
      evm_votes: 317,
      postal_votes: 1,
      total_votes: 318,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 21,
      candidate: "SURENDER SINGH",
      party: "Independent",
      evm_votes: 295,
      postal_votes: 1,
      total_votes: 296,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "RAKESH DHARIWAL",
      party: "Rashtriya Jatigat Aarakshan Virodhi Party",
      evm_votes: 214,
      postal_votes: 1,
      total_votes: 215,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 23,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 2287,
      postal_votes: 33,
      total_votes: 2320,
      percentage_of_votes: 0.21,
    },
  ],
  total: {
    evm_votes: 1119190,
    postal_votes: 4754,
    total_votes: 1123944,
  },
};
export const s077 = {
  cs_name: "ROHTAK",
  cs_shortname: "rohtak",
  cs_code: "s077",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "DEEPENDER SINGH HOODA",
      party: "Indian National Congress",
      evm_votes: 778259,
      postal_votes: 5319,
      total_votes: 783578,
      percentage_of_votes: 62.76,
    },
    {
      serial_no: 2,
      candidate: "DR. ARVIND KUMAR SHARMA",
      party: "Bharatiya Janata Party",
      evm_votes: 435028,
      postal_votes: 3252,
      total_votes: 438280,
      percentage_of_votes: 35.11,
    },
    {
      serial_no: 3,
      candidate: "RAVINDER",
      party: "Jannayak Janta Party",
      evm_votes: 6209,
      postal_votes: 41,
      total_votes: 6250,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 4,
      candidate: "RAKESH SINGH",
      party: "Rashtrawadi Janlok Party (Satya)",
      evm_votes: 2706,
      postal_votes: 8,
      total_votes: 2714,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 5,
      candidate: "BISHAMBAR KUMAR",
      party: "Independent",
      evm_votes: 2394,
      postal_votes: 0,
      total_votes: 2394,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 6,
      candidate: "KALAWATI",
      party: "Independent",
      evm_votes: 2135,
      postal_votes: 15,
      total_votes: 2150,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 7,
      candidate: "PARVEEN KUMAR",
      party: "Independent",
      evm_votes: 1927,
      postal_votes: 1,
      total_votes: 1928,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 8,
      candidate: "UDEVIR",
      party: "Independent",
      evm_votes: 1530,
      postal_votes: 2,
      total_votes: 1532,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 9,
      candidate: "VINOD KUMAR",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 805,
      postal_votes: 4,
      total_votes: 809,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 10,
      candidate: "ASHOK KUMAR",
      party: "Independent",
      evm_votes: 627,
      postal_votes: 2,
      total_votes: 629,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 11,
      candidate: "VISHESH BAMEL",
      party: "Bharatiya Asha Party",
      evm_votes: 613,
      postal_votes: 5,
      total_votes: 618,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 12,
      candidate: "JAIKARAN MANDAUTHI",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 600,
      postal_votes: 7,
      total_votes: 607,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "JAGBIR SINGH",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 574,
      postal_votes: 19,
      total_votes: 593,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "SATISH KUMAR",
      party: "Independent",
      evm_votes: 524,
      postal_votes: 0,
      total_votes: 524,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 15,
      candidate: "SURENDER",
      party: "Aam Aadmi Parivartan Party",
      evm_votes: 447,
      postal_votes: 23,
      total_votes: 470,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "MANISHA",
      party: "Independent",
      evm_votes: 410,
      postal_votes: 21,
      total_votes: 431,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 17,
      candidate: "ARVIND KUMAR SHARMA",
      party: "Independent",
      evm_votes: 360,
      postal_votes: 7,
      total_votes: 367,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 18,
      candidate: "SATISH KUMAR",
      party: "Ekam Sanatan Bharat Dal",
      evm_votes: 359,
      postal_votes: 4,
      total_votes: 363,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 19,
      candidate: "ARVIND",
      party: "Independent",
      evm_votes: 330,
      postal_votes: 0,
      total_votes: 330,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 20,
      candidate: "ADVOCATE ADITYA DHANKHAR",
      party: "Super Power India Party",
      evm_votes: 275,
      postal_votes: 12,
      total_votes: 287,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 21,
      candidate: "MASTER RANDHIR SINGH",
      party: "Independent",
      evm_votes: 267,
      postal_votes: 2,
      total_votes: 269,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 22,
      candidate: "MANJEET",
      party: "Independent",
      evm_votes: 267,
      postal_votes: 2,
      total_votes: 269,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 23,
      candidate: "SANJAY",
      party: "Independent",
      evm_votes: 210,
      postal_votes: 2,
      total_votes: 212,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 24,
      candidate: "YOGESH SHARMA",
      party: "Independent",
      evm_votes: 190,
      postal_votes: 0,
      total_votes: 190,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 25,
      candidate: "VINAY",
      party: "Independent",
      evm_votes: 156,
      postal_votes: 3,
      total_votes: 159,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 26,
      candidate: "LALIT KUMAR",
      party: "Independent",
      evm_votes: 129,
      postal_votes: 2,
      total_votes: 131,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 27,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 2321,
      postal_votes: 41,
      total_votes: 2362,
      percentage_of_votes: 0.19,
    },
  ],
  total: {
    evm_votes: 1239652,
    postal_votes: 8794,
    total_votes: 1248446,
  },
};
export const s101 = {
  cs_name: "Chikkodi",
  cs_shortname: "chikkodi",
  cs_code: "s101",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "PRIYANKA SATISH JARKIHOLI",
      party: "Indian National Congress",
      evm_votes: 710823,
      postal_votes: 2638,
      total_votes: 713461,
      percentage_of_votes: 51.21,
    },
    {
      serial_no: 2,
      candidate: "ANNASAHEB SHANKAR JOLLE",
      party: "Bharatiya Janata Party",
      evm_votes: 618168,
      postal_votes: 4459,
      total_votes: 622627,
      percentage_of_votes: 44.69,
    },
    {
      serial_no: 3,
      candidate: "KALLOLIKAR SHAMBHU KRISHNA",
      party: "Independent",
      evm_votes: 25351,
      postal_votes: 115,
      total_votes: 25466,
      percentage_of_votes: 1.83,
    },
    {
      serial_no: 4,
      candidate: "SAMMED SARADAR VARDHAMANE",
      party: "Independent",
      evm_votes: 5351,
      postal_votes: 2,
      total_votes: 5353,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 5,
      candidate: "SHRENIK ANNASAHEB JANGATE",
      party: "Independent",
      evm_votes: 5179,
      postal_votes: 2,
      total_votes: 5181,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 6,
      candidate: "APPASAHEB SHRIPATI KURANE",
      party: "Sarva Janata Party",
      evm_votes: 4635,
      postal_votes: 19,
      total_votes: 4654,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 7,
      candidate: "VILAS MANNUR",
      party: "Independent",
      evm_votes: 4143,
      postal_votes: 7,
      total_votes: 4150,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 8,
      candidate: "SATTEPPA DASHARATH KALELI",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 1304,
      postal_votes: 15,
      total_votes: 1319,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 9,
      candidate: "YASIN SHIRAJUDDIN PATAKI",
      party: "Independent",
      evm_votes: 1279,
      postal_votes: 2,
      total_votes: 1281,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 10,
      candidate: "KASHINATH KALLAPPA KURANI",
      party: "Independent",
      evm_votes: 1097,
      postal_votes: 11,
      total_votes: 1108,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 11,
      candidate: "MAHESH ASHOK KASAR",
      party: "Independent",
      evm_votes: 1002,
      postal_votes: 7,
      total_votes: 1009,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "MOHAN GURAPPA MOTANNAVAR",
      party: "Independent",
      evm_votes: 938,
      postal_votes: 8,
      total_votes: 946,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "KUMAR SAMBHAJI DONGARE",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 930,
      postal_votes: 11,
      total_votes: 941,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 14,
      candidate: "PAVANKUMAR BABURAV MALAGE",
      party: "Bahujan Bharat Party",
      evm_votes: 655,
      postal_votes: 11,
      total_votes: 666,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "DR KADAYYA HIREMATH (SWAMIJI)",
      party: "Independent",
      evm_votes: 642,
      postal_votes: 5,
      total_votes: 647,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "BHIMASEN DATTU SANADI",
      party: "Independent",
      evm_votes: 614,
      postal_votes: 5,
      total_votes: 619,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 17,
      candidate: "GAJANAN L. PUJARI",
      party: "Independent",
      evm_votes: 591,
      postal_votes: 9,
      total_votes: 600,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 18,
      candidate: "JITENDRA SUBHASH NERLE",
      party: "Independent",
      evm_votes: 452,
      postal_votes: 5,
      total_votes: 457,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 19,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 2571,
      postal_votes: 37,
      total_votes: 2608,
      percentage_of_votes: 0.19,
    },
  ],
  total: {
    evm_votes: 1385725,
    postal_votes: 7368,
    total_votes: 1393093,
  },
};
export const s105 = {
  cs_name: "Gulbarga",
  cs_shortname: "gulbarga",
  cs_code: "s105",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "RADHAKRISHNA",
      party: "Indian National Congress",
      evm_votes: 650623,
      postal_votes: 1698,
      total_votes: 652321,
      percentage_of_votes: 49.78,
    },
    {
      serial_no: 2,
      candidate: "DR. UMESH G JADHAV",
      party: "Bharatiya Janata Party",
      evm_votes: 622615,
      postal_votes: 2501,
      total_votes: 625116,
      percentage_of_votes: 47.7,
    },
    {
      serial_no: 3,
      candidate: "HUCHESHWARA WATHAR GOUR",
      party: "Bahujan Samaj Party",
      evm_votes: 7871,
      postal_votes: 17,
      total_votes: 7888,
      percentage_of_votes: 0.6,
    },
    {
      serial_no: 4,
      candidate: "SUNDAR",
      party: "Independent",
      evm_votes: 3675,
      postal_votes: 2,
      total_votes: 3677,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 5,
      candidate: "JYOTI RAMESH CHAVAN",
      party: "Independent",
      evm_votes: 2787,
      postal_votes: 18,
      total_votes: 2805,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 6,
      candidate: "SHARANAPPA (PINTU)",
      party: "Independent",
      evm_votes: 1879,
      postal_votes: 5,
      total_votes: 1884,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 7,
      candidate: "TARABAI BHOVI",
      party: "Independent",
      evm_votes: 1546,
      postal_votes: 4,
      total_votes: 1550,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 8,
      candidate: "RAMESH BHIMSING CHAVAN",
      party: "Independent",
      evm_votes: 1332,
      postal_votes: 2,
      total_votes: 1334,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 9,
      candidate: "ANAND SINNUR",
      party: "Independent",
      evm_votes: 1271,
      postal_votes: 9,
      total_votes: 1280,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 10,
      candidate: "NAGENDRA RAO",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 1211,
      postal_votes: 15,
      total_votes: 1226,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 11,
      candidate: "S.M.SHARMA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1136,
      postal_votes: 8,
      total_votes: 1144,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 12,
      candidate: "RAJKUMAR",
      party: "BhartiyaBahujanKranti Dal",
      evm_votes: 702,
      postal_votes: 7,
      total_votes: 709,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "VIJAY JADHAV",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 555,
      postal_votes: 5,
      total_votes: 560,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 14,
      candidate: "B. VIJAYKUMAR CHINCHANSOORKAR",
      party: "Prahar Janshakti Party",
      evm_votes: 548,
      postal_votes: 6,
      total_votes: 554,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8416,
      postal_votes: 13,
      total_votes: 8429,
      percentage_of_votes: 0.64,
    },
  ],
  total: {
    evm_votes: 1306167,
    postal_votes: 4310,
    total_votes: 1310477,
  },
};
export const s106 = {
  cs_name: "Raichur",
  cs_shortname: "raichur",
  cs_code: "s106",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "G. KUMAR NAIK.",
      party: "Indian National Congress",
      evm_votes: 669413,
      postal_votes: 1553,
      total_votes: 670966,
      percentage_of_votes: 51.63,
    },
    {
      serial_no: 2,
      candidate: "RAJA AMARESHWARA NAIK.",
      party: "Bharatiya Janata Party",
      evm_votes: 588833,
      postal_votes: 2352,
      total_votes: 591185,
      percentage_of_votes: 45.49,
    },
    {
      serial_no: 3,
      candidate: "S. NARASANNA GOUDA NAYAKA",
      party: "Bahujan Samaj Party",
      evm_votes: 9275,
      postal_votes: 14,
      total_votes: 9289,
      percentage_of_votes: 0.71,
    },
    {
      serial_no: 4,
      candidate: "BASAVA PRABHU. MEDA",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 5173,
      postal_votes: 8,
      total_votes: 5181,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 5,
      candidate: "AMARESH",
      party: "Independent",
      evm_votes: 4459,
      postal_votes: 6,
      total_votes: 4465,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 6,
      candidate: "YALLAMMA DALAPATHI",
      party: "Independent",
      evm_votes: 3629,
      postal_votes: 5,
      total_votes: 3634,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 7,
      candidate: "MEDHAR SHAMRAO",
      party: "Bhartiya Jan Samrat Party",
      evm_votes: 2625,
      postal_votes: 15,
      total_votes: 2640,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 8,
      candidate: "RAMALINGAPPA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2404,
      postal_votes: 5,
      total_votes: 2409,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9825,
      postal_votes: 25,
      total_votes: 9850,
      percentage_of_votes: 0.76,
    },
  ],
  total: {
    evm_votes: 1295636,
    postal_votes: 3983,
    total_votes: 1299619,
  },
};
export const s107 = {
  cs_name: "Bidar",
  cs_shortname: "bidar",
  cs_code: "s107",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "SAGAR ESHWAR KHANDRE",
      party: "Indian National Congress",
      evm_votes: 665162,
      postal_votes: 1155,
      total_votes: 666317,
      percentage_of_votes: 53.63,
    },
    {
      serial_no: 2,
      candidate: "BHAGWANTH KHUBA",
      party: "Bharatiya Janata Party",
      evm_votes: 535766,
      postal_votes: 1676,
      total_votes: 537442,
      percentage_of_votes: 43.26,
    },
    {
      serial_no: 3,
      candidate: "AMBADAS SOPANRAO HULSURE",
      party: "Bahujan Bharat Party",
      evm_votes: 5300,
      postal_votes: 2,
      total_votes: 5302,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 4,
      candidate: "PUTARAJ",
      party: "Bahujan Samaj Party",
      evm_votes: 4541,
      postal_votes: 13,
      total_votes: 4554,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 5,
      candidate: "DR. DINKAR MORE",
      party: "Independent",
      evm_votes: 4105,
      postal_votes: 11,
      total_votes: 4116,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 6,
      candidate: "SRI ROPH ABDUL GANI",
      party: "Independent",
      evm_votes: 3101,
      postal_votes: 3,
      total_votes: 3104,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 7,
      candidate: "SHIVRAJ ADIVEPPA SATWAR",
      party: "Independent",
      evm_votes: 3076,
      postal_votes: 3,
      total_votes: 3079,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 8,
      candidate: "RAMVILAS RAMULALJI NAVANDAR",
      party: "Independent",
      evm_votes: 2694,
      postal_votes: 0,
      total_votes: 2694,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 9,
      candidate: "RIYAZ AHMED",
      party: "Independent",
      evm_votes: 1710,
      postal_votes: 1,
      total_votes: 1711,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "MOHD. SHAFEEQ UR RAHAMAN",
      party: "All India Ulama Congress",
      evm_votes: 1684,
      postal_votes: 10,
      total_votes: 1694,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "JAIRAJ KASHAPPA BUKKA ADVOCATE",
      party: "Independent",
      evm_votes: 1493,
      postal_votes: 0,
      total_votes: 1493,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "BALBHEEM UNNE",
      party: "Independent",
      evm_votes: 1462,
      postal_votes: 8,
      total_votes: 1470,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "WASEEMUDDIN",
      party: "Independent",
      evm_votes: 1169,
      postal_votes: 1,
      total_votes: 1170,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "MAHESH GORNALKAR",
      party: "Republican Party of India (A)",
      evm_votes: 974,
      postal_votes: 7,
      total_votes: 981,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "DILIP KADWAD",
      party: "Independent",
      evm_votes: 791,
      postal_votes: 7,
      total_votes: 798,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 16,
      candidate: "GOPAL M.P. GARAMPALLI",
      party: "Independent",
      evm_votes: 765,
      postal_votes: 2,
      total_votes: 767,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 17,
      candidate: "RAMESH J CHAVAN",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 471,
      postal_votes: 1,
      total_votes: 472,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 18,
      candidate: "RAMCHANDRA NARAYAN KACHAVE",
      party: "Kranti Kari Jai Hind Sena",
      evm_votes: 468,
      postal_votes: 4,
      total_votes: 472,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 19,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4674,
      postal_votes: 12,
      total_votes: 4686,
      percentage_of_votes: 0.38,
    },
  ],
  total: {
    evm_votes: 1239406,
    postal_votes: 2916,
    total_votes: 1242322,
  },
};
export const s108 = {
  cs_name: "Koppal",
  cs_shortname: "koppal",
  cs_code: "s108",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "K. RAJASHEKAR BASAVARAJ HITNAL",
      party: "Indian National Congress",
      evm_votes: 661921,
      postal_votes: 1590,
      total_votes: 663511,
      percentage_of_votes: 49.93,
    },
    {
      serial_no: 2,
      candidate: "DR . BASAVARAJ. K. SHARANAPPA",
      party: "Bharatiya Janata Party",
      evm_votes: 614866,
      postal_votes: 2288,
      total_votes: 617154,
      percentage_of_votes: 46.44,
    },
    {
      serial_no: 3,
      candidate: "MALLIKARJUN HADAPAD",
      party: "Independent",
      evm_votes: 7310,
      postal_votes: 4,
      total_votes: 7314,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 4,
      candidate: "SHANKARA",
      party: "Bahujan Samaj Party",
      evm_votes: 7031,
      postal_votes: 12,
      total_votes: 7043,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 5,
      candidate: "KARADI BASAVARAJ",
      party: "Independent",
      evm_votes: 5868,
      postal_votes: 9,
      total_votes: 5877,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 6,
      candidate: "RUKMINI",
      party: "Independent",
      evm_votes: 5279,
      postal_votes: 3,
      total_votes: 5282,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 7,
      candidate: "SURESH. GOUDA MUNDINAMANE",
      party: "Independent",
      evm_votes: 4641,
      postal_votes: 1,
      total_votes: 4642,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 8,
      candidate: "NAGARAJ KALAL",
      party: "Independent",
      evm_votes: 1992,
      postal_votes: 2,
      total_votes: 1994,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 9,
      candidate: "KALAPPA YACHRAPPA BADIGER VISHWAKARMA",
      party: "Independent",
      evm_votes: 1967,
      postal_votes: 15,
      total_votes: 1982,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "PA.YA. GANESH",
      party: "Independent",
      evm_votes: 1892,
      postal_votes: 3,
      total_votes: 1895,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "HANAMESH . S . H. SHAKHAPUR",
      party: "Independent",
      evm_votes: 1247,
      postal_votes: 1,
      total_votes: 1248,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 12,
      candidate: "KAREEMPASHA GACHCHINMANI.",
      party: "Independent",
      evm_votes: 1233,
      postal_votes: 5,
      total_votes: 1238,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "ANNOJI RAO. G",
      party: "Sarva Janata Party",
      evm_votes: 1113,
      postal_votes: 4,
      total_votes: 1117,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "SHARANAPPA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1097,
      postal_votes: 9,
      total_votes: 1106,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "NIRUPADI K GOMARSI",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 891,
      postal_votes: 9,
      total_votes: 900,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 16,
      candidate: "RAMZANBEE",
      party: "All India Ulama Congress",
      evm_votes: 800,
      postal_votes: 6,
      total_votes: 806,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 17,
      candidate: "D DURGA PRASAD BYATARAYANAJI",
      party: "Challengers Party",
      evm_votes: 798,
      postal_votes: 1,
      total_votes: 799,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 18,
      candidate: "C. SHARANA BASAPPA",
      party: "Republican Party of India (Karnataka)",
      evm_votes: 770,
      postal_votes: 6,
      total_votes: 776,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 19,
      candidate: "IMAMSAB JANGLISAB MULLA",
      party: "Independent",
      evm_votes: 728,
      postal_votes: 5,
      total_votes: 733,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 20,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3496,
      postal_votes: 23,
      total_votes: 3519,
      percentage_of_votes: 0.26,
    },
  ],
  total: {
    evm_votes: 1324940,
    postal_votes: 3996,
    total_votes: 1328936,
  },
};
export const s109 = {
  cs_name: "Bellary",
  cs_shortname: "bellary",
  cs_code: "s109",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "E. TUKARAM",
      party: "Indian National Congress",
      evm_votes: 729581,
      postal_votes: 1264,
      total_votes: 730845,
      percentage_of_votes: 52.58,
    },
    {
      serial_no: 2,
      candidate: "B. SREERAMULU",
      party: "Bharatiya Janata Party",
      evm_votes: 629813,
      postal_votes: 2040,
      total_votes: 631853,
      percentage_of_votes: 45.46,
    },
    {
      serial_no: 3,
      candidate: "VALMIKI KRISHNAPPA",
      party: "Bahujan Samaj Party",
      evm_votes: 5901,
      postal_votes: 10,
      total_votes: 5911,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 4,
      candidate: "C. CHANNAVEERA",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 3794,
      postal_votes: 3,
      total_votes: 3797,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 5,
      candidate: "CONDUCTOR PAMPAPATHI",
      party: "Independent",
      evm_votes: 2458,
      postal_votes: 2,
      total_votes: 2460,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 6,
      candidate: "VEERESH",
      party: "Independent",
      evm_votes: 1862,
      postal_votes: 8,
      total_votes: 1870,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 7,
      candidate: "A. DEVADAS",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1739,
      postal_votes: 2,
      total_votes: 1741,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 8,
      candidate: "MANJUNATHA GOSAL",
      party: "Prahar Janshakti Party",
      evm_votes: 1319,
      postal_votes: 3,
      total_votes: 1322,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 9,
      candidate: "ARUN.S.HIREHAL",
      party: "Independent",
      evm_votes: 1281,
      postal_votes: 1,
      total_votes: 1282,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 10,
      candidate: "G. SWAMY",
      party: "Navabharath Sena",
      evm_votes: 940,
      postal_votes: 4,
      total_votes: 944,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7871,
      postal_votes: 18,
      total_votes: 7889,
      percentage_of_votes: 0.57,
    },
  ],
  total: {
    evm_votes: 1386559,
    postal_votes: 3355,
    total_votes: 1389914,
  },
};
export const s1013 = {
  cs_name: "Davanagere",
  cs_shortname: "davanagere",
  cs_code: "s1013",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "DR. PRABHA MALLIKARJUN",
      party: "Indian National Congress",
      evm_votes: 631453,
      postal_votes: 1606,
      total_votes: 633059,
      percentage_of_votes: 47.95,
    },
    {
      serial_no: 2,
      candidate: "GAYITHRI SIDDESHWARA",
      party: "Bharatiya Janata Party",
      evm_votes: 604531,
      postal_votes: 2434,
      total_votes: 606965,
      percentage_of_votes: 45.97,
    },
    {
      serial_no: 3,
      candidate: "G.B.VINAY KUMAR",
      party: "Independent",
      evm_votes: 42624,
      postal_votes: 283,
      total_votes: 42907,
      percentage_of_votes: 3.25,
    },
    {
      serial_no: 4,
      candidate: "T.JABEENA AAPA",
      party: "Independent",
      evm_votes: 5127,
      postal_votes: 1,
      total_votes: 5128,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 5,
      candidate: "D. HANUMANTHAPPA",
      party: "Bahujan Samaj Party",
      evm_votes: 4462,
      postal_votes: 13,
      total_votes: 4475,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 6,
      candidate: "TASLIM BANU",
      party: "Independent",
      evm_votes: 4140,
      postal_votes: 1,
      total_votes: 4141,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 7,
      candidate: "T.CHANDRU",
      party: "Independent",
      evm_votes: 3043,
      postal_votes: 0,
      total_votes: 3043,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 8,
      candidate: "G.M.GAYITRI SIDDESHI",
      party: "Independent",
      evm_votes: 2453,
      postal_votes: 11,
      total_votes: 2464,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 9,
      candidate: "PARAVEEJ.H",
      party: "Independent",
      evm_votes: 1867,
      postal_votes: 2,
      total_votes: 1869,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "ESHWARA SHENGA",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 1424,
      postal_votes: 6,
      total_votes: 1430,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 11,
      candidate: "A.K.GANESH",
      party: "Independent",
      evm_votes: 1054,
      postal_votes: 1,
      total_votes: 1055,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "M.C.SREENIVAS",
      party: "Bharatiya Prajagala Kalyana Paksha",
      evm_votes: 968,
      postal_votes: 5,
      total_votes: 973,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "SYED ZABIULLA.K",
      party: "Independent",
      evm_votes: 843,
      postal_votes: 1,
      total_votes: 844,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 14,
      candidate: "ANABERU THIPPESWAMY",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 842,
      postal_votes: 7,
      total_votes: 849,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "SALEEM S",
      party: "Independent",
      evm_votes: 799,
      postal_votes: 0,
      total_votes: 799,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 16,
      candidate: "MOHAMED HAYATH.M",
      party: "Independent",
      evm_votes: 721,
      postal_votes: 1,
      total_votes: 722,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 17,
      candidate: "ABDUL NAZEER AHAMMED",
      party: "Independent",
      evm_votes: 651,
      postal_votes: 1,
      total_votes: 652,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 18,
      candidate: "RASHEED KHAN",
      party: "Independent",
      evm_votes: 599,
      postal_votes: 1,
      total_votes: 600,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 19,
      candidate: "M.G.SRIKANTH",
      party: "Navabharath Sena",
      evm_votes: 555,
      postal_votes: 6,
      total_votes: 561,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 20,
      candidate: "M.P. KHALANDAR",
      party: "Country Citizen Party",
      evm_votes: 536,
      postal_votes: 3,
      total_votes: 539,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 21,
      candidate: "PEDDAPPA S",
      party: "Independent",
      evm_votes: 506,
      postal_votes: 2,
      total_votes: 508,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 22,
      candidate: "G.M.BARKATH ALI BASHA",
      party: "Independent",
      evm_votes: 470,
      postal_votes: 1,
      total_votes: 471,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 23,
      candidate: "K.S. VEERBHADRAPPA",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 466,
      postal_votes: 9,
      total_votes: 475,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 24,
      candidate: "VEERESH.S (LION VEERESH)",
      party: "Rani Chennamma Party",
      evm_votes: 457,
      postal_votes: 1,
      total_votes: 458,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 25,
      candidate: "DODDESH.H.S",
      party: "Janahitha Paksha",
      evm_votes: 439,
      postal_votes: 1,
      total_votes: 440,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 26,
      candidate: "BARKATH ALI",
      party: "Independent",
      evm_votes: 409,
      postal_votes: 2,
      total_votes: 411,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 27,
      candidate: "MANJU. MARIKOPPA",
      party: "Independent",
      evm_votes: 376,
      postal_votes: 2,
      total_votes: 378,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 28,
      candidate: "RUDRESHA.K.H",
      party: "Samaj Vikas Kranti Party",
      evm_votes: 346,
      postal_votes: 2,
      total_votes: 348,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 29,
      candidate: "RAVINAIK. B",
      party: "Independent",
      evm_votes: 317,
      postal_votes: 3,
      total_votes: 320,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 30,
      candidate: "MAHABUB BASHA",
      party: "Independent",
      evm_votes: 308,
      postal_votes: 0,
      total_votes: 308,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 31,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3165,
      postal_votes: 11,
      total_votes: 3176,
      percentage_of_votes: 0.24,
    },
  ],
  total: {
    evm_votes: 1315951,
    postal_votes: 4417,
    total_votes: 1320368,
  },
};
export const s1016 = {
  cs_name: "Hassan",
  cs_shortname: "hassan",
  cs_code: "s1016",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "SHREYAS. M. PATEL",
      party: "Indian National Congress",
      evm_votes: 670274,
      postal_votes: 2714,
      total_votes: 672988,
      percentage_of_votes: 49.67,
    },
    {
      serial_no: 2,
      candidate: "PRAJWAL REVANNA",
      party: "Janata Dal (Secular)",
      evm_votes: 626536,
      postal_votes: 3803,
      total_votes: 630339,
      percentage_of_votes: 46.52,
    },
    {
      serial_no: 3,
      candidate: "GANGADHAR BAHUJAN",
      party: "Bahujan Samaj Party",
      evm_votes: 12068,
      postal_votes: 105,
      total_votes: 12173,
      percentage_of_votes: 0.9,
    },
    {
      serial_no: 4,
      candidate: "DEVARAJ M.Y",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 6033,
      postal_votes: 24,
      total_votes: 6057,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 5,
      candidate: "H D REVANNA",
      party: "Purvanchal Mahapanchayat",
      evm_votes: 4256,
      postal_votes: 16,
      total_votes: 4272,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 6,
      candidate: "BASAVARAJU J.D (EX-ARMY)",
      party: "Independent",
      evm_votes: 3983,
      postal_votes: 80,
      total_votes: 4063,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 7,
      candidate: "SANTHOSH B.N",
      party: "Independent",
      evm_votes: 3444,
      postal_votes: 5,
      total_votes: 3449,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 8,
      candidate: "HEMANTH GAVEESH",
      party: "Independent",
      evm_votes: 3277,
      postal_votes: 4,
      total_votes: 3281,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 9,
      candidate: "PRATHAPA K.A",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 3213,
      postal_votes: 37,
      total_votes: 3250,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 10,
      candidate: "S.K. NINGARAJA",
      party: "Bahujan Bharat Party",
      evm_votes: 1710,
      postal_votes: 20,
      total_votes: 1730,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "B.N. SURESHA (SURESHGOWDA)",
      party: "Independent",
      evm_votes: 1409,
      postal_votes: 9,
      total_votes: 1418,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 12,
      candidate: "SIDDABHOVI",
      party: "Independent",
      evm_votes: 940,
      postal_votes: 47,
      total_votes: 987,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "SHIVARAJ. B",
      party: "Republican Party of India Bharatha",
      evm_votes: 843,
      postal_votes: 5,
      total_votes: 848,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 14,
      candidate: "R.G. SATHISHA RAMEDEVARAPURA",
      party: "Independent",
      evm_votes: 823,
      postal_votes: 46,
      total_votes: 869,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "K.R. GANGADHARAPPA",
      party: "Independent",
      evm_votes: 734,
      postal_votes: 8,
      total_votes: 742,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8470,
      postal_votes: 71,
      total_votes: 8541,
      percentage_of_votes: 0.63,
    },
  ],
  total: {
    evm_votes: 1348013,
    postal_votes: 6994,
    total_votes: 1355007,
  },
};
export const s1022 = {
  cs_name: "Chamarajanagar",
  cs_shortname: "chamarajanagar",
  cs_code: "s1022",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "SUNIL BOSE",
      party: "Indian National Congress",
      evm_votes: 749926,
      postal_votes: 1745,
      total_votes: 751671,
      percentage_of_votes: 54.87,
    },
    {
      serial_no: 2,
      candidate: "BALARAJ.S",
      party: "Bharatiya Janata Party",
      evm_votes: 561056,
      postal_votes: 1909,
      total_votes: 562965,
      percentage_of_votes: 41.1,
    },
    {
      serial_no: 3,
      candidate: "M.KRISHNA MURTHY",
      party: "Bahujan Samaj Party",
      evm_votes: 15819,
      postal_votes: 84,
      total_votes: 15903,
      percentage_of_votes: 1.16,
    },
    {
      serial_no: 4,
      candidate: "C.M.KRISHNA",
      party: "Dr. Ambedkar People's Party",
      evm_votes: 8074,
      postal_votes: 15,
      total_votes: 8089,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 5,
      candidate: "G.D.RAJAGOPAL (H.D.KOTE)",
      party: "Independent",
      evm_votes: 3949,
      postal_votes: 5,
      total_votes: 3954,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 6,
      candidate: "SUMA.S",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 3821,
      postal_votes: 5,
      total_votes: 3826,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 7,
      candidate: "MAHADEVASWAMY B M (PAMPI)",
      party: "Independent",
      evm_votes: 3414,
      postal_votes: 7,
      total_votes: 3421,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 8,
      candidate: "H.K.SWAMY HARADANAHALLI",
      party: "Independent",
      evm_votes: 3206,
      postal_votes: 2,
      total_votes: 3208,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 9,
      candidate: "PATAS PRADEEP KUMAR.M",
      party: "Independent",
      evm_votes: 1969,
      postal_votes: 17,
      total_votes: 1986,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "KADAMBA. NAA. AMBARISH",
      party: "Independent",
      evm_votes: 1738,
      postal_votes: 5,
      total_votes: 1743,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "NINGARAJU.S",
      party: "Karnataka Jantha Paksha",
      evm_votes: 1406,
      postal_votes: 5,
      total_votes: 1411,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 12,
      candidate: "KANDALLI MAHESH",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 1249,
      postal_votes: 3,
      total_votes: 1252,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "NINGARAJ.G",
      party: "Independent",
      evm_votes: 1236,
      postal_votes: 11,
      total_votes: 1247,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "PRASANNA KUMAR.B",
      party: "Karnataka Praja Party (RaithaParva)",
      evm_votes: 972,
      postal_votes: 6,
      total_votes: 978,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8094,
      postal_votes: 49,
      total_votes: 8143,
      percentage_of_votes: 0.59,
    },
  ],
  total: {
    evm_votes: 1365929,
    postal_votes: 3868,
    total_votes: 1369797,
  },
};
export const s111 = {
  cs_name: "Kasaragod",
  cs_shortname: "kasaragod",
  cs_code: "s111",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "RAJMOHAN UNNITHAN",
      party: "Indian National Congress",
      evm_votes: 487637,
      postal_votes: 3022,
      total_votes: 490659,
      percentage_of_votes: 44.1,
    },
    {
      serial_no: 2,
      candidate: "M.V BALAKRISHNAN MASTER",
      party: "Communist Party of India (Marxist)",
      evm_votes: 385969,
      postal_votes: 4041,
      total_votes: 390010,
      percentage_of_votes: 35.06,
    },
    {
      serial_no: 3,
      candidate: "M.L ASHWINI",
      party: "Bharatiya Janata Party",
      evm_votes: 217996,
      postal_votes: 1562,
      total_votes: 219558,
      percentage_of_votes: 19.73,
    },
    {
      serial_no: 4,
      candidate: "SUKUMARI M",
      party: "Bahujan Samaj Party",
      evm_votes: 1599,
      postal_votes: 13,
      total_votes: 1612,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 5,
      candidate: "RAJESWARI K.R",
      party: "Independent",
      evm_votes: 886,
      postal_votes: 11,
      total_votes: 897,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 6,
      candidate: "MANOHARAN K",
      party: "Independent",
      evm_votes: 777,
      postal_votes: 27,
      total_votes: 804,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 7,
      candidate: "ANEESH PAYYANNUR",
      party: "Independent",
      evm_votes: 744,
      postal_votes: 15,
      total_votes: 759,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 8,
      candidate: "BALAKRISHNAN N",
      party: "Independent",
      evm_votes: 594,
      postal_votes: 34,
      total_votes: 628,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 9,
      candidate: "N KESHAVA NAYAK",
      party: "Independent",
      evm_votes: 480,
      postal_votes: 27,
      total_votes: 507,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7037,
      postal_votes: 75,
      total_votes: 7112,
      percentage_of_votes: 0.64,
    },
  ],
  total: {
    evm_votes: 1103719,
    postal_votes: 8827,
    total_votes: 1112546,
  },
};
export const s112 = {
  cs_name: "Kannur",
  cs_shortname: "kannur",
  cs_code: "s112",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "K. SUDHAKARAN",
      party: "Indian National Congress",
      evm_votes: 512266,
      postal_votes: 6258,
      total_votes: 518524,
      percentage_of_votes: 48.74,
    },
    {
      serial_no: 2,
      candidate: "M.V. JAYARAJAN",
      party: "Communist Party of India (Marxist)",
      evm_votes: 402614,
      postal_votes: 6928,
      total_votes: 409542,
      percentage_of_votes: 38.5,
    },
    {
      serial_no: 3,
      candidate: "C. RAGHUNATH",
      party: "Bharatiya Janata Party",
      evm_votes: 118352,
      postal_votes: 1524,
      total_votes: 119876,
      percentage_of_votes: 11.27,
    },
    {
      serial_no: 4,
      candidate: "RAMACHANDRAN BAVILEERI",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 2606,
      postal_votes: 43,
      total_votes: 2649,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 5,
      candidate: "K. SUDHAKARAN S/O. KRISHNAN",
      party: "Independent",
      evm_votes: 990,
      postal_votes: 19,
      total_votes: 1009,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 6,
      candidate: "SUDHAKARAN. K S/O. P GOPALAN",
      party: "Independent",
      evm_votes: 893,
      postal_votes: 3,
      total_votes: 896,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 7,
      candidate: "JOY JOHN PATTAR MADATHIL",
      party: "Independent",
      evm_votes: 616,
      postal_votes: 2,
      total_votes: 618,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 8,
      candidate: "JAYARAJ",
      party: "Independent",
      evm_votes: 478,
      postal_votes: 10,
      total_votes: 488,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 9,
      candidate: "JAYARAJAN S/O. VELAYUDHAN",
      party: "Independent",
      evm_votes: 421,
      postal_votes: 4,
      total_votes: 425,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 10,
      candidate: "VADI HAREENDRAN",
      party: "Independent",
      evm_votes: 289,
      postal_votes: 54,
      total_votes: 343,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 11,
      candidate: "C. BALAKRISHNAN YADAV",
      party: "Independent",
      evm_votes: 279,
      postal_votes: 25,
      total_votes: 304,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 12,
      candidate: "NARAYANA KUMAR",
      party: "Independent",
      evm_votes: 263,
      postal_votes: 45,
      total_votes: 308,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8718,
      postal_votes: 155,
      total_votes: 8873,
      percentage_of_votes: 0.83,
    },
  ],
  total: {
    evm_votes: 1048785,
    postal_votes: 15070,
    total_votes: 1063855,
  },
};
export const s113 = {
  cs_name: "Vadakara",
  cs_shortname: "vadakara",
  cs_code: "s113",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "SHAFI PARAMBIL",
      party: "Indian National Congress",
      evm_votes: 552490,
      postal_votes: 5038,
      total_votes: 557528,
      percentage_of_votes: 49.65,
    },
    {
      serial_no: 2,
      candidate: "K K SHAILAJA TEACHER",
      party: "Communist Party of India (Marxist)",
      evm_votes: 437333,
      postal_votes: 5689,
      total_votes: 443022,
      percentage_of_votes: 39.45,
    },
    {
      serial_no: 3,
      candidate: "PRAPHUL KRISHNAN",
      party: "Bharatiya Janata Party",
      evm_votes: 110701,
      postal_votes: 1278,
      total_votes: 111979,
      percentage_of_votes: 9.97,
    },
    {
      serial_no: 4,
      candidate: "SHAFI T P",
      party: "Independent",
      evm_votes: 3715,
      postal_votes: 49,
      total_votes: 3764,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 5,
      candidate: "K K SHYLAJA",
      party: "Independent",
      evm_votes: 1164,
      postal_votes: 15,
      total_votes: 1179,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 6,
      candidate: "KUNHIKANNAN PAYYOLI",
      party: "Independent",
      evm_votes: 867,
      postal_votes: 2,
      total_votes: 869,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 7,
      candidate: "SHYLAJA",
      party: "Independent",
      evm_votes: 661,
      postal_votes: 19,
      total_votes: 680,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 8,
      candidate: "SHAFI",
      party: "Independent",
      evm_votes: 399,
      postal_votes: 23,
      total_votes: 422,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 9,
      candidate: "SYLAJA P",
      party: "Independent",
      evm_votes: 319,
      postal_votes: 7,
      total_votes: 326,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 10,
      candidate: "MURALIDHARAN",
      party: "Independent",
      evm_votes: 261,
      postal_votes: 8,
      total_votes: 269,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 2855,
      postal_votes: 54,
      total_votes: 2909,
      percentage_of_votes: 0.26,
    },
  ],
  total: {
    evm_votes: 1110765,
    postal_votes: 12182,
    total_votes: 1122947,
  },
};
export const s114 = {
  cs_name: "Wayanad",
  cs_shortname: "wayanad",
  cs_code: "s114",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "RAHUL GANDHI",
      party: "Indian National Congress",
      evm_votes: 642299,
      postal_votes: 5146,
      total_votes: 647445,
      percentage_of_votes: 59.69,
    },
    {
      serial_no: 2,
      candidate: "ANNIE RAJA",
      party: "Communist Party of India",
      evm_votes: 280594,
      postal_votes: 2429,
      total_votes: 283023,
      percentage_of_votes: 26.09,
    },
    {
      serial_no: 3,
      candidate: "K SURENDRAN",
      party: "Bharatiya Janata Party",
      evm_votes: 139868,
      postal_votes: 1177,
      total_votes: 141045,
      percentage_of_votes: 13,
    },
    {
      serial_no: 4,
      candidate: "P.R KRISHNANKUTTY",
      party: "Bahujan Samaj Party",
      evm_votes: 1946,
      postal_votes: 27,
      total_votes: 1973,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 5,
      candidate: "SATHYAN K.P",
      party: "Independent",
      evm_votes: 1017,
      postal_votes: 42,
      total_votes: 1059,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 6,
      candidate: "SINOJ A.C",
      party: "Independent",
      evm_votes: 885,
      postal_votes: 18,
      total_votes: 903,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 7,
      candidate: "PRASEETHA AZHIKODE",
      party: "Independent",
      evm_votes: 814,
      postal_votes: 26,
      total_votes: 840,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 8,
      candidate: "AJEEB MUHAMMED",
      party: "Independent",
      evm_votes: 796,
      postal_votes: 8,
      total_votes: 804,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 9,
      candidate: "P RADHAKRISHNAN",
      party: "Independent",
      evm_votes: 555,
      postal_votes: 7,
      total_votes: 562,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6932,
      postal_votes: 67,
      total_votes: 6999,
      percentage_of_votes: 0.65,
    },
  ],
  total: {
    evm_votes: 1075706,
    postal_votes: 8947,
    total_votes: 1084653,
  },
};
export const s115 = {
  cs_name: "Kozhikode",
  cs_shortname: "kozhikode",
  cs_code: "s115",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "M. K. RAGHAVAN",
      party: "Indian National Congress",
      evm_votes: 515608,
      postal_votes: 4813,
      total_votes: 520421,
      percentage_of_votes: 47.74,
    },
    {
      serial_no: 2,
      candidate: "ELAMARAM KAREEM",
      party: "Communist Party of India (Marxist)",
      evm_votes: 369884,
      postal_votes: 4361,
      total_votes: 374245,
      percentage_of_votes: 34.33,
    },
    {
      serial_no: 3,
      candidate: "M. T. RAMESH",
      party: "Bharatiya Janata Party",
      evm_votes: 178692,
      postal_votes: 1974,
      total_votes: 180666,
      percentage_of_votes: 16.57,
    },
    {
      serial_no: 4,
      candidate: "ARAMUKHAN",
      party: "Bahujan Samaj Party",
      evm_votes: 1684,
      postal_votes: 31,
      total_votes: 1715,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 5,
      candidate: "ARAVINDAKSHAN NAIR M. K.",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 1290,
      postal_votes: 15,
      total_votes: 1305,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 6,
      candidate: "RAGHAVAN S/O NARAYANAN NAIR",
      party: "Independent",
      evm_votes: 1057,
      postal_votes: 39,
      total_votes: 1096,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 7,
      candidate: "T. RAGHAVAN S/O VELLANKUTTY",
      party: "Independent",
      evm_votes: 1009,
      postal_votes: 9,
      total_votes: 1018,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 8,
      candidate: "N. RAGHAVAN S/O DAMU",
      party: "Independent",
      evm_votes: 753,
      postal_votes: 29,
      total_votes: 782,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 9,
      candidate: "SUBHA",
      party: "Independent",
      evm_votes: 705,
      postal_votes: 7,
      total_votes: 712,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 10,
      candidate: "DR. M. JYOTHIRAJ",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 641,
      postal_votes: 12,
      total_votes: 653,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 11,
      candidate: "ABDUL KAREEM S/O MAHAMMOOD",
      party: "Independent",
      evm_votes: 536,
      postal_votes: 5,
      total_votes: 541,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 12,
      candidate: "ABDUL KAREEM S/O AYAMMATH KUTTY",
      party: "Independent",
      evm_votes: 285,
      postal_votes: 2,
      total_votes: 287,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 13,
      candidate: "ABDUL KAREEM K. S/O ASSAIN",
      party: "Independent",
      evm_votes: 257,
      postal_votes: 36,
      total_votes: 293,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6236,
      postal_votes: 80,
      total_votes: 6316,
      percentage_of_votes: 0.58,
    },
  ],
  total: {
    evm_votes: 1078637,
    postal_votes: 11413,
    total_votes: 1090050,
  },
};
export const s118 = {
  cs_name: "Palakkad",
  cs_shortname: "palakkad",
  cs_code: "s118",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "V K SREEKANDAN",
      party: "Indian National Congress",
      evm_votes: 418072,
      postal_votes: 3097,
      total_votes: 421169,
      percentage_of_votes: 40.66,
    },
    {
      serial_no: 2,
      candidate: "A VIJAYARAGHAVAN",
      party: "Communist Party of India (Marxist)",
      evm_votes: 342798,
      postal_votes: 3088,
      total_votes: 345886,
      percentage_of_votes: 33.39,
    },
    {
      serial_no: 3,
      candidate: "C KRISHNAKUMAR",
      party: "Bharatiya Janata Party",
      evm_votes: 249568,
      postal_votes: 2210,
      total_votes: 251778,
      percentage_of_votes: 24.31,
    },
    {
      serial_no: 4,
      candidate: "K T PADMINI",
      party: "Bahujan Samaj Party",
      evm_votes: 2102,
      postal_votes: 30,
      total_votes: 2132,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 5,
      candidate: "ANNA KURIAKOSE",
      party: "Independent",
      evm_votes: 1416,
      postal_votes: 17,
      total_votes: 1433,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 6,
      candidate: "N S K PURAM SASIKUMAR",
      party: "Independent",
      evm_votes: 1151,
      postal_votes: 26,
      total_votes: 1177,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 7,
      candidate: "RAJESH K SON OF VELANKUTTY",
      party: "Independent",
      evm_votes: 1033,
      postal_votes: 23,
      total_votes: 1056,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 8,
      candidate: "RAJESH M SON OF MANI C",
      party: "Independent",
      evm_votes: 947,
      postal_votes: 6,
      total_votes: 953,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 9,
      candidate: "SIDHEEK IRUPPASSERY",
      party: "Independent",
      evm_votes: 913,
      postal_votes: 26,
      total_votes: 939,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 10,
      candidate: "C RAJAMANICKAM",
      party: "Independent",
      evm_votes: 516,
      postal_votes: 4,
      total_votes: 520,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8724,
      postal_votes: 69,
      total_votes: 8793,
      percentage_of_votes: 0.85,
    },
  ],
  total: {
    evm_votes: 1027240,
    postal_votes: 8596,
    total_votes: 1035836,
  },
};
export const s1111 = {
  cs_name: "Chalakudy",
  cs_shortname: "chalakudy",
  cs_code: "s1111",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "BENNY BEHANAN",
      party: "Indian National Congress",
      evm_votes: 390216,
      postal_votes: 3955,
      total_votes: 394171,
      percentage_of_votes: 41.44,
    },
    {
      serial_no: 2,
      candidate: "PROF C RAVEENDRANATH",
      party: "Communist Party of India (Marxist)",
      evm_votes: 327382,
      postal_votes: 3035,
      total_votes: 330417,
      percentage_of_votes: 34.73,
    },
    {
      serial_no: 3,
      candidate: "K A UNNIKRISHNAN",
      party: "Bharath Dharma Jana Sena",
      evm_votes: 105706,
      postal_votes: 694,
      total_votes: 106400,
      percentage_of_votes: 11.18,
    },
    {
      serial_no: 4,
      candidate: "ADV CHARLY PAUL",
      party: "Twenty 20 Party",
      evm_votes: 105020,
      postal_votes: 622,
      total_votes: 105642,
      percentage_of_votes: 11.11,
    },
    {
      serial_no: 5,
      candidate: "ROSILIN CHACKO",
      party: "Bahujan Samaj Party",
      evm_votes: 2377,
      postal_votes: 33,
      total_votes: 2410,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 6,
      candidate: "BOSCO KALAMASSERY",
      party: "Independent",
      evm_votes: 1071,
      postal_votes: 16,
      total_votes: 1087,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 7,
      candidate: "SUBRAN K R",
      party: "Independent",
      evm_votes: 789,
      postal_votes: 10,
      total_votes: 799,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 8,
      candidate: "DR M PRADEEPAN",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 688,
      postal_votes: 6,
      total_votes: 694,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 9,
      candidate: "ARUN EDATHADAN",
      party: "Independent",
      evm_votes: 662,
      postal_votes: 24,
      total_votes: 686,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 10,
      candidate: "T S CHANDRAN",
      party: "Independent",
      evm_votes: 481,
      postal_votes: 50,
      total_votes: 531,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 11,
      candidate: "JOHNSON K C",
      party: "Independent",
      evm_votes: 379,
      postal_votes: 8,
      total_votes: 387,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8021,
      postal_votes: 42,
      total_votes: 8063,
      percentage_of_votes: 0.85,
    },
  ],
  total: {
    evm_votes: 942792,
    postal_votes: 8495,
    total_votes: 951287,
  },
};
export const s1112 = {
  cs_name: "Ernakulam",
  cs_shortname: "ernakulam",
  cs_code: "s1112",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "HIBI EDEN",
      party: "Indian National Congress",
      evm_votes: 478989,
      postal_votes: 3328,
      total_votes: 482317,
      percentage_of_votes: 52.97,
    },
    {
      serial_no: 2,
      candidate: "K. J. SHINE TEACHER",
      party: "Communist Party of India (Marxist)",
      evm_votes: 230059,
      postal_votes: 1873,
      total_votes: 231932,
      percentage_of_votes: 25.47,
    },
    {
      serial_no: 3,
      candidate: "DR. K. S. RADHAKRISHNAN",
      party: "Bharatiya Janata Party",
      evm_votes: 143573,
      postal_votes: 927,
      total_votes: 144500,
      percentage_of_votes: 15.87,
    },
    {
      serial_no: 4,
      candidate: "ADV. ANTONY JUDY",
      party: "Twenty 20 Party",
      evm_votes: 39658,
      postal_votes: 150,
      total_votes: 39808,
      percentage_of_votes: 4.37,
    },
    {
      serial_no: 5,
      candidate: "VAYALAR JAYAKUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 1480,
      postal_votes: 18,
      total_votes: 1498,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 6,
      candidate: "SANDEEP RAJENDRAPRASAD",
      party: "Independent",
      evm_votes: 734,
      postal_votes: 18,
      total_votes: 752,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 7,
      candidate: "CYRILL SKARIA",
      party: "Independent",
      evm_votes: 663,
      postal_votes: 27,
      total_votes: 690,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 8,
      candidate: "PRATHAPAN",
      party: "Bahujan Dravida Party",
      evm_votes: 414,
      postal_votes: 5,
      total_votes: 419,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 9,
      candidate: "BRAHMAKUMAR",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 409,
      postal_votes: 3,
      total_votes: 412,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 10,
      candidate: "ROHIT KRISHNAN",
      party: "Independent",
      evm_votes: 408,
      postal_votes: 8,
      total_votes: 416,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7715,
      postal_votes: 43,
      total_votes: 7758,
      percentage_of_votes: 0.85,
    },
  ],
  total: {
    evm_votes: 904102,
    postal_votes: 6400,
    total_votes: 910502,
  },
};
export const s1113 = {
  cs_name: "Idukki",
  cs_shortname: "idukki",
  cs_code: "s1113",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "ADV. DEAN KURIAKOSE",
      party: "Indian National Congress",
      evm_votes: 427129,
      postal_votes: 5243,
      total_votes: 432372,
      percentage_of_votes: 51.43,
    },
    {
      serial_no: 2,
      candidate: "ADV. JOICE GEORGE",
      party: "Communist Party of India (Marxist)",
      evm_votes: 295975,
      postal_votes: 2670,
      total_votes: 298645,
      percentage_of_votes: 35.53,
    },
    {
      serial_no: 3,
      candidate: "ADV. SANGEETHA VISWANATHAN",
      party: "Bharath Dharma Jana Sena",
      evm_votes: 90663,
      postal_votes: 660,
      total_votes: 91323,
      percentage_of_votes: 10.86,
    },
    {
      serial_no: 4,
      candidate: "ADV. RUSSEL JOY",
      party: "Bahujan Samaj Party",
      evm_votes: 4397,
      postal_votes: 40,
      total_votes: 4437,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 5,
      candidate: "JOMON JOHN",
      party: "Independent",
      evm_votes: 1764,
      postal_votes: 55,
      total_votes: 1819,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 6,
      candidate: "SAJI @ SHAJI",
      party: "Viduthalai Chiruthaigal Katchi",
      evm_votes: 1493,
      postal_votes: 15,
      total_votes: 1508,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 7,
      candidate: "P.K. SAJEEVAN",
      party: "Independent",
      evm_votes: 1025,
      postal_votes: 9,
      total_votes: 1034,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9468,
      postal_votes: 51,
      total_votes: 9519,
      percentage_of_votes: 1.13,
    },
  ],
  total: {
    evm_votes: 831914,
    postal_votes: 8743,
    total_votes: 840657,
  },
};
export const s1115 = {
  cs_name: "Alappuzha",
  cs_shortname: "alappuzha",
  cs_code: "s1115",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "K. C VENUGOPAL",
      party: "Indian National Congress",
      evm_votes: 398246,
      postal_votes: 6314,
      total_votes: 404560,
      percentage_of_votes: 38.21,
    },
    {
      serial_no: 2,
      candidate: "A. M ARIFF",
      party: "Communist Party of India (Marxist)",
      evm_votes: 335596,
      postal_votes: 5451,
      total_votes: 341047,
      percentage_of_votes: 32.21,
    },
    {
      serial_no: 3,
      candidate: "SOBHA SURENDRAN",
      party: "Bharatiya Janata Party",
      evm_votes: 295841,
      postal_votes: 3807,
      total_votes: 299648,
      percentage_of_votes: 28.3,
    },
    {
      serial_no: 4,
      candidate: "MURALEEDHARAN KONCHERILLAM",
      party: "Bahujan Samaj Party",
      evm_votes: 1578,
      postal_votes: 48,
      total_votes: 1626,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 5,
      candidate: "ARJUNAN",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1301,
      postal_votes: 18,
      total_votes: 1319,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 6,
      candidate: "VAYALAR RAJEEVAN",
      party: "Bahujan Dravida Party",
      evm_votes: 674,
      postal_votes: 1,
      total_votes: 675,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 7,
      candidate: "ADV.K.M SHAJAHAN",
      party: "Independent",
      evm_votes: 627,
      postal_votes: 55,
      total_votes: 682,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 8,
      candidate: "SHAJAHAN V.A. S/O ABDUL AZEES",
      party: "Independent",
      evm_votes: 553,
      postal_votes: 23,
      total_votes: 576,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 9,
      candidate: "SATHEESH SHENOI",
      party: "Independent",
      evm_votes: 473,
      postal_votes: 9,
      total_votes: 482,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 10,
      candidate: "JAYAKRISHNAN P",
      party: "Independent",
      evm_votes: 329,
      postal_votes: 56,
      total_votes: 385,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 11,
      candidate: "JYOTHI ABRAHAM",
      party: "Independent",
      evm_votes: 328,
      postal_votes: 10,
      total_votes: 338,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7252,
      postal_votes: 113,
      total_votes: 7365,
      percentage_of_votes: 0.7,
    },
  ],
  total: {
    evm_votes: 1042798,
    postal_votes: 15905,
    total_votes: 1058703,
  },
};
export const s1116 = {
  cs_name: "Mavelikkara",
  cs_shortname: "mavelikkara",
  cs_code: "s1116",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "KODIKUNNIL SURESH",
      party: "Indian National Congress",
      evm_votes: 362209,
      postal_votes: 7307,
      total_votes: 369516,
      percentage_of_votes: 41.29,
    },
    {
      serial_no: 2,
      candidate: "ADV ARUN KUMAR C A",
      party: "Communist Party of India",
      evm_votes: 352708,
      postal_votes: 5940,
      total_votes: 358648,
      percentage_of_votes: 40.07,
    },
    {
      serial_no: 3,
      candidate: "BAIJU KALASALA",
      party: "Bharath Dharma Jana Sena",
      evm_votes: 140830,
      postal_votes: 2154,
      total_votes: 142984,
      percentage_of_votes: 15.98,
    },
    {
      serial_no: 4,
      candidate: "C MONICHEN",
      party: "Independent",
      evm_votes: 4893,
      postal_votes: 81,
      total_votes: 4974,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 5,
      candidate: "SANTHOSH PALATHUMPADAN",
      party: "Bahujan Samaj Party",
      evm_votes: 4183,
      postal_votes: 123,
      total_votes: 4306,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 6,
      candidate: "SURESH NOORANAD",
      party: "Ambedkarite Party of India",
      evm_votes: 1441,
      postal_votes: 64,
      total_votes: 1505,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 7,
      candidate: "K BIMALJI",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1336,
      postal_votes: 34,
      total_votes: 1370,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 8,
      candidate: "MANTHARA VELAYUDHAN",
      party: "Independent",
      evm_votes: 910,
      postal_votes: 19,
      total_votes: 929,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 9,
      candidate: "KOZHUVASSERIL SURESH",
      party: "Independent",
      evm_votes: 836,
      postal_votes: 20,
      total_votes: 856,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9640,
      postal_votes: 243,
      total_votes: 9883,
      percentage_of_votes: 1.1,
    },
  ],
  total: {
    evm_votes: 878986,
    postal_votes: 15985,
    total_votes: 894971,
  },
};
export const s1117 = {
  cs_name: "Pathanamthitta",
  cs_shortname: "pathanamthitta",
  cs_code: "s1117",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "ANTO ANTONY",
      party: "Indian National Congress",
      evm_votes: 360957,
      postal_votes: 6666,
      total_votes: 367623,
      percentage_of_votes: 39.98,
    },
    {
      serial_no: 2,
      candidate: "DR.T.M.THOMAS ISSAC",
      party: "Communist Party of India (Marxist)",
      evm_votes: 297888,
      postal_votes: 3616,
      total_votes: 301504,
      percentage_of_votes: 32.79,
    },
    {
      serial_no: 3,
      candidate: "ANIL K.ANTONY",
      party: "Bharatiya Janata Party",
      evm_votes: 231172,
      postal_votes: 3234,
      total_votes: 234406,
      percentage_of_votes: 25.49,
    },
    {
      serial_no: 4,
      candidate: "ADV.P.K.GEETHAKRISHNAN",
      party: "Bahujan Samaj Party",
      evm_votes: 3918,
      postal_votes: 54,
      total_votes: 3972,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 5,
      candidate: "JOY P.MATHEW",
      party: "People's Party of India(secular)",
      evm_votes: 1173,
      postal_votes: 8,
      total_votes: 1181,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 6,
      candidate: "K.C.THOMAS",
      party: "Independent",
      evm_votes: 1080,
      postal_votes: 21,
      total_votes: 1101,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 7,
      candidate: "ADV.HARIKUMAR M.K.",
      party: "Ambedkarite Party of India",
      evm_votes: 735,
      postal_votes: 27,
      total_votes: 762,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 8,
      candidate: "ANOOP V.",
      party: "Independent",
      evm_votes: 557,
      postal_votes: 52,
      total_votes: 609,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8268,
      postal_votes: 143,
      total_votes: 8411,
      percentage_of_votes: 0.91,
    },
  ],
  total: {
    evm_votes: 905748,
    postal_votes: 13821,
    total_votes: 919569,
  },
};
export const s1119 = {
  cs_name: "Attingal",
  cs_shortname: "attingal",
  cs_code: "s1119",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "ADV ADOOR PRAKASH",
      party: "Indian National Congress",
      evm_votes: 323273,
      postal_votes: 4778,
      total_votes: 328051,
      percentage_of_votes: 33.29,
    },
    {
      serial_no: 2,
      candidate: "V JOY",
      party: "Communist Party of India (Marxist)",
      evm_votes: 321715,
      postal_votes: 5652,
      total_votes: 327367,
      percentage_of_votes: 33.22,
    },
    {
      serial_no: 3,
      candidate: "V MURALEEDHARAN",
      party: "Bharatiya Janata Party",
      evm_votes: 307710,
      postal_votes: 4069,
      total_votes: 311779,
      percentage_of_votes: 31.64,
    },
    {
      serial_no: 4,
      candidate: "SURABHI S",
      party: "Bahujan Samaj Party",
      evm_votes: 4485,
      postal_votes: 39,
      total_votes: 4524,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 5,
      candidate: "PRAKASH P L",
      party: "Independent",
      evm_votes: 1762,
      postal_votes: 52,
      total_votes: 1814,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 6,
      candidate: "SANTHOSH K",
      party: "Independent",
      evm_votes: 1146,
      postal_votes: 58,
      total_votes: 1204,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 7,
      candidate: "PRAKASH S",
      party: "Independent",
      evm_votes: 745,
      postal_votes: 66,
      total_votes: 811,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9681,
      postal_votes: 110,
      total_votes: 9791,
      percentage_of_votes: 0.99,
    },
  ],
  total: {
    evm_votes: 970517,
    postal_votes: 14824,
    total_votes: 985341,
  },
};
export const s1120 = {
  cs_name: "Thiruvananthapuram",
  cs_shortname: "thiruvananthapuram",
  cs_code: "s1120",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "SHASHI THAROOR",
      party: "Indian National Congress",
      evm_votes: 353679,
      postal_votes: 4476,
      total_votes: 358155,
      percentage_of_votes: 37.19,
    },
    {
      serial_no: 2,
      candidate: "RAJEEV CHANDRASEKHAR",
      party: "Bharatiya Janata Party",
      evm_votes: 337920,
      postal_votes: 4158,
      total_votes: 342078,
      percentage_of_votes: 35.52,
    },
    {
      serial_no: 3,
      candidate: "PANNIAN RAVINDRAN",
      party: "Communist Party of India",
      evm_votes: 244433,
      postal_votes: 3215,
      total_votes: 247648,
      percentage_of_votes: 25.72,
    },
    {
      serial_no: 4,
      candidate: "ADV RAJENDRAN",
      party: "Bahujan Samaj Party",
      evm_votes: 2315,
      postal_votes: 37,
      total_votes: 2352,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 5,
      candidate: "ADV SHINE LAL M P",
      party: "Independent",
      evm_votes: 1446,
      postal_votes: 37,
      total_votes: 1483,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 6,
      candidate: "J J RUSSELL",
      party: "Independent",
      evm_votes: 1348,
      postal_votes: 17,
      total_votes: 1365,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 7,
      candidate: "S MINI",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1098,
      postal_votes: 11,
      total_votes: 1109,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 8,
      candidate: "M S SUBI",
      party: "Independent",
      evm_votes: 588,
      postal_votes: 11,
      total_votes: 599,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 9,
      candidate: "CHALAI MOHANAN",
      party: "Independent",
      evm_votes: 396,
      postal_votes: 4,
      total_votes: 400,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 10,
      candidate: "NANDAVANAM SUSEELAN",
      party: "Independent",
      evm_votes: 391,
      postal_votes: 29,
      total_votes: 420,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 11,
      candidate: "SHAJU PALIYODU",
      party: "Independent",
      evm_votes: 348,
      postal_votes: 40,
      total_votes: 388,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 12,
      candidate: "SASI KONGAPALLY",
      party: "Independent",
      evm_votes: 228,
      postal_votes: 5,
      total_votes: 233,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6684,
      postal_votes: 69,
      total_votes: 6753,
      percentage_of_votes: 0.7,
    },
  ],
  total: {
    evm_votes: 950874,
    postal_votes: 12109,
    total_votes: 962983,
  },
};
export const s131 = {
  cs_name: "Nandurbar",
  cs_shortname: "nandurbar",
  cs_code: "s131",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "ADV GOWAAL KAGADA PADAVI",
      party: "Indian National Congress",
      evm_votes: 744879,
      postal_votes: 1119,
      total_votes: 745998,
      percentage_of_votes: 53.53,
    },
    {
      serial_no: 2,
      candidate: "DR HEENA VIJAYKUMAR GAVIT",
      party: "Bharatiya Janata Party",
      evm_votes: 585847,
      postal_votes: 1031,
      total_votes: 586878,
      percentage_of_votes: 42.11,
    },
    {
      serial_no: 3,
      candidate: "ANANDA SUKALAL KOLI",
      party: "Bahujan Samaj Party",
      evm_votes: 10628,
      postal_votes: 16,
      total_votes: 10644,
      percentage_of_votes: 0.76,
    },
    {
      serial_no: 4,
      candidate: "SUSHILKUMAR JAHANGIR PAWARA",
      party: "Independent",
      evm_votes: 8878,
      postal_votes: 9,
      total_votes: 8887,
      percentage_of_votes: 0.64,
    },
    {
      serial_no: 5,
      candidate: "NIRMALA VASAVE",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 6923,
      postal_votes: 24,
      total_votes: 6947,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 6,
      candidate: "ROHIDAS GEMAJI VALVI",
      party: "Independent",
      evm_votes: 4941,
      postal_votes: 6,
      total_votes: 4947,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 7,
      candidate: "HEMANT MANSARAM KOLI",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 4062,
      postal_votes: 19,
      total_votes: 4081,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 8,
      candidate: "DEEPAKKUMAR MADHUKAR SHIRSATH",
      party: "Independent",
      evm_votes: 3567,
      postal_votes: 4,
      total_votes: 3571,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 9,
      candidate: "RAVINDRA RANJIT VALVI",
      party: "Bharat Adivasi Party",
      evm_votes: 2608,
      postal_votes: 17,
      total_votes: 2625,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 10,
      candidate: "JALAMSING SUTUM PAWAR",
      party: "Independent",
      evm_votes: 2583,
      postal_votes: 4,
      total_votes: 2587,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 11,
      candidate: "GITANJALI SHASHIKANT KOLI",
      party: "Independent",
      evm_votes: 2372,
      postal_votes: 9,
      total_votes: 2381,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 14108,
      postal_votes: 15,
      total_votes: 14123,
      percentage_of_votes: 1.01,
    },
  ],
  total: {
    evm_votes: 1391396,
    postal_votes: 2273,
    total_votes: 1393669,
  },
};
export const s132 = {
  cs_name: "Dhule",
  cs_shortname: "dhule",
  cs_code: "s132",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "BACHHAV SHOBHA DINESH",
      party: "Indian National Congress",
      evm_votes: 582492,
      postal_votes: 1374,
      total_votes: 583866,
      percentage_of_votes: 47.89,
    },
    {
      serial_no: 2,
      candidate: "BHAMRE SUBHASH RAMRAO",
      party: "Bharatiya Janata Party",
      evm_votes: 577778,
      postal_votes: 2257,
      total_votes: 580035,
      percentage_of_votes: 47.57,
    },
    {
      serial_no: 3,
      candidate: "BHARAT BABURAO JADHAV",
      party: "Independent",
      evm_votes: 19695,
      postal_votes: 18,
      total_votes: 19713,
      percentage_of_votes: 1.62,
    },
    {
      serial_no: 4,
      candidate: "ZAHOOR AHAMAD MOHAMAD YUSUF (JAM JAM)",
      party: "Bahujan Samaj Party",
      evm_votes: 4955,
      postal_votes: 18,
      total_votes: 4973,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 5,
      candidate: "SANJAY RAMESHWAR SHARMA",
      party: "Independent",
      evm_votes: 3741,
      postal_votes: 5,
      total_votes: 3746,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 6,
      candidate: "SHAFEEQUE AHEMAD MD. RAFEEQUE",
      party: "Independent",
      evm_votes: 3706,
      postal_votes: 1,
      total_votes: 3707,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 7,
      candidate: "NAMDEO ROHIDAS YELAVE",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 3659,
      postal_votes: 24,
      total_votes: 3683,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 8,
      candidate: "ADV SACHIN UMAJI NIKAM",
      party: "Independent",
      evm_votes: 3657,
      postal_votes: 3,
      total_votes: 3660,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 9,
      candidate: "RAJ CHAVAN",
      party: "Independent",
      evm_votes: 3443,
      postal_votes: 9,
      total_votes: 3452,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 10,
      candidate: "MOHAMMAD ISMAIL JUMMAN",
      party: "Independent",
      evm_votes: 1084,
      postal_votes: 1,
      total_votes: 1085,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 11,
      candidate: "SURESH JAGANNATH BRAHMANKAR (SURESH BAPU)",
      party: "Independent",
      evm_votes: 1035,
      postal_votes: 3,
      total_votes: 1038,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 12,
      candidate: "PATIL SHIVAJI NATHU",
      party: "All India Forward Bloc",
      evm_votes: 994,
      postal_votes: 7,
      total_votes: 1001,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "SHAIKH MOHAMMAD ZAID SHAMIM AHMED",
      party: "Welfare Party Of India",
      evm_votes: 946,
      postal_votes: 5,
      total_votes: 951,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "MOHAMMAD AAMEEN MOHAMMAD FARUQUE",
      party: "Independent",
      evm_votes: 833,
      postal_votes: 2,
      total_votes: 835,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 15,
      candidate: "MUQEEM MEENA NAGRI",
      party: "Bhim Sena",
      evm_votes: 773,
      postal_votes: 8,
      total_votes: 781,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 16,
      candidate: "IRFAN. MO. ISHAK (NADIR)",
      party: "Independent",
      evm_votes: 763,
      postal_votes: 0,
      total_votes: 763,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 17,
      candidate: "MALAY PRAKASH PATIL",
      party: "Independent",
      evm_votes: 734,
      postal_votes: 2,
      total_votes: 736,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 18,
      candidate: "ABDUL HAFEEZ ABDUL HAQUE",
      party: "Independent",
      evm_votes: 576,
      postal_votes: 1,
      total_votes: 577,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 19,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4661,
      postal_votes: 32,
      total_votes: 4693,
      percentage_of_votes: 0.38,
    },
  ],
  total: {
    evm_votes: 1215525,
    postal_votes: 3770,
    total_votes: 1219295,
  },
};
export const s137 = {
  cs_name: "Amravati",
  cs_shortname: "amravati",
  cs_code: "s137",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "BALWANT BASWANT WANKHADE",
      party: "Indian National Congress",
      evm_votes: 524136,
      postal_votes: 2135,
      total_votes: 526271,
      percentage_of_votes: 44.84,
    },
    {
      serial_no: 2,
      candidate: "NAVNEET RAVI RANA",
      party: "Bharatiya Janata Party",
      evm_votes: 504806,
      postal_votes: 1734,
      total_votes: 506540,
      percentage_of_votes: 43.16,
    },
    {
      serial_no: 3,
      candidate: "DINESH GANESHDAS BUB",
      party: "Prahar Janshakti Party",
      evm_votes: 84985,
      postal_votes: 315,
      total_votes: 85300,
      percentage_of_votes: 7.27,
    },
    {
      serial_no: 4,
      candidate: "ANANDRAJ YASHWANT AMBEDKAR",
      party: "Republican Sena",
      evm_votes: 18713,
      postal_votes: 80,
      total_votes: 18793,
      percentage_of_votes: 1.6,
    },
    {
      serial_no: 5,
      candidate: "KISHOR BHIMRAO LABADE",
      party: "Independent",
      evm_votes: 4522,
      postal_votes: 2,
      total_votes: 4524,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 6,
      candidate: "SANJAYKUMAR FATTESINGH GADGE",
      party: "Bahujan Samaj Party",
      evm_votes: 4485,
      postal_votes: 28,
      total_votes: 4513,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 7,
      candidate: "SUMITRA SAHEBRAO GAIKWAD",
      party: "Independent",
      evm_votes: 2686,
      postal_votes: 4,
      total_votes: 2690,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 8,
      candidate: "ARUN YASHWANTRAO BHAGAT",
      party: "Independent",
      evm_votes: 2086,
      postal_votes: 2,
      total_votes: 2088,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 9,
      candidate: "KISHOR DNYANESHWAR TAYDE",
      party: "Independent",
      evm_votes: 2070,
      postal_votes: 2,
      total_votes: 2072,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 10,
      candidate: "SURAJ DHANRAJ NAGDVANE",
      party: "Independent",
      evm_votes: 1815,
      postal_votes: 3,
      total_votes: 1818,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 11,
      candidate: "GAUTAM ALIAS ANANTA RAMDAS INGALE",
      party: "Independent",
      evm_votes: 1570,
      postal_votes: 0,
      total_votes: 1570,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 12,
      candidate: "PRAMOD RAMKRUSHNA CHOURPAGAR",
      party: "Independent",
      evm_votes: 1306,
      postal_votes: 1,
      total_votes: 1307,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 13,
      candidate: "ANIL THAWARE ALIAS DR. ANILKUMAR NAGBUDDHA",
      party: "Independent",
      evm_votes: 1206,
      postal_votes: 0,
      total_votes: 1206,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 14,
      candidate: "SURESH PUNDLIK MESHRAM",
      party: "Independent",
      evm_votes: 1051,
      postal_votes: 1,
      total_votes: 1052,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 15,
      candidate: "SATISH YASHWANTRAO GEDAM",
      party: "Independent",
      evm_votes: 942,
      postal_votes: 1,
      total_votes: 943,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 16,
      candidate: "MANOHAR KRUSHNAJI KURHADE",
      party: "Independent",
      evm_votes: 871,
      postal_votes: 0,
      total_votes: 871,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 17,
      candidate: "DIGAMBAR WAMAN BHAGAT",
      party: "Naki Bharatiya Ekta Party",
      evm_votes: 760,
      postal_votes: 2,
      total_votes: 762,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 18,
      candidate: "ENGI. AVINASH HARISHCHANDRA DHANAWATE",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 725,
      postal_votes: 5,
      total_votes: 730,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 19,
      candidate: "HIMMAT BHIMRAO DHOLE",
      party: "Independent",
      evm_votes: 668,
      postal_votes: 2,
      total_votes: 670,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 20,
      candidate: "SUSHAMA GAJANAN AWACHAR",
      party: "Jay Vidarbha Party",
      evm_votes: 656,
      postal_votes: 3,
      total_votes: 659,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 21,
      candidate: "GANESH NANAJI RAMTEKE",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 624,
      postal_votes: 5,
      total_votes: 629,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 22,
      candidate: "MANKAR DNYANESHWAR KASHIRAO",
      party: "Independent",
      evm_votes: 538,
      postal_votes: 1,
      total_votes: 539,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 23,
      candidate: "TARA SURESH WANKHADE",
      party: "Independent",
      evm_votes: 526,
      postal_votes: 1,
      total_votes: 527,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 24,
      candidate: "SHRIKRUSHNA SAKHARAMJI KSHIRSAGAR",
      party: "Independent",
      evm_votes: 508,
      postal_votes: 2,
      total_votes: 510,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 25,
      candidate: "ADV. RAJU MADHUKARRAO KALANE",
      party: "Bahujan Bharat Party",
      evm_votes: 495,
      postal_votes: 0,
      total_votes: 495,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 26,
      candidate: "BHARAT CHAMPATRAO YANGAD",
      party: "Independent",
      evm_votes: 441,
      postal_votes: 0,
      total_votes: 441,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 27,
      candidate: "VARSHA BHAGAWANTRAO BHAGAT",
      party: "Independent",
      evm_votes: 440,
      postal_votes: 3,
      total_votes: 443,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 28,
      candidate: "NARENDRA BABULAL KATHANE",
      party: "Desh Janhit Party",
      evm_votes: 422,
      postal_votes: 0,
      total_votes: 422,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 29,
      candidate: "PRABHAKAR PANDURANG BHATKAR",
      party: "Independent",
      evm_votes: 365,
      postal_votes: 0,
      total_votes: 365,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 30,
      candidate: "ADV. PRUTHVISAMRAT MUKINDRAO DIPWANSH",
      party: "Independent",
      evm_votes: 335,
      postal_votes: 2,
      total_votes: 337,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 31,
      candidate: "BHAURAO SAMPATRAO WANKHADE",
      party: "All India Forward Bloc",
      evm_votes: 328,
      postal_votes: 1,
      total_votes: 329,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 32,
      candidate: "GHAZI SAADUDDIN ZAHEER AHMED",
      party: "Rashtriya Ulama Council",
      evm_votes: 325,
      postal_votes: 6,
      total_votes: 331,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 33,
      candidate: "RAVI GUNWANTRAO WANKHADE",
      party: "Independent",
      evm_votes: 286,
      postal_votes: 2,
      total_votes: 288,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 34,
      candidate: "SONALI SANJAY MESHRAM",
      party: "Independent",
      evm_votes: 270,
      postal_votes: 2,
      total_votes: 272,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 35,
      candidate: "SANDEEP BABULAL MESHRAM",
      party: "Independent",
      evm_votes: 257,
      postal_votes: 0,
      total_votes: 257,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 36,
      candidate: "RAJESH TULSHIRAM KHADE",
      party: "Independent",
      evm_votes: 239,
      postal_votes: 0,
      total_votes: 239,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 37,
      candidate: "RAJU MAHADEVRAO SONONE",
      party: "Independent",
      evm_votes: 230,
      postal_votes: 2,
      total_votes: 232,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 38,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 2517,
      postal_votes: 27,
      total_votes: 2544,
      percentage_of_votes: 0.22,
    },
  ],
  total: {
    evm_votes: 1169205,
    postal_votes: 4374,
    total_votes: 1173579,
  },
};
export const s139 = {
  cs_name: "Ramtek",
  cs_shortname: "ramtek",
  cs_code: "s139",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "Shyamkumar (Babalu) Daulat Barve",
      party: "Indian National Congress",
      evm_votes: 611894,
      postal_votes: 1131,
      total_votes: 613025,
      percentage_of_votes: 48.94,
    },
    {
      serial_no: 2,
      candidate: "RAJU DEONATH PARVE",
      party: "Shiv Sena",
      evm_votes: 535234,
      postal_votes: 1023,
      total_votes: 536257,
      percentage_of_votes: 42.81,
    },
    {
      serial_no: 3,
      candidate: "SANDIP SAHADEO MESHRAM",
      party: "Bahujan Samaj Party",
      evm_votes: 26003,
      postal_votes: 95,
      total_votes: 26098,
      percentage_of_votes: 2.08,
    },
    {
      serial_no: 4,
      candidate: "KISHORE UTTAMRAO GAJBHIYE",
      party: "Independent",
      evm_votes: 24324,
      postal_votes: 59,
      total_votes: 24383,
      percentage_of_votes: 1.95,
    },
    {
      serial_no: 5,
      candidate: "AJAY BAKARAM CHAVAN",
      party: "Independent",
      evm_votes: 6021,
      postal_votes: 5,
      total_votes: 6026,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 6,
      candidate: "SANVIDHAN G LOKHANDE",
      party: "Baliraja Party",
      evm_votes: 5810,
      postal_votes: 4,
      total_votes: 5814,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 7,
      candidate: "ROSHANI ATUL GAJBHIYE",
      party: "Gondvana Gantantra Party",
      evm_votes: 5108,
      postal_votes: 29,
      total_votes: 5137,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 8,
      candidate: "GOWARDHAN DEORAO KUMBHARE",
      party: "Viro Ke Vir Indian Party",
      evm_votes: 3750,
      postal_votes: 16,
      total_votes: 3766,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 9,
      candidate: "ARVIND SHIVAJI TANDEKAR",
      party: "Independent",
      evm_votes: 3208,
      postal_votes: 7,
      total_votes: 3215,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 10,
      candidate: "SANJAY VASANTRAO BORKAR",
      party: "Maharashtra Vikas Aghadi",
      evm_votes: 1983,
      postal_votes: 9,
      total_votes: 1992,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 11,
      candidate: "SHANKAR CHINTAMAN CHAHANDE",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 1858,
      postal_votes: 26,
      total_votes: 1884,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 12,
      candidate: "ADV BHIMRAO SHENDE",
      party: "Bahujan Republican Socialist Party",
      evm_votes: 1811,
      postal_votes: 7,
      total_votes: 1818,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 13,
      candidate: "GAIKWAD MANJUSHA SACHIN",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 1760,
      postal_votes: 13,
      total_votes: 1773,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 14,
      candidate: "ASHISH BHAURAO SARODE",
      party: "Bhim Sena",
      evm_votes: 1600,
      postal_votes: 20,
      total_votes: 1620,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 15,
      candidate: "PRAMOD SHRIPAT KHOBRAGADE",
      party: "Ambedkarite Party of India",
      evm_votes: 1337,
      postal_votes: 3,
      total_votes: 1340,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 16,
      candidate: "UMESH RAJU KHADSE",
      party: "Rashtra Samarpan Party",
      evm_votes: 1223,
      postal_votes: 6,
      total_votes: 1229,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 17,
      candidate: "SIDDHARTH GADIJI PATIL",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 975,
      postal_votes: 2,
      total_votes: 977,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 18,
      candidate: "PREMKUMAR DASHRATH GAJBHARE",
      party: "Independent",
      evm_votes: 921,
      postal_votes: 4,
      total_votes: 925,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 19,
      candidate: "RIDDHESHWAR ANANDRAO BELEY",
      party: "Republican Party of India (A)",
      evm_votes: 908,
      postal_votes: 5,
      total_votes: 913,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 20,
      candidate: "BHOJRAJ TUKARAM SARODE",
      party: "Jay Vidarbha Party",
      evm_votes: 881,
      postal_votes: 7,
      total_votes: 888,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 21,
      candidate: "VILAS SHRIPAT KHADSE",
      party: "Independent",
      evm_votes: 857,
      postal_votes: 3,
      total_votes: 860,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 22,
      candidate: "SUBHASH LOKHANDE",
      party: "Independent",
      evm_votes: 849,
      postal_votes: 1,
      total_votes: 850,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 23,
      candidate: "KARTIK GENDLALJI DOKE",
      party: "Independent",
      evm_votes: 844,
      postal_votes: 3,
      total_votes: 847,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 24,
      candidate: "ADV ULHAS SHALIKRAM DUPARE",
      party: "Independent",
      evm_votes: 780,
      postal_votes: 1,
      total_votes: 781,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 25,
      candidate: "LAROKAR SURESH PUNDLIK",
      party: "Independent",
      evm_votes: 693,
      postal_votes: 5,
      total_votes: 698,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 26,
      candidate: "SUNIL UTTAMRAO SALVE",
      party: "Independent",
      evm_votes: 649,
      postal_votes: 2,
      total_votes: 651,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 27,
      candidate: "GOWARDHAN NAMDEO SOMDEVE",
      party: "Independent",
      evm_votes: 509,
      postal_votes: 2,
      total_votes: 511,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 28,
      candidate: "VILAS GANESH ZODAPE",
      party: "Independent",
      evm_votes: 402,
      postal_votes: 5,
      total_votes: 407,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 29,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7769,
      postal_votes: 58,
      total_votes: 7827,
      percentage_of_votes: 0.62,
    },
  ],
  total: {
    evm_votes: 1249961,
    postal_votes: 2551,
    total_votes: 1252512,
  },
};
export const s1311 = {
  cs_name: "Bhandara_Gondiya",
  cs_shortname: "bhandaragondiya",
  cs_code: "s1311",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "DR. PRASHANT YADAORAO PADOLE",
      party: "Indian National Congress",
      evm_votes: 581678,
      postal_votes: 5735,
      total_votes: 587413,
      percentage_of_votes: 47.56,
    },
    {
      serial_no: 2,
      candidate: "SUNIL BABURAO MENDHE",
      party: "Bharatiya Janata Party",
      evm_votes: 546222,
      postal_votes: 3811,
      total_votes: 550033,
      percentage_of_votes: 44.53,
    },
    {
      serial_no: 3,
      candidate: "KUMBHALKAR SANJAY BHAIYY",
      party: "Bahujan Samaj Party",
      evm_votes: 25104,
      postal_votes: 358,
      total_votes: 25462,
      percentage_of_votes: 2.06,
    },
    {
      serial_no: 4,
      candidate: "SANJAY GAJANAN KEWAT",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 24528,
      postal_votes: 330,
      total_votes: 24858,
      percentage_of_votes: 2.01,
    },
    {
      serial_no: 5,
      candidate: "SEWAKBHAU NIRDHAN WAGHAYE",
      party: "Independent",
      evm_votes: 12899,
      postal_votes: 204,
      total_votes: 13103,
      percentage_of_votes: 1.06,
    },
    {
      serial_no: 6,
      candidate: "AJAYKUMAR BHARTIYA",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 3998,
      postal_votes: 59,
      total_votes: 4057,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 7,
      candidate: "SUMIT VIJAY PANDE",
      party: "Independent",
      evm_votes: 3719,
      postal_votes: 0,
      total_votes: 3719,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 8,
      candidate: "VIRENDRAKUMAR KASTURCHAND JAISWAL",
      party: "Independent",
      evm_votes: 2382,
      postal_votes: 7,
      total_votes: 2389,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 9,
      candidate: "DEVILAL SUKHRAM NEPALE",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 2000,
      postal_votes: 9,
      total_votes: 2009,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 10,
      candidate: "DR. AKASH MADHUKAR JIBHAKATE",
      party: "Independent",
      evm_votes: 1972,
      postal_votes: 9,
      total_votes: 1981,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 11,
      candidate: "VILAS BABURAO LENDE",
      party: "Lok Swarajya Party",
      evm_votes: 1624,
      postal_votes: 25,
      total_votes: 1649,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 12,
      candidate: "VILAS JIYALAL RAUT",
      party: "Independent",
      evm_votes: 1611,
      postal_votes: 5,
      total_votes: 1616,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 13,
      candidate: "BENIRAM RAMCHAND FULBANDHE",
      party: "Independent",
      evm_votes: 1482,
      postal_votes: 15,
      total_votes: 1497,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "PRADEEP DHOBALE",
      party: "Independent",
      evm_votes: 1163,
      postal_votes: 33,
      total_votes: 1196,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "SURYAKIRAN ISHWAR NANDAGAWALI",
      party: "Independent",
      evm_votes: 1132,
      postal_votes: 30,
      total_votes: 1162,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 16,
      candidate: "ITWALE SHARAD MARTAND",
      party: "Independent",
      evm_votes: 1020,
      postal_votes: 8,
      total_votes: 1028,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 17,
      candidate: "TULSIRAM URKUDA GEDAM",
      party: "Independent",
      evm_votes: 831,
      postal_votes: 11,
      total_votes: 842,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "CHAITRAM DASHARATH KOKASE",
      party: "Independent",
      evm_votes: 780,
      postal_votes: 11,
      total_votes: 791,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 19,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10106,
      postal_votes: 162,
      total_votes: 10268,
      percentage_of_votes: 0.83,
    },
  ],
  total: {
    evm_votes: 1224251,
    postal_votes: 10822,
    total_votes: 1235073,
  },
};
export const s1312 = {
  cs_name: "Gadchiroli_-_Chimur",
  cs_shortname: "gadchirolichimur",
  cs_code: "s1312",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "DR. KIRSAN NAMDEO",
      party: "Indian National Congress",
      evm_votes: 614610,
      postal_votes: 3182,
      total_votes: 617792,
      percentage_of_votes: 52.97,
    },
    {
      serial_no: 2,
      candidate: "ASHOK MAHADEORAO NETE",
      party: "Bharatiya Janata Party",
      evm_votes: 474376,
      postal_votes: 1720,
      total_votes: 476096,
      percentage_of_votes: 40.82,
    },
    {
      serial_no: 3,
      candidate: "GONNADE YOGESH NAMDEORAO",
      party: "Bahujan Samaj Party",
      evm_votes: 18930,
      postal_votes: 125,
      total_votes: 19055,
      percentage_of_votes: 1.63,
    },
    {
      serial_no: 4,
      candidate: "HITESH PANDURANG MADAVI",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 15824,
      postal_votes: 98,
      total_votes: 15922,
      percentage_of_votes: 1.37,
    },
    {
      serial_no: 5,
      candidate: "VINOD GURUDAS MADAVI",
      party: "Independent",
      evm_votes: 6107,
      postal_votes: 19,
      total_votes: 6126,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 6,
      candidate: "KODAPE VILAS SHAMARAO",
      party: "Independent",
      evm_votes: 4396,
      postal_votes: 6,
      total_votes: 4402,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 7,
      candidate: "SUHAS UMESH KUMRE",
      party: "Bhim Sena",
      evm_votes: 2851,
      postal_votes: 21,
      total_votes: 2872,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 8,
      candidate: "KARAN SURESH SAYAM",
      party: "Independent",
      evm_votes: 2772,
      postal_votes: 17,
      total_votes: 2789,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 9,
      candidate: "BARIKRAO DHARMAJI MADAVI",
      party: "Bahujan Republican Socialist Party",
      evm_votes: 2527,
      postal_votes: 28,
      total_votes: 2555,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 10,
      candidate: "DHIRAJ PURUSHOTTAM SHEDMAKE",
      party: "Janseva Gondwana Party",
      evm_votes: 2141,
      postal_votes: 33,
      total_votes: 2174,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 16507,
      postal_votes: 70,
      total_votes: 16577,
      percentage_of_votes: 1.42,
    },
  ],
  total: {
    evm_votes: 1161041,
    postal_votes: 5319,
    total_votes: 1166360,
  },
};
export const s1313 = {
  cs_name: "Chandrapur",
  cs_shortname: "chandrapur",
  cs_code: "s1313",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "DHANORKAR PRATIBHA SURESH ALIAS BALUBHAU",
      party: "Indian National Congress",
      evm_votes: 716635,
      postal_votes: 1775,
      total_votes: 718410,
      percentage_of_votes: 57.88,
    },
    {
      serial_no: 2,
      candidate: "MUNGANTIWAR SUDHIR SACCHIDANAND",
      party: "Bharatiya Janata Party",
      evm_votes: 456943,
      postal_votes: 1061,
      total_votes: 458004,
      percentage_of_votes: 36.9,
    },
    {
      serial_no: 3,
      candidate: "BELE RAJESH WARLUJI",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 21934,
      postal_votes: 46,
      total_votes: 21980,
      percentage_of_votes: 1.77,
    },
    {
      serial_no: 4,
      candidate: "RAJENDRA HARISHCHANDRA RAMTEKE",
      party: "Bahujan Samaj Party",
      evm_votes: 9145,
      postal_votes: 43,
      total_votes: 9188,
      percentage_of_votes: 0.74,
    },
    {
      serial_no: 5,
      candidate: "SANJAY NILKANTH GAWANDE",
      party: "Independent",
      evm_votes: 5087,
      postal_votes: 1,
      total_votes: 5088,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 6,
      candidate: "DIWAKAR HARIJI URADE",
      party: "Independent",
      evm_votes: 3221,
      postal_votes: 3,
      total_votes: 3224,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 7,
      candidate: "NAMDEO MANIKRAO SHEDMAKE",
      party: "Gondvana Gantantra Party",
      evm_votes: 2238,
      postal_votes: 18,
      total_votes: 2256,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "SEWAKDAS KAWDUJI BARKE",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 1997,
      postal_votes: 1,
      total_votes: 1998,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "AWACHIT SHAMRAO SAYAM",
      party: "Janseva Gondwana Party",
      evm_votes: 1886,
      postal_votes: 20,
      total_votes: 1906,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "MILIND PRALHAD DAHIWALE",
      party: "Independent",
      evm_votes: 1759,
      postal_votes: 2,
      total_votes: 1761,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "ASHOK RANAJI RATHOD",
      party: "Jay Vidarbha Party",
      evm_votes: 1660,
      postal_votes: 10,
      total_votes: 1670,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 12,
      candidate: "VIKAS UTTAMRAO LASANTE",
      party: "Sanman Rajkiya Paksha",
      evm_votes: 1518,
      postal_votes: 2,
      total_votes: 1520,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "VIDYASAGAR KALIDAS KASARLAWAR",
      party: "Bhim Sena",
      evm_votes: 1426,
      postal_votes: 0,
      total_votes: 1426,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 14,
      candidate: "VANITA JITENDRA RAUT",
      party: "Akhil Bharatiya Manavata Paksha",
      evm_votes: 1054,
      postal_votes: 3,
      total_votes: 1057,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 15,
      candidate: "PURNIMA DILIP GHONMODE",
      party: "Bahujan Republican Socialist Party",
      evm_votes: 967,
      postal_votes: 6,
      total_votes: 973,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10821,
      postal_votes: 22,
      total_votes: 10843,
      percentage_of_votes: 0.87,
    },
  ],
  total: {
    evm_votes: 1238291,
    postal_votes: 3013,
    total_votes: 1241304,
  },
};
export const s1316 = {
  cs_name: "Nanded",
  cs_shortname: "nanded",
  cs_code: "s1316",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "CHAVAN VASANTRAO BALWANTRAO",
      party: "Indian National Congress",
      evm_votes: 526865,
      postal_votes: 2029,
      total_votes: 528894,
      percentage_of_votes: 46.88,
    },
    {
      serial_no: 2,
      candidate: "CHIKHALIKAR PRATAPRAO GOVINDRAO",
      party: "Bharatiya Janata Party",
      evm_votes: 467452,
      postal_votes: 2000,
      total_votes: 469452,
      percentage_of_votes: 41.61,
    },
    {
      serial_no: 3,
      candidate: "AVINASH VISHWANATH BHOSIKAR",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 92090,
      postal_votes: 422,
      total_votes: 92512,
      percentage_of_votes: 8.2,
    },
    {
      serial_no: 4,
      candidate: "PANDURANG RAMA ADGULWAR",
      party: "Bahujan Samaj Party",
      evm_votes: 6879,
      postal_votes: 22,
      total_votes: 6901,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 5,
      candidate: "NIKHIL LAXMANRAO GARJE",
      party: "Independent",
      evm_votes: 4320,
      postal_votes: 5,
      total_votes: 4325,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 6,
      candidate: "NAGESH SAMBHAJI GAIKWAD",
      party: "Independent",
      evm_votes: 3316,
      postal_votes: 2,
      total_votes: 3318,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 7,
      candidate: "BHASKAR CHAMPATRAO DOIFODE",
      party: "Independent",
      evm_votes: 2078,
      postal_votes: 2,
      total_votes: 2080,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "SUSHILA NILKANTHRAO PAWAR",
      party: "Samnak Janta Party",
      evm_votes: 1873,
      postal_votes: 17,
      total_votes: 1890,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 9,
      candidate: "ABDUL RAIS AHEMAD",
      party: "Desh Janhit Party",
      evm_votes: 1508,
      postal_votes: 2,
      total_votes: 1510,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 10,
      candidate: "DEVIDAS GOVINDRAO INGLE",
      party: "Independent",
      evm_votes: 1466,
      postal_votes: 5,
      total_votes: 1471,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "KALPANA SANJAY GAIKWAD",
      party: "Independent",
      evm_votes: 1305,
      postal_votes: 5,
      total_votes: 1310,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "KAUSAR SULTANA",
      party: "Indian National League",
      evm_votes: 1211,
      postal_votes: 4,
      total_votes: 1215,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 13,
      candidate: "SAHEBRAO BHIVA GAJBHARE",
      party: "Independent",
      evm_votes: 1189,
      postal_votes: 4,
      total_votes: 1193,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 14,
      candidate: "JAGDISH LAXMAN POTRE",
      party: "Independent",
      evm_votes: 960,
      postal_votes: 3,
      total_votes: 963,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 15,
      candidate: "GAJANAN DATTRAMJI DHUMAL",
      party: "Independent",
      evm_votes: 948,
      postal_votes: 2,
      total_votes: 950,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 16,
      candidate: "LAXMAN NAGORAO PATIL",
      party: "Independent",
      evm_votes: 921,
      postal_votes: 2,
      total_votes: 923,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 17,
      candidate: "MAHARUDRA KESHAV POPLAITKAR",
      party: "Independent",
      evm_votes: 911,
      postal_votes: 2,
      total_votes: 913,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 18,
      candidate: "RUKMINBAI SHANKARRAO GITE",
      party: "Bharatiya Praja Surajya Paksha",
      evm_votes: 897,
      postal_votes: 6,
      total_votes: 903,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 19,
      candidate: "HARI PIRAJI BOYALE",
      party: "Bahujan Bharat Party",
      evm_votes: 877,
      postal_votes: 1,
      total_votes: 878,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 20,
      candidate: "RATHOD SURESH GOPINATH",
      party: "Independent",
      evm_votes: 806,
      postal_votes: 2,
      total_votes: 808,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 21,
      candidate: "KADAM SURAJ DEVENDRA",
      party: "Independent",
      evm_votes: 796,
      postal_votes: 3,
      total_votes: 799,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 22,
      candidate: "RAHUL SURYAKANT YANGADE",
      party: "Bahujan Mukti Party",
      evm_votes: 734,
      postal_votes: 3,
      total_votes: 737,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 23,
      candidate: "DNYANESHWAR RAOSAHEB KAPATE",
      party: "Independent",
      evm_votes: 679,
      postal_votes: 2,
      total_votes: 681,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 24,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3579,
      postal_votes: 49,
      total_votes: 3628,
      percentage_of_votes: 0.32,
    },
  ],
  total: {
    evm_votes: 1123660,
    postal_votes: 4594,
    total_votes: 1128254,
  },
};
export const s1318 = {
  cs_name: "Jalna",
  cs_shortname: "jalna",
  cs_code: "s1318",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "KALYAN VAIJINATHRAO KALE",
      party: "Indian National Congress",
      evm_votes: 605504,
      postal_votes: 2393,
      total_votes: 607897,
      percentage_of_votes: 44.59,
    },
    {
      serial_no: 2,
      candidate: "DANVE RAOSAHEB DADARAO",
      party: "Bharatiya Janata Party",
      evm_votes: 495809,
      postal_votes: 2130,
      total_votes: 497939,
      percentage_of_votes: 36.52,
    },
    {
      serial_no: 3,
      candidate: "MANGESH SANJAY SABLE",
      party: "Independent",
      evm_votes: 155335,
      postal_votes: 595,
      total_votes: 155930,
      percentage_of_votes: 11.44,
    },
    {
      serial_no: 4,
      candidate: "BAKALE PRABHAKAR DEVGAN",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 37678,
      postal_votes: 132,
      total_votes: 37810,
      percentage_of_votes: 2.77,
    },
    {
      serial_no: 5,
      candidate: "BABASAHEB SANTUKRAO SHELKE",
      party: "Samata Party",
      evm_votes: 9564,
      postal_votes: 6,
      total_votes: 9570,
      percentage_of_votes: 0.7,
    },
    {
      serial_no: 6,
      candidate: "CHABUKSWAR RAHUL NIRANJAN",
      party: "Independent",
      evm_votes: 6330,
      postal_votes: 7,
      total_votes: 6337,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 7,
      candidate: "NIVRUTTI VISHWNATH BANSODE",
      party: "Bahujan Samaj Party",
      evm_votes: 5974,
      postal_votes: 34,
      total_votes: 6008,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 8,
      candidate: "DIPAK BHIMRAO BORHADE",
      party: "Samnak Janta Party",
      evm_votes: 5498,
      postal_votes: 24,
      total_votes: 5522,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 9,
      candidate: "TANAJI TUKARAM BHOJANE",
      party: "Independent",
      evm_votes: 4933,
      postal_votes: 15,
      total_votes: 4948,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 10,
      candidate: "KAMBLE MAROTI DASHARATH",
      party: "Independent",
      evm_votes: 3712,
      postal_votes: 16,
      total_votes: 3728,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 11,
      candidate: "RAJENDRA NAMDEO MAGRE",
      party: "Independent",
      evm_votes: 2873,
      postal_votes: 2,
      total_votes: 2875,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 12,
      candidate: "MANOJ NEMINATH KOLTE",
      party: "Independent",
      evm_votes: 2734,
      postal_votes: 3,
      total_votes: 2737,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 13,
      candidate: "MUKESH PRABHAT RATHOD",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 2580,
      postal_votes: 11,
      total_votes: 2591,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 14,
      candidate: "BABASAHEB PATIL SHINDE",
      party: "Independent",
      evm_votes: 2413,
      postal_votes: 4,
      total_votes: 2417,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 15,
      candidate: "KADUBA MHATARBA INGLE",
      party: "Independent",
      evm_votes: 1962,
      postal_votes: 5,
      total_votes: 1967,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 16,
      candidate: "AJHAR ANWAR SAYYAD",
      party: "Independent",
      evm_votes: 1541,
      postal_votes: 5,
      total_votes: 1546,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 17,
      candidate: "AHMAD RAHIM SAIKH BAGWAN",
      party: "Independent",
      evm_votes: 1390,
      postal_votes: 4,
      total_votes: 1394,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 18,
      candidate: "ADV. YOGESH DATTU GULLAPELLI",
      party: "Independent",
      evm_votes: 1339,
      postal_votes: 3,
      total_votes: 1342,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 19,
      candidate: "BHAGWAN SAHEBRAO REGUDE",
      party: "Hindustan Janta Party",
      evm_votes: 1173,
      postal_votes: 1,
      total_votes: 1174,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 20,
      candidate: "DNYANESHWAR DAGDUJI NADE",
      party: "Independent",
      evm_votes: 1142,
      postal_votes: 1,
      total_votes: 1143,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 21,
      candidate: "DHANANJAY RUPRAOJI KAKDE (PATIL)",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 1132,
      postal_votes: 7,
      total_votes: 1139,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 22,
      candidate: "ABDUL RAFIQ ABDUL LATIF",
      party: "Independent",
      evm_votes: 1045,
      postal_votes: 2,
      total_votes: 1047,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 23,
      candidate: "SHYAM RUSTUMRAO SHIRSATH",
      party: "Bahujan Republican Socialist Party",
      evm_votes: 972,
      postal_votes: 2,
      total_votes: 974,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 24,
      candidate: "RATAN ASARAM LANDGE",
      party: "Independent",
      evm_votes: 646,
      postal_votes: 1,
      total_votes: 647,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 25,
      candidate: "VIKAS CHHAGAN LAHANE",
      party: "Independent",
      evm_votes: 644,
      postal_votes: 0,
      total_votes: 644,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 26,
      candidate: "SHAM PAWALS RUPEKAR",
      party: "Independent",
      evm_votes: 544,
      postal_votes: 2,
      total_votes: 546,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 27,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3504,
      postal_votes: 33,
      total_votes: 3537,
      percentage_of_votes: 0.26,
    },
  ],
  total: {
    evm_votes: 1357971,
    postal_votes: 5438,
    total_votes: 1363409,
  },
};
export const s1329 = {
  cs_name: "Mumbai_North_Central",
  cs_shortname: "mumbainorthcentral",
  cs_code: "s1329",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "GAIKWAD VARSHA EKNATH",
      party: "Indian National Congress",
      evm_votes: 444098,
      postal_votes: 1447,
      total_votes: 445545,
      percentage_of_votes: 48.93,
    },
    {
      serial_no: 2,
      candidate: "ADV UJWAL NIKAM",
      party: "Bharatiya Janata Party",
      evm_votes: 427387,
      postal_votes: 1644,
      total_votes: 429031,
      percentage_of_votes: 47.12,
    },
    {
      serial_no: 3,
      candidate: "SANTOSH GANPAT AMBULGE",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 8233,
      postal_votes: 55,
      total_votes: 8288,
      percentage_of_votes: 0.91,
    },
    {
      serial_no: 4,
      candidate: "AYYUB AMIN HUNGUND",
      party: "Bahujan Samaj Party",
      evm_votes: 3229,
      postal_votes: 14,
      total_votes: 3243,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 5,
      candidate: "ANSON THOMAS",
      party: "People's Party of India(secular)",
      evm_votes: 2207,
      postal_votes: 4,
      total_votes: 2211,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 6,
      candidate: "RAMZAN ALI CHAUDHARY",
      party: "All India Majlis-E-Ittehadul Muslimeen",
      evm_votes: 1521,
      postal_votes: 10,
      total_votes: 1531,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 7,
      candidate: "AJAZ MOHAMMAD SAFI KHAN",
      party: "Independent",
      evm_votes: 1041,
      postal_votes: 0,
      total_votes: 1041,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 8,
      candidate: "HAYATULAH ABDULAH SHAIKH",
      party: "Akhil Bharatiya Muslim League (Secular)",
      evm_votes: 818,
      postal_votes: 3,
      total_votes: 821,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 9,
      candidate: "NAZMAKHATHUN MOHAMMED ZAFAR KHAN",
      party: "Independent",
      evm_votes: 730,
      postal_votes: 2,
      total_votes: 732,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 10,
      candidate: "SHANTARAM S DIGHE",
      party: "Independent",
      evm_votes: 723,
      postal_votes: 10,
      total_votes: 733,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 11,
      candidate: "NARENDRA MISHRA",
      party: "Independent",
      evm_votes: 706,
      postal_votes: 2,
      total_votes: 708,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "ADV ASIF ALI SIDDIQUIE",
      party: "Independent",
      evm_votes: 681,
      postal_votes: 2,
      total_votes: 683,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "HARSHADA BABURAV JADHAV",
      party: "Maharashtra Vikas Aghadi",
      evm_votes: 673,
      postal_votes: 17,
      total_votes: 690,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "RAMA ARUN SABLE",
      party: "Independent",
      evm_votes: 626,
      postal_votes: 10,
      total_votes: 636,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 15,
      candidate: "SANDEEP BHAU RAMCHANDRA JADHAV",
      party: "Independent",
      evm_votes: 619,
      postal_votes: 4,
      total_votes: 623,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 16,
      candidate: "DR GAFFAR IBRAHIM SAYED",
      party: "Independent",
      evm_votes: 584,
      postal_votes: 2,
      total_votes: 586,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 17,
      candidate: "ADV UTTAMKUMAR NAKUL SAJANI SAHU",
      party: "Independent",
      evm_votes: 554,
      postal_votes: 4,
      total_votes: 558,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 18,
      candidate: "KHAN ABBAS AHMED",
      party: "Bahujan Maha Party",
      evm_votes: 427,
      postal_votes: 1,
      total_votes: 428,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 19,
      candidate: "MUSHTAQUE HAIDER SHAIKH",
      party: "Independent",
      evm_votes: 405,
      postal_votes: 0,
      total_votes: 405,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 20,
      candidate: "DR ADV YASHWANT RAMBHAU KASBE",
      party: "Bhartiya Jan Vikas Aaghadi",
      evm_votes: 385,
      postal_votes: 6,
      total_votes: 391,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 21,
      candidate: "KURBAN SHAHADAT HUSAIN",
      party: "Rashtriya Ulama Council",
      evm_votes: 366,
      postal_votes: 1,
      total_votes: 367,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 22,
      candidate: "ABDUL TAHIR ADVOCATE BABLU RAJNIKANT",
      party: "Independent",
      evm_votes: 355,
      postal_votes: 0,
      total_votes: 355,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 23,
      candidate: "SHAUKAT ABDUL RAFIQ KHAN",
      party: "Insaniyat Party",
      evm_votes: 341,
      postal_votes: 4,
      total_votes: 345,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 24,
      candidate: "RAJESH MOHAN LOKHANDE",
      party: "Independent",
      evm_votes: 282,
      postal_votes: 7,
      total_votes: 289,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 25,
      candidate: "YUNUSALI RASHID MULLA",
      party: "Independent",
      evm_votes: 220,
      postal_votes: 0,
      total_votes: 220,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 26,
      candidate: "ADV FEROZ SHAIKH",
      party: "Independent",
      evm_votes: 180,
      postal_votes: 0,
      total_votes: 180,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 27,
      candidate: "MUZAFAR ALI SHAIKH",
      party: "Independent",
      evm_votes: 173,
      postal_votes: 0,
      total_votes: 173,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 28,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9665,
      postal_votes: 84,
      total_votes: 9749,
      percentage_of_votes: 1.07,
    },
  ],
  total: {
    evm_votes: 907229,
    postal_votes: 3333,
    total_votes: 910562,
  },
};
export const s1341 = {
  cs_name: "Latur",
  cs_shortname: "latur",
  cs_code: "s1341",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "DR. KALGE SHIVAJI BANDAPPA",
      party: "Indian National Congress",
      evm_votes: 607030,
      postal_votes: 1991,
      total_votes: 609021,
      percentage_of_votes: 49.15,
    },
    {
      serial_no: 2,
      candidate: "SUDHAKAR TUKARAM SHRANGARE",
      party: "Bharatiya Janata Party",
      evm_votes: 545240,
      postal_votes: 1900,
      total_votes: 547140,
      percentage_of_votes: 44.16,
    },
    {
      serial_no: 3,
      candidate: "NARSINGRAO UDGIRKAR",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 42104,
      postal_votes: 121,
      total_votes: 42225,
      percentage_of_votes: 3.41,
    },
    {
      serial_no: 4,
      candidate: "ATITHI KHANDERAO SURYVANSHI",
      party: "Swarajya Shakti Sena",
      evm_votes: 5150,
      postal_votes: 28,
      total_votes: 5178,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 5,
      candidate: "ALTE VISHWANATH MAHADEV",
      party: "Bahujan Samaj Party",
      evm_votes: 5081,
      postal_votes: 40,
      total_votes: 5121,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 6,
      candidate: "KAMANT MACHHINDRA GUNWANTRAO",
      party: "Bahujan Bharat Party",
      evm_votes: 2249,
      postal_votes: 20,
      total_votes: 2269,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 7,
      candidate: "ABHANG GANGARAM SURYAWANSHI",
      party: "Independent",
      evm_votes: 2102,
      postal_votes: 0,
      total_votes: 2102,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 8,
      candidate: "DATTU SOPAN NARSINGE",
      party: "Independent",
      evm_votes: 2000,
      postal_votes: 2,
      total_votes: 2002,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "DEEPAK KEDAR",
      party: "Independent",
      evm_votes: 1712,
      postal_votes: 14,
      total_votes: 1726,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "UMESH AMBADAS KAMBLE",
      party: "Independent",
      evm_votes: 1683,
      postal_votes: 8,
      total_votes: 1691,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "BANSODE RAGHUNATH WAGHOJI",
      party: "Independent",
      evm_votes: 1596,
      postal_votes: 4,
      total_votes: 1600,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 12,
      candidate: "BHARAT HARIBA NANAWARE",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 1442,
      postal_votes: 7,
      total_votes: 1449,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "SHRIKANT BABURAO HOVAL",
      party: "Bahujan Mukti Party",
      evm_votes: 1380,
      postal_votes: 2,
      total_votes: 1382,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 14,
      candidate: "PRAVIN MADHAV JOHARE",
      party: "Swarajya Sena (Maharashtra)",
      evm_votes: 1268,
      postal_votes: 5,
      total_votes: 1273,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "PANCHSHIL VIKRAM KAMBLE",
      party: "Independent",
      evm_votes: 1232,
      postal_votes: 9,
      total_votes: 1241,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 16,
      candidate: "SHANKAR HARI TADAKHE",
      party: "Baliraja Party",
      evm_votes: 1126,
      postal_votes: 2,
      total_votes: 1128,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 17,
      candidate: "AMOL MALU HANMANTE",
      party: "Independent",
      evm_votes: 1094,
      postal_votes: 5,
      total_votes: 1099,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 18,
      candidate: "ADV. KASBEKAR SHRIDHAR LIMBAJI",
      party: "Rashtriya Sant Sandesh Party",
      evm_votes: 987,
      postal_votes: 7,
      total_votes: 994,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 19,
      candidate: "BALAJI SHESHRAO BANSODE",
      party: "Independent",
      evm_votes: 953,
      postal_votes: 1,
      total_votes: 954,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 20,
      candidate: "BHIKAJI GANGARAM JADHAV",
      party: "Kranti Kari Jai Hind Sena",
      evm_votes: 863,
      postal_votes: 3,
      total_votes: 866,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 21,
      candidate: "VIKAS KONDIBA SHINDE",
      party: "Maharashtra Vikas Aghadi",
      evm_votes: 793,
      postal_votes: 8,
      total_votes: 801,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 22,
      candidate: "LAKHAN RAJARAM KAMBLE",
      party: "Rashtriy Bahujan Party",
      evm_votes: 790,
      postal_votes: 5,
      total_votes: 795,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 23,
      candidate: "BALAJI TUKARAM GAIKWAD",
      party: "Bharat Peoples Sena",
      evm_votes: 679,
      postal_votes: 4,
      total_votes: 683,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 24,
      candidate: "SUDHAKAR TUKARAM SURYAWANSHI",
      party: "Independent",
      evm_votes: 617,
      postal_votes: 3,
      total_votes: 620,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 25,
      candidate: "PAPITA RAOSAHEB RANDIVE",
      party: "Independent",
      evm_votes: 576,
      postal_votes: 5,
      total_votes: 581,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 26,
      candidate: "ADV. PRADIP S. CHINCHOLIKAR",
      party: "Independent",
      evm_votes: 528,
      postal_votes: 5,
      total_votes: 533,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 27,
      candidate: "MUKESH GOVINDRAO GHODAKE",
      party: "Independent",
      evm_votes: 524,
      postal_votes: 1,
      total_votes: 525,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 28,
      candidate: "PANKAJ GOPALRAO WAKHARADAKAR",
      party: "Independent",
      evm_votes: 512,
      postal_votes: 1,
      total_votes: 513,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 29,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3542,
      postal_votes: 25,
      total_votes: 3567,
      percentage_of_votes: 0.29,
    },
  ],
  total: {
    evm_votes: 1234853,
    postal_votes: 4226,
    total_votes: 1239079,
  },
};
export const s1342 = {
  cs_name: "Solapur",
  cs_shortname: "solapur",
  cs_code: "s1342",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "PRANITI SUSHILKUMAR SHINDE",
      party: "Indian National Congress",
      evm_votes: 618350,
      postal_votes: 1875,
      total_votes: 620225,
      percentage_of_votes: 51.49,
    },
    {
      serial_no: 2,
      candidate: "RAM VITTHAL SATPUTE",
      party: "Bharatiya Janata Party",
      evm_votes: 544770,
      postal_votes: 1258,
      total_votes: 546028,
      percentage_of_votes: 45.33,
    },
    {
      serial_no: 3,
      candidate: "AATISH MOHAN BANSODE",
      party: "Independent",
      evm_votes: 10493,
      postal_votes: 14,
      total_votes: 10507,
      percentage_of_votes: 0.87,
    },
    {
      serial_no: 4,
      candidate: "BABLU SIDRAM GAIKWAD",
      party: "Bahujan Samaj Party",
      evm_votes: 5236,
      postal_votes: 32,
      total_votes: 5268,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 5,
      candidate: "SHRIVIDYADURGADEVI MAULAPPA KURANE",
      party: "Independent",
      evm_votes: 3489,
      postal_votes: 4,
      total_votes: 3493,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 6,
      candidate: "RAMESH BHIMRAO SHIKHARE",
      party: "Independent",
      evm_votes: 3146,
      postal_votes: 0,
      total_votes: 3146,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 7,
      candidate: "PROF DR ARJUN GENA OHOL",
      party: "Bahujan Mukti Party",
      evm_votes: 2651,
      postal_votes: 3,
      total_votes: 2654,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 8,
      candidate: "BHANTENAAGAMURTI MOULAPPA KURANE",
      party: "Independent",
      evm_votes: 1547,
      postal_votes: 7,
      total_votes: 1554,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 9,
      candidate: "SUNILKUMAR TUKARAM SHINDE",
      party: "Independent",
      evm_votes: 1478,
      postal_votes: 2,
      total_votes: 1480,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 10,
      candidate: "YUGANDHAR CHANDRAKANT THOKALE",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 1125,
      postal_votes: 0,
      total_votes: 1125,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 11,
      candidate: "PARMESHWAR PANDURANG GEJAGE",
      party: "Independent",
      evm_votes: 885,
      postal_votes: 1,
      total_votes: 886,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "ADV VIKRAM UTTAM KASABE",
      party: "Independent",
      evm_votes: 843,
      postal_votes: 3,
      total_votes: 846,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "UGHADE VIJAYKUMAR BHAGWAN",
      party: "Independent",
      evm_votes: 832,
      postal_votes: 1,
      total_votes: 833,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 14,
      candidate: "KUMAR CHANDRAKANT LONDHE",
      party: "Baliraja Party",
      evm_votes: 678,
      postal_votes: 1,
      total_votes: 679,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "KRISHNA NAGNATH BHISE",
      party: "Independent",
      evm_votes: 561,
      postal_votes: 1,
      total_votes: 562,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "ANNA SUKHADEV MASKE",
      party: "Independent",
      evm_votes: 496,
      postal_votes: 5,
      total_votes: 501,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 17,
      candidate: "MAHASIDDHA TUKARAM GAYAKWAD",
      party: "Independent",
      evm_votes: 406,
      postal_votes: 3,
      total_votes: 409,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 18,
      candidate: "SUDARSHAN RAYCHAND KHANDARE",
      party: "Independent",
      evm_votes: 400,
      postal_votes: 1,
      total_votes: 401,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 19,
      candidate: "SONAVANE SHIVAJI CHANDRAKANT",
      party: "Independent",
      evm_votes: 394,
      postal_votes: 0,
      total_votes: 394,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 20,
      candidate: "SACHIN SHAHU MASKE",
      party: "Independent",
      evm_votes: 389,
      postal_votes: 0,
      total_votes: 389,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 21,
      candidate: "PROF. DR. SUBHASH KHANDERAO GAIKWAD",
      party: "Independent",
      evm_votes: 379,
      postal_votes: 1,
      total_votes: 380,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 2713,
      postal_votes: 12,
      total_votes: 2725,
      percentage_of_votes: 0.23,
    },
  ],
  total: {
    evm_votes: 1201261,
    postal_votes: 3224,
    total_votes: 1204485,
  },
};
export const s1347 = {
  cs_name: "Kolhapur",
  cs_shortname: "kolhapur",
  cs_code: "s1347",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "CHHATRAPATI SHAHU SHAHAJI",
      party: "Indian National Congress",
      evm_votes: 750323,
      postal_votes: 4199,
      total_votes: 754522,
      percentage_of_votes: 54.15,
    },
    {
      serial_no: 2,
      candidate: "SANJAY SADASHIVRAO MANDLIK",
      party: "Shiv Sena",
      evm_votes: 597014,
      postal_votes: 2544,
      total_votes: 599558,
      percentage_of_votes: 43.03,
    },
    {
      serial_no: 3,
      candidate: "SANJAY BHIKAJI MAGADE",
      party: "Bahujan Samaj Party",
      evm_votes: 3946,
      postal_votes: 56,
      total_votes: 4002,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 4,
      candidate: "MUSHTAK AJIJ MULLA",
      party: "Independent",
      evm_votes: 3822,
      postal_votes: 2,
      total_votes: 3824,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 5,
      candidate: "MADHURI RAJU JADHAV",
      party: "Independent",
      evm_votes: 3503,
      postal_votes: 5,
      total_votes: 3508,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 6,
      candidate: "KHADE BAJIRAO NANASO",
      party: "Independent",
      evm_votes: 3497,
      postal_votes: 15,
      total_votes: 3512,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 7,
      candidate: "KRUSHNABAI DIPAK CHOUGALE",
      party: "Independent",
      evm_votes: 3045,
      postal_votes: 18,
      total_votes: 3063,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 8,
      candidate: "KOGALE SANDEEP BHAIRAVNATH",
      party: "Desh Janhit Party",
      evm_votes: 2952,
      postal_votes: 23,
      total_votes: 2975,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 9,
      candidate: "ADV. YASH SUHAS HEGDEPATIL",
      party: "Independent",
      evm_votes: 2457,
      postal_votes: 2,
      total_votes: 2459,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 10,
      candidate: "NAGNATH PUNDALIK BENAKE",
      party: "Independent",
      evm_votes: 1215,
      postal_votes: 12,
      total_votes: 1227,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 11,
      candidate: "RAJANDARA BALASO KOLI",
      party: "Independent",
      evm_votes: 1029,
      postal_votes: 15,
      total_votes: 1044,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "B. T. PATIL (EX SERVICEMAN)",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 1011,
      postal_votes: 96,
      total_votes: 1107,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "SUBHASH VAIJU DESAI",
      party: "Independent",
      evm_votes: 847,
      postal_votes: 2,
      total_votes: 849,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 14,
      candidate: "MANGESH JAYSINGH PATIL",
      party: "Independent",
      evm_votes: 647,
      postal_votes: 6,
      total_votes: 653,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "SHASHIBHUSHAN JEEVANRAO DESAI",
      party: "Akhil Bharat Hindu Mahasabha",
      evm_votes: 636,
      postal_votes: 15,
      total_votes: 651,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "SANTOSH GANPATI BISURE",
      party: "Apni Prajahit Party",
      evm_votes: 635,
      postal_votes: 4,
      total_votes: 639,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 17,
      candidate: "DR. SUNIL NAMDEV PATIL",
      party: "National Black Panther Party",
      evm_votes: 626,
      postal_votes: 7,
      total_votes: 633,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 18,
      candidate: "KRUSHNA HANMANT DESAI",
      party: "Independent",
      evm_votes: 602,
      postal_votes: 2,
      total_votes: 604,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 19,
      candidate: "IRFAN ABUTALIB CHAND",
      party: "Independent",
      evm_votes: 595,
      postal_votes: 2,
      total_votes: 597,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 20,
      candidate: "MANE ARVIND BHIVA",
      party: "Bhartiya Rashtriya Dal",
      evm_votes: 558,
      postal_votes: 12,
      total_votes: 570,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 21,
      candidate: "SANDEEP GUNDOPANT SANKPAL",
      party: "Independent",
      evm_votes: 541,
      postal_votes: 3,
      total_votes: 544,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 22,
      candidate: "KUDARTULLA ADAM LATIF",
      party: "Independent",
      evm_votes: 465,
      postal_votes: 2,
      total_votes: 467,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 23,
      candidate: "SALIM NURMUHAMAD BAGWAN",
      party: "Independent",
      evm_votes: 334,
      postal_votes: 1,
      total_votes: 335,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 24,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5932,
      postal_votes: 51,
      total_votes: 5983,
      percentage_of_votes: 0.43,
    },
  ],
  total: {
    evm_votes: 1386232,
    postal_votes: 7094,
    total_votes: 1393326,
  },
};
export const s141 = {
  cs_name: "Inner_Manipur",
  cs_shortname: "innermanipur",
  cs_code: "s141",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "ANGOMCHA BIMOL AKOIJAM",
      party: "Indian National Congress",
      evm_votes: 370678,
      postal_votes: 3339,
      total_votes: 374017,
      percentage_of_votes: 46.93,
    },
    {
      serial_no: 2,
      candidate: "THOUNAOJAM BASANTA KUMAR SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 262217,
      postal_votes: 1999,
      total_votes: 264216,
      percentage_of_votes: 33.16,
    },
    {
      serial_no: 3,
      candidate: "MAHESHWAR THOUNAOJAM",
      party: "Republican Party of India (Athawale)",
      evm_votes: 134308,
      postal_votes: 1332,
      total_votes: 135640,
      percentage_of_votes: 17.02,
    },
    {
      serial_no: 4,
      candidate: "RAJKUMAR SOMENDRO SINGH",
      party: "Independent",
      evm_votes: 12892,
      postal_votes: 158,
      total_votes: 13050,
      percentage_of_votes: 1.64,
    },
    {
      serial_no: 5,
      candidate: "MOIRANGTHEM TOTOMSHANA NONGSHABA",
      party: "Independent",
      evm_votes: 4831,
      postal_votes: 50,
      total_votes: 4881,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 6,
      candidate: "HAORUNGBAM SARAT SINGH",
      party: "Independent",
      evm_votes: 1279,
      postal_votes: 17,
      total_votes: 1296,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 7,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3707,
      postal_votes: 90,
      total_votes: 3797,
      percentage_of_votes: 0.48,
    },
  ],
  total: {
    evm_votes: 789912,
    postal_votes: 6985,
    total_votes: 796897,
  },
};
export const s142 = {
  cs_name: "Outer_Manipur",
  cs_shortname: "outermanipur",
  cs_code: "s142",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "ALFRED KANNGAM S ARTHUR",
      party: "Indian National Congress",
      evm_votes: 381813,
      postal_votes: 3141,
      total_votes: 384954,
      percentage_of_votes: 48.32,
    },
    {
      serial_no: 2,
      candidate: "KACHUI TIMOTHY ZIMIK",
      party: "Naga Peoples Front",
      evm_votes: 298358,
      postal_votes: 1178,
      total_votes: 299536,
      percentage_of_votes: 37.6,
    },
    {
      serial_no: 3,
      candidate: "S KHO JOHN",
      party: "Independent",
      evm_votes: 89677,
      postal_votes: 233,
      total_votes: 89910,
      percentage_of_votes: 11.28,
    },
    {
      serial_no: 4,
      candidate: "ALYSON ABONMAI",
      party: "Independent",
      evm_votes: 14865,
      postal_votes: 245,
      total_votes: 15110,
      percentage_of_votes: 1.9,
    },
    {
      serial_no: 5,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6530,
      postal_votes: 695,
      total_votes: 7225,
      percentage_of_votes: 0.91,
    },
  ],
  total: {
    evm_votes: 791243,
    postal_votes: 5492,
    total_votes: 796735,
  },
};
export const s152 = {
  cs_name: "Tura",
  cs_shortname: "tura",
  cs_code: "s152",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "SALENG A SANGMA",
      party: "Indian National Congress",
      evm_votes: 382058,
      postal_votes: 1861,
      total_votes: 383919,
      percentage_of_votes: 56.96,
    },
    {
      serial_no: 2,
      candidate: "AGATHA K SANGMA",
      party: "National People's Party",
      evm_votes: 227210,
      postal_votes: 1468,
      total_votes: 228678,
      percentage_of_votes: 33.93,
    },
    {
      serial_no: 3,
      candidate: "ZENITH M SANGMA",
      party: "All India Trinamool Congress",
      evm_votes: 48518,
      postal_votes: 191,
      total_votes: 48709,
      percentage_of_votes: 7.23,
    },
    {
      serial_no: 4,
      candidate: "LABENN CH MARAK",
      party: "Independent",
      evm_votes: 6846,
      postal_votes: 64,
      total_votes: 6910,
      percentage_of_votes: 1.03,
    },
    {
      serial_no: 5,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5809,
      postal_votes: 31,
      total_votes: 5840,
      percentage_of_votes: 0.87,
    },
  ],
  total: {
    evm_votes: 670441,
    postal_votes: 3615,
    total_votes: 674056,
  },
};
export const s171 = {
  cs_name: "Nagaland",
  cs_shortname: "nagaland",
  cs_code: "s171",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "S SUPONGMEREN JAMIR",
      party: "Indian National Congress",
      evm_votes: 399512,
      postal_votes: 2439,
      total_votes: 401951,
      percentage_of_votes: 52.83,
    },
    {
      serial_no: 2,
      candidate: "DR CHUMBEN MURRY",
      party: "Nationalist Democratic Progressive Party",
      evm_votes: 350072,
      postal_votes: 895,
      total_votes: 350967,
      percentage_of_votes: 46.13,
    },
    {
      serial_no: 3,
      candidate: "HAYITHUNG TUNGOE LOTHA",
      party: "Independent",
      evm_votes: 6050,
      postal_votes: 182,
      total_votes: 6232,
      percentage_of_votes: 0.82,
    },
    {
      serial_no: 4,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 1520,
      postal_votes: 126,
      total_votes: 1646,
      percentage_of_votes: 0.22,
    },
  ],
  total: {
    evm_votes: 757154,
    postal_votes: 3642,
    total_votes: 760796,
  },
};
export const s1821 = {
  cs_name: "Koraput",
  cs_shortname: "koraput",
  cs_code: "s1821",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "SAPTAGIRI SANKAR ULAKA",
      party: "Indian National Congress",
      evm_votes: 469295,
      postal_votes: 2098,
      total_votes: 471393,
      percentage_of_votes: 41.03,
    },
    {
      serial_no: 2,
      candidate: "KAUSALYA HIKAKA",
      party: "Biju Janata Dal",
      evm_votes: 322797,
      postal_votes: 852,
      total_votes: 323649,
      percentage_of_votes: 28.17,
    },
    {
      serial_no: 3,
      candidate: "KALIRAM MAJHI",
      party: "Bharatiya Janata Party",
      evm_votes: 230792,
      postal_votes: 1722,
      total_votes: 232514,
      percentage_of_votes: 20.24,
    },
    {
      serial_no: 4,
      candidate: "DAMINI JANI",
      party: "Bahujan Samaj Party",
      evm_votes: 15373,
      postal_votes: 54,
      total_votes: 15427,
      percentage_of_votes: 1.34,
    },
    {
      serial_no: 5,
      candidate: "PRAKASH HIKAKA",
      party: "Communist Party of India (Marxist-Leninist) (Liberation)",
      evm_votes: 14847,
      postal_votes: 5,
      total_votes: 14852,
      percentage_of_votes: 1.29,
    },
    {
      serial_no: 6,
      candidate: "LASU HANTAL",
      party: "Independent",
      evm_votes: 14362,
      postal_votes: 11,
      total_votes: 14373,
      percentage_of_votes: 1.25,
    },
    {
      serial_no: 7,
      candidate: "RAGHUMANI GOMANGO",
      party: "Independent",
      evm_votes: 10528,
      postal_votes: 12,
      total_votes: 10540,
      percentage_of_votes: 0.92,
    },
    {
      serial_no: 8,
      candidate: "ABINASH GOMANGO",
      party: "Independent",
      evm_votes: 10258,
      postal_votes: 16,
      total_votes: 10274,
      percentage_of_votes: 0.89,
    },
    {
      serial_no: 9,
      candidate: "SUMAN KANTA",
      party: "Naba Bharata Nirmana Seva Party",
      evm_votes: 8386,
      postal_votes: 10,
      total_votes: 8396,
      percentage_of_votes: 0.73,
    },
    {
      serial_no: 10,
      candidate: "SANTOSH KUMAR BIDIKA",
      party: "Republican Party of India (A)",
      evm_votes: 5413,
      postal_votes: 2,
      total_votes: 5415,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 11,
      candidate: "PRAMILA PUJARI",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 4866,
      postal_votes: 12,
      total_votes: 4878,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 37106,
      postal_votes: 25,
      total_votes: 37131,
      percentage_of_votes: 3.23,
    },
  ],
  total: {
    evm_votes: 1144023,
    postal_votes: 4819,
    total_votes: 1148842,
  },
};
export const s191 = {
  cs_name: "Gurdaspur",
  cs_shortname: "gurdaspur",
  cs_code: "s191",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "SUKHJINDER SINGH RANDHAWA",
      party: "Indian National Congress",
      evm_votes: 361713,
      postal_votes: 2330,
      total_votes: 364043,
      percentage_of_votes: 33.78,
    },
    {
      serial_no: 2,
      candidate: "DINESH SINGH BABBU",
      party: "Bharatiya Janata Party",
      evm_votes: 278701,
      postal_votes: 2481,
      total_votes: 281182,
      percentage_of_votes: 26.09,
    },
    {
      serial_no: 3,
      candidate: "AMANSHER SINGH (SHERY KALSI)",
      party: "Aam Aadmi Party",
      evm_votes: 275416,
      postal_votes: 1836,
      total_votes: 277252,
      percentage_of_votes: 25.72,
    },
    {
      serial_no: 4,
      candidate: "DR. DALJIT SINGH CHEEMA",
      party: "Shiromani Akali Dal",
      evm_votes: 85194,
      postal_votes: 306,
      total_votes: 85500,
      percentage_of_votes: 7.93,
    },
    {
      serial_no: 5,
      candidate: "GURINDER SINGH BAJWA",
      party: "Shiromani Akali Dal (Amritsar)(Simranjit Singh Mann)",
      evm_votes: 25425,
      postal_votes: 340,
      total_votes: 25765,
      percentage_of_votes: 2.39,
    },
    {
      serial_no: 6,
      candidate: "SANT SEWAK",
      party: "Independent",
      evm_votes: 5950,
      postal_votes: 2,
      total_votes: 5952,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 7,
      candidate: "RAJ KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 4859,
      postal_votes: 71,
      total_votes: 4930,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 8,
      candidate: "SANTOSH KAUR",
      party: "Independent",
      evm_votes: 4733,
      postal_votes: 5,
      total_votes: 4738,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 9,
      candidate: "SAMUEL SONI",
      party: "Independent",
      evm_votes: 4427,
      postal_votes: 13,
      total_votes: 4440,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 10,
      candidate: "SURJIT SINGH",
      party: "Independent",
      evm_votes: 3372,
      postal_votes: 1,
      total_votes: 3373,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 11,
      candidate: "SANJEEV SINGH",
      party: "Independent",
      evm_votes: 2172,
      postal_votes: 6,
      total_votes: 2178,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 12,
      candidate: "SURINDER SINGH",
      party: "Independent",
      evm_votes: 1775,
      postal_votes: 7,
      total_votes: 1782,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 13,
      candidate: "GURPREET KAUR BAJWA",
      party: "Independent",
      evm_votes: 1688,
      postal_votes: 21,
      total_votes: 1709,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 14,
      candidate: "SANJEEV MANHAS",
      party: "Independent",
      evm_votes: 1634,
      postal_votes: 1,
      total_votes: 1635,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 15,
      candidate: "ROBI MASIH",
      party: "Independent",
      evm_votes: 1509,
      postal_votes: 2,
      total_votes: 1511,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 16,
      candidate: "SANTOSH KUMARI",
      party: "Megh Desham Party",
      evm_votes: 1171,
      postal_votes: 23,
      total_votes: 1194,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 17,
      candidate: "RAMESH KUMAR TOLLA",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 1085,
      postal_votes: 13,
      total_votes: 1098,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 18,
      candidate: "AMIT AGGARWAL",
      party: "Independent",
      evm_votes: 920,
      postal_votes: 4,
      total_votes: 924,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 19,
      candidate: "TARSEM MASIH SAHOTA",
      party: "Independent",
      evm_votes: 878,
      postal_votes: 7,
      total_votes: 885,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 20,
      candidate: "TILAK RAJ",
      party: "Independent",
      evm_votes: 802,
      postal_votes: 3,
      total_votes: 805,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 21,
      candidate: "RANJODH SINGH",
      party: "Jan Sewa Driver Party",
      evm_votes: 776,
      postal_votes: 13,
      total_votes: 789,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 22,
      candidate: "I.S.GULATI",
      party: "Independent",
      evm_votes: 696,
      postal_votes: 3,
      total_votes: 699,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 23,
      candidate: "RAMESH LAL",
      party: "National Republic Party Of India",
      evm_votes: 653,
      postal_votes: 4,
      total_votes: 657,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 24,
      candidate: "JAGDISH MASIH",
      party: "Independent",
      evm_votes: 545,
      postal_votes: 1,
      total_votes: 546,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 25,
      candidate: "JATINDER KUMAR SHARMA",
      party: "Bhartiya Rashtriya Dal",
      evm_votes: 456,
      postal_votes: 5,
      total_votes: 461,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 26,
      candidate: "DARBARA SINGH",
      party: "Nationalist Justice Party",
      evm_votes: 415,
      postal_votes: 9,
      total_votes: 424,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 27,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3289,
      postal_votes: 65,
      total_votes: 3354,
      percentage_of_votes: 0.31,
    },
  ],
  total: {
    evm_votes: 1070254,
    postal_votes: 7572,
    total_votes: 1077826,
  },
};
export const s192 = {
  cs_name: "Amritsar",
  cs_shortname: "amritsar",
  cs_code: "s192",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "GURJEET SINGH AUJLA",
      party: "Indian National Congress",
      evm_votes: 254414,
      postal_votes: 767,
      total_votes: 255181,
      percentage_of_votes: 28.18,
    },
    {
      serial_no: 2,
      candidate: "KULDEEP SINGH DHALIWAL",
      party: "Aam Aadmi Party",
      evm_votes: 214268,
      postal_votes: 612,
      total_votes: 214880,
      percentage_of_votes: 23.73,
    },
    {
      serial_no: 3,
      candidate: "TARANJIT SINGH SANDHU SAMUNDRI",
      party: "Bharatiya Janata Party",
      evm_votes: 206832,
      postal_votes: 373,
      total_votes: 207205,
      percentage_of_votes: 22.88,
    },
    {
      serial_no: 4,
      candidate: "ANIL JOSHI",
      party: "Shiromani Akali Dal",
      evm_votes: 162686,
      postal_votes: 210,
      total_votes: 162896,
      percentage_of_votes: 17.99,
    },
    {
      serial_no: 5,
      candidate: "EMAAN SINGH MANN",
      party: "Shiromani Akali Dal (Amritsar)(Simranjit Singh Mann)",
      evm_votes: 26584,
      postal_votes: 212,
      total_votes: 26796,
      percentage_of_votes: 2.96,
    },
    {
      serial_no: 6,
      candidate: "SATBIR SINGH JAMMU",
      party: "Independent",
      evm_votes: 3394,
      postal_votes: 5,
      total_votes: 3399,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 7,
      candidate: "VISHAL SIDHU",
      party: "Bahujan Samaj Party",
      evm_votes: 2713,
      postal_votes: 20,
      total_votes: 2733,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 8,
      candidate: "DASWINDER KAUR",
      party: "Communist Party of India",
      evm_votes: 2468,
      postal_votes: 13,
      total_votes: 2481,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 9,
      candidate: "SHARANJIT KAUR",
      party: "Independent",
      evm_votes: 2456,
      postal_votes: 4,
      total_votes: 2460,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 10,
      candidate: "AMANPREET SINGH MAHADIPUR",
      party: "Independent",
      evm_votes: 2353,
      postal_votes: 9,
      total_votes: 2362,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 11,
      candidate: "GURINDER SINGH SABHI GILL",
      party: "Independent",
      evm_votes: 2124,
      postal_votes: 5,
      total_votes: 2129,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 12,
      candidate: "SAHIB SINGH",
      party: "Independent",
      evm_votes: 1987,
      postal_votes: 1,
      total_votes: 1988,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 13,
      candidate: "SHAMSHER SINGH SHERA",
      party: "Independent",
      evm_votes: 1504,
      postal_votes: 3,
      total_votes: 1507,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 14,
      candidate: "SHAM LAL GANDHI",
      party: "Independent",
      evm_votes: 1487,
      postal_votes: 0,
      total_votes: 1487,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 15,
      candidate: "LOVEPREET SHARMA",
      party: "Aas Punjab Party",
      evm_votes: 1372,
      postal_votes: 3,
      total_votes: 1375,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 16,
      candidate: "MASTER HARJINDER PAL",
      party: "Independent",
      evm_votes: 1358,
      postal_votes: 0,
      total_votes: 1358,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 17,
      candidate: "JASPAL MASIH",
      party: "Independent",
      evm_votes: 1223,
      postal_votes: 4,
      total_votes: 1227,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 18,
      candidate: "RESHAM SINGH",
      party: "Independent",
      evm_votes: 1130,
      postal_votes: 3,
      total_votes: 1133,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 19,
      candidate: "BAL KRISHAN SHARMA",
      party: "Independent",
      evm_votes: 1106,
      postal_votes: 1,
      total_votes: 1107,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 20,
      candidate: "SIMRANPREET SINGH",
      party: "Independent",
      evm_votes: 1104,
      postal_votes: 2,
      total_votes: 1106,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 21,
      candidate: "NARINDER KAUR",
      party: "Aam Janta Party (India)",
      evm_votes: 1064,
      postal_votes: 33,
      total_votes: 1097,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 22,
      candidate: "DILDAR MASIH",
      party: "Shiromani Lok Dal Party",
      evm_votes: 1042,
      postal_votes: 2,
      total_votes: 1044,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 23,
      candidate: "RAJINDER KUMAR SHARMA",
      party: "Independent",
      evm_votes: 899,
      postal_votes: 1,
      total_votes: 900,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 24,
      candidate: "BALWINDER SINGH",
      party: "Independent",
      evm_votes: 854,
      postal_votes: 3,
      total_votes: 857,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 25,
      candidate: "GURPREET SINGH RATTAN",
      party: "Republican Party of India (Athawale)",
      evm_votes: 735,
      postal_votes: 3,
      total_votes: 738,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 26,
      candidate: "DR RAMESH KUMAR",
      party: "Sacho Sach Party",
      evm_votes: 650,
      postal_votes: 2,
      total_votes: 652,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 27,
      candidate: "GAGANDEEP",
      party: "Independent",
      evm_votes: 608,
      postal_votes: 2,
      total_votes: 610,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 28,
      candidate: "PRITHVI PAL",
      party: "Independent",
      evm_votes: 434,
      postal_votes: 0,
      total_votes: 434,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 29,
      candidate: "NEELAM",
      party: "Independent",
      evm_votes: 404,
      postal_votes: 5,
      total_votes: 409,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 30,
      candidate: "DILBAGH SINGH",
      party: "Independent",
      evm_votes: 388,
      postal_votes: 3,
      total_votes: 391,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 31,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3685,
      postal_votes: 29,
      total_votes: 3714,
      percentage_of_votes: 0.41,
    },
  ],
  total: {
    evm_votes: 903326,
    postal_votes: 2330,
    total_votes: 905656,
  },
};
export const s194 = {
  cs_name: "Jalandhar",
  cs_shortname: "jalandhar",
  cs_code: "s194",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "CHARANJIT SINGH CHANNI",
      party: "Indian National Congress",
      evm_votes: 389509,
      postal_votes: 544,
      total_votes: 390053,
      percentage_of_votes: 39.43,
    },
    {
      serial_no: 2,
      candidate: "SUSHIL KUMAR RINKU",
      party: "Bharatiya Janata Party",
      evm_votes: 213702,
      postal_votes: 358,
      total_votes: 214060,
      percentage_of_votes: 21.64,
    },
    {
      serial_no: 3,
      candidate: "PAWAN KUMAR TINU",
      party: "Aam Aadmi Party",
      evm_votes: 208549,
      postal_votes: 340,
      total_votes: 208889,
      percentage_of_votes: 21.12,
    },
    {
      serial_no: 4,
      candidate: "MOHINDER SINGH KAYPEE",
      party: "Shiromani Akali Dal",
      evm_votes: 67811,
      postal_votes: 100,
      total_votes: 67911,
      percentage_of_votes: 6.87,
    },
    {
      serial_no: 5,
      candidate: "ADVOCATE BALWINDER KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 64856,
      postal_votes: 85,
      total_votes: 64941,
      percentage_of_votes: 6.57,
    },
    {
      serial_no: 6,
      candidate: "SARABJIT SINGH KHALSA",
      party: "Shiromani Akali Dal (Amritsar)(Simranjit Singh Mann)",
      evm_votes: 19252,
      postal_votes: 32,
      total_votes: 19284,
      percentage_of_votes: 1.95,
    },
    {
      serial_no: 7,
      candidate: "MASTER PARSHOTAM LAL BILGA",
      party: "Communist Party of India (Marxist)",
      evm_votes: 5948,
      postal_votes: 10,
      total_votes: 5958,
      percentage_of_votes: 0.6,
    },
    {
      serial_no: 8,
      candidate: "IQBAL CHAND MATTU",
      party: "Independent",
      evm_votes: 1956,
      postal_votes: 0,
      total_votes: 1956,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 9,
      candidate: "NEETU SHATTRA WALA",
      party: "Independent",
      evm_votes: 1878,
      postal_votes: 1,
      total_votes: 1879,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 10,
      candidate: "AMRISH BHAGAT",
      party: "Independent",
      evm_votes: 1183,
      postal_votes: 1,
      total_votes: 1184,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 11,
      candidate: "GURDEEP SINGH BITTU",
      party: "Independent",
      evm_votes: 1112,
      postal_votes: 1,
      total_votes: 1113,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 12,
      candidate: "RAJ KUMAR SAQI",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 1087,
      postal_votes: 1,
      total_votes: 1088,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 13,
      candidate: "SONIA",
      party: "Republican Party of India (Athawale)",
      evm_votes: 1054,
      postal_votes: 1,
      total_votes: 1055,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 14,
      candidate: "RAJWANT KAUR KHALSA",
      party: "Apna Samaj Party",
      evm_votes: 948,
      postal_votes: 4,
      total_votes: 952,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "BHAGAT GULSHAN AZAAD",
      party: "Democratic Bharatiya Samaj Party",
      evm_votes: 929,
      postal_votes: 1,
      total_votes: 930,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 16,
      candidate: "RAMESH LAL KALA",
      party: "Independent",
      evm_votes: 875,
      postal_votes: 1,
      total_votes: 876,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 17,
      candidate: "ASHOK KUMAR JAKHU",
      party: "Independent",
      evm_votes: 742,
      postal_votes: 1,
      total_votes: 743,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 18,
      candidate: "BAL MUKAND BAWRA",
      party: "Global Republican Party",
      evm_votes: 587,
      postal_votes: 4,
      total_votes: 591,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 19,
      candidate: "PARAMJIT KAUR TEJI",
      party: "Independent",
      evm_votes: 500,
      postal_votes: 0,
      total_votes: 500,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 20,
      candidate: "TARACHAND SHEELA",
      party: "Loktantrik Lok Rajyam Party",
      evm_votes: 400,
      postal_votes: 1,
      total_votes: 401,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 21,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4724,
      postal_votes: 19,
      total_votes: 4743,
      percentage_of_votes: 0.48,
    },
  ],
  total: {
    evm_votes: 987602,
    postal_votes: 1505,
    total_votes: 989107,
  },
};
export const s197 = {
  cs_name: "Ludhiana",
  cs_shortname: "ludhiana",
  cs_code: "s197",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "AMRINDER SINGH RAJA WARRING",
      party: "Indian National Congress",
      evm_votes: 321424,
      postal_votes: 800,
      total_votes: 322224,
      percentage_of_votes: 30.42,
    },
    {
      serial_no: 2,
      candidate: "RAVNEET SINGH BITTU",
      party: "Bharatiya Janata Party",
      evm_votes: 300927,
      postal_votes: 355,
      total_votes: 301282,
      percentage_of_votes: 28.45,
    },
    {
      serial_no: 3,
      candidate: "ASHOK PRASHAR PAPPI",
      party: "Aam Aadmi Party",
      evm_votes: 236620,
      postal_votes: 457,
      total_votes: 237077,
      percentage_of_votes: 22.38,
    },
    {
      serial_no: 4,
      candidate: "RANJIT SINGH DHILLON",
      party: "Shiromani Akali Dal",
      evm_votes: 90073,
      postal_votes: 147,
      total_votes: 90220,
      percentage_of_votes: 8.52,
    },
    {
      serial_no: 5,
      candidate: "KAMALJIT SINGH BRAR",
      party: "Independent",
      evm_votes: 42462,
      postal_votes: 38,
      total_votes: 42500,
      percentage_of_votes: 4.01,
    },
    {
      serial_no: 6,
      candidate: "AMRITPAL SINGH CHHANDRAN",
      party: "Shiromani Akali Dal (Amritsar)(Simranjit Singh Mann)",
      evm_votes: 18199,
      postal_votes: 42,
      total_votes: 18241,
      percentage_of_votes: 1.72,
    },
    {
      serial_no: 7,
      candidate: "DAVINDER SINGH RAMGARHIA",
      party: "Bahujan Samaj Party",
      evm_votes: 10365,
      postal_votes: 29,
      total_votes: 10394,
      percentage_of_votes: 0.98,
    },
    {
      serial_no: 8,
      candidate: "NARESH DHINGAAN",
      party: "Independent",
      evm_votes: 2524,
      postal_votes: 6,
      total_votes: 2530,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 9,
      candidate: "RAKESH KUMAR (RICKY)",
      party: "Sunahara Bharat Party",
      evm_votes: 2091,
      postal_votes: 0,
      total_votes: 2091,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 10,
      candidate: "ER. BALDEV RAJ KATNA (DEBI)",
      party: "Independent",
      evm_votes: 2086,
      postal_votes: 0,
      total_votes: 2086,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 11,
      candidate: "PRITPAL SINGH",
      party: "Bahujan Dravida Party",
      evm_votes: 1821,
      postal_votes: 3,
      total_votes: 1824,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "DR. PALWINDER KAUR",
      party: "Independent",
      evm_votes: 1766,
      postal_votes: 2,
      total_votes: 1768,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 13,
      candidate: "SANJEEV KUMAR (SANJU)",
      party: "Independent",
      evm_votes: 1567,
      postal_votes: 0,
      total_votes: 1567,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 14,
      candidate: "RAJIV KUMAR MEHRA",
      party: "Jan Sewa Driver Party",
      evm_votes: 1516,
      postal_votes: 2,
      total_votes: 1518,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 15,
      candidate: "BHUPINDER SINGH",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 1287,
      postal_votes: 0,
      total_votes: 1287,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 16,
      candidate: "BALJIT SINGH",
      party: "Independent",
      evm_votes: 1137,
      postal_votes: 1,
      total_votes: 1138,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 17,
      candidate: "VIPAN KUMAR BATRA",
      party: "Independent",
      evm_votes: 1041,
      postal_votes: 0,
      total_votes: 1041,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 18,
      candidate: "AMANDEEP SINGH",
      party: "Sehajdhari Sikh Party",
      evm_votes: 981,
      postal_votes: 6,
      total_votes: 987,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 19,
      candidate: "PARAMJIT SINGH",
      party: "Independent",
      evm_votes: 927,
      postal_votes: 0,
      total_votes: 927,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 20,
      candidate: "BALVINDER SINGH BITTA",
      party: "Independent",
      evm_votes: 918,
      postal_votes: 0,
      total_votes: 918,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 21,
      candidate: "SIMRANDEEP SINGH",
      party: "Independent",
      evm_votes: 911,
      postal_votes: 1,
      total_votes: 912,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 22,
      candidate: "DEVINDER BHAGRIA",
      party: "Hindustan Shakti Sena",
      evm_votes: 859,
      postal_votes: 1,
      total_votes: 860,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 23,
      candidate: "KANIYA LAL (DR. KISHAN KUMAR)",
      party: "Independent",
      evm_votes: 820,
      postal_votes: 0,
      total_votes: 820,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 24,
      candidate: "SUDHIR KUMAR TRIPATHI",
      party: "Independent",
      evm_votes: 770,
      postal_votes: 0,
      total_votes: 770,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 25,
      candidate: "GURDEEP SINGH KAHLON",
      party: "Independent",
      evm_votes: 738,
      postal_votes: 0,
      total_votes: 738,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 26,
      candidate: "DR. DEVINDER SINGH GILL",
      party: "Aam Lok Party United",
      evm_votes: 693,
      postal_votes: 2,
      total_votes: 695,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 27,
      candidate: "KIRPAL SINGH KAPURI",
      party: "Independent",
      evm_votes: 621,
      postal_votes: 0,
      total_votes: 621,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 28,
      candidate: "SANTOSH KUMAR",
      party: "Bharatiya Inqalab Party",
      evm_votes: 610,
      postal_votes: 0,
      total_votes: 610,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 29,
      candidate: "KAMAL PAWAR",
      party: "Independent",
      evm_votes: 592,
      postal_votes: 0,
      total_votes: 592,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 30,
      candidate: "RAVINDER PAL SINGH BABA JI BARGAR WALE",
      party: "Independent",
      evm_votes: 546,
      postal_votes: 2,
      total_votes: 548,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 31,
      candidate: "GURMEET SINGH KHARAY",
      party: "Independent",
      evm_votes: 516,
      postal_votes: 1,
      total_votes: 517,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 32,
      candidate: "RUPINDER KUMAR",
      party: "Independent",
      evm_votes: 467,
      postal_votes: 0,
      total_votes: 467,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 33,
      candidate: "CHANDI",
      party: "Independent",
      evm_votes: 456,
      postal_votes: 0,
      total_votes: 456,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 34,
      candidate: "BALDEV SINGH SUMAN",
      party: "Independent",
      evm_votes: 450,
      postal_votes: 0,
      total_votes: 450,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 35,
      candidate: "JAI - PARKASH JAIN (TITU BANIYA)",
      party: "Independent",
      evm_votes: 419,
      postal_votes: 0,
      total_votes: 419,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 36,
      candidate: "SHIVAM YADAV",
      party: "Global Republican Party",
      evm_votes: 414,
      postal_votes: 1,
      total_votes: 415,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 37,
      candidate: "HARVINDER KAUR",
      party: "Samajik Sangharsh Party",
      evm_votes: 413,
      postal_votes: 6,
      total_votes: 419,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 38,
      candidate: "BHOLA SINGH",
      party: "Independent",
      evm_votes: 411,
      postal_votes: 0,
      total_votes: 411,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 39,
      candidate: "DARSHAN SINGH DABA",
      party: "Nationalist Justice Party",
      evm_votes: 390,
      postal_votes: 0,
      total_votes: 390,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 40,
      candidate: "KULDEEP KUMAR SHARMA",
      party: "Independent",
      evm_votes: 383,
      postal_votes: 0,
      total_votes: 383,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 41,
      candidate: "VISHAL KUMAR ARORA",
      party: "Independent",
      evm_votes: 344,
      postal_votes: 0,
      total_votes: 344,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 42,
      candidate: "KARNAIL SINGH",
      party: "Independent",
      evm_votes: 340,
      postal_votes: 0,
      total_votes: 340,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 43,
      candidate: "RAJINDER GHAI",
      party: "Independent",
      evm_votes: 284,
      postal_votes: 0,
      total_votes: 284,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 44,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5040,
      postal_votes: 36,
      total_votes: 5076,
      percentage_of_votes: 0.48,
    },
  ],
  total: {
    evm_votes: 1057219,
    postal_votes: 1938,
    total_votes: 1059157,
  },
};
export const s198 = {
  cs_name: "Fatehgarh_Sahib",
  cs_shortname: "fatehgarhsahib",
  cs_code: "s198",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "AMAR SINGH",
      party: "Indian National Congress",
      evm_votes: 331324,
      postal_votes: 1267,
      total_votes: 332591,
      percentage_of_votes: 34.14,
    },
    {
      serial_no: 2,
      candidate: "GURPREET SINGH GP",
      party: "Aam Aadmi Party",
      evm_votes: 297439,
      postal_votes: 950,
      total_votes: 298389,
      percentage_of_votes: 30.63,
    },
    {
      serial_no: 3,
      candidate: "GEJJA RAM",
      party: "Bharatiya Janata Party",
      evm_votes: 127139,
      postal_votes: 382,
      total_votes: 127521,
      percentage_of_votes: 13.09,
    },
    {
      serial_no: 4,
      candidate: "BIKRAMJIT SINGH KHALSA",
      party: "Shiromani Akali Dal",
      evm_votes: 126461,
      postal_votes: 269,
      total_votes: 126730,
      percentage_of_votes: 13.01,
    },
    {
      serial_no: 5,
      candidate: "RAJ JATINDER SINGH",
      party: "Shiromani Akali Dal (Amritsar)(Simranjit Singh Mann)",
      evm_votes: 43543,
      postal_votes: 101,
      total_votes: 43644,
      percentage_of_votes: 4.48,
    },
    {
      serial_no: 6,
      candidate: "KULWANT SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 20786,
      postal_votes: 106,
      total_votes: 20892,
      percentage_of_votes: 2.14,
    },
    {
      serial_no: 7,
      candidate: "PREM SINGH MOHANPUR",
      party: "Independent",
      evm_votes: 3784,
      postal_votes: 8,
      total_votes: 3792,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 8,
      candidate: "BAHAL SINGH",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 3097,
      postal_votes: 7,
      total_votes: 3104,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 9,
      candidate: "PARAMJIT SINGH",
      party: "Independent",
      evm_votes: 2159,
      postal_votes: 8,
      total_votes: 2167,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 10,
      candidate: "RULDA SINGH",
      party: "Independent",
      evm_votes: 1971,
      postal_votes: 6,
      total_votes: 1977,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 11,
      candidate: "PARKASH PETER",
      party: "Independent",
      evm_votes: 1463,
      postal_votes: 2,
      total_votes: 1465,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 12,
      candidate: "HARJINDER SINGH",
      party: "Independent",
      evm_votes: 1158,
      postal_votes: 8,
      total_votes: 1166,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "KAMALJEET KAUR",
      party: "Independent",
      evm_votes: 888,
      postal_votes: 11,
      total_votes: 899,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "HARGOBIND SINGH",
      party: "Independent",
      evm_votes: 722,
      postal_votes: 9,
      total_votes: 731,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9097,
      postal_votes: 91,
      total_votes: 9188,
      percentage_of_votes: 0.94,
    },
  ],
  total: {
    evm_votes: 971031,
    postal_votes: 3225,
    total_votes: 974256,
  },
};
export const s1910 = {
  cs_name: "Firozpur",
  cs_shortname: "firozpur",
  cs_code: "s1910",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "SHER SINGH GHUBAYA",
      party: "Indian National Congress",
      evm_votes: 264712,
      postal_votes: 1914,
      total_votes: 266626,
      percentage_of_votes: 23.7,
    },
    {
      serial_no: 2,
      candidate: "JAGDEEP SINGH KAKA BRAR",
      party: "Aam Aadmi Party",
      evm_votes: 261273,
      postal_votes: 2111,
      total_votes: 263384,
      percentage_of_votes: 23.41,
    },
    {
      serial_no: 3,
      candidate: "GURMIT SINGH SODHI",
      party: "Bharatiya Janata Party",
      evm_votes: 253841,
      postal_votes: 1256,
      total_votes: 255097,
      percentage_of_votes: 22.67,
    },
    {
      serial_no: 4,
      candidate: "NARDEV SINGH BOBBY MANN",
      party: "Shiromani Akali Dal",
      evm_votes: 252327,
      postal_votes: 1318,
      total_votes: 253645,
      percentage_of_votes: 22.54,
    },
    {
      serial_no: 5,
      candidate: "GURCHARAN SINGH BHULLAR",
      party: "Shiromani Akali Dal (Amritsar)(Simranjit Singh Mann)",
      evm_votes: 15836,
      postal_votes: 105,
      total_votes: 15941,
      percentage_of_votes: 1.42,
    },
    {
      serial_no: 6,
      candidate: "FOJI ANGREJ SINGH WARWAL",
      party: "Independent",
      evm_votes: 9435,
      postal_votes: 43,
      total_votes: 9478,
      percentage_of_votes: 0.84,
    },
    {
      serial_no: 7,
      candidate: "SURINDER KAMBOJ",
      party: "Bahujan Samaj Party",
      evm_votes: 8347,
      postal_votes: 86,
      total_votes: 8433,
      percentage_of_votes: 0.75,
    },
    {
      serial_no: 8,
      candidate: "JASKARAN SINGH KAHAN SINGH WALA",
      party: "Independent",
      evm_votes: 6480,
      postal_votes: 55,
      total_votes: 6535,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 9,
      candidate: "SUKHDEV SINGH",
      party: "Independent",
      evm_votes: 4597,
      postal_votes: 2,
      total_votes: 4599,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 10,
      candidate: "SAHIL MONGA",
      party: "Independent",
      evm_votes: 3705,
      postal_votes: 19,
      total_votes: 3724,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 11,
      candidate: "PRITAM SINGH",
      party: "Independent",
      evm_votes: 3030,
      postal_votes: 1,
      total_votes: 3031,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 12,
      candidate: "GURPREET SINGH",
      party: "Independent",
      evm_votes: 2894,
      postal_votes: 3,
      total_votes: 2897,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 13,
      candidate: "ARWINDER SINGH",
      party: "Independent",
      evm_votes: 2556,
      postal_votes: 14,
      total_votes: 2570,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 14,
      candidate: "DEEPAK KUMAR DEEP DASHANAND",
      party: "Independent",
      evm_votes: 2435,
      postal_votes: 1,
      total_votes: 2436,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 15,
      candidate: "RAJ",
      party: "Independent",
      evm_votes: 2428,
      postal_votes: 4,
      total_votes: 2432,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 16,
      candidate: "PREM CHAND BOONGI",
      party: "Punjab National Party",
      evm_votes: 1944,
      postal_votes: 3,
      total_votes: 1947,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 17,
      candidate: "AJIT SINGH",
      party: "Rashtriya Republican Party",
      evm_votes: 1609,
      postal_votes: 6,
      total_votes: 1615,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 18,
      candidate: "SURINDER KUMAR BANSAL",
      party: "Independent",
      evm_votes: 1537,
      postal_votes: 3,
      total_votes: 1540,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 19,
      candidate: "HARPREET SINGH",
      party: "Independent",
      evm_votes: 1531,
      postal_votes: 7,
      total_votes: 1538,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 20,
      candidate: "KULDEEP SINGH",
      party: "India Praja Bandhu Party",
      evm_votes: 1484,
      postal_votes: 6,
      total_votes: 1490,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 21,
      candidate: "BALWINDER SINGH CHOHAN",
      party: "Jan Sewa Driver Party",
      evm_votes: 1366,
      postal_votes: 1,
      total_votes: 1367,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 22,
      candidate: "BALWINDER SINGH",
      party: "Republican Party of India (Athawale)",
      evm_votes: 1364,
      postal_votes: 0,
      total_votes: 1364,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 23,
      candidate: "GURCHARAN SINGH",
      party: "Independent",
      evm_votes: 1338,
      postal_votes: 4,
      total_votes: 1342,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 24,
      candidate: "UMESH KUMAR",
      party: "Independent",
      evm_votes: 1319,
      postal_votes: 1,
      total_votes: 1320,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 25,
      candidate: "BALWANT SINGH SAMMEWALI",
      party: "Nationalist Justice Party",
      evm_votes: 1148,
      postal_votes: 4,
      total_votes: 1152,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 26,
      candidate: "MOHAN SINGH",
      party: "Independent",
      evm_votes: 1075,
      postal_votes: 3,
      total_votes: 1078,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 27,
      candidate: "JASKARAN SINGH SIDHU",
      party: "Independent",
      evm_votes: 970,
      postal_votes: 1,
      total_votes: 971,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 28,
      candidate: "JASWANT SINGH KHATRI",
      party: "Independent",
      evm_votes: 820,
      postal_votes: 1,
      total_votes: 821,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 29,
      candidate: "CHAMKAUR SINGH",
      party: "Independent",
      evm_votes: 638,
      postal_votes: 4,
      total_votes: 642,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 30,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5895,
      postal_votes: 205,
      total_votes: 6100,
      percentage_of_votes: 0.54,
    },
  ],
  total: {
    evm_votes: 1117934,
    postal_votes: 7181,
    total_votes: 1125115,
  },
};
export const s1913 = {
  cs_name: "Patiala",
  cs_shortname: "patiala",
  cs_code: "s1913",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "DR DHARAMVIRA GANDHI",
      party: "Indian National Congress",
      evm_votes: 304672,
      postal_votes: 944,
      total_votes: 305616,
      percentage_of_votes: 26.54,
    },
    {
      serial_no: 2,
      candidate: "DR BALBIR SINGH",
      party: "Aam Aadmi Party",
      evm_votes: 290085,
      postal_votes: 700,
      total_votes: 290785,
      percentage_of_votes: 25.25,
    },
    {
      serial_no: 3,
      candidate: "PRENEET KAUR",
      party: "Bharatiya Janata Party",
      evm_votes: 288470,
      postal_votes: 528,
      total_votes: 288998,
      percentage_of_votes: 25.09,
    },
    {
      serial_no: 4,
      candidate: "N K SHARMA",
      party: "Shiromani Akali Dal",
      evm_votes: 153780,
      postal_votes: 198,
      total_votes: 153978,
      percentage_of_votes: 13.37,
    },
    {
      serial_no: 5,
      candidate: "PROF MOHINDER PAL SINGH",
      party: "Shiromani Akali Dal (Amritsar)(Simranjit Singh Mann)",
      evm_votes: 47152,
      postal_votes: 122,
      total_votes: 47274,
      percentage_of_votes: 4.1,
    },
    {
      serial_no: 6,
      candidate: "JAGJIT SINGH CHHARBAR",
      party: "Bahujan Samaj Party",
      evm_votes: 22334,
      postal_votes: 66,
      total_votes: 22400,
      percentage_of_votes: 1.94,
    },
    {
      serial_no: 7,
      candidate: "ARVINDER KUMAR",
      party: "Independent",
      evm_votes: 3808,
      postal_votes: 4,
      total_votes: 3812,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 8,
      candidate: "JAGDISH KUMAR",
      party: "Independent",
      evm_votes: 3236,
      postal_votes: 3,
      total_votes: 3239,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 9,
      candidate: "SUKHVINDER SINGH",
      party: "Independent",
      evm_votes: 2885,
      postal_votes: 9,
      total_votes: 2894,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 10,
      candidate: "JODH SINGH PARMAR KAULI",
      party: "Independent",
      evm_votes: 2795,
      postal_votes: 0,
      total_votes: 2795,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 11,
      candidate: "DEVINDER RAJPUT",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 2302,
      postal_votes: 9,
      total_votes: 2311,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 12,
      candidate: "NEERAJ KUMAR NANNA",
      party: "Independent",
      evm_votes: 2299,
      postal_votes: 3,
      total_votes: 2302,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 13,
      candidate: "PARAMJIT SINGH S/O TARLOCHAN SINGH",
      party: "Independent",
      evm_votes: 2264,
      postal_votes: 3,
      total_votes: 2267,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 14,
      candidate: "AMARJIT SINGH JAGDE RAHO",
      party: "Jan Janwadi Party",
      evm_votes: 1981,
      postal_votes: 11,
      total_votes: 1992,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 15,
      candidate: "RANJIT SINGH",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 1964,
      postal_votes: 3,
      total_votes: 1967,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 16,
      candidate: "GURBACHAN SINGH",
      party: "Independent",
      evm_votes: 1589,
      postal_votes: 5,
      total_votes: 1594,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 17,
      candidate: "PARAMJIT SINGH S/O BHAGWAN SINGH",
      party: "Independent",
      evm_votes: 1565,
      postal_votes: 4,
      total_votes: 1569,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 18,
      candidate: "VISHAL SHARMA",
      party: "Independent",
      evm_votes: 1474,
      postal_votes: 1,
      total_votes: 1475,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 19,
      candidate: "LABH SINGH PAL",
      party: "Independent",
      evm_votes: 1443,
      postal_votes: 0,
      total_votes: 1443,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 20,
      candidate: "CHAMKILA SINGH",
      party: "Independent",
      evm_votes: 1225,
      postal_votes: 3,
      total_votes: 1228,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 21,
      candidate: "KRISHAN KUMAR GABA",
      party: "Hindustan Shakti Sena",
      evm_votes: 1000,
      postal_votes: 8,
      total_votes: 1008,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 22,
      candidate: "MANDEEP SINGH",
      party: "Revolutionary Socialist Party",
      evm_votes: 970,
      postal_votes: 3,
      total_votes: 973,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 23,
      candidate: "MAKHAN SINGH",
      party: "Independent",
      evm_votes: 904,
      postal_votes: 3,
      total_votes: 907,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 24,
      candidate: "DIMPLE",
      party: "Independent",
      evm_votes: 846,
      postal_votes: 4,
      total_votes: 850,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 25,
      candidate: "BINDER KAUR",
      party: "Independent",
      evm_votes: 707,
      postal_votes: 4,
      total_votes: 711,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 26,
      candidate: "MANOJ KUMAR",
      party: "Independent",
      evm_votes: 674,
      postal_votes: 0,
      total_votes: 674,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 27,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6641,
      postal_votes: 40,
      total_votes: 6681,
      percentage_of_votes: 0.58,
    },
  ],
  total: {
    evm_votes: 1149065,
    postal_votes: 2678,
    total_votes: 1151743,
  },
};
export const s201 = {
  cs_name: "GANGANAGAR",
  cs_shortname: "ganganagar",
  cs_code: "s201",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "KULDEEP INDORA",
      party: "Indian National Congress",
      evm_votes: 719896,
      postal_votes: 6596,
      total_votes: 726492,
      percentage_of_votes: 51.4,
    },
    {
      serial_no: 2,
      candidate: "PRIYANKA BALAN MEGHWAL",
      party: "Bharatiya Janata Party",
      evm_votes: 632097,
      postal_votes: 6242,
      total_votes: 638339,
      percentage_of_votes: 45.16,
    },
    {
      serial_no: 3,
      candidate: "DEVKARAN NAYAK",
      party: "Bahujan Samaj Party",
      evm_votes: 16090,
      postal_votes: 212,
      total_votes: 16302,
      percentage_of_votes: 1.15,
    },
    {
      serial_no: 4,
      candidate: "RAJKUMAR",
      party: "Independent",
      evm_votes: 4350,
      postal_votes: 25,
      total_votes: 4375,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 5,
      candidate: "KULWANT KAUR",
      party: "Indian Peoples Green Party",
      evm_votes: 4338,
      postal_votes: 24,
      total_votes: 4362,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 6,
      candidate: "DAYA RAM",
      party: "Independent",
      evm_votes: 3285,
      postal_votes: 434,
      total_votes: 3719,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 7,
      candidate: "RAJENDER KUMAR",
      party: "Bhartiya Jan Samman Party",
      evm_votes: 2383,
      postal_votes: 60,
      total_votes: 2443,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 8,
      candidate: "KANA RAM",
      party: "Independent",
      evm_votes: 2248,
      postal_votes: 18,
      total_votes: 2266,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "MAJOR SINGH",
      party: "Jai Hind Congress Party",
      evm_votes: 2075,
      postal_votes: 26,
      total_votes: 2101,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12940,
      postal_votes: 155,
      total_votes: 13095,
      percentage_of_votes: 0.93,
    },
  ],
  total: {
    evm_votes: 1399702,
    postal_votes: 13792,
    total_votes: 1413494,
  },
};
export const s203 = {
  cs_name: "CHURU",
  cs_shortname: "churu",
  cs_code: "s203",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "RAHUL KASWAN",
      party: "Indian National Congress",
      evm_votes: 719352,
      postal_votes: 8859,
      total_votes: 728211,
      percentage_of_votes: 51.12,
    },
    {
      serial_no: 2,
      candidate: "DEVENDRA JHAJHARIA",
      party: "Bharatiya Janata Party",
      evm_votes: 647891,
      postal_votes: 7583,
      total_votes: 655474,
      percentage_of_votes: 46.01,
    },
    {
      serial_no: 3,
      candidate: "DEIRAM MEGHWAL",
      party: "Bahujan Samaj Party",
      evm_votes: 11145,
      postal_votes: 126,
      total_votes: 11271,
      percentage_of_votes: 0.79,
    },
    {
      serial_no: 4,
      candidate: "RAMESH KUMAR",
      party: "Independent",
      evm_votes: 5720,
      postal_votes: 4,
      total_votes: 5724,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 5,
      candidate: "GOMATI DHARAMPAL KATARIA",
      party: "Bharat Rakshak Party (Democratic)",
      evm_votes: 4937,
      postal_votes: 24,
      total_votes: 4961,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 6,
      candidate: "SUKHDEV",
      party: "Independent",
      evm_votes: 4550,
      postal_votes: 9,
      total_votes: 4559,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 7,
      candidate: "RANVEER SINGH",
      party: "Independent",
      evm_votes: 1809,
      postal_votes: 3,
      total_votes: 1812,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 8,
      candidate: "BISAN SINGH",
      party: "Independent",
      evm_votes: 1571,
      postal_votes: 73,
      total_votes: 1644,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 9,
      candidate: "DAULAT RAM PENSIA",
      party: "National Janmandal Party",
      evm_votes: 1178,
      postal_votes: 15,
      total_votes: 1193,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 10,
      candidate: "YUSUF ALI KHAN",
      party: "Independent",
      evm_votes: 1176,
      postal_votes: 54,
      total_votes: 1230,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 11,
      candidate: "SHISHPAL SINGH RANA",
      party: "Bheem Tribal Congress",
      evm_votes: 671,
      postal_votes: 4,
      total_votes: 675,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 12,
      candidate: "NIRANJAN SINGH RATHORE",
      party: "Independent",
      evm_votes: 648,
      postal_votes: 20,
      total_votes: 668,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "ASLAM LILGAR",
      party: "Independent",
      evm_votes: 558,
      postal_votes: 9,
      total_votes: 567,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6583,
      postal_votes: 73,
      total_votes: 6656,
      percentage_of_votes: 0.47,
    },
  ],
  total: {
    evm_votes: 1407789,
    postal_votes: 16856,
    total_votes: 1424645,
  },
};
export const s204 = {
  cs_name: "JHUNJHUNU",
  cs_shortname: "jhunjhunu",
  cs_code: "s204",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "BRIJENDRA SINGH OLA",
      party: "Indian National Congress",
      evm_votes: 541203,
      postal_votes: 11965,
      total_votes: 553168,
      percentage_of_votes: 49.44,
    },
    {
      serial_no: 2,
      candidate: "SHUBHKARAN CHOUDHARY",
      party: "Bharatiya Janata Party",
      evm_votes: 523669,
      postal_votes: 11264,
      total_votes: 534933,
      percentage_of_votes: 47.81,
    },
    {
      serial_no: 3,
      candidate: "SHEKHAWAT RAJENDRA SINGH",
      party: "Independent",
      evm_votes: 7389,
      postal_votes: 301,
      total_votes: 7690,
      percentage_of_votes: 0.69,
    },
    {
      serial_no: 4,
      candidate: "BANSHIDHAR",
      party: "Bahujan Samaj Party",
      evm_votes: 6679,
      postal_votes: 188,
      total_votes: 6867,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 5,
      candidate: "DURGA PRASAD MEENA",
      party: "Ambedkarite Party of India",
      evm_votes: 5440,
      postal_votes: 57,
      total_votes: 5497,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 6,
      candidate: "ALTIF",
      party: "Independent",
      evm_votes: 2025,
      postal_votes: 25,
      total_votes: 2050,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 7,
      candidate: "SATYANARAYAN",
      party: "Bheem Tribal Congress",
      evm_votes: 1127,
      postal_votes: 23,
      total_votes: 1150,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 8,
      candidate: "HAZARI LAL",
      party: "Bahujan Kranti Party (Marxwad-Ambedkarwad)",
      evm_votes: 922,
      postal_votes: 18,
      total_votes: 940,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6454,
      postal_votes: 178,
      total_votes: 6632,
      percentage_of_votes: 0.59,
    },
  ],
  total: {
    evm_votes: 1094908,
    postal_votes: 24019,
    total_votes: 1118927,
  },
};
export const s209 = {
  cs_name: "BHARATPUR",
  cs_shortname: "bharatpur",
  cs_code: "s209",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "SANJNA JATAV",
      party: "Indian National Congress",
      evm_votes: 572808,
      postal_votes: 7082,
      total_votes: 579890,
      percentage_of_votes: 51.18,
    },
    {
      serial_no: 2,
      candidate: "RAMSWAROOP KOLI",
      party: "Bharatiya Janata Party",
      evm_votes: 519269,
      postal_votes: 8638,
      total_votes: 527907,
      percentage_of_votes: 46.59,
    },
    {
      serial_no: 3,
      candidate: "ANJILA JATAV",
      party: "Bahujan Samaj Party",
      evm_votes: 9298,
      postal_votes: 210,
      total_votes: 9508,
      percentage_of_votes: 0.84,
    },
    {
      serial_no: 4,
      candidate: "ANEETA",
      party: "Independent",
      evm_votes: 5027,
      postal_votes: 92,
      total_votes: 5119,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 5,
      candidate: "PURUSHOTTAM LAL",
      party: "Independent",
      evm_votes: 2730,
      postal_votes: 17,
      total_votes: 2747,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 6,
      candidate: "PUSHPENDAR KUMAR",
      party: "Independent",
      evm_votes: 2374,
      postal_votes: 12,
      total_votes: 2386,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 7,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5344,
      postal_votes: 99,
      total_votes: 5443,
      percentage_of_votes: 0.48,
    },
  ],
  total: {
    evm_votes: 1116850,
    postal_votes: 16150,
    total_votes: 1133000,
  },
};
export const s2010 = {
  cs_name: "KARAULI-DHOLPUR",
  cs_shortname: "karaulidholpur",
  cs_code: "s2010",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "BHAJAN LAL JATAV",
      party: "Indian National Congress",
      evm_votes: 525379,
      postal_votes: 4632,
      total_votes: 530011,
      percentage_of_votes: 53.64,
    },
    {
      serial_no: 2,
      candidate: "INDU DEVI",
      party: "Bharatiya Janata Party",
      evm_votes: 426798,
      postal_votes: 4268,
      total_votes: 431066,
      percentage_of_votes: 43.62,
    },
    {
      serial_no: 3,
      candidate: "VIKRAM SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 14005,
      postal_votes: 107,
      total_votes: 14112,
      percentage_of_votes: 1.43,
    },
    {
      serial_no: 4,
      candidate: "RAMKHILADI DHOVI",
      party: "Independent",
      evm_votes: 5426,
      postal_votes: 47,
      total_votes: 5473,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 5,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7402,
      postal_votes: 58,
      total_votes: 7460,
      percentage_of_votes: 0.75,
    },
  ],
  total: {
    evm_votes: 979010,
    postal_votes: 9112,
    total_votes: 988122,
  },
};
export const s2011 = {
  cs_name: "DAUSA",
  cs_shortname: "dausa",
  cs_code: "s2011",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "MURARI LAL MEENA",
      party: "Indian National Congress",
      evm_votes: 636868,
      postal_votes: 9398,
      total_votes: 646266,
      percentage_of_votes: 60.24,
    },
    {
      serial_no: 2,
      candidate: "KANHAIYA LAL MEENA",
      party: "Bharatiya Janata Party",
      evm_votes: 404309,
      postal_votes: 4617,
      total_votes: 408926,
      percentage_of_votes: 38.12,
    },
    {
      serial_no: 3,
      candidate: "SONU DHANKA",
      party: "Bahujan Samaj Party",
      evm_votes: 6752,
      postal_votes: 45,
      total_votes: 6797,
      percentage_of_votes: 0.63,
    },
    {
      serial_no: 4,
      candidate: "DR. RAM ROOP MEENA, ADVOCATE",
      party: "Independent",
      evm_votes: 2701,
      postal_votes: 20,
      total_votes: 2721,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 5,
      candidate: "MOHAN LAL MEENA",
      party: "Independent",
      evm_votes: 2164,
      postal_votes: 14,
      total_votes: 2178,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 6,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5789,
      postal_votes: 57,
      total_votes: 5846,
      percentage_of_votes: 0.54,
    },
  ],
  total: {
    evm_votes: 1058583,
    postal_votes: 14151,
    total_votes: 1072734,
  },
};
export const s2012 = {
  cs_name: "TONK-SAWAI_MADHOPUR",
  cs_shortname: "tonksawaimadhopur",
  cs_code: "s2012",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "HARISH CHANDRA MEENA",
      party: "Indian National Congress",
      evm_votes: 617137,
      postal_votes: 6626,
      total_votes: 623763,
      percentage_of_votes: 50.85,
    },
    {
      serial_no: 2,
      candidate: "SUKHBIR SINGH JAUNAPURIA",
      party: "Bharatiya Janata Party",
      evm_votes: 553309,
      postal_votes: 5505,
      total_votes: 558814,
      percentage_of_votes: 45.56,
    },
    {
      serial_no: 3,
      candidate: "PRAHALAD MALI",
      party: "Bahujan Samaj Party",
      evm_votes: 13089,
      postal_votes: 55,
      total_votes: 13144,
      percentage_of_votes: 1.07,
    },
    {
      serial_no: 4,
      candidate: "GANESH MEENA",
      party: "Indian Peoples Green Party",
      evm_votes: 7425,
      postal_votes: 8,
      total_votes: 7433,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 5,
      candidate: "MAKHAN LAL MEENA",
      party: "Independent",
      evm_votes: 3314,
      postal_votes: 10,
      total_votes: 3324,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 6,
      candidate: "JASRAM MEENA",
      party: "Independent",
      evm_votes: 3044,
      postal_votes: 1,
      total_votes: 3045,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 7,
      candidate: "VIJENDRA",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 2669,
      postal_votes: 28,
      total_votes: 2697,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 8,
      candidate: "GIRRAJ PRASAD MEENA",
      party: "Independent",
      evm_votes: 2512,
      postal_votes: 9,
      total_votes: 2521,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 9,
      candidate: "JAGDISH PRASAD MEENA",
      party: "Bharat Adivasi Party",
      evm_votes: 1366,
      postal_votes: 7,
      total_votes: 1373,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "DULI CHAND SAINI",
      party: "Rajasthan Raj Party",
      evm_votes: 1253,
      postal_votes: 11,
      total_votes: 1264,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "JAGDISH PRASAD SHARMA",
      party: "Bheem Tribal Congress",
      evm_votes: 1023,
      postal_votes: 6,
      total_votes: 1029,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8105,
      postal_votes: 72,
      total_votes: 8177,
      percentage_of_votes: 0.67,
    },
  ],
  total: {
    evm_votes: 1214246,
    postal_votes: 12338,
    total_votes: 1226584,
  },
};
export const s2017 = {
  cs_name: "BARMER",
  cs_shortname: "barmer",
  cs_code: "s2017",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "UMMEDA RAM BENIWAL",
      party: "Indian National Congress",
      evm_votes: 699122,
      postal_votes: 5554,
      total_votes: 704676,
      percentage_of_votes: 41.74,
    },
    {
      serial_no: 2,
      candidate: "RAVINDRA SINGH BHATI",
      party: "Independent",
      evm_votes: 581865,
      postal_votes: 4635,
      total_votes: 586500,
      percentage_of_votes: 34.74,
    },
    {
      serial_no: 3,
      candidate: "KAILASH CHOUDHARY",
      party: "Bharatiya Janata Party",
      evm_votes: 284527,
      postal_votes: 2206,
      total_votes: 286733,
      percentage_of_votes: 16.99,
    },
    {
      serial_no: 4,
      candidate: "TARARAM MEHNA",
      party: "Independent",
      evm_votes: 49768,
      postal_votes: 189,
      total_votes: 49957,
      percentage_of_votes: 2.96,
    },
    {
      serial_no: 5,
      candidate: "PRATAPA RAM",
      party: "Independent",
      evm_votes: 8282,
      postal_votes: 36,
      total_votes: 8318,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 6,
      candidate: "HANIPH",
      party: "Independent",
      evm_votes: 7755,
      postal_votes: 5,
      total_votes: 7760,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 7,
      candidate: "LEELA RAM",
      party: "Bahujan Samaj Party",
      evm_votes: 7557,
      postal_votes: 28,
      total_votes: 7585,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 8,
      candidate: "PRABHU RAM",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 6381,
      postal_votes: 15,
      total_votes: 6396,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 9,
      candidate: "RAMA RAM",
      party: "Independent",
      evm_votes: 6031,
      postal_votes: 10,
      total_votes: 6041,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 10,
      candidate: "POPAT LAL",
      party: "Independent",
      evm_votes: 3117,
      postal_votes: 42,
      total_votes: 3159,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 11,
      candidate: "DEVILAL JAIN",
      party: "Independent",
      evm_votes: 3016,
      postal_votes: 7,
      total_votes: 3023,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 17893,
      postal_votes: 10,
      total_votes: 17903,
      percentage_of_votes: 1.06,
    },
  ],
  total: {
    evm_votes: 1675314,
    postal_votes: 12737,
    total_votes: 1688051,
  },
};
export const s221 = {
  cs_name: "TIRUVALLUR",
  cs_shortname: "tiruvallur",
  cs_code: "s221",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "Sasikanth Senthil",
      party: "Indian National Congress",
      evm_votes: 794608,
      postal_votes: 2348,
      total_votes: 796956,
      percentage_of_votes: 56.21,
    },
    {
      serial_no: 2,
      candidate: "Balaganapathy, V. Pon",
      party: "Bharatiya Janata Party",
      evm_votes: 224042,
      postal_votes: 759,
      total_votes: 224801,
      percentage_of_votes: 15.86,
    },
    {
      serial_no: 3,
      candidate: "Nallathambi, K.",
      party: "Desiya Murpokku Dravida Kazhagam",
      evm_votes: 223550,
      postal_votes: 354,
      total_votes: 223904,
      percentage_of_votes: 15.79,
    },
    {
      serial_no: 4,
      candidate: "Jagadesh Chander, M.",
      party: "Naam Tamilar Katchi",
      evm_votes: 120620,
      postal_votes: 218,
      total_votes: 120838,
      percentage_of_votes: 8.52,
    },
    {
      serial_no: 5,
      candidate: "Tamizhmadhi, D.",
      party: "Bahujan Samaj Party",
      evm_votes: 13772,
      postal_votes: 38,
      total_votes: 13810,
      percentage_of_votes: 0.97,
    },
    {
      serial_no: 6,
      candidate: "Ashok Priyadarshan, S.",
      party: "Desiya Makkal Sakthi Katchi",
      evm_votes: 2937,
      postal_votes: 9,
      total_votes: 2946,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 7,
      candidate: "Malathi",
      party: "Naadaalum Makkal Katchi",
      evm_votes: 2829,
      postal_votes: 24,
      total_votes: 2853,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 8,
      candidate: "Mani, E.",
      party: "Independent",
      evm_votes: 2745,
      postal_votes: 7,
      total_votes: 2752,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 9,
      candidate: "Devandhran, M.",
      party: "Anna MGR Dravida Makkal Kalgam",
      evm_votes: 2001,
      postal_votes: 7,
      total_votes: 2008,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "Manimaran, V.",
      party: "Independent",
      evm_votes: 1921,
      postal_votes: 1,
      total_votes: 1922,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "Balakrishnan, K.",
      party: "Independent",
      evm_votes: 1685,
      postal_votes: 5,
      total_votes: 1690,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "Bharathidasan, S.S.",
      party: "Independent",
      evm_votes: 1619,
      postal_votes: 12,
      total_votes: 1631,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "Shakthivel, A.J.",
      party: "Independent",
      evm_votes: 1605,
      postal_votes: 8,
      total_votes: 1613,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 14,
      candidate: "Sivasankaran, V.",
      party: "Independent",
      evm_votes: 1105,
      postal_votes: 5,
      total_votes: 1110,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 18908,
      postal_votes: 70,
      total_votes: 18978,
      percentage_of_votes: 1.34,
    },
  ],
  total: {
    evm_votes: 1413947,
    postal_votes: 3865,
    total_votes: 1417812,
  },
};
export const s229 = {
  cs_name: "KRISHNAGIRI",
  cs_shortname: "krishnagiri",
  cs_code: "s229",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "GOPINATH K",
      party: "Indian National Congress",
      evm_votes: 491131,
      postal_votes: 1752,
      total_votes: 492883,
      percentage_of_votes: 42.27,
    },
    {
      serial_no: 2,
      candidate: "JAYAPRAKASH V",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 299300,
      postal_votes: 1097,
      total_votes: 300397,
      percentage_of_votes: 25.76,
    },
    {
      serial_no: 3,
      candidate: "NARASIMHAN C",
      party: "Bharatiya Janata Party",
      evm_votes: 212859,
      postal_votes: 1266,
      total_votes: 214125,
      percentage_of_votes: 18.36,
    },
    {
      serial_no: 4,
      candidate: "VIDHYA RANI",
      party: "Naam Tamilar Katchi",
      evm_votes: 106260,
      postal_votes: 823,
      total_votes: 107083,
      percentage_of_votes: 9.18,
    },
    {
      serial_no: 5,
      candidate: "ARUMUGAM M",
      party: "Bharatiya Praja Aikyata Party",
      evm_votes: 6118,
      postal_votes: 36,
      total_votes: 6154,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 6,
      candidate: "DR.SHANMUGAM .A.R",
      party: "Independent",
      evm_votes: 5577,
      postal_votes: 6,
      total_votes: 5583,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 7,
      candidate: "ESHWARAN C",
      party: "Independent",
      evm_votes: 4126,
      postal_votes: 2,
      total_votes: 4128,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 8,
      candidate: "TAMILSELVAN S",
      party: "Bahujan Samaj Party",
      evm_votes: 3665,
      postal_votes: 33,
      total_votes: 3698,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 9,
      candidate: "SWAMINATHAN T G",
      party: "Aravor Munnetra Kazhagam",
      evm_votes: 1969,
      postal_votes: 9,
      total_votes: 1978,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 10,
      candidate: "MANI K",
      party: "Chennai Youth Party",
      evm_votes: 1964,
      postal_votes: 8,
      total_votes: 1972,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 11,
      candidate: "SRINIVASAN K",
      party: "Independent",
      evm_votes: 1619,
      postal_votes: 2,
      total_votes: 1621,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 12,
      candidate: "VENKATESHKUMAR M",
      party: "Independent",
      evm_votes: 1585,
      postal_votes: 5,
      total_votes: 1590,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 13,
      candidate: "ALBERT FRANCIS XAVIER S",
      party: "Independent",
      evm_votes: 1422,
      postal_votes: 1,
      total_votes: 1423,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "SARAVANAKUMAR A",
      party: "Independent",
      evm_votes: 1163,
      postal_votes: 6,
      total_votes: 1169,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "ANAND KUMAR A",
      party: "Independent",
      evm_votes: 1157,
      postal_votes: 3,
      total_votes: 1160,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 16,
      candidate: "SUBRAMANI M",
      party: "Tamilar Makkal Katchi",
      evm_votes: 1156,
      postal_votes: 39,
      total_votes: 1195,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 17,
      candidate: "ANNADURAI K",
      party: "Independent",
      evm_votes: 1154,
      postal_votes: 6,
      total_votes: 1160,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 18,
      candidate: "MAHARAJAN G",
      party: "Independent",
      evm_votes: 991,
      postal_votes: 4,
      total_votes: 995,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 19,
      candidate: "JAGADHEESAN C",
      party: "Viro Ke Vir Indian Party",
      evm_votes: 897,
      postal_votes: 3,
      total_votes: 900,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 20,
      candidate: "VIJAYAKUMAR K P",
      party: "Anaithu India Makkal Katchi",
      evm_votes: 891,
      postal_votes: 46,
      total_votes: 937,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 21,
      candidate: "DEEKSHITH M",
      party: "Karunaadu Party",
      evm_votes: 817,
      postal_votes: 7,
      total_votes: 824,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 22,
      candidate: "RAMESH A",
      party: "Independent",
      evm_votes: 816,
      postal_votes: 3,
      total_votes: 819,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 23,
      candidate: "RAJESHWARI B.H.",
      party: "Independent",
      evm_votes: 793,
      postal_votes: 6,
      total_votes: 799,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 24,
      candidate: "CHANDRAMOHAN K.M.",
      party: "Independent",
      evm_votes: 789,
      postal_votes: 3,
      total_votes: 792,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 25,
      candidate: "DEVAPPA Y",
      party: "Independent",
      evm_votes: 717,
      postal_votes: 7,
      total_votes: 724,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 26,
      candidate: "VEERASAMY C",
      party: "Independent",
      evm_votes: 656,
      postal_votes: 4,
      total_votes: 660,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 27,
      candidate: "YUVARAJ K P U",
      party: "Independent",
      evm_votes: 402,
      postal_votes: 3,
      total_votes: 405,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 28,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10893,
      postal_votes: 89,
      total_votes: 10982,
      percentage_of_votes: 0.94,
    },
  ],
  total: {
    evm_votes: 1160887,
    postal_votes: 5269,
    total_votes: 1166156,
  },
};
export const s2223 = {
  cs_name: "KARUR",
  cs_shortname: "karur",
  cs_code: "s2223",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "JOTHIMANI. S",
      party: "Indian National Congress",
      evm_votes: 531829,
      postal_votes: 3077,
      total_votes: 534906,
      percentage_of_votes: 47.25,
    },
    {
      serial_no: 2,
      candidate: "THANGAVEL. L",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 366209,
      postal_votes: 1881,
      total_votes: 368090,
      percentage_of_votes: 32.52,
    },
    {
      serial_no: 3,
      candidate: "SENTHILNATHAN. V.V",
      party: "Bharatiya Janata Party",
      evm_votes: 101517,
      postal_votes: 965,
      total_votes: 102482,
      percentage_of_votes: 9.05,
    },
    {
      serial_no: 4,
      candidate: "KARUPPAIYA. R",
      party: "Naam Tamilar Katchi",
      evm_votes: 86962,
      postal_votes: 541,
      total_votes: 87503,
      percentage_of_votes: 7.73,
    },
    {
      serial_no: 5,
      candidate: "SANKARAN. S",
      party: "Bahujan Samaj Party",
      evm_votes: 2665,
      postal_votes: 15,
      total_votes: 2680,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 6,
      candidate: "SAKTHIVEL. R",
      party: "Independent",
      evm_votes: 2391,
      postal_votes: 22,
      total_votes: 2413,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 7,
      candidate: "ABILAZAN. N",
      party: "Independent",
      evm_votes: 1916,
      postal_votes: 5,
      total_votes: 1921,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 8,
      candidate: "KARTHIKPERUMAL. R",
      party: "Independent",
      evm_votes: 1808,
      postal_votes: 1,
      total_votes: 1809,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "KATHIRAVAN. P",
      party: "Independent",
      evm_votes: 1445,
      postal_votes: 3,
      total_votes: 1448,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 10,
      candidate: "KARUNAMOORTHY. S",
      party: "Independent",
      evm_votes: 1278,
      postal_votes: 0,
      total_votes: 1278,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 11,
      candidate: "MOHAMED NAZEEM. S",
      party: "Independent",
      evm_votes: 938,
      postal_votes: 0,
      total_votes: 938,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "PALANISAMY. N.A",
      party: "Independent",
      evm_votes: 934,
      postal_votes: 0,
      total_votes: 934,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "SHANMUGAM. N",
      party: "Samaniya Makkal Nala Katchi",
      evm_votes: 924,
      postal_votes: 5,
      total_votes: 929,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "KALAIRAJ. K",
      party: "Independent",
      evm_votes: 809,
      postal_votes: 2,
      total_votes: 811,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 15,
      candidate: "JOTHIMANI. A.K",
      party: "Independent",
      evm_votes: 805,
      postal_votes: 3,
      total_votes: 808,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 16,
      candidate: "KANNAN. T",
      party: "Independent",
      evm_votes: 782,
      postal_votes: 6,
      total_votes: 788,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 17,
      candidate: "JOTHIMANI. S",
      party: "Independent",
      evm_votes: 774,
      postal_votes: 6,
      total_votes: 780,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "SATHASIVAM. R",
      party: "Independent",
      evm_votes: 728,
      postal_votes: 1,
      total_votes: 729,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 19,
      candidate: "MANIKANDAN. K",
      party: "Independent",
      evm_votes: 685,
      postal_votes: 0,
      total_votes: 685,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 20,
      candidate: "KOSAMALAR. P",
      party: "Independent",
      evm_votes: 641,
      postal_votes: 3,
      total_votes: 644,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 21,
      candidate: "MURUGESAN. K",
      party: "Ganasangam Party of India",
      evm_votes: 628,
      postal_votes: 2,
      total_votes: 630,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 22,
      candidate: "MAHESWARAN. A",
      party: "Independent",
      evm_votes: 589,
      postal_votes: 1,
      total_votes: 590,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 23,
      candidate: "REGIS THIYAGARAJAN. V.G.A",
      party: "Independent",
      evm_votes: 570,
      postal_votes: 2,
      total_votes: 572,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 24,
      candidate: "VINCENT. F",
      party: "Anti Corruption Dynamic Party",
      evm_votes: 510,
      postal_votes: 5,
      total_votes: 515,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 25,
      candidate: "NATRAYAN. R",
      party: "Independent",
      evm_votes: 503,
      postal_votes: 0,
      total_votes: 503,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 26,
      candidate: "BANUMATHY. S",
      party: "Independent",
      evm_votes: 494,
      postal_votes: 2,
      total_votes: 496,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 27,
      candidate: "UMADEVI. S",
      party: "Independent",
      evm_votes: 452,
      postal_votes: 1,
      total_votes: 453,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 28,
      candidate: "VARADAN. M",
      party: "Independent",
      evm_votes: 428,
      postal_votes: 1,
      total_votes: 429,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 29,
      candidate: "MURALI. M",
      party: "Independent",
      evm_votes: 417,
      postal_votes: 0,
      total_votes: 417,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 30,
      candidate: "SAKTHIVEL. N",
      party: "Independent",
      evm_votes: 414,
      postal_votes: 1,
      total_votes: 415,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 31,
      candidate: "BABU. G",
      party: "Independent",
      evm_votes: 387,
      postal_votes: 0,
      total_votes: 387,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 32,
      candidate: "JAFAR ALI. A",
      party: "Independent",
      evm_votes: 368,
      postal_votes: 3,
      total_votes: 371,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 33,
      candidate: "PRABHU. G",
      party: "Independent",
      evm_votes: 354,
      postal_votes: 0,
      total_votes: 354,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 34,
      candidate: "PALANIVEL. P",
      party: "Independent",
      evm_votes: 350,
      postal_votes: 0,
      total_votes: 350,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 35,
      candidate: "ANBAZHAGAN. T",
      party: "Independent",
      evm_votes: 338,
      postal_votes: 1,
      total_votes: 339,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 36,
      candidate: "MURUGESAN. P",
      party: "Independent",
      evm_votes: 327,
      postal_votes: 0,
      total_votes: 327,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 37,
      candidate: "SOWMIYA. R",
      party: "Independent",
      evm_votes: 307,
      postal_votes: 3,
      total_votes: 310,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 38,
      candidate: "SATHISHKUMAR. S",
      party: "Independent",
      evm_votes: 304,
      postal_votes: 2,
      total_votes: 306,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 39,
      candidate: "DHURAIYARASAN. P",
      party: "Independent",
      evm_votes: 273,
      postal_votes: 2,
      total_votes: 275,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 40,
      candidate: "VINOTH KUMAR. N",
      party: "Independent",
      evm_votes: 273,
      postal_votes: 3,
      total_votes: 276,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 41,
      candidate: "SATHIS KUMAR. C",
      party: "Independent",
      evm_votes: 258,
      postal_votes: 9,
      total_votes: 267,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 42,
      candidate: "THIRUGNANAM. M",
      party: "Independent",
      evm_votes: 253,
      postal_votes: 5,
      total_votes: 258,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 43,
      candidate: "SATHISH. P",
      party: "Independent",
      evm_votes: 241,
      postal_votes: 3,
      total_votes: 244,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 44,
      candidate: "MAHAMUNI. A",
      party: "Independent",
      evm_votes: 213,
      postal_votes: 0,
      total_votes: 213,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 45,
      candidate: "RAJAPANDIAN. K",
      party: "Independent",
      evm_votes: 207,
      postal_votes: 2,
      total_votes: 209,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 46,
      candidate: "JOTHIVEL. A",
      party: "Independent",
      evm_votes: 206,
      postal_votes: 0,
      total_votes: 206,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 47,
      candidate: "BASKARAN. N",
      party: "Independent",
      evm_votes: 205,
      postal_votes: 0,
      total_votes: 205,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 48,
      candidate: "PUSHPARAJ. N",
      party: "Independent",
      evm_votes: 199,
      postal_votes: 0,
      total_votes: 199,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 49,
      candidate: "NARENDRAKUMAR. G",
      party: "Independent",
      evm_votes: 197,
      postal_votes: 0,
      total_votes: 197,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 50,
      candidate: "PRASANTH. S",
      party: "Independent",
      evm_votes: 194,
      postal_votes: 0,
      total_votes: 194,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 51,
      candidate: "SUBASH MALAYALAM. T",
      party: "Independent",
      evm_votes: 186,
      postal_votes: 0,
      total_votes: 186,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 52,
      candidate: "NAGESVARAN. P",
      party: "Independent",
      evm_votes: 185,
      postal_votes: 0,
      total_votes: 185,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 53,
      candidate: "DANIYA. P",
      party: "Independent",
      evm_votes: 165,
      postal_votes: 0,
      total_votes: 165,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 54,
      candidate: "SIVAKUMAR. V",
      party: "Independent",
      evm_votes: 150,
      postal_votes: 1,
      total_votes: 151,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 55,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8174,
      postal_votes: 101,
      total_votes: 8275,
      percentage_of_votes: 0.73,
    },
  ],
  total: {
    evm_votes: 1125359,
    postal_votes: 6686,
    total_votes: 1132045,
  },
};
export const s2226 = {
  cs_name: "CUDDALORE",
  cs_shortname: "cuddalore",
  cs_code: "s2226",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "M.K. VISHNUPRASAD",
      party: "Indian National Congress",
      evm_votes: 450401,
      postal_votes: 4652,
      total_votes: 455053,
      percentage_of_votes: 44.11,
    },
    {
      serial_no: 2,
      candidate: "P. SIVAKOZHUNDU",
      party: "Desiya Murpokku Dravida Kazhagam",
      evm_votes: 267707,
      postal_votes: 1450,
      total_votes: 269157,
      percentage_of_votes: 26.09,
    },
    {
      serial_no: 3,
      candidate: "THANKAR BACHAN",
      party: "Pattali Makkal Katchi",
      evm_votes: 202372,
      postal_votes: 2872,
      total_votes: 205244,
      percentage_of_votes: 19.9,
    },
    {
      serial_no: 4,
      candidate: "V. MANIVASAGAN",
      party: "Naam Tamilar Katchi",
      evm_votes: 56863,
      postal_votes: 561,
      total_votes: 57424,
      percentage_of_votes: 5.57,
    },
    {
      serial_no: 5,
      candidate: "S. ARIVUDAINAMBI",
      party: "Anti Corruption Dynamic Party",
      evm_votes: 6479,
      postal_votes: 21,
      total_votes: 6500,
      percentage_of_votes: 0.63,
    },
    {
      serial_no: 6,
      candidate: "A. BALAJI",
      party: "Independent",
      evm_votes: 5440,
      postal_votes: 10,
      total_votes: 5450,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 7,
      candidate: "V.THANIGAISELVAN",
      party: "Bahujan Samaj Party",
      evm_votes: 3917,
      postal_votes: 54,
      total_votes: 3971,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 8,
      candidate: "K. MAYAKRISHNAN",
      party: "Veerath Thiyagi Viswanathadoss Thozhilalarkal Katchi",
      evm_votes: 3696,
      postal_votes: 6,
      total_votes: 3702,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 9,
      candidate: "SU.VA. DHATSHANAMOORTHY",
      party: "Independent",
      evm_votes: 3414,
      postal_votes: 5,
      total_votes: 3419,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 10,
      candidate: "S. RAMALINGAM",
      party: "Independent",
      evm_votes: 3208,
      postal_votes: 8,
      total_votes: 3216,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 11,
      candidate: "G. PICHAMUTHU",
      party: "Independent",
      evm_votes: 2186,
      postal_votes: 8,
      total_votes: 2194,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 12,
      candidate: "R. ANANDHI",
      party: "Independent",
      evm_votes: 1677,
      postal_votes: 19,
      total_votes: 1696,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 13,
      candidate: "S. CHAKKARAVARTHY",
      party: "Independent",
      evm_votes: 1484,
      postal_votes: 4,
      total_votes: 1488,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 14,
      candidate: "R. SRINIVASAN",
      party: "Independent",
      evm_votes: 1352,
      postal_votes: 11,
      total_votes: 1363,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 15,
      candidate: "V. DAKSHANAAMURTHY",
      party: "Independent",
      evm_votes: 1276,
      postal_votes: 10,
      total_votes: 1286,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 16,
      candidate: "THANGA MURUGAN",
      party: "Desiya Makkal Sakthi Katchi",
      evm_votes: 1030,
      postal_votes: 6,
      total_votes: 1036,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 17,
      candidate: "R. RAJASEKAR",
      party: "Independent",
      evm_votes: 701,
      postal_votes: 11,
      total_votes: 712,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "R. PRAKASH",
      party: "Independent",
      evm_votes: 694,
      postal_votes: 6,
      total_votes: 700,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 19,
      candidate: "S. RAJAMOHAN",
      party: "Independent",
      evm_votes: 659,
      postal_votes: 1,
      total_votes: 660,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 20,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7084,
      postal_votes: 208,
      total_votes: 7292,
      percentage_of_votes: 0.71,
    },
  ],
  total: {
    evm_votes: 1021640,
    postal_votes: 9923,
    total_votes: 1031563,
  },
};
export const s2228 = {
  cs_name: "MAYILADUTHURAI",
  cs_shortname: "mayiladuthurai",
  cs_code: "s2228",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "SUDHA R",
      party: "Indian National Congress",
      evm_votes: 516534,
      postal_votes: 1925,
      total_votes: 518459,
      percentage_of_votes: 47.67,
    },
    {
      serial_no: 2,
      candidate: "BABU P",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 246083,
      postal_votes: 1193,
      total_votes: 247276,
      percentage_of_votes: 22.73,
    },
    {
      serial_no: 3,
      candidate: "STALIN M K",
      party: "Pattali Makkal Katchi",
      evm_votes: 165770,
      postal_votes: 667,
      total_votes: 166437,
      percentage_of_votes: 15.3,
    },
    {
      serial_no: 4,
      candidate: "KALIYAMMAL P",
      party: "Naam Tamilar Katchi",
      evm_votes: 127276,
      postal_votes: 366,
      total_votes: 127642,
      percentage_of_votes: 11.73,
    },
    {
      serial_no: 5,
      candidate: "KARTHICK A",
      party: "Samaniya Makkal Nala Katchi",
      evm_votes: 3888,
      postal_votes: 3,
      total_votes: 3891,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 6,
      candidate: "ILANCHEZHIAN T",
      party: "Bahujan Samaj Party",
      evm_votes: 2878,
      postal_votes: 20,
      total_votes: 2898,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 7,
      candidate: "BABU S",
      party: "Independent",
      evm_votes: 1832,
      postal_votes: 6,
      total_votes: 1838,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 8,
      candidate: "MANIMARAN T",
      party: "Independent",
      evm_votes: 1710,
      postal_votes: 1,
      total_votes: 1711,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "NAGARAJAN K",
      party: "Independent",
      evm_votes: 1342,
      postal_votes: 5,
      total_votes: 1347,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 10,
      candidate: "SILAMBARASAN D",
      party: "Independent",
      evm_votes: 1255,
      postal_votes: 1,
      total_votes: 1256,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 11,
      candidate: "JAFARULLAH KHAN F",
      party: "Independent",
      evm_votes: 1230,
      postal_votes: 6,
      total_votes: 1236,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 12,
      candidate: "PANDIYARAJAN S",
      party: "Independent",
      evm_votes: 1169,
      postal_votes: 5,
      total_votes: 1174,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 13,
      candidate: "TIMOTHY T",
      party: "Independent",
      evm_votes: 1165,
      postal_votes: 10,
      total_votes: 1175,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 14,
      candidate: "VEDARETHINAM S",
      party: "Anna MGR Dravida Makkal Kalgam",
      evm_votes: 780,
      postal_votes: 2,
      total_votes: 782,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 15,
      candidate: "NITHIYANANTHAM S",
      party: "Naadaalum Makkal Katchi",
      evm_votes: 735,
      postal_votes: 4,
      total_votes: 739,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 16,
      candidate: "SRINIVASAN V",
      party: "Independent",
      evm_votes: 609,
      postal_votes: 8,
      total_votes: 617,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 17,
      candidate: "DHAKSHINAMOORTHY M",
      party: "Independent",
      evm_votes: 518,
      postal_votes: 17,
      total_votes: 535,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 18,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8642,
      postal_votes: 53,
      total_votes: 8695,
      percentage_of_votes: 0.8,
    },
  ],
  total: {
    evm_votes: 1083416,
    postal_votes: 4292,
    total_votes: 1087708,
  },
};
export const s2231 = {
  cs_name: "SIVAGANGA",
  cs_shortname: "sivaganga",
  cs_code: "s2231",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "KARTI P CHIDAMBARAM",
      party: "Indian National Congress",
      evm_votes: 425631,
      postal_votes: 2046,
      total_votes: 427677,
      percentage_of_votes: 40.6,
    },
    {
      serial_no: 2,
      candidate: "XAVIERDASS A",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 221255,
      postal_votes: 758,
      total_votes: 222013,
      percentage_of_votes: 21.08,
    },
    {
      serial_no: 3,
      candidate: "DHEVANATHAN YADAV T",
      party: "Bharatiya Janata Party",
      evm_votes: 194559,
      postal_votes: 1229,
      total_votes: 195788,
      percentage_of_votes: 18.59,
    },
    {
      serial_no: 4,
      candidate: "EZHILARASI",
      party: "Naam Tamilar Katchi",
      evm_votes: 162558,
      postal_votes: 854,
      total_votes: 163412,
      percentage_of_votes: 15.51,
    },
    {
      serial_no: 5,
      candidate: "DHANALAKSHMI M",
      party: "Independent",
      evm_votes: 8494,
      postal_votes: 18,
      total_votes: 8512,
      percentage_of_votes: 0.81,
    },
    {
      serial_no: 6,
      candidate: "GOVINDHARAJU M.G",
      party: "Independent",
      evm_votes: 4231,
      postal_votes: 10,
      total_votes: 4241,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 7,
      candidate: "NAGARAJAN KR",
      party: "Independent",
      evm_votes: 3682,
      postal_votes: 14,
      total_votes: 3696,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 8,
      candidate: "PALANIYAPPAN R",
      party: "Independent",
      evm_votes: 3426,
      postal_votes: 1,
      total_votes: 3427,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 9,
      candidate: "RANJITHKUMAR BALUSWAMY",
      party: "Bahujan Samaj Party",
      evm_votes: 3160,
      postal_votes: 11,
      total_votes: 3171,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 10,
      candidate: "BASKARAN S",
      party: "Independent",
      evm_votes: 2191,
      postal_votes: 3,
      total_votes: 2194,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 11,
      candidate: "KALAISELVAM K",
      party: "Independent",
      evm_votes: 1660,
      postal_votes: 3,
      total_votes: 1663,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 12,
      candidate: "MALAISAMY K",
      party: "Independent",
      evm_votes: 1524,
      postal_votes: 3,
      total_votes: 1527,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 13,
      candidate: "SEKAR A",
      party: "Independent",
      evm_votes: 1285,
      postal_votes: 7,
      total_votes: 1292,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "SELVARAJ P",
      party: "Independent",
      evm_votes: 1108,
      postal_votes: 3,
      total_votes: 1111,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 15,
      candidate: "THILLAIVASAGAM M",
      party: "Vidiyalai Thedum Indhiyargal Party",
      evm_votes: 1068,
      postal_votes: 5,
      total_votes: 1073,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 16,
      candidate: "ASHOK KUMAR K",
      party: "Independent",
      evm_votes: 1030,
      postal_votes: 4,
      total_votes: 1034,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 17,
      candidate: "KARTHICK C",
      party: "Independent",
      evm_votes: 975,
      postal_votes: 6,
      total_votes: 981,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 18,
      candidate: "MUTHARASU PANDIYAN V",
      party: "Independent",
      evm_votes: 824,
      postal_votes: 4,
      total_votes: 828,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 19,
      candidate: "KALAIRAJA B",
      party: "Independent",
      evm_votes: 816,
      postal_votes: 3,
      total_votes: 819,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 20,
      candidate: "KARTHIKAIRAJAN P",
      party: "Independent",
      evm_votes: 762,
      postal_votes: 9,
      total_votes: 771,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 21,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8119,
      postal_votes: 70,
      total_votes: 8189,
      percentage_of_votes: 0.78,
    },
  ],
  total: {
    evm_votes: 1048358,
    postal_votes: 5061,
    total_votes: 1053419,
  },
};
export const s2234 = {
  cs_name: "VIRUDHUNAGAR",
  cs_shortname: "virudhunagar",
  cs_code: "s2234",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "MANICKAM TAGORE B",
      party: "Indian National Congress",
      evm_votes: 382876,
      postal_votes: 2380,
      total_votes: 385256,
      percentage_of_votes: 36.28,
    },
    {
      serial_no: 2,
      candidate: "VIJAYAPRABHAKARAN V",
      party: "Desiya Murpokku Dravida Kazhagam",
      evm_votes: 378243,
      postal_votes: 2634,
      total_votes: 380877,
      percentage_of_votes: 35.87,
    },
    {
      serial_no: 3,
      candidate: "RADIKAA R",
      party: "Bharatiya Janata Party",
      evm_votes: 164149,
      postal_votes: 2122,
      total_votes: 166271,
      percentage_of_votes: 15.66,
    },
    {
      serial_no: 4,
      candidate: "KAUSHIK S",
      party: "Naam Tamilar Katchi",
      evm_votes: 76122,
      postal_votes: 909,
      total_votes: 77031,
      percentage_of_votes: 7.25,
    },
    {
      serial_no: 5,
      candidate: "RAJESH",
      party: "Independent",
      evm_votes: 6167,
      postal_votes: 6,
      total_votes: 6173,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 6,
      candidate: "ASHOK KUMAR M",
      party: "Bharatiya Praja Aikyata Party",
      evm_votes: 5228,
      postal_votes: 65,
      total_votes: 5293,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 7,
      candidate: "SEKAR S",
      party: "Hindu Samaj Party",
      evm_votes: 3436,
      postal_votes: 10,
      total_votes: 3446,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 8,
      candidate: "MANIKANDAN M",
      party: "Independent",
      evm_votes: 3341,
      postal_votes: 5,
      total_votes: 3346,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 9,
      candidate: "MAYAK KANNAN",
      party: "Independent",
      evm_votes: 2749,
      postal_votes: 2,
      total_votes: 2751,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 10,
      candidate: "MAHENDRA RAMAKRISHNAN P",
      party: "Independent",
      evm_votes: 2404,
      postal_votes: 1,
      total_votes: 2405,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 11,
      candidate: "SURESH",
      party: "Bahujan Samaj Party",
      evm_votes: 2378,
      postal_votes: 19,
      total_votes: 2397,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 12,
      candidate: "SELVI K",
      party: "Independent",
      evm_votes: 2165,
      postal_votes: 23,
      total_votes: 2188,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 13,
      candidate: "PANDIYAMMAL R",
      party: "Independent",
      evm_votes: 2128,
      postal_votes: 5,
      total_votes: 2133,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 14,
      candidate: "SUDALAIMANI M",
      party: "Independent",
      evm_votes: 1357,
      postal_votes: 2,
      total_votes: 1359,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 15,
      candidate: "PALANICHAMY P",
      party: "Bahujan Dravida Party",
      evm_votes: 1261,
      postal_votes: 2,
      total_votes: 1263,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 16,
      candidate: "PALANISAMY K",
      party: "Independent",
      evm_votes: 1251,
      postal_votes: 6,
      total_votes: 1257,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 17,
      candidate: "VENKATESWARAN V K",
      party: "Independent",
      evm_votes: 1242,
      postal_votes: 5,
      total_votes: 1247,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 18,
      candidate: "SELVARAJAN V V",
      party: "Independent",
      evm_votes: 1120,
      postal_votes: 1,
      total_votes: 1121,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 19,
      candidate: "MARIEESWARI M",
      party: "Independent",
      evm_votes: 1100,
      postal_votes: 10,
      total_votes: 1110,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 20,
      candidate: "SANKARANARAYANAN N",
      party: "Independent",
      evm_votes: 994,
      postal_votes: 4,
      total_votes: 998,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 21,
      candidate: "SELVAKUMAR M",
      party: "Independent",
      evm_votes: 896,
      postal_votes: 6,
      total_votes: 902,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 22,
      candidate: "MUTHUKANNU C",
      party: "Tamilaga Makkal Nala Katchi",
      evm_votes: 753,
      postal_votes: 8,
      total_votes: 761,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 23,
      candidate: "MARISELVAM S",
      party: "All India People Development Party",
      evm_votes: 708,
      postal_votes: 3,
      total_votes: 711,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 24,
      candidate: "JEYARAJ T",
      party: "Independent",
      evm_votes: 602,
      postal_votes: 8,
      total_votes: 610,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 25,
      candidate: "VEDHA @ DHAMODHARAN",
      party: "Independent",
      evm_votes: 599,
      postal_votes: 1,
      total_votes: 600,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 26,
      candidate: "RAJAGOPAL S",
      party: "Independent",
      evm_votes: 525,
      postal_votes: 2,
      total_votes: 527,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 27,
      candidate: "GANESHAMOORTHI G",
      party: "Independent",
      evm_votes: 457,
      postal_votes: 3,
      total_votes: 460,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 28,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9256,
      postal_votes: 152,
      total_votes: 9408,
      percentage_of_votes: 0.89,
    },
  ],
  total: {
    evm_votes: 1053507,
    postal_votes: 8394,
    total_votes: 1061901,
  },
};
export const s2238 = {
  cs_name: "TIRUNELVELI",
  cs_shortname: "tirunelveli",
  cs_code: "s2238",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "ROBERT BRUCE C",
      party: "Indian National Congress",
      evm_votes: 499493,
      postal_votes: 2803,
      total_votes: 502296,
      percentage_of_votes: 47.06,
    },
    {
      serial_no: 2,
      candidate: "NAINAR NAGENTHRAN",
      party: "Bharatiya Janata Party",
      evm_votes: 334431,
      postal_votes: 2245,
      total_votes: 336676,
      percentage_of_votes: 31.54,
    },
    {
      serial_no: 3,
      candidate: "JANSI RANI M",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 88996,
      postal_votes: 605,
      total_votes: 89601,
      percentage_of_votes: 8.39,
    },
    {
      serial_no: 4,
      candidate: "SATHYA",
      party: "Naam Tamilar Katchi",
      evm_votes: 86918,
      postal_votes: 768,
      total_votes: 87686,
      percentage_of_votes: 8.21,
    },
    {
      serial_no: 5,
      candidate: "POTTAL SUNDARA MUNISWARAN",
      party: "Independent",
      evm_votes: 19802,
      postal_votes: 50,
      total_votes: 19852,
      percentage_of_votes: 1.86,
    },
    {
      serial_no: 6,
      candidate: "ATHISAYAM V",
      party: "Independent",
      evm_votes: 3744,
      postal_votes: 37,
      total_votes: 3781,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 7,
      candidate: "BALASUBRAMANIAN",
      party: "Bahujan Samaj Party",
      evm_votes: 2044,
      postal_votes: 23,
      total_votes: 2067,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 8,
      candidate: "CHINNA MAHARAJA K",
      party: "Independent",
      evm_votes: 1888,
      postal_votes: 7,
      total_votes: 1895,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 9,
      candidate: "DAVID M",
      party: "Independent",
      evm_votes: 1829,
      postal_votes: 7,
      total_votes: 1836,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 10,
      candidate: "SAMUEL LAWRENCE PONNIAH",
      party: "Independent",
      evm_votes: 1493,
      postal_votes: 4,
      total_votes: 1497,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "BISHOP DR GODFREY WASHINGTON NOBLE",
      party: "Aanaithinthiya Jananayaka Pathukappu Kazhagam",
      evm_votes: 1457,
      postal_votes: 18,
      total_votes: 1475,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 12,
      candidate: "LENIN K",
      party: "Independent",
      evm_votes: 1441,
      postal_votes: 9,
      total_votes: 1450,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 13,
      candidate: "CHANDRAN M",
      party: "Veerath Thiyagi Viswanathadoss Thozhilalarkal Katchi",
      evm_votes: 1409,
      postal_votes: 4,
      total_votes: 1413,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 14,
      candidate: "SELVA KUMAR S",
      party: "Bahujan Dravida Party",
      evm_votes: 1317,
      postal_votes: 29,
      total_votes: 1346,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 15,
      candidate: "KUMAR V",
      party: "Puthiya Makkal Tamil Desam Katchi",
      evm_votes: 1057,
      postal_votes: 15,
      total_votes: 1072,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 16,
      candidate: "MUTHURAMAN A",
      party: "Aravor Munnetra Kazhagam",
      evm_votes: 1046,
      postal_votes: 2,
      total_votes: 1048,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 17,
      candidate: "THALAPATHY MURUGAN M",
      party: "Independent",
      evm_votes: 913,
      postal_votes: 4,
      total_votes: 917,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 18,
      candidate: "SIVARAM K",
      party: "Independent",
      evm_votes: 855,
      postal_votes: 20,
      total_votes: 875,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 19,
      candidate: "RAMAKRISHNAN N",
      party: "Nam India Naam Indiyar Katchi",
      evm_votes: 790,
      postal_votes: 0,
      total_votes: 790,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 20,
      candidate: "SURESH",
      party: "Independent",
      evm_votes: 777,
      postal_votes: 11,
      total_votes: 788,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 21,
      candidate: "DR RAJENDRA RETNAM K",
      party: "Independent",
      evm_votes: 685,
      postal_votes: 4,
      total_votes: 689,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 22,
      candidate: "SEVAL KANNAN B ADVOCATE",
      party: "Independent",
      evm_votes: 622,
      postal_votes: 14,
      total_votes: 636,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 23,
      candidate: "RAGHAVAN C M",
      party: "Independent",
      evm_votes: 364,
      postal_votes: 2,
      total_votes: 366,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 24,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7285,
      postal_votes: 111,
      total_votes: 7396,
      percentage_of_votes: 0.69,
    },
  ],
  total: {
    evm_votes: 1060656,
    postal_votes: 6792,
    total_votes: 1067448,
  },
};
export const s2239 = {
  cs_name: "KANNIYAKUMARI",
  cs_shortname: "kanniyakumari",
  cs_code: "s2239",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "VIJAYAKUMAR (Alias) VIJAY VASANTH",
      party: "Indian National Congress",
      evm_votes: 540267,
      postal_votes: 5981,
      total_votes: 546248,
      percentage_of_votes: 53.08,
    },
    {
      serial_no: 2,
      candidate: "RADHAKRISHNAN P",
      party: "Bharatiya Janata Party",
      evm_votes: 361623,
      postal_votes: 4718,
      total_votes: 366341,
      percentage_of_votes: 35.6,
    },
    {
      serial_no: 3,
      candidate: "MARIA JENNIFER CLARA MICHAEL",
      party: "Naam Tamilar Katchi",
      evm_votes: 51893,
      postal_votes: 828,
      total_votes: 52721,
      percentage_of_votes: 5.12,
    },
    {
      serial_no: 4,
      candidate: "PASILIAN NAZERATH",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 40927,
      postal_votes: 466,
      total_votes: 41393,
      percentage_of_votes: 4.02,
    },
    {
      serial_no: 5,
      candidate: "NAGOOR MEERAN PEER MOHAMED U",
      party: "Independent",
      evm_votes: 4412,
      postal_votes: 3,
      total_votes: 4415,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 6,
      candidate: "BERRILA L",
      party: "Independent",
      evm_votes: 3256,
      postal_votes: 6,
      total_votes: 3262,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 7,
      candidate: "BALASUBRAMANIAN T",
      party: "Independent",
      evm_votes: 2047,
      postal_votes: 25,
      total_votes: 2072,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 8,
      candidate: "VIJAYAN G",
      party: "Bahujan Samaj Party",
      evm_votes: 1607,
      postal_votes: 16,
      total_votes: 1623,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "DENNISON V",
      party: "Independent",
      evm_votes: 1274,
      postal_votes: 4,
      total_votes: 1278,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 10,
      candidate: "DR TOM MANOHAR C M",
      party: "Punnagai Desam Party",
      evm_votes: 1034,
      postal_votes: 6,
      total_votes: 1040,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "SATHISH BABU P",
      party: "Independent",
      evm_votes: 747,
      postal_votes: 1,
      total_votes: 748,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "RAJAN SINGH",
      party: "Bahujan Dravida Party",
      evm_votes: 592,
      postal_votes: 6,
      total_votes: 598,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "GEETA M",
      party: "Thakkam Katchi",
      evm_votes: 528,
      postal_votes: 24,
      total_votes: 552,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "SANTHAKUMAR N",
      party: "Independent",
      evm_votes: 521,
      postal_votes: 6,
      total_votes: 527,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "RAMESHKUMAR J L",
      party: "Independent",
      evm_votes: 464,
      postal_votes: 1,
      total_votes: 465,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "ESAKKIMUTHU N",
      party: "Independent",
      evm_votes: 435,
      postal_votes: 6,
      total_votes: 441,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 17,
      candidate: "AYYAPPAN V",
      party: "Independent",
      evm_votes: 358,
      postal_votes: 15,
      total_votes: 373,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 18,
      candidate: "SARAVANAN N",
      party: "Samaniya Makkal Nala Katchi",
      evm_votes: 308,
      postal_votes: 7,
      total_votes: 315,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 19,
      candidate: "VINO JEBA SEELAN T",
      party: "Independent",
      evm_votes: 265,
      postal_votes: 5,
      total_votes: 270,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 20,
      candidate: "KRISHNAN P",
      party: "Independent",
      evm_votes: 250,
      postal_votes: 4,
      total_votes: 254,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 21,
      candidate: "VENKADESH S C",
      party: "Independent",
      evm_votes: 187,
      postal_votes: 1,
      total_votes: 188,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 22,
      candidate: "ANTONY MICHAEL J",
      party: "Independent",
      evm_votes: 166,
      postal_votes: 9,
      total_votes: 175,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 23,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3680,
      postal_votes: 76,
      total_votes: 3756,
      percentage_of_votes: 0.36,
    },
  ],
  total: {
    evm_votes: 1016841,
    postal_votes: 12214,
    total_votes: 1029055,
  },
};
export const s241 = {
  cs_name: "Saharanpur",
  cs_shortname: "saharanpur",
  cs_code: "s241",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "IMRAN MASOOD",
      party: "Indian National Congress",
      evm_votes: 547383,
      postal_votes: 584,
      total_votes: 547967,
      percentage_of_votes: 44.57,
    },
    {
      serial_no: 2,
      candidate: "RAGHAV LAKHANPAL",
      party: "Bharatiya Janata Party",
      evm_votes: 482120,
      postal_votes: 1305,
      total_votes: 483425,
      percentage_of_votes: 39.32,
    },
    {
      serial_no: 3,
      candidate: "MAJID ALI",
      party: "Bahujan Samaj Party",
      evm_votes: 179994,
      postal_votes: 359,
      total_votes: 180353,
      percentage_of_votes: 14.67,
    },
    {
      serial_no: 4,
      candidate: "MOHD INAM",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 5392,
      postal_votes: 8,
      total_votes: 5400,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 5,
      candidate: "SHABNAM",
      party: "Independent",
      evm_votes: 2370,
      postal_votes: 3,
      total_votes: 2373,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 6,
      candidate: "SHAHBAJ",
      party: "Independent",
      evm_votes: 1696,
      postal_votes: 2,
      total_votes: 1698,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 7,
      candidate: "RASHID KHAN",
      party: "Independent",
      evm_votes: 1227,
      postal_votes: 3,
      total_votes: 1230,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 8,
      candidate: "TASMEEM BANO",
      party: "Independent",
      evm_votes: 883,
      postal_votes: 2,
      total_votes: 885,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 9,
      candidate: "RAJKUMAR",
      party: "Independent",
      evm_votes: 807,
      postal_votes: 6,
      total_votes: 813,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 10,
      candidate: "KAMRAN",
      party: "Independent",
      evm_votes: 744,
      postal_votes: 1,
      total_votes: 745,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4559,
      postal_votes: 7,
      total_votes: 4566,
      percentage_of_votes: 0.37,
    },
  ],
  total: {
    evm_votes: 1227175,
    postal_votes: 2280,
    total_votes: 1229455,
  },
};
export const s2430 = {
  cs_name: "Sitapur",
  cs_shortname: "sitapur",
  cs_code: "s2430",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "RAKESH RATHOR",
      party: "Indian National Congress",
      evm_votes: 530467,
      postal_votes: 671,
      total_votes: 531138,
      percentage_of_votes: 48.2,
    },
    {
      serial_no: 2,
      candidate: "RAJESH VERMA",
      party: "Bharatiya Janata Party",
      evm_votes: 441066,
      postal_votes: 431,
      total_votes: 441497,
      percentage_of_votes: 40.06,
    },
    {
      serial_no: 3,
      candidate: "MAHENDRA SINGH YADAV",
      party: "Bahujan Samaj Party",
      evm_votes: 99284,
      postal_votes: 80,
      total_votes: 99364,
      percentage_of_votes: 9.02,
    },
    {
      serial_no: 4,
      candidate: "MO. KASHIF ANSARI",
      party: "Apna Dal (Kamerawadi)",
      evm_votes: 7585,
      postal_votes: 1,
      total_votes: 7586,
      percentage_of_votes: 0.69,
    },
    {
      serial_no: 5,
      candidate: "LEKHRAJ LODHI",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 6734,
      postal_votes: 0,
      total_votes: 6734,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 6,
      candidate: "VIDYAWATI GAUTAM",
      party: "Independent",
      evm_votes: 4193,
      postal_votes: 1,
      total_votes: 4194,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 7,
      candidate: "CHANDRA SHEKHAR VERMA",
      party: "Rashtriya Shoshit Samaj Party",
      evm_votes: 2429,
      postal_votes: 0,
      total_votes: 2429,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 8,
      candidate: "RAM ADHAR VERMA",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 2114,
      postal_votes: 1,
      total_votes: 2115,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6954,
      postal_votes: 4,
      total_votes: 6958,
      percentage_of_votes: 0.63,
    },
  ],
  total: {
    evm_votes: 1100826,
    postal_votes: 1189,
    total_votes: 1102015,
  },
};
export const s2436 = {
  cs_name: "Rae_Bareli",
  cs_shortname: "raebareli",
  cs_code: "s2436",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "RAHUL GANDHI",
      party: "Indian National Congress",
      evm_votes: 686044,
      postal_votes: 1605,
      total_votes: 687649,
      percentage_of_votes: 66.17,
    },
    {
      serial_no: 2,
      candidate: "DINESH PRATAP SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 296703,
      postal_votes: 916,
      total_votes: 297619,
      percentage_of_votes: 28.64,
    },
    {
      serial_no: 3,
      candidate: "THAKUR PRASAD YADAV",
      party: "Bahujan Samaj Party",
      evm_votes: 21588,
      postal_votes: 36,
      total_votes: 21624,
      percentage_of_votes: 2.08,
    },
    {
      serial_no: 4,
      candidate: "DILEEP SINGH",
      party: "Akhil Bhartiya Apna Dal",
      evm_votes: 8676,
      postal_votes: 2,
      total_votes: 8678,
      percentage_of_votes: 0.84,
    },
    {
      serial_no: 5,
      candidate: "HORILAL",
      party: "Independent",
      evm_votes: 5397,
      postal_votes: 2,
      total_votes: 5399,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 6,
      candidate: "SUDARSHAN RAM",
      party: "Bhartiya Panchsheel Party",
      evm_votes: 4343,
      postal_votes: 2,
      total_votes: 4345,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 7,
      candidate: "HIND ROHITASH",
      party: "Manavtawadi Samaj Party",
      evm_votes: 3859,
      postal_votes: 0,
      total_votes: 3859,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 8,
      candidate: "MO MOBIN",
      party: "Apna Dal (Kamerawadi)",
      evm_votes: 2173,
      postal_votes: 1,
      total_votes: 2174,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7861,
      postal_votes: 11,
      total_votes: 7872,
      percentage_of_votes: 0.76,
    },
  ],
  total: {
    evm_votes: 1036644,
    postal_votes: 2575,
    total_votes: 1039219,
  },
};
export const s2437 = {
  cs_name: "Amethi",
  cs_shortname: "amethi",
  cs_code: "s2437",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "KISHORI LAL",
      party: "Indian National Congress",
      evm_votes: 536680,
      postal_votes: 2548,
      total_votes: 539228,
      percentage_of_votes: 54.99,
    },
    {
      serial_no: 2,
      candidate: "SMRITI IRANI",
      party: "Bharatiya Janata Party",
      evm_votes: 370754,
      postal_votes: 1278,
      total_votes: 372032,
      percentage_of_votes: 37.94,
    },
    {
      serial_no: 3,
      candidate: "NANHE SINGH CHAUHAN",
      party: "Bahujan Samaj Party",
      evm_votes: 34418,
      postal_votes: 116,
      total_votes: 34534,
      percentage_of_votes: 3.52,
    },
    {
      serial_no: 4,
      candidate: "SURENDRA KUMAR",
      party: "Independent",
      evm_votes: 5699,
      postal_votes: 0,
      total_votes: 5699,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 5,
      candidate: "DINESH CHANDRA MAURYA",
      party: "Janshakti Samta Party",
      evm_votes: 4569,
      postal_votes: 8,
      total_votes: 4577,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 6,
      candidate: "JAGDAMBA PRASAD YADAV",
      party: "Independent",
      evm_votes: 4019,
      postal_votes: 1,
      total_votes: 4020,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 7,
      candidate: "BHAGWANDIN PASI",
      party: "Moulik Adhikar Party",
      evm_votes: 2333,
      postal_votes: 1,
      total_votes: 2334,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 8,
      candidate: "CHANDRAWATI",
      party: "Independent",
      evm_votes: 2278,
      postal_votes: 13,
      total_votes: 2291,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 9,
      candidate: "MOHD HASAN LAHRI",
      party: "Rashtriya Narayanwadi Vikas Party",
      evm_votes: 1525,
      postal_votes: 2,
      total_votes: 1527,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 10,
      candidate: "KHUSHIRAM",
      party: "Independent",
      evm_votes: 1401,
      postal_votes: 0,
      total_votes: 1401,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "UDAYRAJ",
      party: "Independent",
      evm_votes: 1300,
      postal_votes: 5,
      total_votes: 1305,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 12,
      candidate: "SANTRAM",
      party: "Samajhdar Party",
      evm_votes: 1208,
      postal_votes: 4,
      total_votes: 1212,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "SHAILENDRA KUMAR MISHRA",
      party: "Sanyogwadi Party",
      evm_votes: 1124,
      postal_votes: 4,
      total_votes: 1128,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9371,
      postal_votes: 12,
      total_votes: 9383,
      percentage_of_votes: 0.96,
    },
  ],
  total: {
    evm_votes: 976679,
    postal_votes: 3992,
    total_votes: 980671,
  },
};
export const s2452 = {
  cs_name: "Allahabad",
  cs_shortname: "allahabad",
  cs_code: "s2452",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "UJJWAL RAMAN SINGH",
      party: "Indian National Congress",
      evm_votes: 461100,
      postal_votes: 1045,
      total_votes: 462145,
      percentage_of_votes: 48.8,
    },
    {
      serial_no: 2,
      candidate: "NEERAJ TRIPATHI",
      party: "Bharatiya Janata Party",
      evm_votes: 402659,
      postal_votes: 691,
      total_votes: 403350,
      percentage_of_votes: 42.59,
    },
    {
      serial_no: 3,
      candidate: "RAMESH KUMAR PATEL",
      party: "Bahujan Samaj Party",
      evm_votes: 49045,
      postal_votes: 99,
      total_votes: 49144,
      percentage_of_votes: 5.19,
    },
    {
      serial_no: 4,
      candidate: "GOPAL SWROOP JOSHI",
      party: "Independent",
      evm_votes: 4248,
      postal_votes: 0,
      total_votes: 4248,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 5,
      candidate: "AJEET KUMAR PATEL",
      party: "Pragatisheel Samaj Party",
      evm_votes: 2641,
      postal_votes: 1,
      total_votes: 2642,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 6,
      candidate: "MOOLNIWASI HEMRAJ SINGH",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 2350,
      postal_votes: 5,
      total_votes: 2355,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 7,
      candidate: "GEETA RANI SHARMA",
      party: "Independent",
      evm_votes: 2197,
      postal_votes: 0,
      total_votes: 2197,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 8,
      candidate: "HANSHRAJ KOL",
      party: "Apna Dal (Kamerawadi)",
      evm_votes: 2137,
      postal_votes: 4,
      total_votes: 2141,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 9,
      candidate: "RAJENDRA PRASAD PRAJAPATI",
      party: "Bhagidari Party(P)",
      evm_votes: 1844,
      postal_votes: 3,
      total_votes: 1847,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 10,
      candidate: "AVANEESH KUMAR",
      party: "Independent",
      evm_votes: 1834,
      postal_votes: 1,
      total_votes: 1835,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 11,
      candidate: "ANUJ SWAROOP SHUKLA",
      party: "Independent",
      evm_votes: 1615,
      postal_votes: 1,
      total_votes: 1616,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "SHIV PRASAD VISHWAKARMA",
      party: "Samyak Party",
      evm_votes: 1326,
      postal_votes: 0,
      total_votes: 1326,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 13,
      candidate: "SARVAJEET SINGH",
      party: "Kamera Samaj Party",
      evm_votes: 1177,
      postal_votes: 0,
      total_votes: 1177,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "RAJDHAR SINGH PATEL",
      party: "Rashtriya Samaj Dal (R)",
      evm_votes: 1024,
      postal_votes: 1,
      total_votes: 1025,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9949,
      postal_votes: 3,
      total_votes: 9952,
      percentage_of_votes: 1.05,
    },
  ],
  total: {
    evm_votes: 945146,
    postal_votes: 1854,
    total_votes: 947000,
  },
};
export const s2453 = {
  cs_name: "Barabanki",
  cs_shortname: "barabanki",
  cs_code: "s2453",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "TANUJ PUNIA",
      party: "Indian National Congress",
      evm_votes: 718528,
      postal_votes: 1399,
      total_votes: 719927,
      percentage_of_votes: 55.78,
    },
    {
      serial_no: 2,
      candidate: "RAJRANI RAWAT",
      party: "Bharatiya Janata Party",
      evm_votes: 503452,
      postal_votes: 771,
      total_votes: 504223,
      percentage_of_votes: 39.07,
    },
    {
      serial_no: 3,
      candidate: "SHIV KUMAR DOHAREY",
      party: "Bahujan Samaj Party",
      evm_votes: 39105,
      postal_votes: 72,
      total_votes: 39177,
      percentage_of_votes: 3.04,
    },
    {
      serial_no: 4,
      candidate: "BABOORAM",
      party: "Independent",
      evm_votes: 3669,
      postal_votes: 0,
      total_votes: 3669,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 5,
      candidate: "RAMLAKHAN PASI",
      party: "Independent",
      evm_votes: 2927,
      postal_votes: 1,
      total_votes: 2928,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 6,
      candidate: "MITHALESH KUMARI",
      party: "Independent",
      evm_votes: 2320,
      postal_votes: 1,
      total_votes: 2321,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 7,
      candidate: "DEVATADEEN GAUTAM",
      party: "Independent",
      evm_votes: 2172,
      postal_votes: 1,
      total_votes: 2173,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 8,
      candidate: "ASHA DEVI",
      party: "Swatantrata Abhivyakti Party",
      evm_votes: 2018,
      postal_votes: 2,
      total_votes: 2020,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "PROF. (DR.) RAM GHULAM RAZDAN",
      party: "Public Adhikar Socialist Indian Party",
      evm_votes: 1493,
      postal_votes: 0,
      total_votes: 1493,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 10,
      candidate: "MAHENDRA KUMAR RAWAT",
      party: "Awami Samta Party",
      evm_votes: 1189,
      postal_votes: 1,
      total_votes: 1190,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 11,
      candidate: "OMKAR",
      party: "Independent",
      evm_votes: 1137,
      postal_votes: 1,
      total_votes: 1138,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 12,
      candidate: "ADVOCATE SANTOSH RAWAT",
      party: "Dr. Bhimrao Ambedkar Dal",
      evm_votes: 1076,
      postal_votes: 2,
      total_votes: 1078,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "PREMCHANDRA HARIJAN",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 1075,
      postal_votes: 1,
      total_votes: 1076,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8220,
      postal_votes: 1,
      total_votes: 8221,
      percentage_of_votes: 0.64,
    },
  ],
  total: {
    evm_votes: 1288381,
    postal_votes: 2253,
    total_votes: 1290634,
  },
};
export const s258 = {
  cs_name: "Maldaha_Dakshin",
  cs_shortname: "maldahadakshin",
  cs_code: "s258",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "ISHA KHAN CHOUDHURY",
      party: "Indian National Congress",
      evm_votes: 570966,
      postal_votes: 1429,
      total_votes: 572395,
      percentage_of_votes: 41.79,
    },
    {
      serial_no: 2,
      candidate: "SREERUPA MITRA CHAUDHURY (NIRBHAYA DIDI)",
      party: "Bharatiya Janata Party",
      evm_votes: 441531,
      postal_votes: 2496,
      total_votes: 444027,
      percentage_of_votes: 32.42,
    },
    {
      serial_no: 3,
      candidate: "SHAHNAWAZ ALI RAIHAN",
      party: "All India Trinamool Congress",
      evm_votes: 299825,
      postal_votes: 1201,
      total_votes: 301026,
      percentage_of_votes: 21.98,
    },
    {
      serial_no: 4,
      candidate: "PURNOCHANDRA MANDAL",
      party: "Independent",
      evm_votes: 8381,
      postal_votes: 2,
      total_votes: 8383,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 5,
      candidate: "AHMED HEDATUL HASAN",
      party: "Independent",
      evm_votes: 5735,
      postal_votes: 1,
      total_votes: 5736,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 6,
      candidate: "MD. JAMAL SAIKH",
      party: "Independent",
      evm_votes: 5386,
      postal_votes: 0,
      total_votes: 5386,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 7,
      candidate: "BIKASH RABIDAS",
      party: "Bahujan Samaj Party",
      evm_votes: 5054,
      postal_votes: 11,
      total_votes: 5065,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 8,
      candidate: "BIJOY KUMAR SARKAR",
      party: "Independent",
      evm_votes: 4773,
      postal_votes: 3,
      total_votes: 4776,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 9,
      candidate: "SUSMITA DEY KARJEE",
      party: "Bhartiya Lokmat Rashtrwadi Party",
      evm_votes: 3521,
      postal_votes: 14,
      total_votes: 3535,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 10,
      candidate: "AMAL KUMAR RABIDAS",
      party: "Independent",
      evm_votes: 3263,
      postal_votes: 8,
      total_votes: 3271,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 11,
      candidate: "KAVIR MADAN DAS",
      party: "Ambedkarite Party of India",
      evm_votes: 3137,
      postal_votes: 3,
      total_votes: 3140,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 12,
      candidate: "ASIM MANDAL",
      party: "Jan Sangh Party",
      evm_votes: 2786,
      postal_votes: 2,
      total_votes: 2788,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 13,
      candidate: "MD SHAHNAWAJ RAHMATULLAH",
      party: "Social Democratic Party Of India",
      evm_votes: 2315,
      postal_votes: 8,
      total_votes: 2323,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 14,
      candidate: "JALALUDDIN SARKAR",
      party: "Bharatiya Nyay-Adhikar Raksha Party",
      evm_votes: 2075,
      postal_votes: 0,
      total_votes: 2075,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 15,
      candidate: "ANGSHUDHAR MANDAL",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1178,
      postal_votes: 11,
      total_votes: 1189,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 16,
      candidate: "MD NUR ISLAM SEKH",
      party: "Rashtriya Secular Majlis Party",
      evm_votes: 1130,
      postal_votes: 6,
      total_votes: 1136,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 17,
      candidate: "MD MONIRUL HASAN",
      party: "Jamat-E-Seratul Mustakim",
      evm_votes: 737,
      postal_votes: 4,
      total_votes: 741,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 18,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 2759,
      postal_votes: 38,
      total_votes: 2797,
      percentage_of_votes: 0.2,
    },
  ],
  total: {
    evm_votes: 1364552,
    postal_votes: 5237,
    total_votes: 1369789,
  },
};
export const s264 = {
  cs_name: "KORBA",
  cs_shortname: "korba",
  cs_code: "s264",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "JYOTSNA CHARANDAS MAHANT",
      party: "Indian National Congress",
      evm_votes: 569098,
      postal_votes: 1084,
      total_votes: 570182,
      percentage_of_votes: 46.53,
    },
    {
      serial_no: 2,
      candidate: "SAROJ PANDEY",
      party: "Bharatiya Janata Party",
      evm_votes: 525733,
      postal_votes: 1166,
      total_votes: 526899,
      percentage_of_votes: 43,
    },
    {
      serial_no: 3,
      candidate: "SHYAM SINGH MARKAM",
      party: "Gondvana Gantantra Party",
      evm_votes: 48498,
      postal_votes: 89,
      total_votes: 48587,
      percentage_of_votes: 3.97,
    },
    {
      serial_no: 4,
      candidate: "NIRDOSH KUMAR YADAV",
      party: "Independent",
      evm_votes: 11267,
      postal_votes: 1,
      total_votes: 11268,
      percentage_of_votes: 0.92,
    },
    {
      serial_no: 5,
      candidate: "DUJRAM BOUDDH",
      party: "Bahujan Samaj Party",
      evm_votes: 10689,
      postal_votes: 50,
      total_votes: 10739,
      percentage_of_votes: 0.88,
    },
    {
      serial_no: 6,
      candidate: "JAICHANDRA SONPAKAR",
      party: "Independent",
      evm_votes: 6181,
      postal_votes: 1,
      total_votes: 6182,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 7,
      candidate: "PALAN SINGH",
      party: "Independent",
      evm_votes: 5706,
      postal_votes: 3,
      total_votes: 5709,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 8,
      candidate: "KAMAL DEV",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 4985,
      postal_votes: 3,
      total_votes: 4988,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 9,
      candidate: "DILIP MIRI",
      party: "Independent",
      evm_votes: 3897,
      postal_votes: 5,
      total_votes: 3902,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 10,
      candidate: "KAUSHALYA BAI PORTE",
      party: "Independent",
      evm_votes: 3630,
      postal_votes: 2,
      total_votes: 3632,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 11,
      candidate: "PRATAP BHANU",
      party: "Independent",
      evm_votes: 2500,
      postal_votes: 4,
      total_votes: 2504,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 12,
      candidate: "SANTOSH SHARMA",
      party: "Independent",
      evm_votes: 2475,
      postal_votes: 2,
      total_votes: 2477,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 13,
      candidate: "AMRIKA KARPE",
      party: "Independent",
      evm_votes: 2227,
      postal_votes: 5,
      total_votes: 2232,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 14,
      candidate: "RAJGURU KEWAL GOSWAMI",
      party: "Independent",
      evm_votes: 2202,
      postal_votes: 0,
      total_votes: 2202,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 15,
      candidate: "SHOBRAN SINGH SAIMA",
      party: "Independent",
      evm_votes: 2072,
      postal_votes: 1,
      total_votes: 2073,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 16,
      candidate: "KALYAN SINGH TANWAR",
      party: "Independent",
      evm_votes: 1960,
      postal_votes: 4,
      total_votes: 1964,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 17,
      candidate: "RAJESH PANDEY",
      party: "Independent",
      evm_votes: 1814,
      postal_votes: 0,
      total_votes: 1814,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 18,
      candidate: "SUSHIL KUMAR VISHWAKARMA",
      party: "Bhartiya Janta Secular Party",
      evm_votes: 1684,
      postal_votes: 1,
      total_votes: 1685,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 19,
      candidate: "PRASHANT DANIEL",
      party: "Sarv Adi Dal",
      evm_votes: 1369,
      postal_votes: 23,
      total_votes: 1392,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 20,
      candidate: "REKHA TIWARI",
      party: "Chhattisgarh Vikas Ganga Rashtriya Party",
      evm_votes: 1289,
      postal_votes: 5,
      total_votes: 1294,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 21,
      candidate: "PURUSHOTTAM MANIKPURI",
      party: "Independent",
      evm_votes: 1212,
      postal_votes: 0,
      total_votes: 1212,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 22,
      candidate: "PRIYANKA PATEL",
      party: "Republican Party of India (A)",
      evm_votes: 1197,
      postal_votes: 7,
      total_votes: 1204,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 23,
      candidate: "MAHENDRA KUMAR SHRIWAS (MANJU)",
      party: "Independent",
      evm_votes: 1187,
      postal_votes: 0,
      total_votes: 1187,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 24,
      candidate: "SHEKH RAUF",
      party: "Independent",
      evm_votes: 1168,
      postal_votes: 0,
      total_votes: 1168,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 25,
      candidate: "SHIVPUJAN SINGH",
      party: "Independent",
      evm_votes: 1036,
      postal_votes: 0,
      total_votes: 1036,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 26,
      candidate: "SHANTI BAI MARAVI",
      party: "Independent",
      evm_votes: 858,
      postal_votes: 3,
      total_votes: 861,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 27,
      candidate: "RAMESH DAS MAHANT",
      party: "Independent",
      evm_votes: 823,
      postal_votes: 0,
      total_votes: 823,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 28,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6076,
      postal_votes: 21,
      total_votes: 6097,
      percentage_of_votes: 0.5,
    },
  ],
  total: {
    evm_votes: 1222833,
    postal_votes: 2480,
    total_votes: 1225313,
  },
};
export const s2711 = {
  cs_name: "Khunti",
  cs_shortname: "khunti",
  cs_code: "s2711",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "KALI CHARAN MUNDA",
      party: "Indian National Congress",
      evm_votes: 503997,
      postal_votes: 7650,
      total_votes: 511647,
      percentage_of_votes: 54.62,
    },
    {
      serial_no: 2,
      candidate: "ARJUN MUNDA",
      party: "Bharatiya Janata Party",
      evm_votes: 358076,
      postal_votes: 3896,
      total_votes: 361972,
      percentage_of_votes: 38.64,
    },
    {
      serial_no: 3,
      candidate: "SAVITRI DEVI",
      party: "Bahujan Samaj Party",
      evm_votes: 12202,
      postal_votes: 98,
      total_votes: 12300,
      percentage_of_votes: 1.31,
    },
    {
      serial_no: 4,
      candidate: "BASANT KUMAR LONGA",
      party: "Independent",
      evm_votes: 10735,
      postal_votes: 20,
      total_votes: 10755,
      percentage_of_votes: 1.15,
    },
    {
      serial_no: 5,
      candidate: "ARPANA HANS",
      party: "Jharkhand Party",
      evm_votes: 8450,
      postal_votes: 82,
      total_votes: 8532,
      percentage_of_votes: 0.91,
    },
    {
      serial_no: 6,
      candidate: "PASTAR SANJAY KUMAR TIRKEY",
      party: "Independent",
      evm_votes: 4944,
      postal_votes: 19,
      total_votes: 4963,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 7,
      candidate: "BABITA KACHHAP",
      party: "Bharat Adivasi Party",
      evm_votes: 4507,
      postal_votes: 87,
      total_votes: 4594,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 21814,
      postal_votes: 105,
      total_votes: 21919,
      percentage_of_votes: 2.34,
    },
  ],
  total: {
    evm_votes: 924725,
    postal_votes: 11957,
    total_votes: 936682,
  },
};
export const s2712 = {
  cs_name: "Lohardaga",
  cs_shortname: "lohardaga",
  cs_code: "s2712",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "SUKHDEO BHAGAT",
      party: "Indian National Congress",
      evm_votes: 476112,
      postal_votes: 6926,
      total_votes: 483038,
      percentage_of_votes: 49.95,
    },
    {
      serial_no: 2,
      candidate: "SAMIR ORAON",
      party: "Bharatiya Janata Party",
      evm_votes: 339685,
      postal_votes: 4215,
      total_votes: 343900,
      percentage_of_votes: 35.56,
    },
    {
      serial_no: 3,
      candidate: "CHAMRA LINDA",
      party: "Independent",
      evm_votes: 45082,
      postal_votes: 916,
      total_votes: 45998,
      percentage_of_votes: 4.76,
    },
    {
      serial_no: 4,
      candidate: "MAHENDRA ORAON",
      party: "Communist Party of India",
      evm_votes: 16776,
      postal_votes: 17,
      total_votes: 16793,
      percentage_of_votes: 1.74,
    },
    {
      serial_no: 5,
      candidate: "SHANIYA ORAON",
      party: "Independent",
      evm_votes: 10894,
      postal_votes: 17,
      total_votes: 10911,
      percentage_of_votes: 1.13,
    },
    {
      serial_no: 6,
      candidate: "GIRJANANDAN URAON",
      party: "Bahujan Samaj Party",
      evm_votes: 9103,
      postal_votes: 45,
      total_votes: 9148,
      percentage_of_votes: 0.95,
    },
    {
      serial_no: 7,
      candidate: "MARIANUS TIGGA",
      party: "Independent",
      evm_votes: 9100,
      postal_votes: 31,
      total_votes: 9131,
      percentage_of_votes: 0.94,
    },
    {
      serial_no: 8,
      candidate: "STEPHAN KINDO",
      party: "Independent",
      evm_votes: 7594,
      postal_votes: 5,
      total_votes: 7599,
      percentage_of_votes: 0.79,
    },
    {
      serial_no: 9,
      candidate: "BIHARI BHAGAT",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 7558,
      postal_votes: 44,
      total_votes: 7602,
      percentage_of_votes: 0.79,
    },
    {
      serial_no: 10,
      candidate: "RANJEET BHAGAT",
      party: "Independent",
      evm_votes: 5510,
      postal_votes: 17,
      total_votes: 5527,
      percentage_of_votes: 0.57,
    },
    {
      serial_no: 11,
      candidate: "RAMCHANDRA BHAGAT",
      party: "Lokhit Adhikar Party",
      evm_votes: 4303,
      postal_votes: 7,
      total_votes: 4310,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 12,
      candidate: "PAWAN TIGGA",
      party: "Independent",
      evm_votes: 3685,
      postal_votes: 28,
      total_votes: 3713,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 13,
      candidate: "ARPAN DEO BHAGAT",
      party: "Independent",
      evm_votes: 2796,
      postal_votes: 18,
      total_votes: 2814,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 14,
      candidate: "ARJUN TOPPO",
      party: "Independent",
      evm_votes: 2706,
      postal_votes: 41,
      total_votes: 2747,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 15,
      candidate: "MANI MUNDA",
      party: "Bhagidari Party(P)",
      evm_votes: 2490,
      postal_votes: 20,
      total_votes: 2510,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11304,
      postal_votes: 80,
      total_votes: 11384,
      percentage_of_votes: 1.18,
    },
  ],
  total: {
    evm_votes: 954698,
    postal_votes: 12427,
    total_votes: 967125,
  },
};
export const s292 = {
  cs_name: "Peddapalle",
  cs_shortname: "peddapalle",
  cs_code: "s292",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "VAMSI KRISHNA GADDAM",
      party: "Indian National Congress",
      evm_votes: 470180,
      postal_votes: 5407,
      total_votes: 475587,
      percentage_of_votes: 43.42,
    },
    {
      serial_no: 2,
      candidate: "SRINIVAS GOMASE",
      party: "Bharatiya Janata Party",
      evm_votes: 339107,
      postal_votes: 5116,
      total_votes: 344223,
      percentage_of_votes: 31.43,
    },
    {
      serial_no: 3,
      candidate: "ESHWAR KOPPULA",
      party: "Bharat Rashtra Samithi",
      evm_votes: 191891,
      postal_votes: 1465,
      total_votes: 193356,
      percentage_of_votes: 17.65,
    },
    {
      serial_no: 4,
      candidate: "ERUKULLA RAJA NARSAIAH",
      party: "Bahujan Samaj Party",
      evm_votes: 6730,
      postal_votes: 69,
      total_votes: 6799,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 5,
      candidate: "VENUGOPAL MOYYI",
      party: "Bahujan Mukti Party",
      evm_votes: 5220,
      postal_votes: 3,
      total_votes: 5223,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 6,
      candidate: "MOTHE NARESH",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 4998,
      postal_votes: 9,
      total_votes: 5007,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 7,
      candidate: "RAMESH MANDA",
      party: "Dharma Samaj Party",
      evm_votes: 4314,
      postal_votes: 20,
      total_votes: 4334,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 8,
      candidate: "AMBOJU SUMALATHA",
      party: "Alliance of Democratic Reforms Party",
      evm_votes: 3770,
      postal_votes: 13,
      total_votes: 3783,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 9,
      candidate: "DURGAM RAMULU",
      party: "Independent",
      evm_votes: 3432,
      postal_votes: 2,
      total_votes: 3434,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 10,
      candidate: "MULKALLA RAJENDRAPRASAD",
      party: "Independent",
      evm_votes: 2786,
      postal_votes: 3,
      total_votes: 2789,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 11,
      candidate: "RAMCHANDAR NICHHAKOLA",
      party: "Dalita Bahujana Party",
      evm_votes: 2589,
      postal_votes: 40,
      total_votes: 2629,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 12,
      candidate: "KANDI CHANDU",
      party: "Rashtriya Manav Party",
      evm_votes: 2409,
      postal_votes: 1,
      total_votes: 2410,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 13,
      candidate: "RAMULA KARTHIK",
      party: "Independent",
      evm_votes: 2284,
      postal_votes: 4,
      total_votes: 2288,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 14,
      candidate: "MOLUGU VENKATESH",
      party: "New India Party",
      evm_votes: 2234,
      postal_votes: 35,
      total_votes: 2269,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 15,
      candidate: "ERUGURALA BHAGYALAXMI",
      party: "Pyramid Party of India",
      evm_votes: 2113,
      postal_votes: 12,
      total_votes: 2125,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 16,
      candidate: "GADDAM MARUTHI",
      party: "Independent",
      evm_votes: 2073,
      postal_votes: 8,
      total_votes: 2081,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 17,
      candidate: "NAVEEN NUKALA",
      party: "Independent",
      evm_votes: 2057,
      postal_votes: 10,
      total_votes: 2067,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 18,
      candidate: "MEKALA AKSHAY KUMAR",
      party: "Independent",
      evm_votes: 1899,
      postal_votes: 3,
      total_votes: 1902,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 19,
      candidate: "KATKURI SANDEEP",
      party: "Independent",
      evm_votes: 1867,
      postal_votes: 5,
      total_votes: 1872,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 20,
      candidate: "BOTLA CHANDRAIAH",
      party: "Independent",
      evm_votes: 1744,
      postal_votes: 3,
      total_votes: 1747,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 21,
      candidate: "ERIKILLA RAJESH",
      party: "Independent",
      evm_votes: 1731,
      postal_votes: 3,
      total_votes: 1734,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 22,
      candidate: "AKKAPAKA THIRUPATHI",
      party: "Independent",
      evm_votes: 1662,
      postal_votes: 7,
      total_votes: 1669,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 23,
      candidate: "DURGAM SANTHOSH",
      party: "Prajarajya Samithi",
      evm_votes: 1576,
      postal_votes: 38,
      total_votes: 1614,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 24,
      candidate: "MAMIDIPALLY BAPAIAH",
      party: "Independent",
      evm_votes: 1501,
      postal_votes: 3,
      total_votes: 1504,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 25,
      candidate: "DUDA MAHIPAL",
      party: "Independent",
      evm_votes: 1495,
      postal_votes: 1,
      total_votes: 1496,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 26,
      candidate: "BOODIDHA THIRUPATHI",
      party: "Independent",
      evm_votes: 1392,
      postal_votes: 1,
      total_votes: 1393,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 27,
      candidate: "DAGAM SRINIVAS",
      party: "Independent",
      evm_votes: 1384,
      postal_votes: 4,
      total_votes: 1388,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 28,
      candidate: "KADASI SHEKHAR",
      party: "Independent",
      evm_votes: 1266,
      postal_votes: 6,
      total_votes: 1272,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 29,
      candidate: "DR VASARLA NATHANIAL",
      party: "Independent",
      evm_votes: 1195,
      postal_votes: 22,
      total_votes: 1217,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 30,
      candidate: "AARNAKONDA RAJU",
      party: "Independent",
      evm_votes: 1160,
      postal_votes: 7,
      total_votes: 1167,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 31,
      candidate: "NARESH JANAGAMA",
      party: "Independent",
      evm_votes: 1117,
      postal_votes: 6,
      total_votes: 1123,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 32,
      candidate: "CHILUKA ANAND",
      party: "Yuva Taram Party",
      evm_votes: 1089,
      postal_votes: 4,
      total_votes: 1093,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 33,
      candidate: "TALLAPALLY NARESH",
      party: "Independent",
      evm_votes: 967,
      postal_votes: 1,
      total_votes: 968,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 34,
      candidate: "KURMA MAHENDER",
      party: "Independent",
      evm_votes: 863,
      postal_votes: 2,
      total_votes: 865,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 35,
      candidate: "CHANDANAGIRI SHRINIVAS",
      party: "Independent",
      evm_votes: 766,
      postal_votes: 0,
      total_votes: 766,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 36,
      candidate: "KASHI SATHISH KUMAR",
      party: "Anti Corruption Dynamic Party",
      evm_votes: 745,
      postal_votes: 6,
      total_votes: 751,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 37,
      candidate: "KANAKAIAH MULKALA",
      party: "Socialist Party (India)",
      evm_votes: 709,
      postal_votes: 11,
      total_votes: 720,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 38,
      candidate: "SRINIVAS PANTHUKALA",
      party: "Independent",
      evm_votes: 701,
      postal_votes: 2,
      total_votes: 703,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 39,
      candidate: "KONKATI LINGA MURTHI",
      party: "Independent",
      evm_votes: 659,
      postal_votes: 2,
      total_votes: 661,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 40,
      candidate: "JUPAKA KIRAN",
      party: "Independent",
      evm_votes: 649,
      postal_votes: 4,
      total_votes: 653,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 41,
      candidate: "RAJKUMAR DURGAM",
      party: "Independent",
      evm_votes: 478,
      postal_votes: 1,
      total_votes: 479,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 42,
      candidate: "RAJESHAM RACHARLA",
      party: "Independent",
      evm_votes: 422,
      postal_votes: 1,
      total_votes: 423,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 43,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5591,
      postal_votes: 120,
      total_votes: 5711,
      percentage_of_votes: 0.52,
    },
  ],
  total: {
    evm_votes: 1082815,
    postal_votes: 12480,
    total_votes: 1095295,
  },
};
export const s295 = {
  cs_name: "Zahirabad",
  cs_shortname: "zahirabad",
  cs_code: "s295",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "SURESH KUMAR SHETKAR",
      party: "Indian National Congress",
      evm_votes: 523919,
      postal_votes: 4499,
      total_votes: 528418,
      percentage_of_votes: 42.73,
    },
    {
      serial_no: 2,
      candidate: "B. B. PATIL",
      party: "Bharatiya Janata Party",
      evm_votes: 476023,
      postal_votes: 6207,
      total_votes: 482230,
      percentage_of_votes: 39,
    },
    {
      serial_no: 3,
      candidate: "ANILKUMAR GALI",
      party: "Bharat Rashtra Samithi",
      evm_votes: 171412,
      postal_votes: 666,
      total_votes: 172078,
      percentage_of_votes: 13.92,
    },
    {
      serial_no: 4,
      candidate: "KOTHA BALIJA BASWARAJ",
      party: "Telangana Praja Shakthi Party",
      evm_votes: 8362,
      postal_votes: 11,
      total_votes: 8373,
      percentage_of_votes: 0.68,
    },
    {
      serial_no: 5,
      candidate: "CHAVAGANI MANI",
      party: "Alliance of Democratic Reforms Party",
      evm_votes: 6295,
      postal_votes: 14,
      total_votes: 6309,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 6,
      candidate: "YAKUB SHARIF MD",
      party: "Independent",
      evm_votes: 5484,
      postal_votes: 5,
      total_votes: 5489,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 7,
      candidate: "MAHADHEV SWAMI MATAM",
      party: "Independent",
      evm_votes: 5408,
      postal_votes: 4,
      total_votes: 5412,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 8,
      candidate: "B. MARUTHI RAO",
      party: "Independent",
      evm_votes: 4248,
      postal_votes: 4,
      total_votes: 4252,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 9,
      candidate: "S. RAJU",
      party: "Independent",
      evm_votes: 3511,
      postal_votes: 3,
      total_votes: 3514,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 10,
      candidate: "SATYANARAYANA GOUD KOVURI",
      party: "Independent",
      evm_votes: 2875,
      postal_votes: 1,
      total_votes: 2876,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 11,
      candidate: "RAMESH. SAJJAPUR.",
      party: "Independent",
      evm_votes: 2477,
      postal_votes: 2,
      total_votes: 2479,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 12,
      candidate: "KAMMARI ANANDEESHWAR",
      party: "Independent",
      evm_votes: 2381,
      postal_votes: 3,
      total_votes: 2384,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 13,
      candidate: "JAIPAL NAYAK JADAVATH",
      party: "Independent",
      evm_votes: 2217,
      postal_votes: 51,
      total_votes: 2268,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 14,
      candidate: "GURRAPU MACHANDER",
      party: "All India Forward Bloc",
      evm_votes: 1760,
      postal_votes: 9,
      total_votes: 1769,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 15,
      candidate: "T.D.S. MANI",
      party: "Dharma Samaj Party",
      evm_votes: 1628,
      postal_votes: 27,
      total_votes: 1655,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 16,
      candidate: "MOHD SADDAM",
      party: "Independent",
      evm_votes: 1567,
      postal_votes: 1,
      total_votes: 1568,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 17,
      candidate: "MALEPU MOHAN REDDY",
      party: "Pyramid Party of India",
      evm_votes: 1095,
      postal_votes: 7,
      total_votes: 1102,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 18,
      candidate: "ASHOK TALARI",
      party: "Independent",
      evm_votes: 831,
      postal_votes: 6,
      total_votes: 837,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 19,
      candidate: "ROMALA BABU DURGAIAH",
      party: "India Praja Bandhu Party",
      evm_votes: 601,
      postal_votes: 2,
      total_votes: 603,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 20,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 2933,
      postal_votes: 44,
      total_votes: 2977,
      percentage_of_votes: 0.24,
    },
  ],
  total: {
    evm_votes: 1225027,
    postal_votes: 11566,
    total_votes: 1236593,
  },
};
export const s2912 = {
  cs_name: "Nagarkurnool",
  cs_shortname: "nagarkurnool",
  cs_code: "s2912",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "DR.MALLU RAVI",
      party: "Indian National Congress",
      evm_votes: 459259,
      postal_votes: 5813,
      total_votes: 465072,
      percentage_of_votes: 38.14,
    },
    {
      serial_no: 2,
      candidate: "BHARATH PRASAD POTHUGANTI",
      party: "Bharatiya Janata Party",
      evm_votes: 364898,
      postal_votes: 5760,
      total_votes: 370658,
      percentage_of_votes: 30.4,
    },
    {
      serial_no: 3,
      candidate: "DR.R.S.PRAVEEN KUMAR",
      party: "Bharat Rashtra Samithi",
      evm_votes: 319216,
      postal_votes: 2127,
      total_votes: 321343,
      percentage_of_votes: 26.36,
    },
    {
      serial_no: 4,
      candidate: "AMBOJU RAVI",
      party: "Alliance of Democratic Reforms Party",
      evm_votes: 12544,
      postal_votes: 10,
      total_votes: 12554,
      percentage_of_votes: 1.03,
    },
    {
      serial_no: 5,
      candidate: "BESAMOLLA YOSEF",
      party: "Bahujan Samaj Party",
      evm_votes: 7138,
      postal_votes: 80,
      total_votes: 7218,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 6,
      candidate: "PILLELA SRIKANTH",
      party: "Independent",
      evm_votes: 6403,
      postal_votes: 1,
      total_votes: 6404,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 7,
      candidate: "BUDDHULA SRINIVAS",
      party: "Independent",
      evm_votes: 4358,
      postal_votes: 3,
      total_votes: 4361,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 8,
      candidate: "PALADHI NAGARAJU",
      party: "Independent",
      evm_votes: 4056,
      postal_votes: 2,
      total_votes: 4058,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 9,
      candidate: "AMARNATH",
      party: "India Praja Bandhu Party",
      evm_votes: 3703,
      postal_votes: 6,
      total_votes: 3709,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 10,
      candidate: "PULJALA BALAIAH",
      party: "Independent",
      evm_votes: 3582,
      postal_votes: 2,
      total_votes: 3584,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 11,
      candidate: "GALIMUDI GEETHA",
      party: "Independent",
      evm_votes: 3145,
      postal_votes: 2,
      total_votes: 3147,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 12,
      candidate: "KARNE. SHIREESHA @ BARRELAKKA",
      party: "Independent",
      evm_votes: 3037,
      postal_votes: 50,
      total_votes: 3087,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 13,
      candidate: "PAKIRA RAMULU",
      party: "Independent",
      evm_votes: 2833,
      postal_votes: 6,
      total_votes: 2839,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 14,
      candidate: "BIKSHAPATHI LANDA",
      party: "Independent",
      evm_votes: 1980,
      postal_votes: 1,
      total_votes: 1981,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 15,
      candidate: "AYYAPPA SUNIL",
      party: "Rashtriya Praja Congress (Secular)",
      evm_votes: 1224,
      postal_votes: 3,
      total_votes: 1227,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 16,
      candidate: "GADDAM VIJAY",
      party: "Bahujan Mukti Party",
      evm_votes: 1006,
      postal_votes: 3,
      total_votes: 1009,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 17,
      candidate: "PRASANGI",
      party: "Pyramid Party of India",
      evm_votes: 892,
      postal_votes: 0,
      total_votes: 892,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "VAGGU VINAY",
      party: "Vidhyarthula Rajakiya Party",
      evm_votes: 768,
      postal_votes: 2,
      total_votes: 770,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 19,
      candidate: "DASARI BHARATHI",
      party: "Viduthalai Chiruthaigal Katchi",
      evm_votes: 722,
      postal_votes: 8,
      total_votes: 730,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 20,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4511,
      postal_votes: 69,
      total_votes: 4580,
      percentage_of_votes: 0.38,
    },
  ],
  total: {
    evm_votes: 1205275,
    postal_votes: 13948,
    total_votes: 1219223,
  },
};
export const s2913 = {
  cs_name: "Nalgonda",
  cs_shortname: "nalgonda",
  cs_code: "s2913",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "KUNDURU RAGHUVEER",
      party: "Indian National Congress",
      evm_votes: 772264,
      postal_votes: 12073,
      total_votes: 784337,
      percentage_of_votes: 60.5,
    },
    {
      serial_no: 2,
      candidate: "SAIDI REDDY SHANAMPUDI",
      party: "Bharatiya Janata Party",
      evm_votes: 219606,
      postal_votes: 4826,
      total_votes: 224432,
      percentage_of_votes: 17.31,
    },
    {
      serial_no: 3,
      candidate: "KANCHARLA KRISHNA REDDY",
      party: "Bharat Rashtra Samithi",
      evm_votes: 216681,
      postal_votes: 1736,
      total_votes: 218417,
      percentage_of_votes: 16.85,
    },
    {
      serial_no: 4,
      candidate: "JANAIAH NANDIPATI",
      party: "Telangana Sakalajanula Party",
      evm_votes: 10832,
      postal_votes: 27,
      total_votes: 10859,
      percentage_of_votes: 0.84,
    },
    {
      serial_no: 5,
      candidate: "NAGARAJU BANDARU",
      party: "Independent",
      evm_votes: 7122,
      postal_votes: 6,
      total_votes: 7128,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 6,
      candidate: "ANJAIAH VIRIGINENI",
      party: "Bahujan Samaj Party",
      evm_votes: 7075,
      postal_votes: 102,
      total_votes: 7177,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 7,
      candidate: "MARAM VENKAT REDDY",
      party: "Independent",
      evm_votes: 5962,
      postal_votes: 5,
      total_votes: 5967,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 8,
      candidate: "PANUGOTU LALASINGH",
      party: "Independent",
      evm_votes: 5277,
      postal_votes: 9,
      total_votes: 5286,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 9,
      candidate: "THALARI RAMBABU",
      party: "Dharma Samaj Party",
      evm_votes: 5028,
      postal_votes: 94,
      total_votes: 5122,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 10,
      candidate: "RAMESH SUNKARA",
      party: "Independent",
      evm_votes: 3566,
      postal_votes: 3,
      total_votes: 3569,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 11,
      candidate: "POLISHETTY VENKATESHWARLU",
      party: "Independent",
      evm_votes: 3202,
      postal_votes: 10,
      total_votes: 3212,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 12,
      candidate: "KUKKALA VENKANNA",
      party: "Independent",
      evm_votes: 2561,
      postal_votes: 5,
      total_votes: 2566,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 13,
      candidate: "CHOLLETI PRABHAKAR",
      party: "Independent",
      evm_votes: 2482,
      postal_votes: 22,
      total_votes: 2504,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 14,
      candidate: "GOLI SAIDULU",
      party: "Independent",
      evm_votes: 1770,
      postal_votes: 9,
      total_votes: 1779,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 15,
      candidate: "MARRI NEHEMIAH",
      party: "Independent",
      evm_votes: 1653,
      postal_votes: 9,
      total_votes: 1662,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 16,
      candidate: "RAVI PALAKURI",
      party: "Independent",
      evm_votes: 1545,
      postal_votes: 2,
      total_votes: 1547,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 17,
      candidate: "VASKULA MATTAIAH",
      party: "Marxist Communist Party of India (United)",
      evm_votes: 1033,
      postal_votes: 7,
      total_votes: 1040,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 18,
      candidate: "KUNDARAPU SRIKANTH",
      party: "Independent",
      evm_votes: 940,
      postal_votes: 7,
      total_votes: 947,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 19,
      candidate: "RACHA SUBHADRA REDDY",
      party: "Socialist Party (India)",
      evm_votes: 835,
      postal_votes: 11,
      total_votes: 846,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 20,
      candidate: "LINGAM KRISHNA",
      party: "Independent",
      evm_votes: 666,
      postal_votes: 0,
      total_votes: 666,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 21,
      candidate: "KINNERA YADAIAH",
      party: "Independent",
      evm_votes: 654,
      postal_votes: 7,
      total_votes: 661,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 22,
      candidate: "SHIRISHALA SRINAIAH",
      party: "Independent",
      evm_votes: 513,
      postal_votes: 1,
      total_votes: 514,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 23,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5869,
      postal_votes: 217,
      total_votes: 6086,
      percentage_of_votes: 0.47,
    },
  ],
  total: {
    evm_votes: 1277136,
    postal_votes: 19188,
    total_votes: 1296324,
  },
};
export const s2914 = {
  cs_name: "Bhongir",
  cs_shortname: "bhongir",
  cs_code: "s2914",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "CHAMALA KIRAN KUMAR REDDY",
      party: "Indian National Congress",
      evm_votes: 623762,
      postal_votes: 5381,
      total_votes: 629143,
      percentage_of_votes: 44.89,
    },
    {
      serial_no: 2,
      candidate: "DR. BOORA NARSAIAH GOUD",
      party: "Bharatiya Janata Party",
      evm_votes: 401513,
      postal_votes: 5460,
      total_votes: 406973,
      percentage_of_votes: 29.04,
    },
    {
      serial_no: 3,
      candidate: "KYAMA MALLESH",
      party: "Bharat Rashtra Samithi",
      evm_votes: 254838,
      postal_votes: 1349,
      total_votes: 256187,
      percentage_of_votes: 18.28,
    },
    {
      serial_no: 4,
      candidate: "MAHAMD JAHANGIR",
      party: "Communist Party of India (Marxist)",
      evm_votes: 28412,
      postal_votes: 318,
      total_votes: 28730,
      percentage_of_votes: 2.05,
    },
    {
      serial_no: 5,
      candidate: "RAMESH GOUD THALLAPELLY",
      party: "Alliance of Democratic Reforms Party",
      evm_votes: 8130,
      postal_votes: 16,
      total_votes: 8146,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 6,
      candidate: "AITARAJU ABENDER",
      party: "Bahujan Samaj Party",
      evm_votes: 7703,
      postal_votes: 66,
      total_votes: 7769,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 7,
      candidate: "ASURA BALU",
      party: "Independent",
      evm_votes: 5747,
      postal_votes: 3,
      total_votes: 5750,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 8,
      candidate: "PULIGILLA BIXAPATHI YADAV",
      party: "National Nava Kranthi Party",
      evm_votes: 4718,
      postal_votes: 0,
      total_votes: 4718,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 9,
      candidate: "BUSHIPAKA VENKATAIAH",
      party: "Independent",
      evm_votes: 4674,
      postal_votes: 1,
      total_votes: 4675,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 10,
      candidate: "POOSA SRINIVAS",
      party: "Telangana Rashtra Punahnirmana Samithi",
      evm_votes: 4431,
      postal_votes: 2,
      total_votes: 4433,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 11,
      candidate: "NARENDRAA VEMULA",
      party: "Jai Swaraj Party",
      evm_votes: 4303,
      postal_votes: 1,
      total_votes: 4304,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 12,
      candidate: "KONGARI LINGA SWAMY",
      party: "Dharma Samaj Party",
      evm_votes: 4029,
      postal_votes: 64,
      total_votes: 4093,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 13,
      candidate: "TARIGOPPULA MAHENDER",
      party: "Social Justice Party of India",
      evm_votes: 3088,
      postal_votes: 1,
      total_votes: 3089,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 14,
      candidate: "KARINGULA YADAGIRI",
      party: "Independent",
      evm_votes: 2607,
      postal_votes: 5,
      total_votes: 2612,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 15,
      candidate: "BETHI NARENDER",
      party: "Independent",
      evm_votes: 2168,
      postal_votes: 0,
      total_votes: 2168,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 16,
      candidate: "M. GANESH",
      party: "Pyramid Party of India",
      evm_votes: 1996,
      postal_votes: 14,
      total_votes: 2010,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 17,
      candidate: "MALLEBOINA PARAMESH YADAV",
      party: "Independent",
      evm_votes: 1895,
      postal_votes: 2,
      total_votes: 1897,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 18,
      candidate: "LINGIDI. VENKATESWARLU",
      party: "Prajavani Party",
      evm_votes: 1792,
      postal_votes: 0,
      total_votes: 1792,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 19,
      candidate: "V. SADANANDA REDDY",
      party: "People Protection Party",
      evm_votes: 1734,
      postal_votes: 3,
      total_votes: 1737,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 20,
      candidate: "DHERAVATH GOPI NAIK",
      party: "Jai Maha Bharath Party",
      evm_votes: 1678,
      postal_votes: 4,
      total_votes: 1682,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 21,
      candidate: "PENTA RAMESH",
      party: "Independent",
      evm_votes: 1646,
      postal_votes: 1,
      total_votes: 1647,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 22,
      candidate: "RACHA SUBHADRA REDDY",
      party: "Socialist Party (India)",
      evm_votes: 1444,
      postal_votes: 21,
      total_votes: 1465,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 23,
      candidate: "VARIKUPPALA. KRISHNA VADDERA",
      party: "Independent",
      evm_votes: 1361,
      postal_votes: 5,
      total_votes: 1366,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 24,
      candidate: "BASHABOINA LAKSHMAIAH",
      party: "Independent",
      evm_votes: 1319,
      postal_votes: 1,
      total_votes: 1320,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 25,
      candidate: "NUNE VENKAT SWAMY",
      party: "Independent",
      evm_votes: 919,
      postal_votes: 4,
      total_votes: 923,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 26,
      candidate: "NARRI. SWAMY KURUMA",
      party: "Independent",
      evm_votes: 897,
      postal_votes: 1,
      total_votes: 898,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 27,
      candidate: "JANGA. SUJATHANAVEEN REDDY",
      party: "Independent",
      evm_votes: 847,
      postal_votes: 0,
      total_votes: 847,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 28,
      candidate: "ERRA SURYAM",
      party: "Viduthalai Chiruthaigal Katchi",
      evm_votes: 777,
      postal_votes: 1,
      total_votes: 778,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 29,
      candidate: "KONGARI, MALLAIAH",
      party: "Independent",
      evm_votes: 631,
      postal_votes: 2,
      total_votes: 633,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 30,
      candidate: "DR : MORIGADI KRISHNA",
      party: "Independent",
      evm_votes: 616,
      postal_votes: 1,
      total_votes: 617,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 31,
      candidate: "KANDE. RAMARAJU",
      party: "Independent",
      evm_votes: 577,
      postal_votes: 2,
      total_votes: 579,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 32,
      candidate: "KOTHOJU SRINIVASU",
      party: "National Maha Sabha Party",
      evm_votes: 567,
      postal_votes: 2,
      total_votes: 569,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 33,
      candidate: "AMIREDDY KIRAN REDDY",
      party: "Independent",
      evm_votes: 540,
      postal_votes: 1,
      total_votes: 541,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 34,
      candidate: "CHANDU NAYAK MEGAVATH",
      party: "Independent",
      evm_votes: 501,
      postal_votes: 1,
      total_votes: 502,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 35,
      candidate: "KADIRE KIRAN KUMAR",
      party: "Bahujan Republican Socialist Party",
      evm_votes: 476,
      postal_votes: 2,
      total_votes: 478,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 36,
      candidate: "KARUNAKAR REDDY NALLA",
      party: "Jai Bharat National Party",
      evm_votes: 446,
      postal_votes: 1,
      total_votes: 447,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 37,
      candidate: "KANDADI. MANIPAL REDDY",
      party: "Telangana Republican Party",
      evm_votes: 432,
      postal_votes: 1,
      total_votes: 433,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 38,
      candidate: "UDARI MALLESH",
      party: "Independent",
      evm_votes: 404,
      postal_votes: 2,
      total_votes: 406,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 39,
      candidate: "ANIL KUMAR GADEPAKA",
      party: "Independent",
      evm_votes: 390,
      postal_votes: 3,
      total_votes: 393,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 40,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4571,
      postal_votes: 75,
      total_votes: 4646,
      percentage_of_votes: 0.33,
    },
  ],
  total: {
    evm_votes: 1388579,
    postal_votes: 12817,
    total_votes: 1401396,
  },
};
export const s2915 = {
  cs_name: "Warangal",
  cs_shortname: "warangal",
  cs_code: "s2915",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "KADIYAM KAVYA",
      party: "Indian National Congress",
      evm_votes: 575566,
      postal_votes: 5728,
      total_votes: 581294,
      percentage_of_votes: 45.85,
    },
    {
      serial_no: 2,
      candidate: "AROORI RAMESH",
      party: "Bharatiya Janata Party",
      evm_votes: 355875,
      postal_votes: 5080,
      total_votes: 360955,
      percentage_of_votes: 28.47,
    },
    {
      serial_no: 3,
      candidate: "Dr. MARAPALLY SUDHEER KUMAR",
      party: "Bharat Rashtra Samithi",
      evm_votes: 230820,
      postal_votes: 1213,
      total_votes: 232033,
      percentage_of_votes: 18.3,
    },
    {
      serial_no: 4,
      candidate: "AMBOJU BUDDAIAH",
      party: "Alliance of Democratic Reforms Party",
      evm_votes: 11103,
      postal_votes: 12,
      total_votes: 11115,
      percentage_of_votes: 0.88,
    },
    {
      serial_no: 5,
      candidate: "KALPANA PANJA",
      party: "Bahujan Samaj Party",
      evm_votes: 9230,
      postal_votes: 97,
      total_votes: 9327,
      percentage_of_votes: 0.74,
    },
    {
      serial_no: 6,
      candidate: "KONGARA ANIL KUMAR",
      party: "Independent",
      evm_votes: 6259,
      postal_votes: 0,
      total_votes: 6259,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 7,
      candidate: "ANIL KUMAR GADEPAKA",
      party: "Independent",
      evm_votes: 4482,
      postal_votes: 4,
      total_votes: 4486,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 8,
      candidate: "BUNGA JYOTHI RAMANA",
      party: "Aihra National Party",
      evm_votes: 4262,
      postal_votes: 3,
      total_votes: 4265,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 9,
      candidate: "MEKALA SUMAN",
      party: "Dharma Samaj Party",
      evm_votes: 3972,
      postal_votes: 66,
      total_votes: 4038,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 10,
      candidate: "AR NENA PREM READY RIPEEKA",
      party: "Mana Telangana Rashtra Samaikya Party",
      evm_votes: 3625,
      postal_votes: 9,
      total_votes: 3634,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 11,
      candidate: "KOYYADA NARESH",
      party: "Independent",
      evm_votes: 3283,
      postal_votes: 3,
      total_votes: 3286,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 12,
      candidate: "PRAMEELA JANNU",
      party: "Independent",
      evm_votes: 3022,
      postal_votes: 0,
      total_votes: 3022,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 13,
      candidate: "BARIGELA SHIVA",
      party: "Independent",
      evm_votes: 2947,
      postal_votes: 2,
      total_votes: 2949,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 14,
      candidate: "S P K SAGAR",
      party: "Jai Maha Bharath Party",
      evm_votes: 2769,
      postal_votes: 4,
      total_votes: 2773,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 15,
      candidate: "PASULA RAVI",
      party: "Republican Party of India (Athawale)",
      evm_votes: 2220,
      postal_votes: 19,
      total_votes: 2239,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 16,
      candidate: "DEVARAJU PENDELA",
      party: "Rashtriya Praja Congress (Secular)",
      evm_votes: 2072,
      postal_votes: 11,
      total_votes: 2083,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 17,
      candidate: "POTHARAJU NARASIMHARAJU",
      party: "Independent",
      evm_votes: 1955,
      postal_votes: 0,
      total_votes: 1955,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 18,
      candidate: "BOLLAM VIJAY KUMAR",
      party: "Independent",
      evm_votes: 1821,
      postal_votes: 4,
      total_votes: 1825,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 19,
      candidate: "MACHA DEVENDER",
      party: "Viduthalai Chiruthaigal Katchi",
      evm_votes: 1753,
      postal_votes: 16,
      total_votes: 1769,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 20,
      candidate: "ROOPA POLEPAKA",
      party: "Independent",
      evm_votes: 1657,
      postal_votes: 2,
      total_votes: 1659,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 21,
      candidate: "NARMADA PRAVEEN",
      party: "Pyramid Party of India",
      evm_votes: 1657,
      postal_votes: 7,
      total_votes: 1664,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 22,
      candidate: "DHAYAKAR GAJJI",
      party: "Independent",
      evm_votes: 1637,
      postal_votes: 1,
      total_votes: 1638,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 23,
      candidate: "POGULA ASHOK",
      party: "Independent",
      evm_votes: 1333,
      postal_votes: 4,
      total_votes: 1337,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 24,
      candidate: "THANDRA ESHWAR",
      party: "Independent",
      evm_votes: 1290,
      postal_votes: 2,
      total_votes: 1292,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 25,
      candidate: "MANDA NARESH",
      party: "Independent",
      evm_votes: 1044,
      postal_votes: 7,
      total_votes: 1051,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 26,
      candidate: "KOTHAPALLI SAVITHRI",
      party: "Marxist Communist Party of India (United)",
      evm_votes: 1018,
      postal_votes: 5,
      total_votes: 1023,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 27,
      candidate: "PUTTA VINOD KUMAR",
      party: "Independent",
      evm_votes: 908,
      postal_votes: 1,
      total_votes: 909,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 28,
      candidate: "BOCHU RAJU",
      party: "Independent",
      evm_votes: 905,
      postal_votes: 8,
      total_votes: 913,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 29,
      candidate: "PAITHARA ACHYUTH",
      party: "Independent",
      evm_votes: 901,
      postal_votes: 5,
      total_votes: 906,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 30,
      candidate: "MANGALAPELLI ASHAIAH",
      party: "Independent",
      evm_votes: 847,
      postal_votes: 0,
      total_votes: 847,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 31,
      candidate: "GADDAM CHIRANJEEVI",
      party: "Independent",
      evm_votes: 825,
      postal_votes: 1,
      total_votes: 826,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 32,
      candidate: "MOTHKUPELLY PRABHAKAR",
      party: "Independent",
      evm_votes: 818,
      postal_votes: 1,
      total_votes: 819,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 33,
      candidate: "GANNARAPU RAVINDER",
      party: "Independent",
      evm_votes: 752,
      postal_votes: 0,
      total_votes: 752,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 34,
      candidate: "GANIPAKA PRADEEP",
      party: "Independent",
      evm_votes: 710,
      postal_votes: 3,
      total_votes: 713,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 35,
      candidate: "RAMESH BABU SHANIGARAPU",
      party: "Independent",
      evm_votes: 658,
      postal_votes: 4,
      total_votes: 662,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 36,
      candidate: "Dr GADAM MALLESH",
      party: "Jai Bharat National Party",
      evm_votes: 563,
      postal_votes: 4,
      total_votes: 567,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 37,
      candidate: "THALLAPALLY VENKATAIAH",
      party: "Independent",
      evm_votes: 538,
      postal_votes: 0,
      total_votes: 538,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 38,
      candidate: "BASHIPAKA SUDHAKAR",
      party: "Independent",
      evm_votes: 503,
      postal_votes: 2,
      total_votes: 505,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 39,
      candidate: "RK GANAPURAM",
      party: "Independent",
      evm_votes: 468,
      postal_votes: 6,
      total_votes: 474,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 40,
      candidate: "CHILUVERU PRATHAP",
      party: "Independent",
      evm_votes: 399,
      postal_votes: 2,
      total_votes: 401,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 41,
      candidate: "THATIKAYALA SATHISH BABU",
      party: "Independent",
      evm_votes: 382,
      postal_votes: 2,
      total_votes: 384,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 42,
      candidate: "CHILUMULLA SUJATHA",
      party: "Independent",
      evm_votes: 377,
      postal_votes: 2,
      total_votes: 379,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 43,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8209,
      postal_votes: 171,
      total_votes: 8380,
      percentage_of_votes: 0.66,
    },
  ],
  total: {
    evm_votes: 1255435,
    postal_votes: 12511,
    total_votes: 1267946,
  },
};
export const s2916 = {
  cs_name: "Mahabubabad",
  cs_shortname: "mahabubabad",
  cs_code: "s2916",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "BALRAM NAIK PORIKA",
      party: "Indian National Congress",
      evm_votes: 608505,
      postal_votes: 4269,
      total_votes: 612774,
      percentage_of_votes: 55.27,
    },
    {
      serial_no: 2,
      candidate: "KAVITHA MALOTH",
      party: "Bharat Rashtra Samithi",
      evm_votes: 262416,
      postal_votes: 1193,
      total_votes: 263609,
      percentage_of_votes: 23.77,
    },
    {
      serial_no: 3,
      candidate: "PROFESSOR. AZMEERA SEETARAM NAIK",
      party: "Bharatiya Janata Party",
      evm_votes: 108775,
      postal_votes: 1669,
      total_votes: 110444,
      percentage_of_votes: 9.96,
    },
    {
      serial_no: 4,
      candidate: "ARUN KUMAR MYPATHI",
      party: "All India Forward Bloc",
      evm_votes: 38252,
      postal_votes: 884,
      total_votes: 39136,
      percentage_of_votes: 3.53,
    },
    {
      serial_no: 5,
      candidate: "RAGHU VARMA JATOTHU",
      party: "Alliance of Democratic Reforms Party",
      evm_votes: 11879,
      postal_votes: 14,
      total_votes: 11893,
      percentage_of_votes: 1.07,
    },
    {
      serial_no: 6,
      candidate: "DURGA PALVANCHA",
      party: "Independent",
      evm_votes: 10112,
      postal_votes: 7,
      total_votes: 10119,
      percentage_of_votes: 0.91,
    },
    {
      serial_no: 7,
      candidate: "KONETI SUJATHA",
      party: "Bahujan Samaj Party",
      evm_votes: 8058,
      postal_votes: 35,
      total_votes: 8093,
      percentage_of_votes: 0.73,
    },
    {
      serial_no: 8,
      candidate: "ANIL KUMAR BODA",
      party: "Independent",
      evm_votes: 6419,
      postal_votes: 12,
      total_votes: 6431,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 9,
      candidate: "CHANDA LINGAIAH",
      party: "Independent",
      evm_votes: 5075,
      postal_votes: 25,
      total_votes: 5100,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 10,
      candidate: "MOKALLA MURALIKRISHNA",
      party: "Independent",
      evm_votes: 4056,
      postal_votes: 6,
      total_votes: 4062,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 11,
      candidate: "SOYAM KANNA RAJU",
      party: "Gondvana Gantantra Party",
      evm_votes: 3727,
      postal_votes: 28,
      total_votes: 3755,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 12,
      candidate: "BANOTH PREM NAYAK [BPN]",
      party: "Independent",
      evm_votes: 3707,
      postal_votes: 5,
      total_votes: 3712,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 13,
      candidate: "PAYAM.SINGARAJU",
      party: "Independent",
      evm_votes: 3192,
      postal_votes: 4,
      total_votes: 3196,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 14,
      candidate: "PADIGA YARRAIAH",
      party: "Independent",
      evm_votes: 2873,
      postal_votes: 14,
      total_votes: 2887,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 15,
      candidate: "DV NAIK",
      party: "Independent",
      evm_votes: 2780,
      postal_votes: 1,
      total_votes: 2781,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 16,
      candidate: "GUGULOTH SHEKAR NAIK",
      party: "Independent",
      evm_votes: 2533,
      postal_votes: 9,
      total_votes: 2542,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 17,
      candidate: "RAVVA. BHADRAMMA",
      party: "Dharma Samaj Party",
      evm_votes: 2234,
      postal_votes: 10,
      total_votes: 2244,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 18,
      candidate: "SODE VENKATESWARLU",
      party: "Gondwana Dandkaranya Party",
      evm_votes: 2154,
      postal_votes: 18,
      total_votes: 2172,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 19,
      candidate: "BANOTH LINGYA NAIK",
      party: "Independent",
      evm_votes: 1712,
      postal_votes: 3,
      total_votes: 1715,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 20,
      candidate: "BODA PRASHANTH",
      party: "Independent",
      evm_votes: 1588,
      postal_votes: 2,
      total_votes: 1590,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 21,
      candidate: "BURKA KRISHNAVENI",
      party: "Socialist Party (India)",
      evm_votes: 1438,
      postal_votes: 10,
      total_votes: 1448,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 22,
      candidate: "BANOTHU LINGANNA",
      party: "Blue India Party",
      evm_votes: 1367,
      postal_votes: 0,
      total_votes: 1367,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 23,
      candidate: "VANKUDOTHU RAVI KUMAR (RAVANNA)",
      party: "Telangana Jaghir Party",
      evm_votes: 1117,
      postal_votes: 8,
      total_votes: 1125,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 24,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6511,
      postal_votes: 80,
      total_votes: 6591,
      percentage_of_votes: 0.59,
    },
  ],
  total: {
    evm_votes: 1100480,
    postal_votes: 8306,
    total_votes: 1108786,
  },
};
export const s2917 = {
  cs_name: "Khammam",
  cs_shortname: "khammam",
  cs_code: "s2917",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "RAMASAHAYAM RAGHURAM REDDY",
      party: "Indian National Congress",
      evm_votes: 759603,
      postal_votes: 7326,
      total_votes: 766929,
      percentage_of_votes: 61.29,
    },
    {
      serial_no: 2,
      candidate: "NAMA NAGESWARA RAO",
      party: "Bharat Rashtra Samithi",
      evm_votes: 297592,
      postal_votes: 1490,
      total_votes: 299082,
      percentage_of_votes: 23.9,
    },
    {
      serial_no: 3,
      candidate: "VINOD RAO TANDRA",
      party: "Bharatiya Janata Party",
      evm_votes: 117075,
      postal_votes: 1561,
      total_votes: 118636,
      percentage_of_votes: 9.48,
    },
    {
      serial_no: 4,
      candidate: "VASAM RAMAKRISHNA DORA",
      party: "Independent",
      evm_votes: 6200,
      postal_votes: 15,
      total_votes: 6215,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 5,
      candidate: "ULLENGALA YADAGIRI",
      party: "Independent",
      evm_votes: 6063,
      postal_votes: 1,
      total_votes: 6064,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 6,
      candidate: "ADVOCATE YERRA KAAMESH",
      party: "Bahujan Samaj Party",
      evm_votes: 6030,
      postal_votes: 71,
      total_votes: 6101,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 7,
      candidate: "KUKKALA NAGAIAH",
      party: "Alliance of Democratic Reforms Party",
      evm_votes: 5724,
      postal_votes: 19,
      total_votes: 5743,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 8,
      candidate: "KATUKOJWALA NAGESWAR RAO",
      party: "Independent",
      evm_votes: 4111,
      postal_votes: 3,
      total_votes: 4114,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 9,
      candidate: "CHILAKABATHINI STALIN",
      party: "Independent",
      evm_votes: 3112,
      postal_votes: 1,
      total_votes: 3113,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 10,
      candidate: "SHAIK BAJI BABA",
      party: "Independent",
      evm_votes: 2422,
      postal_votes: 2,
      total_votes: 2424,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 11,
      candidate: "KASIMALLA NAGESWARA RAO",
      party: "Independent",
      evm_votes: 2232,
      postal_votes: 5,
      total_votes: 2237,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 12,
      candidate: "BANDARU NAGARAJU",
      party: "Independent",
      evm_votes: 2122,
      postal_votes: 0,
      total_votes: 2122,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 13,
      candidate: "SHAIK KHALEEL PASHA",
      party: "Independent",
      evm_votes: 2023,
      postal_votes: 5,
      total_votes: 2028,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 14,
      candidate: "EDURUGATLA CHITTI MALLU",
      party: "Independent",
      evm_votes: 1848,
      postal_votes: 6,
      total_votes: 1854,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 15,
      candidate: "GANGIREDDY KOTI REDDY",
      party: "Independent",
      evm_votes: 1789,
      postal_votes: 1,
      total_votes: 1790,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 16,
      candidate: "SHAIK SIRAJUDDIN",
      party: "Independent",
      evm_votes: 1661,
      postal_votes: 2,
      total_votes: 1663,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 17,
      candidate: "SHAIK AJJULU",
      party: "Independent",
      evm_votes: 1342,
      postal_votes: 0,
      total_votes: 1342,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 18,
      candidate: "KOPPULA SRINIVASA RAO",
      party: "Jaibhim Rao Bharat Party",
      evm_votes: 1271,
      postal_votes: 2,
      total_votes: 1273,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 19,
      candidate: "MADDISETTY YERRAPPA @ AJAY",
      party: "Independent",
      evm_votes: 1043,
      postal_votes: 10,
      total_votes: 1053,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 20,
      candidate: "SHAIK MOHMED RASOOL",
      party: "Independent",
      evm_votes: 1021,
      postal_votes: 1,
      total_votes: 1022,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 21,
      candidate: "LINGALA RAVI KUMAR",
      party: "Independent",
      evm_votes: 1017,
      postal_votes: 1,
      total_votes: 1018,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 22,
      candidate: "AVUTAPALLI RAMBABU",
      party: "Independent",
      evm_votes: 948,
      postal_votes: 2,
      total_votes: 950,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 23,
      candidate: "TEJAVATH JOGRAM NAIK",
      party: "Independent",
      evm_votes: 914,
      postal_votes: 5,
      total_votes: 919,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 24,
      candidate: "ANWAR RAICE",
      party: "Socialist Party (India)",
      evm_votes: 754,
      postal_votes: 0,
      total_votes: 754,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 25,
      candidate: "ANANDA PRASAD KASINA",
      party: "Pyramid Party of India",
      evm_votes: 714,
      postal_votes: 4,
      total_votes: 718,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 26,
      candidate: "LINGAIAH DANDA",
      party: "Independent",
      evm_votes: 693,
      postal_votes: 7,
      total_votes: 700,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 27,
      candidate: "ANIL KUMAR MADDINENI",
      party: "Independent",
      evm_votes: 689,
      postal_votes: 4,
      total_votes: 693,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 28,
      candidate: "PAPETLA RAMAMURTHY",
      party: "Sakala Janula Party",
      evm_votes: 659,
      postal_votes: 2,
      total_votes: 661,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 29,
      candidate: "ANTHONI SURESH",
      party: "Bahujana Left Party",
      evm_votes: 607,
      postal_votes: 9,
      total_votes: 616,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 30,
      candidate: "M. RAVI CHANDAR CHOWHAN",
      party: "Independent",
      evm_votes: 597,
      postal_votes: 4,
      total_votes: 601,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 31,
      candidate: "THAMBALLA RAVI",
      party: "Independent",
      evm_votes: 503,
      postal_votes: 5,
      total_votes: 508,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 32,
      candidate: "BHATTU SRINIVAS",
      party: "Jai Bharat National Party",
      evm_votes: 472,
      postal_votes: 4,
      total_votes: 476,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 33,
      candidate: "NAGESWARA RAO LAKAVATH",
      party: "Independent",
      evm_votes: 363,
      postal_votes: 5,
      total_votes: 368,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 34,
      candidate: "MALAMPATI SURYANARAYANA",
      party: "Independent",
      evm_votes: 362,
      postal_votes: 1,
      total_votes: 363,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 35,
      candidate: "MUTYAM ARJUNA RAJU",
      party: "Independent",
      evm_votes: 349,
      postal_votes: 2,
      total_votes: 351,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 36,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6657,
      postal_votes: 125,
      total_votes: 6782,
      percentage_of_votes: 0.54,
    },
  ],
  total: {
    evm_votes: 1240582,
    postal_votes: 10701,
    total_votes: 1251283,
  },
};
export const u021 = {
  cs_name: "Chandigarh",
  cs_shortname: "chandigarh",
  cs_code: "u021",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "MANISH TEWARI",
      party: "Indian National Congress",
      evm_votes: 216350,
      postal_votes: 307,
      total_votes: 216657,
      percentage_of_votes: 48.22,
    },
    {
      serial_no: 2,
      candidate: "SANJAY TANDON",
      party: "Bharatiya Janata Party",
      evm_votes: 213770,
      postal_votes: 383,
      total_votes: 214153,
      percentage_of_votes: 47.67,
    },
    {
      serial_no: 3,
      candidate: "RITU SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 6697,
      postal_votes: 11,
      total_votes: 6708,
      percentage_of_votes: 1.49,
    },
    {
      serial_no: 4,
      candidate: "LAKHVIR SINGH ALIAS KOTLA",
      party: "Independent",
      evm_votes: 2625,
      postal_votes: 1,
      total_votes: 2626,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 5,
      candidate: "DEEPANSHU SHARMA",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 1066,
      postal_votes: 2,
      total_votes: 1068,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 6,
      candidate: "EX-ASSISTANT COMMANDANT RANPREET SINGH",
      party: "Independent",
      evm_votes: 1051,
      postal_votes: 3,
      total_votes: 1054,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 7,
      candidate: "VINOD KUMAR",
      party: "Independent",
      evm_votes: 683,
      postal_votes: 0,
      total_votes: 683,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 8,
      candidate: "SUNIL THAMAN",
      party: "Haryana Jansena Party",
      evm_votes: 576,
      postal_votes: 1,
      total_votes: 577,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 9,
      candidate: "BALJEET SINGH ALIAS LADDI",
      party: "Independent",
      evm_votes: 434,
      postal_votes: 2,
      total_votes: 436,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 10,
      candidate: "SUNIL KUMAR",
      party: "Independent",
      evm_votes: 321,
      postal_votes: 0,
      total_votes: 321,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "PARTAP SINGH RANA",
      party: "Independent",
      evm_votes: 306,
      postal_votes: 1,
      total_votes: 307,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "MAHANT RAVI KANT MUNI",
      party: "Independent",
      evm_votes: 295,
      postal_votes: 0,
      total_votes: 295,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "VIVEK SHARMA",
      party: "Independent",
      evm_votes: 294,
      postal_votes: 0,
      total_votes: 294,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 14,
      candidate: "PIAR CHAND ALIAS KOUNDAL",
      party: "Independent",
      evm_votes: 253,
      postal_votes: 2,
      total_votes: 255,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "KISHOR KUMAR ALIAS BANTI BHAIYA",
      party: "Independent",
      evm_votes: 239,
      postal_votes: 0,
      total_votes: 239,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "RAJINDER KAUR",
      party: "Sainik Samaj Party",
      evm_votes: 215,
      postal_votes: 2,
      total_votes: 217,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 17,
      candidate: "RAJ PRINCE SINGH",
      party: "Super Power India Party",
      evm_votes: 205,
      postal_votes: 0,
      total_votes: 205,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 18,
      candidate: "PUSHPINDER SINGH ALIAS LOVELY ATTAWA",
      party: "Independent",
      evm_votes: 154,
      postal_votes: 0,
      total_votes: 154,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 19,
      candidate: "KULDIP RAI ALIAS HAPPY SOOD MORINDA",
      party: "Independent",
      evm_votes: 114,
      postal_votes: 0,
      total_votes: 114,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 20,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 2900,
      postal_votes: 12,
      total_votes: 2912,
      percentage_of_votes: 0.65,
    },
  ],
  total: {
    evm_votes: 448548,
    postal_votes: 727,
    total_votes: 449275,
  },
};
export const u061 = {
  cs_name: "Lakshadweep",
  cs_shortname: "lakshadweep",
  cs_code: "u061",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "MUHAMMED HAMDULLAH SAYEED",
      party: "Indian National Congress",
      evm_votes: 25405,
      postal_votes: 321,
      total_votes: 25726,
      percentage_of_votes: 52.29,
    },
    {
      serial_no: 2,
      candidate: "MOHAMMED FAIZAL PP",
      party: "Nationalist Congress Party – Sharadchandra Pawar",
      evm_votes: 22837,
      postal_votes: 242,
      total_votes: 23079,
      percentage_of_votes: 46.91,
    },
    {
      serial_no: 3,
      candidate: "YOUSUF TP",
      party: "Nationalist Congress Party",
      evm_votes: 198,
      postal_votes: 3,
      total_votes: 201,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 4,
      candidate: "KOYA",
      party: "Independent",
      evm_votes: 58,
      postal_votes: 3,
      total_votes: 61,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 5,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 132,
      postal_votes: 1,
      total_votes: 133,
      percentage_of_votes: 0.27,
    },
  ],
  total: {
    evm_votes: 48630,
    postal_votes: 570,
    total_votes: 49200,
  },
};
export const u071 = {
  cs_name: "PUDUCHERRY",
  cs_shortname: "puducherry",
  cs_code: "u071",
  winner: "INC",
  stats: [
    {
      serial_no: 1,
      candidate: "VE VAITHILINGAM",
      party: "Indian National Congress",
      evm_votes: 424292,
      postal_votes: 1713,
      total_votes: 426005,
      percentage_of_votes: 52.73,
    },
    {
      serial_no: 2,
      candidate: "A NAMASSIVAYAM",
      party: "Bharatiya Janata Party",
      evm_votes: 288050,
      postal_votes: 1439,
      total_votes: 289489,
      percentage_of_votes: 35.83,
    },
    {
      serial_no: 3,
      candidate: "R MENAGA",
      party: "Naam Tamilar Katchi",
      evm_votes: 39545,
      postal_votes: 58,
      total_votes: 39603,
      percentage_of_votes: 4.9,
    },
    {
      serial_no: 4,
      candidate: "G THAMIZHVENDAN",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 25059,
      postal_votes: 106,
      total_votes: 25165,
      percentage_of_votes: 3.11,
    },
    {
      serial_no: 5,
      candidate: "P SANKARAN",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2057,
      postal_votes: 9,
      total_votes: 2066,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 6,
      candidate: "ARADADI POSIYYA",
      party: "Independent",
      evm_votes: 1540,
      postal_votes: 6,
      total_votes: 1546,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 7,
      candidate: "D ALANGARAVELU",
      party: "Bahujan Samaj Party",
      evm_votes: 1534,
      postal_votes: 6,
      total_votes: 1540,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 8,
      candidate: "K KISHORKUMAR",
      party: "Independent",
      evm_votes: 1240,
      postal_votes: 6,
      total_votes: 1246,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 9,
      candidate: "KOLLANJIAPPAN",
      party: "Independent",
      evm_votes: 1198,
      postal_votes: 1,
      total_votes: 1199,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "M MANIKANDAN",
      party: "Independent",
      evm_votes: 1130,
      postal_votes: 3,
      total_votes: 1133,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "M KRISHNAMURTHY",
      party: "Independent",
      evm_votes: 1052,
      postal_votes: 19,
      total_votes: 1071,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 12,
      candidate: "K PRABHU DEVAN",
      party: "United Republican Party of India",
      evm_votes: 981,
      postal_votes: 8,
      total_votes: 989,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "S BAKTHAVATCHALAM",
      party: "Independent",
      evm_votes: 923,
      postal_votes: 1,
      total_votes: 924,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 14,
      candidate: "S SATHISH KUMAR",
      party: "Independent",
      evm_votes: 842,
      postal_votes: 4,
      total_votes: 846,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "COUTTANE ALIAS DHIVANEEDHE",
      party: "Independent",
      evm_votes: 819,
      postal_votes: 3,
      total_votes: 822,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 16,
      candidate: "E ANANTHAVEL",
      party: "Independent",
      evm_votes: 771,
      postal_votes: 5,
      total_votes: 776,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 17,
      candidate: "S KALA",
      party: "Independent",
      evm_votes: 593,
      postal_votes: 33,
      total_votes: 626,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 18,
      candidate: "P RAJAN",
      party: "Independent",
      evm_votes: 545,
      postal_votes: 2,
      total_votes: 547,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 19,
      candidate: "NIRMALA G",
      party: "Independent",
      evm_votes: 474,
      postal_votes: 1,
      total_votes: 475,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 20,
      candidate: "K ARUNACHALAM",
      party: "Independent",
      evm_votes: 403,
      postal_votes: 2,
      total_votes: 405,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 21,
      candidate: "K RAMADASS",
      party: "Independent",
      evm_votes: 368,
      postal_votes: 4,
      total_votes: 372,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 22,
      candidate: "R RAJA",
      party: "Independent",
      evm_votes: 326,
      postal_votes: 5,
      total_votes: 331,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 23,
      candidate: "D RAVINDIRAN",
      party: "Independent",
      evm_votes: 305,
      postal_votes: 2,
      total_votes: 307,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 24,
      candidate: "S MASCO",
      party: "Independent",
      evm_votes: 248,
      postal_votes: 6,
      total_votes: 254,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 25,
      candidate: "V P MANICKAM",
      party: "Independent",
      evm_votes: 242,
      postal_votes: 2,
      total_votes: 244,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 26,
      candidate: "M MURUGAN",
      party: "Independent",
      evm_votes: 196,
      postal_votes: 0,
      total_votes: 196,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 27,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9756,
      postal_votes: 7,
      total_votes: 9763,
      percentage_of_votes: 1.21,
    },
  ],
  total: {
    evm_votes: 804489,
    postal_votes: 3451,
    total_votes: 807940,
  },
};
export const s242 = {
  cs_name: "Kairana",
  cs_shortname: "kairana",
  cs_code: "s242",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "IQRA CHOUDHARY",
      party: "Samajwadi Party",
      evm_votes: 526887,
      postal_votes: 1126,
      total_votes: 528013,
      percentage_of_votes: 48.9,
    },
    {
      serial_no: 2,
      candidate: "PRADEEP KUMAR",
      party: "Bharatiya Janata Party",
      evm_votes: 456075,
      postal_votes: 2822,
      total_votes: 458897,
      percentage_of_votes: 42.5,
    },
    {
      serial_no: 3,
      candidate: "SRIPAL",
      party: "Bahujan Samaj Party",
      evm_votes: 75978,
      postal_votes: 222,
      total_votes: 76200,
      percentage_of_votes: 7.06,
    },
    {
      serial_no: 4,
      candidate: "JAHID",
      party: "Social Democratic Party Of India",
      evm_votes: 3316,
      postal_votes: 4,
      total_votes: 3320,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 5,
      candidate: "SHOKINDER KUMAR",
      party: "Independent",
      evm_votes: 1983,
      postal_votes: 2,
      total_votes: 1985,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 6,
      candidate: "MANOJ RANA",
      party: "Independent",
      evm_votes: 1925,
      postal_votes: 11,
      total_votes: 1936,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 7,
      candidate: "VIKAS KUMAR",
      party: "Independent",
      evm_votes: 1098,
      postal_votes: 2,
      total_votes: 1100,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 8,
      candidate: "OMBEER",
      party: "Azad Adhikar Sena",
      evm_votes: 1088,
      postal_votes: 8,
      total_votes: 1096,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 9,
      candidate: "VIKRAM SINGH",
      party: "Independent",
      evm_votes: 897,
      postal_votes: 3,
      total_votes: 900,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 10,
      candidate: "PRITI KASHYAP",
      party: "Rashtriya Mazdoor Ekta Party",
      evm_votes: 781,
      postal_votes: 10,
      total_votes: 791,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "VIKRAM SAINI",
      party: "Akhand Bharat Swatantra Party",
      evm_votes: 776,
      postal_votes: 4,
      total_votes: 780,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "YOGESH",
      party: "Independent",
      evm_votes: 664,
      postal_votes: 6,
      total_votes: 670,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "MOHD KADIR",
      party: "Independent",
      evm_votes: 436,
      postal_votes: 6,
      total_votes: 442,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 14,
      candidate: "NAND KISHOOR",
      party: "Aapki Apni Party (Peoples)",
      evm_votes: 420,
      postal_votes: 7,
      total_votes: 427,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3213,
      postal_votes: 36,
      total_votes: 3249,
      percentage_of_votes: 0.3,
    },
  ],
  total: {
    evm_votes: 1075537,
    postal_votes: 4269,
    total_votes: 1079806,
  },
};
export const s243 = {
  cs_name: "Muzaffarnagar",
  cs_shortname: "muzaffarnagar",
  cs_code: "s243",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "HARENDRA SINGH MALIK",
      party: "Samajwadi Party",
      evm_votes: 469804,
      postal_votes: 917,
      total_votes: 470721,
      percentage_of_votes: 43.64,
    },
    {
      serial_no: 2,
      candidate: "SANJEEV KUMAR BALYAN",
      party: "Bharatiya Janata Party",
      evm_votes: 443815,
      postal_votes: 2234,
      total_votes: 446049,
      percentage_of_votes: 41.35,
    },
    {
      serial_no: 3,
      candidate: "DARA SINGH PRAJAPATI",
      party: "Bahujan Samaj Party",
      evm_votes: 143323,
      postal_votes: 384,
      total_votes: 143707,
      percentage_of_votes: 13.32,
    },
    {
      serial_no: 4,
      candidate: "SUNIL TYAGI",
      party: "Independent",
      evm_votes: 7131,
      postal_votes: 36,
      total_votes: 7167,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 5,
      candidate: "KAVITA",
      party: "Rashtrawadi Janlok Party (Satya)",
      evm_votes: 1970,
      postal_votes: 30,
      total_votes: 2000,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 6,
      candidate: "SHASHIKANT SHAMAR",
      party: "Independent",
      evm_votes: 1429,
      postal_votes: 2,
      total_votes: 1431,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 7,
      candidate: "BIRBAL SINGH",
      party: "Vishal Janta Party",
      evm_votes: 1419,
      postal_votes: 2,
      total_votes: 1421,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 8,
      candidate: "NEEL KUMAR",
      party: "Jai Samta Party",
      evm_votes: 858,
      postal_votes: 3,
      total_votes: 861,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 9,
      candidate: "RESHU SHARMA",
      party: "Independent",
      evm_votes: 679,
      postal_votes: 5,
      total_votes: 684,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 10,
      candidate: "ANKUR",
      party: "Independent",
      evm_votes: 362,
      postal_votes: 10,
      total_votes: 372,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 11,
      candidate: "MANUJ VERMA",
      party: "Independent",
      evm_votes: 333,
      postal_votes: 7,
      total_votes: 340,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3884,
      postal_votes: 32,
      total_votes: 3916,
      percentage_of_votes: 0.36,
    },
  ],
  total: {
    evm_votes: 1075007,
    postal_votes: 3662,
    total_votes: 1078669,
  },
};
export const s246 = {
  cs_name: "Moradabad",
  cs_shortname: "moradabad",
  cs_code: "s246",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "RUCHI VIRA",
      party: "Samajwadi Party",
      evm_votes: 636733,
      postal_votes: 630,
      total_votes: 637363,
      percentage_of_votes: 49.67,
    },
    {
      serial_no: 2,
      candidate: "KUNWAR SARVESH KUMAR",
      party: "Bharatiya Janata Party",
      evm_votes: 530176,
      postal_votes: 1425,
      total_votes: 531601,
      percentage_of_votes: 41.43,
    },
    {
      serial_no: 3,
      candidate: "MO IRFAN",
      party: "Bahujan Samaj Party",
      evm_votes: 92116,
      postal_votes: 197,
      total_votes: 92313,
      percentage_of_votes: 7.19,
    },
    {
      serial_no: 4,
      candidate: "AJAY YADAV",
      party: "Apna Haq Party",
      evm_votes: 4084,
      postal_votes: 10,
      total_votes: 4094,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 5,
      candidate: "MUSHARRAT HUSAIN",
      party: "Independent",
      evm_votes: 2583,
      postal_votes: 0,
      total_votes: 2583,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 6,
      candidate: "SADHNA SINGH",
      party: "Independent",
      evm_votes: 2292,
      postal_votes: 4,
      total_votes: 2296,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 7,
      candidate: "MOHD JAMSHED",
      party: "Independent",
      evm_votes: 1717,
      postal_votes: 2,
      total_votes: 1719,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 8,
      candidate: "ONKAR SINGH",
      party: "Bharatiya Bahujan Samta Party",
      evm_votes: 1449,
      postal_votes: 10,
      total_votes: 1459,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 9,
      candidate: "SHAKIL AHMED",
      party: "Samata Party",
      evm_votes: 1356,
      postal_votes: 0,
      total_votes: 1356,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "HAR KISHORE SINGH",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 940,
      postal_votes: 0,
      total_votes: 940,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "AMARJEET SINGH JATAV",
      party: "Independent",
      evm_votes: 937,
      postal_votes: 15,
      total_votes: 952,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "GANGARAM SHARMA",
      party: "Rashtriya Congress(J) Party",
      evm_votes: 629,
      postal_votes: 56,
      total_votes: 685,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5807,
      postal_votes: 17,
      total_votes: 5824,
      percentage_of_votes: 0.45,
    },
  ],
  total: {
    evm_votes: 1280819,
    postal_votes: 2366,
    total_votes: 1283185,
  },
};
export const s247 = {
  cs_name: "Rampur",
  cs_shortname: "rampur",
  cs_code: "s247",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "MOHIBBULLAH",
      party: "Samajwadi Party",
      evm_votes: 481252,
      postal_votes: 251,
      total_votes: 481503,
      percentage_of_votes: 49.74,
    },
    {
      serial_no: 2,
      candidate: "GHANSHYAM SINGH LODHI",
      party: "Bharatiya Janata Party",
      evm_votes: 393154,
      postal_votes: 915,
      total_votes: 394069,
      percentage_of_votes: 40.71,
    },
    {
      serial_no: 3,
      candidate: "ZEESHAN KHAN",
      party: "Bahujan Samaj Party",
      evm_votes: 79561,
      postal_votes: 131,
      total_votes: 79692,
      percentage_of_votes: 8.23,
    },
    {
      serial_no: 4,
      candidate: "ARSHAD WARSI",
      party: "Minorities Democratic Party",
      evm_votes: 2919,
      postal_votes: 8,
      total_votes: 2927,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 5,
      candidate: "MEHMOOD PRACHA",
      party: "Independent",
      evm_votes: 2750,
      postal_votes: 9,
      total_votes: 2759,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 6,
      candidate: "SHIV PRASAD",
      party: "Independent",
      evm_votes: 1465,
      postal_votes: 11,
      total_votes: 1476,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 7,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5643,
      postal_votes: 10,
      total_votes: 5653,
      percentage_of_votes: 0.58,
    },
  ],
  total: {
    evm_votes: 966744,
    postal_votes: 1335,
    total_votes: 968079,
  },
};
export const s248 = {
  cs_name: "Sambhal",
  cs_shortname: "sambhal",
  cs_code: "s248",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "ZIA UR REHMAN",
      party: "Samajwadi Party",
      evm_votes: 570745,
      postal_votes: 416,
      total_votes: 571161,
      percentage_of_votes: 47.8,
    },
    {
      serial_no: 2,
      candidate: "PARMESHWAR LAL SAINI",
      party: "Bharatiya Janata Party",
      evm_votes: 448983,
      postal_votes: 684,
      total_votes: 449667,
      percentage_of_votes: 37.63,
    },
    {
      serial_no: 3,
      candidate: "CHAUDHARY SAULAT ALI",
      party: "Bahujan Samaj Party",
      evm_votes: 152317,
      postal_votes: 143,
      total_votes: 152460,
      percentage_of_votes: 12.76,
    },
    {
      serial_no: 4,
      candidate: "WASEEM SO MOH SHAMIM",
      party: "Independent",
      evm_votes: 3863,
      postal_votes: 1,
      total_votes: 3864,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 5,
      candidate: "MOHD WASEEM SO MOHD FAREED",
      party: "Independent",
      evm_votes: 2077,
      postal_votes: 0,
      total_votes: 2077,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 6,
      candidate: "JAYGAMUL ISLAM",
      party: "Janta Shashan Party",
      evm_votes: 1940,
      postal_votes: 2,
      total_votes: 1942,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 7,
      candidate: "ANUJ VARSHNEY",
      party: "Independent",
      evm_votes: 1674,
      postal_votes: 3,
      total_votes: 1677,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 8,
      candidate: "MAHANDRA SINGH",
      party: "Independent",
      evm_votes: 1291,
      postal_votes: 3,
      total_votes: 1294,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 9,
      candidate: "SANTOSH",
      party: "Kisan Kranti Dal",
      evm_votes: 1201,
      postal_votes: 3,
      total_votes: 1204,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 10,
      candidate: "DR RASHID",
      party: "Peace Party",
      evm_votes: 1059,
      postal_votes: 1,
      total_votes: 1060,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 11,
      candidate: "TAUFIK AHAMAD",
      party: "Independent",
      evm_votes: 722,
      postal_votes: 1,
      total_votes: 723,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "PAVAN KUMAR",
      party: "Log Party",
      evm_votes: 636,
      postal_votes: 1,
      total_votes: 637,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7213,
      postal_votes: 4,
      total_votes: 7217,
      percentage_of_votes: 0.6,
    },
  ],
  total: {
    evm_votes: 1193721,
    postal_votes: 1262,
    total_votes: 1194983,
  },
};
export const s2420 = {
  cs_name: "Firozabad",
  cs_shortname: "firozabad",
  cs_code: "s2420",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "AKSHAYA YADAV",
      party: "Samajwadi Party",
      evm_votes: 541861,
      postal_votes: 1176,
      total_votes: 543037,
      percentage_of_votes: 49.04,
    },
    {
      serial_no: 2,
      candidate: "VISHWADEEP SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 452946,
      postal_votes: 779,
      total_votes: 453725,
      percentage_of_votes: 40.98,
    },
    {
      serial_no: 3,
      candidate: "CHOWDHARY BASHEER",
      party: "Bahujan Samaj Party",
      evm_votes: 90849,
      postal_votes: 99,
      total_votes: 90948,
      percentage_of_votes: 8.21,
    },
    {
      serial_no: 4,
      candidate: "UPENDRA SINGH",
      party: "Bharatiya Kisan Parivartan Party",
      evm_votes: 7111,
      postal_votes: 8,
      total_votes: 7119,
      percentage_of_votes: 0.64,
    },
    {
      serial_no: 5,
      candidate: "RASHMI KANT",
      party: "Parivartan Samaj Party",
      evm_votes: 2927,
      postal_votes: 3,
      total_votes: 2930,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 6,
      candidate: "PREM DUTT",
      party: "Rashtra Uday Party",
      evm_votes: 2876,
      postal_votes: 2,
      total_votes: 2878,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 7,
      candidate: "RAJVEER",
      party: "Independent",
      evm_votes: 1367,
      postal_votes: 13,
      total_votes: 1380,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5273,
      postal_votes: 6,
      total_votes: 5279,
      percentage_of_votes: 0.48,
    },
  ],
  total: {
    evm_votes: 1105210,
    postal_votes: 2086,
    total_votes: 1107296,
  },
};
export const s2421 = {
  cs_name: "Mainpuri",
  cs_shortname: "mainpuri",
  cs_code: "s2421",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "DIMPLE YADAV",
      party: "Samajwadi Party",
      evm_votes: 596710,
      postal_votes: 1816,
      total_votes: 598526,
      percentage_of_votes: 56.79,
    },
    {
      serial_no: 2,
      candidate: "JAYVEER SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 375895,
      postal_votes: 992,
      total_votes: 376887,
      percentage_of_votes: 35.76,
    },
    {
      serial_no: 3,
      candidate: "SHIV PRASAD YADAV",
      party: "Bahujan Samaj Party",
      evm_votes: 66697,
      postal_votes: 117,
      total_votes: 66814,
      percentage_of_votes: 6.34,
    },
    {
      serial_no: 4,
      candidate: "SURESH CHANDRA",
      party: "Independent",
      evm_votes: 2366,
      postal_votes: 8,
      total_votes: 2374,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 5,
      candidate: "NAND RAM BAGRI",
      party: "Voters Party International",
      evm_votes: 1765,
      postal_votes: 4,
      total_votes: 1769,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 6,
      candidate: "MANJU PAL",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 1148,
      postal_votes: 23,
      total_votes: 1171,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 7,
      candidate: "PRAMOD KUMAR",
      party: "Independent",
      evm_votes: 994,
      postal_votes: 12,
      total_votes: 1006,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 8,
      candidate: "SUNIL KUMAR MISHRA",
      party: "Sarva Samaj Janata Party",
      evm_votes: 878,
      postal_votes: 4,
      total_votes: 882,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4570,
      postal_votes: 12,
      total_votes: 4582,
      percentage_of_votes: 0.43,
    },
  ],
  total: {
    evm_votes: 1051023,
    postal_votes: 2988,
    total_votes: 1054011,
  },
};
export const s2422 = {
  cs_name: "Etah",
  cs_shortname: "etah",
  cs_code: "s2422",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "DEVESH SHAKYA",
      party: "Samajwadi Party",
      evm_votes: 474263,
      postal_votes: 1545,
      total_votes: 475808,
      percentage_of_votes: 47.09,
    },
    {
      serial_no: 2,
      candidate: "RAJVEER SINGH (RAJU BHAIYA)",
      party: "Bharatiya Janata Party",
      evm_votes: 446748,
      postal_votes: 1008,
      total_votes: 447756,
      percentage_of_votes: 44.32,
    },
    {
      serial_no: 3,
      candidate: "MOHAMMAD IRFAN ADVOCATE",
      party: "Bahujan Samaj Party",
      evm_votes: 71407,
      postal_votes: 178,
      total_votes: 71585,
      percentage_of_votes: 7.08,
    },
    {
      serial_no: 4,
      candidate: "ANUPAM KUMAR",
      party: "Kisan Kranti Dal",
      evm_votes: 3944,
      postal_votes: 5,
      total_votes: 3949,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 5,
      candidate: "KAILASH KUMAR",
      party: "Independent",
      evm_votes: 1728,
      postal_votes: 0,
      total_votes: 1728,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 6,
      candidate: "DANVEER",
      party: "Independent",
      evm_votes: 1261,
      postal_votes: 4,
      total_votes: 1265,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 7,
      candidate: "UDAY VEER SINGH",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 985,
      postal_votes: 7,
      total_votes: 992,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 8,
      candidate: "ASHOK KUMAR",
      party: "Independent",
      evm_votes: 777,
      postal_votes: 3,
      total_votes: 780,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 9,
      candidate: "VIABHAV MISHRA",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 742,
      postal_votes: 5,
      total_votes: 747,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 10,
      candidate: "AMAR SINGH",
      party: "Independent",
      evm_votes: 609,
      postal_votes: 6,
      total_votes: 615,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5145,
      postal_votes: 11,
      total_votes: 5156,
      percentage_of_votes: 0.51,
    },
  ],
  total: {
    evm_votes: 1007609,
    postal_votes: 2772,
    total_votes: 1010381,
  },
};
export const s2423 = {
  cs_name: "Badaun",
  cs_shortname: "badaun",
  cs_code: "s2423",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "ADITYA YADAV",
      party: "Samajwadi Party",
      evm_votes: 501390,
      postal_votes: 465,
      total_votes: 501855,
      percentage_of_votes: 45.97,
    },
    {
      serial_no: 2,
      candidate: "DURVIJAY SINGH SHAKYA",
      party: "Bharatiya Janata Party",
      evm_votes: 466323,
      postal_votes: 541,
      total_votes: 466864,
      percentage_of_votes: 42.76,
    },
    {
      serial_no: 3,
      candidate: "MUSLIM KHAN",
      party: "Bahujan Samaj Party",
      evm_votes: 97686,
      postal_votes: 65,
      total_votes: 97751,
      percentage_of_votes: 8.95,
    },
    {
      serial_no: 4,
      candidate: "SANDEEP KUMAR",
      party: "Independent",
      evm_votes: 3835,
      postal_votes: 1,
      total_votes: 3836,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 5,
      candidate: "DINESH KUMAR",
      party: "Independent",
      evm_votes: 2672,
      postal_votes: 2,
      total_votes: 2674,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 6,
      candidate: "LATA YADAV",
      party: "Independent",
      evm_votes: 2572,
      postal_votes: 6,
      total_votes: 2578,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 7,
      candidate: "NILAM RANI PAL",
      party: "Rashtriya Shoshit Samaj Party",
      evm_votes: 2428,
      postal_votes: 10,
      total_votes: 2438,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 8,
      candidate: "RAM PRATAP MAURYA",
      party: "Independent",
      evm_votes: 1542,
      postal_votes: 1,
      total_votes: 1543,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 9,
      candidate: "ISRAT ALI",
      party: "Independent",
      evm_votes: 1512,
      postal_votes: 1,
      total_votes: 1513,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "SULEMAN",
      party: "Swaraj Bhartiya Nyay Party",
      evm_votes: 1240,
      postal_votes: 0,
      total_votes: 1240,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 11,
      candidate: "HARIRAJ SINGH URF SIPU YADAV",
      party: "Log Party",
      evm_votes: 909,
      postal_votes: 1,
      total_votes: 910,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8553,
      postal_votes: 9,
      total_votes: 8562,
      percentage_of_votes: 0.78,
    },
  ],
  total: {
    evm_votes: 1090662,
    postal_votes: 1102,
    total_votes: 1091764,
  },
};
export const s2424 = {
  cs_name: "Aonla",
  cs_shortname: "aonla",
  cs_code: "s2424",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "NEERAJ MAURYA",
      party: "Samajwadi Party",
      evm_votes: 491119,
      postal_votes: 1396,
      total_votes: 492515,
      percentage_of_votes: 45.23,
    },
    {
      serial_no: 2,
      candidate: "DHARMENDRA KASHYAP",
      party: "Bharatiya Janata Party",
      evm_votes: 475434,
      postal_votes: 1112,
      total_votes: 476546,
      percentage_of_votes: 43.76,
    },
    {
      serial_no: 3,
      candidate: "ABID ALI",
      party: "Bahujan Samaj Party",
      evm_votes: 95407,
      postal_votes: 223,
      total_votes: 95630,
      percentage_of_votes: 8.78,
    },
    {
      serial_no: 4,
      candidate: "KAUSAR KHAN",
      party: "Peace Party",
      evm_votes: 4210,
      postal_votes: 3,
      total_votes: 4213,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 5,
      candidate: "PREM PAL SAGAR",
      party: "Vanchitsamaj Insaaf Party",
      evm_votes: 3328,
      postal_votes: 2,
      total_votes: 3330,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 6,
      candidate: "MAKKHAN LAL",
      party: "Independent",
      evm_votes: 2946,
      postal_votes: 7,
      total_votes: 2953,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 7,
      candidate: "RAJ KUMAR PATEL",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 2700,
      postal_votes: 6,
      total_votes: 2706,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 8,
      candidate: "MOHAMMAD AMIR KHAN",
      party: "Bharat Jodo Party",
      evm_votes: 2348,
      postal_votes: 7,
      total_votes: 2355,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 9,
      candidate: "NIRMOD KUMAR SINGH",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 1785,
      postal_votes: 5,
      total_votes: 1790,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6843,
      postal_votes: 15,
      total_votes: 6858,
      percentage_of_votes: 0.63,
    },
  ],
  total: {
    evm_votes: 1086120,
    postal_votes: 2776,
    total_votes: 1088896,
  },
};
export const s2428 = {
  cs_name: "Kheri",
  cs_shortname: "kheri",
  cs_code: "s2428",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "UTKARSH VERMA 'MADHUR'",
      party: "Samajwadi Party",
      evm_votes: 555277,
      postal_votes: 2088,
      total_votes: 557365,
      percentage_of_votes: 45.94,
    },
    {
      serial_no: 2,
      candidate: "AJAY KUMAR",
      party: "Bharatiya Janata Party",
      evm_votes: 521954,
      postal_votes: 1082,
      total_votes: 523036,
      percentage_of_votes: 43.11,
    },
    {
      serial_no: 3,
      candidate: "ANSHAY KALRA",
      party: "Bahujan Samaj Party",
      evm_votes: 110122,
      postal_votes: 338,
      total_votes: 110460,
      percentage_of_votes: 9.1,
    },
    {
      serial_no: 4,
      candidate: "SANDHYA JAISWAL",
      party: "Parivartan Samaj Party",
      evm_votes: 3093,
      postal_votes: 3,
      total_votes: 3096,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 5,
      candidate: "SATENDRA KUMAR MAURYA",
      party: "Independent",
      evm_votes: 2980,
      postal_votes: 2,
      total_votes: 2982,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 6,
      candidate: "OM NATH SINGH",
      party: "Rashtriya Shoshit Samaj Party",
      evm_votes: 2298,
      postal_votes: 2,
      total_votes: 2300,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 7,
      candidate: "NARESH SINGH BHADAURIYA",
      party: "Independent",
      evm_votes: 2107,
      postal_votes: 4,
      total_votes: 2111,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 8,
      candidate: "MUKESH KUMAR (BHARTI)",
      party: "Rashtriya Vikalp Party",
      evm_votes: 1266,
      postal_votes: 3,
      total_votes: 1269,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 9,
      candidate: "KHANJAN",
      party: "Netaji Subhash Chander Bose Rashtriya Azad Party",
      evm_votes: 1018,
      postal_votes: 1,
      total_votes: 1019,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 10,
      candidate: "DARWARI URF DARWARI LAL CHAUHAN",
      party: "Janta Kranti Party",
      evm_votes: 935,
      postal_votes: 1,
      total_votes: 936,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 11,
      candidate: "KM. PANCHSHEELA ANAND",
      party: "Uttar Pradesh Republican Party",
      evm_votes: 845,
      postal_votes: 9,
      total_votes: 854,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7915,
      postal_votes: 16,
      total_votes: 7931,
      percentage_of_votes: 0.65,
    },
  ],
  total: {
    evm_votes: 1209810,
    postal_votes: 3549,
    total_votes: 1213359,
  },
};
export const s2429 = {
  cs_name: "Dhaurahra",
  cs_shortname: "dhaurahra",
  cs_code: "s2429",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "ANAND BHADAURIYA",
      party: "Samajwadi Party",
      evm_votes: 442650,
      postal_votes: 1093,
      total_votes: 443743,
      percentage_of_votes: 39.91,
    },
    {
      serial_no: 2,
      candidate: "REKHA VERMA",
      party: "Bharatiya Janata Party",
      evm_votes: 438573,
      postal_votes: 721,
      total_votes: 439294,
      percentage_of_votes: 39.51,
    },
    {
      serial_no: 3,
      candidate: "SHYAM KISHOR AWASTHI",
      party: "Bahujan Samaj Party",
      evm_votes: 184964,
      postal_votes: 510,
      total_votes: 185474,
      percentage_of_votes: 16.68,
    },
    {
      serial_no: 4,
      candidate: "JANARDAN PRASAD",
      party: "Communist Party of India",
      evm_votes: 15898,
      postal_votes: 2,
      total_votes: 15900,
      percentage_of_votes: 1.43,
    },
    {
      serial_no: 5,
      candidate: "RAJESH KUMAR",
      party: "Political Justice Party",
      evm_votes: 5020,
      postal_votes: 3,
      total_votes: 5023,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 6,
      candidate: "ASHUTOSH PATHAK",
      party: "Aam Janta Party (India)",
      evm_votes: 3550,
      postal_votes: 6,
      total_votes: 3556,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 7,
      candidate: "SUDESH KUMAR",
      party: "Lok Jan Sangharsh Party",
      evm_votes: 2811,
      postal_votes: 3,
      total_votes: 2814,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 8,
      candidate: "SAMRENDRA KUMAR",
      party: "Independent",
      evm_votes: 2482,
      postal_votes: 2,
      total_votes: 2484,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 9,
      candidate: "VIJAY KUMAR TIWARI",
      party: "Independent",
      evm_votes: 2017,
      postal_votes: 0,
      total_votes: 2017,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 10,
      candidate: "KASIM ALI",
      party: "Peace Party",
      evm_votes: 1809,
      postal_votes: 3,
      total_votes: 1812,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 11,
      candidate: "SAKASEN",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 1295,
      postal_votes: 1,
      total_votes: 1296,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "RAM NARESH",
      party: "Independent",
      evm_votes: 1290,
      postal_votes: 0,
      total_votes: 1290,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7133,
      postal_votes: 11,
      total_votes: 7144,
      percentage_of_votes: 0.64,
    },
  ],
  total: {
    evm_votes: 1109492,
    postal_votes: 2355,
    total_votes: 1111847,
  },
};
export const s2434 = {
  cs_name: "Mohanlalganj",
  cs_shortname: "mohanlalganj",
  cs_code: "s2434",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "R.K. CHAUDHARY",
      party: "Samajwadi Party",
      evm_votes: 666560,
      postal_votes: 1309,
      total_votes: 667869,
      percentage_of_votes: 48.49,
    },
    {
      serial_no: 2,
      candidate: "KAUSHAL KISHORE",
      party: "Bharatiya Janata Party",
      evm_votes: 596197,
      postal_votes: 1380,
      total_votes: 597577,
      percentage_of_votes: 43.38,
    },
    {
      serial_no: 3,
      candidate: "RAJESH KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 88257,
      postal_votes: 204,
      total_votes: 88461,
      percentage_of_votes: 6.42,
    },
    {
      serial_no: 4,
      candidate: "MAHENDRA",
      party: "Independent",
      evm_votes: 2654,
      postal_votes: 7,
      total_votes: 2661,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 5,
      candidate: "SUSHIL KUMAR",
      party: "Independent",
      evm_votes: 2626,
      postal_votes: 1,
      total_votes: 2627,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 6,
      candidate: "RAMESH KUMAR",
      party: "Independent",
      evm_votes: 1839,
      postal_votes: 2,
      total_votes: 1841,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 7,
      candidate: "SUNITA CHHEDA PASI",
      party: "Aam Janta Party (India)",
      evm_votes: 1736,
      postal_votes: 13,
      total_votes: 1749,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 8,
      candidate: "S. L.SINGH",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 1711,
      postal_votes: 6,
      total_votes: 1717,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 9,
      candidate: "BRIJESH KUMAR VIKRAM",
      party: "Rashtriya Samaj Paksha",
      evm_votes: 1648,
      postal_votes: 7,
      total_votes: 1655,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 10,
      candidate: "JITENDRA KUMAR",
      party: "Independent",
      evm_votes: 1402,
      postal_votes: 12,
      total_votes: 1414,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "SURAJ KUMAR",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 1010,
      postal_votes: 5,
      total_votes: 1015,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8838,
      postal_votes: 28,
      total_votes: 8866,
      percentage_of_votes: 0.64,
    },
  ],
  total: {
    evm_votes: 1374478,
    postal_votes: 2974,
    total_votes: 1377452,
  },
};
export const s2438 = {
  cs_name: "Sultanpur",
  cs_shortname: "sultanpur",
  cs_code: "s2438",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAMBHUAL NISHAD",
      party: "Samajwadi Party",
      evm_votes: 442886,
      postal_votes: 1444,
      total_votes: 444330,
      percentage_of_votes: 43,
    },
    {
      serial_no: 2,
      candidate: "MANEKA SANJAY GANDHI",
      party: "Bharatiya Janata Party",
      evm_votes: 399859,
      postal_votes: 1297,
      total_votes: 401156,
      percentage_of_votes: 38.82,
    },
    {
      serial_no: 3,
      candidate: "UDRAJ VERMA",
      party: "Bahujan Samaj Party",
      evm_votes: 162603,
      postal_votes: 422,
      total_votes: 163025,
      percentage_of_votes: 15.78,
    },
    {
      serial_no: 4,
      candidate: "ABDUL MABOOD",
      party: "Apna Desh Party",
      evm_votes: 4295,
      postal_votes: 2,
      total_votes: 4297,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 5,
      candidate: "MOHAMMAD ASIF",
      party: "Independent",
      evm_votes: 3382,
      postal_votes: 5,
      total_votes: 3387,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 6,
      candidate: "UDAYRAJ VERMA",
      party: "Independent",
      evm_votes: 2900,
      postal_votes: 9,
      total_votes: 2909,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 7,
      candidate: "GIRISH LAL",
      party: "Azad Party",
      evm_votes: 2305,
      postal_votes: 6,
      total_votes: 2311,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 8,
      candidate: "DR SHIV SHANKAR INDIAN",
      party: "Most Backward Classes Of India",
      evm_votes: 2085,
      postal_votes: 5,
      total_votes: 2090,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 9,
      candidate: "JAY PRAKASH",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 1364,
      postal_votes: 5,
      total_votes: 1369,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8500,
      postal_votes: 13,
      total_votes: 8513,
      percentage_of_votes: 0.82,
    },
  ],
  total: {
    evm_votes: 1030179,
    postal_votes: 3208,
    total_votes: 1033387,
  },
};
export const s2439 = {
  cs_name: "Pratapgarh",
  cs_shortname: "pratapgarh",
  cs_code: "s2439",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "SHIV PAL SINGH PATEL (DR. S P SINGH)",
      party: "Samajwadi Party",
      evm_votes: 439836,
      postal_votes: 2096,
      total_votes: 441932,
      percentage_of_votes: 46.65,
    },
    {
      serial_no: 2,
      candidate: "SANGAM LAL GUPTA",
      party: "Bharatiya Janata Party",
      evm_votes: 374208,
      postal_votes: 1518,
      total_votes: 375726,
      percentage_of_votes: 39.66,
    },
    {
      serial_no: 3,
      candidate: "PRATHMESH MISHRA 'SENANI'",
      party: "Bahujan Samaj Party",
      evm_votes: 79872,
      postal_votes: 272,
      total_votes: 80144,
      percentage_of_votes: 8.46,
    },
    {
      serial_no: 4,
      candidate: "SANDEEP SINGH",
      party: "National Jan Dal",
      evm_votes: 5270,
      postal_votes: 16,
      total_votes: 5286,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 5,
      candidate: "ARUN KUMAR PANDEY",
      party: "Hindustan Samaj Party",
      evm_votes: 4984,
      postal_votes: 7,
      total_votes: 4991,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 6,
      candidate: "SHIV RAM SHARMA",
      party: "Moulik Adhikar Party",
      evm_votes: 4709,
      postal_votes: 2,
      total_votes: 4711,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 7,
      candidate: "AMAR SINGH",
      party: "Independent",
      evm_votes: 4135,
      postal_votes: 3,
      total_votes: 4138,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 8,
      candidate: "SUNIL CHANDRA PAL",
      party: "Rashtra Uday Party",
      evm_votes: 3337,
      postal_votes: 3,
      total_votes: 3340,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 9,
      candidate: "AJEET PRATAP",
      party: "Independent",
      evm_votes: 2581,
      postal_votes: 0,
      total_votes: 2581,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 10,
      candidate: "VIJAY SINGH",
      party: "Sanatan Sanskriti Raksha Dal",
      evm_votes: 2457,
      postal_votes: 2,
      total_votes: 2459,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 11,
      candidate: "RISHI PATEL",
      party: "Apna Dal (Kamerawadi)",
      evm_votes: 2425,
      postal_votes: 4,
      total_votes: 2429,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 12,
      candidate: "MAHESH KUMAR PRAJAPATI",
      party: "Samajhdar Party",
      evm_votes: 1963,
      postal_votes: 1,
      total_votes: 1964,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 13,
      candidate: "DHANANJAY",
      party: "Independent",
      evm_votes: 1622,
      postal_votes: 1,
      total_votes: 1623,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 14,
      candidate: "GEETA DEVI",
      party: "Bhartiya Lokmat Rashtrwadi Party",
      evm_votes: 1582,
      postal_votes: 12,
      total_votes: 1594,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 15,
      candidate: "RAMSIDDH YADAV URF R.S.YADAV",
      party: "Manavtawadi Samaj Party",
      evm_votes: 1326,
      postal_votes: 1,
      total_votes: 1327,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 16,
      candidate: "SUNIL KUMAR MISHRA",
      party: "Independent",
      evm_votes: 1134,
      postal_votes: 2,
      total_votes: 1136,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 17,
      candidate: "SHANKAR SUMAN TIVARI",
      party: "Independent",
      evm_votes: 1128,
      postal_votes: 1,
      total_votes: 1129,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 18,
      candidate: "DURGESH KUMAR",
      party: "Rastriya Atal Janta Party",
      evm_votes: 1113,
      postal_votes: 3,
      total_votes: 1116,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 19,
      candidate: "RAM KUMAR YADAV",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1070,
      postal_votes: 1,
      total_votes: 1071,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 20,
      candidate: "SHIV PAL PATEL",
      party: "Independent",
      evm_votes: 1025,
      postal_votes: 5,
      total_votes: 1030,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 21,
      candidate: "DHARMENDRA TIWARI",
      party: "Log Party",
      evm_votes: 992,
      postal_votes: 1,
      total_votes: 993,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 22,
      candidate: "JOKHU PATEL",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 969,
      postal_votes: 2,
      total_votes: 971,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 23,
      candidate: "SHRAWAN KUMAR TRIPATHI",
      party: "Independent",
      evm_votes: 849,
      postal_votes: 2,
      total_votes: 851,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 24,
      candidate: "DHYAN SINGH",
      party: "Independent",
      evm_votes: 675,
      postal_votes: 3,
      total_votes: 678,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 25,
      candidate: "PRAMOD KUMAR",
      party: "Independent",
      evm_votes: 672,
      postal_votes: 2,
      total_votes: 674,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 26,
      candidate: "MOHRAM ALI",
      party: "Independent",
      evm_votes: 501,
      postal_votes: 2,
      total_votes: 503,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 27,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 2869,
      postal_votes: 22,
      total_votes: 2891,
      percentage_of_votes: 0.31,
    },
  ],
  total: {
    evm_votes: 943304,
    postal_votes: 3984,
    total_votes: 947288,
  },
};
export const s2441 = {
  cs_name: "Etawah",
  cs_shortname: "etawah",
  cs_code: "s2441",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "JITENDRA KUMAR DOHARE",
      party: "Samajwadi Party",
      evm_votes: 489358,
      postal_votes: 1389,
      total_votes: 490747,
      percentage_of_votes: 47.47,
    },
    {
      serial_no: 2,
      candidate: "DR RAM SHANKAR KATHERIA",
      party: "Bharatiya Janata Party",
      evm_votes: 430922,
      postal_votes: 1406,
      total_votes: 432328,
      percentage_of_votes: 41.82,
    },
    {
      serial_no: 3,
      candidate: "SARIKA SINGH BAGHEL",
      party: "Bahujan Samaj Party",
      evm_votes: 96253,
      postal_votes: 288,
      total_votes: 96541,
      percentage_of_votes: 9.34,
    },
    {
      serial_no: 4,
      candidate: "SUNIL SHANKWAR ADVOCATE",
      party: "Independent",
      evm_votes: 2954,
      postal_votes: 9,
      total_votes: 2963,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 5,
      candidate: "MULAYAM SINGH",
      party: "Independent",
      evm_votes: 1688,
      postal_votes: 20,
      total_votes: 1708,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 6,
      candidate: "BHUVNESH KUMARI",
      party: "Samyak Party",
      evm_votes: 1648,
      postal_votes: 9,
      total_votes: 1657,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 7,
      candidate: "VIVEK RAJ",
      party: "Janta Samajwadi Party (Vivek Raj)",
      evm_votes: 1569,
      postal_votes: 5,
      total_votes: 1574,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6229,
      postal_votes: 37,
      total_votes: 6266,
      percentage_of_votes: 0.61,
    },
  ],
  total: {
    evm_votes: 1030621,
    postal_votes: 3163,
    total_votes: 1033784,
  },
};
export const s2442 = {
  cs_name: "Kannauj",
  cs_shortname: "kannauj",
  cs_code: "s2442",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "AKHILESH YADAV",
      party: "Samajwadi Party",
      evm_votes: 640207,
      postal_votes: 2085,
      total_votes: 642292,
      percentage_of_votes: 52.74,
    },
    {
      serial_no: 2,
      candidate: "SUBRAT PATHAK",
      party: "Bharatiya Janata Party",
      evm_votes: 470131,
      postal_votes: 1239,
      total_votes: 471370,
      percentage_of_votes: 38.71,
    },
    {
      serial_no: 3,
      candidate: "IMRAN BIN ZAFAR",
      party: "Bahujan Samaj Party",
      evm_votes: 81471,
      postal_votes: 168,
      total_votes: 81639,
      percentage_of_votes: 6.7,
    },
    {
      serial_no: 4,
      candidate: "ALOK VERMA",
      party: "Rashtriya Shoshit Samaj Party",
      evm_votes: 4484,
      postal_votes: 8,
      total_votes: 4492,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 5,
      candidate: "LALIT KUMARI",
      party: "Independent",
      evm_votes: 3262,
      postal_votes: 5,
      total_votes: 3267,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 6,
      candidate: "SINOD KUMAR",
      party: "Independent",
      evm_votes: 1732,
      postal_votes: 1,
      total_votes: 1733,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 7,
      candidate: "RAJ KATHERIA",
      party: "Independent",
      evm_votes: 1463,
      postal_votes: 1,
      total_votes: 1464,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 8,
      candidate: "BHANU PRATAP SINGH DOHRE",
      party: "Independent",
      evm_votes: 1430,
      postal_votes: 2,
      total_votes: 1432,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 9,
      candidate: "SHAILENDRA KUMAR",
      party: "Bharatiya Kisan Parivartan Party",
      evm_votes: 1141,
      postal_votes: 10,
      total_votes: 1151,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 10,
      candidate: "YADVENDRA KISHOR",
      party: "Independent",
      evm_votes: 1061,
      postal_votes: 1,
      total_votes: 1062,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 11,
      candidate: "SUBHASH CHANDRA",
      party: "All India Forward Bloc",
      evm_votes: 799,
      postal_votes: 2,
      total_votes: 801,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "SUNIL",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 738,
      postal_votes: 1,
      total_votes: 739,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "PRAMOD KUMAR YADAV",
      party: "Bhartiya Krishak Dal",
      evm_votes: 557,
      postal_votes: 3,
      total_votes: 560,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "PURUSHOTTAM TIWARI",
      party: "Independent",
      evm_votes: 541,
      postal_votes: 2,
      total_votes: 543,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 15,
      candidate: "IRFAN ALI",
      party: "Independent",
      evm_votes: 463,
      postal_votes: 7,
      total_votes: 470,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4805,
      postal_votes: 13,
      total_votes: 4818,
      percentage_of_votes: 0.4,
    },
  ],
  total: {
    evm_votes: 1214285,
    postal_votes: 3548,
    total_votes: 1217833,
  },
};
export const s2445 = {
  cs_name: "Jalaun",
  cs_shortname: "jalaun",
  cs_code: "s2445",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "NARAYAN DAS AHIRWAR",
      party: "Samajwadi Party",
      evm_votes: 529444,
      postal_votes: 736,
      total_votes: 530180,
      percentage_of_votes: 46.96,
    },
    {
      serial_no: 2,
      candidate: "BHANU PRATAP SINGH VERMA",
      party: "Bharatiya Janata Party",
      evm_votes: 475232,
      postal_votes: 1050,
      total_votes: 476282,
      percentage_of_votes: 42.19,
    },
    {
      serial_no: 3,
      candidate: "SURESH CHANDRA GAUTAM",
      party: "Bahujan Samaj Party",
      evm_votes: 100105,
      postal_votes: 143,
      total_votes: 100248,
      percentage_of_votes: 8.88,
    },
    {
      serial_no: 4,
      candidate: "BRIJ MOHAN",
      party: "Independent",
      evm_votes: 4666,
      postal_votes: 7,
      total_votes: 4673,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 5,
      candidate: "NAGENDRA KUMAR",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 3205,
      postal_votes: 7,
      total_votes: 3212,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 6,
      candidate: "PREMLATA VERMA",
      party: "Independent",
      evm_votes: 3176,
      postal_votes: 24,
      total_votes: 3200,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 7,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11138,
      postal_votes: 16,
      total_votes: 11154,
      percentage_of_votes: 0.99,
    },
  ],
  total: {
    evm_votes: 1126966,
    postal_votes: 1983,
    total_votes: 1128949,
  },
};
export const s2447 = {
  cs_name: "Hamirpur",
  cs_shortname: "hamirpur",
  cs_code: "s2447",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "AJENDRA SINGH LODHI",
      party: "Samajwadi Party",
      evm_votes: 490003,
      postal_votes: 680,
      total_votes: 490683,
      percentage_of_votes: 44,
    },
    {
      serial_no: 2,
      candidate: "KUNWAR PUSHPENDRA SINGH CHANDEL",
      party: "Bharatiya Janata Party",
      evm_votes: 487373,
      postal_votes: 681,
      total_votes: 488054,
      percentage_of_votes: 43.76,
    },
    {
      serial_no: 3,
      candidate: "NIRDOSH KUMAR DIXIT",
      party: "Bahujan Samaj Party",
      evm_votes: 94564,
      postal_votes: 132,
      total_votes: 94696,
      percentage_of_votes: 8.49,
    },
    {
      serial_no: 4,
      candidate: "ANUPAM KUMAR TRIPATHI",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 6401,
      postal_votes: 6,
      total_votes: 6407,
      percentage_of_votes: 0.57,
    },
    {
      serial_no: 5,
      candidate: "BRIJESH KUMAR PAL",
      party: "Rashtra Uday Party",
      evm_votes: 4516,
      postal_votes: 11,
      total_votes: 4527,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 6,
      candidate: "BHUVANENDRA NARAYAN SINGH",
      party: "Independent",
      evm_votes: 4315,
      postal_votes: 5,
      total_votes: 4320,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 7,
      candidate: "SANTOSH KUMAR",
      party: "Independent",
      evm_votes: 3776,
      postal_votes: 1,
      total_votes: 3777,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 8,
      candidate: "RAJESH SINGH",
      party: "Independent",
      evm_votes: 3605,
      postal_votes: 2,
      total_votes: 3607,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 9,
      candidate: "SURESH KUMAR",
      party: "Eklavya Samaj Party",
      evm_votes: 2181,
      postal_votes: 0,
      total_votes: 2181,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 10,
      candidate: "SANJIV KUMAR",
      party: "Spashtwadi Jan Aadhar Party",
      evm_votes: 2003,
      postal_votes: 0,
      total_votes: 2003,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 11,
      candidate: "DHARMRAJ",
      party: "Al-Hind Party",
      evm_votes: 1589,
      postal_votes: 2,
      total_votes: 1591,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13442,
      postal_votes: 11,
      total_votes: 13453,
      percentage_of_votes: 1.21,
    },
  ],
  total: {
    evm_votes: 1113768,
    postal_votes: 1531,
    total_votes: 1115299,
  },
};
export const s2448 = {
  cs_name: "Banda",
  cs_shortname: "banda",
  cs_code: "s2448",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "KRISHNA DEVI SHIVSHANKER PATEL",
      party: "Samajwadi Party",
      evm_votes: 406018,
      postal_votes: 549,
      total_votes: 406567,
      percentage_of_votes: 38.94,
    },
    {
      serial_no: 2,
      candidate: "R. K. SINGH PATEL",
      party: "Bharatiya Janata Party",
      evm_votes: 334950,
      postal_votes: 407,
      total_votes: 335357,
      percentage_of_votes: 32.12,
    },
    {
      serial_no: 3,
      candidate: "MAYANK DWIVEDI",
      party: "Bahujan Samaj Party",
      evm_votes: 245530,
      postal_votes: 215,
      total_votes: 245745,
      percentage_of_votes: 23.54,
    },
    {
      serial_no: 4,
      candidate: "RAMCHANDRA SARAS",
      party: "Communist Party of India",
      evm_votes: 9436,
      postal_votes: 2,
      total_votes: 9438,
      percentage_of_votes: 0.9,
    },
    {
      serial_no: 5,
      candidate: "PANCHA URF PANCHAMLAL",
      party: "Bhagidari Party(P)",
      evm_votes: 7714,
      postal_votes: 3,
      total_votes: 7717,
      percentage_of_votes: 0.74,
    },
    {
      serial_no: 6,
      candidate: "GULAB CHANDER BARMA",
      party: "Rashtra Uday Party",
      evm_votes: 5905,
      postal_votes: 2,
      total_votes: 5907,
      percentage_of_votes: 0.57,
    },
    {
      serial_no: 7,
      candidate: "RAMCHRAN",
      party: "Independent",
      evm_votes: 5419,
      postal_votes: 4,
      total_votes: 5423,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 8,
      candidate: "SURENDRA KUMAR",
      party: "Swatantra Jantaraj Party",
      evm_votes: 4380,
      postal_votes: 2,
      total_votes: 4382,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 9,
      candidate: "SHRIRAM SINGH GAUR",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 3347,
      postal_votes: 3,
      total_votes: 3350,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 10,
      candidate: "DINESH KUMAR PATEL",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 2507,
      postal_votes: 1,
      total_votes: 2508,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 11,
      candidate: "BABULAL",
      party: "Log Party",
      evm_votes: 2358,
      postal_votes: 3,
      total_votes: 2361,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 12,
      candidate: "PRAMOD AZAD",
      party: "Apna Dal (Kamerawadi)",
      evm_votes: 2141,
      postal_votes: 1,
      total_votes: 2142,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13226,
      postal_votes: 9,
      total_votes: 13235,
      percentage_of_votes: 1.27,
    },
  ],
  total: {
    evm_votes: 1042931,
    postal_votes: 1201,
    total_votes: 1044132,
  },
};
export const s2449 = {
  cs_name: "Fatehpur",
  cs_shortname: "fatehpur",
  cs_code: "s2449",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "NARESH CHANDRA UTTAM PATEL",
      party: "Samajwadi Party",
      evm_votes: 499866,
      postal_votes: 462,
      total_votes: 500328,
      percentage_of_votes: 45.2,
    },
    {
      serial_no: 2,
      candidate: "NIRANJAN JYOTI",
      party: "Bharatiya Janata Party",
      evm_votes: 466249,
      postal_votes: 880,
      total_votes: 467129,
      percentage_of_votes: 42.2,
    },
    {
      serial_no: 3,
      candidate: "MANISH SINGH SACHAN",
      party: "Bahujan Samaj Party",
      evm_votes: 90897,
      postal_votes: 73,
      total_votes: 90970,
      percentage_of_votes: 8.22,
    },
    {
      serial_no: 4,
      candidate: "BIRENDRA SINGH",
      party: "Independent",
      evm_votes: 7917,
      postal_votes: 3,
      total_votes: 7920,
      percentage_of_votes: 0.72,
    },
    {
      serial_no: 5,
      candidate: "KAMLESH KUMAR SINGH",
      party: "Parivartan Samaj Party",
      evm_votes: 5212,
      postal_votes: 7,
      total_votes: 5219,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 6,
      candidate: "PANKAJ AWASTHI",
      party: "Independent",
      evm_votes: 4456,
      postal_votes: 1,
      total_votes: 4457,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 7,
      candidate: "RAMKISHOR",
      party: "Rashtra Uday Party",
      evm_votes: 4075,
      postal_votes: 4,
      total_votes: 4079,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 8,
      candidate: "JITENDRA KUMAR MAURYA",
      party: "Independent",
      evm_votes: 3333,
      postal_votes: 3,
      total_votes: 3336,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 9,
      candidate: "KULDEEP KUSHWAHA",
      party: "Independent",
      evm_votes: 2724,
      postal_votes: 2,
      total_votes: 2726,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 10,
      candidate: "RAJ BAHADUR",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 2653,
      postal_votes: 1,
      total_votes: 2654,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 11,
      candidate: "NIRAJ KUMAR",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 2561,
      postal_votes: 6,
      total_votes: 2567,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 12,
      candidate: "RAM BIHARI",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 2342,
      postal_votes: 2,
      total_votes: 2344,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 13,
      candidate: "NEERAJ LODHI",
      party: "Vikas Insaf Party",
      evm_votes: 2028,
      postal_votes: 5,
      total_votes: 2033,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 14,
      candidate: "JAY CHANDRA KUMAR",
      party: "Vishwa Kalyan Rashtriya Manav Samaj Party",
      evm_votes: 1602,
      postal_votes: 2,
      total_votes: 1604,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 15,
      candidate: "RAJESH KUMAR PATEL",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 1449,
      postal_votes: 9,
      total_votes: 1458,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8108,
      postal_votes: 12,
      total_votes: 8120,
      percentage_of_votes: 0.73,
    },
  ],
  total: {
    evm_votes: 1105472,
    postal_votes: 1472,
    total_votes: 1106944,
  },
};
export const s2450 = {
  cs_name: "Kaushambi",
  cs_shortname: "kaushambi",
  cs_code: "s2450",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "PUSHPENDRA SAROJ",
      party: "Samajwadi Party",
      evm_votes: 509175,
      postal_votes: 612,
      total_votes: 509787,
      percentage_of_votes: 50.51,
    },
    {
      serial_no: 2,
      candidate: "VINOD KUMAR SONKAR",
      party: "Bharatiya Janata Party",
      evm_votes: 405142,
      postal_votes: 701,
      total_votes: 405843,
      percentage_of_votes: 40.21,
    },
    {
      serial_no: 3,
      candidate: "SHUBH NARAYAN",
      party: "Bahujan Samaj Party",
      evm_votes: 55797,
      postal_votes: 61,
      total_votes: 55858,
      percentage_of_votes: 5.53,
    },
    {
      serial_no: 4,
      candidate: "PUSHPENDRA KUMAR SAROJ",
      party: "Independent",
      evm_votes: 5178,
      postal_votes: 6,
      total_votes: 5184,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 5,
      candidate: "NARENDRA KUMAR",
      party: "Apna Dal (Kamerawadi)",
      evm_votes: 5127,
      postal_votes: 4,
      total_votes: 5131,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 6,
      candidate: "SANJAY KUMAR",
      party: "Independent",
      evm_votes: 4631,
      postal_votes: 3,
      total_votes: 4634,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 7,
      candidate: "RAJENDRA SONKAR URF PANNA BHAIYA",
      party: "Independent",
      evm_votes: 3421,
      postal_votes: 0,
      total_votes: 3421,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 8,
      candidate: "PRADEEP KUMAR",
      party: "Independent",
      evm_votes: 2539,
      postal_votes: 2,
      total_votes: 2541,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 9,
      candidate: "RAMESHWAR PRASAD",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 2011,
      postal_votes: 2,
      total_votes: 2013,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 10,
      candidate: "RAM ASREY",
      party: "Manavtawadi Samaj Party",
      evm_votes: 1948,
      postal_votes: 2,
      total_votes: 1950,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12958,
      postal_votes: 9,
      total_votes: 12967,
      percentage_of_votes: 1.28,
    },
  ],
  total: {
    evm_votes: 1007927,
    postal_votes: 1402,
    total_votes: 1009329,
  },
};
export const s2454 = {
  cs_name: "Faizabad",
  cs_shortname: "faizabad",
  cs_code: "s2454",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "AWADHESH PRASAD",
      party: "Samajwadi Party",
      evm_votes: 553549,
      postal_votes: 740,
      total_votes: 554289,
      percentage_of_votes: 48.59,
    },
    {
      serial_no: 2,
      candidate: "LALLU SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 499002,
      postal_votes: 720,
      total_votes: 499722,
      percentage_of_votes: 43.81,
    },
    {
      serial_no: 3,
      candidate: "SACHIDANAND PANDEY",
      party: "Bahujan Samaj Party",
      evm_votes: 46347,
      postal_votes: 60,
      total_votes: 46407,
      percentage_of_votes: 4.07,
    },
    {
      serial_no: 4,
      candidate: "ARVIND SEN",
      party: "Communist Party of India",
      evm_votes: 15353,
      postal_votes: 14,
      total_votes: 15367,
      percentage_of_votes: 1.35,
    },
    {
      serial_no: 5,
      candidate: "SUNIL KUMAR BHATT",
      party: "Independent",
      evm_votes: 3826,
      postal_votes: 1,
      total_votes: 3827,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 6,
      candidate: "KANCHAN YADAV",
      party: "Moulik Adhikar Party",
      evm_votes: 2662,
      postal_votes: 4,
      total_votes: 2666,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 7,
      candidate: "AMBRISH DEV GUPTA",
      party: "Bharat Mahaparivar Party",
      evm_votes: 2216,
      postal_votes: 2,
      total_votes: 2218,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 8,
      candidate: "FAREED SALMANI",
      party: "Independent",
      evm_votes: 1779,
      postal_votes: 1,
      total_votes: 1780,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "LAL MANI URF L.M. TRIPATHI (BHAI SAHAB)",
      party: "Independent",
      evm_votes: 1717,
      postal_votes: 0,
      total_votes: 1717,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "ANIL KUMAR RAVAT",
      party: "Rashtriya Janshakti Samaj Party",
      evm_votes: 1636,
      postal_votes: 2,
      total_votes: 1638,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "JAGAT SINGH",
      party: "Independent",
      evm_votes: 1252,
      postal_votes: 2,
      total_votes: 1254,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 12,
      candidate: "BRAJENDRA DUTT TRIPATHI URF B. D. TRIPATHI",
      party: "Adarshwaadi Congress Party",
      evm_votes: 1163,
      postal_votes: 10,
      total_votes: 1173,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 13,
      candidate: "ARUN KUMAR",
      party: "Independent",
      evm_votes: 1063,
      postal_votes: 4,
      total_votes: 1067,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7529,
      postal_votes: 7,
      total_votes: 7536,
      percentage_of_votes: 0.66,
    },
  ],
  total: {
    evm_votes: 1139094,
    postal_votes: 1567,
    total_votes: 1140661,
  },
};
export const s2455 = {
  cs_name: "Ambedkar_Nagar",
  cs_shortname: "ambedkarnagar",
  cs_code: "s2455",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "LALJI VERMA",
      party: "Samajwadi Party",
      evm_votes: 543721,
      postal_votes: 1238,
      total_votes: 544959,
      percentage_of_votes: 46.3,
    },
    {
      serial_no: 2,
      candidate: "RITESH PANDEY",
      party: "Bharatiya Janata Party",
      evm_votes: 406851,
      postal_votes: 861,
      total_votes: 407712,
      percentage_of_votes: 34.64,
    },
    {
      serial_no: 3,
      candidate: "QAMAR HAYAT",
      party: "Bahujan Samaj Party",
      evm_votes: 199252,
      postal_votes: 247,
      total_votes: 199499,
      percentage_of_votes: 16.95,
    },
    {
      serial_no: 4,
      candidate: "NEELAM SINGH",
      party: "Moolniwasi Samaj Party",
      evm_votes: 4321,
      postal_votes: 10,
      total_votes: 4331,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 5,
      candidate: "SHABANA KHATOON",
      party: "Peace Party",
      evm_votes: 3983,
      postal_votes: 7,
      total_votes: 3990,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 6,
      candidate: "JAVED AHMAD SIDDIQUI",
      party: "Independent",
      evm_votes: 3857,
      postal_votes: 3,
      total_votes: 3860,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 7,
      candidate: "RAM NARESH PRAJAPATI",
      party: "Bhagidari Party(P)",
      evm_votes: 3410,
      postal_votes: 3,
      total_votes: 3413,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 8,
      candidate: "VIVEK KUMAR",
      party: "Bhartiya Shakti Chetna Party",
      evm_votes: 1848,
      postal_votes: 2,
      total_votes: 1850,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7429,
      postal_votes: 19,
      total_votes: 7448,
      percentage_of_votes: 0.63,
    },
  ],
  total: {
    evm_votes: 1174672,
    postal_votes: 2390,
    total_votes: 1177062,
  },
};
export const s2458 = {
  cs_name: "Shrawasti",
  cs_shortname: "shrawasti",
  cs_code: "s2458",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAM SHIROMANI VERMA",
      party: "Samajwadi Party",
      evm_votes: 510754,
      postal_votes: 301,
      total_votes: 511055,
      percentage_of_votes: 48.83,
    },
    {
      serial_no: 2,
      candidate: "SAKET MISRA",
      party: "Bharatiya Janata Party",
      evm_votes: 434192,
      postal_votes: 190,
      total_votes: 434382,
      percentage_of_votes: 41.51,
    },
    {
      serial_no: 3,
      candidate: "MUINUDDIN AHAMAD KHAN URF HAZI DADDAN KHAN",
      party: "Bahujan Samaj Party",
      evm_votes: 56225,
      postal_votes: 26,
      total_votes: 56251,
      percentage_of_votes: 5.38,
    },
    {
      serial_no: 4,
      candidate: "AHMAD JIYA KHAN",
      party: "Peace Party",
      evm_votes: 15630,
      postal_votes: 0,
      total_votes: 15630,
      percentage_of_votes: 1.49,
    },
    {
      serial_no: 5,
      candidate: "SHANTI DEVI",
      party: "Independent",
      evm_votes: 4010,
      postal_votes: 1,
      total_votes: 4011,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 6,
      candidate: "SUJEET KUMAR",
      party: "Independent",
      evm_votes: 3946,
      postal_votes: 0,
      total_votes: 3946,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 7,
      candidate: "YUGAL KISHOR SHUKLA",
      party: "Aam Janta Party (India)",
      evm_votes: 2672,
      postal_votes: 1,
      total_votes: 2673,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 8,
      candidate: "KRISHNA KUMAR MAURYA",
      party: "Samyak Party",
      evm_votes: 2266,
      postal_votes: 1,
      total_votes: 2267,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 9,
      candidate: "MALIK RAM",
      party: "Independent",
      evm_votes: 2111,
      postal_votes: 2,
      total_votes: 2113,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 10,
      candidate: "ANIL KUMAR TIWARI",
      party: "Independent",
      evm_votes: 1513,
      postal_votes: 1,
      total_votes: 1514,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "HANUMAN PRASAD MISHRA",
      party: "Kisan Mazdoor Sangharsh Party",
      evm_votes: 1494,
      postal_votes: 1,
      total_votes: 1495,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 12,
      candidate: "KUMARI GEETA GAUTAM",
      party: "Rashtriya Janta Party",
      evm_votes: 1317,
      postal_votes: 2,
      total_votes: 1319,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9872,
      postal_votes: 0,
      total_votes: 9872,
      percentage_of_votes: 0.94,
    },
  ],
  total: {
    evm_votes: 1046002,
    postal_votes: 526,
    total_votes: 1046528,
  },
};
export const s2461 = {
  cs_name: "Basti",
  cs_shortname: "basti",
  cs_code: "s2461",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAM PRASAD CHAUDHARY",
      party: "Samajwadi Party",
      evm_votes: 523487,
      postal_votes: 3518,
      total_votes: 527005,
      percentage_of_votes: 48.67,
    },
    {
      serial_no: 2,
      candidate: "HARISH CHANDRA ALIAS HARISH DWIVEDI",
      party: "Bharatiya Janata Party",
      evm_votes: 424604,
      postal_votes: 1407,
      total_votes: 426011,
      percentage_of_votes: 39.34,
    },
    {
      serial_no: 3,
      candidate: "LAVKUSH PATEL",
      party: "Bahujan Samaj Party",
      evm_votes: 102818,
      postal_votes: 483,
      total_votes: 103301,
      percentage_of_votes: 9.54,
    },
    {
      serial_no: 4,
      candidate: "PANKAJ DUBEY",
      party: "Log Party",
      evm_votes: 4624,
      postal_votes: 3,
      total_votes: 4627,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 5,
      candidate: "PREM KUMAR",
      party: "Moulik Adhikar Party",
      evm_votes: 3607,
      postal_votes: 4,
      total_votes: 3611,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 6,
      candidate: "HAFIZ ALI",
      party: "All India Forward Bloc",
      evm_votes: 2985,
      postal_votes: 3,
      total_votes: 2988,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 7,
      candidate: "RAM KARAN GAUTAM",
      party: "Independent",
      evm_votes: 2640,
      postal_votes: 5,
      total_votes: 2645,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 8,
      candidate: "PRAMOD KUMAR",
      party: "Independent",
      evm_votes: 2554,
      postal_votes: 3,
      total_votes: 2557,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 9,
      candidate: "SHAILENDRA KUMAR",
      party: "Bharat Mahaparivar Party",
      evm_votes: 2361,
      postal_votes: 3,
      total_votes: 2364,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7742,
      postal_votes: 19,
      total_votes: 7761,
      percentage_of_votes: 0.72,
    },
  ],
  total: {
    evm_votes: 1077422,
    postal_votes: 5448,
    total_votes: 1082870,
  },
};
export const s2462 = {
  cs_name: "Sant_Kabir_Nagar",
  cs_shortname: "santkabirnagar",
  cs_code: "s2462",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "LAXMIKANT PAPPU NISHAD",
      party: "Samajwadi Party",
      evm_votes: 497488,
      postal_votes: 1207,
      total_votes: 498695,
      percentage_of_votes: 45.7,
    },
    {
      serial_no: 2,
      candidate: "PRAVIN KUMAR NISHAD",
      party: "Bharatiya Janata Party",
      evm_votes: 405611,
      postal_votes: 914,
      total_votes: 406525,
      percentage_of_votes: 37.25,
    },
    {
      serial_no: 3,
      candidate: "NADEEM ASHRAF",
      party: "Bahujan Samaj Party",
      evm_votes: 150549,
      postal_votes: 263,
      total_votes: 150812,
      percentage_of_votes: 13.82,
    },
    {
      serial_no: 4,
      candidate: "DHEERAJ SRIVASTAV",
      party: "Pragatisheel Samaj Party",
      evm_votes: 5135,
      postal_votes: 5,
      total_votes: 5140,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 5,
      candidate: "RAM KRISHNA DWIVEDI",
      party: "Independent",
      evm_votes: 4509,
      postal_votes: 0,
      total_votes: 4509,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 6,
      candidate: "SATYVANT PRATAP SINGH",
      party: "Independent",
      evm_votes: 4351,
      postal_votes: 7,
      total_votes: 4358,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 7,
      candidate: "KULDEEP",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 3591,
      postal_votes: 4,
      total_votes: 3595,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 8,
      candidate: "MITRASEN",
      party: "Independent",
      evm_votes: 2535,
      postal_votes: 9,
      total_votes: 2544,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 9,
      candidate: "RAJENDRA YADAV URF COLONEL SAHAB",
      party: "Sarvjan Awaz Party",
      evm_votes: 2229,
      postal_votes: 10,
      total_votes: 2239,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 10,
      candidate: "ANAND KUMAR GAUTAM",
      party: "Independent",
      evm_votes: 1840,
      postal_votes: 10,
      total_votes: 1850,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 11,
      candidate: "SUBHASH CHANDRA DUBEY",
      party: "Naki Bharatiya Ekta Party",
      evm_votes: 1702,
      postal_votes: 6,
      total_votes: 1708,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9210,
      postal_votes: 17,
      total_votes: 9227,
      percentage_of_votes: 0.85,
    },
  ],
  total: {
    evm_votes: 1088750,
    postal_votes: 2452,
    total_votes: 1091202,
  },
};
export const s2468 = {
  cs_name: "Lalganj",
  cs_shortname: "lalganj",
  cs_code: "s2468",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "DAROGA PRASAD SAROJ",
      party: "Samajwadi Party",
      evm_votes: 437567,
      postal_votes: 2392,
      total_votes: 439959,
      percentage_of_votes: 43.85,
    },
    {
      serial_no: 2,
      candidate: "NEELAM SONKER",
      party: "Bharatiya Janata Party",
      evm_votes: 324106,
      postal_votes: 830,
      total_votes: 324936,
      percentage_of_votes: 32.38,
    },
    {
      serial_no: 3,
      candidate: "INDU CHAUDHRI",
      party: "Bahujan Samaj Party",
      evm_votes: 209308,
      postal_votes: 745,
      total_votes: 210053,
      percentage_of_votes: 20.93,
    },
    {
      serial_no: 4,
      candidate: "GANGADEEN",
      party: "Communist Party of India",
      evm_votes: 12263,
      postal_votes: 8,
      total_votes: 12271,
      percentage_of_votes: 1.22,
    },
    {
      serial_no: 5,
      candidate: "BALINDAR",
      party: "Janta Kranti Party (Rashtravadi)",
      evm_votes: 4157,
      postal_votes: 1,
      total_votes: 4158,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 6,
      candidate: "SUSHMITA SAROJ",
      party: "Independent",
      evm_votes: 2621,
      postal_votes: 5,
      total_votes: 2626,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 7,
      candidate: "RAM PYARE SHARAN VIJAITA",
      party: "Independent",
      evm_votes: 2337,
      postal_votes: 4,
      total_votes: 2341,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7075,
      postal_votes: 19,
      total_votes: 7094,
      percentage_of_votes: 0.71,
    },
  ],
  total: {
    evm_votes: 999434,
    postal_votes: 4004,
    total_votes: 1003438,
  },
};
export const s2469 = {
  cs_name: "Azamgarh",
  cs_shortname: "azamgarh",
  cs_code: "s2469",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "DHARMENDRA YADAV",
      party: "Samajwadi Party",
      evm_votes: 504837,
      postal_votes: 3402,
      total_votes: 508239,
      percentage_of_votes: 48.2,
    },
    {
      serial_no: 2,
      candidate: "DINESH LAL YADAV “NIRAHUA”",
      party: "Bharatiya Janata Party",
      evm_votes: 345725,
      postal_votes: 1479,
      total_votes: 347204,
      percentage_of_votes: 32.93,
    },
    {
      serial_no: 3,
      candidate: "MASHHOD SABEEHA ANSARI",
      party: "Bahujan Samaj Party",
      evm_votes: 179189,
      postal_votes: 650,
      total_votes: 179839,
      percentage_of_votes: 17.05,
    },
    {
      serial_no: 4,
      candidate: "RAVINDRA NATH SHARMA",
      party: "Moulik Adhikar Party",
      evm_votes: 3038,
      postal_votes: 4,
      total_votes: 3042,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 5,
      candidate: "PARAS YADAV",
      party: "Jan Raajya Party",
      evm_votes: 2489,
      postal_votes: 2,
      total_votes: 2491,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 6,
      candidate: "SHASHIDHAR",
      party: "Independent",
      evm_votes: 2227,
      postal_votes: 5,
      total_votes: 2232,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 7,
      candidate: "MAHENDRA NATH YADAV",
      party: "Moolniwasi Samaj Party",
      evm_votes: 1904,
      postal_votes: 1,
      total_votes: 1905,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "VIJAY KUMAR",
      party: "Independent",
      evm_votes: 1733,
      postal_votes: 2,
      total_votes: 1735,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "PANKAJ KUMAR YADAV",
      party: "Independent",
      evm_votes: 1595,
      postal_votes: 4,
      total_votes: 1599,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6211,
      postal_votes: 23,
      total_votes: 6234,
      percentage_of_votes: 0.59,
    },
  ],
  total: {
    evm_votes: 1048948,
    postal_votes: 5572,
    total_votes: 1054520,
  },
};
export const s2470 = {
  cs_name: "Ghosi",
  cs_shortname: "ghosi",
  cs_code: "s2470",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAJEEV RAI",
      party: "Samajwadi Party",
      evm_votes: 501407,
      postal_votes: 1724,
      total_votes: 503131,
      percentage_of_votes: 43.73,
    },
    {
      serial_no: 2,
      candidate: "DR. ARVIND RAJBHAR",
      party: "Suheldev Bharatiya Samaj Party",
      evm_votes: 339416,
      postal_votes: 772,
      total_votes: 340188,
      percentage_of_votes: 29.57,
    },
    {
      serial_no: 3,
      candidate: "BALKRISHNA CHAUHAN",
      party: "Bahujan Samaj Party",
      evm_votes: 208938,
      postal_votes: 466,
      total_votes: 209404,
      percentage_of_votes: 18.2,
    },
    {
      serial_no: 4,
      candidate: "LILAWATI RAJBHAR",
      party: "Moolniwasi Samaj Party",
      evm_votes: 47520,
      postal_votes: 7,
      total_votes: 47527,
      percentage_of_votes: 4.13,
    },
    {
      serial_no: 5,
      candidate: "PAWAN KUMAR CHAUHAN",
      party: "Independent",
      evm_votes: 7067,
      postal_votes: 10,
      total_votes: 7077,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 6,
      candidate: "VINOD KUMAR RAI",
      party: "Communist Party of India",
      evm_votes: 6987,
      postal_votes: 6,
      total_votes: 6993,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 7,
      candidate: "ROLI GUPTA",
      party: "Akhil Bhartiya Hamara Samaj Party",
      evm_votes: 4211,
      postal_votes: 14,
      total_votes: 4225,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 8,
      candidate: "VIRENDRA",
      party: "Rashtriya Jantantrik Bharat Vikas Party",
      evm_votes: 2344,
      postal_votes: 3,
      total_votes: 2347,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 9,
      candidate: "VIJAY",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2240,
      postal_votes: 1,
      total_votes: 2241,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 10,
      candidate: "AWADHESH KUMAR CHAUHAN",
      party: "Janta Kranti Party (Rashtravadi)",
      evm_votes: 2211,
      postal_votes: 28,
      total_votes: 2239,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 11,
      candidate: "SATISH CHAND PRAJAPATI FAUJI",
      party: "Samajhdar Party",
      evm_votes: 1918,
      postal_votes: 3,
      total_votes: 1921,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "MADAN RAJBHAR",
      party: "Mang Samaj Party",
      evm_votes: 1895,
      postal_votes: 10,
      total_votes: 1905,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 13,
      candidate: "SAUDAGER",
      party: "Independent",
      evm_votes: 1892,
      postal_votes: 0,
      total_votes: 1892,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 14,
      candidate: "RAJENDRA RAM",
      party: "Independent",
      evm_votes: 1649,
      postal_votes: 0,
      total_votes: 1649,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 15,
      candidate: "YAKUB ANSARI",
      party: "Peace Party",
      evm_votes: 1568,
      postal_votes: 5,
      total_votes: 1573,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 16,
      candidate: "PREM CHAND",
      party: "Pragatisheel Manav Samaj Party",
      evm_votes: 1559,
      postal_votes: 2,
      total_votes: 1561,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 17,
      candidate: "SANTOSH KUMAR GUPTA",
      party: "Janlok Vikas Party",
      evm_votes: 1473,
      postal_votes: 0,
      total_votes: 1473,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 18,
      candidate: "JYOTIRMA PATHAK",
      party: "Independent",
      evm_votes: 1199,
      postal_votes: 5,
      total_votes: 1204,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 19,
      candidate: "BADRI NATH",
      party: "Independent",
      evm_votes: 1164,
      postal_votes: 6,
      total_votes: 1170,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 20,
      candidate: "PREMCHAND NAYAK",
      party: "Independent",
      evm_votes: 1116,
      postal_votes: 1,
      total_votes: 1117,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 21,
      candidate: "RAMNARESH YADAV",
      party: "Jan Raajya Party",
      evm_votes: 881,
      postal_votes: 4,
      total_votes: 885,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 22,
      candidate: "RAJIV KUMAR SINGH",
      party: "Independent",
      evm_votes: 855,
      postal_votes: 2,
      total_votes: 857,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 23,
      candidate: "ISHWAR DAYAL SINGH SETH",
      party: "Bhartiya Sabka Dal",
      evm_votes: 827,
      postal_votes: 14,
      total_votes: 841,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 24,
      candidate: "PRAGYESH KUMAR",
      party: "Independent",
      evm_votes: 816,
      postal_votes: 2,
      total_votes: 818,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 25,
      candidate: "RAMVACHAN",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 771,
      postal_votes: 2,
      total_votes: 773,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 26,
      candidate: "RAJENDRA AGRAWAL",
      party: "Independent",
      evm_votes: 729,
      postal_votes: 1,
      total_votes: 730,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 27,
      candidate: "GOPAL SINGH",
      party: "Azad Adhikar Sena",
      evm_votes: 729,
      postal_votes: 8,
      total_votes: 737,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 28,
      candidate: "ISMAIL ANSARI",
      party: "Aawami Pichhada Party",
      evm_votes: 592,
      postal_votes: 2,
      total_votes: 594,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 29,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3487,
      postal_votes: 31,
      total_votes: 3518,
      percentage_of_votes: 0.31,
    },
  ],
  total: {
    evm_votes: 1147461,
    postal_votes: 3129,
    total_votes: 1150590,
  },
};
export const s2471 = {
  cs_name: "Salempur",
  cs_shortname: "salempur",
  cs_code: "s2471",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAMASHANKAR RAJBHAR",
      party: "Samajwadi Party",
      evm_votes: 403616,
      postal_votes: 1856,
      total_votes: 405472,
      percentage_of_votes: 44.2,
    },
    {
      serial_no: 2,
      candidate: "RAVINDAR KUSHAWAHA",
      party: "Bharatiya Janata Party",
      evm_votes: 400489,
      postal_votes: 1410,
      total_votes: 401899,
      percentage_of_votes: 43.81,
    },
    {
      serial_no: 3,
      candidate: "BHIM RAJBHAR",
      party: "Bahujan Samaj Party",
      evm_votes: 80291,
      postal_votes: 308,
      total_votes: 80599,
      percentage_of_votes: 8.79,
    },
    {
      serial_no: 4,
      candidate: "JAIBAHADUR ALIAS JAIBAHADUR CHAUHAN",
      party: "Janta Kranti Party (Rashtravadi)",
      evm_votes: 8241,
      postal_votes: 22,
      total_votes: 8263,
      percentage_of_votes: 0.9,
    },
    {
      serial_no: 5,
      candidate: "SHREENARAYAN MISHRA",
      party: "Akhil Bhartiya Sarvjan Hit Party",
      evm_votes: 3878,
      postal_votes: 14,
      total_votes: 3892,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 6,
      candidate: "SADDAM HUSSAIN",
      party: "Independent",
      evm_votes: 3210,
      postal_votes: 5,
      total_votes: 3215,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 7,
      candidate: "SHRIKRISHNA",
      party: "Independent",
      evm_votes: 2527,
      postal_votes: 5,
      total_votes: 2532,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 8,
      candidate: "AMRESH THAKUR",
      party: "Independent",
      evm_votes: 2147,
      postal_votes: 9,
      total_votes: 2156,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 9,
      candidate: "SURYA PRAKASH GAUTAM",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 1782,
      postal_votes: 3,
      total_votes: 1785,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7520,
      postal_votes: 29,
      total_votes: 7549,
      percentage_of_votes: 0.82,
    },
  ],
  total: {
    evm_votes: 913701,
    postal_votes: 3661,
    total_votes: 917362,
  },
};
export const s2472 = {
  cs_name: "Ballia",
  cs_shortname: "ballia",
  cs_code: "s2472",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "SANATAN PANDEY",
      party: "Samajwadi Party",
      evm_votes: 464473,
      postal_votes: 2595,
      total_votes: 467068,
      percentage_of_votes: 46.37,
    },
    {
      serial_no: 2,
      candidate: "NEERAJ SHEKHAR",
      party: "Bharatiya Janata Party",
      evm_votes: 421277,
      postal_votes: 2407,
      total_votes: 423684,
      percentage_of_votes: 42.06,
    },
    {
      serial_no: 3,
      candidate: "LALLAN SINGH YADAV",
      party: "Bahujan Samaj Party",
      evm_votes: 84943,
      postal_votes: 262,
      total_votes: 85205,
      percentage_of_votes: 8.46,
    },
    {
      serial_no: 4,
      candidate: "SUMESHWAR",
      party: "Independent",
      evm_votes: 5056,
      postal_votes: 0,
      total_votes: 5056,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 5,
      candidate: "SHESHNATH",
      party: "Independent",
      evm_votes: 3587,
      postal_votes: 1,
      total_votes: 3588,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 6,
      candidate: "RAVI KANT SINGH ALIAS RAVI PATEL",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 3174,
      postal_votes: 7,
      total_votes: 3181,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 7,
      candidate: "RAMNIVAS GOND",
      party: "Gondvana Gantantra Party",
      evm_votes: 2642,
      postal_votes: 30,
      total_votes: 2672,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 8,
      candidate: "SURYA BALI PRASAD",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 2143,
      postal_votes: 5,
      total_votes: 2148,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 9,
      candidate: "RANJANA",
      party: "Independent",
      evm_votes: 1871,
      postal_votes: 7,
      total_votes: 1878,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 10,
      candidate: "ASHOK GUPTA",
      party: "Independent",
      evm_votes: 1339,
      postal_votes: 8,
      total_votes: 1347,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "MANINDR",
      party: "Independent",
      evm_votes: 1186,
      postal_votes: 1,
      total_votes: 1187,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "AVADHESH VERMA",
      party: "Independent",
      evm_votes: 1150,
      postal_votes: 11,
      total_votes: 1161,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "PRAKASH KUMAR",
      party: "Independent",
      evm_votes: 960,
      postal_votes: 0,
      total_votes: 960,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8167,
      postal_votes: 20,
      total_votes: 8187,
      percentage_of_votes: 0.81,
    },
  ],
  total: {
    evm_votes: 1001968,
    postal_votes: 5354,
    total_votes: 1007322,
  },
};
export const s2473 = {
  cs_name: "Jaunpur",
  cs_shortname: "jaunpur",
  cs_code: "s2473",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "BABU SINGH KUSHWAHA",
      party: "Samajwadi Party",
      evm_votes: 506076,
      postal_votes: 3054,
      total_votes: 509130,
      percentage_of_votes: 46.21,
    },
    {
      serial_no: 2,
      candidate: "KRIPASHANKAR SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 408512,
      postal_votes: 1283,
      total_votes: 409795,
      percentage_of_votes: 37.19,
    },
    {
      serial_no: 3,
      candidate: "SHYAM SINGH YADAV",
      party: "Bahujan Samaj Party",
      evm_votes: 156691,
      postal_votes: 446,
      total_votes: 157137,
      percentage_of_votes: 14.26,
    },
    {
      serial_no: 4,
      candidate: "SARFARAZ AHMAD",
      party: "Independent",
      evm_votes: 2750,
      postal_votes: 0,
      total_votes: 2750,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 5,
      candidate: "ASHOK SINGH",
      party: "Samaj Vikas Kranti Party",
      evm_votes: 2749,
      postal_votes: 6,
      total_votes: 2755,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 6,
      candidate: "JIYALAL PRAJAPATI",
      party: "Bhagidari Party(P)",
      evm_votes: 2232,
      postal_votes: 3,
      total_votes: 2235,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 7,
      candidate: "SHRIPATI MAURYA",
      party: "Independent",
      evm_votes: 2091,
      postal_votes: 3,
      total_votes: 2094,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 8,
      candidate: "SHAH ALAM",
      party: "Apna Dal (Kamerawadi)",
      evm_votes: 2003,
      postal_votes: 0,
      total_votes: 2003,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 9,
      candidate: "FULACHANDR",
      party: "Moulik Adhikar Party",
      evm_votes: 1632,
      postal_votes: 1,
      total_votes: 1633,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "KRIPASHANKAR C PANDEY",
      party: "Independent",
      evm_votes: 1446,
      postal_votes: 1,
      total_votes: 1447,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "GOVIND LAL",
      party: "Independent",
      evm_votes: 1387,
      postal_votes: 2,
      total_votes: 1389,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 12,
      candidate: "RAM PYARE",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1160,
      postal_votes: 1,
      total_votes: 1161,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 13,
      candidate: "NISHA PATEL",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 1125,
      postal_votes: 3,
      total_votes: 1128,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 14,
      candidate: "BHARAT RAM",
      party: "Pachchasi Parivartan Samaj Party",
      evm_votes: 802,
      postal_votes: 0,
      total_votes: 802,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6301,
      postal_votes: 28,
      total_votes: 6329,
      percentage_of_votes: 0.57,
    },
  ],
  total: {
    evm_votes: 1096957,
    postal_votes: 4831,
    total_votes: 1101788,
  },
};
export const s2474 = {
  cs_name: "Machhlishahr",
  cs_shortname: "machhlishahr",
  cs_code: "s2474",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "PRIYA SAROJ",
      party: "Samajwadi Party",
      evm_votes: 449561,
      postal_votes: 1731,
      total_votes: 451292,
      percentage_of_votes: 42.57,
    },
    {
      serial_no: 2,
      candidate: "BHOLANATH (B.P. SAROJ)",
      party: "Bharatiya Janata Party",
      evm_votes: 414494,
      postal_votes: 948,
      total_votes: 415442,
      percentage_of_votes: 39.19,
    },
    {
      serial_no: 3,
      candidate: "KRIPA SHANKAR SAROJ",
      party: "Bahujan Samaj Party",
      evm_votes: 156930,
      postal_votes: 361,
      total_votes: 157291,
      percentage_of_votes: 14.84,
    },
    {
      serial_no: 4,
      candidate: "SHRAVAN KUMAR",
      party: "Independent",
      evm_votes: 5804,
      postal_votes: 3,
      total_votes: 5807,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 5,
      candidate: "URMILA",
      party: "Samaj Parivartan Party",
      evm_votes: 5296,
      postal_votes: 7,
      total_votes: 5303,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 6,
      candidate: "SUBAS",
      party: "Independent",
      evm_votes: 3691,
      postal_votes: 2,
      total_votes: 3693,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 7,
      candidate: "RANG BAHADUR",
      party: "Independent",
      evm_votes: 2715,
      postal_votes: 3,
      total_votes: 2718,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 8,
      candidate: "BRIJESH KUMAR SAROJ",
      party: "Prabuddhwadi Bahujan Morcha",
      evm_votes: 2365,
      postal_votes: 0,
      total_votes: 2365,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 9,
      candidate: "RAJENDRA PRASAD",
      party: "Rashtra Uday Party",
      evm_votes: 2066,
      postal_votes: 1,
      total_votes: 2067,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 10,
      candidate: "RAM MILAN",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 1713,
      postal_votes: 3,
      total_votes: 1716,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 11,
      candidate: "KAMLESH",
      party: "Azad Samaj Party",
      evm_votes: 1698,
      postal_votes: 4,
      total_votes: 1702,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 12,
      candidate: "BRIJESH KUMAR",
      party: "Prithviraj Janshakti Party",
      evm_votes: 1362,
      postal_votes: 2,
      total_votes: 1364,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9277,
      postal_votes: 26,
      total_votes: 9303,
      percentage_of_votes: 0.88,
    },
  ],
  total: {
    evm_votes: 1056972,
    postal_votes: 3091,
    total_votes: 1060063,
  },
};
export const s2475 = {
  cs_name: "Ghazipur",
  cs_shortname: "ghazipur",
  cs_code: "s2475",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "AFZAL ANSARI",
      party: "Samajwadi Party",
      evm_votes: 537784,
      postal_votes: 2128,
      total_votes: 539912,
      percentage_of_votes: 46.82,
    },
    {
      serial_no: 2,
      candidate: "PARS NATH RAI",
      party: "Bharatiya Janata Party",
      evm_votes: 413518,
      postal_votes: 1533,
      total_votes: 415051,
      percentage_of_votes: 35.99,
    },
    {
      serial_no: 3,
      candidate: "UMESH KUMAR SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 164601,
      postal_votes: 363,
      total_votes: 164964,
      percentage_of_votes: 14.31,
    },
    {
      serial_no: 4,
      candidate: "AJAY",
      party: "Vishwa Kalyan Rashtriya Manav Samaj Party",
      evm_votes: 5068,
      postal_votes: 6,
      total_votes: 5074,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 5,
      candidate: "NUSRAT ANSARI",
      party: "Independent",
      evm_votes: 4567,
      postal_votes: 49,
      total_votes: 4616,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 6,
      candidate: "ADITYA SHRIVASTAV",
      party: "Yuga Thulasi Party",
      evm_votes: 3312,
      postal_votes: 4,
      total_votes: 3316,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 7,
      candidate: "SATYDEV YADAV",
      party: "Independent",
      evm_votes: 3163,
      postal_votes: 9,
      total_votes: 3172,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 8,
      candidate: "GYANCHANDRA BIND",
      party: "Independent",
      evm_votes: 3127,
      postal_votes: 8,
      total_votes: 3135,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 9,
      candidate: "RAMPRAVESH",
      party: "Moulik Adhikar Party",
      evm_votes: 2830,
      postal_votes: 3,
      total_votes: 2833,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 10,
      candidate: "DHANANJAY KUMAR TIWARI",
      party: "Bhartiya Lokvani Party",
      evm_votes: 1949,
      postal_votes: 7,
      total_votes: 1956,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9033,
      postal_votes: 32,
      total_votes: 9065,
      percentage_of_votes: 0.79,
    },
  ],
  total: {
    evm_votes: 1148952,
    postal_votes: 4142,
    total_votes: 1153094,
  },
};
export const s2476 = {
  cs_name: "Chandauli",
  cs_shortname: "chandauli",
  cs_code: "s2476",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "BIRENDRA SINGH",
      party: "Samajwadi Party",
      evm_votes: 472928,
      postal_votes: 1548,
      total_votes: 474476,
      percentage_of_votes: 42.5,
    },
    {
      serial_no: 2,
      candidate: "DR. MAHENDRA NATH PANDEY",
      party: "Bharatiya Janata Party",
      evm_votes: 451802,
      postal_votes: 1109,
      total_votes: 452911,
      percentage_of_votes: 40.57,
    },
    {
      serial_no: 3,
      candidate: "SATYENDRA KUMAR MAURYA",
      party: "Bahujan Samaj Party",
      evm_votes: 159585,
      postal_votes: 318,
      total_votes: 159903,
      percentage_of_votes: 14.32,
    },
    {
      serial_no: 4,
      candidate: "SHOBHANATH PRAJAPATI",
      party: "Bhagidari Party(P)",
      evm_votes: 4027,
      postal_votes: 5,
      total_votes: 4032,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 5,
      candidate: "SHER SINGH",
      party: "Yuga Thulasi Party",
      evm_votes: 3090,
      postal_votes: 6,
      total_votes: 3096,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 6,
      candidate: "SANTOSH KUMAR",
      party: "Independent",
      evm_votes: 2965,
      postal_votes: 4,
      total_votes: 2969,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 7,
      candidate: "RAJESH VISHWAKARMA",
      party: "Moulik Adhikar Party",
      evm_votes: 2759,
      postal_votes: 3,
      total_votes: 2762,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 8,
      candidate: "ARVIND KUMAR PATEL",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 2734,
      postal_votes: 11,
      total_votes: 2745,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 9,
      candidate: "SANJAY KUMAR SINHA",
      party: "Jai Hind National Party",
      evm_votes: 2617,
      postal_votes: 6,
      total_votes: 2623,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 10,
      candidate: "RAM GOVIND",
      party: "Samajhdar Party",
      evm_votes: 1975,
      postal_votes: 2,
      total_votes: 1977,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8987,
      postal_votes: 18,
      total_votes: 9005,
      percentage_of_votes: 0.81,
    },
  ],
  total: {
    evm_votes: 1113469,
    postal_votes: 3030,
    total_votes: 1116499,
  },
};
export const s2480 = {
  cs_name: "Robertsganj",
  cs_shortname: "robertsganj",
  cs_code: "s2480",
  winner: "SP",
  stats: [
    {
      serial_no: 1,
      candidate: "CHHOTELAL",
      party: "Samajwadi Party",
      evm_votes: 465240,
      postal_votes: 608,
      total_votes: 465848,
      percentage_of_votes: 46.14,
    },
    {
      serial_no: 2,
      candidate: "RINKI SINGH",
      party: "Apna Dal (Soneylal)",
      evm_votes: 336344,
      postal_votes: 270,
      total_votes: 336614,
      percentage_of_votes: 33.34,
    },
    {
      serial_no: 3,
      candidate: "DHANESHWAR",
      party: "Bahujan Samaj Party",
      evm_votes: 118669,
      postal_votes: 109,
      total_votes: 118778,
      percentage_of_votes: 11.77,
    },
    {
      serial_no: 4,
      candidate: "ASHOK KUMAR KANNAUJIYA",
      party: "Communist Party of India",
      evm_votes: 23830,
      postal_votes: 22,
      total_votes: 23852,
      percentage_of_votes: 2.36,
    },
    {
      serial_no: 5,
      candidate: "SUBHAGI",
      party: "Janhit Sankalp Party",
      evm_votes: 8191,
      postal_votes: 3,
      total_votes: 8194,
      percentage_of_votes: 0.81,
    },
    {
      serial_no: 6,
      candidate: "ARVIND KUMAR BHARTI",
      party: "Rashtriya Samanta Dal",
      evm_votes: 7583,
      postal_votes: 9,
      total_votes: 7592,
      percentage_of_votes: 0.75,
    },
    {
      serial_no: 7,
      candidate: "SUKALU",
      party: "Independent",
      evm_votes: 6701,
      postal_votes: 11,
      total_votes: 6712,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 8,
      candidate: "SHIVPUJAN",
      party: "Rashtriya Samaj Paksha",
      evm_votes: 6422,
      postal_votes: 22,
      total_votes: 6444,
      percentage_of_votes: 0.64,
    },
    {
      serial_no: 9,
      candidate: "SONU NIGAM",
      party: "Independent",
      evm_votes: 6142,
      postal_votes: 2,
      total_votes: 6144,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 10,
      candidate: "CHANDRIKA PRASAD",
      party: "Janta Kranti Party (Rashtravadi)",
      evm_votes: 3732,
      postal_votes: 9,
      total_votes: 3741,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 11,
      candidate: "PRABHUDAYAL",
      party: "Rashtriya Samaj Dal (R)",
      evm_votes: 3470,
      postal_votes: 1,
      total_votes: 3471,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 12,
      candidate: "RAM SHIROMANI",
      party: "Sardar Patel Siddhant Party",
      evm_votes: 3116,
      postal_votes: 7,
      total_votes: 3123,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 19019,
      postal_votes: 13,
      total_votes: 19032,
      percentage_of_votes: 1.89,
    },
  ],
  total: {
    evm_votes: 1008459,
    postal_votes: 1086,
    total_votes: 1009545,
  },
};
export const s251 = {
  cs_name: "Coochbehar",
  cs_shortname: "coochbehar",
  cs_code: "s251",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "JAGADISH CHANDRA BARMA BASUNIA",
      party: "All India Trinamool Congress",
      evm_votes: 785747,
      postal_votes: 2628,
      total_votes: 788375,
      percentage_of_votes: 48.57,
    },
    {
      serial_no: 2,
      candidate: "NISITH PRAMANIK",
      party: "Bharatiya Janata Party",
      evm_votes: 745144,
      postal_votes: 3981,
      total_votes: 749125,
      percentage_of_votes: 46.16,
    },
    {
      serial_no: 3,
      candidate: "NITISH CHANDRA ROY",
      party: "All India Forward Bloc",
      evm_votes: 29992,
      postal_votes: 275,
      total_votes: 30267,
      percentage_of_votes: 1.86,
    },
    {
      serial_no: 4,
      candidate: "PIYA ROY CHOWDHURY",
      party: "Indian National Congress",
      evm_votes: 10539,
      postal_votes: 140,
      total_votes: 10679,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 5,
      candidate: "HAREKRISHNA SARKAR",
      party: "Independent",
      evm_votes: 9462,
      postal_votes: 5,
      total_votes: 9467,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 6,
      candidate: "KAMALESH BARMAN",
      party: "Independent",
      evm_votes: 2972,
      postal_votes: 21,
      total_votes: 2993,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 7,
      candidate: "NABI BARMAN",
      party: "Independent",
      evm_votes: 2710,
      postal_votes: 14,
      total_votes: 2724,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 8,
      candidate: "SUBODH BARMAN",
      party: "Independent",
      evm_votes: 2594,
      postal_votes: 5,
      total_votes: 2599,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "DILIP CHANDRA BARMAN",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2449,
      postal_votes: 19,
      total_votes: 2468,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "PURNA MOHAN ROY",
      party: "Bahujan Samaj Party",
      evm_votes: 2087,
      postal_votes: 10,
      total_votes: 2097,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "BIDHAN DAS",
      party: "Independent",
      evm_votes: 2067,
      postal_votes: 5,
      total_votes: 2072,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 12,
      candidate: "KISHORE RAY",
      party: "Independent",
      evm_votes: 1588,
      postal_votes: 18,
      total_votes: 1606,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 13,
      candidate: "PRADIP KUMAR ROY",
      party: "Kamatapur People’s Party (United)",
      evm_votes: 1499,
      postal_votes: 19,
      total_votes: 1518,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "AMAL DAS",
      party: "Independent",
      evm_votes: 1488,
      postal_votes: 7,
      total_votes: 1495,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 15510,
      postal_votes: 53,
      total_votes: 15563,
      percentage_of_votes: 0.96,
    },
  ],
  total: {
    evm_votes: 1615848,
    postal_votes: 7200,
    total_votes: 1623048,
  },
};
export const s259 = {
  cs_name: "Jangipur",
  cs_shortname: "jangipur",
  cs_code: "s259",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "KHALILUR RAHAMAN",
      party: "All India Trinamool Congress",
      evm_votes: 543449,
      postal_votes: 978,
      total_votes: 544427,
      percentage_of_votes: 39.75,
    },
    {
      serial_no: 2,
      candidate: "MURTOJA HOSSAIN BOKUL",
      party: "Indian National Congress",
      evm_votes: 427105,
      postal_votes: 685,
      total_votes: 427790,
      percentage_of_votes: 31.23,
    },
    {
      serial_no: 3,
      candidate: "DHANANJAY GHOSH",
      party: "Bharatiya Janata Party",
      evm_votes: 339868,
      postal_votes: 946,
      total_votes: 340814,
      percentage_of_votes: 24.88,
    },
    {
      serial_no: 4,
      candidate: "REJAUL MONDAL",
      party: "Independent",
      evm_votes: 7500,
      postal_votes: 0,
      total_votes: 7500,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 5,
      candidate: "SAJAHAN BISWAS",
      party: "All India Secular Front",
      evm_votes: 6847,
      postal_votes: 11,
      total_votes: 6858,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 6,
      candidate: "MUKTIPADA KONAI",
      party: "Independent",
      evm_votes: 5670,
      postal_votes: 1,
      total_votes: 5671,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 7,
      candidate: "ASHISH BHUIMALI",
      party: "Independent",
      evm_votes: 3949,
      postal_votes: 2,
      total_votes: 3951,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 8,
      candidate: "MD SARWARUL ISLAM SARKAR",
      party: "Bahujan Samaj Party",
      evm_votes: 3511,
      postal_votes: 4,
      total_votes: 3515,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 9,
      candidate: "MD SAHABUDDIN",
      party: "Social Democratic Party Of India",
      evm_votes: 3489,
      postal_votes: 7,
      total_votes: 3496,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 10,
      candidate: "SAMIRUDDIN",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1946,
      postal_votes: 4,
      total_votes: 1950,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "PARIMAL GHOSH",
      party: "Independent",
      evm_votes: 1928,
      postal_votes: 3,
      total_votes: 1931,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 12,
      candidate: "SANUAR ALI",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 1698,
      postal_votes: 2,
      total_votes: 1700,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "RAJIB CHATTERJEE",
      party: "Bhartiya Lokmat Rashtrwadi Party",
      evm_votes: 1527,
      postal_votes: 5,
      total_votes: 1532,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 14,
      candidate: "MD ASADUL BISWAS",
      party: "Independent",
      evm_votes: 1340,
      postal_votes: 0,
      total_votes: 1340,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 17135,
      postal_votes: 23,
      total_votes: 17158,
      percentage_of_votes: 1.25,
    },
  ],
  total: {
    evm_votes: 1366962,
    postal_votes: 2671,
    total_votes: 1369633,
  },
};
export const s2510 = {
  cs_name: "Baharampur",
  cs_shortname: "baharampur",
  cs_code: "s2510",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "PATHAN YUSUF",
      party: "All India Trinamool Congress",
      evm_votes: 523630,
      postal_votes: 886,
      total_votes: 524516,
      percentage_of_votes: 37.88,
    },
    {
      serial_no: 2,
      candidate: "ADHIR RANJAN CHOWDHURY",
      party: "Indian National Congress",
      evm_votes: 438171,
      postal_votes: 1323,
      total_votes: 439494,
      percentage_of_votes: 31.74,
    },
    {
      serial_no: 3,
      candidate: "DR. NIRMAL KUMAR SAHA",
      party: "Bharatiya Janata Party",
      evm_votes: 370370,
      postal_votes: 1515,
      total_votes: 371885,
      percentage_of_votes: 26.86,
    },
    {
      serial_no: 4,
      candidate: "SAMIRAN DAS",
      party: "Independent",
      evm_votes: 18526,
      postal_votes: 1,
      total_votes: 18527,
      percentage_of_votes: 1.34,
    },
    {
      serial_no: 5,
      candidate: "SWAPAN KUMAR MANDAL",
      party: "Independent",
      evm_votes: 6394,
      postal_votes: 2,
      total_votes: 6396,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 6,
      candidate: "SANTOSH BISWAS",
      party: "Bahujan Samaj Party",
      evm_votes: 4219,
      postal_votes: 19,
      total_votes: 4238,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 7,
      candidate: "BISWAMVOIR KALITA",
      party: "Independent",
      evm_votes: 3060,
      postal_votes: 2,
      total_votes: 3062,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 8,
      candidate: "SOMNATH PAUL",
      party: "All India Arya Mahasabha",
      evm_votes: 2250,
      postal_votes: 0,
      total_votes: 2250,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "RUNA LAILA",
      party: "Social Democratic Party Of India",
      evm_votes: 1569,
      postal_votes: 2,
      total_votes: 1571,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "ABHIJIT MANDAL",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1410,
      postal_votes: 8,
      total_votes: 1418,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "ASHIS DAS",
      party: "Independent",
      evm_votes: 1106,
      postal_votes: 4,
      total_votes: 1110,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "ADHIR SWARNAKAR",
      party: "Independent",
      evm_votes: 851,
      postal_votes: 5,
      total_votes: 856,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "AJIT KUMAR MANDAL",
      party: "Independent",
      evm_votes: 745,
      postal_votes: 4,
      total_votes: 749,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "MANIK KUMAR DAS",
      party: "Jai Prakash Janata Dal",
      evm_votes: 729,
      postal_votes: 0,
      total_votes: 729,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "AABDUSA SATTAR SEKH",
      party: "Indian National Socialistic Action Forces",
      evm_votes: 477,
      postal_votes: 7,
      total_votes: 484,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7474,
      postal_votes: 11,
      total_votes: 7485,
      percentage_of_votes: 0.54,
    },
  ],
  total: {
    evm_votes: 1380981,
    postal_votes: 3789,
    total_votes: 1384770,
  },
};
export const s2511 = {
  cs_name: "Murshidabad",
  cs_shortname: "murshidabad",
  cs_code: "s2511",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "ABU TAHER KHAN",
      party: "All India Trinamool Congress",
      evm_votes: 681674,
      postal_votes: 768,
      total_votes: 682442,
      percentage_of_votes: 44.27,
    },
    {
      serial_no: 2,
      candidate: "MD SALIM",
      party: "Communist Party of India (Marxist)",
      evm_votes: 517733,
      postal_votes: 494,
      total_votes: 518227,
      percentage_of_votes: 33.62,
    },
    {
      serial_no: 3,
      candidate: "GOURI SANKAR GHOSH",
      party: "Bharatiya Janata Party",
      evm_votes: 291013,
      postal_votes: 1018,
      total_votes: 292031,
      percentage_of_votes: 18.94,
    },
    {
      serial_no: 4,
      candidate: "HABIB SAIKH",
      party: "All India Secular Front",
      evm_votes: 12359,
      postal_votes: 11,
      total_votes: 12370,
      percentage_of_votes: 0.8,
    },
    {
      serial_no: 5,
      candidate: "ASIM RAY",
      party: "Bhartiya Lokmat Rashtrwadi Party",
      evm_votes: 5522,
      postal_votes: 12,
      total_votes: 5534,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 6,
      candidate: "SAHABUDDIN MALLIK",
      party: "Independent",
      evm_votes: 4156,
      postal_votes: 0,
      total_votes: 4156,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 7,
      candidate: "RAFIKUL ISLAM",
      party: "Independent",
      evm_votes: 4064,
      postal_votes: 1,
      total_votes: 4065,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 8,
      candidate: "MAHAFUJUL ALAM",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 3423,
      postal_votes: 3,
      total_votes: 3426,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 9,
      candidate: "MD TAIEDUL ISLAM",
      party: "Social Democratic Party Of India",
      evm_votes: 3291,
      postal_votes: 2,
      total_votes: 3293,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 10,
      candidate: "DHANANJOY SARKAR",
      party: "Independent",
      evm_votes: 1535,
      postal_votes: 4,
      total_votes: 1539,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "KRISHNA PROSAD BISWAS",
      party: "Independent",
      evm_votes: 1197,
      postal_votes: 9,
      total_votes: 1206,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13249,
      postal_votes: 21,
      total_votes: 13270,
      percentage_of_votes: 0.86,
    },
  ],
  total: {
    evm_votes: 1539216,
    postal_votes: 2343,
    total_votes: 1541559,
  },
};
export const s2512 = {
  cs_name: "Krishnanagar",
  cs_shortname: "krishnanagar",
  cs_code: "s2512",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "MAHUA MOITRA",
      party: "All India Trinamool Congress",
      evm_votes: 624711,
      postal_votes: 4078,
      total_votes: 628789,
      percentage_of_votes: 44.1,
    },
    {
      serial_no: 2,
      candidate: "AMRITA ROY",
      party: "Bharatiya Janata Party",
      evm_votes: 567628,
      postal_votes: 4456,
      total_votes: 572084,
      percentage_of_votes: 40.13,
    },
    {
      serial_no: 3,
      candidate: "S.M. SADI",
      party: "Communist Party of India (Marxist)",
      evm_votes: 179063,
      postal_votes: 1138,
      total_votes: 180201,
      percentage_of_votes: 12.64,
    },
    {
      serial_no: 4,
      candidate: "SANJIT BISWAS",
      party: "Independent",
      evm_votes: 8660,
      postal_votes: 11,
      total_votes: 8671,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 5,
      candidate: "SOURAV HELA",
      party: "Independent",
      evm_votes: 5061,
      postal_votes: 4,
      total_votes: 5065,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 6,
      candidate: "NEMAI BISWAS",
      party: "Independent",
      evm_votes: 5047,
      postal_votes: 22,
      total_votes: 5069,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 7,
      candidate: "AMAL CHANDRA SARKAR",
      party: "Bahujan Samaj Party",
      evm_votes: 5045,
      postal_votes: 55,
      total_votes: 5100,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 8,
      candidate: "AFROJA KHATUN MONDAL",
      party: "All India Secular Front",
      evm_votes: 4284,
      postal_votes: 14,
      total_votes: 4298,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 9,
      candidate: "ISMAT ARA MONDAL",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 3603,
      postal_votes: 19,
      total_votes: 3622,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 10,
      candidate: "MD. MUKTAR SHAIKH",
      party: "Independent",
      evm_votes: 3287,
      postal_votes: 7,
      total_votes: 3294,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 11,
      candidate: "ALIK KUMAR KUNDU",
      party: "Independent",
      evm_votes: 2699,
      postal_votes: 9,
      total_votes: 2708,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6754,
      postal_votes: 57,
      total_votes: 6811,
      percentage_of_votes: 0.48,
    },
  ],
  total: {
    evm_votes: 1415842,
    postal_votes: 9870,
    total_votes: 1425712,
  },
};
export const s2515 = {
  cs_name: "Barrackpur",
  cs_shortname: "barrackpur",
  cs_code: "s2515",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "PARTHA BHOWMICK",
      party: "All India Trinamool Congress",
      evm_votes: 518486,
      postal_votes: 1745,
      total_votes: 520231,
      percentage_of_votes: 45.56,
    },
    {
      serial_no: 2,
      candidate: "ARJUN SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 454184,
      postal_votes: 1609,
      total_votes: 455793,
      percentage_of_votes: 39.92,
    },
    {
      serial_no: 3,
      candidate: "DEBDUT GHOSH",
      party: "Communist Party of India (Marxist)",
      evm_votes: 109038,
      postal_votes: 526,
      total_votes: 109564,
      percentage_of_votes: 9.6,
    },
    {
      serial_no: 4,
      candidate: "MD JAMIR HOSSAIN",
      party: "All India Secular Front",
      evm_votes: 18797,
      postal_votes: 9,
      total_votes: 18806,
      percentage_of_votes: 1.65,
    },
    {
      serial_no: 5,
      candidate: "ROHIT KUMAR PATHAK",
      party: "Independent",
      evm_votes: 7116,
      postal_votes: 16,
      total_votes: 7132,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 6,
      candidate: "CHAPALA RAY (MAJUMDER)",
      party: "Bahujan Samaj Party",
      evm_votes: 3882,
      postal_votes: 27,
      total_votes: 3909,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 7,
      candidate: "SHARMISTHA DAS",
      party: "Independent",
      evm_votes: 3622,
      postal_votes: 12,
      total_votes: 3634,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 8,
      candidate: "ANJAN GOSWAMI",
      party: "Bharatiya Nyay-Adhikar Raksha Party",
      evm_votes: 3434,
      postal_votes: 14,
      total_votes: 3448,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 9,
      candidate: "DHARMENDRA SINGH",
      party: "Independent",
      evm_votes: 2843,
      postal_votes: 4,
      total_votes: 2847,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 10,
      candidate: "SANJAY SINGH",
      party: "Independent",
      evm_votes: 1670,
      postal_votes: 6,
      total_votes: 1676,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 11,
      candidate: "DEBASISH BANERJEE",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1422,
      postal_votes: 12,
      total_votes: 1434,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 12,
      candidate: "KUNDAN SINGH",
      party: "Independent",
      evm_votes: 820,
      postal_votes: 6,
      total_votes: 826,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "UDAY VEER CHOUDHURY",
      party: "Independent",
      evm_votes: 745,
      postal_votes: 12,
      total_votes: 757,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 14,
      candidate: "AMIT KUMAR CHOUBEY",
      party: "Independent",
      evm_votes: 717,
      postal_votes: 4,
      total_votes: 721,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11043,
      postal_votes: 39,
      total_votes: 11082,
      percentage_of_votes: 0.97,
    },
  ],
  total: {
    evm_votes: 1137819,
    postal_votes: 4041,
    total_votes: 1141860,
  },
};
export const s2516 = {
  cs_name: "Dum_dum",
  cs_shortname: "dumdum",
  cs_code: "s2516",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "SOUGATA RAY",
      party: "All India Trinamool Congress",
      evm_votes: 526289,
      postal_votes: 2290,
      total_votes: 528579,
      percentage_of_votes: 41.95,
    },
    {
      serial_no: 2,
      candidate: "SILBHADRA DATTA",
      party: "Bharatiya Janata Party",
      evm_votes: 456138,
      postal_votes: 1781,
      total_votes: 457919,
      percentage_of_votes: 36.34,
    },
    {
      serial_no: 3,
      candidate: "SUJAN CHAKRABORTY",
      party: "Communist Party of India (Marxist)",
      evm_votes: 239418,
      postal_votes: 1366,
      total_votes: 240784,
      percentage_of_votes: 19.11,
    },
    {
      serial_no: 4,
      candidate: "SHEKH IMANUR RAHMAN",
      party: "Independent",
      evm_votes: 6410,
      postal_votes: 1,
      total_votes: 6411,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 5,
      candidate: "SWAMINATH KORI",
      party: "Bahujan Samaj Party",
      evm_votes: 3749,
      postal_votes: 17,
      total_votes: 3766,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 6,
      candidate: "HIRAK SINHA",
      party: "Independent",
      evm_votes: 3144,
      postal_votes: 3,
      total_votes: 3147,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 7,
      candidate: "BANAMALI PANDA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1728,
      postal_votes: 11,
      total_votes: 1739,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 8,
      candidate: "DULU DEY",
      party: "Independent",
      evm_votes: 1688,
      postal_votes: 6,
      total_votes: 1694,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 9,
      candidate: "MANIKA MUKHERJEE",
      party: "Bharatiya Nyay-Adhikar Raksha Party",
      evm_votes: 1231,
      postal_votes: 6,
      total_votes: 1237,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 10,
      candidate: "SANTANU MANDAL",
      party: "Democratic Socialist Party of India",
      evm_votes: 880,
      postal_votes: 5,
      total_votes: 885,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "TRIDIB BHUNIA",
      party: "Independent",
      evm_votes: 765,
      postal_votes: 7,
      total_votes: 772,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "GOURI BISWAS",
      party: "Independent",
      evm_votes: 698,
      postal_votes: 3,
      total_votes: 701,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "SUBRATA BHOWMIK",
      party: "Mera Bharat Mahan Party",
      evm_votes: 602,
      postal_votes: 2,
      total_votes: 604,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "HARI PADA BISWAS",
      party: "Mulnibasi Party of India",
      evm_votes: 465,
      postal_votes: 9,
      total_votes: 474,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11256,
      postal_votes: 78,
      total_votes: 11334,
      percentage_of_votes: 0.9,
    },
  ],
  total: {
    evm_votes: 1254461,
    postal_votes: 5585,
    total_votes: 1260046,
  },
};
export const s2517 = {
  cs_name: "Barasat",
  cs_shortname: "barasat",
  cs_code: "s2517",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "KAKOLI GHOSH DASTIDAR",
      party: "All India Trinamool Congress",
      evm_votes: 689513,
      postal_votes: 2497,
      total_votes: 692010,
      percentage_of_votes: 45.15,
    },
    {
      serial_no: 2,
      candidate: "SWAPAN MAJUMDER",
      party: "Bharatiya Janata Party",
      evm_votes: 576024,
      postal_votes: 1797,
      total_votes: 577821,
      percentage_of_votes: 37.7,
    },
    {
      serial_no: 3,
      candidate: "TAPAS BANERJEE",
      party: "All India Secular Front",
      evm_votes: 121355,
      postal_votes: 85,
      total_votes: 121440,
      percentage_of_votes: 7.92,
    },
    {
      serial_no: 4,
      candidate: "SANJIB CHATTERJEE",
      party: "All India Forward Bloc",
      evm_votes: 99456,
      postal_votes: 544,
      total_votes: 100000,
      percentage_of_votes: 6.52,
    },
    {
      serial_no: 5,
      candidate: "SMRITIKANA HAOLADAR",
      party: "Bahujan Samaj Party",
      evm_votes: 5759,
      postal_votes: 28,
      total_votes: 5787,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 6,
      candidate: "SAIFUDDIN MONDAL",
      party: "Independent",
      evm_votes: 3823,
      postal_votes: 4,
      total_votes: 3827,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 7,
      candidate: "SWAPAN MAJUMDAR",
      party: "Independent",
      evm_votes: 3083,
      postal_votes: 2,
      total_votes: 3085,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 8,
      candidate: "S MUKHTAR AHMED",
      party: "Independent",
      evm_votes: 2639,
      postal_votes: 8,
      total_votes: 2647,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 9,
      candidate: "SEFALI DEY",
      party: "Bharatiya Nyay-Adhikar Raksha Party",
      evm_votes: 2244,
      postal_votes: 8,
      total_votes: 2252,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "SADHAN GHOSH",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2025,
      postal_votes: 22,
      total_votes: 2047,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "BIKASH SARKAR",
      party: "Mulnibasi Party of India",
      evm_votes: 1928,
      postal_votes: 1,
      total_votes: 1929,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 12,
      candidate: "MOHAN LAL ADHIKARY",
      party: "Independent",
      evm_votes: 1850,
      postal_votes: 14,
      total_votes: 1864,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 17834,
      postal_votes: 58,
      total_votes: 17892,
      percentage_of_votes: 1.17,
    },
  ],
  total: {
    evm_votes: 1527533,
    postal_votes: 5068,
    total_votes: 1532601,
  },
};
export const s2518 = {
  cs_name: "Basirhat",
  cs_shortname: "basirhat",
  cs_code: "s2518",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "SK NURUL ISLAM",
      party: "All India Trinamool Congress",
      evm_votes: 801542,
      postal_votes: 2220,
      total_votes: 803762,
      percentage_of_votes: 52.76,
    },
    {
      serial_no: 2,
      candidate: "REKHA PATRA",
      party: "Bharatiya Janata Party",
      evm_votes: 468593,
      postal_votes: 1622,
      total_votes: 470215,
      percentage_of_votes: 30.87,
    },
    {
      serial_no: 3,
      candidate: "AKHTAR RAHAMAN BISWAS",
      party: "All India Secular Front",
      evm_votes: 123432,
      postal_votes: 68,
      total_votes: 123500,
      percentage_of_votes: 8.11,
    },
    {
      serial_no: 4,
      candidate: "NIRAPADA SARDAR",
      party: "Communist Party of India (Marxist)",
      evm_votes: 77611,
      postal_votes: 288,
      total_votes: 77899,
      percentage_of_votes: 5.11,
    },
    {
      serial_no: 5,
      candidate: "MAFIJUL MOLLA",
      party: "Independent",
      evm_votes: 11833,
      postal_votes: 0,
      total_votes: 11833,
      percentage_of_votes: 0.78,
    },
    {
      serial_no: 6,
      candidate: "PRIYAMA DAS",
      party: "Independent",
      evm_votes: 6606,
      postal_votes: 14,
      total_votes: 6620,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 7,
      candidate: "MIRAZ MOLLA",
      party: "Independent",
      evm_votes: 5902,
      postal_votes: 0,
      total_votes: 5902,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 8,
      candidate: "MD ABUL KALAM MOLLA",
      party: "Bahujan Samaj Party",
      evm_votes: 4451,
      postal_votes: 13,
      total_votes: 4464,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 9,
      candidate: "BIDYUT GAYEN",
      party: "Independent",
      evm_votes: 3625,
      postal_votes: 9,
      total_votes: 3634,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 10,
      candidate: "IMRAN ALI SARDAR",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 2548,
      postal_votes: 5,
      total_votes: 2553,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 11,
      candidate: "PIYARUL ISLAM",
      party: "Independent",
      evm_votes: 2388,
      postal_votes: 3,
      total_votes: 2391,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 12,
      candidate: "SARAVAN SINGH",
      party: "Independent",
      evm_votes: 2020,
      postal_votes: 1,
      total_votes: 2021,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 13,
      candidate: "DAUD ALI GAZI",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1163,
      postal_votes: 4,
      total_votes: 1167,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "KINKAR KANTI ROY",
      party: "Independent",
      evm_votes: 1115,
      postal_votes: 3,
      total_votes: 1118,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 15,
      candidate: "BIPUL CHANDRA BISWAS",
      party: "Mulnibasi Party of India",
      evm_votes: 1070,
      postal_votes: 4,
      total_votes: 1074,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5229,
      postal_votes: 19,
      total_votes: 5248,
      percentage_of_votes: 0.34,
    },
  ],
  total: {
    evm_votes: 1519128,
    postal_votes: 4273,
    total_votes: 1523401,
  },
};
export const s2519 = {
  cs_name: "Joynagar",
  cs_shortname: "joynagar",
  cs_code: "s2519",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "PRATIMA MONDAL",
      party: "All India Trinamool Congress",
      evm_votes: 891053,
      postal_votes: 3259,
      total_votes: 894312,
      percentage_of_votes: 60.32,
    },
    {
      serial_no: 2,
      candidate: "ASHOK KANDARY",
      party: "Bharatiya Janata Party",
      evm_votes: 422546,
      postal_votes: 1547,
      total_votes: 424093,
      percentage_of_votes: 28.6,
    },
    {
      serial_no: 3,
      candidate: "MEGHNATH HALDER",
      party: "All India Secular Front",
      evm_votes: 65316,
      postal_votes: 56,
      total_votes: 65372,
      percentage_of_votes: 4.41,
    },
    {
      serial_no: 4,
      candidate: "SAMARENDRA NATH MONDAL",
      party: "Revolutionary Socialist Party",
      evm_votes: 39927,
      postal_votes: 186,
      total_votes: 40113,
      percentage_of_votes: 2.71,
    },
    {
      serial_no: 5,
      candidate: "NIRANJAN NASKAR",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 14547,
      postal_votes: 75,
      total_votes: 14622,
      percentage_of_votes: 0.99,
    },
    {
      serial_no: 6,
      candidate: "SUJAN SARDAR",
      party: "Independent",
      evm_votes: 10779,
      postal_votes: 15,
      total_votes: 10794,
      percentage_of_votes: 0.73,
    },
    {
      serial_no: 7,
      candidate: "SINDHUPADA BAIRAGI",
      party: "Independent",
      evm_votes: 9150,
      postal_votes: 12,
      total_votes: 9162,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 8,
      candidate: "SHANKAR DEB MONDAL",
      party: "Bahujan Samaj Party",
      evm_votes: 6363,
      postal_votes: 28,
      total_votes: 6391,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 9,
      candidate: "JURAN CHANDRA PANDEY",
      party: "Mulnibasi Party of India",
      evm_votes: 3750,
      postal_votes: 19,
      total_votes: 3769,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 10,
      candidate: "MILAN NASKAR",
      party: "Independent",
      evm_votes: 2129,
      postal_votes: 7,
      total_votes: 2136,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "PRATIMA MONDAL",
      party: "Independent",
      evm_votes: 2052,
      postal_votes: 27,
      total_votes: 2079,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9756,
      postal_votes: 32,
      total_votes: 9788,
      percentage_of_votes: 0.66,
    },
  ],
  total: {
    evm_votes: 1477368,
    postal_votes: 5263,
    total_votes: 1482631,
  },
};
export const s2520 = {
  cs_name: "Mathurapur",
  cs_shortname: "mathurapur",
  cs_code: "s2520",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "BAPI HALDAR",
      party: "All India Trinamool Congress",
      evm_votes: 752486,
      postal_votes: 3245,
      total_votes: 755731,
      percentage_of_votes: 50.52,
    },
    {
      serial_no: 2,
      candidate: "ASHOK PURKAIT",
      party: "Bharatiya Janata Party",
      evm_votes: 552798,
      postal_votes: 1876,
      total_votes: 554674,
      percentage_of_votes: 37.08,
    },
    {
      serial_no: 3,
      candidate: "AJAY KUMAR DAS",
      party: "All India Secular Front",
      evm_votes: 87530,
      postal_votes: 76,
      total_votes: 87606,
      percentage_of_votes: 5.86,
    },
    {
      serial_no: 4,
      candidate: "DR SARAT CHANDRA HALDER",
      party: "Communist Party of India (Marxist)",
      evm_votes: 60778,
      postal_votes: 322,
      total_votes: 61100,
      percentage_of_votes: 4.08,
    },
    {
      serial_no: 5,
      candidate: "BAPI HALDER",
      party: "Independent",
      evm_votes: 8922,
      postal_votes: 12,
      total_votes: 8934,
      percentage_of_votes: 0.6,
    },
    {
      serial_no: 6,
      candidate: "BAPI HALDAR",
      party: "Independent",
      evm_votes: 5607,
      postal_votes: 19,
      total_votes: 5626,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 7,
      candidate: "BISWANATH SARDAR",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 4505,
      postal_votes: 29,
      total_votes: 4534,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 8,
      candidate: "PANKAJ KUMAR HALDER",
      party: "Bahujan Samaj Party",
      evm_votes: 4238,
      postal_votes: 19,
      total_votes: 4257,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 9,
      candidate: "ASHOKE PUROKAIT",
      party: "Independent",
      evm_votes: 1489,
      postal_votes: 12,
      total_votes: 1501,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 10,
      candidate: "ASHOKE SARDAR",
      party: "Independent",
      evm_votes: 1474,
      postal_votes: 27,
      total_votes: 1501,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "BAPPA DAS",
      party: "Party for Democratic Socialism",
      evm_votes: 1203,
      postal_votes: 6,
      total_votes: 1209,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "RUDRA PRASAD MANDAL",
      party: "Mulnibasi Party of India",
      evm_votes: 707,
      postal_votes: 13,
      total_votes: 720,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8593,
      postal_votes: 38,
      total_votes: 8631,
      percentage_of_votes: 0.58,
    },
  ],
  total: {
    evm_votes: 1490330,
    postal_votes: 5694,
    total_votes: 1496024,
  },
};
export const s2521 = {
  cs_name: "Diamond_harbour",
  cs_shortname: "diamondharbour",
  cs_code: "s2521",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "ABHISHEK BANERJEE",
      party: "All India Trinamool Congress",
      evm_votes: 1043493,
      postal_votes: 4737,
      total_votes: 1048230,
      percentage_of_votes: 68.48,
    },
    {
      serial_no: 2,
      candidate: "ABHIJIT DAS (BOBBY)",
      party: "Bharatiya Janata Party",
      evm_votes: 336068,
      postal_votes: 1232,
      total_votes: 337300,
      percentage_of_votes: 22.03,
    },
    {
      serial_no: 3,
      candidate: "PRATIK UR RAHAMAN",
      party: "Communist Party of India (Marxist)",
      evm_votes: 86507,
      postal_votes: 446,
      total_votes: 86953,
      percentage_of_votes: 5.68,
    },
    {
      serial_no: 4,
      candidate: "MAJNU LASKAR",
      party: "All India Secular Front",
      evm_votes: 21109,
      postal_votes: 30,
      total_votes: 21139,
      percentage_of_votes: 1.38,
    },
    {
      serial_no: 5,
      candidate: "SUBRATA BOSE",
      party: "Independent",
      evm_votes: 9819,
      postal_votes: 6,
      total_votes: 9825,
      percentage_of_votes: 0.64,
    },
    {
      serial_no: 6,
      candidate: "DASARATHI PAIK",
      party: "Bahujan Samaj Party",
      evm_votes: 7082,
      postal_votes: 29,
      total_votes: 7111,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 7,
      candidate: "MALAY GUHA ROY",
      party: "Independent",
      evm_votes: 2963,
      postal_votes: 8,
      total_votes: 2971,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 8,
      candidate: "PULAKESH MONDAL",
      party: "Independent",
      evm_votes: 1945,
      postal_votes: 3,
      total_votes: 1948,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 9,
      candidate: "KALIMUDDIN SK",
      party: "Indian National Socialistic Action Forces",
      evm_votes: 1579,
      postal_votes: 11,
      total_votes: 1590,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 10,
      candidate: "RAMKUMAR MONDAL",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1411,
      postal_votes: 7,
      total_votes: 1418,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 11,
      candidate: "ANISUR RAHAMAN SK",
      party: "Independent",
      evm_votes: 1152,
      postal_votes: 7,
      total_votes: 1159,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "KASHINATH MONDAL",
      party: "Independent",
      evm_votes: 961,
      postal_votes: 24,
      total_votes: 985,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10134,
      postal_votes: 54,
      total_votes: 10188,
      percentage_of_votes: 0.67,
    },
  ],
  total: {
    evm_votes: 1524223,
    postal_votes: 6594,
    total_votes: 1530817,
  },
};
export const s2522 = {
  cs_name: "Jadavpur",
  cs_shortname: "jadavpur",
  cs_code: "s2522",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "SAYANI GHOSH",
      party: "All India Trinamool Congress",
      evm_votes: 714049,
      postal_votes: 3850,
      total_votes: 717899,
      percentage_of_votes: 45.83,
    },
    {
      serial_no: 2,
      candidate: "DR. ANIRBAN GANGULY",
      party: "Bharatiya Janata Party",
      evm_votes: 457307,
      postal_votes: 2391,
      total_votes: 459698,
      percentage_of_votes: 29.35,
    },
    {
      serial_no: 3,
      candidate: "SRIJAN BHATTACHARYYA",
      party: "Communist Party of India (Marxist)",
      evm_votes: 256951,
      postal_votes: 1761,
      total_votes: 258712,
      percentage_of_votes: 16.52,
    },
    {
      serial_no: 4,
      candidate: "NUR ALAM KHAN",
      party: "All India Secular Front",
      evm_votes: 83291,
      postal_votes: 71,
      total_votes: 83362,
      percentage_of_votes: 5.32,
    },
    {
      serial_no: 5,
      candidate: "RANJIT KUMAR MANDAL",
      party: "Independent",
      evm_votes: 13021,
      postal_votes: 9,
      total_votes: 13030,
      percentage_of_votes: 0.83,
    },
    {
      serial_no: 6,
      candidate: "HOSEN GAZI",
      party: "Independent",
      evm_votes: 4925,
      postal_votes: 2,
      total_votes: 4927,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 7,
      candidate: "SANDIP NATH",
      party: "Bahujan Samaj Party",
      evm_votes: 4434,
      postal_votes: 28,
      total_votes: 4462,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 8,
      candidate: "KALPANA NASKAR DATTA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 4416,
      postal_votes: 35,
      total_votes: 4451,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 9,
      candidate: "BALARAM MANDAL",
      party: "Independent",
      evm_votes: 2829,
      postal_votes: 6,
      total_votes: 2835,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 10,
      candidate: "TANUSHREE MONDAL",
      party: "Independent",
      evm_votes: 2419,
      postal_votes: 8,
      total_votes: 2427,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 11,
      candidate: "SHANKAR MANDAL",
      party: "Independent",
      evm_votes: 1439,
      postal_votes: 3,
      total_votes: 1442,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 12,
      candidate: "SRI GOPAN SARDAR",
      party: "Mulnibasi Party of India",
      evm_votes: 1366,
      postal_votes: 7,
      total_votes: 1373,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "PURNIMA DEBNATH",
      party: "Independent",
      evm_votes: 1301,
      postal_votes: 24,
      total_votes: 1325,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "CHANDRACHUR GOSWAMI",
      party: "Independent",
      evm_votes: 1112,
      postal_votes: 2,
      total_votes: 1114,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 15,
      candidate: "ARUN SARKAR",
      party: "Independent",
      evm_votes: 1107,
      postal_votes: 3,
      total_votes: 1110,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 16,
      candidate: "DIPAK SARDAR",
      party: "Independent",
      evm_votes: 924,
      postal_votes: 3,
      total_votes: 927,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 17,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7316,
      postal_votes: 99,
      total_votes: 7415,
      percentage_of_votes: 0.47,
    },
  ],
  total: {
    evm_votes: 1558207,
    postal_votes: 8302,
    total_votes: 1566509,
  },
};
export const s2523 = {
  cs_name: "Kolkata_Dakshin",
  cs_shortname: "kolkatadakshin",
  cs_code: "s2523",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "MALA ROY",
      party: "All India Trinamool Congress",
      evm_votes: 612725,
      postal_votes: 2549,
      total_votes: 615274,
      percentage_of_votes: 49.48,
    },
    {
      serial_no: 2,
      candidate: "DEBASREE CHAUDHURI",
      party: "Bharatiya Janata Party",
      evm_votes: 426459,
      postal_votes: 1584,
      total_votes: 428043,
      percentage_of_votes: 34.42,
    },
    {
      serial_no: 3,
      candidate: "SAIRA SHAH HALIM",
      party: "Communist Party of India (Marxist)",
      evm_votes: 167771,
      postal_votes: 760,
      total_votes: 168531,
      percentage_of_votes: 13.55,
    },
    {
      serial_no: 4,
      candidate: "NIRAJ AGARWAL",
      party: "Independent",
      evm_votes: 4028,
      postal_votes: 4,
      total_votes: 4032,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 5,
      candidate: "FIROZA KHATUN",
      party: "Bahujan Samaj Party",
      evm_votes: 3958,
      postal_votes: 17,
      total_votes: 3975,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 6,
      candidate: "SANTANU ROY",
      party: "Independent",
      evm_votes: 2918,
      postal_votes: 2,
      total_votes: 2920,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 7,
      candidate: "ZUBAIR RABBANI",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2290,
      postal_votes: 10,
      total_votes: 2300,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "SUSANTA MONDAL",
      party: "Independent",
      evm_votes: 2090,
      postal_votes: 1,
      total_votes: 2091,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 9,
      candidate: "MUMTAZ ALI",
      party: "Independent",
      evm_votes: 1960,
      postal_votes: 9,
      total_votes: 1969,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 10,
      candidate: "MUKTI",
      party: "Right to Recall Party",
      evm_votes: 1537,
      postal_votes: 7,
      total_votes: 1544,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 11,
      candidate: "SANJOY (RANA) CHANDA",
      party: "Kisan Mazdoor Sangharsh Party",
      evm_votes: 1186,
      postal_votes: 7,
      total_votes: 1193,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 12,
      candidate: "SANJANA RAM",
      party: "Independent",
      evm_votes: 1052,
      postal_votes: 4,
      total_votes: 1056,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "YASMIN ISLAM",
      party: "Social Democratic Party Of India",
      evm_votes: 981,
      postal_votes: 4,
      total_votes: 985,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "ATANU DAS",
      party: "Independent",
      evm_votes: 969,
      postal_votes: 2,
      total_votes: 971,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "MRIDUL OJHA",
      party: "Independent",
      evm_votes: 856,
      postal_votes: 6,
      total_votes: 862,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 16,
      candidate: "DIPENDU HAZRA",
      party: "Independent",
      evm_votes: 794,
      postal_votes: 10,
      total_votes: 804,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 17,
      candidate: "MD. SHAKIL WARSI",
      party: "Independent",
      evm_votes: 503,
      postal_votes: 1,
      total_votes: 504,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 18,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6348,
      postal_votes: 75,
      total_votes: 6423,
      percentage_of_votes: 0.52,
    },
  ],
  total: {
    evm_votes: 1238425,
    postal_votes: 5052,
    total_votes: 1243477,
  },
};
export const s2524 = {
  cs_name: "Kolkata_Uttar",
  cs_shortname: "kolkatauttar",
  cs_code: "s2524",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "BANDYOPADHYAY SUDIP",
      party: "All India Trinamool Congress",
      evm_votes: 453177,
      postal_votes: 1519,
      total_votes: 454696,
      percentage_of_votes: 47.44,
    },
    {
      serial_no: 2,
      candidate: "TAPAS ROY",
      party: "Bharatiya Janata Party",
      evm_votes: 361268,
      postal_votes: 868,
      total_votes: 362136,
      percentage_of_votes: 37.78,
    },
    {
      serial_no: 3,
      candidate: "PRADIP BHATTACHARYA",
      party: "Indian National Congress",
      evm_votes: 114556,
      postal_votes: 426,
      total_votes: 114982,
      percentage_of_votes: 12,
    },
    {
      serial_no: 4,
      candidate: "DR. BIPLAB CHANDRA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 3640,
      postal_votes: 18,
      total_votes: 3658,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 5,
      candidate: "OMPRAKASH PRAJAPATI",
      party: "Bahujan Samaj Party",
      evm_votes: 3133,
      postal_votes: 47,
      total_votes: 3180,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 6,
      candidate: "PRANATI PAUL",
      party: "Independent",
      evm_votes: 2267,
      postal_votes: 5,
      total_votes: 2272,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 7,
      candidate: "SURENDRA KUMAR KABRA",
      party: "Independent",
      evm_votes: 1317,
      postal_votes: 5,
      total_votes: 1322,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 8,
      candidate: "SWAPAN DAS",
      party: "Independent",
      evm_votes: 1163,
      postal_votes: 3,
      total_votes: 1166,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 9,
      candidate: "MISBAHUS SALAM",
      party: "Independent",
      evm_votes: 975,
      postal_votes: 6,
      total_votes: 981,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 10,
      candidate: "SAMPA GUHA",
      party: "Bharatiya Nyay-Adhikar Raksha Party",
      evm_votes: 912,
      postal_votes: 4,
      total_votes: 916,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "RAJKUMAR PALIT",
      party: "Mission Naya Digant Party",
      evm_votes: 768,
      postal_votes: 4,
      total_votes: 772,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "SMT. RINKU GUPTA",
      party: "Pyramid Party of India",
      evm_votes: 649,
      postal_votes: 10,
      total_votes: 659,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "AMAL KUMAR DEY",
      party: "Independent",
      evm_votes: 582,
      postal_votes: 9,
      total_votes: 591,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 14,
      candidate: "ANUJIT KUMAR NAN",
      party: "Independent",
      evm_votes: 555,
      postal_votes: 2,
      total_votes: 557,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "BINOY KRISHNA CHATTERJEE",
      party: "Independent",
      evm_votes: 500,
      postal_votes: 1,
      total_votes: 501,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10004,
      postal_votes: 40,
      total_votes: 10044,
      percentage_of_votes: 1.05,
    },
  ],
  total: {
    evm_votes: 955466,
    postal_votes: 2967,
    total_votes: 958433,
  },
};
export const s2525 = {
  cs_name: "Howrah",
  cs_shortname: "howrah",
  cs_code: "s2525",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "PRASUN BANERJEE",
      party: "All India Trinamool Congress",
      evm_votes: 625229,
      postal_votes: 1264,
      total_votes: 626493,
      percentage_of_votes: 49.26,
    },
    {
      serial_no: 2,
      candidate: "DR RATHIN CHAKRAVARTY",
      party: "Bharatiya Janata Party",
      evm_votes: 455996,
      postal_votes: 1055,
      total_votes: 457051,
      percentage_of_votes: 35.94,
    },
    {
      serial_no: 3,
      candidate: "SABYASACHI CHATTERJEE",
      party: "Communist Party of India (Marxist)",
      evm_votes: 151483,
      postal_votes: 522,
      total_votes: 152005,
      percentage_of_votes: 11.95,
    },
    {
      serial_no: 4,
      candidate: "PALASH BACHAR",
      party: "Bahujan Samaj Party",
      evm_votes: 6342,
      postal_votes: 12,
      total_votes: 6354,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 5,
      candidate: "SEIKH SAKIRUL ISLAM",
      party: "Independent",
      evm_votes: 5271,
      postal_votes: 0,
      total_votes: 5271,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 6,
      candidate: "SHIWA MALLICK",
      party: "Independent",
      evm_votes: 3218,
      postal_votes: 2,
      total_votes: 3220,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 7,
      candidate: "UTTAM CHATTERJEE",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2319,
      postal_votes: 6,
      total_votes: 2325,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "SHAMPA DAS",
      party: "Bharatiya Nyay-Adhikar Raksha Party",
      evm_votes: 2053,
      postal_votes: 12,
      total_votes: 2065,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "RATHIN GANGULY",
      party: "Independent",
      evm_votes: 1884,
      postal_votes: 3,
      total_votes: 1887,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "PRASUN LAHIRI",
      party: "Independent",
      evm_votes: 1323,
      postal_votes: 3,
      total_votes: 1326,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "RAJESH KUMAR PRASAD",
      party: "All India Arya Mahasabha",
      evm_votes: 1268,
      postal_votes: 4,
      total_votes: 1272,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 12,
      candidate: "ADARSHA MONDAL",
      party: "Independent",
      evm_votes: 939,
      postal_votes: 6,
      total_votes: 945,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "AJAZ AHMED ANSARI",
      party: "Independent",
      evm_votes: 856,
      postal_votes: 1,
      total_votes: 857,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 14,
      candidate: "TAPAS BARUI",
      party: "Independent",
      evm_votes: 637,
      postal_votes: 4,
      total_votes: 641,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10036,
      postal_votes: 48,
      total_votes: 10084,
      percentage_of_votes: 0.79,
    },
  ],
  total: {
    evm_votes: 1268854,
    postal_votes: 2942,
    total_votes: 1271796,
  },
};
export const s2526 = {
  cs_name: "Uluberia",
  cs_shortname: "uluberia",
  cs_code: "s2526",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "SAJDA AHMED",
      party: "All India Trinamool Congress",
      evm_votes: 722586,
      postal_votes: 2036,
      total_votes: 724622,
      percentage_of_votes: 52.1,
    },
    {
      serial_no: 2,
      candidate: "ARUNUDAY PAULCHOWDHURY",
      party: "Bharatiya Janata Party",
      evm_votes: 504611,
      postal_votes: 1338,
      total_votes: 505949,
      percentage_of_votes: 36.38,
    },
    {
      serial_no: 3,
      candidate: "AZAHAR MOLLICK",
      party: "Indian National Congress",
      evm_votes: 78304,
      postal_votes: 285,
      total_votes: 78589,
      percentage_of_votes: 5.65,
    },
    {
      serial_no: 4,
      candidate: "MOFIKUL ISLAM",
      party: "All India Secular Front",
      evm_votes: 38971,
      postal_votes: 20,
      total_votes: 38991,
      percentage_of_votes: 2.8,
    },
    {
      serial_no: 5,
      candidate: "ARIT KARAK",
      party: "Independent",
      evm_votes: 7287,
      postal_votes: 5,
      total_votes: 7292,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 6,
      candidate: "AMAL KUMAR DEYATI",
      party: "Bharatiya Nyay-Adhikar Raksha Party",
      evm_votes: 5445,
      postal_votes: 6,
      total_votes: 5451,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 7,
      candidate: "RAMESH KHANRA",
      party: "Independent",
      evm_votes: 5094,
      postal_votes: 10,
      total_votes: 5104,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 8,
      candidate: "BIMALESH KUMAR HELA",
      party: "Bahujan Samaj Party",
      evm_votes: 4867,
      postal_votes: 8,
      total_votes: 4875,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 9,
      candidate: "SK. SAPIYAR ALI",
      party: "Independent",
      evm_votes: 2924,
      postal_votes: 0,
      total_votes: 2924,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 10,
      candidate: "NIKHIL BERA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2095,
      postal_votes: 12,
      total_votes: 2107,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 11,
      candidate: "AMAL KUMAR BARMAN",
      party: "Independent",
      evm_votes: 1997,
      postal_votes: 3,
      total_votes: 2000,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 12,
      candidate: "RAMPRASAD GHORAI",
      party: "Indian Unity Centre",
      evm_votes: 1568,
      postal_votes: 4,
      total_votes: 1572,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11263,
      postal_votes: 55,
      total_votes: 11318,
      percentage_of_votes: 0.81,
    },
  ],
  total: {
    evm_votes: 1387012,
    postal_votes: 3782,
    total_votes: 1390794,
  },
};
export const s2527 = {
  cs_name: "Srerampur",
  cs_shortname: "srerampur",
  cs_code: "s2527",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "KALYAN BANERJEE",
      party: "All India Trinamool Congress",
      evm_votes: 671865,
      postal_votes: 2105,
      total_votes: 673970,
      percentage_of_votes: 45.65,
    },
    {
      serial_no: 2,
      candidate: "KABIR SHANKAR BOSE",
      party: "Bharatiya Janata Party",
      evm_votes: 497720,
      postal_votes: 1420,
      total_votes: 499140,
      percentage_of_votes: 33.8,
    },
    {
      serial_no: 3,
      candidate: "DIPSITA DHAR",
      party: "Communist Party of India (Marxist)",
      evm_votes: 238131,
      postal_votes: 1015,
      total_votes: 239146,
      percentage_of_votes: 16.2,
    },
    {
      serial_no: 4,
      candidate: "SAHARIAR MULLICK (BAPI)",
      party: "All India Secular Front",
      evm_votes: 25659,
      postal_votes: 18,
      total_votes: 25677,
      percentage_of_votes: 1.74,
    },
    {
      serial_no: 5,
      candidate: "SUNDAR PATRA",
      party: "Independent",
      evm_votes: 9897,
      postal_votes: 5,
      total_votes: 9902,
      percentage_of_votes: 0.67,
    },
    {
      serial_no: 6,
      candidate: "SASANKA CHAKRABORTY",
      party: "Independent",
      evm_votes: 3933,
      postal_votes: 5,
      total_votes: 3938,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 7,
      candidate: "SUPRIYA BISWAS",
      party: "Bahujan Samaj Party",
      evm_votes: 3572,
      postal_votes: 17,
      total_votes: 3589,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 8,
      candidate: "KALYAN PAUL",
      party: "Independent",
      evm_votes: 1749,
      postal_votes: 9,
      total_votes: 1758,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 9,
      candidate: "PRADYUT CHOWDHURI",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1671,
      postal_votes: 9,
      total_votes: 1680,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "KALYAN SAMANTA",
      party: "Independent",
      evm_votes: 1421,
      postal_votes: 19,
      total_votes: 1440,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "SARKAR MANGAL",
      party: "Bahujan Maha Party",
      evm_votes: 1256,
      postal_votes: 2,
      total_votes: 1258,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 14964,
      postal_votes: 70,
      total_votes: 15034,
      percentage_of_votes: 1.02,
    },
  ],
  total: {
    evm_votes: 1471838,
    postal_votes: 4694,
    total_votes: 1476532,
  },
};
export const s2528 = {
  cs_name: "Hooghly",
  cs_shortname: "hooghly",
  cs_code: "s2528",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "RACHNA BANERJEE",
      party: "All India Trinamool Congress",
      evm_votes: 700120,
      postal_votes: 2624,
      total_votes: 702744,
      percentage_of_votes: 46.31,
    },
    {
      serial_no: 2,
      candidate: "LOCKET CHATTERJEE",
      party: "Bharatiya Janata Party",
      evm_votes: 623867,
      postal_votes: 2024,
      total_votes: 625891,
      percentage_of_votes: 41.24,
    },
    {
      serial_no: 3,
      candidate: "MANADIP GHOSH",
      party: "Communist Party of India (Marxist)",
      evm_votes: 139250,
      postal_votes: 669,
      total_votes: 139919,
      percentage_of_votes: 9.22,
    },
    {
      serial_no: 4,
      candidate: "SURAJIT HEMBRAM",
      party: "Independent",
      evm_votes: 9257,
      postal_votes: 11,
      total_votes: 9268,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 5,
      candidate: "SAMIM ALI MOLLICK",
      party: "Independent",
      evm_votes: 6555,
      postal_votes: 12,
      total_votes: 6567,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 6,
      candidate: "JAMINI BHAR",
      party: "Bahujan Samaj Party",
      evm_votes: 4684,
      postal_votes: 34,
      total_votes: 4718,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 7,
      candidate: "AJANTA SARKAR",
      party: "Bharatiya National Janata Dal",
      evm_votes: 3619,
      postal_votes: 20,
      total_votes: 3639,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 8,
      candidate: "SK KAMALUDDIN",
      party: "Independent",
      evm_votes: 2755,
      postal_votes: 11,
      total_votes: 2766,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 9,
      candidate: "SANCHITA BERA",
      party: "Independent",
      evm_votes: 2632,
      postal_votes: 17,
      total_votes: 2649,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 10,
      candidate: "PABAN MAZUMDER",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2367,
      postal_votes: 13,
      total_votes: 2380,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 11,
      candidate: "PRADIP PAL",
      party: "Bharatiya Nyay-Adhikar Raksha Party",
      evm_votes: 2205,
      postal_votes: 15,
      total_votes: 2220,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 12,
      candidate: "MRINAL KANTI DAS",
      party: "Independent",
      evm_votes: 1387,
      postal_votes: 11,
      total_votes: 1398,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13250,
      postal_votes: 102,
      total_votes: 13352,
      percentage_of_votes: 0.88,
    },
  ],
  total: {
    evm_votes: 1511948,
    postal_votes: 5563,
    total_votes: 1517511,
  },
};
export const s2529 = {
  cs_name: "Arambagh",
  cs_shortname: "arambagh",
  cs_code: "s2529",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "BAG MITALI",
      party: "All India Trinamool Congress",
      evm_votes: 710432,
      postal_votes: 2155,
      total_votes: 712587,
      percentage_of_votes: 45.71,
    },
    {
      serial_no: 2,
      candidate: "ARUP KANTI DIGAR",
      party: "Bharatiya Janata Party",
      evm_votes: 704124,
      postal_votes: 2064,
      total_votes: 706188,
      percentage_of_votes: 45.3,
    },
    {
      serial_no: 3,
      candidate: "BIPLAB KUMAR MOITRA",
      party: "Communist Party of India (Marxist)",
      evm_votes: 92073,
      postal_votes: 429,
      total_votes: 92502,
      percentage_of_votes: 5.93,
    },
    {
      serial_no: 4,
      candidate: "RAGHU MALLIK",
      party: "Independent",
      evm_votes: 16123,
      postal_votes: 7,
      total_votes: 16130,
      percentage_of_votes: 1.03,
    },
    {
      serial_no: 5,
      candidate: "SUBIR KUMAR ROY",
      party: "Bahujan Samaj Party",
      evm_votes: 3807,
      postal_votes: 24,
      total_votes: 3831,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 6,
      candidate: "VASKAR MALIK",
      party: "Independent",
      evm_votes: 3662,
      postal_votes: 3,
      total_votes: 3665,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 7,
      candidate: "SUKANTA POREL",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1807,
      postal_votes: 9,
      total_votes: 1816,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 8,
      candidate: "BABLU GHANTI",
      party: "Independent",
      evm_votes: 1610,
      postal_votes: 19,
      total_votes: 1629,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 9,
      candidate: "ABHIJIT MAJI",
      party: "Independent",
      evm_votes: 1600,
      postal_votes: 6,
      total_votes: 1606,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 10,
      candidate: "ALOK DOLAI",
      party: "Independent",
      evm_votes: 1081,
      postal_votes: 13,
      total_votes: 1094,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 17981,
      postal_votes: 50,
      total_votes: 18031,
      percentage_of_votes: 1.16,
    },
  ],
  total: {
    evm_votes: 1554300,
    postal_votes: 4779,
    total_votes: 1559079,
  },
};
export const s2532 = {
  cs_name: "Ghatal",
  cs_shortname: "ghatal",
  cs_code: "s2532",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "ADHIKARI DEEPAK (DEV)",
      party: "All India Trinamool Congress",
      evm_votes: 834558,
      postal_votes: 3432,
      total_votes: 837990,
      percentage_of_votes: 52.36,
    },
    {
      serial_no: 2,
      candidate: "DR. HIRANMOY CHATTOPADHYAYA",
      party: "Bharatiya Janata Party",
      evm_votes: 652098,
      postal_votes: 3024,
      total_votes: 655122,
      percentage_of_votes: 40.93,
    },
    {
      serial_no: 3,
      candidate: "TAPAN GANGULI",
      party: "Communist Party of India",
      evm_votes: 74399,
      postal_votes: 509,
      total_votes: 74908,
      percentage_of_votes: 4.68,
    },
    {
      serial_no: 4,
      candidate: "SOUMEN MADRAJI",
      party: "Bahujan Samaj Party",
      evm_votes: 7916,
      postal_votes: 25,
      total_votes: 7941,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 5,
      candidate: "GOPAL MONDAL",
      party: "Independent",
      evm_votes: 4238,
      postal_votes: 6,
      total_votes: 4244,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 6,
      candidate: "DINESH MAIKAP",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 4124,
      postal_votes: 24,
      total_votes: 4148,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 7,
      candidate: "SAHEB CHOWDHURY",
      party: "Independent",
      evm_votes: 3228,
      postal_votes: 9,
      total_votes: 3237,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12889,
      postal_votes: 77,
      total_votes: 12966,
      percentage_of_votes: 0.81,
    },
  ],
  total: {
    evm_votes: 1593450,
    postal_votes: 7106,
    total_votes: 1600556,
  },
};
export const s2533 = {
  cs_name: "Jhargram",
  cs_shortname: "jhargram",
  cs_code: "s2533",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "KALIPADA SAREN (KHERWAL)",
      party: "All India Trinamool Congress",
      evm_votes: 740132,
      postal_votes: 3346,
      total_votes: 743478,
      percentage_of_votes: 49.87,
    },
    {
      serial_no: 2,
      candidate: "DR. PRANAT TUDU",
      party: "Bharatiya Janata Party",
      evm_votes: 566553,
      postal_votes: 2877,
      total_votes: 569430,
      percentage_of_votes: 38.2,
    },
    {
      serial_no: 3,
      candidate: "SONAMANI TUDU",
      party: "Communist Party of India (Marxist)",
      evm_votes: 76850,
      postal_votes: 452,
      total_votes: 77302,
      percentage_of_votes: 5.19,
    },
    {
      serial_no: 4,
      candidate: "SURYA SINGH BESRA",
      party: "Jharkhand People's Party",
      evm_votes: 19408,
      postal_votes: 86,
      total_votes: 19494,
      percentage_of_votes: 1.31,
    },
    {
      serial_no: 5,
      candidate: "BARUN MAHATO",
      party: "Independent",
      evm_votes: 12029,
      postal_votes: 58,
      total_votes: 12087,
      percentage_of_votes: 0.81,
    },
    {
      serial_no: 6,
      candidate: "ARUNKUMAR HANSDA",
      party: "Bahujan Samaj Party",
      evm_votes: 11949,
      postal_votes: 40,
      total_votes: 11989,
      percentage_of_votes: 0.8,
    },
    {
      serial_no: 7,
      candidate: "SURENDRANATH SING",
      party: "Independent",
      evm_votes: 10781,
      postal_votes: 6,
      total_votes: 10787,
      percentage_of_votes: 0.72,
    },
    {
      serial_no: 8,
      candidate: "RAMANATH ORANG",
      party: "Independent",
      evm_votes: 7178,
      postal_votes: 10,
      total_votes: 7188,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 9,
      candidate: "BIPLAB HEMBRAM",
      party: "Independent",
      evm_votes: 7156,
      postal_votes: 14,
      total_votes: 7170,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 10,
      candidate: "BAPI SOREN",
      party: "All India Secular Front",
      evm_votes: 5854,
      postal_votes: 41,
      total_votes: 5895,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 11,
      candidate: "BIRSABIR BASKEY",
      party: "Ambedkarite Party of India",
      evm_votes: 4344,
      postal_votes: 18,
      total_votes: 4362,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 12,
      candidate: "SUSHIL MANDI",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2879,
      postal_votes: 13,
      total_votes: 2892,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 13,
      candidate: "GUNADHAR SING",
      party: "Independent",
      evm_votes: 2818,
      postal_votes: 31,
      total_votes: 2849,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 15871,
      postal_votes: 49,
      total_votes: 15920,
      percentage_of_votes: 1.07,
    },
  ],
  total: {
    evm_votes: 1483802,
    postal_votes: 7041,
    total_votes: 1490843,
  },
};
export const s2534 = {
  cs_name: "Medinipur",
  cs_shortname: "medinipur",
  cs_code: "s2534",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "JUNE MALIAH",
      party: "All India Trinamool Congress",
      evm_votes: 699562,
      postal_votes: 2630,
      total_votes: 702192,
      percentage_of_votes: 47.4,
    },
    {
      serial_no: 2,
      candidate: "AGNIMITRA PAUL",
      party: "Bharatiya Janata Party",
      evm_votes: 671328,
      postal_votes: 3673,
      total_votes: 675001,
      percentage_of_votes: 45.56,
    },
    {
      serial_no: 3,
      candidate: "BIPLAB BHATTA",
      party: "Communist Party of India",
      evm_votes: 57345,
      postal_votes: 440,
      total_votes: 57785,
      percentage_of_votes: 3.9,
    },
    {
      serial_no: 4,
      candidate: "ANINDITA JANA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 9744,
      postal_votes: 47,
      total_votes: 9791,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 5,
      candidate: "SANJIB DEY",
      party: "Independent",
      evm_votes: 8703,
      postal_votes: 8,
      total_votes: 8711,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 6,
      candidate: "ANJAN (BABALU) MANDAL",
      party: "Bahujan Samaj Party",
      evm_votes: 6483,
      postal_votes: 20,
      total_votes: 6503,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 7,
      candidate: "KAMALESH MAHATA",
      party: "Independent",
      evm_votes: 4229,
      postal_votes: 21,
      total_votes: 4250,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 8,
      candidate: "SUKESH CHANDRA PALMAL",
      party: "Independent",
      evm_votes: 2757,
      postal_votes: 5,
      total_votes: 2762,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 9,
      candidate: "BISWAJIT DAS",
      party: "Independent",
      evm_votes: 2141,
      postal_votes: 3,
      total_votes: 2144,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12362,
      postal_votes: 62,
      total_votes: 12424,
      percentage_of_votes: 0.84,
    },
  ],
  total: {
    evm_votes: 1474654,
    postal_votes: 6909,
    total_votes: 1481563,
  },
};
export const s2536 = {
  cs_name: "Bankura",
  cs_shortname: "bankura",
  cs_code: "s2536",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "ARUP CHAKRABORTY",
      party: "All India Trinamool Congress",
      evm_votes: 637465,
      postal_votes: 4348,
      total_votes: 641813,
      percentage_of_votes: 44.33,
    },
    {
      serial_no: 2,
      candidate: "DR. SUBHAS SARKAR",
      party: "Bharatiya Janata Party",
      evm_votes: 604836,
      postal_votes: 4199,
      total_votes: 609035,
      percentage_of_votes: 42.07,
    },
    {
      serial_no: 3,
      candidate: "NILANJAN DASGUPTA",
      party: "Communist Party of India (Marxist)",
      evm_votes: 104534,
      postal_votes: 825,
      total_votes: 105359,
      percentage_of_votes: 7.28,
    },
    {
      serial_no: 4,
      candidate: "SURAJIT SINGH KURMALI",
      party: "Independent",
      evm_votes: 18507,
      postal_votes: 62,
      total_votes: 18569,
      percentage_of_votes: 1.28,
    },
    {
      serial_no: 5,
      candidate: "GOPAL MURMU",
      party: "Ambedkarite Party of India",
      evm_votes: 9047,
      postal_votes: 54,
      total_votes: 9101,
      percentage_of_votes: 0.63,
    },
    {
      serial_no: 6,
      candidate: "DIPA BOURI",
      party: "Bahujan Samaj Party",
      evm_votes: 8709,
      postal_votes: 87,
      total_votes: 8796,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 7,
      candidate: "MARSHAL TUDU",
      party: "Independent",
      evm_votes: 6682,
      postal_votes: 35,
      total_votes: 6717,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 8,
      candidate: "TARA SHANKAR GOPE",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 5490,
      postal_votes: 39,
      total_votes: 5529,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 9,
      candidate: "SANDIP KUMAR DEY",
      party: "Independent",
      evm_votes: 4610,
      postal_votes: 12,
      total_votes: 4622,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 10,
      candidate: "SAMAR KUMAR BISWAS",
      party: "Kisan Mazdoor Sangharsh Party",
      evm_votes: 3512,
      postal_votes: 29,
      total_votes: 3541,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 11,
      candidate: "JIBAN CHAKRABORTY",
      party: "Independent",
      evm_votes: 3483,
      postal_votes: 23,
      total_votes: 3506,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 12,
      candidate: "KARTIK CHANDRA BAURI",
      party: "Independent",
      evm_votes: 2741,
      postal_votes: 55,
      total_votes: 2796,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 13,
      candidate: "GOUR CHANDRA HEMBRAM",
      party: "Independent",
      evm_votes: 2064,
      postal_votes: 17,
      total_votes: 2081,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 26126,
      postal_votes: 83,
      total_votes: 26209,
      percentage_of_votes: 1.81,
    },
  ],
  total: {
    evm_votes: 1437806,
    postal_votes: 9868,
    total_votes: 1447674,
  },
};
export const s2538 = {
  cs_name: "Bardhaman_Purba",
  cs_shortname: "bardhamanpurba",
  cs_code: "s2538",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "DR. SHARMILA SARKAR",
      party: "All India Trinamool Congress",
      evm_votes: 717881,
      postal_votes: 2421,
      total_votes: 720302,
      percentage_of_votes: 48.11,
    },
    {
      serial_no: 2,
      candidate: "ASIM KUMAR SARKAR",
      party: "Bharatiya Janata Party",
      evm_votes: 557943,
      postal_votes: 1787,
      total_votes: 559730,
      percentage_of_votes: 37.38,
    },
    {
      serial_no: 3,
      candidate: "NIRAB KHAN",
      party: "Communist Party of India (Marxist)",
      evm_votes: 176358,
      postal_votes: 541,
      total_votes: 176899,
      percentage_of_votes: 11.81,
    },
    {
      serial_no: 4,
      candidate: "NIRMAL MAJHI",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 10964,
      postal_votes: 12,
      total_votes: 10976,
      percentage_of_votes: 0.73,
    },
    {
      serial_no: 5,
      candidate: "MUKUL BISWAS",
      party: "Bahujan Samaj Party",
      evm_votes: 7610,
      postal_votes: 21,
      total_votes: 7631,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 6,
      candidate: "SHARMILA SARKAR",
      party: "Independent",
      evm_votes: 4875,
      postal_votes: 18,
      total_votes: 4893,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 7,
      candidate: "SAJAL KUMAR DE",
      party: "Communist Party of India (Marxist-Leninist) (Liberation)",
      evm_votes: 4163,
      postal_votes: 19,
      total_votes: 4182,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12654,
      postal_votes: 43,
      total_votes: 12697,
      percentage_of_votes: 0.85,
    },
  ],
  total: {
    evm_votes: 1492448,
    postal_votes: 4862,
    total_votes: 1497310,
  },
};
export const s2539 = {
  cs_name: "Bardhaman-Durgapur",
  cs_shortname: "bardhamandurgapur",
  cs_code: "s2539",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "AZAD KIRTI JHA",
      party: "All India Trinamool Congress",
      evm_votes: 717069,
      postal_votes: 3598,
      total_votes: 720667,
      percentage_of_votes: 47.99,
    },
    {
      serial_no: 2,
      candidate: "DILIP GHOSH",
      party: "Bharatiya Janata Party",
      evm_votes: 579609,
      postal_votes: 3077,
      total_votes: 582686,
      percentage_of_votes: 38.8,
    },
    {
      serial_no: 3,
      candidate: "SUKRITI GHOSAL",
      party: "Communist Party of India (Marxist)",
      evm_votes: 152851,
      postal_votes: 978,
      total_votes: 153829,
      percentage_of_votes: 10.24,
    },
    {
      serial_no: 4,
      candidate: "LUXMI NARAYAN KORA",
      party: "Independent",
      evm_votes: 7138,
      postal_votes: 4,
      total_votes: 7142,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 5,
      candidate: "PRABHU NATH SAH",
      party: "Bahujan Samaj Party",
      evm_votes: 6822,
      postal_votes: 34,
      total_votes: 6856,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 6,
      candidate: "TASBIRUL ISLAM",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 3450,
      postal_votes: 19,
      total_votes: 3469,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 7,
      candidate: "REENA LEONG",
      party: "Independent",
      evm_votes: 3100,
      postal_votes: 11,
      total_votes: 3111,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 8,
      candidate: "MIR SAKIMUDDIN",
      party: "The National Road Map Party of India",
      evm_votes: 2413,
      postal_votes: 5,
      total_votes: 2418,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 21532,
      postal_votes: 63,
      total_votes: 21595,
      percentage_of_votes: 1.44,
    },
  ],
  total: {
    evm_votes: 1493984,
    postal_votes: 7789,
    total_votes: 1501773,
  },
};
export const s2540 = {
  cs_name: "Asansol",
  cs_shortname: "asansol",
  cs_code: "s2540",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "SHATRUGHAN PRASAD SINHA",
      party: "All India Trinamool Congress",
      evm_votes: 603105,
      postal_votes: 2540,
      total_votes: 605645,
      percentage_of_votes: 46.53,
    },
    {
      serial_no: 2,
      candidate: "SURENDRAJEET SINGH AHLUWALIA",
      party: "Bharatiya Janata Party",
      evm_votes: 544077,
      postal_votes: 2004,
      total_votes: 546081,
      percentage_of_votes: 41.96,
    },
    {
      serial_no: 3,
      candidate: "JAHANARA KHAN",
      party: "Communist Party of India (Marxist)",
      evm_votes: 105454,
      postal_votes: 510,
      total_votes: 105964,
      percentage_of_votes: 8.14,
    },
    {
      serial_no: 4,
      candidate: "DIPIKA BAURI",
      party: "Independent",
      evm_votes: 10623,
      postal_votes: 24,
      total_votes: 10647,
      percentage_of_votes: 0.82,
    },
    {
      serial_no: 5,
      candidate: "SUNNY KUMAR SAH",
      party: "Bahujan Samaj Party",
      evm_votes: 8546,
      postal_votes: 29,
      total_votes: 8575,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 6,
      candidate: "SUJIT PAL",
      party: "Independent",
      evm_votes: 4749,
      postal_votes: 11,
      total_votes: 4760,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 7,
      candidate: "AMARNATH CHOWDHURY",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 4330,
      postal_votes: 28,
      total_votes: 4358,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 15448,
      postal_votes: 62,
      total_votes: 15510,
      percentage_of_votes: 1.19,
    },
  ],
  total: {
    evm_votes: 1296332,
    postal_votes: 5208,
    total_votes: 1301540,
  },
};
export const s2541 = {
  cs_name: "Bolpur",
  cs_shortname: "bolpur",
  cs_code: "s2541",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "ASIT KUMAR MAL",
      party: "All India Trinamool Congress",
      evm_votes: 851209,
      postal_votes: 4424,
      total_votes: 855633,
      percentage_of_votes: 55.98,
    },
    {
      serial_no: 2,
      candidate: "PIYA SAHA",
      party: "Bharatiya Janata Party",
      evm_votes: 525463,
      postal_votes: 2917,
      total_votes: 528380,
      percentage_of_votes: 34.57,
    },
    {
      serial_no: 3,
      candidate: "SHYAMALI PRADHAN",
      party: "Communist Party of India (Marxist)",
      evm_votes: 98819,
      postal_votes: 564,
      total_votes: 99383,
      percentage_of_votes: 6.5,
    },
    {
      serial_no: 4,
      candidate: "ATUL CHANDRA BAURI",
      party: "Independent",
      evm_votes: 7811,
      postal_votes: 18,
      total_votes: 7829,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 5,
      candidate: "DR MANIK CHANDRA PRODHAN",
      party: "Bahujan Samaj Party",
      evm_votes: 7011,
      postal_votes: 28,
      total_votes: 7039,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 6,
      candidate: "SHANTA DAS",
      party: "Mulnibasi Party of India",
      evm_votes: 3633,
      postal_votes: 7,
      total_votes: 3640,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 7,
      candidate: "BIJOY DOLUI",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2774,
      postal_votes: 18,
      total_votes: 2792,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "MANTU DHIBAR",
      party: "Hindustan Shakti Sena",
      evm_votes: 2048,
      postal_votes: 4,
      total_votes: 2052,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 21640,
      postal_votes: 82,
      total_votes: 21722,
      percentage_of_votes: 1.42,
    },
  ],
  total: {
    evm_votes: 1520408,
    postal_votes: 8062,
    total_votes: 1528470,
  },
};
export const s2542 = {
  cs_name: "Birbhum",
  cs_shortname: "birbhum",
  cs_code: "s2542",
  winner: "AITC",
  stats: [
    {
      serial_no: 1,
      candidate: "SATABDI ROY",
      party: "All India Trinamool Congress",
      evm_votes: 714972,
      postal_votes: 2989,
      total_votes: 717961,
      percentage_of_votes: 47,
    },
    {
      serial_no: 2,
      candidate: "DEBTANU BHATTACHARYA",
      party: "Bharatiya Janata Party",
      evm_votes: 517761,
      postal_votes: 2550,
      total_votes: 520311,
      percentage_of_votes: 34.06,
    },
    {
      serial_no: 3,
      candidate: "MILTON RASHID",
      party: "Indian National Congress",
      evm_votes: 225572,
      postal_votes: 688,
      total_votes: 226260,
      percentage_of_votes: 14.81,
    },
    {
      serial_no: 4,
      candidate: "AMIY GHOSH",
      party: "Independent",
      evm_votes: 11950,
      postal_votes: 8,
      total_votes: 11958,
      percentage_of_votes: 0.78,
    },
    {
      serial_no: 5,
      candidate: "LAKSHMI HEMBROM",
      party: "Ambedkarite Party of India",
      evm_votes: 7677,
      postal_votes: 10,
      total_votes: 7687,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 6,
      candidate: "AYESHA KHATUN",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 6761,
      postal_votes: 18,
      total_votes: 6779,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 7,
      candidate: "RAJNATH SARKAR",
      party: "Bahujan Samaj Party",
      evm_votes: 5866,
      postal_votes: 16,
      total_votes: 5882,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 8,
      candidate: "SAURAV MUDI",
      party: "Independent",
      evm_votes: 4249,
      postal_votes: 7,
      total_votes: 4256,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 9,
      candidate: "ABDUL IMRAN",
      party: "Independent",
      evm_votes: 3823,
      postal_votes: 9,
      total_votes: 3832,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 10,
      candidate: "BISWAJIT MISHRA",
      party: "All India Arya Mahasabha",
      evm_votes: 3806,
      postal_votes: 17,
      total_votes: 3823,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 11,
      candidate: "MD. JAKIR HOSSAIN",
      party: "Bharatiya National Janata Dal",
      evm_votes: 2547,
      postal_votes: 10,
      total_votes: 2557,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "DOLA SARKAR",
      party: "Indian National Socialistic Action Forces",
      evm_votes: 2027,
      postal_votes: 6,
      total_votes: 2033,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 14037,
      postal_votes: 56,
      total_votes: 14093,
      percentage_of_votes: 0.92,
    },
  ],
  total: {
    evm_votes: 1521048,
    postal_votes: 6384,
    total_votes: 1527432,
  },
};
export const s222 = {
  cs_name: "CHENNAI_NORTH",
  cs_shortname: "chennainorth",
  cs_code: "s222",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "DR.KALANIDHI VEERASWAMY",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 495733,
      postal_votes: 1600,
      total_votes: 497333,
      percentage_of_votes: 55.11,
    },
    {
      serial_no: 2,
      candidate: "R. MANOHAR",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 157631,
      postal_votes: 480,
      total_votes: 158111,
      percentage_of_votes: 17.52,
    },
    {
      serial_no: 3,
      candidate: "R.C. PAUL KANAGARAJ",
      party: "Bharatiya Janata Party",
      evm_votes: 112628,
      postal_votes: 690,
      total_votes: 113318,
      percentage_of_votes: 12.56,
    },
    {
      serial_no: 4,
      candidate: "AMUDINI",
      party: "Naam Tamilar Katchi",
      evm_votes: 95715,
      postal_votes: 239,
      total_votes: 95954,
      percentage_of_votes: 10.63,
    },
    {
      serial_no: 5,
      candidate: "SARAH FATHIMA",
      party: "Independent",
      evm_votes: 5899,
      postal_votes: 24,
      total_votes: 5923,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 6,
      candidate: "E. IQBAL",
      party: "Bahujan Samaj Party",
      evm_votes: 3471,
      postal_votes: 15,
      total_votes: 3486,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 7,
      candidate: "MOORTHY. V",
      party: "Independent",
      evm_votes: 3131,
      postal_votes: 6,
      total_votes: 3137,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 8,
      candidate: "SURIYAMUTHU",
      party: "Independent",
      evm_votes: 976,
      postal_votes: 2,
      total_votes: 978,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 9,
      candidate: "C. SENAPATHI",
      party: "Independent",
      evm_votes: 850,
      postal_votes: 0,
      total_votes: 850,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 10,
      candidate: "DILLI GANESH. E",
      party: "Independent",
      evm_votes: 735,
      postal_votes: 1,
      total_votes: 736,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 11,
      candidate: "U. VENKATESH",
      party: "Independent",
      evm_votes: 709,
      postal_votes: 5,
      total_votes: 714,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "M. ZION RAJ",
      party: "Independent",
      evm_votes: 696,
      postal_votes: 1,
      total_votes: 697,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "A. AJITHKUMAR",
      party: "Thakkam Katchi",
      evm_votes: 671,
      postal_votes: 2,
      total_votes: 673,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 14,
      candidate: "J. SEBASTIN",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 586,
      postal_votes: 2,
      total_votes: 588,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 15,
      candidate: "AKSHAY",
      party: "Naadaalum Makkal Katchi",
      evm_votes: 563,
      postal_votes: 3,
      total_votes: 566,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 16,
      candidate: "B. BALAMURUGAN",
      party: "Independent",
      evm_votes: 485,
      postal_votes: 3,
      total_votes: 488,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 17,
      candidate: "K.PALANIAPPAN",
      party: "Independent",
      evm_votes: 460,
      postal_votes: 2,
      total_votes: 462,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 18,
      candidate: "R. SENTHIL KUMAR",
      party: "Independent",
      evm_votes: 457,
      postal_votes: 0,
      total_votes: 457,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 19,
      candidate: "MATHANKUMAR. G",
      party: "Independent",
      evm_votes: 390,
      postal_votes: 0,
      total_votes: 390,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 20,
      candidate: "P. JAYASELAN",
      party: "Independent",
      evm_votes: 386,
      postal_votes: 0,
      total_votes: 386,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 21,
      candidate: "M. KOLANJI",
      party: "Independent",
      evm_votes: 361,
      postal_votes: 1,
      total_votes: 362,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 22,
      candidate: "B. ZAKIR HUSSAIN",
      party: "Independent",
      evm_votes: 354,
      postal_votes: 1,
      total_votes: 355,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 23,
      candidate: "P. UMADEVI",
      party: "Independent",
      evm_votes: 346,
      postal_votes: 0,
      total_votes: 346,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 24,
      candidate: "K.C.JAYAPRAKASH",
      party: "Independent",
      evm_votes: 334,
      postal_votes: 0,
      total_votes: 334,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 25,
      candidate: "C. SRIDHAR",
      party: "Independent",
      evm_votes: 307,
      postal_votes: 0,
      total_votes: 307,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 26,
      candidate: "T. MANOHARAN",
      party: "Independent",
      evm_votes: 293,
      postal_votes: 0,
      total_votes: 293,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 27,
      candidate: "K. SAMPATH",
      party: "Independent",
      evm_votes: 268,
      postal_votes: 1,
      total_votes: 269,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 28,
      candidate: "D. SRINIVASAN",
      party: "Independent",
      evm_votes: 260,
      postal_votes: 1,
      total_votes: 261,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 29,
      candidate: "S. RAVIKUMAR",
      party: "Republican Party of India (Sivaraj)",
      evm_votes: 258,
      postal_votes: 1,
      total_votes: 259,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 30,
      candidate: "T. KAVIYARASU",
      party: "Independent",
      evm_votes: 250,
      postal_votes: 2,
      total_votes: 252,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 31,
      candidate: "SATHISH. B",
      party: "Independent",
      evm_votes: 245,
      postal_votes: 1,
      total_votes: 246,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 32,
      candidate: "R.VINAYAGAMOORTHY",
      party: "Independent",
      evm_votes: 206,
      postal_votes: 0,
      total_votes: 206,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 33,
      candidate: "D. KABILAN",
      party: "Independent",
      evm_votes: 203,
      postal_votes: 6,
      total_votes: 209,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 34,
      candidate: "P. MARIMUTHU",
      party: "Independent",
      evm_votes: 171,
      postal_votes: 1,
      total_votes: 172,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 35,
      candidate: "V.MADHAN MOHAN",
      party: "Independent",
      evm_votes: 163,
      postal_votes: 0,
      total_votes: 163,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 36,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13139,
      postal_votes: 69,
      total_votes: 13208,
      percentage_of_votes: 1.46,
    },
  ],
  total: {
    evm_votes: 899330,
    postal_votes: 3159,
    total_votes: 902489,
  },
};
export const s223 = {
  cs_name: "CHENNAI_SOUTH",
  cs_shortname: "chennaisouth",
  cs_code: "s223",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "T.SUMATHY (ALIAS) THAMIZHACHI THANGAPANDIAN",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 515307,
      postal_votes: 1321,
      total_votes: 516628,
      percentage_of_votes: 47,
    },
    {
      serial_no: 2,
      candidate: "DR.TAMILISAI SOUNDARARAJAN",
      party: "Bharatiya Janata Party",
      evm_votes: 289229,
      postal_votes: 1454,
      total_votes: 290683,
      percentage_of_votes: 26.44,
    },
    {
      serial_no: 3,
      candidate: "DR.J.JAYAVARDHAN",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 172108,
      postal_votes: 383,
      total_votes: 172491,
      percentage_of_votes: 15.69,
    },
    {
      serial_no: 4,
      candidate: "S.TAMILCHELVI",
      party: "Naam Tamilar Katchi",
      evm_votes: 83821,
      postal_votes: 151,
      total_votes: 83972,
      percentage_of_votes: 7.64,
    },
    {
      serial_no: 5,
      candidate: "M.G.RAMU",
      party: "Jebamani Janata",
      evm_votes: 3526,
      postal_votes: 8,
      total_votes: 3534,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 6,
      candidate: "M.AZARUTHEEN",
      party: "Independent",
      evm_votes: 1808,
      postal_votes: 4,
      total_votes: 1812,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 7,
      candidate: "G.P. ROBERT",
      party: "Bahujan Samaj Party",
      evm_votes: 1596,
      postal_votes: 3,
      total_votes: 1599,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 8,
      candidate: "R.RAVICHANDRAN",
      party: "Independent",
      evm_votes: 835,
      postal_votes: 0,
      total_votes: 835,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 9,
      candidate: "S.MANIMARAN",
      party: "Independent",
      evm_votes: 803,
      postal_votes: 0,
      total_votes: 803,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 10,
      candidate: "FARMER JAYARAMAN. S.K.J",
      party: "Desiya Makkal Sakthi Katchi",
      evm_votes: 790,
      postal_votes: 2,
      total_votes: 792,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "A.K.D. ELLAPPAN",
      party: "Mahathma Makkal Munnetra Kazhakam",
      evm_votes: 761,
      postal_votes: 0,
      total_votes: 761,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "K.KANNAN",
      party: "Independent",
      evm_votes: 625,
      postal_votes: 0,
      total_votes: 625,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "BALAJI. G. MANGADU",
      party: "Aravor Munnetra Kazhagam",
      evm_votes: 588,
      postal_votes: 1,
      total_votes: 589,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "KUTTIMANI. S",
      party: "Veerath Thiyagi Viswanathadoss Thozhilalarkal Katchi",
      evm_votes: 565,
      postal_votes: 0,
      total_votes: 565,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "R.KANCHANA",
      party: "Tamizhaga Murpokku Makkal Katchi",
      evm_votes: 565,
      postal_votes: 1,
      total_votes: 566,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "SASI REKHA",
      party: "Naadaalum Makkal Katchi",
      evm_votes: 557,
      postal_votes: 2,
      total_votes: 559,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 17,
      candidate: "P. SATHEESH KUMAR",
      party: "Independent",
      evm_votes: 520,
      postal_votes: 1,
      total_votes: 521,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 18,
      candidate: "P.SEKAR",
      party: "Thakkam Katchi",
      evm_votes: 435,
      postal_votes: 0,
      total_votes: 435,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 19,
      candidate: "R.YOGESHWARAN",
      party: "Independent",
      evm_votes: 425,
      postal_votes: 1,
      total_votes: 426,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 20,
      candidate: "J. SATHISH",
      party: "Independent",
      evm_votes: 418,
      postal_votes: 0,
      total_votes: 418,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 21,
      candidate: "V.P. RAVIKKARAN",
      party: "Independent",
      evm_votes: 372,
      postal_votes: 1,
      total_votes: 373,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "A.PONNUDURAI",
      party: "Independent",
      evm_votes: 362,
      postal_votes: 0,
      total_votes: 362,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 23,
      candidate: "N.V.DAMODARAN",
      party: "National Maha Sabha Party",
      evm_votes: 341,
      postal_votes: 2,
      total_votes: 343,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 24,
      candidate: "M.MUNUSWAMY",
      party: "Viro Ke Vir Indian Party",
      evm_votes: 326,
      postal_votes: 4,
      total_votes: 330,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 25,
      candidate: "KARTHIK MURTHY",
      party: "Independent",
      evm_votes: 312,
      postal_votes: 3,
      total_votes: 315,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 26,
      candidate: "V.P.SURESH",
      party: "Independent",
      evm_votes: 288,
      postal_votes: 1,
      total_votes: 289,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 27,
      candidate: "E.RAMESH",
      party: "Independent",
      evm_votes: 270,
      postal_votes: 0,
      total_votes: 270,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 28,
      candidate: "R.BALAJI",
      party: "Independent",
      evm_votes: 264,
      postal_votes: 0,
      total_votes: 264,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 29,
      candidate: "DINESH KUMAR. B",
      party: "Independent",
      evm_votes: 253,
      postal_votes: 0,
      total_votes: 253,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 30,
      candidate: "A.BABU",
      party: "Independent",
      evm_votes: 246,
      postal_votes: 0,
      total_votes: 246,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 31,
      candidate: "DAYALAN. R",
      party: "Independent",
      evm_votes: 241,
      postal_votes: 1,
      total_votes: 242,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 32,
      candidate: "VIJIRATHINAM. M",
      party: "Independent",
      evm_votes: 237,
      postal_votes: 0,
      total_votes: 237,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 33,
      candidate: "V.SIVAKUMAR",
      party: "Independent",
      evm_votes: 217,
      postal_votes: 0,
      total_votes: 217,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 34,
      candidate: "MURUGAN. P",
      party: "Independent",
      evm_votes: 182,
      postal_votes: 0,
      total_votes: 182,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 35,
      candidate: "G.RAMESHBABU",
      party: "Independent",
      evm_votes: 178,
      postal_votes: 1,
      total_votes: 179,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 36,
      candidate: "S.RAJASEKARAN",
      party: "Independent",
      evm_votes: 177,
      postal_votes: 1,
      total_votes: 178,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 37,
      candidate: "N.NARAYANASWAMY",
      party: "Independent",
      evm_votes: 175,
      postal_votes: 0,
      total_votes: 175,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 38,
      candidate: "R.DEVENDRAN",
      party: "Independent",
      evm_votes: 167,
      postal_votes: 1,
      total_votes: 168,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 39,
      candidate: "PARTHIBAN. S",
      party: "Independent",
      evm_votes: 165,
      postal_votes: 1,
      total_votes: 166,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 40,
      candidate: "I. DAVIDSINGH",
      party: "Independent",
      evm_votes: 150,
      postal_votes: 1,
      total_votes: 151,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 41,
      candidate: "E RAVENDRAN",
      party: "Independent",
      evm_votes: 114,
      postal_votes: 0,
      total_votes: 114,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 42,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 15607,
      postal_votes: 46,
      total_votes: 15653,
      percentage_of_votes: 1.42,
    },
  ],
  total: {
    evm_votes: 1095926,
    postal_votes: 3395,
    total_votes: 1099321,
  },
};
export const s224 = {
  cs_name: "CHENNAI_CENTRAL",
  cs_shortname: "chennaicentral",
  cs_code: "s224",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "DAYANIDHI MARAN",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 412286,
      postal_votes: 1562,
      total_votes: 413848,
      percentage_of_votes: 56.65,
    },
    {
      serial_no: 2,
      candidate: "VINOJ",
      party: "Bharatiya Janata Party",
      evm_votes: 168059,
      postal_votes: 1100,
      total_votes: 169159,
      percentage_of_votes: 23.16,
    },
    {
      serial_no: 3,
      candidate: "B. PARTHASARATHY",
      party: "Desiya Murpokku Dravida Kazhagam",
      evm_votes: 71772,
      postal_votes: 244,
      total_votes: 72016,
      percentage_of_votes: 9.86,
    },
    {
      serial_no: 4,
      candidate: "DR.R.KARTHIKEYAN",
      party: "Naam Tamilar Katchi",
      evm_votes: 45865,
      postal_votes: 166,
      total_votes: 46031,
      percentage_of_votes: 6.3,
    },
    {
      serial_no: 5,
      candidate: "ANBAZHAGAN.G",
      party: "Independent",
      evm_votes: 3175,
      postal_votes: 11,
      total_votes: 3186,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 6,
      candidate: "DILAVAR ALI",
      party: "Tipu Sultan Party",
      evm_votes: 2536,
      postal_votes: 10,
      total_votes: 2546,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 7,
      candidate: "SAMARAN",
      party: "Bahujan Samaj Party",
      evm_votes: 2132,
      postal_votes: 15,
      total_votes: 2147,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 8,
      candidate: "L.KASINATHAN",
      party: "Independent",
      evm_votes: 1059,
      postal_votes: 1,
      total_votes: 1060,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 9,
      candidate: "AGNI AALVAR",
      party: "Independent",
      evm_votes: 783,
      postal_votes: 4,
      total_votes: 787,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "C.S.KARNAN",
      party: "Anti Corruption Dynamic Party",
      evm_votes: 750,
      postal_votes: 11,
      total_votes: 761,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "M.L.RAVI",
      party: "Desiya Makkal Sakthi Katchi",
      evm_votes: 690,
      postal_votes: 6,
      total_votes: 696,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 12,
      candidate: "S.KANDASAMY",
      party: "Independent",
      evm_votes: 595,
      postal_votes: 1,
      total_votes: 596,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "DHAYANIDHI SURESH",
      party: "Independent",
      evm_votes: 565,
      postal_votes: 2,
      total_votes: 567,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "S.SATHISH KUMAR",
      party: "Independent",
      evm_votes: 526,
      postal_votes: 1,
      total_votes: 527,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 15,
      candidate: "P.ARJUNAN",
      party: "Independent",
      evm_votes: 502,
      postal_votes: 7,
      total_votes: 509,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 16,
      candidate: "S.SATHISH KUMAR",
      party: "Independent",
      evm_votes: 500,
      postal_votes: 1,
      total_votes: 501,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 17,
      candidate: "G.OMPRAKASH",
      party: "Independent",
      evm_votes: 486,
      postal_votes: 2,
      total_votes: 488,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "V.V.TAMILARASAN",
      party: "Independent",
      evm_votes: 386,
      postal_votes: 3,
      total_votes: 389,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 19,
      candidate: "UDAYA KUMAR.V",
      party: "Independent",
      evm_votes: 382,
      postal_votes: 0,
      total_votes: 382,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 20,
      candidate: "B.PARTHASARATHY",
      party: "Independent",
      evm_votes: 377,
      postal_votes: 3,
      total_votes: 380,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 21,
      candidate: "K.CHANDRASEKAR",
      party: "Naadaalum Makkal Katchi",
      evm_votes: 373,
      postal_votes: 0,
      total_votes: 373,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 22,
      candidate: "N.RAMAN",
      party: "Tamilnadu Makkal Nalvazhvu Periyakkam",
      evm_votes: 311,
      postal_votes: 3,
      total_votes: 314,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 23,
      candidate: "R.MINNAL RAJ",
      party: "Independent",
      evm_votes: 310,
      postal_votes: 0,
      total_votes: 310,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 24,
      candidate: "R.VIJAY",
      party: "Independent",
      evm_votes: 268,
      postal_votes: 0,
      total_votes: 268,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 25,
      candidate: "A.SELVA KUMAR",
      party: "Thakkam Katchi",
      evm_votes: 265,
      postal_votes: 5,
      total_votes: 270,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 26,
      candidate: "M.NAGARAJ",
      party: "Independent",
      evm_votes: 254,
      postal_votes: 0,
      total_votes: 254,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 27,
      candidate: "J.NAGARAJ",
      party: "Independent",
      evm_votes: 238,
      postal_votes: 3,
      total_votes: 241,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 28,
      candidate: "SENTHILKUMAR",
      party: "Chennai Youth Party",
      evm_votes: 222,
      postal_votes: 1,
      total_votes: 223,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 29,
      candidate: "RANJITHKUMAR.S",
      party: "Independent",
      evm_votes: 215,
      postal_votes: 0,
      total_votes: 215,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 30,
      candidate: "RAVI BALARAMAN",
      party: "Independent",
      evm_votes: 182,
      postal_votes: 1,
      total_votes: 183,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 31,
      candidate: "MANJUNATHA.R",
      party: "Independent",
      evm_votes: 159,
      postal_votes: 0,
      total_votes: 159,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 32,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11082,
      postal_votes: 81,
      total_votes: 11163,
      percentage_of_votes: 1.53,
    },
  ],
  total: {
    evm_votes: 727305,
    postal_votes: 3244,
    total_votes: 730549,
  },
};
export const s225 = {
  cs_name: "SRIPERUMBUDUR",
  cs_shortname: "sriperumbudur",
  cs_code: "s225",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "T R BAALU",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 755671,
      postal_votes: 2940,
      total_votes: 758611,
      percentage_of_votes: 52.65,
    },
    {
      serial_no: 2,
      candidate: "G PREMKUMAR",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 270549,
      postal_votes: 1033,
      total_votes: 271582,
      percentage_of_votes: 18.85,
    },
    {
      serial_no: 3,
      candidate: "V N VENUGOPAL",
      party: "Tamil Maanila Congress (Moopanar)",
      evm_votes: 209183,
      postal_votes: 927,
      total_votes: 210110,
      percentage_of_votes: 14.58,
    },
    {
      serial_no: 4,
      candidate: "V RAVICHANDRAN",
      party: "Naam Tamilar Katchi",
      evm_votes: 139931,
      postal_votes: 302,
      total_votes: 140233,
      percentage_of_votes: 9.73,
    },
    {
      serial_no: 5,
      candidate: "K PRABAKARAN",
      party: "Bahujan Samaj Party",
      evm_votes: 8115,
      postal_votes: 74,
      total_votes: 8189,
      percentage_of_votes: 0.57,
    },
    {
      serial_no: 6,
      candidate: "M S ARUMUGAM",
      party: "Independent",
      evm_votes: 3695,
      postal_votes: 4,
      total_votes: 3699,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 7,
      candidate: "V SUDHAVALLI",
      party: "Aravor Munnetra Kazhagam",
      evm_votes: 3659,
      postal_votes: 35,
      total_votes: 3694,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 8,
      candidate: "S AMBETHKUMAR",
      party: "Mahathma Makkal Munnetra Kazhakam",
      evm_votes: 2211,
      postal_votes: 9,
      total_votes: 2220,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 9,
      candidate: "M ASHOK KUMAR",
      party: "Independent",
      evm_votes: 1696,
      postal_votes: 8,
      total_votes: 1704,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 10,
      candidate: "S SIVAKUMAR",
      party: "Veerath Thiyagi Viswanathadoss Thozhilalarkal Katchi",
      evm_votes: 1349,
      postal_votes: 3,
      total_votes: 1352,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 11,
      candidate: "AYODHI LAKSHMANAN",
      party: "Independent",
      evm_votes: 1255,
      postal_votes: 4,
      total_votes: 1259,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 12,
      candidate: "S CHITTIBABU",
      party: "Naadaalum Makkal Katchi",
      evm_votes: 1238,
      postal_votes: 3,
      total_votes: 1241,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "C ARAVIND",
      party: "Desiya Makkal Sakthi Katchi",
      evm_votes: 959,
      postal_votes: 11,
      total_votes: 970,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 14,
      candidate: "GOPI DEEPAK",
      party: "Independent",
      evm_votes: 878,
      postal_votes: 5,
      total_votes: 883,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "KARTHIK KUMAR P C",
      party: "Independent",
      evm_votes: 866,
      postal_votes: 1,
      total_votes: 867,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 16,
      candidate: "J MOHANRAJ",
      party: "Jebamani Janata",
      evm_votes: 817,
      postal_votes: 5,
      total_votes: 822,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 17,
      candidate: "DR S PRAVEEN",
      party: "Independent",
      evm_votes: 791,
      postal_votes: 8,
      total_votes: 799,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 18,
      candidate: "I MOHAMED YASEEN",
      party: "Thakkam Katchi",
      evm_votes: 668,
      postal_votes: 1,
      total_votes: 669,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 19,
      candidate: "K PALANIYAPPAN",
      party: "Tamizhaga Murpokku Makkal Katchi",
      evm_votes: 613,
      postal_votes: 4,
      total_votes: 617,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 20,
      candidate: "S PRABHU",
      party: "Independent",
      evm_votes: 513,
      postal_votes: 1,
      total_votes: 514,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 21,
      candidate: "P BASKARAN",
      party: "Independent",
      evm_votes: 493,
      postal_votes: 1,
      total_votes: 494,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "P HARIDAS",
      party: "Independent",
      evm_votes: 458,
      postal_votes: 2,
      total_votes: 460,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 23,
      candidate: "C A JAYARAMAN",
      party: "Independent",
      evm_votes: 445,
      postal_votes: 2,
      total_votes: 447,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 24,
      candidate: "G RAMKUMAR",
      party: "Independent",
      evm_votes: 426,
      postal_votes: 0,
      total_votes: 426,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 25,
      candidate: "N MANIKKAM",
      party: "Independent",
      evm_votes: 402,
      postal_votes: 1,
      total_votes: 403,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 26,
      candidate: "M PREMKUMAR",
      party: "Independent",
      evm_votes: 393,
      postal_votes: 1,
      total_votes: 394,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 27,
      candidate: "S MUNIKUMAR",
      party: "Samaniya Makkal Nala Katchi",
      evm_votes: 393,
      postal_votes: 1,
      total_votes: 394,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 28,
      candidate: "M SARAVANAN",
      party: "Independent",
      evm_votes: 385,
      postal_votes: 0,
      total_votes: 385,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 29,
      candidate: "A JINNA MOHAMMED",
      party: "Independent",
      evm_votes: 382,
      postal_votes: 6,
      total_votes: 388,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 30,
      candidate: "S SIVARAMAN",
      party: "Independent",
      evm_votes: 340,
      postal_votes: 3,
      total_votes: 343,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 31,
      candidate: "C PREMKUMAR",
      party: "Independent",
      evm_votes: 313,
      postal_votes: 3,
      total_votes: 316,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 32,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 26294,
      postal_votes: 156,
      total_votes: 26450,
      percentage_of_votes: 1.84,
    },
  ],
  total: {
    evm_votes: 1435381,
    postal_votes: 5554,
    total_votes: 1440935,
  },
};
export const s226 = {
  cs_name: "KANCHEEPURAM",
  cs_shortname: "kancheepuram",
  cs_code: "s226",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "SELVAM. G",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 582978,
      postal_votes: 3066,
      total_votes: 586044,
      percentage_of_votes: 46.53,
    },
    {
      serial_no: 2,
      candidate: "RAJASEKAR. E",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 362991,
      postal_votes: 1580,
      total_votes: 364571,
      percentage_of_votes: 28.94,
    },
    {
      serial_no: 3,
      candidate: "JOTHI. V",
      party: "Pattali Makkal Katchi",
      evm_votes: 163792,
      postal_votes: 1139,
      total_votes: 164931,
      percentage_of_votes: 13.09,
    },
    {
      serial_no: 4,
      candidate: "SANTHOSH KUMAR. V",
      party: "Naam Tamilar Katchi",
      evm_votes: 109858,
      postal_votes: 414,
      total_votes: 110272,
      percentage_of_votes: 8.75,
    },
    {
      serial_no: 5,
      candidate: "ILAYARAJA. S",
      party: "Bahujan Samaj Party",
      evm_votes: 5243,
      postal_votes: 21,
      total_votes: 5264,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 6,
      candidate: "VENKATESAN. K",
      party: "Independent",
      evm_votes: 2798,
      postal_votes: 9,
      total_votes: 2807,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 7,
      candidate: "NARESH BHARATHI. S",
      party: "Independent",
      evm_votes: 2499,
      postal_votes: 12,
      total_votes: 2511,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 8,
      candidate: "ELANGOVAN. M",
      party: "Independent",
      evm_votes: 1874,
      postal_votes: 7,
      total_votes: 1881,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 9,
      candidate: "SURYA. A",
      party: "Independent",
      evm_votes: 1673,
      postal_votes: 29,
      total_votes: 1702,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "RAMESH. S",
      party: "Independent",
      evm_votes: 1317,
      postal_votes: 1,
      total_votes: 1318,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "SELVAM. T",
      party: "Independent",
      evm_votes: 1295,
      postal_votes: 17,
      total_votes: 1312,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 16811,
      postal_votes: 154,
      total_votes: 16965,
      percentage_of_votes: 1.35,
    },
  ],
  total: {
    evm_votes: 1253129,
    postal_votes: 6449,
    total_votes: 1259578,
  },
};
export const s227 = {
  cs_name: "ARAKKONAM",
  cs_shortname: "arakkonam",
  cs_code: "s227",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "S JAGATHRATCHAKAN",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 560578,
      postal_votes: 2638,
      total_votes: 563216,
      percentage_of_votes: 48.39,
    },
    {
      serial_no: 2,
      candidate: "L VIJAYAN",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 255343,
      postal_votes: 1314,
      total_votes: 256657,
      percentage_of_votes: 22.05,
    },
    {
      serial_no: 3,
      candidate: "K BALU",
      party: "Pattali Makkal Katchi",
      evm_votes: 200614,
      postal_votes: 1711,
      total_votes: 202325,
      percentage_of_votes: 17.38,
    },
    {
      serial_no: 4,
      candidate: "S AFSHIA NASRIN",
      party: "Naam Tamilar Katchi",
      evm_votes: 97985,
      postal_votes: 959,
      total_votes: 98944,
      percentage_of_votes: 8.5,
    },
    {
      serial_no: 5,
      candidate: "D PANDIYAN",
      party: "Bahujan Samaj Party",
      evm_votes: 6135,
      postal_votes: 77,
      total_votes: 6212,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 6,
      candidate: "A PALANI",
      party: "Independent",
      evm_votes: 3855,
      postal_votes: 11,
      total_votes: 3866,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 7,
      candidate: "K MANIGANDAN",
      party: "Independent",
      evm_votes: 2045,
      postal_votes: 4,
      total_votes: 2049,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "INDU W",
      party: "Tamil Manila Murpokku Dravida Kazhagam",
      evm_votes: 1872,
      postal_votes: 66,
      total_votes: 1938,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 9,
      candidate: "DR A V NARENDRAN",
      party: "Independent",
      evm_votes: 1711,
      postal_votes: 11,
      total_votes: 1722,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "M VIJAYAN",
      party: "Independent",
      evm_votes: 1674,
      postal_votes: 4,
      total_votes: 1678,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "M BALU",
      party: "Independent",
      evm_votes: 1557,
      postal_votes: 4,
      total_votes: 1561,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 12,
      candidate: "SHETTU S",
      party: "Independent",
      evm_votes: 1556,
      postal_votes: 5,
      total_votes: 1561,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 13,
      candidate: "V BABU",
      party: "United Republican Party of India",
      evm_votes: 1351,
      postal_votes: 15,
      total_votes: 1366,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "M THULASIRAMAN",
      party: "Independent",
      evm_votes: 1046,
      postal_votes: 7,
      total_votes: 1053,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 15,
      candidate: "D JANAKIRAMAN",
      party: "Independent",
      evm_votes: 831,
      postal_votes: 6,
      total_votes: 837,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 16,
      candidate: "S SURESH KUMAR",
      party: "Independent",
      evm_votes: 828,
      postal_votes: 7,
      total_votes: 835,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 17,
      candidate: "R VINOTH",
      party: "Veerath Thiyagi Viswanathadoss Thozhilalarkal Katchi",
      evm_votes: 819,
      postal_votes: 2,
      total_votes: 821,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "V VINAYAGAM",
      party: "Independent",
      evm_votes: 727,
      postal_votes: 2,
      total_votes: 729,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 19,
      candidate: "VIJAYAN K",
      party: "All India Jananayaka Makkal Kazhagam",
      evm_votes: 624,
      postal_votes: 9,
      total_votes: 633,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 20,
      candidate: "SHANAVAS J",
      party: "Independent",
      evm_votes: 580,
      postal_votes: 3,
      total_votes: 583,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 21,
      candidate: "V KRISHNANANDAN",
      party: "Anna MGR Dravida Makkal Kalgam",
      evm_votes: 568,
      postal_votes: 14,
      total_votes: 582,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 22,
      candidate: "A JAYACHANDRAN",
      party: "Independent",
      evm_votes: 534,
      postal_votes: 4,
      total_votes: 538,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 23,
      candidate: "N SUGUMAR",
      party: "Independent",
      evm_votes: 424,
      postal_votes: 8,
      total_votes: 432,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 24,
      candidate: "M VIJAY",
      party: "Independent",
      evm_votes: 416,
      postal_votes: 11,
      total_votes: 427,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 25,
      candidate: "G SUDHAKAR",
      party: "Independent",
      evm_votes: 367,
      postal_votes: 13,
      total_votes: 380,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 26,
      candidate: "K VENKATESAN",
      party: "Independent",
      evm_votes: 261,
      postal_votes: 3,
      total_votes: 264,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 27,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12423,
      postal_votes: 190,
      total_votes: 12613,
      percentage_of_votes: 1.08,
    },
  ],
  total: {
    evm_votes: 1156724,
    postal_votes: 7098,
    total_votes: 1163822,
  },
};
export const s228 = {
  cs_name: "VELLORE",
  cs_shortname: "vellore",
  cs_code: "s228",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "DM KATHIR ANAND",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 566435,
      postal_votes: 2257,
      total_votes: 568692,
      percentage_of_votes: 50.35,
    },
    {
      serial_no: 2,
      candidate: "AC SHANMUGAM",
      party: "Bharatiya Janata Party",
      evm_votes: 350956,
      postal_votes: 2034,
      total_votes: 352990,
      percentage_of_votes: 31.25,
    },
    {
      serial_no: 3,
      candidate: "DR S PASUPATHI",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 116964,
      postal_votes: 718,
      total_votes: 117682,
      percentage_of_votes: 10.42,
    },
    {
      serial_no: 4,
      candidate: "D MAHESH ANAND",
      party: "Naam Tamilar Katchi",
      evm_votes: 52707,
      postal_votes: 577,
      total_votes: 53284,
      percentage_of_votes: 4.72,
    },
    {
      serial_no: 5,
      candidate: "A NATARAJAN",
      party: "Bharatiya Praja Aikyata Party",
      evm_votes: 3982,
      postal_votes: 23,
      total_votes: 4005,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 6,
      candidate: "SHANMUGAVELU",
      party: "Independent",
      evm_votes: 3570,
      postal_votes: 6,
      total_votes: 3576,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 7,
      candidate: "MANSOOR ALI KHAN",
      party: "Independent",
      evm_votes: 2769,
      postal_votes: 35,
      total_votes: 2804,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 8,
      candidate: "SYED ALI D",
      party: "Independent",
      evm_votes: 2121,
      postal_votes: 3,
      total_votes: 2124,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 9,
      candidate: "P SHANMUGAM",
      party: "Independent",
      evm_votes: 2037,
      postal_votes: 2,
      total_votes: 2039,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 10,
      candidate: "NAGARAJ P",
      party: "Independent",
      evm_votes: 1948,
      postal_votes: 11,
      total_votes: 1959,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 11,
      candidate: "K JAYAMANI",
      party: "Bahujan Samaj Party",
      evm_votes: 1684,
      postal_votes: 18,
      total_votes: 1702,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 12,
      candidate: "D SARAVANAN",
      party: "Independent",
      evm_votes: 1497,
      postal_votes: 0,
      total_votes: 1497,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 13,
      candidate: "MP SHANMUGAM",
      party: "Independent",
      evm_votes: 859,
      postal_votes: 9,
      total_votes: 868,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "N SIVAKUMAR",
      party: "Independent",
      evm_votes: 701,
      postal_votes: 0,
      total_votes: 701,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "AFROSE",
      party: "Independent",
      evm_votes: 672,
      postal_votes: 1,
      total_votes: 673,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 16,
      candidate: "A BEEMARAO MILINTHAR",
      party: "United Republican Party of India",
      evm_votes: 667,
      postal_votes: 3,
      total_votes: 670,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 17,
      candidate: "DR S JAYARAJ",
      party: "Independent",
      evm_votes: 659,
      postal_votes: 2,
      total_votes: 661,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 18,
      candidate: "K SHANMUGAM",
      party: "Independent",
      evm_votes: 601,
      postal_votes: 15,
      total_votes: 616,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 19,
      candidate: "V KALIAPERUMAL",
      party: "Ganasangam Party of India",
      evm_votes: 594,
      postal_votes: 6,
      total_votes: 600,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 20,
      candidate: "G SHANMUGAM",
      party: "Independent",
      evm_votes: 560,
      postal_votes: 12,
      total_votes: 572,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 21,
      candidate: "PP JAYAPRAKASH",
      party: "Independent",
      evm_votes: 455,
      postal_votes: 6,
      total_votes: 461,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 22,
      candidate: "PASUPATHI",
      party: "Independent",
      evm_votes: 407,
      postal_votes: 8,
      total_votes: 415,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 23,
      candidate: "S WILLIAM SATHYARAJ",
      party: "Tamil Manila Murpokku Dravida Kazhagam",
      evm_votes: 373,
      postal_votes: 4,
      total_votes: 377,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 24,
      candidate: "M BABU",
      party: "Independent",
      evm_votes: 303,
      postal_votes: 3,
      total_votes: 306,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 25,
      candidate: "B JAGAN",
      party: "Independent",
      evm_votes: 282,
      postal_votes: 3,
      total_votes: 285,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 26,
      candidate: "AHMED RAASHID PALLIMIRA",
      party: "Independent",
      evm_votes: 271,
      postal_votes: 2,
      total_votes: 273,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 27,
      candidate: "SHANMUGASUNDARAM",
      party: "Independent",
      evm_votes: 250,
      postal_votes: 1,
      total_votes: 251,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 28,
      candidate: "C MADHAVAN",
      party: "Independent",
      evm_votes: 201,
      postal_votes: 1,
      total_votes: 202,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 29,
      candidate: "T JOHNSON",
      party: "Independent",
      evm_votes: 174,
      postal_votes: 0,
      total_votes: 174,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 30,
      candidate: "E RAJKUMAR",
      party: "Independent",
      evm_votes: 129,
      postal_votes: 4,
      total_votes: 133,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 31,
      candidate: "C RAMACHANDHIRAN",
      party: "Independent",
      evm_votes: 128,
      postal_votes: 2,
      total_votes: 130,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 32,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8669,
      postal_votes: 67,
      total_votes: 8736,
      percentage_of_votes: 0.77,
    },
  ],
  total: {
    evm_votes: 1123625,
    postal_votes: 5833,
    total_votes: 1129458,
  },
};
export const s2210 = {
  cs_name: "DHARMAPURI",
  cs_shortname: "dharmapuri",
  cs_code: "s2210",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "MANI. A.",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 429301,
      postal_votes: 3366,
      total_votes: 432667,
      percentage_of_votes: 34.67,
    },
    {
      serial_no: 2,
      candidate: "SOWMIYA ANBUMANI",
      party: "Pattali Makkal Katchi",
      evm_votes: 408445,
      postal_votes: 2922,
      total_votes: 411367,
      percentage_of_votes: 32.97,
    },
    {
      serial_no: 3,
      candidate: "ASOKAN. R. DR.",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 291590,
      postal_votes: 2039,
      total_votes: 293629,
      percentage_of_votes: 23.53,
    },
    {
      serial_no: 4,
      candidate: "ABINAYA",
      party: "Naam Tamilar Katchi",
      evm_votes: 64576,
      postal_votes: 805,
      total_votes: 65381,
      percentage_of_votes: 5.24,
    },
    {
      serial_no: 5,
      candidate: "THANGAVEL. K.",
      party: "Dhesiya Makkal Kazhagam",
      evm_votes: 7323,
      postal_votes: 8,
      total_votes: 7331,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 6,
      candidate: "THIRUMURUGAN. P.",
      party: "Independent",
      evm_votes: 5668,
      postal_votes: 3,
      total_votes: 5671,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 7,
      candidate: "HARI. R.",
      party: "Bahujan Samaj Party",
      evm_votes: 3128,
      postal_votes: 38,
      total_votes: 3166,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 8,
      candidate: "SOWMIYA",
      party: "Independent",
      evm_votes: 3001,
      postal_votes: 27,
      total_votes: 3028,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 9,
      candidate: "ELAYARANI. P.",
      party: "Ganasangam Party of India",
      evm_votes: 2301,
      postal_votes: 14,
      total_votes: 2315,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 10,
      candidate: "ARUMUGAM. M.",
      party: "Independent",
      evm_votes: 2274,
      postal_votes: 16,
      total_votes: 2290,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 11,
      candidate: "AGNI AALVAR",
      party: "Independent",
      evm_votes: 1643,
      postal_votes: 8,
      total_votes: 1651,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 12,
      candidate: "PALANI. K.",
      party: "Independent",
      evm_votes: 1441,
      postal_votes: 9,
      total_votes: 1450,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "MANI. R.",
      party: "Independent",
      evm_votes: 1029,
      postal_votes: 4,
      total_votes: 1033,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "ASHOKAN. M.",
      party: "Independent",
      evm_votes: 1024,
      postal_votes: 2,
      total_votes: 1026,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "ALAGARASAN. R.",
      party: "Independent",
      evm_votes: 899,
      postal_votes: 1,
      total_votes: 900,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 16,
      candidate: "SUNDHARAMOORTHY. M.",
      party: "Independent",
      evm_votes: 797,
      postal_votes: 3,
      total_votes: 800,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 17,
      candidate: "MANI. C.",
      party: "Independent",
      evm_votes: 706,
      postal_votes: 4,
      total_votes: 710,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 18,
      candidate: "PARTHASARATHI",
      party: "Tamilar Makkal Katchi",
      evm_votes: 698,
      postal_votes: 11,
      total_votes: 709,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 19,
      candidate: "PADMARAJAN. K. DR.",
      party: "Independent",
      evm_votes: 649,
      postal_votes: 8,
      total_votes: 657,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 20,
      candidate: "MANIVASAGAM. C.",
      party: "Independent",
      evm_votes: 648,
      postal_votes: 3,
      total_votes: 651,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 21,
      candidate: "ASOKAN. G.",
      party: "Independent",
      evm_votes: 609,
      postal_votes: 2,
      total_votes: 611,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 22,
      candidate: "AYYAKANNU",
      party: "Independent",
      evm_votes: 583,
      postal_votes: 3,
      total_votes: 586,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 23,
      candidate: "JAGANATHAN. A.",
      party: "Independent",
      evm_votes: 551,
      postal_votes: 2,
      total_votes: 553,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 24,
      candidate: "MANI. S.",
      party: "Independent",
      evm_votes: 451,
      postal_votes: 1,
      total_votes: 452,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 25,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9093,
      postal_votes: 105,
      total_votes: 9198,
      percentage_of_votes: 0.74,
    },
  ],
  total: {
    evm_votes: 1238428,
    postal_votes: 9404,
    total_votes: 1247832,
  },
};
export const s2211 = {
  cs_name: "TIRUVANNAMALAI",
  cs_shortname: "tiruvannamalai",
  cs_code: "s2211",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "ANNADURAI, C.N.",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 543857,
      postal_votes: 3522,
      total_votes: 547379,
      percentage_of_votes: 47.75,
    },
    {
      serial_no: 2,
      candidate: "KALIYAPERUMAL M",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 311384,
      postal_votes: 2064,
      total_votes: 313448,
      percentage_of_votes: 27.34,
    },
    {
      serial_no: 3,
      candidate: "ASUVATHAMAN, A.",
      party: "Bharatiya Janata Party",
      evm_votes: 154736,
      postal_votes: 1914,
      total_votes: 156650,
      percentage_of_votes: 13.67,
    },
    {
      serial_no: 4,
      candidate: "RAMESHBABU, R.",
      party: "Naam Tamilar Katchi",
      evm_votes: 83148,
      postal_votes: 721,
      total_votes: 83869,
      percentage_of_votes: 7.32,
    },
    {
      serial_no: 5,
      candidate: "KALIAPERUMAL, M.",
      party: "Independent",
      evm_votes: 5704,
      postal_votes: 9,
      total_votes: 5713,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 6,
      candidate: "BENNYRAJAN, C.J.",
      party: "Bharatiya Praja Aikyata Party",
      evm_votes: 4703,
      postal_votes: 107,
      total_votes: 4810,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 7,
      candidate: "KALIYAPERUMAL, A.",
      party: "Independent",
      evm_votes: 3402,
      postal_votes: 5,
      total_votes: 3407,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 8,
      candidate: "MOHANRAJA, V.M.",
      party: "Bahujan Samaj Party",
      evm_votes: 2615,
      postal_votes: 22,
      total_votes: 2637,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 9,
      candidate: "CAPTAIN GOWTHAM",
      party: "Independent",
      evm_votes: 1823,
      postal_votes: 19,
      total_votes: 1842,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 10,
      candidate: "UDHAYAKUMAR, P.S.",
      party: "Independent",
      evm_votes: 1246,
      postal_votes: 5,
      total_votes: 1251,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 11,
      candidate: "ANNADURAI, S.",
      party: "Independent",
      evm_votes: 1165,
      postal_votes: 7,
      total_votes: 1172,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 12,
      candidate: "KOTHANDABANI, B.",
      party: "Independent",
      evm_votes: 963,
      postal_votes: 2,
      total_votes: 965,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "SELVAM , K.",
      party: "Veerath Thiyagi Viswanathadoss Thozhilalarkal Katchi",
      evm_votes: 945,
      postal_votes: 9,
      total_votes: 954,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "KALASTHIRI, S.",
      party: "All India Uzhavargal Uzhaippalargal Katchi",
      evm_votes: 934,
      postal_votes: 16,
      total_votes: 950,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "VIJAYAKUMAR, S.",
      party: "Independent",
      evm_votes: 928,
      postal_votes: 6,
      total_votes: 934,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 16,
      candidate: "JAGANNATHAN, R.",
      party: "Independent",
      evm_votes: 857,
      postal_votes: 7,
      total_votes: 864,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 17,
      candidate: "SENTHAMIL SELVAN, M.",
      party: "Independent",
      evm_votes: 848,
      postal_votes: 8,
      total_votes: 856,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "AGNI SELVARASU, J.",
      party: "Naadaalum Makkal Katchi",
      evm_votes: 759,
      postal_votes: 18,
      total_votes: 777,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 19,
      candidate: "SATHIYAMOORTHY, S.",
      party: "Makkal Nala Kazhagam",
      evm_votes: 716,
      postal_votes: 20,
      total_votes: 736,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 20,
      candidate: "ANNADURAI, S.",
      party: "Independent",
      evm_votes: 642,
      postal_votes: 6,
      total_votes: 648,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 21,
      candidate: "SUB MAJOR SETTU, M.",
      party: "Viro Ke Vir Indian Party",
      evm_votes: 636,
      postal_votes: 22,
      total_votes: 658,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 22,
      candidate: "SANKAR, S.",
      party: "Independent",
      evm_votes: 569,
      postal_votes: 9,
      total_votes: 578,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 23,
      candidate: "ANNADURAI, A.",
      party: "Independent",
      evm_votes: 482,
      postal_votes: 34,
      total_votes: 516,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 24,
      candidate: "NALLASIVAM, M.",
      party: "Independent",
      evm_votes: 426,
      postal_votes: 3,
      total_votes: 429,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 25,
      candidate: "PALANI , T.N.",
      party: "Independent",
      evm_votes: 413,
      postal_votes: 1,
      total_votes: 414,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 26,
      candidate: "POONGODY, A.",
      party: "Independent",
      evm_votes: 412,
      postal_votes: 5,
      total_votes: 417,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 27,
      candidate: "VIMAL, V.",
      party: "Independent",
      evm_votes: 341,
      postal_votes: 1,
      total_votes: 342,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 28,
      candidate: "THANGARAJ, P.",
      party: "Independent",
      evm_votes: 291,
      postal_votes: 4,
      total_votes: 295,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 29,
      candidate: "RAMESH , T.P.",
      party: "Independent",
      evm_votes: 280,
      postal_votes: 1,
      total_votes: 281,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 30,
      candidate: "NAKKEERAN , A.",
      party: "Independent",
      evm_votes: 260,
      postal_votes: 5,
      total_votes: 265,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 31,
      candidate: "DEEPAMMAL SUNDARI",
      party: "Independent",
      evm_votes: 252,
      postal_votes: 7,
      total_votes: 259,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 32,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11527,
      postal_votes: 430,
      total_votes: 11957,
      percentage_of_votes: 1.04,
    },
  ],
  total: {
    evm_votes: 1137264,
    postal_votes: 9009,
    total_votes: 1146273,
  },
};
export const s2212 = {
  cs_name: "ARANI",
  cs_shortname: "arani",
  cs_code: "s2212",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "THARANIVENTHAN M S",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 497083,
      postal_votes: 3016,
      total_votes: 500099,
      percentage_of_votes: 43.86,
    },
    {
      serial_no: 2,
      candidate: "GAJENDRAN, G.V.",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 289650,
      postal_votes: 1683,
      total_votes: 291333,
      percentage_of_votes: 25.55,
    },
    {
      serial_no: 3,
      candidate: "DR GANESH KUMAR A ME PHD",
      party: "Pattali Makkal Katchi",
      evm_votes: 234538,
      postal_votes: 2033,
      total_votes: 236571,
      percentage_of_votes: 20.75,
    },
    {
      serial_no: 4,
      candidate: "BAKIYALAKSHMI, K.",
      party: "Naam Tamilar Katchi",
      evm_votes: 65981,
      postal_votes: 759,
      total_votes: 66740,
      percentage_of_votes: 5.85,
    },
    {
      serial_no: 5,
      candidate: "MANIGANDAN, V.",
      party: "Independent",
      evm_votes: 4787,
      postal_votes: 21,
      total_votes: 4808,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 6,
      candidate: "GANESH KUMAR, U.",
      party: "Independent",
      evm_votes: 3809,
      postal_votes: 3,
      total_votes: 3812,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 7,
      candidate: "DURAI M",
      party: "Bahujan Samaj Party",
      evm_votes: 3635,
      postal_votes: 50,
      total_votes: 3685,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 8,
      candidate: "GAJENDRAN, J.",
      party: "Independent",
      evm_votes: 3393,
      postal_votes: 7,
      total_votes: 3400,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 9,
      candidate: "KARVANNAN, K.",
      party: "Independent",
      evm_votes: 3032,
      postal_votes: 4,
      total_votes: 3036,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 10,
      candidate: "GAJENDIRAN, E.",
      party: "Independent",
      evm_votes: 2612,
      postal_votes: 1,
      total_votes: 2613,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 11,
      candidate: "KOTTAI VENKATESAN, M.",
      party: "Independent",
      evm_votes: 1893,
      postal_votes: 15,
      total_votes: 1908,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "SENTHIL KUMAR, D.",
      party: "Independent",
      evm_votes: 1469,
      postal_votes: 6,
      total_votes: 1475,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 13,
      candidate: "SEKAR, N.",
      party: "Independent",
      evm_votes: 1397,
      postal_votes: 3,
      total_votes: 1400,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "SAKTHIVEL A",
      party: "Thakkam Katchi",
      evm_votes: 1174,
      postal_votes: 12,
      total_votes: 1186,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "GANESH, P.",
      party: "Independent",
      evm_votes: 978,
      postal_votes: 2,
      total_votes: 980,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 16,
      candidate: "NAGARAJAN P",
      party: "Veerath Thiyagi Viswanathadoss Thozhilalarkal Katchi",
      evm_votes: 921,
      postal_votes: 6,
      total_votes: 927,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 17,
      candidate: "CAPTAIN SETTU M",
      party: "Viro Ke Vir Indian Party",
      evm_votes: 881,
      postal_votes: 41,
      total_votes: 922,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 18,
      candidate: "JAISANKAR, M.",
      party: "Independent",
      evm_votes: 836,
      postal_votes: 4,
      total_votes: 840,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 19,
      candidate: "EZHIL ARASU M",
      party: "Independent",
      evm_votes: 741,
      postal_votes: 12,
      total_votes: 753,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 20,
      candidate: "BABU N",
      party: "Independent",
      evm_votes: 669,
      postal_votes: 5,
      total_votes: 674,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 21,
      candidate: "DURUGAN, M.",
      party: "United Republican Party of India",
      evm_votes: 596,
      postal_votes: 8,
      total_votes: 604,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 22,
      candidate: "MOHMMAD SIDDIQ M H",
      party: "Independent",
      evm_votes: 525,
      postal_votes: 3,
      total_votes: 528,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 23,
      candidate: "DHARANI, D.",
      party: "Independent",
      evm_votes: 466,
      postal_votes: 2,
      total_votes: 468,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 24,
      candidate: "MANAVALAN, A.",
      party: "Anna MGR Dravida Makkal Kalgam",
      evm_votes: 433,
      postal_votes: 47,
      total_votes: 480,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 25,
      candidate: "ARUL, M.",
      party: "Independent",
      evm_votes: 429,
      postal_votes: 81,
      total_votes: 510,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 26,
      candidate: "MURUGESAN, D.",
      party: "Independent",
      evm_votes: 398,
      postal_votes: 5,
      total_votes: 403,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 27,
      candidate: "ELLAPPAN, R.",
      party: "Independent",
      evm_votes: 375,
      postal_votes: 3,
      total_votes: 378,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 28,
      candidate: "FEROZKHAN, A.",
      party: "Independent",
      evm_votes: 295,
      postal_votes: 3,
      total_votes: 298,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 29,
      candidate: "DHAMODHARAN, G.",
      party: "Independent",
      evm_votes: 237,
      postal_votes: 5,
      total_votes: 242,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 30,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9084,
      postal_votes: 104,
      total_votes: 9188,
      percentage_of_votes: 0.81,
    },
  ],
  total: {
    evm_votes: 1132317,
    postal_votes: 7944,
    total_votes: 1140261,
  },
};
export const s2214 = {
  cs_name: "KALLAKURICHI",
  cs_shortname: "kallakurichi",
  cs_code: "s2214",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "MALAIYARASAN D",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 558346,
      postal_votes: 3243,
      total_votes: 561589,
      percentage_of_votes: 44.94,
    },
    {
      serial_no: 2,
      candidate: "KUMARAGURU R",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 505577,
      postal_votes: 2228,
      total_votes: 507805,
      percentage_of_votes: 40.64,
    },
    {
      serial_no: 3,
      candidate: "JAGADESAN A",
      party: "Naam Tamilar Katchi",
      evm_votes: 73098,
      postal_votes: 554,
      total_votes: 73652,
      percentage_of_votes: 5.89,
    },
    {
      serial_no: 4,
      candidate: "DEVADASS RAMASAMY",
      party: "Pattali Makkal Katchi",
      evm_votes: 70633,
      postal_votes: 657,
      total_votes: 71290,
      percentage_of_votes: 5.71,
    },
    {
      serial_no: 5,
      candidate: "PRABU C",
      party: "Independent",
      evm_votes: 4268,
      postal_votes: 7,
      total_votes: 4275,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 6,
      candidate: "BALAKRISHNAN P",
      party: "Independent",
      evm_votes: 3468,
      postal_votes: 1,
      total_votes: 3469,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 7,
      candidate: "JEEVANRAJ N",
      party: "Bahujan Samaj Party",
      evm_votes: 3075,
      postal_votes: 21,
      total_votes: 3096,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 8,
      candidate: "MAYILAMPARAI MARI A",
      party: "Independent",
      evm_votes: 2891,
      postal_votes: 1,
      total_votes: 2892,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 9,
      candidate: "NATTANMAI GUNASEKARANA A.S",
      party: "Anna Makkal Katchi",
      evm_votes: 2497,
      postal_votes: 8,
      total_votes: 2505,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 10,
      candidate: "KUMARAGURU N",
      party: "Independent",
      evm_votes: 2226,
      postal_votes: 1,
      total_votes: 2227,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 11,
      candidate: "MURUGESAN M",
      party: "Independent",
      evm_votes: 2223,
      postal_votes: 2,
      total_votes: 2225,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 12,
      candidate: "SUBRAMANIAN R K",
      party: "Independent",
      evm_votes: 868,
      postal_votes: 4,
      total_votes: 872,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "ARUL INIYAN A",
      party: "Independent",
      evm_votes: 772,
      postal_votes: 12,
      total_votes: 784,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 14,
      candidate: "VENKATRAMAN J",
      party: "Naadaalum Makkal Katchi",
      evm_votes: 687,
      postal_votes: 4,
      total_votes: 691,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "KAMALAKANNAN M",
      party: "Independent",
      evm_votes: 629,
      postal_votes: 1,
      total_votes: 630,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "GOVINDARAJ S R",
      party: "Independent",
      evm_votes: 533,
      postal_votes: 25,
      total_votes: 558,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 17,
      candidate: "PALANIYAMMAL S",
      party: "Desiya Makkal Sakthi Katchi",
      evm_votes: 533,
      postal_votes: 15,
      total_votes: 548,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 18,
      candidate: "SIGAMANI A",
      party: "Independent",
      evm_votes: 515,
      postal_votes: 27,
      total_votes: 542,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 19,
      candidate: "RAJASEKAR K",
      party: "Independent",
      evm_votes: 501,
      postal_votes: 10,
      total_votes: 511,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 20,
      candidate: "JAYABAL PM",
      party: "Independent",
      evm_votes: 473,
      postal_votes: 3,
      total_votes: 476,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 21,
      candidate: "RAJAMANICKAM C",
      party: "Independent",
      evm_votes: 398,
      postal_votes: 1,
      total_votes: 399,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8351,
      postal_votes: 181,
      total_votes: 8532,
      percentage_of_votes: 0.68,
    },
  ],
  total: {
    evm_votes: 1242562,
    postal_votes: 7006,
    total_votes: 1249568,
  },
};
export const s2215 = {
  cs_name: "SALEM",
  cs_shortname: "salem",
  cs_code: "s2215",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "SELVAGANAPATHI T M",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 562022,
      postal_votes: 4063,
      total_votes: 566085,
      percentage_of_votes: 43.38,
    },
    {
      serial_no: 2,
      candidate: "VIGNESH P",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 492958,
      postal_votes: 2770,
      total_votes: 495728,
      percentage_of_votes: 37.99,
    },
    {
      serial_no: 3,
      candidate: "ANNADURAI N",
      party: "Pattali Makkal Katchi",
      evm_votes: 125829,
      postal_votes: 1310,
      total_votes: 127139,
      percentage_of_votes: 9.74,
    },
    {
      serial_no: 4,
      candidate: "DR MANOJKUMAR G",
      party: "Naam Tamilar Katchi",
      evm_votes: 75636,
      postal_votes: 571,
      total_votes: 76207,
      percentage_of_votes: 5.84,
    },
    {
      serial_no: 5,
      candidate: "BHALAJI M",
      party: "Independent",
      evm_votes: 3437,
      postal_votes: 4,
      total_votes: 3441,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 6,
      candidate: "MURALI S",
      party: "Bahujan Samaj Party",
      evm_votes: 3333,
      postal_votes: 39,
      total_votes: 3372,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 7,
      candidate: "PALANIVEL K",
      party: "Independent",
      evm_votes: 2853,
      postal_votes: 3,
      total_votes: 2856,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 8,
      candidate: "JEGANNATHAN A",
      party: "Independent",
      evm_votes: 2384,
      postal_votes: 27,
      total_votes: 2411,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 9,
      candidate: "RAJA S",
      party: "Independent",
      evm_votes: 1442,
      postal_votes: 14,
      total_votes: 1456,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "MUTHUSAMY S",
      party: "Independent",
      evm_votes: 1193,
      postal_votes: 4,
      total_votes: 1197,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 11,
      candidate: "ER PRABAKARAN S",
      party: "Independent",
      evm_votes: 1151,
      postal_votes: 4,
      total_votes: 1155,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 12,
      candidate: "SHANMUGAM G",
      party: "Independent",
      evm_votes: 1144,
      postal_votes: 3,
      total_votes: 1147,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "SANTHALINGAM C",
      party: "Independent",
      evm_votes: 1079,
      postal_votes: 19,
      total_votes: 1098,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "SUTHARSANAM S",
      party: "Aravor Munnetra Kazhagam",
      evm_votes: 1030,
      postal_votes: 6,
      total_votes: 1036,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "AMBEDKAR C",
      party: "Ambedkarite Party of India",
      evm_votes: 944,
      postal_votes: 17,
      total_votes: 961,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 16,
      candidate: "RAMACHANDRAN C",
      party: "Dhesiya Makkal Kazhagam",
      evm_votes: 748,
      postal_votes: 10,
      total_votes: 758,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 17,
      candidate: "GOVINDHAN A",
      party: "Independent",
      evm_votes: 686,
      postal_votes: 7,
      total_votes: 693,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 18,
      candidate: "MANICKAM R",
      party: "Ulzaipali Makkal Katchy",
      evm_votes: 533,
      postal_votes: 4,
      total_votes: 537,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 19,
      candidate: "AHAMED SHAHJAHAN M",
      party: "Independent",
      evm_votes: 453,
      postal_votes: 13,
      total_votes: 466,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 20,
      candidate: "INTHRAJITHKUPTHA G",
      party: "Independent",
      evm_votes: 425,
      postal_votes: 7,
      total_votes: 432,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 21,
      candidate: "RAMASUNDARAM D",
      party: "Independent",
      evm_votes: 419,
      postal_votes: 3,
      total_votes: 422,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "KARUNAKARAN M",
      party: "Independent",
      evm_votes: 359,
      postal_votes: 18,
      total_votes: 377,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 23,
      candidate: "RAJA A",
      party: "Independent",
      evm_votes: 329,
      postal_votes: 3,
      total_votes: 332,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 24,
      candidate: "VIGNESH M",
      party: "Independent",
      evm_votes: 324,
      postal_votes: 4,
      total_votes: 328,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 25,
      candidate: "JOTHILINGAM M",
      party: "Independent",
      evm_votes: 314,
      postal_votes: 2,
      total_votes: 316,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 26,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 14677,
      postal_votes: 217,
      total_votes: 14894,
      percentage_of_votes: 1.14,
    },
  ],
  total: {
    evm_votes: 1295702,
    postal_votes: 9142,
    total_votes: 1304844,
  },
};
export const s2216 = {
  cs_name: "NAMAKKAL",
  cs_shortname: "namakkal",
  cs_code: "s2216",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "MATHESWARAN V S",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 457695,
      postal_votes: 4341,
      total_votes: 462036,
      percentage_of_votes: 40.31,
    },
    {
      serial_no: 2,
      candidate: "TAMILMANI S",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 429358,
      postal_votes: 3566,
      total_votes: 432924,
      percentage_of_votes: 37.77,
    },
    {
      serial_no: 3,
      candidate: "DR RAMALINGAM K P",
      party: "Bharatiya Janata Party",
      evm_votes: 103577,
      postal_votes: 1113,
      total_votes: 104690,
      percentage_of_votes: 9.13,
    },
    {
      serial_no: 4,
      candidate: "KANIMOZHI G",
      party: "Naam Tamilar Katchi",
      evm_votes: 94895,
      postal_votes: 682,
      total_votes: 95577,
      percentage_of_votes: 8.34,
    },
    {
      serial_no: 5,
      candidate: "DEEPAN CHAKKRAVARTHI S",
      party: "Independent",
      evm_votes: 3546,
      postal_votes: 33,
      total_votes: 3579,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 6,
      candidate: "RAJENDRAN C",
      party: "Viduthalai Kalam Katchi",
      evm_votes: 2592,
      postal_votes: 7,
      total_votes: 2599,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 7,
      candidate: "NATARAJAN M",
      party: "Independent",
      evm_votes: 2565,
      postal_votes: 23,
      total_votes: 2588,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 8,
      candidate: "RAMESH T",
      party: "Ahimsa Socialist Party",
      evm_votes: 2412,
      postal_votes: 9,
      total_votes: 2421,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 9,
      candidate: "EZHIL SELVAN P",
      party: "Tamilaga Makkal Thannurimai Katchi",
      evm_votes: 2149,
      postal_votes: 16,
      total_votes: 2165,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 10,
      candidate: "RAMAN V",
      party: "Bahujan Samaj Party",
      evm_votes: 2137,
      postal_votes: 20,
      total_votes: 2157,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 11,
      candidate: "UTHAYAKUMAR P",
      party: "Independent",
      evm_votes: 1899,
      postal_votes: 2,
      total_votes: 1901,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "KANDASAMY A M",
      party: "Independent",
      evm_votes: 1510,
      postal_votes: 4,
      total_votes: 1514,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 13,
      candidate: "RAMASAMY P",
      party: "Independent",
      evm_votes: 1338,
      postal_votes: 12,
      total_votes: 1350,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "KARTHI S",
      party: "Independent",
      evm_votes: 1332,
      postal_votes: 2,
      total_votes: 1334,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 15,
      candidate: "RAMESH T R",
      party: "Independent",
      evm_votes: 1317,
      postal_votes: 8,
      total_votes: 1325,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 16,
      candidate: "SATHEESH G",
      party: "Independent",
      evm_votes: 1284,
      postal_votes: 8,
      total_votes: 1292,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 17,
      candidate: "RAMASAMY N",
      party: "Independent",
      evm_votes: 1243,
      postal_votes: 7,
      total_votes: 1250,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 18,
      candidate: "MADHESWARAN V",
      party: "Independent",
      evm_votes: 1144,
      postal_votes: 3,
      total_votes: 1147,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 19,
      candidate: "DR GURUNATHAN S",
      party: "Independent",
      evm_votes: 1048,
      postal_votes: 23,
      total_votes: 1071,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 20,
      candidate: "KARTHIKEYAN S",
      party: "Independent",
      evm_votes: 918,
      postal_votes: 2,
      total_votes: 920,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 21,
      candidate: "NALLATHAMBI P",
      party: "Independent",
      evm_votes: 769,
      postal_votes: 1,
      total_votes: 770,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 22,
      candidate: "EASWARAMOORTHY M",
      party: "Independent",
      evm_votes: 758,
      postal_votes: 10,
      total_votes: 768,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 23,
      candidate: "BALAJICHEZHIAN P",
      party: "Independent",
      evm_votes: 716,
      postal_votes: 3,
      total_votes: 719,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 24,
      candidate: "RAVIKUMAR P",
      party: "Ganasangam Party of India",
      evm_votes: 633,
      postal_votes: 4,
      total_votes: 637,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 25,
      candidate: "NAVEEN K",
      party: "Independent",
      evm_votes: 633,
      postal_votes: 2,
      total_votes: 635,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 26,
      candidate: "DURAIRAJ C",
      party: "Independent",
      evm_votes: 590,
      postal_votes: 2,
      total_votes: 592,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 27,
      candidate: "ARULMANI M",
      party: "Independent",
      evm_votes: 564,
      postal_votes: 4,
      total_votes: 568,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 28,
      candidate: "THIYAGARAJAN R",
      party: "Samaniya Makkal Nala Katchi",
      evm_votes: 527,
      postal_votes: 1,
      total_votes: 528,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 29,
      candidate: "VENNILA S",
      party: "Independent",
      evm_votes: 520,
      postal_votes: 8,
      total_votes: 528,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 30,
      candidate: "KALIANNAN S",
      party: "Independent",
      evm_votes: 496,
      postal_votes: 1,
      total_votes: 497,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 31,
      candidate: "KANDASAMY P",
      party: "Independent",
      evm_votes: 475,
      postal_votes: 5,
      total_votes: 480,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 32,
      candidate: "MANIKKAM S",
      party: "Ulzaipali Makkal Katchy",
      evm_votes: 439,
      postal_votes: 3,
      total_votes: 442,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 33,
      candidate: "MOORTHI J",
      party: "Independent",
      evm_votes: 385,
      postal_votes: 4,
      total_votes: 389,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 34,
      candidate: "TAMILMANI A",
      party: "Independent",
      evm_votes: 352,
      postal_votes: 2,
      total_votes: 354,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 35,
      candidate: "SELVARAJ K R",
      party: "Independent",
      evm_votes: 301,
      postal_votes: 2,
      total_votes: 303,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 36,
      candidate: "SAKTHIVEL S",
      party: "Independent",
      evm_votes: 298,
      postal_votes: 1,
      total_votes: 299,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 37,
      candidate: "NETAJIKAVIARASU V",
      party: "Independent",
      evm_votes: 287,
      postal_votes: 1,
      total_votes: 288,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 38,
      candidate: "SIVAKUMAR V",
      party: "Independent",
      evm_votes: 244,
      postal_votes: 2,
      total_votes: 246,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 39,
      candidate: "MOORTHY V",
      party: "Independent",
      evm_votes: 220,
      postal_votes: 2,
      total_votes: 222,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 40,
      candidate: "GOPAL P",
      party: "Independent",
      evm_votes: 214,
      postal_votes: 0,
      total_votes: 214,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 41,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12736,
      postal_votes: 248,
      total_votes: 12984,
      percentage_of_votes: 1.13,
    },
  ],
  total: {
    evm_votes: 1136116,
    postal_votes: 10187,
    total_votes: 1146303,
  },
};
export const s2217 = {
  cs_name: "ERODE",
  cs_shortname: "erode",
  cs_code: "s2217",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "K E PRAKASH",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 558849,
      postal_votes: 3490,
      total_votes: 562339,
      percentage_of_votes: 51.43,
    },
    {
      serial_no: 2,
      candidate: "ASHOK KUMAR",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 323751,
      postal_votes: 2022,
      total_votes: 325773,
      percentage_of_votes: 29.79,
    },
    {
      serial_no: 3,
      candidate: "KARMEGAN M",
      party: "Naam Tamilar Katchi",
      evm_votes: 82332,
      postal_votes: 464,
      total_votes: 82796,
      percentage_of_votes: 7.57,
    },
    {
      serial_no: 4,
      candidate: "P VIJAYAKUMAR",
      party: "Tamil Maanila Congress (Moopanar)",
      evm_votes: 77319,
      postal_votes: 592,
      total_votes: 77911,
      percentage_of_votes: 7.13,
    },
    {
      serial_no: 5,
      candidate: "P ESWARAN",
      party: "Bahujan Samaj Party",
      evm_votes: 5161,
      postal_votes: 62,
      total_votes: 5223,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 6,
      candidate: "T V R AMIRTHALINGAM",
      party: "Independent",
      evm_votes: 4118,
      postal_votes: 31,
      total_votes: 4149,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 7,
      candidate: "KEERTHANA M",
      party: "Independent",
      evm_votes: 3775,
      postal_votes: 24,
      total_votes: 3799,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 8,
      candidate: "J GOPALAKRISHNAN",
      party: "Independent",
      evm_votes: 1949,
      postal_votes: 6,
      total_votes: 1955,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 9,
      candidate: "S ANANTHI",
      party: "Independent",
      evm_votes: 1683,
      postal_votes: 3,
      total_votes: 1686,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "PRASATH CHITRARASU",
      party: "Independent",
      evm_votes: 1629,
      postal_votes: 11,
      total_votes: 1640,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 11,
      candidate: "RAVICHANDRAN L",
      party: "Independent",
      evm_votes: 1378,
      postal_votes: 2,
      total_votes: 1380,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 12,
      candidate: "P RAJENDRAN",
      party: "Independent",
      evm_votes: 973,
      postal_votes: 3,
      total_votes: 976,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "P KUPPUSAMY",
      party: "Ulzaipali Makkal Katchy",
      evm_votes: 945,
      postal_votes: 5,
      total_votes: 950,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "M SHANMUGAM",
      party: "Independent",
      evm_votes: 768,
      postal_votes: 4,
      total_votes: 772,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 15,
      candidate: "R KUMARESAN",
      party: "Independent",
      evm_votes: 744,
      postal_votes: 7,
      total_votes: 751,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 16,
      candidate: "N ASHOK KUMAR",
      party: "Independent",
      evm_votes: 689,
      postal_votes: 1,
      total_votes: 690,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 17,
      candidate: "DHANALAKSHMI V",
      party: "Naadaalum Makkal Katchi",
      evm_votes: 675,
      postal_votes: 4,
      total_votes: 679,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 18,
      candidate: "A T MUNUSAMY",
      party: "Samaniya Makkal Nala Katchi",
      evm_votes: 611,
      postal_votes: 5,
      total_votes: 616,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 19,
      candidate: "K MAATHAN",
      party: "Ganasangam Party of India",
      evm_votes: 609,
      postal_votes: 9,
      total_votes: 618,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 20,
      candidate: "B DHARMARAJ",
      party: "Anti Corruption Dynamic Party",
      evm_votes: 597,
      postal_votes: 4,
      total_votes: 601,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 21,
      candidate: "ARUMUGA AC KANNAN",
      party: "Independent",
      evm_votes: 547,
      postal_votes: 2,
      total_votes: 549,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 22,
      candidate: "M SABARINATHAN",
      party: "Independent",
      evm_votes: 525,
      postal_votes: 6,
      total_votes: 531,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 23,
      candidate: "R DHANDAPANI",
      party: "Veerath Thiyagi Viswanathadoss Thozhilalarkal Katchi",
      evm_votes: 502,
      postal_votes: 2,
      total_votes: 504,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 24,
      candidate: "R KUMAR",
      party: "Anna Puratchi Thalaivar Amma Dravida Munnetra Kazhagam",
      evm_votes: 424,
      postal_votes: 8,
      total_votes: 432,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 25,
      candidate: "R.MINNALMURUGESH",
      party: "Independent",
      evm_votes: 413,
      postal_votes: 2,
      total_votes: 415,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 26,
      candidate: "K K VADUGANATHAN",
      party: "Independent",
      evm_votes: 398,
      postal_votes: 1,
      total_votes: 399,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 27,
      candidate: "G MYLSAMY",
      party: "Independent",
      evm_votes: 301,
      postal_votes: 0,
      total_votes: 301,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 28,
      candidate: "MYLVAKANAN D -",
      party: "Independent",
      evm_votes: 276,
      postal_votes: 3,
      total_votes: 279,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 29,
      candidate: "M PRABAKARAN",
      party: "Independent",
      evm_votes: 274,
      postal_votes: 2,
      total_votes: 276,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 30,
      candidate: "NARENDRANATH",
      party: "Independent",
      evm_votes: 272,
      postal_votes: 0,
      total_votes: 272,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 31,
      candidate: "K SENTHIL KUMAR",
      party: "Independent",
      evm_votes: 177,
      postal_votes: 1,
      total_votes: 178,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 32,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13742,
      postal_votes: 241,
      total_votes: 13983,
      percentage_of_votes: 1.28,
    },
  ],
  total: {
    evm_votes: 1086406,
    postal_votes: 7017,
    total_votes: 1093423,
  },
};
export const s2219 = {
  cs_name: "NILGIRIS",
  cs_shortname: "nilgiris",
  cs_code: "s2219",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "RAJA A",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 470729,
      postal_votes: 2483,
      total_votes: 473212,
      percentage_of_votes: 46.44,
    },
    {
      serial_no: 2,
      candidate: "DR MURUGAN L",
      party: "Bharatiya Janata Party",
      evm_votes: 231111,
      postal_votes: 1516,
      total_votes: 232627,
      percentage_of_votes: 22.83,
    },
    {
      serial_no: 3,
      candidate: "LOGESH TAMILSELVAN D",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 219417,
      postal_votes: 813,
      total_votes: 220230,
      percentage_of_votes: 21.61,
    },
    {
      serial_no: 4,
      candidate: "JAYAKUMAR A",
      party: "Naam Tamilar Katchi",
      evm_votes: 58588,
      postal_votes: 233,
      total_votes: 58821,
      percentage_of_votes: 5.77,
    },
    {
      serial_no: 5,
      candidate: "VIJAYAKUMAR D",
      party: "Independent",
      evm_votes: 4805,
      postal_votes: 3,
      total_votes: 4808,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 6,
      candidate: "GANESHAMOORTHI A",
      party: "Bahujan Samaj Party",
      evm_votes: 3015,
      postal_votes: 18,
      total_votes: 3033,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 7,
      candidate: "BADRAN M",
      party: "Ganasangam Party of India",
      evm_votes: 2406,
      postal_votes: 19,
      total_votes: 2425,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 8,
      candidate: "SELVAN N",
      party: "Independent",
      evm_votes: 2038,
      postal_votes: 16,
      total_votes: 2054,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 9,
      candidate: "DHANABAL R",
      party: "Independent",
      evm_votes: 1906,
      postal_votes: 22,
      total_votes: 1928,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 10,
      candidate: "JAYANTHI M",
      party: "Ambedkarite Party of India",
      evm_votes: 1539,
      postal_votes: 17,
      total_votes: 1556,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 11,
      candidate: "MURUGAN Y",
      party: "Independent",
      evm_votes: 1408,
      postal_votes: 5,
      total_votes: 1413,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 12,
      candidate: "MURUGESAN",
      party: "Independent",
      evm_votes: 1057,
      postal_votes: 2,
      total_votes: 1059,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 13,
      candidate: "MALARMANNAN M",
      party: "Samaniya Makkal Nala Katchi",
      evm_votes: 813,
      postal_votes: 7,
      total_votes: 820,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "SATHISH M",
      party: "Independent",
      evm_votes: 734,
      postal_votes: 6,
      total_votes: 740,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 15,
      candidate: "KRISHNA KUMAR",
      party: "Independent",
      evm_votes: 588,
      postal_votes: 27,
      total_votes: 615,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 16,
      candidate: "ANBHUGURU V",
      party: "Independent",
      evm_votes: 560,
      postal_votes: 5,
      total_votes: 565,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 17,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12902,
      postal_votes: 98,
      total_votes: 13000,
      percentage_of_votes: 1.28,
    },
  ],
  total: {
    evm_votes: 1013616,
    postal_votes: 5290,
    total_votes: 1018906,
  },
};
export const s2220 = {
  cs_name: "COIMBATORE",
  cs_shortname: "coimbatore",
  cs_code: "s2220",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "GANAPATHY RAJKUMAR P",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 565428,
      postal_votes: 2772,
      total_votes: 568200,
      percentage_of_votes: 41.39,
    },
    {
      serial_no: 2,
      candidate: "ANNAMALAI K",
      party: "Bharatiya Janata Party",
      evm_votes: 447608,
      postal_votes: 2524,
      total_votes: 450132,
      percentage_of_votes: 32.79,
    },
    {
      serial_no: 3,
      candidate: "SINGAI G RAMACHANDRAN",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 235603,
      postal_votes: 887,
      total_votes: 236490,
      percentage_of_votes: 17.23,
    },
    {
      serial_no: 4,
      candidate: "KALAMANI JAGANATHAN",
      party: "Naam Tamilar Katchi",
      evm_votes: 82387,
      postal_votes: 270,
      total_votes: 82657,
      percentage_of_votes: 6.02,
    },
    {
      serial_no: 5,
      candidate: "VELMURUGAN K",
      party: "Bahujan Samaj Party",
      evm_votes: 2364,
      postal_votes: 11,
      total_votes: 2375,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 6,
      candidate: "SANJAYKUMAR S",
      party: "Independent",
      evm_votes: 1787,
      postal_votes: 2,
      total_votes: 1789,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 7,
      candidate: "ANNADURAI R",
      party: "Independent",
      evm_votes: 1767,
      postal_votes: 5,
      total_votes: 1772,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 8,
      candidate: "SURESH M",
      party: "Independent",
      evm_votes: 1472,
      postal_votes: 2,
      total_votes: 1474,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 9,
      candidate: "GOPALAKRISHNAN S",
      party: "Independent",
      evm_votes: 1196,
      postal_votes: 3,
      total_votes: 1199,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 10,
      candidate: "RAGHUL GANDHI K",
      party: "Hindustan Janta Party",
      evm_votes: 1170,
      postal_votes: 6,
      total_votes: 1176,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 11,
      candidate: "KARTHICK T",
      party: "Independent",
      evm_votes: 1070,
      postal_votes: 3,
      total_votes: 1073,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "RAJKUMAR M",
      party: "Independent",
      evm_votes: 1067,
      postal_votes: 1,
      total_votes: 1068,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "RAJKUMAR",
      party: "Independent",
      evm_votes: 1010,
      postal_votes: 3,
      total_votes: 1013,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 14,
      candidate: "RAJKUMAR G",
      party: "Independent",
      evm_votes: 888,
      postal_votes: 4,
      total_votes: 892,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "SHANMUGAM K",
      party: "Independent",
      evm_votes: 881,
      postal_votes: 1,
      total_votes: 882,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 16,
      candidate: "KRISHNAN V",
      party: "Independent",
      evm_votes: 722,
      postal_votes: 4,
      total_votes: 726,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 17,
      candidate: "RAMACHANDRAN M",
      party: "Independent",
      evm_votes: 663,
      postal_votes: 0,
      total_votes: 663,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 18,
      candidate: "RAJAMANICKAM N",
      party: "Independent",
      evm_votes: 626,
      postal_votes: 3,
      total_votes: 629,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 19,
      candidate: "RAJKUMAR G B",
      party: "Independent",
      evm_votes: 607,
      postal_votes: 4,
      total_votes: 611,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 20,
      candidate: "KUMAR A",
      party: "Ganasangam Party of India",
      evm_votes: 548,
      postal_votes: 3,
      total_votes: 551,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 21,
      candidate: "SANGEETHA R",
      party: "Naadaalum Makkal Katchi",
      evm_votes: 491,
      postal_votes: 1,
      total_votes: 492,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 22,
      candidate: "RICHARD VINCENT",
      party: "Viro Ke Vir Indian Party",
      evm_votes: 489,
      postal_votes: 3,
      total_votes: 492,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 23,
      candidate: "SURIYAKUMAR K",
      party: "Independent",
      evm_votes: 443,
      postal_votes: 4,
      total_votes: 447,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 24,
      candidate: "ARUNKANTH V",
      party: "Independent",
      evm_votes: 432,
      postal_votes: 6,
      total_votes: 438,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 25,
      candidate: "ANANDHAKUMAR S",
      party: "Rashtriya Samaj Paksha",
      evm_votes: 417,
      postal_votes: 2,
      total_votes: 419,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 26,
      candidate: "RAMACHANDRAN R",
      party: "Independent",
      evm_votes: 368,
      postal_votes: 1,
      total_votes: 369,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 27,
      candidate: "MUTHUSAMY P P",
      party: "Independent",
      evm_votes: 368,
      postal_votes: 1,
      total_votes: 369,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 28,
      candidate: "PALANISAMYRAAJ M",
      party: "Independent",
      evm_votes: 313,
      postal_votes: 3,
      total_votes: 316,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 29,
      candidate: "PUSHPANANTHAM V",
      party: "Tamizhaga Murpokku Makkal Katchi",
      evm_votes: 303,
      postal_votes: 2,
      total_votes: 305,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 30,
      candidate: "DINESH K",
      party: "Independent",
      evm_votes: 284,
      postal_votes: 1,
      total_votes: 285,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 31,
      candidate: "PREMKUMAR P",
      party: "Independent",
      evm_votes: 281,
      postal_votes: 2,
      total_votes: 283,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 32,
      candidate: "RAVICHANDRAN",
      party: "Independent",
      evm_votes: 271,
      postal_votes: 1,
      total_votes: 272,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 33,
      candidate: "DURAISAMY A",
      party: "Independent",
      evm_votes: 263,
      postal_votes: 3,
      total_votes: 266,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 34,
      candidate: "ZAHIR HUSSAIN",
      party: "Independent",
      evm_votes: 259,
      postal_votes: 1,
      total_votes: 260,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 35,
      candidate: "BOOBALAN",
      party: "Independent",
      evm_votes: 252,
      postal_votes: 1,
      total_votes: 253,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 36,
      candidate: "NOORMUHAMAD A",
      party: "Independent",
      evm_votes: 232,
      postal_votes: 2,
      total_votes: 234,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 37,
      candidate: "PASUPATHI S",
      party: "Independent",
      evm_votes: 170,
      postal_votes: 3,
      total_votes: 173,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 38,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11677,
      postal_votes: 111,
      total_votes: 11788,
      percentage_of_votes: 0.86,
    },
  ],
  total: {
    evm_votes: 1366177,
    postal_votes: 6656,
    total_votes: 1372833,
  },
};
export const s2221 = {
  cs_name: "POLLACHI",
  cs_shortname: "pollachi",
  cs_code: "s2221",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "ESWARASAMY K",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 530742,
      postal_votes: 2635,
      total_votes: 533377,
      percentage_of_votes: 47.37,
    },
    {
      serial_no: 2,
      candidate: "KARTHIKEYAN A",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 280230,
      postal_votes: 1105,
      total_votes: 281335,
      percentage_of_votes: 24.98,
    },
    {
      serial_no: 3,
      candidate: "VASANTHARAJAN K",
      party: "Bharatiya Janata Party",
      evm_votes: 221945,
      postal_votes: 1409,
      total_votes: 223354,
      percentage_of_votes: 19.84,
    },
    {
      serial_no: 4,
      candidate: "SURESHKUMAR N",
      party: "Naam Tamilar Katchi",
      evm_votes: 57962,
      postal_votes: 234,
      total_votes: 58196,
      percentage_of_votes: 5.17,
    },
    {
      serial_no: 5,
      candidate: "PRAKASH P",
      party: "Independent",
      evm_votes: 2834,
      postal_votes: 17,
      total_votes: 2851,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 6,
      candidate: "BENJAMINKIRUBAKARAN J",
      party: "Bahujan Samaj Party",
      evm_votes: 2267,
      postal_votes: 17,
      total_votes: 2284,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 7,
      candidate: "VASANTHAKUMAR T",
      party: "Independent",
      evm_votes: 2186,
      postal_votes: 4,
      total_votes: 2190,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 8,
      candidate: "RAMASAMY K",
      party: "Independent",
      evm_votes: 1816,
      postal_votes: 9,
      total_votes: 1825,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "ESWARASAMY K",
      party: "Independent",
      evm_votes: 1775,
      postal_votes: 15,
      total_votes: 1790,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 10,
      candidate: "GOPALAKRISHNAN M",
      party: "New Generation People’s Party",
      evm_votes: 1127,
      postal_votes: 6,
      total_votes: 1133,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "KALIMUTHU S",
      party: "Independent",
      evm_votes: 976,
      postal_votes: 5,
      total_votes: 981,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 12,
      candidate: "KARTHIKEYAN D",
      party: "Independent",
      evm_votes: 600,
      postal_votes: 19,
      total_votes: 619,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "NOOR MUHAMAD A",
      party: "Independent",
      evm_votes: 599,
      postal_votes: 6,
      total_votes: 605,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "KARTHIKEYAN P",
      party: "Independent",
      evm_votes: 548,
      postal_votes: 19,
      total_votes: 567,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "KARTHIKEYAN D",
      party: "Independent",
      evm_votes: 433,
      postal_votes: 2,
      total_votes: 435,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 14399,
      postal_votes: 104,
      total_votes: 14503,
      percentage_of_votes: 1.29,
    },
  ],
  total: {
    evm_votes: 1120439,
    postal_votes: 5606,
    total_votes: 1126045,
  },
};
export const s2225 = {
  cs_name: "PERAMBALUR",
  cs_shortname: "perambalur",
  cs_code: "s2225",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "ARUN NEHRU",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 598003,
      postal_votes: 5206,
      total_votes: 603209,
      percentage_of_votes: 53.42,
    },
    {
      serial_no: 2,
      candidate: "CHANDRAMOHAN N D",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 212495,
      postal_votes: 1607,
      total_votes: 214102,
      percentage_of_votes: 18.96,
    },
    {
      serial_no: 3,
      candidate: "PAARIVENDHAR T R",
      party: "Bharatiya Janata Party",
      evm_votes: 160606,
      postal_votes: 1260,
      total_votes: 161866,
      percentage_of_votes: 14.33,
    },
    {
      serial_no: 4,
      candidate: "THENMOZHI R",
      party: "Naam Tamilar Katchi",
      evm_votes: 112200,
      postal_votes: 892,
      total_votes: 113092,
      percentage_of_votes: 10.02,
    },
    {
      serial_no: 5,
      candidate: "MANI S",
      party: "Independent",
      evm_votes: 3950,
      postal_votes: 1,
      total_votes: 3951,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 6,
      candidate: "MADHU R",
      party: "Independent",
      evm_votes: 3498,
      postal_votes: 10,
      total_votes: 3508,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 7,
      candidate: "RENGARAJ S",
      party: "Independent",
      evm_votes: 3251,
      postal_votes: 22,
      total_votes: 3273,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 8,
      candidate: "ELANGOVAN R",
      party: "Bahujan Samaj Party",
      evm_votes: 3033,
      postal_votes: 26,
      total_votes: 3059,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 9,
      candidate: "VASUDEVAN A",
      party: "Independent",
      evm_votes: 1355,
      postal_votes: 7,
      total_votes: 1362,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 10,
      candidate: "ARUN NEHRU",
      party: "Independent",
      evm_votes: 1280,
      postal_votes: 19,
      total_votes: 1299,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 11,
      candidate: "LAKSHMANAN",
      party: "Independent",
      evm_votes: 1087,
      postal_votes: 0,
      total_votes: 1087,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 12,
      candidate: "PARY T",
      party: "Independent",
      evm_votes: 1077,
      postal_votes: 4,
      total_votes: 1081,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 13,
      candidate: "ANANDARAJU R",
      party: "Independent",
      evm_votes: 1009,
      postal_votes: 6,
      total_votes: 1015,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "JAYAKUMAR K",
      party: "Samaniya Makkal Nala Katchi",
      evm_votes: 1003,
      postal_votes: 3,
      total_votes: 1006,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 15,
      candidate: "TAMILSELVAN M",
      party: "Independent",
      evm_votes: 872,
      postal_votes: 6,
      total_votes: 878,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 16,
      candidate: "THANGAMANI K",
      party: "Independent",
      evm_votes: 868,
      postal_votes: 5,
      total_votes: 873,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 17,
      candidate: "P K AMMAN G SIVAKUMAR",
      party: "Independent",
      evm_votes: 740,
      postal_votes: 15,
      total_votes: 755,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "EBINESAN M",
      party: "Independent",
      evm_votes: 731,
      postal_votes: 17,
      total_votes: 748,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 19,
      candidate: "MURUGANANTHAM T",
      party: "Independent",
      evm_votes: 730,
      postal_votes: 3,
      total_votes: 733,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 20,
      candidate: "SUDHAKAR S",
      party: "Independent",
      evm_votes: 550,
      postal_votes: 5,
      total_votes: 555,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 21,
      candidate: "SAMBATH D",
      party: "Independent",
      evm_votes: 530,
      postal_votes: 2,
      total_votes: 532,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 22,
      candidate: "MUTHUKUMAR",
      party: "Independent",
      evm_votes: 467,
      postal_votes: 2,
      total_votes: 469,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 23,
      candidate: "VEERAMALAI K",
      party: "Independent",
      evm_votes: 449,
      postal_votes: 2,
      total_votes: 451,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 24,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10075,
      postal_votes: 247,
      total_votes: 10322,
      percentage_of_votes: 0.91,
    },
  ],
  total: {
    evm_votes: 1119859,
    postal_votes: 9367,
    total_votes: 1129226,
  },
};
export const s2230 = {
  cs_name: "THANJAVUR",
  cs_shortname: "thanjavur",
  cs_code: "s2230",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "MURASOLI S",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 498822,
      postal_votes: 3423,
      total_votes: 502245,
      percentage_of_votes: 48.82,
    },
    {
      serial_no: 2,
      candidate: "SIVANESAN P",
      party: "Desiya Murpokku Dravida Kazhagam",
      evm_votes: 182172,
      postal_votes: 490,
      total_votes: 182662,
      percentage_of_votes: 17.76,
    },
    {
      serial_no: 3,
      candidate: "MURUGANANTHAM M",
      party: "Bharatiya Janata Party",
      evm_votes: 169587,
      postal_votes: 1026,
      total_votes: 170613,
      percentage_of_votes: 16.59,
    },
    {
      serial_no: 4,
      candidate: "HUMAYUN KABIR",
      party: "Naam Tamilar Katchi",
      evm_votes: 119683,
      postal_votes: 610,
      total_votes: 120293,
      percentage_of_votes: 11.69,
    },
    {
      serial_no: 5,
      candidate: "RENGASAMY C",
      party: "Independent",
      evm_votes: 18130,
      postal_votes: 61,
      total_votes: 18191,
      percentage_of_votes: 1.77,
    },
    {
      serial_no: 6,
      candidate: "SARAVANAN M",
      party: "Independent",
      evm_votes: 6730,
      postal_votes: 65,
      total_votes: 6795,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 7,
      candidate: "SENTHIL KUMAR N",
      party: "Independent",
      evm_votes: 4133,
      postal_votes: 25,
      total_votes: 4158,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 8,
      candidate: "EZHILARASAN S",
      party: "Independent",
      evm_votes: 3834,
      postal_votes: 17,
      total_votes: 3851,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 9,
      candidate: "JAYABAL A",
      party: "Bahujan Samaj Party",
      evm_votes: 2400,
      postal_votes: 10,
      total_votes: 2410,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 10,
      candidate: "SANTHOSH M",
      party: "Independent",
      evm_votes: 1982,
      postal_votes: 10,
      total_votes: 1992,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 11,
      candidate: "ER ARJUN S",
      party: "Independent",
      evm_votes: 1440,
      postal_votes: 6,
      total_votes: 1446,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 12,
      candidate: "KARIKALA CHOLAN S",
      party: "Independent",
      evm_votes: 1209,
      postal_votes: 8,
      total_votes: 1217,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12674,
      postal_votes: 159,
      total_votes: 12833,
      percentage_of_votes: 1.25,
    },
  ],
  total: {
    evm_votes: 1022796,
    postal_votes: 5910,
    total_votes: 1028706,
  },
};
export const s2233 = {
  cs_name: "THENI",
  cs_shortname: "theni",
  cs_code: "s2233",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "THANGA TAMILSELVAN",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 569110,
      postal_votes: 2383,
      total_votes: 571493,
      percentage_of_votes: 50.08,
    },
    {
      serial_no: 2,
      candidate: "TTV DHINAKARAN",
      party: "Amma Makkal Munnettra Kazagam",
      evm_votes: 289909,
      postal_votes: 2759,
      total_votes: 292668,
      percentage_of_votes: 25.65,
    },
    {
      serial_no: 3,
      candidate: "NARAYANASAMY V T",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 154871,
      postal_votes: 716,
      total_votes: 155587,
      percentage_of_votes: 13.63,
    },
    {
      serial_no: 4,
      candidate: "MADHAN",
      party: "Naam Tamilar Katchi",
      evm_votes: 75495,
      postal_votes: 1339,
      total_votes: 76834,
      percentage_of_votes: 6.73,
    },
    {
      serial_no: 5,
      candidate: "PRAKASH P",
      party: "Independent",
      evm_votes: 6293,
      postal_votes: 5,
      total_votes: 6298,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 6,
      candidate: "PANDIKUMAR S",
      party: "Independent",
      evm_votes: 3907,
      postal_votes: 60,
      total_votes: 3967,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 7,
      candidate: "AJEETHKUMAR S",
      party: "Independent",
      evm_votes: 2977,
      postal_votes: 29,
      total_votes: 3006,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 8,
      candidate: "PARAMASIVAN S",
      party: "Independent",
      evm_votes: 2852,
      postal_votes: 14,
      total_votes: 2866,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 9,
      candidate: "PARTHIBAN G",
      party: "Independent",
      evm_votes: 2275,
      postal_votes: 2,
      total_votes: 2277,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 10,
      candidate: "JEEVA M",
      party: "Bahujan Samaj Party",
      evm_votes: 2123,
      postal_votes: 99,
      total_votes: 2222,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 11,
      candidate: "CHARCHIL DURAI P",
      party: "Humanity for Peace Party",
      evm_votes: 1905,
      postal_votes: 30,
      total_votes: 1935,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "PREMA S",
      party: "Independent",
      evm_votes: 1225,
      postal_votes: 7,
      total_votes: 1232,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 13,
      candidate: "MANIKANDAN R",
      party: "Independent",
      evm_votes: 1195,
      postal_votes: 6,
      total_votes: 1201,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 14,
      candidate: "REVATHI K",
      party: "Independent",
      evm_votes: 1115,
      postal_votes: 13,
      total_votes: 1128,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "MUDIYARASU",
      party: "All India Youth Development Party",
      evm_votes: 1078,
      postal_votes: 16,
      total_votes: 1094,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 16,
      candidate: "THIAGARAJAN O V",
      party: "Independent",
      evm_votes: 924,
      postal_votes: 8,
      total_votes: 932,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 17,
      candidate: "VASANTHA SARAVANAN",
      party: "Independent",
      evm_votes: 803,
      postal_votes: 2,
      total_votes: 805,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "VIJAYAN K",
      party: "Independent",
      evm_votes: 665,
      postal_votes: 2,
      total_votes: 667,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 19,
      candidate: "SETHUPATHI G",
      party: "Independent",
      evm_votes: 613,
      postal_votes: 4,
      total_votes: 617,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 20,
      candidate: "JOHNGILBERTRAJ",
      party: "Independent",
      evm_votes: 598,
      postal_votes: 5,
      total_votes: 603,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 21,
      candidate: "AATHIMUTHUKUMAR M",
      party: "Independent",
      evm_votes: 587,
      postal_votes: 5,
      total_votes: 592,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 22,
      candidate: "MUTHUKUMAR",
      party: "Independent",
      evm_votes: 539,
      postal_votes: 4,
      total_votes: 543,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 23,
      candidate: "HARIKRISHNAKUMAR R",
      party: "Independent",
      evm_votes: 481,
      postal_votes: 5,
      total_votes: 486,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 24,
      candidate: "SATHEESH KUMAR A",
      party: "Independent",
      evm_votes: 447,
      postal_votes: 6,
      total_votes: 453,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 25,
      candidate: "KUMAR",
      party: "Independent",
      evm_votes: 376,
      postal_votes: 1,
      total_votes: 377,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 26,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11206,
      postal_votes: 130,
      total_votes: 11336,
      percentage_of_votes: 0.99,
    },
  ],
  total: {
    evm_votes: 1133569,
    postal_votes: 7650,
    total_votes: 1141219,
  },
};
export const s2236 = {
  cs_name: "THOOTHUKKUDI",
  cs_shortname: "thoothukkudi",
  cs_code: "s2236",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "KANIMOZHI KARUNANIDHI",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 537879,
      postal_votes: 2850,
      total_votes: 540729,
      percentage_of_votes: 55.26,
    },
    {
      serial_no: 2,
      candidate: "SIVASAMY VELUMANI R",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 147407,
      postal_votes: 584,
      total_votes: 147991,
      percentage_of_votes: 15.12,
    },
    {
      serial_no: 3,
      candidate: "VIJAYASEELAN SDR",
      party: "Tamil Maanila Congress (Moopanar)",
      evm_votes: 121680,
      postal_votes: 700,
      total_votes: 122380,
      percentage_of_votes: 12.51,
    },
    {
      serial_no: 4,
      candidate: "ROWENA RUTH JANE J",
      party: "Naam Tamilar Katchi",
      evm_votes: 119374,
      postal_votes: 926,
      total_votes: 120300,
      percentage_of_votes: 12.29,
    },
    {
      serial_no: 5,
      candidate: "RAJHA N P",
      party: "Naam Indiar Party",
      evm_votes: 6622,
      postal_votes: 18,
      total_votes: 6640,
      percentage_of_votes: 0.68,
    },
    {
      serial_no: 6,
      candidate: "ARUNADEVI R",
      party: "Independent",
      evm_votes: 6029,
      postal_votes: 43,
      total_votes: 6072,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 7,
      candidate: "CHITHIRAI JEGAN S",
      party: "Independent",
      evm_votes: 3637,
      postal_votes: 3,
      total_votes: 3640,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 8,
      candidate: "SIVANESWARAN J",
      party: "Independent",
      evm_votes: 3363,
      postal_votes: 13,
      total_votes: 3376,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 9,
      candidate: "SAMUEL T",
      party: "Independent",
      evm_votes: 2240,
      postal_votes: 5,
      total_votes: 2245,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 10,
      candidate: "MANIKKARAJ A",
      party: "Bahujan Samaj Party",
      evm_votes: 2158,
      postal_votes: 37,
      total_votes: 2195,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 11,
      candidate: "AASIRIYAR SHANMUGA SUNDARAM K",
      party: "Independent",
      evm_votes: 1832,
      postal_votes: 7,
      total_votes: 1839,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 12,
      candidate: "KALEERMURUGAPAVENTHAN R",
      party: "Makkal Nalvaazhvuk Katchi",
      evm_votes: 1736,
      postal_votes: 56,
      total_votes: 1792,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 13,
      candidate: "PONKUMARAN T P S",
      party: "Independent",
      evm_votes: 894,
      postal_votes: 3,
      total_votes: 897,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "JEYAKUMAR C",
      party: "Independent",
      evm_votes: 825,
      postal_votes: 4,
      total_votes: 829,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "GANDHIMALLAR S M",
      party: "Independent",
      evm_votes: 791,
      postal_votes: 14,
      total_votes: 805,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 16,
      candidate: "BISHOP DR GODFREY NOBLE",
      party: "Aanaithinthiya Jananayaka Pathukappu Kazhagam",
      evm_votes: 757,
      postal_votes: 12,
      total_votes: 769,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 17,
      candidate: "PERUMALKUMAR P",
      party: "Puthiya Makkal Tamil Desam Katchi",
      evm_votes: 727,
      postal_votes: 12,
      total_votes: 739,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 18,
      candidate: "ESAKKIMUTHU V",
      party: "Independent",
      evm_votes: 699,
      postal_votes: 11,
      total_votes: 710,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 19,
      candidate: "KANNAN G",
      party: "Independent",
      evm_votes: 679,
      postal_votes: 10,
      total_votes: 689,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 20,
      candidate: "DAVID JEBASEELAN J",
      party: "Independent",
      evm_votes: 656,
      postal_votes: 7,
      total_votes: 663,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 21,
      candidate: "SUDALAIMUTHU P",
      party: "Independent",
      evm_votes: 576,
      postal_votes: 2,
      total_votes: 578,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 22,
      candidate: "KRISHNAN S",
      party: "Independent",
      evm_votes: 479,
      postal_votes: 1,
      total_votes: 480,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 23,
      candidate: "RADHAKRISHNAN V G",
      party: "Independent",
      evm_votes: 456,
      postal_votes: 5,
      total_votes: 461,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 24,
      candidate: "JAMES P",
      party: "Independent",
      evm_votes: 433,
      postal_votes: 11,
      total_votes: 444,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 25,
      candidate: "PONRAJ K",
      party: "Independent",
      evm_votes: 428,
      postal_votes: 1,
      total_votes: 429,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 26,
      candidate: "PRASANNA KUMAR M",
      party: "Independent",
      evm_votes: 396,
      postal_votes: 0,
      total_votes: 396,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 27,
      candidate: "SELVAMUTHUKUMAR M",
      party: "Independent",
      evm_votes: 341,
      postal_votes: 0,
      total_votes: 341,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 28,
      candidate: "SENTHIL KUMAR S",
      party: "Independent",
      evm_votes: 290,
      postal_votes: 1,
      total_votes: 291,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 29,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9682,
      postal_votes: 124,
      total_votes: 9806,
      percentage_of_votes: 1,
    },
  ],
  total: {
    evm_votes: 973066,
    postal_votes: 5460,
    total_votes: 978526,
  },
};
export const s2237 = {
  cs_name: "TENKASI",
  cs_shortname: "tenkasi",
  cs_code: "s2237",
  winner: "DMK",
  stats: [
    {
      serial_no: 1,
      candidate: "DR RANI SRI KUMAR",
      party: "Dravida Munnetra Kazhagam",
      evm_votes: 423025,
      postal_votes: 2654,
      total_votes: 425679,
      percentage_of_votes: 40.97,
    },
    {
      serial_no: 2,
      candidate: "DR K KRISHNASAMY",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 228290,
      postal_votes: 1190,
      total_votes: 229480,
      percentage_of_votes: 22.08,
    },
    {
      serial_no: 3,
      candidate: "B JOHN PANDIAN",
      party: "Bharatiya Janata Party",
      evm_votes: 206982,
      postal_votes: 1843,
      total_votes: 208825,
      percentage_of_votes: 20.1,
    },
    {
      serial_no: 4,
      candidate: "ESAI MATHIVANAN",
      party: "Naam Tamilar Katchi",
      evm_votes: 128750,
      postal_votes: 1585,
      total_votes: 130335,
      percentage_of_votes: 12.54,
    },
    {
      serial_no: 5,
      candidate: "KARPAGAVALLI P",
      party: "Independent",
      evm_votes: 6200,
      postal_votes: 114,
      total_votes: 6314,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 6,
      candidate: "T MAHESHKUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 3526,
      postal_votes: 28,
      total_votes: 3554,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 7,
      candidate: "RAMASAMY P",
      party: "Viro Ke Vir Indian Party",
      evm_votes: 3395,
      postal_votes: 17,
      total_votes: 3412,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 8,
      candidate: "M MANMATHAN",
      party: "Independent",
      evm_votes: 3276,
      postal_votes: 2,
      total_votes: 3278,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 9,
      candidate: "A SEETHA",
      party: "Bahujan Dravida Party",
      evm_votes: 2476,
      postal_votes: 0,
      total_votes: 2476,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 10,
      candidate: "KRISHNASAMY",
      party: "Independent",
      evm_votes: 1828,
      postal_votes: 5,
      total_votes: 1833,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 11,
      candidate: "M UMAMAHESWARI",
      party: "Desiya Makkal Sakthi Katchi",
      evm_votes: 1626,
      postal_votes: 29,
      total_votes: 1655,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 12,
      candidate: "A MUTHAIAH",
      party: "Independent",
      evm_votes: 1440,
      postal_votes: 17,
      total_votes: 1457,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 13,
      candidate: "P KRISHNASAMY",
      party: "Independent",
      evm_votes: 1342,
      postal_votes: 8,
      total_votes: 1350,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 14,
      candidate: "ARUMUGASWAMI M",
      party: "Independent",
      evm_votes: 1292,
      postal_votes: 21,
      total_votes: 1313,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 15,
      candidate: "RAJA SEKAR",
      party: "Independent",
      evm_votes: 982,
      postal_votes: 11,
      total_votes: 993,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 16877,
      postal_votes: 288,
      total_votes: 17165,
      percentage_of_votes: 1.65,
    },
  ],
  total: {
    evm_votes: 1031307,
    postal_votes: 7812,
    total_votes: 1039119,
  },
};
export const s012 = {
  cs_name: "Srikakulam",
  cs_shortname: "srikakulam",
  cs_code: "s012",
  winner: "TDP",
  stats: [
    {
      serial_no: 1,
      candidate: "KINJARAPU RAMMOHAN NAIDU",
      party: "Telugu Desam",
      evm_votes: 734501,
      postal_votes: 19827,
      total_votes: 754328,
      percentage_of_votes: 61.05,
    },
    {
      serial_no: 2,
      candidate: "TILAK PERADA",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 420394,
      postal_votes: 6033,
      total_votes: 426427,
      percentage_of_votes: 34.51,
    },
    {
      serial_no: 3,
      candidate: "PEDADA PARAMESWARA RAO",
      party: "Indian National Congress",
      evm_votes: 6724,
      postal_votes: 448,
      total_votes: 7172,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 4,
      candidate: "SANAPALA SRAVAN KUMAR",
      party: "Independent",
      evm_votes: 5950,
      postal_votes: 8,
      total_votes: 5958,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 5,
      candidate: "NAIDUGARI RAJASEKHAR",
      party: "Bahujan Samaj Party",
      evm_votes: 4661,
      postal_votes: 81,
      total_votes: 4742,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 6,
      candidate: "VABA YOGESWARA RAO",
      party: "Independent",
      evm_votes: 3472,
      postal_votes: 126,
      total_votes: 3598,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 7,
      candidate: "BETHA VIVEKANANDA MAHARAJ",
      party: "Independent",
      evm_votes: 2523,
      postal_votes: 13,
      total_votes: 2536,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 8,
      candidate: "YEDDU LAKSHMINARAYANA",
      party: "Independent",
      evm_votes: 1598,
      postal_votes: 7,
      total_votes: 1605,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 9,
      candidate: "BORUBADRA CHANDRAKALA",
      party: "Bharatha Chaitanya Yuvajana Party",
      evm_votes: 1315,
      postal_votes: 42,
      total_votes: 1357,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "IPPILI SEETHARAJU",
      party: "Jai Bharat National Party",
      evm_votes: 1080,
      postal_votes: 50,
      total_votes: 1130,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 11,
      candidate: "KAYA DURGARAO",
      party: "Navarang Congress Party",
      evm_votes: 811,
      postal_votes: 7,
      total_votes: 818,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "CHELLURI DANIYAL",
      party: "Independent",
      evm_votes: 796,
      postal_votes: 20,
      total_votes: 816,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "BOMMALI TIRUPATI RAO",
      party: "Pyramid Party of India",
      evm_votes: 589,
      postal_votes: 5,
      total_votes: 594,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 24498,
      postal_votes: 107,
      total_votes: 24605,
      percentage_of_votes: 1.99,
    },
  ],
  total: {
    evm_votes: 1208912,
    postal_votes: 26774,
    total_votes: 1235686,
  },
};
export const s013 = {
  cs_name: "Vizianagaram",
  cs_shortname: "vizianagaram",
  cs_code: "s013",
  winner: "TDP",
  stats: [
    {
      serial_no: 1,
      candidate: "APPALANAIDU KALISETTI",
      party: "Telugu Desam",
      evm_votes: 729284,
      postal_votes: 13829,
      total_votes: 743113,
      percentage_of_votes: 57.2,
    },
    {
      serial_no: 2,
      candidate: "BELLANA CHANDRASEKHAR",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 487691,
      postal_votes: 6071,
      total_votes: 493762,
      percentage_of_votes: 38,
    },
    {
      serial_no: 3,
      candidate: "BOBBILI SRINU",
      party: "Indian National Congress",
      evm_votes: 10270,
      postal_votes: 353,
      total_votes: 10623,
      percentage_of_votes: 0.82,
    },
    {
      serial_no: 4,
      candidate: "PEERUBANDI PRAKASH RAO",
      party: "Bahujan Samaj Party",
      evm_votes: 7985,
      postal_votes: 53,
      total_votes: 8038,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 5,
      candidate: "VELURI VENKATA TRINADHARAO",
      party: "Independent",
      evm_votes: 4146,
      postal_votes: 6,
      total_votes: 4152,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 6,
      candidate: "ALLU SRIRAMULU",
      party: "Independent",
      evm_votes: 3396,
      postal_votes: 5,
      total_votes: 3401,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 7,
      candidate: "SIYYADULA YELLA RAO",
      party: "Independent",
      evm_votes: 3105,
      postal_votes: 2,
      total_votes: 3107,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 8,
      candidate: "SRINIVASA RAO SAMBHANA",
      party: "Yuga Thulasi Party",
      evm_votes: 1758,
      postal_votes: 3,
      total_votes: 1761,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 9,
      candidate: "MARISERLA KRISHNAMURTHY NAIDU",
      party: "Independent",
      evm_votes: 1617,
      postal_votes: 12,
      total_votes: 1629,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 10,
      candidate: "VANGAPANDU MAHESWARA RAO",
      party: "Naba Bharata Nirmana Seva Party",
      evm_votes: 1544,
      postal_votes: 9,
      total_votes: 1553,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 11,
      candidate: "CHINNAM ARUNA KUMAR",
      party: "Jaibhim Rao Bharat Party",
      evm_votes: 1401,
      postal_votes: 10,
      total_votes: 1411,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 12,
      candidate: "ATCHIYYA NAIDU SAMIREDDY",
      party: "Samajwadi Party",
      evm_votes: 1059,
      postal_votes: 11,
      total_votes: 1070,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "ADINARAYANA TADDI",
      party: "Independent",
      evm_votes: 855,
      postal_votes: 1,
      total_votes: 856,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 14,
      candidate: "P V A ANANDA SAGAR",
      party: "Independent",
      evm_votes: 787,
      postal_votes: 4,
      total_votes: 791,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "SAKHINETI ANANTHA RAJU",
      party: "Pyramid Party of India",
      evm_votes: 715,
      postal_votes: 10,
      total_votes: 725,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 23097,
      postal_votes: 153,
      total_votes: 23250,
      percentage_of_votes: 1.79,
    },
  ],
  total: {
    evm_votes: 1278710,
    postal_votes: 20532,
    total_votes: 1299242,
  },
};
export const s014 = {
  cs_name: "Visakhapatnam",
  cs_shortname: "visakhapatnam",
  cs_code: "s014",
  winner: "TDP",
  stats: [
    {
      serial_no: 1,
      candidate: "SRIBHARAT MATHUKUMILI",
      party: "Telugu Desam",
      evm_votes: 893884,
      postal_votes: 13583,
      total_votes: 907467,
      percentage_of_votes: 65.42,
    },
    {
      serial_no: 2,
      candidate: "JHANSI LAKSHMI. BOTCHA.",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 397821,
      postal_votes: 5399,
      total_votes: 403220,
      percentage_of_votes: 29.07,
    },
    {
      serial_no: 3,
      candidate: "P. SATYA REDDY",
      party: "Indian National Congress",
      evm_votes: 29430,
      postal_votes: 837,
      total_votes: 30267,
      percentage_of_votes: 2.18,
    },
    {
      serial_no: 4,
      candidate: "K.A. PAUL",
      party: "Praja Shanthi Party",
      evm_votes: 7529,
      postal_votes: 167,
      total_votes: 7696,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 5,
      candidate: "PEDAPENKI SIVA PRASADA RAO",
      party: "Bahujan Samaj Party",
      evm_votes: 6006,
      postal_votes: 85,
      total_votes: 6091,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 6,
      candidate: "BIKKAVOLU. CHALAMAJI",
      party: "Navarang Congress Party",
      evm_votes: 4883,
      postal_votes: 1,
      total_votes: 4884,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 7,
      candidate: "V.N. SATYA NARAYANA",
      party: "Rashtriya Praja Congress (Secular)",
      evm_votes: 2534,
      postal_votes: 3,
      total_votes: 2537,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "ARUNA SRI MURALA",
      party: "Independent",
      evm_votes: 1991,
      postal_votes: 2,
      total_votes: 1993,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 9,
      candidate: "VASUPILLI SURESH",
      party: "Independent",
      evm_votes: 1931,
      postal_votes: 2,
      total_votes: 1933,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "SONDI KRISHNA.",
      party: "Independent",
      evm_votes: 1811,
      postal_votes: 7,
      total_votes: 1818,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "ANDUKURI VIJAYA BHASKAR",
      party: "India Praja Bandhu Party",
      evm_votes: 1098,
      postal_votes: 11,
      total_votes: 1109,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "DR. GANAPATHI KONGARAPU.",
      party: "Republican Party of India (A)",
      evm_votes: 944,
      postal_votes: 6,
      total_votes: 950,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 13,
      candidate: "BANNA. RAMESH",
      party: "Dalita Bahujana Party",
      evm_votes: 941,
      postal_votes: 7,
      total_votes: 948,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 14,
      candidate: "P. SATYA VANI",
      party: "Pyramid Party of India",
      evm_votes: 875,
      postal_votes: 7,
      total_votes: 882,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "DURGA PRASAD. GUNTU",
      party: "Bhartiya Rashtriya Dal",
      evm_votes: 760,
      postal_votes: 7,
      total_votes: 767,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 16,
      candidate: "CHAPPIDI RAMU",
      party: "Independent",
      evm_votes: 756,
      postal_votes: 1,
      total_votes: 757,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 17,
      candidate: "M.G.M. KHAN",
      party: "Independent",
      evm_votes: 738,
      postal_votes: 4,
      total_votes: 742,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 18,
      candidate: "SHANKAR DEVARA YADAV",
      party: "Independent",
      evm_votes: 656,
      postal_votes: 5,
      total_votes: 661,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 19,
      candidate: "VENU MADHAV KARRI",
      party: "Independent",
      evm_votes: 646,
      postal_votes: 1,
      total_votes: 647,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 20,
      candidate: "DR. ACHUTHA BALAJI YADAV (MURAPALA)",
      party: "Bharatha Chaitanya Yuvajana Party",
      evm_votes: 628,
      postal_votes: 10,
      total_votes: 638,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 21,
      candidate: "THOTA VENKATA SAI MUKUND",
      party: "Praja Prasthanam Party",
      evm_votes: 628,
      postal_votes: 5,
      total_votes: 633,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 22,
      candidate: "PIDIDHI APPARAO",
      party: "Independent",
      evm_votes: 576,
      postal_votes: 4,
      total_votes: 580,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 23,
      candidate: "G.A.N. ANAND",
      party: "Independent",
      evm_votes: 543,
      postal_votes: 0,
      total_votes: 543,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 24,
      candidate: "VADDI. HARI GANESH",
      party: "Independent",
      evm_votes: 518,
      postal_votes: 2,
      total_votes: 520,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 25,
      candidate: "JANARDHAN PONNADA.",
      party: "Independent",
      evm_votes: 499,
      postal_votes: 1,
      total_votes: 500,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 26,
      candidate: "GANAPATHI. JAGADEESWARA RAO (JAGADEESH)",
      party: "Jai Maha Bharath Party",
      evm_votes: 494,
      postal_votes: 6,
      total_votes: 500,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 27,
      candidate: "JALADI VIJAYA KUMARI",
      party: "Samajwadi Party",
      evm_votes: 463,
      postal_votes: 3,
      total_votes: 466,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 28,
      candidate: "CHINTADA SURYAM",
      party: "Naba Bharata Nirmana Seva Party",
      evm_votes: 443,
      postal_votes: 5,
      total_votes: 448,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 29,
      candidate: "GANDIKOTA RAJESH",
      party: "Navataram Party",
      evm_votes: 392,
      postal_votes: 1,
      total_votes: 393,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 30,
      candidate: "KOLLI NAGA RAJU",
      party: "Independent",
      evm_votes: 322,
      postal_votes: 7,
      total_votes: 329,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 31,
      candidate: "METTA RAMA RAO",
      party: "Independent",
      evm_votes: 320,
      postal_votes: 6,
      total_votes: 326,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 32,
      candidate: "LAGUDU. GOVINDA RAO",
      party: "Independent",
      evm_votes: 293,
      postal_votes: 1,
      total_votes: 294,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 33,
      candidate: "MALLA SRAVANI",
      party: "Independent",
      evm_votes: 271,
      postal_votes: 2,
      total_votes: 273,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 34,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5171,
      postal_votes: 142,
      total_votes: 5313,
      percentage_of_votes: 0.38,
    },
  ],
  total: {
    evm_votes: 1366795,
    postal_votes: 20330,
    total_votes: 1387125,
  },
};
export const s017 = {
  cs_name: "Amalapuram",
  cs_shortname: "amalapuram",
  cs_code: "s017",
  winner: "TDP",
  stats: [
    {
      serial_no: 1,
      candidate: "G M HARISH (BALAYOGI)",
      party: "Telugu Desam",
      evm_votes: 786149,
      postal_votes: 10832,
      total_votes: 796981,
      percentage_of_votes: 61.25,
    },
    {
      serial_no: 2,
      candidate: "RAPAKA VARAPRASADA RAO",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 450362,
      postal_votes: 4423,
      total_votes: 454785,
      percentage_of_votes: 34.95,
    },
    {
      serial_no: 3,
      candidate: "GOUTHAM JANGA",
      party: "Indian National Congress",
      evm_votes: 14523,
      postal_votes: 559,
      total_votes: 15082,
      percentage_of_votes: 1.16,
    },
    {
      serial_no: 4,
      candidate: "DORABABU YALLA",
      party: "Bahujan Samaj Party",
      evm_votes: 5202,
      postal_votes: 186,
      total_votes: 5388,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 5,
      candidate: "KATRU NAGA BABU",
      party: "Telugu Nava Garjana Party",
      evm_votes: 3736,
      postal_votes: 5,
      total_votes: 3741,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 6,
      candidate: "VADDI LALITH KUMAR",
      party: "Independent",
      evm_votes: 2974,
      postal_votes: 3,
      total_votes: 2977,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 7,
      candidate: "MAKEY DAVY PRASAD",
      party: "Independent",
      evm_votes: 2104,
      postal_votes: 7,
      total_votes: 2111,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 8,
      candidate: "GUDE VENKATESWARULU",
      party: "Independent",
      evm_votes: 1741,
      postal_votes: 4,
      total_votes: 1745,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 9,
      candidate: "RAMESH JILLELLA",
      party: "Jatiya Jana Sena Party",
      evm_votes: 866,
      postal_votes: 6,
      total_votes: 872,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 10,
      candidate: "YALANGI RAMESH",
      party: "Jaibhim Rao Bharat Party",
      evm_votes: 738,
      postal_votes: 2,
      total_votes: 740,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 11,
      candidate: "MATHA KRISHNA",
      party: "Pyramid Party of India",
      evm_votes: 718,
      postal_votes: 1,
      total_votes: 719,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "GIDDI JNANA PRAKASA RAO",
      party: "Republican Party of India",
      evm_votes: 717,
      postal_votes: 9,
      total_votes: 726,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "YADLA RAJESH",
      party: "Liberation Congress Party",
      evm_votes: 628,
      postal_votes: 7,
      total_votes: 635,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "KAPA KALYAN",
      party: "Republican Party of India (A)",
      evm_votes: 579,
      postal_votes: 2,
      total_votes: 581,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 15,
      candidate: "PATHURI RAMESH",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 502,
      postal_votes: 3,
      total_votes: 505,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13418,
      postal_votes: 100,
      total_votes: 13518,
      percentage_of_votes: 1.04,
    },
  ],
  total: {
    evm_votes: 1284957,
    postal_votes: 16149,
    total_votes: 1301106,
  },
};
export const s0110 = {
  cs_name: "Eluru",
  cs_shortname: "eluru",
  cs_code: "s0110",
  winner: "TDP",
  stats: [
    {
      serial_no: 1,
      candidate: "PUTTA MAHESH KUMAR",
      party: "Telugu Desam",
      evm_votes: 737644,
      postal_votes: 8707,
      total_votes: 746351,
      percentage_of_votes: 54,
    },
    {
      serial_no: 2,
      candidate: "KARUMURI SUNIL KUMAR",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 559318,
      postal_votes: 5176,
      total_votes: 564494,
      percentage_of_votes: 40.84,
    },
    {
      serial_no: 3,
      candidate: "KAVURI LAVANYA",
      party: "Indian National Congress",
      evm_votes: 19820,
      postal_votes: 1006,
      total_votes: 20826,
      percentage_of_votes: 1.51,
    },
    {
      serial_no: 4,
      candidate: "AJAY BABU BODA",
      party: "Navarang Congress Party",
      evm_votes: 12197,
      postal_votes: 13,
      total_votes: 12210,
      percentage_of_votes: 0.88,
    },
    {
      serial_no: 5,
      candidate: "NITTA AKHILA DHARANI PAL",
      party: "Bahujan Samaj Party",
      evm_votes: 6664,
      postal_votes: 68,
      total_votes: 6732,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 6,
      candidate: "BOKINALA KOTESWARA RAO",
      party: "Independent",
      evm_votes: 2253,
      postal_votes: 10,
      total_votes: 2263,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 7,
      candidate: "RUDRAPAKA RATNA RAO (R.R.R)",
      party: "Andhra Rastra Praja Samithi",
      evm_votes: 1523,
      postal_votes: 3,
      total_votes: 1526,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 8,
      candidate: "KANDAVALLI JEEVA DEVA DAYAKAR",
      party: "Independent",
      evm_votes: 1172,
      postal_votes: 8,
      total_votes: 1180,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 9,
      candidate: "AGASTEEN KOMMINA",
      party: "Independent",
      evm_votes: 1001,
      postal_votes: 3,
      total_votes: 1004,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 10,
      candidate: "DR. MENDEM SANTHOSH KUMAR (PEDDA BABULU)",
      party: "Liberation Congress Party",
      evm_votes: 777,
      postal_votes: 9,
      total_votes: 786,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 11,
      candidate: "RAJESWARA RAO KONDRU",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 729,
      postal_votes: 3,
      total_votes: 732,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 12,
      candidate: "GODUGUPATI VEERA RAGHAVULU",
      party: "Pyramid Party of India",
      evm_votes: 710,
      postal_votes: 5,
      total_votes: 715,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "BYRABOYINA MALYADRI",
      party: "Bharatha Chaitanya Yuvajana Party",
      evm_votes: 705,
      postal_votes: 10,
      total_votes: 715,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 22371,
      postal_votes: 144,
      total_votes: 22515,
      percentage_of_votes: 1.63,
    },
  ],
  total: {
    evm_votes: 1366884,
    postal_votes: 15165,
    total_votes: 1382049,
  },
};
export const s0112 = {
  cs_name: "Vijayawada",
  cs_shortname: "vijayawada",
  cs_code: "s0112",
  winner: "TDP",
  stats: [
    {
      serial_no: 1,
      candidate: "KESINENI SIVANATH (CHINNI)",
      party: "Telugu Desam",
      evm_votes: 784722,
      postal_votes: 9432,
      total_votes: 794154,
      percentage_of_votes: 58.21,
    },
    {
      serial_no: 2,
      candidate: "KESINENI SRINIVAS (NANI)",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 505637,
      postal_votes: 6432,
      total_votes: 512069,
      percentage_of_votes: 37.53,
    },
    {
      serial_no: 3,
      candidate: "BHARGAV VALLURU",
      party: "Indian National Congress",
      evm_votes: 23498,
      postal_votes: 608,
      total_votes: 24106,
      percentage_of_votes: 1.77,
    },
    {
      serial_no: 4,
      candidate: "KRISHNA KISHORE YANAMANDRA",
      party: "Navataram Party",
      evm_votes: 7710,
      postal_votes: 168,
      total_votes: 7878,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 5,
      candidate: "VENKATA ASHOK PAPPURI",
      party: "Independent",
      evm_votes: 6672,
      postal_votes: 0,
      total_votes: 6672,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 6,
      candidate: "DEVARAPALLI MALLIKARJUNA RAO (MAHESH)",
      party: "Independent",
      evm_votes: 3199,
      postal_votes: 0,
      total_votes: 3199,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 7,
      candidate: "MEKA VENKATESWARA RAO",
      party: "Bahujan Samaj Party",
      evm_votes: 2184,
      postal_votes: 49,
      total_votes: 2233,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 8,
      candidate: "ANIL KUMAR MADDINENI",
      party: "Independent",
      evm_votes: 1240,
      postal_votes: 0,
      total_votes: 1240,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 9,
      candidate: "BARRE SREENIVASARAO (JAI BHEEM SRINIVAS)",
      party: "Telugu Rajadhikara Samiti Party",
      evm_votes: 609,
      postal_votes: 6,
      total_votes: 615,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 10,
      candidate: "SYED KHAMURUNNISA",
      party: "Navarang Congress Party",
      evm_votes: 600,
      postal_votes: 1,
      total_votes: 601,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 11,
      candidate: "CHAGANTIPATI GANGADHAR GANDHI",
      party: "Pyramid Party of India",
      evm_votes: 486,
      postal_votes: 3,
      total_votes: 489,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 12,
      candidate: "DATLA LURTHU MERI",
      party: "Jaibhim Rao Bharat Party",
      evm_votes: 409,
      postal_votes: 8,
      total_votes: 417,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 13,
      candidate: "PERAM SIVA NAGESWARA RAO",
      party: "Republican Party of India (Athawale)",
      evm_votes: 365,
      postal_votes: 3,
      total_votes: 368,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 14,
      candidate: "DEVARASETTY RAVEENDRA BABU",
      party: "Andhra Rastra Praja Samithi",
      evm_votes: 328,
      postal_votes: 3,
      total_votes: 331,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 15,
      candidate: "GUJJULA LALITHA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 323,
      postal_votes: 4,
      total_votes: 327,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 16,
      candidate: "N DASARADHA RAMI REDDY",
      party: "All India Jaihind Party",
      evm_votes: 268,
      postal_votes: 1,
      total_votes: 269,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 17,
      candidate: "CHINTHALACHERUVU HEMALATHA",
      party: "Jai Maha Bharath Party",
      evm_votes: 239,
      postal_votes: 5,
      total_votes: 244,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 18,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9038,
      postal_votes: 155,
      total_votes: 9193,
      percentage_of_votes: 0.67,
    },
  ],
  total: {
    evm_votes: 1347527,
    postal_votes: 16878,
    total_votes: 1364405,
  },
};
export const s0113 = {
  cs_name: "Guntur",
  cs_shortname: "guntur",
  cs_code: "s0113",
  winner: "TDP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR CHANDRA SEKHAR PEMMASANI",
      party: "Telugu Desam",
      evm_votes: 851020,
      postal_votes: 13928,
      total_votes: 864948,
      percentage_of_votes: 60.68,
    },
    {
      serial_no: 2,
      candidate: "KILARI VENKATA ROSAIAH",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 513563,
      postal_votes: 6690,
      total_votes: 520253,
      percentage_of_votes: 36.5,
    },
    {
      serial_no: 3,
      candidate: "JANGALA AJAY KUMAR",
      party: "Communist Party of India",
      evm_votes: 8317,
      postal_votes: 320,
      total_votes: 8637,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 4,
      candidate: "SIVAREDDY. ENDREDDY",
      party: "Navataram Party",
      evm_votes: 5628,
      postal_votes: 1,
      total_votes: 5629,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 5,
      candidate: "TENALI PRAKASH",
      party: "Bahujan Samaj Party",
      evm_votes: 4172,
      postal_votes: 100,
      total_votes: 4272,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 6,
      candidate: "SIKHAKOLLI HEMA GOWRI SHANKAR",
      party: "Jatiya Jana Sena Party",
      evm_votes: 1959,
      postal_votes: 5,
      total_votes: 1964,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 7,
      candidate: "SAMUDRALA CHINNA KOTAIAH",
      party: "Jaibhim Rao Bharat Party",
      evm_votes: 1748,
      postal_votes: 5,
      total_votes: 1753,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 8,
      candidate: "UMAR BHASHA SHAIK",
      party: "Jana Sahayaka Sakthi",
      evm_votes: 1273,
      postal_votes: 2,
      total_votes: 1275,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 9,
      candidate: "SYED. SAIDA",
      party: "Navarang Congress Party",
      evm_votes: 1216,
      postal_votes: 6,
      total_votes: 1222,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 10,
      candidate: "AMBATI CHALAMAIAH",
      party: "Pyramid Party of India",
      evm_votes: 1029,
      postal_votes: 2,
      total_votes: 1031,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 11,
      candidate: "PILLI BABU RAO",
      party: "Independent",
      evm_votes: 688,
      postal_votes: 4,
      total_votes: 692,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 12,
      candidate: "HARI VENKATA SATISH KURNALA",
      party: "Jai Bharat National Party",
      evm_votes: 540,
      postal_votes: 2,
      total_votes: 542,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 13,
      candidate: "VISHNU REDDY LANKIREDDY",
      party: "Bengaluru Nava Nirmana Party",
      evm_votes: 461,
      postal_votes: 0,
      total_votes: 461,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 14,
      candidate: "EMANI. CHANDRASEKHAR RAO",
      party: "Navodayam Party",
      evm_votes: 409,
      postal_votes: 5,
      total_votes: 414,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 15,
      candidate: "SHAIK. KHAJAVALI",
      party: "Independent",
      evm_votes: 387,
      postal_votes: 4,
      total_votes: 391,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 16,
      candidate: "CHALLAPALLI RATAN RAJU",
      party: "Viduthalai Chiruthaigal Katchi",
      evm_votes: 374,
      postal_votes: 4,
      total_votes: 378,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 17,
      candidate: "KANNEBOYINA VAMSI KRISHNA YADAV",
      party: "Independent",
      evm_votes: 368,
      postal_votes: 7,
      total_votes: 375,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 18,
      candidate: "MOHAMMAD KHAJA MOINUDDIN CHISTI PASHA",
      party: "Andhra Pradesh Rashtra Samithi",
      evm_votes: 353,
      postal_votes: 5,
      total_votes: 358,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 19,
      candidate: "GAYATRI AUDHIPUDI",
      party: "Independent",
      evm_votes: 350,
      postal_votes: 7,
      total_votes: 357,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 20,
      candidate: "ASHOK ANAND GALI",
      party: "Independent",
      evm_votes: 335,
      postal_votes: 0,
      total_votes: 335,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 21,
      candidate: "SHAIK ASLAM AKTHER",
      party: "Independent",
      evm_votes: 334,
      postal_votes: 1,
      total_votes: 335,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 22,
      candidate: "PATHAN KHAJA (BASHA)",
      party: "Telugu Rajadhikara Samiti Party",
      evm_votes: 328,
      postal_votes: 4,
      total_votes: 332,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 23,
      candidate: "AMARTHALURI VENKATESWARA RAO.",
      party: "Marxist Communist Party of India (United)",
      evm_votes: 308,
      postal_votes: 4,
      total_votes: 312,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 24,
      candidate: "DR. RAYAPUDI RAJESH VIJAY KUMAR",
      party: "Indian Labour Party (Ambedkar Phule)",
      evm_votes: 306,
      postal_votes: 4,
      total_votes: 310,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 25,
      candidate: "BURAGA RATNAM",
      party: "Loktantrik Janta Dal",
      evm_votes: 304,
      postal_votes: 12,
      total_votes: 316,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 26,
      candidate: "VANKAYALAPATI VENKATA SIVA RAMANJANEYULU",
      party: "Independent",
      evm_votes: 268,
      postal_votes: 0,
      total_votes: 268,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 27,
      candidate: "SIVA PARVATHI",
      party: "Independent",
      evm_votes: 262,
      postal_votes: 4,
      total_votes: 266,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 28,
      candidate: "BOMMASANI MUTYALA RAO",
      party: "All Peoples Party",
      evm_votes: 200,
      postal_votes: 7,
      total_votes: 207,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 29,
      candidate: "SRIKRISHNA AKKISETTI",
      party: "Independent",
      evm_votes: 179,
      postal_votes: 0,
      total_votes: 179,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 30,
      candidate: "MADAVARAPU NAGA RAJU",
      party: "Independent",
      evm_votes: 172,
      postal_votes: 0,
      total_votes: 172,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 31,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7210,
      postal_votes: 177,
      total_votes: 7387,
      percentage_of_votes: 0.52,
    },
  ],
  total: {
    evm_votes: 1404061,
    postal_votes: 21310,
    total_votes: 1425371,
  },
};
export const s0114 = {
  cs_name: "Narsaraopet",
  cs_shortname: "narsaraopet",
  cs_code: "s0114",
  winner: "TDP",
  stats: [
    {
      serial_no: 1,
      candidate: "LAVU SRIKRISHNA DEVARAYALU",
      party: "Telugu Desam",
      evm_votes: 798097,
      postal_votes: 9899,
      total_votes: 807996,
      percentage_of_votes: 53.88,
    },
    {
      serial_no: 2,
      candidate: "ANIL KUMAR POLUBOINA",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 642186,
      postal_votes: 6081,
      total_votes: 648267,
      percentage_of_votes: 43.23,
    },
    {
      serial_no: 3,
      candidate: "GARNEPUDI ALEXANDER SUDHAKAR",
      party: "Indian National Congress",
      evm_votes: 17520,
      postal_votes: 526,
      total_votes: 18046,
      percentage_of_votes: 1.2,
    },
    {
      serial_no: 4,
      candidate: "ORSU PREMARAJU",
      party: "Bahujan Samaj Party",
      evm_votes: 5276,
      postal_votes: 33,
      total_votes: 5309,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 5,
      candidate: "KUCHIPUDI JOHN BABU",
      party: "Jaibhim Rao Bharat Party",
      evm_votes: 2328,
      postal_votes: 8,
      total_votes: 2336,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 6,
      candidate: "GARLAPATI SUBHASH PREM",
      party: "Revolutionary Socialist Party",
      evm_votes: 1243,
      postal_votes: 2,
      total_votes: 1245,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 7,
      candidate: "YAMPATI VEERANJANEYA REDDY",
      party: "Independent",
      evm_votes: 1056,
      postal_votes: 1,
      total_votes: 1057,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 8,
      candidate: "SUBBARAO CHALLA",
      party: "Independent",
      evm_votes: 1051,
      postal_votes: 0,
      total_votes: 1051,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 9,
      candidate: "ABDUL RAJAK SHAIK",
      party: "Independent",
      evm_votes: 718,
      postal_votes: 5,
      total_votes: 723,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 10,
      candidate: "KOTA POTHURAJU",
      party: "Pyramid Party of India",
      evm_votes: 667,
      postal_votes: 3,
      total_votes: 670,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 11,
      candidate: "P VENKATESH",
      party: "Jaathia Chethi Vruthula Ikya Vedika Party",
      evm_votes: 385,
      postal_votes: 3,
      total_votes: 388,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 12,
      candidate: "PASUPULETI PICHAIAH",
      party: "National Nava Kranthi Party",
      evm_votes: 353,
      postal_votes: 3,
      total_votes: 356,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 13,
      candidate: "PAPPULA SAMBASIVA RAO",
      party: "Navarang Congress Party",
      evm_votes: 331,
      postal_votes: 1,
      total_votes: 332,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 14,
      candidate: "DOCTOR GODA RAMESH KUMAR",
      party: "Jatiya Jana Sena Party",
      evm_votes: 305,
      postal_votes: 4,
      total_votes: 309,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 15,
      candidate: "THOKALA NAGA RAJU",
      party: "All India Forward Bloc",
      evm_votes: 277,
      postal_votes: 8,
      total_votes: 285,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11120,
      postal_votes: 79,
      total_votes: 11199,
      percentage_of_votes: 0.75,
    },
  ],
  total: {
    evm_votes: 1482913,
    postal_votes: 16656,
    total_votes: 1499569,
  },
};
export const s0115 = {
  cs_name: "Bapatla",
  cs_shortname: "bapatla",
  cs_code: "s0115",
  winner: "TDP",
  stats: [
    {
      serial_no: 1,
      candidate: "KRISHNA PRASAD TENNETI",
      party: "Telugu Desam",
      evm_votes: 706757,
      postal_votes: 10736,
      total_votes: 717493,
      percentage_of_votes: 55.16,
    },
    {
      serial_no: 2,
      candidate: "NANDIGAM SURESH BABU",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 503816,
      postal_votes: 5646,
      total_votes: 509462,
      percentage_of_votes: 39.17,
    },
    {
      serial_no: 3,
      candidate: "J.D. SEELAM",
      party: "Indian National Congress",
      evm_votes: 41673,
      postal_votes: 1586,
      total_votes: 43259,
      percentage_of_votes: 3.33,
    },
    {
      serial_no: 4,
      candidate: "DOKKA JAGAN MOHANA RAO",
      party: "Bahujan Samaj Party",
      evm_votes: 6774,
      postal_votes: 83,
      total_votes: 6857,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 5,
      candidate: "NALAMALA TIRUPATI RAO",
      party: "Navataram Party",
      evm_votes: 2888,
      postal_votes: 76,
      total_votes: 2964,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 6,
      candidate: "BUSSA. NAGARAJU",
      party: "Independent",
      evm_votes: 1311,
      postal_votes: 7,
      total_votes: 1318,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 7,
      candidate: "GADDE. VIJAYA LAKSHMI",
      party: "Independent",
      evm_votes: 1046,
      postal_votes: 5,
      total_votes: 1051,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 8,
      candidate: "KATTAPOGU. VIJAY KUMAR",
      party: "Pyramid Party of India",
      evm_votes: 893,
      postal_votes: 4,
      total_votes: 897,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 9,
      candidate: "KONDRU KIRAN KUMAR",
      party: "Independent",
      evm_votes: 791,
      postal_votes: 6,
      total_votes: 797,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 10,
      candidate: "KATTA ANAND BABU",
      party: "Independent",
      evm_votes: 704,
      postal_votes: 25,
      total_votes: 729,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 11,
      candidate: "MERUGUPALA SADASIVA RAO",
      party: "India Praja Bandhu Party",
      evm_votes: 669,
      postal_votes: 4,
      total_votes: 673,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 12,
      candidate: "KATTA HEMANAND",
      party: "Independent",
      evm_votes: 547,
      postal_votes: 2,
      total_votes: 549,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 13,
      candidate: "PARRE. KOTAIAH",
      party: "Jaibhim Rao Bharat Party",
      evm_votes: 408,
      postal_votes: 4,
      total_votes: 412,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 14,
      candidate: "PALADUG VIJENDRA RAO (BAHUJAN)",
      party: "Liberation Congress Party",
      evm_votes: 294,
      postal_votes: 12,
      total_votes: 306,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 15,
      candidate: "CHINNAM MURALIDHAR",
      party: "All Peoples Party",
      evm_votes: 252,
      postal_votes: 3,
      total_votes: 255,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13599,
      postal_votes: 124,
      total_votes: 13723,
      percentage_of_votes: 1.06,
    },
  ],
  total: {
    evm_votes: 1282422,
    postal_votes: 18323,
    total_votes: 1300745,
  },
};
export const s0116 = {
  cs_name: "Ongole",
  cs_shortname: "ongole",
  cs_code: "s0116",
  winner: "TDP",
  stats: [
    {
      serial_no: 1,
      candidate: "MAGUNTA SREENIVASULU REDDY",
      party: "Telugu Desam",
      evm_votes: 689829,
      postal_votes: 12065,
      total_votes: 701894,
      percentage_of_votes: 49.35,
    },
    {
      serial_no: 2,
      candidate: "DR CHEVIREDDY BHASKAR REDD",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 644052,
      postal_votes: 7643,
      total_votes: 651695,
      percentage_of_votes: 45.82,
    },
    {
      serial_no: 3,
      candidate: "EDA SUDHAKARA REDDY",
      party: "Indian National Congress",
      evm_votes: 25803,
      postal_votes: 919,
      total_votes: 26722,
      percentage_of_votes: 1.88,
    },
    {
      serial_no: 4,
      candidate: "DHARANIKOTA LAKSHMI NARAYANA",
      party: "Bahujan Samaj Party",
      evm_votes: 6238,
      postal_votes: 76,
      total_votes: 6314,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 5,
      candidate: "BUCHI EDUKONDALU",
      party: "Independent",
      evm_votes: 3046,
      postal_votes: 2,
      total_votes: 3048,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 6,
      candidate: "PODILI RAMBABU",
      party: "Independent",
      evm_votes: 3035,
      postal_votes: 38,
      total_votes: 3073,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 7,
      candidate: "ORSU VEENA",
      party: "Jai Bharat National Party",
      evm_votes: 2242,
      postal_votes: 25,
      total_votes: 2267,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 8,
      candidate: "BONTHA RANGA REDDY",
      party: "Independent",
      evm_votes: 1923,
      postal_votes: 0,
      total_votes: 1923,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 9,
      candidate: "BODDU KRANTHI KUMAR",
      party: "Independent",
      evm_votes: 1797,
      postal_votes: 1,
      total_votes: 1798,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 10,
      candidate: "MOHAN GOUD J V",
      party: "Independent",
      evm_votes: 1442,
      postal_votes: 5,
      total_votes: 1447,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "U MADHU BABU",
      party: "Independent",
      evm_votes: 1006,
      postal_votes: 2,
      total_votes: 1008,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "KARATAPU RAJU",
      party: "Independent",
      evm_votes: 730,
      postal_votes: 5,
      total_votes: 735,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "JAGADEESH RAYAPATI",
      party: "Independent",
      evm_votes: 713,
      postal_votes: 12,
      total_votes: 725,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "DEVIREDDY BALANJANEYULU",
      party: "Independent",
      evm_votes: 710,
      postal_votes: 6,
      total_votes: 716,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "HARI PRASAD TUPAKULA",
      party: "Independent",
      evm_votes: 706,
      postal_votes: 5,
      total_votes: 711,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "PASAM VENKATESWARLU YADAV",
      party: "Samajwadi Party",
      evm_votes: 699,
      postal_votes: 44,
      total_votes: 743,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 17,
      candidate: "SURYA TEJA KOTA",
      party: "Pyramid Party of India",
      evm_votes: 674,
      postal_votes: 9,
      total_votes: 683,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 18,
      candidate: "AKUMALLA MALLIKARJUNA REDDY",
      party: "Independent",
      evm_votes: 575,
      postal_votes: 0,
      total_votes: 575,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 19,
      candidate: "VESHAPOGU GURAVABABU",
      party: "Independent",
      evm_votes: 457,
      postal_votes: 4,
      total_votes: 461,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 20,
      candidate: "KOMMU YOHANU",
      party: "Liberation Congress Party",
      evm_votes: 454,
      postal_votes: 7,
      total_votes: 461,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 21,
      candidate: "SESHUBABU GUDDANTI",
      party: "Independent",
      evm_votes: 449,
      postal_votes: 0,
      total_votes: 449,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "SINGAMNENI SREEKANTH",
      party: "Independent",
      evm_votes: 407,
      postal_votes: 3,
      total_votes: 410,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 23,
      candidate: "YARRA DASARADHARAMAIAH",
      party: "Independent",
      evm_votes: 394,
      postal_votes: 0,
      total_votes: 394,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 24,
      candidate: "KOTA VAMSI KRISHNA",
      party: "Independent",
      evm_votes: 365,
      postal_votes: 0,
      total_votes: 365,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 25,
      candidate: "K PAVAN KALYAN",
      party: "Jatiya Jana Sena Party",
      evm_votes: 362,
      postal_votes: 30,
      total_votes: 392,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 26,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13066,
      postal_votes: 139,
      total_votes: 13205,
      percentage_of_votes: 0.93,
    },
  ],
  total: {
    evm_votes: 1401174,
    postal_votes: 21040,
    total_votes: 1422214,
  },
};
export const s0117 = {
  cs_name: "Nandyal",
  cs_shortname: "nandyal",
  cs_code: "s0117",
  winner: "TDP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR BYREDDY SHABARI",
      party: "Telugu Desam",
      evm_votes: 689417,
      postal_votes: 11714,
      total_votes: 701131,
      percentage_of_votes: 49.92,
    },
    {
      serial_no: 2,
      candidate: "POCHA BRAHMANANDA REDDY",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 583155,
      postal_votes: 6001,
      total_votes: 589156,
      percentage_of_votes: 41.95,
    },
    {
      serial_no: 3,
      candidate: "J LAKSHMI NARASIMHA YADAV",
      party: "Indian National Congress",
      evm_votes: 54920,
      postal_votes: 1284,
      total_votes: 56204,
      percentage_of_votes: 4,
    },
    {
      serial_no: 4,
      candidate: "P GURUVAIAH",
      party: "Independent",
      evm_votes: 8190,
      postal_votes: 1,
      total_votes: 8191,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 5,
      candidate: "A CHINNA MOULALI",
      party: "Bahujan Samaj Party",
      evm_votes: 7768,
      postal_votes: 63,
      total_votes: 7831,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 6,
      candidate: "HAFIZ ATAULLAH KHAN",
      party: "Social Democratic Party Of India",
      evm_votes: 6858,
      postal_votes: 74,
      total_votes: 6932,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 7,
      candidate: "KOMMUSANI PEDDA KAMBAGIRI SWAMY",
      party: "Independent",
      evm_votes: 4633,
      postal_votes: 0,
      total_votes: 4633,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 8,
      candidate: "GAGGERA VENKATA RAMANAIAH",
      party: "Independent",
      evm_votes: 3027,
      postal_votes: 4,
      total_votes: 3031,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 9,
      candidate: "DUMAVATH SWAMY NAIK",
      party: "Independent",
      evm_votes: 2348,
      postal_votes: 10,
      total_votes: 2358,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 10,
      candidate: "KUNUKUNTLA RAMUDU",
      party: "Independent",
      evm_votes: 1408,
      postal_votes: 5,
      total_votes: 1413,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "E ANIL KUMAR",
      party: "Independent",
      evm_votes: 1332,
      postal_votes: 2,
      total_votes: 1334,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 12,
      candidate: "C SURENDRA NATHA REDDY",
      party: "Independent",
      evm_votes: 1267,
      postal_votes: 1,
      total_votes: 1268,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "BRAHMANANDA REDDY K",
      party: "Independent",
      evm_votes: 1261,
      postal_votes: 11,
      total_votes: 1272,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "UPPU SUBBARAYUDU",
      party: "Jaathia Chethi Vruthula Ikya Vedika Party",
      evm_votes: 785,
      postal_votes: 6,
      total_votes: 791,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "NAGESWARA RAO",
      party: "Independent",
      evm_votes: 678,
      postal_votes: 2,
      total_votes: 680,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "VIJAYA BHASKAR REDDYPOGU",
      party: "Independent",
      evm_votes: 641,
      postal_votes: 0,
      total_votes: 641,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 17,
      candidate: "YELAMPALLE GOVARDHAN REDDY",
      party: "All India Forward Bloc",
      evm_votes: 638,
      postal_votes: 4,
      total_votes: 642,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 18,
      candidate: "BHUMA VEERA BHADRA REDDY",
      party: "Navarang Congress Party",
      evm_votes: 630,
      postal_votes: 16,
      total_votes: 646,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 19,
      candidate: "PANDU RANGA YADAV SIDDAPU",
      party: "Samajwadi Party",
      evm_votes: 594,
      postal_votes: 11,
      total_votes: 605,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 20,
      candidate: "CHANDINI REDDY VAKATI",
      party: "Liberation Congress Party",
      evm_votes: 546,
      postal_votes: 10,
      total_votes: 556,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 21,
      candidate: "B C RAMANADHA AREDDY",
      party: "Independent",
      evm_votes: 472,
      postal_votes: 0,
      total_votes: 472,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "GAJULA SHALIMIYA",
      party: "Anna YSR Congress Party",
      evm_votes: 468,
      postal_votes: 18,
      total_votes: 486,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 23,
      candidate: "KULURU RAMA KRISHNA REDDY",
      party: "Neethi Nijayithi Party",
      evm_votes: 428,
      postal_votes: 2,
      total_votes: 430,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 24,
      candidate: "CH P MALLESWARUDU",
      party: "Independent",
      evm_votes: 417,
      postal_votes: 4,
      total_votes: 421,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 25,
      candidate: "I V PAKKIR REDDY",
      party: "Independent",
      evm_votes: 408,
      postal_votes: 2,
      total_votes: 410,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 26,
      candidate: "V MADHUSUDHANA REDDY",
      party: "Independent",
      evm_votes: 399,
      postal_votes: 2,
      total_votes: 401,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 27,
      candidate: "BUSHAKU MAHESWARA REDDY",
      party: "Independent",
      evm_votes: 390,
      postal_votes: 0,
      total_votes: 390,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 28,
      candidate: "U P MUNI REDDY",
      party: "Independent",
      evm_votes: 376,
      postal_votes: 0,
      total_votes: 376,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 29,
      candidate: "GOGULA SUGUNAMMA",
      party: "Jai Maha Bharath Party",
      evm_votes: 375,
      postal_votes: 4,
      total_votes: 379,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 30,
      candidate: "BELLAMKONDA SREENIVASULU",
      party: "Independent",
      evm_votes: 352,
      postal_votes: 1,
      total_votes: 353,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 31,
      candidate: "BALIJA SHIVA KUMAR",
      party: "Independent",
      evm_votes: 288,
      postal_votes: 0,
      total_votes: 288,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 32,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10538,
      postal_votes: 159,
      total_votes: 10697,
      percentage_of_votes: 0.76,
    },
  ],
  total: {
    evm_votes: 1385007,
    postal_votes: 19411,
    total_votes: 1404418,
  },
};
export const s0118 = {
  cs_name: "Kurnoolu",
  cs_shortname: "kurnoolu",
  cs_code: "s0118",
  winner: "TDP",
  stats: [
    {
      serial_no: 1,
      candidate: "BASTIPATI NAGARAJU PANCHALINGALA",
      party: "Telugu Desam",
      evm_votes: 650335,
      postal_votes: 8579,
      total_votes: 658914,
      percentage_of_votes: 49.51,
    },
    {
      serial_no: 2,
      candidate: "B Y RAMAIAH",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 543801,
      postal_votes: 3815,
      total_votes: 547616,
      percentage_of_votes: 41.15,
    },
    {
      serial_no: 3,
      candidate: "P.G.RAMPULLAIAH YADAV LUCKY-2",
      party: "Indian National Congress",
      evm_votes: 68972,
      postal_votes: 1401,
      total_votes: 70373,
      percentage_of_votes: 5.29,
    },
    {
      serial_no: 4,
      candidate: "MANCHALA LAKSHMI NARAYANA",
      party: "Bahujan Samaj Party",
      evm_votes: 8533,
      postal_votes: 56,
      total_votes: 8589,
      percentage_of_votes: 0.65,
    },
    {
      serial_no: 5,
      candidate: "BOYA SURESH",
      party: "Independent",
      evm_votes: 5891,
      postal_votes: 9,
      total_votes: 5900,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 6,
      candidate: "T. BEECHUPALLI",
      party: "Independent",
      evm_votes: 5125,
      postal_votes: 2,
      total_votes: 5127,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 7,
      candidate: "VIJAYA BHASKAR .REDDYPOGU",
      party: "Independent",
      evm_votes: 3504,
      postal_votes: 4,
      total_votes: 3508,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 8,
      candidate: "PAGADALA KODANDA",
      party: "Independent",
      evm_votes: 3070,
      postal_votes: 6,
      total_votes: 3076,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 9,
      candidate: "DEVARAPOGU MADDILETY",
      party: "Independent",
      evm_votes: 2576,
      postal_votes: 6,
      total_votes: 2582,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 10,
      candidate: "PRAKASH REDDY RALAMPADU",
      party: "Independent",
      evm_votes: 2222,
      postal_votes: 3,
      total_votes: 2225,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 11,
      candidate: "JAHANGIR AHMED",
      party: "Social Democratic Party Of India",
      evm_votes: 2058,
      postal_votes: 16,
      total_votes: 2074,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 12,
      candidate: "DR. REDDYPOGU PRAVEEN KUMAR",
      party: "Independent",
      evm_votes: 1847,
      postal_votes: 9,
      total_votes: 1856,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 13,
      candidate: "ABDUL SATTAR A.S.R",
      party: "Anna YSR Congress Party",
      evm_votes: 1565,
      postal_votes: 36,
      total_votes: 1601,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "VALMIKI ARJUNA",
      party: "Bharatha Chaitanya Yuvajana Party",
      evm_votes: 1385,
      postal_votes: 5,
      total_votes: 1390,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "URUKUNDU VADDE",
      party: "Independent",
      evm_votes: 1284,
      postal_votes: 11,
      total_votes: 1295,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 16,
      candidate: "MALLEPOGU NAGANNA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1162,
      postal_votes: 2,
      total_votes: 1164,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 17,
      candidate: "SUNKARA SREEDHAR",
      party: "Jai Bharat National Party",
      evm_votes: 1149,
      postal_votes: 14,
      total_votes: 1163,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 18,
      candidate: "TARIGOPULA RAJABABU",
      party: "All Peoples Party",
      evm_votes: 1032,
      postal_votes: 1,
      total_votes: 1033,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 19,
      candidate: "AMRUTH KUMAR SALKAPURAM",
      party: "Liberation Congress Party",
      evm_votes: 777,
      postal_votes: 6,
      total_votes: 783,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 20,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10394,
      postal_votes: 117,
      total_votes: 10511,
      percentage_of_votes: 0.79,
    },
  ],
  total: {
    evm_votes: 1316682,
    postal_votes: 14098,
    total_votes: 1330780,
  },
};
export const s0119 = {
  cs_name: "Ananthapur",
  cs_shortname: "ananthapur",
  cs_code: "s0119",
  winner: "TDP",
  stats: [
    {
      serial_no: 1,
      candidate: "AMBICA G LAKSHMINARAYANA VALMIKI",
      party: "Telugu Desam",
      evm_votes: 755862,
      postal_votes: 12383,
      total_votes: 768245,
      percentage_of_votes: 53.33,
    },
    {
      serial_no: 2,
      candidate: "MALAGUNDLA SANKAR NARAYANA",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 573769,
      postal_votes: 5921,
      total_votes: 579690,
      percentage_of_votes: 40.24,
    },
    {
      serial_no: 3,
      candidate: "MALLIKARJUNA VAJJALA",
      party: "Indian National Congress",
      evm_votes: 41954,
      postal_votes: 1263,
      total_votes: 43217,
      percentage_of_votes: 3,
    },
    {
      serial_no: 4,
      candidate: "G.V.RAMANA REDDY",
      party: "Independent",
      evm_votes: 6436,
      postal_votes: 3,
      total_votes: 6439,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 5,
      candidate: "BANASI NARAYANAPPA",
      party: "Bahujan Samaj Party",
      evm_votes: 6280,
      postal_votes: 116,
      total_votes: 6396,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 6,
      candidate: "V.MURALI PRASAD REDDY",
      party: "Independent",
      evm_votes: 5775,
      postal_votes: 3,
      total_votes: 5778,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 7,
      candidate: "DR.NALLANI RAMESH NAIDU",
      party: "Andhra Rastra Praja Samithi",
      evm_votes: 4862,
      postal_votes: 127,
      total_votes: 4989,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 8,
      candidate: "M.SURENDRA",
      party: "Pyramid Party of India",
      evm_votes: 2927,
      postal_votes: 8,
      total_votes: 2935,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 9,
      candidate: "U.C.VENKATASUBBAIAH",
      party: "Jaathia Chethi Vruthula Ikya Vedika Party",
      evm_votes: 2533,
      postal_votes: 7,
      total_votes: 2540,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 10,
      candidate: "P.CHOWDESWARI",
      party: "Independent",
      evm_votes: 1514,
      postal_votes: 4,
      total_votes: 1518,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 11,
      candidate: "VADLA KESAVAIAH",
      party: "Independent",
      evm_votes: 1488,
      postal_votes: 6,
      total_votes: 1494,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 12,
      candidate: "B.RAGHAVENDRA PRASAD",
      party: "Independent",
      evm_votes: 1317,
      postal_votes: 1,
      total_votes: 1318,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "KETHIREDDY RAGHUNATHAREDDY",
      party: "Independent",
      evm_votes: 1086,
      postal_votes: 5,
      total_votes: 1091,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "LOKANATH GOWD SURAGOWINI",
      party: "Independent",
      evm_votes: 1085,
      postal_votes: 6,
      total_votes: 1091,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "SREERANGARAJULA GOPINATH ROYAL",
      party: "Independent",
      evm_votes: 903,
      postal_votes: 1,
      total_votes: 904,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 16,
      candidate: "SHAIK.NIZAM",
      party: "Samajwadi Party",
      evm_votes: 749,
      postal_votes: 4,
      total_votes: 753,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 17,
      candidate: "RAMAVTH CHINNA RAMAPPA NAIK",
      party: "Jaibhim Rao Bharat Party",
      evm_votes: 735,
      postal_votes: 10,
      total_votes: 745,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 18,
      candidate: "KURUBA MALLIKARJUNA.S.",
      party: "All India Kisan Janatha party",
      evm_votes: 574,
      postal_votes: 4,
      total_votes: 578,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 19,
      candidate: "B.NAGAMUTHYALU",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 566,
      postal_votes: 4,
      total_votes: 570,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 20,
      candidate: "SRINIVASA RAJU.O",
      party: "Bhartiya Panchyat Party",
      evm_votes: 489,
      postal_votes: 5,
      total_votes: 494,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 21,
      candidate: "DURGA PRASAD D BYATARAYANAJI",
      party: "Challengers Party",
      evm_votes: 439,
      postal_votes: 5,
      total_votes: 444,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 22,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9014,
      postal_votes: 185,
      total_votes: 9199,
      percentage_of_votes: 0.64,
    },
  ],
  total: {
    evm_votes: 1420357,
    postal_votes: 20071,
    total_votes: 1440428,
  },
};
export const s0120 = {
  cs_name: "Hindupur",
  cs_shortname: "hindupur",
  cs_code: "s0120",
  winner: "TDP",
  stats: [
    {
      serial_no: 1,
      candidate: "B K PARTHASARATHI",
      party: "Telugu Desam",
      evm_votes: 713013,
      postal_votes: 12521,
      total_votes: 725534,
      percentage_of_votes: 51.23,
    },
    {
      serial_no: 2,
      candidate: "J SHANTHA",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 587406,
      postal_votes: 5701,
      total_votes: 593107,
      percentage_of_votes: 41.88,
    },
    {
      serial_no: 3,
      candidate: "B A SAMAD SHAHEEN",
      party: "Indian National Congress",
      evm_votes: 53989,
      postal_votes: 1070,
      total_votes: 55059,
      percentage_of_votes: 3.89,
    },
    {
      serial_no: 4,
      candidate: "BHAGYA R S",
      party: "Bahujan Samaj Party",
      evm_votes: 7949,
      postal_votes: 66,
      total_votes: 8015,
      percentage_of_votes: 0.57,
    },
    {
      serial_no: 5,
      candidate: "PRATHAP REDDY GORLA",
      party: "Independent",
      evm_votes: 5380,
      postal_votes: 64,
      total_votes: 5444,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 6,
      candidate: "H D HANUMANTHE GOWD",
      party: "Independent",
      evm_votes: 2270,
      postal_votes: 8,
      total_votes: 2278,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 7,
      candidate: "ANJINAPPA GARI SREENIVASULU",
      party: "Revolutionary Socialist Party",
      evm_votes: 2045,
      postal_votes: 6,
      total_votes: 2051,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 8,
      candidate: "S N SURESH",
      party: "Independent",
      evm_votes: 1611,
      postal_votes: 20,
      total_votes: 1631,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 9,
      candidate: "DASAGANIPALLI KULLAYAPPA",
      party: "Independent",
      evm_votes: 1317,
      postal_votes: 11,
      total_votes: 1328,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 10,
      candidate: "ASHOK",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1282,
      postal_votes: 10,
      total_votes: 1292,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 11,
      candidate: "T SUDHAKAR REDDY",
      party: "Independent",
      evm_votes: 1227,
      postal_votes: 10,
      total_votes: 1237,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 12,
      candidate: "S RAGHUNATHA REDDY",
      party: "Independent",
      evm_votes: 693,
      postal_votes: 17,
      total_votes: 710,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "BUDILI DHANUNJAYA",
      party: "National Nava Kranthi Party",
      evm_votes: 679,
      postal_votes: 3,
      total_votes: 682,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 17826,
      postal_votes: 145,
      total_votes: 17971,
      percentage_of_votes: 1.27,
    },
  ],
  total: {
    evm_votes: 1396687,
    postal_votes: 19652,
    total_votes: 1416339,
  },
};
export const s0122 = {
  cs_name: "Nellore",
  cs_shortname: "nellore",
  cs_code: "s0122",
  winner: "TDP",
  stats: [
    {
      serial_no: 1,
      candidate: "PRABHAKAR REDDY VEMIREDDY",
      party: "Telugu Desam",
      evm_votes: 750771,
      postal_votes: 15431,
      total_votes: 766202,
      percentage_of_votes: 55.7,
    },
    {
      serial_no: 2,
      candidate: "VENUMBAKA VIJAYASAI REDDY",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 514375,
      postal_votes: 5925,
      total_votes: 520300,
      percentage_of_votes: 37.82,
    },
    {
      serial_no: 3,
      candidate: "KOPPULA RAJU",
      party: "Indian National Congress",
      evm_votes: 53264,
      postal_votes: 1580,
      total_votes: 54844,
      percentage_of_votes: 3.99,
    },
    {
      serial_no: 4,
      candidate: "BHASKAR NASINA",
      party: "Bahujan Samaj Party",
      evm_votes: 5710,
      postal_votes: 101,
      total_votes: 5811,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 5,
      candidate: "VIVEKA MANNEPALLI",
      party: "Revolutionary Socialist Party",
      evm_votes: 2794,
      postal_votes: 10,
      total_votes: 2804,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 6,
      candidate: "KOPPALA RAGHU",
      party: "Independent",
      evm_votes: 2010,
      postal_votes: 5,
      total_votes: 2015,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 7,
      candidate: "GAJULA SOMA SEKHAR",
      party: "Jai Bharat National Party",
      evm_votes: 1775,
      postal_votes: 4,
      total_votes: 1779,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 8,
      candidate: "SHAIK MUZIBUR RAHAMAN",
      party: "Pyramid Party of India",
      evm_votes: 1315,
      postal_votes: 15,
      total_votes: 1330,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 9,
      candidate: "SHAIK MAHABOOB BASHA (MABU)",
      party: "Republican Party of India (A)",
      evm_votes: 1228,
      postal_votes: 5,
      total_votes: 1233,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 10,
      candidate: "MALLEPALLY RAGHU",
      party: "Navarang Congress Party",
      evm_votes: 1104,
      postal_votes: 1,
      total_votes: 1105,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 11,
      candidate: "SHAIK SHAFI AHMED",
      party: "All India Forward Bloc",
      evm_votes: 1037,
      postal_votes: 0,
      total_votes: 1037,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 12,
      candidate: "NAKKA DINESH",
      party: "Bharatha Chaitanya Yuvajana Party",
      evm_votes: 576,
      postal_votes: 24,
      total_votes: 600,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 13,
      candidate: "TALARI VENKATAIAH",
      party: "Jaibhim Rao Bharat Party",
      evm_votes: 553,
      postal_votes: 5,
      total_votes: 558,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 14,
      candidate: "PUNGANURU PRASANNA GANAPATHI",
      party: "Radical Party of India (Ambedkarist)",
      evm_votes: 452,
      postal_votes: 2,
      total_votes: 454,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 15454,
      postal_votes: 123,
      total_votes: 15577,
      percentage_of_votes: 1.13,
    },
  ],
  total: {
    evm_votes: 1352418,
    postal_votes: 23231,
    total_votes: 1375649,
  },
};
export const s0125 = {
  cs_name: "Chittoor",
  cs_shortname: "chittoor",
  cs_code: "s0125",
  winner: "TDP",
  stats: [
    {
      serial_no: 1,
      candidate: "DAGGUMALLA PRASADA RAO",
      party: "Telugu Desam",
      evm_votes: 765536,
      postal_votes: 12535,
      total_votes: 778071,
      percentage_of_votes: 54.84,
    },
    {
      serial_no: 2,
      candidate: "REDDEPPA.N .",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 552469,
      postal_votes: 5123,
      total_votes: 557592,
      percentage_of_votes: 39.3,
    },
    {
      serial_no: 3,
      candidate: "MOTUKUR JAGAPATHI",
      party: "Indian National Congress",
      evm_votes: 29431,
      postal_votes: 719,
      total_votes: 30150,
      percentage_of_votes: 2.12,
    },
    {
      serial_no: 4,
      candidate: "THOGURU VIJAYA KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 13389,
      postal_votes: 122,
      total_votes: 13511,
      percentage_of_votes: 0.95,
    },
    {
      serial_no: 5,
      candidate: "YUGANDAR . KONDA",
      party: "Independent",
      evm_votes: 6889,
      postal_votes: 84,
      total_votes: 6973,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 6,
      candidate: "K.MUNI KRISHNA",
      party: "Independent",
      evm_votes: 3954,
      postal_votes: 2,
      total_votes: 3956,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 7,
      candidate: "P.JANAKI RAMA RAO ( BABU)",
      party: "National Maha Sabha Party",
      evm_votes: 2822,
      postal_votes: 11,
      total_votes: 2833,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 8,
      candidate: "PETA.MOHAN .",
      party: "Independent",
      evm_votes: 2365,
      postal_votes: 1,
      total_votes: 2366,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 9,
      candidate: "C.SUDHAKAR",
      party: "Independent",
      evm_votes: 1934,
      postal_votes: 1,
      total_votes: 1935,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "N.CHANDRABABU",
      party: "Independent",
      evm_votes: 1878,
      postal_votes: 7,
      total_votes: 1885,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "MADASI SUDHARSHAN",
      party: "Independent",
      evm_votes: 1575,
      postal_votes: 3,
      total_votes: 1578,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 12,
      candidate: "D.MAHESH",
      party: "Independent",
      evm_votes: 1109,
      postal_votes: 10,
      total_votes: 1119,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "P.MADHU",
      party: "Independent",
      evm_votes: 902,
      postal_votes: 4,
      total_votes: 906,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 14,
      candidate: "N.BHUPATHI",
      party: "Independent",
      evm_votes: 862,
      postal_votes: 32,
      total_votes: 894,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "A. PURNA CHANDRA",
      party: "Independent",
      evm_votes: 856,
      postal_votes: 1,
      total_votes: 857,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 16,
      candidate: "PALLIPATTU.ABHINAV VISHNU",
      party: "Samajwadi Party",
      evm_votes: 670,
      postal_votes: 6,
      total_votes: 676,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 17,
      candidate: "N.GANGAIAH .",
      party: "Independent",
      evm_votes: 547,
      postal_votes: 5,
      total_votes: 552,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 18,
      candidate: "T NARESH",
      party: "Independent",
      evm_votes: 532,
      postal_votes: 2,
      total_votes: 534,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 19,
      candidate: "BANDAPALLI RAMESH",
      party: "Independent",
      evm_votes: 490,
      postal_votes: 3,
      total_votes: 493,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 20,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11812,
      postal_votes: 164,
      total_votes: 11976,
      percentage_of_votes: 0.84,
    },
  ],
  total: {
    evm_votes: 1400022,
    postal_votes: 18835,
    total_votes: 1418857,
  },
};
export const s041 = {
  cs_name: "Valmiki_Nagar",
  cs_shortname: "valmikinagar",
  cs_code: "s041",
  winner: "JD(U)",
  stats: [
    {
      serial_no: 1,
      candidate: "SUNIL KUMAR",
      party: "Janata Dal (United)",
      evm_votes: 522495,
      postal_votes: 927,
      total_votes: 523422,
      percentage_of_votes: 47.5,
    },
    {
      serial_no: 2,
      candidate: "DEEPAK YADAV",
      party: "Rashtriya Janata Dal",
      evm_votes: 422959,
      postal_votes: 1788,
      total_votes: 424747,
      percentage_of_votes: 38.55,
    },
    {
      serial_no: 3,
      candidate: "PRAVESH KUMAR MISHRA @ ANUP",
      party: "Independent",
      evm_votes: 23195,
      postal_votes: 30,
      total_votes: 23225,
      percentage_of_votes: 2.11,
    },
    {
      serial_no: 4,
      candidate: "DINESH AGRAWAL",
      party: "Independent",
      evm_votes: 19787,
      postal_votes: 23,
      total_votes: 19810,
      percentage_of_votes: 1.8,
    },
    {
      serial_no: 5,
      candidate: "DURGESH SINGH CHAUHAN",
      party: "Bahujan Samaj Party",
      evm_votes: 18739,
      postal_votes: 77,
      total_votes: 18816,
      percentage_of_votes: 1.71,
    },
    {
      serial_no: 6,
      candidate: "CHANDRESHWAR MISHRA",
      party: "Independent",
      evm_votes: 13590,
      postal_votes: 8,
      total_votes: 13598,
      percentage_of_votes: 1.23,
    },
    {
      serial_no: 7,
      candidate: "SHAFI MOHAMMAD MIYA",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 13419,
      postal_votes: 13,
      total_votes: 13432,
      percentage_of_votes: 1.22,
    },
    {
      serial_no: 8,
      candidate: "PARSHURAM SAH",
      party: "Independent",
      evm_votes: 12142,
      postal_votes: 8,
      total_votes: 12150,
      percentage_of_votes: 1.1,
    },
    {
      serial_no: 9,
      candidate: "NABA KUMAR SARANIA (HIRA BHAI)",
      party: "Gana Suraksha Party",
      evm_votes: 11420,
      postal_votes: 3,
      total_votes: 11423,
      percentage_of_votes: 1.04,
    },
    {
      serial_no: 10,
      candidate: "SHAMBHU PRASAD",
      party: "Independent",
      evm_votes: 10478,
      postal_votes: 20,
      total_votes: 10498,
      percentage_of_votes: 0.95,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 30673,
      postal_votes: 36,
      total_votes: 30709,
      percentage_of_votes: 2.79,
    },
  ],
  total: {
    evm_votes: 1098897,
    postal_votes: 2933,
    total_votes: 1101830,
  },
};
export const s044 = {
  cs_name: "Sheohar",
  cs_shortname: "sheohar",
  cs_code: "s044",
  winner: "JD(U)",
  stats: [
    {
      serial_no: 1,
      candidate: "LOVELY ANAND",
      party: "Janata Dal (United)",
      evm_votes: 475709,
      postal_votes: 903,
      total_votes: 476612,
      percentage_of_votes: 45.15,
    },
    {
      serial_no: 2,
      candidate: "RITU JAISWAL",
      party: "Rashtriya Janata Dal",
      evm_votes: 445482,
      postal_votes: 1987,
      total_votes: 447469,
      percentage_of_votes: 42.39,
    },
    {
      serial_no: 3,
      candidate: "AKHILESHWAR SHRIVAISHANV",
      party: "Independent",
      evm_votes: 29014,
      postal_votes: 80,
      total_votes: 29094,
      percentage_of_votes: 2.76,
    },
    {
      serial_no: 4,
      candidate: "KANHAIYA KUMAR",
      party: "Independent",
      evm_votes: 20585,
      postal_votes: 7,
      total_votes: 20592,
      percentage_of_votes: 1.95,
    },
    {
      serial_no: 5,
      candidate: "RANA RANJIT",
      party: "All India Majlis-E-Ittehadul Muslimeen",
      evm_votes: 11947,
      postal_votes: 32,
      total_votes: 11979,
      percentage_of_votes: 1.13,
    },
    {
      serial_no: 6,
      candidate: "SUDHIR KUMAR SINGH",
      party: "Viro Ke Vir Indian Party",
      evm_votes: 9096,
      postal_votes: 6,
      total_votes: 9102,
      percentage_of_votes: 0.86,
    },
    {
      serial_no: 7,
      candidate: "VIJENDRA THAKUR",
      party: "Bahujan Samaj Party",
      evm_votes: 8865,
      postal_votes: 23,
      total_votes: 8888,
      percentage_of_votes: 0.84,
    },
    {
      serial_no: 8,
      candidate: "UPENDRA SAHANI",
      party: "Rashtriya Jansambhavna Party",
      evm_votes: 6332,
      postal_votes: 6,
      total_votes: 6338,
      percentage_of_votes: 0.6,
    },
    {
      serial_no: 9,
      candidate: "MAMTA KUMARI",
      party: "Samata Party",
      evm_votes: 5431,
      postal_votes: 4,
      total_votes: 5435,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 10,
      candidate: "MOHAMMAD MAHTAB ALAM",
      party: "Samaj Shakti Party",
      evm_votes: 4867,
      postal_votes: 4,
      total_votes: 4871,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 11,
      candidate: "JAGDISH PRASAD",
      party: "Bajjikanchal Vikas Party",
      evm_votes: 2950,
      postal_votes: 5,
      total_votes: 2955,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 12,
      candidate: "DILIP KUMAR MISHRA",
      party: "Prabal Bharat Party",
      evm_votes: 2097,
      postal_votes: 2,
      total_votes: 2099,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 30130,
      postal_votes: 38,
      total_votes: 30168,
      percentage_of_votes: 2.86,
    },
  ],
  total: {
    evm_votes: 1052505,
    postal_votes: 3097,
    total_votes: 1055602,
  },
};
export const s045 = {
  cs_name: "Sitamarhi",
  cs_shortname: "sitamarhi",
  cs_code: "s045",
  winner: "JD(U)",
  stats: [
    {
      serial_no: 1,
      candidate: "DEVESH CHANDRA THAKUR",
      party: "Janata Dal (United)",
      evm_votes: 514940,
      postal_votes: 779,
      total_votes: 515719,
      percentage_of_votes: 47.14,
    },
    {
      serial_no: 2,
      candidate: "ARJUN RAY",
      party: "Rashtriya Janata Dal",
      evm_votes: 463107,
      postal_votes: 1256,
      total_votes: 464363,
      percentage_of_votes: 42.45,
    },
    {
      serial_no: 3,
      candidate: "SAIQUA NAWAZ AZMAT",
      party: "Independent",
      evm_votes: 28911,
      postal_votes: 1,
      total_votes: 28912,
      percentage_of_votes: 2.64,
    },
    {
      serial_no: 4,
      candidate: "SHYAM NANDAN KISHOR PRASAD",
      party: "Independent",
      evm_votes: 11173,
      postal_votes: 15,
      total_votes: 11188,
      percentage_of_votes: 1.02,
    },
    {
      serial_no: 5,
      candidate: "BHOLA SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 8773,
      postal_votes: 37,
      total_votes: 8810,
      percentage_of_votes: 0.81,
    },
    {
      serial_no: 6,
      candidate: "UPENDRA SAHANI",
      party: "Rashtriya Jansambhavna Party",
      evm_votes: 8446,
      postal_votes: 8,
      total_votes: 8454,
      percentage_of_votes: 0.77,
    },
    {
      serial_no: 7,
      candidate: "CHANDRIKA PRASAD",
      party: "Independent",
      evm_votes: 7109,
      postal_votes: 5,
      total_votes: 7114,
      percentage_of_votes: 0.65,
    },
    {
      serial_no: 8,
      candidate: "VINOD SAH",
      party: "Independent",
      evm_votes: 4477,
      postal_votes: 4,
      total_votes: 4481,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 9,
      candidate: "KRISHNA KISHORE",
      party: "Independent",
      evm_votes: 3280,
      postal_votes: 0,
      total_votes: 3280,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 10,
      candidate: "MUSHAHID RAZA",
      party: "Desh Janhit Party",
      evm_votes: 2190,
      postal_votes: 2,
      total_votes: 2192,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 11,
      candidate: "UMESH KUMAR YADAV",
      party: "Independent",
      evm_votes: 2126,
      postal_votes: 2,
      total_votes: 2128,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 12,
      candidate: "RAJENDRA MAHTO",
      party: "Karpoori Janta Dal",
      evm_votes: 1976,
      postal_votes: 5,
      total_votes: 1981,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 13,
      candidate: "ANAND KUMAR",
      party: "Independent",
      evm_votes: 1787,
      postal_votes: 9,
      total_votes: 1796,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 14,
      candidate: "ABDUL SAMAD",
      party: "Independent",
      evm_votes: 1439,
      postal_votes: 2,
      total_votes: 1441,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 32102,
      postal_votes: 31,
      total_votes: 32133,
      percentage_of_votes: 2.94,
    },
  ],
  total: {
    evm_votes: 1091836,
    postal_votes: 2156,
    total_votes: 1093992,
  },
};
export const s047 = {
  cs_name: "Jhanjharpur",
  cs_shortname: "jhanjharpur",
  cs_code: "s047",
  winner: "JD(U)",
  stats: [
    {
      serial_no: 1,
      candidate: "RAMPRIT MANDAL",
      party: "Janata Dal (United)",
      evm_votes: 532138,
      postal_votes: 894,
      total_votes: 533032,
      percentage_of_votes: 48.73,
    },
    {
      serial_no: 2,
      candidate: "SUMAN KUMAR MAHASETH",
      party: "Vikassheel Insaan Party",
      evm_votes: 347989,
      postal_votes: 874,
      total_votes: 348863,
      percentage_of_votes: 31.9,
    },
    {
      serial_no: 3,
      candidate: "GULAB YADAV",
      party: "Bahujan Samaj Party",
      evm_votes: 73532,
      postal_votes: 352,
      total_votes: 73884,
      percentage_of_votes: 6.76,
    },
    {
      serial_no: 4,
      candidate: "RAM PRASAD RAUT",
      party: "Independent",
      evm_votes: 25993,
      postal_votes: 17,
      total_votes: 26010,
      percentage_of_votes: 2.38,
    },
    {
      serial_no: 5,
      candidate: "GANGA PRASAD YADAV",
      party: "Independent",
      evm_votes: 18064,
      postal_votes: 10,
      total_votes: 18074,
      percentage_of_votes: 1.65,
    },
    {
      serial_no: 6,
      candidate: "GAURI SHANKAR SAHU",
      party: "Independent",
      evm_votes: 14307,
      postal_votes: 18,
      total_votes: 14325,
      percentage_of_votes: 1.31,
    },
    {
      serial_no: 7,
      candidate: "RAJIV KUMAR JHA",
      party: "Independent",
      evm_votes: 12488,
      postal_votes: 25,
      total_votes: 12513,
      percentage_of_votes: 1.14,
    },
    {
      serial_no: 8,
      candidate: "BABLU KUMAR",
      party: "Aadarsh Mithila Party",
      evm_votes: 11638,
      postal_votes: 35,
      total_votes: 11673,
      percentage_of_votes: 1.07,
    },
    {
      serial_no: 9,
      candidate: "VIDYA NAND RAM",
      party: "Wazib Adhikar Party",
      evm_votes: 10843,
      postal_votes: 19,
      total_votes: 10862,
      percentage_of_votes: 0.99,
    },
    {
      serial_no: 10,
      candidate: "VIJAY KUMAR MANDAL",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 8556,
      postal_votes: 21,
      total_votes: 8577,
      percentage_of_votes: 0.78,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 35846,
      postal_votes: 82,
      total_votes: 35928,
      percentage_of_votes: 3.28,
    },
  ],
  total: {
    evm_votes: 1091394,
    postal_votes: 2347,
    total_votes: 1093741,
  },
};
export const s048 = {
  cs_name: "Supaul",
  cs_shortname: "supaul",
  cs_code: "s048",
  winner: "JD(U)",
  stats: [
    {
      serial_no: 1,
      candidate: "DILESHWAR KAMAIT",
      party: "Janata Dal (United)",
      evm_votes: 592922,
      postal_votes: 2116,
      total_votes: 595038,
      percentage_of_votes: 48.33,
    },
    {
      serial_no: 2,
      candidate: "CHANDRAHAS CHAUPAL",
      party: "Rashtriya Janata Dal",
      evm_votes: 422037,
      postal_votes: 3198,
      total_votes: 425235,
      percentage_of_votes: 34.54,
    },
    {
      serial_no: 3,
      candidate: "BAIDYA NATH MEHTA",
      party: "Independent",
      evm_votes: 51325,
      postal_votes: 327,
      total_votes: 51652,
      percentage_of_votes: 4.2,
    },
    {
      serial_no: 4,
      candidate: "YOG NARAYAN SINGH",
      party: "Independent",
      evm_votes: 27447,
      postal_votes: 7,
      total_votes: 27454,
      percentage_of_votes: 2.23,
    },
    {
      serial_no: 5,
      candidate: "VINDESHWARI PRASAD",
      party: "Independent",
      evm_votes: 18450,
      postal_votes: 7,
      total_votes: 18457,
      percentage_of_votes: 1.5,
    },
    {
      serial_no: 6,
      candidate: "KIRAN DEVI",
      party: "Bahujan Samaj Party",
      evm_votes: 17961,
      postal_votes: 118,
      total_votes: 18079,
      percentage_of_votes: 1.47,
    },
    {
      serial_no: 7,
      candidate: "MOHD KALIM KHAN",
      party: "Rashtriya Jansambhavna Party",
      evm_votes: 14064,
      postal_votes: 14,
      total_votes: 14078,
      percentage_of_votes: 1.14,
    },
    {
      serial_no: 8,
      candidate: "UMESH PRASAD SAH",
      party: "Jai Hind Party",
      evm_votes: 12971,
      postal_votes: 43,
      total_votes: 13014,
      percentage_of_votes: 1.06,
    },
    {
      serial_no: 9,
      candidate: "RAJ KUMAR YADAV",
      party: "Viro Ke Vir Indian Party",
      evm_votes: 10795,
      postal_votes: 11,
      total_votes: 10806,
      percentage_of_votes: 0.88,
    },
    {
      serial_no: 10,
      candidate: "RAMESH KUMAR ANAND",
      party: "Proutist Bloc, India",
      evm_votes: 9439,
      postal_votes: 12,
      total_votes: 9451,
      percentage_of_votes: 0.77,
    },
    {
      serial_no: 11,
      candidate: "ABDUL MATIN",
      party: "Independent",
      evm_votes: 7177,
      postal_votes: 17,
      total_votes: 7194,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 12,
      candidate: "BAMBAM KUMAR",
      party: "Independent",
      evm_votes: 7000,
      postal_votes: 6,
      total_votes: 7006,
      percentage_of_votes: 0.57,
    },
    {
      serial_no: 13,
      candidate: "NITISH KUMAR",
      party: "Independent",
      evm_votes: 5894,
      postal_votes: 21,
      total_votes: 5915,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 14,
      candidate: "AJAY KUMAR SAH",
      party: "Independent",
      evm_votes: 5330,
      postal_votes: 21,
      total_votes: 5351,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 15,
      candidate: "SHIV HARI AGRAWAL",
      party: "Bharat Nirman Party",
      evm_votes: 3770,
      postal_votes: 2,
      total_votes: 3772,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 18444,
      postal_votes: 168,
      total_votes: 18612,
      percentage_of_votes: 1.51,
    },
  ],
  total: {
    evm_votes: 1225026,
    postal_votes: 6088,
    total_votes: 1231114,
  },
};
export const s0413 = {
  cs_name: "Madhepura",
  cs_shortname: "madhepura",
  cs_code: "s0413",
  winner: "JD(U)",
  stats: [
    {
      serial_no: 1,
      candidate: "DINESH CHANDRA YADAV",
      party: "Janata Dal (United)",
      evm_votes: 639758,
      postal_votes: 891,
      total_votes: 640649,
      percentage_of_votes: 52.96,
    },
    {
      serial_no: 2,
      candidate: "DA KUMAR CHANDRADEEP",
      party: "Rashtriya Janata Dal",
      evm_votes: 464982,
      postal_votes: 1133,
      total_votes: 466115,
      percentage_of_votes: 38.53,
    },
    {
      serial_no: 3,
      candidate: "SURESHWAR PODDAR",
      party: "Aadarsh Mithila Party",
      evm_votes: 15423,
      postal_votes: 11,
      total_votes: 15434,
      percentage_of_votes: 1.28,
    },
    {
      serial_no: 4,
      candidate: "MD ARSHAD HUSSAIN",
      party: "Bahujan Samaj Party",
      evm_votes: 12947,
      postal_votes: 44,
      total_votes: 12991,
      percentage_of_votes: 1.07,
    },
    {
      serial_no: 5,
      candidate: "JAWAHAR LAL JAISWAL",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 11320,
      postal_votes: 2,
      total_votes: 11322,
      percentage_of_votes: 0.94,
    },
    {
      serial_no: 6,
      candidate: "UCHESHWAR PANDIT",
      party: "Samajhdar Party",
      evm_votes: 11127,
      postal_votes: 22,
      total_votes: 11149,
      percentage_of_votes: 0.92,
    },
    {
      serial_no: 7,
      candidate: "PRO KAMESHWAR YADAV",
      party: "Bharatiya Jan Kranti Dal (Democratic)",
      evm_votes: 10665,
      postal_votes: 9,
      total_votes: 10674,
      percentage_of_votes: 0.88,
    },
    {
      serial_no: 8,
      candidate: "AJABLAL MEHTA",
      party: "Yuva Krantikari Party",
      evm_votes: 8720,
      postal_votes: 16,
      total_votes: 8736,
      percentage_of_votes: 0.72,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 32557,
      postal_votes: 68,
      total_votes: 32625,
      percentage_of_votes: 2.7,
    },
  ],
  total: {
    evm_votes: 1207499,
    postal_votes: 2196,
    total_votes: 1209695,
  },
};
export const s0417 = {
  cs_name: "Gopalganj",
  cs_shortname: "gopalganj",
  cs_code: "s0417",
  winner: "JD(U)",
  stats: [
    {
      serial_no: 1,
      candidate: "DR. ALOK KUMAR SUMAN",
      party: "Janata Dal (United)",
      evm_votes: 510329,
      postal_votes: 1537,
      total_votes: 511866,
      percentage_of_votes: 48.15,
    },
    {
      serial_no: 2,
      candidate: "PREM NATH CHANCHAL ALIAS CHANCHAL PASWAN",
      party: "Vikassheel Insaan Party",
      evm_votes: 383065,
      postal_votes: 1621,
      total_votes: 384686,
      percentage_of_votes: 36.19,
    },
    {
      serial_no: 3,
      candidate: "DINANATH MANJHI",
      party: "All India Majlis-E-Ittehadul Muslimeen",
      evm_votes: 29900,
      postal_votes: 92,
      total_votes: 29992,
      percentage_of_votes: 2.82,
    },
    {
      serial_no: 4,
      candidate: "SUJEET KUMAR RAM",
      party: "Bahujan Samaj Party",
      evm_votes: 29145,
      postal_votes: 127,
      total_votes: 29272,
      percentage_of_votes: 2.75,
    },
    {
      serial_no: 5,
      candidate: "ANIL RAM",
      party: "Independent",
      evm_votes: 12988,
      postal_votes: 14,
      total_votes: 13002,
      percentage_of_votes: 1.22,
    },
    {
      serial_no: 6,
      candidate: "SATYENDRA BAITHA",
      party: "Independent",
      evm_votes: 12253,
      postal_votes: 16,
      total_votes: 12269,
      percentage_of_votes: 1.15,
    },
    {
      serial_no: 7,
      candidate: "BHOLA HARIJAN",
      party: "Independent",
      evm_votes: 11138,
      postal_votes: 2,
      total_votes: 11140,
      percentage_of_votes: 1.05,
    },
    {
      serial_no: 8,
      candidate: "RAM KUMAR MANJHI",
      party: "Gana Suraksha Party",
      evm_votes: 8531,
      postal_votes: 9,
      total_votes: 8540,
      percentage_of_votes: 0.8,
    },
    {
      serial_no: 9,
      candidate: "NAMI RAM",
      party: "Independent",
      evm_votes: 6716,
      postal_votes: 0,
      total_votes: 6716,
      percentage_of_votes: 0.63,
    },
    {
      serial_no: 10,
      candidate: "SURENDRA RAM",
      party: "Bhartiya Rashtriya Dal",
      evm_votes: 6673,
      postal_votes: 39,
      total_votes: 6712,
      percentage_of_votes: 0.63,
    },
    {
      serial_no: 11,
      candidate: "JITENDRA RAM",
      party: "Bahujan Mukti Party",
      evm_votes: 5942,
      postal_votes: 27,
      total_votes: 5969,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 42713,
      postal_votes: 150,
      total_votes: 42863,
      percentage_of_votes: 4.03,
    },
  ],
  total: {
    evm_votes: 1059393,
    postal_votes: 3634,
    total_votes: 1063027,
  },
};
export const s0418 = {
  cs_name: "Siwan",
  cs_shortname: "siwan",
  cs_code: "s0418",
  winner: "JD(U)",
  stats: [
    {
      serial_no: 1,
      candidate: "VIJAYLAKSHMI DEVI",
      party: "Janata Dal (United)",
      evm_votes: 385625,
      postal_votes: 883,
      total_votes: 386508,
      percentage_of_votes: 38.73,
    },
    {
      serial_no: 2,
      candidate: "HENA SHAHAB",
      party: "Independent",
      evm_votes: 293084,
      postal_votes: 567,
      total_votes: 293651,
      percentage_of_votes: 29.42,
    },
    {
      serial_no: 3,
      candidate: "AWADH BIHARI CHOUDHARY",
      party: "Rashtriya Janata Dal",
      evm_votes: 198063,
      postal_votes: 760,
      total_votes: 198823,
      percentage_of_votes: 19.92,
    },
    {
      serial_no: 4,
      candidate: "SATYENDRA KUSHWAHA",
      party: "Independent",
      evm_votes: 21558,
      postal_votes: 7,
      total_votes: 21565,
      percentage_of_votes: 2.16,
    },
    {
      serial_no: 5,
      candidate: "JEEWAN KUMAR",
      party: "Independent",
      evm_votes: 18489,
      postal_votes: 101,
      total_votes: 18590,
      percentage_of_votes: 1.86,
    },
    {
      serial_no: 6,
      candidate: "DILIP KU SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 12032,
      postal_votes: 80,
      total_votes: 12112,
      percentage_of_votes: 1.21,
    },
    {
      serial_no: 7,
      candidate: "SANJAY KUMAR SAH",
      party: "Bhartiya Lok Nayak Party",
      evm_votes: 8851,
      postal_votes: 28,
      total_votes: 8879,
      percentage_of_votes: 0.89,
    },
    {
      serial_no: 8,
      candidate: "DR. RAVINDRA NATH SHUKLA",
      party: "Independent",
      evm_votes: 8681,
      postal_votes: 12,
      total_votes: 8693,
      percentage_of_votes: 0.87,
    },
    {
      serial_no: 9,
      candidate: "DEVKANT MISHRA",
      party: "Independent",
      evm_votes: 6162,
      postal_votes: 19,
      total_votes: 6181,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 10,
      candidate: "PRAMOD KUMAR",
      party: "Independent",
      evm_votes: 5666,
      postal_votes: 2,
      total_votes: 5668,
      percentage_of_votes: 0.57,
    },
    {
      serial_no: 11,
      candidate: "PRAKASH MANI TIWARI",
      party: "Independent",
      evm_votes: 4503,
      postal_votes: 6,
      total_votes: 4509,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 12,
      candidate: "MAHENDRA SINGH ALIAS MAHENDRA RAI",
      party: "Independent",
      evm_votes: 3920,
      postal_votes: 2,
      total_votes: 3922,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 13,
      candidate: "DILIP KUMAR SINGH",
      party: "Independent",
      evm_votes: 1947,
      postal_votes: 6,
      total_votes: 1953,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 26925,
      postal_votes: 39,
      total_votes: 26964,
      percentage_of_votes: 2.7,
    },
  ],
  total: {
    evm_votes: 995506,
    postal_votes: 2512,
    total_votes: 998018,
  },
};
export const s0426 = {
  cs_name: "Bhagalpur",
  cs_shortname: "bhagalpur",
  cs_code: "s0426",
  winner: "JD(U)",
  stats: [
    {
      serial_no: 1,
      candidate: "AJAY KUMAR MANDAL",
      party: "Janata Dal (United)",
      evm_votes: 534923,
      postal_votes: 1108,
      total_votes: 536031,
      percentage_of_votes: 50.38,
    },
    {
      serial_no: 2,
      candidate: "AJEET SHARMA",
      party: "Indian National Congress",
      evm_votes: 430025,
      postal_votes: 1138,
      total_votes: 431163,
      percentage_of_votes: 40.52,
    },
    {
      serial_no: 3,
      candidate: "POONAM SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 12547,
      postal_votes: 99,
      total_votes: 12646,
      percentage_of_votes: 1.19,
    },
    {
      serial_no: 4,
      candidate: "HARERAM YADAV",
      party: "Independent",
      evm_votes: 12255,
      postal_votes: 3,
      total_votes: 12258,
      percentage_of_votes: 1.15,
    },
    {
      serial_no: 5,
      candidate: "DEEPAK KUMAR SINGH",
      party: "Rashtriya Jansambhavna Party",
      evm_votes: 7654,
      postal_votes: 16,
      total_votes: 7670,
      percentage_of_votes: 0.72,
    },
    {
      serial_no: 6,
      candidate: "CHHOTE LAL KUMAR",
      party: "Independent",
      evm_votes: 6677,
      postal_votes: 6,
      total_votes: 6683,
      percentage_of_votes: 0.63,
    },
    {
      serial_no: 7,
      candidate: "RAMESH TUDU",
      party: "Independent",
      evm_votes: 6629,
      postal_votes: 5,
      total_votes: 6634,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 8,
      candidate: "UMESH PRASAD YADAV",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 5105,
      postal_votes: 21,
      total_votes: 5126,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 9,
      candidate: "DEEPAK KUMAR",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 3758,
      postal_votes: 5,
      total_votes: 3763,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 10,
      candidate: "MUKESH KUMAR",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 3670,
      postal_votes: 11,
      total_votes: 3681,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 11,
      candidate: "DAYARAM MANDAL",
      party: "Lok Sewa Dal",
      evm_votes: 3509,
      postal_votes: 10,
      total_votes: 3519,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 12,
      candidate: "OM PRAKASH PODDAR",
      party: "Independent",
      evm_votes: 3091,
      postal_votes: 13,
      total_votes: 3104,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 31771,
      postal_votes: 32,
      total_votes: 31803,
      percentage_of_votes: 2.99,
    },
  ],
  total: {
    evm_votes: 1061614,
    postal_votes: 2467,
    total_votes: 1064081,
  },
};
export const s0427 = {
  cs_name: "Banka",
  cs_shortname: "banka",
  cs_code: "s0427",
  winner: "JD(U)",
  stats: [
    {
      serial_no: 1,
      candidate: "GIRIDHARI YADAV",
      party: "Janata Dal (United)",
      evm_votes: 505674,
      postal_votes: 1004,
      total_votes: 506678,
      percentage_of_votes: 49.96,
    },
    {
      serial_no: 2,
      candidate: "JAI PRAKASH NARAYAN YADAV",
      party: "Rashtriya Janata Dal",
      evm_votes: 401567,
      postal_votes: 1267,
      total_votes: 402834,
      percentage_of_votes: 39.72,
    },
    {
      serial_no: 3,
      candidate: "NARESH YADAV",
      party: "Independent",
      evm_votes: 14936,
      postal_votes: 3,
      total_votes: 14939,
      percentage_of_votes: 1.47,
    },
    {
      serial_no: 4,
      candidate: "JAY PRAKASH YADAV",
      party: "Rashtriya Jansambhavna Party",
      evm_votes: 12254,
      postal_votes: 23,
      total_votes: 12277,
      percentage_of_votes: 1.21,
    },
    {
      serial_no: 5,
      candidate: "AMRIT TANTI",
      party: "Bhartiya Dalit Party",
      evm_votes: 9615,
      postal_votes: 55,
      total_votes: 9670,
      percentage_of_votes: 0.95,
    },
    {
      serial_no: 6,
      candidate: "NARESH KUMAR PRIYADARSHI",
      party: "Independent",
      evm_votes: 8103,
      postal_votes: 22,
      total_votes: 8125,
      percentage_of_votes: 0.8,
    },
    {
      serial_no: 7,
      candidate: "GANESH KUMAR KUSHAWAHA",
      party: "Samata Party",
      evm_votes: 7627,
      postal_votes: 18,
      total_votes: 7645,
      percentage_of_votes: 0.75,
    },
    {
      serial_no: 8,
      candidate: "UTTAM KUMAR SINGH",
      party: "Aam Janta Party Rashtriya",
      evm_votes: 6874,
      postal_votes: 37,
      total_votes: 6911,
      percentage_of_votes: 0.68,
    },
    {
      serial_no: 9,
      candidate: "KAVINDRA PANDIT",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 5226,
      postal_votes: 20,
      total_votes: 5246,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 10,
      candidate: "UMAKANT YADAV",
      party: "Independent",
      evm_votes: 4932,
      postal_votes: 9,
      total_votes: 4941,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 34808,
      postal_votes: 81,
      total_votes: 34889,
      percentage_of_votes: 3.44,
    },
  ],
  total: {
    evm_votes: 1011616,
    postal_votes: 2539,
    total_votes: 1014155,
  },
};
export const s0428 = {
  cs_name: "Munger",
  cs_shortname: "munger",
  cs_code: "s0428",
  winner: "JD(U)",
  stats: [
    {
      serial_no: 1,
      candidate: "RAJIV RANJAN SINGH ALIAS LALAN SINGH",
      party: "Janata Dal (United)",
      evm_votes: 547814,
      postal_votes: 2332,
      total_votes: 550146,
      percentage_of_votes: 48.3,
    },
    {
      serial_no: 2,
      candidate: "KUMARI ANITA",
      party: "Rashtriya Janata Dal",
      evm_votes: 467412,
      postal_votes: 1864,
      total_votes: 469276,
      percentage_of_votes: 41.2,
    },
    {
      serial_no: 3,
      candidate: "SHANKAR PRASAD BIND",
      party: "Independent",
      evm_votes: 21016,
      postal_votes: 7,
      total_votes: 21023,
      percentage_of_votes: 1.85,
    },
    {
      serial_no: 4,
      candidate: "PRIYADARSHI PIYUSH",
      party: "Independent",
      evm_votes: 15718,
      postal_votes: 13,
      total_votes: 15731,
      percentage_of_votes: 1.38,
    },
    {
      serial_no: 5,
      candidate: "A. K. SINGH ‘ASHOK’",
      party: "Independent",
      evm_votes: 13809,
      postal_votes: 16,
      total_votes: 13825,
      percentage_of_votes: 1.21,
    },
    {
      serial_no: 6,
      candidate: "KUMAR NAVNEET HIMANSHU",
      party: "Bahujan Samaj Party",
      evm_votes: 12143,
      postal_votes: 137,
      total_votes: 12280,
      percentage_of_votes: 1.08,
    },
    {
      serial_no: 7,
      candidate: "KULDIP YADAV",
      party: "Hindustan People's Party (Democratic)",
      evm_votes: 9455,
      postal_votes: 31,
      total_votes: 9486,
      percentage_of_votes: 0.83,
    },
    {
      serial_no: 8,
      candidate: "NEETISH KUMAR",
      party: "Independent",
      evm_votes: 7470,
      postal_votes: 37,
      total_votes: 7507,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 9,
      candidate: "PRAVAL KUMAR",
      party: "Independent",
      evm_votes: 5366,
      postal_votes: 9,
      total_votes: 5375,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 10,
      candidate: "RAVINDRA MANDAL",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 4893,
      postal_votes: 20,
      total_votes: 4913,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 11,
      candidate: "ADITYA SINGH MADHUKAR",
      party: "Independent",
      evm_votes: 4124,
      postal_votes: 16,
      total_votes: 4140,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 12,
      candidate: "PANKAJ KUMAR",
      party: "Independent",
      evm_votes: 3410,
      postal_votes: 18,
      total_votes: 3428,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 21920,
      postal_votes: 31,
      total_votes: 21951,
      percentage_of_votes: 1.93,
    },
  ],
  total: {
    evm_votes: 1134550,
    postal_votes: 4531,
    total_votes: 1139081,
  },
};
export const s0429 = {
  cs_name: "Nalanda",
  cs_shortname: "nalanda",
  cs_code: "s0429",
  winner: "JD(U)",
  stats: [
    {
      serial_no: 1,
      candidate: "KAUSHALENDRA KUMAR",
      party: "Janata Dal (United)",
      evm_votes: 557054,
      postal_votes: 2368,
      total_votes: 559422,
      percentage_of_votes: 48.88,
    },
    {
      serial_no: 2,
      candidate: "DR. SANDEEP SAURAV",
      party: "Communist Party of India (Marxist-Leninist) (Liberation)",
      evm_votes: 388052,
      postal_votes: 2256,
      total_votes: 390308,
      percentage_of_votes: 34.11,
    },
    {
      serial_no: 3,
      candidate: "SUDHIR KUMAR",
      party: "Sanyukt Kisan Vikas Party",
      evm_votes: 25286,
      postal_votes: 5,
      total_votes: 25291,
      percentage_of_votes: 2.21,
    },
    {
      serial_no: 4,
      candidate: "SURENDRA PRASAD",
      party: "Al-Hind Party",
      evm_votes: 18571,
      postal_votes: 5,
      total_votes: 18576,
      percentage_of_votes: 1.62,
    },
    {
      serial_no: 5,
      candidate: "SHASHI KUMAR",
      party: "Samaj Shakti Party",
      evm_votes: 18472,
      postal_votes: 8,
      total_votes: 18480,
      percentage_of_votes: 1.61,
    },
    {
      serial_no: 6,
      candidate: "PRAMOD KUMAR NIRALA",
      party: "Bahujan Samaj Party",
      evm_votes: 15233,
      postal_votes: 99,
      total_votes: 15332,
      percentage_of_votes: 1.34,
    },
    {
      serial_no: 7,
      candidate: "AJEET KUMAR",
      party: "Independent",
      evm_votes: 14746,
      postal_votes: 4,
      total_votes: 14750,
      percentage_of_votes: 1.29,
    },
    {
      serial_no: 8,
      candidate: "VINAY PRATAP SINGH",
      party: "Rashtriya Sanatan Party",
      evm_votes: 9593,
      postal_votes: 9,
      total_votes: 9602,
      percentage_of_votes: 0.84,
    },
    {
      serial_no: 9,
      candidate: "SANJAY RAJESH",
      party: "Karpoori Janta Dal",
      evm_votes: 7878,
      postal_votes: 9,
      total_votes: 7887,
      percentage_of_votes: 0.69,
    },
    {
      serial_no: 10,
      candidate: "SUDHIR DAS",
      party: "Independent",
      evm_votes: 6804,
      postal_votes: 2,
      total_votes: 6806,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 11,
      candidate: "KANHAIYA LAL YADAV",
      party: "Jagrook Janta Party",
      evm_votes: 6665,
      postal_votes: 27,
      total_votes: 6692,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 12,
      candidate: "SANYUKTA KUMARI",
      party: "Independent",
      evm_votes: 6232,
      postal_votes: 6,
      total_votes: 6238,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 13,
      candidate: "AJIT KUMAR",
      party: "Independent",
      evm_votes: 5515,
      postal_votes: 5,
      total_votes: 5520,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 14,
      candidate: "SHYAM SUNDAR PRASAD",
      party: "Independent",
      evm_votes: 3922,
      postal_votes: 10,
      total_votes: 3932,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 15,
      candidate: "RAMESH KUMAR",
      party: "Naki Bharatiya Ekta Party",
      evm_votes: 3840,
      postal_votes: 5,
      total_votes: 3845,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 16,
      candidate: "KISLAY KUMAR",
      party: "Independent",
      evm_votes: 3599,
      postal_votes: 3,
      total_votes: 3602,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 17,
      candidate: "KESHO JAMADAR",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 3369,
      postal_votes: 18,
      total_votes: 3387,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 18,
      candidate: "SURENDER SINGH",
      party: "Independent",
      evm_votes: 3341,
      postal_votes: 4,
      total_votes: 3345,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 19,
      candidate: "DILIP KUMAR",
      party: "Moolniwasi Samaj Party",
      evm_votes: 2724,
      postal_votes: 5,
      total_votes: 2729,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 20,
      candidate: "NAND KISHOR PARASAD",
      party: "Pragatisheel Magahi Samaj",
      evm_votes: 2456,
      postal_votes: 12,
      total_votes: 2468,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 21,
      candidate: "NISHA RANJAN",
      party: "Samata Party",
      evm_votes: 2397,
      postal_votes: 18,
      total_votes: 2415,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 22,
      candidate: "SHASHIRANJAN SUMAN",
      party: "Independent",
      evm_votes: 2331,
      postal_votes: 9,
      total_votes: 2340,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 23,
      candidate: "GHANSHYAM PRASAD SINGH",
      party: "Independent",
      evm_votes: 2108,
      postal_votes: 2,
      total_votes: 2110,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 24,
      candidate: "KUMAR HARI CHARAN SINGH YADAV",
      party: "Bharatiya Momin Front",
      evm_votes: 1971,
      postal_votes: 9,
      total_votes: 1980,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 25,
      candidate: "MUNNA KUMAR",
      party: "Sankhyanupati Bhagidari Party",
      evm_votes: 1937,
      postal_votes: 12,
      total_votes: 1949,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 26,
      candidate: "MUNNA KUMAR",
      party: "Samajwadi Lok Parishad",
      evm_votes: 1867,
      postal_votes: 13,
      total_votes: 1880,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 27,
      candidate: "PAWAN KUMAR",
      party: "Independent",
      evm_votes: 1595,
      postal_votes: 2,
      total_votes: 1597,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 28,
      candidate: "PUJA DEVI",
      party: "Independent",
      evm_votes: 1491,
      postal_votes: 14,
      total_votes: 1505,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 29,
      candidate: "CHANDRA KISHOR PRASAD YADAV",
      party: "Independent",
      evm_votes: 1177,
      postal_votes: 1,
      total_votes: 1178,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 30,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 19110,
      postal_votes: 107,
      total_votes: 19217,
      percentage_of_votes: 1.68,
    },
  ],
  total: {
    evm_votes: 1139336,
    postal_votes: 5047,
    total_votes: 1144383,
  },
};
export const s1314 = {
  cs_name: "Yavatmal-_Washim",
  cs_shortname: "yavatmalwashim",
  cs_code: "s1314",
  winner: "SHSUBT",
  stats: [
    {
      serial_no: 1,
      candidate: "SANJAY UTTAMRAO DESHMUKH",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 591899,
      postal_votes: 2908,
      total_votes: 594807,
      percentage_of_votes: 48.53,
    },
    {
      serial_no: 2,
      candidate: "RAJSHRITAI HEMANT PATIL (MAHALLE)",
      party: "Shiv Sena",
      evm_votes: 498514,
      postal_votes: 1820,
      total_votes: 500334,
      percentage_of_votes: 40.83,
    },
    {
      serial_no: 3,
      candidate: "ANIL JAYRAM RATHOD",
      party: "Samnak Janta Party",
      evm_votes: 56175,
      postal_votes: 215,
      total_votes: 56390,
      percentage_of_votes: 4.6,
    },
    {
      serial_no: 4,
      candidate: "HARISING (HARIBHAU) NASARU RATHOD",
      party: "Bahujan Samaj Party",
      evm_votes: 17256,
      postal_votes: 140,
      total_votes: 17396,
      percentage_of_votes: 1.42,
    },
    {
      serial_no: 5,
      candidate: "SANGITA DINESH CHAVHAN",
      party: "Independent",
      evm_votes: 7169,
      postal_votes: 11,
      total_votes: 7180,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 6,
      candidate: "RAMDAS BAJIRAO GHODAM",
      party: "Independent",
      evm_votes: 6776,
      postal_votes: 5,
      total_votes: 6781,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 7,
      candidate: "VINOD PANJABRAO NANDAGAVALI",
      party: "Independent",
      evm_votes: 6291,
      postal_votes: 7,
      total_votes: 6298,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 8,
      candidate: "SANDIP SAMPAT SHINDE",
      party: "Independent",
      evm_votes: 5511,
      postal_votes: 3,
      total_votes: 5514,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 9,
      candidate: "DHARAM DILIPSING THAKUR",
      party: "Sanman Rajkiya Paksha",
      evm_votes: 4539,
      postal_votes: 16,
      total_votes: 4555,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 10,
      candidate: "AMOL KOMAWAR",
      party: "Hindrashtra Sangh",
      evm_votes: 3364,
      postal_votes: 13,
      total_votes: 3377,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 11,
      candidate: "UTTAM ONKAR INGOLE",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 2958,
      postal_votes: 17,
      total_votes: 2975,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 12,
      candidate: "MANOJ MAHADEORAO GEDAM",
      party: "Independent",
      evm_votes: 2783,
      postal_votes: 11,
      total_votes: 2794,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 13,
      candidate: "NUR ALI MAHEBUB ALI SHAH",
      party: "Independent",
      evm_votes: 1951,
      postal_votes: 7,
      total_votes: 1958,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 14,
      candidate: "DR ARJUNKUMAR SITARAM RATHOD",
      party: "Independent",
      evm_votes: 1857,
      postal_votes: 6,
      total_votes: 1863,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 15,
      candidate: "PRO KISAN RAMRAO AMBURE",
      party: "Independent",
      evm_votes: 1705,
      postal_votes: 15,
      total_votes: 1720,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 16,
      candidate: "DIKSHANT NAMDEVRAO SAWAIKAR",
      party: "Independent",
      evm_votes: 1095,
      postal_votes: 8,
      total_votes: 1103,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 17,
      candidate: "GOKUL PREMDAS CHAVHAN",
      party: "Independent",
      evm_votes: 1091,
      postal_votes: 3,
      total_votes: 1094,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 18,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9316,
      postal_votes: 75,
      total_votes: 9391,
      percentage_of_votes: 0.77,
    },
  ],
  total: {
    evm_votes: 1220250,
    postal_votes: 5280,
    total_votes: 1225530,
  },
};
export const s1315 = {
  cs_name: "Hingoli",
  cs_shortname: "hingoli",
  cs_code: "s1315",
  winner: "SHSUBT",
  stats: [
    {
      serial_no: 1,
      candidate: "AASHTIKAR PATIL NAGESH BAPURAO",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 490432,
      postal_votes: 2103,
      total_votes: 492535,
      percentage_of_votes: 42.49,
    },
    {
      serial_no: 2,
      candidate: "BABURAO KADAM KOHALIKAR",
      party: "Shiv Sena",
      evm_votes: 382599,
      postal_votes: 1334,
      total_votes: 383933,
      percentage_of_votes: 33.12,
    },
    {
      serial_no: 3,
      candidate: "DR. B. D. CHAVHAN",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 161324,
      postal_votes: 490,
      total_votes: 161814,
      percentage_of_votes: 13.96,
    },
    {
      serial_no: 4,
      candidate: "AMBADAS SUKAJI GADE",
      party: "Independent",
      evm_votes: 14729,
      postal_votes: 13,
      total_votes: 14742,
      percentage_of_votes: 1.27,
    },
    {
      serial_no: 5,
      candidate: "VIJAY RAMJI GABHANE",
      party: "Communist Party of India (Marxist)",
      evm_votes: 14617,
      postal_votes: 27,
      total_votes: 14644,
      percentage_of_votes: 1.26,
    },
    {
      serial_no: 6,
      candidate: "ANAND RAJARAM DHULE",
      party: "Independent",
      evm_votes: 9807,
      postal_votes: 10,
      total_votes: 9817,
      percentage_of_votes: 0.85,
    },
    {
      serial_no: 7,
      candidate: "GAJANAN DHONDBA DAL",
      party: "Bahujan Samaj Party",
      evm_votes: 7410,
      postal_votes: 55,
      total_votes: 7465,
      percentage_of_votes: 0.64,
    },
    {
      serial_no: 8,
      candidate: "DATTA SHRIKRISHNA SURYAWANSHI",
      party: "Independent",
      evm_votes: 7233,
      postal_votes: 6,
      total_votes: 7239,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 9,
      candidate: "SARJERAO NIVRUTTI KHANDARE",
      party: "Independent",
      evm_votes: 5010,
      postal_votes: 4,
      total_votes: 5014,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 10,
      candidate: "RAMPRASAD NARAYAN BANGAR",
      party: "Independent",
      evm_votes: 4518,
      postal_votes: 7,
      total_votes: 4525,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 11,
      candidate: "BABURAO ANANDRAO KADAM",
      party: "Independent",
      evm_votes: 4469,
      postal_votes: 13,
      total_votes: 4482,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 12,
      candidate: "DEVSARKAR VARSHA SHIVAJIRAO",
      party: "Bahujan Mukti Party",
      evm_votes: 4081,
      postal_votes: 18,
      total_votes: 4099,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 13,
      candidate: "A. KADIR MASTAN SAYYED (GOREGAONKAR)",
      party: "Independent",
      evm_votes: 3707,
      postal_votes: 6,
      total_votes: 3713,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 14,
      candidate: "ANIL DEVRAO MOHITE",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 3353,
      postal_votes: 21,
      total_votes: 3374,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 15,
      candidate: "SUNIL MOTIRAM GAJBHAR",
      party: "Independent",
      evm_votes: 3192,
      postal_votes: 0,
      total_votes: 3192,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 16,
      candidate: "ADV ALTAF AHMED",
      party: "Indian National League",
      evm_votes: 2934,
      postal_votes: 16,
      total_votes: 2950,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 17,
      candidate: "ASHOK PANDURANG RATHOD",
      party: "Independent",
      evm_votes: 2897,
      postal_votes: 9,
      total_votes: 2906,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 18,
      candidate: "ADV RAVI SHINDE",
      party: "Independent",
      evm_votes: 2687,
      postal_votes: 14,
      total_votes: 2701,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 19,
      candidate: "SATTAR PATHAN",
      party: "Independent",
      evm_votes: 2461,
      postal_votes: 2,
      total_votes: 2463,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 20,
      candidate: "MAHESH KAILAS NAPTE",
      party: "Independent",
      evm_votes: 2298,
      postal_votes: 1,
      total_votes: 2299,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 21,
      candidate: "ADV. SHIVAJIRAO JADHAV",
      party: "Independent",
      evm_votes: 2181,
      postal_votes: 33,
      total_votes: 2214,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 22,
      candidate: "BHAWAR GOVINDRAO FULAJI",
      party: "Independent",
      evm_votes: 2138,
      postal_votes: 8,
      total_votes: 2146,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 23,
      candidate: "TRISHALA MILIND KAMBLE",
      party: "Bahujan Samaj Party (Ambedkar)",
      evm_votes: 2128,
      postal_votes: 22,
      total_votes: 2150,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 24,
      candidate: "PRAKASH MASARAM RANVIR",
      party: "Republican Party of India (A)",
      evm_votes: 2097,
      postal_votes: 5,
      total_votes: 2102,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 25,
      candidate: "ADV. RAMRAO ATMARAM JUMBADE",
      party: "Independent",
      evm_votes: 2095,
      postal_votes: 9,
      total_votes: 2104,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 26,
      candidate: "DESHA SHYAM BANJARA",
      party: "Samnak Janta Party",
      evm_votes: 2052,
      postal_votes: 11,
      total_votes: 2063,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 27,
      candidate: "VISHVANATH BHAURAO FALEGAONKAR",
      party: "Independent",
      evm_votes: 1856,
      postal_votes: 3,
      total_votes: 1859,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 28,
      candidate: "HEMANT RADHAKISHAN KANAKE",
      party: "Rashtriya Kisan Bahujan Party",
      evm_votes: 1791,
      postal_votes: 9,
      total_votes: 1800,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 29,
      candidate: "RAVI RAMDAS JADHAV (SAWANEKAR)",
      party: "Abhinav Bharat Janseva Paksh",
      evm_votes: 1590,
      postal_votes: 12,
      total_votes: 1602,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 30,
      candidate: "DEWJI GANGARAM ASOLE",
      party: "Independent",
      evm_votes: 1476,
      postal_votes: 7,
      total_votes: 1483,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 31,
      candidate: "VIJAY DNYANBA RAUT",
      party: "Independent",
      evm_votes: 1115,
      postal_votes: 11,
      total_votes: 1126,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 32,
      candidate: "SUNIL DASHARATH INGOLE",
      party: "Bhim Sena",
      evm_votes: 1024,
      postal_votes: 4,
      total_votes: 1028,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 33,
      candidate: "VASANT KISAN PAIKRAO",
      party: "Independent",
      evm_votes: 590,
      postal_votes: 3,
      total_votes: 593,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 34,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3079,
      postal_votes: 44,
      total_votes: 3123,
      percentage_of_votes: 0.27,
    },
  ],
  total: {
    evm_votes: 1154970,
    postal_votes: 4330,
    total_votes: 1159300,
  },
};
export const s1317 = {
  cs_name: "Parbhani",
  cs_shortname: "parbhani",
  cs_code: "s1317",
  winner: "SHSUBT",
  stats: [
    {
      serial_no: 1,
      candidate: "JADHAV SANJAY ( BANDU ) HARIBHAU",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 596871,
      postal_votes: 4472,
      total_votes: 601343,
      percentage_of_votes: 45.17,
    },
    {
      serial_no: 2,
      candidate: "JANKAR MAHADEV JAGANNATH",
      party: "Rashtriya Samaj Paksha",
      evm_votes: 463764,
      postal_votes: 3518,
      total_votes: 467282,
      percentage_of_votes: 35.1,
    },
    {
      serial_no: 3,
      candidate: "PANJAB UTTAMRAO DAKH",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 95394,
      postal_votes: 573,
      total_votes: 95967,
      percentage_of_votes: 7.21,
    },
    {
      serial_no: 4,
      candidate: "DR. GOVARDHAN BHIWAJI KHANDAGALE",
      party: "Bahujan Bharat Party",
      evm_votes: 15355,
      postal_votes: 40,
      total_votes: 15395,
      percentage_of_votes: 1.16,
    },
    {
      serial_no: 5,
      candidate: "KAMBLE SHIVAJI DEVAJI",
      party: "Independent",
      evm_votes: 11050,
      postal_votes: 10,
      total_votes: 11060,
      percentage_of_votes: 0.83,
    },
    {
      serial_no: 6,
      candidate: "ALAMGIR MOHAMMAD KHAN",
      party: "Bahujan Samaj Party",
      evm_votes: 10972,
      postal_votes: 90,
      total_votes: 11062,
      percentage_of_votes: 0.83,
    },
    {
      serial_no: 7,
      candidate: "SAMIR GANESHRAO DUDHGAONKAR",
      party: "Independent",
      evm_votes: 10951,
      postal_votes: 105,
      total_votes: 11056,
      percentage_of_votes: 0.83,
    },
    {
      serial_no: 8,
      candidate: "KRUSHNA TRIMBAKRAO PAWAR",
      party: "Independent",
      evm_votes: 10145,
      postal_votes: 9,
      total_votes: 10154,
      percentage_of_votes: 0.76,
    },
    {
      serial_no: 9,
      candidate: "KAILAS BALIRAM PAWAR (NAIK)",
      party: "Baliraja Party",
      evm_votes: 9487,
      postal_votes: 33,
      total_votes: 9520,
      percentage_of_votes: 0.72,
    },
    {
      serial_no: 10,
      candidate: "PROF. DR. VILAS BANSIDHAR TANGADE",
      party: "Independent",
      evm_votes: 8814,
      postal_votes: 6,
      total_votes: 8820,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 11,
      candidate: "ARJUN DNYANOBA BHISE",
      party: "Independent",
      evm_votes: 6915,
      postal_votes: 8,
      total_votes: 6923,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 12,
      candidate: "COMRADE RAJAN KSHIRSAGAR",
      party: "Communist Party of India",
      evm_votes: 6627,
      postal_votes: 22,
      total_votes: 6649,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 13,
      candidate: "KISHORKUMAR PRAKASH SHINDE",
      party: "Independent",
      evm_votes: 6547,
      postal_votes: 1,
      total_votes: 6548,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 14,
      candidate: "KISHORE RADHAKISHAN DHAGE",
      party: "Independent",
      evm_votes: 5446,
      postal_votes: 34,
      total_votes: 5480,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 15,
      candidate: "SAYED IRSHAD ALI SAYYED LAYAKH ALI",
      party: "Social Democratic Party Of India",
      evm_votes: 4629,
      postal_votes: 15,
      total_votes: 4644,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 16,
      candidate: "DASHARATH PRABHKAR RATHOD",
      party: "Maharashtra Vikas Aghadi",
      evm_votes: 4354,
      postal_votes: 40,
      total_votes: 4394,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 17,
      candidate: "KARBHARI KUNDLIK MITHE",
      party: "Independent",
      evm_votes: 4268,
      postal_votes: 8,
      total_votes: 4276,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 18,
      candidate: "APPASAHEB OMKAR KADAM",
      party: "Independent",
      evm_votes: 3883,
      postal_votes: 8,
      total_votes: 3891,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 19,
      candidate: "SHRIRAM BANSILAL JADHAV",
      party: "Jai Sewalal Bahujan Vikas Party",
      evm_votes: 3617,
      postal_votes: 11,
      total_votes: 3628,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 20,
      candidate: "COMRADE GANPAT BHISE",
      party: "Independent",
      evm_votes: 3589,
      postal_votes: 15,
      total_votes: 3604,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 21,
      candidate: "DNYANESHWAR JAGANNATH DAHIBHATE",
      party: "Independent",
      evm_votes: 3538,
      postal_votes: 1,
      total_votes: 3539,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 22,
      candidate: "ANIL MANIKRAO MUDGALKAR",
      party: "Independent",
      evm_votes: 3531,
      postal_votes: 2,
      total_votes: 3533,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 23,
      candidate: "GOVIND BHAIYYA RAMRAO DESHMUKH",
      party: "Independent",
      evm_votes: 3452,
      postal_votes: 7,
      total_votes: 3459,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 24,
      candidate: "SUBHASH DATTARAV JAVLE",
      party: "Independent",
      evm_votes: 3429,
      postal_votes: 5,
      total_votes: 3434,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 25,
      candidate: "RAJABHAU SHESHRAO KAKDE",
      party: "Independent",
      evm_votes: 3299,
      postal_votes: 3,
      total_votes: 3302,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 26,
      candidate: "RAJENDRA RAMDAS ATKAL",
      party: "Independent",
      evm_votes: 2807,
      postal_votes: 5,
      total_votes: 2812,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 27,
      candidate: "SAYYD ABDUL SATTAR AJIJ",
      party: "Independent",
      evm_votes: 2646,
      postal_votes: 4,
      total_votes: 2650,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 28,
      candidate: "SHAIKH SALEEM SHAIKH IBRAHIM",
      party: "All India Majlis-E-Inquilab-E-Millat",
      evm_votes: 2617,
      postal_votes: 2,
      total_votes: 2619,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 29,
      candidate: "SANGEETA VYAKANTRAO GIRI",
      party: "Swarajya Shakti Sena",
      evm_votes: 2324,
      postal_votes: 15,
      total_votes: 2339,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 30,
      candidate: "ADV. VINOD CHHAGANRAO AMBHORE",
      party: "Bahujan Mukti Party",
      evm_votes: 2017,
      postal_votes: 24,
      total_votes: 2041,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 31,
      candidate: "VIJAY ANNASAHEB THOMBARE",
      party: "Independent",
      evm_votes: 1957,
      postal_votes: 2,
      total_votes: 1959,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 32,
      candidate: "VISHNUDAS SHIVAJI BHOSALE",
      party: "Independent",
      evm_votes: 1856,
      postal_votes: 4,
      total_votes: 1860,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 33,
      candidate: "MUSTAFA MAINODDIN SHAIKH",
      party: "Independent",
      evm_votes: 1298,
      postal_votes: 4,
      total_votes: 1302,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 34,
      candidate: "BOBADE SAKHARAM PADEGAONKAR",
      party: "Independent",
      evm_votes: 1244,
      postal_votes: 2,
      total_votes: 1246,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 35,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3306,
      postal_votes: 79,
      total_votes: 3385,
      percentage_of_votes: 0.25,
    },
  ],
  total: {
    evm_votes: 1321999,
    postal_votes: 9177,
    total_votes: 1331176,
  },
};
export const s1321 = {
  cs_name: "Nashik",
  cs_shortname: "nashik",
  cs_code: "s1321",
  winner: "SHSUBT",
  stats: [
    {
      serial_no: 1,
      candidate: "RAJABHAU (PARAG) PRAKASH WAJE",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 614517,
      postal_votes: 2212,
      total_votes: 616729,
      percentage_of_votes: 49.85,
    },
    {
      serial_no: 2,
      candidate: "GODSE HEMANT TUKARAM",
      party: "Shiv Sena",
      evm_votes: 453414,
      postal_votes: 1314,
      total_votes: 454728,
      percentage_of_votes: 36.75,
    },
    {
      serial_no: 3,
      candidate: "KARAN PANDHARINATH GAIKAR",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 47114,
      postal_votes: 79,
      total_votes: 47193,
      percentage_of_votes: 3.81,
    },
    {
      serial_no: 4,
      candidate: "SHANTIGIRIJI MAUNGIRIJI MAHARAJ",
      party: "Independent",
      evm_votes: 44415,
      postal_votes: 109,
      total_votes: 44524,
      percentage_of_votes: 3.6,
    },
    {
      serial_no: 5,
      candidate: "ARUN MADHUKAR KALE",
      party: "Bahujan Samaj Party",
      evm_votes: 7698,
      postal_votes: 53,
      total_votes: 7751,
      percentage_of_votes: 0.63,
    },
    {
      serial_no: 6,
      candidate: "CHANDRAKANT KESHAVRAO THAKUR",
      party: "Independent",
      evm_votes: 6607,
      postal_votes: 1,
      total_votes: 6608,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 7,
      candidate: "AMOL SAMPATRAO KAMBLE",
      party: "Rashtriya Kisan Bahujan Party",
      evm_votes: 4980,
      postal_votes: 17,
      total_votes: 4997,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 8,
      candidate: "DARSHANA AMOL MEDHE",
      party: "Prabuddha Republican Party",
      evm_votes: 4730,
      postal_votes: 6,
      total_votes: 4736,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 9,
      candidate: "KANOJE PRAKASH GIRDHARI",
      party: "Independent",
      evm_votes: 3917,
      postal_votes: 3,
      total_votes: 3920,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 10,
      candidate: "VAMAN MAHADEV SANGALE",
      party: "Dharmarajya Paksha",
      evm_votes: 3861,
      postal_votes: 0,
      total_votes: 3861,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 11,
      candidate: "SUDHIR SHRIDHAR DESHMUKH",
      party: "Independent",
      evm_votes: 3162,
      postal_votes: 5,
      total_votes: 3167,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 12,
      candidate: "JITENDRA NARESH BHABHE (JITENDRA BHAVE)",
      party: "Independent",
      evm_votes: 3125,
      postal_votes: 7,
      total_votes: 3132,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 13,
      candidate: "ARIF USMAN MANSURI",
      party: "Independent",
      evm_votes: 2538,
      postal_votes: 5,
      total_votes: 2543,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 14,
      candidate: "GANESH BALASAHEB BORASTE",
      party: "Independent",
      evm_votes: 2403,
      postal_votes: 1,
      total_votes: 2404,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 15,
      candidate: "JAYSHRI MAHENDRA PATIL",
      party: "Sainik Samaj Party",
      evm_votes: 2398,
      postal_votes: 28,
      total_votes: 2426,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 16,
      candidate: "SIDDHESHWARANAND SARASWATI",
      party: "Independent",
      evm_votes: 2378,
      postal_votes: 10,
      total_votes: 2388,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 17,
      candidate: "SUSHAMA ABHIJIT GORANE",
      party: "Independent",
      evm_votes: 2258,
      postal_votes: 2,
      total_votes: 2260,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 18,
      candidate: "KANTILAL KISAN JADHAV",
      party: "Ambedkarite Party of India",
      evm_votes: 2047,
      postal_votes: 8,
      total_votes: 2055,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 19,
      candidate: "CHANDRABHAN AABAJI PURKAR",
      party: "Independent",
      evm_votes: 1888,
      postal_votes: 2,
      total_votes: 1890,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 20,
      candidate: "YASHWANT VALU PARDHI",
      party: "Bhartiya Asmita Party",
      evm_votes: 1867,
      postal_votes: 7,
      total_votes: 1874,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 21,
      candidate: "KAILASH MARUTI CHAVAN",
      party: "Aam Janta Party (India)",
      evm_votes: 1686,
      postal_votes: 17,
      total_votes: 1703,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 22,
      candidate: "KAMLAKAR BALASAHEB GAIKWAD",
      party: "Dalit Soshit Pichhara Varg Adhikar Dal",
      evm_votes: 1639,
      postal_votes: 7,
      total_votes: 1646,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 23,
      candidate: "DHANAJI ASHOK TOPALE",
      party: "Independent",
      evm_votes: 1376,
      postal_votes: 0,
      total_votes: 1376,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 24,
      candidate: "BHAGYASHRI NITIN ADSUL",
      party: "Indian Peoples Adhikar Party",
      evm_votes: 1242,
      postal_votes: 2,
      total_votes: 1244,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 25,
      candidate: "SOPAN NIVRUTTI SOMWANSHI",
      party: "Independent",
      evm_votes: 1210,
      postal_votes: 1,
      total_votes: 1211,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 26,
      candidate: "ADV. ZUNJAR MHASUJI AVHAD",
      party: "Bahujan Republican Socialist Party",
      evm_votes: 1205,
      postal_votes: 5,
      total_votes: 1210,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 27,
      candidate: "KOLAPPA HANUMANT DHOTRE",
      party: "Independent",
      evm_votes: 1198,
      postal_votes: 2,
      total_votes: 1200,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 28,
      candidate: "TILOTTAMA SUNIL JAGTAP",
      party: "Independent",
      evm_votes: 654,
      postal_votes: 2,
      total_votes: 656,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 29,
      candidate: "DEEPAK GAIKWAD",
      party: "Independent",
      evm_votes: 640,
      postal_votes: 2,
      total_votes: 642,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 30,
      candidate: "SACHINRAJE DATTATREYA DEORE",
      party: "Independent",
      evm_votes: 549,
      postal_votes: 2,
      total_votes: 551,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 31,
      candidate: "DEVIDAS PIRAJI SARKATE",
      party: "Independent",
      evm_votes: 392,
      postal_votes: 2,
      total_votes: 394,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 32,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6144,
      postal_votes: 41,
      total_votes: 6185,
      percentage_of_votes: 0.5,
    },
  ],
  total: {
    evm_votes: 1233252,
    postal_votes: 3952,
    total_votes: 1237204,
  },
};
export const s1328 = {
  cs_name: "Mumbai_North_East",
  cs_shortname: "mumbainortheast",
  cs_code: "s1328",
  winner: "SHSUBT",
  stats: [
    {
      serial_no: 1,
      candidate: "SANJAY DINA PATIL",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 448604,
      postal_votes: 2333,
      total_votes: 450937,
      percentage_of_votes: 48.67,
    },
    {
      serial_no: 2,
      candidate: "MIHIR CHANDRAKANT KOTECHA",
      party: "Bharatiya Janata Party",
      evm_votes: 419589,
      postal_votes: 1487,
      total_votes: 421076,
      percentage_of_votes: 45.45,
    },
    {
      serial_no: 3,
      candidate: "DAULAT KADAR KHAN",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 14571,
      postal_votes: 86,
      total_votes: 14657,
      percentage_of_votes: 1.58,
    },
    {
      serial_no: 4,
      candidate: "NANDESH VITHAL UMAP",
      party: "Bahujan Samaj Party",
      evm_votes: 8217,
      postal_votes: 101,
      total_votes: 8318,
      percentage_of_votes: 0.9,
    },
    {
      serial_no: 5,
      candidate: "DR. SUSHMA MAURYA",
      party: "Independent",
      evm_votes: 2886,
      postal_votes: 5,
      total_votes: 2891,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 6,
      candidate: "SANJAY B. PATIL",
      party: "National People's Party",
      evm_votes: 2757,
      postal_votes: 7,
      total_votes: 2764,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 7,
      candidate: "SANJU MARUTI PAWAR",
      party: "Maharashtra Vikas Aghadi",
      evm_votes: 2555,
      postal_votes: 27,
      total_votes: 2582,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 8,
      candidate: "PROF. DR. PRASHANT GANGAWANE",
      party: "Desh Janhit Party",
      evm_votes: 2338,
      postal_votes: 35,
      total_votes: 2373,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 9,
      candidate: "PREM RAMAPATI GUPTA",
      party: "Independent",
      evm_votes: 1598,
      postal_votes: 3,
      total_votes: 1601,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 10,
      candidate: "MOHAMMED AHMED SHAIKH",
      party: "Independent",
      evm_votes: 1289,
      postal_votes: 5,
      total_votes: 1294,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "MOHAMMAD ARMAN MOHAMMAD WASI KHAN",
      party: "Rashtriya Janhit Sangharsh Party",
      evm_votes: 1085,
      postal_votes: 5,
      total_votes: 1090,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "VIDYA NAIK",
      party: "Independent",
      evm_votes: 974,
      postal_votes: 5,
      total_votes: 979,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 13,
      candidate: "BHOPINDER SINGH SAINI",
      party: "Viro Ke Vir Indian Party",
      evm_votes: 941,
      postal_votes: 5,
      total_votes: 946,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 14,
      candidate: "BANSODE DILIP",
      party: "Independent",
      evm_votes: 831,
      postal_votes: 7,
      total_votes: 838,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 15,
      candidate: "BHAWANI H. CHOUDHARY",
      party: "Sardar Vallabhbhai Patel Party",
      evm_votes: 742,
      postal_votes: 4,
      total_votes: 746,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 16,
      candidate: "DATTATRAY ARJUN UTEKAR",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 707,
      postal_votes: 4,
      total_votes: 711,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 17,
      candidate: "COMRADE SURENDRA SIBAG",
      party: "All India Forward Bloc",
      evm_votes: 675,
      postal_votes: 3,
      total_votes: 678,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "SHAHAJI NANAI THORAT",
      party: "Independent",
      evm_votes: 648,
      postal_votes: 2,
      total_votes: 650,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 19,
      candidate: "SANJAY SAOJI DESHPANDE",
      party: "Republican Party of India",
      evm_votes: 641,
      postal_votes: 0,
      total_votes: 641,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 20,
      candidate: "SANJAY PATIL",
      party: "Independent",
      evm_votes: 520,
      postal_votes: 4,
      total_votes: 524,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 21,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10088,
      postal_votes: 85,
      total_votes: 10173,
      percentage_of_votes: 1.1,
    },
  ],
  total: {
    evm_votes: 922256,
    postal_votes: 4213,
    total_votes: 926469,
  },
};
export const s1330 = {
  cs_name: "Mumbai_South_Central",
  cs_shortname: "mumbaisouthcentral",
  cs_code: "s1330",
  winner: "SHSUBT",
  stats: [
    {
      serial_no: 1,
      candidate: "ANIL YESHWANT DESAI",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 393342,
      postal_votes: 1796,
      total_votes: 395138,
      percentage_of_votes: 49.73,
    },
    {
      serial_no: 2,
      candidate: "RAHUL RAMESH SHEWALE",
      party: "Shiv Sena",
      evm_votes: 339828,
      postal_votes: 1926,
      total_votes: 341754,
      percentage_of_votes: 43.01,
    },
    {
      serial_no: 3,
      candidate: "ABUL HASAN ALI HASAN KHAN",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 23675,
      postal_votes: 192,
      total_votes: 23867,
      percentage_of_votes: 3,
    },
    {
      serial_no: 4,
      candidate: "VIDHYASAGAR ALIAS SURESH BHIMRAO VIDHYAGAR",
      party: "Bahujan Samaj Party",
      evm_votes: 6462,
      postal_votes: 70,
      total_votes: 6532,
      percentage_of_votes: 0.82,
    },
    {
      serial_no: 5,
      candidate: "AKASH LAXMAN KHARATMAL",
      party: "Independent",
      evm_votes: 1891,
      postal_votes: 8,
      total_votes: 1899,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 6,
      candidate: "ASHWINI KUMAR PATHAK",
      party: "Independent",
      evm_votes: 1607,
      postal_votes: 5,
      total_votes: 1612,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 7,
      candidate: "ADV. SANTOSH PUNJIRAM SANJKAR",
      party: "Independent",
      evm_votes: 1565,
      postal_votes: 13,
      total_votes: 1578,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 8,
      candidate: "KARAM HUSSAIN KITABULLAH KHAN",
      party: "Peace Party",
      evm_votes: 1533,
      postal_votes: 4,
      total_votes: 1537,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 9,
      candidate: "ADV. MAHENDRA TULSHIRAM BHINGARDIVE",
      party: "Right to Recall Party",
      evm_votes: 1414,
      postal_votes: 30,
      total_votes: 1444,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 10,
      candidate: "SAEED CHOUDHARY",
      party: "Social Democratic Party Of India",
      evm_votes: 1186,
      postal_votes: 5,
      total_votes: 1191,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 11,
      candidate: "ISHWAR VILAS TATHAWADE",
      party: "Rashtriya Mahaswaraj Bhumi Party",
      evm_votes: 968,
      postal_votes: 3,
      total_votes: 971,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "DEEPAK M. CHAUGULE",
      party: "Bahujan Republican Socialist Party",
      evm_votes: 968,
      postal_votes: 7,
      total_votes: 975,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "JAHID ALI NASIR AHMED SHAIKH",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 957,
      postal_votes: 2,
      total_votes: 959,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "DR. ARJUN MAHADEO MURUDKAR",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 913,
      postal_votes: 17,
      total_votes: 930,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 15,
      candidate: "VIVEK YESHWANT PATIL",
      party: "Independent",
      evm_votes: 713,
      postal_votes: 1,
      total_votes: 714,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13316,
      postal_votes: 107,
      total_votes: 13423,
      percentage_of_votes: 1.69,
    },
  ],
  total: {
    evm_votes: 790338,
    postal_votes: 4186,
    total_votes: 794524,
  },
};
export const s1331 = {
  cs_name: "Mumbai_South",
  cs_shortname: "mumbaisouth",
  cs_code: "s1331",
  winner: "SHSUBT",
  stats: [
    {
      serial_no: 1,
      candidate: "ARVIND GANPAT SAWANT",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 393735,
      postal_votes: 1920,
      total_votes: 395655,
      percentage_of_votes: 51.18,
    },
    {
      serial_no: 2,
      candidate: "YAMINI YASHWANT JADHAV",
      party: "Shiv Sena",
      evm_votes: 341291,
      postal_votes: 1691,
      total_votes: 342982,
      percentage_of_votes: 44.36,
    },
    {
      serial_no: 3,
      candidate: "AFZAL S. DAWOODANI",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 5575,
      postal_votes: 37,
      total_votes: 5612,
      percentage_of_votes: 0.73,
    },
    {
      serial_no: 4,
      candidate: "MOHAMMED SHUAIB BASHIR KHATEEB",
      party: "Bahujan Samaj Party",
      evm_votes: 4440,
      postal_votes: 38,
      total_votes: 4478,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 5,
      candidate: "ARVIND NARAYAN SAWANT",
      party: "Independent",
      evm_votes: 3065,
      postal_votes: 25,
      total_votes: 3090,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 6,
      candidate: "MOHD. NAEEM SHAIKH",
      party: "Aim Political Party",
      evm_votes: 1709,
      postal_votes: 6,
      total_votes: 1715,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 7,
      candidate: "SABEEHA KHAN",
      party: "Independent",
      evm_votes: 1678,
      postal_votes: 16,
      total_votes: 1694,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 8,
      candidate: "GOHIL MANISHA SHIVRAM",
      party: "Independent",
      evm_votes: 1032,
      postal_votes: 13,
      total_votes: 1045,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 9,
      candidate: "SHANKAR SONAWANE",
      party: "Independent",
      evm_votes: 854,
      postal_votes: 8,
      total_votes: 862,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "RAHUL PHANASWADIKAR",
      party: "Lokshahi Ekta Party",
      evm_votes: 784,
      postal_votes: 3,
      total_votes: 787,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 11,
      candidate: "SUBHASH RAMESH CHIPLUNKAR",
      party: "Rashtriya Kisan Bahujan Party",
      evm_votes: 563,
      postal_votes: 4,
      total_votes: 567,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 12,
      candidate: "PRASHANT PRAKASH GHADGE",
      party: "Independent",
      evm_votes: 487,
      postal_votes: 3,
      total_votes: 490,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 13,
      candidate: "MOHAMMED MAHTAB SHAIKH",
      party: "Independent",
      evm_votes: 455,
      postal_votes: 3,
      total_votes: 458,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 14,
      candidate: "MATEEN AHMED RANGREJ",
      party: "Independent",
      evm_votes: 265,
      postal_votes: 2,
      total_votes: 267,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13290,
      postal_votes: 121,
      total_votes: 13411,
      percentage_of_votes: 1.73,
    },
  ],
  total: {
    evm_votes: 769223,
    postal_votes: 3890,
    total_votes: 773113,
  },
};
export const s1338 = {
  cs_name: "Shirdi",
  cs_shortname: "shirdi",
  cs_code: "s1338",
  winner: "SHSUBT",
  stats: [
    {
      serial_no: 1,
      candidate: "BHAUSAHEB RAJARAM WAKCHAURE",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 475637,
      postal_votes: 1263,
      total_votes: 476900,
      percentage_of_votes: 45,
    },
    {
      serial_no: 2,
      candidate: "LOKHANDE SADASHIV KISAN",
      party: "Shiv Sena",
      evm_votes: 425498,
      postal_votes: 873,
      total_votes: 426371,
      percentage_of_votes: 40.23,
    },
    {
      serial_no: 3,
      candidate: "UTKARSHA PREMANAND RUPWATE",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 90748,
      postal_votes: 181,
      total_votes: 90929,
      percentage_of_votes: 8.58,
    },
    {
      serial_no: 4,
      candidate: "BHAUSAHEB RAMNATH WAKCHAURE",
      party: "Independent",
      evm_votes: 13973,
      postal_votes: 33,
      total_votes: 14006,
      percentage_of_votes: 1.32,
    },
    {
      serial_no: 5,
      candidate: "RAMCHANDRA NAMDEV JADHAV",
      party: "Bahujan Samaj Party",
      evm_votes: 7000,
      postal_votes: 40,
      total_votes: 7040,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 6,
      candidate: "RAVINDRA KALLAYYA SWAMY",
      party: "Independent",
      evm_votes: 5536,
      postal_votes: 1,
      total_votes: 5537,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 7,
      candidate: "ABHIJEET ASHOKRAO POTE",
      party: "Independent",
      evm_votes: 4761,
      postal_votes: 15,
      total_votes: 4776,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 8,
      candidate: "BHARAT SAMBHAJI BHOSLE",
      party: "Samata Party",
      evm_votes: 3395,
      postal_votes: 8,
      total_votes: 3403,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 9,
      candidate: "SATISH BHIVA PAWAR",
      party: "Independent",
      evm_votes: 2765,
      postal_votes: 6,
      total_votes: 2771,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 10,
      candidate: "GANGADHAR RAJARAM KADAM",
      party: "Independent",
      evm_votes: 2743,
      postal_votes: 14,
      total_votes: 2757,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 11,
      candidate: "BAGUL GORAKSHA TANHAJI",
      party: "Independent",
      evm_votes: 2658,
      postal_votes: 2,
      total_votes: 2660,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 12,
      candidate: "PRASHANT VASANT NIKAM",
      party: "Independent",
      evm_votes: 2542,
      postal_votes: 3,
      total_votes: 2545,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 13,
      candidate: "ADV NITIN DADAHARI POL",
      party: "Bahujan Bharat Party",
      evm_votes: 2517,
      postal_votes: 15,
      total_votes: 2532,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 14,
      candidate: "RAJENDRA RATNAKAR WAGHMARE",
      party: "Rashtriya Jankranti Party",
      evm_votes: 2500,
      postal_votes: 5,
      total_votes: 2505,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 15,
      candidate: "ADV SIDHARTH DEEPAK BODHAK",
      party: "Independent",
      evm_votes: 2202,
      postal_votes: 2,
      total_votes: 2204,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 16,
      candidate: "CHANDRAKANT SAMBHAJI DONDE",
      party: "Independent",
      evm_votes: 2074,
      postal_votes: 6,
      total_votes: 2080,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 17,
      candidate: "ASHOK RAMCHANDRA ALHAT",
      party: "Independent",
      evm_votes: 1794,
      postal_votes: 4,
      total_votes: 1798,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 18,
      candidate: "KHAJEKAR VIJAYRAO GOVINDRAO",
      party: "Independent",
      evm_votes: 1555,
      postal_votes: 2,
      total_votes: 1557,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 19,
      candidate: "KHARAT NACHIKET RAGHUNATH",
      party: "Independent",
      evm_votes: 1146,
      postal_votes: 4,
      total_votes: 1150,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 20,
      candidate: "SANJAY POPAT BHALERAO",
      party: "Independent",
      evm_votes: 958,
      postal_votes: 5,
      total_votes: 963,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 21,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5326,
      postal_votes: 54,
      total_votes: 5380,
      percentage_of_votes: 0.51,
    },
  ],
  total: {
    evm_votes: 1057328,
    postal_votes: 2536,
    total_votes: 1059864,
  },
};
export const s1340 = {
  cs_name: "Osmanabad",
  cs_shortname: "osmanabad",
  cs_code: "s1340",
  winner: "SHSUBT",
  stats: [
    {
      serial_no: 1,
      candidate: "OMPRAKASH BHUPALSINH ALIAS PAVAN RAJENIMBALKAR",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 743597,
      postal_votes: 5155,
      total_votes: 748752,
      percentage_of_votes: 58.45,
    },
    {
      serial_no: 2,
      candidate: "ARCHANA RANAJAGJITSINH PATIL",
      party: "Nationalist Congress Party",
      evm_votes: 416699,
      postal_votes: 2207,
      total_votes: 418906,
      percentage_of_votes: 32.7,
    },
    {
      serial_no: 3,
      candidate: "ANDHALKAR BHAUSAHEB RAOSAHEB",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 33221,
      postal_votes: 181,
      total_votes: 33402,
      percentage_of_votes: 2.61,
    },
    {
      serial_no: 4,
      candidate: "GOVARDHAN SUBRAO NIMBALKAR",
      party: "Independent",
      evm_votes: 18947,
      postal_votes: 19,
      total_votes: 18966,
      percentage_of_votes: 1.48,
    },
    {
      serial_no: 5,
      candidate: "DNYANESHWAR NAGANATHRAO KOLI",
      party: "Samata Party",
      evm_votes: 6459,
      postal_votes: 13,
      total_votes: 6472,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 6,
      candidate: "KAKASAHEB SANDIPAN KHOT",
      party: "Independent",
      evm_votes: 5707,
      postal_votes: 8,
      total_votes: 5715,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 7,
      candidate: "SANJAYKUMAR BHAGWAT WAGHMARE",
      party: "Bahujan Samaj Party",
      evm_votes: 5525,
      postal_votes: 90,
      total_votes: 5615,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 8,
      candidate: "KAKA FULCHAND KAMBLE",
      party: "Independent",
      evm_votes: 4807,
      postal_votes: 4,
      total_votes: 4811,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 9,
      candidate: "RAM HANUMANT SHENDAGE",
      party: "Independent",
      evm_votes: 3255,
      postal_votes: 8,
      total_votes: 3263,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 10,
      candidate: "UMAJI PANDURANG GAIKWAD",
      party: "Independent",
      evm_votes: 3089,
      postal_votes: 6,
      total_votes: 3095,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 11,
      candidate: "GORE NETAJI NAGNATH",
      party: "Desh Janhit Party",
      evm_votes: 2314,
      postal_votes: 22,
      total_votes: 2336,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 12,
      candidate: "HANUMANT LAXMAN BONDAR",
      party: "Independent",
      evm_votes: 2170,
      postal_votes: 2,
      total_votes: 2172,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 13,
      candidate: "NAVNATH DASHRATH UPALEKAR",
      party: "Independent",
      evm_votes: 2099,
      postal_votes: 9,
      total_votes: 2108,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 14,
      candidate: "SIDDIK IBRAHIM BAUDIWALE ALIAS GOLABHAI",
      party: "All India Majlis-E-Ittehadul Muslimeen",
      evm_votes: 2052,
      postal_votes: 8,
      total_votes: 2060,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 15,
      candidate: "ARJUN (DADA) SALGAR",
      party: "Independent",
      evm_votes: 1923,
      postal_votes: 12,
      total_votes: 1935,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 16,
      candidate: "ARYANRAJE KISANRAO SHINDE",
      party: "Rashtriya Samaj Dal (R)",
      evm_votes: 1852,
      postal_votes: 35,
      total_votes: 1887,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 17,
      candidate: "SHAIKH NAUSHAD IQBAL",
      party: "Swarajya Shakti Sena",
      evm_votes: 1671,
      postal_votes: 15,
      total_votes: 1686,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 18,
      candidate: "SHAMRAO HARIBHAU PAWAR",
      party: "Samnak Janta Party",
      evm_votes: 1492,
      postal_votes: 17,
      total_votes: 1509,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 19,
      candidate: "YOGIRAJ ANANTA TAMBE",
      party: "Independent",
      evm_votes: 1326,
      postal_votes: 10,
      total_votes: 1336,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 20,
      candidate: "SOMNATH NANASAHEB KAMBLE",
      party: "Independent",
      evm_votes: 1262,
      postal_votes: 6,
      total_votes: 1268,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 21,
      candidate: "BHORE NITIN KHANDU",
      party: "Independent",
      evm_votes: 1195,
      postal_votes: 9,
      total_votes: 1204,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 22,
      candidate: "MANOHAR ANANDRAO PATIL",
      party: "Independent",
      evm_votes: 1182,
      postal_votes: 8,
      total_votes: 1190,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 23,
      candidate: "NAVNATH VISHWANATH DUDHAL",
      party: "Vishva Shakti Party",
      evm_votes: 1123,
      postal_votes: 12,
      total_votes: 1135,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 24,
      candidate: "NITESH SHIVAJI PAWAR",
      party: "Hindrashtra Sangh",
      evm_votes: 1060,
      postal_votes: 19,
      total_votes: 1079,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 25,
      candidate: "ADV. VISHVJEET VIJAYKUMAR SHINDE",
      party: "Aadarsh Sangram Party",
      evm_votes: 855,
      postal_votes: 10,
      total_votes: 865,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 26,
      candidate: "SHAYANI NAVANATH JADHAV",
      party: "Independent",
      evm_votes: 779,
      postal_votes: 3,
      total_votes: 782,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 27,
      candidate: "NITIN NAGNATH GAIKWAD",
      party: "Independent",
      evm_votes: 729,
      postal_votes: 2,
      total_votes: 731,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 28,
      candidate: "ADV. BHAUSAHEB ANIL BELURE",
      party: "Independent",
      evm_votes: 675,
      postal_votes: 2,
      total_votes: 677,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 29,
      candidate: "VILAS BHAGWAT GHADGE",
      party: "Independent",
      evm_votes: 604,
      postal_votes: 6,
      total_votes: 610,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 30,
      candidate: "SAMEERSINGH RAMESHCHANDRA SALVI",
      party: "Independent",
      evm_votes: 591,
      postal_votes: 3,
      total_votes: 594,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 31,
      candidate: "RAJKUMAR SAHEBRAO PATIL",
      party: "Independent",
      evm_votes: 589,
      postal_votes: 4,
      total_votes: 593,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 32,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4250,
      postal_votes: 48,
      total_votes: 4298,
      percentage_of_votes: 0.34,
    },
  ],
  total: {
    evm_votes: 1273099,
    postal_votes: 7953,
    total_votes: 1281052,
  },
};
export const s138 = {
  cs_name: "Wardha",
  cs_shortname: "wardha",
  cs_code: "s138",
  winner: "NCPSP",
  stats: [
    {
      serial_no: 1,
      candidate: "AMAR SHARADRAO KALE",
      party: "Nationalist Congress Party – Sharadchandra Pawar",
      evm_votes: 530761,
      postal_votes: 2345,
      total_votes: 533106,
      percentage_of_votes: 48.68,
    },
    {
      serial_no: 2,
      candidate: "RAMDAS CHANDRABHAN TADAS",
      party: "Bharatiya Janata Party",
      evm_votes: 449599,
      postal_votes: 1859,
      total_votes: 451458,
      percentage_of_votes: 41.23,
    },
    {
      serial_no: 3,
      candidate: "DR. MOHAN RAMRAOJI RAIKWAR",
      party: "Bahujan Samaj Party",
      evm_votes: 20653,
      postal_votes: 142,
      total_votes: 20795,
      percentage_of_votes: 1.9,
    },
    {
      serial_no: 4,
      candidate: "PROF. RAJENDRA GULABRAO SALUNKHE",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 15408,
      postal_votes: 84,
      total_votes: 15492,
      percentage_of_votes: 1.41,
    },
    {
      serial_no: 5,
      candidate: "ASIF",
      party: "Independent",
      evm_votes: 15176,
      postal_votes: 6,
      total_votes: 15182,
      percentage_of_votes: 1.39,
    },
    {
      serial_no: 6,
      candidate: "KISHOR BABA PAVAR",
      party: "Independent",
      evm_votes: 12917,
      postal_votes: 3,
      total_votes: 12920,
      percentage_of_votes: 1.18,
    },
    {
      serial_no: 7,
      candidate: "SUHAS V. THAKARE",
      party: "Independent",
      evm_votes: 7639,
      postal_votes: 9,
      total_votes: 7648,
      percentage_of_votes: 0.7,
    },
    {
      serial_no: 8,
      candidate: "AKSHAY MEHARE BHARTIYA",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 5446,
      postal_votes: 21,
      total_votes: 5467,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 9,
      candidate: "MAROTI GULABRAO UIKE",
      party: "Gondvana Gantantra Party",
      evm_votes: 4651,
      postal_votes: 21,
      total_votes: 4672,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 10,
      candidate: "ADV. BHASKAR MAROTRAO NEWARE",
      party: "Independent",
      evm_votes: 4021,
      postal_votes: 11,
      total_votes: 4032,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 11,
      candidate: "JAGADISH UDDVRAO WANKHADE",
      party: "Independent",
      evm_votes: 2338,
      postal_votes: 11,
      total_votes: 2349,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 12,
      candidate: "POOJA PANKAJ TADAS",
      party: "Independent",
      evm_votes: 2121,
      postal_votes: 14,
      total_votes: 2135,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 13,
      candidate: "ANIL K. GHUSHE",
      party: "Independent",
      evm_votes: 1967,
      postal_votes: 4,
      total_votes: 1971,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 14,
      candidate: "ASHISH LEKHIRAM IZANKAR",
      party: "Vidarbha Rajya Aghadi",
      evm_votes: 1819,
      postal_votes: 9,
      total_votes: 1828,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 15,
      candidate: "VIJAY DNYANESHWARRAO SHRIRAO",
      party: "Independent",
      evm_votes: 1723,
      postal_votes: 15,
      total_votes: 1738,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 16,
      candidate: "ARVIND SHAMRAO LILLORE",
      party: "Independent",
      evm_votes: 1475,
      postal_votes: 1,
      total_votes: 1476,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 17,
      candidate: "RAMRAO BAJIRAO GHODASKAR",
      party: "All India Forward Bloc",
      evm_votes: 1418,
      postal_votes: 20,
      total_votes: 1438,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 18,
      candidate: "KRUSHNA SUBHASHRAO FULKARI",
      party: "Lok Swarajya Party",
      evm_votes: 1342,
      postal_votes: 1,
      total_votes: 1343,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 19,
      candidate: "UMESH SOMAJI WAWARE",
      party: "Maharashtra Vikas Aghadi",
      evm_votes: 1231,
      postal_votes: 15,
      total_votes: 1246,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 20,
      candidate: "KRUSHNA ANNAJI KALODE",
      party: "Hindrashtra Sangh",
      evm_votes: 1052,
      postal_votes: 9,
      total_votes: 1061,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 21,
      candidate: "RAMESH SINHA",
      party: "Independent",
      evm_votes: 799,
      postal_votes: 0,
      total_votes: 799,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 22,
      candidate: "DR. MORESHWAR RAMJI NAGRALE",
      party: "Republican Party of India",
      evm_votes: 792,
      postal_votes: 5,
      total_votes: 797,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 23,
      candidate: "DIXITA ANAND",
      party: "Desh Janhit Party",
      evm_votes: 731,
      postal_votes: 5,
      total_votes: 736,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 24,
      candidate: "RAHUL T. BHOYAR",
      party: "Independent",
      evm_votes: 685,
      postal_votes: 4,
      total_votes: 689,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 25,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4583,
      postal_votes: 51,
      total_votes: 4634,
      percentage_of_votes: 0.42,
    },
  ],
  total: {
    evm_votes: 1090347,
    postal_votes: 4665,
    total_votes: 1095012,
  },
};
export const s1320 = {
  cs_name: "Dindori",
  cs_shortname: "dindori",
  cs_code: "s1320",
  winner: "NCPSP",
  stats: [
    {
      serial_no: 1,
      candidate: "BHASKAR MURLIDHAR BHAGARE",
      party: "Nationalist Congress Party – Sharadchandra Pawar",
      evm_votes: 575291,
      postal_votes: 2048,
      total_votes: 577339,
      percentage_of_votes: 46.53,
    },
    {
      serial_no: 2,
      candidate: "DR. BHARATI PRAVIN PAWAR",
      party: "Bharatiya Janata Party",
      evm_votes: 462333,
      postal_votes: 1807,
      total_votes: 464140,
      percentage_of_votes: 37.4,
    },
    {
      serial_no: 3,
      candidate: "BABU SADU BHAGRE (SIR)",
      party: "Independent",
      evm_votes: 103526,
      postal_votes: 106,
      total_votes: 103632,
      percentage_of_votes: 8.35,
    },
    {
      serial_no: 4,
      candidate: "DHOMSE MALATI RAHUL",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 37006,
      postal_votes: 97,
      total_votes: 37103,
      percentage_of_votes: 2.99,
    },
    {
      serial_no: 5,
      candidate: "JAGTAP DIPAK GANPAT",
      party: "Independent",
      evm_votes: 15664,
      postal_votes: 17,
      total_votes: 15681,
      percentage_of_votes: 1.26,
    },
    {
      serial_no: 6,
      candidate: "TULSHIRAM CHIMAN KHOTARE",
      party: "Bahujan Samaj Party",
      evm_votes: 9589,
      postal_votes: 65,
      total_votes: 9654,
      percentage_of_votes: 0.78,
    },
    {
      serial_no: 7,
      candidate: "KISHOR AMBADAS DAGALE",
      party: "Ambedkarite Party of India",
      evm_votes: 8032,
      postal_votes: 23,
      total_votes: 8055,
      percentage_of_votes: 0.65,
    },
    {
      serial_no: 8,
      candidate: "ANIL GAWALIRAM BARDE",
      party: "Independent",
      evm_votes: 6432,
      postal_votes: 9,
      total_votes: 6441,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 9,
      candidate: "BHARAT ARUN PAWAR",
      party: "Bahujan Republican Socialist Party",
      evm_votes: 5613,
      postal_votes: 8,
      total_votes: 5621,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 10,
      candidate: "GULAB MOHAN BARDE",
      party: "Prabuddha Republican Party",
      evm_votes: 4983,
      postal_votes: 15,
      total_votes: 4998,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8206,
      postal_votes: 40,
      total_votes: 8246,
      percentage_of_votes: 0.66,
    },
  ],
  total: {
    evm_votes: 1236675,
    postal_votes: 4235,
    total_votes: 1240910,
  },
};
export const s1323 = {
  cs_name: "Bhiwandi",
  cs_shortname: "bhiwandi",
  cs_code: "s1323",
  winner: "NCPSP",
  stats: [
    {
      serial_no: 1,
      candidate: "BALYA MAMA - SURESH GOPINATH MHATRE",
      party: "Nationalist Congress Party – Sharadchandra Pawar",
      evm_votes: 498199,
      postal_votes: 1265,
      total_votes: 499464,
      percentage_of_votes: 39.85,
    },
    {
      serial_no: 2,
      candidate: "KAPIL MORESHWAR PATIL",
      party: "Bharatiya Janata Party",
      evm_votes: 431907,
      postal_votes: 1436,
      total_votes: 433343,
      percentage_of_votes: 34.57,
    },
    {
      serial_no: 3,
      candidate: "NILESH BHAGWAN SAMBARE",
      party: "Independent",
      evm_votes: 230254,
      postal_votes: 1163,
      total_votes: 231417,
      percentage_of_votes: 18.46,
    },
    {
      serial_no: 4,
      candidate: "KANCHAN VINAYAK VAKHARE",
      party: "New Rashtriya Samaj Party",
      evm_votes: 24607,
      postal_votes: 18,
      total_votes: 24625,
      percentage_of_votes: 1.96,
    },
    {
      serial_no: 5,
      candidate: "TARA PINTYA WAGHE",
      party: "Independent",
      evm_votes: 6908,
      postal_votes: 0,
      total_votes: 6908,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 6,
      candidate: "MUMATAZ ABDULSATTAR ANSARI",
      party: "Bahujan Samaj Party",
      evm_votes: 6801,
      postal_votes: 21,
      total_votes: 6822,
      percentage_of_votes: 0.54,
    },
    {
      serial_no: 7,
      candidate: "MANISHA SANTOSH GONDHALE",
      party: "Independent",
      evm_votes: 5120,
      postal_votes: 0,
      total_votes: 5120,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 8,
      candidate: "MANOJ GOVAR TURE",
      party: "Independent",
      evm_votes: 4896,
      postal_votes: 1,
      total_votes: 4897,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 9,
      candidate: "JAHID MURABTAR ANSARI",
      party: "Independent",
      evm_votes: 4879,
      postal_votes: 3,
      total_votes: 4882,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 10,
      candidate: "CHANDRAKANT RAMBHAJI MOTE",
      party: "Independent",
      evm_votes: 3429,
      postal_votes: 4,
      total_votes: 3433,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 11,
      candidate: "RAHUL ASHOK KATHOLE",
      party: "Independent",
      evm_votes: 3131,
      postal_votes: 4,
      total_votes: 3135,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 12,
      candidate: "ASHOK BHIKU BAHADARE",
      party: "Sanyukt Bharat Paksh",
      evm_votes: 2044,
      postal_votes: 3,
      total_votes: 2047,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 13,
      candidate: "SURESH PANDAGALE",
      party: "Apni Prajahit Party",
      evm_votes: 1997,
      postal_votes: 16,
      total_votes: 2013,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 14,
      candidate: "MILIND KASHINATH KAMBLE",
      party: "Independent",
      evm_votes: 1843,
      postal_votes: 18,
      total_votes: 1861,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 15,
      candidate: "KHAN MOHD AKARAM ABDUL HANAN",
      party: "All India Majlis-E-Ittehadul Muslimeen",
      evm_votes: 1739,
      postal_votes: 2,
      total_votes: 1741,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 16,
      candidate: "DANISH EZAZ AH. SHAIKH",
      party: "Bahujan Maha Party",
      evm_votes: 1580,
      postal_votes: 6,
      total_votes: 1586,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 17,
      candidate: "HARSHAD RAMESH MHATRE",
      party: "Independent",
      evm_votes: 1568,
      postal_votes: 0,
      total_votes: 1568,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 18,
      candidate: "MUTKIRI SHANKAR NAGESH",
      party: "Independent",
      evm_votes: 1445,
      postal_votes: 0,
      total_votes: 1445,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 19,
      candidate: "KAPIL JAYHIND PATIL",
      party: "Independent",
      evm_votes: 1237,
      postal_votes: 5,
      total_votes: 1242,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 20,
      candidate: "SAIFAN CHAND PATHAN",
      party: "Bhartiya Manavta Party",
      evm_votes: 1188,
      postal_votes: 1,
      total_votes: 1189,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 21,
      candidate: "RANJANA RAVI TRIBHUWAN",
      party: "Independent",
      evm_votes: 1082,
      postal_votes: 3,
      total_votes: 1085,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 22,
      candidate: "MOHD. KALEEM ANSARI",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 934,
      postal_votes: 3,
      total_votes: 937,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 23,
      candidate: "WASEEM TUFAIL SIDDIQUI",
      party: "Independent",
      evm_votes: 803,
      postal_votes: 2,
      total_votes: 805,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 24,
      candidate: "SURESH SITARAM MHATRE",
      party: "Independent",
      evm_votes: 706,
      postal_votes: 1,
      total_votes: 707,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 25,
      candidate: "SONALI ASHOK GANGAWANE",
      party: "Independent",
      evm_votes: 698,
      postal_votes: 0,
      total_votes: 698,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 26,
      candidate: "AAPPA - VISHAL VIJAY MORE",
      party: "Independent",
      evm_votes: 598,
      postal_votes: 3,
      total_votes: 601,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 27,
      candidate: "PROF. AMEERUL HASAN SAYYED",
      party: "Independent",
      evm_votes: 479,
      postal_votes: 2,
      total_votes: 481,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 28,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9266,
      postal_votes: 81,
      total_votes: 9347,
      percentage_of_votes: 0.75,
    },
  ],
  total: {
    evm_votes: 1249338,
    postal_votes: 4061,
    total_votes: 1253399,
  },
};
export const s1335 = {
  cs_name: "Baramati",
  cs_shortname: "baramati",
  cs_code: "s1335",
  winner: "NCPSP",
  stats: [
    {
      serial_no: 1,
      candidate: "SUPRIYA SULE",
      party: "Nationalist Congress Party – Sharadchandra Pawar",
      evm_votes: 731400,
      postal_votes: 912,
      total_votes: 732312,
      percentage_of_votes: 51.85,
    },
    {
      serial_no: 2,
      candidate: "SUNETRA AJITDADA PAWAR",
      party: "Nationalist Congress Party",
      evm_votes: 573391,
      postal_votes: 588,
      total_votes: 573979,
      percentage_of_votes: 40.64,
    },
    {
      serial_no: 3,
      candidate: "MAHESH SITARAM BHAGWAT",
      party: "Independent",
      evm_votes: 15620,
      postal_votes: 43,
      total_votes: 15663,
      percentage_of_votes: 1.11,
    },
    {
      serial_no: 4,
      candidate: "SHAIKH SOYALSHAHA YUNUSSHAHA",
      party: "Independent",
      evm_votes: 14913,
      postal_votes: 4,
      total_votes: 14917,
      percentage_of_votes: 1.06,
    },
    {
      serial_no: 5,
      candidate: "PRIYADARSHANI NANDKUMAR KOKARE",
      party: "Bahujan Samaj Party",
      evm_votes: 9995,
      postal_votes: 35,
      total_votes: 10030,
      percentage_of_votes: 0.71,
    },
    {
      serial_no: 6,
      candidate: "SACHIN SHANKAR AGAWANE",
      party: "Independent",
      evm_votes: 6633,
      postal_votes: 0,
      total_votes: 6633,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 7,
      candidate: "KAMBLE TRISHALA",
      party: "Bahujan Samaj Party (Ambedkar)",
      evm_votes: 5032,
      postal_votes: 4,
      total_votes: 5036,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 8,
      candidate: "SUNITA PAWAR",
      party: "Independent",
      evm_votes: 4150,
      postal_votes: 1,
      total_votes: 4151,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 9,
      candidate: "GAJANAN UTTAM GAWALI (PATIL)",
      party: "Independent",
      evm_votes: 2991,
      postal_votes: 4,
      total_votes: 2995,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 10,
      candidate: "MANOJ BALASAHEB RASAL",
      party: "Independent",
      evm_votes: 2610,
      postal_votes: 0,
      total_votes: 2610,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 11,
      candidate: "MAHADEV SAHEBRAO KHENGARE - PATIL",
      party: "Bhartiya Navjawan Sena (Paksha)",
      evm_votes: 2412,
      postal_votes: 6,
      total_votes: 2418,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "PRADEEP RAMCHANDRA MANE",
      party: "Independent",
      evm_votes: 2234,
      postal_votes: 1,
      total_votes: 2235,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 13,
      candidate: "SHIVAJI JAYSING KOKRE",
      party: "Independent",
      evm_votes: 2161,
      postal_votes: 3,
      total_votes: 2164,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 14,
      candidate: "PROF. NAMDEORAO JADHAV",
      party: "Independent",
      evm_votes: 2125,
      postal_votes: 9,
      total_votes: 2134,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 15,
      candidate: "RAJENDRA PANDURANG BHOSALE",
      party: "People’s Union Party",
      evm_votes: 2007,
      postal_votes: 2,
      total_votes: 2009,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 16,
      candidate: "KALYANI SUJITKUMAR WAGHMODE",
      party: "Independent",
      evm_votes: 1974,
      postal_votes: 1,
      total_votes: 1975,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 17,
      candidate: "DASHRATH NANA RAUT",
      party: "Bharatiya Praja Surajya Paksha",
      evm_votes: 1828,
      postal_votes: 6,
      total_votes: 1834,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 18,
      candidate: "ANKUSH DNYANESHAWAR PILANE",
      party: "Independent",
      evm_votes: 1663,
      postal_votes: 0,
      total_votes: 1663,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 19,
      candidate: "SANDIP ABAJI DEOKATE",
      party: "Independent",
      evm_votes: 1622,
      postal_votes: 2,
      total_votes: 1624,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 20,
      candidate: "DATTATRAY RAMBHAU CHANDARE",
      party: "Independent",
      evm_votes: 1433,
      postal_votes: 1,
      total_votes: 1434,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 21,
      candidate: "SHAILENDRA ALIAS SANDEEP KARANJAWANE",
      party: "Independent",
      evm_votes: 1392,
      postal_votes: 0,
      total_votes: 1392,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 22,
      candidate: "SHUBHANGI DHAYGUDE",
      party: "Independent",
      evm_votes: 1283,
      postal_votes: 9,
      total_votes: 1292,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 23,
      candidate: "DR. SOMNATH ALIAS BALASAHEB ARJUN POL",
      party: "Independent",
      evm_votes: 1169,
      postal_votes: 0,
      total_votes: 1169,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 24,
      candidate: "ROHIDAS BALASO KONDAKE",
      party: "Bahujan Republican Socialist Party",
      evm_votes: 1127,
      postal_votes: 3,
      total_votes: 1130,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 25,
      candidate: "MILIND VITTHAL SHINDE",
      party: "Independent",
      evm_votes: 1099,
      postal_votes: 0,
      total_votes: 1099,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 26,
      candidate: "SURESHDADA BABURAO VIR",
      party: "Independent",
      evm_votes: 1012,
      postal_votes: 0,
      total_votes: 1012,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 27,
      candidate: "BAPU PRALHAD PAWAR",
      party: "Independent",
      evm_votes: 997,
      postal_votes: 1,
      total_votes: 998,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 28,
      candidate: "RAJENDRA MAHADEV BARAKADE",
      party: "Independent",
      evm_votes: 905,
      postal_votes: 2,
      total_votes: 907,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 29,
      candidate: "UMESH MAHADEV MHETRE",
      party: "Independent",
      evm_votes: 807,
      postal_votes: 1,
      total_votes: 808,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 30,
      candidate: "SHIVAJI RAMBHAU NANDKHILE",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 785,
      postal_votes: 4,
      total_votes: 789,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 31,
      candidate: "SHARAD RAM PAWAR",
      party: "Independent",
      evm_votes: 730,
      postal_votes: 1,
      total_votes: 731,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 32,
      candidate: "LAXMAN RAM KUMBHAR",
      party: "Dalit Soshit Pichhara Varg Adhikar Dal",
      evm_votes: 694,
      postal_votes: 2,
      total_votes: 696,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 33,
      candidate: "SHRIDHAR NARAYAN SALVE",
      party: "Bhim Sena",
      evm_votes: 675,
      postal_votes: 4,
      total_votes: 679,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 34,
      candidate: "BALASO MARUTI DHAPATE",
      party: "Independent",
      evm_votes: 655,
      postal_votes: 0,
      total_votes: 655,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 35,
      candidate: "VIJAY LAXMAN GHAVALE",
      party: "Independent",
      evm_votes: 617,
      postal_votes: 3,
      total_votes: 620,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 36,
      candidate: "VISHAL ARUN PAWAR",
      party: "Independent",
      evm_votes: 553,
      postal_votes: 0,
      total_votes: 553,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 37,
      candidate: "SAVITA BHIMRAO KADALE",
      party: "Hindustan Janta Party",
      evm_votes: 549,
      postal_votes: 2,
      total_votes: 551,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 38,
      candidate: "VIJAYPRAKASH ANANT KONDEKAR",
      party: "Independent",
      evm_votes: 301,
      postal_votes: 0,
      total_votes: 301,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 39,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9130,
      postal_votes: 21,
      total_votes: 9151,
      percentage_of_votes: 0.65,
    },
  ],
  total: {
    evm_votes: 1410674,
    postal_votes: 1675,
    total_votes: 1412349,
  },
};
export const s1336 = {
  cs_name: "Shirur",
  cs_shortname: "shirur",
  cs_code: "s1336",
  winner: "NCPSP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR. AMOL RAMSING KOLHE",
      party: "Nationalist Congress Party – Sharadchandra Pawar",
      evm_votes: 697768,
      postal_votes: 924,
      total_votes: 698692,
      percentage_of_votes: 50.83,
    },
    {
      serial_no: 2,
      candidate: "ADHALRAO SHIVAJI DATTATREY",
      party: "Nationalist Congress Party",
      evm_votes: 557138,
      postal_votes: 603,
      total_votes: 557741,
      percentage_of_votes: 40.58,
    },
    {
      serial_no: 3,
      candidate: "MANOHAR MAHADU WADEKAR",
      party: "Independent",
      evm_votes: 28324,
      postal_votes: 6,
      total_votes: 28330,
      percentage_of_votes: 2.06,
    },
    {
      serial_no: 4,
      candidate: "DR. ANWAR SHAIKH",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 17444,
      postal_votes: 18,
      total_votes: 17462,
      percentage_of_votes: 1.27,
    },
    {
      serial_no: 5,
      candidate: "RAHUL RAGHUNATH OWHAL",
      party: "Bahujan Samaj Party",
      evm_votes: 10280,
      postal_votes: 15,
      total_votes: 10295,
      percentage_of_votes: 0.75,
    },
    {
      serial_no: 6,
      candidate: "CHANDGUDE VINOD VASANT",
      party: "Independent",
      evm_votes: 8076,
      postal_votes: 2,
      total_votes: 8078,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 7,
      candidate: "GORAKSH KASABE",
      party: "Independent",
      evm_votes: 4913,
      postal_votes: 2,
      total_votes: 4915,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 8,
      candidate: "ADV. DR. LAXMAN RAMBHAU DAMSE",
      party: "Bharat Adivasi Party",
      evm_votes: 4125,
      postal_votes: 11,
      total_votes: 4136,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 9,
      candidate: "GAIKWAD NILOBA CHANGADEO",
      party: "Independent",
      evm_votes: 3569,
      postal_votes: 2,
      total_votes: 3571,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 10,
      candidate: "UMESH MAHADEV MHETRE",
      party: "Independent",
      evm_votes: 3499,
      postal_votes: 0,
      total_votes: 3499,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 11,
      candidate: "ADV.SWAPNIL BHAUSAHEB SHELAR",
      party: "Independent",
      evm_votes: 2667,
      postal_votes: 0,
      total_votes: 2667,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 12,
      candidate: "AMOL VILAS PACHUNDKAR",
      party: "Independent",
      evm_votes: 2386,
      postal_votes: 2,
      total_votes: 2388,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 13,
      candidate: "ASLAM ISAK BAGWAN",
      party: "Lok Sena Party",
      evm_votes: 2348,
      postal_votes: 2,
      total_votes: 2350,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 14,
      candidate: "KAMBLE TRIMBAK NARAYAN",
      party: "Baliraja Party",
      evm_votes: 2223,
      postal_votes: 4,
      total_votes: 2227,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 15,
      candidate: "SUBASH KASHINATH SARODE",
      party: "Independent",
      evm_votes: 2161,
      postal_votes: 0,
      total_votes: 2161,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 16,
      candidate: "CHAAYA SANJAY JAGDALE-SOLANKE",
      party: "Independent",
      evm_votes: 1823,
      postal_votes: 7,
      total_votes: 1830,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 17,
      candidate: "SUSHIL DNYANDEV KANSE",
      party: "Independent",
      evm_votes: 1731,
      postal_votes: 1,
      total_votes: 1732,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 18,
      candidate: "NARAYAN PRABHAKAR ANKUSHE",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 1279,
      postal_votes: 5,
      total_votes: 1284,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 19,
      candidate: "BHOR VIKAS ROHIDAS",
      party: "Independent",
      evm_votes: 1145,
      postal_votes: 2,
      total_votes: 1147,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 20,
      candidate: "DADA ALIAS VIKAS SURESH KASBE",
      party: "Independent",
      evm_votes: 1105,
      postal_votes: 1,
      total_votes: 1106,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 21,
      candidate: "SURAJ CHANDRAKANT GAIKWAD",
      party: "Independent",
      evm_votes: 936,
      postal_votes: 2,
      total_votes: 938,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 22,
      candidate: "SWAPNIL DHANAJI LONDHE",
      party: "Bhim Sena",
      evm_votes: 913,
      postal_votes: 1,
      total_votes: 914,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 23,
      candidate: "AMAR MACHINDRA BORHADE",
      party: "Independent",
      evm_votes: 887,
      postal_votes: 1,
      total_votes: 888,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 24,
      candidate: "RAVINDRA MOTIRAM SIRSAM",
      party: "Independent",
      evm_votes: 870,
      postal_votes: 0,
      total_votes: 870,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 25,
      candidate: "TUKARAM NAMDEO DAPHAL",
      party: "Sainik Samaj Party",
      evm_votes: 862,
      postal_votes: 20,
      total_votes: 882,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 26,
      candidate: "PANDURANG BHAGWAN WAGHOLE",
      party: "Independent",
      evm_votes: 818,
      postal_votes: 1,
      total_votes: 819,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 27,
      candidate: "SANJAY LAXMAN PADWAL",
      party: "Bhartiya Navjawan Sena (Paksha)",
      evm_votes: 779,
      postal_votes: 5,
      total_votes: 784,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 28,
      candidate: "HARIDAS CHANDAR BHISE",
      party: "Bharatiya Lokvikas Party",
      evm_votes: 721,
      postal_votes: 2,
      total_votes: 723,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 29,
      candidate: "SALIM BABA SAYYAD",
      party: "Independent",
      evm_votes: 671,
      postal_votes: 0,
      total_votes: 671,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 30,
      candidate: "PRAKASH SUKHADEO JAMDHADE",
      party: "Independent",
      evm_votes: 602,
      postal_votes: 1,
      total_votes: 603,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 31,
      candidate: "WAKHARE PRADIP PANDURANG",
      party: "Independent",
      evm_votes: 574,
      postal_votes: 0,
      total_votes: 574,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 32,
      candidate: "RAHUL ARJUN HORE",
      party: "Independent",
      evm_votes: 526,
      postal_votes: 0,
      total_votes: 526,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 33,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9634,
      postal_votes: 27,
      total_votes: 9661,
      percentage_of_votes: 0.7,
    },
  ],
  total: {
    evm_votes: 1372797,
    postal_votes: 1667,
    total_votes: 1374464,
  },
};
export const s1337 = {
  cs_name: "Ahmednagar",
  cs_shortname: "ahmednagar",
  cs_code: "s1337",
  winner: "NCPSP",
  stats: [
    {
      serial_no: 1,
      candidate: "NILESH DNYANDEV LANKE",
      party: "Nationalist Congress Party – Sharadchandra Pawar",
      evm_votes: 622144,
      postal_votes: 2653,
      total_votes: 624797,
      percentage_of_votes: 47.14,
    },
    {
      serial_no: 2,
      candidate: "DR. SUJAY RADHAKRISHNA VIKHEPATIL",
      party: "Bharatiya Janata Party",
      evm_votes: 593568,
      postal_votes: 2300,
      total_votes: 595868,
      percentage_of_votes: 44.95,
    },
    {
      serial_no: 3,
      candidate: "ALEKAR GORAKH DASHRATH",
      party: "Independent",
      evm_votes: 44582,
      postal_votes: 15,
      total_votes: 44597,
      percentage_of_votes: 3.36,
    },
    {
      serial_no: 4,
      candidate: "DEELIP KONDIBA KHEDKAR",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 13689,
      postal_votes: 60,
      total_votes: 13749,
      percentage_of_votes: 1.04,
    },
    {
      serial_no: 5,
      candidate: "AMOL VILAS PACHUNDKAR",
      party: "Independent",
      evm_votes: 5491,
      postal_votes: 2,
      total_votes: 5493,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 6,
      candidate: "BHAGWAT DHONDIBA GAIKWAD",
      party: "Samata Party",
      evm_votes: 4941,
      postal_votes: 3,
      total_votes: 4944,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 7,
      candidate: "UMASHANKAR SHYAMBABU YADAV",
      party: "Bahujan Samaj Party",
      evm_votes: 4509,
      postal_votes: 30,
      total_votes: 4539,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 8,
      candidate: "ADV. JAMIR SHAIKH",
      party: "Independent",
      evm_votes: 3934,
      postal_votes: 1,
      total_votes: 3935,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 9,
      candidate: "AARTI KISHORKUMAR HALDAR",
      party: "Prabuddha Republican Party",
      evm_votes: 3790,
      postal_votes: 9,
      total_votes: 3799,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 10,
      candidate: "NAVSHAD MUNSILAL SHAIKH",
      party: "Independent",
      evm_votes: 2226,
      postal_votes: 0,
      total_votes: 2226,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 11,
      candidate: "SURYABHAN (ALIAS SURESH) DATTATRAYA LAMBE",
      party: "Independent",
      evm_votes: 2092,
      postal_votes: 0,
      total_votes: 2092,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 12,
      candidate: "KALIRAM BAHIRU POPALGHAT",
      party: "Bhartiya Navjawan Sena (Paksha)",
      evm_votes: 1975,
      postal_votes: 6,
      total_votes: 1981,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 13,
      candidate: "GAVADE MACCHINDRA RADHAKISAN",
      party: "Independent",
      evm_votes: 1940,
      postal_votes: 1,
      total_votes: 1941,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 14,
      candidate: "PRAVIN SUBHASH DALVI",
      party: "Independent",
      evm_votes: 1896,
      postal_votes: 2,
      total_votes: 1898,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 15,
      candidate: "MADAN KANIFNATH SONVANE",
      party: "Right to Recall Party",
      evm_votes: 1445,
      postal_votes: 6,
      total_votes: 1451,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 16,
      candidate: "DR. KAILASH NIVRUTTI JADHAV",
      party: "Maharashtra Vikas Aghadi",
      evm_votes: 1425,
      postal_votes: 10,
      total_votes: 1435,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 17,
      candidate: "KOTHARI RAVINDRA LILACHAND",
      party: "Rashtriya Janmanch (Secular)",
      evm_votes: 1330,
      postal_votes: 3,
      total_votes: 1333,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 18,
      candidate: "ADV. SHIVAJIRAO WAMAN DAMALE (EX. SGT OF IAF)",
      party: "Sainik Samaj Party",
      evm_votes: 1093,
      postal_votes: 11,
      total_votes: 1104,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 19,
      candidate: "GANGADHAR HARIBHAU KOLEKAR",
      party: "Independent",
      evm_votes: 1059,
      postal_votes: 0,
      total_votes: 1059,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 20,
      candidate: "DATTATRAY APPA WAGHMODE",
      party: "Rashtriya Jankranti Party",
      evm_votes: 868,
      postal_votes: 5,
      total_votes: 873,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 21,
      candidate: "WABLE BHAUSAHEB BAPURAO",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 790,
      postal_votes: 11,
      total_votes: 801,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 22,
      candidate: "SHEKATKAR ANIL GANPAT",
      party: "Independent",
      evm_votes: 702,
      postal_votes: 0,
      total_votes: 702,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 23,
      candidate: "ADV. MAHENDRA DADASAHEB SHINDE",
      party: "Independent",
      evm_votes: 674,
      postal_votes: 1,
      total_votes: 675,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 24,
      candidate: "BILAL GAFUR SHAIKH",
      party: "Independent",
      evm_votes: 531,
      postal_votes: 0,
      total_votes: 531,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 25,
      candidate: "RAOSAHEB SHANKAR KALE",
      party: "Independent",
      evm_votes: 370,
      postal_votes: 2,
      total_votes: 372,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 26,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3254,
      postal_votes: 28,
      total_votes: 3282,
      percentage_of_votes: 0.25,
    },
  ],
  total: {
    evm_votes: 1320318,
    postal_votes: 5159,
    total_votes: 1325477,
  },
};
export const s1339 = {
  cs_name: "Beed",
  cs_shortname: "beed",
  cs_code: "s1339",
  winner: "NCPSP",
  stats: [
    {
      serial_no: 1,
      candidate: "BAJRANG MANOHAR SONWANE",
      party: "Nationalist Congress Party – Sharadchandra Pawar",
      evm_votes: 682532,
      postal_votes: 1418,
      total_votes: 683950,
      percentage_of_votes: 44.93,
    },
    {
      serial_no: 2,
      candidate: "PANKAJA GOPINATHRAO MUNDE",
      party: "Bharatiya Janata Party",
      evm_votes: 675349,
      postal_votes: 2048,
      total_votes: 677397,
      percentage_of_votes: 44.5,
    },
    {
      serial_no: 3,
      candidate: "ASHOK BHAGOJI THORAT",
      party: "Bahujan Maha Party",
      evm_votes: 54829,
      postal_votes: 21,
      total_votes: 54850,
      percentage_of_votes: 3.6,
    },
    {
      serial_no: 4,
      candidate: "ASHOK SUKHDEV HINGE",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 50741,
      postal_votes: 126,
      total_votes: 50867,
      percentage_of_votes: 3.34,
    },
    {
      serial_no: 5,
      candidate: "SHAIKH TOUSIF ABDUL SATTAR",
      party: "Independent",
      evm_votes: 9472,
      postal_votes: 0,
      total_votes: 9472,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 6,
      candidate: "DR. SIDDHARTH RAJENDRA TAKANKAR",
      party: "Bahujan Samaj Party",
      evm_votes: 5092,
      postal_votes: 19,
      total_votes: 5111,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 7,
      candidate: "SHAMSHER KHAN SAHEB KHAN PATHAN",
      party: "Independent",
      evm_votes: 4704,
      postal_votes: 0,
      total_votes: 4704,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 8,
      candidate: "JAVED SIKANDAR MOMIN",
      party: "Independent",
      evm_votes: 2662,
      postal_votes: 0,
      total_votes: 2662,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 9,
      candidate: "NAZEM KHAN JABBAR KHAN PATHAN",
      party: "Independent",
      evm_votes: 2586,
      postal_votes: 0,
      total_votes: 2586,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 10,
      candidate: "GOKUL BAPURAO SAWASE",
      party: "Independent",
      evm_votes: 2576,
      postal_votes: 1,
      total_votes: 2577,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 11,
      candidate: "GAFFARKHAN JABBARKHAN PATHAN",
      party: "Independent",
      evm_votes: 2561,
      postal_votes: 2,
      total_votes: 2563,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "DATTA SUDAM GAIKWAD",
      party: "Independent",
      evm_votes: 2130,
      postal_votes: 0,
      total_votes: 2130,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 13,
      candidate: "SATISH PADMAKAR KAPSE",
      party: "Independent",
      evm_votes: 2098,
      postal_votes: 1,
      total_votes: 2099,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 14,
      candidate: "KARUNA DHANANJAY MUNDE",
      party: "Swarajya Shakti Sena",
      evm_votes: 1594,
      postal_votes: 5,
      total_votes: 1599,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 15,
      candidate: "DR. SHRIRAM VITTHALRAO KHALGE",
      party: "Independent",
      evm_votes: 1512,
      postal_votes: 0,
      total_votes: 1512,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 16,
      candidate: "ADV. GANESH NAVNATHRAO KARANDE",
      party: "Independent",
      evm_votes: 1506,
      postal_votes: 2,
      total_votes: 1508,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 17,
      candidate: "SHITAL SHIVAJI DHONDRE",
      party: "Independent",
      evm_votes: 1302,
      postal_votes: 1,
      total_votes: 1303,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 18,
      candidate: "SHAIKH YASHED SHAIKH TAYYAB",
      party: "Independent",
      evm_votes: 1258,
      postal_votes: 0,
      total_votes: 1258,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 19,
      candidate: "JAWED SALEEM SYED",
      party: "Tipu Sultan Party",
      evm_votes: 972,
      postal_votes: 6,
      total_votes: 978,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 20,
      candidate: "PRAKASH BHAGWANRAO SOLANKE",
      party: "Independent",
      evm_votes: 885,
      postal_votes: 1,
      total_votes: 886,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 21,
      candidate: "CHANDRAKANT KUMAR HAJARE",
      party: "Maharashtra Vikas Aghadi",
      evm_votes: 800,
      postal_votes: 3,
      total_votes: 803,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 22,
      candidate: "SALAUDDIN KHAN PATHAN",
      party: "Independent",
      evm_votes: 724,
      postal_votes: 0,
      total_votes: 724,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 23,
      candidate: "KASPATE GANESH VYANKATRAO",
      party: "Independent",
      evm_votes: 719,
      postal_votes: 0,
      total_votes: 719,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 24,
      candidate: "MUBEEN ZUBERI ZAHEER UL AFAQUE",
      party: "Independent",
      evm_votes: 675,
      postal_votes: 1,
      total_votes: 676,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 25,
      candidate: "WASIM SHAIKH SALIM SHAIKH",
      party: "Independent",
      evm_votes: 579,
      postal_votes: 1,
      total_votes: 580,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 26,
      candidate: "SHAIKH EJAJ SHAIKH UMAR",
      party: "Independent",
      evm_votes: 550,
      postal_votes: 0,
      total_votes: 550,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 27,
      candidate: "SHESHERAO CHOKHOBA VEER",
      party: "Prabuddha Republican Party",
      evm_votes: 537,
      postal_votes: 0,
      total_votes: 537,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 28,
      candidate: "TATE MAHENDRA ASHOK",
      party: "Ambedkarist Republican Party",
      evm_votes: 531,
      postal_votes: 2,
      total_votes: 533,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 29,
      candidate: "MUSTAFA MAINODDIN SHAIKH",
      party: "Independent",
      evm_votes: 531,
      postal_votes: 0,
      total_votes: 531,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 30,
      candidate: "SULEMAN KHAIRODDIN MOHMMAD",
      party: "Independent",
      evm_votes: 522,
      postal_votes: 0,
      total_votes: 522,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 31,
      candidate: "HIDAYAT SADEKH ALI SYED",
      party: "Independent",
      evm_votes: 448,
      postal_votes: 0,
      total_votes: 448,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 32,
      candidate: "RAJENDRA ACHYUTRAO HOKE",
      party: "Independent",
      evm_votes: 427,
      postal_votes: 0,
      total_votes: 427,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 33,
      candidate: "ADV. MANIK BANSI AADMANE",
      party: "Bahujan Republican Socialist Party",
      evm_votes: 425,
      postal_votes: 1,
      total_votes: 426,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 34,
      candidate: "VACHISHT UDDHAV KUTE",
      party: "Independent",
      evm_votes: 414,
      postal_votes: 0,
      total_votes: 414,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 35,
      candidate: "ADV. SHARAD BAHINAJI KAMBLE",
      party: "All India National Raksha Sena",
      evm_votes: 393,
      postal_votes: 3,
      total_votes: 396,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 36,
      candidate: "BHASKAR BANSIDHARRAO KHANDE",
      party: "Independent",
      evm_votes: 390,
      postal_votes: 0,
      total_votes: 390,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 37,
      candidate: "RAHEMAN BAHODDIN SAYYED",
      party: "Independent",
      evm_votes: 384,
      postal_votes: 0,
      total_votes: 384,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 38,
      candidate: "LAXMIBAI BAJIRAO MORE",
      party: "Independent",
      evm_votes: 382,
      postal_votes: 0,
      total_votes: 382,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 39,
      candidate: "SYED MINHAJ ALI",
      party: "Independent",
      evm_votes: 310,
      postal_votes: 0,
      total_votes: 310,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 40,
      candidate: "SALIM ALLABAKSHA SAYYAD",
      party: "Independent",
      evm_votes: 238,
      postal_votes: 0,
      total_votes: 238,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 41,
      candidate: "SADEK IBRAHIM SHAIKH",
      party: "Independent",
      evm_votes: 206,
      postal_votes: 0,
      total_votes: 206,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 42,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 2068,
      postal_votes: 19,
      total_votes: 2087,
      percentage_of_votes: 0.14,
    },
  ],
  total: {
    evm_votes: 1518614,
    postal_votes: 3681,
    total_votes: 1522295,
  },
};
export const s1343 = {
  cs_name: "Madha",
  cs_shortname: "madha",
  cs_code: "s1343",
  winner: "NCPSP",
  stats: [
    {
      serial_no: 1,
      candidate: "MOHITE-PATIL DHAIRYASHEEL RAJSINH",
      party: "Nationalist Congress Party – Sharadchandra Pawar",
      evm_votes: 618566,
      postal_votes: 3647,
      total_votes: 622213,
      percentage_of_votes: 48.86,
    },
    {
      serial_no: 2,
      candidate: "RANJEETSINGH HINDURAO NAIK NIMBALKAR",
      party: "Bharatiya Janata Party",
      evm_votes: 499171,
      postal_votes: 2205,
      total_votes: 501376,
      percentage_of_votes: 39.37,
    },
    {
      serial_no: 3,
      candidate: "ER. RAMCHANDRA MAYAPPA GHUTUKADE",
      party: "New Rashtriya Samaj Party",
      evm_votes: 58344,
      postal_votes: 77,
      total_votes: 58421,
      percentage_of_votes: 4.59,
    },
    {
      serial_no: 4,
      candidate: "BARASKAR RAMESH NAGNATH",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 20519,
      postal_votes: 85,
      total_votes: 20604,
      percentage_of_votes: 1.62,
    },
    {
      serial_no: 5,
      candidate: "JANKAR SWARUP DADA",
      party: "Bahujan Samaj Party",
      evm_votes: 7036,
      postal_votes: 58,
      total_votes: 7094,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 6,
      candidate: "HAKE LAXMAN SOPAN",
      party: "Independent",
      evm_votes: 5083,
      postal_votes: 51,
      total_votes: 5134,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 7,
      candidate: "SITARAM VITTHAL RANDIVE",
      party: "Independent",
      evm_votes: 4980,
      postal_votes: 6,
      total_votes: 4986,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 8,
      candidate: "DHANAJI SHRIPATI MASKE",
      party: "Independent",
      evm_votes: 4433,
      postal_votes: 0,
      total_votes: 4433,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 9,
      candidate: "BICHUKALE SANTOSH BALASAHEB",
      party: "Republican Party of India (A)",
      evm_votes: 4039,
      postal_votes: 7,
      total_votes: 4046,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 10,
      candidate: "SAWANT RAHUL SHASHIKANT",
      party: "Independent",
      evm_votes: 3903,
      postal_votes: 6,
      total_votes: 3909,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 11,
      candidate: "AWARE SIDDHESHWAR BHARAT",
      party: "All India Forward Bloc",
      evm_votes: 3058,
      postal_votes: 9,
      total_votes: 3067,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 12,
      candidate: "HANUMANT NARAYAN MANE",
      party: "Independent",
      evm_votes: 2862,
      postal_votes: 2,
      total_votes: 2864,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 13,
      candidate: "NANDU SAMBHAJI MORE",
      party: "Independent",
      evm_votes: 2686,
      postal_votes: 1,
      total_votes: 2687,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 14,
      candidate: "NAVANATH BIRA MADANE",
      party: "Independent",
      evm_votes: 2434,
      postal_votes: 1,
      total_votes: 2435,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 15,
      candidate: "GIRISH PRABHAKAR SHETE",
      party: "Independent",
      evm_votes: 2380,
      postal_votes: 3,
      total_votes: 2383,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 16,
      candidate: "ADV. JORE SACHIN BHASKAR",
      party: "Independent",
      evm_votes: 2344,
      postal_votes: 3,
      total_votes: 2347,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 17,
      candidate: "NANASAHEB RAMHARI YADAV",
      party: "Independent",
      evm_votes: 2213,
      postal_votes: 1,
      total_votes: 2214,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 18,
      candidate: "KIRAN TANAJI SATHE",
      party: "Independent",
      evm_votes: 2167,
      postal_votes: 8,
      total_votes: 2175,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 19,
      candidate: "BHAUSAHEB SUKHADEV LIGADE",
      party: "Independent",
      evm_votes: 2034,
      postal_votes: 18,
      total_votes: 2052,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 20,
      candidate: "ER. BALIRAM SUKHADEV MORE",
      party: "Independent",
      evm_votes: 1952,
      postal_votes: 1,
      total_votes: 1953,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 21,
      candidate: "KHARAT SANDIP JANARADHAN",
      party: "Independent",
      evm_votes: 1746,
      postal_votes: 9,
      total_votes: 1755,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 22,
      candidate: "NARAYAN TATOBA KALEL",
      party: "Independent",
      evm_votes: 1557,
      postal_votes: 3,
      total_votes: 1560,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 23,
      candidate: "KASHINATH KISAN DEOKATE",
      party: "Independent",
      evm_votes: 1276,
      postal_votes: 3,
      total_votes: 1279,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 24,
      candidate: "GOPAL YESHVANT JADHAV",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 1273,
      postal_votes: 16,
      total_votes: 1289,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 25,
      candidate: "SATYAWAN VIJAY OMBASE",
      party: "Swarajya Sena (Maharashtra)",
      evm_votes: 1254,
      postal_votes: 6,
      total_votes: 1260,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 26,
      candidate: "SITAPURE VINOD DILIP",
      party: "Independent",
      evm_votes: 1190,
      postal_votes: 1,
      total_votes: 1191,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 27,
      candidate: "ASHOK DNYANDEV WAGHMODE",
      party: "Independent",
      evm_votes: 984,
      postal_votes: 5,
      total_votes: 989,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 28,
      candidate: "ANIL BHAGAVAN SHEDAGE",
      party: "Independent",
      evm_votes: 977,
      postal_votes: 5,
      total_votes: 982,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 29,
      candidate: "AMOL MADHUKAR KARADE",
      party: "Independent",
      evm_votes: 900,
      postal_votes: 3,
      total_votes: 903,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 30,
      candidate: "RASHID ISMAIL SHAIKH",
      party: "Independent",
      evm_votes: 842,
      postal_votes: 1,
      total_votes: 843,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 31,
      candidate: "MORE ROHIT RAMKRUSHNA",
      party: "Independent",
      evm_votes: 669,
      postal_votes: 2,
      total_votes: 671,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 32,
      candidate: "SARADE GANESH NAVNATH",
      party: "Independent",
      evm_votes: 598,
      postal_votes: 3,
      total_votes: 601,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 33,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3655,
      postal_votes: 47,
      total_votes: 3702,
      percentage_of_votes: 0.29,
    },
  ],
  total: {
    evm_votes: 1267125,
    postal_votes: 6293,
    total_votes: 1273418,
  },
};
export const s135 = {
  cs_name: "Buldhana",
  cs_shortname: "buldhana",
  cs_code: "s135",
  winner: "SHS",
  stats: [
    {
      serial_no: 1,
      candidate: "JADHAV PRATAPRAO GANPATRAO",
      party: "Shiv Sena",
      evm_votes: 348289,
      postal_votes: 1578,
      total_votes: 349867,
      percentage_of_votes: 31.53,
    },
    {
      serial_no: 2,
      candidate: "NARENDRA DAGDU KHEDEKAR",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 319415,
      postal_votes: 973,
      total_votes: 320388,
      percentage_of_votes: 28.88,
    },
    {
      serial_no: 3,
      candidate: "RAVIKANT CHANDRADAS TUPKAR",
      party: "Independent",
      evm_votes: 249042,
      postal_votes: 921,
      total_votes: 249963,
      percentage_of_votes: 22.53,
    },
    {
      serial_no: 4,
      candidate: "VASANTRAO RAJARAM MAGAR",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 98093,
      postal_votes: 348,
      total_votes: 98441,
      percentage_of_votes: 8.87,
    },
    {
      serial_no: 5,
      candidate: "ASHOK WAMAN HIWALE",
      party: "Independent",
      evm_votes: 15414,
      postal_votes: 22,
      total_votes: 15436,
      percentage_of_votes: 1.39,
    },
    {
      serial_no: 6,
      candidate: "SANDEEP RAMRAO SHELKE",
      party: "Independent",
      evm_votes: 12993,
      postal_votes: 57,
      total_votes: 13050,
      percentage_of_votes: 1.18,
    },
    {
      serial_no: 7,
      candidate: "GAUTAM KISANRAO MAGHADE",
      party: "Bahujan Samaj Party",
      evm_votes: 8125,
      postal_votes: 93,
      total_votes: 8218,
      percentage_of_votes: 0.74,
    },
    {
      serial_no: 8,
      candidate: "ASLAM SHAH HASAN SHAH",
      party: "Maharashtra Vikas Aghadi",
      evm_votes: 6117,
      postal_votes: 36,
      total_votes: 6153,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 9,
      candidate: "NANDU JAGANNATH LAWANGE",
      party: "Independent",
      evm_votes: 5696,
      postal_votes: 17,
      total_votes: 5713,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 10,
      candidate: "MACHHINDRA SHESHRAV MAGHADE",
      party: "Socialist Party (India)",
      evm_votes: 5247,
      postal_votes: 11,
      total_votes: 5258,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 11,
      candidate: "MOHAMMAD HASAN INAMDAR",
      party: "Minorities Democratic Party",
      evm_votes: 4966,
      postal_votes: 12,
      total_votes: 4978,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 12,
      candidate: "GAJANAN JANARDAN DHANDE",
      party: "Independent",
      evm_votes: 4841,
      postal_votes: 13,
      total_votes: 4854,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 13,
      candidate: "DINKAR TUKARAM SAMBARE",
      party: "Independent",
      evm_votes: 4550,
      postal_votes: 14,
      total_votes: 4564,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 14,
      candidate: "BALASAHEB RAMCHANDRA INGLE",
      party: "Independent",
      evm_votes: 3911,
      postal_votes: 20,
      total_votes: 3931,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 15,
      candidate: "PRATAP PANDHARINATH PATIL",
      party: "Independent",
      evm_votes: 2547,
      postal_votes: 14,
      total_votes: 2561,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 16,
      candidate: "SANTOSH BHIMRAO INGLE",
      party: "Republican Party of India (A)",
      evm_votes: 2524,
      postal_votes: 6,
      total_votes: 2530,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 17,
      candidate: "MADHAVRAO SAKHARAM BANSODE",
      party: "Bahujan Republican Socialist Party",
      evm_votes: 2441,
      postal_votes: 14,
      total_votes: 2455,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 18,
      candidate: "VIKAS BHAI NANDAVE",
      party: "Bhim Sena",
      evm_votes: 2322,
      postal_votes: 9,
      total_votes: 2331,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 19,
      candidate: "UDDHAV ONKAR ATOLE",
      party: "Independent",
      evm_votes: 1946,
      postal_votes: 22,
      total_votes: 1968,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 20,
      candidate: "REKHA KAILAS POPHALKAR",
      party: "Independent",
      evm_votes: 1533,
      postal_votes: 7,
      total_votes: 1540,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 21,
      candidate: "PROF SUMANTAI TIRPUDE",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 1505,
      postal_votes: 6,
      total_votes: 1511,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 22,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3750,
      postal_votes: 36,
      total_votes: 3786,
      percentage_of_votes: 0.34,
    },
  ],
  total: {
    evm_votes: 1105267,
    postal_votes: 4229,
    total_votes: 1109496,
  },
};
export const s1319 = {
  cs_name: "Aurangabad",
  cs_shortname: "aurangabad",
  cs_code: "s1319",
  winner: "SHS",
  stats: [
    {
      serial_no: 1,
      candidate: "BHUMARE SANDIPANRAO ASARAM",
      party: "Shiv Sena",
      evm_votes: 474434,
      postal_votes: 1696,
      total_votes: 476130,
      percentage_of_votes: 36.56,
    },
    {
      serial_no: 2,
      candidate: "IMTIAZ JALEEL SYED",
      party: "All India Majlis-E-Ittehadul Muslimeen",
      evm_votes: 340877,
      postal_votes: 603,
      total_votes: 341480,
      percentage_of_votes: 26.22,
    },
    {
      serial_no: 3,
      candidate: "CHANDRAKANT KHAIRE",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 291870,
      postal_votes: 1580,
      total_votes: 293450,
      percentage_of_votes: 22.53,
    },
    {
      serial_no: 4,
      candidate: "AFSAR KHAN YASEEN KHA",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 69087,
      postal_votes: 179,
      total_votes: 69266,
      percentage_of_votes: 5.32,
    },
    {
      serial_no: 5,
      candidate: "HARSHWARDHAN DADA RAIBHANJI JADHAV",
      party: "Independent",
      evm_votes: 39654,
      postal_votes: 174,
      total_votes: 39828,
      percentage_of_votes: 3.06,
    },
    {
      serial_no: 6,
      candidate: "SURENDRA DIGAMBAR GAJBHARE",
      party: "Independent",
      evm_votes: 10719,
      postal_votes: 6,
      total_votes: 10725,
      percentage_of_votes: 0.82,
    },
    {
      serial_no: 7,
      candidate: "SANJAY UTTAMRAO JAGTAP",
      party: "Bahujan Samaj Party",
      evm_votes: 8188,
      postal_votes: 64,
      total_votes: 8252,
      percentage_of_votes: 0.63,
    },
    {
      serial_no: 8,
      candidate: "RAVINDRA BHASKARRAO BODKHE",
      party: "Bharatiya Yuva Jan Ekta Party",
      evm_votes: 6250,
      postal_votes: 4,
      total_votes: 6254,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 9,
      candidate: "ABDUL AZIM ABDUL AZIZ SHAIKH",
      party: "Independent",
      evm_votes: 4693,
      postal_votes: 0,
      total_votes: 4693,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 10,
      candidate: "SANJAY BHASKAR SHIRSAT",
      party: "Independent",
      evm_votes: 3809,
      postal_votes: 3,
      total_votes: 3812,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 11,
      candidate: "DR JIVAN BHAWLAL RAJPUT",
      party: "Independent",
      evm_votes: 3788,
      postal_votes: 19,
      total_votes: 3807,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 12,
      candidate: "MANISHA KHARAT",
      party: "Bahujan Maha Party",
      evm_votes: 3097,
      postal_votes: 8,
      total_votes: 3105,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 13,
      candidate: "JAGANNATH KHANDERAO JADHAV J K JADHAV",
      party: "Independent",
      evm_votes: 2916,
      postal_votes: 16,
      total_votes: 2932,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 14,
      candidate: "MAANOJ VINAYAKRAO GHODKAY",
      party: "Independent",
      evm_votes: 2582,
      postal_votes: 5,
      total_votes: 2587,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 15,
      candidate: "SANGITA GANESH JADHAV",
      party: "Independent",
      evm_votes: 2206,
      postal_votes: 6,
      total_votes: 2212,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 16,
      candidate: "GALPHADE ARJUN BHAGWANRAO",
      party: "Rashtriya Kisan Bahujan Party",
      evm_votes: 2185,
      postal_votes: 7,
      total_votes: 2192,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 17,
      candidate: "VISHAL UDDHAV NANDARKAR",
      party: "Independent",
      evm_votes: 2132,
      postal_votes: 7,
      total_votes: 2139,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 18,
      candidate: "ARVIND KISANRAO KAMBLE",
      party: "Bahujan Republican Socialist Party",
      evm_votes: 1975,
      postal_votes: 4,
      total_votes: 1979,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 19,
      candidate: "PRASHANT PUNDALIKRAO AVHALE",
      party: "Independent",
      evm_votes: 1945,
      postal_votes: 3,
      total_votes: 1948,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 20,
      candidate: "GHUGE NITIN PUNDLIK",
      party: "Independent",
      evm_votes: 1870,
      postal_votes: 2,
      total_votes: 1872,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 21,
      candidate: "BHALERAO VASANT SAMBHAJI",
      party: "Prabuddha Republican Party",
      evm_votes: 1546,
      postal_votes: 1,
      total_votes: 1547,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 22,
      candidate: "BHARAT PURUSHOTTAM KADAM",
      party: "Rashtriya Maratha Party",
      evm_votes: 1489,
      postal_votes: 6,
      total_votes: 1495,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 23,
      candidate: "ABDUL SAMAD BAGWAN",
      party: "All India Majlis-E-Inquilab-E-Millat",
      evm_votes: 1478,
      postal_votes: 4,
      total_votes: 1482,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 24,
      candidate: "SANDIP DADARAO MANKAR",
      party: "Independent",
      evm_votes: 1373,
      postal_votes: 2,
      total_votes: 1375,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 25,
      candidate: "ADV B U GOSAWI",
      party: "Hindustan Janta Party",
      evm_votes: 1271,
      postal_votes: 4,
      total_votes: 1275,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 26,
      candidate: "PANCHASHILA BABULAL JADHAV",
      party: "Republican Bahujan Sena",
      evm_votes: 1267,
      postal_votes: 7,
      total_votes: 1274,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 27,
      candidate: "PRATIKSHA PRASHANT CHAVAN",
      party: "Ambedkarist Republican Party",
      evm_votes: 1187,
      postal_votes: 7,
      total_votes: 1194,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 28,
      candidate: "BHANUDAS SON OF RAMDAS SARODE PATIL",
      party: "Independent",
      evm_votes: 1107,
      postal_votes: 3,
      total_votes: 1110,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 29,
      candidate: "NARAYAN JADHAV",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 1100,
      postal_votes: 3,
      total_votes: 1103,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 30,
      candidate: "SURESH ASARAM FULARE",
      party: "Independent",
      evm_votes: 910,
      postal_votes: 1,
      total_votes: 911,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 31,
      candidate: "SHAIKH KHAJA KISMATWALA KASIM KISMATWALA",
      party: "Independent",
      evm_votes: 892,
      postal_votes: 2,
      total_votes: 894,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 32,
      candidate: "MEENASING AVDHESHSING SING",
      party: "Independent",
      evm_votes: 783,
      postal_votes: 1,
      total_votes: 784,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 33,
      candidate: "LATIF JABBAR KHAN",
      party: "Independent",
      evm_votes: 765,
      postal_votes: 0,
      total_votes: 765,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 34,
      candidate: "JAGANNATH KISAN UGALE",
      party: "Independent",
      evm_votes: 753,
      postal_votes: 2,
      total_votes: 755,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 35,
      candidate: "SANDIP DEVIDAS JADHAV",
      party: "Independent",
      evm_votes: 609,
      postal_votes: 0,
      total_votes: 609,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 36,
      candidate: "DEVIDAS RATAN KASBE",
      party: "Independent",
      evm_votes: 595,
      postal_votes: 0,
      total_votes: 595,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 37,
      candidate: "TRIBHUVAN MADHUKAR PADMAKAR",
      party: "Independent",
      evm_votes: 593,
      postal_votes: 0,
      total_votes: 593,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 38,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5729,
      postal_votes: 44,
      total_votes: 5773,
      percentage_of_votes: 0.44,
    },
  ],
  total: {
    evm_votes: 1297724,
    postal_votes: 4473,
    total_votes: 1302197,
  },
};
export const s1324 = {
  cs_name: "Kalyan",
  cs_shortname: "kalyan",
  cs_code: "s1324",
  winner: "SHS",
  stats: [
    {
      serial_no: 1,
      candidate: "DR SHRIKANT EKNATH SHINDE",
      party: "Shiv Sena",
      evm_votes: 587921,
      postal_votes: 1715,
      total_votes: 589636,
      percentage_of_votes: 56.38,
    },
    {
      serial_no: 2,
      candidate: "VAISHALI DAREKAR - RANE",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 379236,
      postal_votes: 1256,
      total_votes: 380492,
      percentage_of_votes: 36.39,
    },
    {
      serial_no: 3,
      candidate: "MO. SAHABUDDIN SHAIKH SULEMANI THAKUR",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 18697,
      postal_votes: 44,
      total_votes: 18741,
      percentage_of_votes: 1.79,
    },
    {
      serial_no: 4,
      candidate: "PRASHANT RAMESH INGLE",
      party: "Bahujan Samaj Party",
      evm_votes: 11285,
      postal_votes: 56,
      total_votes: 11341,
      percentage_of_votes: 1.08,
    },
    {
      serial_no: 5,
      candidate: "AMIT UPADHYAY",
      party: "Right to Recall Party",
      evm_votes: 4185,
      postal_votes: 20,
      total_votes: 4205,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 6,
      candidate: "ARUN BHAURAO NITURE",
      party: "Rashtriya Kisan Bahujan Party",
      evm_votes: 2751,
      postal_votes: 2,
      total_votes: 2753,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 7,
      candidate: "AMRISH RAJ MORAJKAR",
      party: "Independent",
      evm_votes: 2616,
      postal_votes: 4,
      total_votes: 2620,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 8,
      candidate: "ASHWINI AMOL KENDRE",
      party: "Independent",
      evm_votes: 2461,
      postal_votes: 7,
      total_votes: 2468,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 9,
      candidate: "NAFIS AHMED ANSARI",
      party: "Independent",
      evm_votes: 1997,
      postal_votes: 3,
      total_votes: 2000,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 10,
      candidate: "SUSHILA KASHINATH KAMBLE",
      party: "Bahujan Samaj Party (Ambedkar)",
      evm_votes: 1847,
      postal_votes: 11,
      total_votes: 1858,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 11,
      candidate: "ABHIJIT WAMANRAO BICHUKALE",
      party: "Independent",
      evm_votes: 1823,
      postal_votes: 1,
      total_votes: 1824,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "AJAY SHYAM MORYA",
      party: "Independent",
      evm_votes: 1798,
      postal_votes: 4,
      total_votes: 1802,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 13,
      candidate: "SAMBHAJI JAGANNATH JADHAV",
      party: "Sanyukt Bharat Paksh",
      evm_votes: 1607,
      postal_votes: 2,
      total_votes: 1609,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 14,
      candidate: "CHANDRAKANT RAMBHAJI MOTE",
      party: "Independent",
      evm_votes: 1378,
      postal_votes: 2,
      total_votes: 1380,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 15,
      candidate: "POONAM JAGANNATH BAISANE",
      party: "Bahujan Republican Socialist Party",
      evm_votes: 1376,
      postal_votes: 5,
      total_votes: 1381,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 16,
      candidate: "HINDURAO DADU PATIL",
      party: "Rashtriya Maratha Party",
      evm_votes: 1236,
      postal_votes: 7,
      total_votes: 1243,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 17,
      candidate: "GAWALI PRAVIN SHIVAJI",
      party: "Apni Prajahit Party",
      evm_votes: 1080,
      postal_votes: 2,
      total_votes: 1082,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 18,
      candidate: "ARUN VAMAN JADHAV",
      party: "Independent",
      evm_votes: 1077,
      postal_votes: 5,
      total_votes: 1082,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 19,
      candidate: "SHRIDHAR NARAYAN SALVE",
      party: "Bhim Sena",
      evm_votes: 1068,
      postal_votes: 4,
      total_votes: 1072,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 20,
      candidate: "SHRIKANT SHIVAJI VANJARE",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 806,
      postal_votes: 2,
      total_votes: 808,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 21,
      candidate: "PRAJAKTA NILESH YELVE",
      party: "Independent",
      evm_votes: 780,
      postal_votes: 3,
      total_votes: 783,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 22,
      candidate: "DNYANESHWAR LOKHANDE MAHARAJ",
      party: "Independent",
      evm_votes: 738,
      postal_votes: 3,
      total_votes: 741,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 23,
      candidate: "ADV. HITESH JAIKISHAN JESWANI",
      party: "Independent",
      evm_votes: 712,
      postal_votes: 3,
      total_votes: 715,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 24,
      candidate: "MOHAMMAD YUSUF KHAN",
      party: "Independent",
      evm_votes: 648,
      postal_votes: 5,
      total_votes: 653,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 25,
      candidate: "RAKESH KUMAR DHISULAL JAIN",
      party: "Independent",
      evm_votes: 599,
      postal_votes: 3,
      total_votes: 602,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 26,
      candidate: "SHIVA KRISHNAMURTI IYER",
      party: "Independent",
      evm_votes: 435,
      postal_votes: 2,
      total_votes: 437,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 27,
      candidate: "SALIMUDDIN KHALILUDDIN SHAIKH",
      party: "Independent",
      evm_votes: 370,
      postal_votes: 1,
      total_votes: 371,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 28,
      candidate: "DR. SACHIN SAHEBRAO PATIL",
      party: "Independent",
      evm_votes: 346,
      postal_votes: 5,
      total_votes: 351,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 29,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11603,
      postal_votes: 83,
      total_votes: 11686,
      percentage_of_votes: 1.12,
    },
  ],
  total: {
    evm_votes: 1042476,
    postal_votes: 3260,
    total_votes: 1045736,
  },
};
export const s1325 = {
  cs_name: "Thane",
  cs_shortname: "thane",
  cs_code: "s1325",
  winner: "SHS",
  stats: [
    {
      serial_no: 1,
      candidate: "NARESH GANPAT MHASKE",
      party: "Shiv Sena",
      evm_votes: 732109,
      postal_votes: 2122,
      total_votes: 734231,
      percentage_of_votes: 56.09,
    },
    {
      serial_no: 2,
      candidate: "RAJAN BABURAO VICHARE",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 515013,
      postal_votes: 2207,
      total_votes: 517220,
      percentage_of_votes: 39.51,
    },
    {
      serial_no: 3,
      candidate: "SANTOSH BHIKAJI BHALERAO",
      party: "Bahujan Samaj Party",
      evm_votes: 10602,
      postal_votes: 60,
      total_votes: 10662,
      percentage_of_votes: 0.81,
    },
    {
      serial_no: 4,
      candidate: "ADV. GURUDEV NARSINGH SURYAWANSHI",
      party: "Independent",
      evm_votes: 3489,
      postal_votes: 5,
      total_votes: 3494,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 5,
      candidate: "MUKESH KAILASNATH TIWARI",
      party: "Bhim Sena",
      evm_votes: 2509,
      postal_votes: 13,
      total_votes: 2522,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 6,
      candidate: "JHA SUBHASH CHANDRA",
      party: "Sardar Vallabhbhai Patel Party",
      evm_votes: 2354,
      postal_votes: 4,
      total_votes: 2358,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 7,
      candidate: "DR. PIYUSH K. SAXENA",
      party: "Independent",
      evm_votes: 2292,
      postal_votes: 1,
      total_votes: 2293,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "CHANDRAKANT VITTHAL SONAWANE",
      party: "Independent",
      evm_votes: 2245,
      postal_votes: 4,
      total_votes: 2249,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 9,
      candidate: "VIJAY DNYANOBA GHATE",
      party: "Republican Bahujan Sena",
      evm_votes: 1870,
      postal_votes: 17,
      total_votes: 1887,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 10,
      candidate: "IRFAN IBRAHIM SHAIKH",
      party: "Independent",
      evm_votes: 1867,
      postal_votes: 8,
      total_votes: 1875,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "PRAMOD ANANDRAO DHUMAL",
      party: "Independent",
      evm_votes: 1785,
      postal_votes: 1,
      total_votes: 1786,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 12,
      candidate: "BHANWARLAL KHETMAL MEHTA",
      party: "Hindu Samaj Party",
      evm_votes: 1483,
      postal_votes: 5,
      total_votes: 1488,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 13,
      candidate: "UTTAM KISANRAO TIRPUDE",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 1349,
      postal_votes: 5,
      total_votes: 1354,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 14,
      candidate: "ARCHANA DINKAR GAIKWAD",
      party: "Independent",
      evm_votes: 1164,
      postal_votes: 16,
      total_votes: 1180,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 15,
      candidate: "SALIMA MUKTAR VASANI",
      party: "Bahujan Maha Party",
      evm_votes: 905,
      postal_votes: 7,
      total_votes: 912,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 16,
      candidate: "RAJIV KONDIBA BHOSALE",
      party: "Independent",
      evm_votes: 878,
      postal_votes: 8,
      total_votes: 886,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 17,
      candidate: "RAHUL JAGBIRSINGH MEHAROLIYA",
      party: "Bahujan Republican Socialist Party",
      evm_votes: 818,
      postal_votes: 0,
      total_votes: 818,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 18,
      candidate: "RAJENDRA RAMCHANDRA SANKHE",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 759,
      postal_votes: 8,
      total_votes: 767,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 19,
      candidate: "KHAJASAB RASULSAB MULLA",
      party: "Independent",
      evm_votes: 646,
      postal_votes: 2,
      total_votes: 648,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 20,
      candidate: "SANJAY MANOHAR MORE",
      party: "Independent",
      evm_votes: 639,
      postal_votes: 4,
      total_votes: 643,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 21,
      candidate: "MALLIKARJUN SAYBANNA PUJARI",
      party: "Independent",
      evm_votes: 597,
      postal_votes: 2,
      total_votes: 599,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 22,
      candidate: "SAWALE DATTATRAY SITARAM",
      party: "Independent",
      evm_votes: 529,
      postal_votes: 2,
      total_votes: 531,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 23,
      candidate: "SURENDRAKUMAR K. JAIN",
      party: "Independent",
      evm_votes: 438,
      postal_votes: 2,
      total_votes: 440,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 24,
      candidate: "SIDDHANT CHHABAN SHIRSAT",
      party: "Independent",
      evm_votes: 319,
      postal_votes: 5,
      total_votes: 324,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 25,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 17768,
      postal_votes: 133,
      total_votes: 17901,
      percentage_of_votes: 1.37,
    },
  ],
  total: {
    evm_votes: 1304427,
    postal_votes: 4641,
    total_votes: 1309068,
  },
};
export const s1327 = {
  cs_name: "Mumbai_North_West",
  cs_shortname: "mumbainorthwest",
  cs_code: "s1327",
  winner: "SHS",
  stats: [
    {
      serial_no: 1,
      candidate: "AMOL GAJANAN KIRTIKAR",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 451095,
      postal_votes: 1501,
      total_votes: 452596,
      percentage_of_votes: 47.4,
    },
    {
      serial_no: 2,
      candidate: "RAVINDRA DATTARAM WAIKAR",
      party: "Shiv Sena",
      evm_votes: 451094,
      postal_votes: 1550,
      total_votes: 452644,
      percentage_of_votes: 47.4,
    },
    {
      serial_no: 3,
      candidate: "PARMESHWAR ASHOK RANSHUR",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 10001,
      postal_votes: 51,
      total_votes: 10052,
      percentage_of_votes: 1.05,
    },
    {
      serial_no: 4,
      candidate: "RAJESH RAMKISAN MALLAH",
      party: "Bahujan Samaj Party",
      evm_votes: 4790,
      postal_votes: 22,
      total_votes: 4812,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 5,
      candidate: "SAMEER MORE",
      party: "Independent",
      evm_votes: 3171,
      postal_votes: 2,
      total_votes: 3173,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 6,
      candidate: "SUNIL BHIMA CHAVAN",
      party: "Independent",
      evm_votes: 1999,
      postal_votes: 3,
      total_votes: 2002,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 7,
      candidate: "BALA VENKATESH VINAYAK NADAR",
      party: "Aapki Apni Party (Peoples)",
      evm_votes: 1756,
      postal_votes: 7,
      total_votes: 1763,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "ARORA SURINDER MOHAN",
      party: "Bharat Jan Aadhar Party",
      evm_votes: 1667,
      postal_votes: 8,
      total_votes: 1675,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 9,
      candidate: "ROHAN SATONE",
      party: "Independent",
      evm_votes: 1476,
      postal_votes: 3,
      total_votes: 1479,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "ADV. SANJEEVKUMAR APPARAV KALKORI",
      party: "Independent",
      evm_votes: 1184,
      postal_votes: 13,
      total_votes: 1197,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "ADV. LATA PANDURANG SHINDE",
      party: "Independent",
      evm_votes: 1152,
      postal_votes: 2,
      total_votes: 1154,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "MANOJ SHRAVAN NAYAK",
      party: "Right to Recall Party",
      evm_votes: 1132,
      postal_votes: 17,
      total_votes: 1149,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "HRUDA DHANANJAY SHINDE",
      party: "Independent",
      evm_votes: 1066,
      postal_votes: 12,
      total_votes: 1078,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 14,
      candidate: "BHARAT KHIMJI SHAH",
      party: "Hindu Samaj Party",
      evm_votes: 932,
      postal_votes: 5,
      total_votes: 937,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "SUSHMA DAYANAND MEHTA",
      party: "Independent",
      evm_votes: 875,
      postal_votes: 7,
      total_votes: 882,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 16,
      candidate: "ADVOCATE KAPIL KANTILAL SONI",
      party: "Independent",
      evm_votes: 776,
      postal_votes: 4,
      total_votes: 780,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 17,
      candidate: "SARIKA DABRAL",
      party: "India Greens Party",
      evm_votes: 760,
      postal_votes: 8,
      total_votes: 768,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 18,
      candidate: "GAJANAN TUKARAM SONKAMBLE",
      party: "Independent",
      evm_votes: 496,
      postal_votes: 5,
      total_votes: 501,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 19,
      candidate: "ADV MITESH VARSHNEY",
      party: "Bhim Sena",
      evm_votes: 492,
      postal_votes: 5,
      total_votes: 497,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 20,
      candidate: "HARISHANKAR YADAV",
      party: "Samaj Vikas Kranti Party",
      evm_votes: 398,
      postal_votes: 7,
      total_votes: 405,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 21,
      candidate: "SANTOSH MANIK RAYBAN",
      party: "Independent",
      evm_votes: 234,
      postal_votes: 0,
      total_votes: 234,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 22,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 15036,
      postal_votes: 125,
      total_votes: 15161,
      percentage_of_votes: 1.59,
    },
  ],
  total: {
    evm_votes: 951582,
    postal_votes: 3357,
    total_votes: 954939,
  },
};
export const s1333 = {
  cs_name: "Maval",
  cs_shortname: "maval",
  cs_code: "s1333",
  winner: "SHS",
  stats: [
    {
      serial_no: 1,
      candidate: "SHRIRANG APPA CHANDU BARNE",
      party: "Shiv Sena",
      evm_votes: 692101,
      postal_votes: 731,
      total_votes: 692832,
      percentage_of_votes: 48.81,
    },
    {
      serial_no: 2,
      candidate: "SANJOG BHIKU WAGHERE PATIL",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 595542,
      postal_votes: 675,
      total_votes: 596217,
      percentage_of_votes: 42,
    },
    {
      serial_no: 3,
      candidate: "MADHAVITAI NARESH JOSHI",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 27745,
      postal_votes: 23,
      total_votes: 27768,
      percentage_of_votes: 1.96,
    },
    {
      serial_no: 4,
      candidate: "RAJARAM NARAYAN PATIL",
      party: "Bahujan Samaj Party",
      evm_votes: 13982,
      postal_votes: 21,
      total_votes: 14003,
      percentage_of_votes: 0.99,
    },
    {
      serial_no: 5,
      candidate: "ADV JYOTISHWAR VISHNU BHOSALE",
      party: "Baliraja Party",
      evm_votes: 10874,
      postal_votes: 5,
      total_votes: 10879,
      percentage_of_votes: 0.77,
    },
    {
      serial_no: 6,
      candidate: "UMAKANT RAMESHWAR MISHRA",
      party: "Independent",
      evm_votes: 5029,
      postal_votes: 1,
      total_votes: 5030,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 7,
      candidate: "SANJOG PATIL",
      party: "Independent",
      evm_votes: 4828,
      postal_votes: 2,
      total_votes: 4830,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 8,
      candidate: "KAMBLE MARUTI APARAI",
      party: "Independent",
      evm_votes: 4827,
      postal_votes: 2,
      total_votes: 4829,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 9,
      candidate: "AJAY HANUMANT LONDHE",
      party: "Independent",
      evm_votes: 4766,
      postal_votes: 1,
      total_votes: 4767,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 10,
      candidate: "SANTOSH UBALE",
      party: "Bhim Sena",
      evm_votes: 3824,
      postal_votes: 1,
      total_votes: 3825,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 11,
      candidate: "ADHALGE LAXMAN SADASHIV",
      party: "Independent",
      evm_votes: 3166,
      postal_votes: 1,
      total_votes: 3167,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 12,
      candidate: "SUHAS MANOHAR RANE",
      party: "Independent",
      evm_votes: 2923,
      postal_votes: 0,
      total_votes: 2923,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 13,
      candidate: "IQBAL IBRAHIM NAWDEKAR",
      party: "Independent",
      evm_votes: 2899,
      postal_votes: 1,
      total_votes: 2900,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 14,
      candidate: "DADARAO KISAN KAMBLE",
      party: "Independent",
      evm_votes: 2636,
      postal_votes: 6,
      total_votes: 2642,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 15,
      candidate: "RAHUL NIVRUTTI MADANE",
      party: "Independent",
      evm_votes: 2279,
      postal_votes: 3,
      total_votes: 2282,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 16,
      candidate: "TUSHAR DIGAMBAR LONDHE",
      party: "Bahujan Bharat Party",
      evm_votes: 2199,
      postal_votes: 4,
      total_votes: 2203,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 17,
      candidate: "RAHIM MAINUDDIN SAYYAD",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 2148,
      postal_votes: 1,
      total_votes: 2149,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 18,
      candidate: "INDRAJEET D GOND",
      party: "Independent",
      evm_votes: 2135,
      postal_votes: 0,
      total_votes: 2135,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 19,
      candidate: "SHIVAJI KISAN JADHAV",
      party: "All India Forward Bloc",
      evm_votes: 1672,
      postal_votes: 4,
      total_votes: 1676,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 20,
      candidate: "YASHWANT VITTHAL PAWAR",
      party: "Kranti Kari Jai Hind Sena",
      evm_votes: 1634,
      postal_votes: 4,
      total_votes: 1638,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 21,
      candidate: "PRAFUL PANDIT BHOSALE",
      party: "Independent",
      evm_votes: 1602,
      postal_votes: 1,
      total_votes: 1603,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 22,
      candidate: "RAJENDRA MARUTI KATE",
      party: "Independent",
      evm_votes: 1417,
      postal_votes: 2,
      total_votes: 1419,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 23,
      candidate: "MAHESH THAKUR",
      party: "Dharmarajya Paksha",
      evm_votes: 1369,
      postal_votes: 3,
      total_votes: 1372,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 24,
      candidate: "PRASHANT RAMKRISHNA BHAGAT",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 1283,
      postal_votes: 8,
      total_votes: 1291,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 25,
      candidate: "PANKAJ PRABHAKAR OZARKAR",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 1259,
      postal_votes: 2,
      total_votes: 1261,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 26,
      candidate: "MANOJ BHASKAR GARBADE",
      party: "Independent",
      evm_votes: 1233,
      postal_votes: 0,
      total_votes: 1233,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 27,
      candidate: "HAJRAT IMAMSAB PATEL",
      party: "Independent",
      evm_votes: 1093,
      postal_votes: 0,
      total_votes: 1093,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 28,
      candidate: "GOVIND GANGARAM HERODE",
      party: "Independent",
      evm_votes: 966,
      postal_votes: 0,
      total_votes: 966,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 29,
      candidate: "MADHUKAR DAMODAR THORAT",
      party: "Independent",
      evm_votes: 905,
      postal_votes: 0,
      total_votes: 905,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 30,
      candidate: "RAFIQUE RASHID QURESHI",
      party: "Desh Janhit Party",
      evm_votes: 829,
      postal_votes: 0,
      total_votes: 829,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 31,
      candidate: "CHIMAJI DHONDIBA SHINDE",
      party: "Independent",
      evm_votes: 757,
      postal_votes: 0,
      total_votes: 757,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 32,
      candidate: "ADV RAJU PATIL",
      party: "Independent",
      evm_votes: 669,
      postal_votes: 1,
      total_votes: 670,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 33,
      candidate: "MUKESH MANOHAR AGARWAL",
      party: "Independent",
      evm_votes: 546,
      postal_votes: 1,
      total_votes: 547,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 34,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 16729,
      postal_votes: 31,
      total_votes: 16760,
      percentage_of_votes: 1.18,
    },
  ],
  total: {
    evm_votes: 1417866,
    postal_votes: 1535,
    total_votes: 1419401,
  },
};
export const s1348 = {
  cs_name: "Hatkanangale",
  cs_shortname: "hatkanangale",
  cs_code: "s1348",
  winner: "SHS",
  stats: [
    {
      serial_no: 1,
      candidate: "DHAIRYASHEEL SAMBHAJIRAO MANE",
      party: "Shiv Sena",
      evm_votes: 518334,
      postal_votes: 1856,
      total_votes: 520190,
      percentage_of_votes: 40.14,
    },
    {
      serial_no: 2,
      candidate: "SATYAJEET BABASAHEB PATIL (AABA) SARUDKAR",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 504181,
      postal_votes: 2583,
      total_votes: 506764,
      percentage_of_votes: 39.1,
    },
    {
      serial_no: 3,
      candidate: "RAJU SHETTI",
      party: "Swabhimani Paksha",
      evm_votes: 178866,
      postal_votes: 984,
      total_votes: 179850,
      percentage_of_votes: 13.88,
    },
    {
      serial_no: 4,
      candidate: "D. C. PATIL DADASAHEB / DADGONDA CHAVGONDA PATIL",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 32591,
      postal_votes: 105,
      total_votes: 32696,
      percentage_of_votes: 2.52,
    },
    {
      serial_no: 5,
      candidate: "ASLAM AINODIN MULLA",
      party: "Independent",
      evm_votes: 6107,
      postal_votes: 4,
      total_votes: 6111,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 6,
      candidate: "ANANDRAO VASANTRAO SARNAIK (FOUJI BAPU)",
      party: "Independent",
      evm_votes: 4944,
      postal_votes: 11,
      total_votes: 4955,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 7,
      candidate: "TANDALE LAXMAN SHIVAJI",
      party: "Independent",
      evm_votes: 4788,
      postal_votes: 1,
      total_votes: 4789,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 8,
      candidate: "PROF. PARASHURAM MANE",
      party: "Independent",
      evm_votes: 4467,
      postal_votes: 10,
      total_votes: 4477,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 9,
      candidate: "KAMBLE RAVINDRA TUKARAM",
      party: "Bahujan Samaj Party",
      evm_votes: 3968,
      postal_votes: 65,
      total_votes: 4033,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 10,
      candidate: "SATYAJEET PATIL (AABA)",
      party: "Independent",
      evm_votes: 3602,
      postal_votes: 30,
      total_votes: 3632,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 11,
      candidate: "ANANDRAO TUKARAM THORAT",
      party: "Independent",
      evm_votes: 3497,
      postal_votes: 2,
      total_votes: 3499,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 12,
      candidate: "IMRAN IQBAL KHATIB",
      party: "Bahujan Mukti Party",
      evm_votes: 3172,
      postal_votes: 18,
      total_votes: 3190,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 13,
      candidate: "RAGHUNATHDADA PATIL",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 2146,
      postal_votes: 28,
      total_votes: 2174,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 14,
      candidate: "JAVED SIKANDAR MUJAVAR",
      party: "Independent",
      evm_votes: 1849,
      postal_votes: 3,
      total_votes: 1852,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 15,
      candidate: "SANTOSH KERBA KHOT",
      party: "Kamgaar Kisan Party",
      evm_votes: 1697,
      postal_votes: 4,
      total_votes: 1701,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 16,
      candidate: "DR. ISHWAR MAHADEV YAMGAR",
      party: "Bharatiya Lokshakti Party",
      evm_votes: 1520,
      postal_votes: 17,
      total_votes: 1537,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 17,
      candidate: "SHIVAJI DHONDIRAM SANKPAL",
      party: "Independent",
      evm_votes: 1390,
      postal_votes: 2,
      total_votes: 1392,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 18,
      candidate: "SHARAD BABURAO PATIL",
      party: "National Black Panther Party",
      evm_votes: 1356,
      postal_votes: 5,
      total_votes: 1361,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 19,
      candidate: "MANOHAR PRADIP SATPUTE",
      party: "Independent",
      evm_votes: 1063,
      postal_votes: 3,
      total_votes: 1066,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 20,
      candidate: "DHANAJI JAGANNATH GURAV (SHIVAREKAR)",
      party: "Lokrajya Janata Party",
      evm_votes: 1054,
      postal_votes: 7,
      total_votes: 1061,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 21,
      candidate: "DAVARI LAXMAN SHRIPATI",
      party: "Independent",
      evm_votes: 1004,
      postal_votes: 5,
      total_votes: 1009,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 22,
      candidate: "RAJENDRA BHIMRAO MANE",
      party: "Independent",
      evm_votes: 935,
      postal_votes: 5,
      total_votes: 940,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 23,
      candidate: "DINAKARRAO TULSHIDAS CHAVAN (PATIL)",
      party: "All India Forward Bloc",
      evm_votes: 674,
      postal_votes: 5,
      total_votes: 679,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 24,
      candidate: "RAMCHANDRA GOVINDRAO SALUNKHE",
      party: "Independent",
      evm_votes: 618,
      postal_votes: 0,
      total_votes: 618,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 25,
      candidate: "MOHITE DEVENDRA NANA",
      party: "Independent",
      evm_votes: 559,
      postal_votes: 1,
      total_votes: 560,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 26,
      candidate: "MANE ARVIND BHIVA",
      party: "Independent",
      evm_votes: 422,
      postal_votes: 2,
      total_votes: 424,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 27,
      candidate: "MAHAMMAD MUBARAK DARAVESHI",
      party: "Independent",
      evm_votes: 360,
      postal_votes: 1,
      total_votes: 361,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 28,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5027,
      postal_votes: 76,
      total_votes: 5103,
      percentage_of_votes: 0.39,
    },
  ],
  total: {
    evm_votes: 1290191,
    postal_votes: 5833,
    total_votes: 1296024,
  },
};
export const s0416 = {
  cs_name: "Vaishali",
  cs_shortname: "vaishali",
  cs_code: "s0416",
  winner: "LJPRV",
  stats: [
    {
      serial_no: 1,
      candidate: "VEENA DEVI",
      party: "Lok Janshakti Party(Ram Vilas)",
      evm_votes: 566225,
      postal_votes: 818,
      total_votes: 567043,
      percentage_of_votes: 48.38,
    },
    {
      serial_no: 2,
      candidate: "VIJAY KUMAR SHUKLA",
      party: "Rashtriya Janata Dal",
      evm_votes: 476199,
      postal_votes: 1210,
      total_votes: 477409,
      percentage_of_votes: 40.73,
    },
    {
      serial_no: 3,
      candidate: "VIKKY KUMAR",
      party: "Independent",
      evm_votes: 34253,
      postal_votes: 3,
      total_votes: 34256,
      percentage_of_votes: 2.92,
    },
    {
      serial_no: 4,
      candidate: "SHAMBHU KUMAR SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 21367,
      postal_votes: 69,
      total_votes: 21436,
      percentage_of_votes: 1.83,
    },
    {
      serial_no: 5,
      candidate: "RAJ NARAYAN YADAV",
      party: "Independent",
      evm_votes: 9614,
      postal_votes: 2,
      total_votes: 9616,
      percentage_of_votes: 0.82,
    },
    {
      serial_no: 6,
      candidate: "PARAMHANS SINGH",
      party: "Independent",
      evm_votes: 5316,
      postal_votes: 1,
      total_votes: 5317,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 7,
      candidate: "HARI NARAYAN RAM",
      party: "Viro Ke Vir Indian Party",
      evm_votes: 5097,
      postal_votes: 3,
      total_votes: 5100,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 8,
      candidate: "RAMESHWAR MAHTO",
      party: "Bhartiya Sarthak Party",
      evm_votes: 4141,
      postal_votes: 9,
      total_votes: 4150,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 9,
      candidate: "KUMAR SHISHIR",
      party: "Independent",
      evm_votes: 3903,
      postal_votes: 4,
      total_votes: 3907,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 10,
      candidate: "MUHAMAD AVAS",
      party: "Independent",
      evm_votes: 3758,
      postal_votes: 0,
      total_votes: 3758,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 11,
      candidate: "ABHA RAY",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 3263,
      postal_votes: 11,
      total_votes: 3274,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 12,
      candidate: "NARESH RAM",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2998,
      postal_votes: 11,
      total_votes: 3009,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 13,
      candidate: "VINOD KUMAR SHARMA",
      party: "Jantantra Awaj Party",
      evm_votes: 2929,
      postal_votes: 5,
      total_votes: 2934,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 14,
      candidate: "ANIKET RAJ",
      party: "Rashtriya Samaj Paksha",
      evm_votes: 2018,
      postal_votes: 7,
      total_votes: 2025,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 15,
      candidate: "BALENDRA TIWARY",
      party: "Hindustan Janta Party Secular",
      evm_votes: 1360,
      postal_votes: 6,
      total_votes: 1366,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 27420,
      postal_votes: 40,
      total_votes: 27460,
      percentage_of_votes: 2.34,
    },
  ],
  total: {
    evm_votes: 1169861,
    postal_votes: 2199,
    total_votes: 1172060,
  },
};
export const s0421 = {
  cs_name: "Hajipur",
  cs_shortname: "hajipur",
  cs_code: "s0421",
  winner: "LJPRV",
  stats: [
    {
      serial_no: 1,
      candidate: "CHIRAG PASWAN",
      party: "Lok Janshakti Party(Ram Vilas)",
      evm_votes: 613106,
      postal_votes: 2612,
      total_votes: 615718,
      percentage_of_votes: 53.29,
    },
    {
      serial_no: 2,
      candidate: "SHIV CHANDRA RAM",
      party: "Rashtriya Janata Dal",
      evm_votes: 442553,
      postal_votes: 3060,
      total_votes: 445613,
      percentage_of_votes: 38.57,
    },
    {
      serial_no: 3,
      candidate: "SURENDRA KUMAR PASWAN",
      party: "Independent",
      evm_votes: 17241,
      postal_votes: 4,
      total_votes: 17245,
      percentage_of_votes: 1.49,
    },
    {
      serial_no: 4,
      candidate: "SHASHI SWARAJ",
      party: "Bahujan Samaj Party",
      evm_votes: 8591,
      postal_votes: 88,
      total_votes: 8679,
      percentage_of_votes: 0.75,
    },
    {
      serial_no: 5,
      candidate: "HARIWANSH PASWAN",
      party: "Independent",
      evm_votes: 7113,
      postal_votes: 4,
      total_votes: 7117,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 6,
      candidate: "SHAILENDRA KUMAR",
      party: "Independent",
      evm_votes: 6153,
      postal_votes: 8,
      total_votes: 6161,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 7,
      candidate: "ASHOK KUMAR",
      party: "Samata Party",
      evm_votes: 4701,
      postal_votes: 20,
      total_votes: 4721,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 8,
      candidate: "RANDHIR PASWAN",
      party: "Independent",
      evm_votes: 2671,
      postal_votes: 4,
      total_votes: 2675,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 9,
      candidate: "RAJ KUMAR PASWAN",
      party: "Yuva Bihar Sena",
      evm_votes: 1983,
      postal_votes: 7,
      total_votes: 1990,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 10,
      candidate: "DHARMENDRA KUMAR",
      party: "Jantantra Awaj Party",
      evm_votes: 1971,
      postal_votes: 7,
      total_votes: 1978,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 11,
      candidate: "MADHUSUDHAN PASWAN",
      party: "Independent",
      evm_votes: 1793,
      postal_votes: 8,
      total_votes: 1801,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 12,
      candidate: "RAJESH KUMAR RAUSHAN",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1694,
      postal_votes: 7,
      total_votes: 1701,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 13,
      candidate: "MEGHNATH PASWAN",
      party: "Independent",
      evm_votes: 1680,
      postal_votes: 0,
      total_votes: 1680,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 14,
      candidate: "BALENDRA DAS",
      party: "Janshakti Janta Dal",
      evm_votes: 1308,
      postal_votes: 10,
      total_votes: 1318,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 36836,
      postal_votes: 91,
      total_votes: 36927,
      percentage_of_votes: 3.2,
    },
  ],
  total: {
    evm_votes: 1149394,
    postal_votes: 5930,
    total_votes: 1155324,
  },
};
export const s0423 = {
  cs_name: "Samastipur",
  cs_shortname: "samastipur",
  cs_code: "s0423",
  winner: "LJPRV",
  stats: [
    {
      serial_no: 1,
      candidate: "SHAMBHAVI",
      party: "Lok Janshakti Party(Ram Vilas)",
      evm_votes: 579095,
      postal_votes: 691,
      total_votes: 579786,
      percentage_of_votes: 52.97,
    },
    {
      serial_no: 2,
      candidate: "SUNNY HAZARI",
      party: "Indian National Congress",
      evm_votes: 391558,
      postal_votes: 977,
      total_votes: 392535,
      percentage_of_votes: 35.86,
    },
    {
      serial_no: 3,
      candidate: "SHASHI BHUSHAN DAS",
      party: "Independent",
      evm_votes: 25953,
      postal_votes: 4,
      total_votes: 25957,
      percentage_of_votes: 2.37,
    },
    {
      serial_no: 4,
      candidate: "RAVI RAUSHAN KUMAR",
      party: "Independent",
      evm_votes: 16151,
      postal_votes: 4,
      total_votes: 16155,
      percentage_of_votes: 1.48,
    },
    {
      serial_no: 5,
      candidate: "RAM LAKHAN MAHTO",
      party: "Bahujan Samaj Party",
      evm_votes: 12698,
      postal_votes: 34,
      total_votes: 12732,
      percentage_of_votes: 1.16,
    },
    {
      serial_no: 6,
      candidate: "PINKU PASWAN",
      party: "Rashtriya Jansambhavna Party",
      evm_votes: 12426,
      postal_votes: 11,
      total_votes: 12437,
      percentage_of_votes: 1.14,
    },
    {
      serial_no: 7,
      candidate: "MUKESH CHAUPAL",
      party: "Independent",
      evm_votes: 5574,
      postal_votes: 5,
      total_votes: 5579,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 8,
      candidate: "JIBACHH KUMAR HAJARI",
      party: "Independent",
      evm_votes: 4686,
      postal_votes: 8,
      total_votes: 4694,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 9,
      candidate: "RATAN BIHARI",
      party: "Desh Janhit Party",
      evm_votes: 3411,
      postal_votes: 8,
      total_votes: 3419,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 10,
      candidate: "VIDYA NAND RAM",
      party: "Wazib Adhikar Party",
      evm_votes: 3110,
      postal_votes: 2,
      total_votes: 3112,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 11,
      candidate: "AMRITA KUMARI",
      party: "Independent",
      evm_votes: 2849,
      postal_votes: 3,
      total_votes: 2852,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 12,
      candidate: "LAL BABU MAHTO",
      party: "Sathi Aur Aapka Faisala Party",
      evm_votes: 2665,
      postal_votes: 1,
      total_votes: 2666,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 32645,
      postal_votes: 23,
      total_votes: 32668,
      percentage_of_votes: 2.98,
    },
  ],
  total: {
    evm_votes: 1092821,
    postal_votes: 1771,
    total_votes: 1094592,
  },
};
export const s0425 = {
  cs_name: "Khagaria",
  cs_shortname: "khagaria",
  cs_code: "s0425",
  winner: "LJPRV",
  stats: [
    {
      serial_no: 1,
      candidate: "RAJESH VERMA",
      party: "Lok Janshakti Party(Ram Vilas)",
      evm_votes: 537331,
      postal_votes: 1326,
      total_votes: 538657,
      percentage_of_votes: 50.73,
    },
    {
      serial_no: 2,
      candidate: "SANJAY KUMAR",
      party: "Communist Party of India (Marxist)",
      evm_votes: 375988,
      postal_votes: 1538,
      total_votes: 377526,
      percentage_of_votes: 35.55,
    },
    {
      serial_no: 3,
      candidate: "RUPAM DEVI",
      party: "Independent",
      evm_votes: 27370,
      postal_votes: 25,
      total_votes: 27395,
      percentage_of_votes: 2.58,
    },
    {
      serial_no: 4,
      candidate: "SONU KUMAR",
      party: "Independent",
      evm_votes: 24478,
      postal_votes: 5,
      total_votes: 24483,
      percentage_of_votes: 2.31,
    },
    {
      serial_no: 5,
      candidate: "PINKESH KUMAR",
      party: "Rashtriya Jan Kalyan Party Secular",
      evm_votes: 19487,
      postal_votes: 8,
      total_votes: 19495,
      percentage_of_votes: 1.84,
    },
    {
      serial_no: 6,
      candidate: "DR RAVI KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 13894,
      postal_votes: 159,
      total_votes: 14053,
      percentage_of_votes: 1.32,
    },
    {
      serial_no: 7,
      candidate: "AJAY KUMAR",
      party: "Rashtriya Jansambhavna Party",
      evm_votes: 8256,
      postal_votes: 54,
      total_votes: 8310,
      percentage_of_votes: 0.78,
    },
    {
      serial_no: 8,
      candidate: "PRIYADARSHEE DINKAR",
      party: "Independent",
      evm_votes: 7892,
      postal_votes: 12,
      total_votes: 7904,
      percentage_of_votes: 0.74,
    },
    {
      serial_no: 9,
      candidate: "DINANATH CHANDRAVANSHI",
      party: "Independent",
      evm_votes: 6142,
      postal_votes: 20,
      total_votes: 6162,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 10,
      candidate: "CHANDRA KISHOR THAKUR",
      party: "Aam Janta Party Rashtriya",
      evm_votes: 2806,
      postal_votes: 43,
      total_votes: 2849,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 11,
      candidate: "ASIF IMAM",
      party: "Aadarsh Mithila Party",
      evm_votes: 2434,
      postal_votes: 9,
      total_votes: 2443,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 12,
      candidate: "KANCHAN MALA",
      party: "Hindustan People's Party (Democratic)",
      evm_votes: 1478,
      postal_votes: 28,
      total_votes: 1506,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 31056,
      postal_votes: 55,
      total_votes: 31111,
      percentage_of_votes: 2.93,
    },
  ],
  total: {
    evm_votes: 1058612,
    postal_votes: 3282,
    total_votes: 1061894,
  },
};
export const s0440 = {
  cs_name: "Jamui",
  cs_shortname: "jamui",
  cs_code: "s0440",
  winner: "LJPRV",
  stats: [
    {
      serial_no: 1,
      candidate: "ARUN BHARTI",
      party: "Lok Janshakti Party(Ram Vilas)",
      evm_votes: 508179,
      postal_votes: 867,
      total_votes: 509046,
      percentage_of_votes: 51.98,
    },
    {
      serial_no: 2,
      candidate: "ARCHANA KUMARI",
      party: "Rashtriya Janata Dal",
      evm_votes: 395696,
      postal_votes: 868,
      total_votes: 396564,
      percentage_of_votes: 40.5,
    },
    {
      serial_no: 3,
      candidate: "SUBHASH PASWAN",
      party: "Independent",
      evm_votes: 13841,
      postal_votes: 34,
      total_votes: 13875,
      percentage_of_votes: 1.42,
    },
    {
      serial_no: 4,
      candidate: "SAKALDEO DAS",
      party: "Bahujan Samaj Party",
      evm_votes: 12313,
      postal_votes: 53,
      total_votes: 12366,
      percentage_of_votes: 1.26,
    },
    {
      serial_no: 5,
      candidate: "SRAWON KUMAR",
      party: "Rashtriya Jansambhavna Party",
      evm_votes: 8604,
      postal_votes: 21,
      total_votes: 8625,
      percentage_of_votes: 0.88,
    },
    {
      serial_no: 6,
      candidate: "JAGDISH PRASAD",
      party: "Loktantrik Samajik Nyay Party",
      evm_votes: 6532,
      postal_votes: 10,
      total_votes: 6542,
      percentage_of_votes: 0.67,
    },
    {
      serial_no: 7,
      candidate: "SANTOSH KUMAR DAS",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 6025,
      postal_votes: 8,
      total_votes: 6033,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 26148,
      postal_votes: 34,
      total_votes: 26182,
      percentage_of_votes: 2.67,
    },
  ],
  total: {
    evm_votes: 977338,
    postal_votes: 1895,
    total_votes: 979233,
  },
};
export const s011 = {
  cs_name: "Araku",
  cs_shortname: "araku",
  cs_code: "s011",
  winner: "YSRCP",
  stats: [
    {
      serial_no: 1,
      candidate: "GUMMA THANUJA RANI",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 471470,
      postal_votes: 5535,
      total_votes: 477005,
      percentage_of_votes: 40.96,
    },
    {
      serial_no: 2,
      candidate: "KOTHAPALLI GEETHA",
      party: "Bharatiya Janata Party",
      evm_votes: 417113,
      postal_votes: 9312,
      total_votes: 426425,
      percentage_of_votes: 36.62,
    },
    {
      serial_no: 3,
      candidate: "APPALANARASA PACHIPENTA",
      party: "Communist Party of India (Marxist)",
      evm_votes: 119016,
      postal_votes: 4113,
      total_votes: 123129,
      percentage_of_votes: 10.57,
    },
    {
      serial_no: 4,
      candidate: "AVASHYA LAHARI . VARAM",
      party: "Bahujan Samaj Party",
      evm_votes: 24858,
      postal_votes: 892,
      total_votes: 25750,
      percentage_of_votes: 2.21,
    },
    {
      serial_no: 5,
      candidate: "SAMAREDDY BALAKRISHNA",
      party: "Independent",
      evm_votes: 9493,
      postal_votes: 42,
      total_votes: 9535,
      percentage_of_votes: 0.82,
    },
    {
      serial_no: 6,
      candidate: "NIMMAKA JAYARAJU",
      party: "Independent",
      evm_votes: 8798,
      postal_votes: 664,
      total_votes: 9462,
      percentage_of_votes: 0.81,
    },
    {
      serial_no: 7,
      candidate: "ATHIDHI",
      party: "Independent",
      evm_votes: 8121,
      postal_votes: 15,
      total_votes: 8136,
      percentage_of_votes: 0.7,
    },
    {
      serial_no: 8,
      candidate: "CHANTI BADNAINA",
      party: "Jai Bharat National Party",
      evm_votes: 7205,
      postal_votes: 47,
      total_votes: 7252,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 9,
      candidate: "MOTTADAM . RAJABABU",
      party: "Bharat Adivasi Party",
      evm_votes: 6370,
      postal_votes: 396,
      total_votes: 6766,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 10,
      candidate: "RANJITH KUMAR PALAKA",
      party: "Independent",
      evm_votes: 6000,
      postal_votes: 47,
      total_votes: 6047,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 11,
      candidate: "RAMBABU JALLI",
      party: "Telugu Rajadhikara Samiti Party",
      evm_votes: 4933,
      postal_votes: 26,
      total_votes: 4959,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 12,
      candidate: "MANDALA GIRIDHARA RAO",
      party: "Independent",
      evm_votes: 4931,
      postal_votes: 36,
      total_votes: 4967,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 13,
      candidate: "VUYAKA NIREEKSHAN (V. CHENCHU)",
      party: "India Praja Bandhu Party",
      evm_votes: 4500,
      postal_votes: 42,
      total_votes: 4542,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 50205,
      postal_votes: 265,
      total_votes: 50470,
      percentage_of_votes: 4.33,
    },
  ],
  total: {
    evm_votes: 1143013,
    postal_votes: 21432,
    total_votes: 1164445,
  },
};
export const s0121 = {
  cs_name: "Kadapa",
  cs_shortname: "kadapa",
  cs_code: "s0121",
  winner: "YSRCP",
  stats: [
    {
      serial_no: 1,
      candidate: "Y. S. AVINASH REDDY",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 598406,
      postal_votes: 6737,
      total_votes: 605143,
      percentage_of_votes: 45.78,
    },
    {
      serial_no: 2,
      candidate: "CHADIPIRALLA BHUPESH SUBBARAMI REDDY",
      party: "Telugu Desam",
      evm_votes: 532358,
      postal_votes: 10090,
      total_votes: 542448,
      percentage_of_votes: 41.03,
    },
    {
      serial_no: 3,
      candidate: "Y.S. SHARMILA REDDY",
      party: "Indian National Congress",
      evm_votes: 136395,
      postal_votes: 4644,
      total_votes: 141039,
      percentage_of_votes: 10.67,
    },
    {
      serial_no: 4,
      candidate: "PANDITI. GURAPPA.",
      party: "Bahujan Samaj Party",
      evm_votes: 3790,
      postal_votes: 20,
      total_votes: 3810,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 5,
      candidate: "VENU GOPAL RACHINENI",
      party: "Rashtriya Praja Congress (Secular)",
      evm_votes: 3500,
      postal_votes: 70,
      total_votes: 3570,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 6,
      candidate: "CHINNAPUREDDY GOPALA KRISHNA REDDY",
      party: "Independent",
      evm_votes: 2414,
      postal_votes: 16,
      total_votes: 2430,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 7,
      candidate: "KUNCHAM. VENKATA SUBBA REDDY",
      party: "Rayalaseema Rashtra Samithi",
      evm_votes: 2329,
      postal_votes: 4,
      total_votes: 2333,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "L. KHAJA HUSSAIN",
      party: "Independent",
      evm_votes: 1087,
      postal_votes: 1,
      total_votes: 1088,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 9,
      candidate: "ANKIREDDY. SURESH KUMAR REDDY.",
      party: "All India Forward Bloc",
      evm_votes: 752,
      postal_votes: 3,
      total_votes: 755,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 10,
      candidate: "KAKARLA SHANMUKHA REDDY",
      party: "Independent",
      evm_votes: 633,
      postal_votes: 14,
      total_votes: 647,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 11,
      candidate: "PALLE RAMESH",
      party: "Jaibhim Rao Bharat Party",
      evm_votes: 619,
      postal_votes: 5,
      total_votes: 624,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 12,
      candidate: "CHAN BASHA. S",
      party: "Anna YSR Congress Party",
      evm_votes: 432,
      postal_votes: 7,
      total_votes: 439,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 13,
      candidate: "J. V. RAMANA",
      party: "Jaathia Chethi Vruthula Ikya Vedika Party",
      evm_votes: 414,
      postal_votes: 20,
      total_votes: 434,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 14,
      candidate: "MALIKIREDDY. HANUMANTHA REDDY",
      party: "Jana Sahayaka Sakthi",
      evm_votes: 358,
      postal_votes: 11,
      total_votes: 369,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 16660,
      postal_votes: 186,
      total_votes: 16846,
      percentage_of_votes: 1.27,
    },
  ],
  total: {
    evm_votes: 1300147,
    postal_votes: 21828,
    total_votes: 1321975,
  },
};
export const s0123 = {
  cs_name: "Thirupathi",
  cs_shortname: "thirupathi",
  cs_code: "s0123",
  winner: "YSRCP",
  stats: [
    {
      serial_no: 1,
      candidate: "GURUMOORTHY MADDILA",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 626702,
      postal_votes: 5526,
      total_votes: 632228,
      percentage_of_votes: 45.73,
    },
    {
      serial_no: 2,
      candidate: "VARA PRASAD RAO VELAGAPALLI",
      party: "Bharatiya Janata Party",
      evm_votes: 608494,
      postal_votes: 9165,
      total_votes: 617659,
      percentage_of_votes: 44.67,
    },
    {
      serial_no: 3,
      candidate: "CHINTA MOHAN",
      party: "Indian National Congress",
      evm_votes: 63790,
      postal_votes: 1733,
      total_votes: 65523,
      percentage_of_votes: 4.74,
    },
    {
      serial_no: 4,
      candidate: "PENUMURU. GURAPPA",
      party: "Bahujan Samaj Party",
      evm_votes: 10300,
      postal_votes: 206,
      total_votes: 10506,
      percentage_of_votes: 0.76,
    },
    {
      serial_no: 5,
      candidate: "KATTAMANCHI. PRABHAKAR",
      party: "Independent",
      evm_votes: 5796,
      postal_votes: 6,
      total_votes: 5802,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 6,
      candidate: "M UMADEVI",
      party: "Janata Congress",
      evm_votes: 5387,
      postal_votes: 38,
      total_votes: 5425,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 7,
      candidate: "ALLAM.RAJA",
      party: "Independent",
      evm_votes: 4948,
      postal_votes: 22,
      total_votes: 4970,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 8,
      candidate: "VIJAY KUMAR G.SRKR",
      party: "Liberation Congress Party",
      evm_votes: 4108,
      postal_votes: 194,
      total_votes: 4302,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 9,
      candidate: "ANJAIAH.P",
      party: "Republican Party of India (A)",
      evm_votes: 3657,
      postal_votes: 9,
      total_votes: 3666,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 10,
      candidate: "V.C. NAVEEN GUPTA",
      party: "Independent",
      evm_votes: 1723,
      postal_votes: 8,
      total_votes: 1731,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "A. VARAPRASAD",
      party: "Jatiya Jana Sena Party",
      evm_votes: 1668,
      postal_votes: 30,
      total_votes: 1698,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "SYAMDHAN KURAPATI",
      party: "Aihra National Party",
      evm_votes: 1567,
      postal_votes: 1,
      total_votes: 1568,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 13,
      candidate: "C. PUNYAMURTHY",
      party: "United Republican Party of India",
      evm_votes: 1511,
      postal_votes: 4,
      total_votes: 1515,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 14,
      candidate: "KARRA SIVA",
      party: "Pyramid Party of India",
      evm_votes: 1501,
      postal_votes: 9,
      total_votes: 1510,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 15,
      candidate: "VELURU. THEJOVATHI",
      party: "Samajwadi Party",
      evm_votes: 1362,
      postal_votes: 27,
      total_votes: 1389,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 16,
      candidate: "C. DAVID",
      party: "Indian Believers Party (IBP)",
      evm_votes: 1237,
      postal_votes: 2,
      total_votes: 1239,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 17,
      candidate: "K. JEEVARATHNAM",
      party: "Independent",
      evm_votes: 1203,
      postal_votes: 1,
      total_votes: 1204,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 18,
      candidate: "DASARI. GOWTHAM",
      party: "Independent",
      evm_votes: 1077,
      postal_votes: 10,
      total_votes: 1087,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 19,
      candidate: "VIJAYA KUMAR.G",
      party: "Independent",
      evm_votes: 797,
      postal_votes: 4,
      total_votes: 801,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 20,
      candidate: "A. MADHU",
      party: "Jai Hindustan Party",
      evm_votes: 711,
      postal_votes: 0,
      total_votes: 711,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 21,
      candidate: "B. BHARANI BAS",
      party: "Ambedkar National Congress",
      evm_votes: 688,
      postal_votes: 0,
      total_votes: 688,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 22,
      candidate: "Y. MAHESH",
      party: "Independent",
      evm_votes: 615,
      postal_votes: 7,
      total_votes: 622,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 23,
      candidate: "PRASAD PATIBANDLA",
      party: "Independent",
      evm_votes: 607,
      postal_votes: 1,
      total_votes: 608,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 24,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 15909,
      postal_votes: 209,
      total_votes: 16118,
      percentage_of_votes: 1.17,
    },
  ],
  total: {
    evm_votes: 1365358,
    postal_votes: 17212,
    total_votes: 1382570,
  },
};
export const s0124 = {
  cs_name: "Rajampet",
  cs_shortname: "rajampet",
  cs_code: "s0124",
  winner: "YSRCP",
  stats: [
    {
      serial_no: 1,
      candidate: "P V MIDHUN REDDY",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 639111,
      postal_votes: 5733,
      total_votes: 644844,
      percentage_of_votes: 48.38,
    },
    {
      serial_no: 2,
      candidate: "NALLARI KIRAN KUMAR REDDY",
      party: "Bharatiya Janata Party",
      evm_votes: 557030,
      postal_votes: 11743,
      total_votes: 568773,
      percentage_of_votes: 42.67,
    },
    {
      serial_no: 3,
      candidate: "SHAIK BASHEED",
      party: "Indian National Congress",
      evm_votes: 52229,
      postal_votes: 1071,
      total_votes: 53300,
      percentage_of_votes: 4,
    },
    {
      serial_no: 4,
      candidate: "ABBAVARAM UGENDRA",
      party: "Bahujan Samaj Party",
      evm_votes: 12306,
      postal_votes: 57,
      total_votes: 12363,
      percentage_of_votes: 0.93,
    },
    {
      serial_no: 5,
      candidate: "POOLA REDDY SEKHAR",
      party: "Independent",
      evm_votes: 6097,
      postal_votes: 3,
      total_votes: 6100,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 6,
      candidate: "NAGARAJU JETTI",
      party: "Independent",
      evm_votes: 5984,
      postal_votes: 15,
      total_votes: 5999,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 7,
      candidate: "PRADEEP PENAMADU",
      party: "All India Forward Bloc",
      evm_votes: 5671,
      postal_votes: 1,
      total_votes: 5672,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 8,
      candidate: "CHOUDVARAM SUBBANARASAIAH",
      party: "Independent",
      evm_votes: 4731,
      postal_votes: 4,
      total_votes: 4735,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 9,
      candidate: "PULI SREENIVASULU",
      party: "Independent",
      evm_votes: 4613,
      postal_votes: 3,
      total_votes: 4616,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 10,
      candidate: "SREENIVASULU MARRIPATI",
      party: "Independent",
      evm_votes: 4436,
      postal_votes: 7,
      total_votes: 4443,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 11,
      candidate: "AKBAR M BASHA",
      party: "Anna YSR Congress Party",
      evm_votes: 2394,
      postal_votes: 17,
      total_votes: 2411,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 12,
      candidate: "SANGARAJU NAGESWARA RAJU",
      party: "Independent",
      evm_votes: 1523,
      postal_votes: 12,
      total_votes: 1535,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "MADA RAJA",
      party: "Jaathia Chethi Vruthula Ikya Vedika Party",
      evm_votes: 1415,
      postal_votes: 0,
      total_votes: 1415,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 14,
      candidate: "BATTHALA RAMANAIAH",
      party: "Jai Bharat National Party",
      evm_votes: 1360,
      postal_votes: 6,
      total_votes: 1366,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "DODDAPANENI RAJA NAIDU",
      party: "Independent",
      evm_votes: 1315,
      postal_votes: 3,
      total_votes: 1318,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 16,
      candidate: "MAYANA MOHAMMED SALMAN KHAN",
      party: "Liberation Congress Party",
      evm_votes: 1069,
      postal_votes: 2,
      total_votes: 1071,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 17,
      candidate: "KASTURI OBAIAH NAIDU",
      party: "Independent",
      evm_votes: 885,
      postal_votes: 2,
      total_votes: 887,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 18,
      candidate: "ASADI VENKATADRI",
      party: "Independent",
      evm_votes: 689,
      postal_votes: 2,
      total_votes: 691,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 19,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11324,
      postal_votes: 76,
      total_votes: 11400,
      percentage_of_votes: 0.86,
    },
  ],
  total: {
    evm_votes: 1314182,
    postal_votes: 18757,
    total_votes: 1332939,
  },
};
export const s0431 = {
  cs_name: "Patliputra",
  cs_shortname: "patliputra",
  cs_code: "s0431",
  winner: "RJD",
  stats: [
    {
      serial_no: 1,
      candidate: "MISHA BHARTI",
      party: "Rashtriya Janata Dal",
      evm_votes: 612147,
      postal_votes: 1136,
      total_votes: 613283,
      percentage_of_votes: 49.86,
    },
    {
      serial_no: 2,
      candidate: "RAM KRIPAL YADAV",
      party: "Bharatiya Janata Party",
      evm_votes: 526608,
      postal_votes: 1501,
      total_votes: 528109,
      percentage_of_votes: 42.93,
    },
    {
      serial_no: 3,
      candidate: "DR S P SHARMA",
      party: "Hindustan Vikas Dal",
      evm_votes: 15139,
      postal_votes: 1,
      total_votes: 15140,
      percentage_of_votes: 1.23,
    },
    {
      serial_no: 4,
      candidate: "HARIKESHWAR RAM",
      party: "Bahujan Samaj Party",
      evm_votes: 10931,
      postal_votes: 34,
      total_votes: 10965,
      percentage_of_votes: 0.89,
    },
    {
      serial_no: 5,
      candidate: "VINDU DEVI",
      party: "Rashtriya Jansambhavna Party",
      evm_votes: 10741,
      postal_votes: 3,
      total_votes: 10744,
      percentage_of_votes: 0.87,
    },
    {
      serial_no: 6,
      candidate: "SHIV KUMAR SINGH",
      party: "Apna Kisan Party",
      evm_votes: 9011,
      postal_votes: 5,
      total_votes: 9016,
      percentage_of_votes: 0.73,
    },
    {
      serial_no: 7,
      candidate: "SANJAY KUMAR SINGH",
      party: "Bhartiya Sarthak Party",
      evm_votes: 4753,
      postal_votes: 0,
      total_votes: 4753,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 8,
      candidate: "MD FAROOQUE RAZA",
      party: "All India Majlis-E-Ittehadul Muslimeen",
      evm_votes: 4010,
      postal_votes: 13,
      total_votes: 4023,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 9,
      candidate: "RAM NIRANJAN ROY",
      party: "Rashtrawadi Chetna Party",
      evm_votes: 3914,
      postal_votes: 1,
      total_votes: 3915,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 10,
      candidate: "MRITUNJAY KUMAR",
      party: "Bhagidari Party(P)",
      evm_votes: 3483,
      postal_votes: 0,
      total_votes: 3483,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 11,
      candidate: "DURGESH NANDAN SINGH",
      party: "Independent",
      evm_votes: 3104,
      postal_votes: 2,
      total_votes: 3106,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 12,
      candidate: "NAGESHWAR PRASAD",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 2048,
      postal_votes: 3,
      total_votes: 2051,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 13,
      candidate: "SUBHASH KUMAR",
      party: "Bhartiya Lok Chetna Party",
      evm_votes: 1979,
      postal_votes: 0,
      total_votes: 1979,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 14,
      candidate: "NITESH KUMAR PATEL",
      party: "Adarsh Jankalyan Dal",
      evm_votes: 1816,
      postal_votes: 1,
      total_votes: 1817,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 15,
      candidate: "RANJAN KUMAR",
      party: "Independent",
      evm_votes: 1807,
      postal_votes: 2,
      total_votes: 1809,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 16,
      candidate: "NIRAJ KUMAR",
      party: "Independent",
      evm_votes: 1767,
      postal_votes: 4,
      total_votes: 1771,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 17,
      candidate: "KUNAL KUMAR",
      party: "Hamara Sahara Party",
      evm_votes: 1607,
      postal_votes: 7,
      total_votes: 1614,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 18,
      candidate: "KHUSHBOO KUMARI",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 1479,
      postal_votes: 4,
      total_votes: 1483,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 19,
      candidate: "ANIL DAS",
      party: "Bhartiya Dalit Party",
      evm_votes: 1465,
      postal_votes: 4,
      total_votes: 1469,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 20,
      candidate: "ANIL KUMAR CHAND",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1421,
      postal_votes: 3,
      total_votes: 1424,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 21,
      candidate: "MADHURI KUMARI",
      party: "All India Forward Bloc",
      evm_votes: 1301,
      postal_votes: 1,
      total_votes: 1302,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 22,
      candidate: "JAWAHAR SINGH",
      party: "Bhartiya Manavta Party",
      evm_votes: 1235,
      postal_votes: 2,
      total_votes: 1237,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 23,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5573,
      postal_votes: 33,
      total_votes: 5606,
      percentage_of_votes: 0.46,
    },
  ],
  total: {
    evm_votes: 1227339,
    postal_votes: 2760,
    total_votes: 1230099,
  },
};
export const s0433 = {
  cs_name: "Buxar",
  cs_shortname: "buxar",
  cs_code: "s0433",
  winner: "RJD",
  stats: [
    {
      serial_no: 1,
      candidate: "SUDHAKAR SINGH",
      party: "Rashtriya Janata Dal",
      evm_votes: 435062,
      postal_votes: 3283,
      total_votes: 438345,
      percentage_of_votes: 40.82,
    },
    {
      serial_no: 2,
      candidate: "MITHILESH TIWARI",
      party: "Bharatiya Janata Party",
      evm_votes: 405795,
      postal_votes: 2459,
      total_votes: 408254,
      percentage_of_votes: 38.02,
    },
    {
      serial_no: 3,
      candidate: "ANIL KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 114312,
      postal_votes: 402,
      total_votes: 114714,
      percentage_of_votes: 10.68,
    },
    {
      serial_no: 4,
      candidate: "ANAND MISHRA S/O- PARAM HANS MISHRA",
      party: "Independent",
      evm_votes: 46527,
      postal_votes: 882,
      total_votes: 47409,
      percentage_of_votes: 4.42,
    },
    {
      serial_no: 5,
      candidate: "DADAN YADAV",
      party: "Independent",
      evm_votes: 15653,
      postal_votes: 183,
      total_votes: 15836,
      percentage_of_votes: 1.47,
    },
    {
      serial_no: 6,
      candidate: "SUDHAKAR MISHRA",
      party: "Independent",
      evm_votes: 12742,
      postal_votes: 7,
      total_votes: 12749,
      percentage_of_votes: 1.19,
    },
    {
      serial_no: 7,
      candidate: "SUNIL KUMAR DUBY",
      party: "Independent",
      evm_votes: 7757,
      postal_votes: 2,
      total_votes: 7759,
      percentage_of_votes: 0.72,
    },
    {
      serial_no: 8,
      candidate: "RAM SWAROOP CHAUHAN",
      party: "Independent",
      evm_votes: 5506,
      postal_votes: 10,
      total_votes: 5516,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 9,
      candidate: "RAJU SINGH",
      party: "Bahujan Mukti Party",
      evm_votes: 4322,
      postal_votes: 17,
      total_votes: 4339,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 10,
      candidate: "AANAND MISRA S/O MADAN KUMAR MISRA",
      party: "Independent",
      evm_votes: 2697,
      postal_votes: 137,
      total_votes: 2834,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 11,
      candidate: "BHAGWAN SINGH YADAV",
      party: "Independent",
      evm_votes: 2045,
      postal_votes: 8,
      total_votes: 2053,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 12,
      candidate: "NIRANJAN KUMAR RAY",
      party: "Independent",
      evm_votes: 1667,
      postal_votes: 2,
      total_votes: 1669,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 13,
      candidate: "HEM LATA",
      party: "Jagrook Janta Party",
      evm_votes: 1554,
      postal_votes: 16,
      total_votes: 1570,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 14,
      candidate: "AKHILESH KUMAR PANDEY",
      party: "Independent",
      evm_votes: 1089,
      postal_votes: 6,
      total_votes: 1095,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 9572,
      postal_votes: 45,
      total_votes: 9617,
      percentage_of_votes: 0.9,
    },
  ],
  total: {
    evm_votes: 1066300,
    postal_votes: 7459,
    total_votes: 1073759,
  },
};
export const s0436 = {
  cs_name: "Jahanabad",
  cs_shortname: "jahanabad",
  cs_code: "s0436",
  winner: "RJD",
  stats: [
    {
      serial_no: 1,
      candidate: "SURENDRA PRASAD YADAV",
      party: "Rashtriya Janata Dal",
      evm_votes: 440352,
      postal_votes: 2683,
      total_votes: 443035,
      percentage_of_votes: 47.88,
    },
    {
      serial_no: 2,
      candidate: "CHANDESHWAR PRASAD",
      party: "Janata Dal (United)",
      evm_votes: 299044,
      postal_votes: 1400,
      total_votes: 300444,
      percentage_of_votes: 32.47,
    },
    {
      serial_no: 3,
      candidate: "ARUN KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 85696,
      postal_votes: 684,
      total_votes: 86380,
      percentage_of_votes: 9.33,
    },
    {
      serial_no: 4,
      candidate: "MINTA DEVI",
      party: "Independent",
      evm_votes: 16112,
      postal_votes: 11,
      total_votes: 16123,
      percentage_of_votes: 1.74,
    },
    {
      serial_no: 5,
      candidate: "MUNILAL YADAV",
      party: "Independent",
      evm_votes: 13021,
      postal_votes: 8,
      total_votes: 13029,
      percentage_of_votes: 1.41,
    },
    {
      serial_no: 6,
      candidate: "ASHUTOSH KUMAR",
      party: "Rashtriya Jan Jan Party",
      evm_votes: 12954,
      postal_votes: 259,
      total_votes: 13213,
      percentage_of_votes: 1.43,
    },
    {
      serial_no: 7,
      candidate: "DEEPAK KUMAR",
      party: "Jagrook Janta Party",
      evm_votes: 8944,
      postal_votes: 26,
      total_votes: 8970,
      percentage_of_votes: 0.97,
    },
    {
      serial_no: 8,
      candidate: "BUDDHADEV SAV",
      party: "Independent",
      evm_votes: 5765,
      postal_votes: 8,
      total_votes: 5773,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 9,
      candidate: "NARESH KUMAR",
      party: "Independent",
      evm_votes: 5709,
      postal_votes: 3,
      total_votes: 5712,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 10,
      candidate: "CHANDESHWAR PRASAD",
      party: "Independent",
      evm_votes: 5356,
      postal_votes: 22,
      total_votes: 5378,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 11,
      candidate: "PIYUSH SINGH",
      party: "Shoshit Samaj Dal",
      evm_votes: 4611,
      postal_votes: 7,
      total_votes: 4618,
      percentage_of_votes: 0.5,
    },
    {
      serial_no: 12,
      candidate: "RAJ KISHOR SHARMA",
      party: "Bhartiya Sarthak Party",
      evm_votes: 3230,
      postal_votes: 13,
      total_votes: 3243,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 13,
      candidate: "KAPIL CHAUHAN",
      party: "Jan Janwadi Party",
      evm_votes: 2954,
      postal_votes: 10,
      total_votes: 2964,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 14,
      candidate: "ASHUTOSH VINAY KUMAR",
      party: "Rashtriya Garib Dal",
      evm_votes: 2895,
      postal_votes: 14,
      total_votes: 2909,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 15,
      candidate: "UMA SHANKAR VERMA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2509,
      postal_votes: 15,
      total_votes: 2524,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10960,
      postal_votes: 95,
      total_votes: 11055,
      percentage_of_votes: 1.19,
    },
  ],
  total: {
    evm_votes: 920112,
    postal_votes: 5258,
    total_votes: 925370,
  },
};
export const s0437 = {
  cs_name: "Aurangabad_bihar",
  cs_shortname: "aurangabad_bihar",
  cs_code: "s0437",
  winner: "RJD",
  stats: [
    {
      serial_no: 1,
      candidate: "ABHAY KUMAR SINHA",
      party: "Rashtriya Janata Dal",
      evm_votes: 463845,
      postal_votes: 1722,
      total_votes: 465567,
      percentage_of_votes: 49.22,
    },
    {
      serial_no: 2,
      candidate: "SUSHIL KUMAR SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 384819,
      postal_votes: 1637,
      total_votes: 386456,
      percentage_of_votes: 40.86,
    },
    {
      serial_no: 3,
      candidate: "SUNESH KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 20232,
      postal_votes: 77,
      total_votes: 20309,
      percentage_of_votes: 2.15,
    },
    {
      serial_no: 4,
      candidate: "PRATIBHA RANI",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 12265,
      postal_votes: 43,
      total_votes: 12308,
      percentage_of_votes: 1.3,
    },
    {
      serial_no: 5,
      candidate: "RAJ BALLABH SINGH",
      party: "Independent",
      evm_votes: 11150,
      postal_votes: 9,
      total_votes: 11159,
      percentage_of_votes: 1.18,
    },
    {
      serial_no: 6,
      candidate: "RAMJIT SINGH",
      party: "Rashtriya Jansambhavna Party",
      evm_votes: 9237,
      postal_votes: 6,
      total_votes: 9243,
      percentage_of_votes: 0.98,
    },
    {
      serial_no: 7,
      candidate: "SURESH PRASAD VERMA",
      party: "Independent",
      evm_votes: 6704,
      postal_votes: 7,
      total_votes: 6711,
      percentage_of_votes: 0.71,
    },
    {
      serial_no: 8,
      candidate: "MOHAMMAD WALIULLAH KHAN",
      party: "Independent",
      evm_votes: 6591,
      postal_votes: 13,
      total_votes: 6604,
      percentage_of_votes: 0.7,
    },
    {
      serial_no: 9,
      candidate: "SHAILESH RAHI",
      party: "Akhil Hind Forward Bloc (Krantikari)",
      evm_votes: 4892,
      postal_votes: 4,
      total_votes: 4896,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 22566,
      postal_votes: 61,
      total_votes: 22627,
      percentage_of_votes: 2.39,
    },
  ],
  total: {
    evm_votes: 942301,
    postal_votes: 3579,
    total_votes: 945880,
  },
};
export const s119 = {
  cs_name: "Alathur",
  cs_shortname: "alathur",
  cs_code: "s119",
  winner: "CPI(M)",
  stats: [
    {
      serial_no: 1,
      candidate: "K.RADHAKRISHNAN",
      party: "Communist Party of India (Marxist)",
      evm_votes: 398818,
      postal_votes: 4629,
      total_votes: 403447,
      percentage_of_votes: 40.66,
    },
    {
      serial_no: 2,
      candidate: "RAMYA HARIDAS",
      party: "Indian National Congress",
      evm_votes: 379231,
      postal_votes: 4105,
      total_votes: 383336,
      percentage_of_votes: 38.63,
    },
    {
      serial_no: 3,
      candidate: "DR T N SARASU",
      party: "Bharatiya Janata Party",
      evm_votes: 186441,
      postal_votes: 1789,
      total_votes: 188230,
      percentage_of_votes: 18.97,
    },
    {
      serial_no: 4,
      candidate: "HARI ARUMBIL",
      party: "Bahujan Samaj Party",
      evm_votes: 2835,
      postal_votes: 36,
      total_votes: 2871,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 5,
      candidate: "KRISHNANKUTTY KUNISSERY",
      party: "Independent",
      evm_votes: 2323,
      postal_votes: 28,
      total_votes: 2351,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 6,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11918,
      postal_votes: 115,
      total_votes: 12033,
      percentage_of_votes: 1.21,
    },
  ],
  total: {
    evm_votes: 981566,
    postal_votes: 10702,
    total_votes: 992268,
  },
};
export const s205 = {
  cs_name: "SIKAR",
  cs_shortname: "sikar",
  cs_code: "s205",
  winner: "CPI(M)",
  stats: [
    {
      serial_no: 1,
      candidate: "AMRARAM",
      party: "Communist Party of India (Marxist)",
      evm_votes: 645559,
      postal_votes: 13741,
      total_votes: 659300,
      percentage_of_votes: 50.68,
    },
    {
      serial_no: 2,
      candidate: "SUMEDHANAND SARASWATI",
      party: "Bharatiya Janata Party",
      evm_votes: 574740,
      postal_votes: 11664,
      total_votes: 586404,
      percentage_of_votes: 45.08,
    },
    {
      serial_no: 3,
      candidate: "SURESH PAREEK",
      party: "Independent",
      evm_votes: 10014,
      postal_votes: 7,
      total_votes: 10021,
      percentage_of_votes: 0.77,
    },
    {
      serial_no: 4,
      candidate: "AMARCHAND",
      party: "Bahujan Samaj Party",
      evm_votes: 8293,
      postal_votes: 326,
      total_votes: 8619,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 5,
      candidate: "VISHNU KUMAR NATRAJ",
      party: "Independent",
      evm_votes: 5796,
      postal_votes: 7,
      total_votes: 5803,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 6,
      candidate: "RAVINDRA SINGH SHEKHAWAT",
      party: "Independent",
      evm_votes: 5775,
      postal_votes: 250,
      total_votes: 6025,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 7,
      candidate: "ASHOK SEHRA",
      party: "Ambedkarite Party of India",
      evm_votes: 5333,
      postal_votes: 49,
      total_votes: 5382,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 8,
      candidate: "DHIRENDRA VERMA",
      party: "Ummeed Party of India",
      evm_votes: 2885,
      postal_votes: 59,
      total_votes: 2944,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 9,
      candidate: "MAHESH KUMAR",
      party: "Independent",
      evm_votes: 1962,
      postal_votes: 94,
      total_votes: 2056,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 10,
      candidate: "BENI PRASAD KAUSHIK (LATA)",
      party: "Rashtriya Sawarn Dal",
      evm_votes: 1720,
      postal_votes: 11,
      total_votes: 1731,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 11,
      candidate: "RAMESH SHARMA",
      party: "Bhartiya Party",
      evm_votes: 1516,
      postal_votes: 33,
      total_votes: 1549,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "VIJENDER MAHARIA",
      party: "Bharatiya Yuva Jan Ekta Party",
      evm_votes: 1421,
      postal_votes: 30,
      total_votes: 1451,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 13,
      candidate: "OM PRAKASH",
      party: "Independent",
      evm_votes: 1182,
      postal_votes: 42,
      total_votes: 1224,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "BIRBALSINGH",
      party: "Independent",
      evm_votes: 1034,
      postal_votes: 47,
      total_votes: 1081,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7081,
      postal_votes: 185,
      total_votes: 7266,
      percentage_of_votes: 0.56,
    },
  ],
  total: {
    evm_votes: 1274311,
    postal_votes: 26545,
    total_votes: 1300856,
  },
};
export const s2222 = {
  cs_name: "DINDIGUL",
  cs_shortname: "dindigul",
  cs_code: "s2222",
  winner: "CPI(M)",
  stats: [
    {
      serial_no: 1,
      candidate: "SACHITHANANTHAM R",
      party: "Communist Party of India (Marxist)",
      evm_votes: 667391,
      postal_votes: 2758,
      total_votes: 670149,
      percentage_of_votes: 58.29,
    },
    {
      serial_no: 2,
      candidate: "MOHAMED MUBARAK M A",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 225492,
      postal_votes: 836,
      total_votes: 226328,
      percentage_of_votes: 19.69,
    },
    {
      serial_no: 3,
      candidate: "THILGABAMA M",
      party: "Pattali Makkal Katchi",
      evm_votes: 111013,
      postal_votes: 1490,
      total_votes: 112503,
      percentage_of_votes: 9.79,
    },
    {
      serial_no: 4,
      candidate: "KAILAI RAJAN D",
      party: "Naam Tamilar Katchi",
      evm_votes: 96891,
      postal_votes: 954,
      total_votes: 97845,
      percentage_of_votes: 8.51,
    },
    {
      serial_no: 5,
      candidate: "RAJKUMAR R",
      party: "Independent",
      evm_votes: 4410,
      postal_votes: 6,
      total_votes: 4416,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 6,
      candidate: "NATCHIMUTHU S",
      party: "Bahujan Samaj Party",
      evm_votes: 4231,
      postal_votes: 53,
      total_votes: 4284,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 7,
      candidate: "DINESHKUMAR S",
      party: "All India Youth Development Party",
      evm_votes: 2411,
      postal_votes: 23,
      total_votes: 2434,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 8,
      candidate: "MURUGESAN (A) VISHNU MURUGESAN C",
      party: "Independent",
      evm_votes: 2004,
      postal_votes: 4,
      total_votes: 2008,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 9,
      candidate: "ANGUCHAMY M",
      party: "Independent",
      evm_votes: 1286,
      postal_votes: 4,
      total_votes: 1290,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "SURESH K",
      party: "Independent",
      evm_votes: 1254,
      postal_votes: 3,
      total_votes: 1257,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 11,
      candidate: "ARUMUGAM R",
      party: "Independent",
      evm_votes: 1078,
      postal_votes: 11,
      total_votes: 1089,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 12,
      candidate: "SABARINATH S",
      party: "Independent",
      evm_votes: 1001,
      postal_votes: 10,
      total_votes: 1011,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "ANBUROSE D",
      party: "Independent",
      evm_votes: 997,
      postal_votes: 15,
      total_votes: 1012,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 14,
      candidate: "PALANISAMY K",
      party: "Independent",
      evm_votes: 941,
      postal_votes: 8,
      total_votes: 949,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 15,
      candidate: "SATHISH KANNA G T",
      party: "Independent",
      evm_votes: 914,
      postal_votes: 12,
      total_votes: 926,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 21860,
      postal_votes: 260,
      total_votes: 22120,
      percentage_of_votes: 1.92,
    },
  ],
  total: {
    evm_votes: 1143174,
    postal_votes: 6447,
    total_votes: 1149621,
  },
};
export const s2232 = {
  cs_name: "MADURAI",
  cs_shortname: "madurai",
  cs_code: "s2232",
  winner: "CPI(M)",
  stats: [
    {
      serial_no: 1,
      candidate: "VENKATESAN S",
      party: "Communist Party of India (Marxist)",
      evm_votes: 428201,
      postal_votes: 2122,
      total_votes: 430323,
      percentage_of_votes: 43.6,
    },
    {
      serial_no: 2,
      candidate: "RAAMA SREENIVASAN",
      party: "Bharatiya Janata Party",
      evm_votes: 219035,
      postal_votes: 1879,
      total_votes: 220914,
      percentage_of_votes: 22.38,
    },
    {
      serial_no: 3,
      candidate: "SARAVANAN P",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 204163,
      postal_votes: 641,
      total_votes: 204804,
      percentage_of_votes: 20.75,
    },
    {
      serial_no: 4,
      candidate: "SATHYADEVI T",
      party: "Naam Tamilar Katchi",
      evm_votes: 92386,
      postal_votes: 493,
      total_votes: 92879,
      percentage_of_votes: 9.41,
    },
    {
      serial_no: 5,
      candidate: "VELMURUGAN A",
      party: "Bharatiya Praja Aikyata Party",
      evm_votes: 5966,
      postal_votes: 43,
      total_votes: 6009,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 6,
      candidate: "VENKATESAN M",
      party: "Independent",
      evm_votes: 2497,
      postal_votes: 8,
      total_votes: 2505,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 7,
      candidate: "RAMAR PANDI T",
      party: "Bahujan Samaj Party",
      evm_votes: 2489,
      postal_votes: 17,
      total_votes: 2506,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 8,
      candidate: "SHANMUGA SUNDARAM A",
      party: "United Republican Party of India",
      evm_votes: 2338,
      postal_votes: 11,
      total_votes: 2349,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 9,
      candidate: "PERIYASAMY K",
      party: "Independent",
      evm_votes: 1966,
      postal_votes: 2,
      total_votes: 1968,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 10,
      candidate: "PANDIYAN S",
      party: "Bahujan Dravida Party",
      evm_votes: 1559,
      postal_votes: 3,
      total_votes: 1562,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 11,
      candidate: "BOOMINATHAN C",
      party: "Independent",
      evm_votes: 1211,
      postal_votes: 2,
      total_votes: 1213,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "MUTHUPANDI S",
      party: "Independent",
      evm_votes: 1206,
      postal_votes: 1,
      total_votes: 1207,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "RAMANATHAN S",
      party: "Independent",
      evm_votes: 1191,
      postal_votes: 3,
      total_votes: 1194,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "VENKATESH S",
      party: "Independent",
      evm_votes: 1041,
      postal_votes: 5,
      total_votes: 1046,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 15,
      candidate: "SANKARAPANDI M.P.",
      party: "Independent",
      evm_votes: 1025,
      postal_votes: 4,
      total_votes: 1029,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 16,
      candidate: "GOPISON M.M.",
      party: "Independent",
      evm_votes: 942,
      postal_votes: 4,
      total_votes: 946,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 17,
      candidate: "SARAVANAN K.K.",
      party: "Independent",
      evm_votes: 843,
      postal_votes: 1,
      total_votes: 844,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 18,
      candidate: "GOPALAKRISHNAN S",
      party: "Independent",
      evm_votes: 745,
      postal_votes: 1,
      total_votes: 746,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 19,
      candidate: "CHANDRASEKAR N",
      party: "Independent",
      evm_votes: 708,
      postal_votes: 3,
      total_votes: 711,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 20,
      candidate: "PANDIYAN P",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 576,
      postal_votes: 2,
      total_votes: 578,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 21,
      candidate: "AVADAINATHAN V",
      party: "Independent",
      evm_votes: 461,
      postal_votes: 1,
      total_votes: 462,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 22,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11074,
      postal_votes: 100,
      total_votes: 11174,
      percentage_of_votes: 1.13,
    },
  ],
  total: {
    evm_votes: 981623,
    postal_votes: 5346,
    total_votes: 986969,
  },
};
export const s116 = {
  cs_name: "Malappuram",
  cs_shortname: "malappuram",
  cs_code: "s116",
  winner: "IUML",
  stats: [
    {
      serial_no: 1,
      candidate: "E.T. MOHAMMED BASHEER",
      party: "Indian Union Muslim League",
      evm_votes: 640269,
      postal_votes: 3737,
      total_votes: 644006,
      percentage_of_votes: 59.35,
    },
    {
      serial_no: 2,
      candidate: "V. VASEEF",
      party: "Communist Party of India (Marxist)",
      evm_votes: 341510,
      postal_votes: 2378,
      total_votes: 343888,
      percentage_of_votes: 31.69,
    },
    {
      serial_no: 3,
      candidate: "DR. ABDUL SALAM",
      party: "Bharatiya Janata Party",
      evm_votes: 84529,
      postal_votes: 832,
      total_votes: 85361,
      percentage_of_votes: 7.87,
    },
    {
      serial_no: 4,
      candidate: "T. KRISHNAN",
      party: "Bahujan Samaj Party",
      evm_votes: 1392,
      postal_votes: 25,
      total_votes: 1417,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 5,
      candidate: "P.C. NARAYANAN",
      party: "Bahujan Dravida Party",
      evm_votes: 1304,
      postal_votes: 5,
      total_votes: 1309,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 6,
      candidate: "NASEEF ALI MULLAPPALLI",
      party: "Independent",
      evm_votes: 1154,
      postal_votes: 14,
      total_votes: 1168,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 7,
      candidate: "ABDULSALAM S/O MUHAMMED HAJI",
      party: "Independent",
      evm_votes: 771,
      postal_votes: 12,
      total_votes: 783,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 8,
      candidate: "THRISSUR NAZEER",
      party: "Independent",
      evm_votes: 459,
      postal_votes: 25,
      total_votes: 484,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6650,
      postal_votes: 116,
      total_votes: 6766,
      percentage_of_votes: 0.62,
    },
  ],
  total: {
    evm_votes: 1078038,
    postal_votes: 7144,
    total_votes: 1085182,
  },
};
export const s117 = {
  cs_name: "Ponnani",
  cs_shortname: "ponnani",
  cs_code: "s117",
  winner: "IUML",
  stats: [
    {
      serial_no: 1,
      candidate: "DR. M.P ABDUSSAMAD SAMADANI",
      party: "Indian Union Muslim League",
      evm_votes: 559570,
      postal_votes: 2946,
      total_votes: 562516,
      percentage_of_votes: 54.81,
    },
    {
      serial_no: 2,
      candidate: "K.S HAMZA",
      party: "Communist Party of India (Marxist)",
      evm_votes: 324562,
      postal_votes: 2194,
      total_votes: 326756,
      percentage_of_votes: 31.84,
    },
    {
      serial_no: 3,
      candidate: "ADV. NIVEDIDA",
      party: "Bharatiya Janata Party",
      evm_votes: 123733,
      postal_votes: 1065,
      total_votes: 124798,
      percentage_of_votes: 12.16,
    },
    {
      serial_no: 4,
      candidate: "ABDUSSAMED MALAYAMPALLI",
      party: "Independent",
      evm_votes: 1634,
      postal_votes: 19,
      total_votes: 1653,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 5,
      candidate: "VINOD",
      party: "Bahujan Samaj Party",
      evm_votes: 1449,
      postal_votes: 28,
      total_votes: 1477,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 6,
      candidate: "HAMSA S/O MOIDUTTY",
      party: "Independent",
      evm_votes: 952,
      postal_votes: 26,
      total_votes: 978,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 7,
      candidate: "BINDHU W/O DEVARAJAN",
      party: "Independent",
      evm_votes: 826,
      postal_votes: 21,
      total_votes: 847,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 8,
      candidate: "HAMZA KADAVANDI",
      party: "Independent",
      evm_votes: 707,
      postal_votes: 3,
      total_votes: 710,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6499,
      postal_votes: 62,
      total_votes: 6561,
      percentage_of_votes: 0.64,
    },
  ],
  total: {
    evm_votes: 1019932,
    postal_votes: 6364,
    total_votes: 1026296,
  },
};
export const s2235 = {
  cs_name: "RAMANATHAPURAM",
  cs_shortname: "ramanathapuram",
  cs_code: "s2235",
  winner: "IUML",
  stats: [
    {
      serial_no: 1,
      candidate: "NAVASKANI K",
      party: "Indian Union Muslim League",
      evm_votes: 506690,
      postal_votes: 2974,
      total_votes: 509664,
      percentage_of_votes: 45.92,
    },
    {
      serial_no: 2,
      candidate: "PANNEERSELVAM O S/O OTTAKARATHEVAR",
      party: "Independent",
      evm_votes: 340778,
      postal_votes: 2104,
      total_votes: 342882,
      percentage_of_votes: 30.89,
    },
    {
      serial_no: 3,
      candidate: "JEYAPERUMAL P",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 98757,
      postal_votes: 1023,
      total_votes: 99780,
      percentage_of_votes: 8.99,
    },
    {
      serial_no: 4,
      candidate: "DR CHANDRA PRABHA JEYAPAL",
      party: "Naam Tamilar Katchi",
      evm_votes: 96701,
      postal_votes: 971,
      total_votes: 97672,
      percentage_of_votes: 8.8,
    },
    {
      serial_no: 5,
      candidate: "MUVITH KUMAR N",
      party: "Independent",
      evm_votes: 7901,
      postal_votes: 18,
      total_votes: 7919,
      percentage_of_votes: 0.71,
    },
    {
      serial_no: 6,
      candidate: "SENTHILMALLAR K",
      party: "Independent",
      evm_votes: 6629,
      postal_votes: 198,
      total_votes: 6827,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 7,
      candidate: "SIVASHANKAR N",
      party: "Independent",
      evm_votes: 6583,
      postal_votes: 10,
      total_votes: 6593,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 8,
      candidate: "KANNADHASAN R",
      party: "Independent",
      evm_votes: 3485,
      postal_votes: 17,
      total_votes: 3502,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 9,
      candidate: "SELVARAJ G",
      party: "Puthiya Makkal Tamil Desam Katchi",
      evm_votes: 2943,
      postal_votes: 14,
      total_votes: 2957,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 10,
      candidate: "PANNEERSELVAM O S/O OCHAPPAN",
      party: "Independent",
      evm_votes: 2939,
      postal_votes: 42,
      total_votes: 2981,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 11,
      candidate: "PANCHAVARNAM V",
      party: "Independent",
      evm_votes: 2561,
      postal_votes: 11,
      total_votes: 2572,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 12,
      candidate: "PARIRAJAN C",
      party: "Veerath Thiyagi Viswanathadoss Thozhilalarkal Katchi",
      evm_votes: 2493,
      postal_votes: 4,
      total_votes: 2497,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 13,
      candidate: "PANNIR SELVAM M S/O MALAIYANDI",
      party: "Independent",
      evm_votes: 2387,
      postal_votes: 15,
      total_votes: 2402,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 14,
      candidate: "NAWASKHAN",
      party: "Independent",
      evm_votes: 2301,
      postal_votes: 22,
      total_votes: 2323,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 15,
      candidate: "SIVANANDAM N",
      party: "Bahujan Samaj Party",
      evm_votes: 1958,
      postal_votes: 56,
      total_votes: 2014,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 16,
      candidate: "PANNEERSELVAM O S/O OYYADEVAR",
      party: "Independent",
      evm_votes: 1920,
      postal_votes: 9,
      total_votes: 1929,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 17,
      candidate: "ABUBAKKAR SITHICK J",
      party: "Desiya Makkal Sakthi Katchi",
      evm_votes: 1497,
      postal_votes: 21,
      total_votes: 1518,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 18,
      candidate: "MANIVASAGAM S",
      party: "Bahujan Dravida Party",
      evm_votes: 1392,
      postal_votes: 11,
      total_votes: 1403,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 19,
      candidate: "PANNEERSELVAM O S/O OYYARAM",
      party: "Independent",
      evm_votes: 1369,
      postal_votes: 7,
      total_votes: 1376,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 20,
      candidate: "SALEEM",
      party: "Independent",
      evm_votes: 961,
      postal_votes: 13,
      total_votes: 974,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 21,
      candidate: "SASIKANI K",
      party: "Independent",
      evm_votes: 896,
      postal_votes: 3,
      total_votes: 899,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 22,
      candidate: "SATHURAGIRI K",
      party: "Independent",
      evm_votes: 815,
      postal_votes: 12,
      total_votes: 827,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 23,
      candidate: "SIKKANDAR B",
      party: "Independent",
      evm_votes: 804,
      postal_votes: 19,
      total_votes: 823,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 24,
      candidate: "VIGNESH V",
      party: "Independent",
      evm_votes: 643,
      postal_votes: 9,
      total_votes: 652,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 25,
      candidate: "PANNERSELVAM O S/O OCHATTHEVAR",
      party: "Independent",
      evm_votes: 564,
      postal_votes: 8,
      total_votes: 572,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 26,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6163,
      postal_votes: 132,
      total_votes: 6295,
      percentage_of_votes: 0.57,
    },
  ],
  total: {
    evm_votes: 1102130,
    postal_votes: 7723,
    total_votes: 1109853,
  },
};
export const s195 = {
  cs_name: "Hoshiarpur",
  cs_shortname: "hoshiarpur",
  cs_code: "s195",
  winner: "AAAP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR. RAJ KUMAR CHABBEWAL",
      party: "Aam Aadmi Party",
      evm_votes: 302402,
      postal_votes: 1457,
      total_votes: 303859,
      percentage_of_votes: 32.04,
    },
    {
      serial_no: 2,
      candidate: "YAMINI GOMAR",
      party: "Indian National Congress",
      evm_votes: 257936,
      postal_votes: 1812,
      total_votes: 259748,
      percentage_of_votes: 27.39,
    },
    {
      serial_no: 3,
      candidate: "ANITA SOM PARKASH",
      party: "Bharatiya Janata Party",
      evm_votes: 198576,
      postal_votes: 1418,
      total_votes: 199994,
      percentage_of_votes: 21.09,
    },
    {
      serial_no: 4,
      candidate: "SOHAN SINGH THANDAL",
      party: "Shiromani Akali Dal",
      evm_votes: 91503,
      postal_votes: 286,
      total_votes: 91789,
      percentage_of_votes: 9.68,
    },
    {
      serial_no: 5,
      candidate: "RANJIT KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 47975,
      postal_votes: 239,
      total_votes: 48214,
      percentage_of_votes: 5.08,
    },
    {
      serial_no: 6,
      candidate: "JASWANT SINGH",
      party: "Shiromani Akali Dal (Amritsar)(Simranjit Singh Mann)",
      evm_votes: 20680,
      postal_votes: 243,
      total_votes: 20923,
      percentage_of_votes: 2.21,
    },
    {
      serial_no: 7,
      candidate: "JEEVAN SINGH TAMIL",
      party: "Bahujan Dravida Party",
      evm_votes: 4607,
      postal_votes: 14,
      total_votes: 4621,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 8,
      candidate: "ROHIT KUMAR TINKU",
      party: "Independent",
      evm_votes: 2417,
      postal_votes: 2,
      total_votes: 2419,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 9,
      candidate: "RAJESH",
      party: "Democratic Bharatiya Samaj Party",
      evm_votes: 2330,
      postal_votes: 6,
      total_votes: 2336,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 10,
      candidate: "SONU SINGH PHAGWARA",
      party: "Independent",
      evm_votes: 1871,
      postal_votes: 13,
      total_votes: 1884,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 11,
      candidate: "DAVINDER SINGH",
      party: "Independent",
      evm_votes: 1584,
      postal_votes: 33,
      total_votes: 1617,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 12,
      candidate: "HARDEEP SINGH",
      party: "Nationalist Justice Party",
      evm_votes: 1403,
      postal_votes: 22,
      total_votes: 1425,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 13,
      candidate: "RAJPAL NADALI",
      party: "Bahujan Mukti Party",
      evm_votes: 1138,
      postal_votes: 2,
      total_votes: 1140,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 14,
      candidate: "BHIMRAO YASHWANT AMBEDKAR",
      party: "Global Republican Party",
      evm_votes: 1034,
      postal_votes: 7,
      total_votes: 1041,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 15,
      candidate: "SATPAL",
      party: "Independent",
      evm_votes: 978,
      postal_votes: 15,
      total_votes: 993,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 16,
      candidate: "DAVINDER KUMAR SAROYA",
      party: "Samaj Bhalai Morcha",
      evm_votes: 923,
      postal_votes: 7,
      total_votes: 930,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 17,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5453,
      postal_votes: 99,
      total_votes: 5552,
      percentage_of_votes: 0.59,
    },
  ],
  total: {
    evm_votes: 942810,
    postal_votes: 5675,
    total_votes: 948485,
  },
};
export const s196 = {
  cs_name: "Anandpur_Sahib",
  cs_shortname: "anandpursahib",
  cs_code: "s196",
  winner: "AAAP",
  stats: [
    {
      serial_no: 1,
      candidate: "MALVINDER SINGH KANG",
      party: "Aam Aadmi Party",
      evm_votes: 312241,
      postal_votes: 976,
      total_votes: 313217,
      percentage_of_votes: 29.08,
    },
    {
      serial_no: 2,
      candidate: "VIJAY INDER SINGLA",
      party: "Indian National Congress",
      evm_votes: 301414,
      postal_votes: 957,
      total_votes: 302371,
      percentage_of_votes: 28.07,
    },
    {
      serial_no: 3,
      candidate: "DR SUBHASH SHARMA",
      party: "Bharatiya Janata Party",
      evm_votes: 185836,
      postal_votes: 742,
      total_votes: 186578,
      percentage_of_votes: 17.32,
    },
    {
      serial_no: 4,
      candidate: "PREM SINGH CHANDUMAJRA",
      party: "Shiromani Akali Dal",
      evm_votes: 117651,
      postal_votes: 285,
      total_votes: 117936,
      percentage_of_votes: 10.95,
    },
    {
      serial_no: 5,
      candidate: "JASVIR SINGH GARHI",
      party: "Bahujan Samaj Party",
      evm_votes: 89953,
      postal_votes: 204,
      total_votes: 90157,
      percentage_of_votes: 8.37,
    },
    {
      serial_no: 6,
      candidate: "KUSHAL PAL SINGH MANN",
      party: "Shiromani Akali Dal (Amritsar)(Simranjit Singh Mann)",
      evm_votes: 24725,
      postal_votes: 106,
      total_votes: 24831,
      percentage_of_votes: 2.31,
    },
    {
      serial_no: 7,
      candidate: "GAJJAN SINGH",
      party: "Independent",
      evm_votes: 6164,
      postal_votes: 7,
      total_votes: 6171,
      percentage_of_votes: 0.57,
    },
    {
      serial_no: 8,
      candidate: "SIMPLE KUMAR",
      party: "Independent",
      evm_votes: 2962,
      postal_votes: 0,
      total_votes: 2962,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 9,
      candidate: "SATPAL",
      party: "Independent",
      evm_votes: 2294,
      postal_votes: 3,
      total_votes: 2297,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 10,
      candidate: "GURMIT SINGH",
      party: "Independent",
      evm_votes: 2179,
      postal_votes: 3,
      total_votes: 2182,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 11,
      candidate: "DHARMINDER SINGH HARMAN",
      party: "Akhil Bhartiya Parivar Party",
      evm_votes: 2037,
      postal_votes: 6,
      total_votes: 2043,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 12,
      candidate: "SUNIL KUMAR",
      party: "Bhartiya Jan Samman Party",
      evm_votes: 1968,
      postal_votes: 9,
      total_votes: 1977,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 13,
      candidate: "ER DALJEET SINGH SAINI",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 1710,
      postal_votes: 5,
      total_votes: 1715,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 14,
      candidate: "KULWINDER SINGH",
      party: "Democratic Bharatiya Samaj Party",
      evm_votes: 1651,
      postal_votes: 1,
      total_votes: 1652,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 15,
      candidate: "SUNAINA",
      party: "Bhartiya Rashtriya Dal",
      evm_votes: 1621,
      postal_votes: 19,
      total_votes: 1640,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 16,
      candidate: "ADV KULWINDER SINGH",
      party: "Independent",
      evm_votes: 1596,
      postal_votes: 4,
      total_votes: 1600,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 17,
      candidate: "MEGH RAJ",
      party: "Independent",
      evm_votes: 1429,
      postal_votes: 1,
      total_votes: 1430,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 18,
      candidate: "HARWINDER KARWAL",
      party: "Independent",
      evm_votes: 1309,
      postal_votes: 2,
      total_votes: 1311,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 19,
      candidate: "DAVINDER SINGH",
      party: "Independent",
      evm_votes: 1164,
      postal_votes: 11,
      total_votes: 1175,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 20,
      candidate: "DARSHAN SINGH",
      party: "Punjab National Party",
      evm_votes: 1164,
      postal_votes: 8,
      total_votes: 1172,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 21,
      candidate: "HANISH SHARMA",
      party: "Ekam Sanatan Bharat Dal",
      evm_votes: 1072,
      postal_votes: 4,
      total_votes: 1076,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 22,
      candidate: "DEEPAK SHARMA",
      party: "Independent",
      evm_votes: 1012,
      postal_votes: 0,
      total_votes: 1012,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 23,
      candidate: "PARMINDER SINGH",
      party: "Independent",
      evm_votes: 930,
      postal_votes: 0,
      total_votes: 930,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 24,
      candidate: "YOG RAJ SAHOTA",
      party: "Revolutionary Socialist Party",
      evm_votes: 925,
      postal_votes: 0,
      total_votes: 925,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 25,
      candidate: "RAMJAAN KHAN",
      party: "Independent",
      evm_votes: 672,
      postal_votes: 0,
      total_votes: 672,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 26,
      candidate: "ROHTASH",
      party: "Independent",
      evm_votes: 617,
      postal_votes: 2,
      total_votes: 619,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 27,
      candidate: "MANDAR SINGH",
      party: "Independent",
      evm_votes: 555,
      postal_votes: 0,
      total_votes: 555,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 28,
      candidate: "KIRAN JAIN",
      party: "Hindustan Shakti Sena",
      evm_votes: 507,
      postal_votes: 8,
      total_votes: 515,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 29,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6351,
      postal_votes: 51,
      total_votes: 6402,
      percentage_of_votes: 0.59,
    },
  ],
  total: {
    evm_votes: 1073709,
    postal_votes: 3414,
    total_votes: 1077123,
  },
};
export const s1912 = {
  cs_name: "Sangrur",
  cs_shortname: "sangrur",
  cs_code: "s1912",
  winner: "AAAP",
  stats: [
    {
      serial_no: 1,
      candidate: "GURMEET SINGH MEET HAYER",
      party: "Aam Aadmi Party",
      evm_votes: 362791,
      postal_votes: 1294,
      total_votes: 364085,
      percentage_of_votes: 36.06,
    },
    {
      serial_no: 2,
      candidate: "SUKHPAL SINGH KHAIRA",
      party: "Indian National Congress",
      evm_votes: 190209,
      postal_votes: 1316,
      total_votes: 191525,
      percentage_of_votes: 18.97,
    },
    {
      serial_no: 3,
      candidate: "SIMRANJIT SINGH MANN",
      party: "Shiromani Akali Dal (Amritsar)(Simranjit Singh Mann)",
      evm_votes: 186543,
      postal_votes: 703,
      total_votes: 187246,
      percentage_of_votes: 18.55,
    },
    {
      serial_no: 4,
      candidate: "ARVIND KHANNA",
      party: "Bharatiya Janata Party",
      evm_votes: 127781,
      postal_votes: 472,
      total_votes: 128253,
      percentage_of_votes: 12.7,
    },
    {
      serial_no: 5,
      candidate: "IQBAL SINGH JHUNDAN",
      party: "Shiromani Akali Dal",
      evm_votes: 62265,
      postal_votes: 223,
      total_votes: 62488,
      percentage_of_votes: 6.19,
    },
    {
      serial_no: 6,
      candidate: "MAKHAN SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 37604,
      postal_votes: 127,
      total_votes: 37731,
      percentage_of_votes: 3.74,
    },
    {
      serial_no: 7,
      candidate: "PARDEEP KUMAR",
      party: "Apni Ekta Party",
      evm_votes: 5131,
      postal_votes: 8,
      total_votes: 5139,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 8,
      candidate: "HARBHAJAN SINGH",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 3417,
      postal_votes: 14,
      total_votes: 3431,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 9,
      candidate: "SUKHWINDER SINGH",
      party: "Independent",
      evm_votes: 3225,
      postal_votes: 0,
      total_votes: 3225,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 10,
      candidate: "BALWINDER SINGH SEKHON",
      party: "Independent",
      evm_votes: 2562,
      postal_votes: 10,
      total_votes: 2572,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 11,
      candidate: "BALWINDER SINGH",
      party: "Independent",
      evm_votes: 2284,
      postal_votes: 4,
      total_votes: 2288,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 12,
      candidate: "JASWANT SINGH",
      party: "Independent",
      evm_votes: 2222,
      postal_votes: 2,
      total_votes: 2224,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 13,
      candidate: "PAPPU KUMAR",
      party: "Independent",
      evm_votes: 1826,
      postal_votes: 1,
      total_votes: 1827,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 14,
      candidate: "KRISHAN DEV",
      party: "Punjab National Party",
      evm_votes: 1803,
      postal_votes: 12,
      total_votes: 1815,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 15,
      candidate: "AMANPREET SINGH",
      party: "Independent",
      evm_votes: 1686,
      postal_votes: 7,
      total_votes: 1693,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 16,
      candidate: "RANGI KHAN",
      party: "Aam Janta Party (India)",
      evm_votes: 1578,
      postal_votes: 3,
      total_votes: 1581,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 17,
      candidate: "JILE SINGH",
      party: "Bhartiya Jan Samman Party",
      evm_votes: 1546,
      postal_votes: 2,
      total_votes: 1548,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 18,
      candidate: "KAMAL KUMAR VERMA (BHASAUR)",
      party: "Adarsh Janta Party,",
      evm_votes: 1376,
      postal_votes: 0,
      total_votes: 1376,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 19,
      candidate: "CHANNVEER SINGH",
      party: "Independent",
      evm_votes: 1281,
      postal_votes: 2,
      total_votes: 1283,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 20,
      candidate: "JAGTAR SINGH",
      party: "Nationalist Justice Party",
      evm_votes: 1224,
      postal_votes: 1,
      total_votes: 1225,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 21,
      candidate: "VIJAY SYAL",
      party: "Independent",
      evm_votes: 1144,
      postal_votes: 1,
      total_votes: 1145,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 22,
      candidate: "HARPREET KAUR",
      party: "India Greens Party",
      evm_votes: 1095,
      postal_votes: 10,
      total_votes: 1105,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 23,
      candidate: "RAJA SINGH",
      party: "Independent",
      evm_votes: 1037,
      postal_votes: 3,
      total_votes: 1040,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 24,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3773,
      postal_votes: 47,
      total_votes: 3820,
      percentage_of_votes: 0.38,
    },
  ],
  total: {
    evm_votes: 1005403,
    postal_votes: 4262,
    total_votes: 1009665,
  },
};
export const s271 = {
  cs_name: "Rajmahal",
  cs_shortname: "rajmahal",
  cs_code: "s271",
  winner: "JMM",
  stats: [
    {
      serial_no: 1,
      candidate: "VIJAY KUMAR HANSDAK",
      party: "Jharkhand Mukti Morcha",
      evm_votes: 607293,
      postal_votes: 6078,
      total_votes: 613371,
      percentage_of_votes: 50.35,
    },
    {
      serial_no: 2,
      candidate: "TALA MARANDI",
      party: "Bharatiya Janata Party",
      evm_votes: 430544,
      postal_votes: 4563,
      total_votes: 435107,
      percentage_of_votes: 35.72,
    },
    {
      serial_no: 3,
      candidate: "LOBIN HEMBRAM",
      party: "Independent",
      evm_votes: 41592,
      postal_votes: 548,
      total_votes: 42140,
      percentage_of_votes: 3.46,
    },
    {
      serial_no: 4,
      candidate: "GOPIN SOREN",
      party: "Communist Party of India (Marxist)",
      evm_votes: 37191,
      postal_votes: 100,
      total_votes: 37291,
      percentage_of_votes: 3.06,
    },
    {
      serial_no: 5,
      candidate: "MAHESH PAHARIYA",
      party: "Independent",
      evm_votes: 14950,
      postal_votes: 28,
      total_votes: 14978,
      percentage_of_votes: 1.23,
    },
    {
      serial_no: 6,
      candidate: "AJIT MARANDI",
      party: "Rashtriya Jansambhavna Party",
      evm_votes: 11243,
      postal_votes: 8,
      total_votes: 11251,
      percentage_of_votes: 0.92,
    },
    {
      serial_no: 7,
      candidate: "SEBASTION HEMBROM",
      party: "Independent",
      evm_votes: 9492,
      postal_votes: 1,
      total_votes: 9493,
      percentage_of_votes: 0.78,
    },
    {
      serial_no: 8,
      candidate: "MARIYAM MARANDI",
      party: "Bahujan Samaj Party",
      evm_votes: 8225,
      postal_votes: 34,
      total_votes: 8259,
      percentage_of_votes: 0.68,
    },
    {
      serial_no: 9,
      candidate: "PAUL SOREN",
      party: "All India Majlis-E-Ittehadul Muslimeen",
      evm_votes: 7854,
      postal_votes: 48,
      total_votes: 7902,
      percentage_of_votes: 0.65,
    },
    {
      serial_no: 10,
      candidate: "DIPA TUDU",
      party: "Independent",
      evm_votes: 6847,
      postal_votes: 9,
      total_votes: 6856,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 11,
      candidate: "KHALIFA KISKU",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 4092,
      postal_votes: 15,
      total_votes: 4107,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 12,
      candidate: "VINOD KUMAR MANDAL",
      party: "Lokhit Adhikar Party",
      evm_votes: 3774,
      postal_votes: 35,
      total_votes: 3809,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 13,
      candidate: "MUNSHI KISKU",
      party: "Navyug Pragatisheel Morcha",
      evm_votes: 2777,
      postal_votes: 2,
      total_votes: 2779,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 14,
      candidate: "LILI HANSDA",
      party: "Samata Party",
      evm_votes: 2682,
      postal_votes: 7,
      total_votes: 2689,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 18119,
      postal_votes: 98,
      total_votes: 18217,
      percentage_of_votes: 1.5,
    },
  ],
  total: {
    evm_votes: 1206675,
    postal_votes: 11574,
    total_votes: 1218249,
  },
};
export const s272 = {
  cs_name: "Dumka",
  cs_shortname: "dumka",
  cs_code: "s272",
  winner: "JMM",
  stats: [
    {
      serial_no: 1,
      candidate: "NALIN SOREN",
      party: "Jharkhand Mukti Morcha",
      evm_votes: 542299,
      postal_votes: 5071,
      total_votes: 547370,
      percentage_of_votes: 46.23,
    },
    {
      serial_no: 2,
      candidate: "SITA MURMU",
      party: "Bharatiya Janata Party",
      evm_votes: 520714,
      postal_votes: 4129,
      total_votes: 524843,
      percentage_of_votes: 44.32,
    },
    {
      serial_no: 3,
      candidate: "MAHASHON MURMU",
      party: "Independent",
      evm_votes: 21371,
      postal_votes: 1,
      total_votes: 21372,
      percentage_of_votes: 1.8,
    },
    {
      serial_no: 4,
      candidate: "BABYLATA TUDU",
      party: "Independent",
      evm_votes: 19275,
      postal_votes: 85,
      total_votes: 19360,
      percentage_of_votes: 1.63,
    },
    {
      serial_no: 5,
      candidate: "RAJESH KISKU",
      party: "Communist Party of India",
      evm_votes: 11484,
      postal_votes: 22,
      total_votes: 11506,
      percentage_of_votes: 0.97,
    },
    {
      serial_no: 6,
      candidate: "DR. SRILAL KISKU",
      party: "Independent",
      evm_votes: 9414,
      postal_votes: 2,
      total_votes: 9416,
      percentage_of_votes: 0.8,
    },
    {
      serial_no: 7,
      candidate: "PARESH MARANDI",
      party: "Bahujan Samaj Party",
      evm_votes: 7713,
      postal_votes: 20,
      total_votes: 7733,
      percentage_of_votes: 0.65,
    },
    {
      serial_no: 8,
      candidate: "ANIL TUDU",
      party: "Lokhit Adhikar Party",
      evm_votes: 6811,
      postal_votes: 7,
      total_votes: 6818,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 9,
      candidate: "SUSHANTI HEMBROM",
      party: "Independent",
      evm_votes: 5150,
      postal_votes: 2,
      total_votes: 5152,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 10,
      candidate: "MANOJ HEMBROM",
      party: "Independent",
      evm_votes: 4814,
      postal_votes: 2,
      total_votes: 4816,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 11,
      candidate: "BIREN MOHLI",
      party: "Independent",
      evm_votes: 4416,
      postal_votes: 2,
      total_votes: 4418,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 12,
      candidate: "RAJU EMANUEL MURMU",
      party: "Ambedkarite Party of India",
      evm_votes: 3056,
      postal_votes: 4,
      total_votes: 3060,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 13,
      candidate: "KAMISHNAR MURMU",
      party: "Independent",
      evm_votes: 2509,
      postal_votes: 7,
      total_votes: 2516,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 14,
      candidate: "BAGHRAI SOREN",
      party: "Independent",
      evm_votes: 2255,
      postal_votes: 1,
      total_votes: 2256,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 15,
      candidate: "SANJAY TUDU",
      party: "Independent",
      evm_votes: 2171,
      postal_votes: 3,
      total_votes: 2174,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 16,
      candidate: "MUNNI HANSDA",
      party: "Samata Party",
      evm_votes: 1753,
      postal_votes: 5,
      total_votes: 1758,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 17,
      candidate: "NIRMAL SOREN",
      party: "Independent",
      evm_votes: 1707,
      postal_votes: 6,
      total_votes: 1713,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 18,
      candidate: "ALESH HANSDA",
      party: "Indian National Socialistic Action Forces",
      evm_votes: 1666,
      postal_votes: 8,
      total_votes: 1674,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 19,
      candidate: "JONATHAN MARDI",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 1650,
      postal_votes: 1,
      total_votes: 1651,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 20,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4453,
      postal_votes: 73,
      total_votes: 4526,
      percentage_of_votes: 0.38,
    },
  ],
  total: {
    evm_votes: 1174681,
    postal_votes: 9451,
    total_votes: 1184132,
  },
};
export const s2710 = {
  cs_name: "Singhbhum",
  cs_shortname: "singhbhum",
  cs_code: "s2710",
  winner: "JMM",
  stats: [
    {
      serial_no: 1,
      candidate: "JOBA MAJHI",
      party: "Jharkhand Mukti Morcha",
      evm_votes: 515989,
      postal_votes: 4175,
      total_votes: 520164,
      percentage_of_votes: 51.62,
    },
    {
      serial_no: 2,
      candidate: "GEETA KORA",
      party: "Bharatiya Janata Party",
      evm_votes: 349006,
      postal_votes: 2756,
      total_votes: 351762,
      percentage_of_votes: 34.91,
    },
    {
      serial_no: 3,
      candidate: "DAMODAR SINGH HANSDA",
      party: "Independent",
      evm_votes: 43838,
      postal_votes: 454,
      total_votes: 44292,
      percentage_of_votes: 4.4,
    },
    {
      serial_no: 4,
      candidate: "MADHAV CHANDRA KUNKAL",
      party: "Independent",
      evm_votes: 19729,
      postal_votes: 105,
      total_votes: 19834,
      percentage_of_votes: 1.97,
    },
    {
      serial_no: 5,
      candidate: "SANGRAM MARDI",
      party: "Independent",
      evm_votes: 16097,
      postal_votes: 14,
      total_votes: 16111,
      percentage_of_votes: 1.6,
    },
    {
      serial_no: 6,
      candidate: "PARDESHI LAL MUNDA",
      party: "Bahujan Samaj Party",
      evm_votes: 8708,
      postal_votes: 34,
      total_votes: 8742,
      percentage_of_votes: 0.87,
    },
    {
      serial_no: 7,
      candidate: "DURGA LAL MURMU",
      party: "Independent",
      evm_votes: 4912,
      postal_votes: 12,
      total_votes: 4924,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 8,
      candidate: "CHITRASEN SINKU",
      party: "Jharkhand Party",
      evm_votes: 3188,
      postal_votes: 22,
      total_votes: 3210,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 9,
      candidate: "BISHWA VIJAY MARDI",
      party: "Ambedkarite Party of India",
      evm_votes: 3067,
      postal_votes: 3,
      total_votes: 3070,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 10,
      candidate: "ASHA KUMARI RUNDA",
      party: "Independent",
      evm_votes: 2736,
      postal_votes: 18,
      total_votes: 2754,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 11,
      candidate: "KRISHNA MARDI",
      party: "Jharkhand Mukti Morcha (Ulgulan)",
      evm_votes: 2597,
      postal_votes: 18,
      total_votes: 2615,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 12,
      candidate: "SUDHA RANI BESRA",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 2398,
      postal_votes: 11,
      total_votes: 2409,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 13,
      candidate: "BIR SINGH DEOGAM",
      party: "Right to Recall Party",
      evm_votes: 1919,
      postal_votes: 8,
      total_votes: 1927,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 14,
      candidate: "PANMANI SINGH",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1793,
      postal_votes: 8,
      total_votes: 1801,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 23907,
      postal_votes: 75,
      total_votes: 23982,
      percentage_of_votes: 2.38,
    },
  ],
  total: {
    evm_votes: 999884,
    postal_votes: 7713,
    total_votes: 1007597,
  },
};
export const s016 = {
  cs_name: "Kakinada",
  cs_shortname: "kakinada",
  cs_code: "s016",
  winner: "JnP",
  stats: [
    {
      serial_no: 1,
      candidate: "TANGELLA UDAY SRINIVAS (TEA TIME UDAY)",
      party: "Janasena Party",
      evm_votes: 718971,
      postal_votes: 10728,
      total_votes: 729699,
      percentage_of_votes: 54.87,
    },
    {
      serial_no: 2,
      candidate: "CHALAMALASETTY SUNIL",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 495188,
      postal_votes: 5020,
      total_votes: 500208,
      percentage_of_votes: 37.62,
    },
    {
      serial_no: 3,
      candidate: "MALLIPUDI MANGAPATI PALLAM RAJU",
      party: "Indian National Congress",
      evm_votes: 20258,
      postal_votes: 851,
      total_votes: 21109,
      percentage_of_votes: 1.59,
    },
    {
      serial_no: 4,
      candidate: "UDAY",
      party: "Navarang Congress Party",
      evm_votes: 15578,
      postal_votes: 125,
      total_votes: 15703,
      percentage_of_votes: 1.18,
    },
    {
      serial_no: 5,
      candidate: "KANDREGULA NARASIMHAM",
      party: "Bahujan Samaj Party",
      evm_votes: 11916,
      postal_votes: 63,
      total_votes: 11979,
      percentage_of_votes: 0.9,
    },
    {
      serial_no: 6,
      candidate: "DR ANUSHA YADAV",
      party: "Bharatha Chaitanya Yuvajana Party",
      evm_votes: 9818,
      postal_votes: 36,
      total_votes: 9854,
      percentage_of_votes: 0.74,
    },
    {
      serial_no: 7,
      candidate: "TANGELLA SRINIVASU",
      party: "Jatiya Jana Sena Party",
      evm_votes: 6516,
      postal_votes: 43,
      total_votes: 6559,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 8,
      candidate: "YEDIDA VARA PRASAD KUMAR",
      party: "Republican Party of India (A)",
      evm_votes: 3924,
      postal_votes: 3,
      total_votes: 3927,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 9,
      candidate: "BUGATHA BANGARRAO",
      party: "Communist Party of India (Marxist-Leninist) (Liberation)",
      evm_votes: 2816,
      postal_votes: 7,
      total_votes: 2823,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 10,
      candidate: "SUDHA VEERA GANESH",
      party: "Independent",
      evm_votes: 2645,
      postal_votes: 2,
      total_votes: 2647,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 11,
      candidate: "BHAVANI SANKAR PRASAD MERLA",
      party: "Independent",
      evm_votes: 1963,
      postal_votes: 6,
      total_votes: 1969,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 12,
      candidate: "YENUGUPALLI KRISHNA",
      party: "Jaibhim Rao Bharat Party",
      evm_votes: 1762,
      postal_votes: 18,
      total_votes: 1780,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 13,
      candidate: "BODDETI APPARAO",
      party: "Independent",
      evm_votes: 1687,
      postal_votes: 0,
      total_votes: 1687,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 14,
      candidate: "NEELAKANTAM DONAM",
      party: "Independent",
      evm_votes: 1401,
      postal_votes: 11,
      total_votes: 1412,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 15,
      candidate: "PASUPULETI S.V.S.BHUSHANAM",
      party: "Independent",
      evm_votes: 1222,
      postal_votes: 5,
      total_votes: 1227,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 17105,
      postal_votes: 115,
      total_votes: 17220,
      percentage_of_votes: 1.29,
    },
  ],
  total: {
    evm_votes: 1312770,
    postal_votes: 17033,
    total_votes: 1329803,
  },
};
export const s0111 = {
  cs_name: "Machilipatnam",
  cs_shortname: "machilipatnam",
  cs_code: "s0111",
  winner: "JnP",
  stats: [
    {
      serial_no: 1,
      candidate: "BALASHOWRY VALLABHANENI",
      party: "Janasena Party",
      evm_votes: 712149,
      postal_votes: 12290,
      total_votes: 724439,
      percentage_of_votes: 55.22,
    },
    {
      serial_no: 2,
      candidate: "SIMHADRI CHANDRA SEKHAR RAO",
      party: "Yuvajana Sramika Rythu Congress Party",
      evm_votes: 495211,
      postal_votes: 6049,
      total_votes: 501260,
      percentage_of_votes: 38.21,
    },
    {
      serial_no: 3,
      candidate: "GOLLU KRISHNA",
      party: "Indian National Congress",
      evm_votes: 31130,
      postal_votes: 695,
      total_votes: 31825,
      percentage_of_votes: 2.43,
    },
    {
      serial_no: 4,
      candidate: "BAAL SHOURI CHODABATHINA",
      party: "Jatiya Jana Sena Party",
      evm_votes: 10518,
      postal_votes: 623,
      total_votes: 11141,
      percentage_of_votes: 0.85,
    },
    {
      serial_no: 5,
      candidate: "DEVAMANI DEVARAPALLI",
      party: "Bahujan Samaj Party",
      evm_votes: 9911,
      postal_votes: 117,
      total_votes: 10028,
      percentage_of_votes: 0.76,
    },
    {
      serial_no: 6,
      candidate: "KOMMARAJU SIVA NARASIMHA RAO",
      party: "All India Forward Bloc",
      evm_votes: 5245,
      postal_votes: 9,
      total_votes: 5254,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 7,
      candidate: "VALLABHANENI NAGA PAVAN KUMAR",
      party: "Independent",
      evm_votes: 5206,
      postal_votes: 14,
      total_votes: 5220,
      percentage_of_votes: 0.4,
    },
    {
      serial_no: 8,
      candidate: "MARADANI K R SWAMY",
      party: "Jai Maha Bharath Party",
      evm_votes: 2206,
      postal_votes: 8,
      total_votes: 2214,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 9,
      candidate: "GANDHI DHANEKULA",
      party: "Independent",
      evm_votes: 1989,
      postal_votes: 2,
      total_votes: 1991,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "PAMULA BALA SOWRYAMMA",
      party: "Independent",
      evm_votes: 1576,
      postal_votes: 6,
      total_votes: 1582,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 11,
      candidate: "ANUSHA YERUVA",
      party: "Independent",
      evm_votes: 1233,
      postal_votes: 2,
      total_votes: 1235,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 12,
      candidate: "GUDAVALLI VENKATA KEDARESWARA RAO",
      party: "Independent",
      evm_votes: 1102,
      postal_votes: 9,
      total_votes: 1111,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "JOGI VEERANJANEYULU",
      party: "Independent",
      evm_votes: 1020,
      postal_votes: 2,
      total_votes: 1022,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "DAMODARA RAO GUDAVALLI",
      party: "Independent",
      evm_votes: 928,
      postal_votes: 2,
      total_votes: 930,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 15,
      candidate: "APPARAO ENNETI",
      party: "Independent",
      evm_votes: 625,
      postal_votes: 1,
      total_votes: 626,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 16,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 12008,
      postal_votes: 118,
      total_votes: 12126,
      percentage_of_votes: 0.92,
    },
  ],
  total: {
    evm_votes: 1292057,
    postal_votes: 19947,
    total_votes: 1312004,
  },
};
export const s0432 = {
  cs_name: "Arrah",
  cs_shortname: "arrah",
  cs_code: "s0432",
  winner: "CPI(ML)(L)",
  stats: [
    {
      serial_no: 1,
      candidate: "SUDAMA PRASAD",
      party: "Communist Party of India (Marxist-Leninist) (Liberation)",
      evm_votes: 526564,
      postal_votes: 2818,
      total_votes: 529382,
      percentage_of_votes: 48.28,
    },
    {
      serial_no: 2,
      candidate: "R. K. SINGH",
      party: "Bharatiya Janata Party",
      evm_votes: 464736,
      postal_votes: 4838,
      total_votes: 469574,
      percentage_of_votes: 42.82,
    },
    {
      serial_no: 3,
      candidate: "VIRENDRA KUMAR SINGH",
      party: "Independent",
      evm_votes: 23630,
      postal_votes: 5,
      total_votes: 23635,
      percentage_of_votes: 2.16,
    },
    {
      serial_no: 4,
      candidate: "SHIV DAS SINGH",
      party: "Independent",
      evm_votes: 13373,
      postal_votes: 6,
      total_votes: 13379,
      percentage_of_votes: 1.22,
    },
    {
      serial_no: 5,
      candidate: "LAL BADSAH SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 10631,
      postal_votes: 195,
      total_votes: 10826,
      percentage_of_votes: 0.99,
    },
    {
      serial_no: 6,
      candidate: "KRISHNA PASWAN",
      party: "Bhartiya Kranti Vir Party",
      evm_votes: 8709,
      postal_votes: 40,
      total_votes: 8749,
      percentage_of_votes: 0.8,
    },
    {
      serial_no: 7,
      candidate: "RANDHIR LAL",
      party: "Independent",
      evm_votes: 5765,
      postal_votes: 1,
      total_votes: 5766,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 8,
      candidate: "NAVNIT KUMAR",
      party: "Independent",
      evm_votes: 4764,
      postal_votes: 9,
      total_votes: 4773,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 9,
      candidate: "RAM SARE SINGH",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 2531,
      postal_votes: 10,
      total_votes: 2541,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 10,
      candidate: "DHARMATMA SHARMA",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 2449,
      postal_votes: 18,
      total_votes: 2467,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 11,
      candidate: "RAMJEE SINGH",
      party: "Voters Party International",
      evm_votes: 2435,
      postal_votes: 5,
      total_votes: 2440,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 12,
      candidate: "ASHOK TIWARY",
      party: "Independent",
      evm_votes: 2263,
      postal_votes: 32,
      total_votes: 2295,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 13,
      candidate: "MANMOHAN SINGH",
      party: "Rashtrawadi Janlok Party (Satya)",
      evm_votes: 2055,
      postal_votes: 42,
      total_votes: 2097,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 14,
      candidate: "SUMITRA DEVI",
      party: "Jagrook Janta Party",
      evm_votes: 1640,
      postal_votes: 34,
      total_votes: 1674,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 16864,
      postal_votes: 99,
      total_votes: 16963,
      percentage_of_votes: 1.55,
    },
  ],
  total: {
    evm_votes: 1088409,
    postal_votes: 8152,
    total_votes: 1096561,
  },
};
export const s0435 = {
  cs_name: "Karakat",
  cs_shortname: "karakat",
  cs_code: "s0435",
  winner: "CPI(ML)(L)",
  stats: [
    {
      serial_no: 1,
      candidate: "RAJA RAM SINGH",
      party: "Communist Party of India (Marxist-Leninist) (Liberation)",
      evm_votes: 378922,
      postal_votes: 1659,
      total_votes: 380581,
      percentage_of_votes: 36.89,
    },
    {
      serial_no: 2,
      candidate: "PAWAN SINGH",
      party: "Independent",
      evm_votes: 273546,
      postal_votes: 1177,
      total_votes: 274723,
      percentage_of_votes: 26.63,
    },
    {
      serial_no: 3,
      candidate: "UPENDRA KUSHWAHA",
      party: "Rashtriya Lok Morcha",
      evm_votes: 253088,
      postal_votes: 788,
      total_votes: 253876,
      percentage_of_votes: 24.61,
    },
    {
      serial_no: 4,
      candidate: "DHEERAJ KUMAR SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 23549,
      postal_votes: 108,
      total_votes: 23657,
      percentage_of_votes: 2.29,
    },
    {
      serial_no: 5,
      candidate: "RAJA RAM SINGH",
      party: "Independent",
      evm_votes: 21357,
      postal_votes: 26,
      total_votes: 21383,
      percentage_of_votes: 2.07,
    },
    {
      serial_no: 6,
      candidate: "PRIYANKA PRASAD CHOUDHARY",
      party: "All India Majlis-E-Ittehadul Muslimeen",
      evm_votes: 10983,
      postal_votes: 23,
      total_votes: 11006,
      percentage_of_votes: 1.07,
    },
    {
      serial_no: 7,
      candidate: "AJEET KUMAR SINGH",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 9634,
      postal_votes: 19,
      total_votes: 9653,
      percentage_of_votes: 0.94,
    },
    {
      serial_no: 8,
      candidate: "VIKASH VINAYAK",
      party: "Jan Janwadi Party",
      evm_votes: 7661,
      postal_votes: 3,
      total_votes: 7664,
      percentage_of_votes: 0.74,
    },
    {
      serial_no: 9,
      candidate: "INDRA RAJ ROUSHAN",
      party: "Independent",
      evm_votes: 6315,
      postal_votes: 9,
      total_votes: 6324,
      percentage_of_votes: 0.61,
    },
    {
      serial_no: 10,
      candidate: "PRADEEP KUMAR JOSHI",
      party: "Rashtra Sewa Dal",
      evm_votes: 5891,
      postal_votes: 28,
      total_votes: 5919,
      percentage_of_votes: 0.57,
    },
    {
      serial_no: 11,
      candidate: "AVADHESH PASAWAN",
      party: "Bharatiya Aam Awam Party",
      evm_votes: 5527,
      postal_votes: 58,
      total_votes: 5585,
      percentage_of_votes: 0.54,
    },
    {
      serial_no: 12,
      candidate: "RAJESHWAR PASWAN",
      party: "Akhil Hind Forward Bloc (Krantikari)",
      evm_votes: 5003,
      postal_votes: 3,
      total_votes: 5006,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 13,
      candidate: "PRAYAG PASWAN",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 4641,
      postal_votes: 5,
      total_votes: 4646,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 21548,
      postal_votes: 47,
      total_votes: 21595,
      percentage_of_votes: 2.09,
    },
  ],
  total: {
    evm_votes: 1027665,
    postal_votes: 3953,
    total_votes: 1031618,
  },
};
export const s1020 = {
  cs_name: "Mandya",
  cs_shortname: "mandya",
  cs_code: "s1020",
  winner: "JD(S)",
  stats: [
    {
      serial_no: 1,
      candidate: "H.D. KUMARASWAMY",
      party: "Janata Dal (Secular)",
      evm_votes: 847482,
      postal_votes: 4399,
      total_votes: 851881,
      percentage_of_votes: 58.34,
    },
    {
      serial_no: 2,
      candidate: "VENKATARAMANE GOWDA (STAR CHANDRU)",
      party: "Indian National Congress",
      evm_votes: 564778,
      postal_votes: 2483,
      total_votes: 567261,
      percentage_of_votes: 38.85,
    },
    {
      serial_no: 3,
      candidate: "CHANDAN GOWDA. K",
      party: "Independent",
      evm_votes: 12310,
      postal_votes: 84,
      total_votes: 12394,
      percentage_of_votes: 0.85,
    },
    {
      serial_no: 4,
      candidate: "SHIVASHANKARA. S",
      party: "Bahujan Samaj Party",
      evm_votes: 6903,
      postal_votes: 61,
      total_votes: 6964,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 5,
      candidate: "RAMAIAH. D",
      party: "Independent",
      evm_votes: 3796,
      postal_votes: 14,
      total_votes: 3810,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 6,
      candidate: "RANJITHA. N",
      party: "Independent",
      evm_votes: 2897,
      postal_votes: 12,
      total_votes: 2909,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 7,
      candidate: "CHANDRASHEKARA. K.R (CHANDRU KEELARA)",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 1465,
      postal_votes: 11,
      total_votes: 1476,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 8,
      candidate: "BUDAYYA. B.P",
      party: "Karunaadu Party",
      evm_votes: 1358,
      postal_votes: 12,
      total_votes: 1370,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 9,
      candidate: "LOKESHA. S (AARYA LOKESH)",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 1151,
      postal_votes: 12,
      total_votes: 1163,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 10,
      candidate: "BEERESH. C.T (SAINIKA BEERESH)",
      party: "Independent",
      evm_votes: 919,
      postal_votes: 12,
      total_votes: 931,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 11,
      candidate: "N.BASAVARAJU",
      party: "Independent",
      evm_votes: 802,
      postal_votes: 41,
      total_votes: 843,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "H.D. REVANNA",
      party: "Purvanchal Mahapanchayat",
      evm_votes: 584,
      postal_votes: 6,
      total_votes: 590,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 13,
      candidate: "CHANNAMAYIGOWDA",
      party: "Independent",
      evm_votes: 496,
      postal_votes: 1,
      total_votes: 497,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 14,
      candidate: "S.ARVIND",
      party: "Independent",
      evm_votes: 440,
      postal_votes: 2,
      total_votes: 442,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 7706,
      postal_votes: 30,
      total_votes: 7736,
      percentage_of_votes: 0.53,
    },
  ],
  total: {
    evm_votes: 1453087,
    postal_votes: 7180,
    total_votes: 1460267,
  },
};
export const s1028 = {
  cs_name: "Kolar",
  cs_shortname: "kolar",
  cs_code: "s1028",
  winner: "JD(S)",
  stats: [
    {
      serial_no: 1,
      candidate: "M. MALLESH BABU",
      party: "Janata Dal (Secular)",
      evm_votes: 689185,
      postal_votes: 2296,
      total_votes: 691481,
      percentage_of_votes: 51.02,
    },
    {
      serial_no: 2,
      candidate: "K V GOWTHAM",
      party: "Indian National Congress",
      evm_votes: 618700,
      postal_votes: 1393,
      total_votes: 620093,
      percentage_of_votes: 45.76,
    },
    {
      serial_no: 3,
      candidate: "SUMAN H N",
      party: "Independent",
      evm_votes: 6484,
      postal_votes: 3,
      total_votes: 6487,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 4,
      candidate: "HOLUR SRINIVASA",
      party: "Independent",
      evm_votes: 6159,
      postal_votes: 4,
      total_votes: 6163,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 5,
      candidate: "S B SURESHA",
      party: "Bahujan Samaj Party",
      evm_votes: 4688,
      postal_votes: 44,
      total_votes: 4732,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 6,
      candidate: "K R DEVARAJA",
      party: "Delhi Janta Party",
      evm_votes: 4588,
      postal_votes: 9,
      total_votes: 4597,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 7,
      candidate: "DR M. VENKATASWAMY",
      party: "Independent",
      evm_votes: 3336,
      postal_votes: 18,
      total_votes: 3354,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 8,
      candidate: "R RANJITHKUMAR",
      party: "Independent",
      evm_votes: 2116,
      postal_votes: 2,
      total_votes: 2118,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "S N NARAYANASWAMY V",
      party: "Independent",
      evm_votes: 1971,
      postal_votes: 14,
      total_votes: 1985,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "D GOPALAKRISHNA",
      party: "Socialist Party (India)",
      evm_votes: 1661,
      postal_votes: 8,
      total_votes: 1669,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 11,
      candidate: "DEVARAJA A ALUSURUDINNE",
      party: "Uttama Prajaakeeya Party",
      evm_votes: 1343,
      postal_votes: 11,
      total_votes: 1354,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 12,
      candidate: "M S BADARINARAYANA",
      party: "Independent",
      evm_votes: 1104,
      postal_votes: 12,
      total_votes: 1116,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "MAHESH A V",
      party: "Karnataka Rashtra Samithi",
      evm_votes: 1035,
      postal_votes: 30,
      total_votes: 1065,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "THIMMARAYAPPA",
      party: "Republican Party of India (Karnataka)",
      evm_votes: 856,
      postal_votes: 8,
      total_votes: 864,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 15,
      candidate: "R RAJENDRA",
      party: "Independent",
      evm_votes: 750,
      postal_votes: 1,
      total_votes: 751,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 16,
      candidate: "M MUNIGANGAPPA",
      party: "Independent",
      evm_votes: 595,
      postal_votes: 9,
      total_votes: 604,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 17,
      candidate: "M C HALLI VENU",
      party: "Viduthalai Chiruthaigal Katchi",
      evm_votes: 514,
      postal_votes: 7,
      total_votes: 521,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 18,
      candidate: "KRISHNAIAH N",
      party: "Independent",
      evm_votes: 438,
      postal_votes: 5,
      total_votes: 443,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 19,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5798,
      postal_votes: 33,
      total_votes: 5831,
      percentage_of_votes: 0.43,
    },
  ],
  total: {
    evm_votes: 1351321,
    postal_votes: 3907,
    total_votes: 1355228,
  },
};
export const s2213 = {
  cs_name: "VILUPPURAM",
  cs_shortname: "viluppuram",
  cs_code: "s2213",
  winner: "VCK",
  stats: [
    {
      serial_no: 1,
      candidate: "RAVIKUMAR. D",
      party: "Viduthalai Chiruthaigal Katchi",
      evm_votes: 474230,
      postal_votes: 2803,
      total_votes: 477033,
      percentage_of_votes: 41.39,
    },
    {
      serial_no: 2,
      candidate: "BHAGYARAJ. J",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 404503,
      postal_votes: 1827,
      total_votes: 406330,
      percentage_of_votes: 35.25,
    },
    {
      serial_no: 3,
      candidate: "MURALI SANKAR. S",
      party: "Pattali Makkal Katchi",
      evm_votes: 180020,
      postal_votes: 1862,
      total_votes: 181882,
      percentage_of_votes: 15.78,
    },
    {
      serial_no: 4,
      candidate: "KALANCHIYAM. M",
      party: "Naam Tamilar Katchi",
      evm_votes: 56766,
      postal_votes: 476,
      total_votes: 57242,
      percentage_of_votes: 4.97,
    },
    {
      serial_no: 5,
      candidate: "KARUNCHIRUTHAI KALIYAMURTHY. K",
      party: "Bahujan Samaj Party",
      evm_votes: 3883,
      postal_votes: 37,
      total_votes: 3920,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 6,
      candidate: "ARUMUGAM. M",
      party: "United Republican Party of India",
      evm_votes: 3487,
      postal_votes: 17,
      total_votes: 3504,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 7,
      candidate: "DHARMA. K",
      party: "Independent",
      evm_votes: 2375,
      postal_votes: 7,
      total_votes: 2382,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 8,
      candidate: "VIVEKANANDHAN. E",
      party: "Independent",
      evm_votes: 1945,
      postal_votes: 8,
      total_votes: 1953,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 9,
      candidate: "ARASAN. K",
      party: "Independent",
      evm_votes: 1835,
      postal_votes: 7,
      total_votes: 1842,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 10,
      candidate: "VIGNESVARAN. R",
      party: "Independent",
      evm_votes: 1643,
      postal_votes: 13,
      total_votes: 1656,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "SURESH. S",
      party: "Independent",
      evm_votes: 1374,
      postal_votes: 23,
      total_votes: 1397,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "PERIYAN. A",
      party: "Independent",
      evm_votes: 1041,
      postal_votes: 8,
      total_votes: 1049,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "GUNASEKARAN. K",
      party: "Independent",
      evm_votes: 960,
      postal_votes: 3,
      total_votes: 963,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 14,
      candidate: "VIJAYAN. A",
      party: "Independent",
      evm_votes: 859,
      postal_votes: 2,
      total_votes: 861,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 15,
      candidate: "NAGARAJ. A",
      party: "Independent",
      evm_votes: 669,
      postal_votes: 5,
      total_votes: 674,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 16,
      candidate: "SURESH. K",
      party: "Independent",
      evm_votes: 592,
      postal_votes: 6,
      total_votes: 598,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 17,
      candidate: "SATHIYARAJ. N",
      party: "Independent",
      evm_votes: 412,
      postal_votes: 3,
      total_votes: 415,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 18,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8874,
      postal_votes: 92,
      total_votes: 8966,
      percentage_of_votes: 0.78,
    },
  ],
  total: {
    evm_votes: 1145468,
    postal_votes: 7199,
    total_votes: 1152667,
  },
};
export const s2227 = {
  cs_name: "CHIDAMBARAM",
  cs_shortname: "chidambaram",
  cs_code: "s2227",
  winner: "VCK",
  stats: [
    {
      serial_no: 1,
      candidate: "THIRUMAAVALAVAN THOL",
      party: "Viduthalai Chiruthaigal Katchi",
      evm_votes: 501851,
      postal_votes: 3233,
      total_votes: 505084,
      percentage_of_votes: 43.28,
    },
    {
      serial_no: 2,
      candidate: "CHANDRAHASAN M",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 399502,
      postal_votes: 2028,
      total_votes: 401530,
      percentage_of_votes: 34.4,
    },
    {
      serial_no: 3,
      candidate: "KARTHIYAYINI P",
      party: "Bharatiya Janata Party",
      evm_votes: 166114,
      postal_votes: 2379,
      total_votes: 168493,
      percentage_of_votes: 14.44,
    },
    {
      serial_no: 4,
      candidate: "JANCIRANI R",
      party: "Naam Tamilar Katchi",
      evm_votes: 65116,
      postal_votes: 473,
      total_votes: 65589,
      percentage_of_votes: 5.62,
    },
    {
      serial_no: 5,
      candidate: "NEELAMEGAM K",
      party: "Bahujan Samaj Party",
      evm_votes: 3177,
      postal_votes: 26,
      total_votes: 3203,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 6,
      candidate: "TAMILVENDHAN P",
      party: "Independent",
      evm_votes: 3043,
      postal_votes: 19,
      total_votes: 3062,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 7,
      candidate: "PERUMAL S",
      party: "Independent",
      evm_votes: 2231,
      postal_votes: 8,
      total_votes: 2239,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 8,
      candidate: "VETTRIVEL G",
      party: "Independent",
      evm_votes: 2039,
      postal_votes: 2,
      total_votes: 2041,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 9,
      candidate: "ARCHUNAN M A T",
      party: "Independent",
      evm_votes: 1833,
      postal_votes: 3,
      total_votes: 1836,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 10,
      candidate: "DHAMODHARAN S",
      party: "Naadaalum Makkal Katchi",
      evm_votes: 1628,
      postal_votes: 14,
      total_votes: 1642,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "RATHA G",
      party: "Independent",
      evm_votes: 1273,
      postal_votes: 7,
      total_votes: 1280,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 12,
      candidate: "RAJAMANICKAM C",
      party: "Independent",
      evm_votes: 1068,
      postal_votes: 19,
      total_votes: 1087,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 13,
      candidate: "CHINNADURAI A",
      party: "Independent",
      evm_votes: 628,
      postal_votes: 10,
      total_votes: 638,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 14,
      candidate: "ELAVARASAN C",
      party: "Independent",
      evm_votes: 584,
      postal_votes: 2,
      total_votes: 586,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8631,
      postal_votes: 130,
      total_votes: 8761,
      percentage_of_votes: 0.75,
    },
  ],
  total: {
    evm_votes: 1158718,
    postal_votes: 8353,
    total_votes: 1167071,
  },
};
export const s2218 = {
  cs_name: "TIRUPPUR",
  cs_shortname: "tiruppur",
  cs_code: "s2218",
  winner: "CPI",
  stats: [
    {
      serial_no: 1,
      candidate: "SUBBARAYAN, K.",
      party: "Communist Party of India",
      evm_votes: 470195,
      postal_votes: 2544,
      total_votes: 472739,
      percentage_of_votes: 41.38,
    },
    {
      serial_no: 2,
      candidate: "ARUNACHALAM, P.",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 345326,
      postal_votes: 1485,
      total_votes: 346811,
      percentage_of_votes: 30.35,
    },
    {
      serial_no: 3,
      candidate: "MURUGANANDAM, A.P.",
      party: "Bharatiya Janata Party",
      evm_votes: 184066,
      postal_votes: 1256,
      total_votes: 185322,
      percentage_of_votes: 16.22,
    },
    {
      serial_no: 4,
      candidate: "SEETHALAKSHMI, M.K.",
      party: "Naam Tamilar Katchi",
      evm_votes: 95250,
      postal_votes: 476,
      total_votes: 95726,
      percentage_of_votes: 8.38,
    },
    {
      serial_no: 5,
      candidate: "VELUSAMY, A.",
      party: "Independent",
      evm_votes: 7089,
      postal_votes: 36,
      total_votes: 7125,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 6,
      candidate: "PALANI, V.",
      party: "Bahujan Samaj Party",
      evm_votes: 6013,
      postal_votes: 41,
      total_votes: 6054,
      percentage_of_votes: 0.53,
    },
    {
      serial_no: 7,
      candidate: "SATHISHKUMAR, N.",
      party: "Independent",
      evm_votes: 2315,
      postal_votes: 21,
      total_votes: 2336,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 8,
      candidate: "JANARTHANAM, P.",
      party: "Tamilaga Makkal Thannurimai Katchi",
      evm_votes: 1861,
      postal_votes: 7,
      total_votes: 1868,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 9,
      candidate: "KANNAN, M.",
      party: "Independent",
      evm_votes: 1752,
      postal_votes: 13,
      total_votes: 1765,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 10,
      candidate: "SENGUTTUVAN, M.R.",
      party: "Independent",
      evm_votes: 1560,
      postal_votes: 7,
      total_votes: 1567,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 11,
      candidate: "SUBRAMANI, N.",
      party: "Independent",
      evm_votes: 1341,
      postal_votes: 9,
      total_votes: 1350,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 12,
      candidate: "MALARVIZHI, G.",
      party: "Rashtriya Samaj Paksha",
      evm_votes: 1311,
      postal_votes: 14,
      total_votes: 1325,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 13,
      candidate: "KARTHIKEYAN, P.",
      party: "Independent",
      evm_votes: 810,
      postal_votes: 14,
      total_votes: 824,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 17554,
      postal_votes: 183,
      total_votes: 17737,
      percentage_of_votes: 1.55,
    },
  ],
  total: {
    evm_votes: 1136443,
    postal_votes: 6106,
    total_votes: 1142549,
  },
};
export const s2229 = {
  cs_name: "NAGAPATTINAM",
  cs_shortname: "nagapattinam",
  cs_code: "s2229",
  winner: "CPI",
  stats: [
    {
      serial_no: 1,
      candidate: "SELVARAJ V",
      party: "Communist Party of India",
      evm_votes: 462759,
      postal_votes: 2285,
      total_votes: 465044,
      percentage_of_votes: 47.79,
    },
    {
      serial_no: 2,
      candidate: "DR SURSITH SANKAR G",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 254676,
      postal_votes: 1411,
      total_votes: 256087,
      percentage_of_votes: 26.32,
    },
    {
      serial_no: 3,
      candidate: "KARTHIKA M",
      party: "Naam Tamilar Katchi",
      evm_votes: 130846,
      postal_votes: 448,
      total_votes: 131294,
      percentage_of_votes: 13.49,
    },
    {
      serial_no: 4,
      candidate: "RAMESHGOVIND S G M",
      party: "Bharatiya Janata Party",
      evm_votes: 101535,
      postal_votes: 638,
      total_votes: 102173,
      percentage_of_votes: 10.5,
    },
    {
      serial_no: 5,
      candidate: "VIJAYARAGAVAN N",
      party: "Independent",
      evm_votes: 3072,
      postal_votes: 30,
      total_votes: 3102,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 6,
      candidate: "SUBRAMANIAN K",
      party: "Independent",
      evm_votes: 2434,
      postal_votes: 33,
      total_votes: 2467,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 7,
      candidate: "JAGADEESH J BE",
      party: "Bahujan Samaj Party",
      evm_votes: 1789,
      postal_votes: 17,
      total_votes: 1806,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 8,
      candidate: "BOOMINATHAN B",
      party: "Desiya Makkal Sakthi Katchi",
      evm_votes: 1048,
      postal_votes: 2,
      total_votes: 1050,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 9,
      candidate: "PREM S",
      party: "Independent",
      evm_votes: 1047,
      postal_votes: 23,
      total_votes: 1070,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8843,
      postal_votes: 75,
      total_votes: 8918,
      percentage_of_votes: 0.92,
    },
  ],
  total: {
    evm_votes: 968049,
    postal_votes: 4962,
    total_votes: 973011,
  },
};
export const s244 = {
  cs_name: "Bijnor",
  cs_shortname: "bijnor",
  cs_code: "s244",
  winner: "RLD",
  stats: [
    {
      serial_no: 1,
      candidate: "CHANDAN CHAUHAN",
      party: "Rashtriya Lok Dal",
      evm_votes: 402604,
      postal_votes: 1889,
      total_votes: 404493,
      percentage_of_votes: 39.48,
    },
    {
      serial_no: 2,
      candidate: "DEEPAK",
      party: "Samajwadi Party",
      evm_votes: 366226,
      postal_votes: 759,
      total_votes: 366985,
      percentage_of_votes: 35.82,
    },
    {
      serial_no: 3,
      candidate: "VIJENDER SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 218294,
      postal_votes: 692,
      total_votes: 218986,
      percentage_of_votes: 21.38,
    },
    {
      serial_no: 4,
      candidate: "ABDUL BARI",
      party: "Jai Samta Party",
      evm_votes: 8046,
      postal_votes: 26,
      total_votes: 8072,
      percentage_of_votes: 0.79,
    },
    {
      serial_no: 5,
      candidate: "DEEPAK KUMAR",
      party: "Independent",
      evm_votes: 7284,
      postal_votes: 34,
      total_votes: 7318,
      percentage_of_votes: 0.71,
    },
    {
      serial_no: 6,
      candidate: "ZAHEER",
      party: "Independent",
      evm_votes: 2780,
      postal_votes: 8,
      total_votes: 2788,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 7,
      candidate: "RAJAPAL",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 2361,
      postal_votes: 12,
      total_votes: 2373,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 8,
      candidate: "MOHD. SHAHJAD",
      party: "Independent",
      evm_votes: 2342,
      postal_votes: 5,
      total_votes: 2347,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 9,
      candidate: "FARMAN",
      party: "Majloom Samaj Party",
      evm_votes: 2334,
      postal_votes: 9,
      total_votes: 2343,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 10,
      candidate: "CHANDAN SINGH",
      party: "Independent",
      evm_votes: 2132,
      postal_votes: 44,
      total_votes: 2176,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 11,
      candidate: "RAMDHAN SINGH",
      party: "Majdoor Kisan Union Party",
      evm_votes: 2088,
      postal_votes: 17,
      total_votes: 2105,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 12,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4386,
      postal_votes: 60,
      total_votes: 4446,
      percentage_of_votes: 0.43,
    },
  ],
  total: {
    evm_votes: 1020877,
    postal_votes: 3555,
    total_votes: 1024432,
  },
};
export const s2411 = {
  cs_name: "Baghpat",
  cs_shortname: "baghpat",
  cs_code: "s2411",
  winner: "RLD",
  stats: [
    {
      serial_no: 1,
      candidate: "DR RAJKUMAR SANGWAN",
      party: "Rashtriya Lok Dal",
      evm_votes: 485765,
      postal_votes: 3202,
      total_votes: 488967,
      percentage_of_votes: 52.36,
    },
    {
      serial_no: 2,
      candidate: "AMARPAL",
      party: "Samajwadi Party",
      evm_votes: 328487,
      postal_votes: 1021,
      total_votes: 329508,
      percentage_of_votes: 35.29,
    },
    {
      serial_no: 3,
      candidate: "PRAVEEN BANSAL",
      party: "Bahujan Samaj Party",
      evm_votes: 91740,
      postal_votes: 526,
      total_votes: 92266,
      percentage_of_votes: 9.88,
    },
    {
      serial_no: 4,
      candidate: "MUKESH KUMAR SHARMA",
      party: "Azad Adhikar Sena",
      evm_votes: 5441,
      postal_votes: 82,
      total_votes: 5523,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 5,
      candidate: "MAHENDER SINGH",
      party: "Sarvjan Samta Party",
      evm_votes: 4957,
      postal_votes: 63,
      total_votes: 5020,
      percentage_of_votes: 0.54,
    },
    {
      serial_no: 6,
      candidate: "SUKHVIR SINGH",
      party: "Swatantra Jantaraj Party",
      evm_votes: 4056,
      postal_votes: 43,
      total_votes: 4099,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 7,
      candidate: "RUBY KASHYAP",
      party: "Sarvjan Lok Shakti Party",
      evm_votes: 3202,
      postal_votes: 78,
      total_votes: 3280,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4980,
      postal_votes: 130,
      total_votes: 5110,
      percentage_of_votes: 0.55,
    },
  ],
  total: {
    evm_votes: 928628,
    postal_votes: 5145,
    total_votes: 933773,
  },
};
export const u082 = {
  cs_name: "SRINAGAR",
  cs_shortname: "srinagar",
  cs_code: "u082",
  winner: "JKN",
  stats: [
    {
      serial_no: 1,
      candidate: "AGA SYED RUHULLAH MEHDI",
      party: "Jammu & Kashmir National Conference",
      evm_votes: 355294,
      postal_votes: 1572,
      total_votes: 356866,
      percentage_of_votes: 52.85,
    },
    {
      serial_no: 2,
      candidate: "WAHEED UR REHMAN PARA",
      party: "Jammu & Kashmir Peoples Democratic Party",
      evm_votes: 167666,
      postal_votes: 784,
      total_votes: 168450,
      percentage_of_votes: 24.95,
    },
    {
      serial_no: 3,
      candidate: "MOHAMMAD ASHRAF MIR",
      party: "Jammu and Kashmir Apni Party",
      evm_votes: 65494,
      postal_votes: 460,
      total_votes: 65954,
      percentage_of_votes: 9.77,
    },
    {
      serial_no: 4,
      candidate: "AMIR AHMAD BHAT",
      party: "Democratic Progressive Azad Party",
      evm_votes: 15061,
      postal_votes: 43,
      total_votes: 15104,
      percentage_of_votes: 2.24,
    },
    {
      serial_no: 5,
      candidate: "SHEEBAN ASHAI",
      party: "Independent",
      evm_votes: 13063,
      postal_votes: 8,
      total_votes: 13071,
      percentage_of_votes: 1.94,
    },
    {
      serial_no: 6,
      candidate: "SAYIM MUSTAFA",
      party: "Independent",
      evm_votes: 8831,
      postal_votes: 49,
      total_votes: 8880,
      percentage_of_votes: 1.32,
    },
    {
      serial_no: 7,
      candidate: "JIBRAN FIRDOUS DAR",
      party: "Independent",
      evm_votes: 5431,
      postal_votes: 67,
      total_votes: 5498,
      percentage_of_votes: 0.81,
    },
    {
      serial_no: 8,
      candidate: "JAHANGIR AHMAD SHEIKH",
      party: "Independent",
      evm_votes: 4412,
      postal_votes: 10,
      total_votes: 4422,
      percentage_of_votes: 0.65,
    },
    {
      serial_no: 9,
      candidate: "SAJAD AHMAD DAR",
      party: "Independent",
      evm_votes: 3755,
      postal_votes: 9,
      total_votes: 3764,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 10,
      candidate: "HAKIKAT SINGH",
      party: "Jammu & Kashmir National Panthers Party (Bhim)",
      evm_votes: 3732,
      postal_votes: 46,
      total_votes: 3778,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 11,
      candidate: "RUBINA AKHTER",
      party: "National Loktantrik Party",
      evm_votes: 2855,
      postal_votes: 21,
      total_votes: 2876,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 12,
      candidate: "WASEEM HASSAN SHEIKH",
      party: "Independent",
      evm_votes: 2838,
      postal_votes: 17,
      total_votes: 2855,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 13,
      candidate: "RIYAZ AHMAD BHAT",
      party: "Independent",
      evm_votes: 2791,
      postal_votes: 1,
      total_votes: 2792,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 14,
      candidate: "FAYAZ AHMAD BUTT",
      party: "Independent",
      evm_votes: 2221,
      postal_votes: 6,
      total_votes: 2227,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 15,
      candidate: "SHAHNAZ HUSSAIN SHAH",
      party: "Independent",
      evm_votes: 2196,
      postal_votes: 7,
      total_votes: 2203,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 16,
      candidate: "MOHD YOUSUF BHAT",
      party: "Gana Suraksha Party",
      evm_votes: 1880,
      postal_votes: 18,
      total_votes: 1898,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 17,
      candidate: "WAHEEDA TABASUM SHAH",
      party: "Independent",
      evm_votes: 1594,
      postal_votes: 12,
      total_votes: 1606,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 18,
      candidate: "NISAR AHMAD AHANGAR",
      party: "Independent",
      evm_votes: 1115,
      postal_votes: 9,
      total_votes: 1124,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 19,
      candidate: "MIRZA SAJAD HUSSAIN BEIGH",
      party: "Independent",
      evm_votes: 1049,
      postal_votes: 15,
      total_votes: 1064,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 20,
      candidate: "GHULAM MOHAMMAD WANI",
      party: "Independent",
      evm_votes: 1028,
      postal_votes: 12,
      total_votes: 1040,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 21,
      candidate: "AMIN DAR",
      party: "Independent",
      evm_votes: 989,
      postal_votes: 7,
      total_votes: 996,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 22,
      candidate: "JAVEED AHMAD WANI",
      party: "Independent",
      evm_votes: 928,
      postal_votes: 4,
      total_votes: 932,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 23,
      candidate: "YOUNIS AHMAD MIR",
      party: "Bharat Jodo Party",
      evm_votes: 913,
      postal_votes: 12,
      total_votes: 925,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 24,
      candidate: "DR QAZI ASHRAF",
      party: "Independent",
      evm_votes: 910,
      postal_votes: 9,
      total_votes: 919,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 25,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5887,
      postal_votes: 111,
      total_votes: 5998,
      percentage_of_votes: 0.89,
    },
  ],
  total: {
    evm_votes: 671933,
    postal_votes: 3309,
    total_votes: 675242,
  },
};
export const u083 = {
  cs_name: "ANANTNAG-RAJOURI",
  cs_shortname: "anantnagrajouri",
  cs_code: "u083",
  winner: "JKN",
  stats: [
    {
      serial_no: 1,
      candidate: "MIAN ALTAF AHMAD",
      party: "Jammu & Kashmir National Conference",
      evm_votes: 517489,
      postal_votes: 4347,
      total_votes: 521836,
      percentage_of_votes: 50.85,
    },
    {
      serial_no: 2,
      candidate: "MEHBOOBA MUFTI",
      party: "Jammu & Kashmir Peoples Democratic Party",
      evm_votes: 238210,
      postal_votes: 1832,
      total_votes: 240042,
      percentage_of_votes: 23.39,
    },
    {
      serial_no: 3,
      candidate: "ZAFAR IQBAL KHAN MANHAS",
      party: "Jammu and Kashmir Apni Party",
      evm_votes: 141316,
      postal_votes: 879,
      total_votes: 142195,
      percentage_of_votes: 13.86,
    },
    {
      serial_no: 4,
      candidate: "MOHAMAD SALEEM PARAY",
      party: "Democratic Progressive Azad Party",
      evm_votes: 25440,
      postal_votes: 121,
      total_votes: 25561,
      percentage_of_votes: 2.49,
    },
    {
      serial_no: 5,
      candidate: "ABDUL RAOUF MALIK",
      party: "Independent",
      evm_votes: 10566,
      postal_votes: 16,
      total_votes: 10582,
      percentage_of_votes: 1.03,
    },
    {
      serial_no: 6,
      candidate: "SUSHIL KUMAR SHARMA",
      party: "Independent",
      evm_votes: 9102,
      postal_votes: 126,
      total_votes: 9228,
      percentage_of_votes: 0.9,
    },
    {
      serial_no: 7,
      candidate: "ABDUL ROUF NAIK",
      party: "Independent",
      evm_votes: 9079,
      postal_votes: 70,
      total_votes: 9149,
      percentage_of_votes: 0.89,
    },
    {
      serial_no: 8,
      candidate: "SAJAD AHMAD DAR",
      party: "Independent",
      evm_votes: 7988,
      postal_votes: 16,
      total_votes: 8004,
      percentage_of_votes: 0.78,
    },
    {
      serial_no: 9,
      candidate: "IMRAN SHEIKH",
      party: "Independent",
      evm_votes: 7457,
      postal_votes: 28,
      total_votes: 7485,
      percentage_of_votes: 0.73,
    },
    {
      serial_no: 10,
      candidate: "IMTIYAZ AHMAD",
      party: "National Loktantrik Party",
      evm_votes: 6936,
      postal_votes: 77,
      total_votes: 7013,
      percentage_of_votes: 0.68,
    },
    {
      serial_no: 11,
      candidate: "ARSHEED AHMED LONE",
      party: "Jammu & Kashmir National Panthers Party (Bhim)",
      evm_votes: 6163,
      postal_votes: 226,
      total_votes: 6389,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 12,
      candidate: "BALDEV KUMAR",
      party: "Independent",
      evm_votes: 6120,
      postal_votes: 69,
      total_votes: 6189,
      percentage_of_votes: 0.6,
    },
    {
      serial_no: 13,
      candidate: "ALI MOHAMMAD WANI",
      party: "Independent",
      evm_votes: 5238,
      postal_votes: 17,
      total_votes: 5255,
      percentage_of_votes: 0.51,
    },
    {
      serial_no: 14,
      candidate: "SHEIKH MUZAFAR AHMED",
      party: "Jammu and Kashmir Nationalist People's Front",
      evm_votes: 4774,
      postal_votes: 40,
      total_votes: 4814,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 15,
      candidate: "DILIP KUMAR PANDITA",
      party: "Independent",
      evm_votes: 3638,
      postal_votes: 84,
      total_votes: 3722,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 16,
      candidate: "RAVINDER SINGH",
      party: "Independent",
      evm_votes: 2828,
      postal_votes: 134,
      total_votes: 2962,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 17,
      candidate: "GULSHAN AKHTER",
      party: "Independent",
      evm_votes: 2778,
      postal_votes: 29,
      total_votes: 2807,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 18,
      candidate: "MOHAMMAD MAQBOOL TELI",
      party: "Garib Democratic Party",
      evm_votes: 2731,
      postal_votes: 17,
      total_votes: 2748,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 19,
      candidate: "SUDARSHAN SINGH",
      party: "National Awami United Party",
      evm_votes: 2166,
      postal_votes: 216,
      total_votes: 2382,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 20,
      candidate: "JAVAID AHMED",
      party: "All India Forward Bloc",
      evm_votes: 1534,
      postal_votes: 28,
      total_votes: 1562,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 21,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5967,
      postal_votes: 256,
      total_votes: 6223,
      percentage_of_votes: 0.61,
    },
  ],
  total: {
    evm_votes: 1017520,
    postal_votes: 8628,
    total_votes: 1026148,
  },
};
export const s031 = {
  cs_name: "Kokrajhar",
  cs_shortname: "kokrajhar",
  cs_code: "s031",
  winner: "UPPL",
  stats: [
    {
      serial_no: 1,
      candidate: "JOYANTA BASUMATARY",
      party: "United People’s Party, Liberal",
      evm_votes: 484681,
      postal_votes: 4314,
      total_votes: 488995,
      percentage_of_votes: 39.39,
    },
    {
      serial_no: 2,
      candidate: "KAMPA BORGOYARI",
      party: "Bodoland Peoples Front",
      evm_votes: 432768,
      postal_votes: 4644,
      total_votes: 437412,
      percentage_of_votes: 35.23,
    },
    {
      serial_no: 3,
      candidate: "GARJAN MASHAHARY",
      party: "Indian National Congress",
      evm_votes: 112624,
      postal_votes: 1112,
      total_votes: 113736,
      percentage_of_votes: 9.16,
    },
    {
      serial_no: 4,
      candidate: "BINITA DEKA",
      party: "Gana Suraksha Party",
      evm_votes: 93266,
      postal_votes: 923,
      total_votes: 94189,
      percentage_of_votes: 7.59,
    },
    {
      serial_no: 5,
      candidate: "PRITHVIRAJ NARAYAN DEV MECH",
      party: "Independent",
      evm_votes: 33636,
      postal_votes: 101,
      total_votes: 33737,
      percentage_of_votes: 2.72,
    },
    {
      serial_no: 6,
      candidate: "GAURI SANKAR SARANIA",
      party: "All India Trinamool Congress",
      evm_votes: 23362,
      postal_votes: 157,
      total_votes: 23519,
      percentage_of_votes: 1.89,
    },
    {
      serial_no: 7,
      candidate: "RANJAY KR BRAHMA",
      party: "Independent",
      evm_votes: 8782,
      postal_votes: 48,
      total_votes: 8830,
      percentage_of_votes: 0.71,
    },
    {
      serial_no: 8,
      candidate: "LALIT PEGU",
      party: "Voters Party International",
      evm_votes: 7891,
      postal_votes: 41,
      total_votes: 7932,
      percentage_of_votes: 0.64,
    },
    {
      serial_no: 9,
      candidate: "JYOTISH KUMAR DAS",
      party: "Independent",
      evm_votes: 5944,
      postal_votes: 162,
      total_votes: 6106,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 10,
      candidate: "PANKAJ ISLARY",
      party: "Independent",
      evm_votes: 4473,
      postal_votes: 35,
      total_votes: 4508,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 11,
      candidate: "TRIPTINA RABHA",
      party: "Independent",
      evm_votes: 4425,
      postal_votes: 115,
      total_votes: 4540,
      percentage_of_votes: 0.37,
    },
    {
      serial_no: 12,
      candidate: "AJOY NARZARY",
      party: "Independent",
      evm_votes: 4032,
      postal_votes: 48,
      total_votes: 4080,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13662,
      postal_votes: 250,
      total_votes: 13912,
      percentage_of_votes: 1.12,
    },
  ],
  total: {
    evm_votes: 1229546,
    postal_votes: 11950,
    total_votes: 1241496,
  },
};
export const s033 = {
  cs_name: "Barpeta",
  cs_shortname: "barpeta",
  cs_code: "s033",
  winner: "AGP",
  stats: [
    {
      serial_no: 1,
      candidate: "PHANI BHUSAN CHOUDHURY",
      party: "Asom Gana Parishad",
      evm_votes: 853642,
      postal_votes: 6471,
      total_votes: 860113,
      percentage_of_votes: 51.02,
    },
    {
      serial_no: 2,
      candidate: "DEEP BAYAN",
      party: "Indian National Congress",
      evm_votes: 635411,
      postal_votes: 2351,
      total_votes: 637762,
      percentage_of_votes: 37.83,
    },
    {
      serial_no: 3,
      candidate: "MANORANJAN TALUKDAR",
      party: "Communist Party of India (Marxist)",
      evm_votes: 95020,
      postal_votes: 1118,
      total_votes: 96138,
      percentage_of_votes: 5.7,
    },
    {
      serial_no: 4,
      candidate: "ABUL KALAM AZAD",
      party: "All India Trinamool Congress",
      evm_votes: 16359,
      postal_votes: 73,
      total_votes: 16432,
      percentage_of_votes: 0.97,
    },
    {
      serial_no: 5,
      candidate: "DEWAN MOHIBUL ISLAM",
      party: "Independent",
      evm_votes: 9930,
      postal_votes: 11,
      total_votes: 9941,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 6,
      candidate: "SAMEJ UDDIN",
      party: "Bodoland Peoples Front",
      evm_votes: 9515,
      postal_votes: 177,
      total_votes: 9692,
      percentage_of_votes: 0.57,
    },
    {
      serial_no: 7,
      candidate: "DULU AHMED",
      party: "Independent",
      evm_votes: 9275,
      postal_votes: 63,
      total_votes: 9338,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 8,
      candidate: "MATIAR RAHMAN",
      party: "Independent",
      evm_votes: 7920,
      postal_votes: 15,
      total_votes: 7935,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 9,
      candidate: "JAGANNATH RAY",
      party: "Independent",
      evm_votes: 5474,
      postal_votes: 74,
      total_votes: 5548,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 10,
      candidate: "SHAJAHAN ALI KHAN",
      party: "Voters Party International",
      evm_votes: 4441,
      postal_votes: 7,
      total_votes: 4448,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 11,
      candidate: "AYNAL HAQUE",
      party: "Independent",
      evm_votes: 3174,
      postal_votes: 18,
      total_votes: 3192,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 12,
      candidate: "CHITRALEKHA DAS",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 3060,
      postal_votes: 71,
      total_votes: 3131,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 13,
      candidate: "AMZAD ALI",
      party: "Republican Party of India (Athawale)",
      evm_votes: 2657,
      postal_votes: 22,
      total_votes: 2679,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 14,
      candidate: "ABUBAKKAR SIDDIQUE",
      party: "Rashtriya Ulama Council",
      evm_votes: 2463,
      postal_votes: 14,
      total_votes: 2477,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 16888,
      postal_votes: 229,
      total_votes: 17117,
      percentage_of_votes: 1.02,
    },
  ],
  total: {
    evm_votes: 1675229,
    postal_votes: 10714,
    total_votes: 1685943,
  },
};
export const s0438 = {
  cs_name: "Gaya",
  cs_shortname: "gaya",
  cs_code: "s0438",
  winner: "HAMS",
  stats: [
    {
      serial_no: 1,
      candidate: "JITAN RAM MANJHI",
      party: "Hindustani Awam Morcha (Secular)",
      evm_votes: 492732,
      postal_votes: 2228,
      total_votes: 494960,
      percentage_of_votes: 51.36,
    },
    {
      serial_no: 2,
      candidate: "KUMAR SARVJEET",
      party: "Rashtriya Janata Dal",
      evm_votes: 390469,
      postal_votes: 2679,
      total_votes: 393148,
      percentage_of_votes: 40.8,
    },
    {
      serial_no: 3,
      candidate: "SUSHMA KUMARI",
      party: "Bahujan Samaj Party",
      evm_votes: 12234,
      postal_votes: 142,
      total_votes: 12376,
      percentage_of_votes: 1.28,
    },
    {
      serial_no: 4,
      candidate: "DEVENDRA PRATAP",
      party: "Independent",
      evm_votes: 9209,
      postal_votes: 2,
      total_votes: 9211,
      percentage_of_votes: 0.96,
    },
    {
      serial_no: 5,
      candidate: "DHEERENDRA PRASAD",
      party: "Loktantrik Samajwadi Party",
      evm_votes: 5616,
      postal_votes: 13,
      total_votes: 5629,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 6,
      candidate: "GIRIDHAR SAPERA",
      party: "The National Road Map Party of India",
      evm_votes: 4708,
      postal_votes: 19,
      total_votes: 4727,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 7,
      candidate: "RANU KUMAR CHAUDHARY",
      party: "Independent",
      evm_votes: 4495,
      postal_votes: 10,
      total_votes: 4505,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 8,
      candidate: "RANJAN KUMAR",
      party: "Independent",
      evm_votes: 4233,
      postal_votes: 1,
      total_votes: 4234,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 9,
      candidate: "AYUSH KUMAR",
      party: "Independent",
      evm_votes: 4098,
      postal_votes: 5,
      total_votes: 4103,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 10,
      candidate: "SURENDRA MANJHI",
      party: "Rashtriya Jansambhavna Party",
      evm_votes: 3023,
      postal_votes: 12,
      total_votes: 3035,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 11,
      candidate: "AMRESH KUMAR",
      party: "Independent",
      evm_votes: 2800,
      postal_votes: 21,
      total_votes: 2821,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 12,
      candidate: "ASHOK KUMAR PASWAN",
      party: "Independent",
      evm_votes: 2692,
      postal_votes: 9,
      total_votes: 2701,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 13,
      candidate: "ARUN KUMAR",
      party: "Independent",
      evm_votes: 2531,
      postal_votes: 23,
      total_votes: 2554,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 14,
      candidate: "SHEO SHANKAR",
      party: "Bhartiya Lok Chetna Party",
      evm_votes: 2370,
      postal_votes: 14,
      total_votes: 2384,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 17177,
      postal_votes: 111,
      total_votes: 17288,
      percentage_of_votes: 1.79,
    },
  ],
  total: {
    evm_votes: 958387,
    postal_votes: 5289,
    total_votes: 963676,
  },
};
export const s1114 = {
  cs_name: "Kottayam",
  cs_shortname: "kottayam",
  cs_code: "s1114",
  winner: "KEC",
  stats: [
    {
      serial_no: 1,
      candidate: "ADV K FRANCIS GEORGE",
      party: "Kerala Congress",
      evm_votes: 358369,
      postal_votes: 6262,
      total_votes: 364631,
      percentage_of_votes: 43.6,
    },
    {
      serial_no: 2,
      candidate: "THOMAS CHAZHIKADAN",
      party: "Kerala Congress (M)",
      evm_votes: 272418,
      postal_votes: 4947,
      total_votes: 277365,
      percentage_of_votes: 33.17,
    },
    {
      serial_no: 3,
      candidate: "THUSHAR VELLAPPALLY",
      party: "Bharath Dharma Jana Sena",
      evm_votes: 163227,
      postal_votes: 1819,
      total_votes: 165046,
      percentage_of_votes: 19.74,
    },
    {
      serial_no: 4,
      candidate: "VIJU CHERIAN",
      party: "Bahujan Samaj Party",
      evm_votes: 7075,
      postal_votes: 148,
      total_votes: 7223,
      percentage_of_votes: 0.86,
    },
    {
      serial_no: 5,
      candidate: "P O PETER",
      party: "Samajwadi Jan Parishad",
      evm_votes: 1581,
      postal_votes: 56,
      total_votes: 1637,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 6,
      candidate: "V P KOCHUMON",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 1551,
      postal_votes: 44,
      total_votes: 1595,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 7,
      candidate: "JOSSIN K JOSEPH",
      party: "Independent",
      evm_votes: 1397,
      postal_votes: 92,
      total_votes: 1489,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 8,
      candidate: "CHANDRABOSE P",
      party: "Independent",
      evm_votes: 1071,
      postal_votes: 16,
      total_votes: 1087,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 9,
      candidate: "M M SCARIA",
      party: "Independent",
      evm_votes: 913,
      postal_votes: 14,
      total_votes: 927,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 10,
      candidate: "JOEMON JOSEPH SRAMPICKAL A P J JUMAN V S",
      party: "Independent",
      evm_votes: 875,
      postal_votes: 18,
      total_votes: 893,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 11,
      candidate: "ROBY MATTAPPALLY",
      party: "Independent",
      evm_votes: 697,
      postal_votes: 42,
      total_votes: 739,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 12,
      candidate: "SANTHOSH PULICKAL",
      party: "Independent",
      evm_votes: 686,
      postal_votes: 24,
      total_votes: 710,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 13,
      candidate: "SUNIL ALANCHERIL",
      party: "Independent",
      evm_votes: 499,
      postal_votes: 28,
      total_votes: 527,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 14,
      candidate: "MAN HOUSE MANMADHAN",
      party: "Independent",
      evm_votes: 403,
      postal_votes: 18,
      total_votes: 421,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 11804,
      postal_votes: 129,
      total_votes: 11933,
      percentage_of_votes: 1.43,
    },
  ],
  total: {
    evm_votes: 822566,
    postal_votes: 13657,
    total_votes: 836223,
  },
};
export const s1118 = {
  cs_name: "Kollam",
  cs_shortname: "kollam",
  cs_code: "s1118",
  winner: "RSP",
  stats: [
    {
      serial_no: 1,
      candidate: "N K PREMACHANDRAN",
      party: "Revolutionary Socialist Party",
      evm_votes: 437516,
      postal_votes: 6112,
      total_votes: 443628,
      percentage_of_votes: 48.45,
    },
    {
      serial_no: 2,
      candidate: "M MUKESH",
      party: "Communist Party of India (Marxist)",
      evm_votes: 288861,
      postal_votes: 4465,
      total_votes: 293326,
      percentage_of_votes: 32.03,
    },
    {
      serial_no: 3,
      candidate: "KRISHNAKUMAR G",
      party: "Bharatiya Janata Party",
      evm_votes: 160956,
      postal_votes: 2254,
      total_votes: 163210,
      percentage_of_votes: 17.82,
    },
    {
      serial_no: 4,
      candidate: "VIPINLAL VIDHYADHARAN",
      party: "Bahujan Samaj Party",
      evm_votes: 2163,
      postal_votes: 31,
      total_votes: 2194,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 5,
      candidate: "PREMACHANDRAN NAIR",
      party: "Independent",
      evm_votes: 1713,
      postal_votes: 21,
      total_votes: 1734,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 6,
      candidate: "GOKULAM SURESH KUMAR",
      party: "Independent",
      evm_votes: 1369,
      postal_votes: 5,
      total_votes: 1374,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 7,
      candidate: "PRADEEP KOTTARAKKARA",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 793,
      postal_votes: 42,
      total_votes: 835,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 8,
      candidate: "TWINKLE PRABHAKARAN",
      party: "Socialist Unity Centre Of India (COMMUNIST)",
      evm_votes: 689,
      postal_votes: 19,
      total_votes: 708,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 9,
      candidate: "JOSE SARANATH",
      party: "Ambedkarite Party of India",
      evm_votes: 553,
      postal_votes: 6,
      total_votes: 559,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 10,
      candidate: "BRO NOUSHAD SHERIFF J",
      party: "Independent",
      evm_votes: 545,
      postal_votes: 34,
      total_votes: 579,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 11,
      candidate: "P KRISHNAMMAL",
      party: "Marxist Communist Party of India (United)",
      evm_votes: 537,
      postal_votes: 12,
      total_votes: 549,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 12,
      candidate: "N JAYARAJAN",
      party: "Independent",
      evm_votes: 407,
      postal_votes: 42,
      total_votes: 449,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 13,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6437,
      postal_votes: 109,
      total_votes: 6546,
      percentage_of_votes: 0.71,
    },
  ],
  total: {
    evm_votes: 902539,
    postal_votes: 13152,
    total_votes: 915691,
  },
};
export const s1332 = {
  cs_name: "Raigad",
  cs_shortname: "raigad",
  cs_code: "s1332",
  winner: "NCP",
  stats: [
    {
      serial_no: 1,
      candidate: "TATKARE SUNIL DATTATREY",
      party: "Nationalist Congress Party",
      evm_votes: 506529,
      postal_votes: 1823,
      total_votes: 508352,
      percentage_of_votes: 50.17,
    },
    {
      serial_no: 2,
      candidate: "ANANT GEETE",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 423137,
      postal_votes: 2431,
      total_votes: 425568,
      percentage_of_votes: 42,
    },
    {
      serial_no: 3,
      candidate: "KUMUDINI RAVINDRA CHAVAN",
      party: "Vanchit Bahujan Aaghadi",
      evm_votes: 19500,
      postal_votes: 118,
      total_votes: 19618,
      percentage_of_votes: 1.94,
    },
    {
      serial_no: 4,
      candidate: "SHRINIVAS SATYANARAYAN MATTAPARTI",
      party: "Independent",
      evm_votes: 9379,
      postal_votes: 15,
      total_votes: 9394,
      percentage_of_votes: 0.93,
    },
    {
      serial_no: 5,
      candidate: "AMIT SHRIPAL KAWADE",
      party: "Independent",
      evm_votes: 5603,
      postal_votes: 31,
      total_votes: 5634,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 6,
      candidate: "ANANT BALOJI GITE",
      party: "Independent",
      evm_votes: 3485,
      postal_votes: 30,
      total_votes: 3515,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 7,
      candidate: "NITIN JAGANNATH MAYEKAR",
      party: "Independent",
      evm_votes: 2548,
      postal_votes: 12,
      total_votes: 2560,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 8,
      candidate: "CHAULE PANDURANG DAMODAR",
      party: "Independent",
      evm_votes: 2394,
      postal_votes: 23,
      total_votes: 2417,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 9,
      candidate: "ANANT PADMA GITE",
      party: "Independent",
      evm_votes: 2001,
      postal_votes: 39,
      total_votes: 2040,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 10,
      candidate: "COL PRAKASHRAO CHAVAN",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 1863,
      postal_votes: 76,
      total_votes: 1939,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 11,
      candidate: "KOLI MANGESH PADMAKAR",
      party: "Independent",
      evm_votes: 1797,
      postal_votes: 23,
      total_votes: 1820,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 12,
      candidate: "ADV AJAY UPADHYE",
      party: "Independent",
      evm_votes: 1756,
      postal_votes: 39,
      total_votes: 1795,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 13,
      candidate: "ANJANI ASHWIN KELKAR",
      party: "Independent",
      evm_votes: 1325,
      postal_votes: 25,
      total_votes: 1350,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 14,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 27188,
      postal_votes: 82,
      total_votes: 27270,
      percentage_of_votes: 2.69,
    },
  ],
  total: {
    evm_votes: 1008505,
    postal_votes: 4767,
    total_votes: 1013272,
  },
};
export const s151 = {
  cs_name: "Shillong",
  cs_shortname: "shillong",
  cs_code: "s151",
  winner: "VOTPP",
  stats: [
    {
      serial_no: 1,
      candidate: "DR. RICKY ANDREW J. SYNGKON",
      party: "Voice of the People Party",
      evm_votes: 569156,
      postal_votes: 1922,
      total_votes: 571078,
      percentage_of_votes: 55.02,
    },
    {
      serial_no: 2,
      candidate: "VINCENT H. PALA",
      party: "Indian National Congress",
      evm_votes: 198045,
      postal_votes: 1123,
      total_votes: 199168,
      percentage_of_votes: 19.19,
    },
    {
      serial_no: 3,
      candidate: "DR. MAZEL AMPAREEN LYNGDOH",
      party: "National People's Party",
      evm_votes: 185531,
      postal_votes: 957,
      total_votes: 186488,
      percentage_of_votes: 17.97,
    },
    {
      serial_no: 4,
      candidate: "ROBERTJUNE KHARJAHRIN",
      party: "United Democratic Party",
      evm_votes: 44278,
      postal_votes: 285,
      total_votes: 44563,
      percentage_of_votes: 4.29,
    },
    {
      serial_no: 5,
      candidate: "PROF. LAKHON KMA",
      party: "Independent",
      evm_votes: 18522,
      postal_votes: 60,
      total_votes: 18582,
      percentage_of_votes: 1.79,
    },
    {
      serial_no: 6,
      candidate: "PETER SHALLAM",
      party: "Independent",
      evm_votes: 6975,
      postal_votes: 49,
      total_votes: 7024,
      percentage_of_votes: 0.68,
    },
    {
      serial_no: 7,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 10944,
      postal_votes: 64,
      total_votes: 11008,
      percentage_of_votes: 1.06,
    },
  ],
  total: {
    evm_votes: 1033451,
    postal_votes: 4460,
    total_votes: 1037911,
  },
};
export const s161 = {
  cs_name: "MIZORAM",
  cs_shortname: "mizoram",
  cs_code: "s161",
  winner: "ZPM",
  stats: [
    {
      serial_no: 1,
      candidate: "RICHARD VANLALHMANGAIHA",
      party: "Zoram People’s Movement",
      evm_votes: 206377,
      postal_votes: 2175,
      total_votes: 208552,
      percentage_of_votes: 42.45,
    },
    {
      serial_no: 2,
      candidate: "K VANLALVENA",
      party: "Mizo National Front",
      evm_votes: 139532,
      postal_votes: 732,
      total_votes: 140264,
      percentage_of_votes: 28.55,
    },
    {
      serial_no: 3,
      candidate: "LALBIAKZAMA",
      party: "Indian National Congress",
      evm_votes: 97761,
      postal_votes: 834,
      total_votes: 98595,
      percentage_of_votes: 20.07,
    },
    {
      serial_no: 4,
      candidate: "VANLALHMUAKA",
      party: "Bharatiya Janata Party",
      evm_votes: 33009,
      postal_votes: 524,
      total_votes: 33533,
      percentage_of_votes: 6.82,
    },
    {
      serial_no: 5,
      candidate: "LALHRIATRENGA CHHANGTE",
      party: "Independent",
      evm_votes: 4663,
      postal_votes: 43,
      total_votes: 4706,
      percentage_of_votes: 0.96,
    },
    {
      serial_no: 6,
      candidate: "RITA MALSAWMI",
      party: "Mizoram People's Conference",
      evm_votes: 3681,
      postal_votes: 112,
      total_votes: 3793,
      percentage_of_votes: 0.77,
    },
    {
      serial_no: 7,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 1866,
      postal_votes: 27,
      total_votes: 1893,
      percentage_of_votes: 0.39,
    },
  ],
  total: {
    evm_votes: 486889,
    postal_votes: 4447,
    total_votes: 491336,
  },
};
export const s1911 = {
  cs_name: "Bathinda",
  cs_shortname: "bathinda",
  cs_code: "s1911",
  winner: "SAD",
  stats: [
    {
      serial_no: 1,
      candidate: "HARSIMRAT KAUR BADAL",
      party: "Shiromani Akali Dal",
      evm_votes: 375019,
      postal_votes: 1539,
      total_votes: 376558,
      percentage_of_votes: 32.71,
    },
    {
      serial_no: 2,
      candidate: "GURMEET SINGH KHUDIAN",
      party: "Aam Aadmi Party",
      evm_votes: 325019,
      postal_votes: 1883,
      total_votes: 326902,
      percentage_of_votes: 28.4,
    },
    {
      serial_no: 3,
      candidate: "JEETMOHINDER SINGH SIDHU",
      party: "Indian National Congress",
      evm_votes: 200859,
      postal_votes: 1152,
      total_votes: 202011,
      percentage_of_votes: 17.55,
    },
    {
      serial_no: 4,
      candidate: "PARAMPAL KAUR SIDHU",
      party: "Bharatiya Janata Party",
      evm_votes: 110255,
      postal_votes: 507,
      total_votes: 110762,
      percentage_of_votes: 9.62,
    },
    {
      serial_no: 5,
      candidate: "LAKHVIR SINGH LAKHA SIDHANA",
      party: "Shiromani Akali Dal (Amritsar)(Simranjit Singh Mann)",
      evm_votes: 84222,
      postal_votes: 462,
      total_votes: 84684,
      percentage_of_votes: 7.36,
    },
    {
      serial_no: 6,
      candidate: "NIKKA SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 12979,
      postal_votes: 60,
      total_votes: 13039,
      percentage_of_votes: 1.13,
    },
    {
      serial_no: 7,
      candidate: "JAGJIWAN BALLI",
      party: "Independent",
      evm_votes: 5480,
      postal_votes: 4,
      total_votes: 5484,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 8,
      candidate: "PARWINDER SINGH",
      party: "Independent",
      evm_votes: 4494,
      postal_votes: 3,
      total_votes: 4497,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 9,
      candidate: "PALA RAM",
      party: "Independent",
      evm_votes: 3428,
      postal_votes: 7,
      total_votes: 3435,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 10,
      candidate: "BHAGWANT SINGH SAMAON",
      party: "Independent",
      evm_votes: 3372,
      postal_votes: 16,
      total_votes: 3388,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 11,
      candidate: "GURMEET SINGH",
      party: "Independent",
      evm_votes: 3072,
      postal_votes: 10,
      total_votes: 3082,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 12,
      candidate: "JASVIR SINGH BATHINDA",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 3039,
      postal_votes: 106,
      total_votes: 3145,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 13,
      candidate: "GURPREET SINGH URF PREET GHAINT",
      party: "Jan Sewa Driver Party",
      evm_votes: 2619,
      postal_votes: 9,
      total_votes: 2628,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 14,
      candidate: "GURBARN SINGH",
      party: "Independent",
      evm_votes: 1618,
      postal_votes: 9,
      total_votes: 1627,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 15,
      candidate: "NAIB SINGH",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 1593,
      postal_votes: 8,
      total_votes: 1601,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 16,
      candidate: "KULWANT SINGH",
      party: "Independent",
      evm_votes: 1172,
      postal_votes: 10,
      total_votes: 1182,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 17,
      candidate: "AMANDEEP SINGH",
      party: "Independent",
      evm_votes: 1146,
      postal_votes: 2,
      total_votes: 1148,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 18,
      candidate: "POONAM RANI",
      party: "Nationalist Justice Party",
      evm_votes: 1060,
      postal_votes: 4,
      total_votes: 1064,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 19,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4818,
      postal_votes: 115,
      total_votes: 4933,
      percentage_of_votes: 0.43,
    },
  ],
  total: {
    evm_votes: 1145264,
    postal_votes: 5906,
    total_votes: 1151170,
  },
};
export const s2014 = {
  cs_name: "NAGAUR",
  cs_shortname: "nagaur",
  cs_code: "s2014",
  winner: "RLTP",
  stats: [
    {
      serial_no: 1,
      candidate: "HANUMAN BENIWAL",
      party: "Rashtriya Loktantrik Party",
      evm_votes: 591460,
      postal_votes: 5495,
      total_votes: 596955,
      percentage_of_votes: 48.2,
    },
    {
      serial_no: 2,
      candidate: "JYOTI MIRDHA",
      party: "Bharatiya Janata Party",
      evm_votes: 550304,
      postal_votes: 4426,
      total_votes: 554730,
      percentage_of_votes: 44.79,
    },
    {
      serial_no: 3,
      candidate: "DR ASHOK CHOUDHARY",
      party: "Abhinav Rajasthan Party",
      evm_votes: 26251,
      postal_votes: 43,
      total_votes: 26294,
      percentage_of_votes: 2.12,
    },
    {
      serial_no: 4,
      candidate: "DR. GAJENDRA SINGH RATHORE",
      party: "Bahujan Samaj Party",
      evm_votes: 23551,
      postal_votes: 457,
      total_votes: 24008,
      percentage_of_votes: 1.94,
    },
    {
      serial_no: 5,
      candidate: "RAJKUMAR JAT",
      party: "Independent",
      evm_votes: 8219,
      postal_votes: 13,
      total_votes: 8232,
      percentage_of_votes: 0.66,
    },
    {
      serial_no: 6,
      candidate: "HARI RAM",
      party: "Independent",
      evm_votes: 7610,
      postal_votes: 16,
      total_votes: 7626,
      percentage_of_votes: 0.62,
    },
    {
      serial_no: 7,
      candidate: "PREMRAJ KHARDIYA",
      party: "Independent",
      evm_votes: 4504,
      postal_votes: 8,
      total_votes: 4512,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 8,
      candidate: "AMEEN KHAN",
      party: "Independent",
      evm_votes: 4439,
      postal_votes: 24,
      total_votes: 4463,
      percentage_of_votes: 0.36,
    },
    {
      serial_no: 9,
      candidate: "HANUMAN SINGH KALVI",
      party: "Rashtriya Janshakti Party (Secular)",
      evm_votes: 2862,
      postal_votes: 20,
      total_votes: 2882,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 10,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 8711,
      postal_votes: 60,
      total_votes: 8771,
      percentage_of_votes: 0.71,
    },
  ],
  total: {
    evm_votes: 1227911,
    postal_votes: 10562,
    total_votes: 1238473,
  },
};
export const s2020 = {
  cs_name: "BANSWARA",
  cs_shortname: "banswara",
  cs_code: "s2020",
  winner: "BHRTADVSIP",
  stats: [
    {
      serial_no: 1,
      candidate: "RAJ KUMAR ROAT",
      party: "Bharat Adivasi Party",
      evm_votes: 815430,
      postal_votes: 5401,
      total_votes: 820831,
      percentage_of_votes: 50.15,
    },
    {
      serial_no: 2,
      candidate: "MAHENDRAJEETSINGH MALVIYA",
      party: "Bharatiya Janata Party",
      evm_votes: 568589,
      postal_votes: 5188,
      total_votes: 573777,
      percentage_of_votes: 35.05,
    },
    {
      serial_no: 3,
      candidate: "RAJKUMAR S/O HIRALAL",
      party: "Independent",
      evm_votes: 74551,
      postal_votes: 47,
      total_votes: 74598,
      percentage_of_votes: 4.56,
    },
    {
      serial_no: 4,
      candidate: "ARVIND SITA DAMOR",
      party: "Indian National Congress",
      evm_votes: 60917,
      postal_votes: 294,
      total_votes: 61211,
      percentage_of_votes: 3.74,
    },
    {
      serial_no: 5,
      candidate: "RAJKUMAR S/O PREMJI",
      party: "Independent",
      evm_votes: 41742,
      postal_votes: 48,
      total_votes: 41790,
      percentage_of_votes: 2.55,
    },
    {
      serial_no: 6,
      candidate: "BANSHILAL AHARI",
      party: "Independent",
      evm_votes: 17884,
      postal_votes: 12,
      total_votes: 17896,
      percentage_of_votes: 1.09,
    },
    {
      serial_no: 7,
      candidate: "SHANKARLAL BAMANIYA",
      party: "Indian Peoples Green Party",
      evm_votes: 17169,
      postal_votes: 19,
      total_votes: 17188,
      percentage_of_votes: 1.05,
    },
    {
      serial_no: 8,
      candidate: "DILEEP KUMAR MEENA",
      party: "Bahujan Samaj Party",
      evm_votes: 8573,
      postal_votes: 18,
      total_votes: 8591,
      percentage_of_votes: 0.52,
    },
    {
      serial_no: 9,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 20892,
      postal_votes: 78,
      total_votes: 20970,
      percentage_of_votes: 1.28,
    },
  ],
  total: {
    evm_votes: 1625747,
    postal_votes: 11105,
    total_votes: 1636852,
  },
};
export const s211 = {
  cs_name: "Sikkim",
  cs_shortname: "sikkim",
  cs_code: "s211",
  winner: "SKM",
  stats: [
    {
      serial_no: 1,
      candidate: "INDRA HANG SUBBA",
      party: "Sikkim Krantikari Morcha",
      evm_votes: 156836,
      postal_votes: 7560,
      total_votes: 164396,
      percentage_of_votes: 42.71,
    },
    {
      serial_no: 2,
      candidate: "BHARAT BASNETT",
      party: "Citizen Action Party-Sikkim",
      evm_votes: 78666,
      postal_votes: 4900,
      total_votes: 83566,
      percentage_of_votes: 21.71,
    },
    {
      serial_no: 3,
      candidate: "PREM DAS RAI",
      party: "Sikkim Democratic Front",
      evm_votes: 75464,
      postal_votes: 1707,
      total_votes: 77171,
      percentage_of_votes: 20.05,
    },
    {
      serial_no: 4,
      candidate: "LATEN TSHERING SHERPA",
      party: "Independent",
      evm_votes: 20197,
      postal_votes: 1066,
      total_votes: 21263,
      percentage_of_votes: 5.52,
    },
    {
      serial_no: 5,
      candidate: "DINESH CHANDRA NEPAL",
      party: "Bharatiya Janata Party",
      evm_votes: 18788,
      postal_votes: 247,
      total_votes: 19035,
      percentage_of_votes: 4.95,
    },
    {
      serial_no: 6,
      candidate: "SAMBHU CHETTRI",
      party: "Independent",
      evm_votes: 4682,
      postal_votes: 8,
      total_votes: 4690,
      percentage_of_votes: 1.22,
    },
    {
      serial_no: 7,
      candidate: "KHARGA BHADUR RAI",
      party: "Sikkim Republican Party",
      evm_votes: 4591,
      postal_votes: 208,
      total_votes: 4799,
      percentage_of_votes: 1.25,
    },
    {
      serial_no: 8,
      candidate: "GOPAL CHETTRI",
      party: "Indian National Congress",
      evm_votes: 2190,
      postal_votes: 51,
      total_votes: 2241,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 9,
      candidate: "NAWIN KIRAN PRADHAN",
      party: "Independent",
      evm_votes: 1121,
      postal_votes: 45,
      total_votes: 1166,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 10,
      candidate: "RAVI CHANDRA RAI",
      party: "Independent",
      evm_votes: 1077,
      postal_votes: 46,
      total_votes: 1123,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 11,
      candidate: "BEENA RAI",
      party: "Independent",
      evm_votes: 1066,
      postal_votes: 59,
      total_votes: 1125,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 12,
      candidate: "SHYAMAL PAL",
      party: "Independent",
      evm_votes: 735,
      postal_votes: 5,
      total_votes: 740,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 13,
      candidate: "RUDRA MANI PRADHAN",
      party: "Independent",
      evm_votes: 512,
      postal_votes: 7,
      total_votes: 519,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 14,
      candidate: "MADHUKAR DHAKAL",
      party: "Independent",
      evm_votes: 511,
      postal_votes: 21,
      total_votes: 532,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 15,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 2433,
      postal_votes: 94,
      total_votes: 2527,
      percentage_of_votes: 0.66,
    },
  ],
  total: {
    evm_votes: 368869,
    postal_votes: 16024,
    total_votes: 384893,
  },
};
export const s2224 = {
  cs_name: "TIRUCHIRAPPALLI",
  cs_shortname: "tiruchirappalli",
  cs_code: "s2224",
  winner: "MDMK",
  stats: [
    {
      serial_no: 1,
      candidate: "DURAI VAIKO",
      party: "Marumalarchi Dravida Munnetra Kazhagam",
      evm_votes: 538877,
      postal_votes: 3336,
      total_votes: 542213,
      percentage_of_votes: 51.35,
    },
    {
      serial_no: 2,
      candidate: "KARUPPAIAH. P",
      party: "All India Anna Dravida Munnetra Kazhagam",
      evm_votes: 227445,
      postal_votes: 1674,
      total_votes: 229119,
      percentage_of_votes: 21.7,
    },
    {
      serial_no: 3,
      candidate: "RAJESSH. T",
      party: "Naam Tamilar Katchi",
      evm_votes: 106752,
      postal_votes: 706,
      total_votes: 107458,
      percentage_of_votes: 10.18,
    },
    {
      serial_no: 4,
      candidate: "SENTHILNATHAN. P",
      party: "Amma Makkal Munnettra Kazagam",
      evm_votes: 99561,
      postal_votes: 1186,
      total_votes: 100747,
      percentage_of_votes: 9.54,
    },
    {
      serial_no: 5,
      candidate: "SELVARAJ. S",
      party: "Independent",
      evm_votes: 14781,
      postal_votes: 15,
      total_votes: 14796,
      percentage_of_votes: 1.4,
    },
    {
      serial_no: 6,
      candidate: "DAMODARAN. S",
      party: "Independent",
      evm_votes: 5138,
      postal_votes: 28,
      total_votes: 5166,
      percentage_of_votes: 0.49,
    },
    {
      serial_no: 7,
      candidate: "ALLIMUTHU. M",
      party: "Bahujan Samaj Party",
      evm_votes: 4353,
      postal_votes: 78,
      total_votes: 4431,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 8,
      candidate: "RAJENDRAN. S",
      party: "Independent",
      evm_votes: 3489,
      postal_votes: 10,
      total_votes: 3499,
      percentage_of_votes: 0.33,
    },
    {
      serial_no: 9,
      candidate: "JEEVANANTHAM. M",
      party: "Independent",
      evm_votes: 2879,
      postal_votes: 73,
      total_votes: 2952,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 10,
      candidate: "SAKTHIVEL",
      party: "Anna Puratchi Thalaivar Amma Dravida Munnetra Kazhagam",
      evm_votes: 2587,
      postal_votes: 59,
      total_votes: 2646,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 11,
      candidate: "KARUPPIAH. S",
      party: "Independent",
      evm_votes: 2156,
      postal_votes: 15,
      total_votes: 2171,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 12,
      candidate: "BACKIYARAJ VELLAISAMY",
      party: "Naadaalum Makkal Katchi",
      evm_votes: 2120,
      postal_votes: 163,
      total_votes: 2283,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 13,
      candidate: "SATHIS KUMAR. S",
      party: "Independent",
      evm_votes: 2048,
      postal_votes: 4,
      total_votes: 2052,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 14,
      candidate: "KAVITHA. S",
      party: "Independent",
      evm_votes: 1719,
      postal_votes: 17,
      total_votes: 1736,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 15,
      candidate: "NAGARAJAN. M",
      party: "Independent",
      evm_votes: 1656,
      postal_votes: 8,
      total_votes: 1664,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 16,
      candidate: "GOVINDARASU. P",
      party: "Independent",
      evm_votes: 1562,
      postal_votes: 4,
      total_votes: 1566,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 17,
      candidate: "ANBIN AMUDHAN. R",
      party: "Independent",
      evm_votes: 1492,
      postal_votes: 6,
      total_votes: 1498,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 18,
      candidate: "DURAIRAJ. A",
      party: "Independent",
      evm_votes: 1455,
      postal_votes: 8,
      total_votes: 1463,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 19,
      candidate: "SASIKUMAR. P",
      party: "Independent",
      evm_votes: 1346,
      postal_votes: 6,
      total_votes: 1352,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 20,
      candidate: "PANNEERSELVAM. P",
      party: "Independent",
      evm_votes: 1255,
      postal_votes: 8,
      total_votes: 1263,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 21,
      candidate: "DURAI. S",
      party: "Independent",
      evm_votes: 1198,
      postal_votes: 4,
      total_votes: 1202,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 22,
      candidate: "ANAND SELVARAJ",
      party: "Independent",
      evm_votes: 1172,
      postal_votes: 6,
      total_votes: 1178,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 23,
      candidate: "SANKAR. R",
      party: "Independent",
      evm_votes: 1162,
      postal_votes: 4,
      total_votes: 1166,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 24,
      candidate: "AMBIVENKATESAN",
      party: "Independent",
      evm_votes: 972,
      postal_votes: 6,
      total_votes: 978,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 25,
      candidate: "DURAI. T",
      party: "Independent",
      evm_votes: 877,
      postal_votes: 3,
      total_votes: 880,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 26,
      candidate: "SATHYAMURTHY. T",
      party: "Independent",
      evm_votes: 826,
      postal_votes: 8,
      total_votes: 834,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 27,
      candidate: "VIJAYAKUMAR. P",
      party: "Independent",
      evm_votes: 797,
      postal_votes: 5,
      total_votes: 802,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 28,
      candidate: "PERIYASAMY. V",
      party: "Independent",
      evm_votes: 771,
      postal_votes: 3,
      total_votes: 774,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 29,
      candidate: "AKILA. M",
      party: "Independent",
      evm_votes: 742,
      postal_votes: 5,
      total_votes: 747,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 30,
      candidate: "ROJA RAMANI. S",
      party: "Anna MGR Dravida Makkal Kalgam",
      evm_votes: 738,
      postal_votes: 39,
      total_votes: 777,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 31,
      candidate: "RAJENDRAN. P",
      party: "Independent",
      evm_votes: 674,
      postal_votes: 1,
      total_votes: 675,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 32,
      candidate: "MANIKANDARAJ. S",
      party: "Independent",
      evm_votes: 601,
      postal_votes: 7,
      total_votes: 608,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 33,
      candidate: "MURUGESAN. P",
      party: "Independent",
      evm_votes: 494,
      postal_votes: 3,
      total_votes: 497,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 34,
      candidate: "JOSEPH. L",
      party: "Samaniya Makkal Nala Katchi",
      evm_votes: 482,
      postal_votes: 9,
      total_votes: 491,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 35,
      candidate: "MUTHURAJA. T",
      party: "Independent",
      evm_votes: 428,
      postal_votes: 3,
      total_votes: 431,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 36,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 13560,
      postal_votes: 289,
      total_votes: 13849,
      percentage_of_votes: 1.31,
    },
  ],
  total: {
    evm_votes: 1048165,
    postal_votes: 7799,
    total_votes: 1055964,
  },
};
export const s245 = {
  cs_name: "Nagina",
  cs_shortname: "nagina",
  cs_code: "s245",
  winner: "ASPKR",
  stats: [
    {
      serial_no: 1,
      candidate: "CHANDRASHEKHAR",
      party: "Aazad Samaj Party (Kanshi Ram)",
      evm_votes: 511812,
      postal_votes: 740,
      total_votes: 512552,
      percentage_of_votes: 51.19,
    },
    {
      serial_no: 2,
      candidate: "OM KUMAR",
      party: "Bharatiya Janata Party",
      evm_votes: 359751,
      postal_votes: 1328,
      total_votes: 361079,
      percentage_of_votes: 36.06,
    },
    {
      serial_no: 3,
      candidate: "MANOJ KUMAR",
      party: "Samajwadi Party",
      evm_votes: 102104,
      postal_votes: 270,
      total_votes: 102374,
      percentage_of_votes: 10.22,
    },
    {
      serial_no: 4,
      candidate: "SURENDRA PAL SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 13212,
      postal_votes: 60,
      total_votes: 13272,
      percentage_of_votes: 1.33,
    },
    {
      serial_no: 5,
      candidate: "JOGENDRA",
      party: "Independent",
      evm_votes: 4509,
      postal_votes: 9,
      total_votes: 4518,
      percentage_of_votes: 0.45,
    },
    {
      serial_no: 6,
      candidate: "SANJEEV KUMAR",
      party: "Independent",
      evm_votes: 2195,
      postal_votes: 6,
      total_votes: 2201,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 7,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5287,
      postal_votes: 20,
      total_votes: 5307,
      percentage_of_votes: 0.53,
    },
  ],
  total: {
    evm_votes: 998870,
    postal_votes: 2433,
    total_votes: 1001303,
  },
};
export const s2479 = {
  cs_name: "Mirzapur",
  cs_shortname: "mirzapur",
  cs_code: "s2479",
  winner: "ADAL",
  stats: [
    {
      serial_no: 1,
      candidate: "ANUPRIYA PATEL",
      party: "Apna Dal (Soneylal)",
      evm_votes: 470866,
      postal_votes: 765,
      total_votes: 471631,
      percentage_of_votes: 42.67,
    },
    {
      serial_no: 2,
      candidate: "RAMESH CHAND BIND",
      party: "Samajwadi Party",
      evm_votes: 433286,
      postal_votes: 535,
      total_votes: 433821,
      percentage_of_votes: 39.25,
    },
    {
      serial_no: 3,
      candidate: "MANISH KUMAR",
      party: "Bahujan Samaj Party",
      evm_votes: 144279,
      postal_votes: 167,
      total_votes: 144446,
      percentage_of_votes: 13.07,
    },
    {
      serial_no: 4,
      candidate: "RAJESH",
      party: "Independent",
      evm_votes: 10927,
      postal_votes: 9,
      total_votes: 10936,
      percentage_of_votes: 0.99,
    },
    {
      serial_no: 5,
      candidate: "LAL JI VERMA",
      party: "Independent",
      evm_votes: 6355,
      postal_votes: 4,
      total_votes: 6359,
      percentage_of_votes: 0.58,
    },
    {
      serial_no: 6,
      candidate: "ANIL KUMAR",
      party: "Eklavya Samaj Party",
      evm_votes: 6166,
      postal_votes: 5,
      total_votes: 6171,
      percentage_of_votes: 0.56,
    },
    {
      serial_no: 7,
      candidate: "DAULAT SINGH",
      party: "Apna Dal (Kamerawadi)",
      evm_votes: 5227,
      postal_votes: 8,
      total_votes: 5235,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 8,
      candidate: "RAMDHANI",
      party: "Rashtriya Samaj Paksha",
      evm_votes: 4914,
      postal_votes: 1,
      total_votes: 4915,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 9,
      candidate: "SAMEER SINGH",
      party: "All India Forward Bloc",
      evm_votes: 3486,
      postal_votes: 1,
      total_votes: 3487,
      percentage_of_votes: 0.32,
    },
    {
      serial_no: 10,
      candidate: "SATYADEV",
      party: "Purvanchal Mahapanchayat",
      evm_votes: 3351,
      postal_votes: 4,
      total_votes: 3355,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 11,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 15043,
      postal_votes: 6,
      total_votes: 15049,
      percentage_of_votes: 1.36,
    },
  ],
  total: {
    evm_votes: 1103900,
    postal_votes: 1505,
    total_votes: 1105405,
  },
};
export const s276 = {
  cs_name: "Giridih",
  cs_shortname: "giridih",
  cs_code: "s276",
  winner: "AJSUP",
  stats: [
    {
      serial_no: 1,
      candidate: "CHANDRA PRAKASH CHOUDHARY",
      party: "AJSU Party",
      evm_votes: 447896,
      postal_votes: 3243,
      total_votes: 451139,
      percentage_of_votes: 35.67,
    },
    {
      serial_no: 2,
      candidate: "MATHURA PRASAD MAHATO",
      party: "Jharkhand Mukti Morcha",
      evm_votes: 367197,
      postal_votes: 3062,
      total_votes: 370259,
      percentage_of_votes: 29.27,
    },
    {
      serial_no: 3,
      candidate: "JAIRAM KUMAR MAHATO",
      party: "Independent",
      evm_votes: 342241,
      postal_votes: 5081,
      total_votes: 347322,
      percentage_of_votes: 27.46,
    },
    {
      serial_no: 4,
      candidate: "KAMAL PRASAD",
      party: "Bahujan Samaj Party",
      evm_votes: 13971,
      postal_votes: 103,
      total_votes: 14074,
      percentage_of_votes: 1.11,
    },
    {
      serial_no: 5,
      candidate: "SUBODH KUMAR YADAV",
      party: "Independent",
      evm_votes: 13093,
      postal_votes: 13,
      total_votes: 13106,
      percentage_of_votes: 1.04,
    },
    {
      serial_no: 6,
      candidate: "RAMESHWAR DUSADH",
      party: "Independent",
      evm_votes: 11011,
      postal_votes: 9,
      total_votes: 11020,
      percentage_of_votes: 0.87,
    },
    {
      serial_no: 7,
      candidate: "SUNITA TUDU",
      party: "Independent",
      evm_votes: 10899,
      postal_votes: 45,
      total_votes: 10944,
      percentage_of_votes: 0.87,
    },
    {
      serial_no: 8,
      candidate: "MD AINUL ANSARI",
      party: "Bahujan Mukti Party",
      evm_votes: 6919,
      postal_votes: 20,
      total_votes: 6939,
      percentage_of_votes: 0.55,
    },
    {
      serial_no: 9,
      candidate: "DWARAKA PRASAD LALA",
      party: "Independent",
      evm_votes: 5617,
      postal_votes: 10,
      total_votes: 5627,
      percentage_of_votes: 0.44,
    },
    {
      serial_no: 10,
      candidate: "KALAWATI DEVI",
      party: "Independent",
      evm_votes: 5414,
      postal_votes: 16,
      total_votes: 5430,
      percentage_of_votes: 0.43,
    },
    {
      serial_no: 11,
      candidate: "GYANESHWAR PRASAD",
      party: "Lokhit Adhikar Party",
      evm_votes: 5348,
      postal_votes: 8,
      total_votes: 5356,
      percentage_of_votes: 0.42,
    },
    {
      serial_no: 12,
      candidate: "SHIVJI PRASAD",
      party: "Right to Recall Party",
      evm_votes: 4248,
      postal_votes: 6,
      total_votes: 4254,
      percentage_of_votes: 0.34,
    },
    {
      serial_no: 13,
      candidate: "PRAMOD RAM",
      party: "Peoples Party of India (Democratic)",
      evm_votes: 3805,
      postal_votes: 20,
      total_votes: 3825,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 14,
      candidate: "USHA SINGH",
      party: "Independent",
      evm_votes: 3703,
      postal_votes: 58,
      total_votes: 3761,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 15,
      candidate: "PAPPU KUMAR NISHAD",
      party: "Bhartiya Lokmat Rashtrwadi Party",
      evm_votes: 3203,
      postal_votes: 13,
      total_votes: 3216,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 16,
      candidate: "SUBHASH KUMAR THAKUR",
      party: "Sarv Samaj Party",
      evm_votes: 2513,
      postal_votes: 4,
      total_votes: 2517,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 17,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 5927,
      postal_votes: 117,
      total_votes: 6044,
      percentage_of_votes: 0.48,
    },
  ],
  total: {
    evm_votes: 1253005,
    postal_votes: 11828,
    total_votes: 1264833,
  },
};
export const s299 = {
  cs_name: "Hyderabad",
  cs_shortname: "hyderabad",
  cs_code: "s299",
  winner: "AIMIM",
  stats: [
    {
      serial_no: 1,
      candidate: "ASADUDDIN OWAISI",
      party: "All India Majlis-E-Ittehadul Muslimeen",
      evm_votes: 659278,
      postal_votes: 2703,
      total_votes: 661981,
      percentage_of_votes: 61.28,
    },
    {
      serial_no: 2,
      candidate: "MADHAVI LATHA KOMPELLA",
      party: "Bharatiya Janata Party",
      evm_votes: 320481,
      postal_votes: 3413,
      total_votes: 323894,
      percentage_of_votes: 29.98,
    },
    {
      serial_no: 3,
      candidate: "MOHAMMED WALIULLAH SAMEER",
      party: "Indian National Congress",
      evm_votes: 62497,
      postal_votes: 465,
      total_votes: 62962,
      percentage_of_votes: 5.83,
    },
    {
      serial_no: 4,
      candidate: "SRINIVAS YADAV GADDAM",
      party: "Bharat Rashtra Samithi",
      evm_votes: 18498,
      postal_votes: 143,
      total_votes: 18641,
      percentage_of_votes: 1.73,
    },
    {
      serial_no: 5,
      candidate: "MEKALA RAGHUMA REDDY",
      party: "Yuga Thulasi Party",
      evm_votes: 2231,
      postal_votes: 1,
      total_votes: 2232,
      percentage_of_votes: 0.21,
    },
    {
      serial_no: 6,
      candidate: "DR.J.PADMAJA",
      party: "Viduthalai Chiruthaigal Katchi",
      evm_votes: 899,
      postal_votes: 1,
      total_votes: 900,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 7,
      candidate: "K.S.KRISHNA",
      party: "Bahujan Samaj Party",
      evm_votes: 763,
      postal_votes: 6,
      total_votes: 769,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 8,
      candidate: "RAMESH KUMAR MATANGI",
      party: "Sakala Janula Party",
      evm_votes: 663,
      postal_votes: 0,
      total_votes: 663,
      percentage_of_votes: 0.06,
    },
    {
      serial_no: 9,
      candidate: "KRANTHI KUMAR BANDELA",
      party: "Indian National Yuva Jana Party",
      evm_votes: 561,
      postal_votes: 1,
      total_votes: 562,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 10,
      candidate: "M.JOHNSON",
      party: "All India Mahila Empowerment Party",
      evm_votes: 515,
      postal_votes: 0,
      total_votes: 515,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 11,
      candidate: "JAMEEL SAYED",
      party: "Alliance of Democratic Reforms Party",
      evm_votes: 492,
      postal_votes: 0,
      total_votes: 492,
      percentage_of_votes: 0.05,
    },
    {
      serial_no: 12,
      candidate: "ASHOK KUMAR MAMBA",
      party: "Maa Telangana Party",
      evm_votes: 422,
      postal_votes: 1,
      total_votes: 423,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 13,
      candidate: "DR.LUBNA SARWATH",
      party: "Vidhyarthula Rajakiya Party",
      evm_votes: 410,
      postal_votes: 1,
      total_votes: 411,
      percentage_of_votes: 0.04,
    },
    {
      serial_no: 14,
      candidate: "SARAF TULASI GUPTA",
      party: "Praja Ektha Party",
      evm_votes: 281,
      postal_votes: 0,
      total_votes: 281,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 15,
      candidate: "V.SHANKER",
      party: "Yuva Taram Party",
      evm_votes: 275,
      postal_votes: 1,
      total_votes: 276,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 16,
      candidate: "GADDAM HARISH GOUD",
      party: "Dharma Samaj Party",
      evm_votes: 269,
      postal_votes: 2,
      total_votes: 271,
      percentage_of_votes: 0.03,
    },
    {
      serial_no: 17,
      candidate: "SYED ANWAR",
      party: "Independent",
      evm_votes: 251,
      postal_votes: 0,
      total_votes: 251,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 18,
      candidate: "KHAJA MOINUDDIN",
      party: "All India Majlis-E-Inquilab-E-Millat",
      evm_votes: 233,
      postal_votes: 1,
      total_votes: 234,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 19,
      candidate: "AMJAD KHAN",
      party: "Independent",
      evm_votes: 195,
      postal_votes: 1,
      total_votes: 196,
      percentage_of_votes: 0.02,
    },
    {
      serial_no: 20,
      candidate: "AMBI HANUMANTH RAO",
      party: "Socialist Party (India)",
      evm_votes: 161,
      postal_votes: 1,
      total_votes: 162,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 21,
      candidate: "SHAIK BASHA",
      party: "Dalita Bahujana Party",
      evm_votes: 153,
      postal_votes: 0,
      total_votes: 153,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 22,
      candidate: "M.K.AHMED",
      party: "Independent",
      evm_votes: 149,
      postal_votes: 0,
      total_votes: 149,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 23,
      candidate: "SALVERU RAMESH",
      party: "Indian Praja Congress",
      evm_votes: 141,
      postal_votes: 0,
      total_votes: 141,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 24,
      candidate: "L.ASHOKNATH",
      party: "Telangana Prajaa Jeevana Rythu Party",
      evm_votes: 141,
      postal_votes: 0,
      total_votes: 141,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 25,
      candidate: "J.SHYAMSUNDER RAO",
      party: "Independent",
      evm_votes: 138,
      postal_votes: 0,
      total_votes: 138,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 26,
      candidate: "ANDE USHA KANNA",
      party: "Bahujan Mukti Party",
      evm_votes: 133,
      postal_votes: 0,
      total_votes: 133,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 27,
      candidate: "SATTI SHABABU REDDY",
      party: "Independent",
      evm_votes: 123,
      postal_votes: 0,
      total_votes: 123,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 28,
      candidate: "ANIL SEN",
      party: "Independent",
      evm_votes: 111,
      postal_votes: 0,
      total_votes: 111,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 29,
      candidate: "CHAITANYA KUMAR REDDY PELLAKURU",
      party: "Independent",
      evm_votes: 110,
      postal_votes: 0,
      total_votes: 110,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 30,
      candidate: "SILIVERU NARESH",
      party: "Telangana Jaghir Party",
      evm_votes: 103,
      postal_votes: 0,
      total_votes: 103,
      percentage_of_votes: 0.01,
    },
    {
      serial_no: 31,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 2867,
      postal_votes: 39,
      total_votes: 2906,
      percentage_of_votes: 0.27,
    },
  ],
  total: {
    evm_votes: 1073544,
    postal_votes: 6780,
    total_votes: 1080324,
  },
};
export const s0412 = {
  cs_name: "Purnia",
  cs_shortname: "purnia",
  cs_code: "s0412",
  winner: "IND",
  stats: [
    {
      serial_no: 1,
      candidate: "RAJESH RANJAN ALIAS PAPPU YADAV",
      party: "Independent",
      evm_votes: 566867,
      postal_votes: 689,
      total_votes: 567556,
      percentage_of_votes: 47.46,
    },
    {
      serial_no: 2,
      candidate: "SANTOSH KUMAR",
      party: "Janata Dal (United)",
      evm_votes: 543313,
      postal_votes: 396,
      total_votes: 543709,
      percentage_of_votes: 45.47,
    },
    {
      serial_no: 3,
      candidate: "BIMA BHARTI",
      party: "Rashtriya Janata Dal",
      evm_votes: 27017,
      postal_votes: 103,
      total_votes: 27120,
      percentage_of_votes: 2.27,
    },
    {
      serial_no: 4,
      candidate: "ARUN DAS",
      party: "Bahujan Samaj Party",
      evm_votes: 10595,
      postal_votes: 24,
      total_votes: 10619,
      percentage_of_votes: 0.89,
    },
    {
      serial_no: 5,
      candidate: "SATYENDRA YADAV",
      party: "Independent",
      evm_votes: 8457,
      postal_votes: 2,
      total_votes: 8459,
      percentage_of_votes: 0.71,
    },
    {
      serial_no: 6,
      candidate: "NOMAN ALAM",
      party: "Independent",
      evm_votes: 7693,
      postal_votes: 0,
      total_votes: 7693,
      percentage_of_votes: 0.64,
    },
    {
      serial_no: 7,
      candidate: "KISHOR KUMAR YADAV",
      party: "All India Forward Bloc",
      evm_votes: 6846,
      postal_votes: 8,
      total_votes: 6854,
      percentage_of_votes: 0.57,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 23819,
      postal_votes: 15,
      total_votes: 23834,
      percentage_of_votes: 1.99,
    },
  ],
  total: {
    evm_votes: 1194607,
    postal_votes: 1237,
    total_votes: 1195844,
  },
};
export const s1344 = {
  cs_name: "Sangli",
  cs_shortname: "sangli",
  cs_code: "s1344",
  winner: "IND",
  stats: [
    {
      serial_no: 1,
      candidate: "VISHAL (DADA) PRAKASHBAPU PATIL",
      party: "Independent",
      evm_votes: 569651,
      postal_votes: 2015,
      total_votes: 571666,
      percentage_of_votes: 48.91,
    },
    {
      serial_no: 2,
      candidate: "SANJAY (KAKA) PATIL",
      party: "Bharatiya Janata Party",
      evm_votes: 469392,
      postal_votes: 2221,
      total_votes: 471613,
      percentage_of_votes: 40.35,
    },
    {
      serial_no: 3,
      candidate: "CHANDRAHAR SUBHASH PATIL",
      party: "Shiv Sena (Uddhav Balasaheb Thackrey)",
      evm_votes: 60115,
      postal_votes: 745,
      total_votes: 60860,
      percentage_of_votes: 5.21,
    },
    {
      serial_no: 4,
      candidate: "ALLAUDDIN HAYATCHAND KAZI",
      party: "Independent",
      evm_votes: 9269,
      postal_votes: 13,
      total_votes: 9282,
      percentage_of_votes: 0.79,
    },
    {
      serial_no: 5,
      candidate: "PRAKASH SHIVAJIRAO SHENDGE",
      party: "Independent",
      evm_votes: 8150,
      postal_votes: 101,
      total_votes: 8251,
      percentage_of_votes: 0.71,
    },
    {
      serial_no: 6,
      candidate: "PANDURANG RAOSAHEB BHOSALE",
      party: "Bharatheeya Jawan Kisan Party",
      evm_votes: 5518,
      postal_votes: 47,
      total_votes: 5565,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 7,
      candidate: "TIPUSULATAN SIKANDAR PATAVEGAR",
      party: "Bahujan Samaj Party",
      evm_votes: 5502,
      postal_votes: 58,
      total_votes: 5560,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 8,
      candidate: "MAHESH YASHWANT KHARADE",
      party: "Swabhimani Paksha",
      evm_votes: 5491,
      postal_votes: 30,
      total_votes: 5521,
      percentage_of_votes: 0.47,
    },
    {
      serial_no: 9,
      candidate: "RAVINDRA CHANDAR SOLANKAR",
      party: "Independent",
      evm_votes: 3649,
      postal_votes: 9,
      total_votes: 3658,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 10,
      candidate: "ANAND SHANKAR NALAGE",
      party: "Baliraja Party",
      evm_votes: 3531,
      postal_votes: 9,
      total_votes: 3540,
      percentage_of_votes: 0.3,
    },
    {
      serial_no: 11,
      candidate: "SHASHIKANT GAUTAM DESHMUKH",
      party: "Independent",
      evm_votes: 3143,
      postal_votes: 12,
      total_votes: 3155,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 12,
      candidate: "SATISH LALITA KRISHNA KADAM",
      party: "Hindustan Janta Party",
      evm_votes: 2851,
      postal_votes: 8,
      total_votes: 2859,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 13,
      candidate: "SUWARNATAI SUDHAKAR GAYAKWAD",
      party: "Independent",
      evm_votes: 1925,
      postal_votes: 12,
      total_votes: 1937,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 14,
      candidate: "DATTATRAY PANDIT PATIL",
      party: "Independent",
      evm_votes: 1468,
      postal_votes: 1,
      total_votes: 1469,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 15,
      candidate: "TOUHID ILAHI MOMIN",
      party: "Independent",
      evm_votes: 1460,
      postal_votes: 0,
      total_votes: 1460,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 16,
      candidate: "AJIT DHANAJI KHANDARE",
      party: "Independent",
      evm_votes: 1380,
      postal_votes: 18,
      total_votes: 1398,
      percentage_of_votes: 0.12,
    },
    {
      serial_no: 17,
      candidate: "BANDAGAR NANASO BALASO",
      party: "Independent",
      evm_votes: 1292,
      postal_votes: 8,
      total_votes: 1300,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 18,
      candidate: "JALINDAR MACHINDRA THOMAKE",
      party: "Independent",
      evm_votes: 1257,
      postal_votes: 3,
      total_votes: 1260,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 19,
      candidate: "SANGRAM RAJARAM MORE",
      party: "Independent",
      evm_votes: 1004,
      postal_votes: 7,
      total_votes: 1011,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 20,
      candidate: "DR AKASH VHATKAR",
      party: "Independent",
      evm_votes: 803,
      postal_votes: 11,
      total_votes: 814,
      percentage_of_votes: 0.07,
    },
    {
      serial_no: 21,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 6512,
      postal_votes: 53,
      total_votes: 6565,
      percentage_of_votes: 0.56,
    },
  ],
  total: {
    evm_votes: 1163363,
    postal_votes: 5381,
    total_votes: 1168744,
  },
};
export const s193 = {
  cs_name: "Khadoor_Sahib",
  cs_shortname: "khadoorsahib",
  cs_code: "s193",
  winner: "IND",
  stats: [
    {
      serial_no: 1,
      candidate: "AMRITPAL SINGH",
      party: "Independent",
      evm_votes: 402600,
      postal_votes: 1830,
      total_votes: 404430,
      percentage_of_votes: 38.62,
    },
    {
      serial_no: 2,
      candidate: "KULBIR SINGH ZIRA",
      party: "Indian National Congress",
      evm_votes: 206286,
      postal_votes: 1024,
      total_votes: 207310,
      percentage_of_votes: 19.8,
    },
    {
      serial_no: 3,
      candidate: "LALJIT SINGH BHULLAR",
      party: "Aam Aadmi Party",
      evm_votes: 194160,
      postal_votes: 676,
      total_votes: 194836,
      percentage_of_votes: 18.61,
    },
    {
      serial_no: 4,
      candidate: "VIRSA SINGH VALTOHA",
      party: "Shiromani Akali Dal",
      evm_votes: 86291,
      postal_votes: 125,
      total_votes: 86416,
      percentage_of_votes: 8.25,
    },
    {
      serial_no: 5,
      candidate: "MANJIT SINGH MANNA",
      party: "Bharatiya Janata Party",
      evm_votes: 85810,
      postal_votes: 563,
      total_votes: 86373,
      percentage_of_votes: 8.25,
    },
    {
      serial_no: 6,
      candidate: "PARAMJIT SINGH",
      party: "Independent",
      evm_votes: 12522,
      postal_votes: 3,
      total_votes: 12525,
      percentage_of_votes: 1.2,
    },
    {
      serial_no: 7,
      candidate: "CHAIN SINGH BAINKA",
      party: "Aas Punjab Party",
      evm_votes: 6728,
      postal_votes: 11,
      total_votes: 6739,
      percentage_of_votes: 0.64,
    },
    {
      serial_no: 8,
      candidate: "SATNAM SINGH",
      party: "Bahujan Samaj Party",
      evm_votes: 5021,
      postal_votes: 45,
      total_votes: 5066,
      percentage_of_votes: 0.48,
    },
    {
      serial_no: 9,
      candidate: "SARABJIT SINGH",
      party: "Independent",
      evm_votes: 4835,
      postal_votes: 1,
      total_votes: 4836,
      percentage_of_votes: 0.46,
    },
    {
      serial_no: 10,
      candidate: "GURDIAL SINGH",
      party: "Communist Party of India",
      evm_votes: 3945,
      postal_votes: 7,
      total_votes: 3952,
      percentage_of_votes: 0.38,
    },
    {
      serial_no: 11,
      candidate: "ANOKH SINGH KATWAL",
      party: "Independent",
      evm_votes: 2766,
      postal_votes: 3,
      total_votes: 2769,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 12,
      candidate: "GURPREET SINGH",
      party: "Independent",
      evm_votes: 2609,
      postal_votes: 0,
      total_votes: 2609,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 13,
      candidate: "AJIT SINGH",
      party: "Independent",
      evm_votes: 2528,
      postal_votes: 7,
      total_votes: 2535,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 14,
      candidate: "ARUN KUMAR",
      party: "Independent",
      evm_votes: 2522,
      postal_votes: 3,
      total_votes: 2525,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 15,
      candidate: "SURJIT SINGH BHIKHIWIND",
      party: "Independent",
      evm_votes: 2367,
      postal_votes: 4,
      total_votes: 2371,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 16,
      candidate: "KAWALJIT SINGH",
      party: "Independent",
      evm_votes: 2290,
      postal_votes: 4,
      total_votes: 2294,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 17,
      candidate: "HARJINDER SINGH",
      party: "Independent",
      evm_votes: 1969,
      postal_votes: 1,
      total_votes: 1970,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 18,
      candidate: "KANWALJIT SINGH",
      party: "Independent",
      evm_votes: 1895,
      postal_votes: 6,
      total_votes: 1901,
      percentage_of_votes: 0.18,
    },
    {
      serial_no: 19,
      candidate: "PARMINDER SINGH",
      party: "Independent",
      evm_votes: 1802,
      postal_votes: 1,
      total_votes: 1803,
      percentage_of_votes: 0.17,
    },
    {
      serial_no: 20,
      candidate: "JASWANT SINGH SOHAL",
      party: "Independent",
      evm_votes: 1637,
      postal_votes: 2,
      total_votes: 1639,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 21,
      candidate: "LAKHBIR SINGH",
      party: "Independent",
      evm_votes: 1420,
      postal_votes: 1,
      total_votes: 1421,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 22,
      candidate: "VIKRAMJIT SINGH",
      party: "Independent",
      evm_votes: 1417,
      postal_votes: 3,
      total_votes: 1420,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 23,
      candidate: "VIJAY KUMAR",
      party: "Independent",
      evm_votes: 1388,
      postal_votes: 0,
      total_votes: 1388,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 24,
      candidate: "DILBAGH SINGH",
      party: "All India Mazdoor Party (Rangreta)",
      evm_votes: 1363,
      postal_votes: 11,
      total_votes: 1374,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 25,
      candidate: "SIMRANJEET SINGH",
      party: "Independent",
      evm_votes: 1338,
      postal_votes: 3,
      total_votes: 1341,
      percentage_of_votes: 0.13,
    },
    {
      serial_no: 26,
      candidate: "MAHINDER SINGH HAMIRA",
      party: "Independent",
      evm_votes: 1044,
      postal_votes: 2,
      total_votes: 1046,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 27,
      candidate: "NAVEEN KUMAR SHARMA",
      party: "Sanjhi Virasat Party",
      evm_votes: 823,
      postal_votes: 1,
      total_votes: 824,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 28,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 3420,
      postal_votes: 32,
      total_votes: 3452,
      percentage_of_votes: 0.33,
    },
  ],
  total: {
    evm_votes: 1042796,
    postal_votes: 4369,
    total_votes: 1047165,
  },
};
export const s199 = {
  cs_name: "Faridkot",
  cs_shortname: "faridkot",
  cs_code: "s199",
  winner: "IND",
  stats: [
    {
      serial_no: 1,
      candidate: "SARABJEET SINGH KHALSA",
      party: "Independent",
      evm_votes: 296922,
      postal_votes: 1140,
      total_votes: 298062,
      percentage_of_votes: 29.38,
    },
    {
      serial_no: 2,
      candidate: "KARAMJIT SINGH ANMOL",
      party: "Aam Aadmi Party",
      evm_votes: 226676,
      postal_votes: 1333,
      total_votes: 228009,
      percentage_of_votes: 22.48,
    },
    {
      serial_no: 3,
      candidate: "AMARJIT KAUR SAHOKE",
      party: "Indian National Congress",
      evm_votes: 159352,
      postal_votes: 1005,
      total_votes: 160357,
      percentage_of_votes: 15.81,
    },
    {
      serial_no: 4,
      candidate: "RAJWINDER SINGH DHARAMKOT",
      party: "Shiromani Akali Dal",
      evm_votes: 137776,
      postal_votes: 475,
      total_votes: 138251,
      percentage_of_votes: 13.63,
    },
    {
      serial_no: 5,
      candidate: "HANS RAJ HANS",
      party: "Bharatiya Janata Party",
      evm_votes: 123007,
      postal_votes: 526,
      total_votes: 123533,
      percentage_of_votes: 12.18,
    },
    {
      serial_no: 6,
      candidate: "GURCHARAN SINGH MANN",
      party: "Communist Party of India",
      evm_votes: 14935,
      postal_votes: 15,
      total_votes: 14950,
      percentage_of_votes: 1.47,
    },
    {
      serial_no: 7,
      candidate: "GURBAKSH SINGH CHAUHAN",
      party: "Bahujan Samaj Party",
      evm_votes: 8129,
      postal_votes: 81,
      total_votes: 8210,
      percentage_of_votes: 0.81,
    },
    {
      serial_no: 8,
      candidate: "AVTAR SINGH SAHOTA",
      party: "Independent",
      evm_votes: 4155,
      postal_votes: 5,
      total_votes: 4160,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 9,
      candidate: "KIKKAR SINGH DHALIWAL",
      party: "Independent",
      evm_votes: 3514,
      postal_votes: 3,
      total_votes: 3517,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 10,
      candidate: "KARAM SINGH MALUKA",
      party: "Independent",
      evm_votes: 2859,
      postal_votes: 3,
      total_votes: 2862,
      percentage_of_votes: 0.28,
    },
    {
      serial_no: 11,
      candidate: "RUPINDER SINGH KOHARWALA",
      party: "Nationalist Justice Party",
      evm_votes: 2672,
      postal_votes: 5,
      total_votes: 2677,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 12,
      candidate: "BALDEV SINGH GAGRA",
      party: "Shiromani Akali Dal (Amritsar)(Simranjit Singh Mann)",
      evm_votes: 2367,
      postal_votes: 28,
      total_votes: 2395,
      percentage_of_votes: 0.24,
    },
    {
      serial_no: 13,
      candidate: "NIRMAL SINGH RAJEANA",
      party: "Independent",
      evm_votes: 2287,
      postal_votes: 2,
      total_votes: 2289,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 14,
      candidate: "BADAL SINGH BHALOOR",
      party: "Bhartiya Rashtriya Dal",
      evm_votes: 2027,
      postal_votes: 1,
      total_votes: 2028,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 15,
      candidate: "RAJ KUMAR CHAUHAN",
      party: "Independent",
      evm_votes: 1958,
      postal_votes: 3,
      total_votes: 1961,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 16,
      candidate: "OM PARKASH BANKA",
      party: "Independent",
      evm_votes: 1947,
      postal_votes: 3,
      total_votes: 1950,
      percentage_of_votes: 0.19,
    },
    {
      serial_no: 17,
      candidate: "CAPTAIN BAHADUR SINGH",
      party: "Independent",
      evm_votes: 1632,
      postal_votes: 8,
      total_votes: 1640,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 18,
      candidate: "MEJOR SINGH BHATTI",
      party: "Jan Sewa Driver Party",
      evm_votes: 1605,
      postal_votes: 7,
      total_votes: 1612,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 19,
      candidate: "SUKHBIR SINGH BABBAL BHATTI",
      party: "Rashtriya Republican Party",
      evm_votes: 1526,
      postal_votes: 4,
      total_votes: 1530,
      percentage_of_votes: 0.15,
    },
    {
      serial_no: 20,
      candidate: "GURMEET SINGH",
      party: "Independent",
      evm_votes: 1419,
      postal_votes: 1,
      total_votes: 1420,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 21,
      candidate: "AMRIK SINGH",
      party: "Independent",
      evm_votes: 1411,
      postal_votes: 3,
      total_votes: 1414,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 22,
      candidate: "MANPREET SHANT",
      party: "Independent",
      evm_votes: 1376,
      postal_votes: 1,
      total_votes: 1377,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 23,
      candidate: "PREM LAL",
      party: "Democratic Bharatiya Samaj Party",
      evm_votes: 1165,
      postal_votes: 0,
      total_votes: 1165,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 24,
      candidate: "PARGAT SINGH RAJEANA",
      party: "Apna Samaj Party",
      evm_votes: 1128,
      postal_votes: 3,
      total_votes: 1131,
      percentage_of_votes: 0.11,
    },
    {
      serial_no: 25,
      candidate: "JASWANT RAI RAJORA",
      party: "Independent",
      evm_votes: 1042,
      postal_votes: 5,
      total_votes: 1047,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 26,
      candidate: "DR DEV INDER GAGALANI",
      party: "Republican Party of India",
      evm_votes: 1035,
      postal_votes: 5,
      total_votes: 1040,
      percentage_of_votes: 0.1,
    },
    {
      serial_no: 27,
      candidate: "PRITAM SINGH",
      party: "Bahujan Mukti Party",
      evm_votes: 927,
      postal_votes: 12,
      total_votes: 939,
      percentage_of_votes: 0.09,
    },
    {
      serial_no: 28,
      candidate: "KULWANT KAUR",
      party: "Sanjhi Virasat Party",
      evm_votes: 785,
      postal_votes: 1,
      total_votes: 786,
      percentage_of_votes: 0.08,
    },
    {
      serial_no: 29,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4077,
      postal_votes: 66,
      total_votes: 4143,
      percentage_of_votes: 0.41,
    },
  ],
  total: {
    evm_votes: 1009711,
    postal_votes: 4744,
    total_votes: 1014455,
  },
};
export const u031 = {
  cs_name: "Daman_&_Diu",
  cs_shortname: "daman&diu",
  cs_code: "u031",
  winner: "IND",
  stats: [
    {
      serial_no: 1,
      candidate: "PATEL UMESHBHAI BABUBHAI",
      party: "Independent",
      evm_votes: 42469,
      postal_votes: 54,
      total_votes: 42523,
      percentage_of_votes: 46.02,
    },
    {
      serial_no: 2,
      candidate: "LALUBHAI BABUBHAI PATEL",
      party: "Bharatiya Janata Party",
      evm_votes: 36243,
      postal_votes: 55,
      total_votes: 36298,
      percentage_of_votes: 39.28,
    },
    {
      serial_no: 3,
      candidate: "KETAN DAHYABHAI PATEL",
      party: "Indian National Congress",
      evm_votes: 11237,
      postal_votes: 21,
      total_votes: 11258,
      percentage_of_votes: 12.18,
    },
    {
      serial_no: 4,
      candidate: "UMESH PATEL",
      party: "Navsarjan Bharat Party",
      evm_votes: 545,
      postal_votes: 0,
      total_votes: 545,
      percentage_of_votes: 0.59,
    },
    {
      serial_no: 5,
      candidate: "SAKIL LATIF KHAN",
      party: "Independent",
      evm_votes: 323,
      postal_votes: 0,
      total_votes: 323,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 6,
      candidate: "IDRISH G MULLA",
      party: "Independent",
      evm_votes: 247,
      postal_votes: 0,
      total_votes: 247,
      percentage_of_votes: 0.27,
    },
    {
      serial_no: 7,
      candidate: "PATEL UMESHBHAI BABU",
      party: "Independent",
      evm_votes: 238,
      postal_votes: 0,
      total_votes: 238,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 8,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 977,
      postal_votes: 1,
      total_votes: 978,
      percentage_of_votes: 1.06,
    },
  ],
  total: {
    evm_votes: 92279,
    postal_votes: 131,
    total_votes: 92410,
  },
};
export const u081 = {
  cs_name: "BARAMULLA",
  cs_shortname: "baramulla",
  cs_code: "u081",
  winner: "IND",
  stats: [
    {
      serial_no: 1,
      candidate: "ABDUL RASHID SHEIKH",
      party: "Independent",
      evm_votes: 469574,
      postal_votes: 2907,
      total_votes: 472481,
      percentage_of_votes: 45.7,
    },
    {
      serial_no: 2,
      candidate: "OMAR ABDULLAH",
      party: "Jammu & Kashmir National Conference",
      evm_votes: 266301,
      postal_votes: 2038,
      total_votes: 268339,
      percentage_of_votes: 25.95,
    },
    {
      serial_no: 3,
      candidate: "SAJAD GANI LONE",
      party: "Jammu & Kashmir People Conference",
      evm_votes: 171582,
      postal_votes: 1657,
      total_votes: 173239,
      percentage_of_votes: 16.76,
    },
    {
      serial_no: 4,
      candidate: "MIR MOHAMMAD FAYAZ",
      party: "Jammu & Kashmir Peoples Democratic Party",
      evm_votes: 27173,
      postal_votes: 315,
      total_votes: 27488,
      percentage_of_votes: 2.66,
    },
    {
      serial_no: 5,
      candidate: "SURAIYA NISSAR",
      party: "Independent",
      evm_votes: 21565,
      postal_votes: 53,
      total_votes: 21618,
      percentage_of_votes: 2.09,
    },
    {
      serial_no: 6,
      candidate: "FIRDOUS AHMAD BHAT",
      party: "Independent",
      evm_votes: 15707,
      postal_votes: 15,
      total_votes: 15722,
      percentage_of_votes: 1.52,
    },
    {
      serial_no: 7,
      candidate: "SHAFEEQA BEGUM",
      party: "Independent",
      evm_votes: 6203,
      postal_votes: 11,
      total_votes: 6214,
      percentage_of_votes: 0.6,
    },
    {
      serial_no: 8,
      candidate: "PEERZADA MUDASIR RASHID SHAH",
      party: "Jammu & Kashmir National Panthers Party (Bhim)",
      evm_votes: 5499,
      postal_votes: 67,
      total_votes: 5566,
      percentage_of_votes: 0.54,
    },
    {
      serial_no: 9,
      candidate: "KHURSHEED AHMAD SHAH",
      party: "Rashtriya Jankranti Party",
      evm_votes: 4213,
      postal_votes: 55,
      total_votes: 4268,
      percentage_of_votes: 0.41,
    },
    {
      serial_no: 10,
      candidate: "MOHAMMAD SULTAN GANAIE",
      party: "Independent",
      evm_votes: 4054,
      postal_votes: 9,
      total_votes: 4063,
      percentage_of_votes: 0.39,
    },
    {
      serial_no: 11,
      candidate: "SHADIB HANIEF KHAN",
      party: "Independent",
      evm_votes: 3668,
      postal_votes: 2,
      total_votes: 3670,
      percentage_of_votes: 0.35,
    },
    {
      serial_no: 12,
      candidate: "MUDASIR AHMAD TANTARY",
      party: "Independent",
      evm_votes: 3196,
      postal_votes: 22,
      total_votes: 3218,
      percentage_of_votes: 0.31,
    },
    {
      serial_no: 13,
      candidate: "NAZIR AHMAD SOFI",
      party: "Independent",
      evm_votes: 2980,
      postal_votes: 3,
      total_votes: 2983,
      percentage_of_votes: 0.29,
    },
    {
      serial_no: 14,
      candidate: "SHABIR AHMAD DAR",
      party: "Independent",
      evm_votes: 2694,
      postal_votes: 6,
      total_votes: 2700,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 15,
      candidate: "HILAL AHMAD WAGAY",
      party: "Independent",
      evm_votes: 2681,
      postal_votes: 8,
      total_votes: 2689,
      percentage_of_votes: 0.26,
    },
    {
      serial_no: 16,
      candidate: "MEHRAJ UDDIN NAJAR",
      party: "Independent",
      evm_votes: 2560,
      postal_votes: 10,
      total_votes: 2570,
      percentage_of_votes: 0.25,
    },
    {
      serial_no: 17,
      candidate: "SYED AMEER SUHAIL",
      party: "Independent",
      evm_votes: 2409,
      postal_votes: 8,
      total_votes: 2417,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 18,
      candidate: "FAROOQ AHMAD BHAT",
      party: "National Youth Party",
      evm_votes: 2315,
      postal_votes: 28,
      total_votes: 2343,
      percentage_of_votes: 0.23,
    },
    {
      serial_no: 19,
      candidate: "ARUN KUMAR RAINA",
      party: "Independent",
      evm_votes: 2161,
      postal_votes: 80,
      total_votes: 2241,
      percentage_of_votes: 0.22,
    },
    {
      serial_no: 20,
      candidate: "MUNIR AHMAD KHAN",
      party: "Jammu and Kashmir Nationalist People's Front",
      evm_votes: 2004,
      postal_votes: 15,
      total_votes: 2019,
      percentage_of_votes: 0.2,
    },
    {
      serial_no: 21,
      candidate: "MUZAFFAR HUSSAIN DAR",
      party: "Independent",
      evm_votes: 1663,
      postal_votes: 8,
      total_votes: 1671,
      percentage_of_votes: 0.16,
    },
    {
      serial_no: 22,
      candidate: "MUSHTAQ AHMAD MIR",
      party: "National Loktantrik Party",
      evm_votes: 1382,
      postal_votes: 15,
      total_votes: 1397,
      percentage_of_votes: 0.14,
    },
    {
      serial_no: 23,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 4873,
      postal_votes: 111,
      total_votes: 4984,
      percentage_of_votes: 0.48,
    },
  ],
  total: {
    evm_votes: 1026457,
    postal_votes: 7443,
    total_votes: 1033900,
  },
};
export const u091 = {
  cs_name: "Ladakh",
  cs_shortname: "ladakh",
  cs_code: "u091",
  winner: "IND",
  stats: [
    {
      serial_no: 1,
      candidate: "MOHMAD HANEEFA",
      party: "Independent",
      evm_votes: 64443,
      postal_votes: 816,
      total_votes: 65259,
      percentage_of_votes: 48.15,
    },
    {
      serial_no: 2,
      candidate: "TSERING NAMGYAL",
      party: "Indian National Congress",
      evm_votes: 35770,
      postal_votes: 1627,
      total_votes: 37397,
      percentage_of_votes: 27.59,
    },
    {
      serial_no: 3,
      candidate: "TASHI GYALSON",
      party: "Bharatiya Janata Party",
      evm_votes: 31505,
      postal_votes: 451,
      total_votes: 31956,
      percentage_of_votes: 23.58,
    },
    {
      serial_no: 4,
      candidate: "NOTA",
      party: "None of the Above",
      evm_votes: 896,
      postal_votes: 16,
      total_votes: 912,
      percentage_of_votes: 0.67,
    },
  ],
  total: {
    evm_votes: 132614,
    postal_votes: 2910,
    total_votes: 135524,
  },
};

export const cs_all_data = [
  s015,
  s018,
  s019,
  s021,
  s022,
  s034,
  s035,
  s036,
  s037,
  s038,
  s0310,
  s0311,
  s0312,
  s0313,
  s042,
  s043,
  s046,
  s049,
  s0414,
  s0415,
  s0419,
  s0420,
  s0422,
  s0424,
  s0430,
  s0439,
  s051,
  s061,
  s063,
  s064,
  s065,
  s066,
  s067,
  s068,
  s069,
  s0610,
  s0611,
  s0612,
  s0613,
  s0614,
  s0615,
  s0616,
  s0617,
  s0618,
  s0619,
  s0620,
  s0621,
  s0622,
  s0623,
  s0624,
  s0625,
  s0626,
  s072,
  s075,
  s078,
  s079,
  s0710,
  s081,
  s082,
  s083,
  s084,
  s102,
  s103,
  s104,
  s1010,
  s1011,
  s1012,
  s1014,
  s1015,
  s1017,
  s1018,
  s1019,
  s1021,
  s1023,
  s1024,
  s1025,
  s1026,
  s1027,
  s1110,
  s121,
  s122,
  s123,
  s124,
  s125,
  s126,
  s127,
  s128,
  s129,
  s1210,
  s1211,
  s1212,
  s1213,
  s1214,
  s1215,
  s1216,
  s1217,
  s1218,
  s1219,
  s1220,
  s1221,
  s1222,
  s1223,
  s1224,
  s1225,
  s1226,
  s1227,
  s1228,
  s1229,
  s133,
  s134,
  s136,
  s1310,
  s1322,
  s1326,
  s1334,
  s1345,
  s1346,
  s181,
  s182,
  s183,
  s184,
  s185,
  s186,
  s187,
  s188,
  s189,
  s1810,
  s1811,
  s1812,
  s1813,
  s1814,
  s1815,
  s1816,
  s1817,
  s1818,
  s1819,
  s1820,
  s202,
  s206,
  s207,
  s208,
  s2013,
  s2015,
  s2016,
  s2018,
  s2019,
  s2021,
  s2022,
  s2023,
  s2024,
  s2025,
  s231,
  s232,
  s249,
  s2410,
  s2412,
  s2413,
  s2414,
  s2415,
  s2416,
  s2417,
  s2418,
  s2419,
  s2425,
  s2426,
  s2427,
  s2431,
  s2432,
  s2433,
  s2435,
  s2440,
  s2443,
  s2444,
  s2446,
  s2451,
  s2456,
  s2457,
  s2459,
  s2460,
  s2463,
  s2464,
  s2465,
  s2466,
  s2467,
  s2477,
  s2478,
  s252,
  s253,
  s254,
  s255,
  s256,
  s257,
  s2513,
  s2514,
  s2530,
  s2531,
  s2535,
  s2537,
  s261,
  s262,
  s263,
  s265,
  s266,
  s267,
  s268,
  s269,
  s2610,
  s2611,
  s273,
  s274,
  s275,
  s277,
  s278,
  s279,
  s2713,
  s2714,
  s281,
  s282,
  s283,
  s284,
  s285,
  s291,
  s293,
  s294,
  s296,
  s297,
  s298,
  s2910,
  s2911,
  u011,
  u032,
  u051,
  u052,
  u053,
  u054,
  u055,
  u056,
  u057,
  u084,
  u085,
  s032,
  s039,
  s0314,
  s0410,
  s0411,
  s0434,
  s052,
  s062,
  s071,
  s073,
  s074,
  s076,
  s077,
  s101,
  s105,
  s106,
  s107,
  s108,
  s109,
  s1013,
  s1016,
  s1022,
  s111,
  s112,
  s113,
  s114,
  s115,
  s118,
  s1111,
  s1112,
  s1113,
  s1115,
  s1116,
  s1117,
  s1119,
  s1120,
  s131,
  s132,
  s137,
  s139,
  s1311,
  s1312,
  s1313,
  s1316,
  s1318,
  s1329,
  s1341,
  s1342,
  s1347,
  s141,
  s142,
  s152,
  s171,
  s1821,
  s191,
  s192,
  s194,
  s197,
  s198,
  s1910,
  s1913,
  s201,
  s203,
  s204,
  s209,
  s2010,
  s2011,
  s2012,
  s2017,
  s221,
  s229,
  s2223,
  s2226,
  s2228,
  s2231,
  s2234,
  s2238,
  s2239,
  s241,
  s2430,
  s2436,
  s2437,
  s2452,
  s2453,
  s258,
  s264,
  s2711,
  s2712,
  s292,
  s295,
  s2912,
  s2913,
  s2914,
  s2915,
  s2916,
  s2917,
  u021,
  u061,
  u071,
  s242,
  s243,
  s246,
  s247,
  s248,
  s2420,
  s2421,
  s2422,
  s2423,
  s2424,
  s2428,
  s2429,
  s2434,
  s2438,
  s2439,
  s2441,
  s2442,
  s2445,
  s2447,
  s2448,
  s2449,
  s2450,
  s2454,
  s2455,
  s2458,
  s2461,
  s2462,
  s2468,
  s2469,
  s2470,
  s2471,
  s2472,
  s2473,
  s2474,
  s2475,
  s2476,
  s2480,
  s251,
  s259,
  s2510,
  s2511,
  s2512,
  s2515,
  s2516,
  s2517,
  s2518,
  s2519,
  s2520,
  s2521,
  s2522,
  s2523,
  s2524,
  s2525,
  s2526,
  s2527,
  s2528,
  s2529,
  s2532,
  s2533,
  s2534,
  s2536,
  s2538,
  s2539,
  s2540,
  s2541,
  s2542,
  s222,
  s223,
  s224,
  s225,
  s226,
  s227,
  s228,
  s2210,
  s2211,
  s2212,
  s2214,
  s2215,
  s2216,
  s2217,
  s2219,
  s2220,
  s2221,
  s2225,
  s2230,
  s2233,
  s2236,
  s2237,
  s012,
  s013,
  s014,
  s017,
  s0110,
  s0112,
  s0113,
  s0114,
  s0115,
  s0116,
  s0117,
  s0118,
  s0119,
  s0120,
  s0122,
  s0125,
  s041,
  s044,
  s045,
  s047,
  s048,
  s0413,
  s0417,
  s0418,
  s0426,
  s0427,
  s0428,
  s0429,
  s1314,
  s1315,
  s1317,
  s1321,
  s1328,
  s1330,
  s1331,
  s1338,
  s1340,
  s138,
  s1320,
  s1323,
  s1335,
  s1336,
  s1337,
  s1339,
  s1343,
  s135,
  s1319,
  s1324,
  s1325,
  s1327,
  s1333,
  s1348,
  s0416,
  s0421,
  s0423,
  s0425,
  s0440,
  s011,
  s0121,
  s0123,
  s0124,
  s0431,
  s0433,
  s0436,
  s0437,
  s119,
  s205,
  s2222,
  s2232,
  s116,
  s117,
  s2235,
  s195,
  s196,
  s1912,
  s271,
  s272,
  s2710,
  s016,
  s0111,
  s0432,
  s0435,
  s1020,
  s1028,
  s2213,
  s2227,
  s2218,
  s2229,
  s244,
  s2411,
  u082,
  u083,
  s031,
  s033,
  s0438,
  s1114,
  s1118,
  s1332,
  s151,
  s161,
  s1911,
  s2014,
  s2020,
  s211,
  s2224,
  s245,
  s2479,
  s276,
  s299,
  s0412,
  s1344,
  s193,
  s199,
  u031,
  u081,
  u091,
];
