"use strict";
import * as cs_result from "../data/csresult_test.js";
import * as phase_result from "../data/phase_result.js";
import { party_map } from "../data/party_map_out.js";
// import * as party_logos from "../images/party_logos/*.png";
import {
  filterFunction,
  sortingfilter_party,
  sortingfilter_wmargin,
  sortingfilter_cs,
  sortingfilter_totalevm,
  sortingfilter_countedevm,
  sortingfilter_verdict,
} from "./modules/utility-eci01.js";

// import party_logos from "../images/party_logos/*.png";

const cs_data_core = cs_result.cs_all_data;
const ph_data_core = phase_result.phases_data;
const party_map_core = party_map;
// console.log(party_map);

let cs_data = {};
let ph_data = {};
let party_data = {};

//// USER SCREEN PARAMS
// const MOBILE_SCREEN_SIZE=1120px;
const DESKTOP_SCREEN_SIZE = 1200;

cs_data = cs_data_core;
ph_data = ph_data_core;
party_data = party_map_core;
// console.log(typeof cs_data);

// console.log(party_data);

// console.log(party_data.find((g) => g.party_full_name == "Samajwadi Party"));

////////////////////////////////////////////////
// _    _ _______ __  __ _
// | |  | |__   __|  \/  | |
// | |__| |  | |  | \  / | |
// |  __  |  | |  | |\/| | |
// | |  | |  | |  | |  | | |____
// |_|  |_|  |_|  |_|  |_|______|
///////////////////////////////////////////////

//// Selecting the HTML components //////

//////////////////////////////////////////
const totalCSwithDiscrepancy = document.querySelector(".cwdCalc01");
const totalCSwithExcessEvmVotes = document.querySelector(".eevCalc01");
const totalCSwithDiscardedEvmVotes = document.querySelector(".devCalc01");
const totalCSwithDMatchedEvmVotes = document.querySelector(".vcmCalc01");
const totalEVMDiscardedVotes = document.querySelector(".tevdCalc01");
const totalEVMExtraVotes = document.querySelector(".teevfCalc01");

const MobileTableRender = document.querySelector(".mobile_content");
const DesktopTableRender = document.querySelector(".desktop_table_content");
const selectMobileTableView = document.querySelector(".mobile-table-parent");
const selectDesktopTableView = document.querySelector(".desktop-table-parent");

// console.log(DesktopTableRender);

//// SELECTING INTRACTIVE ELEMENS

const bigInputBox = document.querySelector(".inputBox_UIF");
const selectSortDropper = document.querySelector(".selclass-1");
const selectPartyDropper = document.querySelector(".selclass-2");

const winningMargin = document.querySelector(".winning_mar_m");
const WMSort = document.querySelector(".party_sort_m");
// const partySortM = document.querySelector(".party_sort_m");
// const partySortM = document.querySelector(".party_sort_m");
// const partySortM = document.querySelector(".party_sort_m");

/// SORT ELEMENTS
const sor_partyElem = document.querySelector(".party_sort");
const sor_winningmElem = document.querySelector(".winm_sort");
const sor_csnameElem = document.querySelector(".cs_sort");
const sor_totalevmElem = document.querySelector(".totalevm_sort");
const sor_countedevmElem = document.querySelector(".countevm_sort");
const sor_verdictElem = document.querySelector(".verdict_sort");

const sor_partyElemD = document.querySelector(".party_elem");
const sor_winningmElemD = document.querySelector(".winning_margin_elem");
const sor_csnameElemD = document.querySelector(".cs_name_elem");
const sor_totalevmElemD = document.querySelector(".totalevm_elem");
const sor_countedevmElemD = document.querySelector(".countedevm_elem");
const sor_verdictElemD = document.querySelector(".verdict_elem");

// BOOLEANS
let sortParty = false,
  sortWinningM = false,
  sortCsName = false,
  sortTotalEvm = false,
  sortCountedEvm = false,
  sortVerdict = false;

// console.log(bigInputBox);

// /// DEMO DISABLE MOBILE VIEW
// selectMobileTableView.classList.add("display-none");

// /// DEMO DISABLE DESKTOP VIEW
// selectDesktopTableView.classList.add("display-none");
////////////////////////////////////////////////
// ╦ ╦╔╦╗╔╦╗╦    ╔═╗╔╗╔╔╦╗╔═╗  ╦ ╦╔═╗╦═╗╔═╗
// ╠═╣ ║ ║║║║    ║╣ ║║║ ║║╚═╗  ╠═╣║╣ ╠╦╝║╣
// ╩ ╩ ╩ ╩ ╩╩═╝  ╚═╝╝╚╝═╩╝╚═╝  ╩ ╩╚═╝╩╚═╚═╝
////////////////////////////////////////////////

////////////////////////////////////////////////
// TRACK USER SCREEN SIZE
let user_Screen_currentwidth;
// console.log(user_Screen_currentwidth);

//// Function for Switching the Table View based on the screen size
const responsiveModuleSwitcher = function (event) {
  user_Screen_currentwidth =
    window.innerWidth > 0 ? window.innerWidth : screen.width;
  // console.log(user_Screen_currentwidth);
  // /REBUILDING DATA
  // rebuildData(cs_data, ph_data);

  // console.log(cs_data);

  if (+user_Screen_currentwidth >= +DESKTOP_SCREEN_SIZE) {
    // console.log("DESKTOP SCREEN DETECTED");
    // DO SOMETHING FOR DESKTOP
    ///  DISABLE MOBILE VIEW
    selectMobileTableView.classList.add("display-none");
    selectDesktopTableView.classList.remove("display-none");
  }
  if (+user_Screen_currentwidth < +DESKTOP_SCREEN_SIZE) {
    // console.log("MOBILE DEVICE DETECTED");
    // DO SOMETHING FOR MOBILE
    ///  DISABLE DESKTOP VIEW
    selectDesktopTableView.classList.add("display-none");
    selectMobileTableView.classList.remove("display-none");
  }
};

///// function for updatind the display elements of main cards stats
const displayHeroStats = function (
  CsWithDiscrepancy = 0,
  CsWithExcessVotes = 0,
  CsWithDiscardedVotes = 0,
  CsWithCountMatched = 0,
  discardedVotesCount = 0,
  extraVoteCounts = 0
) {
  totalCSwithDiscrepancy.innerHTML = CsWithDiscrepancy;
  totalCSwithExcessEvmVotes.innerHTML = CsWithExcessVotes;
  totalCSwithDiscardedEvmVotes.innerHTML = CsWithDiscardedVotes;
  totalCSwithDMatchedEvmVotes.innerHTML = CsWithCountMatched;
  totalEVMDiscardedVotes.innerHTML = discardedVotesCount;
  totalEVMExtraVotes.innerHTML = extraVoteCounts;
};

////////////////////////////
const phase_shortcodes = ph_data.flat(2).map((e) => e.cs_short);
const cs_shortcodes = cs_data.map((e) => e.cs_shortname);

const differenceMargin = function (candidate1_Votes, candidate2_Votes) {
  return +candidate1_Votes - +candidate2_Votes;
};

let count = 0;
let count1 = 0;

// const rebuildData = function (cs_data_func, ph_data) {
//   // console.log("===========================");
//   // console.log(cs_data_func);
//   // console.log("===========================");
//   const cs_length_chk = cs_data_func.length ?? 0;
//   const ph_length_chk = ph_data.length ?? 0;

//   DesktopTableRender.innerHTML = "";
//   MobileTableRender.innerHTML = "";
//   // console.log(cs_data_func);
//   // if (+cs_length_chk > 0 && +ph_length_chk > 0) {
//   cs_data_func.map((e) => {
//     const findPhase_cs =
//       ph_data.flat().find((f) => f.cs_short == e.cs_shortname) ?? 0;
//     // console.log(e);
//     const counted_Evm_VotesCS_Wise = +e.total.evm_votes;
//     const tottal_Evm_VotesCS_Wise = findPhase_cs.evm_votes ?? 0;

//     const winningMargin_EVM_CSWise = Math.abs(
//       differenceMargin(+e.stats[0].evm_votes || 0, +e.stats[1]?.evm_votes || 0)
//     );

//     const diffrence_EVM_VotesCS_Wise = differenceMargin(
//       +e.total.evm_votes || 0,
//       +findPhase_cs.evm_votes || 0
//     );

//     //// Logged Winning Candidate Margin
//     e.evm_candidate_winning_margin = winningMargin_EVM_CSWise;
//     //// Logged After Particual Phase End ::::
//     e.loggedEVMvotesAfterPhaseEnd = tottal_Evm_VotesCS_Wise;

//     e.evmRecordedVsCountedDifference = diffrence_EVM_VotesCS_Wise;
//     //// Counted on RESULT Day ::::
//     e.countedEVM_VotesOn4thJune = e.total.evm_votes;
//     e.state_name = findPhase_cs.state;

//     const verdictEVM = function (diffrence_EVM_VotesCS_Wise) {
//       if (diffrence_EVM_VotesCS_Wise > 0) {
//         return `+${diffrence_EVM_VotesCS_Wise} ( ⬆️ EXCESS EVM VOTES)`;
//       } else if (diffrence_EVM_VotesCS_Wise < 0) {
//         return `${diffrence_EVM_VotesCS_Wise} ( 🚫 DISCARDED EVM VOTES)`;
//       } else {
//         return `✅ 100% EVM VOTES MATCHED!!! (POLL DAY vs COUNT DAY)`;
//       }
//     };
//     count++;
//     // console.log(count);
//     // console.log(findPhase_cs.state);

//     // console.log(
//     //   `${e.cs_shortname.toUpperCase()} >>> CANDIDATE EVM_Winning Margin: (${winningMargin_EVM_CSWise}) | WINNING PARTY: (${
//     //     e.stats[0].party
//     //   }-${
//     //     e.winner
//     //   }) |  TOTAL EVM_VOTES: ${tottal_Evm_VotesCS_Wise} | COUNTED EVM_VOTES: ${counted_Evm_VotesCS_Wise} | OBSERVATION: ${verdictEVM(
//     //     diffrence_EVM_VotesCS_Wise
//     //   )} | STATE: ${
//     //     e.state_name ? e.state_name.toUpperCase() : e.statename.toUpperCase()
//     //   }`
//     // );

//     // console.log("-------------------------------");
//     return e.total.evm_votes;
//   });

//   // console.log(Object.keys(cs_data_func).length);
//   /// Counting the CS with Discrepancy
//   const TotalCsWithDiscr = cs_data_func
//     .map((e) => e.evmRecordedVsCountedDifference)
//     .filter((e) => +e != 0);

//   const TotalCsWithDiscrCount = TotalCsWithDiscr.length;

//   /// Counting the CS with EXCESS VOTES
//   const TotalCsWithExcess = cs_data_func
//     .map((e) => e.evmRecordedVsCountedDifference)
//     .filter((e) => +e > 0);

//   const TotalCsWithExcessCount = TotalCsWithExcess.length;
//   // console.log(TotalCsWithExcessCount);

//   /// Counting the CS with Discarded Votes
//   const TotalCsWithDiscarded = cs_data_func
//     .map((e) => e.evmRecordedVsCountedDifference)
//     .filter((e) => +e < 0);

//   const TotalCsWithDiscardCount = TotalCsWithDiscarded.length;
//   // console.log(TotalCsWithDiscardCount);

//   /// Counting the CS with Matched Votes
//   const TotalCsWithMatched = cs_data_func
//     .map((e) => e.evmRecordedVsCountedDifference)
//     .filter((e) => +e == 0);

//   const TotalCsWithMatchedCount = TotalCsWithMatched.length;
//   // console.log(TotalCsWithMatchedCount);

//   /// TOTAL EXCESS VOTES
//   const TotalExcessVotes = cs_data_func
//     .map((e) => e.evmRecordedVsCountedDifference)
//     .filter((e) => +e > 0)
//     .reduce((a, c) => a + c, 0);

//   // const TotalCsWithExcessCount = TotalCsWithExcess.length;
//   // console.log(TotalExcessVotes);

//   /// TOTAL Discarded Votes
//   const TotalDiscardedVotes = cs_data_func
//     .map((e) => e.evmRecordedVsCountedDifference)
//     .filter((e) => +e < 0)
//     .reduce((a, c) => a + c, 0);

//   // console.log(TotalDiscardedVotes);

//   displayHeroStats(
//     TotalCsWithDiscrCount,
//     TotalCsWithExcessCount,
//     TotalCsWithDiscardCount,
//     TotalCsWithMatchedCount,
//     Math.abs(TotalDiscardedVotes),
//     TotalExcessVotes
//   );

//   ///// SHOWING TABLE BASED ON SCREEN SIZE

//   user_Screen_currentwidth =
//     window.innerWidth > 0 ? window.innerWidth : screen.width;
//   // console.log(user_Screen_currentwidth);

//   if (+user_Screen_currentwidth >= +DESKTOP_SCREEN_SIZE) {
//     // console.log("DESKTOP SCREEN DETECTED");
//     // DO SOMETHING FOR DESKTOP
//     ///  DISABLE MOBILE VIEW
//     selectMobileTableView.classList.add("display-none");
//     selectDesktopTableView.classList.remove("display-none");

//     cs_data_func.forEach((e) => {
//       console.log(e);
//       const partyName = e.stats[0].party;
//       const winningMargin = e.evm_candidate_winning_margin;
//       const cs_name = e.cs_name;
//       const state_name = e.state_name;
//       const total_evm_votes = e.loggedEVMvotesAfterPhaseEnd;
//       const counted_evm_votes = e.countedEVM_VotesOn4thJune;
//       const verdict_num =
//         e.evmRecordedVsCountedDifference > 0
//           ? "+" + e.evmRecordedVsCountedDifference
//           : e.evmRecordedVsCountedDifference;
//       let verdict_tag;
//       let verdict_message;

//       const verdict_indicators = function () {
//         if (+e.evmRecordedVsCountedDifference > 0) {
//           verdict_tag = "excess";
//           verdict_message = "EXCESS EVM VOTES";
//         }
//         if (+e.evmRecordedVsCountedDifference == 0) {
//           verdict_tag = "match";
//           verdict_message = "100% EVM MATCH";
//         }
//         if (+e.evmRecordedVsCountedDifference < 0) {
//           verdict_tag = "discarded";
//           verdict_message = "VOTES DISCARDED";
//         }
//         return 0;
//       };

//       verdict_indicators();

//       const html = `
//       <div class="row t-body text-center align-middle g-2 my-3">
//                                   <div class="col-xl-2 text-center"><img src="images/Zinzu Chan Lee.jpg" alt="" class="clu-img"><br><span class="party-name">${partyName}</span></div>
//                                   <div class="col-xl-2 "><span class="win_margin">${winningMargin}</span></div>
//                                   <div class="col-xl-2 t-cell"><span class="cs_name">${cs_name}</span><br><span class="state_name">${state_name}</span></div>
//                                   <div class="col-xl-2 t-cell text-center"><span class="total_evm">${total_evm_votes}</span></div>
//                                   <div class="col-xl-2 t-cell text-center"><span class="counted_evm">${counted_evm_votes}</span></div>
//                                   <div class="col-xl-2 "><span class="verdict_count-${verdict_tag}">${verdict_num}</span><br><span class="status ${verdict_tag}">${verdict_message}</span></div>
//                                 </div>
//       `;

//       DesktopTableRender.insertAdjacentHTML("afterbegin", html);
//     });
//   }
//   if (+user_Screen_currentwidth < +DESKTOP_SCREEN_SIZE) {
//     // console.log("MOBILE DEVICE DETECTED");
//     // DO SOMETHING FOR MOBILE
//     ///  DISABLE DESKTOP VIEW
//     selectDesktopTableView.classList.add("display-none");
//     selectMobileTableView.classList.remove("display-none");

//     // console.log(cs_data_func);

//     cs_data_func.forEach((e) => {
//       // console.log(e);
//       const partyName = e.stats[0].party;
//       const winningMargin = e.evm_candidate_winning_margin;
//       const cs_name = e.cs_name;
//       const state_name = e.state_name;
//       const total_evm_votes = e.loggedEVMvotesAfterPhaseEnd;
//       const counted_evm_votes = e.countedEVM_VotesOn4thJune;
//       const verdict_num =
//         e.evmRecordedVsCountedDifference > 0
//           ? "+" + e.evmRecordedVsCountedDifference
//           : e.evmRecordedVsCountedDifference;
//       let verdict_tag;
//       let verdict_message;

//       const verdict_indicators = function () {
//         if (+e.evmRecordedVsCountedDifference > 0) {
//           verdict_tag = "excess";
//           verdict_message = "EXCESS EVM VOTES";
//         }
//         if (+e.evmRecordedVsCountedDifference == 0) {
//           verdict_tag = "match";
//           verdict_message = "100% EVM MATCH";
//         }
//         if (+e.evmRecordedVsCountedDifference < 0) {
//           verdict_tag = "discarded";
//           verdict_message = "VOTES DISCARDED";
//         }
//         return 0;
//       };

//       verdict_indicators();

//       const html = `
//       <div class="row t-body text-center align-middle  my-3">
//       <div class="row bg_${verdict_tag} rounded-2 border g-2">
//         <div class="col-5  mobile-card-title text-start">Political Party</div>
//         <div class="col-7 text-start"><img src="images/Zinzu Chan Lee.jpg" alt="" class="clu-img"><br><span class="party-name">${partyName}</span></div>
//       </div>

//       <div class="row g-2 sec_row_alt">
//         <div class="col-5  mobile-card-title text-start">Winning Margin</div>
//         <div class="col-7 text-start"><span class="win_margin">${winningMargin}</span></div>
//        </div>

//        <div class="row g-2 ">
//         <div class="col-5  mobile-card-title text-start">Constituency Name</div>
//         <div class="col-7 text-start"><span class="cs_name_m">${cs_name}</span></div>
//        </div>
//        <div class="row g-2 sec_row_alt">
//         <div class="col-5  mobile-card-title text-start">State Name</div>
//         <div class="col-7 text-start"><span class="state_name_m">${state_name}</span></div>
//        </div>
//        <div class="row g-2">
//         <div class="col-5  mobile-card-title text-start">Total EVM Votes*</div>
//         <div class="col-7 text-start"><span class="total_evm">${total_evm_votes}</span></div>
//        </div>

//        <div class="row g-2 sec_row_alt">
//         <div class="col-5 mobile-card-title text-start">Counted EVM Votes**</div>
//         <div class="col-7 text-start"><span class="counted_evm">${counted_evm_votes}</span></div>
//        </div>

//        <div class="row g-2">
//         <div class="col-5  mobile-card-title text-start">Verdict</div>
//         <div class="col-7 text-start"><span class="verdict_count-${verdict_tag}">${verdict_num}</span><span class="status ${verdict_tag} m"> ${verdict_message}</span></div>
//        </div>
//     </div>
//       `;
//       console.log("CALLING MOBILE RENDER");
//       MobileTableRender.insertAdjacentHTML("afterbegin", html);
//       // MobileTableRender.appendChild(html);
//       // console.log(html);
//       // MobileTableRender.insertAdjacentElement("afterbegin", "RAM");
//     });
//   }
//   // } else {
//   //   cs_data_func = cs_data_func;
//   //   ph_data = ph_data;
//   // }
// };
let phase_link;
const rebuildData = function (cs_data_func, ph_data) {
  const cs_length_chk = cs_data_func.length ?? 0;
  const ph_length_chk = ph_data.length ?? 0;

  DesktopTableRender.innerHTML = "";
  MobileTableRender.innerHTML = "";
  // if (+cs_length_chk > 0 && +ph_length_chk > 0) {
  cs_data_func.map((e) => {
    const findPhase_cs =
      ph_data.flat().find((f) => f.cs_short == e.cs_shortname) ?? 0;

    // console.log(e);
    const counted_Evm_VotesCS_Wise = +e.total.evm_votes;
    const tottal_Evm_VotesCS_Wise = findPhase_cs.evm_votes ?? 0;

    const winningMargin_EVM_CSWise = Math.abs(
      differenceMargin(+e.stats[0].evm_votes || 0, +e.stats[1]?.evm_votes || 0)
    );

    const diffrence_EVM_VotesCS_Wise = differenceMargin(
      +e.total.evm_votes || 0,
      +findPhase_cs.evm_votes || 0
    );

    //// Logged Winning Candidate Margin
    e.evm_candidate_winning_margin = winningMargin_EVM_CSWise;
    //// Logged After Particual Phase End ::::
    e.loggedEVMvotesAfterPhaseEnd = tottal_Evm_VotesCS_Wise;

    e.evmRecordedVsCountedDifference = diffrence_EVM_VotesCS_Wise;
    //// Counted on RESULT Day ::::
    e.countedEVM_VotesOn4thJune = e.total.evm_votes;
    e.state_name = findPhase_cs.state;
    e.phase_num = findPhase_cs.serial_no;

    // console.log(e.phase_num);

    // console.log(findPhase_cs);
    const verdictEVM = function (diffrence_EVM_VotesCS_Wise) {
      if (diffrence_EVM_VotesCS_Wise > 0) {
        return `+${diffrence_EVM_VotesCS_Wise} ( ⬆️ EXCESS EVM VOTES)`;
      } else if (diffrence_EVM_VotesCS_Wise < 0) {
        return `${diffrence_EVM_VotesCS_Wise} ( 🚫 DISCARDED EVM VOTES)`;
      } else {
        return `✅ 100% EVM VOTES MATCHED!!! (POLL DAY vs COUNT DAY)`;
      }
    };
    count++;
    // console.log(count);
    // console.log(findPhase_cs.state);

    // console.log(
    //   `${e.cs_shortname.toUpperCase()} >>> CANDIDATE EVM_Winning Margin: (${winningMargin_EVM_CSWise}) | WINNING PARTY: (${
    //     e.stats[0].party
    //   }-${
    //     e.winner
    //   }) |  TOTAL EVM_VOTES: ${tottal_Evm_VotesCS_Wise} | COUNTED EVM_VOTES: ${counted_Evm_VotesCS_Wise} | OBSERVATION: ${verdictEVM(
    //     diffrence_EVM_VotesCS_Wise
    //   )} | STATE: ${
    //     e.state_name ? e.state_name.toUpperCase() : e.statename.toUpperCase()
    //   }`
    // );

    // console.log("-------------------------------");
    return e.total.evm_votes;
  });

  // console.log(Object.keys(cs_data_func).length);
  /// Counting the CS with Discrepancy
  const TotalCsWithDiscr = cs_data_func
    .map((e) => e.evmRecordedVsCountedDifference)
    .filter((e) => +e != 0);

  const TotalCsWithDiscrCount = TotalCsWithDiscr.length;

  /// Counting the CS with EXCESS VOTES
  const TotalCsWithExcess = cs_data_func
    .map((e) => e.evmRecordedVsCountedDifference)
    .filter((e) => +e > 0);

  const TotalCsWithExcessCount = TotalCsWithExcess.length;
  // console.log(TotalCsWithExcessCount);

  /// Counting the CS with Discarded Votes
  const TotalCsWithDiscarded = cs_data_func
    .map((e) => e.evmRecordedVsCountedDifference)
    .filter((e) => +e < 0);

  const TotalCsWithDiscardCount = TotalCsWithDiscarded.length;
  // console.log(TotalCsWithDiscardCount);

  /// Counting the CS with Matched Votes
  const TotalCsWithMatched = cs_data_func
    .map((e) => e.evmRecordedVsCountedDifference)
    .filter((e) => +e == 0);

  const TotalCsWithMatchedCount = TotalCsWithMatched.length;
  // console.log(TotalCsWithMatchedCount);

  /// TOTAL EXCESS VOTES
  const TotalExcessVotes = cs_data_func
    .map((e) => e.evmRecordedVsCountedDifference)
    .filter((e) => +e > 0)
    .reduce((a, c) => a + c, 0);

  // const TotalCsWithExcessCount = TotalCsWithExcess.length;
  // console.log(TotalExcessVotes);

  /// TOTAL Discarded Votes
  const TotalDiscardedVotes = cs_data_func
    .map((e) => e.evmRecordedVsCountedDifference)
    .filter((e) => +e < 0)
    .reduce((a, c) => a + c, 0);

  // console.log(TotalDiscardedVotes);

  displayHeroStats(
    TotalCsWithDiscrCount,
    TotalCsWithExcessCount,
    TotalCsWithDiscardCount,
    TotalCsWithMatchedCount,
    Math.abs(TotalDiscardedVotes),
    TotalExcessVotes
  );

  // ///// SHOWING TABLE BASED ON SCREEN SIZE

  // user_Screen_currentwidth =
  //   window.innerWidth > 0 ? window.innerWidth : screen.width;
  // // console.log(user_Screen_currentwidth);

  // if (+user_Screen_currentwidth >= +DESKTOP_SCREEN_SIZE) {
  //   // console.log("DESKTOP SCREEN DETECTED");
  //   // DO SOMETHING FOR DESKTOP
  //   ///  DISABLE MOBILE VIEW
  //   selectMobileTableView.classList.add("display-none");
  //   selectDesktopTableView.classList.remove("display-none");

  cs_data_func.forEach((e) => {
    // console.log(e);
    const partyName = e.stats[0].party;
    const winningMargin = e.evm_candidate_winning_margin;
    const cs_name = e.cs_name ? e.cs_name : "N/A";
    const state_name = e.state_name ? e.state_name : "N/A";
    const total_evm_votes = e.loggedEVMvotesAfterPhaseEnd;
    const counted_evm_votes = e.countedEVM_VotesOn4thJune;
    const verdict_num =
      e.evmRecordedVsCountedDifference > 0
        ? "+" + e.evmRecordedVsCountedDifference
        : e.evmRecordedVsCountedDifference;

    const cs_code_upper = e.cs_code.toUpperCase();

    const party_map_func =
      party_data.find((g) => g.party_short_name == e.winner) ?? 0;
    e.winner_party_name = party_map_func.party_full_name;

    if (e.winner_party_name != partyName) {
      e.leading_candidate = "lost";
      // console.log("---------------------------");
      // console.log(
      //   `DECLEARED WINNER: ${e.winner_party_name} | Leading Party in EVM: ${partyName}`
      // );
      // console.log(e);
    } else {
      e.leading_candidate = "won";
    }

    // console.log(party_map_func);
    // console.log("-------------");
    // console.log(`Party_Map: ${party_map_func.party_full_name}`);
    // console.log(`Party_Obj: ${partyName}`);
    // console.log("-------------");

    if (
      e.phase_num?.includes("A") ||
      e.phase_num?.includes("B") ||
      e.phase_num?.includes("C") ||
      e.phase_num?.includes("D") ||
      e.phase_num?.includes("E")
    ) {
      // console.log("Phase 1-2-3-4-5");
      phase_link =
        "https://drive.google.com/file/d/1boZBnpU17hDqOLb6jE8lL5vFqPxl0HFZ/view";
    }

    if (e.phase_num?.includes("F")) {
      phase_link =
        "https://drive.google.com/file/d/1qP7q9Aptsmb7gOgaZbyqn7Ain2OLMTA0/view";
      // console.log("Phase 6");
    }
    if (e.phase_num?.includes("G")) {
      phase_link =
        "https://drive.google.com/file/d/1osz7PWatEXTE-mxotlrRIpQac2ngwb-N/view";
      // console.log("Phase 7");
    }
    // console.log(cs_code_upper);
    let verdict_tag;
    let verdict_message;

    const verdict_indicators = function () {
      if (+e.evmRecordedVsCountedDifference > 0) {
        verdict_tag = "excess";
        verdict_message = "EXCESS EVM VOTES";
      }
      if (+e.evmRecordedVsCountedDifference == 0) {
        verdict_tag = "match";
        verdict_message = "100% EVM MATCH";
      }
      if (+e.evmRecordedVsCountedDifference < 0) {
        verdict_tag = "discarded";
        verdict_message = "EVM VOTES DISCARDED";
      }
      return 0;
    };

    // console.log(e.stats[0].party);
    // console.log(e.stats[0].party);

    verdict_indicators();
    const party_leading = party_data.find(
      (g) => g.party_full_name == partyName
    ).party_short_name;

    // console.log(party_leading);
    const html_desktop = `
      <div class="row t-body text-center align-middle g-2 my-3">
      <div class="col-xl-1 text-center"><img src="images/party_logos/${
        party_map_func.party_short_name
      }.png" alt="" class="clu-img"><br><span class="party-name_win">${
      e.winner_party_name
    }</span></div>
                                  <div class="col-xl-2 text-center"><img src="./images/party_logos/${party_leading}.png" alt="" class="clu-img"><br><span class="party-name">${partyName}</span></div>
                                  <div class="col-xl-2" style="margin-top: 2.9rem !important;"><span class="win_margin"><a href="https://results.eci.gov.in/PcResultGenJune2024/Constituencywise${cs_code_upper}.htm" target="_blank" rel="noopener noreferrer">${winningMargin}</a></span></div>
                                  <div class="col-xl-2 t-cell "><span class="cs_name ">${cs_name}</span><br><span class="state_name">${state_name}</span></div>
                                  <div class="col-xl-1 t-cell text-center"><span class="total_evm"><a href="${phase_link}" target="_blank" rel="noopener noreferrer">${total_evm_votes}</a></span></div>
                                  <div class="col-xl-2 t-cell text-center"><span class="counted_evm"><a href="https://results.eci.gov.in/PcResultGenJune2024/Constituencywise${cs_code_upper}.htm" target="_blank" rel="noopener noreferrer">${counted_evm_votes}</a></span></div>
                                  <div class="col-xl-2 " style="margin-top: 2rem;"><span class="verdict_count-${verdict_tag}">${
      +verdict_num != 0 ? `${verdict_num}</span><br>` : ``
    }<span class="status ${verdict_tag}">${verdict_message}</span></div>
                                </div>
      `;

    DesktopTableRender.insertAdjacentHTML("afterbegin", html_desktop);
    //   });
    // }
    // if (+user_Screen_currentwidth < +DESKTOP_SCREEN_SIZE) {
    // // console.log("MOBILE DEVICE DETECTED");
    // // DO SOMETHING FOR MOBILE
    // ///  DISABLE DESKTOP VIEW
    // selectDesktopTableView.classList.add("display-none");
    // selectMobileTableView.classList.remove("display-none");

    // console.log(cs_data_func);

    // cs_data_func.forEach((e) => {
    //   // console.log(e);
    //   const partyName = e.stats[0].party;
    //   const winningMargin = e.evm_candidate_winning_margin;
    //   const cs_name = e.cs_name;
    //   const state_name = e.state_name;
    //   const total_evm_votes = e.loggedEVMvotesAfterPhaseEnd;
    //   const counted_evm_votes = e.countedEVM_VotesOn4thJune;
    //   const verdict_num =
    //     e.evmRecordedVsCountedDifference > 0
    //       ? "+" + e.evmRecordedVsCountedDifference
    //       : e.evmRecordedVsCountedDifference;
    //   let verdict_tag;
    //   let verdict_message;

    //   const verdict_indicators = function () {
    //     if (+e.evmRecordedVsCountedDifference > 0) {
    //       verdict_tag = "excess";
    //       verdict_message = "EXCESS EVM VOTES";
    //     }
    //     if (+e.evmRecordedVsCountedDifference == 0) {
    //       verdict_tag = "match";
    //       verdict_message = "100% EVM MATCH";
    //     }
    //     if (+e.evmRecordedVsCountedDifference < 0) {
    //       verdict_tag = "discarded";
    //       verdict_message = "VOTES DISCARDED";
    //     }
    //     return 0;
    //   };

    //   verdict_indicators();

    const html_mobile = `
      <div class="row t-body text-center align-middle  my-3">
      <div class="row bg_${verdict_tag} rounded-2 border g-2">
        <div class="col-5  mobile-card-title text-start">WINNER</div>
        <div class="col-7 text-start"><img src="images/party_logos/${
          party_map_func.party_short_name
        }.png" alt="" class="clu-img"><br><span class="party-name">${
      e.winner_party_name
    }</span></div>
      </div>

       <div class="row rounded-2 border g-2">
        <div class="col-5  mobile-card-title text-start">Leading Party*<br><span class="mobile-subtext-headfield">(based on EVM votes)</span></div>
        <div class="col-7 text-start"><img src="images/party_logos/${party_leading}.png" alt="" class="clu-img"><br><span class="party-name">${partyName}</span></div>
      </div>

      <div class="row g-2 sec_row_alt">
        <div class="col-5  mobile-card-title text-start">Winning Margin<br>(EVM Votes)</div>
        <div class="col-7 text-start"><span class="win_margin"><a href="https://results.eci.gov.in/PcResultGenJune2024/Constituencywise${cs_code_upper}.htm" target="_blank" rel="noopener noreferrer">${winningMargin}</a></span></div>
       </div>
       
       <div class="row g-2 ">
        <div class="col-5  mobile-card-title text-start">Constituency Name</div>
        <div class="col-7 text-start"><span class="cs_name_m">${cs_name}</span></div>
       </div>
       <div class="row g-2 sec_row_alt">
        <div class="col-5  mobile-card-title text-start">State Name</div>
        <div class="col-7 text-start s_margin_adj"><span class="state_name_m">${state_name}</span></div>
       </div>
       <div class="row g-2">
        <div class="col-5  mobile-card-title text-start">Total EVM Votes*</div>
        <div class="col-7 text-start"><span class="total_evm"><a href="${phase_link}" target="_blank" rel="noopener noreferrer">${total_evm_votes}</a></span></div>
       </div>
       
       <div class="row g-2 sec_row_alt">
        <div class="col-5 mobile-card-title text-start">Counted EVM Votes**</div>
        <div class="col-7 text-start"><span class="counted_evm"><a href="https://results.eci.gov.in/PcResultGenJune2024/Constituencywise${cs_code_upper}.htm" target="_blank" rel="noopener noreferrer">${counted_evm_votes}</a></span></div>
       </div>
       
       <div class="row g-2">
        <div class="col-5  mobile-card-title text-start">Verdict</div>
        <div class="col-7 text-start"><span class="verdict_count-${verdict_tag}">${
      +verdict_num != 0 ? `${verdict_num}</span><br>` : ``
    }<span class="status ${verdict_tag} m"> ${verdict_message}</span></div>
       </div>
    </div>
      `;
    // console.log("CALLING MOBILE RENDER");
    MobileTableRender.insertAdjacentHTML("afterbegin", html_mobile);
    // console.log("----------------------------------");
    // console.log(party_leading);
    // MobileTableRender.appendChild(html);
    // console.log(html);
    // MobileTableRender.insertAdjacentElement("afterbegin", "RAM");
  });
  // }
  // } else {
  //   cs_data_func = cs_data_func;
  //   ph_data = ph_data;
  // }
};

// const rebuildData_Test01 = function (cs_data_func) {
//   // count1++;
//   count1 = 10;
//   // console.log(count1);
//   MobileTableRender.innerHTML = "";
//   DesktopTableRender.innerHTML = "";
//   // console.log(cs_data_func);

//   cs_data_func.forEach((e) => {
//     const html = `
//     <div class="test_cls02">🎧RADHE RADHE RADHE</div>
//     <div class="test_cls01">${cs_data_func[0].cs_name}</div>
//     <div class="test_cls03">🎧RADHE RADHE RADHE</div>
//     `;

//     MobileTableRender.insertAdjacentHTML("afterbegin", html);
//     DesktopTableRender.insertAdjacentHTML("afterbegin", html);
//   });
// };

// /REBUILDING DATA
rebuildData(cs_data, ph_data);

const resetUI = function () {
  // console.log("reset invoked.....");
  bigInputBox.value = "";
  // selectPartyDropper.value = "";
  // selectSortDropper.value = 0;
};

//// TRIGGERS ON PAGE LOAD
window.onload = function () {
  resetUI();
  // console.log("HOOOOOLA");
  responsiveModuleSwitcher();
};

//// TRIGGERS ON PAGE RESIZE
window.addEventListener("resize", responsiveModuleSwitcher, true);

// console.log("--------------------");
// console.log("🌺 Radhe Radhe 🌟");
// console.log("--------------------");

// console.log([-1, -2, -3, -4, 0].reduce((a, c) => a + c, 0));

// console.log(cs_data);
// console.log(_data)

let currentCSfilter;
let currentPartyfilter;
let currentStatefilter;

function callFilter({ filter_cs = "", filter_party = "", filter_state = "" }) {
  cs_data = filterFunction(
    cs_data_core,
    // ph_data_core,
    filter_cs,
    filter_party,
    filter_state
  );
  // console.log("----------------------");
  // console.log(cs_data);
  // console.log("----------------------");
  // console.log(cs_data);
  // ph_data = filterFunction(ph_data_core);
  // cs_data = {};
  // ph_data = {};
  rebuildData(cs_data, ph_data);
  // console.log(ph_data);
}

//// USER INTRACTIONS
bigInputBox.addEventListener("input", (e) => {
  currentCSfilter = e.target.value;
  callFilter({
    filter_cs: currentCSfilter,
    filter_party: currentPartyfilter,
    filter_state: currentStatefilter,
  });

  /// DEBUG
  // console.log("CS Dropdown Triggered!!!");
  // console.log(
  //   `Current CS: ${currentCSfilter} | Current State: ${currentStatefilter} | Current Party: ${currentPartyfilter}`
  // );
  // console.log("-----------------");
  // console.log(e.target.value);
});

// selectPartyDropper.addEventListener("click", (e) => {
//   currentPartyfilter = e.target.value;
//   callFilter({
//     filter_cs: currentCSfilter,
//     filter_party: currentPartyfilter,
//     filter_state: currentStatefilter,
//   });

//   /// DEBUG
//   // console.log("Party Dropdown Triggered!!!");
//   // console.log(
//   //   `Current CS: ${currentCSfilter} | Current State: ${currentStatefilter} | Current Party: ${currentPartyfilter}`
//   // );
//   // console.log("-----------------");
//   // console.log(e.target.value);
// });

const sortPartyInvoke = function () {
  sortParty = !sortParty ? true : false;
  [sortWinningM, sortCsName, sortTotalEvm, sortCountedEvm, sortVerdict] = [
    false,
    false,
    false,
    false,
    false,
  ];
  // console.log(
  // sortParty,
  // sortWinningM,
  // sortCsName,
  // sortTotalEvm,
  // sortCountedEvm,
  // sortVerdict
  // );
  // console.log(sortParty);

  cs_data = sortingfilter_party({ cs_object: cs_data, party_sort: sortParty });
  // console.log(cs_data);
  rebuildData(cs_data, ph_data);
  // console.log(cs_data);
};

const sortWinningMarginInvoke = function () {
  sortWinningM = !sortWinningM ? true : false;
  [sortParty, sortCsName, sortTotalEvm, sortCountedEvm, sortVerdict] = [
    false,
    false,
    false,
    false,
    false,
  ];
  // console.log(
  //   sortParty,
  //   sortWinningM,
  //   sortCsName,
  //   sortTotalEvm,
  //   sortCountedEvm,
  //   sortVerdict
  // );
  // console.log(sortWinningM);
  // console.log(sortWinningM);
  cs_data = sortingfilter_wmargin({
    cs_object: cs_data,
    winning_mar_sort: sortWinningM,
  });
  // console.log(cs_data);
  rebuildData(cs_data, ph_data);
  // cs_data.forEach((e) => console.log(e.evm_candidate_winning_margin));
};
const sortCsNameInvoke = function () {
  sortCsName = !sortCsName ? true : false;
  [sortWinningM, sortParty, sortTotalEvm, sortCountedEvm, sortVerdict] = [
    false,
    false,
    false,
    false,
    false,
  ];
  // console.log(
  //   sortParty,
  //   sortWinningM,
  //   sortCsName,
  //   sortTotalEvm,
  //   sortCountedEvm,
  //   sortVerdict
  // );
  // console.log(sortCsName);

  cs_data = sortingfilter_cs({ cs_object: cs_data, csname_sort: sortCsName });
  rebuildData(cs_data, ph_data);
};
const sortTotalEvmInvoke = function () {
  sortTotalEvm = !sortTotalEvm ? true : false;
  [sortWinningM, sortCsName, sortParty, sortCountedEvm, sortVerdict] = [
    false,
    false,
    false,
    false,
    false,
  ];
  // console.log(
  //   sortParty,
  //   sortWinningM,
  //   sortCsName,
  //   sortTotalEvm,
  //   sortCountedEvm,
  //   sortVerdict
  // );
  // console.log(sortTotalEvm);

  cs_data = sortingfilter_totalevm({
    cs_object: cs_data,
    totalevm_sort: sortTotalEvm,
  });
  rebuildData(cs_data, ph_data);
};
const sortCountedEvmInvoke = function () {
  sortCountedEvm = !sortCountedEvm ? true : false;
  [sortWinningM, sortCsName, sortTotalEvm, sortParty, sortVerdict] = [
    false,
    false,
    false,
    false,
    false,
  ];
  // console.log(
  //   sortParty,
  //   sortWinningM,
  //   sortCsName,
  //   sortTotalEvm,
  //   sortCountedEvm,
  //   sortVerdict
  // );
  // console.log(sortCountedEvm);
  cs_data = sortingfilter_countedevm({
    cs_object: cs_data,
    countedevm_sort: sortCountedEvm,
  });
  rebuildData(cs_data, ph_data);
};

const sortVerdictInvoke = function () {
  sortVerdict = !sortVerdict ? true : false;
  [sortWinningM, sortCsName, sortTotalEvm, sortCountedEvm, sortParty] = [
    false,
    false,
    false,
    false,
    false,
  ];
  // console.log(
  //   sortParty,
  //   sortWinningM,
  //   sortCsName,
  //   sortTotalEvm,
  //   sortCountedEvm,
  //   sortVerdict
  // );
  // console.log(sortVerdict);

  cs_data = sortingfilter_verdict({
    cs_object: cs_data,
    verdict_sort: sortVerdict,
  });
  rebuildData(cs_data, ph_data);
};

sor_partyElem.addEventListener("click", (e) => {
  sortPartyInvoke();
});

sor_winningmElem.addEventListener("click", (e) => {
  sortWinningMarginInvoke();
});

sor_csnameElem.addEventListener("click", (e) => {
  sortCsNameInvoke();
});

sor_totalevmElem.addEventListener("click", (e) => {
  sortTotalEvmInvoke();
});

sor_countedevmElem.addEventListener("click", (e) => {
  sortCountedEvmInvoke();
});

/// CHECK CS DUPLICATE START ///

const check_duplicate_in_array = (input_array) => {
  const duplicates = input_array.filter(
    (item, index) => input_array.indexOf(item) !== index
  );
  return Array.from(new Set(duplicates));
};

const aiques = cs_data.flatMap((e) => e.cs_shortname);

// console.log(check_duplicate_in_array(aiques));
// console.log(aiques);
// console.log(dupliTest);

/// CHECK CS DUPLICATE END ///

sor_verdictElem.addEventListener("click", (e) => {
  sortVerdictInvoke();
});

// console.log(cs_data);
// console.log(cs_data[0]);

sor_partyElem.addEventListener("click", sortPartyInvoke());
sor_winningmElem.addEventListener("click", sortWinningMarginInvoke());
sor_csnameElem.addEventListener("click", sortCsNameInvoke());
sor_totalevmElem.addEventListener("click", sortTotalEvmInvoke());
sor_countedevmElem.addEventListener("click", sortCountedEvmInvoke());
sor_verdictElem.addEventListener("click", sortVerdictInvoke());

sor_partyElemD.addEventListener("click", sortPartyInvoke);
sor_winningmElemD.addEventListener("click", sortWinningMarginInvoke);
sor_csnameElemD.addEventListener("click", sortCsNameInvoke);
sor_totalevmElemD.addEventListener("click", sortTotalEvmInvoke);
sor_countedevmElemD.addEventListener("click", sortCountedEvmInvoke);
sor_verdictElemD.addEventListener("click", sortVerdictInvoke);
// console.log(cs_data);
