export const party_map = [
  {
    party_full_name: "Bharatiya Janata Party",
    party_short_name: "BJP",
  },
  {
    party_full_name: "Indian National Congress",
    party_short_name: "INC",
  },
  {
    party_full_name: "Samajwadi Party",
    party_short_name: "SP",
  },
  {
    party_full_name: "Biju Janata Dal",
    party_short_name: "BJD",
  },
  {
    party_full_name: "All India Trinamool Congress",
    party_short_name: "AITC",
  },
  {
    party_full_name: "Dravida Munnetra Kazhagam",
    party_short_name: "DMK",
  },
  {
    party_full_name: "Telugu Desam",
    party_short_name: "TDP",
  },
  {
    party_full_name: "Janata Dal (United)",
    party_short_name: "JD(U)",
  },
  {
    party_full_name: "Shiv Sena (Uddhav Balasaheb Thackrey)",
    party_short_name: "SHSUBT",
  },
  {
    party_full_name: "Nationalist Congress Party – Sharadchandra Pawar",
    party_short_name: "NCPSP",
  },
  {
    party_full_name: "Shiv Sena",
    party_short_name: "SHS",
  },
  {
    party_full_name: "Lok Janshakti Party(Ram Vilas)",
    party_short_name: "LJPRV",
  },
  {
    party_full_name: "Yuvajana Sramika Rythu Congress Party",
    party_short_name: "YSRCP",
  },
  {
    party_full_name: "Rashtriya Janata Dal",
    party_short_name: "RJD",
  },
  {
    party_full_name: "Communist Party of India (Marxist)",
    party_short_name: "CPI(M)",
  },
  {
    party_full_name: "Indian Union Muslim League",
    party_short_name: "IUML",
  },
  {
    party_full_name: "Aam Aadmi Party",
    party_short_name: "AAAP",
  },
  {
    party_full_name: "Jharkhand Mukti Morcha",
    party_short_name: "JMM",
  },
  {
    party_full_name: "Janasena Party",
    party_short_name: "JnP",
  },
  {
    party_full_name: "Communist Party of India (Marxist-Leninist) (Liberation)",
    party_short_name: "CPI(ML)(L)",
  },
  {
    party_full_name: "Janata Dal (Secular)",
    party_short_name: "JD(S)",
  },
  {
    party_full_name: "Viduthalai Chiruthaigal Katchi",
    party_short_name: "VCK",
  },
  {
    party_full_name: "Communist Party of India",
    party_short_name: "CPI",
  },
  {
    party_full_name: "Rashtriya Lok Dal",
    party_short_name: "RLD",
  },
  {
    party_full_name: "Jammu & Kashmir National Conference",
    party_short_name: "JKN",
  },
  {
    party_full_name: "United People’s Party, Liberal",
    party_short_name: "UPPL",
  },
  {
    party_full_name: "Asom Gana Parishad",
    party_short_name: "AGP",
  },
  {
    party_full_name: "Hindustani Awam Morcha (Secular)",
    party_short_name: "HAMS",
  },
  {
    party_full_name: "Kerala Congress",
    party_short_name: "KEC",
  },
  {
    party_full_name: "Revolutionary Socialist Party",
    party_short_name: "RSP",
  },
  {
    party_full_name: "Nationalist Congress Party",
    party_short_name: "NCP",
  },
  {
    party_full_name: "Voice of the People Party",
    party_short_name: "VOTPP",
  },
  {
    party_full_name: "Zoram People’s Movement",
    party_short_name: "ZPM",
  },
  {
    party_full_name: "Shiromani Akali Dal",
    party_short_name: "SAD",
  },
  {
    party_full_name: "Rashtriya Loktantrik Party",
    party_short_name: "RLTP",
  },
  {
    party_full_name: "Bharat Adivasi Party",
    party_short_name: "BHRTADVSIP",
  },
  {
    party_full_name: "Sikkim Krantikari Morcha",
    party_short_name: "SKM",
  },
  {
    party_full_name: "Marumalarchi Dravida Munnetra Kazhagam",
    party_short_name: "MDMK",
  },
  {
    party_full_name: "Aazad Samaj Party (Kanshi Ram)",
    party_short_name: "ASPKR",
  },
  {
    party_full_name: "Apna Dal (Soneylal)",
    party_short_name: "ADAL",
  },
  {
    party_full_name: "AJSU Party",
    party_short_name: "AJSUP",
  },
  {
    party_full_name: "All India Majlis-E-Ittehadul Muslimeen",
    party_short_name: "AIMIM",
  },
  {
    party_full_name: "Independent",
    party_short_name: "IND",
  },
];
