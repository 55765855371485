export const phase1 = [
  {
    serial_no: "A1",
    state: "Andaman & Nicobar Islands",
    cs_name: "Andaman-& Nicobar-Islands",
    cs_short: "andaman&nicobarislands",
    count_of_elector: 315148,
    poll_percentage: 64.1,
    evm_votes: 202018,
  },
  {
    serial_no: "A2",
    state: "Arunachal Pradesh",
    cs_name: "Arunachal-East",
    cs_short: "arunachaleast",
    count_of_elector: 375310,
    poll_percentage: 83.31,
    evm_votes: 312658,
  },
  {
    serial_no: "A3",
    state: "Arunachal Pradesh",
    cs_name: "Arunachal-West",
    cs_short: "arunachalwest",
    count_of_elector: 517384,
    poll_percentage: 73.6,
    evm_votes: 380783,
  },
  {
    serial_no: "A4",
    state: "Assam",
    cs_name: "Dibrugarh",
    cs_short: "dibrugarh",
    count_of_elector: 1659588,
    poll_percentage: 76.75,
    evm_votes: 1273744,
  },
  {
    serial_no: "A5",
    state: "Assam",
    cs_name: "Jorhat",
    cs_short: "jorhat",
    count_of_elector: 1727121,
    poll_percentage: 79.89,
    evm_votes: 1379749,
  },
  {
    serial_no: "A6",
    state: "Assam",
    cs_name: "Kaziranga",
    cs_short: "kaziranga",
    count_of_elector: 2050126,
    poll_percentage: 79.33,
    evm_votes: 1626408,
  },
  {
    serial_no: "A7",
    state: "Assam",
    cs_name: "Lakhimpur",
    cs_short: "lakhimpur",
    count_of_elector: 1577234,
    poll_percentage: 76.42,
    evm_votes: 1205331,
  },
  {
    serial_no: "A8",
    state: "Assam",
    cs_name: "Sonitpur",
    cs_short: "sonitpur",
    count_of_elector: 1633800,
    poll_percentage: 78.46,
    evm_votes: 1281959,
  },
  {
    serial_no: "A9",
    state: "Bihar",
    cs_name: "Aurangabad_bihar",
    cs_short: "aurangabad_bihar",
    count_of_elector: 1871564,
    poll_percentage: 50.35,
    evm_votes: 942382,
  },
  {
    serial_no: "A10",
    state: "Bihar",
    cs_name: "Gaya",
    cs_short: "gaya",
    count_of_elector: 1816815,
    poll_percentage: 52.76,
    evm_votes: 958623,
  },
  {
    serial_no: "A11",
    state: "Bihar",
    cs_name: "Jamui",
    cs_short: "jamui",
    count_of_elector: 1907126,
    poll_percentage: 51.25,
    evm_votes: 977369,
  },
  {
    serial_no: "A12",
    state: "Bihar",
    cs_name: "Nawada",
    cs_short: "nawada",
    count_of_elector: 2006124,
    poll_percentage: 43.17,
    evm_votes: 866102,
  },
  {
    serial_no: "A13",
    state: "Chhattisgarh",
    cs_name: "BASTAR",
    cs_short: "bastar",
    count_of_elector: 1472207,
    poll_percentage: 68.29,
    evm_votes: 1005392,
  },
  {
    serial_no: "A14",
    state: "Jammu and Kashmir",
    cs_name: "UDHAMPUR",
    cs_short: "udhampur",
    count_of_elector: 1623195,
    poll_percentage: 68.27,
    evm_votes: 1108206,
  },
  {
    serial_no: "A15",
    state: "Lakshadweep",
    cs_name: "Lakshadweep",
    cs_short: "lakshadweep",
    count_of_elector: 57784,
    poll_percentage: 84.16,
    evm_votes: 48630,
  },
  {
    serial_no: "A16",
    state: "Madhya Pradesh",
    cs_name: "BALAGHAT",
    cs_short: "balaghat",
    count_of_elector: 1873653,
    poll_percentage: 73.45,
    evm_votes: 1376207,
  },
  {
    serial_no: "A17",
    state: "Madhya Pradesh",
    cs_name: "CHHINDWARA",
    cs_short: "chhindwara",
    count_of_elector: 1632190,
    poll_percentage: 79.83,
    evm_votes: 1303001,
  },
  {
    serial_no: "A18",
    state: "Madhya Pradesh",
    cs_name: "JABALPUR",
    cs_short: "jabalpur",
    count_of_elector: 1896346,
    poll_percentage: 61.0,
    evm_votes: 1156722,
  },
  {
    serial_no: "A19",
    state: "Madhya Pradesh",
    cs_name: "MANDLA",
    cs_short: "mandla",
    count_of_elector: 2101811,
    poll_percentage: 72.84,
    evm_votes: 1530861,
  },
  {
    serial_no: "A20",
    state: "Madhya Pradesh",
    cs_name: "SHAHDOL",
    cs_short: "shahdol",
    count_of_elector: 1777185,
    poll_percentage: 64.68,
    evm_votes: 1149506,
  },
  {
    serial_no: "A21",
    state: "Madhya Pradesh",
    cs_name: "SIDHI",
    cs_short: "sidhi",
    count_of_elector: 2028451,
    poll_percentage: 56.5,
    evm_votes: 1146150,
  },
  {
    serial_no: "A22",
    state: "Maharashtra",
    cs_name: "Bhandara-Gondiya",
    cs_short: "bhandaragondiya",
    count_of_elector: 1827188,
    poll_percentage: 67.04,
    evm_votes: 1224928,
  },
  {
    serial_no: "A23",
    state: "Maharashtra",
    cs_name: "Chandrapur",
    cs_short: "chandrapur",
    count_of_elector: 1837906,
    poll_percentage: 67.55,
    evm_votes: 1241574,
  },
  {
    serial_no: "A24",
    state: "Maharashtra",
    cs_name: "Gadchiroli-Chimur",
    cs_short: "gadchirolichimur",
    count_of_elector: 1617207,
    poll_percentage: 71.88,
    evm_votes: 1162476,
  },
  {
    serial_no: "A25",
    state: "Maharashtra",
    cs_name: "Nagpur",
    cs_short: "nagpur",
    count_of_elector: 2223281,
    poll_percentage: 54.32,
    evm_votes: 1207738,
  },
  {
    serial_no: "A26",
    state: "Maharashtra",
    cs_name: "Ramtek",
    cs_short: "ramtek",
    count_of_elector: 2049085,
    poll_percentage: 61.01,
    evm_votes: 1250190,
  },
  {
    serial_no: "A27",
    state: "Manipur",
    cs_name: "Inner-Manipur",
    cs_short: "innermanipur",
    count_of_elector: 991574,
    poll_percentage: 80.15,
    evm_votes: 794790,
  },
  {
    serial_no: "A28",
    state: "Manipur",
    cs_name: "Outer-Manipur",
    cs_short: "outermanipur",
    count_of_elector: 553078,
    poll_percentage: 68.83,
    evm_votes: 380688,
  },
  {
    serial_no: "A29",
    state: "Meghalaya",
    cs_name: "Shillong",
    cs_short: "shillong",
    count_of_elector: 1400411,
    poll_percentage: 73.78,
    evm_votes: 1033251,
  },
  {
    serial_no: "A30",
    state: "Meghalaya",
    cs_name: "Tura",
    cs_short: "tura",
    count_of_elector: 826156,
    poll_percentage: 81.37,
    evm_votes: 672284,
  },
  {
    serial_no: "A31",
    state: "Mizoram",
    cs_name: "MIZORAM",
    cs_short: "mizoram",
    count_of_elector: 856364,
    poll_percentage: 56.87,
    evm_votes: 487013,
  },
  {
    serial_no: "A32",
    state: "Nagaland",
    cs_name: "Nagaland",
    cs_short: "nagaland",
    count_of_elector: 1317536,
    poll_percentage: 57.72,
    evm_votes: 760507,
  },
  {
    serial_no: "A33",
    state: "Puducherry",
    cs_name: "PUDUCHERRY",
    cs_short: "puducherry",
    count_of_elector: 1023699,
    poll_percentage: 78.9,
    evm_votes: 807724,
  },
  {
    serial_no: "A34",
    state: "Rajasthan",
    cs_name: "ALWAR",
    cs_short: "alwar",
    count_of_elector: 2059888,
    poll_percentage: 60.07,
    evm_votes: 1237446,
  },
  {
    serial_no: "A35",
    state: "Rajasthan",
    cs_name: "BHARATPUR",
    cs_short: "bharatpur",
    count_of_elector: 2114916,
    poll_percentage: 52.8,
    evm_votes: 1116742,
  },
  {
    serial_no: "A36",
    state: "Rajasthan",
    cs_name: "BIKANER",
    cs_short: "bikaner",
    count_of_elector: 2048399,
    poll_percentage: 54.11,
    evm_votes: 1108418,
  },
  {
    serial_no: "A37",
    state: "Rajasthan",
    cs_name: "CHURU",
    cs_short: "churu",
    count_of_elector: 2213187,
    poll_percentage: 63.61,
    evm_votes: 1407716,
  },
  {
    serial_no: "A38",
    state: "Rajasthan",
    cs_name: "DAUSA",
    cs_short: "dausa",
    count_of_elector: 1899304,
    poll_percentage: 55.72,
    evm_votes: 1058386,
  },
  {
    serial_no: "A39",
    state: "Rajasthan",
    cs_name: "GANGANAGAR",
    cs_short: "ganganagar",
    count_of_elector: 2102002,
    poll_percentage: 66.59,
    evm_votes: 1399688,
  },
  {
    serial_no: "A40",
    state: "Rajasthan",
    cs_name: "JAIPUR",
    cs_short: "jaipur",
    count_of_elector: 2287350,
    poll_percentage: 63.38,
    evm_votes: 1449612,
  },
  {
    serial_no: "A41",
    state: "Rajasthan",
    cs_name: "JAIPUR-RURAL",
    cs_short: "jaipurrural",
    count_of_elector: 2184978,
    poll_percentage: 56.7,
    evm_votes: 1238818,
  },
  {
    serial_no: "A42",
    state: "Rajasthan",
    cs_name: "JHUNJHUNU",
    cs_short: "jhunjhunu",
    count_of_elector: 2068540,
    poll_percentage: 52.93,
    evm_votes: 1094900,
  },
  {
    serial_no: "A43",
    state: "Rajasthan",
    cs_name: "KARAULI-DHOLPUR",
    cs_short: "karaulidholpur",
    count_of_elector: 1975352,
    poll_percentage: 49.59,
    evm_votes: 979618,
  },
  {
    serial_no: "A44",
    state: "Rajasthan",
    cs_name: "NAGAUR",
    cs_short: "nagaur",
    count_of_elector: 2146725,
    poll_percentage: 57.23,
    evm_votes: 1228494,
  },
  {
    serial_no: "A45",
    state: "Rajasthan",
    cs_name: "SIKAR",
    cs_short: "sikar",
    count_of_elector: 2214900,
    poll_percentage: 57.53,
    evm_votes: 1274270,
  },
  {
    serial_no: "A46",
    state: "Sikkim",
    cs_name: "Sikkim",
    cs_short: "sikkim",
    count_of_elector: 464140,
    poll_percentage: 79.88,
    evm_votes: 370765,
  },
  {
    serial_no: "A47",
    state: "Tamil Nadu",
    cs_name: "ARAKKONAM",
    cs_short: "arakkonam",
    count_of_elector: 1562871,
    poll_percentage: 74.19,
    evm_votes: 1159441,
  },
  {
    serial_no: "A48",
    state: "Tamil Nadu",
    cs_name: "ARANI",
    cs_short: "arani",
    count_of_elector: 1496118,
    poll_percentage: 75.76,
    evm_votes: 1133520,
  },
  {
    serial_no: "A49",
    state: "Tamil Nadu",
    cs_name: "CHENNAI-CENTRAL",
    cs_short: "chennaicentral",
    count_of_elector: 1350161,
    poll_percentage: 53.96,
    evm_votes: 728614,
  },
  {
    serial_no: "A50",
    state: "Tamil Nadu",
    cs_name: "CHENNAI-NORTH",
    cs_short: "chennainorth",
    count_of_elector: 1496224,
    poll_percentage: 60.11,
    evm_votes: 899367,
  },
  {
    serial_no: "A51",
    state: "Tamil Nadu",
    cs_name: "CHENNAI-SOUTH",
    cs_short: "chennaisouth",
    count_of_elector: 2023133,
    poll_percentage: 54.17,
    evm_votes: 1096026,
  },
  {
    serial_no: "A52",
    state: "Tamil Nadu",
    cs_name: "CHIDAMBARAM",
    cs_short: "chidambaram",
    count_of_elector: 1519847,
    poll_percentage: 76.37,
    evm_votes: 1160762,
  },
  {
    serial_no: "A53",
    state: "Tamil Nadu",
    cs_name: "COIMBATORE",
    cs_short: "coimbatore",
    count_of_elector: 2106124,
    poll_percentage: 64.89,
    evm_votes: 1366597,
  },
  {
    serial_no: "A54",
    state: "Tamil Nadu",
    cs_name: "CUDDALORE",
    cs_short: "cuddalore",
    count_of_elector: 1412746,
    poll_percentage: 72.57,
    evm_votes: 1025298,
  },
  {
    serial_no: "A55",
    state: "Tamil Nadu",
    cs_name: "DHARMAPURI",
    cs_short: "dharmapuri",
    count_of_elector: 1524896,
    poll_percentage: 81.2,
    evm_votes: 1238184,
  },
  {
    serial_no: "A56",
    state: "Tamil Nadu",
    cs_name: "DINDIGUL",
    cs_short: "dindigul",
    count_of_elector: 1607051,
    poll_percentage: 71.14,
    evm_votes: 1143196,
  },
  {
    serial_no: "A57",
    state: "Tamil Nadu",
    cs_name: "ERODE",
    cs_short: "erode",
    count_of_elector: 1538778,
    poll_percentage: 70.59,
    evm_votes: 1086287,
  },
  {
    serial_no: "A58",
    state: "Tamil Nadu",
    cs_name: "KALLAKURICHI",
    cs_short: "kallakurichi",
    count_of_elector: 1568681,
    poll_percentage: 79.21,
    evm_votes: 1242597,
  },
  {
    serial_no: "A59",
    state: "Tamil Nadu",
    cs_name: "KANCHEEPURAM",
    cs_short: "kancheepuram",
    count_of_elector: 1748866,
    poll_percentage: 71.68,
    evm_votes: 1253582,
  },
  {
    serial_no: "A60",
    state: "Tamil Nadu",
    cs_name: "KANNIYAKUMARI",
    cs_short: "kanniyakumari",
    count_of_elector: 1557915,
    poll_percentage: 65.44,
    evm_votes: 1019532,
  },
  {
    serial_no: "A61",
    state: "Tamil Nadu",
    cs_name: "KARUR",
    cs_short: "karur",
    count_of_elector: 1429790,
    poll_percentage: 78.7,
    evm_votes: 1125241,
  },
  {
    serial_no: "A62",
    state: "Tamil Nadu",
    cs_name: "KRISHNAGIRI",
    cs_short: "krishnagiri",
    count_of_elector: 1623179,
    poll_percentage: 71.5,
    evm_votes: 1160498,
  },
  {
    serial_no: "A63",
    state: "Tamil Nadu",
    cs_name: "MADURAI",
    cs_short: "madurai",
    count_of_elector: 1582271,
    poll_percentage: 62.04,
    evm_votes: 981650,
  },
  {
    serial_no: "A64",
    state: "Tamil Nadu",
    cs_name: "MAYILADUTHURAI",
    cs_short: "mayiladuthurai",
    count_of_elector: 1545568,
    poll_percentage: 70.09,
    evm_votes: 1083243,
  },
  {
    serial_no: "A65",
    state: "Tamil Nadu",
    cs_name: "NAGAPATTINAM",
    cs_short: "nagapattinam",
    count_of_elector: 1345120,
    poll_percentage: 71.94,
    evm_votes: 967694,
  },
  {
    serial_no: "A66",
    state: "Tamil Nadu",
    cs_name: "NAMAKKAL",
    cs_short: "namakkal",
    count_of_elector: 1452562,
    poll_percentage: 78.21,
    evm_votes: 1136069,
  },
  {
    serial_no: "A67",
    state: "Tamil Nadu",
    cs_name: "NILGIRIS",
    cs_short: "nilgiris",
    count_of_elector: 1428387,
    poll_percentage: 70.95,
    evm_votes: 1013410,
  },
  {
    serial_no: "A68",
    state: "Tamil Nadu",
    cs_name: "PERAMBALUR",
    cs_short: "perambalur",
    count_of_elector: 1446352,
    poll_percentage: 77.43,
    evm_votes: 1119881,
  },
  {
    serial_no: "A69",
    state: "Tamil Nadu",
    cs_name: "POLLACHI",
    cs_short: "pollachi",
    count_of_elector: 1597467,
    poll_percentage: 70.41,
    evm_votes: 1124743,
  },
  {
    serial_no: "A70",
    state: "Tamil Nadu",
    cs_name: "RAMANATHAPURAM",
    cs_short: "ramanathapuram",
    count_of_elector: 1617688,
    poll_percentage: 68.19,
    evm_votes: 1103036,
  },
  {
    serial_no: "A71",
    state: "Tamil Nadu",
    cs_name: "SALEM",
    cs_short: "salem",
    count_of_elector: 1658681,
    poll_percentage: 78.16,
    evm_votes: 1296481,
  },
  {
    serial_no: "A72",
    state: "Tamil Nadu",
    cs_name: "SIVAGANGA",
    cs_short: "sivaganga",
    count_of_elector: 1633857,
    poll_percentage: 64.26,
    evm_votes: 1049887,
  },
  {
    serial_no: "A73",
    state: "Tamil Nadu",
    cs_name: "SRIPERUMBUDUR",
    cs_short: "sriperumbudur",
    count_of_elector: 2382119,
    poll_percentage: 60.25,
    evm_votes: 1435243,
  },
  {
    serial_no: "A74",
    state: "Tamil Nadu",
    cs_name: "TENKASI",
    cs_short: "tenkasi",
    count_of_elector: 1525439,
    poll_percentage: 67.65,
    evm_votes: 1031961,
  },
  {
    serial_no: "A75",
    state: "Tamil Nadu",
    cs_name: "THANJAVUR",
    cs_short: "thanjavur",
    count_of_elector: 1501226,
    poll_percentage: 68.27,
    evm_votes: 1024949,
  },
  {
    serial_no: "A76",
    state: "Tamil Nadu",
    cs_name: "THENI",
    cs_short: "theni",
    count_of_elector: 1622949,
    poll_percentage: 69.84,
    evm_votes: 1133513,
  },
  {
    serial_no: "A77",
    state: "Tamil Nadu",
    cs_name: "THOOTHUKKUDI",
    cs_short: "thoothukkudi",
    count_of_elector: 1458430,
    poll_percentage: 66.88,
    evm_votes: 975468,
  },
  {
    serial_no: "A78",
    state: "Tamil Nadu",
    cs_name: "TIRUCHIRAPPALLI",
    cs_short: "tiruchirappalli",
    count_of_elector: 1553985,
    poll_percentage: 67.51,
    evm_votes: 1049093,
  },
  {
    serial_no: "A79",
    state: "Tamil Nadu",
    cs_name: "TIRUNELVELI",
    cs_short: "tirunelveli",
    count_of_elector: 1654503,
    poll_percentage: 64.1,
    evm_votes: 1060461,
  },
  {
    serial_no: "A80",
    state: "Tamil Nadu",
    cs_name: "TIRUPPUR",
    cs_short: "tiruppur",
    count_of_elector: 1608521,
    poll_percentage: 70.62,
    evm_votes: 1135998,
  },
  {
    serial_no: "A81",
    state: "Tamil Nadu",
    cs_name: "TIRUVALLUR",
    cs_short: "tiruvallur",
    count_of_elector: 2085991,
    poll_percentage: 68.59,
    evm_votes: 1430738,
  },
  {
    serial_no: "A82",
    state: "Tamil Nadu",
    cs_name: "TIRUVANNAMALAI",
    cs_short: "tiruvannamalai",
    count_of_elector: 1533099,
    poll_percentage: 74.24,
    evm_votes: 1138102,
  },
  {
    serial_no: "A83",
    state: "Tamil Nadu",
    cs_name: "VELLORE",
    cs_short: "vellore",
    count_of_elector: 1528273,
    poll_percentage: 73.53,
    evm_votes: 1123715,
  },
  {
    serial_no: "A84",
    state: "Tamil Nadu",
    cs_name: "VILUPPURAM",
    cs_short: "viluppuram",
    count_of_elector: 1503115,
    poll_percentage: 76.52,
    evm_votes: 1150164,
  },
  {
    serial_no: "A85",
    state: "Tamil Nadu",
    cs_name: "VIRUDHUNAGAR",
    cs_short: "virudhunagar",
    count_of_elector: 1501942,
    poll_percentage: 70.22,
    evm_votes: 1054634,
  },
  {
    serial_no: "A86",
    state: "Tripura",
    cs_name: "Tripura-West",
    cs_short: "tripurawest",
    count_of_elector: 1463526,
    poll_percentage: 81.48,
    evm_votes: 1192435,
  },
  {
    serial_no: "A87",
    state: "Uttar Pradesh",
    cs_name: "Bijnor",
    cs_short: "bijnor",
    count_of_elector: 1738307,
    poll_percentage: 58.73,
    evm_votes: 1020947,
  },
  {
    serial_no: "A88",
    state: "Uttar Pradesh",
    cs_name: "Kairana",
    cs_short: "kairana",
    count_of_elector: 1722432,
    poll_percentage: 62.46,
    evm_votes: 1075839,
  },
  {
    serial_no: "A89",
    state: "Uttar Pradesh",
    cs_name: "Moradabad",
    cs_short: "moradabad",
    count_of_elector: 2059578,
    poll_percentage: 62.18,
    evm_votes: 1280706,
  },
  {
    serial_no: "A90",
    state: "Uttar Pradesh",
    cs_name: "Muzaffarnagar",
    cs_short: "muzaffarnagar",
    count_of_elector: 1817472,
    poll_percentage: 59.13,
    evm_votes: 1074608,
  },
  {
    serial_no: "A91",
    state: "Uttar Pradesh",
    cs_name: "Nagina",
    cs_short: "nagina",
    count_of_elector: 1644909,
    poll_percentage: 60.75,
    evm_votes: 999203,
  },
  {
    serial_no: "A92",
    state: "Uttar Pradesh",
    cs_name: "Pilibhit",
    cs_short: "pilibhit",
    count_of_elector: 1831699,
    poll_percentage: 63.11,
    evm_votes: 1155917,
  },
  {
    serial_no: "A93",
    state: "Uttar Pradesh",
    cs_name: "Rampur",
    cs_short: "rampur",
    count_of_elector: 1731836,
    poll_percentage: 55.85,
    evm_votes: 967200,
  },
  {
    serial_no: "A94",
    state: "Uttar Pradesh",
    cs_name: "Saharanpur",
    cs_short: "saharanpur",
    count_of_elector: 1855310,
    poll_percentage: 66.14,
    evm_votes: 1227083,
  },
  {
    serial_no: "A95",
    state: "Uttarakhand",
    cs_name: "Almora",
    cs_short: "almora",
    count_of_elector: 1339327,
    poll_percentage: 48.74,
    evm_votes: 652726,
  },
  {
    serial_no: "A96",
    state: "Uttarakhand",
    cs_name: "Garhwal",
    cs_short: "garhwal",
    count_of_elector: 1369388,
    poll_percentage: 52.42,
    evm_votes: 717834,
  },
  {
    serial_no: "A97",
    state: "Uttarakhand",
    cs_name: "Haridwar",
    cs_short: "haridwar",
    count_of_elector: 2035726,
    poll_percentage: 63.53,
    evm_votes: 1293362,
  },
  {
    serial_no: "A98",
    state: "Uttarakhand",
    cs_name: "Nainital-Udhamsingh-Nagar",
    cs_short: "nainitaludhamsinghnagar",
    count_of_elector: 2015809,
    poll_percentage: 62.47,
    evm_votes: 1259180,
  },
  {
    serial_no: "A99",
    state: "Uttarakhand",
    cs_name: "Tehri-Garhwal",
    cs_short: "tehrigarhwal",
    count_of_elector: 1577664,
    poll_percentage: 53.76,
    evm_votes: 848186,
  },
  {
    serial_no: "A100",
    state: "West Bengal",
    cs_name: "Alipurduars",
    cs_short: "alipurduars",
    count_of_elector: 1773252,
    poll_percentage: 79.76,
    evm_votes: 1414258,
  },
  {
    serial_no: "A101",
    state: "West Bengal",
    cs_name: "Coochbehar",
    cs_short: "coochbehar",
    count_of_elector: 1966893,
    poll_percentage: 82.16,
    evm_votes: 1616079,
  },
  {
    serial_no: "A102",
    state: "West Bengal",
    cs_name: "Jalpaiguri",
    cs_short: "jalpaiguri",
    count_of_elector: 1885963,
    poll_percentage: 83.66,
    evm_votes: 1577828,
  },
];
export const phase2 = [
  {
    serial_no: "B1",
    state: "Assam",
    cs_name: "Darrang-Udalguri",
    cs_short: "darrangudalguri",
    count_of_elector: 2209314,
    poll_percentage: 82.01,
    evm_votes: 1811764,
  },
  {
    serial_no: "B2",
    state: "Assam",
    cs_name: "Diphu",
    cs_short: "diphu",
    count_of_elector: 901032,
    poll_percentage: 75.74,
    evm_votes: 682441,
  },
  {
    serial_no: "B3",
    state: "Assam",
    cs_name: "Karimganj",
    cs_short: "karimganj",
    count_of_elector: 1412148,
    poll_percentage: 80.48,
    evm_votes: 1136538,
  },
  {
    serial_no: "B4",
    state: "Assam",
    cs_name: "Nagaon",
    cs_short: "nagaon",
    count_of_elector: 1817204,
    poll_percentage: 84.97,
    evm_votes: 1544081,
  },
  {
    serial_no: "B5",
    state: "Assam",
    cs_name: "Silchar",
    cs_short: "silchar",
    count_of_elector: 1369578,
    poll_percentage: 79.05,
    evm_votes: 1082616,
  },
  {
    serial_no: "B6",
    state: "Bihar",
    cs_name: "Banka",
    cs_short: "banka",
    count_of_elector: 1856566,
    poll_percentage: 54.48,
    evm_votes: 1011513,
  },
  {
    serial_no: "B7",
    state: "Bihar",
    cs_name: "Bhagalpur",
    cs_short: "bhagalpur",
    count_of_elector: 1983031,
    poll_percentage: 53.5,
    evm_votes: 1060982,
  },
  {
    serial_no: "B8",
    state: "Bihar",
    cs_name: "Katihar",
    cs_short: "katihar",
    count_of_elector: 1833009,
    poll_percentage: 63.76,
    evm_votes: 1168752,
  },
  {
    serial_no: "B9",
    state: "Bihar",
    cs_name: "Kishanganj",
    cs_short: "kishanganj",
    count_of_elector: 1829994,
    poll_percentage: 62.84,
    evm_votes: 1149988,
  },
  {
    serial_no: "B10",
    state: "Bihar",
    cs_name: "Purnia",
    cs_short: "purnia",
    count_of_elector: 1893698,
    poll_percentage: 63.08,
    evm_votes: 1194484,
  },
  {
    serial_no: "B11",
    state: "Chhattisgarh",
    cs_name: "KANKER",
    cs_short: "kanker",
    count_of_elector: 1654440,
    poll_percentage: 76.23,
    evm_votes: 1261103,
  },
  {
    serial_no: "B12",
    state: "Chhattisgarh",
    cs_name: "MAHASAMUND",
    cs_short: "mahasamund",
    count_of_elector: 1762477,
    poll_percentage: 75.02,
    evm_votes: 1322125,
  },
  {
    serial_no: "B13",
    state: "Chhattisgarh",
    cs_name: "RAJNANDGAON",
    cs_short: "rajnandgaon",
    count_of_elector: 1868021,
    poll_percentage: 77.42,
    evm_votes: 1446247,
  },
  {
    serial_no: "B14",
    state: "Jammu and Kashmir",
    cs_name: "JAMMUUU",
    cs_short: "jammu",
    count_of_elector: 1780835,
    poll_percentage: 72.22,
    evm_votes: 1286144,
  },
  {
    serial_no: "B15",
    state: "Karnataka",
    cs_name: "Bangalore-central",
    cs_short: "bangalorecentral",
    count_of_elector: 2433751,
    poll_percentage: 54.06,
    evm_votes: 1315612,
  },
  {
    serial_no: "B16",
    state: "Karnataka",
    cs_name: "Bangalore-North",
    cs_short: "bangalorenorth",
    count_of_elector: 3214496,
    poll_percentage: 54.45,
    evm_votes: 1750372,
  },
  {
    serial_no: "B17",
    state: "Karnataka",
    cs_name: "Bangalore-Rural",
    cs_short: "bangalorerural",
    count_of_elector: 2802580,
    poll_percentage: 68.3,
    evm_votes: 1914030,
  },
  {
    serial_no: "B18",
    state: "Karnataka",
    cs_name: "Bangalore-South",
    cs_short: "bangaloresouth",
    count_of_elector: 2341759,
    poll_percentage: 53.17,
    evm_votes: 1245168,
  },
  {
    serial_no: "B19",
    state: "Karnataka",
    cs_name: "Chamarajanagar",
    cs_short: "chamarajanagar",
    count_of_elector: 1778310,
    poll_percentage: 76.82,
    evm_votes: 1366015,
  },
  {
    serial_no: "B20",
    state: "Karnataka",
    cs_name: "Chikkballapur",
    cs_short: "chikkballapur",
    count_of_elector: 1981347,
    poll_percentage: 77.0,
    evm_votes: 1525718,
  },
  {
    serial_no: "B21",
    state: "Karnataka",
    cs_name: "Chitradurga",
    cs_short: "chitradurga",
    count_of_elector: 1856876,
    poll_percentage: 73.3,
    evm_votes: 1361031,
  },
  {
    serial_no: "B22",
    state: "Karnataka",
    cs_name: "Dakshina-Kannada",
    cs_short: "dakshinakannada",
    count_of_elector: 1817603,
    poll_percentage: 77.56,
    evm_votes: 1409653,
  },
  {
    serial_no: "B23",
    state: "Karnataka",
    cs_name: "Hassan",
    cs_short: "hassan",
    count_of_elector: 1736610,
    poll_percentage: 77.68,
    evm_votes: 1348966,
  },
  {
    serial_no: "B24",
    state: "Karnataka",
    cs_name: "Kolar",
    cs_short: "kolar",
    count_of_elector: 1726914,
    poll_percentage: 78.27,
    evm_votes: 1351646,
  },
  {
    serial_no: "B25",
    state: "Karnataka",
    cs_name: "Mandya",
    cs_short: "mandya",
    count_of_elector: 1779243,
    poll_percentage: 81.67,
    evm_votes: 1453060,
  },
  {
    serial_no: "B26",
    state: "Karnataka",
    cs_name: "Mysore",
    cs_short: "mysore",
    count_of_elector: 2092222,
    poll_percentage: 70.62,
    evm_votes: 1477576,
  },
  {
    serial_no: "B27",
    state: "Karnataka",
    cs_name: "Tumkur",
    cs_short: "tumkur",
    count_of_elector: 1661309,
    poll_percentage: 78.05,
    evm_votes: 1296720,
  },
  {
    serial_no: "B28",
    state: "Karnataka",
    cs_name: "Udupi-Chikmagalur",
    cs_short: "udupichikmagalur",
    count_of_elector: 1585162,
    poll_percentage: 77.15,
    evm_votes: 1222888,
  },
  {
    serial_no: "B29",
    state: "Kerala",
    cs_name: "Alappuzha",
    cs_short: "alappuzha",
    count_of_elector: 1400082,
    poll_percentage: 75.05,
    evm_votes: 1050726,
  },
  {
    serial_no: "B30",
    state: "Kerala",
    cs_name: "Alathur",
    cs_short: "alathur",
    count_of_elector: 1337496,
    poll_percentage: 73.42,
    evm_votes: 981945,
  },
  {
    serial_no: "B31",
    state: "Kerala",
    cs_name: "Attingal",
    cs_short: "attingal",
    count_of_elector: 1396807,
    poll_percentage: 69.48,
    evm_votes: 970517,
  },
  {
    serial_no: "B32",
    state: "Kerala",
    cs_name: "Chalakudy",
    cs_short: "chalakudy",
    count_of_elector: 1310529,
    poll_percentage: 71.94,
    evm_votes: 942787,
  },
  {
    serial_no: "B33",
    state: "Kerala",
    cs_name: "Ernakulam",
    cs_short: "ernakulam",
    count_of_elector: 1324047,
    poll_percentage: 68.29,
    evm_votes: 904131,
  },
  {
    serial_no: "B34",
    state: "Kerala",
    cs_name: "Idukki",
    cs_short: "idukki",
    count_of_elector: 1250157,
    poll_percentage: 66.55,
    evm_votes: 831936,
  },
  {
    serial_no: "B35",
    state: "Kerala",
    cs_name: "Kannur",
    cs_short: "kannur",
    count_of_elector: 1358368,
    poll_percentage: 77.21,
    evm_votes: 1048839,
  },
  {
    serial_no: "B36",
    state: "Kerala",
    cs_name: "Kasaragod",
    cs_short: "kasaragod",
    count_of_elector: 1452230,
    poll_percentage: 76.04,
    evm_votes: 1104331,
  },
  {
    serial_no: "B37",
    state: "Kerala",
    cs_name: "Kollam",
    cs_short: "kollam",
    count_of_elector: 1326648,
    poll_percentage: 68.15,
    evm_votes: 904047,
  },
  {
    serial_no: "B38",
    state: "Kerala",
    cs_name: "Kottayam",
    cs_short: "kottayam",
    count_of_elector: 1254823,
    poll_percentage: 65.61,
    evm_votes: 823237,
  },
  {
    serial_no: "B39",
    state: "Kerala",
    cs_name: "Kozhikode",
    cs_short: "kozhikode",
    count_of_elector: 1429631,
    poll_percentage: 75.52,
    evm_votes: 1079683,
  },
  {
    serial_no: "B40",
    state: "Kerala",
    cs_name: "Malappuram",
    cs_short: "malappuram",
    count_of_elector: 1479921,
    poll_percentage: 72.95,
    evm_votes: 1079547,
  },
  {
    serial_no: "B41",
    state: "Kerala",
    cs_name: "Mavelikkara",
    cs_short: "mavelikkara",
    count_of_elector: 1331880,
    poll_percentage: 65.95,
    evm_votes: 878360,
  },
  {
    serial_no: "B42",
    state: "Kerala",
    cs_name: "Palakkad",
    cs_short: "palakkad",
    count_of_elector: 1398143,
    poll_percentage: 73.57,
    evm_votes: 1028627,
  },
  {
    serial_no: "B43",
    state: "Kerala",
    cs_name: "Pathanamthitta",
    cs_short: "pathanamthitta",
    count_of_elector: 1429700,
    poll_percentage: 63.37,
    evm_votes: 906051,
  },
  {
    serial_no: "B44",
    state: "Kerala",
    cs_name: "Ponnani",
    cs_short: "ponnani",
    count_of_elector: 1470804,
    poll_percentage: 69.34,
    evm_votes: 1019889,
  },
  {
    serial_no: "B45",
    state: "Kerala",
    cs_name: "Thiruvananthapuram",
    cs_short: "thiruvananthapuram",
    count_of_elector: 1430531,
    poll_percentage: 66.47,
    evm_votes: 950829,
  },
  {
    serial_no: "B46",
    state: "Kerala",
    cs_name: "Thrissur",
    cs_short: "thrissur",
    count_of_elector: 1483055,
    poll_percentage: 72.9,
    evm_votes: 1081125,
  },
  {
    serial_no: "B47",
    state: "Kerala",
    cs_name: "Vadakara",
    cs_short: "vadakara",
    count_of_elector: 1421883,
    poll_percentage: 78.41,
    evm_votes: 1114950,
  },
  {
    serial_no: "B48",
    state: "Kerala",
    cs_name: "Wayanad",
    cs_short: "wayanad",
    count_of_elector: 1462423,
    poll_percentage: 73.57,
    evm_votes: 1075921,
  },
  {
    serial_no: "B49",
    state: "Madhya Pradesh",
    cs_name: "DAMOH",
    cs_short: "damoh",
    count_of_elector: 1925314,
    poll_percentage: 56.48,
    evm_votes: 1087455,
  },
  {
    serial_no: "B50",
    state: "Madhya Pradesh",
    cs_name: "HOSHANGABAD",
    cs_short: "hoshangabad",
    count_of_elector: 1855692,
    poll_percentage: 67.21,
    evm_votes: 1247298,
  },
  {
    serial_no: "B51",
    state: "Madhya Pradesh",
    cs_name: "KHAJURAHO",
    cs_short: "khajuraho",
    count_of_elector: 1997483,
    poll_percentage: 56.97,
    evm_votes: 1137867,
  },
  {
    serial_no: "B52",
    state: "Madhya Pradesh",
    cs_name: "REWA",
    cs_short: "rewa",
    count_of_elector: 1852126,
    poll_percentage: 49.43,
    evm_votes: 915444,
  },
  {
    serial_no: "B53",
    state: "Madhya Pradesh",
    cs_name: "SATNA",
    cs_short: "satna",
    count_of_elector: 1705260,
    poll_percentage: 61.94,
    evm_votes: 1056175,
  },
  {
    serial_no: "B54",
    state: "Madhya Pradesh",
    cs_name: "TIKAMGARH",
    cs_short: "tikamgarh",
    count_of_elector: 1826585,
    poll_percentage: 60.0,
    evm_votes: 1096023,
  },
  {
    serial_no: "B55",
    state: "Maharashtra",
    cs_name: "Akola",
    cs_short: "akola",
    count_of_elector: 1890814,
    poll_percentage: 61.79,
    evm_votes: 1168366,
  },
  {
    serial_no: "B56",
    state: "Maharashtra",
    cs_name: "Amravati",
    cs_short: "amravati",
    count_of_elector: 1836078,
    poll_percentage: 63.67,
    evm_votes: 1169121,
  },
  {
    serial_no: "B57",
    state: "Maharashtra",
    cs_name: "Buldhana",
    cs_short: "buldhana",
    count_of_elector: 1782700,
    poll_percentage: 62.03,
    evm_votes: 1105761,
  },
  {
    serial_no: "B58",
    state: "Maharashtra",
    cs_name: "Hingoli",
    cs_short: "hingoli",
    count_of_elector: 1817734,
    poll_percentage: 63.54,
    evm_votes: 1154958,
  },
  {
    serial_no: "B59",
    state: "Maharashtra",
    cs_name: "Nanded",
    cs_short: "nanded",
    count_of_elector: 1851843,
    poll_percentage: 60.94,
    evm_votes: 1128564,
  },
  {
    serial_no: "B60",
    state: "Maharashtra",
    cs_name: "Parbhani",
    cs_short: "parbhani",
    count_of_elector: 2123056,
    poll_percentage: 62.26,
    evm_votes: 1321868,
  },
  {
    serial_no: "B61",
    state: "Maharashtra",
    cs_name: "Wardha",
    cs_short: "wardha",
    count_of_elector: 1682771,
    poll_percentage: 64.85,
    evm_votes: 1091351,
  },
  {
    serial_no: "B62",
    state: "Maharashtra",
    cs_name: "Yavatmal-Washim",
    cs_short: "yavatmalwashim",
    count_of_elector: 1940916,
    poll_percentage: 62.87,
    evm_votes: 1220189,
  },
  {
    serial_no: "B63",
    state: "Manipur",
    cs_name: "Outer-Manipur",
    cs_short: "outermanipur",
    count_of_elector: 484949,
    poll_percentage: 85.11,
    evm_votes: 412737,
  },
  {
    serial_no: "B64",
    state: "Rajasthan",
    cs_name: "AJMER",
    cs_short: "ajmer",
    count_of_elector: 1995699,
    poll_percentage: 59.66,
    evm_votes: 1190561,
  },
  {
    serial_no: "B65",
    state: "Rajasthan",
    cs_name: "BANSWARA",
    cs_short: "banswara",
    count_of_elector: 2200438,
    poll_percentage: 73.88,
    evm_votes: 1625731,
  },
  {
    serial_no: "B66",
    state: "Rajasthan",
    cs_name: "BARMER",
    cs_short: "barmer",
    count_of_elector: 2206237,
    poll_percentage: 75.93,
    evm_votes: 1675276,
  },
  {
    serial_no: "B67",
    state: "Rajasthan",
    cs_name: "BHILWARA",
    cs_short: "bhilwara",
    count_of_elector: 2147159,
    poll_percentage: 60.37,
    evm_votes: 1296228,
  },
  {
    serial_no: "B68",
    state: "Rajasthan",
    cs_name: "CHITTORGARH",
    cs_short: "chittorgarh",
    count_of_elector: 2170167,
    poll_percentage: 68.61,
    evm_votes: 1488898,
  },
  {
    serial_no: "B69",
    state: "Rajasthan",
    cs_name: "JALORE",
    cs_short: "jalore",
    count_of_elector: 2297328,
    poll_percentage: 62.89,
    evm_votes: 1444866,
  },
  {
    serial_no: "B70",
    state: "Rajasthan",
    cs_name: "JHALAWAR-BARAN",
    cs_short: "jhalawarbaran",
    count_of_elector: 2030525,
    poll_percentage: 69.71,
    evm_votes: 1415420,
  },
  {
    serial_no: "B71",
    state: "Rajasthan",
    cs_name: "JODHPUR",
    cs_short: "jodhpur",
    count_of_elector: 2132713,
    poll_percentage: 64.27,
    evm_votes: 1370616,
  },
  {
    serial_no: "B72",
    state: "Rajasthan",
    cs_name: "KOTA",
    cs_short: "kota",
    count_of_elector: 2088023,
    poll_percentage: 71.26,
    evm_votes: 1487879,
  },
  {
    serial_no: "B73",
    state: "Rajasthan",
    cs_name: "PALI",
    cs_short: "pali",
    count_of_elector: 2343232,
    poll_percentage: 57.19,
    evm_votes: 1339989,
  },
  {
    serial_no: "B74",
    state: "Rajasthan",
    cs_name: "RAJSAMAND",
    cs_short: "rajsamand",
    count_of_elector: 2060942,
    poll_percentage: 58.39,
    evm_votes: 1203299,
  },
  {
    serial_no: "B75",
    state: "Rajasthan",
    cs_name: "TONK-SAWAI-MADHOPUR",
    cs_short: "tonksawaimadhopur",
    count_of_elector: 2148128,
    poll_percentage: 56.58,
    evm_votes: 1215309,
  },
  {
    serial_no: "B76",
    state: "Rajasthan",
    cs_name: "UDAIPUR",
    cs_short: "udaipur",
    count_of_elector: 2230971,
    poll_percentage: 66.66,
    evm_votes: 1487268,
  },
  {
    serial_no: "B77",
    state: "Tripura",
    cs_name: "Tripura East",
    cs_short: "tripuraeast",
    count_of_elector: 1396761,
    poll_percentage: 80.36,
    evm_votes: 1122424,
  },
  {
    serial_no: "B78",
    state: "Uttar Pradesh",
    cs_name: "Aligarh",
    cs_short: "aligarh",
    count_of_elector: 1997234,
    poll_percentage: 56.93,
    evm_votes: 1137051,
  },
  {
    serial_no: "B79",
    state: "Uttar Pradesh",
    cs_name: "Amroha",
    cs_short: "amroha",
    count_of_elector: 1716641,
    poll_percentage: 64.58,
    evm_votes: 1108579,
  },
  {
    serial_no: "B80",
    state: "Uttar Pradesh",
    cs_name: "Baghpat",
    cs_short: "baghpat",
    count_of_elector: 1653146,
    poll_percentage: 56.16,
    evm_votes: 928392,
  },
  {
    serial_no: "B81",
    state: "Uttar Pradesh",
    cs_name: "Bulandshahr",
    cs_short: "bulandshahr",
    count_of_elector: 1859462,
    poll_percentage: 56.42,
    evm_votes: 1049189,
  },
  {
    serial_no: "B82",
    state: "Uttar Pradesh",
    cs_name: "Gautam-Buddha-Nagar",
    cs_short: "gautambuddhanagar",
    count_of_elector: 2675148,
    poll_percentage: 53.63,
    evm_votes: 1434667,
  },
  {
    serial_no: "B83",
    state: "Uttar Pradesh",
    cs_name: "Ghaziabad",
    cs_short: "ghaziabad",
    count_of_elector: 2945487,
    poll_percentage: 49.88,
    evm_votes: 1469260,
  },
  {
    serial_no: "B84",
    state: "Uttar Pradesh",
    cs_name: "Mathura",
    cs_short: "mathura",
    count_of_elector: 1929550,
    poll_percentage: 49.41,
    evm_votes: 953388,
  },
  {
    serial_no: "B85",
    state: "Uttar Pradesh",
    cs_name: "Meerut",
    cs_short: "meerut",
    count_of_elector: 2000530,
    poll_percentage: 58.94,
    evm_votes: 1179121,
  },
  {
    serial_no: "B86",
    state: "West Bengal",
    cs_name: "Balurghat",
    cs_short: "balurghat",
    count_of_elector: 1561966,
    poll_percentage: 79.09,
    evm_votes: 1235347,
  },
  {
    serial_no: "B87",
    state: "West Bengal",
    cs_name: "Darjeeling",
    cs_short: "darjeeling",
    count_of_elector: 1765744,
    poll_percentage: 74.76,
    evm_votes: 1320072,
  },
  {
    serial_no: "B88",
    state: "West Bengal",
    cs_name: "Raiganj",
    cs_short: "raiganj",
    count_of_elector: 1790245,
    poll_percentage: 76.18,
    evm_votes: 1363854,
  },
];
export const phase3 = [
  {
    serial_no: "C1",
    state: "Assam",
    cs_name: "Barpeta",
    cs_short: "barpeta",
    count_of_elector: 1966847,
    poll_percentage: 85.24,
    evm_votes: 1676633,
  },
  {
    serial_no: "C2",
    state: "Assam",
    cs_name: "Dhubri",
    cs_short: "dhubri",
    count_of_elector: 2660827,
    poll_percentage: 92.08,
    evm_votes: 2450041,
  },
  {
    serial_no: "C3",
    state: "Assam",
    cs_name: "Guwahati",
    cs_short: "guwahati",
    count_of_elector: 2036846,
    poll_percentage: 78.39,
    evm_votes: 1596664,
  },
  {
    serial_no: "C4",
    state: "Assam",
    cs_name: "Kokrajhar",
    cs_short: "kokrajhar",
    count_of_elector: 1484571,
    poll_percentage: 83.55,
    evm_votes: 1240306,
  },
  {
    serial_no: "C5",
    state: "Bihar",
    cs_name: "Araria",
    cs_short: "araria",
    count_of_elector: 2018767,
    poll_percentage: 61.93,
    evm_votes: 1250261,
  },
  {
    serial_no: "C6",
    state: "Bihar",
    cs_name: "Jhanjharpur",
    cs_short: "jhanjharpur",
    count_of_elector: 2003040,
    poll_percentage: 54.48,
    evm_votes: 1091258,
  },
  {
    serial_no: "C7",
    state: "Bihar",
    cs_name: "Khagaria",
    cs_short: "khagaria",
    count_of_elector: 1840217,
    poll_percentage: 57.52,
    evm_votes: 1058539,
  },
  {
    serial_no: "C8",
    state: "Bihar",
    cs_name: "Madhepura",
    cs_short: "madhepura",
    count_of_elector: 2071166,
    poll_percentage: 58.29,
    evm_votes: 1207368,
  },
  {
    serial_no: "C9",
    state: "Bihar",
    cs_name: "Supaul",
    cs_short: "supaul",
    count_of_elector: 1927207,
    poll_percentage: 63.55,
    evm_votes: 1224821,
  },
  {
    serial_no: "C10",
    state: "Chhattisgarh",
    cs_name: "BILASPUR",
    cs_short: "bilaspur",
    count_of_elector: 2102687,
    poll_percentage: 64.77,
    evm_votes: 1361871,
  },
  {
    serial_no: "C11",
    state: "Chhattisgarh",
    cs_name: "DURG",
    cs_short: "durg",
    count_of_elector: 2090414,
    poll_percentage: 73.68,
    evm_votes: 1540193,
  },
  {
    serial_no: "C12",
    state: "Chhattisgarh",
    cs_name: "JANJGIR-CHAMPA",
    cs_short: "janjgirchampa",
    count_of_elector: 2056047,
    poll_percentage: 67.56,
    evm_votes: 1389012,
  },
  {
    serial_no: "C13",
    state: "Chhattisgarh",
    cs_name: "KORBA",
    cs_short: "korba",
    count_of_elector: 1618864,
    poll_percentage: 75.63,
    evm_votes: 1224268,
  },
  {
    serial_no: "C14",
    state: "Chhattisgarh",
    cs_name: "RAIGARH",
    cs_short: "raigarh",
    count_of_elector: 1838547,
    poll_percentage: 78.85,
    evm_votes: 1449673,
  },
  {
    serial_no: "C15",
    state: "Chhattisgarh",
    cs_name: "RAIPUR",
    cs_short: "raipur",
    count_of_elector: 2375379,
    poll_percentage: 66.82,
    evm_votes: 1587116,
  },
  {
    serial_no: "C16",
    state: "Chhattisgarh",
    cs_name: "SURGUJA",
    cs_short: "surguja",
    count_of_elector: 1819347,
    poll_percentage: 79.89,
    evm_votes: 1453444,
  },
  {
    serial_no: "C17",
    state: "Dadra & Nagar Haveli and Daman & Diu",
    cs_name: "Dadar-& Nagar-Haveli",
    cs_short: "dadar&nagarhaveli",
    count_of_elector: 283024,
    poll_percentage: 72.52,
    evm_votes: 205248,
  },
  {
    serial_no: "C18",
    state: "Dadra & Nagar Haveli and Daman & Diu",
    cs_name: "Daman-&-Diu",
    cs_short: "daman&diu",
    count_of_elector: 134189,
    poll_percentage: 68.77,
    evm_votes: 92279,
  },
  {
    serial_no: "C19",
    state: "Goa",
    cs_name: "North-Goa",
    cs_short: "northgoa",
    count_of_elector: 580577,
    poll_percentage: 77.69,
    evm_votes: 451042,
  },
  {
    serial_no: "C20",
    state: "Goa",
    cs_name: "South-Goa",
    cs_short: "southgoa",
    count_of_elector: 598767,
    poll_percentage: 74.47,
    evm_votes: 445916,
  },
  {
    serial_no: "C21",
    state: "Gujarat",
    cs_name: "Ahmedabad-East",
    cs_short: "ahmedabadeast",
    count_of_elector: 2038162,
    poll_percentage: 54.72,
    evm_votes: 1115317,
  },
  {
    serial_no: "C22",
    state: "Gujarat",
    cs_name: "Ahmedabad-West",
    cs_short: "ahmedabadwest",
    count_of_elector: 1726987,
    poll_percentage: 55.45,
    evm_votes: 957573,
  },
  {
    serial_no: "C23",
    state: "Gujarat",
    cs_name: "Amreli",
    cs_short: "amreli",
    count_of_elector: 1732810,
    poll_percentage: 50.29,
    evm_votes: 871373,
  },
  {
    serial_no: "C24",
    state: "Gujarat",
    cs_name: "Anand",
    cs_short: "anand",
    count_of_elector: 1780182,
    poll_percentage: 65.04,
    evm_votes: 1157763,
  },
  {
    serial_no: "C25",
    state: "Gujarat",
    cs_name: "Banaskantha",
    cs_short: "banaskantha",
    count_of_elector: 1961924,
    poll_percentage: 69.62,
    evm_votes: 1365989,
  },
  {
    serial_no: "C26",
    state: "Gujarat",
    cs_name: "Bardoli",
    cs_short: "bardoli",
    count_of_elector: 2048408,
    poll_percentage: 64.81,
    evm_votes: 1327669,
  },
  {
    serial_no: "C27",
    state: "Gujarat",
    cs_name: "Bharuch",
    cs_short: "bharuch",
    count_of_elector: 1723353,
    poll_percentage: 69.16,
    evm_votes: 1191877,
  },
  {
    serial_no: "C28",
    state: "Gujarat",
    cs_name: "Bhavnagar",
    cs_short: "bhavnagar",
    count_of_elector: 1916900,
    poll_percentage: 53.92,
    evm_votes: 1033629,
  },
  {
    serial_no: "C29",
    state: "Gujarat",
    cs_name: "Chhota-Udaipur",
    cs_short: "chhotaudaipur",
    count_of_elector: 1821708,
    poll_percentage: 69.15,
    evm_votes: 1259760,
  },
  {
    serial_no: "C30",
    state: "Gujarat",
    cs_name: "Dahod",
    cs_short: "dahod",
    count_of_elector: 1875136,
    poll_percentage: 59.31,
    evm_votes: 1112211,
  },
  {
    serial_no: "C31",
    state: "Gujarat",
    cs_name: "Gandhinagar",
    cs_short: "gandhinagar",
    count_of_elector: 2182736,
    poll_percentage: 59.8,
    evm_votes: 1305197,
  },
  {
    serial_no: "C32",
    state: "Gujarat",
    cs_name: "Jamnagar",
    cs_short: "jamnagar",
    count_of_elector: 1817864,
    poll_percentage: 57.67,
    evm_votes: 1048410,
  },
  {
    serial_no: "C33",
    state: "Gujarat",
    cs_name: "Junagadh",
    cs_short: "junagadh",
    count_of_elector: 1795110,
    poll_percentage: 58.91,
    evm_votes: 1057462,
  },
  {
    serial_no: "C34",
    state: "Gujarat",
    cs_name: "Kachchh",
    cs_short: "kachchh",
    count_of_elector: 1943136,
    poll_percentage: 56.14,
    evm_votes: 1090878,
  },
  {
    serial_no: "C35",
    state: "Gujarat",
    cs_name: "Kheda",
    cs_short: "kheda",
    count_of_elector: 2007404,
    poll_percentage: 58.12,
    evm_votes: 1166619,
  },
  {
    serial_no: "C36",
    state: "Gujarat",
    cs_name: "Mahesana",
    cs_short: "mahesana",
    count_of_elector: 1770617,
    poll_percentage: 59.86,
    evm_votes: 1059938,
  },
  {
    serial_no: "C37",
    state: "Gujarat",
    cs_name: "Navsari",
    cs_short: "navsari",
    count_of_elector: 2223550,
    poll_percentage: 59.66,
    evm_votes: 1326542,
  },
  {
    serial_no: "C38",
    state: "Gujarat",
    cs_name: "Panchmahal",
    cs_short: "panchmahal",
    count_of_elector: 1896743,
    poll_percentage: 58.85,
    evm_votes: 1116171,
  },
  {
    serial_no: "C39",
    state: "Gujarat",
    cs_name: "Patan",
    cs_short: "patan",
    count_of_elector: 2019916,
    poll_percentage: 58.56,
    evm_votes: 1182950,
  },
  {
    serial_no: "C40",
    state: "Gujarat",
    cs_name: "Porbandar",
    cs_short: "porbandar",
    count_of_elector: 1768212,
    poll_percentage: 51.83,
    evm_votes: 916519,
  },
  {
    serial_no: "C41",
    state: "Gujarat",
    cs_name: "Rajkot",
    cs_short: "rajkot",
    count_of_elector: 2112273,
    poll_percentage: 59.69,
    evm_votes: 1260768,
  },
  {
    serial_no: "C42",
    state: "Gujarat",
    cs_name: "Sabarkantha",
    cs_short: "sabarkantha",
    count_of_elector: 1976349,
    poll_percentage: 63.56,
    evm_votes: 1256210,
  },
  {
    serial_no: "C43",
    state: "Gujarat",
    cs_name: "Surendranagar",
    cs_short: "surendranagar",
    count_of_elector: 2033419,
    poll_percentage: 55.09,
    evm_votes: 1120128,
  },
  {
    serial_no: "C44",
    state: "Gujarat",
    cs_name: "Vadodara",
    cs_short: "vadodara",
    count_of_elector: 1949573,
    poll_percentage: 61.59,
    evm_votes: 1200768,
  },
  {
    serial_no: "C45",
    state: "Gujarat",
    cs_name: "Valsad",
    cs_short: "valsad",
    count_of_elector: 1859974,
    poll_percentage: 72.71,
    evm_votes: 1352413,
  },
  {
    serial_no: "C46",
    state: "Karnataka",
    cs_name: "Bagalkot",
    cs_short: "bagalkot",
    count_of_elector: 1806183,
    poll_percentage: 72.66,
    evm_votes: 1312319,
  },
  {
    serial_no: "C47",
    state: "Karnataka",
    cs_name: "Belgaum",
    cs_short: "belgaum",
    count_of_elector: 1923788,
    poll_percentage: 71.49,
    evm_votes: 1375283,
  },
  {
    serial_no: "C48",
    state: "Karnataka",
    cs_name: "Bellary",
    cs_short: "bellary",
    count_of_elector: 1884040,
    poll_percentage: 73.59,
    evm_votes: 1386553,
  },
  {
    serial_no: "C49",
    state: "Karnataka",
    cs_name: "Bidar",
    cs_short: "bidar",
    count_of_elector: 1892962,
    poll_percentage: 65.47,
    evm_votes: 1239358,
  },
  {
    serial_no: "C50",
    state: "Karnataka",
    cs_name: "Bijapur",
    cs_short: "bijapur",
    count_of_elector: 1946090,
    poll_percentage: 66.32,
    evm_votes: 1290719,
  },
  {
    serial_no: "C51",
    state: "Karnataka",
    cs_name: "Chikkodi",
    cs_short: "chikkodi",
    count_of_elector: 1761694,
    poll_percentage: 78.66,
    evm_votes: 1385688,
  },
  {
    serial_no: "C52",
    state: "Karnataka",
    cs_name: "Davanagere",
    cs_short: "davanagere",
    count_of_elector: 1709244,
    poll_percentage: 76.99,
    evm_votes: 1315916,
  },
  {
    serial_no: "C53",
    state: "Karnataka",
    cs_name: "Dharwad",
    cs_short: "dharwad",
    count_of_elector: 1831975,
    poll_percentage: 74.37,
    evm_votes: 1362421,
  },
  {
    serial_no: "C54",
    state: "Karnataka",
    cs_name: "Gulbarga",
    cs_short: "gulbarga",
    count_of_elector: 2098202,
    poll_percentage: 62.25,
    evm_votes: 1306119,
  },
  {
    serial_no: "C55",
    state: "Karnataka",
    cs_name: "Haveri",
    cs_short: "haveri",
    count_of_elector: 1792774,
    poll_percentage: 77.6,
    evm_votes: 1391214,
  },
  {
    serial_no: "C56",
    state: "Karnataka",
    cs_name: "Koppal",
    cs_short: "koppal",
    count_of_elector: 1866397,
    poll_percentage: 70.99,
    evm_votes: 1324898,
  },
  {
    serial_no: "C57",
    state: "Karnataka",
    cs_name: "Raichur",
    cs_short: "raichur",
    count_of_elector: 2010103,
    poll_percentage: 64.66,
    evm_votes: 1299806,
  },
  {
    serial_no: "C58",
    state: "Karnataka",
    cs_name: "Shimoga",
    cs_short: "shimoga",
    count_of_elector: 1752885,
    poll_percentage: 78.33,
    evm_votes: 1372949,
  },
  {
    serial_no: "C59",
    state: "Karnataka",
    cs_name: "Uttara-Kannada",
    cs_short: "uttarakannada",
    count_of_elector: 1641156,
    poll_percentage: 76.53,
    evm_votes: 1256027,
  },
  {
    serial_no: "C60",
    state: "Madhya Pradesh",
    cs_name: "BETUL",
    cs_short: "betul",
    count_of_elector: 1895331,
    poll_percentage: 73.53,
    evm_votes: 1393608,
  },
  {
    serial_no: "C61",
    state: "Madhya Pradesh",
    cs_name: "BHIND",
    cs_short: "bhind",
    count_of_elector: 1900654,
    poll_percentage: 54.93,
    evm_votes: 1044022,
  },
  {
    serial_no: "C62",
    state: "Madhya Pradesh",
    cs_name: "BHOPAL",
    cs_short: "bhopal",
    count_of_elector: 2339411,
    poll_percentage: 64.06,
    evm_votes: 1498626,
  },
  {
    serial_no: "C63",
    state: "Madhya Pradesh",
    cs_name: "GUNA",
    cs_short: "guna",
    count_of_elector: 1889551,
    poll_percentage: 72.43,
    evm_votes: 1368554,
  },
  {
    serial_no: "C64",
    state: "Madhya Pradesh",
    cs_name: "GWALIOR",
    cs_short: "gwalior",
    count_of_elector: 2154601,
    poll_percentage: 62.13,
    evm_votes: 1338708,
  },
  {
    serial_no: "C65",
    state: "Madhya Pradesh",
    cs_name: "MORENA",
    cs_short: "morena",
    count_of_elector: 2006730,
    poll_percentage: 58.97,
    evm_votes: 1183282,
  },
  {
    serial_no: "C66",
    state: "Madhya Pradesh",
    cs_name: "RAJGARH",
    cs_short: "rajgarh",
    count_of_elector: 1875211,
    poll_percentage: 76.04,
    evm_votes: 1425911,
  },
  {
    serial_no: "C67",
    state: "Madhya Pradesh",
    cs_name: "SAGAR",
    cs_short: "sagar",
    count_of_elector: 1745690,
    poll_percentage: 65.75,
    evm_votes: 1147866,
  },
  {
    serial_no: "C68",
    state: "Madhya Pradesh",
    cs_name: "VIDISHA",
    cs_short: "vidisha",
    count_of_elector: 1945404,
    poll_percentage: 74.48,
    evm_votes: 1449010,
  },
  {
    serial_no: "C69",
    state: "Maharashtra",
    cs_name: "Baramati",
    cs_short: "baramati",
    count_of_elector: 2372668,
    poll_percentage: 59.5,
    evm_votes: 1411621,
  },
  {
    serial_no: "C70",
    state: "Maharashtra",
    cs_name: "Hatkanangale",
    cs_short: "hatkanangale",
    count_of_elector: 1814277,
    poll_percentage: 71.11,
    evm_votes: 1290073,
  },
  {
    serial_no: "C71",
    state: "Maharashtra",
    cs_name: "Kolhapur",
    cs_short: "kolhapur",
    count_of_elector: 1936403,
    poll_percentage: 71.59,
    evm_votes: 1386230,
  },
  {
    serial_no: "C72",
    state: "Maharashtra",
    cs_name: "Latur",
    cs_short: "latur",
    count_of_elector: 1977042,
    poll_percentage: 62.59,
    evm_votes: 1237355,
  },
  {
    serial_no: "C73",
    state: "Maharashtra",
    cs_name: "Madha",
    cs_short: "madha",
    count_of_elector: 1991454,
    poll_percentage: 63.65,
    evm_votes: 1267530,
  },
  {
    serial_no: "C74",
    state: "Maharashtra",
    cs_name: "Osmanabad",
    cs_short: "osmanabad",
    count_of_elector: 1992737,
    poll_percentage: 63.88,
    evm_votes: 1272969,
  },
  {
    serial_no: "C75",
    state: "Maharashtra",
    cs_name: "Raigad",
    cs_short: "raigad",
    count_of_elector: 1668372,
    poll_percentage: 60.51,
    evm_votes: 1009567,
  },
  {
    serial_no: "C76",
    state: "Maharashtra",
    cs_name: "Ratnagiri-Sindhudurg",
    cs_short: "ratnagirisindhudurg",
    count_of_elector: 1451630,
    poll_percentage: 62.52,
    evm_votes: 907618,
  },
  {
    serial_no: "C77",
    state: "Maharashtra",
    cs_name: "Sangli",
    cs_short: "sangli",
    count_of_elector: 1868174,
    poll_percentage: 62.27,
    evm_votes: 1163353,
  },
  {
    serial_no: "C78",
    state: "Maharashtra",
    cs_name: "Satara",
    cs_short: "satara",
    count_of_elector: 1889740,
    poll_percentage: 63.16,
    evm_votes: 1193492,
  },
  {
    serial_no: "C79",
    state: "Maharashtra",
    cs_name: "Solapur",
    cs_short: "solapur",
    count_of_elector: 2030119,
    poll_percentage: 59.19,
    evm_votes: 1201586,
  },
  {
    serial_no: "C80",
    state: "Uttar Pradesh",
    cs_name: "Agra",
    cs_short: "agra",
    count_of_elector: 2072685,
    poll_percentage: 54.08,
    evm_votes: 1120864,
  },
  {
    serial_no: "C81",
    state: "Uttar Pradesh",
    cs_name: "Aonla",
    cs_short: "aonla",
    count_of_elector: 1891713,
    poll_percentage: 57.44,
    evm_votes: 1086687,
  },
  {
    serial_no: "C82",
    state: "Uttar Pradesh",
    cs_name: "Badaun",
    cs_short: "badaun",
    count_of_elector: 2008758,
    poll_percentage: 54.35,
    evm_votes: 1091697,
  },
  {
    serial_no: "C83",
    state: "Uttar Pradesh",
    cs_name: "Bareilly",
    cs_short: "bareilly",
    count_of_elector: 1924434,
    poll_percentage: 58.03,
    evm_votes: 1116749,
  },
  {
    serial_no: "C84",
    state: "Uttar Pradesh",
    cs_name: "Etah",
    cs_short: "etah",
    count_of_elector: 1700524,
    poll_percentage: 59.31,
    evm_votes: 1008533,
  },
  {
    serial_no: "C85",
    state: "Uttar Pradesh",
    cs_name: "Fatehpur-Sikri",
    cs_short: "fatehpursikri",
    count_of_elector: 1798823,
    poll_percentage: 57.19,
    evm_votes: 1028791,
  },
  {
    serial_no: "C86",
    state: "Uttar Pradesh",
    cs_name: "Firozabad",
    cs_short: "firozabad",
    count_of_elector: 1890772,
    poll_percentage: 58.53,
    evm_votes: 1106747,
  },
  {
    serial_no: "C87",
    state: "Uttar Pradesh",
    cs_name: "Hathras",
    cs_short: "hathras",
    count_of_elector: 1938080,
    poll_percentage: 55.71,
    evm_votes: 1079731,
  },
  {
    serial_no: "C88",
    state: "Uttar Pradesh",
    cs_name: "Mainpuri",
    cs_short: "mainpuri",
    count_of_elector: 1790797,
    poll_percentage: 58.73,
    evm_votes: 1051758,
  },
  {
    serial_no: "C89",
    state: "Uttar Pradesh",
    cs_name: "Sambhal",
    cs_short: "sambhal",
    count_of_elector: 1898202,
    poll_percentage: 62.91,
    evm_votes: 1194209,
  },
  {
    serial_no: "C90",
    state: "West Bengal",
    cs_name: "Jangipur",
    cs_short: "jangipur",
    count_of_elector: 1805360,
    poll_percentage: 75.72,
    evm_votes: 1367014,
  },
  {
    serial_no: "C91",
    state: "West Bengal",
    cs_name: "Maldaha-Dakshin",
    cs_short: "maldahadakshin",
    count_of_elector: 1782159,
    poll_percentage: 76.69,
    evm_votes: 1366728,
  },
  {
    serial_no: "C92",
    state: "West Bengal",
    cs_name: "Maldaha-Uttar",
    cs_short: "maldahauttar",
    count_of_elector: 1862035,
    poll_percentage: 76.03,
    evm_votes: 1415718,
  },
  {
    serial_no: "C93",
    state: "West Bengal",
    cs_name: "Murshidabad",
    cs_short: "murshidabad",
    count_of_elector: 1888097,
    poll_percentage: 81.52,
    evm_votes: 1539112,
  },
];
export const phase4 = [
  {
    serial_no: "D1",
    state: "Andhra Pradesh",
    cs_name: "Amalapuram-(SC)",
    cs_short: "amalapuram",
    count_of_elector: 1531410,
    poll_percentage: 83.85,
    evm_votes: 1284018,
  },
  {
    serial_no: "D2",
    state: "Andhra Pradesh",
    cs_name: "Anakapalle",
    cs_short: "anakapalle",
    count_of_elector: 1596916,
    poll_percentage: 82.03,
    evm_votes: 1309977,
  },
  {
    serial_no: "D3",
    state: "Andhra Pradesh",
    cs_name: "Ananthapur",
    cs_short: "ananthapur",
    count_of_elector: 1767591,
    poll_percentage: 80.51,
    evm_votes: 1423108,
  },
  {
    serial_no: "D4",
    state: "Andhra Pradesh",
    cs_name: "Araku-(ST)",
    cs_short: "araku",
    count_of_elector: 1554633,
    poll_percentage: 73.68,
    evm_votes: 1145426,
  },
  {
    serial_no: "D5",
    state: "Andhra Pradesh",
    cs_name: "Bapatla-(SC)",
    cs_short: "bapatla",
    count_of_elector: 1506354,
    poll_percentage: 85.48,
    evm_votes: 1287704,
  },
  {
    serial_no: "D6",
    state: "Andhra Pradesh",
    cs_name: "Chittoor(SC)",
    cs_short: "chittoor",
    count_of_elector: 1640202,
    poll_percentage: 85.77,
    evm_votes: 1406880,
  },
  {
    serial_no: "D7",
    state: "Andhra Pradesh",
    cs_name: "Eluru",
    cs_short: "eluru",
    count_of_elector: 1637430,
    poll_percentage: 83.68,
    evm_votes: 1370153,
  },
  {
    serial_no: "D8",
    state: "Andhra Pradesh",
    cs_name: "Guntur",
    cs_short: "guntur",
    count_of_elector: 1791543,
    poll_percentage: 78.81,
    evm_votes: 1411989,
  },
  {
    serial_no: "D9",
    state: "Andhra Pradesh",
    cs_name: "Hindupur",
    cs_short: "hindupur",
    count_of_elector: 1656775,
    poll_percentage: 84.7,
    evm_votes: 1403259,
  },
  {
    serial_no: "D10",
    state: "Andhra Pradesh",
    cs_name: "Kadapa",
    cs_short: "kadapa",
    count_of_elector: 1639066,
    poll_percentage: 79.57,
    evm_votes: 1304256,
  },
  {
    serial_no: "D11",
    state: "Andhra Pradesh",
    cs_name: "Kakinada",
    cs_short: "kakinada",
    count_of_elector: 1634122,
    poll_percentage: 80.3,
    evm_votes: 1312255,
  },
  {
    serial_no: "D12",
    state: "Andhra Pradesh",
    cs_name: "Kurnoolu",
    cs_short: "kurnoolu",
    count_of_elector: 1722857,
    poll_percentage: 76.8,
    evm_votes: 1323071,
  },
  {
    serial_no: "D13",
    state: "Andhra Pradesh",
    cs_name: "Machilipatnam",
    cs_short: "machilipatnam",
    count_of_elector: 1539460,
    poll_percentage: 84.05,
    evm_votes: 1293935,
  },
  {
    serial_no: "D14",
    state: "Andhra Pradesh",
    cs_name: "Nandyal",
    cs_short: "nandyal",
    count_of_elector: 1721013,
    poll_percentage: 80.61,
    evm_votes: 1387367,
  },
  {
    serial_no: "D15",
    state: "Andhra Pradesh",
    cs_name: "Narsapuram",
    cs_short: "narsapuram",
    count_of_elector: 1472923,
    poll_percentage: 82.59,
    evm_votes: 1216550,
  },
  {
    serial_no: "D16",
    state: "Andhra Pradesh",
    cs_name: "Narsaraopet",
    cs_short: "narsaraopet",
    count_of_elector: 1734858,
    poll_percentage: 85.65,
    evm_votes: 1485909,
  },
  {
    serial_no: "D17",
    state: "Andhra Pradesh",
    cs_name: "Nellore",
    cs_short: "nellore",
    count_of_elector: 1712274,
    poll_percentage: 79.05,
    evm_votes: 1353563,
  },
  {
    serial_no: "D18",
    state: "Andhra Pradesh",
    cs_name: "Ongole",
    cs_short: "ongole",
    count_of_elector: 1607832,
    poll_percentage: 87.06,
    evm_votes: 1399707,
  },
  {
    serial_no: "D19",
    state: "Andhra Pradesh",
    cs_name: "Rajahmundry",
    cs_short: "rajahmundry",
    count_of_elector: 1623149,
    poll_percentage: 80.93,
    evm_votes: 1313630,
  },
  {
    serial_no: "D20",
    state: "Andhra Pradesh",
    cs_name: "Rajampet",
    cs_short: "rajampet",
    count_of_elector: 1665702,
    poll_percentage: 79.09,
    evm_votes: 1317448,
  },
  {
    serial_no: "D21",
    state: "Andhra Pradesh",
    cs_name: "Srikakulam",
    cs_short: "srikakulam",
    count_of_elector: 1631174,
    poll_percentage: 74.43,
    evm_votes: 1214128,
  },
  {
    serial_no: "D22",
    state: "Andhra Pradesh",
    cs_name: "Thirupathi(SC)",
    cs_short: "thirupathi",
    count_of_elector: 1729832,
    poll_percentage: 79.1,
    evm_votes: 1368362,
  },
  {
    serial_no: "D23",
    state: "Andhra Pradesh",
    cs_name: "Vijayawada",
    cs_short: "vijayawada",
    count_of_elector: 1704077,
    poll_percentage: 79.37,
    evm_votes: 1352495,
  },
  {
    serial_no: "D24",
    state: "Andhra Pradesh",
    cs_name: "Visakhapatnam",
    cs_short: "visakhapatnam",
    count_of_elector: 1927303,
    poll_percentage: 71.11,
    evm_votes: 1370484,
  },
  {
    serial_no: "D25",
    state: "Andhra Pradesh",
    cs_name: "Vizianagaram",
    cs_short: "vizianagaram",
    count_of_elector: 1585206,
    poll_percentage: 81.05,
    evm_votes: 1284886,
  },
  {
    serial_no: "D26",
    state: "Bihar",
    cs_name: "Begusarai",
    cs_short: "begusarai",
    count_of_elector: 2196089,
    poll_percentage: 58.7,
    evm_votes: 1289076,
  },
  {
    serial_no: "D27",
    state: "Bihar",
    cs_name: "Darbhanga",
    cs_short: "darbhanga",
    count_of_elector: 1781356,
    poll_percentage: 57.37,
    evm_votes: 1021962,
  },
  {
    serial_no: "D28",
    state: "Bihar",
    cs_name: "Munger",
    cs_short: "munger",
    count_of_elector: 2042279,
    poll_percentage: 55.55,
    evm_votes: 1134548,
  },
  {
    serial_no: "D29",
    state: "Bihar",
    cs_name: "Samastipur",
    cs_short: "samastipur",
    count_of_elector: 1818530,
    poll_percentage: 60.11,
    evm_votes: 1093182,
  },
  {
    serial_no: "D30",
    state: "Bihar",
    cs_name: "Ujiarpur",
    cs_short: "ujiarpur",
    count_of_elector: 1745408,
    poll_percentage: 59.59,
    evm_votes: 1040026,
  },
  {
    serial_no: "D31",
    state: "Jammu and Kashmir",
    cs_name: "SRINAGAR",
    cs_short: "srinagar",
    count_of_elector: 1747810,
    poll_percentage: 38.49,
    evm_votes: 672653,
  },
  {
    serial_no: "D32",
    state: "Jharkhand",
    cs_name: "Khunti",
    cs_short: "khunti",
    count_of_elector: 1326138,
    poll_percentage: 69.93,
    evm_votes: 927422,
  },
  {
    serial_no: "D33",
    state: "Jharkhand",
    cs_name: "Lohardaga",
    cs_short: "lohardaga",
    count_of_elector: 1441302,
    poll_percentage: 66.45,
    evm_votes: 957690,
  },
  {
    serial_no: "D34",
    state: "Jharkhand",
    cs_name: "Palamau",
    cs_short: "palamau",
    count_of_elector: 2243034,
    poll_percentage: 61.27,
    evm_votes: 1374358,
  },
  {
    serial_no: "D35",
    state: "Jharkhand",
    cs_name: "Singhbhum",
    cs_short: "singhbhum",
    count_of_elector: 1447562,
    poll_percentage: 69.32,
    evm_votes: 1003482,
  },
  {
    serial_no: "D36",
    state: "Madhya Pradesh",
    cs_name: "DEWAS",
    cs_short: "dewas",
    count_of_elector: 1940472,
    poll_percentage: 75.48,
    evm_votes: 1464690,
  },
  {
    serial_no: "D37",
    state: "Madhya Pradesh",
    cs_name: "DHAR",
    cs_short: "dhar",
    count_of_elector: 1953834,
    poll_percentage: 72.76,
    evm_votes: 1421585,
  },
  {
    serial_no: "D38",
    state: "Madhya Pradesh",
    cs_name: "INDORE",
    cs_short: "indore",
    count_of_elector: 2526803,
    poll_percentage: 61.67,
    evm_votes: 1558341,
  },
  {
    serial_no: "D39",
    state: "Madhya Pradesh",
    cs_name: "KHANDWA",
    cs_short: "khandwa",
    count_of_elector: 2112203,
    poll_percentage: 71.52,
    evm_votes: 1510588,
  },
  {
    serial_no: "D40",
    state: "Madhya Pradesh",
    cs_name: "KHARGONE",
    cs_short: "khargone",
    count_of_elector: 2046030,
    poll_percentage: 76.03,
    evm_votes: 1555585,
  },
  {
    serial_no: "D41",
    state: "Madhya Pradesh",
    cs_name: "MANDSOUR",
    cs_short: "mandsour",
    count_of_elector: 1898060,
    poll_percentage: 75.27,
    evm_votes: 1428623,
  },
  {
    serial_no: "D42",
    state: "Madhya Pradesh",
    cs_name: "RATLAM",
    cs_short: "ratlam",
    count_of_elector: 2094548,
    poll_percentage: 72.94,
    evm_votes: 1527828,
  },
  {
    serial_no: "D43",
    state: "Madhya Pradesh",
    cs_name: "UJJAIN",
    cs_short: "ujjain",
    count_of_elector: 1798704,
    poll_percentage: 73.82,
    evm_votes: 1327715,
  },
  {
    serial_no: "D44",
    state: "Maharashtra",
    cs_name: "Ahmednagar",
    cs_short: "ahmednagar",
    count_of_elector: 1981866,
    poll_percentage: 66.61,
    evm_votes: 1320168,
  },
  {
    serial_no: "D45",
    state: "Maharashtra",
    cs_name: "Aurangabad",
    cs_short: "aurangabad",
    count_of_elector: 2059710,
    poll_percentage: 63.03,
    evm_votes: 1298227,
  },
  {
    serial_no: "D46",
    state: "Maharashtra",
    cs_name: "Beed",
    cs_short: "beed",
    count_of_elector: 2142547,
    poll_percentage: 70.92,
    evm_votes: 1519526,
  },
  {
    serial_no: "D47",
    state: "Maharashtra",
    cs_name: "Jalgaon",
    cs_short: "jalgaon",
    count_of_elector: 1994046,
    poll_percentage: 58.47,
    evm_votes: 1165968,
  },
  {
    serial_no: "D48",
    state: "Maharashtra",
    cs_name: "Jalna",
    cs_short: "jalna",
    count_of_elector: 1967574,
    poll_percentage: 69.18,
    evm_votes: 1361226,
  },
  {
    serial_no: "D49",
    state: "Maharashtra",
    cs_name: "Maval",
    cs_short: "maval",
    count_of_elector: 2585018,
    poll_percentage: 54.87,
    evm_votes: 1418439,
  },
  {
    serial_no: "D50",
    state: "Maharashtra",
    cs_name: "Nandurbar",
    cs_short: "nandurbar",
    count_of_elector: 1970327,
    poll_percentage: 70.68,
    evm_votes: 1392635,
  },
  {
    serial_no: "D51",
    state: "Maharashtra",
    cs_name: "Pune",
    cs_short: "pune",
    count_of_elector: 2061276,
    poll_percentage: 53.54,
    evm_votes: 1103678,
  },
  {
    serial_no: "D52",
    state: "Maharashtra",
    cs_name: "Raver",
    cs_short: "raver",
    count_of_elector: 1821750,
    poll_percentage: 64.28,
    evm_votes: 1170944,
  },
  {
    serial_no: "D53",
    state: "Maharashtra",
    cs_name: "Shirdi",
    cs_short: "shirdi",
    count_of_elector: 1677335,
    poll_percentage: 63.03,
    evm_votes: 1057298,
  },
  {
    serial_no: "D54",
    state: "Maharashtra",
    cs_name: "Shirur",
    cs_short: "shirur",
    count_of_elector: 2539702,
    poll_percentage: 54.16,
    evm_votes: 1375593,
  },
  {
    serial_no: "D55",
    state: "Odisha",
    cs_name: "Berhampur",
    cs_short: "berhampur",
    count_of_elector: 1591380,
    poll_percentage: 65.41,
    evm_votes: 1040924,
  },
  {
    serial_no: "D56",
    state: "Odisha",
    cs_name: "Kalahandi",
    cs_short: "kalahandi",
    count_of_elector: 1700780,
    poll_percentage: 77.9,
    evm_votes: 1324936,
  },
  {
    serial_no: "D57",
    state: "Odisha",
    cs_name: "Koraput",
    cs_short: "koraput",
    count_of_elector: 1480922,
    poll_percentage: 77.53,
    evm_votes: 1148182,
  },
  {
    serial_no: "D58",
    state: "Odisha",
    cs_name: "Nabarangpur",
    cs_short: "nabarangpur",
    count_of_elector: 1514140,
    poll_percentage: 82.16,
    evm_votes: 1243957,
  },
  {
    serial_no: "D59",
    state: "Telangana",
    cs_name: "Adilabad",
    cs_short: "adilabad",
    count_of_elector: 1650175,
    poll_percentage: 74.03,
    evm_votes: 1221553,
  },
  {
    serial_no: "D60",
    state: "Telangana",
    cs_name: "Bhongir",
    cs_short: "bhongir",
    count_of_elector: 1808585,
    poll_percentage: 76.78,
    evm_votes: 1388680,
  },
  {
    serial_no: "D61",
    state: "Telangana",
    cs_name: "Chevella",
    cs_short: "chevella",
    count_of_elector: 2938370,
    poll_percentage: 56.4,
    evm_votes: 1657107,
  },
  {
    serial_no: "D62",
    state: "Telangana",
    cs_name: "Hyderabad",
    cs_short: "hyderabad",
    count_of_elector: 2217094,
    poll_percentage: 48.48,
    evm_votes: 1074827,
  },
  {
    serial_no: "D63",
    state: "Telangana",
    cs_name: "Karimnagar",
    cs_short: "karimnagar",
    count_of_elector: 1797150,
    poll_percentage: 72.54,
    evm_votes: 1303690,
  },
  {
    serial_no: "D64",
    state: "Telangana",
    cs_name: "Khammam",
    cs_short: "khammam",
    count_of_elector: 1631039,
    poll_percentage: 76.09,
    evm_votes: 1241135,
  },
  {
    serial_no: "D65",
    state: "Telangana",
    cs_name: "Mahabubabad",
    cs_short: "mahabubabad",
    count_of_elector: 1532366,
    poll_percentage: 71.85,
    evm_votes: 1101030,
  },
  {
    serial_no: "D66",
    state: "Telangana",
    cs_name: "Mahbubnagar",
    cs_short: "mahbubnagar",
    count_of_elector: 1682470,
    poll_percentage: 72.43,
    evm_votes: 1218587,
  },
  {
    serial_no: "D67",
    state: "Telangana",
    cs_name: "Malkajgiri",
    cs_short: "malkajgiri",
    count_of_elector: 3779596,
    poll_percentage: 50.78,
    evm_votes: 1919131,
  },
  {
    serial_no: "D68",
    state: "Telangana",
    cs_name: "Medak",
    cs_short: "medak",
    count_of_elector: 1828210,
    poll_percentage: 75.09,
    evm_votes: 1372894,
  },
  {
    serial_no: "D69",
    state: "Telangana",
    cs_name: "Nagarkurnool",
    cs_short: "nagarkurnool",
    count_of_elector: 1738254,
    poll_percentage: 69.46,
    evm_votes: 1207470,
  },
  {
    serial_no: "D70",
    state: "Telangana",
    cs_name: "Nalgonda",
    cs_short: "nalgonda",
    count_of_elector: 1725465,
    poll_percentage: 74.02,
    evm_votes: 1277137,
  },
  {
    serial_no: "D71",
    state: "Telangana",
    cs_name: "Nizamabad",
    cs_short: "nizamabad",
    count_of_elector: 1704867,
    poll_percentage: 71.92,
    evm_votes: 1226133,
  },
  {
    serial_no: "D72",
    state: "Telangana",
    cs_name: "Peddapalle",
    cs_short: "peddapalle",
    count_of_elector: 1596430,
    poll_percentage: 67.87,
    evm_votes: 1083467,
  },
  {
    serial_no: "D73",
    state: "Telangana",
    cs_name: "Secunderabad",
    cs_short: "secunderabad",
    count_of_elector: 2120401,
    poll_percentage: 49.04,
    evm_votes: 1039834,
  },
  {
    serial_no: "D74",
    state: "Telangana",
    cs_name: "Warangal",
    cs_short: "warangal",
    count_of_elector: 1824466,
    poll_percentage: 68.86,
    evm_votes: 1256301,
  },
  {
    serial_no: "D75",
    state: "Telangana",
    cs_name: "Zahirabad",
    cs_short: "zahirabad",
    count_of_elector: 1641410,
    poll_percentage: 74.63,
    evm_votes: 1225049,
  },
  {
    serial_no: "D76",
    state: "Uttar Pradesh",
    cs_name: "Akbarpur",
    cs_short: "akbarpur",
    count_of_elector: 1869167,
    poll_percentage: 57.78,
    evm_votes: 1079978,
  },
  {
    serial_no: "D77",
    state: "Uttar Pradesh",
    cs_name: "Baharaich",
    cs_short: "baharaich",
    count_of_elector: 1838684,
    poll_percentage: 57.42,
    evm_votes: 1055818,
  },
  {
    serial_no: "D78",
    state: "Uttar Pradesh",
    cs_name: "Dhaurahra",
    cs_short: "dhaurahra",
    count_of_elector: 1719345,
    poll_percentage: 64.54,
    evm_votes: 1109680,
  },
  {
    serial_no: "D79",
    state: "Uttar Pradesh",
    cs_name: "Etawah",
    cs_short: "etawah",
    count_of_elector: 1828498,
    poll_percentage: 56.36,
    evm_votes: 1030554,
  },
  {
    serial_no: "D80",
    state: "Uttar Pradesh",
    cs_name: "Farrukhabad",
    cs_short: "farrukhabad",
    count_of_elector: 1747182,
    poll_percentage: 59.08,
    evm_votes: 1032244,
  },
  {
    serial_no: "D81",
    state: "Uttar Pradesh",
    cs_name: "Hardoi",
    cs_short: "hardoi",
    count_of_elector: 1910485,
    poll_percentage: 57.52,
    evm_votes: 1098820,
  },
  {
    serial_no: "D82",
    state: "Uttar Pradesh",
    cs_name: "Kannauj",
    cs_short: "kannauj",
    count_of_elector: 1988925,
    poll_percentage: 61.08,
    evm_votes: 1214886,
  },
  {
    serial_no: "D83",
    state: "Uttar Pradesh",
    cs_name: "Kanpur",
    cs_short: "kanpur",
    count_of_elector: 1662859,
    poll_percentage: 53.05,
    evm_votes: 882074,
  },
  {
    serial_no: "D84",
    state: "Uttar Pradesh",
    cs_name: "Kheri",
    cs_short: "kheri",
    count_of_elector: 1870170,
    poll_percentage: 64.68,
    evm_votes: 1209621,
  },
  {
    serial_no: "D85",
    state: "Uttar Pradesh",
    cs_name: "Misrikh",
    cs_short: "misrikh",
    count_of_elector: 1878195,
    poll_percentage: 55.89,
    evm_votes: 1049630,
  },
  {
    serial_no: "D86",
    state: "Uttar Pradesh",
    cs_name: "Shahjahanpur",
    cs_short: "shahjahanpur",
    count_of_elector: 2331834,
    poll_percentage: 53.36,
    evm_votes: 1244364,
  },
  {
    serial_no: "D87",
    state: "Uttar Pradesh",
    cs_name: "Sitapur",
    cs_short: "sitapur",
    count_of_elector: 1759943,
    poll_percentage: 62.54,
    evm_votes: 1100741,
  },
  {
    serial_no: "D88",
    state: "Uttar Pradesh",
    cs_name: "Unnao",
    cs_short: "unnao",
    count_of_elector: 2341740,
    poll_percentage: 55.46,
    evm_votes: 1298677,
  },
  {
    serial_no: "D89",
    state: "West Bengal",
    cs_name: "Asansol",
    cs_short: "asansol",
    count_of_elector: 1770281,
    poll_percentage: 73.27,
    evm_votes: 1297096,
  },
  {
    serial_no: "D90",
    state: "West Bengal",
    cs_name: "Baharampur",
    cs_short: "baharampur",
    count_of_elector: 1783078,
    poll_percentage: 77.54,
    evm_votes: 1382678,
  },
  {
    serial_no: "D91",
    state: "West Bengal",
    cs_name: "Bardhaman Purba",
    cs_short: "bardhamanpurba",
    count_of_elector: 1801333,
    poll_percentage: 82.85,
    evm_votes: 1492453,
  },
  {
    serial_no: "D92",
    state: "West Bengal",
    cs_name: "Bardhaman-Durgapur",
    cs_short: "bardhamandurgapur",
    count_of_elector: 1851780,
    poll_percentage: 80.72,
    evm_votes: 1494778,
  },
  {
    serial_no: "D93",
    state: "West Bengal",
    cs_name: "Birbhum",
    cs_short: "birbhum",
    count_of_elector: 1857022,
    poll_percentage: 81.91,
    evm_votes: 1521023,
  },
  {
    serial_no: "D94",
    state: "West Bengal",
    cs_name: "Bolpur",
    cs_short: "bolpur",
    count_of_elector: 1839234,
    poll_percentage: 82.66,
    evm_votes: 1520401,
  },
  {
    serial_no: "D95",
    state: "West Bengal",
    cs_name: "Krishnanagar",
    cs_short: "krishnanagar",
    count_of_elector: 1755631,
    poll_percentage: 80.65,
    evm_votes: 1415859,
  },
  {
    serial_no: "D96",
    state: "West Bengal",
    cs_name: "Ranaghat",
    cs_short: "ranaghat",
    count_of_elector: 1871658,
    poll_percentage: 81.87,
    evm_votes: 1532304,
  },
];
export const phase5 = [
  {
    serial_no: "E1",
    state: "Bihar",
    cs_name: "Hajipur",
    cs_short: "hajipur",
    count_of_elector: 1967094,
    poll_percentage: 58.43,
    evm_votes: 1149406,
  },
  {
    serial_no: "E2",
    state: "Bihar",
    cs_name: "Madhubani",
    cs_short: "madhubani",
    count_of_elector: 1934980,
    poll_percentage: 53.04,
    evm_votes: 1026408,
  },
  {
    serial_no: "E3",
    state: "Bihar",
    cs_name: "Muzaffarpur",
    cs_short: "muzaffarpur",
    count_of_elector: 1866106,
    poll_percentage: 59.47,
    evm_votes: 1109688,
  },
  {
    serial_no: "E4",
    state: "Bihar",
    cs_name: "Saran",
    cs_short: "saran",
    count_of_elector: 1795010,
    poll_percentage: 56.73,
    evm_votes: 1018366,
  },
  {
    serial_no: "E5",
    state: "Bihar",
    cs_name: "Sitamarhi",
    cs_short: "sitamarhi",
    count_of_elector: 1947996,
    poll_percentage: 56.21,
    evm_votes: 1094885,
  },
  {
    serial_no: "E6",
    state: "Jammu and Kashmir",
    cs_name: "BARAMULLA",
    cs_short: "baramulla",
    count_of_elector: 1737865,
    poll_percentage: 59.1,
    evm_votes: 1027084,
  },
  {
    serial_no: "E7",
    state: "Jharkhand",
    cs_name: "Chatra",
    cs_short: "chatra",
    count_of_elector: 1689926,
    poll_percentage: 63.69,
    evm_votes: 1076352,
  },
  {
    serial_no: "E8",
    state: "Jharkhand",
    cs_name: "Hazaribagh",
    cs_short: "hazaribagh",
    count_of_elector: 1939374,
    poll_percentage: 64.39,
    evm_votes: 1248798,
  },
  {
    serial_no: "E9",
    state: "Jharkhand",
    cs_name: "Kodarma",
    cs_short: "kodarma",
    count_of_elector: 2205318,
    poll_percentage: 61.81,
    evm_votes: 1363010,
  },
  {
    serial_no: "E10",
    state: "Ladakh",
    cs_name: "Ladakh",
    cs_short: "ladakh",
    count_of_elector: 184808,
    poll_percentage: 71.82,
    evm_votes: 132727,
  },
  {
    serial_no: "E11",
    state: "Maharashtra",
    cs_name: "Bhiwandi",
    cs_short: "bhiwandi",
    count_of_elector: 2087244,
    poll_percentage: 59.89,
    evm_votes: 1250090,
  },
  {
    serial_no: "E12",
    state: "Maharashtra",
    cs_name: "Dhule",
    cs_short: "dhule",
    count_of_elector: 2022061,
    poll_percentage: 60.21,
    evm_votes: 1217523,
  },
  {
    serial_no: "E13",
    state: "Maharashtra",
    cs_name: "Dindori",
    cs_short: "dindori",
    count_of_elector: 1853387,
    poll_percentage: 66.75,
    evm_votes: 1237180,
  },
  {
    serial_no: "E14",
    state: "Maharashtra",
    cs_name: "Kalyan",
    cs_short: "kalyan",
    count_of_elector: 2082221,
    poll_percentage: 50.12,
    evm_votes: 1043610,
  },
  {
    serial_no: "E15",
    state: "Maharashtra",
    cs_name: "Mumbai-North",
    cs_short: "mumbainorth",
    count_of_elector: 1811942,
    poll_percentage: 57.02,
    evm_votes: 1033241,
  },
  {
    serial_no: "E16",
    state: "Maharashtra",
    cs_name: "Mumbai-North-Central",
    cs_short: "mumbainorthcentral",
    count_of_elector: 1744128,
    poll_percentage: 51.98,
    evm_votes: 906530,
  },
  {
    serial_no: "E17",
    state: "Maharashtra",
    cs_name: "Mumbai-North-East",
    cs_short: "mumbainortheast",
    count_of_elector: 1636890,
    poll_percentage: 56.37,
    evm_votes: 922760,
  },
  {
    serial_no: "E18",
    state: "Maharashtra",
    cs_name: "Mumbai-North-West",
    cs_short: "mumbainorthwest",
    count_of_elector: 1735088,
    poll_percentage: 54.84,
    evm_votes: 951580,
  },
  {
    serial_no: "E19",
    state: "Maharashtra",
    cs_name: "Mumbai-South",
    cs_short: "mumbaisouth",
    count_of_elector: 1536168,
    poll_percentage: 50.06,
    evm_votes: 769010,
  },
  {
    serial_no: "E20",
    state: "Maharashtra",
    cs_name: "Mumbai-South-Central",
    cs_short: "mumbaisouthcentral",
    count_of_elector: 1474405,
    poll_percentage: 53.6,
    evm_votes: 790339,
  },
  {
    serial_no: "E21",
    state: "Maharashtra",
    cs_name: "Nashik",
    cs_short: "nashik",
    count_of_elector: 2030124,
    poll_percentage: 60.75,
    evm_votes: 1233379,
  },
  {
    serial_no: "E22",
    state: "Maharashtra",
    cs_name: "Palghar",
    cs_short: "palghar",
    count_of_elector: 2148514,
    poll_percentage: 63.91,
    evm_votes: 1373162,
  },
  {
    serial_no: "E23",
    state: "Maharashtra",
    cs_name: "Thane",
    cs_short: "thane",
    count_of_elector: 2507372,
    poll_percentage: 52.09,
    evm_votes: 1306194,
  },
  {
    serial_no: "E24",
    state: "Odisha",
    cs_name: "Aska",
    cs_short: "aska",
    count_of_elector: 1620974,
    poll_percentage: 62.67,
    evm_votes: 1015883,
  },
  {
    serial_no: "E25",
    state: "Odisha",
    cs_name: "Bargarh",
    cs_short: "bargarh",
    count_of_elector: 1631974,
    poll_percentage: 79.78,
    evm_votes: 1302069,
  },
  {
    serial_no: "E26",
    state: "Odisha",
    cs_name: "Bolangir",
    cs_short: "bolangir",
    count_of_elector: 1801744,
    poll_percentage: 77.52,
    evm_votes: 1396719,
  },
  {
    serial_no: "E27",
    state: "Odisha",
    cs_name: "Kandhamal",
    cs_short: "kandhamal",
    count_of_elector: 1339090,
    poll_percentage: 74.16,
    evm_votes: 993091,
  },
  {
    serial_no: "E28",
    state: "Odisha",
    cs_name: "Sundargarh",
    cs_short: "sundargarh",
    count_of_elector: 1576105,
    poll_percentage: 73.02,
    evm_votes: 1150875,
  },
  {
    serial_no: "E29",
    state: "Uttar Pradesh",
    cs_name: "Amethi",
    cs_short: "amethi",
    count_of_elector: 1796098,
    poll_percentage: 54.34,
    evm_votes: 976053,
  },
  {
    serial_no: "E30",
    state: "Uttar Pradesh",
    cs_name: "Banda",
    cs_short: "banda",
    count_of_elector: 1747425,
    poll_percentage: 59.7,
    evm_votes: 1043256,
  },
  {
    serial_no: "E31",
    state: "Uttar Pradesh",
    cs_name: "Barabanki",
    cs_short: "barabanki",
    count_of_elector: 1918491,
    poll_percentage: 67.2,
    evm_votes: 1289258,
  },
  {
    serial_no: "E32",
    state: "Uttar Pradesh",
    cs_name: "Faizabad",
    cs_short: "faizabad",
    count_of_elector: 1927459,
    poll_percentage: 59.14,
    evm_votes: 1139882,
  },
  {
    serial_no: "E33",
    state: "Uttar Pradesh",
    cs_name: "Fatehpur",
    cs_short: "fatehpur",
    count_of_elector: 1938563,
    poll_percentage: 57.09,
    evm_votes: 1106690,
  },
  {
    serial_no: "E34",
    state: "Uttar Pradesh",
    cs_name: "Gonda",
    cs_short: "gonda",
    count_of_elector: 1843121,
    poll_percentage: 51.62,
    evm_votes: 951394,
  },
  {
    serial_no: "E35",
    state: "Uttar Pradesh",
    cs_name: "Hamirpur",
    cs_short: "hamirpur",
    count_of_elector: 1839761,
    poll_percentage: 60.6,
    evm_votes: 1114874,
  },
  {
    serial_no: "E36",
    state: "Uttar Pradesh",
    cs_name: "Jalaun",
    cs_short: "jalaun",
    count_of_elector: 2006161,
    poll_percentage: 56.18,
    evm_votes: 1127112,
  },
  {
    serial_no: "E37",
    state: "Uttar Pradesh",
    cs_name: "Jhansi",
    cs_short: "jhansi",
    count_of_elector: 2161221,
    poll_percentage: 63.86,
    evm_votes: 1380214,
  },
  {
    serial_no: "E38",
    state: "Uttar Pradesh",
    cs_name: "Kaiserganj",
    cs_short: "kaiserganj",
    count_of_elector: 1904726,
    poll_percentage: 55.68,
    evm_votes: 1060576,
  },
  {
    serial_no: "E39",
    state: "Uttar Pradesh",
    cs_name: "Kaushambi",
    cs_short: "kaushambi",
    count_of_elector: 1909620,
    poll_percentage: 52.8,
    evm_votes: 1008255,
  },
  {
    serial_no: "E40",
    state: "Uttar Pradesh",
    cs_name: "Lucknow",
    cs_short: "lucknow",
    count_of_elector: 2172171,
    poll_percentage: 52.28,
    evm_votes: 1135624,
  },
  {
    serial_no: "E41",
    state: "Uttar Pradesh",
    cs_name: "Mohanlalganj",
    cs_short: "mohanlalganj",
    count_of_elector: 2187232,
    poll_percentage: 62.88,
    evm_votes: 1375372,
  },
  {
    serial_no: "E42",
    state: "Uttar Pradesh",
    cs_name: "Rae-Bareli",
    cs_short: "raebareli",
    count_of_elector: 1784314,
    poll_percentage: 58.12,
    evm_votes: 1036997,
  },
  {
    serial_no: "E43",
    state: "West Bengal",
    cs_name: "Arambagh",
    cs_short: "arambagh",
    count_of_elector: 1883266,
    poll_percentage: 82.62,
    evm_votes: 1555882,
  },
  {
    serial_no: "E44",
    state: "West Bengal",
    cs_name: "Bangaon",
    cs_short: "bangaon",
    count_of_elector: 1836374,
    poll_percentage: 81.04,
    evm_votes: 1488209,
  },
  {
    serial_no: "E45",
    state: "West Bengal",
    cs_name: "Barrackpur",
    cs_short: "barrackpur",
    count_of_elector: 1508728,
    poll_percentage: 75.41,
    evm_votes: 1137763,
  },
  {
    serial_no: "E46",
    state: "West Bengal",
    cs_name: "Hooghly",
    cs_short: "hooghly",
    count_of_elector: 1858067,
    poll_percentage: 81.38,
    evm_votes: 1512060,
  },
  {
    serial_no: "E47",
    state: "West Bengal",
    cs_name: "Howrah",
    cs_short: "howrah",
    count_of_elector: 1769184,
    poll_percentage: 71.73,
    evm_votes: 1269079,
  },
  {
    serial_no: "E48",
    state: "West Bengal",
    cs_name: "Srerampur",
    cs_short: "srerampur",
    count_of_elector: 1926645,
    poll_percentage: 76.44,
    evm_votes: 1472793,
  },
  {
    serial_no: "E49",
    state: "West Bengal",
    cs_name: "Uluberia",
    cs_short: "uluberia",
    count_of_elector: 1741438,
    poll_percentage: 79.78,
    evm_votes: 1389316,
  },
];
export const phase6 = [
  {
    serial_no: "F1",
    state: "Bihar",
    cs_name: "Gopalganj",
    cs_short: "gopalganj",
    count_of_elector: 2024673,
    poll_percentage: 52.32,
    evm_votes: 1059298,
  },
  {
    serial_no: "F2",
    state: "Bihar",
    cs_name: "Maharajganj_bihar",
    cs_short: "maharajganj_bihar",
    count_of_elector: 1934937,
    poll_percentage: 52.27,
    evm_votes: 1011421,
  },
  {
    serial_no: "F3",
    state: "Bihar",
    cs_name: "Paschim-Champaran",
    cs_short: "paschimchamparan",
    count_of_elector: 1756078,
    poll_percentage: 61.62,
    evm_votes: 1082178,
  },
  {
    serial_no: "F4",
    state: "Bihar",
    cs_name: "Purvi-Champaran",
    cs_short: "purvichamparan",
    count_of_elector: 1790761,
    poll_percentage: 59.68,
    evm_votes: 1068642,
  },
  {
    serial_no: "F5",
    state: "Bihar",
    cs_name: "Sheohar",
    cs_short: "sheohar",
    count_of_elector: 1832745,
    poll_percentage: 57.4,
    evm_votes: 1052021,
  },
  {
    serial_no: "F6",
    state: "Bihar",
    cs_name: "Siwan",
    cs_short: "siwan",
    count_of_elector: 1896512,
    poll_percentage: 52.49,
    evm_votes: 995416,
  },
  {
    serial_no: "F7",
    state: "Bihar",
    cs_name: "Vaishali",
    cs_short: "vaishali",
    count_of_elector: 1869178,
    poll_percentage: 62.59,
    evm_votes: 1170009,
  },
  {
    serial_no: "F8",
    state: "Bihar",
    cs_name: "Valmiki-Nagar",
    cs_short: "valmikinagar",
    count_of_elector: 1827281,
    poll_percentage: 60.19,
    evm_votes: 1099781,
  },
  {
    serial_no: "F9",
    state: "Haryana",
    cs_name: "AMBALA",
    cs_short: "ambala",
    count_of_elector: 1996708,
    poll_percentage: 67.34,
    evm_votes: 1344503,
  },
  {
    serial_no: "F10",
    state: "Haryana",
    cs_name: "BHIWANI-MAHENDRAGARH",
    cs_short: "bhiwanimahendragarh",
    count_of_elector: 1793029,
    poll_percentage: 65.39,
    evm_votes: 1172526,
  },
  {
    serial_no: "F11",
    state: "Haryana",
    cs_name: "FARIDABAD",
    cs_short: "faridabad",
    count_of_elector: 2430212,
    poll_percentage: 60.52,
    evm_votes: 1470649,
  },
  {
    serial_no: "F12",
    state: "Haryana",
    cs_name: "GURGAON",
    cs_short: "gurgaon",
    count_of_elector: 2573411,
    poll_percentage: 62.03,
    evm_votes: 1596240,
  },
  {
    serial_no: "F13",
    state: "Haryana",
    cs_name: "HISAR",
    cs_short: "hisar",
    count_of_elector: 1790722,
    poll_percentage: 65.27,
    evm_votes: 1168784,
  },
  {
    serial_no: "F14",
    state: "Haryana",
    cs_name: "KARNAL",
    cs_short: "karnal",
    count_of_elector: 2104229,
    poll_percentage: 63.74,
    evm_votes: 1341174,
  },
  {
    serial_no: "F15",
    state: "Haryana",
    cs_name: "KURUKSHETRA",
    cs_short: "kurukshetra",
    count_of_elector: 1794300,
    poll_percentage: 67.01,
    evm_votes: 1202401,
  },
  {
    serial_no: "F16",
    state: "Haryana",
    cs_name: "ROHTAK",
    cs_short: "rohtak",
    count_of_elector: 1889844,
    poll_percentage: 65.68,
    evm_votes: 1241201,
  },
  {
    serial_no: "F17",
    state: "Haryana",
    cs_name: "SIRSA",
    cs_short: "sirsa",
    count_of_elector: 1937689,
    poll_percentage: 69.77,
    evm_votes: 1351932,
  },
  {
    serial_no: "F18",
    state: "Haryana",
    cs_name: "SONIPAT",
    cs_short: "sonipat",
    count_of_elector: 1766624,
    poll_percentage: 63.44,
    evm_votes: 1120791,
  },
  {
    serial_no: "F19",
    state: "Jammu and Kashmir",
    cs_name: "ANANTNAG-RAJOURI",
    cs_short: "anantnagrajouri",
    count_of_elector: 1836576,
    poll_percentage: 55.4,
    evm_votes: 1017451,
  },
  {
    serial_no: "F20",
    state: "Jharkhand",
    cs_name: "Dhanbad",
    cs_short: "dhanbad",
    count_of_elector: 2285237,
    poll_percentage: 62.06,
    evm_votes: 1418264,
  },
  {
    serial_no: "F21",
    state: "Jharkhand",
    cs_name: "Giridih",
    cs_short: "giridih",
    count_of_elector: 1864660,
    poll_percentage: 67.23,
    evm_votes: 1253553,
  },
  {
    serial_no: "F22",
    state: "Jharkhand",
    cs_name: "Jamshedpur",
    cs_short: "jamshedpur",
    count_of_elector: 1869278,
    poll_percentage: 67.68,
    evm_votes: 1265169,
  },
  {
    serial_no: "F23",
    state: "Jharkhand",
    cs_name: "Ranchi",
    cs_short: "ranchi",
    count_of_elector: 2197331,
    poll_percentage: 65.36,
    evm_votes: 1436127,
  },
  {
    serial_no: "F24",
    state: "NCT OF Delhi",
    cs_name: "Chandni-Chowk",
    cs_short: "chandnichowk",
    count_of_elector: 1645958,
    poll_percentage: 58.6,
    evm_votes: 964503,
  },
  {
    serial_no: "F25",
    state: "NCT OF Delhi",
    cs_name: "East-Delhi",
    cs_short: "eastdelhi",
    count_of_elector: 2120584,
    poll_percentage: 59.51,
    evm_votes: 1261988,
  },
  {
    serial_no: "F26",
    state: "NCT OF Delhi",
    cs_name: "New-Delhi",
    cs_short: "newdelhi",
    count_of_elector: 1525071,
    poll_percentage: 55.43,
    evm_votes: 845285,
  },
  {
    serial_no: "F27",
    state: "NCT OF Delhi",
    cs_name: "North-East-Delhi",
    cs_short: "northeastdelhi",
    count_of_elector: 2463159,
    poll_percentage: 62.89,
    evm_votes: 1549202,
  },
  {
    serial_no: "F28",
    state: "NCT OF Delhi",
    cs_name: "North-West-Delhi",
    cs_short: "northwestdelhi",
    count_of_elector: 2567423,
    poll_percentage: 57.85,
    evm_votes: 1485378,
  },
  {
    serial_no: "F29",
    state: "NCT OF Delhi",
    cs_name: "South-Delhi",
    cs_short: "southdelhi",
    count_of_elector: 2291764,
    poll_percentage: 56.45,
    evm_votes: 1293598,
  },
  {
    serial_no: "F30",
    state: "NCT OF Delhi",
    cs_name: "West-Delhi",
    cs_short: "westdelhi",
    count_of_elector: 2587977,
    poll_percentage: 58.79,
    evm_votes: 1521541,
  },
  {
    serial_no: "F31",
    state: "Odisha",
    cs_name: "Bhubaneswar",
    cs_short: "bhubaneswar",
    count_of_elector: 1672774,
    poll_percentage: 64.49,
    evm_votes: 1078810,
  },
  {
    serial_no: "F32",
    state: "Odisha",
    cs_name: "Cuttack",
    cs_short: "cuttack",
    count_of_elector: 1571622,
    poll_percentage: 71.2,
    evm_votes: 1118918,
  },
  {
    serial_no: "F33",
    state: "Odisha",
    cs_name: "Dhenkanal",
    cs_short: "dhenkanal",
    count_of_elector: 1529785,
    poll_percentage: 78.01,
    evm_votes: 1193460,
  },
  {
    serial_no: "F34",
    state: "Odisha",
    cs_name: "Keonjhar",
    cs_short: "keonjhar",
    count_of_elector: 1588179,
    poll_percentage: 78.97,
    evm_votes: 1254163,
  },
  {
    serial_no: "F35",
    state: "Odisha",
    cs_name: "Puri",
    cs_short: "puri",
    count_of_elector: 1586465,
    poll_percentage: 75.43,
    evm_votes: 1196684,
  },
  {
    serial_no: "F36",
    state: "Odisha",
    cs_name: "Sambalpur",
    cs_short: "sambalpur",
    count_of_elector: 1499728,
    poll_percentage: 79.5,
    evm_votes: 1192226,
  },
  {
    serial_no: "F37",
    state: "Uttar Pradesh",
    cs_name: "Allahabad",
    cs_short: "allahabad",
    count_of_elector: 1825730,
    poll_percentage: 51.82,
    evm_votes: 946076,
  },
  {
    serial_no: "F38",
    state: "Uttar Pradesh",
    cs_name: "Ambedkar-Nagar",
    cs_short: "ambedkarnagar",
    count_of_elector: 1911297,
    poll_percentage: 61.58,
    evm_votes: 1176920,
  },
  {
    serial_no: "F39",
    state: "Uttar Pradesh",
    cs_name: "Azamgarh",
    cs_short: "azamgarh",
    count_of_elector: 1868165,
    poll_percentage: 56.16,
    evm_votes: 1049205,
  },
  {
    serial_no: "F40",
    state: "Uttar Pradesh",
    cs_name: "Basti",
    cs_short: "basti",
    count_of_elector: 1902898,
    poll_percentage: 56.67,
    evm_votes: 1078313,
  },
  {
    serial_no: "F41",
    state: "Uttar Pradesh",
    cs_name: "Bhadohi",
    cs_short: "bhadohi",
    count_of_elector: 2037925,
    poll_percentage: 53.07,
    evm_votes: 1081465,
  },
  {
    serial_no: "F42",
    state: "Uttar Pradesh",
    cs_name: "Domariyaganj",
    cs_short: "domariyaganj",
    count_of_elector: 1961845,
    poll_percentage: 51.97,
    evm_votes: 1019548,
  },
  {
    serial_no: "F43",
    state: "Uttar Pradesh",
    cs_name: "Jaunpur",
    cs_short: "jaunpur",
    count_of_elector: 1977237,
    poll_percentage: 55.59,
    evm_votes: 1099223,
  },
  {
    serial_no: "F44",
    state: "Uttar Pradesh",
    cs_name: "Lalganj",
    cs_short: "lalganj",
    count_of_elector: 1838882,
    poll_percentage: 54.38,
    evm_votes: 1000053,
  },
  {
    serial_no: "F45",
    state: "Uttar Pradesh",
    cs_name: "Machhlishahr",
    cs_short: "machhlishahr",
    count_of_elector: 1940605,
    poll_percentage: 54.49,
    evm_votes: 1057361,
  },
  {
    serial_no: "F46",
    state: "Uttar Pradesh",
    cs_name: "Phulpur",
    cs_short: "phulpur",
    count_of_elector: 2067043,
    poll_percentage: 48.91,
    evm_votes: 1010909,
  },
  {
    serial_no: "F47",
    state: "Uttar Pradesh",
    cs_name: "Pratapgarh",
    cs_short: "pratapgarh",
    count_of_elector: 1833312,
    poll_percentage: 51.45,
    evm_votes: 943245,
  },
  {
    serial_no: "F48",
    state: "Uttar Pradesh",
    cs_name: "Sant-Kabir-Nagar",
    cs_short: "santkabirnagar",
    count_of_elector: 2071964,
    poll_percentage: 52.57,
    evm_votes: 1089154,
  },
  {
    serial_no: "F49",
    state: "Uttar Pradesh",
    cs_name: "Shrawasti",
    cs_short: "shrawasti",
    count_of_elector: 1980381,
    poll_percentage: 52.83,
    evm_votes: 1046253,
  },
  {
    serial_no: "F50",
    state: "Uttar Pradesh",
    cs_name: "Sultanpur",
    cs_short: "sultanpur",
    count_of_elector: 1852590,
    poll_percentage: 55.63,
    evm_votes: 1030583,
  },
  {
    serial_no: "F51",
    state: "West Bengal",
    cs_name: "Bankura",
    cs_short: "bankura",
    count_of_elector: 1780580,
    poll_percentage: 80.75,
    evm_votes: 1437826,
  },
  {
    serial_no: "F52",
    state: "West Bengal",
    cs_name: "Bishnupur",
    cs_short: "bishnupur",
    count_of_elector: 1754268,
    poll_percentage: 85.91,
    evm_votes: 1507040,
  },
  {
    serial_no: "F53",
    state: "West Bengal",
    cs_name: "Ghatal",
    cs_short: "ghatal",
    count_of_elector: 1939945,
    poll_percentage: 82.17,
    evm_votes: 1593990,
  },
  {
    serial_no: "F54",
    state: "West Bengal",
    cs_name: "Jhargram",
    cs_short: "jhargram",
    count_of_elector: 1779794,
    poll_percentage: 83.47,
    evm_votes: 1485591,
  },
  {
    serial_no: "F55",
    state: "West Bengal",
    cs_name: "Kanthi",
    cs_short: "kanthi",
    count_of_elector: 1794537,
    poll_percentage: 84.77,
    evm_votes: 1521159,
  },
  {
    serial_no: "F56",
    state: "West Bengal",
    cs_name: "Medinipur",
    cs_short: "medinipur",
    count_of_elector: 1811243,
    poll_percentage: 81.56,
    evm_votes: 1477309,
  },
  {
    serial_no: "F57",
    state: "West Bengal",
    cs_name: "Purulia",
    cs_short: "purulia",
    count_of_elector: 1823120,
    poll_percentage: 78.39,
    evm_votes: 1429190,
  },
  {
    serial_no: "F58",
    state: "West Bengal",
    cs_name: "Tamluk",
    cs_short: "tamluk",
    count_of_elector: 1850741,
    poll_percentage: 84.79,
    evm_votes: 1569233,
  },
];
export const phase7 = [
  {
    serial_no: "G1",
    state: "Bihar",
    cs_name: "Arrah",
    cs_short: "arrah",
    count_of_elector: 2165574,
    poll_percentage: 50.27,
    evm_votes: 1088685,
  },
  {
    serial_no: "G2",
    state: "Bihar",
    cs_name: "Buxar",
    cs_short: "buxar",
    count_of_elector: 1923164,
    poll_percentage: 55.39,
    evm_votes: 1065290,
  },
  {
    serial_no: "G3",
    state: "Bihar",
    cs_name: "Jahanabad",
    cs_short: "jahanabad",
    count_of_elector: 1670327,
    poll_percentage: 55.09,
    evm_votes: 920114,
  },
  {
    serial_no: "G4",
    state: "Bihar",
    cs_name: "Karakat",
    cs_short: "karakat",
    count_of_elector: 1881191,
    poll_percentage: 54.68,
    evm_votes: 1028641,
  },
  {
    serial_no: "G5",
    state: "Bihar",
    cs_name: "Nalanda",
    cs_short: "nalanda",
    count_of_elector: 2288240,
    poll_percentage: 49.78,
    evm_votes: 1139006,
  },
  {
    serial_no: "G6",
    state: "Bihar",
    cs_name: "Patliputra",
    cs_short: "patliputra",
    count_of_elector: 2073685,
    poll_percentage: 59.24,
    evm_votes: 1228549,
  },
  {
    serial_no: "G7",
    state: "Bihar",
    cs_name: "Patna-Sahib",
    cs_short: "patnasahib",
    count_of_elector: 2292045,
    poll_percentage: 46.85,
    evm_votes: 1073847,
  },
  {
    serial_no: "G8",
    state: "Bihar",
    cs_name: "Sasaram",
    cs_short: "sasaram",
    count_of_elector: 1910368,
    poll_percentage: 57.16,
    evm_votes: 1091993,
  },
  {
    serial_no: "G9",
    state: "Chandigarh",
    cs_name: "Chandigarh",
    cs_short: "chandigarh",
    count_of_elector: 659805,
    poll_percentage: 67.98,
    evm_votes: 448547,
  },
  {
    serial_no: "G10",
    state: "Himachal Pradesh",
    cs_name: "HAMIRPUR_hp",
    cs_short: "hamirpur_hp",
    count_of_elector: 1432636,
    poll_percentage: 71.56,
    evm_votes: 1025237,
  },
  {
    serial_no: "G11",
    state: "Himachal Pradesh",
    cs_name: "KANGRA",
    cs_short: "kangra",
    count_of_elector: 1502514,
    poll_percentage: 67.89,
    evm_votes: 1020026,
  },
  {
    serial_no: "G12",
    state: "Himachal Pradesh",
    cs_name: "MANDI",
    cs_short: "mandi",
    count_of_elector: 1364060,
    poll_percentage: 73.15,
    evm_votes: 997833,
  },
  {
    serial_no: "G13",
    state: "Himachal Pradesh",
    cs_name: "SHIMLA",
    cs_short: "shimla",
    count_of_elector: 1346369,
    poll_percentage: 71.26,
    evm_votes: 959445,
  },
  {
    serial_no: "G14",
    state: "Jharkhand",
    cs_name: "Dumka",
    cs_short: "dumka",
    count_of_elector: 1591061,
    poll_percentage: 73.87,
    evm_votes: 1175294,
  },
  {
    serial_no: "G15",
    state: "Jharkhand",
    cs_name: "Godda",
    cs_short: "godda",
    count_of_elector: 2028154,
    poll_percentage: 68.63,
    evm_votes: 1391960,
  },
  {
    serial_no: "G16",
    state: "Jharkhand",
    cs_name: "Rajmahal",
    cs_short: "rajmahal",
    count_of_elector: 1704671,
    poll_percentage: 70.78,
    evm_votes: 1206577,
  },
  {
    serial_no: "G17",
    state: "Odisha",
    cs_name: "Balasore",
    cs_short: "balasore",
    count_of_elector: 1608014,
    poll_percentage: 76.77,
    evm_votes: 1234427,
  },
  {
    serial_no: "G18",
    state: "Odisha",
    cs_name: "Bhadrak",
    cs_short: "bhadrak",
    count_of_elector: 1770915,
    poll_percentage: 73.23,
    evm_votes: 1296802,
  },
  {
    serial_no: "G19",
    state: "Odisha",
    cs_name: "Jagatsinghpur",
    cs_short: "jagatsinghpur",
    count_of_elector: 1700814,
    poll_percentage: 75.48,
    evm_votes: 1283700,
  },
  {
    serial_no: "G20",
    state: "Odisha",
    cs_name: "Jajpur",
    cs_short: "jajpur",
    count_of_elector: 1545664,
    poll_percentage: 74.47,
    evm_votes: 1151038,
  },
  {
    serial_no: "G21",
    state: "Odisha",
    cs_name: "Kendrapara",
    cs_short: "kendrapara",
    count_of_elector: 1792723,
    poll_percentage: 71.22,
    evm_votes: 1276773,
  },
  {
    serial_no: "G22",
    state: "Odisha",
    cs_name: "Mayurbhanj",
    cs_short: "mayurbhanj",
    count_of_elector: 1542927,
    poll_percentage: 75.79,
    evm_votes: 1169335,
  },
  {
    serial_no: "G23",
    state: "Punjab",
    cs_name: "Amritsar",
    cs_short: "amritsar",
    count_of_elector: 1611263,
    poll_percentage: 56.06,
    evm_votes: 903206,
  },
  {
    serial_no: "G24",
    state: "Punjab",
    cs_name: "Anandpur-Sahib",
    cs_short: "anandpursahib",
    count_of_elector: 1732211,
    poll_percentage: 61.98,
    evm_votes: 1073572,
  },
  {
    serial_no: "G25",
    state: "Punjab",
    cs_name: "Bathinda",
    cs_short: "bathinda",
    count_of_elector: 1651188,
    poll_percentage: 69.36,
    evm_votes: 1145241,
  },
  {
    serial_no: "G26",
    state: "Punjab",
    cs_name: "Faridkot",
    cs_short: "faridkot",
    count_of_elector: 1594033,
    poll_percentage: 63.34,
    evm_votes: 1009637,
  },
  {
    serial_no: "G27",
    state: "Punjab",
    cs_name: "Fatehgarh-Sahib",
    cs_short: "fatehgarhsahib",
    count_of_elector: 1552567,
    poll_percentage: 62.53,
    evm_votes: 970783,
  },
  {
    serial_no: "G28",
    state: "Punjab",
    cs_name: "Firozpur",
    cs_short: "firozpur",
    count_of_elector: 1670008,
    poll_percentage: 67.02,
    evm_votes: 1119167,
  },
  {
    serial_no: "G29",
    state: "Punjab",
    cs_name: "Gurdaspur",
    cs_short: "gurdaspur",
    count_of_elector: 1605204,
    poll_percentage: 66.67,
    evm_votes: 1070267,
  },
  {
    serial_no: "G30",
    state: "Punjab",
    cs_name: "Hoshiarpur",
    cs_short: "hoshiarpur",
    count_of_elector: 1601826,
    poll_percentage: 58.86,
    evm_votes: 942766,
  },
  {
    serial_no: "G31",
    state: "Punjab",
    cs_name: "Jalandhar",
    cs_short: "jalandhar",
    count_of_elector: 1654005,
    poll_percentage: 59.7,
    evm_votes: 987508,
  },
  {
    serial_no: "G32",
    state: "Punjab",
    cs_name: "Khadoor-Sahib",
    cs_short: "khadoorsahib",
    count_of_elector: 1667797,
    poll_percentage: 62.55,
    evm_votes: 1043248,
  },
  {
    serial_no: "G33",
    state: "Punjab",
    cs_name: "Ludhiana",
    cs_short: "ludhiana",
    count_of_elector: 1758614,
    poll_percentage: 60.12,
    evm_votes: 1057274,
  },
  {
    serial_no: "G34",
    state: "Punjab",
    cs_name: "Patiala",
    cs_short: "patiala",
    count_of_elector: 1806424,
    poll_percentage: 63.63,
    evm_votes: 1149417,
  },
  {
    serial_no: "G35",
    state: "Punjab",
    cs_name: "Sangrur",
    cs_short: "sangrur",
    count_of_elector: 1556601,
    poll_percentage: 64.63,
    evm_votes: 1006048,
  },
  {
    serial_no: "G36",
    state: "Uttar Pradesh",
    cs_name: "Ballia",
    cs_short: "ballia",
    count_of_elector: 1923645,
    poll_percentage: 52.05,
    evm_votes: 1001317,
  },
  {
    serial_no: "G37",
    state: "Uttar Pradesh",
    cs_name: "Bansgaon",
    cs_short: "bansgaon",
    count_of_elector: 1820854,
    poll_percentage: 51.79,
    evm_votes: 943007,
  },
  {
    serial_no: "G38",
    state: "Uttar Pradesh",
    cs_name: "Chandauli",
    cs_short: "chandauli",
    count_of_elector: 1843196,
    poll_percentage: 60.58,
    evm_votes: 1116673,
  },
  {
    serial_no: "G39",
    state: "Uttar Pradesh",
    cs_name: "Deoria",
    cs_short: "deoria",
    count_of_elector: 1873821,
    poll_percentage: 55.51,
    evm_votes: 1040178,
  },
  {
    serial_no: "G40",
    state: "Uttar Pradesh",
    cs_name: "Ghazipur",
    cs_short: "ghazipur",
    count_of_elector: 2074883,
    poll_percentage: 55.45,
    evm_votes: 1150496,
  },
  {
    serial_no: "G41",
    state: "Uttar Pradesh",
    cs_name: "Ghosi",
    cs_short: "ghosi",
    count_of_elector: 2083928,
    poll_percentage: 55.05,
    evm_votes: 1147213,
  },
  {
    serial_no: "G42",
    state: "Uttar Pradesh",
    cs_name: "Gorakhpur",
    cs_short: "gorakhpur",
    count_of_elector: 2097202,
    poll_percentage: 54.93,
    evm_votes: 1152057,
  },
  {
    serial_no: "G43",
    state: "Uttar Pradesh",
    cs_name: "Kushi-Nagar",
    cs_short: "kushinagar",
    count_of_elector: 1875222,
    poll_percentage: 57.57,
    evm_votes: 1079573,
  },
  {
    serial_no: "G44",
    state: "Uttar Pradesh",
    cs_name: "Maharajganj",
    cs_short: "maharajganj",
    count_of_elector: 2004050,
    poll_percentage: 60.31,
    evm_votes: 1208589,
  },
  {
    serial_no: "G45",
    state: "Uttar Pradesh",
    cs_name: "Mirzapur",
    cs_short: "mirzapur",
    count_of_elector: 1906327,
    poll_percentage: 57.92,
    evm_votes: 1104186,
  },
  {
    serial_no: "G46",
    state: "Uttar Pradesh",
    cs_name: "Robertsganj",
    cs_short: "robertsganj",
    count_of_elector: 1779189,
    poll_percentage: 56.78,
    evm_votes: 1010277,
  },
  {
    serial_no: "G47",
    state: "Uttar Pradesh",
    cs_name: "Salempur",
    cs_short: "salempur",
    count_of_elector: 1776982,
    poll_percentage: 51.38,
    evm_votes: 913009,
  },
  {
    serial_no: "G48",
    state: "Uttar Pradesh",
    cs_name: "Varanasi",
    cs_short: "varanasi",
    count_of_elector: 1997578,
    poll_percentage: 56.49,
    evm_votes: 1128527,
  },
  {
    serial_no: "G49",
    state: "West Bengal",
    cs_name: "Barasat",
    cs_short: "barasat",
    count_of_elector: 1905400,
    poll_percentage: 80.17,
    evm_votes: 1527620,
  },
  {
    serial_no: "G50",
    state: "West Bengal",
    cs_name: "Basirhat",
    cs_short: "basirhat",
    count_of_elector: 1804261,
    poll_percentage: 84.31,
    evm_votes: 1521154,
  },
  {
    serial_no: "G51",
    state: "West Bengal",
    cs_name: "Diamond-harbour",
    cs_short: "diamondharbour",
    count_of_elector: 1880779,
    poll_percentage: 81.04,
    evm_votes: 1524138,
  },
  {
    serial_no: "G52",
    state: "West Bengal",
    cs_name: "Dum-dum",
    cs_short: "dumdum",
    count_of_elector: 1699656,
    poll_percentage: 73.81,
    evm_votes: 1254452,
  },
  {
    serial_no: "G53",
    state: "West Bengal",
    cs_name: "Jadavpur",
    cs_short: "jadavpur",
    count_of_elector: 2033525,
    poll_percentage: 76.68,
    evm_votes: 1559330,
  },
  {
    serial_no: "G54",
    state: "West Bengal",
    cs_name: "Joynagar",
    cs_short: "joynagar",
    count_of_elector: 1844780,
    poll_percentage: 80.08,
    evm_votes: 1477298,
  },
  {
    serial_no: "G55",
    state: "West Bengal",
    cs_name: "Kolkata-Dakshin",
    cs_short: "kolkatadakshin",
    count_of_elector: 1849520,
    poll_percentage: 66.95,
    evm_votes: 1238256,
  },
  {
    serial_no: "G56",
    state: "West Bengal",
    cs_name: "Kolkata-Uttar",
    cs_short: "kolkatauttar",
    count_of_elector: 1505356,
    poll_percentage: 63.59,
    evm_votes: 957319,
  },
  {
    serial_no: "G57",
    state: "West Bengal",
    cs_name: "Mathurapur",
    cs_short: "mathurapur",
    count_of_elector: 1817068,
    poll_percentage: 82.02,
    evm_votes: 1490299,
  },
];

export const phases_data = [
  phase1,
  phase2,
  phase3,
  phase4,
  phase5,
  phase6,
  phase7,
];
