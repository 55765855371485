"use strict";

export const filterFunction = function (
  cs_object = {},
  // ph_object = {},
  filt_cs_name = "",
  filt_party = "",
  filt_state = ""
) {
  const searchTerm_CS = filt_cs_name.toLowerCase();
  const searchTerm_Party = filt_party.toUpperCase();
  const searchTerm_State = filt_state.toUpperCase();

  // if (searchTerm_CS) {
  //   console.log(`CS = ${searchTerm_CS}`);
  // }

  // if (searchTerm_Party) {
  //   console.log(`STATE = ${searchTerm_State}`);
  // }

  // if (searchTerm_State) {
  //   console.log(`PARTY = ${searchTerm_Party}`);
  // }
  // // console.log("Radhe Krishna from Utils World!!!! 😄");
  // console.log(searchTerm_CS);
  let cs_object1 = cs_object.filter(
    (e) =>
      // console.log(e.hasOwnProperty("state_name"))
      e.state_name?.toLowerCase().includes(searchTerm_CS) ||
      e.cs_name?.toLowerCase().includes(searchTerm_CS) ||
      e.winner?.toLowerCase().includes(searchTerm_CS) ||
      e.stats[0]?.party?.toLowerCase().includes(searchTerm_CS) ||
      e.leading_candidate.toLowerCase().includes(searchTerm_CS)
    // console.log(e.state_name.toLowerCase().includes(searchTerm_CS));
    // e.state_name.toLowerCase().includes(searchTerm_CS)
  );
  // let ph_object1 = ph_object;

  // console.log(typeof [1, 2, 3, 4, 5]);
  // console.log(cs_object.filter((e) => e.cs_name.toLowerCase() == "ladakh"));

  // console.log([1, 2, 3, 4, 5].filter((e) => e % 2 == 0));
  // console.log("-----------------------------");
  // console.log(cs_object1);
  // console.log("-----------------------------");
  // console.log("----------------------------");
  // console.log(cs_object1);
  // console.log("----------------------------");
  return cs_object1;
};

export const sortingfilter_party = function ({
  cs_object,
  party_sort = false,
  winning_mar_sort = false,
  csname_sort = false,
  totalevm_sort = false,
  countedevm_sort = false,
  verdict_sort = false,
}) {
  if (party_sort) {
    return cs_object.sort((a, b) => {
      if (a.stats[0].party < b.stats[0].party) {
        return -1;
      }
      if (a.stats[0].party > b.stats[0].party) {
        return 1;
      }
    });
    // console.log(typeof partywise_sort);
    // return partywise_sort;
  }

  return cs_object.sort((a, b) => {
    if (a.stats[0].party < b.stats[0].party) {
      return 1;
    }
    if (a.stats[0].party > b.stats[0].party) {
      return -1;
    }
  });
};

export const sortingfilter_wmargin = function ({
  cs_object,
  party_sort = false,
  winning_mar_sort = false,
  csname_sort = false,
  totalevm_sort = false,
  countedevm_sort = false,
  verdict_sort = false,
}) {
  if (winning_mar_sort) {
    return cs_object.sort((a, b) => {
      return +a.evm_candidate_winning_margin - +b.evm_candidate_winning_margin;
    });
  }
  return cs_object.sort((a, b) => {
    return +b.evm_candidate_winning_margin - +a.evm_candidate_winning_margin;
  });
};

export const sortingfilter_cs = function ({
  cs_object,
  party_sort = false,
  winning_mar_sort = false,
  csname_sort = false,
  totalevm_sort = false,
  countedevm_sort = false,
  verdict_sort = false,
}) {
  if (csname_sort) {
    return cs_object.sort((a, b) => {
      if (a.cs_name < b.cs_name) {
        return -1;
      }
      if (a.cs_name > b.cs_name) {
        return 1;
      }
    });
    // console.log(typeof partywise_sort);
    // return partywise_sort;
  }

  return cs_object.sort((a, b) => {
    if (a.cs_name < b.cs_name) {
      return 1;
    }
    if (a.cs_name > b.cs_name) {
      return -1;
    }
  });
};

export const sortingfilter_totalevm = function ({
  cs_object,
  party_sort = false,
  winning_mar_sort = false,
  csname_sort = false,
  totalevm_sort = false,
  countedevm_sort = false,
  verdict_sort = false,
}) {
  if (totalevm_sort) {
    return cs_object.sort((a, b) => {
      return +a.loggedEVMvotesAfterPhaseEnd - +b.loggedEVMvotesAfterPhaseEnd;
    });
  }
  return cs_object.sort((a, b) => {
    return +b.loggedEVMvotesAfterPhaseEnd - +a.loggedEVMvotesAfterPhaseEnd;
  });
};

export const sortingfilter_countedevm = function ({
  cs_object,
  party_sort = false,
  winning_mar_sort = false,
  csname_sort = false,
  totalevm_sort = false,
  countedevm_sort = false,
  verdict_sort = false,
}) {
  if (countedevm_sort) {
    return cs_object.sort((a, b) => {
      return +a.countedEVM_VotesOn4thJune - +b.countedEVM_VotesOn4thJune;
    });
  }
  return cs_object.sort((a, b) => {
    return +b.countedEVM_VotesOn4thJune - +a.countedEVM_VotesOn4thJune;
  });
};

export const sortingfilter_verdict = function ({
  cs_object,
  party_sort = false,
  winning_mar_sort = false,
  csname_sort = false,
  totalevm_sort = false,
  countedevm_sort = false,
  verdict_sort = false,
}) {
  if (verdict_sort) {
    return cs_object.sort((a, b) => {
      return (
        +a.evmRecordedVsCountedDifference - +b.evmRecordedVsCountedDifference
      );
    });
  }
  return cs_object.sort((a, b) => {
    return (
      +b.evmRecordedVsCountedDifference - +a.evmRecordedVsCountedDifference
    );
  });
};
// console.log([1, 4, 8, 2, 0, 11, 6].sort((a, c) => c - a));
